<template>
    <div>
    <form @submit.prevent="setList" class="filter"
          @reset.prevent="resetForm">
        <b-row>
            <b-col md="2" class="mb-3">
                <label>{{$t('TYPE')}}</label>
                <app-select v-model="filter.type" text-field="label" mode="multiselect"
                :options="unit_type_options" :search-empty-item="false"></app-select>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('PARTNER')}}</label>
                <app-select v-model="filter.partner"
                            :options="partnersOptions"
                            mode="multiselect"
                            :disabled="false"
                            :set-first="false"
                            text-field="name">
                </app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{$t('COUNTRY')}}</label>
                <app-select mode="multiselect" text-field="name"
                            :options="countryList" v-model="country">
                </app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{$t('CITY')}}</label>
                <app-select mode="multiselect" text-field="name"
                            v-model="city" :options="cityList">
                </app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{$t('PROPERTY')}}</label>
                <app-select v-if="propertySearch" mode="search" v-model="property" text-field="name"
                            :search="searchProperty" :search-clear="true" :options="propertyList"></app-select>
                <app-select v-else mode="multiselect" text-field="name"
                            :options="propertyList" v-model="property">
                </app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{$t('COUNTRY_DESTINATION')}}</label>
                <app-select text-field="label" :options="countryTagOptions"
                            v-model="countryTag" mode="multiselect">
                </app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{$t('REGION_DESTINATION')}}</label>
                <app-select text-field="label" :options="regionTagOptions"
                            v-model="regionTag" mode="multiselect">
                </app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{$t('CITY_DESTINATION')}}</label>
                <app-select text-field="label" :options="destinationTagOptions"
                            v-model="filter.destinationTag" mode="multiselect">
                </app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{$t('TAG')}}</label>
                <app-select text-field="label" :options="customTagOptions"
                            v-model="filter.customTag" mode="multiselect">
                </app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{$t('FILTER')}}</label>
                <app-select v-model="filter.active"
                            :options="activeList"
                            text-field="label"
                            :search-empty-item="false"
                            mode="select">
                </app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{$t('STATUS')}}</label>
                <app-select v-model="filter.status"
                            :options="statusList"
                            text-field="name"
                            :search-empty-item="false"
                            mode="multiselect"
                ></app-select>
            </b-col>
            <b-col md="3" class="mb-3 d-flex align-items-baseline">
                <app-button-submit class="filter-submit-wrapper" :inline="true" :disabled="loading" :loading="loaderSubmit" variant="primary"
                                   button_type="search">{{
                        $t("SEARCH") }}
                </app-button-submit>
                <app-button-reset :inline="true"  class="pl-2 ml-2" :disabled="loading" :loading="loaderReset" ></app-button-reset>
            </b-col>
        </b-row>
    </form>
    </div>
</template>

<script>
import {getPropertyOwners} from '@/services/owner/index'
import {getAllUnitTypeList, getUnitList, getUnitTypeList} from '@/services/unit/index'
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppAside from '@/components/app/form/AppAside'
import {EventBus} from '@/shared/EventBus'
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import AppButton from "@/components/app/AppButton/AppButton";
import ActivationWizard from "@/components/direct_booking/website/unit_activation/ActivationWizard";
import {getCityList, getCountries} from "@/services/location";
import {getPropertyList, getPropertyTypeList} from "@/services/property";
import {getAllTags, getTagsPaginated} from "@/services/tag";
import {
    DEFAULT_PAGINATION_LIMIT,
    TAG_TYPE_CUSTOM,
    TAG_TYPE_DESTINATION,
    TAG_DESTINATION_TYPE_CITY,
    TAG_DESTINATION_TYPE_COUNTRY, TAG_DESTINATION_TYPE_REGION
} from "@/shared/constants";
import {getUnitStatuses, getWebsiteCompanyList} from "@/services/direct_booking/website";
import {cloneDeep} from "lodash";

export default {
    name: "UnitActivationFilter",
    props:{
        loading:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            unitTypeList: [],
            ownerList: [],
            countryList:[],
            cityList:[],
            propertyList: [],
            destinationTagOptions:[],
            countryTagOptions:[],
            regionTagOptions:[],
            customTagOptions:[],
            unit_type_options: [],
            partnersOptions: [],
            allCompanies: [],
            statusList: [],
            activeList: [
                {id: 1, label: this.$t('ACTIVE')},
                {id: 0, label: this.$t('INACTIVE')},
                {id: 2, label: this.$t('DESTINATION_ASSIGNED')},
                {id: 3, label: this.$t('DESTINATION_NOT_ASSIGNED')}
            ],
            country: [],
            city: [],
            property: [],
            countryTag:[],
            regionTag:[],
            propertySearch: false,
            filter: {
                page: 1,
                limit: 10,
                company: null,
                name: '',
                type: [],
                active: null,
                destinationTag: [],
                customTag: [],
                setDestination: null,
                partner: [],
                status: []
            },
            reset:false,
            loaderReset:false,
            loaderSubmit:false,
            activateUnit: false,
            TAG_DESTINATION_TYPE_CITY
        }
    },
    components: {
        AppButton,
        AppButtonReset,
        AppSelect,
        AppButtonSubmit,
        AppAside,
        ActivationWizard
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        website(){
            return this.$store.getters['website/getWebsite']
        }
    },
    methods: {
        setList() {
            this.reset = false
            this.$emit("search",cloneDeep(this.formatRequest()))
        },
        formatRequest() {
            return {
                page: this.filter.page,
                limit: this.filter.limit,
                property_id: this.propertySearch ? this.property.id : this.property,
                company: this.filter.partner.length < 1 ? this.allCompanies.length ? this.allCompanies : this.company.id : this.filter.partner,
                type: this.filter.type,
                active: this.filter.active,
                country: this.country,
                city: this.city,
                destination: this.filter.destinationTag,
                region:this.regionTag,
                countries:this.countryTag,
                custom: this.filter.customTag,
                setDestination: this.filter.setDestination,
                status: this.filter.status
            }
        },
        resetForm() {
            this.loaderReset = true
            this.reset = true
            this.filter.type = []
            this.filter.active = null
            this.countryTag = []
            this.regionTag = []
            this.country = []
            this.city = []
            this.property = []
            this.filter.destinationTag = []
            this.filter.customTag = []
            this.filter.setDestination = null
            this.filter.partner = []
            this.filter.status = []
            this.$emit("search",this.formatRequest())

        },
        searchPropertyOwners() {
            return getPropertyOwners(Number(this.$route.params.id))
        },
        activateForDistribution(){
            this.activateUnit = true;
        },
        searchCountry(){
            getCountries({user_country: true}).then(response => {
                this.countryList = response.data
            })
        },
        searchCity(){
            getCityList({user_cities: true}).then(response=>{
                this.cityList = response.data
            })
        },
        searchProperty(value){
            return getPropertyList({name: value, company_id:this.company.id, city: this.city, country: this.country}).then(response => {
                return {data: response.data.items}
            })
        },
        getTags(type){
            getAllTags(type, {company: this.company.id}).then(response => {
                if(type === TAG_TYPE_DESTINATION){
                    this.destinationTagOptions = response.data
                }
                if(type === TAG_TYPE_CUSTOM){
                    this.customTagOptions = response.data
                }
            })
        }
    },
    created() {
        getAllUnitTypeList().then(response => {
            this.unit_type_options = response.data
        }, () => {
            this.unit_type_options = []
        })

        if (this.$route.query.activate) {
            this.activateUnit = true
            this.$router.replace({query: {}})
        }

        let destinationTypes = [TAG_DESTINATION_TYPE_CITY, TAG_DESTINATION_TYPE_COUNTRY, TAG_DESTINATION_TYPE_REGION]

        destinationTypes.forEach(el => {
            let req = {
                page: 1,
                perPage: 1000,
                destination_type: el,
                company: this.company.id,
                tag_type: TAG_TYPE_DESTINATION,
                order_by: 'label',
            }

            getTagsPaginated(req).then(response => {
                if (el === TAG_DESTINATION_TYPE_CITY){this.destinationTagOptions = response.data.items}
                if (el === TAG_DESTINATION_TYPE_COUNTRY){this.countryTagOptions = response.data.items}
                if (el === TAG_DESTINATION_TYPE_REGION){this.regionTagOptions = response.data.items}
            })
        })

        getPropertyList({ company_id:this.company.id, city: this.city, country: this.country}).then(response => {
            this.$nextTick(()=>{
                const data = response.data &&  response.data.hasOwnProperty('items') ? response.data.items : []
                this.propertyList = data.map(el=>{
                    return {id:el.id,name:el.name}
                })
            })
        })

        getWebsiteCompanyList(this.$route.params.id).then(response => {
            this.partnersOptions = response.data
            this.allCompanies = []
            response.data.forEach(el => {
                this.allCompanies.push(el.id)
            })
            if (response.data.length === 1){
                const properties = JSON.parse(localStorage.getItem('presets')).properties
                if (properties < 11){
                    this.propertyVisible = true
                }
            }
        })

        getUnitStatuses({distribution_id: this.website.distribution.id}).then(response => {
            this.statusList = response.data
        })

        this.getTags(TAG_TYPE_CUSTOM)
        this.searchCountry()
        this.searchCity()
        let localProperty = JSON.parse(localStorage.getItem('presets'))
        if (localProperty.properties !== null){
            if (localProperty.properties > 10){
                this.propertySearch = true
            }
        }else{
            this.propertySearch = true
        }
    },
    mounted(){
        this.setList()
        EventBus.$on('gl_close_wizard', ()=>{
            this.resetForm()
        })
    },
    watch:{
        country:{
            handler(){
                getCityList({user_cities: true, country: this.country}).then(response=>{
                    this.cityList = response.data
                })
                getPropertyList({company_id: this.company.id, country:this.country, city: this.city}).then(response => {
                    this.propertyList = response.data.items
                    this.propertySearch = response.data.items.length >= 11;
                })
            }
        },
        countryTag:{
            handler(value){
                let req = {
                    page: 1,
                    perPage: 10,
                    destination_type: TAG_DESTINATION_TYPE_REGION,
                    company: this.company.id,
                    country_parent_id: value,
                    tag_type: TAG_TYPE_DESTINATION,
                }
                getTagsPaginated(req).then(response => {
                    this.regionTagOptions = response.data.items
                })

                let reqCity = {
                    page: 1,
                    perPage: 10,
                    destination_type: TAG_DESTINATION_TYPE_CITY,
                    company: this.company.id,
                    country: value,
                    tag_type: TAG_TYPE_DESTINATION,
                }
                getTagsPaginated(reqCity).then(response => {
                    this.destinationTagOptions = response.data.items
                })
            }
        },
        regionTag:{
            handler(value){
                let req = {
                    page: 1,
                    perPage: 10,
                    destination_type: TAG_DESTINATION_TYPE_CITY,
                    company: this.company.id,
                    region_parent_id: value,
                    tag_type: TAG_TYPE_DESTINATION,
                }
                getTagsPaginated(req).then(response => {
                    this.destinationTagOptions = response.data.items
                })
            }
        },
        city:{
            handler(value){
                getPropertyList({company_id:this.company.id, country: this.country, city: this.city}).then(response => {
                    this.$nextTick(()=>{
                        const data = response.data &&  response.data.hasOwnProperty('items') ? response.data.items : []
                        this.propertyList = data.map(el=>{
                            return {id:el.id,name:el.name}
                        })
                        this.propertySearch = response.data.items.length >= 11;
                    })
                })
            }
        },
        loading: {
            handler(value) {
                if (this.reset) {
                    this.loaderReset = value
                } else {
                    this.loaderSubmit = value
                }
            }
        }
    },
}
</script>

<style scoped>

.active-button{
    display: flex;
    flex-direction: row-reverse;
}

</style>
