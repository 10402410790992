<template>
    <div v-if="hasPermission">
        <cancellation-policy-list class="mt-3"
                                  :key="'cancellation_policy_list_' + cancellationPolicyList.length"
                                  @AccessControlEvent="addToAccessControlCounter()"
                                  :cancellation-policy-list="cancellationPolicyList"
                                  :loading="loading"
                                  @editPolicy="editSidebar"
                                  @linkPolicy="linkPolicySidebar"
                                  @openSysPolicyPanel="systemPolicyPanelState=true"
                                  @openNewPolicy="editSidebar(null)"
                                  @unlinkPolicy="savePolicy"
        >
        </cancellation-policy-list>
        <AppAside v-model="editSidebarState">
            <template v-if="edit_id !== null"
                      slot="header">
                {{$t("EDIT_POLICY_SIDEBAR_TITLE")}}
            </template>
            <template v-else
                      slot="header">
                {{$t("NEW_POLICY_SIDEBAR_TITLE")}}
            </template>
            <cancellation-policy :edit_id="edit_id"
                                 @savePolicy="savePolicy"
                                 @deletePolicy="deletePolicy">
            </cancellation-policy>
        </AppAside>
        <AppAside v-model="systemPolicyPanelState">
            <template slot="header">{{$t("APPLY_SYSTEM_POLICY_SIDEBAR_TITLE")}}</template>
            <cancellation-policy-system-list @save="applyPolicy"></cancellation-policy-system-list>
        </AppAside>
        <app-aside v-model="linkPolicySidebarState">
            <template slot="header">
                {{$t("LINK_SYSTEM_CANCELLATION_POLICY")}}
            </template>
            <cancellation-policy-link-system-policy :company="Number(company)"
                                                    :policy-id="edit_id"
                                                    @linkPolicy="linkPolicy">
            </cancellation-policy-link-system-policy>
        </app-aside>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import CancellationPolicyList from "@/components/property/cancelation_policies/policy/CancelationPolicyList";
    import AppAside from "@/components/app/form/AppAside";
    import CancellationPolicy from '@/components/property/cancelation_policies/policy/CancelationPolicyItemEdit';
    import CancellationPolicySystemList
        from "@/components/property/cancelation_policies/system/CancelationPolicySystemList";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {
        C_PROPERTY_CANCELATION_POLICY_V,
        C_PROPERTY_CANCELLATION_POLICY_APPLY_SYSTEM_POLICY,
        C_PROPERTY_CANCELLATION_POLICY_NEW
    } from "@/shared/component_permission";
    import {deletePropertyCancelationPolicy, getPropertyCancelationPolicies} from "@/services/property";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_PROPERTY_CANCELLATION_POLICY_KEY} from "@/mixins/AccessControl/AccessControlEnumeration";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import CancellationPolicyText from "@/components/property/cancelation_policies/policy/CancellationPolicyText";
    import CancellationPolicyLinkSystemPolicy
        from "@/components/property/cancelation_policies/policy/CancellationPolicyLinkSystemPolicy";
    import {EventBus, GE_CANCELLATION_POLICY_APPLY, GE_CANCELLATION_POLICY_NEW} from "@/shared/EventBus";

    export default {
        name: "Index",
        components: {
            CancellationPolicyLinkSystemPolicy,
            CancellationPolicyText,
            AppSelect,
            AppButton,
            CancellationPolicyList,
            AppAside,
            CancellationPolicy,
            CancellationPolicySystemList
        },
        mixins: [getErrorMessage,AccessControlComponent],
        data() {
            return {
                cancellationPolicyList: [],
                editSidebarState: false,
                edit_id: null,
                policyListLoaded: false,
                systemPolicyPanelState: false,
                linkPolicySidebarState: false,
                C_PROPERTY_CANCELATION_POLICY_V,
                C_PROPERTY_CANCELLATION_POLICY_NEW,
                C_PROPERTY_CANCELLATION_POLICY_APPLY_SYSTEM_POLICY,
                access_control_fetch_key: AC_PROPERTY_CANCELLATION_POLICY_KEY,
                access_control_components: 1,
            }
        },
        watch: {
            '$route.params.id': {
                immediate: true,
                handler(id) {
                    this.getCancellationPolicies(id)
                }
            },
        },
        methods: {
            editSidebar(id = null) {
                this.edit_id = id
                this.editSidebarState = true
            },
            getCancellationPolicies(id) {
                getPropertyCancelationPolicies(id).then(response => {
                    this.cancellationPolicyList = response.data
                }, () => {
                    this.cancellationPolicyList = []
                }).finally(() => {
                    this.policyListLoaded = true
                    this.$store.commit("property/setCancellationPolicies", this.cancellationPolicyList)
                })
            },
            savePolicy() {
                this.getCancellationPolicies(this.$route.params.id)
            },
            applyPolicy() {
                this.getCancellationPolicies(this.$route.params.id)
                this.systemPolicyPanelState = false
            },
            linkPolicy() {
                this.getCancellationPolicies(this.$route.params.id)
                this.linkPolicySidebarState = false
            },
            deletePolicy(id) {
                deletePropertyCancelationPolicy(this.$route.params.id, id).then(response => {
                    this.cancellationPolicyList = response.data
                    this.editSidebarState = false
                    this.$store.commit("property/setCancellationPolicies", this.cancellationPolicyList)
                    notifySuccess()
                }, error => {
                    this.showErrorMessages(error)
                })
            },
            linkPolicySidebar(id) {
                this.edit_id = id
                this.linkPolicySidebarState = true
            }
        },
        computed: {
            hasPermission() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_CANCELATION_POLICY_V)
            },
            loading() {
                return !this.policyListLoaded
            },
            company() {
                return this.$store.getters['user/getCompany']
            }
        },
        created(){
            this.access_control_general_context = {
                property: this.$route.params.id,
                company: this.company
            }
        },
        mounted() {
            EventBus.$on(GE_CANCELLATION_POLICY_NEW, () => {
                this.editSidebar()
            })
            EventBus.$on(GE_CANCELLATION_POLICY_APPLY, () => {
                this.systemPolicyPanelState = true
            })
        },
        beforeDestroy() {
            EventBus.$off(GE_CANCELLATION_POLICY_NEW)
            EventBus.$off(GE_CANCELLATION_POLICY_APPLY)
        }
    }
</script>

<style scoped>

</style>
