<template>
    <div>
        <b-row v-if="actionButtonsRow">
            <b-col class="mb-4">
                <app-button
                    v-if="enableSuggestions && hasPermissionSuggestion"
                    :loading="loadingSuggestions"
                    :disabled="resettingOccupancyList || loading"
                    class="mr-3"
                    @click="getSuggestions">
                    {{ $t("SUGGESTIONS") }}
                </app-button>
                <app-button
                    v-if="enableReset && occupancyListChanged"
                    :loading="resettingOccupancyList"
                    :disabled="loading || loadingSuggestions"
                    @click="resetOccupancyList">
                    {{ $t("RESET") }}
                </app-button>
            </b-col>
        </b-row>
        <div class="table-wrapper">
            <table class="table">
                <thead>
                <tr>
                    <th class="table-header-label" scope="col">{{ $t("DEFAULT_OCCUPANCY") }}</th>
                    <th class="table-header-from">{{ $t("FROM") }}</th>
                    <th class="table-header-to">{{ $t("TO") }}</th>
                    <th class="table-header-increase-value">+ / -</th>
                    <th class="table-header-increase-type">{{ $t("TYPE") }}</th>
                    <th class="table-header-actions text-right"></th>
                </tr>
                </thead>
                <tbody>
                <unit-occupancy-list-item
                    @AccessControlEvent="addToAccessControlCounter"
                    @update="updateOccupancy($event, index)"
                    @delete="removeOccupancy(index)"
                    :occupancy="occupancy"
                    v-show="occupancy.deleted !== 1"
                    :key="index"
                    v-for="(occupancy, index) in occupancyList_">
                </unit-occupancy-list-item>
                <unit-occupancy-list-item-new
                    @AccessControlEvent="addToAccessControlCounter"
                    @save="addOccupancy"
                    :loading="loadingAddNew">
                </unit-occupancy-list-item-new>
                </tbody>
            </table>
        </div>

        <b-row>
            <b-col class="mt-4">
                <submit-button @AccessControlEvent="addToAccessControlCounter"
                               :access_control_context="{function: C_UNIT_CALENDAR_OCCUPANCY_E, key: AC_UNIT_OCCUPANCY}"
                               :v_show="checkPermission(C_UNIT_CALENDAR_OCCUPANCY_E)"
                               @click="setupValidate"
                               :loading="loading">
                </submit-button>
            </b-col>
        </b-row>

        <app-confirmation-dialog
            :show="confirmationDialogState"
            @confirm="save"
            @cancel="confirmationDialogState = false"
            :title="$t('OCCUPANCY_UPDATE_CONFIRMATION_TITLE')">
            {{ $t("OCCUPANCY_UPDATE_CONFIRMATION_MSG") }}
        </app-confirmation-dialog>
    </div>
</template>

<script>
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import SubmitButton from "@/components/app/AppButton/AppButtonSubmit";
import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import {notifySuccess} from '@/shared/plugins/toastr'
import UnitOccupancyListItem from "@/components/unit/calendar/forms/occupancy/UnitOccupancyListItem";
import {getPricingOccupancySuggestions, pricingSetupValidate, updatePricingOccupancyList} from "@/services/pricing";
import {C_UNIT_CALENDAR_OCCUPANCY_E, C_UNIT_CALENDAR_OCCUPANCY_SUGGESTIONS} from "@/shared/component_permission";
import UnitOccupancyListItemNew from "@/components/unit/calendar/forms/occupancy/UnitOccupancyListItemNew";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_UNIT_OCCUPANCY} from "@/mixins/AccessControl/AccessControlEnumeration";
import AppButton from "@/components/app/AppButton/AppButton";
import _cloneDeep from "lodash/cloneDeep";
import _isEqual from "lodash/isEqual";

export default {
    name: "UnitOccupancyList",
    components: {AppButton, UnitOccupancyListItemNew, UnitOccupancyListItem, SubmitButton, AppConfirmationDialog},
    mixins: [getErrorMessage, AccessControlComponent],
    props: {
        occupancyList: {
            type: Array | Object,
            default: () => {
                return []
            }
        },
        unit: {
            type: Object,
            required: true,
            validator: (value) => {
                return value && value.hasOwnProperty("id") && value.id
            },
        },
        maximumGuests: {
            type: Number,
            default: null,
            validator: value => {
                return value === null || (Number.isInteger(value) && value > 0)
            },
        },
        enableReset: {
            type: Boolean,
            default: false,
        },
        enableSuggestions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            C_UNIT_CALENDAR_OCCUPANCY_E,
            loadingAddNew: false,
            confirmationDialogState: false,
            saving: false,
            loading: false,
            occupancyList_: [],
            AC_UNIT_OCCUPANCY,
            access_control_fetch_key: AC_UNIT_OCCUPANCY,
            initialOccupancyList: null,
            loadingSuggestions: false,
            resettingOccupancyList: false,
            changes: null,
        }
    },
    computed: {
        actionButtonsRow() {
            return (this.enableSuggestions && this.hasPermissionSuggestion) || (this.enableReset && this.occupancyListChanged)
        },
        occupancyListChanged() {
            return !_isEqual(this.occupancyList_, this.initialOccupancyList)
        },
        hasPermissionSuggestion() {
            return this.checkPermission(C_UNIT_CALENDAR_OCCUPANCY_SUGGESTIONS)
        },
    },
    watch: {
        occupancyList: {
            immediate: true,
            deep: true,
            handler(value) {
                this.occupancyList_ = _cloneDeep(value)
                this.access_control_components = 3
                if (this.occupancyList_.length) {
                    this.occupancyList_.forEach((occupancy) => {
                        this.$set(occupancy, "discount", occupancy.discount === null ? null : Number(occupancy.discount))
                        this.$set(occupancy, "deleted", 0)
                    })
                }
                if (this.enableReset && this.initialOccupancyList === null) {
                    this.initialOccupancyList = _cloneDeep(this.occupancyList_)
                }
            },
        },
    },
    methods: {
        setupValidate() {
            this.loading = true
            let request = {
                unit: this.unit.id,
                validation_list: ["occupancy"],
                occupancy_list: this.getOccupancyListRequest(),
            }
            if (this.maximumGuests) {
                this.$set(request, "maximum_guests", this.maximumGuests)
            }
            pricingSetupValidate(request).then((response) => {
                this.changes = response.data.occupancy
                if (this.changes.changes_detected) {
                    if (this.changes.should_open_pop_up_dialog) {
                        this.confirmationDialogState = true
                    } else {
                        this.save()
                    }
                }
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
            })
        },
        updateOccupancy(payload, index) {
            const previousOccupancy = this.occupancyList_[index]
            const occupancy = {...previousOccupancy, ...payload}
            if (occupancy.default === 1 && (occupancy.discount !== null || occupancy.discount_type !== null)) {
                this.$set(occupancy, "discount", null)
                this.$set(occupancy, "discount_type", null)
            }
            if ((Number.isNaN(occupancy.discount) || Number(occupancy.discount) !== occupancy.discount) && occupancy.discount_type !== null) {
                this.$set(occupancy, "discount_type", null)

            }
            this.$set(this.occupancyList_, index, occupancy)
            if (occupancy.default === 1 && previousOccupancy.default !== 1) {
                this.occupancyList_.forEach((occupancyItem, occupancyIndex) => {
                    if (occupancyIndex !== index) {
                        this.$set(occupancyItem, "default", 0)
                        this.$set(occupancyItem, "discount", null)
                        this.$set(occupancyItem, "discount_type", null)
                    }
                })
            }
        },
        removeOccupancy(index) {
            this.$set(this.occupancyList_[index], "deleted", 1)
        },
        addOccupancy(occupancy) {
            this.loadingAddNew = true
            if (occupancy.default === 1) {
                this.occupancyList_.forEach((occupancyItem, occupancyIndex) => {
                    occupancyItem.default = 0
                    occupancyItem.discount = null
                    occupancyItem.discount_type = null
                    this.$set(this.occupancyList_, occupancyIndex, occupancyItem)
                })
            }
            let occupancyId = "index_" + this.occupancyList_.length
            this.occupancyList_.push({...occupancy, ...{id: occupancyId, deleted: 0}})
            this.$nextTick(() => this.loadingAddNew = false)
        },
        getOccupancyListRequest() {
            let occupancyList = []
            this.occupancyList_.forEach((occupancy) => {
                let relationValue = occupancy.discount
                if (relationValue !== null) {
                    relationValue = Number(parseFloat((typeof relationValue === "string" ? relationValue : relationValue.toString()))).toFixed(2)
                }
                if (isNaN(occupancy.id)) {
                    if (occupancy.deleted === 0) {
                        occupancyList.push({...occupancy, ...{discount: relationValue}})
                    }
                } else {
                    occupancyList.push({...occupancy, ...{discount: relationValue}})
                }
            })
            return occupancyList
        },
        save() {
            this.saving = true
            let request = {
                unit: this.unit.id,
                occupancy_list: this.getOccupancyListRequest(),
            }
            if (this.maximumGuests) {
                this.$set(request, "maximum_guests", this.maximumGuests)
            }
            updatePricingOccupancyList(request)
                .then(response => {
                        this.$emit("update", {occupancy_list: response.data.occupancy_list, changes: this.changes})
                        notifySuccess()
                    },
                    error => {
                        this.showErrorMessages(error)
                    }).finally(() => {
                this.saving = false
                this.confirmationDialogState = false
            })
        },
        getSuggestions() {
            this.loadingSuggestions = true
            let request = {
                unit: this.unit.id
            }
            if (this.maximumGuests) {
                this.$set(request, "maximum_guests", this.maximumGuests)
            }
            getPricingOccupancySuggestions(request).then((response) => {
                let occupancyList = response.data
                occupancyList.forEach((occupancy) => {
                    this.$set(occupancy, "deleted", 0)
                })
                if (this.occupancyList) {
                    this.occupancyList.forEach((initialOccupancy) => {
                        if (occupancyList.findIndex(occupancy => occupancy.id === initialOccupancy.id) < 0) {
                            let deletedOccupancy = _cloneDeep(initialOccupancy)
                            this.$set(deletedOccupancy, "deleted", 1)
                            occupancyList.push(deletedOccupancy)
                        }
                    })
                }
                this.occupancyList_ = _cloneDeep(occupancyList)
            }).finally(() => {
                this.loadingSuggestions = false
            })
        },
        resetOccupancyList() {
            this.resettingOccupancyList = true
            this.occupancyList_ = _cloneDeep(this.initialOccupancyList)
            this.$nextTick(() => {
                this.resettingOccupancyList = false
            })
        },
    },
    created() {
        this.access_control_general_context = {
            unit: this.unit.id,
            company: this.$store.getters['user/getCompany']
        }
    },
}
</script>

<style scoped>
.table-wrapper {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.table-header-label {
    width: 25%;
    min-width: 7.5rem;
}

.table-header-from {
    width: 10%;
    min-width: 4.25rem;
}

.table-header-to {
    width: 10%;
    min-width: 4.25rem;
}

.table-header-increase-value {
    width: 15%;
    min-width: 5.25rem;
}

.table-header-increase-type {
    width: 10%;
    min-width: 4.5rem;
}

.table-header-actions {
    width: 30%;
    min-width: 6.25rem;
}
</style>
