import {isNumber as _isNumber} from "lodash";
import {toast} from "@/shared/plugins/toastr";
import i18n from '@/locale/index'
import {isObject as _isObject} from 'lodash'
import {textFormatters} from "@/mixins/shared/helpers";

export const getErrorMessage = {
    mixins: [textFormatters],
    methods: {
        getMessages(error, validationErrorObject = null) {
            let msg = ""

            if (!error.response) {
                return "Server error"
            }
            if (!error.response.data) {
                return "Server error"
            }

            let err_list = error.response.data.error_list

            if (validationErrorObject && validationErrorObject === Object(validationErrorObject)) {
                err_list = filterMessages(err_list, validationErrorObject)
            }

            if (!err_list || err_list.length === 0) {
                return ''
            }

            for (let i = 0; i < err_list.length; i++) {
                if (err_list[i]['code'] && (_isNumber(err_list[i]['code']) || err_list[i]['code'].length)) {
                    msg += 'Code: ' + err_list[i]['code'] + '<br>'
                }
                if (err_list[i]['message'] && err_list[i]['message'].length) {
                    msg += this.sanitizeText(err_list[i]['message']);
                }
                if (err_list.length - 1 !== i) {
                    msg += '<hr>'
                }

            }
            return msg.trim()
        },
        /**
         *
         * @param error
         * @param validationErrorObject Validation error object containing codes which will be removed from the raw server response
         * @param type
         * @param timeout
         */
        showErrorMessages(error, validationErrorObject = null, type = 'error', timeout = 6000) {
            const message = this.getMessages(error, validationErrorObject)
            if (message) {
                toast({
                    title: i18n.t('NOTIFICATIONS.ERROR'),
                    message: message,
                    type,
                    timeout
                })
            }
        }
    }
}

function filterMessages(errorList, errorObject) {
    return errorList.filter(serverError => {
        let flag = true
        for (const [fieldName, value] of Object.entries(errorObject)) {
            if (Array.isArray(value)) {
                value.forEach(error => {
                    if (findError(error, serverError)) {
                        flag = false
                    }
                })
            } else if (_isNumber(value) || (_isObject(value) && value.hasOwnProperty('code'))) {
                if (findError(value, serverError)) {
                    flag = false
                }
            } else {
                for (const [type, errorList] of Object.entries(Object(value))) {
                    if (Array.isArray(errorList)) {
                        errorList.forEach(error => {
                            if (findError(error, serverError)) {
                                flag = false
                            }
                        })
                    }
                }
            }
        }
        return flag
    })
}

function findError(error, serverError) {
    if (_isNumber(error)) {
        if (Number(serverError.code) === Number(error)) {
            return true
        }
    } else if (error.hasOwnProperty('code')) {
        if (Number(serverError.code) === Number(error.code)) {
            return true
        }
    } else {
        return false
    }
}

