<template>
    <div :key="componentFormKey">
        <b-form @submit.prevent="createRatePlan">
            <b-row>
                <b-col sm="6" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.plan_name">
                        <template v-slot:input="data">
                            <label>{{$t('NAME')}}</label>
                            <b-form-input :state="data.state" type="text" v-model="object.plan_name"></b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col sm="6" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.rate_plan_type">
                        <template v-slot:input="data">
                            <app-select :state="data.state" :label="$t('TYPE')" v-model="object.rate_plan_type.id"
                                        :options="rate_plan_type_list"></app-select>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col sm="6" class="mb-4">
                    <app-select disabled :label="$t('LINKED_TO')" v-model="object.parent_rate_plan"
                                :options="ratePlanList"></app-select>

                </b-col>
                <b-col sm="6" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.mask_setup">
                        <template v-slot:input="data">
                            <label>{{$t('LINKED_VALUE')}}
                                <app-access-control  tag="span" :access_control_context="{function:C_UNIT_RATE_PLAN_MASK_SETUP,unit:unit_id,distribution:object.distribution,key:AC_RATE_PLAN_FORM}"
                                                     @AccessControlEvent="addToAccessControlCounter()">
                                <a class="cursor-pointer" @click="toggleSeasonalRateMask">({{$t('SEASONAL')}})</a>
                                </app-access-control>
                            </label>
                            <b-input-group>
                                <b-form-input :state="data.state" v-model="object.mask_setup.length > 1 || yearPeriod ? null : object.linked_value"
                                              :disabled="object.mask_setup.length > 0 || yearPeriod"></b-form-input>
                                <b-input-group-append>
                                    <app-select v-model="object.linked_type"
                                                :options="relationTypeOptions"></app-select>
                                </b-input-group-append>
                            </b-input-group>
                            <app-access-control  tag="span" :access_control_context="{function:C_UNIT_RATE_PLAN_MASK_SETUP,unit:unit_id,distribution:object.distribution,key:AC_RATE_PLAN_FORM}"
                                                 @AccessControlEvent="addToAccessControlCounter()">
                            <period-rate-mask-list :mask-setup="object.mask_setup"
                                                   v-if="seasonalRateMaskVisible === true"
                                                   @update="setMaskSetup">
                            </period-rate-mask-list>
                            </app-access-control>
                        </template>
                    </app-input-control>

                </b-col>
                <b-col sm="6" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.distribution">
                        <template v-slot:input="data">
                            <app-select :state="data.state" :label="$t('DISTRIBUTION')" v-model="object.distribution"
                                        :options="distribution_list"></app-select>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col sm="6" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.cancellation_policy">
                        <template v-slot:input="data">
                            <label>{{$t('CANCELLATION_POLICY')}}</label>
                            <app-select :state="data.state" v-model="object.cancellation_policy"
                                        :options="cancellationPolicyList" text-field="label"></app-select>
                        </template>
                    </app-input-control>
                </b-col>
                <app-access-control sm="6" class="mb-4"
                                    :access_control_context="{function:C_UNIT_RATE_PLAN_LOS_V,distribution:object.distribution,unit:unit_id,key:AC_RATE_PLAN_FORM}"
                                    @AccessControlEvent="addToAccessControlCounter()">
                    <app-input-control :error-object="validationErrorObject.los">
                        <template v-slot:input="data">
                            <app-select :state="data.state" :label="$t('LOS')" v-model="object.los" :options="losList" set-first :search-empty-item="false"></app-select>
                        </template>
                    </app-input-control>
                </app-access-control>
                <app-access-control sm="6" class="mb-4"   :access_control_context="{function:C_UNIT_RATE_PLAN_COMMUNICATION_TYPE,distribution:object.distribution,unit:unit_id,key:AC_RATE_PLAN_FORM}"
                                    @AccessControlEvent="addToAccessControlCounter()">
                    <template v-slot:default="props">
                    <label>
                        {{$t('OUTBOUND')}}
                    </label>
                    <app-input-control :error-object="validationErrorObject.cm_communication_type">
                        <template v-slot:input="data">

                            <app-select  :disabled="props.disabled"  :search-empty-item="false" :state="data.state" value-field="value" text-field="name" v-model="object.cm_communication_type" :options="nextpax_type_options">

                            </app-select>
                        </template>
                    </app-input-control>
                    </template>
                </app-access-control>
            </b-row>
            <b-row v-if="object.distribution === BOOKING">
                <b-col lg="6" md="6">
                    <b-form-checkbox v-model="object.virtual_card">{{$t('VIRTUAL_CARD')}}</b-form-checkbox>
                </b-col>

            </b-row>
<!--            <b-row>-->
<!--                <b-col class="mb-4">-->
<!--                    <app-translation-input-->
<!--                        :languages="supportedLanguages" v-model="parameter.complex_object.objects[0].content.value"-->
<!--                        :label="parameter.complex_object.objects[0].content.label "-->
<!--                        :component="'placeholder'"-->
<!--                        :placeholders="parameter.complex_object.objects[0].content.allowed_values"-->
<!--                        :lang_id="parameter.complex_object.objects[0].content.lang_id">-->
<!--                    </app-translation-input>-->
<!--                </b-col>-->
<!--            </b-row>-->
<!--            <b-row>-->
<!--                <b-col lg="6" md="6">-->
<!--                    <label>{{ parameter.complex_object.objects[0].payment_status.label }}</label>-->
<!--                    <app-select mode="multiselect" v-model="parameter.complex_object.objects[0].payment_status.value"-->
<!--                                :options="parameter.complex_object.objects[0].payment_status.allowed_values"-->
<!--                                text-field="label">-->
<!--                    </app-select>-->
<!--                </b-col>-->
<!--            </b-row>-->
            <form-header>
                {{$t('INHERIT_FROM_LINKED_RATE_PLAN')}}
            </form-header>
            <b-form-group>
                <b-checkbox  v-for="(restriction, index) in restriction_setup_list" v-if="restriction.id !== SALES_ENABLED" :key="index"
                             switch
                             v-model="object.inherit_restrictions"
                             :value="restriction.id"
                >{{restriction.label}}
                </b-checkbox>
            </b-form-group>
            <b-row class="mt-4" >
                <b-col sm="6">
                    <rate-plan-save :loading="saving"></rate-plan-save>
                </b-col>
            </b-row>
        </b-form>
    </div>

</template>

<script>
import PeriodRateMaskList from "@/components/pricing/rate_plan/period/PeriodRateMaskList";
import RatePlanSave from '@/components/app/AppButton/AppButtonSubmit'
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import ValidationDescription from '@/components/app/form/AppValidationDescription'
import {
    BOOKING,
    EXPEDIA,
    NEXTPAX, RATE_PLAN_TYPE_NON_REFUNDABLE, RATE_PLAN_TYPE_STANDARD, SETUP_PARAMETER_RESERVATION_NOTE_SETUP,
    SYSTEM_NON_REFUNDABLE_RATE,
    SYSTEM_STANDARD_RATE,
    TRIP_ADVISOR
} from "@/shared/constants";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {getPricingRatePlanList, getRatePlanType} from "@/services/pricing";
import AppInputControl from "@/components/app/AppInputControl";
import {
    ACCOMMODATION_PRICING_RATE_PLAN_NAME_VALIDATION_FAIL,
    ACCOMMODATION_PRICING_RATE_PLAN_DISTRIBUTION_VALIDATION_FAIL,
    ACCOMMODATION_PRICING_RATE_PLAN_TYPE_VALIDATION_FAIL,
    ACCOMMODATION_PRICING_PARENT_RATE_PLAN_VALIDATION_FAIL,
    ACCOMMODATION_PRICING_RATE_PLAN_CANCELLATION_POLICY_VALIDATION_FAIL,
    ACCOMMODATION_PRICING_RATE_PLAN_LINKED_VALUE_VALIDATION_FAIL,
    ACCOMMODATION_PRICING_RATE_PLAN_LINKED_TYPE_VALIDATION_FAIL,
    ACCOMMODATION_PRICING_RATE_PLAN_LINKED_VALUE_PERC_VALIDATION_FAIL,
    ACCOMMODATION_PRICING_RATE_PLAN_LOS_VALIDATION_FAILED,
    ACCOMMODATION_PRICING_RATE_PLAN_COMMUNICATION_TYPE,
    ACCOMMODATION_PRICING_RATE_PLAN_COMMUNICATION_TYPE_OUTBOUND_EXIST

} from "@/shared/error_codes";
import AppAccessControl from "@/components/app/AppAccessControl";
import {
    AC_FEATURE_SETUP,
    AC_RATE_PLAN_FORM,
    AC_RATE_PLAN_FORM_NEXTPAX
} from "@/mixins/AccessControl/AccessControlEnumeration";
import {
    C_UNIT_RATE_PLAN_MASK_SETUP,
    C_UNIT_RATE_PLAN_LOS_V,
    C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
    C_UNIT_RATE_PLAN_COMMUNICATION_TYPE
} from "@/shared/component_permission";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import FormHeader from "@/components/app/form/FormHeader";
import {MIN_STAY, MAX_STAY, RELEASE_PERIOD, CLOSED_ON_ARRIVAL, CLOSED_ON_DEPARTURE, SALES_ENABLED} from "@/shared/constants";
import {getRestrictionList} from "@/services/pricing/restriction";
import {getDistributionList} from "@/services/distribution";
import {getPropertyCancelationPolicies} from "@/services/property";
import {fetchAccessControlData} from "@/services/access";
import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
import {cloneDeep} from "lodash";
import {getReservationParameter, saveReservationParameter} from "@/services/accommodation_reservation";
import {getSystemLanguageList} from "@/services/system";
import moment from "moment";


export default {
    name: "new-rate-plan-form",
    mixins: [getErrorMessage, AccessControlComponent],
    props: {
        unit_id: {
            type: Number
        },
        property: {

            type: Object
        },

    },

    data() {
        return {
            EXPEDIA,
            TRIP_ADVISOR,
            AC_RATE_PLAN_FORM,
            AC_RATE_PLAN_FORM_NEXTPAX,
            C_UNIT_RATE_PLAN_LOS_V,
            object: {
                plan_name: null,
                distribution: null,
                parent_rate_plan: null,
                cancellation_policy: null,
                linked_type: null,
                linked_value: null,
                los: null,
                apply_min_stay: null,
                rate_plan_type: {
                    id: null,
                    name: null
                },
                unit: Number(this.$route.params.unit_id),
                inherit_restrictions:[],
                virtual_card:false,
                cm_communication_type:null,
                mask_setup: []
            },
            rate_plan_type_list: [
                /*{id: 1, name: 'Standard'},*/
            ],
            relationTypeOptions: [
                {id: 'perc', name: '%'},
                {id: 'fix', name: 'fix'}
            ],
            ratePlanList: [],
            saving: false,
            validationErrorObject: {
                plan_name: [ACCOMMODATION_PRICING_RATE_PLAN_NAME_VALIDATION_FAIL],
                rate_plan_type: [ACCOMMODATION_PRICING_RATE_PLAN_TYPE_VALIDATION_FAIL],
                parent: ACCOMMODATION_PRICING_PARENT_RATE_PLAN_VALIDATION_FAIL,
                distribution: ACCOMMODATION_PRICING_RATE_PLAN_DISTRIBUTION_VALIDATION_FAIL,
                cancellation_policy: ACCOMMODATION_PRICING_RATE_PLAN_CANCELLATION_POLICY_VALIDATION_FAIL,
                mask_setup: [ACCOMMODATION_PRICING_RATE_PLAN_LINKED_VALUE_PERC_VALIDATION_FAIL, ACCOMMODATION_PRICING_RATE_PLAN_LINKED_VALUE_VALIDATION_FAIL,ACCOMMODATION_PRICING_RATE_PLAN_LINKED_TYPE_VALIDATION_FAIL],
                los:[ACCOMMODATION_PRICING_RATE_PLAN_LOS_VALIDATION_FAILED],
                cm_communication_type:[ACCOMMODATION_PRICING_RATE_PLAN_COMMUNICATION_TYPE,ACCOMMODATION_PRICING_RATE_PLAN_COMMUNICATION_TYPE_OUTBOUND_EXIST]
            },
            access_control_fetch_key: AC_RATE_PLAN_FORM,
            access_control_components: 3,
            C_UNIT_RATE_PLAN_COMMUNICATION_TYPE,
            C_UNIT_RATE_PLAN_MASK_SETUP,
            seasonalRateMaskVisible: false,
            componentFormKey: 1,
            componentFormNameKey: 11,
            SALES_ENABLED,
            restriction_setup_list:[],
            distribution_list:[],
            cancellationPolicyList:[],
            BOOKING,
            nextpax_type_options:[
                {value:1, name:this.$t('YES')},
                {value:2, name:this.$t('NO')},
            ],
            NEXTPAX,
            cm_communication_type:null,
            subjectFocusLocation: null,
            parameter: null,
            supportedLanguages: [],
            ratePlanTypeList: [],
    }
    },

    components: {
        AppTranslationInput,
        FormHeader,
        AppInputControl,
        AppSelect,
        RatePlanSave,
        ValidationDescription,
        AppAccessControl,
        PeriodRateMaskList
    },
    computed: {
        firstDayCurrentYear() {
            return moment().startOf('year').format('MM/DD')
        },
        endDayCurrentYear() {
            return moment().endOf('year').format('MM/DD')
        },
        yearPeriod(){
            if (
                this.object.mask_setup.length === 1 &&
                (((this.object.mask_setup[0].period_start > this.firstDayCurrentYear) &&
                        (this.object.mask_setup[0].period_end < this.endDayCurrentYear)) ||
                    ((this.object.mask_setup[0].period_start === this.firstDayCurrentYear) &&
                        (this.object.mask_setup[0].period_end < this.endDayCurrentYear)) ||
                    ((this.object.mask_setup[0].period_start > this.firstDayCurrentYear) &&
                        (this.object.mask_setup[0].period_end === this.endDayCurrentYear)))
            ){
                return true
            }
            return false
        },
        losList() {
            let losList = []
            let list = this.$store.getters['pricing/getLosList']

            if (list.length > 0) {
                for (let id of list) {

                    let los = this.$store.getters['pricing/getLosById'](id)
                    let object = {
                        id: los.id,
                        name: los.min_stay + (!los.max_stay ? ' + ' : ' - ' + los.max_stay)
                    }
                    losList.push(object)
                }
            }

            return losList
        },

        company() {
            return this.$store.getters['user/getCompany']
        },

        parentRatePlan() {
            if (!this.object.parent_rate_plan) {
                return null
            }
            return this.$store.getters['pricing/getRatePlanById'](this.object.parent_rate_plan)
        },
    },
    methods: {
        toggleSeasonalRateMask() {
            this.seasonalRateMaskVisible = !this.seasonalRateMaskVisible
        },
        setMaskSetup(maskSetup) {
            this.$set(this.object, 'mask_setup', maskSetup)
        },

        searchDistribution() {
            getDistributionList({company: this.company, for_reservation:1, sorted:1}).then(res => {
                this.distribution_list = res.data
            })
        },

        prepareRequest() {
            let data = {
                plan_name: this.object.plan_name,
                distribution: this.object.distribution,
                parent_rate_plan: this.object.parent_rate_plan,
                cancellation_policy: this.object.cancellation_policy,
                linked_type: this.object.linked_type,
                linked_value: this.object.linked_value,
                los: this.object.los,
                apply_min_stay: this.object.apply_min_stay,
                rate_plan_type: this.object.rate_plan_type.id,
                unit: this.object.unit,
            }
            if(this.object.hasOwnProperty('virtual_card') && this.object.distribution === BOOKING){
                data.virtual_card = this.object.virtual_card
            }
            if(this.object.hasOwnProperty('cm_communication_type') && this.object.distribution === NEXTPAX){
                data.cm_communication_type = this.object.cm_communication_type
            }
            return {...data,...this.prepareRestrictionRequest(this.object),...{mask_setup: this.prepareMaskSetup(this.object)}}
        },
        prepareMaskSetup(requestObject){
            let maskSetup = []
            if (requestObject.hasOwnProperty('mask_setup') && Array.isArray(requestObject.mask_setup) && requestObject.mask_setup.length > 0) {
                requestObject.mask_setup.forEach(maskSetupPeriod => {
                    maskSetup.push({
                        period_start: maskSetupPeriod.period_start,
                        period_end: maskSetupPeriod.period_end,
                        value_type_id: this.object.linked_type === 'fix' ? 2 : 1,
                        value: maskSetupPeriod.value
                    })
                })
                return maskSetup
            }
        },
        prepareRestrictionRequest(requestObject){
            let restriction = {
                inherit_minimum_stay:false,
                inherit_maximum_stay:false,
                inherit_release_period:false,
                inherit_check_in_days:false,
                inherit_check_out_days:false
            }
            if(requestObject.hasOwnProperty('inherit_restrictions') && requestObject.inherit_restrictions.length > 0){
                requestObject.inherit_restrictions.forEach(restriction_id =>{
                    if(restriction_id === MIN_STAY){
                        restriction.inherit_minimum_stay = true
                    }
                    if(restriction_id === MAX_STAY){
                        restriction.inherit_maximum_stay = true
                    }
                    if(restriction_id === RELEASE_PERIOD){
                        restriction.inherit_release_period = true
                    }
                    if(restriction_id === CLOSED_ON_ARRIVAL){
                        restriction.inherit_check_in_days = true
                    }
                    if (restriction_id === CLOSED_ON_DEPARTURE){
                        restriction.inherit_check_out_days = true
                    }
                })
            }
            return restriction
        },
        createRatePlan() {

            let object = this.prepareRequest()
            this.saving = true
            this.$store.dispatch('pricing/actionCreatePricingRatePlan', {object})
                .then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                        this.$emit('ratePlanSaved')
                        // this.saveParameter()
                    }, error => {
                        this.showErrorMessages(error, this.validationErrorObject)
                    }
                ).finally(() => {
                this.saving = false
            })

        },

        // Commented out cause bugy and complex form - supporting this feature could probably cause more bugs

        // saveParameter(){
        //     let params = cloneDeep(this.parameter)
        //
        //     let paramsObject = params.complex_object
        //     if (paramsObject && paramsObject.type === 'custom') {
        //         let value = {}
        //         Object.entries(paramsObject.objects).map(([key, val]) => {
        //             if (key === 'content') {
        //                 value[key] = {}
        //                 value[key]['lang_id'] = val ? val.lang_id ? val.lang_id : null : null
        //                 value[key]['value'] = val ? val.value ? val.value : null : null
        //             } else {
        //                 value[key] = val ? val.value ? val.value : null : null
        //             }
        //         })
        //         params.value_text = JSON.stringify(value)
        //     }
        //     if (paramsObject && paramsObject.type === 'composite') {
        //         let array = []
        //         paramsObject.objects.forEach( param => {
        //             let value = {}
        //             Object.entries(param).map(([key, val]) => {
        //                 if (key === 'content') {
        //                     value[key] = {}
        //                     value[key]['lang_id'] = val ? val.lang_id ? val.lang_id : null : null
        //                     value[key]['value'] = val ? val.value ? val.value : null : null
        //                 } else {
        //                     value[key] = val ? val.value ? val.value : null : null
        //                 }
        //             })
        //             array.push(value)
        //         })
        //         params.value_text = JSON.stringify(array)
        //     }
        //     params.complex_object = null
        //     saveReservationParameter({item: params}).then(() => {
        //         notifySuccess()
        //         this.$emit('ratePlanSaved')
        //     }).catch(error => {
        //         this.showErrorMessages(error)
        //     }).finally(() => {
        //         this.saving = false
        //         }
        //     )
        // },
        getRateList() {
            getPricingRatePlanList({unit: this.unit_id, no_distribution:1, limit: 1000})
                .then(response => {
                    for (let item of response.data.items) {
                        this.ratePlanList.push(item)
                    }
                })
        },
        // getReservationsParameter() {
        //     let req = {
        //         rate_plan: this.id,
        //         context_company:this.context_company
        //     }
        //
        //     getReservationParameter(SETUP_PARAMETER_RESERVATION_NOTE_SETUP, req).then(response => {
        //         this.parameter = response.data
        //     })
        // },
        // insertPlaceholder(value) {
        //     if (this.subjectFocusLocation !== null) {
        //         if (this.object.value) {
        //             this.object.value = this.itemType.value.substring(0, this.subjectFocusLocation) + value + this.itemType.value.substring(this.subjectFocusLocation, this.itemType.value.length)
        //         } else {
        //             this.object.value = value
        //         }
        //         this.subjectFocusLocation += value.length
        //     } else {
        //         if (this.object.value) {
        //             this.object.value += value
        //         } else {
        //             this.object.value = value
        //         }
        //     }
        // },
        // getCurrentPosition(e) {
        //     this.subjectFocusLocation = e.target.selectionStart
        // },
        // getSupportedLanguages() {
        //     getSystemLanguageList().then(response => {
        //         response.data.forEach(el => {
        //             this.supportedLanguages.push(el.name)
        //         })
        //     })
        // },
        forceReRender() {
            this.componentFormKey += 1
            this.componentFormNameKey += 1
        },
    },
    watch: {
        'object.distribution': {
            handler(value) {
                if(value === NEXTPAX){
                    let request = {
                        data: []
                    }
                    request.data.push({
                        uid: C_UNIT_RATE_PLAN_COMMUNICATION_TYPE,
                        function: C_UNIT_RATE_PLAN_COMMUNICATION_TYPE,
                        distribution:value,
                        unit:this.unit_id,
                    })
                    fetchAccessControlData(AC_RATE_PLAN_FORM_NEXTPAX, request).then(response => {
                        const responseObject = response.data[C_UNIT_RATE_PLAN_COMMUNICATION_TYPE]
                        this.object.cm_communication_type = responseObject && responseObject.hasOwnProperty('value') ? responseObject.value : null
                    })
                }

                let maskRequest = {
                    data: []
                }
                maskRequest.data.push({
                    uid: C_UNIT_RATE_PLAN_MASK_SETUP,
                    function: C_UNIT_RATE_PLAN_MASK_SETUP,
                    distribution: value,
                    unit: this.unit_id,
                })
                fetchAccessControlData(AC_RATE_PLAN_FORM, maskRequest).then(response => {
                    if (response.data[C_UNIT_RATE_PLAN_MASK_SETUP].disabled === true) {
                        if(this.object.linked_value === null) {
                            this.object.mask_setup = []
                        }
                    }
                })

                this.object.distribution = value
                this.forceReRender()
            }
        },
        'object.mask_setup':{
            handler(value){
                if(this.object.mask_setup.length > 0){
                    this.object.linked_value = null
                }
            }
        },
        '$route.params.unit_id': {
            handler(unit_id) {
                this.$store.dispatch('pricing/actionSetLosList', {unit: unit_id})
            },
            immediate: true
        },
        ratePlanList:{
            handler(list){
                if(list.length > 0){
                    let nrfRate = list.find(el => el.rate_plan_category === SYSTEM_NON_REFUNDABLE_RATE)
                    let standardRate = list.find(el => el.rate_plan_category === SYSTEM_STANDARD_RATE)
                    const nonRefundableTypeIncludedInList = this.rate_plan_type_list.find(el => el.id === RATE_PLAN_TYPE_NON_REFUNDABLE)
                    if(!nrfRate){
                        this.object.rate_plan_type.id = RATE_PLAN_TYPE_STANDARD
                        this.object.parent_rate_plan = standardRate.id
                        if (nonRefundableTypeIncludedInList) {
                            const index = this.rate_plan_type_list.findIndex(ratePlanType => {
                                return ratePlanType.id === RATE_PLAN_TYPE_NON_REFUNDABLE
                            })
                            this.rate_plan_type_list.splice(index, 1)
                        }
                    } else {
                        if(!nonRefundableTypeIncludedInList){
                            const nonRefundableRatePlanTypeIndex = this.ratePlanTypeList.findIndex(ratePlanType => {
                                return ratePlanType.id === RATE_PLAN_TYPE_NON_REFUNDABLE
                            })
                            if (nonRefundableRatePlanTypeIndex > -1) {
                                this.rate_plan_type_list.push({
                                    id: this.ratePlanTypeList[nonRefundableRatePlanTypeIndex].id,
                                    name: this.ratePlanTypeList[nonRefundableRatePlanTypeIndex].label,
                                })
                            }
                        }
                    }
                }
            }, immediate:true
        },
        'object.rate_plan_type.id':{
            handler(value){
                if(value){
                    let parenRatePlan =  this.ratePlanList.find(el => el.rate_plan_category === value)
                    if(parenRatePlan){
                        this.object.parent_rate_plan = parenRatePlan.id
                    } else {
                        this.object.parent_rate_plan = null
                    }
                } else {
                    this.object.parent_rate_plan = null
                }
            }
        }
    },

    created() {
        this.getRateList()
        this.object.inherit_restrictions.push(MIN_STAY,MAX_STAY, RELEASE_PERIOD, CLOSED_ON_ARRIVAL,CLOSED_ON_DEPARTURE)
        this.object.linked_type = 'perc'
        this.searchDistribution()
        getRestrictionList()
            .then(response => {
                this.restriction_setup_list = response.data
            })
        getPropertyCancelationPolicies(this.property.id).then(response =>{
            this.cancellationPolicyList = response.data
        })
        // this.getReservationsParameter()
        // this.getSupportedLanguages()

        getRatePlanType().then((response) => {
            this.ratePlanTypeList = response.data
            const standardRatePlanTypeIndex = this.ratePlanTypeList.findIndex(ratePlanType => {
                return ratePlanType.id === RATE_PLAN_TYPE_STANDARD
            })
            if (standardRatePlanTypeIndex > -1) {
                this.rate_plan_type_list.push({
                    id: this.ratePlanTypeList[standardRatePlanTypeIndex].id,
                    name: this.ratePlanTypeList[standardRatePlanTypeIndex].label,
                })
            }
        })
    }

}
</script>

<style scoped>

</style>
