<template>
    <div>
        <app-table-v2 search-event="reloadTags" :provider="getData" :fields="computedFields"
                      :show-limit="true"
                      selectable
                      :search-on-create="true">

            <template v-slot:image.url="data">
                <template v-if="data.item.image && data.item.image.url">
                    <b-img height="40px" :src="data.item.image.url"></b-img>
                </template>
            </template>
            <template v-slot:children="data">
                <b-link
                    :to="navigateToList(data.item)">
                    {{ data.item.children }}
                </b-link>
            </template>

            <template v-slot:label="data">
                <template>
                    <span class="mr-3">{{ data.item.label }}</span>
                    <template v-if="data.item.languages">
                        <template v-for="(translation, locale) in data.item.languages">
                            <b-badge
                                variant="primary" pill
                                :id="`${data.item.id}-${locale}`"
                                class="mr-1"
                            >
                                {{ locale.toUpperCase() }}
                            </b-badge>
                            <b-tooltip :target="`${data.item.id}-${locale}`" placement="top" triggers="hover">
                                {{ translation }}
                            </b-tooltip>
                        </template>
                    </template>
                </template>
            </template>

            <template v-slot:edit="data">
                <app-button v-if="isNavOn"
                            variant="link"
                            button_type="edit"
                            :show_text="false"
                            :to="navigateTo(data.item)"
                ></app-button>
                <app-button
                    v-if="edit_tag_sidebar && checkPermission(C_SETUP_TAG_E)"
                    variant="link"
                    button_type="edit"
                    :show_text="false"
                    @click="openTagSidebar(data)"
                ></app-button>
            </template>

            <template v-slot:selectedContainer="data">
                <app-button @click="destinationTagModal(data.selected)" class="destination_tag">{{ $t('TAG') }}
                </app-button>
            </template>

            <template v-slot:tag="data">
                <template v-if="data.item.tagged">
                    <template v-for="item in data.item.tagged">
                        <b-badge
                            variant="primary" pill
                            class="mr-1"
                            style="cursor: pointer">
                            {{ item.label }}
                            <i @click="showDeleteDialog(item,data)" :disabled="deleteItem"
                               class="fa fa-times fa-fw ml-1"
                               aria-hidden="true"></i>
                        </b-badge>
                    </template>
                </template>
            </template>

            <template v-slot:tableNoData="props">
                <app-no-data :show-tip="false" :heading="$t('NO_SEARCH_RESULTS')">
                    <app-button v-if="checkPermission(C_WEBSITE_TAG_NEW_V)" button_type="new" @click="$emit('openNew')"
                                class="mr-3"> {{ buttonLabel }}
                    </app-button>
                    <app-button
                        :access_control_context="{function:C_WEBSITE_TAG_APPLY_DESTINATIONS,key:AC_WEBSITE,company:company.id}"
                        @AccessControlEvent="addToAccessControlCounter()"
                        v-if="destination_type === TAG_DESTINATION_TYPE_COUNTRY" :loading="mapping" button_type="new"
                        @click="showConfirmationDialog=true"> {{ $t('APPLY_DESTINATIONS') }}
                    </app-button>
                </app-no-data>
            </template>
        </app-table-v2>

        <app-aside v-model="tagMappingSidebar" :widths="['col-lg-3', 'col-sm-12', '40%']">
            <template slot="header">{{ asideLabel }}</template>
            <template v-slot="props">
                <app-tag-entity-form :selected-items="selectedTags"
                                     :tag-types="getTagTypes"
                                     :destinationTypes="getDestinationTypes"
                                     :object-type="OBJECT_TYPE_TAG"
                                     :parent-tag="parent_tag"
                                     :selected-check-box="tagged"
                                     @saved="saveTagMapping"
                                     @destinationSaved="tagMappingSidebar=false"
                ></app-tag-entity-form>
            </template>
        </app-aside>

        <app-confirmation-dialog :show="showDeleteConfirmDialog"
                                 :delete_title="true"
                                 @confirm="deleteTagMapping()"
                                 @cancel="showDeleteConfirmDialog = false">
        </app-confirmation-dialog>

        <app-confirmation-dialog :show="showConfirmationDialog"
                                 :title="$t('APPLY_DESTINATIONS')"
                                 @confirm="copyMapData()"
                                 @cancel="showConfirmationDialog = false">
            {{ $t('APPLY_FROM_SYSTEM_CONFIRMATION_TITLE') }}
        </app-confirmation-dialog>
    </div>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import routeNames from "@/router/routeNames";
import {createAndMapDestinationTags, deleteEntityTags, getTagsPaginated} from "@/services/tag";
import {
    TAG_DESTINATION_TYPE_COUNTRY,
    TAG_DESTINATION_TYPE_REGION,
    TAG_DESTINATION_TYPE_CITY,
    TAG_TYPE_DESTINATION,
    TAG_TYPE_CUSTOM,
    OBJECT_TYPE_TAG
} from "@/shared/constants";
import AppAside from "@/components/app/form/AppAside";
import AppTableV2 from "@/components/app/AppTableV2";
import AppNoData from "@/components/app/AppNoData";
import {EventBus} from "@/shared/EventBus";
import {notifySuccess} from "@/shared/plugins/toastr";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {C_WEBSITE_TAG_NEW_V, C_WEBSITE_TAG_APPLY_DESTINATIONS, C_SETUP_TAG_E} from "@/shared/component_permission";
import {AC_WEBSITE} from "@/mixins/AccessControl/AccessControlEnumeration";
import AppAccessControl from "@/components/app/AppAccessControl";
import {TableRule} from "@/mixins/Table/TableRule";
import AppTagEntityForm from "@/components/app/tag/AppTagEntityForm";

export default {
    name: "TagList",
    mixins: [AccessControlComponent, TableRule],
    components: {AppTagEntityForm, AppAccessControl, AppConfirmationDialog, AppTableV2, AppButton, AppNoData, AppAside},
    props: {
        destination_type: {
            type: String,
            default: null,
        },
        tag_type: {
            type: Number,
            default: null
        },
        not_tag_type: {
            type: Array,
            default: () => []
        },
        refresh: {
            type: Boolean,
            default: null
        },
        parent_tag: {
            type: Number,
            default: null
        },
        edit_tag_sidebar: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            TAG_TYPE_CUSTOM, TAG_DESTINATION_TYPE_CITY,
            TAG_DESTINATION_TYPE_COUNTRY, OBJECT_TYPE_TAG,
            C_WEBSITE_TAG_APPLY_DESTINATIONS, AC_WEBSITE,
            C_WEBSITE_TAG_NEW_V, C_SETUP_TAG_E,
            TAG_TYPE_DESTINATION,
            showConfirmationDialog: false,
            mapping: false,
            filter: {},
            routeNames,
            parentTag: null,
            fields: [
                {key: 'id', label: this.$t('ID'),},
                {key: 'label', label: this.$t('NAME')},
                {key: 'tag_type_label', label: this.$t('TYPE')},
                {key: 'image.url', label: this.$t('IMAGE')},
                {
                    key: 'children',
                    label: this.destination_type === TAG_DESTINATION_TYPE_REGION ? this.$t('CITIES') : this.$t('REGIONS')
                },
                {key: 'tag', label: this.$t('TAG')},
                {key: 'edit', label: '', class: 'text-right'}
            ],
            exception_fields: [
                {key: 'image.url', label: this.$t('IMAGE')},
                {key: 'tag', label: this.$t('TAG')},
                {key: 'edit', label: '', tdClass: 'text-right'},
            ],
            omittedFields: [],
            access_control_fetch_key: AC_WEBSITE,
            access_control_components: 1,
            list: [],
            tagMappingSidebar: false,
            selectedTags: [],
            deleteItem: false,
            showDeleteConfirmDialog: false,
            tagId: null,
            entityId: null,
            tagged: null
        }
    },
    methods: {
        getData(filter) {
            let parent_tag = {}

            if (this.parent_tag) {
                parent_tag = {parent_tag: this.parent_tag}
            }

            let req = {
                page: filter.currentPage,
                perPage: filter.perPage,
                destination_type: this.destination_type,
                company: this.company.id,
                tag_type: this.tag_type,
                not_tag_type: this.not_tag_type,
                ...parent_tag
            }

            let provider = getTagsPaginated(req)
            provider.then(response => {
                if (response.data.items && response.data.items.length > 0) {
                    this.$emit('showButton')
                    this.$store.commit('tag/setRegionButton', true)
                }
                this.list = response.data.items
            })
            return provider
        },
        navigateTo(item) {
            if (this.destination_type === TAG_DESTINATION_TYPE_COUNTRY) {
                return {
                    name: routeNames.RN_SETUP_DESTINATION_TAG_COUNTRY_ID,
                    params: {id: item.id},
                    query: {label: item.label}
                }
            }
            if (this.destination_type === TAG_DESTINATION_TYPE_REGION) {
                return {
                    name: routeNames.RN_SETUP_DESTINATION_TAG_GENERAL_REGION,
                    params: {id: this.$route.params.id, region_id: item.id}
                }
            }
        },
        navigateToList(item) {
            if (this.destination_type === TAG_DESTINATION_TYPE_COUNTRY) {
                return {
                    name: routeNames.RN_SETUP_DESTINATION_TAG_REGION,
                    params: {id: item.id},
                    query: {label: item.label}
                }
            }
            if (this.destination_type === TAG_DESTINATION_TYPE_REGION) {
                return {
                    name: routeNames.RN_SETUP_DESTINATION_TAG_CITY,
                    params: {id: this.$route.params.id, region_id: item.id}
                }
            }
        },
        openTagSidebar(data) {
            this.$emit('openTagSidebar', data)
        },
        copyMapData() {
            this.mapping = true
            createAndMapDestinationTags({company: this.company.id}).then(() => {
                notifySuccess()
                EventBus.$emit('reloadTags')
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.mapping = false
                this.showConfirmationDialog = false
            })
        },
        destinationTagModal(selected) {
            this.tagMappingSidebar = true

            let mapTagged = {}
            this.getTagTypes.map(el => mapTagged[el] = [])

            if (selected.length === 1) {
                selected.map(el => {
                    el.tagged.map(el1 => {
                        if (!mapTagged[el1.tag_type].includes(el1.id))
                            mapTagged[el1.tag_type].push(el1.id)
                    })
                });
            }

            this.tagged = mapTagged

            this.selectedTags = selected.map(el => el.id);
        },
        showDeleteDialog(item, data) {
            this.tagId = item.id
            this.entityId = data.item.id
            this.showDeleteConfirmDialog = true
        },
        deleteTagMapping() {
            deleteEntityTags(OBJECT_TYPE_TAG, this.entityId, [this.tagId]).then((response) => {
                notifySuccess()
                EventBus.$emit('reloadTags')
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.showDeleteConfirmDialog = false
            })
        },
        saveTagMapping() {
            this.tagMappingSidebar = false
            EventBus.$emit('reloadTags')
        },
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        isNavOn() {
            if(this.edit_tag_sidebar){
                return false
            }
            if (this.destination_type === TAG_DESTINATION_TYPE_CITY) {
                return false
            }
            if (this.tag_type === TAG_TYPE_CUSTOM) {
                return false
            }

            return true
        },
        computedFields() {
            return this.getFields(this.list,
                {
                    fields: this.fields.filter(el => !this.omittedFields.includes(el.key)),
                    exception_fields: this.exception_fields
                })
        },
        buttonLabel() {
            if (this.destination_type === TAG_DESTINATION_TYPE_CITY) {
                return this.$t('NEW_CITY')
            }
            if (this.destination_type === TAG_DESTINATION_TYPE_REGION) {
                return this.$t('NEW_REGION')
            }
            if (this.destination_type === TAG_DESTINATION_TYPE_COUNTRY) {
                return this.$t('NEW_COUNTRY')
            }
            if (this.tag_type === TAG_TYPE_CUSTOM) {
                return this.$t('NEW_TAG')
            }

            return this.$t('NEW_TAG')
        },
        asideLabel() {
            if (this.destination_type === TAG_DESTINATION_TYPE_CITY) {
                return this.$t('TAG_CITIES')
            }
            if (this.destination_type === TAG_DESTINATION_TYPE_REGION) {
                return this.$t('TAG_REGIONS')
            }
            if (this.destination_type === TAG_DESTINATION_TYPE_COUNTRY) {
                return this.$t('TAG_COUNTRIES')
            } else {
                return this.$t('TAG')
            }
        },
        getTagTypes() {
            if (this.destination_type === TAG_DESTINATION_TYPE_REGION) {
                return [TAG_TYPE_CUSTOM, TAG_TYPE_DESTINATION]
            }

            return [TAG_TYPE_CUSTOM]
        },
        getDestinationTypes() {
            if (this.destination_type === TAG_DESTINATION_TYPE_REGION) {
                return TAG_DESTINATION_TYPE_REGION
            }
            return null
        }
    },
    created() {
        if (this.destination_type === TAG_DESTINATION_TYPE_CITY) {
            this.omittedFields.push('children')
        }
        if (this.tag_type === TAG_TYPE_DESTINATION) {
            this.omittedFields.push('tag_type_label')
        }
        if (this.tag_type !== TAG_TYPE_DESTINATION) {
            this.omittedFields.push('image.url')
            this.omittedFields.push('children')
            this.omittedFields.push('tag')
        }
        this.access_control_general_context = {
            company: this.company.id
        }
    },

    watch: {
        refresh: {
            handler() {
                EventBus.$emit('reloadTags')
            }
        }
    },
    beforeDestroy() {
        this.$store.commit('tag/setRegionButton', false)
    }
}
</script>

<style scoped>

</style>
