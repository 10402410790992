<template>
    <div>
        <b-form @submit.prevent autocomplete="off" v-if="checkPermission(C_COMPANY_ADDITIONAL_SETUP_E)">
            <form-wizard ref="wizard" :startIndex="currentIndex" transition="fade-in" color=var(--color-primary) errorColor=var(--color-warning)
                         name="additionalSetupWizard" title="" subtitle=""
                         @on-validate="handleNextStep"
                         @on-complete="save">
                <tab-content name="basic" :title="$t('BASIC')">
                    <b-row class="d-flex justify-content-center">
                        <b-col md="6" lg="3" class="mb-4">

                            <label>{{$t('COUNTRY')}}</label><i class=" fa fa-question-circle-o ml-1 pull-right tooltip-help mr-3 mt-1 text-center" v-b-tooltip.hover.top="$t('COUNTRY_TOOLTIP_MSG')"></i>

                            <app-input-control :error-object="validationErrorObject.basic.country">
                                <template v-slot:input="data">
                                    <app-select  mode="search" :search="searchCountry"
                                                 :state="data.state"
                                                 v-model="additional_setup.country" :search-clear="true"
                                                 :search-empty-item="false">
                                    </app-select>
                                </template>
                            </app-input-control>
                        </b-col>
                        <b-col md="6" lg="3" class="mb-4">
                            <b-tooltip  class="tooltiptext" :target="'main_currency_'+company_id"
                                       placement="top"
                                        triggers="hover"
                                        >
                                {{$t('MAIN_CURRENCY_TOOLTIP_MSG')}}
                            </b-tooltip>
                            <label>{{$t('MAIN_CURRENCY')}}</label><i class=" fa fa-question-circle-o ml-1 pull-right tooltip-help  mr-3 mt-1" :id="'main_currency_'+company_id"></i>

                            <app-select  mode="select" :options="currencyList"
                                        value-field="id" text-field="name"
                                        :search-empty-item="false" disabled
                                        v-model="additional_setup.main_currency">
                            </app-select>

                        </b-col>
                        <b-col md="6" lg="3" class="mb-4">
                            <b-tooltip  class="tooltiptext" :target="'working_currency'+company_id"
                                        placement="top"
                                        triggers="hover"
                            >
                                {{$t('WORKING_CURRENCY_TOOLTIP_MSG')}}
                            </b-tooltip>
                            <app-input-control :error-object="validationErrorObject.basic.working_currency">
                                <template v-slot:input="data">
                            <label>{{$t('WORKING_CURRENCY')}}</label><i class=" fa fa-question-circle-o ml-1 pull-right tooltip-help  mr-3 mt-1" :id="'working_currency'+company_id"></i>
                            <app-select :state="data.state" mode="select" :options="currencyList"
                                        value-field="id" text-field="name"
                                        :search-empty-item="false"
                                        v-model="additional_setup.working_currency">
                            </app-select>
                                </template>
                            </app-input-control>
                        </b-col>
                        <b-col md="6" lg="3" class="mb-4" v-if="additional_setup.main_currency && additional_setup.working_currency && additional_setup.main_currency !=additional_setup.working_currency">

                            <b-tooltip  class="tooltiptext" :target="'fixed_exchange_rate'+company_id"
                                        placement="top"
                                        triggers="hover"
                            >
                                {{$t('FIXED_EXCHANGE_RATE_MSG')}}
                            </b-tooltip>
                            <app-input-control :error-object="validationErrorObject.basic.exchange_rate">
                                <template v-slot:input="data">
                                    <label>{{$t('FIXED_EXCHANGE_RATE')}} ({{getCurrencyNameById(additional_setup.working_currency)}} -> {{getCurrencyNameById(additional_setup.main_currency)}}) </label><i :id="'fixed_exchange_rate'+company_id" class=" fa fa-question-circle-o ml-1 pull-right tooltip-help  mr-3 mt-1" ></i>
                                    <app-number-input v-model="additional_setup.exchange_rate"
                                                      :min="0" allow-decimal
                                                      :decimals="9" align="right" :nullable="false"
                                                      :state="data.state">
                                    </app-number-input>
                                </template>
                            </app-input-control>
                        </b-col>

                    </b-row>
                </tab-content>

                <button class="btn btn-outline-primary" slot="prev">{{$t('PREVIOUS')}}</button>
                <button class="btn btn-primary" slot="next">{{$t('NEXT')}}</button>
                <button class="btn btn-primary" slot="finish" :loading="loading">{{$t('FINISH')}}</button>
            </form-wizard>

        </b-form>
        <b-row v-else>
            <additional-setup-contact-admin :contact="contact"
                                         @logout="logout">
            </additional-setup-contact-admin>
        </b-row>
    </div>
</template>

<script>
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import AppInputControl from "@/components/app/AppInputControl";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {getCurrencyList} from "@/services/finance";
    import {createAdditionalSetupData, fetchAdditionalSetupData} from "@/services/company";
    import {getCityMapping, getCountries} from "@/services/location";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {AC_ADDITIONAL_SETUP} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {
        C_COMPANY_ADDITIONAL_SETUP_SET_CITY,
        C_COMPANY_ADDITIONAL_SETUP_SET_COUNTRY,
        C_COMPANY_ADDITIONAL_SETUP_SET_CURRENCY,
        C_COMPANY_ADDITIONAL_SETUP_SET_FIXED_EXCHANGE_RATE,
        C_COMPANY_ADDITIONAL_SETUP_E
    } from "@/shared/component_permission";
    import {
        COM_CITY_ID_REQUIRED,
        COM_COUNTRY_ID_REQUIRED, COM_EMPTY_WORKING_CURRENCY,
        COM_EXCHANGE_RATE_MUST_BE_GREATER_THAN_ZERO,
        COM_EXCHANGE_RATE_MUST_BE_NUMERIC,
        COM_EXCHANGE_RATE_REQUIRED,
        COM_MAIN_CURRENCY_ID_REQUIRED
    } from "@/shared/error_codes";
    import {EventBus, GE_ADDITIONAL_SETUP_WIZARD_FINISHED} from "@/shared/EventBus";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {toast} from "@/shared/plugins/toastr";
    import routeNames from "@/router/routeNames";
    import {LoginPageEn, LoginPageHr} from "@/shared/constants";
    import AdditionalSetupContactAdmin from "@/components/company/setup/AdditionalSetupContactAdmin";
    import {cloneDeep} from "lodash";

    export default {
        name: "AdditionalSetupWizard",
        components: {
            AdditionalSetupContactAdmin,
            AppAccessControl,
            FormWizard,
            TabContent,
            AppInputControl,
            AppSelect,
            AppNumberInput,
            AppButton
        },
        mixins: [
            AccessControlComponent,
            getErrorMessage
        ],
        data() {
            return {
                routeNames,
                currentIndex: 0,
                currencyList: [],
                working_currency: {
                    id: null,
                    code: null
                },
                additional_setup: {
                    city: {
                        id: null,
                        name: null
                    },
                    country: {
                        id: null,
                        name: null,
                    },
                    main_currency: null,
                    working_currency: null,
                    exchange_rate: 1
                },
                initialSetupData: {},
                contact: null,
                validationErrorObject: {
                    basic: {
                        city: {
                            input: [COM_CITY_ID_REQUIRED]
                        },
                        country: {
                            input: [COM_COUNTRY_ID_REQUIRED]
                        },

                        working_currency: {
                            input:[COM_EMPTY_WORKING_CURRENCY]
                        },
                        exchange_rate: {
                            input: [
                                COM_EXCHANGE_RATE_REQUIRED,
                                COM_EXCHANGE_RATE_MUST_BE_NUMERIC,
                                COM_EXCHANGE_RATE_MUST_BE_GREATER_THAN_ZERO
                            ]
                        }
                    }
                },
                C_COMPANY_ADDITIONAL_SETUP_SET_CITY,
                C_COMPANY_ADDITIONAL_SETUP_SET_COUNTRY,
                C_COMPANY_ADDITIONAL_SETUP_SET_CURRENCY,
                C_COMPANY_ADDITIONAL_SETUP_SET_FIXED_EXCHANGE_RATE,
                C_COMPANY_ADDITIONAL_SETUP_E,
                AC_ADDITIONAL_SETUP,
                access_control_components: 2,
                access_control_fetch_key: AC_ADDITIONAL_SETUP,
                loading:false
            }
        },
        computed:{
            company_id(){
                return this.$store.getters['user/getCompany']
            }
        },
        watch: {

            'additional_setup.country': {
                handler(value) {
                    if (value && value.hasOwnProperty('id') && value.id && value.hasOwnProperty('currency')) {
                        this.additional_setup.main_currency = value.currency

                    } else if (value.hasOwnProperty('id') && !value.id) {
                        this.additional_setup.country.id = null
                        this.additional_setup.country.name = null
                        this.additional_setup.main_currency = null
                    }
                },
                immediate: true
            }
        },
        methods: {
            getCurrencyNameById(id){
                const currencyObject = this.currencyList.find(el=>el.id === id)
              return  currencyObject ? currencyObject.name : ''
            },
            searchCountry(value) {
                return getCountries({
                    name: value
                })
            },
            searchCity(value) {
                return getCityMapping({
                    name: value
                })
            },
            getRequestObject() {
                if (this.additional_setup.exchange_rate) {
                    return {
                        country_id: this.additional_setup.country.id,
                        main_currency_id: this.additional_setup.main_currency,
                        exchange_rate: this.additional_setup.exchange_rate,
                        working_currency_id: this.additional_setup.working_currency,
                        company:this.company_id
                    }
                }
                return {
                    country_id: this.additional_setup.country.id,
                    main_currency_id: this.additional_setup.main_currency,
                    working_currency_id: this.additional_setup.working_currency
                }
            },
            save() {
                this.loading = true
                let requestObject = this.getRequestObject();
                createAdditionalSetupData(requestObject).then(response => {
                    this.$store.dispatch('user/actionUpdateUser', response.data)
                    this.$store.dispatch('user/actionSetCurrentCompany', response.data.company)
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    EventBus.$emit(GE_ADDITIONAL_SETUP_WIZARD_FINISHED)
                }, error => {
                    this.showErrorMessages(error, this.validationErrorObject.basic)
                }).finally(()=>{
                    this.$router.replace({name: routeNames.RN_PROPERTY_LIST})
                    this.loading = false
                })
            },
            logout() {
                this.$store.dispatch('auth/actionLogout').then(() => {
                    if (process.env.NODE_ENV === 'production') {
                        if (this.$i18n.locale == 'hr') {
                            window.location.href = LoginPageHr
                        } else {
                            window.location.href = LoginPageEn
                        }
                    } else {
                        this.$router.replace({name: routeNames.RN_LOGIN})
                    }
                })
            },
            handleNextStep() {

            }
        },
        created() {
            getCurrencyList().then(response => {
                this.currencyList = response.data
            })
            fetchAdditionalSetupData().then(response => {
                if (response.data.city.id) {
                    this.additional_setup.city = response.data.city
                    this.additional_setup.country = response.data.city.country
                }
                if (response.data.country.id) {
                    this.additional_setup.country = response.data.country
                }
                if (response.data.main_currency.id) {
                    this.additional_setup.main_currency = response.data.main_currency.id !== null  ? response.data.main_currency.id : response.data.country.currency
                }
                if (response.data.working_currency.id) {
                    this.additional_setup.working_currency = response.data.working_currency.id
                }
                this.contact = response.data.contact

                this.initialSetupData = cloneDeep(this.additional_setup)
            })
        }
    }
</script>

<style scoped>

</style>
