<template>
    <div v-if="reservationObject" class="inbox_res">
<!--        <div class="header b-b-1 mb-1 pb-3">
            <h6 v-if="_has(reservationObject, 'status.name')">{{reservationObject.status.name}}</h6>
            <h5 class="mb-1" v-if="_has(reservationObject, 'guest.first_name')">{{reservationObject.guest.first_name}}
                {{reservationObject.guest.last_name}}
            </h5>
            <ul>
                <li>
                    {{reservationObject.num_guests}} {{ reservationObject.num_guests > 1 ? $t('GUESTS').toLowerCase() :
                    $t('GUEST').toLowerCase() }}
                </li>
                <li>
                    {{reservationObject.num_nights}} {{ reservationObject.num_nights > 1 ? $t('NIGHTS').toLowerCase() :
                    $t('NIGHT').toLowerCase()}}
                </li>
            </ul>
            <div v-if="_has(reservationObject, 'unit.name')">
                {{reservationObject.unit.name}}
            </div>
        </div>-->

        <div class="read-only">
            <div class="items">
                <div v-if="_has(reservationObject, 'guest.phone')" class="item">
                    <div class="item-label">{{$t("PHONE")}}</div>
                    <div class="item-content text-right">{{reservationObject.guest.phone}}</div>
                </div>

    <!--            <div class="item">
                    <div class="item-label">{{$t("DISTRIBUTION")}}</div>
                    <div class="item-content text-right" v-if="_has(reservationObject, 'distribution.name')">
                        {{reservationObject.distribution.name}}
                    </div>
                </div>-->
                <div class="item">
                    <div class="item-label">{{$t("GUESTS")}}</div>
                    <div class="item-content text-right">
                        <div v-if="reservationObject.adult_no">{{$t("ADULTS")}}: {{reservationObject.adult_no}}</div>
                        <div v-if="reservationObject.child_no" class="ml-2">{{$t("CHILDREN")}}:
                            {{reservationObject.child_no}}
                        </div>
                        <div v-if="reservationObject.baby_no" class="ml-2">{{$t("BABIES")}}: {{reservationObject.baby_no}}
                        </div>
                    </div>
                </div>
<!--                <div class="item">
                    <div class="item-label">{{$t("CHECKIN")}}</div>

                    <div>
                        <small v-if="_get(reservationObject, 'check_in_time')" class="d-flex text-muted">
                            {{reservationObject.check_in_time|time}}
                        </small>
                        <div>{{reservationObject.check_in_date|date}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="item-label">{{$t("CHECKOUT")}}</div>
                    <div>
                        <small v-if="_get(reservationObject, 'check_out_time')" class="d-flex text-muted">
                            {{reservationObject.check_out_time|time}}
                        </small>
                        <div>{{reservationObject.check_out_date|date}}</div>
                    </div>
                </div>-->
                <div class="item">
                    <div class="item-label">{{$t("RESERVATION_DATE")}}</div>
                    <div class="item-content text-right">
                        <small class="d-flex justify-content-end text-muted ">{{reservationObject.ts_insert|time}}</small>
                        <div>{{reservationObject.ts_insert|date}}</div>
                    </div>
                </div>
  <!--              <div class="item">
                    <div class="item-label">{{$t("RESERVATION_CODE")}}</div>
                    <div>{{reservationObject.reservation_code}}</div>
                </div>-->

                <div class="item">
                    <div class="item-label">{{$t("TOTAL")}}</div>
                    <div class="item-content text-right">
                        <small v-if="_has(reservationObject, 'total')" class="d-block text-muted">
                            <span v-if="_has(reservationObject, 'channel_currency.code')">{{reservationObject.channel_currency.code}}</span>
                            {{Number(reservationObject.total).toFixed(2)}}
                        </small>
                        <span v-if="_has(reservationObject, 'company_currency.code')">{{reservationObject.company_currency.code}}</span>
                        {{Number(reservationObject.company_total).toFixed(2)}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {fetchAccommodationReservation} from '@/services/accommodation_reservation'
    import {cloneDeep, has, get} from 'lodash'
    import AppButton from "@/components/app/AppButton/AppButton";
    import {R_RESERVATION_ACCOMMODATION_GENERAL} from '@/shared/route_permission'
    import routeNames from "@/router/routeNames";
    import AppAside from "@/components/app/form/AppAside"

    export default {
        name: "InboxReservation",
        components: {AppButton, AppAside},
        props: {
            reservation: {
                type: Object | Number
            }
        },
        data() {
            return {
                reservationObject: null,
                R_RESERVATION_ACCOMMODATION_GENERAL,
                routeNames,
            }
        },
        methods: {
            fetch(id) {
                fetchAccommodationReservation(id).then(response => {
                    this.reservationObject = cloneDeep(response.data)
                })
            },
            _has(object, path) {
                return has(object, path)
            },
            _get(object, path) {
                return get(object, path)
            },
        },
        watch: {
            reservation: {
                handler(val) {
                    if (typeof val === 'number') {
                        this.fetch(val)
                    } else {
                        this.reservationObject = cloneDeep(val)
                    }
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>

<style scoped>
</style>
