export const TYPE_UNIT_AMENITIES_CHANGE = 10
export const TYPE_UNIT_KEY_PICKUP_CHANGE = 14
export const TYPE_UNIT_IMAGE_CHANGE = 15
export const TYPE_UNIT_ROOMS_CHANGE = 16
export const TYPE_UNIT_FEE_CHANGE = 18

export const TYPE_UNIT_CONTINGENTS_CHANGE = 20
export const TYPE_UNIT_LOS_OCCUPANCY_CHANGE = 21


export const TYPE = [
    {
        id: 1,
        t: "PROPERTY_BASIC_CHANGE",
    },
    {
        id: 2,
        t: "CANCELLATION_POLICY_CHANGE",
    },
    {
        id: 4,
        t: "PROPERTY_RULES_CHANGE",
    },
    {
        id: 5,
        t: "PROPERTY_IMAGE_CHANGE",
    },
    {
        id: 6,
        t: "POINT_OF_INTEREST_CHANGE",
    },
    {
        id: 7,
        t: "OWNER_ADD",
    },
    {
        id: 8,
        t: "OWNER_BASIC_CHANGE"
    },
    {
        id: 9,
        t: "OWNER_FINANCE_CHANGE"
    },
    {
        id: 10,
        t: "UNIT_AMENITIES_CHANGE"
    }, {
        id: 11,
        t: "UNIT_ADD"
    },
    {
        id: 12,
        t: "UNIT_DELETE"
    },
    {
        id: 13,
        t: "UNIT_BASIC_CHANGE"
    },
    {
        id: 14,
        t: "UNIT_KEY_PICKUP_CHANGE"
    },
    {
        id: 15,
        t: "UNIT_IMAGE_CHANGE"
    },
    {
        id: 16,
        t: "UNIT_ROOMS_CHANGE"
    },
    {
        id: 17,
        t: "UNIT_DAMAGE_DEPOSIT_CHANGE"
    },
    {
        id: 18,
        t: "UNIT_FEE_CHANGE"
    },
    {
        id: 19,
        t: "UNIT_RESTRICTION_CHANGE"
    },
    {
        id: 20,
        t: "UNIT_CONTINGENTS_CHANGE"
    },
    {
        id: 21,
        t: "UNIT_LOS_OCCUPANCY_CHANGE"
    },
    {
        id: 22,
        t: "OWNER_DELETE"
    },
    {
        id:23,
        t:"SALES_CHANNEL_CHANGE"
    }
]
export const KEYS = [
    {
        id: 1,
        t: "NAME",
    },
    {
        id: 2,
        t: "CATEGORIZATION",
    },
    {
        id: 3,
        t: "UNIT_NUMBER",
    },
    {
        id: 4,
        t: "TYPE",
    },
    {
        id: 6,
        t: "ADDRESS",
    },
    {
        id: 7,
        t: "STREET_NUMBER",
    },
    {
        id: 8,
        t: "ZIP"
    },
    {
        id: 9,
        t: "CITY"
    },
    {
        id: 10,
        t: "COUNTY"
    }, {
        id: 11,
        t: "COUNTRY"
    },
    {
        id: 12,
        t: "REGION"
    },
    {
        id: 13,
        t: "COORDINATE"
    },
    {
        id: 14,
        t: "CHILDREN"
    },
    {
        id: 15,
        t: "PAYMENT_METHOD"
    },
    {
        id: 16,
        t: "RECEIVE_RESERVATIONS"
    },
    {
        id: 17,
        t: "BAGGAGE_DROPOFF"
    },
    {
        id: 18,
        t: "PETS"
    },
    {
        id: 19,
        t: "PARKING"
    },
    {
        id: 20,
        t: "WIFI"
    },
    {
        id: 21,
        t: "CHECK_IN"
    },
    {
        id: 22,
        t: "CHECK_OUT"
    },
    {
        id: 23,
        t: "FULL_NAME"
    },
    {
        id: 24,
        t: "VAT"
    },
    {
        id: 25,
        t: "ADDRESS"
    },
    {
        id: 26,
        t: "ZIP"
    },
    {
        id: 27,
        t: "CITY"
    },
    {
        id: 28,
        t: "COUNTRY"
    },
    {
        id: 29,
        t: "REGISTRATION_NUMBER"
    },
    {
        id: 30,
        t: "ENTITY_TYPE"
    },
    {
        id: 31,
        t: "IBAN"
    },
    {
        id: 32,
        t: "SELF_ISSUE"
    },
    {
        id: 33,
        t: "REGISTERED_FOR_VAT"
    },
    {
        id: 34,
        t: "TYPE"
    },
    {
        id: 35,
        t: "OWNER"
    },
    {
        id: 36,
        t: "SIZE"
    },
    {
        id: 37,
        t: "NUMBER_BEDROOMS"
    },
    {
        id: 38,
        t: "FLOOR"
    },
    {
        id: 39,
        t: "ADDRESS"
    },
    {
        id: 40,
        t: "STREET_NUMBER"
    },
    {
        id: 41,
        t: "LATITUDE"
    },
    {
        id: 42,
        t: "NUM_GUESTS"
    },
    {
        id: 43,
        t: "NAME"
    },
    {
        id: 44,
        t: "FPM"
    },
    {
        id: 45,
        t: "TURN_ON"
    },
    {
        id: 46,
        t: "ADDRESS"
    },
    {
        id: 47,
        t: "LATITUDE"
    },
    {
        id: 48,
        t: "RELEASE_PERIOD"
    },
    {
        id: 49,
        t: "CHECK_IN_ALLOWED"
    },
    {
        id: 50,
        t: "CHECK_OUT_ALLOWED"
    },
    {
        id: 51,
        t: "LOS"
    },
    {
        id: 52,
        t: "OCCUPANCY"
    },
    {
        id:53,
        t:"COT"
    },
    {
        id:54,
        t:"STREET_NUMBER"
    },
    {
        id:56,
        t:"LONGITUDE"
    },
    {
        id:57,
        t:"ID"
    },
    {
        id:58,
        t:"NAME"
    },
    {
        id:59,
        t:"USERNAME",
    },
    {
        id:60,
        t:"PASSWORD"
    },
    {
        id:61,
        t:"URL"
    },
    {
        id:62,
        t:"DESCRIPTION"
    },
    {
        id:63,
        t:"ADD"
    },
    {
        id:64,
        t:"DELETE"
    },
    {
        id:65,
        t:"LONGITUDE"
    },
    {
        id:66,
        t:"ROOM_ADD"
    },
    {
        id:67,
        t:"ROOM_DELETE"
    },
    {
        id:68,
        t:"ROOM_QUANTITY"
    },
    {
        id:69,
        t:"INVENTORY_ADD"
    },
    {
        id:70,
        t:"INVENTORY_DELETE"
    },
    {
        id:71,
        t:"INVENTORY_QUANTITY"
    },
]
