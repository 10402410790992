<template>
    <div
        class="main_calendar_test--cell main_calendar_test-bb main_calendar_test--background_gray d-flex flex-column justify-content-around main_calendar_test--poiner animated fadeIn">
        <div @click="modalShow=true" class="main_calendar_test__holder--sidebar-h1 position-relative"><span
            class="main_calendar_test--dropdown-plus">{{$t("RATE_PLANS")}}</span>
        </div>

        <b-modal v-model="modalShow" :title="$t('RATE_PLANS')" @ok="update">
            <p>
              
                <!--
                <b-form-checkbox-group
                    ref="rateplan-checkbox-list"
                    stacked
                    id="rateplan-checkbox-list"
                    value-field="id"
                    text-field="name"
                    :options="listFormatted"

                    name="rateplan-checkbox-list"
                    v-model="selected"

                ></b-form-checkbox-group>-->
            </p>
        </b-modal>

    </div>
</template>

<script>
    export default {
        name: "TableRateplanSidebar",
        props: {
            ratePlans: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        data() {
            return {
                modalShow: false,
                selected: []
            }
        },
        computed: {
            listFormatted() {
                return this.ratePlans.map(item => {
                    return {...item, ...{name: item.name + ' (' + item.distribution.name + ')'}}
                })
            }
        },
        methods: {

            update() {
                this.$emit('update', this.selected)
            }
        }
    }
</script>

<style scoped>

</style>
