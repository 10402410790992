<template functional>
    <div class="main_calendar--cell position-relative main_calendar--availability-cell"
         :class="{'main_calendar__bg-hover': props.highlighted, 'disable-restriction': props.cellData.disabled,'main_calendar-br_green': props.cellData.lastDayOfMonthClass}"
         @click.shift.exact="listeners.drag"
         @click.exact="listeners.startEndDrag"
         @mouseover.shift="listeners.currentDrag">
        <div class="justify-content-center main_calendar--status" :class="props.cellData.weekdayClasses">

            <i  v-if="props.cellData.showOpenIndicator"
                class="fa fa-circle m-0 main_calendar_test_indicator-open"></i>

            <i  v-else class="fa fa-circle m-0 main_calendar_test_indicator-close" ></i>
        </div>
    </div>
</template>

<script>
export default {
    name: "TableRestrictionPeriodCell",
    props: {
        cellData: {
            type: Object
        },
        highlighted: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
.disable-restriction{
    background:#edeff18f;
    pointer-events: none;
    cursor: default;
}
</style>
