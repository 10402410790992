<template>
    <form>
        <b-row class="mt-4">
            <b-col lg="6">
                <app-translation-input :languages="supportedLanguages" v-model="category.name"
                                       :label="$t('NAME')"
                                       :lang_id="category.lang_id"></app-translation-input>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col lg="6">
                <app-button :loading="loading" @click="save">{{$t('SAVE')}}</app-button>
            </b-col>
            <b-col lg="6" class="d-flex flex-lg-row-reverse">
                <app-button-delete @click="deleteDialogState=true"></app-button-delete>
            </b-col>
        </b-row>
        <app-confirmation-dialog
            :show="deleteDialogState"
            @confirm="deleteCategory"
            @cancel="deleteDialogState=false"
            :delete_title="true"
        ></app-confirmation-dialog>
    </form>
</template>

<script>

import AppButton from "@/components/app/AppButton/AppButton";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
import {getSystemLanguageList, updateTranslations} from "@/services/system";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {deleteThemeColorCategory} from "@/services/appearance";

export default {
    name: "AppearanceEditColorCategoryForm",
    mixins:[getErrorMessage],
    components:{AppButton, AppButtonDelete, AppConfirmationDialog, AppTranslationInput},
    props:{
        category:{
            type: Object,
            default: null
        }
    },
    data(){
        return {
            loading: false,
            deleteDialogState: false,
            supportedLanguages: []
        }
    },
    methods:{
        save(){
            this.loading = true
            updateTranslations(this.category.lang_id, {"en": this.category.label}).then(response => {
                notifySuccess()
                this.loading = false
                this.$emit('save-edit-category')
            }, error => {
                this.showErrorMessages(error)
                this.loading = false
            })
        },
        deleteCategory(){
            this.deleteDialogState = false
            deleteThemeColorCategory({category_id: this.category.id}).then(response => {
                notifySuccess()
                this.$emit('save-edit-category')
            }, error => {
                this.showErrorMessages(error)
            })
        }
    },
    created() {
        getSystemLanguageList().then(response => {
            response.data.forEach(el => {this.supportedLanguages.push(el.name)})
        })
    }
}
</script>

<style scoped>

</style>
