<template>
    <AppHeaderDropdown right no-caret>

        <template slot="header">

            <img v-if="profile_photo_path"
                 @contextmenu="preventRightClick"
                 :src="profile_photo_path"
                 alt="profile photo"
                 class="img-avatar"/>
            <i v-else class="fa fa-user-circle-o fake_profile_photo" aria-hidden="true"></i>

        </template>
        <template slot="dropdown">
            <div>
                <b-dropdown-item :to="item.route" :key="itemIndex"
                                 v-for="(item, itemIndex) in dropdownList">
                    <i v-if="item.icon" :class="item.icon"></i>{{$t(item.label)}}
                </b-dropdown-item>
                <b-dropdown-item @click="logout"> {{ $t("LOGOUT") }}</b-dropdown-item>
            </div>
        </template>
    </AppHeaderDropdown>
</template>

<script>
    import {HeaderDropdown as AppHeaderDropdown} from '@coreui/vue'
    import routeNames from '@/router/routeNames'
    import {BOOKER_TOOLS_DOMAIN, LoginPageEn, LoginPageHr, TestDomainLoginPageHr} from "@/shared/constants";

    export default {
        name: 'DefaultHeaderDropdown',
        components: {
            AppHeaderDropdown,
        },
        data() {
            return {
                RouteUserSettings: routeNames.RN_USER
            }
        },
        computed: {
            dropdownList() {

                return this.routes.filter(element => {
                    return this.checkRoutePermission(element)
                })

                // return this.routes.filter(listItem => typeof listItem.r_permission === 'undefined' || this.$store.getters['user/getPermission'](listItem.r_permission))
            },
            profile_photo_path() {
                return this.$store.getters['user/getUserItem']('userData.profile_photo_thumb')
            },
            routes() {
                return [
                    {
                        label: 'MY_PROFILE',
                        route: {name: routeNames.RN_USER, params: {id: this.$store.getters['user/getUser'].id}},
                    },
                    {
                        label: 'ORGANIZATION',
                        route: {name: routeNames.RN_COMPANY, params: {id: this.$store.getters['user/getCompany']}},
                    }
                ]
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('auth/actionLogout').then(() => {
                    if (process.env.NODE_ENV === 'production') {
                        let domain = window.location.host;
                        if (domain !== BOOKER_TOOLS_DOMAIN) {
                            window.location.href = String(domain + "/w-login")
                        } else {
                            if (this.$i18n.locale == 'hr') {
                                window.location.href = LoginPageHr
                            } else {
                                window.location.href = LoginPageEn
                            }
                        }
                    } else {
                        this.$router.replace({name: routeNames.RN_LOGIN})
                    }
                })
            },
            preventRightClick(e) {
                if (e.button === 2) {
                    e.preventDefault()
                    return false
                }
            },
            checkRoutePermission(navItem) {
                let route = this.$router.resolve(navItem.route)
                return route.route.meta.permission_id === 'undefined'
                    || this.checkPermission(route.route.meta.permission_id)
            }
        },
        created() {
            this.$store.dispatch('user/actionSetUser')
        }
    }
</script>

<style scoped>
    .fake_profile_photo {

        font-size: 2.2rem;
        margin-right: 10px;
    }
</style>

