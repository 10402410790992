<template>
    <div>
        <app-table-v2 class="reservation_table" :items="tableData" :fields="computedFields" :provider="getTableData"
                      :default-limit="defaultLimit" :show-limit="showLimit"
                      @busyToggled="toggleBusy"
                      :selectable="true"
                      :http="{methods:'GET', url:INVOICE_LIST_URL}"
                      :filter="filter"
                      :exportColumns="computedExportColumns"
                      :search-on-create="searchOnCreate"
                      :maxExportLimit="5000" :search-event="searchEventName">
            <template v-slot:reservations="data">
                <template v-if="data.item.reservations.length === 1">
                    <b-link
                        @click="() => {reservationAsideObject={id: data.item.reservations[0].id};reservationAsideState=true}">
                        {{data.item.reservations[0].distribution_booking_id}}
                    </b-link>
                </template>
                <template v-else-if="data.item.reservations.length > 1">
                    <b-link :to="{name: routeNames.RN_RESERVATION_ACCOMMODATION_LIST, query: {invoice: data.item.id}}"
                            target="_blank">{{$t('SHOW_ALL')}}
                    </b-link>
                </template>
                <template v-else>
                    {{''}}
                </template>
            </template>

            <template v-slot:invoice_type="data">
                <small class="text-muted d-block">
                    {{data.item.system_invoice_type.label}}
                </small>
                {{ data.item.invoice_type.name }}
            </template>

            <template v-slot:created_date="data">
                <small class="text-muted d-block">
                    {{data.item.created_date | time}}
                </small>
                {{data.item.created_date | date}}
            </template>


            <template v-slot:delivery_date="data">
                {{ data.item.delivery_date | date }}
            </template>

            <template v-slot:due_date="data">
                {{ data.item.due_date | date }}
            </template>

            <template v-slot:amount_gross="data">
                {{ data.item.amount_gross }}
            </template>


            <template v-slot:status="data">
                {{ simplifyStatus(data.item.status)}}
            </template>
            <template v-slot:created_date="data">
                <small class="text-muted d-block">
                    {{data.item.created_date | time}}
                </small>
                {{data.item.created_date | date}}
            </template>

            <template v-slot:actions="data">

                <app-button
                    v-if="showXls(data.item)"
                    variant="link"
                    button_type="excel"
                    :show_text="false"
                    :href="data.item.calculation.xls">
                </app-button>

                <app-button
                    v-if="data.item.action_permissions.download"
                    variant="link"
                    button_type="pdf"
                    :show_text="false"
                    @click="downloadPDF(data.item.id)">
                </app-button>

                <app-button
                    v-if="data.item.action_permissions.cancel && !notCancelableList.includes(data.item.status.id)"
                    variant="link"
                    icon="cancel"
                    :show_text="false"
                    @click="openConfirmationDialog(ACTION_CANCEL, data.item.id)">
                </app-button>

                <app-button v-if="data.item.action_permissions.resend"
                            variant="link"
                            icon="send"
                            :show_text="false"
                            @click="openConfirmationDialog(ACTION_RESEND, data.item.id, data.item.invoicee)">
                </app-button>
            </template>

            <template v-slot:selectedContainerOptions="data">
                <app-access-control @AccessControlEvent="addToAccessControlCounter()" class="pr-0"
                                    :access_control_context="{function:F_INVOICE_REPORT_DOWNLOAD,  key:access_control_fetch_key}">
                    <app-button @click="exportReportModal = true"
                                class="pull-right ml-3 ">{{ $t('FINANCIAL_REPORT') }}
                    </app-button>
                </app-access-control>
                <b-modal :title="$t('DOWNLOAD')" v-model="exportReportModal">
                    <small  class="text-muted d-flex justify-content-center">
                        {{$t('EXPORT_MAX_LIMIT_WARNING', {num:5000})}}
                    </small>
                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <div v-if="data.totalRows > 1 && data.totalRows > data.selected.length">
                                <b-checkbox v-model="selectAllForPdfInvoice">
                                    {{$t("APPLY_TO_ALL_NUMBER", {number:data.totalRows})}}
                                    <span v-if="data.selected.length === 1" class="d-block text-muted font-xs">({{$t("ITEM_SELECTED", {value: data.selected.length})}})</span>
                                    <span v-if="data.selected.length > 1" class="d-block text-muted font-xs">({{$t("ITEMS_SELECTED", {value: data.selected.length})}})</span>
                                </b-checkbox>

                            </div>
                            <app-button :loading="exporting_xls"  @click="exportReportXls(data)" class="mr-3">
                                {{$t('DOWNLOAD')}}
                            </app-button>
                        </div>
                    </template>
                </b-modal>


                <app-button v-if="checkPermission(F_INVOICE_DOWNLOAD)" @click="exportPdfModal = true" class="pull-right ml-3 mr-3">{{$t('DOWNLOAD')}}</app-button>

                <b-modal :title="$t('DOWNLOAD')" v-model="exportPdfModal">
                    <small  class="text-muted d-flex justify-content-center">
                        {{$t('EXPORT_MAX_LIMIT_WARNING', {num:5000})}}
                    </small>
                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <div v-if="data.totalRows > 1 && data.totalRows > data.selected.length">
                                <b-checkbox v-model="selectAllForPdfInvoice">
                                    {{$t("APPLY_TO_ALL_NUMBER", {number:data.totalRows})}}
                                    <span v-if="data.selected.length === 1" class="d-block text-muted font-xs">({{$t("ITEM_SELECTED", {value: data.selected.length})}})</span>
                                    <span v-if="data.selected.length > 1" class="d-block text-muted font-xs">({{$t("ITEMS_SELECTED", {value: data.selected.length})}})</span>
                                </b-checkbox>

                            </div>
                            <app-button :loading="exporting_pdf"  @click="exportPdf(data)" class="mr-3">
                                {{$t('DOWNLOAD')}}
                            </app-button>
                        </div>
                    </template>
                </b-modal>
            </template>
        </app-table-v2>

        <app-aside v-model="reservationAsideState" :widths="['col-lg-12','col-sm-12', '100%']" full-width
                   header-styles="position: sticky; top: 0; z-index: 999; background-color:#fff">
            <template slot="header">
                <reservation-accommodation-header
                    :reservation="reservationAsideObject"></reservation-accommodation-header>
            </template>
            <reservation-details :reservation-id="reservationAsideObject.id" tab="general"
                                 @update="reservationAsideObject=$event"></reservation-details>
        </app-aside>

        <app-confirmation-dialog
            :show="dialogState"
            @confirm="callAction(dialogAction, dialogObjectId)"
            :variant="dialogAction === ACTION_CANCEL ? 'danger': 'primary'"
            @cancel="dialogState=false">
            {{dialogTitle}}
        </app-confirmation-dialog>
    </div>
</template>

<script>
    import AppSearchData from "@/components/app/AppSearchData";
    import AppNoData from "@/components/app/AppNoData";
    import {
        cancelInvoice,
        downloadInvoicePDF, downloadReportXls,
        exportInvoiceXLS, exportPdfInvoice,
        getOwnerInvoiceList,
        sendInvoiceNotice
    } from "@/services/finance";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {notifySuccess, toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {EventBus} from "@/shared/EventBus";
    import {cloneDeep} from "lodash";
    import {
        INVOICE_STATUS_PAID,
        INVOICE_STATUS_COMPLETED,
        INVOICE_STATUS_CANCELED,
        INVOICE_LIST_URL
    } from '@/shared/constants'
    import AppPagination from "@/components/app/filter/AppPagination";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import routeNames from "@/router/routeNames";
    import ReservationAccommodationHeader from "@/components/reservation/accommodation/ReservationAccommodationHeader";
    import ReservationDetails from "@/components/reservation/accommodation/ReservationDetails";
    import AppAside from "@/components/app/form/AppAside";
    import AppTableV2 from "@/components/app/AppTableV2";
    import {INVOICE_URL} from "@/services/endpoints";
    import {fileHelpers} from "@/mixins/shared/helpers";
    import moment from 'moment';
    import {F_INVOICE_DOWNLOAD, F_INVOICE_REPORT_DOWNLOAD} from "@/shared/function_permission";
    import {AC_INVOICE_NEW} from "@/mixins/AccessControl/AccessControlEnumeration";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

    const [CREATED, PROCESSING, PROCESSED, COMPLETED, PAID, CANCELED] = [1, 2, 3, 4, 5, 6]

    const ACTION_CANCEL = 1
    const ACTION_RESEND = 2

    const actions = {
        [ACTION_CANCEL]: cancelInvoice,
        [ACTION_RESEND]: sendInvoiceNotice
    }

    export default {
        name: "OwnerInvoiceList",
        components: {
            AppAccessControl,
            AppTableV2,
            ReservationDetails,
            ReservationAccommodationHeader,
            AppConfirmationDialog, AppPagination, AppButton, AppNoData, AppSearchData, AppAside
        },
        mixins: [AccessControlComponent,getErrorMessage, fileHelpers],
        props: {
            searchCriteria: {
                type: Object,
                default() {
                    return {}
                }
            },
            searchOnCreate: {
                type: Boolean,
                default: true
            },
            omittedFields: {
                type: Array,
                default() {
                    return []
                }
            },
            defaultLimit: {
                type: Number,
                default: 10
            },
            showLimit: {
                type: Boolean,
                default: true
            },
            searchEventName: {
                type: String,
                default: 'getInvoiceList'
            },
            selectable: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                F_INVOICE_DOWNLOAD,
                F_INVOICE_REPORT_DOWNLOAD,
                tableData: [],
                selectAllForPdfInvoice:false,
                exportPdfModal:false,
                exporting_pdf:false,
                exporting_xls:false,
                exportReportModal:false,
                fields: [
                    {key: 'id', label: 'Id'},
                    {key: 'hr_number', label: this.$t('NUMBER')},
                    {key: 'description', label: this.$t('DESCRIPTION'), tdClass: 'w-15 remove_padding'},
                    {key: 'reservations', label: this.$t('RESERVATION_CODE')},
                    {key: 'invoice_type', label: this.$t('TYPE'), tdClass: 'w-10 remove_padding'},
                    {key: 'nett', label: this.$t('AMOUNT')},
                    {key: 'discount', label: this.$t('DISCOUNT')},
                    {key: 'tax', label: this.$t('TAX'), tdClass: ''},
                    {key: 'amount_gross', label: this.$t('TOTAL_AMOUNT')},
                    {key: 'status', label: this.$t('STATUS')},
                    {key: 'delivery_date', label: this.$t('DELIVERY_DATE'), sortable: true},
                    {key: 'due_date', label: this.$t('DUE_DATE'),sortable: true},
                    {key: 'created_date', label: this.$t('DATE_CREATED'),sortable:true},
                    {key: 'actions', label: '', class: 'text-right ws-no-wrap'},
                ],
                exportColumns: [
                    {value: 'id', text: 'Id'},
                    {value: 'hr_number', text: this.$t('NUMBER')},
                    {value: 'description', text: this.$t('DESCRIPTION')},
                    {value: 'invoice_type.name', text: this.$t('TYPE')},
                    {value: 'invoicee.first_name', text: this.$t('RECIPIENT')},
                    {value: 'amount_gross', text: this.$t('AMOUNT')},
                    {value: 'status.label', text: this.$t('STATUS')},
                    {value: 'delivery_date', text: this.$t('DELIVERY_DATE')},
                    {value: 'due_date', text: this.$t('DUE_DATE')},
                    {value: 'created_date', text: this.$t('DATE_CREATED')}
                ],
                loading: false,
                filter: {},
                INVOICE_STATUS_PAID,
                INVOICE_STATUS_COMPLETED,
                ACTION_CANCEL, ACTION_RESEND,
                dialogAction: null,
                dialogObjectId: null,
                dialogState: false,
                dialogTitle: '',
                notCancelableList: [CREATED, PROCESSING, CANCELED],
                routeNames,
                reservationAsideObject: {},
                reservationAsideState: false,
                INVOICE_URL,
                exportModal: false,
                selectAllForSend: false,
                exporting: false,
                AC_INVOICE_NEW,
                access_control_fetch_key: AC_INVOICE_NEW,
                access_control_components: 1,
                access_control_general_context: null,
                INVOICE_LIST_URL
            }
        },
        computed: {
            computedFields() {
                return this.fields.filter(el => !this.omittedFields.includes(el.key))
            },
            computedExportColumns() {
                return this.exportColumns.filter(el => !this.omittedFields.includes(el.value))
            },
            exportURL() {
                return `${process.env.VUE_APP_API_URL}/${INVOICE_URL}/export/xls`
            },
            company() {
                return this.$store.getters['user/getCurrentCompany']
            }
        },
        methods: {
            exportPdf(data){
                let requestData
                if (this.selectAllForPdfInvoice) {
                    requestData = {...this.filter, limit: 5000}
                } else {
                    requestData = {invoice: data.selected.map(el => el.id)}
                }

                this.exporting_pdf = true
                exportPdfInvoice(requestData).then(response => {
                    if (response) {
                        this.handleFileDownload(response, `${this.$t('INVOICES')}_${moment(new Date()).format('YYYY-MM-DD')}`, 'zip')
                    }

                    this.exportPdfModal = false
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => this.exporting_pdf = false)


            },
            exportReportXls(data){
                this.exporting_xls = true
                let requestData

                if (this.selectAllForPdfInvoice) {
                    requestData = {filter:{...this.filter, limit: 5000}}
                } else {
                    requestData = {data: data.selected}
                }


                downloadReportXls(requestData).then(response => {
                    if (response) {
                        this.handleFileDownload(response, `${this.$t('INVOICES')}_${moment(new Date()).format('YYYY-MM-DD')}`,'xlsx')
                    }

                    this.exportReportModal = false
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.exporting_xls = false
                })
            },
            showXls(item) {
                return item.hasOwnProperty('calculation') && item.calculation.hasOwnProperty('xls') && item.calculation.xls !== null
            },
            getTableData(filter) {
                let data = {
                    page: filter.currentPage,
                    per_page: filter.perPage,
                    order_by: filter.sortBy,
                    order_by_direction: filter.sortDirection,
                    ...this.filter,
                }
                return getOwnerInvoiceList(data)
            },
            simplifyStatus(status) {
                if (status.id === INVOICE_STATUS_PAID) {
                    return this.$t('PAID')
                } else if (status.id === INVOICE_STATUS_CANCELED) {
                    return this.$t('CANCELED')
                } else {
                    return this.$t('UNPAID')
                }
            },
            downloadPDF(id) {
                downloadInvoicePDF(id).then(response => {
                    const url = window.URL.createObjectURL(response);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${id}.pdf`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }).catch((error) => {
                    this.showErrorMessages(error)
                })
            },
            openConfirmationDialog(action, id, partner = {}) {
                this.dialogAction = action
                this.dialogTitle = action === ACTION_CANCEL ? this.$t('INVOICE_CANCEL_DIALOG', {value: id}) : this.$t('INVOICE_RESEND_DIALOG', {
                    id,
                    email: partner.email,
                    interpolation: {escapeValue: false}
                })
                this.dialogObjectId = id
                this.dialogState = true
            },
            callAction(action, id) {
                this.dialogState = false
                actions[action](id).then(() => {
                    notifySuccess()
                }, (error) => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    if (action === ACTION_CANCEL) {
                        this.search(this.filter)
                    }
                })
            },
            toggleBusy($event) {
                EventBus.$emit('invoiceLoading', $event)
            },
            exportXLS(data) {
                let requestData
                if (this.selectAllForSend) {
                    requestData = {filter:{...this.filter, limit: 5000}}
                } else {
                    requestData = {invoice: data.selected.map(el => el.id)}
                }

                this.exporting = true
                exportInvoiceXLS(requestData).then(response => {
                    if (response) {
                        this.handleFileDownload(response, `${this.$t('INVOICES')}_${moment(new Date()).format('YYYY-MM-DD')}`, 'xlsx')
                    }

                    this.exportModal = false
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => this.exporting = false)
            }
        },
        created() {
            this.access_control_components = 1
            this.access_control_general_context = {
                company: this.company.id
            }

            if (this.searchOnCreate) {
                // Todo refactor to get owner_export from filter component
                this.searchCriteria.owner_export= true
                this.filter = cloneDeep(this.searchCriteria)
                EventBus.$emit(this.searchEventName)
            }
        },
        mounted() {
            EventBus.$on('invoiceSearch', (filter) => {
                this.filter = cloneDeep(filter)
                EventBus.$emit(this.searchEventName)
            })
        },
        beforeDestroy() {
            if (![routeNames.RN_INVOICE_LIST, routeNames.RN_OWNER_INVOICE_LIST].includes(this.$route.name)) {
                EventBus.$off('invoiceSearch')
            }
        }
    }
</script>

<style scoped>

</style>
