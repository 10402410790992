<template>
    <b-form @submit.prevent="save">
        <b-row>
            <b-col cols="12" class="mb-4">
                <label>{{$t('DECLINE_REASON')}}</label>
                <app-select  text-field="text" value-field="key" v-model="decline_reason" :options="decline_reason_options"></app-select>
            </b-col>
            <b-col cols="12" class="mb-4">
                <label>{{$t('MESSAGE_TO_GUEST')}}</label>
                <b-textarea v-model="decline_message_to_guest"></b-textarea>
            </b-col>
            <b-col cols="12" class="mb-4">
                <label>{{$t('MESSAGE_TO_AIRBNB')}}</label>
                <b-textarea v-model="decline_message_to_airbnb"></b-textarea>
            </b-col>

        </b-row>

        <b-row class="mt-2">
            <b-col>
                <app-button-submit  :disabled="(required['decline_reason'] && !decline_reason) || (required['decline_message_to_guest'] && !decline_message_to_guest)" :loading="saving">{{$t('SEND')}}</app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {declinePendingReservation} from "@/services/inbox";
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "InboxDeclineForm",
    props:{
        threadObject:{
            type:Object
        }
    },
    components: {AppSelect, AppButtonSubmit},
    mixins: [getErrorMessage],
    data() {
        return {
            saving:false,
            decline_data:null,
            decline_message_to_guest:null,
            decline_message_to_airbnb:null,
            decline_reason:null,
            decline_reason_options:[],
            required:{}
        }
    },
    methods: {
        save() {
            this.saving = true
            let req = {
                decline_reason: this.decline_reason,
                decline_message_to_guest:this.decline_message_to_guest,
                decline_message_to_airbnb:this.decline_message_to_airbnb

            }
            declinePendingReservation(this.threadObject.id,req).then(()=>{
                notifySuccess();
                this.$emit('close')
            },error=>{
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(()=>{
                this.saving = false
            })
        }
    },
    watch:{
        threadObject:{
            handler(thread){
                if(thread.hasOwnProperty('pending_reservation')){
                    for(const declineData of thread.pending_reservation.decline_data){
                        this.required[declineData.id] = declineData.required
                        if(declineData.id === 'decline_reason'){
                           for(const [key,value] of Object.entries(declineData.allowed_values)){

                               this.decline_reason_options.push({key,text:value})
                           }
                        }

                    }
                }
            },immediate:true
        }
    }
}
</script>

<style scoped>

</style>
