<template>
    <b-form @submit.prevent="save" v-if="formData.id">
        <b-row>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("_DATE") }}</label>
                <app-date-picker v-model="formData.transfer_date"></app-date-picker>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("ARRIVAL_TIME") }}</label>
                <time-picker v-model="formData.transfer_time"></time-picker>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("ROUTE") }}</label>
                <app-select mode="search" :search="searchRoute" @input="updateLocationList" text-field="name"
                            v-model="formData.route"
                            :search-empty-item="false"
                            :search-clear="true"></app-select>
            </b-col>
            <b-col class="mb-3" lg="6">
                <label>{{ $t("DRIVER") }}</label>
                <app-select v-model="formData.driver.id" :options="driverList" :search-input="true"
                            :search-empty-item="false"
                            text-field="name"></app-select>
            </b-col>
            <b-col class="mb-3" lg="6">
                <label>{{ $t("FROM") }}</label>
                <app-select :options="locationList" @input="updateLocation($event, true)" value-field="id"
                            text-field="name"
                            :search-empty-item="false"
                            v-model="formData.pickup.id"></app-select>
            </b-col>
            <template v-for="(attribute,attrIndex) in formData.attributes.pickup_location">
                <b-col lg="6" class="mb-3">
                    <label>{{ attribute.Name }}</label>
                    <b-input :placeholder="attribute.Description"
                             v-model="formData.attributes.pickup_location[attrIndex].Value"></b-input>
                </b-col>
            </template>
            <b-col class="mb-3" lg="6">
                <label>{{ $t("TO") }}</label>
                <app-select :options="locationList" @input="updateLocation($event, false)" value-field="id"
                            text-field="name"
                            :search-empty-item="false"
                            v-model="formData.dropoff.id"></app-select>
            </b-col>
            <template v-for="(attribute,attrIndex) in formData.attributes.dropoff_location">
                <b-col lg="6" class="mb-3">
                    <label>{{ attribute.Name }}</label>
                    <b-input :placeholder="attribute.Description"
                             v-model="formData.attributes.dropoff_location[attrIndex].Value"></b-input>
                </b-col>
            </template>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("STATUS") }}</label>
                <app-select :options="statusList" text-field="name" :search-empty-item="false"
                            v-model="formData.status"></app-select>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("NUM_GUESTS") }}</label>
                <app-number-input :controls="true" v-model="formData.num_guests" :min="1" :max="100"></app-number-input>
            </b-col>
            <b-col lg="12" class="mb-3">
                <app-button
                    :access_control_context="{function:C_TRANSFER_RESERVATION_ADD_RETURN_TRANSFER, key:AC_ADD_RETURN_TRANSFER, transfer_reservation: formData.id}"
                    @AccessControlEvent="addToAccessControlCounter()"
                    variant="primary" button_type="new" class="pull-left"
                    @click="returnTransferAsideForm">{{ $t('RETURN_TRANSFER') }}
                </app-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <form-header>{{ $t("SEND_RESERVATION_NOTIFICATION") }}</form-header>
            </b-col>
        </b-row>
        <b-row class="mb-3">
            <b-col>
                <b-form-checkbox
                    v-model="formData.notify_driver"
                    :checked-value="true" :unchecked-value="false">
                    {{ $t("RESERVATION_TO_DRIVER") }}
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <form-header>{{ $t("GUEST") }}</form-header>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("FIRST_NAME") }}</label>
                <b-input v-model="formData.guest_firstname"></b-input>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("LAST_NAME") }}</label>
                <b-input v-model="formData.guest_lastname"></b-input>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("EMAIL") }}</label>
                <b-input v-model="formData.guest_email"></b-input>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("PHONE") }}</label>
                <b-input v-model="formData.guest_phone"></b-input>
            </b-col>
            <b-col sm="12" class="mb-3">
                <label>{{ $t("MESSAGE") }}</label>
                <b-textarea v-model="formData.message"></b-textarea>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <form-header>{{ $t("FINANCE") }}
                    <app-button @click="calculatePrice" class="action_button" :show_text="true" variant="link"><i
                        class="fa fa-refresh" aria-hidden="true"></i></app-button>
                </form-header>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("PRICE") }}</label>
                <app-number-input :min="0" v-model="formData.total" :append="formData.currency_code"></app-number-input>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("TOTAL") }}</label>
                <app-number-input :min="0" :disabled="true" :value="totalCalculated"
                                  :append="formData.currency_code"></app-number-input>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("DISCOUNT") }}</label>
                <app-number-input :min="0" v-model="formData.discount_value"></app-number-input>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("TYPE") }}</label>
                <app-select :search-empty-item="false" :options="paymentTypeList"
                            v-model="formData.discount_type"></app-select>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("COMMISSION") }}</label>
                <app-number-input :min="0" v-model="formData.commission_value"></app-number-input>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("TYPE") }}</label>
                <app-select :search-empty-item="false" :options="paymentTypeList"
                            v-model="formData.commission_type"></app-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-3">
                <app-submit :loading="loading" :disabled="saveDisabled"></app-submit>
            </b-col>
        </b-row>
        <app-aside v-model="asideReturnTransfer">
            <template slot="header">{{ $t('RETURN_TRANSFER') }}</template>
            <return-transfer-reservation-form
                :transfer-data="formData"
                @addReturnTransfer="addReturnTransfer">
            </return-transfer-reservation-form>
        </app-aside>
    </b-form>
</template>

<script>
import {
    getDriverList,
    getRouteList,
    getTransferReservation,
    getPrice,
    UpdateTransferReservation
} from "@/services/transfer";
import AppAside from "@/components/app/form/AppAside";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {cloneDeep} from 'lodash'
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import vueTextMask from 'vue-text-mask'
import TimePicker from "@/components/app/datetime/TimePicker";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import FormHeader from "@/components/app/form/FormHeader";
import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButton from "@/components/app/AppButton/AppButton";
import {notifySuccess} from '@/shared/plugins/toastr';
import ReturnTransferReservationForm from "@/components/transfer/reservation/ReturnTransferReservationForm";
import {C_TRANSFER_RESERVATION_ADD_RETURN_TRANSFER} from "@/shared/component_permission";
import AppAccessControl from "@/components/app/AppAccessControl";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_ADD_RETURN_TRANSFER} from "@/mixins/AccessControl/AccessControlEnumeration";

export default {
    name: "TransferReservationForm",
    components: {
        AppButton,
        AppSubmit,
        FormHeader,
        AppNumberInput,
        TimePicker,
        AppDatePicker,
        AppSelect,
        vueTextMask,
        AppAside, ReturnTransferReservationForm,
        AppAccessControl
    },
    mixins: [AccessControlComponent],
    props: {
        reservationId: {
            type: Number
        }
    },
    data() {
        return {
            formData: {
                id: null,
                status: null,
                res_id: null,
                transfer_date: null,
                transfer_time: null,
                guest_firstname: null,
                num_guests: null,
                sibling_id: null,
                guest_lastname: null,
                guest_email: null,
                guest_phone: null,
                message: null,
                currency_code: null,
                currency_date: null,
                currency_value: null,
                discount: null,
                discount_value: null,
                discount_type: null,
                total: null,
                total_discount: null,
                total_with_discount: null,
                commission_value: null,
                commission_type: null,
                driver: {id: null, name: null},
                created_on: null,
                timestamp: null,
                pickup: {id: null, name: null},
                dropoff: {id: null, name: null},
                route: {id: null, name: null},
                attributes: {
                    pickup_location: [],
                    dropoff_location: []
                },
                created_by: {id: null, name: null},
                sibling: null,
                notify_driver: false,
            },
            locationList: [],
            driverList: [],
            regionDriverListIds: [],
            loading: false,
            asideReturnTransfer: false,
            C_TRANSFER_RESERVATION_ADD_RETURN_TRANSFER,
            access_control_components: 1,
            access_control_fetch_key: AC_ADD_RETURN_TRANSFER,
            AC_ADD_RETURN_TRANSFER
        }
    },
    computed: {
        saveDisabled() {
            let disabled = false

            if (!this.formData.transfer_date || !this.formData.transfer_time || !this.formData.route.id || !this.formData.pickup.id || !this.formData.dropoff.id || !this.formData.driver.id) {
                disabled = true
            }

            if (this.formData.total === null || this.formData.discount_value === null || this.formData.commission_value === null) {
                disabled = true
            }

            if (!this.formData.guest_email || !this.formData.guest_firstname || !this.formData.guest_lastname) {
                disabled = true
            }

            return disabled
        },
        totalCalculated() {
            if (this.formData.discount_value && this.formData.discount_type) {
                if (this.formData.discount_type === 'fix') {
                    return this.formData.total - this.formData.discount_value
                } else {
                    return this.formData.total - ((this.formData.discount_value / 100) * this.formData.total)
                }
            } else {
                return this.formData.total
            }
        },
        statusList() {
            return [
                {
                    id: "CONFIRMED",
                    name: this.$t("STATUS_CONFIRMED")
                },
                {
                    id: "CANCELED",
                    name: this.$t("STATUS_CANCELED")
                }
            ]
        },
        paymentTypeList() {
            return [
                {id: 'perc', name: '%'},
                {id: 'fix', name: this.formData.currency_code}
            ]
        }
    },
    watch: {
        reservationId: {
            handler(id) {
                getTransferReservation(id).then(response => {
                    this.formData = response.data
                    this.formData.notify_driver = false
                    this.locationList = []
                    this.regionDriverListIds = []
                    this.driverList = []
                    if (response.data.pickup.id) {
                        this.locationList.push(cloneDeep(response.data.pickup))

                        if (response.data.pickup.region.id) {
                            this.regionDriverListIds.push(cloneDeep(response.data.pickup.region.id))
                        }
                    }
                    if (response.data.dropoff.id) {
                        this.locationList.push(cloneDeep(response.data.dropoff))
                        if (response.data.dropoff.region.id) {
                            this.regionDriverListIds.push(cloneDeep(response.data.dropoff.region.id))
                        }
                    }
                    this.searchDriver()
                    if (response.data.driver.id) {
                        if (!this.driverList.find(el => el.id === response.data.driver.id)) {
                            this.driverList.push(cloneDeep(response.data.driver))
                        }
                    }

                })
            },
            immediate: true
        }
    },
    methods: {
        addReturnTransfer() {
            this.asideReturnTransfer = false
            this.$emit('addReturnTransfer')
        },
        searchRoute(value) {
            return getRouteList({
                name: value,
                perPage: 10000
            }).then(response => {

                return {data: response.data.items}
            })
        },
        async searchDriver() {
            this.driverList = []
            if (this.regionDriverListIds.length) {
                return await getDriverList({
                    region: this.regionDriverListIds,
                    perPage: 10000
                }).then(response => {
                    this.driverList = response.data.items
                })
            }
            return []
        },
        updateLocationList(item) {
            if (item.locations) {
                this.locationList = cloneDeep(item.locations)
            } else {
                this.locationList = []
            }

            this.formData.pickup.id = null
            this.formData.dropoff.id = null
            this.formData.driver.id = null
            this.formData.driver.name = null
            this.formData.attributes.pickup_location = []
            this.formData.attributes.dropoff_location = []

            this.regionDriverListIds = []
            this.driverList = []
            if (item.regions) {
                this.regionDriverListIds = item.regions.map(el => el.id)
                this.searchDriver()
            }
        },
        updateLocation($event, start = true) {
            if ($event) {
                let foundItem = this.locationList.find(el => Number(el.id) !== $event)
                let foundTrueItem = this.locationList.find(el => Number(el.id) === $event)
                if (foundItem && foundItem.hasOwnProperty('id')) {
                    if (start) {
                        this.formData.pickup.id = $event
                        this.formData.dropoff.id = foundItem.id

                        if (foundTrueItem.hasOwnProperty('attributes') && foundTrueItem.attributes.length) {
                            this.formData.attributes.pickup_location = cloneDeep(foundTrueItem.attributes)
                        }
                        if (foundItem.hasOwnProperty('attributes') && foundItem.attributes.length) {
                            this.formData.attributes.dropoff_location = cloneDeep(foundItem.attributes)
                        }

                    } else {
                        this.formData.dropoff.id = $event
                        this.formData.pickup.id = foundItem.id

                        if (foundTrueItem.hasOwnProperty('attributes') && foundTrueItem.attributes.length) {
                            this.formData.attributes.dropoff_location = cloneDeep(foundTrueItem.attributes)
                        }

                        if (foundItem.hasOwnProperty('attributes') && foundItem.attributes.length) {
                            this.formData.attributes.pickup_location = cloneDeep(foundItem.attributes)
                        }
                    }
                }
            }
        },
        calculatePrice() {
            if (this.formData.route.id && this.formData.driver.id && this.formData.num_guests) {
                getPrice({
                    route: this.formData.route.id,
                    driver: this.formData.driver.id,
                    num_guests: this.formData.num_guests,
                    return_transfer: this.formData.sibling_id > 0,
                    reservation: this.formData.id
                }).then(response => {
                    this.formData.discount_value = response.data.discount_value
                    this.formData.discount_type = response.data.discount_type
                    this.formData.total_discount = response.data.total_discount
                    this.formData.total_commission = response.data.total_commission
                    this.formData.commission_value = response.data.commission_value
                    this.formData.commission_type = response.data.commission_type
                    this.formData.total = response.data.total
                    this.formData.total_with_discount = response.data.total_with_discount
                })
            }
        },
        returnTransferAsideForm() {
            this.asideReturnTransfer = true
        },
        save() {
            this.loading = true
            UpdateTransferReservation(this.formData.id, this.formData).then(response => {
                this.formData = cloneDeep(response.data)
                this.formData.notify_driver = false
                notifySuccess()
                this.$emit('reload')
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>

</style>
