<template>
    <div role="tablist" v-if="hasPermissionView">
        <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-btn block href="#" v-b-toggle.contact variant="primary">{{$t('CONTRACT')}}</b-btn>
            </b-card-header>
            <b-collapse id="contact" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <gallery :c_permission_v="c_permission_v" :c_permission_e="c_permission_e"
                             :object-name="contractObjectName"
                                      document-type="application/pdf" :ObjectId="Number($route.params.owner_id)"
                                      :DocumentTypeId="DocumentTypeIdContract"></gallery>
                </b-card-body>
            </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-btn block href="#" v-b-toggle.id_scan variant="primary">{{$t('ID_CARD')}}</b-btn>
            </b-card-header>
            <b-collapse id="id_scan" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <gallery :c_permission_v="c_permission_v" :c_permission_e="c_permission_e"
                             :object-name="idCardObjectName"
                                     document-type="image/*,application/pdf" :ObjectId="Number($route.params.owner_id)"
                                     :DocumentTypeId="DocumentTypeIdIdScan"></gallery>
                </b-card-body>
            </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-btn block href="#" v-b-toggle.categorization variant="primary">{{$t('CATEGORIZATION')}}</b-btn>
            </b-card-header>
            <b-collapse id="categorization" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <gallery :c_permission_v="c_permission_v" :c_permission_e="c_permission_e"
                             :object-name="categorizationObjectName"
                                      document-type="application/pdf"
                                      :ObjectId="Number($route.params.owner_id)"
                                      :DocumentTypeId="DocumentTypeIdRjesenje"></gallery>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>

    import gallery from "@/components/app/gallery/Gallery";

    import {TYPE_OWNER_CONTRACT, TYPE_OWNER_RJESENJE, TYPE_OWNER_ID_SCAN} from '@/shared/constants'
    import {C_OWNER_DOCUMENT_V, C_OWNER_DOCUMENT_E} from "@/shared/component_permission";

    export default {
        name: "Index",
        components: {gallery},
        data() {
            return {
                c_permission_v: C_OWNER_DOCUMENT_V,
                c_permission_e: C_OWNER_DOCUMENT_E,
                DocumentTypeIdContract: TYPE_OWNER_CONTRACT,
                DocumentTypeIdRjesenje: TYPE_OWNER_RJESENJE,
                DocumentTypeIdIdScan: TYPE_OWNER_ID_SCAN,
            }
        },
        computed: {
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](this.c_permission_v)
            },
            ownerObject() {
                return this.$store.getters['owner/getOwner']
            },
            idCardObjectName(){
                return this.$t("OWNER_ID_CARD_GALLERY_OBJECT_NAME", {value: this.ownerObject.name})

            },
            contractObjectName(){
                return this.$t("OWNER_CONTRACT_GALLERY_OBJECT_NAME", {value: this.ownerObject.name})

            },
            categorizationObjectName(){
                return this.$t("OWNER_CATEGORIZATION_GALLERY_OBJECT_NAME", {value: this.ownerObject.name})

            },
        }
    }
</script>

<style scoped>

</style>
