<template>
    <b-form @submit.prevent="save" v-if="loaded">
        <template v-if="parameter">
            <b-row>
                <b-col md="12" lg="12" class="mb-4">
                    <distribution-reservation-parameter-item :parameter="parameter"
                                                             @update="updateParameter"
                                                             :supported-languages="supportedLanguages">
                    </distribution-reservation-parameter-item>
                </b-col>
            </b-row>
            <app-button-submit
                :loading="saving"
            ></app-button-submit>
        </template>
        <app-no-data v-else>
        </app-no-data>
    </b-form>
</template>

<script>
import {
    getReservationParameter,
    saveReservationParameter
} from "@/services/accommodation_reservation";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getSystemLanguageList} from "@/services/system";
import {cloneDeep} from "lodash";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "ReservationSetupParameter",
    components: {
        "AppNoData": () => import("@/components/app/AppNoData"),
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "DistributionReservationParameterItem": () => import("@/components/channel_manager/distribution/DistributionReservationParameterItem")
    },
    mixins: [getErrorMessage],
    props: {
        parameter_id: {
            type: Number,
            default: null
        },
        owner_id: {
            type: Number,
            default: null
        },
        property_id: {
            type: Number,
            default: null
        },
        company_id: {
            type: Number,
            default: null
        },
        rate_plan: {
            type:Number,
            default: null
        },
        context_company: {
            type:Number,
            default: null
        }
    },
    data() {
        return {
            parameter: null,
            saving: false,
            supportedLanguages: [],
            loaded: false,
        }
    },
    methods: {
        save() {
            this.saving = true

            let formattedParameter = {}
            if (this.parameter.complex_object && typeof this.parameter.complex_object === 'object' && this.parameter.complex_object.hasOwnProperty('type')) {
                if (this.parameter.complex_object.type === 'custom') {
                    let parameterValue = {}
                    for (const [key, val] of Object.entries(this.parameter.complex_object.objects)) {
                        if (val && val.value !== null && val.value !== "") {
                            parameterValue[key] = val.value
                        } else {
                            parameterValue[key] = val ? (val.value ? val.value : null) : null
                        }
                    }
                    formattedParameter = ({...this.parameter, ...{value_text: JSON.stringify(parameterValue), complex_object: null}})
                } else if (this.parameter.complex_object.type === 'composite') {
                    let parameterValue = []
                    for (const parameterObject of this.parameter.complex_object.objects) {
                        let parameterItemValue = {}
                        for (const [key, val] of Object.entries(parameterObject)) {
                            if (key === 'content') {
                                parameterItemValue[key] = {}
                                parameterItemValue[key].lang_id = val ? val.lang_id ? val.lang_id : null : null
                                parameterItemValue[key].value = val ? val.value ? val.value : null : null
                            } else {
                                if (val && val.value !== null && val.value !== "") {
                                    parameterItemValue[key] = val.value
                                } else {
                                    parameterItemValue[key] = val ? (val.value ? val.value : null) : null
                                }
                            }
                        }
                        parameterValue.push(parameterItemValue)
                    }
                    formattedParameter = ({...this.parameter, ...{value_text: JSON.stringify(parameterValue), complex_object: null}})
                } else {
                    formattedParameter = ({...this.parameter, ...{complex_object: null}})
                }
            } else {
                formattedParameter = ({...this.parameter, ...{complex_object: null}})
            }

            saveReservationParameter({item: formattedParameter}).then(() => {
                notifySuccess()
                this.getReservationsParameter()
                this.$emit('saved')
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                    this.saving = false
                }
            )

        },
        getReservationsParameter() {
            let req = {
                owner_id: this.owner_id,
                property_id: this.property_id,
                company_id: this.company_id,
                rate_plan: this.rate_plan,
                context_company:this.context_company
            }

            getReservationParameter(this.parameter_id, req).then(response => {
                this.parameter = response.data
            }).finally(() => {
                this.loaded = true
            })
        },
        getSupportedLanguages() {
            getSystemLanguageList().then(response => {
                response.data.forEach(el => {
                    this.supportedLanguages.push(el.name)
                })
            })
        },
        updateParameter(setupParameter) {
            this.parameter = setupParameter
        },
    },
    created() {
        this.getSupportedLanguages()
        this.getReservationsParameter()
    }
}
</script>

<style scoped>

</style>
