import {normalize, schema} from "normalizr";
import {getRestrictionList} from "@/services/pricing/restriction";
import moment from 'moment'

const state = {
    date_range: {
        start: null,
        end: null
    },
    date_list: [],
    entities: {
        restrictions: null
    },
    result: {
        restrictions: []
    }
}

const getters = {
    getRestrictions(state) {
        return state.entities.restrictions
    },
    getDateRange(state) {
        return state.date_range
    },
    getDateRangeEnd(state) {
        return state.date_range.end
    },
    getDateRangeStart(state) {
        return state.date_range.start
    },
    getDateRangeArray(state) {
        return [state.date_range.start, state.date_range.end]
    },
    getDateList(state) {
        return state.date_list
    }
}

const mutations = {
    setSchema(state, {schema, data}) {
        state.entities[schema] = data.entities[schema]
        state.result[schema] = data.result[schema]
    },
    setDateRange(state, {start, end}) {
        state.date_range.start = start
        state.date_range.end = end
    },
    setDateList(state, {start, end}) {
        state.date_list = []
        let currentDate = moment(start)
        const stopDate = moment(end)
        while (currentDate <= stopDate) {
            state.date_list.push(moment(currentDate))
            currentDate = moment(currentDate).add(1, 'days');
        }
    }
}

const actions = {
    actionSetRestrictions({state, commit}) {
        return new Promise((resolve, reject) => {
            if (state.result.restrictions.length === 0) {
                getRestrictionList().then(response => {
                    if (response.data.length > 0) {
                        const entity = new schema.Entity('restrictions');
                        const newSchema = {restrictions: [entity]}
                        const normalizedData = normalize({restrictions: response.data}, newSchema);
                        commit('setSchema', {schema: 'restrictions', data: normalizedData})
                    }

                }, error => {
                    reject(error)
                })
            }
            resolve(state.entities.restrictions)
        })
    },
    actionSetDateRange({commit}, {start, end}) {
        commit('setDateRange', {start, end})
        commit('setDateList', {start, end})
    },

}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
