<template>
    <b-form @submit.prevent="save" v-if="tagObject">
        <app-input-control :error-object="validationObject.name">
            <template v-slot:input="data">
                <app-translation-input
                    :label="$t('NAME')"
                    :languages="tagObject.languages"
                    :lang_id="tagObject.lang_id"
                    v-model="tagObject.label"
                    :context-label="$t('NAME')"
                    :state="data.state"
                    :width="['col-lg-4','col-sm-12', '40%']"
                    @saved="refresh"
                ></app-translation-input>
            </template>
        </app-input-control>
        <b-row>
            <b-col class="mt-4">
                <app-button-submit :loading="loading" ref="saveButton"></app-button-submit>
            </b-col>
            <b-col class="mt-4">
                <app-button-delete
                    @click="deleteItem"
                    class="pull-right">
                </app-button-delete>
            </b-col>
        </b-row>
        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="continueAction"
            @cancel="cancelAction"
            :title="$t('DELETE_TITLE')">
            <p>{{$t('DELETE_MESSAGE',{name})}}</p>
        </app-confirmation-dialog>
    </b-form>
</template>

<script>
import {TAG_TYPE_CUSTOM} from "@/shared/constants";
import {saveCustomTags, deleteTag, getTag} from "@/services/tag";
import {notifySuccess} from "@/shared/plugins/toastr";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {cloneDeep} from "lodash";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppButton from "@/components/app/AppButton/AppButton";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
import AppAside from "@/components/app/form/AppAside";
import {getTranslateList} from "@/services/system";
import AppTranslationList from "@/components/app/translation/AppTranslationList";
import AppInputControl from "@/components/app/AppInputControl";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {TAG_ALREADY_EXIST, TAG_LABEL_VALIDATION_FAIL} from "@/shared/error_codes";
import {EventBus, GE_TABLE_REFRESH_SEARCH} from "@/shared/EventBus";

export default {
    name: "CustomTag",
    components: {
        AppInputControl,
        AppTranslationList,
        AppTranslationInput,
        AppButtonDelete,
        AppButton,
        AppButtonSubmit,
        AppAside,
        AppConfirmationDialog
    },
    mixins: [getErrorMessage],
    props: {
        tag: {
            type: Object,
            default: null,
        },
        newTag: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            loading: false,
            deleting: false,
            translate_sidebar: false,
            label: null,
            tagObject: null,
            name: null,
            languageList: [],
            confirmation_dialog_state: false,
            validationObject: {
                name: {
                    input: [TAG_ALREADY_EXIST, TAG_LABEL_VALIDATION_FAIL],
                },
            }
        }
    },
    methods: {
        save() {
            this.loading = true
            let tagList = []

            tagList.push(this.tagObject)

            saveCustomTags({list: tagList}).then(() => {
                notifySuccess()
                this.$emit('saved')
            }).catch(error => {
                this.showErrorMessages(error,this.validationObject)
            }).finally(() => this.loading = false)
        },
        deleteItem() {
            this.confirmation_dialog_state = true
        },
        continueAction(){
            this.deleting = true
            deleteTag(this.tagObject.id).then(() => {
                notifySuccess()
                this.$emit('saved')
                this.confirmation_dialog_state = false
            }).catch(error => {
                this.confirmation_dialog_state = false
                this.showErrorMessages(error)
            }).finally(() => this.deleting = false)
        },
        cancelAction(){
            this.confirmation_dialog_state = false
        },
        saved() {
            this.translate_sidebar = false;
            this.$emit('saved')
        },
        edit() {
            this.translate_sidebar = true;
        },
        getTagObject() {
            getTag(this.tag.id).then(response => {
                this.tagObject = response.data
                this.name = response.data.label
            }, () => {
                this.tagObject = {}
            })

        },
        getTranslateList() {
            getTranslateList().then(response => {
                this.languageList = response.data
            })
        },
        refresh(){
            EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
        }
    },
    mounted() {
        if (this.tag && this.tag.id) {
            this.getTagObject()
        }
        this.getTranslateList()
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
}
</script>

<style scoped>

</style>
