<template>
    <b-card v-if="isVisible && property">
     <b-row>
         <b-col sm="12" md="6" xl="8" class="mb-3 mb-md-0 d-flex flex-column justify-content-between">
             <p class="header-2 mb-3 mb-xl-5">{{$t("PROPERTY_DOCUMENT")}}</p>
             <div class="d-flex  flex-wrap">
                 <template v-if="showEmailButton">
                     <b-button size="default" v-if="property.pdf.length > 0" :key="index" v-for="(pdf, index) in property.pdf"
                               class="app-button-btn mr-3 btn-pdf action_button pull-right"
                               :href="pdf.route + '?version=' + pdf.id"
                               target="_blank"
                               variant="link"
                     >
                         <i class="fa fa-file-pdf-o fa-fw mr-1 btn-pdf-i"
                            aria-hidden="true">
                         </i>
                         <template v-if="property.pdf.length > 1">
                             {{pdf.description}}
                         </template>
                     </b-button>
                 </template>
                 <app-access-control class="mr-3" tag="span" @AccessControlEvent="addToAccessControlCounter()"
                                     :access_control_context="{function:C_PROPERTY_PDF_SEND, key:AC_PROPERTY_OVERVIEW}">
                     <send-email-button  @click="sendEmailModal=true"  variant="primary">
                         {{$t('SEND')}}
                     </send-email-button>

                 </app-access-control>
             </div>
         </b-col>
         <b-col sm="12" md="6" xl="4"
                class="d-flex align-items-start flex-column justify-content-end bl-md-show pl-md-3">
             <app-access-control tag="span" @AccessControlEvent="addToAccessControlCounter()" :access_control_context="{function:C_PROPERTY_PDF_NEW_VERSION, key:AC_PROPERTY_OVERVIEW}">
                 <app-button
                     @click="generateConfirmationModal = true"
                     button_type="new"
                     id="generatePdfBtn"
                     :loading="generatePdfState"
                     :c_permission="c_permission"
                     variant="primary">{{$t('NEW_VERSION')}}
                 </app-button>

             </app-access-control>

         </b-col>
     </b-row>
        <AppConfirmationDialog v-if="generateConfirmationModal" :show="generateConfirmationModal" @confirm="generatePdf" @cancel="generateConfirmationModal =false" :disabled="generatePdfSubmitState" :title="$t('GENERATE_CONFIRMATION')">
            {{$t("GENERATE_PROPERTY_PDF_TEXT")}}
        </AppConfirmationDialog>
        <app-confirmation-dialog v-if="sendEmailModal"  :show="sendEmailModal" :disabled="contacts.length === 0 || generatePdfLanguage === null" @confirm="sendEmail" @cancel="sendEmailModal=false" :title="$t('PROPERTY_DOCUMENT_DELIVERY_CONFIRMATION')">
            <b-checkbox :checked="true" @change="updateSelectAll">
                {{$t("SELECT_ALL")}}
            </b-checkbox>
            <hr class="p-0 m-0 mb-1 mt-1">

            <b-form-checkbox-group
                                stacked
                                id="contact_list"
                                name="contact_list"
                                value-field="index"
                                text-field="name"
                                v-model="selected_contacts"
                                :options="contactWithEmail">
           </b-form-checkbox-group>
                            <small v-if="contactWithEmail.length === 0">
                                {{$t("NO_DATA")}}
                            </small>


        </app-confirmation-dialog>
    </b-card>

</template>

<script>

    import DownloadButton from '@/components/app/AppButton/AppButton'
    import SendEmailButton from '@/components/app/AppButton/AppButton'
    import AppButton from '@/components/app/AppButton/AppButton'
    import SubmitButton from '@/components/app/AppButton/AppButtonSubmit'
    import NoData from '@/components/app/AppNoData'
    import {toast} from '@/shared/plugins/toastr'
    import {C_PROPERTY_PDF_E, C_PROPERTY_PDF_SEND, C_PROPERTY_PDF_NEW_VERSION} from "@/shared/component_permission";
    import {STATUS_PROPERTY_IN_ACQUISITION, STATUS_PROPERTY_UPDATE} from "@/shared/constants";
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {sendPropertyPdfEmail, getPropertyPdfEmails} from "@/services/property";
    import FormHeader from "@/components/app/form/FormHeader";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_PROPERTY_OVERVIEW} from "@/mixins/AccessControl/AccessControlEnumeration";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    export default {
        name: "PropertyPdf",
        components: {
            AppConfirmationDialog,
            AppAccessControl,
            AppButton, SubmitButton, NoData, FormHeader, DownloadButton, SendEmailButton
        },
        mixins: [getErrorMessage,AccessControlComponent],
        props:{
            access_control_context: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                c_permission: C_PROPERTY_PDF_E,
                generatePdfState: false,
                sendPdfState: false,
                generatePdfPopowerShow: false,
                sendEmailPopowerShow: false,
                generatePdfLanguage: null,
                default_language: null,
                contacts: [],
                selected_contacts: [],
                C_PROPERTY_PDF_SEND,
                C_PROPERTY_PDF_NEW_VERSION,
                AC_PROPERTY_OVERVIEW,
                access_control_components:2,
                access_control_fetch_key:AC_PROPERTY_OVERVIEW,
                sendEmailModal:false,
                generateConfirmationModal:false
            }
        },
        methods: {
            getContacts(propertyId) {
                this.contacts = []
                getPropertyPdfEmails(propertyId).then(response => {
                    this.contacts = response.data.list
                    if (response.data.default_language) {
                        this.generatePdfLanguage = response.data.default_language ? response.data.default_language : null
                    }

                    this.contacts.forEach((el, index) => {
                        this.selected_contacts.push(index)
                    })
                }, () => {
                    this.contacts = []
                })
            },
            generatePdf() {

                this.$store.dispatch('property/ActionCreatePdf').then(response => {
                    toast({
                        'title': "",
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 6000
                    })
                    this.generatePdfState = false
                    this.generateConfirmationModal=false
                }, error => {
                    this.generateConfirmationModal=false
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 30000
                    })
                    this.generatePdfState = false
                })

            },
            sendEmail() {
                this.sendPdfState = true
                this.sendEmailModal  = true
                let req = {
                    list: this.contacts.filter((el, index) => this.selected_contacts.includes(index)),
                }

                sendPropertyPdfEmail(this.property.id, req).then(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 6000
                    })
                    this.sendPdfState = false
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                    this.sendPdfState = false
                }).finally(()=>{
                    this.sendEmailModal  = false
                })
            },
            updateSelectAll($event){
                if ($event) {
                    this.selected_contacts = this.contactWithEmail.map(el => el.index)
                } else {
                    this.selected_contacts = []
                }
            }
        },
        computed: {
            isVisible() {
                return this.AccessControlVisible
            },
            generatePdfSubmitState() {
                return this.generatePdfLanguage === null
            },
            property() {
                return this.$store.getters.getItem('property.basic')
            },
            showEmailButton() {
                return this.property.pdf.length > 0
            },
            contactWithEmail() {
                return this.contacts.map((el, index) => {
                    return {
                        id: el.id,
                        index: index,
                        email: el.email,
                        name: el.name + ` - ${el.email}`
                    }
                })
            }
        },
        watch: {

            '$route.params.id': {
                handler(propertyId) {

                    this.getContacts(propertyId)
                    this.access_control_general_context = {
                        property: propertyId,
                        company: this.$store.getters['user/getCompany']
                    }
                },
                immediate: true
            }
        }
    }
</script>

<style  scoped>
    .btn-pdf {
        line-height: inherit;
        color: var(--color-button);
        text-decoration:none;
    }
    .btn-pdf-i {
        font-size: 30px;
        width: 25px !important;
     }

</style>
