<template>
    <div>
        <b-row class="mt-4" v-if="fetch_data && list.length > 0">
            <b-col sm="12">
                <div v-if="computedSortList.length"
                     class="d-flex mb-3  align-items-end justify-content-end">
                    <div class="d-flex align-items-end">
                        <div class="d-flex align-items-center" v-if="computedSortList.length"
                             style="min-width: 15rem">
                            <span class="mr-2">{{$t('SORT_RESULTS_BY')}}</span>

                            <app-select class="flex-grow-1" @input="sort" v-model="sortCustomIndex" :options="computedSortList"
                                        text-field="label"
                                        :sanitize-text-value="false"
                                        value-field="index"></app-select>
                        </div>

                    </div>
                </div>
            </b-col>
            <b-col  sm="12" :key="unit_data.id" v-for="unit_data in list" >

                <b-card class="repeater-1" v-if="show_repeater">
                    <div slot="header">
                        <b-row>
                            <b-col>
                                <repeater-header class="mb-2">
                                    <small class="text-muted d-block" v-if="property_count > 1">
                                        <b-link v-if="checkPermission(R_PROPERTY_BASIC) && unit_data.property_id"
                                                :to="{name: routeNames.RN_PROPERTY_BASIC, params: {id: unit_data.property_id}}"
                                                target="_blank">
                                            {{unit_data.property_name}}
                                        </b-link>
                                        <template v-else>
                                            {{unit_data.property_name}}
                                        </template>
                                    </small>
                                    <template >
                                        <b-link v-if="checkPermission(R_PROPERTY_UNIT_BASIC)"
                                                :to="{name: routeNames.RM_PROPERTY_UNIT_BASIC, params: {id: unit_data.property_id, unit_id: unit_data.id}}"
                                                target="_blank">
                                            {{unit_data.title}}
                                        </b-link>
                                        <template v-else>
                                            {{unit_data.title}}
                                        </template>
                                    </template>

                                </repeater-header>
                            </b-col>
                        </b-row>
                    </div>


                    <b-table :busy="loader" :sort-by="sortBy" :sort-direction="sortDirection" class="mt-2" responsive small hover v-if="unit_data.date_list.length > 0" :fields="custom_fields"
                             :items="unit_data.date_list">
                        <template v-slot:value="data">
                            <span style="line-height: 1.5rem;" v-html="parseNewLine(data.item.value)"></span>

                        </template>
                        <template v-slot:date="data">
                            {{ data.item.date|date }}
                        </template>
                        <template v-slot:user="data">

                            {{getUser(data.item.user) }}
                        </template>
                        <template v-slot:timestamp="data">
                            {{ data.item.timestamp|timestamp }}
                        </template>

                        <template v-slot:edit="data">
                            <app-button class="pull-right" @click="edit(unit_data.id,data.item.date)" variant="link" button_type="bars"
                                        :show_text="false"></app-button>
                        </template>

                    </b-table>
                </b-card>
                <b-table  v-if="unit_data.date_list.length > 0 && !show_repeater" :sort-by="sortBy" :sort-direction="sortDirection" class="mt-2" responsive small hover  :fields="custom_fields"
                         :items="unit_data.date_list">
                    <template v-slot:value="data">
                        <span style="line-height: 1.5rem;" v-html="parseNewLine(data.item.value)"></span>

                    </template>
                    <template v-slot:date="data">
                        {{ data.item.date|date }}
                    </template>
                    <template v-slot:user="data">

                        {{getUser(data.item.user) }}
                    </template>
                    <template v-slot:timestamp="data">
                        {{ data.item.timestamp|timestamp }}
                    </template>

                    <template v-slot:edit="data">
                        <app-button class="pull-right" @click="edit(unit_data.id,data.item.date)" variant="link" button_type="bars"
                                    :show_text="false"></app-button>
                    </template>

                </b-table>
            </b-col>
            <app-aside v-model="aside" :widths="['col-lg-6']">
                <template v-slot:header>
                    <div class="property-wrapper mb-0 no-border">
                        <div class="card-header">
                            <div class="header-caption">{{ $t('OVERVIEW_SALE_TERMS_CHANGE_LOG') }}</div>
                            <h2 class="property-wrapper-padding mb-2">
                                {{ date|date }}
                            </h2>
                        </div>
                    </div>
                </template>
                <log-list-per-day :type="filter.type" :unit_id="edit_unit" :date="date" ></log-list-per-day>
            </app-aside>
        </b-row>

        <app-search-data v-if="!fetch_data"></app-search-data>
        <app-no-data v-else-if="fetch_data && list.length === 0"></app-no-data>
    </div>


</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import {getInventoryLog} from "@/services/unit";
import RepeaterHeader from "@/components/app/form/RepeaterHeader";
import LogListPerDay from "@/components/logs/LogListPerDay";
import routeNames from "@/router/routeNames";
import {R_PROPERTY_UNIT_BASIC,R_PROPERTY_BASIC} from "@/shared/route_permission";
import AppNoData from "@/components/app/AppNoData";
import AppSearchData from "@/components/app/AppSearchData";
import {EventBus} from "@/shared/EventBus";
import AppTableV2 from "@/components/app/AppTableV2";
import AppSelect from "@/components/app/AppSelect/AppSelect";

export default {
    name: "LogTable",
    components: {AppSelect, AppTableV2, AppSearchData, AppNoData, LogListPerDay, RepeaterHeader, AppButton, AppAside},
    props:{
        filter:{
            type:Object,
            default(){
                return {}
            }
        },
        property_count:{
            type:Number
        }
    },
    data() {
        return {
            sortCustomIndex:null,
            custom_fields:[],
            field_rates: [
                {key: 'date', label: this.$t('_DATE')},
                {key: 'value', label: this.$t('RATES') },
                {key: 'user', label: this.$t('USER')},
                {key: 'timestamp', label: this.$t('LAST_CHANGE')},
                {key: 'edit', label:'', thClass: 'text-right'},
            ],
            field_min_stay: [
                {key: 'date', label: this.$t('_DATE')},
                {key: 'value', label: this.$t('MIN_STAY') },
                {key: 'user', label: this.$t('USER')},
                {key: 'timestamp', label: this.$t('LAST_CHANGE')},
                {key: 'edit', label:'', thClass: 'text-right'},
            ],
            date: null,
            list: [],
            aside: false,
            routeNames,
            R_PROPERTY_UNIT_BASIC,
            R_PROPERTY_BASIC,
            edit_unit:null,
            fetch_data:false,
            additionalSortOptions: [
                {key: 'date_id', label: this.$t('_DATE')},
                {key: 'ts_insert', label: this.$t('LAST_CHANGE')},

            ],
            sortBy:null,
            sortDirection:null,
            show_repeater:true,
            loader:false
        }
    },
    computed: {

        computedSortList() {
            if(this.custom_fields.length > 0){
                return [...this.custom_fields.filter(el => {
                    return el.hasOwnProperty('sortable') && el.sortable
                }), ...this.additionalSortOptions]
                    .reduce((list, element) => {
                        list.push({
                            ...element, ...{
                                sortDesc: true,
                                label: `<div class="d-flex align-items-baseline"><div>${element.label}</div><small class="ml-1""> &#9660;</small></div>`
                            }
                        })
                        list.push({
                            ...element, ...{
                                sortDesc: false,
                                label: `<div class="d-flex align-items-baseline"><div>${element.label}</div><small class="ml-1">&#9650;</small></div>`
                            }
                        })
                        return list
                    }, [])
                    .map((el, index) => {
                        return {...el, ...{index: index}}
                    })
            }

        },
    },
    methods: {

        sort(){
           const sortObject =  this.computedSortList[this.sortCustomIndex]
           if(sortObject){
               this.sortBy = sortObject.key
               this.sortDirection = sortObject.sortDesc ? 'desc' : 'asc'
            //   this.$emit('sort-changed',{sortDesc:sortObject.sortDesc,sortBy:sortObject.key,currentPage:1,perPage:0})
           }

        },
        getUser(user){
           return user.first_name === 'application' ? 'revenue manager' : user.first_name + ' ' + user.last_name
        },
        parseNewLine(string) {
            return this.$options.filters.newLineToLineBreak(string)
        },
        countDateList(unit_id) {
            return this.list.length > 0 ? this.list.find(el => el.id === unit_id).date_list.length : []
        },
        edit(unit_id, date) {
            this.edit_unit = unit_id
            this.date = date
            this.aside = true
        },
        getData(filter){
            this.loader = true
            this.$emit('busyToggled',true)
            if (this.sortBy && this.sortDesc !== null) {
                filter.order_by = this.sortBy
                filter.order_by_direction = this.sortDesc ? 'DESC' : 'ASC'
            }
            getInventoryLog(filter).then(response => {
                this.setFields(filter)
                this.list = []
                if(response.data && response.data.hasOwnProperty('filter') && response.data.filter !== null){
                    EventBus.$emit('ge_revenue_log_set_filter',response.data.filter)
                }
            if (response.data && response.data.hasOwnProperty('property_list') && response.data.property_list){
                response.data.property_list.forEach(el => {
                    if(el.unit_list.length > 0){

                        for(const unitData of el.unit_list){
                            const object = {...unitData,...{property_name:el.title,property_id:el.id}}
                            this.list.push(object)
                        }
                    }

                })
            }

            }).finally(()=>{
                this.fetch_data = true
                this.loader = false
                this.$emit('busyToggled',false)
            })
        },
        setFields(filter){
            if(filter && filter.hasOwnProperty('type') && filter.type === 'minimum_stay' ){
                this.custom_fields = this.field_min_stay
            } else  {
                this.custom_fields = this.field_rates
            }
        },
    },
    beforeDestroy() {
        EventBus.$off('re_rm_log_show_only_table')
    },
    mounted() {
        EventBus.$on('re_rm_log_show_only_table',()=>{
           this.show_repeater = false

        })
    },

    watch:{
        filter:{
            handler(filterObject){
                if(filterObject){

                    this.getData(filterObject)
                }

            }
        },
        sortCustomIndex(index, oldIndex) {
            this.sortBy = null
            this.sortDesc = false
            if (index !== oldIndex) {
                let element = this.computedSortList.find(el => el.index === index)
                if (element) {
                    this.sortBy = element.key
                    this.sortDesc = element.sortDesc
                } else {
                    this.sortBy = null
                    this.sortDesc = false
                }

                this.getData(this.filter)
            }
        },
    },

}
</script>

<style scoped>
.no-border {
    box-shadow: none;
}

.no-border > div {
    border: none !important;
    padding-left: 0;
}
</style>
