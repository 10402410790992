<template>
    <tr>
        <td style="width: 80%">
            <app-date-range-picker :from-date="fromDate" :format="format" :min-date="minDate" :max-date="maxDate"
                                   v-model="period" :disabled-dates="disabledDates" @input="onPeriodInput"></app-date-range-picker>
        </td>
        <td style="width: 20%" class="text-right" v-if="addButton">
            <app-new-button :disabled="!period.start || !period.end" button_type="new"
                            @click="save"
                            :loading="loading">
                {{$t('ADD_ITEM')}}
            </app-new-button>
        </td>
    </tr>
</template>

<script>
    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
    import AppNewButton from "@/components/app/AppButton/AppButton";
    import moment from 'moment'

    export default {
        name: "PeriodItemNew",
        data() {
            return {
                period: {
                    start: null,
                    end: null
                }
            }
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            disabledDates: {
                type: Array,
                default: null,
            },
            minDate: {
                default: null
            },
            maxDate: {
                default: null
            },
            format:{
                type:String,
                default:null
            },
            fromDate: {
                default: null,
            },
            addButton: {
                type: Boolean,
                default: true,
            },
        },
        components: {
            AppDateRangePicker,
            AppNewButton,
        },
        computed: {
            disabledDatesList() {
                return this.disabledDates
            }
        },
        watch: {
            loading: {
                handler(value) {
                    if (!value) {
                        this.period.start = null
                        this.period.end = null
                    }
                },
                immediate: true
            }
        },
        methods: {
            save() {
                let period = {
                    start: this.formatDate(this.period.start),
                    end: this.formatDate(this.period.end)
                }
                this.$emit('save', period)
                this.period.start = null
                this.period.end = null
            },
            formatDate(value) {
                if (value) {
                    let date = new Date(value)
                    return moment(date).format('YYYY-MM-DD')
                }
            },
            onPeriodInput() {
                if (this.addButton) {
                    return
                }

                this.save()
            },
        },
    }
</script>

<style scoped>

</style>
