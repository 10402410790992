<template>
    <div class="availability_calendar--sidebar">
        <div class="availability_rows" :class="{'availability_calendar_wrapper-loader':showLoading}">

            <div class="availability_row availability_calendar_no-border">

                <div class="availability_sidebar availability_calendar-bt d-flex align-items-center justify-content-center">

                    <div class="availability_sidebar-select-all">
                        <b-form-checkbox v-model="select_all" size="sm"
                                         :class="overflow ? 'mt-3': 'mt-2'"
                                         :value="true"
                                         :unchecked-value="false"
                                         @change="updateSelectAll"
                        ></b-form-checkbox>
                    </div>

                    <calendar-year-cell-test class="availability_sidebar-name"
                                             :date-list="dates"
                                             :isOverflown="overflow"
                                             :display_work_mode="display_work_mode"
                                             :scroll-left-position="scrollLeftPosition"
                    ></calendar-year-cell-test>

                </div>

                <div ref="calendar_scroller"
                     class="availability_content_grid_monthly availability_calendar-bt">
                    <property-calendar-date-cell-test v-for="(date, dateIndex) in dates"
                                                      :key="dateIndex"
                                                      :index="dateIndex"
                                                      :date="date"
                                                      :display_work_mode="display_work_mode"
                                                      :scrollLeftPosition="scrollLeftPosition"
                    ></property-calendar-date-cell-test>
                </div>

            </div>

            <div class="availability_row_scroller">
                <div v-if="showSlider" class="scroller_sidebar">
                    <div v-if="overflow" class="scroller_sidebar-select">

                    </div>
                </div>

                <div
                    v-if="showSlider"
                    ref="calendar_scroll_container"
                   :class="display_work_mode === MULTI_UNIT_VIEW ? 'main_calendar__holder--content-scroll' : 'main_calendar__holder--content-scroll-monthly'"
                    @scroll="onScroll"
                >
                    <div class="availability_calendar-bb"
                         :key="date.start+'_date_'+index"
                         v-for="(date, index) in dates">
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {AvailabilityScrollMixin} from "@/mixins/calendar/AvailabilityScrollMixin";
import PropertyCalendarDateCellTest from "@/components/property/calendar/PropertyCalendarDateCellTest";
import TableYearCell from "@/components/unit/calendar_opt/Table/Dates/TableYearCell";
import CalendarYearCell from "@/components/property/calendar/CalendarYearCell";
import {
    EventBus,
    GE_CALENDAR_SELECT_ALL_UNIT,
} from "@/shared/EventBus";
import CalendarYearCellTest from "@/components/property/calendar/CalendarYearCellTest";
import {MULTI_UNIT_VIEW} from "@/shared/constants";

export default {
    name: "PropertyCalendarDatesTest",
    mixins: [AvailabilityScrollMixin],
    components: {CalendarYearCellTest, CalendarYearCell, TableYearCell, PropertyCalendarDateCellTest},
    props: {
        selectedUnits: {
            type: Array,
        },
        dates: {
            type: Array
        },
        unit: {
            type: Object
        },
        showPropertyItem: {
            type: Boolean,
            default: true
        },
        scrollLeftPosition: {
            type: Number,
            default: 0
        },
        loadMore: {
            type: Object,
            default() {
                return {
                    value: null,
                    load: null
                }
            }
        },
        showLoading: {
            type: Boolean,
            default: false
        },
        display_work_mode: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            select_all: false,
            dateIndex: null,
            start: null,
            valueC: 0,
            initial: true,
            calculatedPosition: null,
            showSlider: true,
            overflow: true,
            MULTI_UNIT_VIEW
        }
    },
    computed: {
        datesLength() {
            return this.dates.length > 31
        },
        lastIndexDate() {
            return this.dates.length > 0 ? this.dates.length - 1 : null
        },
        pixelsWide() {
            return 2.4 * 16
        },
    },
    methods: {
        updateSelectAll(value) {
            this.select_all = value
            EventBus.$emit(GE_CALENDAR_SELECT_ALL_UNIT, {value})
        },
        onScroll($event) {
            this.showMoreHandler()
            this.$emit('scroll', $event.target.scrollLeft)
        },
        isOverflown() {
            let element = document.querySelector('.main_calendar__holder--content-scroll-monthly')
            if (element) {
                this.overflow = element.scrollWidth === element.clientWidth
                return
            }
            this.overflow = true
        },
        showMoreHandler() {
            if (this.$refs.calendar_scroll_container) {
                if ((this.$refs.calendar_scroll_container.scrollWidth === this.$refs.calendar_scroll_container.clientWidth) || (this.$refs.calendar_scroll_container.scrollLeft === (this.$refs.calendar_scroll_container.scrollWidth - this.$refs.calendar_scroll_container.clientWidth))) {
                    this.$emit('showLoadMore', true)
                } else {
                    this.$emit('showLoadMore', false)
                }
            }
        },
        setShowSlider() {
            if (this.$refs.calendar_scroller) {
                this.showSlider = this.$refs.calendar_scroller.scrollWidth > this.$refs.calendar_scroller.clientWidth
            } else {
                this.showSlider = true
            }
        },
    },
    mounted() {
        window.addEventListener('resize', this.setShowSlider)
    },
    watch: {
        selectedUnits: {
            handler(select_units) {
                if (select_units.length === 0) {
                    this.select_all = false
                }
                if (select_units.length === 1) {
                    this.select_all = select_units[0].hasOwnProperty('select_all') && select_units[0].select_all
                }
            }, immediate: true
        },
        loadMore: {
            handler(object) {
                if (object.value && !object.load) {
                    this.calculatedPosition = this.pixelsWide * this.lastIndexDate
                }
                if (this.display_work_mode === MULTI_UNIT_VIEW) {
                    if (object.value && object.load) {
                        this.$emit('updateScrollLeft', this.calculatedPosition)
                        if (document.querySelector('.main_calendar__holder--content-scroll-monthly')) {
                            document.querySelector('.main_calendar__holder--content-scroll-monthly').scrollLeft = this.calculatedPosition
                        }
                    }
                }
            }
        },
        dates: {
            handler(value) {
                this.$nextTick(() => {
                    this.setShowSlider()
                    this.isOverflown()
                    this.showMoreHandler()
                })
            }, immediate: true
        }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.setShowSlider)
    },
}
</script>

<style scoped>
.no-border {
    border-bottom: none !important
}

.main_calendar__holder--content-scroll-month {
    grid-auto-columns: 2.4rem
}
</style>
