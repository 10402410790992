<template>
    <div>
        <b-row>
            <b-col>
                <form-header>{{$t('PROPERTY_ADDITIONAL_CASH_REGISTER_SETUP')}}</form-header>
            </b-col>
        </b-row>
        <b-row >
            <b-form @submit.prevent="saveEntitySetup">
                <b-col sm="12" lg="12" :key="property.id" v-for="(property, index) in propertyList">
                    <b-card class="repeater-1">
                        <div slot="header">
                            <b-row class="mb-2">
                                <repeater-header :headerClass="'mt-0 mb-0'">
                                    {{property.name}}
                                </repeater-header>
                            </b-row>
                        </div>
                        <b-row>
                            <b-col md="3" class="mb-4">
                                <label>{{$t('CADASTRAL_NUMBER')}}</label>
                                <app-number-input :max="9999" v-model="entitySetupForm[index].cadastral_number" :maxlength="4" :disabled="disableInput(index)"></app-number-input>
                            </b-col>
                            <b-col md="3" class="mb-4">
                                <label>{{$t('BUILDING_NUMBER')}} </label>
                                <app-number-input :max="99999"  v-model="entitySetupForm[index].building_number"  :maxlength="4" :disabled="disableInput(index)"></app-number-input>
                            </b-col>
                            <b-col md="3" class="mb-4">
                                <label>{{$t('BUILDING_SECTION_NUMBER')}} </label>
                                <app-number-input  :max="99999" v-model="entitySetupForm[index].building_section_number" :maxlength="4" type="number" :disabled="disableInput(index)"></app-number-input>
                            </b-col>
                            <b-col md="3" class="mb-4">
                                <label>{{$t('COMMUNITY')}}</label>
                                <b-input type="text" :maxlength="255" v-model="entitySetupForm[index].community"  :disabled="disableInput(index)"></b-input>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col md="6" class="mb-4">
                    <app-button-submit :c_permission="C_USER_CASH_REGISTER_SETUP_ADDITIONAL_PROPERTY_SAVE" :disabled="disableSubmit" :loading="savingEntity"></app-button-submit>
                </b-col>

            </b-form>
        </b-row>

    </div>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import RepeaterHeader from "@/components/app/form/RepeaterHeader";
    import {getCashRegisterEntitySetup, saveCashRegisterEntitySetup} from "@/services/finance";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import FormHeader from "@/components/app/form/FormHeader";
    import {C_USER_CASH_REGISTER_SETUP_ADDITIONAL_PROPERTY_SAVE} from '@/shared/component_permission';
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    export default {
        name: "UserCashRegisterEntitySetupForm",
        mixins: [getErrorMessage],
        props:{
            propertyList:{
                type:Array
            },
            cash_register_setup_id:{
                type:Number
            }
        },
        data(){
            return{
                entitySetupForm:[],
                validation:{

                },
                disabledSaveEntity:false,
                savingEntity:false,
                loadData:false,
                C_USER_CASH_REGISTER_SETUP_ADDITIONAL_PROPERTY_SAVE
            }
        },
        components: {AppNumberInput, FormHeader, RepeaterHeader, AppButtonSubmit},
        computed:{
            disableSubmit(){
                let list =   this.entitySetupForm.filter(item => item.id === null)
                if(list.length === 0){
                   return true
               }
               return false
            }
        },
        methods:{
            saveEntitySetup(){
                let request = this.entitySetupForm.filter(item=>item.id === null)
                this.savingEntity = true
                saveCashRegisterEntitySetup(this.cash_register_setup_id, {setup:request}).then(response=>{
                    notifySuccess()
                    this.setEntitySetupForm(this.propertyList)
                }).catch(error => {
                    this.showErrorMessages(error, this.validation)
                }).finally(()=>{
                    this.savingEntity= false
                })
            },
            disableInput(index){
              return this.entitySetupForm[index].id ? true : false
            },

            setEntitySetupForm(properties){
                this.loadData = false
                this.entitySetupForm = []
                this.entitySetupForm = []
                if(properties.length > 0){
                    properties.forEach(el=>{
                        let object = {
                            id:null,
                            object_type: 10,
                            object: el.id,
                            cadastral_number: null,
                            building_number: null,
                            building_section_number: null,
                            community: null
                        }
                    this.entitySetupForm.push(object)
                    })
                    getCashRegisterEntitySetup(this.cash_register_setup_id).then(response => {
                            if(response.data.length > 0){
                                this.setFormData(response.data)
                            }
                    }).finally(() => {
                        this.loadData = true
                    })
                }
            },
            setFormData(list){
                list.forEach(item=>{
                    let index = this.entitySetupForm.findIndex(el=>el.object === item.object)
                    this.entitySetupForm[index].id = item.id
                    this.entitySetupForm[index].object_type = item.object_type
                    this.entitySetupForm[index].object = item.object_type
                    this.entitySetupForm[index].cadastral_number = item.data.cadastral_number
                    this.entitySetupForm[index].building_number = item.data.building_number
                    this.entitySetupForm[index].building_section_number = item.data.building_section_number
                    this.entitySetupForm[index].community = item.data.community
                })
            }
        },
        watch:{
            propertyList: {
                handler(properties){
                    this.setEntitySetupForm(properties)
                }, immediate:true
            }

        }
    }
</script>

<style scoped>

</style>
