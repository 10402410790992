<template>
    <div>
        <website-tag-filter @search="setFilter" :loading="loading" @emptyCountryList="show_tip=true"></website-tag-filter>
        <website-tag-table :filter="filter" @busyToggled="toggleBusy" :show_tip="show_tip"></website-tag-table>
    </div>
</template>

<script>
    import WebsiteTagFilter from "@/components/direct_booking/website/tag/WebsiteTagFilter";
    import WebsiteTagTable from "@/components/direct_booking/website/tag/WebsiteTagTable";

    export default {
        name: "Index",
        components: {WebsiteTagTable, WebsiteTagFilter},
        data(){
            return{
                show_tip:false,
                loading:false,
                filter: null,
            }
        },
        methods: {
            setFilter(values) {
                this.filter = values
            },
            toggleBusy($event) {
                this.loading = $event
            }
        }
    }
</script>

<style scoped>

</style>
