<template>
    <div>
        <b-tooltip v-if="!hasPermission" :target="compnentId"
                   :placement="tooltipPlacement"
                   :title="$t('NO_PERMISSION', {value:c_permission})"></b-tooltip>
        <span :id="compnentId">
        <b-button
                class="app-button-delete"
                @click.prevent="$emit('click')"
                :variant="variant"
                :disabled="isDisabled"
        >
            <i :class="icon" aria-hidden="true"></i>
            <slot>{{$t('DELETE')}}</slot>
        </b-button>
    </span>
    </div>
</template>

<script>
    import AppValidationDescription from '@/components/app/form/AppValidationDescription'

    export default {
        name: "AppButtonDelete",
        components: {
            AppValidationDescription
        },
        props: {
            id: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            variant: {
                type: String,
                default: "outline-info"
            },
            button_type: {
                type: String,
                default: "delete"
            },
            c_permission: {
                type: Number,
                default: null
            },
            tooltipPlacement:{
                type:String,
                default: "top"
            }
        },
        data() {
            return {
                compnentId: null,
                icons: {
                    delete: "fa fa-trash-o fa-fw mr-1",
                    loading: "fa fa-spinner fa-spin fa-fw'"
                }
            }
        },
        computed: {
            icon() {
                if (this.icons[this.button_type]) {
                    return this.icons[this.button_type]
                }
                return this.icons.delete
            },
            hasPermission() {
                if (this.c_permission !== null) {
                    return this.$store.getters['user/getPermission'](this.c_permission)
                }
                return true
            },
            isDisabled() {
                return this.disabled || !this.hasPermission
            }
        },
        created() {
            this.compnentId = this.id
            if (this.compnentId === null) {
                this.compnentId = 'AppButton_' + this._uid
            }
        }
    }
</script>

<style scoped>
    .app-button-delete {
        white-space: nowrap;
        height: 2.4rem;
    }
</style>
