import { render, staticRenderFns } from "./ImportPropertyWizardUnitsForm.vue?vue&type=template&id=3844d77e&scoped=true&"
import script from "./ImportPropertyWizardUnitsForm.vue?vue&type=script&lang=js&"
export * from "./ImportPropertyWizardUnitsForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3844d77e",
  null
  
)

export default component.exports