<template>
    <div class="restriction">
        <b-form>


            <div class="mb-4" :key="restriction.id" v-for="(restriction) in restrictions" v-if="restriction.id < 4">
                <component :value="restrictionValue(restriction.id)" @input="updateValue(restriction.id, $event)"
                           :disabled="restrictionDisabled(restriction.id)"
                           :color_border="minStayColor(restriction)"
                           :restriction="restriction"
                           :allowed_values="getAllowedValues(restriction)"
                           :is="restriction.allowed_values.element_type + 'Element'"></component>
            </div>

            <b-row>
                <b-col v-if="restriction.id == CLOSED_ON_ARRIVAL || restriction.id == CLOSED_ON_DEPARTURE"
                       :key="restriction.id" v-for="(restriction) in restrictions">
                   <arrival-departure-days-form-element
                        :disabled="restrictionDisabled(restriction.id)"
                        :value="restrictionValue(restriction.id)" :restriction="restriction"
                        :allowed_values="getAllowedValues(restriction)"
                        @input="updateValue(restriction.id, $event)"></arrival-departure-days-form-element>
                </b-col>


            </b-row>
            <div class="mt-2">
                <template v-for="(restriction) in restrictions"
                          v-if="restriction.id == SALES_ENABLED && selectedRatePlan.rate_plan_category === SYSTEM_STANDARD_RATE">
                    <restriction-sales-period-enabled :key="restriction.id+'_sales_enabled'"
                                                      :value="restrictionValue(restriction.id)"
                                                      :restriction="restriction"
                                                      @input="updateValue(restriction.id, $event)"></restriction-sales-period-enabled>

                </template>
            </div>
        </b-form>
    </div>
</template>

<script>
    import ArrivalDepartureDaysFormElement
        from '@/components/unit/calendar/forms/restriction/ArrivalDepartureDaysFormElement'
    import {CLOSED_ON_ARRIVAL, CLOSED_ON_DEPARTURE, SALES_ENABLED, MIN_STAY} from "@/shared/constants";
    import RestrictionSalesPeriodEnabled
        from '@/components/unit/bulk_calendar/restriction/RestrictionSalesPeriodEnabled'
    import AppButton from "@/components/app/AppButton/AppButton";
    import {SYSTEM_STANDARD_RATE, SYSTEM_NON_REFUNDABLE_RATE} from "@/shared/constants";
    import SelectRestrictionFormElementNew
        from "@/components/unit/calendar/forms/restriction/SelectRestrictionFormElementNew";

    export default {
        name: "unit-bulk-calendar-restriction",
        props: {
            restrictions: {
                type: Array
            },
            period: {
                type: Object
            },
            selectedRatePlan: {
                type: Object
            },
            statistic: {
                type: Object,
            },
        },
        data() {
            return {
                SYSTEM_STANDARD_RATE,
                SYSTEM_NON_REFUNDABLE_RATE,
                CLOSED_ON_ARRIVAL,
                CLOSED_ON_DEPARTURE,
                SALES_ENABLED,
                MIN_STAY,
                options: [
                    {text: "Disabled", value: 0},
                    {text: "Enabled", value: 1}
                ]

            }
        },
        components: {

            AppButton,
            'selectElement': SelectRestrictionFormElementNew,
            ArrivalDepartureDaysFormElement,
            RestrictionSalesPeriodEnabled

        },

        methods: {
            getWeekday(number) {
                switch (Number(number)) {
                    case 1:
                        return this.$t('DATE.DAYS.MONDAY')
                    case 2:
                        // code block
                        return this.$t('DATE.DAYS.TUESDAY')
                    case 3:
                        return this.$t('DATE.DAYS.WEDNESDAY')
                    case 4:
                        return this.$t('DATE.DAYS.THURSDAY')
                    case 5:
                        return this.$t('DATE.DAYS.FRIDAY')
                    case 6:
                        return this.$t('DATE.DAYS.SATURDAY')
                    case 7:
                        return this.$t('DATE.DAYS.SUNDAY')
                }
            },
            getAllowedValues(restriction){
                if(restriction.id == CLOSED_ON_ARRIVAL || restriction.id == CLOSED_ON_DEPARTURE){
                    let data = []
                    for (const key in restriction.allowed_values.allowed_values) {

                        data.push({text: this.getWeekday(key), value: key,notEnabled: this.disabled })
                    }
                    return data
                }else {
                    return Object.keys(restriction.allowed_values.allowed_values).map(key => {
                        return {
                            id: Number(key),
                            name: key,
                        }
                    })
                }

           },
            minStayColor(restriction) {
                if (restriction.id === MIN_STAY && this.statistic) {
                    if (this.statistic.avg_stay !== 0 && this.statistic.avg_stay < this.restrictionValue(restriction.id)) {
                        return true
                    }
                }
                return false
            },
            restrictionValue(restriction_id) {

                let id = this.period.id + '_' + restriction_id + '_' + this.selectedRatePlan.id
                let restriction = this.$store.getters['pricing/getRestrictionById'](id)
                if (typeof restriction === "undefined") {
                    return
                }
                if ((restriction.type === CLOSED_ON_ARRIVAL || restriction.type === CLOSED_ON_DEPARTURE)) {
                    if (restriction.value === null || restriction.value === '') {
                        restriction.value = "1234567"
                    }
                    restriction.value = restriction.value.toString()
                }
                if (restriction.parent_rate_plan_id) {

                    let parent_id = this.period.id + '_' + restriction_id + '_' + restriction.parent_rate_plan_id
                    let parentRateRestriction = this.$store.getters['pricing/getRestrictionById'](parent_id)
                    if (typeof parentRateRestriction !== "undefined") {
                        if ((restriction.type === CLOSED_ON_ARRIVAL || restriction.type === CLOSED_ON_DEPARTURE)) {
                            if (restriction.value === null || restriction.value === '') {
                                restriction.value = "1234567"
                            }
                            restriction.value = restriction.value.toString()
                        }

                        return parentRateRestriction.value
                    }
                }
                return restriction.value

            },
            restrictionDisabled(restriction_id) {
                let id = this.period.id + '_' + restriction_id + '_' + this.selectedRatePlan.id
                let restriction = this.$store.getters['pricing/getRestrictionById'](id)
                if (typeof restriction === "undefined") {
                    return
                }
                if (restriction.parent_rate_plan_id === null) {
                    return false;
                } else {
                    return true
                }
            },
            updateValue(restriction_id, value) {
                let id = this.period.id + '_' + restriction_id + '_' + this.selectedRatePlan.id
                let restriction = this.$store.getters['pricing/getRestrictionById'](id)
                if (typeof restriction !== "undefined") {
                    if (restriction.parent_rate_plan_id) {
                        let parent_id = this.period.id + '_' + restriction_id + '_' + restriction.parent_rate_plan_id
                        this.$store.commit('pricing/setRestrictionValue', {parent_id, value})
                    } else {
                        this.$store.commit('pricing/setRestrictionValue', {id, value})
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>


</style>
