<template>
    <b-form @submit.prevent="save" autocomplete="off" v-if="Object.keys(tagOptions).length">
        <template v-for="tag in tagTypes">
            <b-row>
                <b-col class="mb-4">
                    <app-input-control :error-object="validationObject.input">
                        <template v-slot:input="data" v-if="tagOptions[tag].length">
                            <label>{{ tag === TAG_TYPE_DESTINATION ? $t("DESTINATION") : $t("TAG") }}</label>
                            <app-select text-field="label" :options="tagOptions[tag]"
                                        v-model="selectedTags[tag]" mode="multiselect" :state="data.state">
                            </app-select>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
        </template>
        <b-row v-if="hasPermission(C_UNIT_ACTIVATION_TAG_SAVE_V)">
            <b-col class="mb-4">
                <app-button-submit :inline="true" :disabled="loading || disableSave" :loading="loading">
                    {{ $t("SAVE") }}
                </app-button-submit>
            </b-col>
        </b-row>
        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="continueAction"
            @cancel="confirmation_dialog_state=false"
            :title="$t('CUSTOM_TAGGING.TITLE')">
            {{ $t('CUSTOM_TAGGING.MSG') }} {{ $t('CUSTOM_TAGGING.MSG_CONF') }}
        </app-confirmation-dialog>
    </b-form>
</template>

<script>
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppInputControl from "@/components/app/AppInputControl";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {NO_TAG_ITEM_SELECTED} from "@/shared/error_codes";
import {bulkSaveEntityTags, getAllTags, getExistingTagsList} from "@/services/tag";
import {notifySuccess} from "@/shared/plugins/toastr";
import {TAG_TYPE_CUSTOM, TAG_TYPE_DESTINATION} from "@/shared/constants";
import {cloneDeep} from "@babel/types";
import {isEqual} from "lodash";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppNoData from "@/components/app/AppNoData";
import {C_UNIT_ACTIVATION_TAG_SAVE_V} from "@/shared/component_permission";

export default {
    name: "AppTagEntityForm",
    mixins: [getErrorMessage],
    components: {AppNoData, AppSelect, AppInputControl, AppButtonSubmit, AppConfirmationDialog},
    props: {
        selectedItems: {
            type: Array,
            default: () => []
        },
        objectType: {
            type: Number,
            default: null,
        },
        tagTypes: {
            type: Array,
            default: null,
        },
        destinationTypes: {
            type: String,
            default: null,
        },
        parentTag: {
            type: Number,
            default: null,
        },
        filterDestinations: {
            type: Boolean,
            default: true,
        },
        selectedCheckBox: {
            type: Object
        }
    },

    data() {
        return {
            TAG_TYPE_CUSTOM,
            TAG_TYPE_DESTINATION,
            selectedTags: {},
            tagOptions: {},
            loading: false,
            confirmation_dialog_state: false,
            isSame: true,
            checkTagList: false,
            validationObject: {
                input: [NO_TAG_ITEM_SELECTED]
            },
            C_UNIT_ACTIVATION_TAG_SAVE_V
        }
    },

    methods: {
        save() {
            if (this.isSame === false) {
                this.confirmation_dialog_state = true
            } else {
                this.loading = true

                let selectedValues = []
                Object.values(this.selectedTags).map(el => {
                    selectedValues = [...selectedValues, ...el]
                });

                bulkSaveEntityTags(this.objectType, {
                    entity_id: this.selectedItems,
                    list: selectedValues,
                }).then(() => {
                    notifySuccess()
                    this.$emit('saved')
                }).catch(error => {
                    this.showErrorMessages(error, this.validationObject)
                }).finally(() => this.loading = false)
            }
        },
        continueAction() {
            this.confirmation_dialog_state = false
            this.isSame = true
            this.save()
        },
        getTags(tagTypes) {
            return Promise.all(tagTypes.map(type => {
                let params = {
                    company: this.company.id
                }

                if (this.parentTag && type === TAG_TYPE_DESTINATION) {
                    params.parent_tag = this.parentTag
                }

                if (this.destinationTypes && type === TAG_TYPE_DESTINATION) {
                    params.destination_type = this.destinationTypes
                }
                return getAllTags(type, params)
            }))
        },
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        disableSave() {
            return isEqual(this.selectedTags, this.selectedCheckBox)
        },
        hasPermission() {
            return this.$store.getters['user/getPermission']
        }
    },
    created() {
        this.getTags(this.tagTypes).then(tagOptionResponses => {
            this.tagTypes.forEach((type, index) => {
                let filteredData = tagOptionResponses[index].data.filter(el => !this.selectedItems.includes(el.id))
                this.$set(this.tagOptions, type, filteredData)
            })
        })

        this.selectedTags = {...this.selectedCheckBox}
    }
}
</script>

<style scoped>

</style>
