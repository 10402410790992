<template>
    <div>
        <b-form @submit.prevent="save" autocomplete="off" v-if="formLoaded">
            <b-row>
                <b-col lg="12" class="mb-3">
                    <label>{{ $t('PAYMENT_TYPE') }}</label>
                    <app-select :search-empty-item="false"
                                :options="subscriptionPaymentTypeOptions"
                                v-model="subscriptionPaymentType"
                                :disabled="!checkPermission(C_COMPANY_SUBSCRIPTION_PAYMENT_TYPE_E)"
                                value-field="value"
                                text-field="text" set-first></app-select>
                </b-col>

                <b-col lg="12" class="mb-3" v-if="handleCards">
                    <label>{{ $t('CREDIT_CARD') }}</label>

                    <app-button v-if="checkPermission(C_COMPANY_SUBSCRIPTION_PAYMENT_TYPE_E)"
                                variant="link"
                                icon="new"
                                :show_text="false"
                                class="pull-right"
                                @click="$emit('addCreditCard')">
                    </app-button>

                    <template v-if="subscriptionDataLoadedList && subscriptionDataLoadedList.length" v-for="(creditCard, index) of subscriptionDataLoadedList">
                        <b-form-radio v-model="selectedCreditCard"
                                      :key="index"
                                      :value="creditCard.id"
                                      :checked="selectedCreditCard && selectedCreditCard === creditCard.id">
                            <app-button v-if="checkPermission(C_COMPANY_SUBSCRIPTION_PAYMENT_TYPE_E)"
                                        variant="link"
                                        icon="delete"
                                        :show_text="false"
                                        class="pull-right"
                                        @click="deleteCreditCard(creditCard.id)">
                            </app-button>

                            <app-button v-if="checkPermission(C_COMPANY_SUBSCRIPTION_PAYMENT_TYPE_E)"
                                        variant="link"
                                        icon="edit"
                                        :show_text="false"
                                        class="pull-right"
                                        @click="$emit('editCreditCard', creditCard)">
                            </app-button>
                            <app-button v-else
                                        variant="link"
                                        icon="edit"
                                        :show_text="false"
                                        class="pull-right"
                                        @click="$emit('showCreditCard', creditCard)">
                            </app-button>

                            {{ creditCard.ch_name }} {{ creditCard.type }} {{ creditCard.number.replace(/X+/i, '••') }}
                        </b-form-radio>
                    </template>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <app-button-submit v-if="checkPermission(C_COMPANY_SUBSCRIPTION_PAYMENT_TYPE_E)"
                                       class="filter-submit-wrapper"
                                       :inline="true"
                                       variant="primary"
                                       :loading="loading">
                    </app-button-submit>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import AppInputControl from "@/components/app/AppInputControl";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {
    fetchSubscriptionPaymentType,
    updateSubscriptionPaymentType,
} from "@/services/company";
import {
    SUBSCRIPTION_PAYMENT_TYPE_PAYMENT_LINK,
    PAYMENT_TYPE_BANK_TRANSFER,
    PAYMENT_TYPE_RECURRING_CARD_PAYMENT,
} from "@/shared/constants";
import {notifySuccess} from "@/shared/plugins/toastr";
import {
    C_COMPANY_SUBSCRIPTION_PAYMENT_TYPE_E,
} from "@/shared/component_permission";

export default {
    name: "PaymentTypeForm",
    mixins: [getErrorMessage],
    components: {
        AppNumberInput,
        AppInputControl,
        AppDatePicker,
        AppButtonSubmit,
        AppSelect,
        AppButton,
    },
    props: {
        company: {
            type: Number,
        },
        refreshListRequested: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            handleCards: false, // show GUI for cards
            deletedCreditCards: [],
            selectedCreditCard: null,
            subscriptionCreditCards: [],
            subscriptionPaymentType: SUBSCRIPTION_PAYMENT_TYPE_PAYMENT_LINK,
            subscriptionPaymentTypeOptions: [
                {text: this.$t("PAYMENT_TYPE_PAYMENT_LINK"), value: SUBSCRIPTION_PAYMENT_TYPE_PAYMENT_LINK},
                {text: this.$t("PAYMENT_TYPE_BANK_TRANSFER"), value: PAYMENT_TYPE_BANK_TRANSFER},
                // {text: this.$t("PAYMENT_TYPE_RECURRING_CARD_PAYMENT"), value: PAYMENT_TYPE_RECURRING_CARD_PAYMENT},
            ],
            loading: false,
            subscriptionDataLoaded: false,
            C_COMPANY_SUBSCRIPTION_PAYMENT_TYPE_E,
        }
    },
    computed: {
        formLoaded() {
            return this.subscriptionDataLoaded
        },
        subscriptionDataLoadedList() {
            return this.subscriptionCreditCards.filter(card => !this.deletedCreditCards.includes(card.id))
        },
    },
    methods: {
        save() {
            let requestData = {
                payment_type: this.subscriptionPaymentType,
                delete_credit_cards: this.deletedCreditCards,
                credit_cards: this.subscriptionCreditCards.filter(card => !this.deletedCreditCards.includes(card.id)).map(card => {
                    if (this.selectedCreditCard !== null && this.selectedCreditCard === card.id) {
                        return {...card, ...{recurring_charge: true}}
                    } else {
                        return {...card, ...{recurring_charge: false}}
                    }
                }),
            }

            this.loading = true
            updateSubscriptionPaymentType(this.company, requestData).then(response => {
                notifySuccess()
                this.subscriptionPaymentType = response.data.hasOwnProperty('payment_type') && response.data.payment_type ? response.data.payment_type : null
                this.subscriptionCreditCards = response.data.hasOwnProperty('credit_cards') && response.data.credit_cards ? response.data.credit_cards : []
            }, error => {
                this.showErrorMessages(error)
                this.deletedCreditCards = []
            }).finally(() => {
                this.loading = false
            })
        },
        fetchPaymentType() {
            this.loading = true
            fetchSubscriptionPaymentType(this.company).then(response => {
                this.subscriptionPaymentType = response.data.hasOwnProperty('payment_type') && response.data.payment_type ? response.data.payment_type : null
                this.subscriptionCreditCards = response.data.hasOwnProperty('credit_cards') && response.data.credit_cards ? response.data.credit_cards : []
                const selectedCreditCard = this.subscriptionCreditCards.find(card => card.recurring_charge)
                this.selectedCreditCard = selectedCreditCard && selectedCreditCard.hasOwnProperty('id') ? selectedCreditCard.id : null
                this.subscriptionDataLoaded = true
            }, () => {
                this.subscriptionDataLoaded = false
            }).finally(() => {
                this.loading = false
            })
        },
        reloadCreditCardList() {
            this.loading = true
            fetchSubscriptionPaymentType(this.company).then(response => {
                this.subscriptionCreditCards = response.data.hasOwnProperty('credit_cards') && response.data.credit_cards ? response.data.credit_cards : []
            }, () => {
            }).finally(() => {
                this.loading = false
            })
        },
        deleteCreditCard(chd_id) {
            this.deletedCreditCards.push(chd_id)
        }
    },
    watch: {
        refreshListRequested: {
            handler(value) {
                if (value !== null) {
                    this.reloadCreditCardList()
                }
            },
        },
    },
    created() {
        this.fetchPaymentType()
    },
}
</script>

<style scoped>

.custom-radio {
    display: block !important;
    min-height: 25px;
}

</style>
