<template>
    <b-form @submit.prevent="save">
        <b-row class="mt-3 mb-3">
            <b-col lg="6" class="mb-3">
                <label>{{$t('NAME')}}</label>
                <b-form-input v-model="filter.name"></b-form-input>
            </b-col>
            <b-col lg="6" class="mb-3">
                <app-input-control :error-object="validation.code">
                    <template v-slot:input="data">
                        <label>{{$t('CODE')}}</label>
                        <b-form-input :state="data.state" type="text" v-model="filter.code"></b-form-input>
                    </template>
                </app-input-control>
            </b-col>
            <b-col lg="6" class="mb-3">
                <app-input-control :error-object="validation.category">
                    <template v-slot:input="data">
                        <label>{{$t('CATEGORY')}}</label>
                        <app-select :options="categories"
                                    :state="data.state"
                                    v-model="filter.category"
                                    :search-empty-item="false">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{$t('VALID_THROUGH')}}</label>
                <app-date-range-picker v-model="periodValid"></app-date-range-picker>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{$t('FOR_DATES')}}</label>
                <app-date-range-picker v-model="periodUse"></app-date-range-picker>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{$t('AVAILABLE')}}</label>
                <b-form-input type="number" v-model="filter.available_count"></b-form-input>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{$t('DISCOUNT')}}</label>
                <app-input-control :error-object="validation.value">
                    <template v-slot:input="data">
                        <b-input-group>
                            <b-form-input :state="data.state" type="number" v-if="valueSelected === 1" v-model="filter.value_percentage"></b-form-input>
                            <b-form-input :state="data.state" type="number" v-else v-model="filter.value_fixed"></b-form-input>
                            <b-input-group-append>
                                <app-select :search-empty-item="false"
                                            v-model="valueSelected"
                                            :options="valueType"></app-select>
                            </b-input-group-append>
                        </b-input-group>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <app-button @click="save" :loading="loader">{{$t('SAVE')}}</app-button>
    </b-form>
</template>

<script>
import {createPromoCode, fetchPromoCategories} from "@/services/promo_codes";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import AppButton from "@/components/app/AppButton/AppButton";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppInputControl from "@/components/app/AppInputControl";
import {PROMO_CODE_CATEGORY_ID_VALIDATION_FAILED, PROMO_CODE_CODE_VALUE_VALIDATION_FAILED,
    PROMO_CODE_VALUES_REQUIRED_VALIDATION_FAILED,
    PROMO_CODE_VALUES_RANGE_VALIDATION_FAILED,
    PROMO_CODE_FIX_VALUES_RANGE_VALIDATION_FAILED} from "@/shared/error_codes";

export default {
    name: "PromoCodeNewForm",
    components:{AppSelect, AppDatePicker, AppButton, AppInputControl, AppDateRangePicker},
    mixins:[getErrorMessage],
    data(){
        return{
            loader: false,
            categories: [],
            valueType:[
                {id: 1, name: '%'},
                {id: 2, name: 'fix'}
            ],
            valueSelected: 1,
            filter:{
                company: this.$store.getters['user/getCurrentCompany'].id,
                category: null,
                code: null,
                name: null,
                available_count: null,
                value_fixed: null,
                value_percentage: null,
                valid_dates:{
                    from: null,
                    to: null
                },
                for_dates:{
                    from: null,
                    to: null
                }
            },
            validation:{
                code: PROMO_CODE_CODE_VALUE_VALIDATION_FAILED,
                category: PROMO_CODE_CATEGORY_ID_VALIDATION_FAILED,
                value: [
                    PROMO_CODE_VALUES_REQUIRED_VALIDATION_FAILED,
                    PROMO_CODE_VALUES_RANGE_VALIDATION_FAILED,
                    PROMO_CODE_FIX_VALUES_RANGE_VALIDATION_FAILED
                ],
            },
        }
    },

    computed:{
        periodUse: {
            get() {
                return {
                    start: this.filter.for_dates.from,
                    end: this.filter.for_dates.to
                }
            },
            set({start, end}) {
                this.filter.for_dates.from = start
                this.filter.for_dates.to = end
            }
        },
        periodValid: {
            get() {
                return {
                    start: this.filter.valid_dates.from,
                    end: this.filter.valid_dates.to
                }
            },
            set({start, end}) {
                this.filter.valid_dates.from = start
                this.filter.valid_dates.to = end
            }
        },
    },
    methods:{
        save(){
            this.loader = true
            const data = this.filter
            createPromoCode({...data}).then(response => {
                notifySuccess()
                this.loader = false
                this.$emit('saved', response.data)
            }, error => {
                this.loader = false
                this.showErrorMessages(error, this.validation)
            })
        },
    },
    created() {
        fetchPromoCategories().then(response => {
            this.categories = response.data
        }, error => {
            this.showErrorMessages(error)
        })
    },
    watch:{
        valueSelected:{
            handler(value){
                if (value === 1){
                    this.filter.value_fixed = null
                }else{
                    this.filter.value_percentage = null
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
