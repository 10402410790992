<template>
    <b-form @submit.prevent="save" v-if="notes.length">
        <b-tabs content-class="tab_content" pills vertical>
            <b-tab :key="index" v-for="(note, index)  in notes" v-if="checkPermission(permissionMap[note.id].v)">
                <template slot="title">
                    {{note.label}}
                </template>
                <b-form-textarea
                    no-resize
                    :id="'note_' + note.id"
                    v-model="note.content"
                    :disabled="!checkPermission(permissionMap[note.id].e)"
                    :rows="15">
                </b-form-textarea>
            </b-tab>
        </b-tabs>
        <b-row>
            <b-col class="mt-4">
                <app-submit v-if="editPermission" :loading="saving"></app-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import {fetchAccommodationReservationNotes, updateAccommodationReservationNotes} from "@/services/accommodation_reservation";
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {cloneDeep, isEqual} from 'lodash'
    import {toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {
        C_RESERVATION_ACCOMMODATION_NOTE_E,
        C_RESERVATION_ACCOMMODATION_NOTE_V,
        C_RESERVATION_ACCOMMODATION_NOTE_HOST_V,
        C_RESERVATION_ACCOMMODATION_NOTE_HOST_E,
        C_RESERVATION_ACCOMMODATION_NOTE_PRIVATE_V,
        C_RESERVATION_ACCOMMODATION_NOTE_PRIVATE_E,
        C_RESERVATION_ACCOMMODATION_COMMENT_GUEST_V,
        C_RESERVATION_ACCOMMODATION_COMMENT_GUEST_E,
        C_RESERVATION_ACCOMMODATION_COMMENT_RESERVATION_V,
        C_RESERVATION_ACCOMMODATION_COMMENT_RESERVATION_E,
    } from "@/shared/component_permission";
    import {
        RES_NOTE_TYPE_GUEST_COMMENT, RES_NOTE_TYPE_HOST,
        RES_NOTE_TYPE_NOTE,
        RES_NOTE_TYPE_PRIVATE,
        RES_NOTE_TYPE_RES_COMMENT
    } from "@/shared/constants";

    const permissionMap = {
        [RES_NOTE_TYPE_NOTE]: {
            v: C_RESERVATION_ACCOMMODATION_NOTE_V,
            e: C_RESERVATION_ACCOMMODATION_NOTE_E
        },
        [RES_NOTE_TYPE_PRIVATE]: {
            v: C_RESERVATION_ACCOMMODATION_NOTE_PRIVATE_V,
            e: C_RESERVATION_ACCOMMODATION_NOTE_PRIVATE_E
        },
        [RES_NOTE_TYPE_GUEST_COMMENT]: {
            v: C_RESERVATION_ACCOMMODATION_COMMENT_GUEST_V,
            e: C_RESERVATION_ACCOMMODATION_COMMENT_GUEST_E
        },
        [RES_NOTE_TYPE_HOST]: {
            v: C_RESERVATION_ACCOMMODATION_NOTE_HOST_V,
            e: C_RESERVATION_ACCOMMODATION_NOTE_HOST_E
        },
        [RES_NOTE_TYPE_RES_COMMENT]: {
            v: C_RESERVATION_ACCOMMODATION_COMMENT_RESERVATION_V,
            e: C_RESERVATION_ACCOMMODATION_COMMENT_RESERVATION_E
        },
        1: {v: null, e: null} // filler for unused to-be-removed note (var_comment)
    }

    export default {
        name: "Index",
        components: {AppSubmit},
        mixins: [getErrorMessage],
        props: {
            objectType: {
                type: Number,
                required: true
            },
            reservation: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                notes: [],
                initialNotes: [],
                saving: false,
                permissionMap,
                editPermission: false
            }
        },
        watch: {
            'reservation.id': {
                handler(id) {
                    fetchAccommodationReservationNotes(id)
                        .then(response => {
                            this.notes = response.data
                            this.notes.forEach((note, index, array) => {
                                if (!array[index].content) {
                                    array[index].content = ""
                                }
                            })
                            this.initialNotes = cloneDeep(this.notes)
                        })
                        .catch(() => {
                            this.notes = []
                        })
                },
                immediate: true
            }
        },
        methods: {
            save() {
                if (!isEqual(this.notes, this.initialNotes)) {
                    this.saving = true
                    updateAccommodationReservationNotes(this.reservation.id, {notes: this.notes}).then(response => {
                        this.notes = response.data
                        this.initialNotes = cloneDeep(this.notes)
                        this.$emit("reloadReservation")
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                    }).catch(error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 2000
                        })
                    }).finally(() => this.saving = false)
                } else {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NO_CHANGE'),
                        'type': 'error',
                        'timeout': 2000
                    })
                }
            }
        },
        created() {
            this.$emit('created', true)
            for (const [, value] of Object.entries(permissionMap)) {
                if (this.checkPermission(value.e)) {
                    this.editPermission = true
                    break
                }
            }
        }
    }
</script>

<style scoped>

</style>
