<template>
    <div>
        <app-table v-if="tableData.length" :items="tableData" :fields="tableFields"
                   :hover="true" :responsive="true">
            <template v-slot:select_invoice="data" v-if="selectable">
                <b-checkbox v-model="data.item.selected" @change="$emit('select')"></b-checkbox>
            </template>

            <template v-slot:reservations="data">
                <template v-if="data.item.reservations.length === 1">
                    <b-link @click="() => {reservationAsideObject={id: data.item.reservations[0].id};reservationAsideState=true}">{{data.item.reservations[0].distribution_booking_id}}
                    </b-link>
                </template>
                <template v-else-if="data.item.reservations.length > 1">
                    <b-link :to="{name: routeNames.RN_RESERVATION_ACCOMMODATION_LIST, query: {invoice: data.item.id}}" target="_blank">{{$t('SHOW_ALL')}}</b-link>
                </template>
            </template>

            <template v-slot:type="data">
                <small class="text-muted d-block">
                    {{data.item.system_invoice_type.label}}
                </small>
                {{data.item.type.label}}
            </template>

            <template v-slot:status="data">
                {{data.item.status.label}}
            </template>

            <template v-slot:dates="data">
                {{data.item.delivery_date | date}} / {{data.item.due_date | date}}
            </template>

            <template v-slot:amount="data">
                {{data.item.amount.toFixed(2)}}
            </template>
        </app-table>

        <app-aside v-model="reservationAsideState" :widths="['col-lg-12','col-sm-12', '100%']" full-width
                   header-styles="position: sticky; top: 0; z-index: 999; background-color:#fff">
            <template slot="header">
                <reservation-accommodation-header :reservation="reservationAsideObject"></reservation-accommodation-header>
            </template>
            <reservation-details :reservation-id="reservationAsideObject.id" tab="general" @update="reservationAsideObject=$event"></reservation-details>
        </app-aside>
    </div>
</template>

<script>
    import AppTable from "@/components/app/AppTable";
    import routeNames from "@/router/routeNames";
    import AppAside from "@/components/app/form/AppAside";
    import ReservationAccommodationHeader from "@/components/reservation/accommodation/ReservationAccommodationHeader";
    import ReservationDetails from "@/components/reservation/accommodation/ReservationDetails";

    export default {
        name: "PartnerInvoiceTable",
        components: {ReservationDetails, ReservationAccommodationHeader, AppTable, AppAside},
        props: {
            tableData: {
                type: Array,
                default() {
                    return []
                }
            },
            selectable: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                tableFields: [
                    {key: 'select_invoice', label: '', class: 'text-left'},
                    {key: 'id', label: this.$i18n.t('ID')},
                    {key: 'number', label: this.$i18n.t('NUMBER')},
                    {key: 'description', label: this.$i18n.t('DESCRIPTION'), class: 'w-25'},
                    {
                        key: 'reservations',
                        label: this.$t('RESERVATION_CODE')
                    },
                    {key: 'type', label: this.$i18n.t('TYPE')},
                    {key: 'status', label: this.$i18n.t('STATUS')},
                    {key: 'dates', label: this.$i18n.t('DELIVERY_DATE') + ' / ' + this.$i18n.t('DUE_DATE')},
                    {key: 'amount', label: this.$i18n.t('AMOUNT'), class: 'text-right'}
                ],
                routeNames,
                reservationAsideObject: {},
                reservationAsideState: false,
            }
        }
    }
</script>

<style scoped>

</style>
