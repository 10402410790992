<template>
    <div>
        <app-table-v2
            :selectable="checkPermission(C_RESERVATION_ACCOMMODATION_LIST_SELECTABLE)"
            class="reservation_table"
            :fields="computedFields"
            :generic-download-fields="genericDownloadFields"
            :provider="getData"
            :limit-list="[DEFAULT_PAGINATION_LIMIT, 20, 50, 100]"
            @busyToggled="toggleBusy"
            :search-event="EVENT_TABLE_SEARCH"
            :additional-sort-options="additionalSortOptions"
            :sort-desc-option="sort && sort.sortDescOption"
            :sort-by-option="sort && sort.orderByOption"
            :refresh-event="EVENT_TABLE_REFRESH"
            :deselect-event="EVENT_TABLE_DESELECT_ALL">

            <template v-slot:reservation_code="data">
                <div>
                    <small v-if="data.item.tag && checkPermission(C_RESERVATION_ACCOMMODATION_TAG_V)"
                           class="text-muted d-block">
                        <b-badge
                            variant="primary" pill
                            v-for="item in data.item.tag"
                            class="mr-1"
                            style="cursor: pointer; font-size: 90%">
                            {{ item.label }}
                            <i v-if="checkPermission(C_RESERVATION_ACCOMMODATION_TAG_E)"
                               @click="showDeleteCustomTagDialog(item)"
                               class="fa fa-times fa-fw ml-1"
                               aria-hidden="true"></i>
                        </b-badge>
                    </small>
                    {{ data.item.reservation_code }}
                </div>
            </template>

            <template v-slot:source="data">
                <template v-if="Array.prototype.includes.call(HAS_CHANNEL_DATA, data.item.distribution.id)">
                    <small class="text-muted d-block">
                        {{ data.item.distribution.name }}
                    </small>
                    {{ data.item.channel.name }}
                </template>

                <template v-else-if="!data.item.distribution.id">
                    {{ data.item.channel.name }}
                </template>
                <template v-else>
                    {{ data.item.distribution.name }}
                </template>
            </template>

            <template v-slot:unit="data">
                <small class="text-muted d-block">
                    <b-link v-if="checkPermission(R_PROPERTY_BASIC) && data.item.property.id && data.item.enabled"
                            :to="{name: routeNames.RN_PROPERTY_BASIC, params: {id: data.item.property.id}}"
                            target="_blank">
                        {{ data.item.property.name }}
                    </b-link>
                    <template v-else>
                        {{ data.item.property.name }}
                    </template>
                </small>

                <b-link v-if="checkPermission(R_PROPERTY_UNIT_BASIC) && data.item.unit.id && data.item.enabled"
                        :to="{name: routeNames.RM_PROPERTY_UNIT_BASIC, params: {id: data.item.property.id, unit_id: data.item.unit.id}}"
                        target="_blank">
                    {{ data.item.unit.name }}
                </b-link>
                <template v-else>
                    {{ data.item.unit.name }}
                </template>
            </template>

            <template v-slot:status="data">
                <small class="text-muted d-block">{{ data.item.status_updated_date | date }}
                    {{ data.item.status_updated_date | time }}</small>
                <template v-if="data.item.status.id === RES_STATUS_UNKNOWN">
                    <span class="text-danger">{{ data.item.status.name }}</span>
                    <app-button
                        v-if="checkPermission(C_RESERVATION_ACCOMMODATION_GENERAL_UNKNOWN_E)"
                        variant="link"
                        icon="edit"
                        :show_text="false"
                        @click="openResolveForm(data.item.id)">
                    </app-button>
                </template>
                <template v-else>
                    {{ data.item.status.name }}
                </template>
            </template>

            <template v-slot:date="data">
                <small class="text-muted d-block">
                    {{ data.item.num_nights }} {{
                        data.item.num_nights > 1 ? $t('NIGHTS').toLowerCase() :
                            $t('NIGHT').toLowerCase()
                    }} {{
                        data.item.check_in_time ? `| ${formatTime(data.item.check_in_time)}` :
                            ''
                    }}
                    <template v-if="data.item.transfer">
                        |
                        <b-link v-if="checkPermission(R_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES)"
                                @click="showReservationDetails(data.item, 'services')">
                            <i class="fa fa-car" style="color: #6F6EFE"></i>
                        </b-link>
                        <i v-else class="fa fa-car" style="color: #6F6EFE"></i>
                    </template>
                </small>

                <b-link
                    v-if="checkPermission(R_PROPERTY_CALENDAR_DEV) && data.item.unit.id && data.item.property.id && data.item.enabled"
                    :to="getCalendarLink(data.item)"
                    target="_blank">
                    {{ data.item.check_in_date | date(false) }} - {{ data.item.check_out_date | date }}
                </b-link>
                <template v-else>
                    {{ data.item.check_in_date | date(false) }} - {{ data.item.check_out_date | date }}
                </template>
            </template>

            <template v-slot:guest.name="data">
                <small class="text-muted d-block">
                    {{ data.item.num_guests }} {{
                        data.item.num_guests > 1 ? $t('GUESTS').toLowerCase() :
                            $t('GUEST').toLowerCase()
                    }}
                </small>

                <b-link
                    v-if="checkPermission(C_RESERVATION_ACCOMMODATION_MODULES_INFO) && data.item.unit.id"
                    @click="showInfo(data.item)">
                    {{ data.item.guest.name }}
                </b-link>
                <template v-else>
                    {{ data.item.guest.name }}
                </template>
            </template>

            <template v-slot:total="data">
                <small class="text-muted d-block" v-if="company.main_currency.id !== data.item.channel_currency.id">
                    {{ data.item.channel_total }}
                </small>
                <small class="text-muted d-block" v-else-if="company.main_currency.id !== data.item.channel_currency.id && checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_PLATFORM_FEE_E)">
                    {{ data.item.db_owner_total.channel}}
                </small>

                <b-link v-if="checkPermission(R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD) && data.item.enabled"
                        @click="showReservationDetails(data.item, 'finance')">
                    {{ data.item.company_total }}
                </b-link>
                <b-link v-else-if="checkPermission(R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD && C_RESERVATION_ACCOMMODATION_FINANCE_PLATFORM_FEE_E) && data.item.enabled"
                        @click="showReservationDetails(data.item, 'finance')">
                    {{ data.item.db_owner_total.company}}
                </b-link>
                <template v-else>
                    {{ data.item.company_total }}
                </template>
            </template>

            <template v-slot:date_booked="data">
                <small class="text-muted d-block">
                    {{ data.item.created_date | time }} {{ data.item.user.name ? `| ${data.item.user.name}` : '' }}
                </small>
                {{ data.item.created_date | date }}
            </template>

            <template v-slot:indicator="data">
                <app-indicator :indicator_type="indicatorType(data.item)"></app-indicator>
            </template>

            <template v-slot:edit="data">
                <app-button
                    v-if="checkPermission(R_RESERVATION_ACCOMMODATION_GENERAL) && data.item.enabled"
                    variant="link"
                    icon="edit"
                    :show_text="false" @click="showReservationDetails(data.item)">
                </app-button>
            </template>

            <template v-slot:selectedContainer="data">
                <app-button
                    v-if="checkPermission(C_RESERVATION_ACCOMMODATION_LIST_GROUP_FINANCE_UPDATE) && groupActionUpdateFinanceDataEnabled"
                    @click="bulkFinanceUpdateModalState = true"
                    class="mr-3">
                    {{ $t('BATCH_UPDATE_FINANCE') }}
                </app-button>
                <app-button v-if="checkPermission(C_RESERVATION_ACCOMMODATION_LIST_GROUP_MIGRATION)"
                            @click="migrationModalState = true"
                            class="mr-3">
                    {{ $t('MIGRATE') }}
                </app-button>
                <app-button v-if="checkPermission(C_RESERVATION_ACCOMMODATION_TAG_E)"
                            @click="openTagging(data.selected)"
                            class="mr-3">
                    {{ $t('TAG') }}
                </app-button>

                <app-button
                    v-if="data.selected.length === 1 && checkPermission(C_RESERVATION_ACCOMMODATION_COPY_RESERVATION)"
                    @click="copyReservation(data.selected)"
                    class="mr-3">
                    {{ $t('COPY') }}
                </app-button>
                <app-button v-if="checkPermission(C_RESERVATION_ACCOMMODATION_LIST_GROUP_EMAIL)" @click="sendModal=true"
                            class="mr-3">
                    {{ $t('SEND') }}
                </app-button>
                <app-button v-if="checkPermission(C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT)"
                            @click="exportModal=true">
                    {{ $t("EXPORT") }}
                </app-button>
                <b-modal :title="$t('SEND_RESERVATION_NOTIFICATION')" v-model="sendModal">
                    <b-checkbox :checked="false" @change="updateSelectAllSendEmail">
                        {{ $t("SELECT_ALL") }}
                    </b-checkbox>
                    <hr class="p-0 m-0 mb-1 mt-1">
                    <b-checkbox-group v-model="sendActionSelected" stacked
                                      :options="sendActionVisibleData(data.selected)"></b-checkbox-group>
                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <div v-if="data.totalRows <= maxSendLimit">
                                <b-checkbox v-model="selectAllForSend">
                                    {{ $t("APPLY_TO_ALL_NUMBER", {number: data.totalRows}) }}
                                    <span v-if="data.selected.length === 1"
                                          class="d-block text-muted font-xs">({{
                                            $t("ITEM_SELECTED", {value: data.selected.length})
                                        }})</span>
                                    <span v-if="data.selected.length > 1"
                                          class="d-block text-muted font-xs">({{
                                            $t("ITEMS_SELECTED", {value: data.selected.length})
                                        }})</span>
                                </b-checkbox>
                                <small v-if="data.totalRows > maxSendLimit" class="text-muted">
                                    {{ $t('SEND_MAX_EMAILS_WARNING', {num: maxSendLimit}) }}
                                </small>
                            </div>
                            <app-button @click="sendActionEmails(data.selected)" :loading="sendActionLoading"
                                        :disabled="sendActionSelected.length === 0 || sendActionSelected.length > maxSendLimit"
                                        class="mr-3">

                                {{ $t('SEND') }}
                            </app-button>
                        </div>
                    </template>
                </b-modal>
                <b-modal :title="$t('EXPORT')" v-model="exportModal" size="lg">
                    <template v-for="group in exportColumns">
                        <b-row>
                            <b-col md="6">
                                <h6>{{ group.text }}</h6>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="8" class="pl-3">
                                <b-checkbox
                                    v-model="selectAllGroupValue[group.value]"
                                    @change="updateSelectAllExport($event, group)"
                                    :value="true"
                                    stacked
                                    :inline="true">{{ $t('SELECT_ALL') }}
                                </b-checkbox>
                            </b-col>
                            <b-col md="4">
                                <app-select
                                    v-if="group.value === DISPLAY_SETUP_RESERVATION_GROUP_FINANCE && componentAccess.export_company_currency_options === true"
                                    v-model="exportCurrencyOptionsSelected"
                                    :options="exportCurrencyOptions" text-field="label"
                                ></app-select>
                            </b-col>
                            <b-col md="12">
                                <hr class="p-0 m-0 mb-1 mt-1">
                            </b-col>
                        </b-row>
                        <div class="three_col_checkbox_group mb-4 pl-1">
                            <b-checkbox v-for="item in group.items"
                                        :key="item.value"
                                        :value="item.value"
                                        v-model="exportActionSelected"
                                        @change="updateSelectAllGroupValue($event, group)"
                                        class="custom-checkbox mr-0"
                                        :inline="true">{{ item.text }}
                            </b-checkbox>
                        </div>
                    </template>
                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <div v-if="data.totalRows > 1 && data.totalRows > data.selected.length">
                                <b-checkbox v-model="selectAllForSend">
                                    {{ $t("APPLY_TO_ALL_NUMBER", {number: data.totalRows}) }}
                                    <span v-if="data.selected.length === 1"
                                          class="d-block text-muted font-xs">({{
                                            $t("ITEM_SELECTED", {value: data.selected.length})
                                        }})</span>
                                    <span v-if="data.selected.length > 1"
                                          class="d-block text-muted font-xs">({{
                                            $t("ITEMS_SELECTED", {value: data.selected.length})
                                        }})</span>
                                </b-checkbox>
                                <small v-if="data.totalRows > maxExportLimit" class="text-muted">
                                    {{ $t('EXPORT_MAX_LIMIT_WARNING', {num: maxExportLimit}) }}
                                </small>
                            </div>
                            <app-button @click="exportData(data)" :loading="sendActionLoading"
                                        :disabled="exportActionSelected.length === 0" class="mr-3">
                                {{ $t('EXPORT') }}
                            </app-button>
                        </div>
                    </template>
                </b-modal>
                <b-modal size="lg" :title="$t('BATCH_UPDATE_FINANCE')" v-model="bulkFinanceUpdateModalState">
                    <template>
                        <b-row v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_PAYMENT_STATUS_E)">
                            <b-col md="6" class="mb-4">
                                <label>{{ $t("PAYMENT_STATUS") }}</label>
                                <app-select v-model="bulkUpdateFinanceData.payment_status"
                                            :options="paymentStatusOptions" text-field="label"
                                            :search-empty-item="false"></app-select>
                            </b-col>
                        </b-row>

                        <b-row md="6" class="mb-4">
                            <b-col md="6"
                                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_E)">
                                <label style="white-space: nowrap">{{ $t("SALES_CHANNEL_COMMISSION") }}</label>
                                <app-input-control :error-object="validation.channel_commission">
                                    <template v-slot:input="data">
                                        <b-input-group>
                                            <b-form-input type="number" min="0" max="99.99" step="0.01"
                                                          v-model="bulkUpdateFinanceData.channel_commission.value"
                                                          :state="data.state"></b-form-input>
                                            <b-input-group-append>
                                                <app-select :search-empty-item="false"
                                                            v-model="bulkUpdateFinanceData.channel_commission.type"
                                                            :options="relationTypeOptions"
                                                            :state="data.state"></app-select>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </template>
                                </app-input-control>
                            </b-col>

                            <b-col md="6"
                                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_VAT_E)">
                                <label style="white-space: nowrap">{{ $t("VAT_CHANNEL_COMMISSION") }}</label>
                                <app-input-control :error-object="validation.channel_vat">
                                    <template v-slot:input="data">
                                        <b-input-group>
                                            <b-form-input type="number" min="0" max="99.99" step="0.01"
                                                          v-model="bulkUpdateFinanceData.channel_commission_vat.value"
                                                          :state="data.state"></b-form-input>
                                            <b-input-group-append>
                                                <app-select :search-empty-item="false"
                                                            v-model="bulkUpdateFinanceData.channel_commission_vat.type"
                                                            :options="relationTypeOptions"
                                                            :state="data.state"></app-select>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </template>
                                </app-input-control>
                            </b-col>
                        </b-row>

                        <b-row md="6" class="mb-4">
                            <b-col md="6"
                                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_E)">
                                <label style="white-space: nowrap">{{ $t("AGENCY_COMMISSION") }}</label>
                                <app-input-control :error-object="validation.agency_commission">
                                    <template v-slot:input="data">
                                        <b-input-group>
                                            <b-form-input type="number" min="0" max="99.99" step="0.01"
                                                          v-model="bulkUpdateFinanceData.agency_commission.value"
                                                          :state="data.state"></b-form-input>
                                            <b-input-group-append>
                                                <app-select :search-empty-item="false"
                                                            v-model="bulkUpdateFinanceData.agency_commission.type"
                                                            :options="relationTypeOptions"
                                                            :state="data.state"></app-select>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </template>
                                </app-input-control>
                            </b-col>

                            <b-col md="6"
                                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_FIXED_AGENCY_COMMISSION_E)">
                                <label style="white-space: nowrap">{{ $t("FIXED_AGENCY_COMMISSION") }}</label>
                                <b-input-group>
                                    <b-form-input type="number" min="0" max="99.99" step="0.01"
                                                  v-model="bulkUpdateFinanceData.agency_commission_fixed"></b-form-input>
                                </b-input-group>
                            </b-col>
                        </b-row>

                        <b-row md="6" class="mb-4">
                            <b-col md="6"
                                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_E)">
                                <label style="white-space: nowrap">{{ $t("DISTRIBUTION_COMMISSION") }}</label>
                                <app-input-control :error-object="validation.distribution_commission">
                                    <template v-slot:input="data">
                                        <b-input-group>
                                            <b-form-input type="number" min="0" max="99.99" step="0.01"
                                                          v-model="bulkUpdateFinanceData.distribution_commission.value"
                                                          :state="data.state"></b-form-input>
                                            <b-input-group-append>
                                                <app-select :search-empty-item="false"
                                                            v-model="bulkUpdateFinanceData.distribution_commission.type"
                                                            :options="relationTypeOptions"
                                                            :state="data.state"></app-select>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </template>
                                </app-input-control>
                            </b-col>

                            <b-col md="6"
                                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_VAT_E)">
                                <label style="white-space: nowrap">{{ $t("VAT_DISTRIBUTION_COMMISSION") }}</label>
                                <app-input-control :error-object="validation.distribution_commission_vat">
                                    <template v-slot:input="data">
                                        <b-input-group>
                                            <b-form-input type="number" min="0" max="99.99" step="0.01"
                                                          v-model="bulkUpdateFinanceData.distribution_commission_vat.value"
                                                          :state="data.state"></b-form-input>
                                            <b-input-group-append>
                                                <app-select :search-empty-item="false"
                                                            v-model="bulkUpdateFinanceData.distribution_commission_vat.type"
                                                            :options="relationTypeOptions"
                                                            :state="data.state"></app-select>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </template>
                                </app-input-control>
                            </b-col>
                        </b-row>

                        <b-row md="6" class="mb-4">
                            <b-col md="6"
                                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_E)">
                                <label style="white-space: nowrap">{{ $t("ADDITIONAL_COMMISSION") }}</label>
                                <app-input-control :error-object="validation.additional_commission">
                                    <template v-slot:input="data">
                                        <b-input-group>
                                            <b-form-input type="number" min="0" max="99.99" step="0.01"
                                                          v-model="bulkUpdateFinanceData.additional_commission.value"
                                                          :state="data.state"></b-form-input>
                                            <b-input-group-append>
                                                <app-select :search-empty-item="false"
                                                            v-model="bulkUpdateFinanceData.additional_commission.type"
                                                            :options="relationTypeOptions"
                                                            :state="data.state"></app-select>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </template>
                                </app-input-control>
                            </b-col>

                            <b-col md="6"
                                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_VAT_E)">
                                <label style="white-space: nowrap">{{ $t("VAT_ADDITIONAL_COMMISSION") }}</label>
                                <app-input-control :error-object="validation.additional_commission_vat">
                                    <template v-slot:input="data">
                                        <b-input-group>
                                            <b-form-input type="number" min="0" max="99.99" step="0.01"
                                                          v-model="bulkUpdateFinanceData.additional_commission_vat.value"
                                                          :state="data.state"></b-form-input>
                                            <b-input-group-append>
                                                <app-select :search-empty-item="false"
                                                            v-model="bulkUpdateFinanceData.additional_commission_vat.type"
                                                            :options="relationTypeOptions"
                                                            :state="data.state"></app-select>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </template>
                                </app-input-control>
                            </b-col>
                        </b-row>

                        <b-row md="6" class="mb-4">
                            <b-col md="6" v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_TRANSACTION_FEE_E)">
                                <label style="white-space: nowrap">{{ $t("TRANSACTION_FEE") }}</label>
                                <app-input-control :error-object="validation.bank_commission">
                                    <template v-slot:input="data">
                                        <b-input-group>
                                            <b-form-input type="number" min="0" max="99.99" step="0.01"
                                                          v-model="bulkUpdateFinanceData.bank_commission.value"
                                                          :state="data.state"></b-form-input>
                                            <b-input-group-append>
                                                <app-select :search-empty-item="false"
                                                            v-model="bulkUpdateFinanceData.bank_commission.type"
                                                            :options="relationTypeOptions"
                                                            :state="data.state"></app-select>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </template>
                                </app-input-control>
                            </b-col>
                        </b-row>

                    </template>

                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <div v-if="data.totalRows > 1 && data.totalRows > data.selected.length">
                                <b-checkbox v-model="selectAllForSend">
                                    {{ $t("APPLY_TO_ALL_NUMBER", {number: data.totalRows}) }}
                                    <span v-if="data.selected.length === 1" class="d-block text-muted font-xs">({{
                                            $t("ITEM_SELECTED", {value: data.selected.length})
                                        }})</span>
                                    <span v-if="data.selected.length > 1" class="d-block text-muted font-xs">({{
                                            $t("ITEMS_SELECTED", {value: data.selected.length})
                                        }})</span>
                                </b-checkbox>
                            </div>
                            <app-button @click="bulkUpdateFinance(data.selected)" :loading="sendActionLoading"
                                        class="mr-3">
                                {{ $t('SAVE') }}
                            </app-button>
                        </div>
                    </template>
                </b-modal>

                <b-modal :title="$t('MIGRATE')" v-model="migrationModalState">
                    {{ $t('MIGRATE_RESERVATION_LIST_MESSAGE') }}
                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <div v-if="data.totalRows > 1 && data.totalRows > data.selected.length">
                                <b-checkbox v-model="selectAllForSend">
                                    {{ $t("APPLY_TO_ALL_NUMBER", {number: data.totalRows}) }}
                                    <span v-if="data.selected.length === 1" class="d-block text-muted font-xs">({{
                                            $t("ITEM_SELECTED", {value: data.selected.length})
                                        }})</span>
                                    <span v-if="data.selected.length > 1" class="d-block text-muted font-xs">({{
                                            $t("ITEMS_SELECTED", {value: data.selected.length})
                                        }})</span>
                                </b-checkbox>
                            </div>
                            <app-button @click="migrate(data.selected)" :loading="sendActionLoading"
                                        class="mr-3">
                                {{ $t('MIGRATE') }}
                            </app-button>
                        </div>
                    </template>
                </b-modal>

            </template>

        </app-table-v2>

        <app-aside v-model="infoSidebarState">
            <template slot="header">
                <reservation-accommodation-header
                    :reservation="reservationDetailsObject">
                </reservation-accommodation-header>
            </template>

            <information-module :reservation-id="infoReservationId"></information-module>
        </app-aside>

        <app-aside v-model="resolveAsideState">
            <template slot="header">
                {{ $t('RESERVATION') }}: {{ resolveReservationId }}
            </template>
            <reservation-accommodation-resolve-form
                :reservation-id="resolveReservationId" @saved="closeResolveForm">
            </reservation-accommodation-resolve-form>
        </app-aside>

        <app-aside v-model="reservationDetailsAsideState" :widths="['col-lg-12','col-sm-12', '100%']" full-width
                   :header-classes="[]" header-styles="position: sticky; top: 0; z-index: 999; background-color:#fff">
            <template slot="header">
                <reservation-accommodation-header
                    :reservation="reservationDetailsObject"></reservation-accommodation-header>
            </template>
            <reservation-details-component
                :reservation-id="reservationDetailsObject.id"
                @saved="updateData"
                @update="refreshData"
                :tab="reservationDetailsTab">
            </reservation-details-component>
        </app-aside>

        <app-aside :widths="['col-lg-3', 'col-sm-12', '30%']" v-model="tagReservationSideBar">
            <template slot="header">
                {{ $t('TAG') }}
            </template>
            <app-tag-entity-form
                :selected-items="selectedReservations"
                :tag-types="[TAG_TYPE_RESERVATION]"
                :object-type="OBJECT_TYPE_ACCOMMODATION_RESERVATION"
                :selected-check-box="tagged"
                @saved="closeTagForm">
            </app-tag-entity-form>
        </app-aside>

        <app-confirmation-dialog :show="deleteCustomTagConfirmDialog"
                                 :delete_title="true"
                                 @confirm="deleteTagMapping()"
                                 @cancel="deleteCustomTagConfirmDialog = false">
        </app-confirmation-dialog>
    </div>
</template>

<script>
import {cloneDeep} from "lodash";
import AppSearchData from "@/components/app/AppSearchData";
import AppNoData from "@/components/app/AppNoData";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import routeNames from "@/router/routeNames";
import {
    C_RESERVATION_ACCOMMODATION_GENERAL_E,
    C_RESERVATION_ACCOMMODATION_LIST_SELECTABLE,
    C_RESERVATION_ACCOMMODATION_LIST_TOTAL_V,
    C_RESERVATION_ACCOMMODATION_MODULES_INFO,
    C_RESERVATION_ACCOMMODATION_LIST_GROUP_EMAIL,
    C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT,
    C_RESERVATION_ACCOMMODATION_GENERAL_UNKNOWN_E,
    C_RESERVATION_ACCOMMODATION_TAG_V,
    C_RESERVATION_ACCOMMODATION_TAG_E,
    C_RESERVATION_ACCOMMODATION_COPY_RESERVATION,
    C_RESERVATION_ACCOMMODATION_LIST_GROUP_MIGRATION,
    C_RESERVATION_ACCOMMODATION_LIST_GROUP_FINANCE_UPDATE,
    C_RESERVATION_ACCOMMODATION_FINANCE_PAYMENT_STATUS_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_VAT_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_FIXED_AGENCY_COMMISSION_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_VAT_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_VAT_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_TRANSACTION_FEE_E,
    C_RESERVATION_ACCOMMODATION_EXPORT_CURRENCY_OPTIONS,
    C_RESERVATION_ACCOMMODATION_FINANCE_PLATFORM_FEE_E
} from "@/shared/component_permission";
import {
    R_RESERVATION_ACCOMMODATION_GENERAL,
    R_PROPERTY_BASIC,
    R_PROPERTY_UNIT_BASIC,
    R_PROPERTY_CALENDAR_DEV,
    R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD,
    R_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES,
    R_RESERVATION_WEBSITE_EDIT
} from "@/shared/route_permission";
import AppTable from "@/components/app/AppTable";
import {
    HAS_CHANNEL_DATA,
    CONTACT_ACTION_RESERVATION_UPDATE,
    CONTACT_ACTION_RESERVATION_ARRIVAL_TIME,
    CONTACT_ACTION_RESERVATION_NEW,
    CONTACT_RECIPENT_GUEST,
    CONTACT_RECIPENT_PDF,
    DEFAULT_PAGINATION_LIMIT,
    STATUS_CONFIRMED,
    RES_STATUS_CANCELED,
    STATUS_HOLD,
    RES_STATUS_UNKNOWN_COMPLETED,
    TAG_TYPE_RESERVATION,
    OBJECT_TYPE_ACCOMMODATION_RESERVATION,
    DISPLAY_SETUP_RESERVATION,
    DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT,
    DISPLAY_SETUP_RESERVATION_GROUPS,
    DIRECT_BOOKER_COMPANY,
    DISPLAY_SETUP_RESERVATION_GROUP_FINANCE,
    CO_RECIPIENT_TYPE_USERS_WITH_UNIT_ACCESS,
    CO_RECIPIENT_TYPE_USERS_WITH_COMPANY_UNITS_ACCESS,
    IVAN_BOGOJE_2_COMPANY,
} from "@/shared/constants";
import AppTableV2 from "@/components/app/AppTableV2";
import {EventBus} from "@/shared/EventBus";
import {
    getAccommodationReservationList,
    groupNotify,
    groupExport,
    fetchAccommodationReservation, fetchReuseAccommodationReservation, setSetupBatch,
    getReservationVisibilitySetup, bulkUpdateFinance
} from "@/services/accommodation_reservation";
import InformationModule from "@/components/reservation/accommodation/modules/InformationModule";
import {WORKFLOW_DIRECT_BOOKER, FIX, PERC, OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE} from "@/shared/constants";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {toast, notifySuccess} from '@/shared/plugins/toastr'
import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import {RES_STATUS_UNKNOWN} from '@/shared/constants';
import ReservationAccommodationResolveForm
    from "@/components/reservation/accommodation/forms/ReservationAccommodationResolveForm";
import AppIndicator from "@/components/app/indicators/AppIndicator";
import ReservationDetailsComponent from '@/components/reservation/accommodation/ReservationDetails'
import AppButtonLink from "@/components/app/AppButton/AppButtonLink";
import moment from 'moment';
import ReservationAccommodationHeader from "@/components/reservation/accommodation/ReservationAccommodationHeader";
import {TableRule} from "@/mixins/Table/TableRule";
import AppTagEntityForm from "@/components/app/tag/AppTagEntityForm";
import {deleteEntityTags} from "@/services/tag";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppInputControl from "@/components/app/AppInputControl";
import {getStatusList} from "@/services/status";
import {fetchAccessControlData} from "@/services/access";
import AppValidationDescription from "@/components/app/form/AppValidationDescription";
import {AC_ACCOMMODATION_RESERVATION_LIST} from "../../../../mixins/AccessControl/AccessControlEnumeration";
import {
    AR_AGENCY_COMMISSION_TYPE_NULL, AR_AGENCY_COMMISSION_VALUE_NULL,
    AR_CHANNEL_COMMISSION_TYPE_NULL, AR_CHANNEL_COMMISSION_VALUE_NULL,
    AR_CHANNEL_COMMISSION_VAT_TYPE_NULL, AR_CHANNEL_COMMISSION_VAT_VALUE_NULL,
    AR_DISTRIBUTION_COMMISSION_TYPE_NULL, AR_DISTRIBUTION_COMMISSION_VALUE_NULL,
    AR_DISTRIBUTION_COMMISSION_VAT_TYPE_NULL, AR_DISTRIBUTION_COMMISSION_VAT_VALUE_NULL,
    AR_ADDITIONAL_COMMISSION_TYPE_NULL, AR_ADDITIONAL_COMMISSION_VALUE_NULL,
    AR_ADDITIONAL_COMMISSION_VAT_TYPE_NULL, AR_ADDITIONAL_COMMISSION_VAT_VALUE_NULL,
    AR_BANK_COMMISSION_TYPE_NULL, AR_BANK_COMMISSION_VALUE_NULL
} from "@/shared/error_codes";

export default {
    name: "AccommodationReservationTable",
    components: {
        AppConfirmationDialog,
        AppInputControl,
        AppTagEntityForm,
        ReservationAccommodationHeader,
        AppButtonLink,
        AppIndicator,
        ReservationAccommodationResolveForm,
        AppSelect,
        InformationModule,
        AppTableV2,
        AppTable,
        AppButton,
        AppNoData,
        AppSearchData,
        AppAside,
        ReservationDetailsComponent,
        AppValidationDescription,
    },
    mixins: [getErrorMessage, TableRule],
    props: {
        filter: {
            type: Object
        },
        website: {
            type: Boolean,
            default: false
        },
        sort: {
            type: Object
        }
    },
    data() {
        return {
            sendActionLoading: false,
            sendActionSelected: [],
            sendModal: false,
            exportModal: false,
            migrationModalState: false,
            bulkFinanceUpdateModalState: false,
            selectAllForSend: false,
            maxSendLimit: 20,
            maxExportLimit: 5000,
            routeNames,
            C_RESERVATION_ACCOMMODATION_LIST_SELECTABLE,
            C_RESERVATION_ACCOMMODATION_GENERAL_E,
            R_RESERVATION_ACCOMMODATION_GENERAL,
            C_RESERVATION_ACCOMMODATION_MODULES_INFO,
            RES_STATUS_UNKNOWN,
            C_RESERVATION_ACCOMMODATION_LIST_GROUP_EMAIL,
            C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT,
            C_RESERVATION_ACCOMMODATION_GENERAL_UNKNOWN_E,
            R_PROPERTY_BASIC,
            R_PROPERTY_UNIT_BASIC,
            DEFAULT_PAGINATION_LIMIT,
            R_PROPERTY_CALENDAR_DEV,
            R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD,
            R_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES,
            R_RESERVATION_WEBSITE_EDIT,
            OBJECT_TYPE_ACCOMMODATION_RESERVATION, TAG_TYPE_RESERVATION,
            C_RESERVATION_ACCOMMODATION_TAG_V, C_RESERVATION_ACCOMMODATION_TAG_E,
            C_RESERVATION_ACCOMMODATION_COPY_RESERVATION,
            C_RESERVATION_ACCOMMODATION_LIST_GROUP_MIGRATION,
            C_RESERVATION_ACCOMMODATION_LIST_GROUP_FINANCE_UPDATE,
            C_RESERVATION_ACCOMMODATION_FINANCE_PAYMENT_STATUS_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_VAT_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_FIXED_AGENCY_COMMISSION_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_VAT_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_VAT_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_TRANSACTION_FEE_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_PLATFORM_FEE_E,
            FIX,
            PERC,
            OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE,
            DIRECT_BOOKER_COMPANY,
            DISPLAY_SETUP_RESERVATION_GROUP_FINANCE,
            EVENT_TABLE_SEARCH: 'getAccommodationReservationData',
            EVENT_TABLE_REFRESH: 'refreshAccommodationReservationData',
            EVENT_TABLE_DESELECT_ALL: 'deselectAccommodationReservationData',
            tableFields: [
                {key: 'id', label: this.$i18n.t('ID')},
                {key: 'reservation_code', label: this.$i18n.t('RESERVATION_CODE')},
                {key: 'source', label: this.$i18n.t('SALES_CHANNEL'), tdClass: 'remove_padding text-nowrap'},
                {key: 'date', label: this.$i18n.t('PERIOD'), tdClass: 'text-nowrap'},
                {key: 'guest.name', label: this.$i18n.t('GUEST'), tdClass: 'w-10'},
                {key: 'status', label: this.$i18n.t('STATUS'), tdClass: 'text-nowrap'},
                {key: 'unit', label: this.$i18n.t('UNIT'), tdClass: 'w-15 remove_padding',},
                {key: 'total', label: this.$i18n.t('TOTAL'), tdClass: 'remove_padding text-right'},
                {key: 'payment_status.label', label: this.$t('PAYMENT_STATUS'), thClass: 'text-nowrap'},
                {key: 'date_booked', label: this.$i18n.t('BOOKED_AT'), tdClass: 'remove_padding text-nowrap'},
                {key: 'indicator', label: '', class: 'p-0'},
                {key: 'edit', label: "", class: 'text-right ws-no-wrap'},
            ],
            genericDownloadFields: [
                {key: 'id', label: this.$t("ID")},
                {key: 'reservation_code', label: this.$t("RESERVATION_CODE")},
                {key: 'guest.name', label: this.$t("GUEST")},
                {key: 'channel.name', label: this.$t("SALES_CHANNEL")},
                {key: 'distribution.name', label: this.$t("DISTRIBUTION")},
                {key: 'check_in_date', label: this.$t("CHECKIN")},
                {key: 'check_out_date', label: this.$t("CHECKOUT")},
                {key: 'status.name', label: this.$t("STATUS")},
                {key: 'property.name', label: this.$t("PROPERTY")},
                {key: 'unit.name', label: this.$t("UNIT")},
                {key: 'channel_total', label: this.$t("TOTAL")},
                {key: 'company_total', label: this.$t("TOTAL")},
                {key: 'created_date', label: this.$t("BOOKED_AT")},
            ],
            additionalSortOptions: [
                {key: 'check_in_from_date', label: this.$i18n.t('CHECKIN')},
                {key: 'check_out_from_date', label: this.$i18n.t('CHECKOUT')},
                {key: 'created_date', label: this.$i18n.t('BOOKED_AT')},
                {key: 'status_updated_date', label: this.$i18n.t('LAST_STATUS_CHANGE')}
            ],
            exception_fields: [
                {key: 'id', label: this.$i18n.t('ID')},
                {key: 'reservation_code', label: this.$i18n.t('RESERVATION_CODE')},
                {key: 'date', label: this.$i18n.t('PERIOD'), tdClass: 'text-nowrap'},
                {key: 'guest.name', label: this.$i18n.t('GUEST'), tdClass: 'w-10'},
                {key: 'status', label: this.$i18n.t('STATUS'), tdClass: 'text-nowrap'},
                {key: 'unit', label: this.$i18n.t('UNIT'), tdClass: 'w-15 remove_padding',},
                {key: 'total', label: this.$i18n.t('TOTAL'), tdClass: 'remove_padding text-right'},
                {key: 'payment_status.label', label: this.$t('PAYMENT_STATUS'), thClass: 'text-nowrap'},
                {key: 'date_booked', label: this.$i18n.t('BOOKED_AT'), tdClass: 'remove_padding text-nowrap'},
                {key: 'indicator', label: '', class: 'p-0'},
                {key: 'edit', label: "", class: 'text-right ws-no-wrap'},
            ],
            relationTypeOptions: [{id: PERC, name: '%'}, {id: FIX, name: 'fix'}],
            paymentStatusOptions: [],
            infoReservationId: null,
            infoSidebarState: false,
            HAS_CHANNEL_DATA,
            WORKFLOW_DIRECT_BOOKER,
            omittedFields: [],
            resolveAsideState: false,
            resolveReservationId: null,
            sendActions: [
                {
                    value: 'contact_list_reservation',
                    text: this.$t('RESERVATION_TO_CONTACT_LIST'),
                    request: {
                        action: CONTACT_ACTION_RESERVATION_UPDATE,
                        recipient_type: [CONTACT_RECIPENT_PDF],
                    }
                },
                {
                    value: 'contact_list_arrival_time',
                    text: this.$t('ARRIVAL_TIME_TO_CONTACT_LIST'),
                    request: {
                        action: CONTACT_ACTION_RESERVATION_ARRIVAL_TIME,
                        recipient_type: [
                            CONTACT_RECIPENT_PDF,
                            CO_RECIPIENT_TYPE_USERS_WITH_UNIT_ACCESS,
                            CO_RECIPIENT_TYPE_USERS_WITH_COMPANY_UNITS_ACCESS,
                        ],
                    }
                },
                {
                    value: 'guest_confirmation_mail',
                    text: this.$t('CONFIRMATION_TO_GUEST'),
                    request: {
                        action: CONTACT_ACTION_RESERVATION_NEW,
                        recipient_type: [CONTACT_RECIPENT_GUEST],
                    }
                },
            ],
            exportActions: [
                {
                    value: 'export_xls',
                    text: 'Export to excel'
                },
                {
                    value: 'export_pdf',
                    text: 'Export to pdf'
                },
            ],
            exportActionAllowed: [],
            exportActionSelected: [],
            reservationDetailsObject: {},
            reservationDetailsAsideState: false,
            reservationDetailsTab: null,
            list: [],
            tagReservationSideBar: false,
            selectedReservations: null,
            tagId: null,
            entityId: null,
            deleteCustomTagConfirmDialog: false,
            tagged: null,
            columns: [],
            selectAllGroupValue: {},
            exportCurrencyOptions:
                [
                    {
                        id: null,
                        label: this.$t('RESERVATION_EXPORT_ONLY_CHANNEL_CURRENCY')
                    },
                    {
                        id: 'show_only_company_currency',
                        label: this.$t('RESERVATION_EXPORT_ONLY_COMPANY_CURRENCY')
                    },
                    {
                        id: 'add_company_currency',
                        label: this.$t('RESERVATION_EXPORT_ADD_COMPANY_CURRENCY')
                    }
                ],
            exportCurrencyOptionsSelected: null,
            bulkUpdateFinanceData: {
                payment_status: null,
                channel_commission: {
                    type: null,
                    value: null
                },
                channel_commission_vat: {
                    type: null,
                    value: null
                },
                agency_commission: {
                    type: null,
                    value: null
                },
                agency_commission_fixed: null,
                bank_commission: {
                    type: null,
                    value: null
                },
                distribution_commission: {
                    type: null,
                    value: null
                },
                distribution_commission_vat: {
                    type: null,
                    value: null
                },
                additional_commission: {
                    type: null,
                    value: null
                },
                additional_commission_vat: {
                    type: null,
                    value: null
                }
            },
            componentAccess: {
                export_company_currency_options: false
            },
            validation: {
                agency_commission: {
                    input: [AR_AGENCY_COMMISSION_TYPE_NULL, AR_AGENCY_COMMISSION_VALUE_NULL]
                },
                channel_commission: {
                    input: [AR_CHANNEL_COMMISSION_TYPE_NULL, AR_CHANNEL_COMMISSION_VALUE_NULL]
                },
                channel_vat: {
                    input: [AR_CHANNEL_COMMISSION_VAT_TYPE_NULL, AR_CHANNEL_COMMISSION_VAT_VALUE_NULL]
                },
                distribution_commission: {
                    input: [AR_DISTRIBUTION_COMMISSION_TYPE_NULL, AR_DISTRIBUTION_COMMISSION_VALUE_NULL]
                },
                distribution_commission_vat: {
                    input: [AR_DISTRIBUTION_COMMISSION_VAT_TYPE_NULL, AR_DISTRIBUTION_COMMISSION_VAT_VALUE_NULL]
                },
                additional_commission: {
                    input: [AR_ADDITIONAL_COMMISSION_TYPE_NULL, AR_ADDITIONAL_COMMISSION_VALUE_NULL]
                },
                additional_commission_vat: {
                    input: [AR_ADDITIONAL_COMMISSION_VAT_TYPE_NULL, AR_ADDITIONAL_COMMISSION_VAT_VALUE_NULL]
                },
                bank_commission: {
                    input: [AR_BANK_COMMISSION_TYPE_NULL, AR_BANK_COMMISSION_VALUE_NULL]
                },
            },
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        computedFields() {
            return this.getFields(this.list, {
                fields: this.tableFields.filter(el => !this.omittedFields.includes(el.key)),
                exception_fields: this.exception_fields
            })
        },
        groupActionUpdateFinanceDataEnabled() {
            return !!this.company && !!this.company.id && [
                DIRECT_BOOKER_COMPANY,
                IVAN_BOGOJE_2_COMPANY,
            ].indexOf(this.company.id) >= 0
        },
        exportOptions() {
            let options_list = []
            if (this.exportCurrencyOptionsSelected) {
                options_list.push(this.exportCurrencyOptionsSelected)
            }
            return options_list
        },
        exportColumns() {
            let show_list = []
            DISPLAY_SETUP_RESERVATION.forEach((displaySetup) => {
                if (displaySetup.locations.includes(DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT) && this.exportActionAllowed.includes(displaySetup.value)) {
                    const listGroup = DISPLAY_SETUP_RESERVATION_GROUPS.find(listItem => listItem.value === displaySetup.group)
                    let listGroupIndex = show_list.findIndex(listItem => listItem.value === displaySetup.group)
                    if (listGroup && !(listGroupIndex > -1)) {
                        show_list.push({
                            value: listGroup.value,
                            text: this.$t(listGroup.text),
                            items: [],
                        })
                        listGroupIndex = show_list.findIndex(listItem => listItem.value === displaySetup.group)
                    }

                    if (listGroupIndex > -1 && show_list.hasOwnProperty(listGroupIndex)) {
                        show_list[listGroupIndex].items.push({
                            value: displaySetup.value,
                            text: this.$t(displaySetup.text),
                        })
                    }
                }
            })
            return show_list
        }
    },
    methods: {
        sendActionVisibleData(selected) {
            let visibleData = cloneDeep(this.sendActions)
            let selectedReservation = this.getActionFilterVisible(selected)
            if (selectedReservation && typeof selectedReservation === 'object' && selectedReservation.hasOwnProperty('reservation') && selectedReservation.reservation) {
                selectedReservation.reservation.forEach(item => {
                    if (item.hasOwnProperty('check_in_time') && item.check_in_time === null) {
                        const index = visibleData.findIndex(el => el.value === 'contact_list_arrival_time')
                        if (index > -1) {
                            visibleData.splice(index, 1)
                        }
                    }
                })
            }
            return visibleData
        },
        updateData(reservation) {
            this.reservationDetailsObject = reservation
            EventBus.$emit(this.EVENT_TABLE_SEARCH)
        },
        refreshData(reservation) {
            this.reservationDetailsObject = reservation
            EventBus.$emit(this.EVENT_TABLE_REFRESH)
        },
        indicatorType(item) {
            if (item.status.id === STATUS_CONFIRMED) {
                return 'indicator-primary'
            }
            if (item.status.id === RES_STATUS_CANCELED) {
                return 'indicator-res-canceled'
            }
            if (item.status.id === STATUS_HOLD) {
                return 'indicator-warning-yellow'
            }
            if (item.status.id === RES_STATUS_UNKNOWN || item.status.id === RES_STATUS_UNKNOWN_COMPLETED) {
                return 'indicator-danger'
            }
        },
        toggleBusy($event) {
            this.$emit('busyToggled', $event)
        },
        formatTime(timestamp) {
            return this.$options.filters.time(timestamp)
        },
        getData(filter) {
            let req = {
                ...this.filter,
                ...{
                    page: filter.currentPage,
                    perPage: filter.perPage,
                    order_by: filter.sortBy ? filter.sortBy : this.sort && this.sort.orderByOption ? this.sort.orderByOption : null,
                    order_by_direction: filter.sortDirection ? filter.sortDirection : this.sort && !this.sort.sortDirection ? "DESC" : null,
                    context_company: this.company.id
                    // company: this.company.id
                }
            }

            let provider = getAccommodationReservationList(req)
            provider.then(response => {
                this.list = response.data.items
            })
            return provider
        },
        showInfo(reservation) {
            this.reservationDetailsObject = reservation
            this.infoReservationId = reservation.id
            this.infoSidebarState = true
        },
        async sendActionEmails(selected) {
            this.sendActionLoading = true
            let sendActionArray = []
            let filterReq = this.getActionFilter(selected, this.maxSendLimit)

            for (let index = 0; index < this.sendActionSelected.length; index++) {
                const selectedItem = this.sendActionSelected[index]
                let requestItem = this.sendActions.find(el => {
                    return el.value === selectedItem
                })
                sendActionArray.push(requestItem.request)
            }

            await groupNotify({action_recipient_type_list: {...sendActionArray}, ...filterReq}).then(response => {
                notifySuccess()
            }, (error) => {
                this.showErrorMessages(error)
            })

            this.sendActionLoading = false
            this.sendModal = false
        },
        async exportData(data) {
            this.sendActionLoading = true
            let filterReq = this.getActionFilter(data.selected, this.maxExportLimit)

            await groupExport({
                ...filterReq, ...data.sort, ...{columns: this.exportActionSelected}, ...{options: this.exportOptions},
                website: this.website
            }, this.$t("RESERVATION_EXPORT_FILENAME", {date: moment().format('YYYY-MM-DD')})).then(response => {
                notifySuccess()
            }, (error) => {
                this.showErrorMessages(error)
            })
            this.sendActionLoading = false
            this.exportModal = false
            EventBus.$emit(this.EVENT_TABLE_DESELECT_ALL)
        },
        async migrate(selectedItems) {
            this.sendActionLoading = true
            let filterReq = this.getActionFilter(selectedItems)

            await setSetupBatch({filter: {...filterReq, website: this.website}}).then(() => {
                toast({
                    'title': this.$t('NOTIFICATIONS.BATCH_RESERVATION_MIGRATION.TITLE'),
                    'message': this.$t('NOTIFICATIONS.BATCH_RESERVATION_MIGRATION.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
            }, (error) => {
                this.showErrorMessages(error)
            })
            this.sendActionLoading = false
            this.migrationModalState = false
            EventBus.$emit(this.EVENT_TABLE_DESELECT_ALL)
        },
        async bulkUpdateFinance(selectedItems) {
            this.sendActionLoading = true
            let filterReq = this.getActionFilter(selectedItems)
            let setup = {}
            if (this.bulkUpdateFinanceData.payment_status !== null) {
                setup.payment_status = this.bulkUpdateFinanceData.payment_status
            }
            if (this.bulkUpdateFinanceData.additional_commission.type !== null || this.bulkUpdateFinanceData.additional_commission.value !== null) {
                setup.additional_commission = this.bulkUpdateFinanceData.additional_commission
            }
            if (this.bulkUpdateFinanceData.additional_commission_vat.type !== null || this.bulkUpdateFinanceData.additional_commission_vat.value !== null) {
                setup.additional_commission_vat = this.bulkUpdateFinanceData.additional_commission_vat
            }
            if (this.bulkUpdateFinanceData.agency_commission.type !== null || this.bulkUpdateFinanceData.agency_commission.value !== null) {
                setup.agency_commission = this.bulkUpdateFinanceData.agency_commission
            }
            if (this.bulkUpdateFinanceData.agency_commission_fixed !== null) {
                setup.agency_commission_fixed = this.bulkUpdateFinanceData.agency_commission_fixed
            }
            if (this.bulkUpdateFinanceData.bank_commission.type !== null || this.bulkUpdateFinanceData.bank_commission.value !== null) {
                setup.bank_commission = this.bulkUpdateFinanceData.bank_commission
            }
            if (this.bulkUpdateFinanceData.channel_commission.type !== null || this.bulkUpdateFinanceData.channel_commission.value !== null) {
                setup.channel_commission = this.bulkUpdateFinanceData.channel_commission
            }
            if (this.bulkUpdateFinanceData.channel_commission_vat.type !== null || this.bulkUpdateFinanceData.channel_commission_vat.value !== null) {
                setup.channel_commission_vat = this.bulkUpdateFinanceData.channel_commission_vat
            }
            if (this.bulkUpdateFinanceData.distribution_commission.type !== null || this.bulkUpdateFinanceData.distribution_commission.value !== null) {
                setup.distribution_commission = this.bulkUpdateFinanceData.distribution_commission
            }
            if (this.bulkUpdateFinanceData.distribution_commission_vat.type !== null || this.bulkUpdateFinanceData.distribution_commission_vat.value !== null) {
                setup.distribution_commission_vat = this.bulkUpdateFinanceData.distribution_commission_vat
            }
            if (Object.keys(setup).length === 0) {
                this.sendActionLoading = false
                toast({
                    'message': this.$t('NO_CHANGE'),
                    'type': 'error',
                    'timeout': 3000
                })
                return;
            }

            await bulkUpdateFinance({
                filter: {...filterReq, website: this.website,},
                setup: setup,
            }).then(() => {
                toast({
                    'title': this.$t('NOTIFICATIONS.BATCH_RESERVATION_MIGRATION.TITLE'),
                    'message': this.$t('NOTIFICATIONS.BATCH_RESERVATION_MIGRATION.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
                this.bulkFinanceUpdateModalState = false
                EventBus.$emit(this.EVENT_TABLE_DESELECT_ALL)
            }, error => {
                this.showErrorMessages(error, this.validation)
                }).finally( () =>{
                this.sendActionLoading = false
            })
        },
        getActionFilter(selected, limit = null) {
            if (this.selectAllForSend) {
                let filter = {...this.filter, company: this.company.id}
                if (limit !== null) {
                    filter.perPage = limit
                } else if (filter.hasOwnProperty('perPage')) {
                    delete filter.perPage
                }
                return filter
            }
            return {
                reservation: selected.map(el => {
                    return el.id
                })
            }
        },
        getActionFilterVisible(selected, limit = null) {
            if (this.selectAllForSend) {
                let filter = {...this.filter, company: this.company.id}
                if (limit !== null) {
                    filter.perPage = limit
                } else if (filter.hasOwnProperty('perPage')) {
                    delete filter.perPage
                }
                return filter
            }
            return {
                reservation: selected.map(el => {
                    return el
                })
            }
        },
        openResolveForm(id) {
            this.resolveReservationId = id
            this.resolveAsideState = true
        },
        closeResolveForm(data) {
            this.resolveReservationId = null
            this.resolveAsideState = false
            if (data) {
                EventBus.$emit(this.EVENT_TABLE_SEARCH)
            }
        },
        updateSelectAllExport($event, group) {
            if ($event) {
                const relevantItems = group.items.filter((item) => !this.exportActionSelected.includes(item.value))
                if (relevantItems.length) {
                    relevantItems.forEach((item) => {
                        this.exportActionSelected.push(item.value)
                    })
                }
            } else {
                const relevantItems = group.items.filter((item) => this.exportActionSelected.includes(item.value))
                if (relevantItems.length) {
                    relevantItems.forEach((item) => {
                        const selectedIndex = this.exportActionSelected.findIndex(el => el === item.value)
                        if (selectedIndex > -1) {
                            this.exportActionSelected.splice(selectedIndex, 1)
                        }
                    })
                }
            }
        },
        updateSelectAllGroupValue($event, group) {
            if ($event) {
                setTimeout(() => {
                    const checkedItems = group.items.filter((item) => this.exportActionSelected.includes(item.value))
                    if (group.items.length === checkedItems.length) {
                        this.$set(this.selectAllGroupValue, group.value, true)
                    } else {
                        this.$set(this.selectAllGroupValue, group.value, false)
                    }
                }, 50)
            } else {
                this.$set(this.selectAllGroupValue, group.value, false)
            }
        },
        updateSelectAllSendEmail($event) {
            if ($event) {
                this.sendActionSelected = this.sendActions.map(el => el.value)
            } else {
                this.sendActionSelected = []
            }
        },
        showReservationDetails(reservation, tab = null) {
            this.reservationDetailsTab = tab ? tab : null;
            this.reservationDetailsObject = reservation
            this.reservationDetailsAsideState = true
        },
        getCalendarLink(reservation) {
            return {
                name: routeNames.RN_PROPERTY_CALENDAR,
                params: {id: reservation.property.id},
                query: {
                    unit: reservation.unit.parent_id ? reservation.unit.parent_id : reservation.unit.id,
                    start: moment(reservation.check_in_date).subtract(15, 'days').format('YYYY-MM-DD'),
                    end: moment(reservation.check_in_date).add(15, 'days').format('YYYY-MM-DD')
                }
            }
        },
        copyReservation(data) {
            fetchReuseAccommodationReservation(data[0].id).then(response => {
                this.$emit('copyReservation', response.data)
            })
        },
        openTagging(data) {
            this.selectedReservations = data.map(el => el.id);

            let mapTagged = {}
            mapTagged[TAG_TYPE_RESERVATION] = []
            if (data.length === 1) {
                data.map(el => {
                    el.tag.map(el1 => {
                        if (!mapTagged[TAG_TYPE_RESERVATION].includes(el1.tag_id))
                            mapTagged[TAG_TYPE_RESERVATION].push(el1.id)
                    })
                });
            }
            this.tagged = mapTagged

            this.tagReservationSideBar = true
        },
        showDeleteCustomTagDialog(item) {
            this.tagId = item.tag_id
            this.entityId = item.entity_id
            this.deleteCustomTagConfirmDialog = true
        },
        deleteTagMapping() {
            deleteEntityTags(OBJECT_TYPE_ACCOMMODATION_RESERVATION, this.entityId, [this.tagId]).then((response) => {
                notifySuccess()
                EventBus.$emit(this.EVENT_TABLE_SEARCH)
                if (this.reservationDetailsObject && Object.keys(this.reservationDetailsObject).length > 0) {
                    let temp = this.reservationDetailsObject.tag
                    this.reservationDetailsObject.tag = temp.filter(el => el.tag_id !== this.tagId)
                }
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.deleteCustomTagConfirmDialog = false
            })
        },
        closeTagForm() {
            this.tagReservationSideBar = false
            EventBus.$emit(this.EVENT_TABLE_SEARCH)
        },
        setExportModalData() {
            this.exportActionSelected = DISPLAY_SETUP_RESERVATION.filter((displaySetup) => {
                return (displaySetup.locations.includes(DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT) && this.exportActionAllowed.includes(displaySetup.value))
            }).map(el => el.value)

            this.selectAllGroupValue = {}
            if (this.exportActionSelected.length) {
                this.exportColumns.forEach((group) => {
                    this.$set(this.selectAllGroupValue, group.value, true)
                })
            }
            this.exportCurrencyOptionsSelected = null
        },
        setBulkUpdateFinanceModalData() {
            this.bulkUpdateFinanceData = {
                payment_status: null,
                channel_commission: {
                    type: null,
                    value: null
                },
                channel_commission_vat: {
                    type: null,
                    value: null
                },
                agency_commission: {
                    type: null,
                    value: null
                },
                agency_commission_fixed: null,
                bank_commission: {
                    type: null,
                    value: null
                },
                distribution_commission: {
                    type: null,
                    value: null
                },
                distribution_commission_vat: {
                    type: null,
                    value: null
                },
                additional_commission: {
                    type: null,
                    value: null
                },
                additional_commission_vat: {
                    type: null,
                    value: null
                }
            }
        },
    },
    watch: {
        filter: {
            handler() {
                EventBus.$emit(this.EVENT_TABLE_SEARCH)
            },
            deep: true
        },
        '$route.query': {
            handler(queryParams) {
                const queryParamKeys = Object.keys(queryParams)
                if (queryParamKeys.length > 0) {
                    if (queryParamKeys.includes('tab') && queryParamKeys.includes('reservation')) {
                        fetchAccommodationReservation(queryParams.reservation).then(response => {
                            if (queryParams.tab === 'info') {
                                this.showInfo(response.data)
                            } else {
                                this.reservationDetailsObject = response.data
                                this.showReservationDetails({...this.reservationDetailsObject}, queryParams.tab)
                            }
                        })
                        this.$router.replace({query: {}})
                    }
                }
            },
            immediate: true
        },
        exportModal: {
            handler(value) {
                if (!value) {
                    this.setExportModalData()
                }
            }
        },
        bulkFinanceUpdateModalState: {
            handler(value) {
                if (!value) {
                    this.setBulkUpdateFinanceModalData()
                }
            }
        },
    },
    created() {
        if (!this.checkPermission(C_RESERVATION_ACCOMMODATION_LIST_TOTAL_V)) {
            this.omittedFields.push('total')
        }
        getReservationVisibilitySetup(OBJECT_TYPE_ACCOMMODATION_RESERVATION).then(response => {
            this.exportActionAllowed = response.data
            this.setExportModalData()
        }, error => this.showErrorMessages(error))
        getStatusList(OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE).then(res => {
            this.paymentStatusOptions = res.data.filter(status => status.object_type.id === OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE)
        })

        // component access
        let request = {
            context: {
                company: this.$store.getters['user/getCompany']
            },
            data: []
        }
        request.data.push({
            uid: C_RESERVATION_ACCOMMODATION_EXPORT_CURRENCY_OPTIONS,
            function: C_RESERVATION_ACCOMMODATION_EXPORT_CURRENCY_OPTIONS
        })
        fetchAccessControlData(AC_ACCOMMODATION_RESERVATION_LIST, request).then(response => {
            this.componentAccess.export_company_currency_options = response.data[C_RESERVATION_ACCOMMODATION_EXPORT_CURRENCY_OPTIONS].visible
        })
    }
}
</script>

<style scoped>
.res-no-border > div {
    border: none !important;
    padding-left: 0;
}
</style>
