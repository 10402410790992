// The Vue build version to load with the `import` command //
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './polyfill'

// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import store from './store/store'
import router from './router/index'
import Vuelidate from "vuelidate";
import i18n from './locale/index'
import moment from 'moment'
import * as Sentry from "@sentry/browser";
import { Vue as SentryVueIntegration } from "@sentry/integrations";
import VSanitize from "v-sanitize";
//font awesome 5
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHiking, faFileInvoice, faProjectDiagram,faReceipt,faSlidersH,faBalanceScale,faSitemap,faCompressArrowsAlt,faArrowsAltH, faKey,faExchangeAlt} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faHiking,faFileInvoice,faProjectDiagram,faReceipt,faBalanceScale,faSitemap,faSlidersH,faCompressArrowsAlt,faArrowsAltH,faKey,faExchangeAlt)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// todo
// cssVars()

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VSanitize)

Vue.filter('time', function (value) {
    let format = i18n.t('DATE.HOUR_MIN_FORMAT')

    if (format === null) {
        format = "HH:mm"
    }

    if (value) {
        if (typeof value === 'number') {
            return moment.unix(value).format(format)
        } else if (typeof value === 'string') {
            if ((new Date(value)).getTime() > 0) {
                return moment(value).format(format)
            } else {
                return moment(value, "HH:mm").format(format)
            }
        }
    } else {
        return value
    }
})

Vue.filter('date', function (value, year = true) {
    if (!value) return

    let format = (year) ? i18n.t('DATE.DATE_FORMAT') : i18n.t('DATE.DATE_FORMAT_SANS_YEAR')

    if (format === null) {
        format = "YYYY-MM-DD"
    }

    if(typeof value === 'number'){
        return moment.unix(value).format(format)
    }

    return moment(String(value)).format(format)
})
Vue.filter('periodDate', function (value) {
    if (!value) return
    const format =  i18n.t('DATE.DATE_FORMAT_SANS_YEAR')
    if (typeof value === 'number') {
        return moment.unix(value).format(format)
    }
    return moment(String(value),'MM/DD').format(format)
})

Vue.filter('dayString', function (value) {
    let format = "dd"

    if (value) {
        return moment(value, "HH:mm").format(format)
    }
    return value
})

Vue.filter('dayNumber', function (value) {
    let format = "DD"

    if (value) {
        return moment(value, "HH:mm").format(format)
    }
    return value
})

Vue.filter('timestamp', function (value) {
    let format = i18n.t('DATE.TIMESTAMP_FORMAT')
    if (format === null) {
        format = "YYYY-MM-DD HH:mm:ss"
    }
    return moment(String(value)).format(format)
})

Vue.mixin({
    methods: {
        checkPermission(permissionId) {
            return this.$store.getters['user/getPermission'](permissionId)
        }
    }
})

Vue.directive('visible', function (el, binding) {
    el.style.visibility = !!binding.value ? 'visible' : 'hidden';
})

Vue.filter('unixHumanize', function (value) {
    let mDate = moment.unix(value)
    if (moment().diff(mDate, 'days') > 2) {
        let format = i18n.t('DATE.TIMESTAMP_FORMAT')
        if (format === null) {
            format = "YYYY-MM-DD HH:mm:ss"
        }
        return mDate.format(format)
    }
    return moment(mDate.toArray()).fromNow()
})

Vue.filter('newLineToLineBreak', value => String(value).replace(/(?:\r\n|\r|\n)/g, '<br>'))

Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [
        new SentryVueIntegration({
            Vue,
            tracing: true,
            tracingOptions: {
                trackComponents: true,
            },
        })
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5,
});

if (process.env.NODE_ENV === 'development') {
    // https://www.vuemastery.com/blog/mock-service-worker-api-mocking-for-vuejs-development-testing/
    const handlers = require('./mocks/handlers');
    if (typeof handlers === 'object' && handlers.hasOwnProperty('default') && handlers.default && handlers.default.length > 0) {
        const {worker} = require('./mocks/browser')
        worker.start({
            onUnhandledRequest: 'bypass'
        })
    }
}


/* eslint-disable no-new */
export const app = new Vue({
    el: '#app',
    i18n,
    store,
    router,
    template: '<App/>',
    components: {
        App
    }
})
