<template>

        <vue-monthly-picker
            @input="input"
            v-model="val"
            :clearOption="true"
            :dateFormat="format"
            :monthLabels="localeMonth">
            class="month-picker"
        </vue-monthly-picker>


</template>

<script>
import VueMonthlyPicker from 'vue-monthly-picker'
import moment from "moment";
export default {
name: "AppMonthPicker",
    components:{
    VueMonthlyPicker
    },
    props:{
        value:{
            type:String
        },
        format: {
            default: 'MMMM, YYYY'
        },
    },
    data(){
    return {
        val:null
    }
    },
    computed:{
        pickerDate() {
            return this.value !== null ? new moment(this.value, 'YYYY-MM-DD').toDate() : null
        },
        localeMonth() {
            return Object.values(this.$t('DATE.MONTHS'))
        },
        previewDate() {
            if (this.format) {
                return this.getMonthName(new moment(this.val).format('M')) + ' ' +  new moment(this.val).format('Y')
            }
            return  this.val
        },

        component_input_id() {
            return 'monthpicker_' + this._uid
        },
    },
    methods:{

        input(val){
            const date = typeof val === 'object' ?  val.format('YYYY-MM') :val
            this.$emit('input', date)
        },
        getMonthName(month){
            return this.localeMonth[month-1]
        },
        setValue(value) {

            if (value) {
                this.$emit('input', new moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'))
            }

        },
    },
    watch:{
        value:{
            handler(value){

                this.val = moment(value).format('YYYY-MM')
            },
            immediate: true
        }
    }
}
</script>

<style>
.month-picker{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
}
.vue-monthly-picker .display-text{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
}
.vue-monthly-picker .input{
    box-shadow:none!important;
    border-color:  #e4e7ea!important;
}
.vue-monthly-picker .month-picker-wrapper{
    min-width: 8rem !important;
}
.vue-monthly-picker .date-popover{
    font-family: Lato, sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
}
.vue-monthly-picker .vmp-input-append {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    padding: 6px;
    display: none!important;
}
.item.active.selected {
    background-color: #319795 !important;
}
.vue-monthly-picker .prev:after{
    border-right: 10px solid  #6F6EFE !important;
}
.vue-monthly-picker .next:after {
    border-left: 10px solid #6F6EFE !important;
}
.vue-monthly-picker .selected {
    color: #fff;
    font-weight: 500;
}
.vue-monthly-picker .date-popover {


    font-size: .8rem !important;
    font-weight: 500 !important;
    font-family: 'Lato'sans-serif;
}
.vue-monthly-picker .picker .monthItem .item.active:hover {
    cursor: pointer;
    background-color: rgba(50, 145, 148, 0.1)!important;
}

element.style {
    display: block;
}
.vue-monthly-picker .date-popover {

    width: 150px !important;
    box-shadow:none!important;
    margin-top: 0.5rem !important;
}

</style>
