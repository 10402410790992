import Vue from 'vue'
import Router from 'vue-router'
import routeName from './routeNames'
import {WORKFLOW_DIRECT_BOOKER} from "@/shared/constants";

import PG_Login from '@/pages/Login'
import PG_W_Login from '@/pages/WLogin'
import PG_PropertyReset from '@/pages/Public/PropertyReset'
import Page404 from '@/pages/Page404'
import PG_Home from '@/containers/DefaultContainer'
import PG_Dashboard from '@/views/dashboard/Index'
import PG_User from '@/views/user/Index'
import PG_User_List from '@/views/user/list/Index'
import PG_Property_List from '@/views/property/list/Index'
import PG_Property from '@/views/property/Index'
import PG_Property_Overview from '@/views/property/overview/Index'
import PG_Property_Basic from '@/views/property/basic/Index'
import PG_Property_Poi from '@/views/property/poi/Index'
import PG_Property_Booking_Sites from '@/views/property/booking_sites/Index'
import PG_Property_Cancelation_Policies from '@/views/property/cancelation_policies/Index'
import PG_Property_Details from '@/views/property/details/Index'
import PG_Property_Transfers from '@/views/property/transfer/Index'

import PG_Property_Amenities from '@/views/property/amenities/Index'
import PG_Property_Descriptions from '@/views/property/descriptions/Index'
import PG_Property_Gallery from '@/views/property/gallery/Index'
import PG_New_Property_Gallery from '@/views/property/gallery/PropertyGallery'
import PG_Property_Declined_CC_Action from '@/views/property/declined_cc_actions/Index'
import PG_Property_Crm from '@/views/property/crm/Index'
import PG_Property_Owner_List from '@/views/owner/list/Index'
import PG_Property_Owner_Edit from '@/views/owner/edit/Index'
import PG_Property_Owner_Basic from '@/views/owner/edit/basic/Index'
import PG_Property_Owner_Contacts from '@/views/owner/edit/contacts/Index'
import PG_Property_Owner_Documents from '@/views/owner/edit/documents/Index'
import PG_Property_Unit_Edit from '@/views/unit/Index'
import PG_Property_Unit_Basic from '@/views/unit/basic/Index'
import PG_Property_Unit_Gallery from '@/views/unit/gallery/Index'
import PG_Property_Unit_Amenity from '@/views/unit/amenity/Index'
import PG_Property_EventTemplate from '@/views/property/event_template/Index'
import PG_Property_UserAccess from '@/views/property/user_access/Index'
import PG_GuestCheckIn from '@/views/guest/checkin/Index'
import PG_ICal from '@/views/property/ical/Index'
import PG_Property_Unit_Descriptions from '@/views/unit/description/Index'
import PG_Property_Unit_Commissions from '@/views/unit/commissions/Index'
import PG_Property_Unit_Fees from '@/views/unit/fees/Index'
import PG_Property_Unit_Damage_Deposit from '@/views/unit/damage_deposit/Index'
import PG_Property_Unit_List from '@/views/unit/list/Index'
import PG_Property_Promotions from '@/views/property/promotions/Index'
import PG_Property_OwnerRateManager from '@/views/property/owner_rate_manager/Index'
import PG_Property_Unit_Key_Pickup from '@/views/unit/key_pickup/Index'
import PG_Property_Unit_Bulk_Calendar from '@/views/unit/bulk_calendar/Index'
import PG_Property_Unit_Rate_Plans from '@/views/unit/rate_plan/Index'
import PG_Property_Channel_Manager_Distribution from '@/views/channel_manager/distribution_list/Index'
import PG_Property_Channel_Manager_Edit from '@/views/channel_manager/Index'
import PG_Property_Review from '@/views/property/review/Index'
import PG_Property_Channel_Manager_Synchronization_Log from '@/views/channel_manager/synchronization_log_2/Index'
import PG_Property_Channel_Manager_Setup from '@/views/channel_manager/setup/Index'
import PG_Property_Channel_Manager_Mapping from '@/views/channel_manager/product_management/Index'
import PG_Property_MainCalendarOpt from '@/views/property/calendar/Index'
import PG_Property_Owner_Finance from '@/views/owner/edit/finance/Index'
import PG_Property_Unit_Contingent_Units from '@/views/unit/conntigent_units/Index'
import PG_Property_Unit_Default_Restrictions from '@/views/unit/default_restrictions/Index'
import PG_Property_Default_Rate from '@/views/property/default_rates_restrictions/Index'
import PG_Company_List from '@/views/company/list/Index'
import PG_Company from '@/views/company/Index'
import PG_Company_Basic from '@/components/company/forms/CompanyBasicForm'
import PG_Company_Contacts from '@/views/company/contacts/Index'
import PG_Company_Settings from '@/views/company/settings/Index'
import PG_User_Basic from '@/components/user/UserBasicForm'
import PG_User_Account from '@/components/user/UserPasswordForm'
import PG_User_Roles from '@/components/user/UserRolesForm'
import PG_User_Notifications from '@/components/user/UserNotificationsForm'
import PG_Company_Subscription from '@/components/company/subscription/Subscription'
import PG_Company_Invoice_List from '@/views/company/invoices/Index'
import PG_Property_Contacts from '@/views/property/contacts/Index'
import PG_Register from '@/pages/Public/Register'
import PG_ResetPassword from '@/pages/Public/ResetPassword'
import PG_W_ResetPassword from '@/pages/Public/WResetPassword'
import PG_ReactivatePassword from '@/pages/Public/ReactivatePassword'
import PG_ChangePassword from '@/pages/Public/ChangePassword'
import PG_ActivateUser from '@/pages/Public/ActivateUser'
import PG_PropertySettings from '@/views/property/settings/Index'

import PG_Inbox from '@/views/inbox/Index'
import PG_Calendar from '@/views/calendar/Index'
import PG_CalendarTest from '@/views/calendar/TestIndex'
import PG_MultiUnitView from '@/views/calendar/multi_unit_view/Index'
import PG_SingleUnit from '@/views/calendar/single_unit/Index'

//user cash register setup
import PG_UserCashRegisterSetup from "@/components/user/UserCashRegisterSetupForm"
// reservation imports
import PG_Reservation from '@/views/reservation/Index'
import PG_Reservation_Accommodation_List from '@/views/reservation/accommodation/list/Index'
import PG_Reservation_Accommodation from '@/components/reservation/accommodation/ReservationDetails'
import PG_Reservation_Accommodation_General
    from '@/components/reservation/accommodation/forms/ReservationAccommodationGeneral'
import PG_Reservation_Accommodation_Guests from '@/views/reservation/accommodation/guests/Index'
import PG_Reservation_Accommodation_Notes from '@/views/reservation/accommodation/notes/Index'
import PG_Reservation_Accommodation_Invoices from '@/views/reservation/accommodation/invoices/Index'
import PG_Reservation_Accommodation_CC from '@/views/reservation/accommodation/credit_card/Index'
import PG_Reservation_Accommodation_Finance
    from '@/components/reservation/accommodation/forms/ReservationAccommodationFinanceForm'
import PG_Reservation_Accommodation_Finance_Dashboard from '@/views/reservation/accommodation/finance_dashboard/Index'
import PG_Reservation_Accommodation_Connected_Services from '@/views/reservation/accommodation/connected_services/Index'
import PG_Reservation_Accommodation_Inbox from '@/views/reservation/accommodation/inbox/Index'
import PG_Reservation_Accommodation_Scheduler from '@/components/reservation/accommodation/modules/ReservationAccommodationScheduler'

// settings imports
import PG_Setup from '@/views/settings/Index'
import PG_Setup_General from '@/views/settings/general/Index'
import PG_Setup_Finance_Vat from '@/views/settings/finance/vat/Index'
import PG_Setup_Finance_Item_Description from '@/views/settings/finance/articles/Index'
import PG_Setup_Finance_Invoice_Types from '@/views/settings/finance/invoice_types/Index'
import PG_Setup_Finance_Invoice_Creation from '@/views/settings/finance/invoice_creation/Index'
import PG_Setup_Finance_CE_Fixed from '@/views/settings/finance/fixed_exchange_rates/fixed/Index'
import PG_Setup_Finance_Partners from '@/views/settings/finance/partners/Index'

// Reservation process
import PG_Setup_Reservation_Process from '@/views/settings/reservation/distribution/Index'
import PG_Setup_Reservation_Process_Owner_Statement from '@/views/settings/reservation/owner_statement/Index'
import PG_Setup_Reservation_Process_Owner_Statement_Owner from '@/views/settings/reservation/owner_statement/owner/Index'
import PG_Setup_Reservation_Process_Owner_Statement_Company from '@/views/settings/reservation/owner_statement/company/Index'
import PG_Setup_Reservation_Process_Owner_Statement_Property from '@/views/settings/reservation/owner_statement/property/Index'

import PG_Setup_Reservation_Process_Currency from '@/views/settings/reservation/currency/Index'
import PG_Setup_Reservation_Process_Currency_Rate_Plan from '@/views/settings/reservation/currency/rate_plan/Index'
import PG_Setup_Reservation_Process_Currency_Company from '@/views/settings/reservation/currency/company/Index'
import PG_Setup_Reservation_Process_Currency_Property from '@/views/settings/reservation/currency/property/Index'

import PG_Setup_Reservation_Process_Note_Setup from '@/views/settings/reservation/note_setup/Index'
import PG_Setup_Reservation_Process_Note_Setup_Owner from '@/views/settings/reservation/note_setup/owner/Index'
import PG_Setup_Reservation_Process_Note_Setup_Company from '@/views/settings/reservation/note_setup/company/Index'
import PG_Setup_Reservation_Process_Note_Setup_Property from '@/views/settings/reservation/note_setup/property/Index'
import PG_Setup_Reservation_Process_Note_Setup_Rate_Plan from '@/views/settings/reservation/note_setup/rate_plan/Index'

import PG_Setup_Reservation_Process_Data_Transition from '@/views/settings/reservation/data_transition/Index'
import PG_Setup_Reservation_Process_Data_Transition_Owner from '@/views/settings/reservation/data_transition/owner/Index'
import PG_Setup_Reservation_Process_Data_Transition_Company from '@/views/settings/reservation/data_transition/company/Index'
import PG_Setup_Reservation_Process_Data_Transition_Property from '@/views/settings/reservation/data_transition/property/Index'


import PG_Setup_Distribution from '@/views/settings/distribution/Index'
import PG_Setup_Distribution_General from '@/views/settings/distribution/general/Index'
import PG_Setup_Distribution_Property from '@/views/settings/distribution/property/Index'
import PG_Setup_Distribution_Unit from '@/views/settings/distribution/unit/Index'
import PG_Setup_Distribution_Owner from '@/views/settings/distribution/owner/Index'
import PG_Setup_Distribution_Rate_Plan from '@/views/settings/distribution/rate_plan/Index'

// System settings fee
import PG_Setup_Fees_Platform_Fee from "@/views/settings/fees/platform_fee/Index";
import PG_Setup_Fees_Platform_Fee_Distribution from "@/views/settings/fees/platform_fee/distribution/Index";
import PG_Setup_Fees_Platform_Fee_Distribution_Property from "@/views/settings/fees/platform_fee/distribution/property/Index";
import PG_Setup_Fees_Platform_Fee_Distribution_Unit from "@/views/settings/fees/platform_fee/distribution/unit/Index";

import PG_Invoice_List from '@/views/finance/invoice/list/Index'
import PG_OwnerInvoice_List from '@/views/finance/invoice/list/OwnerIndex'
import PG_Payment_Order_List from '@/views/finance/payment_order/list/Index'
import PG_TransactionList from '@/views/finance/transaction/Index'
import PG_PayoutList from '@/views/finance/payout_report/Index'
// integrations
import PG_Integration from '@/views/integrations/Index'
import PG_Integration_CM from '@/views/integrations/channel_manager/Index'
import PG_Integration_CM_Dashboard from '@/views/integrations/channel_manager/dashboard/Index'
import PG_Integration_CM_Log from '@/views/integrations/channel_manager/log/Index'
import PG_Integration_CM_Setup from '@/views/integrations/channel_manager/setup/Index'
import PG_Integration_PG from '@/views/integrations/payment_gateway/Index'
import PG_Integration_PG_List from '@/views/integrations/payment_gateway/list/Index'

// Transfers
import PG_Transfer_List from '@/views/reservation/transfer/list/Index'

// Activities
import PG_Activity_List from '@/views/reservation/activity/list/Index'

// Setup
import PG_Setup_Whitelabel_General from '@/views/settings/whitelabel/general/Index'
import PG_Setup_Whitelabel_Booker_Tools_Appearance from '@/views/settings/whitelabel/booker_tools_appearance/Index'
import PG_Setup_Whitelabel_Guest_Backoffice_Appearance from '@/views/settings/whitelabel/guest_backoffice_appearance/Index'
import PG_Setup_System_Templates_Email_Templates from '@/views/settings/system_templates/email_templates/Index'
import PG_Setup_System_Templates_Cancellation_Policies
    from '@/views/settings/system_templates/cancellation_policies/Index'
import PG_Setup_System_Templates_Sales_Periods from '@/views/settings/system_templates/sales_periods/Index'
import PG_SetupRgisterSetup from '@/views/settings/finance/register_setup/Index'
import PG_Setup_Destination_Tag from '@/views/settings/destination_tag/Index.vue'
import PG_Setup_Destination_Tag_General from '@/views/settings/destination_tag/general/Index.vue'
import PG_Setup_Destination_Tag_Country from '@/views/settings/destination_tag/country/Index.vue'
import PG_Setup_Destination_Tag_Region from '@/views/settings/destination_tag/region/Index.vue'
import PG_Setup_Destination_Tag_City from '@/views/settings/destination_tag/city/Index.vue'
import PG_Setup_Custom_Tag from '@/views/settings/custom_tag/Index.vue'
import PG_Setup_Guest_Backoffice from '@/views/settings/guest_backoffice/Index'
import PG_Setup_Event_System from '@/views/settings/event/system/Index.vue'
import PG_Setup_Event_Property from '@/views/settings/event/property/Index.vue'
import PG_Setup_Event_Preset from '@/views/settings/event/preset/Index.vue'

import PG_Guest_List from '@/views/guest/list/Index'
import PG_Property_Booking_Engine from '@/views/property/booking_engine/Index'
import DevZone from '@/views/devzone/Index'
import DevZoneCalendar from '@/views/devzone/DevZoneCalendar'
import store from '@/store/store'
import * as r_permission from '@/shared/route_permission'

// Rate manager
import PG_Rate_Manager_Overview from '@/views/rate_manager/overview/Index'

// Payment Message
import PG_Payment_Message_List from '@/views/finance/payment_message/list/Index'
import PG_Owner_Calculation_List from '@/views/finance/owner_calculation/list/Index'

//Review

import PG_AccommodationReviewList from '@/views/reviews/accommodation/list/Index'
import PG_ActivityReviewList from '@/views/reviews/activity/list/Index'

import {
    F_INBOX,
    F_REVENUE_MANAGER,
    F_DIRECT_BOOKING,
    F_REVENUE_MANAGER_ACTIVATION,
    F_CALCULATION_MENU,
    F_DIRECT_BOOKING_MARKETPLACE, F_COMPANY_WHITELABEL,
    F_DIRECT_BOOKING_ACTIVATION
} from "@/shared/function_permission";

import PG_TransferReviewList from '@/views/reviews/transfer/list/Index'
import {AC_PROPERTY_NAVIGATION} from "@/mixins/AccessControl/AccessControlEnumeration";


//Health Monitor
import PG_Health_Monitor from '@/views/health_monitor/Index'

//Website
import PG_Direct_Booking from '@/views/direct_booking/Index'
import PG_Direct_Booking_Website from '@/views/direct_booking/website/Index'
import PG_Direct_Booking_Website_List from '@/views/direct_booking/website/list/Index'
import PG_Direct_Booking_Website_Reservation from '@/views/direct_booking/website/reservation/Index'
import PG_Direct_Booking_Setup from '@/views/direct_booking/setup/Index'
import PG_Direct_Booking_Website_General from '@/views/direct_booking/website/basic/Index'
import PG_Direct_Booking_Website_Unit_Activation from '@/views/direct_booking/website/unit_activation/Index'
import PG_Direct_Booking_Website_Integrations from '@/views/direct_booking/website/integrations/Index'
import PG_Direct_Booking_Website_Pages from "@/views/direct_booking/website/pages/list/Index";
import PG_Direct_Booking_Website_Navigation from "@/components/direct_booking/website/pages/WebsiteNavigationForm";
import PG_Direct_Booking_Website_Gallery from "@/components/direct_booking/website/gallery/WebsiteGallery";
import PG_Direct_Booking_Website_Page from "@/views/direct_booking/website/pages/details/Index";
import PG_Direct_Booking_Website_Page_General from "@/components/direct_booking/website/pages/WebsitePageGeneralForm";
import PG_Direct_Booking_Website_Page_Content from "@/components/direct_booking/website/pages/WebsitePageContentForm";
import PG_Direct_Booking_Website_Page_Overview from "@/components/direct_booking/website/pages/WebsitePageOverview";
import PG_Direct_Booking_Website_Page_Filters from "@/components/direct_booking/website/pages/WebsitePageFiltersForm";
import PG_Direct_Booking_Website_Amenities from "@/views/direct_booking/website/amenities/Index";
import PG_Direct_Booking_Website_Tags from "@/views/direct_booking/website/tag/Index";
import PG_Direct_Booking_Website_Unit_Type from "@/views/direct_booking/website/unit_type/Index.vue"
import PG_Direct_Booking_Website_Amenity_Categories from "@/views/direct_booking/website/amenities/categories/Index"
import PG_Direct_Booking_Website_Promo_Codes from "@/views/direct_booking/website/promo_codes/Index"
import PG_Direct_Booking_Website_Appearance from "@/views/direct_booking/website/appearance/Index"

//DirectBooking
import PG_Direct_Booking_Activation from '@/views/direct_booking/activation/Index'
import PG_Direct_Booking_Promo_Codes from '@/views/direct_booking/promo_codes/Index'
import PG_Direct_Booking_Promo_Codes_List from '@/views/direct_booking/promo_codes/list/Index'
import PG_Direct_Booking_Promo_Codes_Category from '@/views/direct_booking/promo_codes/category/Index'
import PG_Direct_Booking_Marketplace from '@/views/direct_booking/marketplace/Index'
import PG_Direct_Booking_Marketplace_List from '@/views/direct_booking/marketplace/list/Index'
import PG_Direct_Booking_Marketplace_General from '@/views/direct_booking/marketplace/general/Index'

//Revenue Manager
import  PG_RevenueManager from '@/views/revenue_manager/Index'
import PG_RevenueManagerActivation from '@/views/revenue_manager/activation/Index'
import PG_BookingWindowList from '@/views/revenue_manager/booking_windows/Index'

import  PG_RevenueManagerSetupPriceAction from '@/views/revenue_manager/price_action/Index'
import  PG_RevenueManagerSetupMinStayAction from '@/views/revenue_manager/min_stay_action/Index'
import  PG_RevenueManagerSetup from '@/views/revenue_manager/setup/Index'
import  PG_RevenueManagerSimilarListing from '@/views/revenue_manager/similar_listing/Index'
import  PG_RevenueManagerSimilarListingFilterList from '@/views/revenue_manager/similar_listing/filter_list/Index'
import  PG_RevenueManagerSimilarListingUnit from '@/views/revenue_manager/similar_listing/unit/Index'
import  PG_RevenueManagerPropertyList from '@/views/revenue_manager/properties/Index'

//Appearance
// import PG_Appearance from '@/views/appearance/Index'
import PG_Appearance_Color from '@/views/settings/appearance/color/Index'
import PG_Appearance_Font from '@/views/settings/appearance/font/Index'
import PG_Appearance_Color_Category from '@/views/settings/appearance/color_category/Index'


Vue.use(Router)

const router = new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    //scrollBehavior: () => ({y: 0}),
    scrollBehavior: function (to) {
        if (to.hash) {
            return {selector: to.hash, offset: {y: 100}}
        } else {
            return {y: 0}
        }
    },
    routes: [

        {path: '/login', name: routeName.RN_LOGIN, component: PG_Login},
        {path: '/w-login', name: routeName.RN_W_LOGIN, component: PG_W_Login},
        {path: '/property-status-reset/:id', name: routeName.PUBLIC_PROPERTY_RESET, component: PG_PropertyReset},

        {path: '/register', name: routeName.RN_REGISTER, component: PG_Register},
        {path: '/register/:company_id/:token', name: routeName.RN_ACTIVATE_USER, component: PG_ActivateUser},
        {path: '/password/reset', name: routeName.RN_RESET_PASSWORD, component: PG_ResetPassword},
        {path: '/password/w-reset', name: routeName.RN_W_RESET_PASSWORD, component: PG_W_ResetPassword},
        {path: '/password/expired', name: routeName.RN_CHANGE_EXPIRED_PASSWORD, component: PG_ReactivatePassword},
        {path: '/password/change/:user_id/:token', name: routeName.RN_CHANGE_PASSWORD, component: PG_ChangePassword},
        {
            path: '/',
            redirect: '/dashboard',
            name: routeName.RN_HOME,
            component: PG_Home,
            meta: {
                t: "MAIN_PG",
            },
            children: [
                {
                    path: 'dashboard',
                    name: routeName.RN_DASHBOARD,
                    component: PG_Dashboard,
                    meta: {
                        //permission_id: r_permission.R_DASHBOARD
                    },

                },
                {
                    path: 'inbox',
                    name: routeName.RN_INBOX,
                    component: PG_Inbox,
                    meta: {
                        permission_id: r_permission.R_INBOX,
                        function: F_INBOX
                    },
                },
                // {
                //     path: 'calendar-old',
                //     name: routeName.RN_CALENDAR_TEST,
                //     component: PG_Calendar,
                //     meta: {
                //         permission_id: r_permission.R_CALENDAR_NEW,
                //     },
                // },
                {
                    path: 'calendar',
                    name: routeName.RN_CALENDAR,
                    component: PG_CalendarTest,
                    meta: {
                        permission_id: r_permission.R_CALENDAR,
                    },
                    children: [
                        {
                            path:'multi-unit-view',
                            component: PG_MultiUnitView,
                            name:routeName.RN_CALENDAR_MULTI_UNIT_VIEW,
                            meta: {
                                t: "MULTI_UNIT_VIEW",
                                permission_id: r_permission.R_CALENDAR_MULTI_UNIT_VIEW,
                            },
                        },
                       {
                            path: 'single-unit',
                            component: PG_SingleUnit,
                            name: routeName.RN_CALENDAR_SINGLE_UNIT,
                            meta: {
                                t: "SINGLE_UNIT",
                                permission_id: r_permission.R_CALENDAR_YEARLY_VIEW,
                            }
                        },
                    ]
                },
                {path: 'devzone', name: 'DevZone', component: DevZone},
                {path: 'devzone-calendar_old', name: 'DevZoneCalendar', component: DevZoneCalendar},
                {
                    path: 'property',
                    name: routeName.RN_PROPERTY,
                    component: {
                        template: '<router-view></router-view>'
                    },
                    redirect: {name: routeName.RN_PROPERTY_LIST},
                    meta: {
                        t: "SIDEBAR.PROPERTY"
                    },
                    children: [
                        {
                            path: 'list',
                            name: routeName.RN_PROPERTY_LIST,
                            component: PG_Property_List,
                            meta: {
                                t: "PROPERTIES",
                                permission_id: r_permission.R_PROPERTY_LIST
                            },
                        },
                        {
                            component: PG_Property,
                            path: ':id',
                            children: [

                                {
                                    path: 'overview',
                                    name: routeName.RN_PROPERTY_OVERVIEW,
                                    component: PG_Property_Overview,
                                    meta: {
                                        t: "OVERVIEW",
                                        permission_id: r_permission.R_PROPERTY_OVERVIEW
                                    }
                                },
                                {
                                    path: 'basic',
                                    name: routeName.RN_PROPERTY_BASIC,
                                    component: PG_Property_Basic,
                                    meta: {
                                        t: "GENERAL",
                                        permission_id: r_permission.R_PROPERTY_BASIC
                                    },
                                },
                                {
                                    path: 'poi',
                                    name: routeName.RN_PROPERTY_POI,
                                    component: PG_Property_Poi,
                                    meta: {
                                        t: "POI",
                                        permission_id: r_permission.R_PROPERTY_POI
                                    },
                                },
                                {
                                    path: 'contacts',
                                    name: routeName.RN_PROPERTY_CONTACTS,
                                    component: PG_Property_Contacts,
                                    meta: {
                                        t: "CONTACTS",
                                        permission_id: r_permission.R_PROPERTY_CONTACTS

                                    },
                                },
                                {
                                    path: 'sales-channels',
                                    name: routeName.RN_PROPERTY_BOOKING_SITES,
                                    component: PG_Property_Booking_Sites,
                                    meta: {
                                        t: "SALES_CHANNELS",
                                        permission_id: r_permission.R_PROPERTY_BOOKING_SITES
                                    },
                                },
                                {
                                    path: 'policies/cancelation-policies',
                                    name: routeName.RN_PROPERTY_CANCELATION_POLICIES,
                                    component: PG_Property_Cancelation_Policies,
                                    meta: {
                                        t: "CANCELLATION_AND_PREPAYMENT",
                                        permission_id: r_permission.R_PROPERTY_CANCELATION_POLICIES
                                    },
                                },
                                {
                                    path: 'policies/other',
                                    name: routeName.RN_PROPERTY_POLICY_OTHER,
                                    component: PG_Property_Details,
                                    meta: {
                                        t: "OTHER",
                                        permission_id: r_permission.R_PROPERTY_DETAILS
                                    },
                                },
                                {
                                    path: 'transfer',
                                    name: routeName.RN_PROPERTY_TRANSFER,
                                    component: PG_Property_Transfers,
                                    meta: {
                                        t: "TRANSFERS",
                                        permission_id: r_permission.R_PROPERTY_TRANSFERS
                                    },
                                },
                                {
                                    path: 'amenities',
                                    name: routeName.RN_PROPERTY_AMENITIES,
                                    component: PG_Property_Amenities,
                                    meta: {
                                        t: "UNIT_AMENITIES",
                                        permission_id: r_permission.R_PROPERTY_AMENTIES
                                    },
                                },
                                {
                                    path: 'description',
                                    name: routeName.RN_PROPERTY_DESCRIPTIONS,
                                    component: PG_Property_Descriptions,
                                    meta: {
                                        t: "DESCRIPTIONS",
                                        permission_id: r_permission.R_PROPERTY_DESCRIPTION
                                    },
                                },
                                {
                                    path: 'gallery',
                                    name: routeName.RN_PROPERTY_GALLERY,
                                    component: PG_Property_Gallery,
                                    meta: {
                                        t: "GALLERY",
                                        permission_id: r_permission.R_PROPERTY_GALLERY
                                    },
                                },
                                {
                                    path: 'property-gallery',
                                    name: routeName.RN_NEW_PROPERTY_GALLERY,
                                    component: PG_New_Property_Gallery,
                                    meta: {
                                        t: "GALLERY",
                                        permission_id: r_permission.R_PROPERTY_GALLERY
                                    },
                                },
                                {
                                    path: 'declined-cc-actions',
                                    name: routeName.RN_PROPERTY_DECLINED_CC_ACTIONS,
                                    component: PG_Property_Declined_CC_Action,
                                    meta: {
                                        t: "DECLINED_CC_ACTIONS",
                                        permission_id: r_permission.R_PROPERTY_DECLINED_CC_ACTIONS
                                    },
                                },
                                {
                                    path: 'crm',
                                    name: routeName.RN_PROPERTY_CRM,
                                    component: PG_Property_Crm,
                                    meta: {
                                        t: "CRM",
                                        permission_id: r_permission.R_PROPERTY_CRM
                                    },
                                },
                                {
                                    path: 'owner',
                                    name: routeName.RN_PROPERTY_OWNER_LIST,
                                    component: PG_Property_Owner_List,
                                    meta: {
                                        t: "OWNERS",
                                        permission_id: r_permission.R_PROPERTY_OWNER_LIST
                                    },
                                },
                                {
                                    path: 'unit',
                                    name: routeName.RN_PROPERTY_UNIT_LIST,
                                    component: PG_Property_Unit_List,
                                    meta: {
                                        t: "UNITS",
                                        permission_id: r_permission.R_PROPERTY_UNIT_LIST
                                    },
                                },
                                {
                                    path: 'channel-manager',
                                    name: routeName.RN_PROPERTY_CHANNEL_MANAGER_DISTRIBUTION,
                                    component: PG_Property_Channel_Manager_Distribution,
                                    meta: {
                                        t: "CHANNEL_MANAGER",
                                        permission_id: r_permission.R_PROPERTY_CM
                                    }
                                },
                                {
                                    path: 'review',
                                    name: routeName.RN_PROPERTY_REVIEW,
                                    component: PG_Property_Review,
                                    meta: {
                                        t: "REVIEW",
                                        permission_id: r_permission.R_PROPERTY_REVIEW
                                    }
                                },
                                {
                                    path: 'guest-checkin',
                                    name: routeName.RN_PROPERTY_GUEST_CHECKIN,
                                    component: PG_GuestCheckIn,
                                    meta: {
                                        t: "GUEST_REGISTRATION",
                                        permission_id: r_permission.R_PROPERTY_GUEST_CHECKIN

                                    }
                                },
                                {
                                    path: 'ical',
                                    name: routeName.RN_PROPERTY_ICAL,
                                    component: PG_ICal,
                                    meta: {
                                        t: "ICAL",
                                        permission_id: r_permission.R_PROPERTY_ICAL

                                    }
                                },
                                {
                                    path: 'booking-engine',
                                    name: routeName.RN_PROPERTY_BOOKING_ENGINE,
                                    component: PG_Property_Booking_Engine,
                                    meta: {
                                        t: "BOOKING_ENGINE",
                                        permission_id: r_permission.R_PROPERTY_BE
                                    }
                                },
                                {
                                    path: 'calendar',
                                    name: routeName.RN_PROPERTY_CALENDAR,
                                    component: PG_Property_MainCalendarOpt,
                                    meta: {
                                        t: "CALENDAR",
                                        permission_id: r_permission.R_PROPERTY_CALENDAR_DEV
                                    },

                                },
                                {
                                    path: 'r-manager',
                                    name: routeName.RN_PROPERTY_OWNER_RATE_MANAGER,
                                    component: PG_Property_OwnerRateManager,
                                    meta: {
                                        t: "RATE_MANAGER",
                                        permission_id: r_permission.R_PROPERTY_OWNER_RATE_MANAGER
                                    },

                                },

                                {
                                    path: 'user-access',
                                    name: routeName.RN_PROPERTY_USER_ACCESS,
                                    component: PG_Property_UserAccess,
                                    meta: {
                                        t: "USER_ACCESS",
                                        permission_id: r_permission.R_PROPERTY_USER_ACCESS
                                    }
                                },
                                {
                                    path: 'property-settings',
                                    name: routeName.RN_PROPERTY_SETTINGS,
                                    component: PG_PropertySettings,
                                    meta: {
                                        t: "SETTINGS",
                                        permission_id: r_permission.R_PROPERTY_SETTINGS
                                    }
                                },
                                {
                                    path: 'promotions',
                                    name: routeName.RN_PROPERTY_PROMOTIONS,
                                    component: PG_Property_Promotions,
                                    meta: {
                                        t: "PROMOTIONS",
                                        permission_id: r_permission.R_PROPERTY_PROMOTIONS
                                    },

                                },
                                {
                                    path: 'event-templates',
                                    name: routeName.RN_PROPERTY_EVENT_TEMPLATES,
                                    component: PG_Property_EventTemplate,
                                    meta: {
                                        t: "AUTOMATIC_COMMUNICATION",
                                        permission_id: r_permission.R_PROPERTY_EVENT_TEMPLATES
                                    }
                                },
                                {
                                    path:'default-rates-restrictions',
                                    name: routeName.RN_PROPERTY_DEFAULT_RATES_RESTRICTIONS,
                                    component: PG_Property_Default_Rate,
                                    meta: {
                                        t: "DEFAULT_RATES_RESTRICTIONS",
                                        permission_id: r_permission.R_PROPERTY_DEFAULT_VALUE
                                    }
                                },

                            ]
                        },
                        {
                            path: ':id/unit/:unit_id',
                            redirect: 'unit/:unit_id/basic',
                            name: routeName.RN_PROPERTY_UNIT_EDIT,
                            component: PG_Property_Unit_Edit,
                            meta: {
                                t: "UNIT"
                            },
                            children: [
                                {
                                    path: 'basic',
                                    name: routeName.RM_PROPERTY_UNIT_BASIC,
                                    component: PG_Property_Unit_Basic,
                                    meta: {
                                        t: "GENERAL",
                                        permission_id: r_permission.R_PROPERTY_UNIT_BASIC
                                    }
                                },
                                {
                                    path: 'gallery',
                                    name: routeName.RM_PROPERTY_UNIT_GALLERY,
                                    component: PG_Property_Unit_Gallery,
                                    meta: {
                                        t: "GALLERY",
                                        permission_id: r_permission.R_PROPERTY_UNIT_GALLERY
                                    },
                                },
                                {
                                    path: 'amenity',
                                    name: routeName.RM_PROPERTY_UNIT_AMENITY,
                                    component: PG_Property_Unit_Amenity,
                                    meta: {
                                        t: "ROOMS",
                                        permission_id: r_permission.R_PROPERTY_UNIT_AMENITY
                                    },
                                },
                                {
                                    path: 'description',
                                    name: routeName.RM_PROPERTY_UNIT_DESCRIPTIONS,
                                    component: PG_Property_Unit_Descriptions,
                                    meta: {
                                        t: "DESCRIPTIONS",
                                        permission_id: r_permission.R_PROPERTY_UNIT_DESCRIPTIONS
                                    },
                                },
                                {
                                    path: 'commissions',
                                    name: routeName.RM_PROPERTY_UNIT_COMMISSIONS,
                                    component: PG_Property_Unit_Commissions,
                                    meta: {
                                        t: "COMMISSIONS",
                                        permission_id: r_permission.R_PROPERTY_UNIT_COMMISIONS
                                    },

                                },
                                {
                                    path: 'fee-list',
                                    name: routeName.RM_PROPERTY_UNIT_FEES,
                                    component: PG_Property_Unit_Fees,
                                    meta: {
                                        t: "FEES",
                                        permission_id: r_permission.R_PROPERTY_UNIT_FEES
                                    },

                                },
                                {
                                    path: 'damage-deposit',
                                    name: routeName.RM_PROPERTY_UNIT_DAMAGE_DEPOSIT,
                                    component: PG_Property_Unit_Damage_Deposit,
                                    meta: {
                                        t: "DMG_DEPOSIT",
                                        permission_id: r_permission.R_PROPERTY_UNIT_DAMAGE_DEPOSIT
                                    },

                                },
                                {
                                    path: 'key-pickup',
                                    name: routeName.RM_PROPERTY_UNIT_KEY_PICKUP,
                                    component: PG_Property_Unit_Key_Pickup,
                                    meta: {
                                        t: "KEY_PICKUP",
                                        permission_id: r_permission.R_PROPERTY_UNIT_KEY_PICKUP
                                    },
                                },
                                {
                                    path: 'rate-plan-list',
                                    name: routeName.RM_PROPERTY_UNIT_RATE_PLANS,
                                    component: PG_Property_Unit_Rate_Plans,
                                    meta: {
                                        t: "RATE_PLANS",
                                        permission_id: r_permission.R_PROPERTY_UNIT_RATE_PLANS
                                    },
                                },

                                {
                                    path: 'pricing',
                                    name: routeName.RM_PROPERTY_UNIT_PRICES,
                                    component: PG_Property_Unit_Bulk_Calendar,
                                    meta: {
                                        t: "CALENDAR",
                                        permission_id: r_permission.R_PROPERTY_UNIT_BULK_CALENDAR
                                    },
                                },
                                {
                                    path: 'default-restrictions',
                                    name: routeName.RM_PROPERTY_UNIT_DEFAULT_RESTRICTIONS,
                                    component: PG_Property_Unit_Default_Restrictions,
                                    meta: {
                                        t: "DEFAULT_RATES_RESTRICTIONS",
                                        permission_id: r_permission.R_PROPERTY_UNIT_DEFAULT_RESTRICTIONS
                                    },
                                },
                                {
                                    path: 'contingent-units',
                                    name: routeName.RM_PROPERTY_UNIT_CONTINGENT_LIST,
                                    component: PG_Property_Unit_Contingent_Units,
                                    meta: {
                                        t: "GROUP_UNITS",
                                        permission_id: r_permission.R_PROPERTY_UNIT_CONTINGENT_SETUP
                                    },
                                }
                            ]
                        },
                        {
                            path: ':id/owner/:owner_id',
                            name: routeName.RN_PROPERTY_OWNER_EDIT,
                            component: PG_Property_Owner_Edit,
                            meta: {
                                t: "OWNER"
                            },
                            children: [
                                {
                                    path: 'basic',
                                    name: routeName.RN_PROPERTY_OWNER_BASIC,
                                    component: PG_Property_Owner_Basic,
                                    meta: {
                                        t: "GENERAL",
                                        permission_id: r_permission.R_PROPERTY_OWNER_BASIC
                                    }
                                },
                                {
                                    path: 'contacts',
                                    name: routeName.RN_PROPERTY_OWNER_CONTACTS,
                                    component: PG_Property_Owner_Contacts,
                                    meta: {
                                        t: "CONTACTS",
                                        permission_id: r_permission.R_PROPERTY_OWNER_CONTACTS
                                    },
                                },
                                {
                                    path: 'documents',
                                    name: routeName.RN_PROPERTY_OWNER_DOCUMENTS,
                                    component: PG_Property_Owner_Documents,
                                    meta: {
                                        t: "DOCUMENTS",
                                        permission_id: r_permission.R_PROPERTY_OWNER_DOCUMENTS
                                    },
                                },
                                {
                                    path: 'finance',
                                    name: routeName.RN_PROPERTY_OWNER_FINANCE,
                                    component: PG_Property_Owner_Finance,
                                    meta: {
                                        t: 'FINANCE',
                                        permission_id: r_permission.R_PROPERTY_OWNER_USER
                                    }
                                }
                            ]
                        },
                        {
                            path: ':id/channel-manager/distribution/:distribution_id',
                            name: routeName.RN_PROPERTY_CHANNEL_MANAGER_EDIT,
                            component: PG_Property_Channel_Manager_Edit,
                            meta: {
                                t: 'DISTRIBUTION',
                                permission_id: r_permission.R_PROPERTY_CM_DISTRIBUTION
                            },
                            children: [
                                {
                                    path: 'product-management',
                                    name: routeName.RN_PROPERTY_CHANNEL_MANAGER_PRODUCT_MANAGEMENT,
                                    component: PG_Property_Channel_Manager_Mapping,
                                    meta: {
                                        t: 'PRODUCT_MANAGEMENT',
                                        permission_id: r_permission.R_PROPERTY_CM_DISTRIBUTION_MAPPING
                                    }
                                },
                                {
                                    path: 'synchronization-log',
                                    name: routeName.RN_PROPERTY_CHANNEL_MANAGER_SYNCHRONIZATION_LOG,
                                    component: PG_Property_Channel_Manager_Synchronization_Log,
                                    meta: {
                                        t: 'SYNCHRONIZATION_LOG',
                                        permission_id: r_permission.R_PROPERTY_CM_DISTRIBUTION_QUEUE
                                    }
                                },
                                {
                                    path: 'setup',
                                    name: routeName.RN_PROPERTY_CHANNEL_MANAGER_SETUP,
                                    component: PG_Property_Channel_Manager_Setup,
                                    meta: {
                                        t: 'SETUP',
                                        permission_id: r_permission.R_PROPERTY_CM_DISTRIBUTION_SETUP
                                    }
                                }
                            ],
                        },
                    ]
                },
                {
                    path: 'guest-list',
                    name: routeName.RN_GUEST_LIST,
                    component: PG_Guest_List,
                    meta: {
                        t: 'GUEST_BOOK',
                        permission_id: r_permission.R_GUEST_LIST
                    }
                },
                {
                    path: 'reservation',
                    name: routeName.RN_RESERVATION,
                    component: PG_Reservation,
                    meta: {
                        t: "RESERVATION",
                        permission_id: r_permission.R_RESERVATION
                    },
                    children: [
                        {
                            path: 'accommodation',
                            name: routeName.RN_RESERVATION_ACCOMMODATION,
                            component: {
                                template: '<router-view></router-view>'
                            },
                            redirect: {name: routeName.RN_RESERVATION_ACCOMMODATION_LIST},
                            children: [
                                {
                                    path: 'list',
                                    name: routeName.RN_RESERVATION_ACCOMMODATION_LIST,
                                    component: PG_Reservation_Accommodation_List,
                                    meta: {
                                        t: "ACCOMMODATION_RESERVATIONS",
                                        permission_id: r_permission.R_RESERVATION_ACCOMMODATION_LIST
                                    },
                                },
                                {
                                    path: ':id',
                                    name: routeName.RN_RESERVATION_ACCOMMODATION_ID,
                                    redirect: {name: routeName.RN_RESERVATION_ACCOMMODATION_GENERAL},
                                    component: PG_Reservation_Accommodation,
                                    meta: {
                                        t: "ACCOMMODATION_RESERVATION",
                                        permission_id: r_permission.R_RESERVATION_ACCOMMODATION
                                    },
                                    children: [
                                        {
                                            path: 'general',
                                            name: routeName.RN_RESERVATION_ACCOMMODATION_GENERAL,
                                            component: PG_Reservation_Accommodation_General,
                                            meta: {
                                                t: "GENERAL",
                                                permission_id: r_permission.R_RESERVATION_ACCOMMODATION_GENERAL
                                            },
                                        },
                                        {
                                            path: 'finance',
                                            name: routeName.RN_RESERVATION_ACCOMMODATION_FINANCE,
                                            component: PG_Reservation_Accommodation_Finance,
                                            meta: {
                                                t: "FINANCE",
                                                permission_id: r_permission.R_RESERVATION_ACCOMMODATION_FINANCE
                                            },
                                        },
                                        {
                                            path: 'guests',
                                            name: routeName.RN_RESERVATION_ACCOMMODATION_GUESTS,
                                            component: PG_Reservation_Accommodation_Guests,
                                            meta: {
                                                t: "GUESTS",
                                                permission_id: r_permission.R_RESERVATION_ACCOMMODATION_GUESTS
                                            },
                                        },
                                        {
                                            path: 'notes',
                                            name: routeName.RN_RESERVATION_ACCOMMODATION_NOTES,
                                            component: PG_Reservation_Accommodation_Notes,
                                            meta: {
                                                t: "NOTES",
                                                permission_id: r_permission.R_RESERVATION_ACCOMMODATION_NOTES
                                            },
                                        },
                                        {
                                            path: 'invoices',
                                            name: routeName.RN_RESERVATION_ACCOMMODATION_INVOICES,
                                            component: PG_Reservation_Accommodation_Invoices,
                                            meta: {
                                                t: "INVOICES",
                                                permission_id: r_permission.R_RESERVATION_ACCOMMODATION_INVOICES
                                            },
                                        },
                                        {
                                            path: 'credit-card',
                                            name: routeName.RN_RESERVATION_ACCOMMODATION_CC,
                                            component: PG_Reservation_Accommodation_CC,
                                            meta: {
                                                t: "CC_DATA",
                                                permission_id: r_permission.R_RESERVATION_ACCOMMODATION_CC
                                            },
                                        },
                                        {
                                            path: 'finance-dashboard',
                                            name: routeName.RN_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD,
                                            component: PG_Reservation_Accommodation_Finance_Dashboard,
                                            meta: {
                                                t: "FINANCE",
                                                permission_id: r_permission.R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD
                                            },
                                        },
                                        {
                                            path: 'connected-services',
                                            name: routeName.RN_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES,
                                            component: PG_Reservation_Accommodation_Connected_Services,
                                            meta: {
                                                t: "CONNECTED_SERVICES",
                                                permission_id: r_permission.R_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES
                                            },
                                        },
                                        {
                                            path: 'inbox',
                                            name: routeName.RN_RESERVATION_ACCOMMODATION_INBOX,
                                            component: PG_Reservation_Accommodation_Inbox,
                                            meta: {
                                                t: "INBOX",
                                                permission_id: r_permission.R_RESERVATION_ACCOMMODATION_INBOX
                                            },
                                        },
                                        {
                                            path: 'scheduler',
                                            name: routeName.RN_RESERVATION_ACCOMMODATION_SCHEDULER,
                                            component: PG_Reservation_Accommodation_Scheduler,
                                            meta: {
                                                t: "SCHEDULER",
                                                permission_id: r_permission.R_RESERVATION_ACCOMMODATION_SCHEDULER
                                            },
                                        },
                                    ]
                                }
                            ]
                        },
                        {
                            path: 'transfer',
                            name: routeName.RN_TRANSFER,
                            component: {
                                template: '<router-view></router-view>'
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: routeName.RN_RESERVATION_TRANSFER_LIST,
                                    component: PG_Transfer_List,
                                    meta: {
                                        t: "TRANSFER",
                                        permission_id: r_permission.R_RESERVATION_TRANSFER_LIST
                                    },
                                },
                            ]
                        },
                        {
                            path: 'activity',
                            name: routeName.RN_RESERVATION_ACTIVITY,
                            component: {
                                template: '<router-view></router-view>'
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: routeName.RN_RESERVATION_ACTIVITY_LIST,
                                    component: PG_Activity_List,
                                    meta: {
                                        t: "ACTIVITIES",
                                        permission_id: r_permission.R_RESERVATION_ACTIVITY_LIST
                                    },
                                },
                            ]
                        }

                    ]
                },
                {
                    path: 'system',
                    name: routeName.RN_SYSTEM,
                    component: {
                        template: "<router-view></router-view>"
                    },
                    children: [
                        {
                            path: 'company',
                            name: routeName.RN_COMPANY_ROOT,
                            component: {
                                template: "<router-view></router-view>"
                            },
                            redirect: {name: routeName.RN_COMPANY_LIST},
                            children: [
                                {
                                    path: 'list',
                                    name: routeName.RN_COMPANY_LIST,
                                    component: PG_Company_List,
                                    meta: {
                                        t: "ORGANIZATIONS",
                                        permission_id: r_permission.R_COMPANY_LIST
                                    },
                                },
                                {
                                    path: ':id',
                                    name: routeName.RN_COMPANY,
                                    redirect: {name: routeName.RN_COMPANY_BASIC},
                                    component: PG_Company,
                                    meta: {
                                        t: "COMPANY",
                                        permission_id: r_permission.R_COMPANY
                                    },
                                    children: [
                                        {
                                            path: 'basic',
                                            name: routeName.RN_COMPANY_BASIC,
                                            component: PG_Company_Basic,
                                            meta: {
                                                t: "GENERAL",
                                                permission_id: r_permission.R_COMPANY_BASIC
                                            },
                                        },
                                        {
                                            path: 'contacts',
                                            name: routeName.RN_COMPANY_CONTACTS,
                                            component: PG_Company_Contacts,
                                            meta: {
                                                t: "CONTACTS",
                                                permission_id: r_permission.R_COMPANY_CONTACTS
                                            },
                                        },
                                        {
                                            path: 'account',
                                            name: routeName.RN_COMPANY_SUBSCRIPTION,
                                            component: PG_Company_Subscription,
                                            meta: {
                                                t: "ACCOUNT",
                                                permission_id: r_permission.R_COMPANY_SUBSCRIPTION
                                            }
                                        },
                                        {
                                            path: 'invoice-list',
                                            name: routeName.RN_COMPANY_INVOICE_LIST,
                                            component: PG_Company_Invoice_List,
                                            meta: {
                                                t: "INVOICES_PAYMENT_STATUS",
                                                permission_id: r_permission.R_COMPANY_INVOICE_LIST
                                            }
                                        },
                                        {
                                            path: 'settings',
                                            name: routeName.RN_COMPANY_SETTINGS,
                                            component: PG_Company_Settings,
                                            meta: {
                                                t: "SETTINGS",
                                                permission_id: r_permission.R_COMPANY_SETTINGS,
                                            }
                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            path: 'user',
                            name: routeName.RN_USER_ROOT,
                            component: {
                                template: "<router-view></router-view>"
                            },
                            redirect: {name: routeName.RN_USER_LIST},
                            children: [
                                {
                                    path: 'list',
                                    name: routeName.RN_USER_LIST,
                                    component: PG_User_List,
                                    meta: {
                                        t: "USERS",
                                        permission_id: r_permission.R_USER_LIST
                                    },
                                },
                                {
                                    path: ':id',
                                    name: routeName.RN_USER,
                                    component: PG_User,
                                    redirect: {name: routeName.RN_USER_BASIC},
                                    meta: {
                                        t: "USER",
                                        permission_id: r_permission.R_USER
                                    },
                                    children: [
                                        {
                                            path: 'basic',
                                            name: routeName.RN_USER_BASIC,
                                            component: PG_User_Basic,
                                            meta: {
                                                t: "GENERAL",
                                                permission_id: r_permission.R_USER_BASIC
                                            }
                                        },
                                        {
                                            path: 'account',
                                            name: routeName.RN_USER_ACCOUNT,
                                            component: PG_User_Account,
                                            meta: {
                                                t: "ACCOUNT",
                                                permission_id: r_permission.R_USER_ACCOUNT
                                            }
                                        },
                                        {
                                            path: 'roles',
                                            name: routeName.RN_USER_ROLES,
                                            component: PG_User_Roles,
                                            meta: {
                                                t: "ROLES",
                                                permission_id: r_permission.R_USER_ROLES
                                            }
                                        },
                                        {
                                            path: 'notification',
                                            name: routeName.RN_USER_NOTIFICATION,
                                            component: PG_User_Notifications,
                                            meta: {
                                                t: "USERS_NOTIFICATIONS",
                                                permission_id: r_permission.R_USER_NOTIFICATIONS
                                            }
                                        },
                                        {
                                            path:'cash-register-setup',
                                            name:routeName.RN_USER_CASH_REGISTER_SETUP,
                                            component:PG_UserCashRegisterSetup,
                                            meta:{
                                                t:"CASH_REGISTER_SETUP",
                                                permission_id: r_permission.R_USER_CASH_REGISTER_SETUP
                                            }
                                        }
                                    ]
                                },
                                // {
                                //     path: 'system/health',
                                //     name: routeName.RN_HEALTH_MONITOR,
                                //     component: PG_Health_Monitor,
                                //     meta: {
                                //         t: "HEALTH_MONITOR",
                                //         permission_id: r_permission.R_HEALTH_MONITOR,
                                //     },
                                // },
                            ]
                        },
                        {
                            path: 'health',
                            name: routeName.RN_HEALTH_MONITOR_ROOT,
                            component: {
                                template: '<router-view></router-view>'
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: routeName.RN_HEALTH_MONITOR,
                                    component: PG_Health_Monitor,
                                    meta: {
                                        t: "HEALTH_MONITOR",
                                        permission_id: r_permission.R_HEALTH_MONITOR
                                    },
                                },
                            ]
                        },
                    ]
                },
                {
                    path: 'system/settings',
                    name: routeName.RN_SETUP,
                    component: PG_Setup,
                    redirect: {name: routeName.RN_SETUP_GENERAL},
                    meta: {
                        t: "SETTINGS",
                        permission_id: r_permission.R_SETUP
                    },
                    children: [
                        {
                            path: 'general',
                            name: routeName.RN_SETUP_GENERAL,
                            component: PG_Setup_General,
                            meta: {
                                t: "GENERAL",
                                permission_id: r_permission.R_SETUP_GENERAL
                            }
                        },
                        {
                            path: 'finance/invoice-creation-setup',
                            name: routeName.RN_SETUP_FINANCE_INVOICE_CREATION,
                            component: PG_Setup_Finance_Invoice_Creation,
                            meta: {
                                t: "INVOICE_CREATION_SETUP",
                                permission_id: r_permission.R_SETUP_FINANCE_INVOICE_CREATION
                            }
                        },
                        {
                            path: 'finance/vat-setup',
                            name: routeName.RN_SETUP_FINANCE_VAT,
                            component: PG_Setup_Finance_Vat,
                            meta: {
                                t: "VAT_SETUP",
                                permission_id: r_permission.R_SETUP_FINANCE_VAT
                            }
                        },
                        {
                            path: 'finance/item-description-setup',
                            name: routeName.RN_SETUP_FINANCE_ITEM_DESCRIPTION,
                            component: PG_Setup_Finance_Item_Description,
                            meta: {
                                t: "ITEM_DESCRIPTION_SETUP",
                                permission_id: r_permission.R_SETUP_FINANCE_ITEM_DESCRIPTION
                            }
                        },
                        {
                            path: 'finance/invoice-type-setup',
                            name: routeName.RN_SETUP_FINANCE_INVOICE_TYPES,
                            component: PG_Setup_Finance_Invoice_Types,
                            meta: {
                                t: "INVOICE_TYPE_SETUP",
                                permission_id: r_permission.R_SETUP_FINANCE_INVOICE_TYPES
                            }
                        },
                        {
                            path: 'finance/fixed-exchange-rates',
                            name: routeName.RN_SETUP_FINANCE_CE_FIXED,
                            component: PG_Setup_Finance_CE_Fixed,
                            meta: {
                                t: "FIXED_EXCHANGE_RATES",
                                permission_id: r_permission.R_SETUP_FINANCE_CE_FIXED
                            }
                        },
                        {
                            path: 'finance/partners',
                            name: routeName.RN_SETUP_FINANCE_PARTNERS,
                            component: PG_Setup_Finance_Partners,
                            meta: {
                                t: "CLIENTS",
                                permission_id: r_permission.R_SETUP_FINANCE_PARTNERS
                            }
                        },
                        {
                            path: 'finance/register-setup',
                            name: routeName.RN_SETUP_REGISTER_SETUP,
                            component: PG_SetupRgisterSetup,
                            meta: {
                                t: "FINANCE",
                                permission_id: r_permission.R_SETUP_REGISTER_SETUP
                            }
                        },
                        // {
                        //     path: 'system-templates/email-templates',
                        //     name: routeName.RN_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES,
                        //     component: PG_Setup_System_Templates_Email_Templates,
                        //     meta: {
                        //         t: "EMAIL_TEMPLATES",
                        //         permission_id: r_permission.R_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES
                        //     }
                        // },
                        {
                            path: 'system-templates/automatic-communication',
                            name: routeName.RN_SETUP_SYSTEM_EVENTS_PRESET,
                            component: PG_Setup_Event_Preset,
                            meta: {
                                t: "AUTOMATIC_COMMUNICATION",
                                permission_id: r_permission.R_SETUP_SYSTEM_EVENTS_PRESET
                            }
                        },
                        {
                            path: 'automatic-communication-options',
                            name: routeName.RN_SETUP_EVENTS,
                            redirect: {name: routeName.RN_SETUP_EVENTS_SYSTEM},
                            component: {
                                template: "<router-view></router-view>"
                            },
                            meta: {
                                t: "AUTOMATIC_COMMUNICATION",
                                permission_id: r_permission.R_SETUP_SYSTEM_EVENTS
                            },
                            children: [
                                {
                                    path: 'system',
                                    name: routeName.RN_SETUP_EVENTS_SYSTEM,
                                    component: PG_Setup_Event_System,
                                    meta: {
                                        t: "ORGANIZATION",
                                        permission_id: r_permission.R_SETUP_SYSTEM_EVENTS_SYSTEM
                                    }
                                },
                                {
                                    path: 'property',
                                    name: routeName.RN_SETUP_SYSTEM_EVENTS_PROPERTY_OVERVIEW,
                                    component: PG_Setup_Event_Property,
                                    meta: {
                                        t: "PROPERTIES",
                                        permission_id: r_permission.R_SETUP_SYSTEM_EVENTS_PROPERTY_OVERVIEW
                                    }
                                },
                            ]
                        },
                        {
                            path: 'system-templates/cancellation-policies',
                            name: routeName.RN_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICIES,
                            component: PG_Setup_System_Templates_Cancellation_Policies,
                            meta: {
                                t: "CANCELLATION_POLICIES",
                                permission_id: r_permission.R_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICIES
                            }
                        },
                        {
                            path: 'system-templates/sales-periods',
                            name: routeName.RN_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS,
                            component: PG_Setup_System_Templates_Sales_Periods,
                            meta: {
                                t: "SALES_PERIODS",
                                permission_id: r_permission.R_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS
                            }
                        },
                        {
                            path: 'whitelabel',
                            name: routeName.RN_SETUP_WHITELABEL,
                            component: {template: "<router-view></router-view>"},
                            meta: {
                                t: "WHITELABEL",
                                permission_id: r_permission.R_COMPANY_WHITELABEL,
                            },
                            children:[
                                {
                                    path: 'general',
                                    name: routeName.RN_SYSTEM_WHITELABEL_GENERAL,
                                    component: PG_Setup_Whitelabel_General,
                                    meta: {
                                        t: "GENERAL",
                                         permission_id: r_permission.R_SETUP_WHITELABEL_GENERAL
                                    }
                                },
                                {
                                    path: 'booker-tools-appearance',
                                    name: routeName.RN_SYSTEM_WHITELABEL_BOOKER_TOOLS_APPEARANCE,
                                    component: PG_Setup_Whitelabel_Booker_Tools_Appearance,
                                    meta: {
                                        t: "BOOKER_TOOLS_APPEARANCE",
                                         permission_id: r_permission.R_SETUP_WHITELABEL_BOOKER_TOOLS
                                    }
                                },
                                {
                                    path: 'guest-backoffice-appearance',
                                    name: routeName.RN_SYSTEM_WHITELABEL_GUEST_BACKOFFICE_APPEARANCE,
                                    component: PG_Setup_Whitelabel_Guest_Backoffice_Appearance,
                                    meta: {
                                        t: "GUEST_BACKOFFICE_APPEARANCE",
                                        permission_id: r_permission.R_SETUP_WHITELABEL_GUEST_BACKOFFICE
                                    }
                                }

                            ]
                        },
                        {
                            path: 'color',
                            name: routeName.RN_SYSTEM_APPEARANCE_COLORS,
                            component: PG_Appearance_Color,
                            meta:{
                                t: "COLOR",
                                permission_id: r_permission.R_APPEARANCE
                            }
                        },
                        {
                            path: 'font',
                            name: routeName.RN_SYSTEM_APPEARANCE_FONTS,
                            component: PG_Appearance_Font,
                            meta: {
                                t: "FONT",
                                permission_id: r_permission.R_APPEARANCE
                            }
                        },
                        {
                            path: 'color-category',
                            name: routeName.RN_SYSTEM_APPEARANCE_COLOR_CATEGORY,
                            component: PG_Appearance_Color_Category,
                            meta:{
                                t: 'COLOR_CATEGORIES',
                                permission_id: r_permission.R_APPEARANCE
                            }
                        },
                        {
                            path: 'custom-tag',
                            name: routeName.RN_SETUP_CUSTOM_TAG,
                            component: PG_Setup_Custom_Tag,
                            meta: {
                                t: "TAGS",
                                permission_id: r_permission.R_SETUP_TAGS_CUSTOM
                            }
                        },
                        {
                            path: 'reservation-process',
                            name: routeName.RN_SYSTEM_RESERVATION_PROCESS,
                            component: PG_Setup_Reservation_Process,
                            meta:{
                                t: 'RESERVATION_PROCESS',
                                permission_id: r_permission.R_RESERVATION_PROCESS
                            }
                        },
                        {
                            path: 'guest-backoffice',
                            name: routeName.RN_SYSTEM_GUEST_BACKOFFICE,
                            component: PG_Setup_Guest_Backoffice,
                            meta: {
                                t: "GUEST_BACKOFFICE",
                                permission_id: r_permission.R_SETUP_GUEST_BACKOFFICE
                            }
                        },
                        {
                            path: 'fee',
                            name: routeName.RN_SETUP_FEES,
                            component: {template: "<router-view></router-view>"},
                            meta: {
                                t: "FEES",
                                permission_id: r_permission.R_SETUP_FEES,
                            },
                            children: [
                                {
                                    path: 'platform-fee',
                                    name: routeName.RN_SETUP_FEES_PLATFORM_FEE,
                                    component: PG_Setup_Fees_Platform_Fee,
                                    meta: {
                                        t: "BOOKING_FEE",
                                        permission_id: r_permission.R_SETUP_FEES_PLATFORM_FEE,
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'system/settings/reservation/owner-statement',
                    name: routeName.RN_SYSTEM_RESERVATION_OWNER_STATEMENT,
                    component: PG_Setup_Reservation_Process_Owner_Statement,
                    meta:{
                        t: 'OWNER_STATEMENT_SETUP',
                        permission_id: r_permission.R_RESERVATION_PROCESS_OWNER_STATEMENT
                    },
                    children: [
                        {
                            path: 'owner',
                            name: routeName.RN_SYSTEM_RESERVATION_OWNER_STATEMENT_OWNER,
                            component: PG_Setup_Reservation_Process_Owner_Statement_Owner,
                            meta:{
                                t: 'OWNERS',
                                permission_id: r_permission.R_RESERVATION_PROCESS_OWNER_STATEMENT_OWNER
                            }
                        },
                        {
                            path: 'company',
                            name: routeName.RN_SYSTEM_RESERVATION_OWNER_STATEMENT_COMPANY,
                            component: PG_Setup_Reservation_Process_Owner_Statement_Company,
                            meta:{
                                t: 'ORGANIZATION',
                                permission_id: r_permission.R_RESERVATION_PROCESS_OWNER_STATEMENT_COMPANY
                            }
                        },
                        {
                            path: 'property',
                            name: routeName.RN_SYSTEM_RESERVATION_OWNER_STATEMENT_PROPERTY,
                            component: PG_Setup_Reservation_Process_Owner_Statement_Property,
                            meta:{
                                t: 'PROPERTIES',
                                permission_id: r_permission.R_RESERVATION_PROCESS_OWNER_STATEMENT_PROPERTY
                            }
                        },
                    ]
                },
                {
                    path: 'system/settings/reservation/currency',
                    name: routeName.RN_SYSTEM_RESERVATION_CURRENCY,
                    component: PG_Setup_Reservation_Process_Currency,
                    meta:{
                        t: 'CURRENCY_SOURCE_SETUP',
                        permission_id: r_permission.R_RESERVATION_PROCESS_CURRENCY
                    },
                    children: [
                        {
                            path: 'rate-plan',
                            name: routeName.RN_SYSTEM_RESERVATION_CURRENCY_RATE_PLAN,
                            component: PG_Setup_Reservation_Process_Currency_Rate_Plan,
                            meta:{
                                t: 'RATE_PLANS',
                                permission_id: r_permission.R_RESERVATION_PROCESS_CURRENCY_RATE_PLAN
                            }
                        },
                        {
                            path: 'company',
                            name: routeName.RN_SYSTEM_RESERVATION_CURRENCY_COMPANY,
                            component: PG_Setup_Reservation_Process_Currency_Company,
                            meta:{
                                t: 'ORGANIZATION',
                                permission_id: r_permission.R_RESERVATION_PROCESS_CURRENCY_COMPANY
                            }
                        },
                        {
                            path: 'property',
                            name: routeName.RN_SYSTEM_RESERVATION_CURRENCY_PROPERTY,
                            component: PG_Setup_Reservation_Process_Currency_Property,
                            meta:{
                                t: 'PROPERTIES',
                                permission_id: r_permission.R_RESERVATION_PROCESS_CURRENCY_PROPERTY
                            }
                        },
                    ]
                },
                {
                    path: 'system/settings/reservation/note-setup',
                    name: routeName.RN_SYSTEM_RESERVATION_PROCESS_NOTE_SETUP,
                    component: PG_Setup_Reservation_Process_Note_Setup,
                    meta:{
                        t: 'RESERVATION_NOTE_SETUP',
                        permission_id: r_permission.R_RESERVATION_PROCESS_NOTE_SETUP
                    },
                    children: [
                        {
                            path: 'owner',
                            name: routeName.RN_SYSTEM_RESERVATION_PROCESS_NOTE_SETUP_OWNER,
                            component: PG_Setup_Reservation_Process_Note_Setup_Owner,
                            meta:{
                                t: 'OWNERS',
                                permission_id: r_permission.R_RESERVATION_PROCESS_NOTE_SETUP_OWNER
                            }
                        },
                        {
                            path: 'company',
                            name: routeName.RN_SYSTEM_RESERVATION_PROCESS_NOTE_SETUP_COMPANY,
                            component: PG_Setup_Reservation_Process_Note_Setup_Company,
                            meta:{
                                t: 'ORGANIZATION',
                                permission_id: r_permission.R_RESERVATION_PROCESS_NOTE_SETUP_COMPANY
                            }
                        },
                        {
                            path: 'property',
                            name: routeName.RN_SYSTEM_RESERVATION_PROCESS_NOTE_SETUP_PROPERTY,
                            component: PG_Setup_Reservation_Process_Note_Setup_Property,
                            meta:{
                                t: 'PROPERTIES',
                                permission_id: r_permission.R_RESERVATION_PROCESS_NOTE_SETUP_PROPERTY
                            }
                        },
                        {
                            path: 'rate-plan',
                            name: routeName.RN_SYSTEM_RESERVATION_PROCESS_NOTE_SETUP_RATE_PLAN,
                            component: PG_Setup_Reservation_Process_Note_Setup_Rate_Plan,
                            meta:{
                                t: 'RATE_PLANS',
                                permission_id: r_permission.R_RESERVATION_PROCESS_NOTE_SETUP_RATE_PLAN
                            }
                        },
                    ]
                },
                {
                    path: 'reservation/data-transition',
                    name: routeName.RN_SYSTEM_RESERVATION_PROCESS_DATA_TRANSITION,
                    component: PG_Setup_Reservation_Process_Data_Transition,
                    meta:{
                        t: 'DATA_TRANSITION_AFTER_EVENT_SETUP',
                        permission_id: r_permission.R_RESERVATION_PROCESS_DATA_TRANSITION
                    },
                    children: [
                        {
                            path: 'owner',
                            name: routeName.RN_SYSTEM_RESERVATION_PROCESS_DATA_TRANSITION_OWNER,
                            component: PG_Setup_Reservation_Process_Data_Transition_Owner,
                            meta:{
                                t: 'OWNERS',
                                permission_id: r_permission.R_RESERVATION_PROCESS_DATA_TRANSITION_OWNER
                            }
                        },
                        {
                            path: 'company',
                            name: routeName.RN_SYSTEM_RESERVATION_PROCESS_DATA_TRANSITION_COMPANY,
                            component: PG_Setup_Reservation_Process_Data_Transition_Company,
                            meta:{
                                t: 'ORGANIZATION',
                                permission_id: r_permission.R_RESERVATION_PROCESS_DATA_TRANSITION_COMPANY
                            }
                        },
                        {
                            path: 'property',
                            name: routeName.RN_SYSTEM_RESERVATION_PROCESS_DATA_TRANSITION_PROPERTY,
                            component: PG_Setup_Reservation_Process_Data_Transition_Property,
                            meta:{
                                t: 'PROPERTIES',
                                permission_id: r_permission.R_RESERVATION_PROCESS_DATA_TRANSITION_PROPERTY
                            }
                        },
                    ]
                },
                {
                    path: 'system/settings/distribution/:distribution_id',
                    name: routeName.RN_SYSTEM_DISTRIBUTION,
                    component: PG_Setup_Distribution,
                    redirect: {name: routeName.RN_SYSTEM_DISTRIBUTION_PROPERTY},
                    meta:{
                        t: 'DISTRIBUTIONS',
                        permission_id: r_permission.R_RESERVATION_PROCESS_DISTRIBUTION
                    },
                    children: [
                        {
                            path: 'general',
                            name: routeName.RN_SYSTEM_DISTRIBUTION_GENERAL,
                            component: PG_Setup_Distribution_General,
                            meta: {
                                t: "GENERAL",
                                permission_id: r_permission.R_RESERVATION_PROCESS_DISTRIBUTION
                            },
                        },
                        {
                            path: 'property',
                            name: routeName.RN_SYSTEM_DISTRIBUTION_PROPERTY,
                            component: PG_Setup_Distribution_Property,
                            meta: {
                                t: "PROPERTIES",
                                permission_id: r_permission.R_RESERVATION_PROCESS_DISTRIBUTION_PROPERTY
                            },
                        },
                        {
                            path: 'unit',
                            name: routeName.RN_SYSTEM_DISTRIBUTION_UNIT,
                            component: PG_Setup_Distribution_Unit,
                            meta: {
                                t: "UNITS",
                                permission_id: r_permission.R_RESERVATION_PROCESS_DISTRIBUTION_UNIT
                            },
                        },
                        {
                            path: 'rate-plan',
                            name: routeName.RN_SYSTEM_DISTRIBUTION_RATE_PLAN,
                            component: PG_Setup_Distribution_Rate_Plan,
                            meta: {
                                t: "RATE_PLANS",
                                permission_id: r_permission.R_RESERVATION_PROCESS_DISTRIBUTION_RATE_PLAN
                            },
                        },
                        {
                            path: 'owner',
                            name: routeName.RN_SYSTEM_DISTRIBUTION_OWNER,
                            component: PG_Setup_Distribution_Owner,
                            meta: {
                                t: "OWNERS",
                                permission_id: r_permission.R_RESERVATION_PROCESS_DISTRIBUTION
                            },
                        },
                    ]
                },
                {
                    path: 'system/settings/fee/platform-fee/distribution/:distribution_id',
                    name: routeName.RN_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION,
                    component: PG_Setup_Fees_Platform_Fee_Distribution,
                    redirect: {name: routeName.RN_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION_PROPERTY},
                    children: [
                        {
                            path: 'property',
                            name: routeName.RN_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION_PROPERTY,
                            component: PG_Setup_Fees_Platform_Fee_Distribution_Property,
                            meta: {
                                t: "PROPERTIES",
                                permission_id: r_permission.R_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION_PROPERTY,
                            },
                        },
                        {
                            path: 'unit',
                            name: routeName.RN_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION_UNIT,
                            component: PG_Setup_Fees_Platform_Fee_Distribution_Unit,
                            meta: {
                                t: "UNITS",
                                permission_id: r_permission.R_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION_UNIT,
                            },
                        },
                    ],
                },
                {
                    path: 'finance/invoice',
                    name: routeName.RN_INVOICE,
                    component: {
                        template: '<router-view></router-view>'
                    },
                    redirect: {name: routeName.RN_INVOICE_LIST},
                    children: [
                        {
                            path: 'list',
                            name: routeName.RN_INVOICE_LIST,
                            component: PG_Invoice_List,
                            meta: {
                                t: "INVOICES",
                                permission_id: r_permission.R_FINANCE_INVOICE_LIST
                            },
                        },
                        {
                            path: 'invoice-list',
                            name: routeName.RN_OWNER_INVOICE_LIST,
                            component: PG_OwnerInvoice_List,
                            meta: {
                                t: "INVOICES",
                                permission_id: r_permission.R_FINANCE_OWNER_INVOICE_LIST
                            },
                        },
                    ]
                },
                {
                    path: 'finance/payment-order',
                    name: routeName.RN_PAYMENT_ORDER,
                    component: {
                        template: '<router-view></router-view>'
                    },
                    redirect: {name: routeName.RN_PAYMENT_ORDER_LIST},
                    children: [
                        {
                            path: 'list',
                            name: routeName.RN_PAYMENT_ORDER_LIST,
                            component: PG_Payment_Order_List,
                            meta: {
                                t: 'PAYMENT_ORDERS',
                                permission_id: r_permission.R_FINANCE_PAYMENT_ORDER_LIST
                            },
                        }
                    ]
                },
                {
                    path: 'finance/transaction',
                    name: routeName.RN_TRANSACTION_LIST,
                    component: PG_TransactionList,
                    meta: {
                        t: 'TRANSACTIONS',
                        permission_id: r_permission.R_FINANCE_TRANSACTIONS,
                        function:r_permission.R_FINANCE_TRANSACTIONS
                    },
                },
                {
                    path: 'finance/payout-report',
                    name: routeName.RN_PAYOUT_REPORT_LIST,
                    component: PG_PayoutList,
                    meta: {
                        t: 'PAYMENT_ORDERS',
                        permission_id: r_permission.R_FINANCE_PAYMENT_ORDER_LIST
                    },
                },

                {
                    path: 'integrations',
                    name: routeName.RN_INTEGRATIONS,
                    component: PG_Integration,
                    meta: {
                        t: "INTEGRATIONS",
                        permission_id: r_permission.R_INTEGRATIONS
                    },
                    children: [
                        {
                            path: 'channel-manager',
                            name: routeName.RN_CHANNEL_MANAGER,
                            component: PG_Integration_CM,
                            redirect: {name: routeName.RN_CHANNEL_MANAGER_LOG},
                            children: [
                                {
                                    path: 'dashboard',
                                    name: routeName.RN_CHANNEL_MANAGER_DASHBOARD,
                                    component: PG_Integration_CM_Dashboard,
                                    meta: {
                                        t: "SIDEBAR.DASHBOARD",
                                        permission_id: r_permission.R_INTEGRATIONS_CM_DASHBOARD

                                    }
                                },
                                {
                                    path: 'log',
                                    name: routeName.RN_CHANNEL_MANAGER_LOG,
                                    component: PG_Integration_CM_Log,
                                    meta: {
                                        t: "SYNCHRONIZATION_LOG",
                                        permission_id: r_permission.R_INTEGRATIONS_CM_LOG
                                    }
                                },
                                {
                                    path: 'setup',
                                    name: routeName.RN_CHANNEL_MANAGER_SETUP,
                                    component: PG_Integration_CM_Setup,
                                    meta: {
                                        t: "SETUP",
                                        permission_id: r_permission.R_INTEGRATIONS_CM_SETUP
                                    }
                                },
                            ]
                        },
                        {
                            path: 'payment-gateway',
                            name: routeName.RN_PAYMENT_GATEWAY,
                            component: PG_Integration_PG,
                            redirect: {name: routeName.RN_PAYMENT_GATEWAY_LIST},
                            children: [
                                {
                                    path: 'list',
                                    name: routeName.RN_PAYMENT_GATEWAY_LIST,
                                    component: PG_Integration_PG_List,
                                    meta: {
                                        t: "PAYMENT_GATEWAYS",
                                        permission_id: r_permission.R_INTEGRATIONS_PG_LIST
                                    },
                                },
                            ],
                        },
                    ]
                },
                {
                    path:'direct-booking-activate',
                    name:routeName.RN_DIRECT_BOOKING_ACTIVATION,
                    component: PG_Direct_Booking_Activation,
                    meta:{
                        t:'DIRECT_BOOKING',
                        permission_id: r_permission.R_DIRECT_BOOKING,
                        function: F_DIRECT_BOOKING_ACTIVATION
                    },
                },
                {
                    path: 'direct-booking',
                    name: routeName.RN_DIRECT_BOOKING,
                    component:
                        {
                            template: '<router-view></router-view>'
                        },
                    meta: {
                        t: "DIRECT_BOOKING",
                        permission_id: r_permission.R_DIRECT_BOOKING,
                    },
                    children: [
                        {
                            path: 'website',
                            name: routeName.RN_DIRECT_BOOKING_WEBSITE_ROOT,
                            component: PG_Direct_Booking,
                            meta: {
                                t: "WEBSITES",
                                permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE,
                                function : F_DIRECT_BOOKING
                            },
                            redirect: {name: routeName.RN_DIRECT_BOOKING_WEBSITE_LIST},
                            children: [
                                {
                                    path: 'list',
                                    name: routeName.RN_DIRECT_BOOKING_WEBSITE_LIST,
                                    component: PG_Direct_Booking_Website_List,
                                    meta: {
                                        t: "WEBSITES",
                                        permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_LIST,
                                        function : F_DIRECT_BOOKING
                                    },
                                },
                                {
                                    path: ':id',
                                    name: routeName.RN_DIRECT_BOOKING_WEBSITE,
                                    redirect: {name: routeName.RN_DIRECT_BOOKING_WEBSITE_GENERAL},
                                    component: PG_Direct_Booking_Website,
                                    meta: {
                                        t: "WEBSITES",
                                        permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE,
                                        function : F_DIRECT_BOOKING
                                    },
                                    children: [
                                        {
                                            path: 'reservations',
                                            name: routeName.RN_DIRECT_BOOKING_WEBSITE_RESERVATION,
                                            component: PG_Direct_Booking_Website_Reservation,
                                            meta: {
                                                t: "RESERVATIONS",
                                                permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_RESERVATIONS,
                                                function : F_DIRECT_BOOKING
                                            }
                                        },
                                        {
                                            path: 'overview',
                                            name: routeName.RN_DIRECT_BOOKING_WEBSITE_OVERVIEW,
                                            component: PG_Direct_Booking_Website_General,
                                            meta: {
                                                t: "OVERVIEW",
                                                permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_OVERVIEW,
                                                function : F_DIRECT_BOOKING
                                            }
                                        },
                                        {
                                            path: 'general',
                                            name: routeName.RN_DIRECT_BOOKING_WEBSITE_GENERAL,
                                            component: PG_Direct_Booking_Website_General,
                                            meta: {
                                                t: "GENERAL",
                                                permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_GENERAL,
                                                function : F_DIRECT_BOOKING
                                            },
                                        },
                                        {
                                            path: 'units',
                                            name: routeName.RN_DIRECT_BOOKING_WEBSITE_UNIT_ACTIVATION,
                                            component: PG_Direct_Booking_Website_Unit_Activation,
                                            meta: {
                                                t: "UNITS",
                                                permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE,
                                                function : F_DIRECT_BOOKING
                                            }
                                        },
                                        {
                                            path: 'integrations',
                                            name: routeName.RN_DIRECT_BOOKING_WEBSITE_INTEGRATIONS,
                                            component: PG_Direct_Booking_Website_Integrations,
                                            meta:{
                                                t: "INTEGRATIONS",
                                                permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE,
                                                function: F_DIRECT_BOOKING
                                            }
                                        },
                                        {
                                            path: 'pages',
                                            name: routeName.RN_DIRECT_BOOKING_WEBSITE_PAGES,
                                            component: PG_Direct_Booking_Website_Pages,
                                            meta: {
                                                t: "PAGES",
                                                permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_PAGES,
                                                function : F_DIRECT_BOOKING
                                            },
                                        },
                                        {
                                            path: 'navigation',
                                            name: routeName.RN_DIRECT_BOOKING_WEBSITE_NAVIGATION,
                                            component: PG_Direct_Booking_Website_Navigation,
                                            meta: {
                                                t: "NAVIGATION",
                                                permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_PAGES,
                                                function : F_DIRECT_BOOKING
                                            },
                                        },
                                        {
                                            path: 'gallery',
                                            name: routeName.RN_DIRECT_BOOKING_WEBSITE_GALLERY,
                                            component: PG_Direct_Booking_Website_Gallery,
                                            meta: {
                                                t: "GALLERY",
                                                permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE,
                                                function : F_DIRECT_BOOKING
                                            },
                                        },
                                        {
                                            path: 'pages/:page_id',
                                            redirect: {name: routeName.RN_DIRECT_BOOKING_WEBSITE_PAGE_GENERAL},
                                            name: routeName.RN_DIRECT_BOOKING_WEBSITE_PAGE,
                                            component: PG_Direct_Booking_Website_Page,
                                            meta: {
                                                t: "PAGES",
                                                permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_PAGES,
                                                function : F_DIRECT_BOOKING
                                            },
                                            children: [
                                                {
                                                    path: 'overview',
                                                    name: routeName.RN_DIRECT_BOOKING_WEBSITE_PAGE_OVERVIEW,
                                                    component: PG_Direct_Booking_Website_Page_Overview,
                                                    meta: {
                                                        t: "OVERVIEW",
                                                        permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_PAGES,
                                                        function : F_DIRECT_BOOKING
                                                    },
                                                },
                                                {
                                                    path: 'general',
                                                    name: routeName.RN_DIRECT_BOOKING_WEBSITE_PAGE_GENERAL,
                                                    component: PG_Direct_Booking_Website_Page_General,
                                                    meta: {
                                                        t: "GENERAL",
                                                        permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_PAGES,
                                                        function : F_DIRECT_BOOKING
                                                    },
                                                },
                                                {
                                                    path: 'content',
                                                    name: routeName.RN_DIRECT_BOOKING_WEBSITE_PAGE_CONTENT,
                                                    component: PG_Direct_Booking_Website_Page_Content,
                                                    meta: {
                                                        t: "CONTENT",
                                                        permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_PAGES,
                                                        function : F_DIRECT_BOOKING
                                                    },
                                                },
                                                {
                                                    path: 'filter',
                                                    name: routeName.RN_DIRECT_BOOKING_WEBSITE_PAGE_FILTERS,
                                                    component: PG_Direct_Booking_Website_Page_Filters,
                                                    meta: {
                                                        t: "FILTERS",
                                                        permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_PAGES,
                                                        function: F_DIRECT_BOOKING
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            path: 'amenities-category',
                                            name: routeName.RN_DIRECT_BOOKING_WEBSITE_AMENITY_CATEGORIES,
                                            component: PG_Direct_Booking_Website_Amenity_Categories,
                                            meta: {
                                                t: "AMENITY_CATEGORIES",
                                                permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_AMENITY_CATEGORIES,
                                                function : F_DIRECT_BOOKING
                                            },
                                        },
                                        {
                                            path: 'amenities',
                                            name: routeName.RN_DIRECT_BOOKING_WEBSITE_AMENITIES,
                                            component: PG_Direct_Booking_Website_Amenities,
                                            meta: {
                                                t: "UNIT_AMENITIES",
                                                permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_AMENITIES,
                                                function : F_DIRECT_BOOKING
                                            },
                                        },
                                        {
                                            path: 'unit-type',
                                            name: routeName.RN_DIRECT_BOOKING_WEBSITE_UNIT_TYPE,
                                            component: PG_Direct_Booking_Website_Unit_Type,
                                            meta: {
                                                t: "UNIT_TYPES",
                                                permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_PAGES,
                                                function : F_DIRECT_BOOKING
                                            },
                                        },
                                        {
                                            path: 'tag',
                                            name: routeName.RN_DIRECT_BOOKING_WEBSITE_TAGS,
                                            component: PG_Direct_Booking_Website_Tags,
                                            meta: {
                                                t: "DESTINATIONS",
                                                permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_TAG,
                                                function : F_DIRECT_BOOKING
                                            },
                                        },
                                        {
                                            path: 'promo_codes',
                                            name: routeName.RN_DIRECT_BOOKING_WEBSITE_PROMO_CODES,
                                            component: PG_Direct_Booking_Website_Promo_Codes,
                                            meta: {
                                                t: 'PROMO_CODES',
                                                permission_id: r_permission.R_DIRECT_BOOKING_WEBSITE_PROMO_CODES,
                                                function : F_DIRECT_BOOKING
                                            }
                                        },
                                        {
                                            path: 'appearance',
                                            name: routeName.RN_DIRECT_BOOKING_APPEARANCE,
                                            component: PG_Direct_Booking_Website_Appearance,
                                            meta: {
                                                t: 'APPEARANCE',
                                                permission_id: r_permission.R_DIRECT_BOOKING_APPEARANCE_VIEW,
                                                function : F_DIRECT_BOOKING
                                            }
                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            path: 'setup',
                            name: routeName.RN_DIRECT_BOOKING_SETUP,
                            component: PG_Direct_Booking_Setup,
                            meta: {
                                t: "SETUP",
                                permission_id: r_permission.R_DIRECT_BOOKING_SETUP,
                                function : F_DIRECT_BOOKING
                            }
                        },
                        {
                            path: 'destination-tag/country',
                            name: routeName.RN_SETUP_DESTINATION_TAG,
                            component:
                                {
                                    template: '<router-view></router-view>'
                                },
                            meta: {
                                t: "DESTINATION_TAG",
                                permission_id: r_permission.R_SETUP_TAGS_COUNTRY,
                                function : F_DIRECT_BOOKING
                            },
                            redirect: {name: routeName.RN_SETUP_DESTINATION_TAG_COUNTRY},
                            children: [
                                {
                                    path: 'list',
                                    name: routeName.RN_SETUP_DESTINATION_TAG_COUNTRY,
                                    component: PG_Setup_Destination_Tag_Country,
                                    meta: {
                                        t: "COUNTRIES",
                                        permission_id: r_permission.R_SETUP_TAGS_COUNTRY_LIST,
                                        function : F_DIRECT_BOOKING
                                    },
                                },
                            ]
                        },
                        {
                            path: 'promo-codes',
                            name: routeName.RN_DIRECT_BOOKING_PROMO_CODES,
                            component: PG_Direct_Booking_Promo_Codes,
                            meta: {
                                t: "COUPONS",
                                permission_id: r_permission.R_DIRECT_BOOKING_PROMO_CODES,
                                function : F_DIRECT_BOOKING
                            },
                            redirect: {name: routeName.RN_DIRECT_BOOKING_PROMO_CODES_LIST},
                            children: [
                                {
                                    path: 'list',
                                    name: routeName.RN_DIRECT_BOOKING_PROMO_CODES_LIST,
                                    component: PG_Direct_Booking_Promo_Codes_List,
                                    meta: {
                                        t: "PROMO_CODES",
                                        permission_id: r_permission.R_DIRECT_BOOKING_PROMO_CODES_PROMOTIONS,
                                        function : F_DIRECT_BOOKING
                                    }
                                },
                                {
                                    path: 'category',
                                    name: routeName.RN_DIRECT_BOOKING_PROMO_CODES_CATEGORY,
                                    component: PG_Direct_Booking_Promo_Codes_Category,
                                    meta: {
                                        t: "PROMO_CODE_CATEGORIES",
                                        permission_id: r_permission.R_DIRECT_BOOKING_PROMO_CODES_CATEGORY,
                                        function : F_DIRECT_BOOKING
                                    }
                                }
                            ]
                        },
                        {
                            path: 'marketplace',
                            name: routeName.RN_DIRECT_BOOKING_MARKETPLACE,
                            component: PG_Direct_Booking_Marketplace,
                            meta: {
                                t: "MARKETPLACE",
                                permission_id: r_permission.R_DIRECT_BOOKING_MARKETPLACE,
                                function: F_DIRECT_BOOKING_MARKETPLACE
                            },
                            redirect: {name: routeName.RN_DIRECT_BOOKING_MARKETPLACE_GENERAL},
                            children: [
                                {
                                    path: 'list',
                                    name: routeName.RN_DIRECT_BOOKING_MARKETPLACE_LIST,
                                    component: PG_Direct_Booking_Marketplace_List,
                                    meta: {
                                        t: "MARKETPLACE",
                                        permission_id: r_permission.R_DIRECT_BOOKING_MARKETPLACE_LIST,
                                        function: F_DIRECT_BOOKING_MARKETPLACE
                                    }
                                },
                                {
                                    path: 'general',
                                    name: routeName.RN_DIRECT_BOOKING_MARKETPLACE_GENERAL,
                                    component: PG_Direct_Booking_Marketplace_General,
                                    meta: {
                                        t: "MY_WEBSITES",
                                        permission_id: r_permission.R_DIRECT_BOOKING_MARKETPLACE_OVERVIEW,
                                        function: F_DIRECT_BOOKING_MARKETPLACE
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    path: 'direct-booking/destination-tag/country/:id',
                    redirect: {name: routeName.RN_SETUP_DESTINATION_TAG_GENERAL},
                    name: routeName.RN_SETUP_DESTINATION_TAG_COUNTRY_ID,
                    component: PG_Setup_Destination_Tag,
                    meta: {
                        t: "DESTINATION_TAG",
                        permission_id: r_permission.R_SETUP_TAGS_COUNTRY_LIST,
                        function : F_DIRECT_BOOKING
                    },
                    children: [
                        {
                            path: 'general',
                            name: routeName.RN_SETUP_DESTINATION_TAG_GENERAL,
                            component: PG_Setup_Destination_Tag_General,
                            meta: {
                                t: "GENERAL",
                                permission_id: r_permission.R_SETUP_TAGS_COUNTRY_GENERAL,
                                function : F_DIRECT_BOOKING
                            },
                        },
                        {
                            path: 'region',
                            name: routeName.RN_SETUP_DESTINATION_TAG_REGION,
                            component: PG_Setup_Destination_Tag_Region,
                            meta: {
                                t: "REGIONS",
                                permission_id: r_permission.R_SETUP_TAGS_REGION_LIST,
                                function : F_DIRECT_BOOKING
                            },
                        },
                        {
                            path: 'region/:region_id/general',
                            name: routeName.RN_SETUP_DESTINATION_TAG_GENERAL_REGION,
                            component: PG_Setup_Destination_Tag_General,
                            meta: {
                                t: "GENERAL",
                                permission_id: r_permission.R_SETUP_TAGS_REGION_GENERAL,
                                function : F_DIRECT_BOOKING
                            },
                        },
                        {
                            path: 'region/:region_id/city',
                            name: routeName.RN_SETUP_DESTINATION_TAG_CITY,
                            component: PG_Setup_Destination_Tag_City,
                            meta: {
                                t: "CITIES",
                                permission_id: r_permission.R_SETUP_TAGS_CITY,
                                function : F_DIRECT_BOOKING
                            },
                        }

                    ]
                },
                {
                    path: 'finance/payment-message',
                    name: routeName.RN_PAYMENT_MESSAGE,
                    component: {
                        template: '<router-view></router-view>'
                    },
                    meta: {
                        t: "PAYMENT_MESSAGES",
                        permission_id: r_permission.R_FINANCE_PAYMENT_MESSAGE
                    },
                    redirect: {name: routeName.RN_PAYMENT_MESSAGE_LIST},
                    children: [
                        {
                            path: 'list',
                            name: routeName.RN_PAYMENT_MESSAGE_LIST,
                            component: PG_Payment_Message_List,
                            meta: {
                                t: "PAYMENT_MESSAGES",
                                permission_id: r_permission.R_FINANCE_PAYMENT_MESSAGE
                            }
                        }
                    ]
                },
                {
                    path: 'finance/owner-calculation',
                    name: routeName.RN_OWNER_CALCULATION,
                    component: {
                        template: '<router-view></router-view>'
                    },

                    meta: {
                        t: "OWNER_CALCULATION",
                        permission_id: r_permission.R_FINANCE_CALCULATION,
                    },

                    redirect: {name: routeName.RN_OWNER_CALCULATION_LIST},
                    children: [
                        {
                            path: 'list',
                            name: routeName.RN_OWNER_CALCULATION_LIST,
                            component: PG_Owner_Calculation_List,
                            meta: {
                                t: "OWNER_STATEMENTS",
                                permission_id: r_permission.R_FINANCE_CALCULATION,
                                function:F_CALCULATION_MENU,
                            }
                        }
                    ]
                },
                {
                    path:'review/accommodation/list',
                    name:routeName.RN_ACCOMMODATION_REVIEW,
                    component:PG_AccommodationReviewList,
                    meta:{
                        t:'ACCOMMODATION_REVIEW',
                        permission_id: r_permission.R_REVIEW_ACCOMMODATION
                    }
                },
                {
                    path:'review/activity/list',
                    name:routeName.RN_ACTIVITY_REVIEW,
                    component:PG_ActivityReviewList,
                    meta:{
                        t:'ACTIVITY_REVIEW',
                        permission_id: r_permission.R_REVIEW_ACTIVITY
                    }

                },
                {
                    path:'review/transfer/list',
                    name:routeName.RN_TRANSFER_REVIEW,
                    component:PG_TransferReviewList,
                    meta:{
                        t:'TRANSFER_REVIEW',
                        permission_id: r_permission.R_REVIEW_TRANSFER
                    }

                },
                {
                    path:'revenue-manager-activate',
                    name:routeName.RN_REVENUE_MANAGER_ACTIVATION,
                    component: PG_RevenueManagerActivation,
                    meta:{
                        t:'REVENUE_MANAGER',
                        permission_id: r_permission.R_REVENUE_MANAGER,
                        function:F_REVENUE_MANAGER_ACTIVATION
                    },
                },
                {
                    path:'revenue-manager',
                    name:routeName.RN_REVENUE_MANAGER,
                    component: PG_RevenueManager,
                    meta:{
                        t:'REVENUE_MANAGER',
                        permission_id: r_permission.R_REVENUE_MANAGER,
                    },
                    children: [
                        {
                            path:'setup',
                            name:routeName.RN_REVENUE_MANAGER_SETUP_ACTION,
                            redirect: {name: routeName.RN_REVENUE_MANAGER_SETUP_ACTION_PRICE},
                            component: PG_RevenueManagerSetup,
                            meta: {
                                t: "SETUP",
                                permission_id: r_permission.R_REVENUE_MANAGER_SETUP,
                                function:F_REVENUE_MANAGER
                            },
                            children: [
                                {
                                path:'price-action',
                                component: PG_RevenueManagerSetupPriceAction,
                                name:routeName.RN_REVENUE_MANAGER_SETUP_ACTION_PRICE,
                                meta: {
                                    t: "AUTOMATIC_RATES_SETUP",
                                    permission_id: r_permission.R_REVENUE_MANAGER_SETUP,
                                    function:F_REVENUE_MANAGER
                                 },
                                },
                                {
                                    path: 'min-stay-action',
                                    name: routeName.RN_REVENUE_MANAGER_SETUP_ACTION_MIN_STAY,
                                    component: PG_RevenueManagerSetupMinStayAction,
                                    meta: {
                                        t: "AUTOMATIC_MIN_STAY_SETUP",
                                        permission_id: r_permission.R_REVENUE_MANAGER_SETUP,
                                        function:F_REVENUE_MANAGER
                                    }
                                },
                                {
                                    path: 'properties',
                                    name: routeName.RN_REVENUE_MANAGER_SETUP_PROPERTIES,
                                    component: PG_RevenueManagerPropertyList,
                                    meta: {
                                        t: "PROPERTIES",
                                        permission_id: r_permission.R_REVENUE_MANAGER_SETUP,
                                    }
                                }
                            ]
                        },
                        {
                            path:'similar-listings',
                            name:routeName.RN_REVENUE_MANAGER_SIMILAR_LISTING,
                            component: PG_RevenueManagerSimilarListing,
                            redirect: {name: routeName.RN_REVENUE_MANAGER_SIMILAR_LISTING_FILTER},
                            meta: {
                                t: "COMPETITORS",
                                permission_id: r_permission.R_REVENUE_MANAGER_SETUP,
                                function:F_REVENUE_MANAGER
                            },
                            children: [
                                {
                                    path: 'filter-list',
                                    component: PG_RevenueManagerSimilarListingFilterList,
                                    name: routeName.RN_REVENUE_MANAGER_SIMILAR_LISTING_FILTER,
                                    meta: {
                                        t: "GROUPS",
                                        permission_id: r_permission.R_REVENUE_MANAGER_SETUP,
                                        function:F_REVENUE_MANAGER
                                    },

                                },
                                {
                                    path: 'unit',
                                    component: PG_RevenueManagerSimilarListingUnit,
                                    name: routeName.RN_REVENUE_MANAGER_SIMILAR_LISTING_UNIT,
                                    meta: {
                                        t: "UNITS",
                                        permission_id: r_permission.R_REVENUE_MANAGER_SETUP,
                                        function:F_REVENUE_MANAGER
                                    },

                                },
                            ]
                        },
                        {
                            path:'booking-windows',
                            name: routeName.RN_REVENUE_MANAGER_BOOKING_WINDOW_LIST,
                            component: PG_BookingWindowList,
                            meta: {
                                t: "BOOKING_WINDOWS",
                                permission_id: r_permission.R_REVENUE_MANAGER_SETUP,
                                function:F_REVENUE_MANAGER
                            },
                        }
                    ]

                }
            ]
        },

        // last route, replace with 404 page
        {path: '*', name: routeName.RN_404, component: Page404},

    ]
})

router.beforeEach((to, from, next) => {
    if (typeof to.meta.permission_id === 'undefined') {
        next()
    } else {
        if (store.getters['user/getPermission'](to.meta.permission_id)) {
            next()
        } else {
            if(to.fullPath){

                next({
                    path:'/login',
                    query: {
                        redirect: to.fullPath,
                    }
                })
            } else {
                // redirect to login
                next(from.fullPath)
            }

        }
    }
})

export default router
