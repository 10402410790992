<template>
    <div>
        <b-form @submit.prevent="save">
            <b-row>
                <b-col md="8" class="mb-4">
                    <app-input-control :error-object="validation.name">
                        <template v-slot:input="data">
                            <label>{{ $t('NAME') }}</label>
                            <b-input type="text" v-model="formData.label" :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="4" class="mb-4">
                    <app-input-control :error-object="validation.amount">
                        <template v-slot:input="data">
                            <label>{{$t('VAT_RATE')}}</label>
                            <app-number-input
                                v-model="formData.amount" :allow-decimal="true" append="%" :min="0" :max="99.99" :state="data.state" align="right" :nullable="false">
                            </app-number-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="mb-4">
                    <app-button-submit :c_permission="C_SETUP_FINANCE_VAT_E" :loading="saving"></app-button-submit>
                </b-col>

                <b-col md="6">
                    <app-button-delete
                        v-if="vatObject.id"
                        :c_permission="C_SETUP_FINANCE_VAT_E"
                        class="pull-right"
                        @click="deleteDialogState=true">
                    </app-button-delete>

                    <app-confirmation-dialog
                        :show="deleteDialogState"
                        @confirm="_delete"
                        :delete_title="true"
                        @cancel="deleteDialogState=false">
                    </app-confirmation-dialog>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
    import {cloneDeep} from "lodash";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {C_SETUP_FINANCE_VAT_E} from "@/shared/component_permission";
    import {updateVatRate, createVatRate, deleteVatRate} from "@/services/finance/setup";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import AppInputControl from "@/components/app/AppInputControl";
    import {VAT_AMOUNT_VALIDATION_FAILED, VAT_LABEL_VALIDATION_FAILED} from "@/shared/error_codes";

    export default {
        name: "VatSetupForm",
        components: {AppInputControl, AppButtonSubmit, AppNumberInput, AppButtonDelete, AppConfirmationDialog},
        mixins: [getErrorMessage],
        props: {
            vatObject: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                C_SETUP_FINANCE_VAT_E,
                formData: null,
                saving: false,
                deleteDialogState: false,
                validation: {
                    name: {
                        input: [VAT_LABEL_VALIDATION_FAILED]
                    },
                    amount: {
                        input: [VAT_AMOUNT_VALIDATION_FAILED]
                    }
                }
            }
        },
        methods: {
            save() {
                this.saving = true
                const savePromise = !this.vatObject.id
                    ? createVatRate(this.formData)
                    : updateVatRate(this.vatObject.id, this.formData);
                savePromise.then(response => {
                    !this.vatObject.id ? this.$emit('created', response.data) : this.$emit('updated', response.data)
                    notifySuccess()
                }).catch(error => {
                    this.showErrorMessages(error, this.validation)
                }).finally(() => this.saving = false)
            },
            _delete() {
                this.$emit('deleted', this.vatObject)
            }
        },
        created() {
            this.formData = cloneDeep(this.vatObject)
        }
    }
</script>

<style scoped>

</style>
