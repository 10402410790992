<template>
    <div class="main_calendar_test--cell d-flex align-items-center position-relative main_calendar_test--poiner animated fadeIn main_calendar_test-bb main_calendar_test--background_gray main_calendar_test__holder--sidebar-h1">
        <span>
            {{$t("ROOM_STATUS")}}
        </span>
    </div>
</template>

<script>
    export default {
        name: "TableUnitStatusSidebar"
    }
</script>

<style scoped>

</style>
