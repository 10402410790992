<template>
    <div class="position-relative" v-if="galleryListComputed && hasPermissionView">
        <b-row>
            <b-col>
                <gallery-upload :disabled="!hasPermissionEdit" @uploadSuccess="fetchList" :ObjectId="ObjectId"
                                :DocumentTypeId="DocumentTypeId" :document-type="DocumentType"></gallery-upload>
            </b-col>
        </b-row>

        <gallery-copy
            @saveCopyItems="duplicateDocuments"
            v-if="CopyComponent"
            :list="selectedDocumentList"
            :state="copy_sidebar_state"
            @closeAside="copy_sidebar_state=false"
            :object-list="CopyObjects"></gallery-copy>

        <app-aside v-if="taggable" :widths="['col-lg-3', 'col-sm-12', '30%']" v-model="tag_sidebar_state">
            <template slot="header">{{$t("TAGS")}}</template>
            <tag-form :tag_type="tag_type" :object_type="object_type" :entity="selectedDocumentList"></tag-form>
        </app-aside>

        <gallery :images="galleryList" :index="index" @close="index = null">
            <template #close>
                <i class="fa fa-close" style="color: white"></i>
            </template>
        </gallery>
        <b-form v-if="galleryListComputed.length > 0" @submit.prevent="submit">
            <b-row class="mb-3 mt-2">
                <b-col >
                    <b-dropdown :disabled="actionState" ref="gallery_dropdown_action" variant="primary" size="sm" :popper-opts="{placement: 'left'}"
                                class="pull-right">
                        <template slot="button-content">
                            <i class="fa fa-cog fa-fw" aria-hidden="true"></i>
                        </template>
                        <b-dropdown-item-button @click="selectAll">{{ this.selectedDocumentList.length ===
                            this.galleryListFull.length ? $t("DESELECT") : $t("SELECT_ALL")}}
                        </b-dropdown-item-button>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item-button @click="deleteSelected" :disabled="disabledActionBarItem" v-if="hasPermissionEdit">
                            {{$t("DELETE_SELECTED")}}
                        </b-dropdown-item-button>
                        <b-dropdown-item-button @click="downloadSelected" :disabled="disabledActionBarItem">
                            {{$t('DOWNLOAD_SELECTED')}}
                        </b-dropdown-item-button>
                        <b-dropdown-item-button :disabled="disabledActionBarItem" v-if="CopyComponent && hasPermissionEdit"
                                                @click="copy_sidebar_state=true">{{$t('COPY')}}
                        </b-dropdown-item-button>
                        <b-dropdown-item-button @click="tag_sidebar_state=true" v-if="taggable && hasPermissionEdit"
                                                :disabled="disabledActionBarItem">
                            {{$t("TAGS")}}
                        </b-dropdown-item-button>

                    </b-dropdown>
                </b-col>
            </b-row>
            <draggable v-model="galleryListComputed" @change="changeOrder" class="row">
                <GalleryItem ref="galleryItem"
                             :key="img.id" v-for="(img, imgIndex) in galleryListComputed"
                             @imageClicked="index=imgIndex"
                             @textfieldEdit="updateTextfield($event, img.id)"
                             @removeDocument="removeItemFromList(img.id)"
                             @copyCheck="editCheckedList(img.id, $event)"
                             :showCheckbox="CopyComponent"
                             :checkboxSelectedList="selectedDocumentList"
                             :disabled="actionState || !hasPermissionEdit"
                             :DocumentTypeId="DocumentTypeId"
                             :item="img">
                </GalleryItem>
            </draggable>
            <b-row class="mb-3">
                <b-col>
                    <save-button :disabled="!textEdited" :c_permission="c_permission_e"></save-button>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
    import VueGallery from 'vue-gallery';
    import GalleryCopy from '@/components/app/gallery/GalleryCopy'
    import GalleryUpload from '@/components/app/gallery/GalleryUpload'
    import SaveButton from '@/components/app/AppButton/AppButtonSubmit'
    import DownloadButton from '@/components/app/AppDownload'
    import AppButton from '@/components/app/AppButton/AppButton'
    import GalleryItem from '@/components/app/gallery/GalleryItem'
    import {
        getDocumentList,
        deleteDocument,
        updateDocumentList,
        downloadDocument,
        downloadListOfDocuments,
        copyDocuments,
        deleteDocumentList
    } from '@/services/document/index'
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import draggable from 'vuedraggable'
    import AppAside from '@/components/app/form/AppAside'
    import TagForm from '@/components/app/tag/TagForm'
    import {OBJECT_TYPE_PROPERTY, OBJECT_TYPE_UNIT} from "@/shared/constants";

    export default {
        name: "Gallery",
        components: {
            'gallery': VueGallery,
            GalleryItem,
            GalleryUpload,
            draggable,
            SaveButton,
            DownloadButton,
            GalleryCopy,
            AppButton,
            AppAside,
            TagForm
        },
        mixins: [getErrorMessage],
        props: {
            ObjectId: {
                type: Number
            },
            DocumentTypeId: {
                type: Number
            },
            DocumentType: {
                type: String
            },
            CopyComponent: {
                type: Boolean,
                default: false
            },
            CopyObjects: {
                type: Array,
                default: () => {
                    return []
                }
            },
            c_permission_v: {
                default: null,
                type: Number
            },
            c_permission_e: {
                default: null,
                type: Number
            },
            tag_type: {
                type: Number,
                default: null
            },
            tag_permission_v: {
                type: Number,
                default: null
            },
            object_type: {
                type: Number,
                default: null
            },
            objectName: {
                type: String,
                default: "documents"
            }
        },
        data() {
            return {
                index: null,
                galleryListFull: [],
                selectedDocumentList: [],
                copy_sidebar_state: false,
                tag_sidebar_state: false,
                actionState: false,
                textEdited: false,
            }
        },
        computed: {
            disabledActionBarItem() {
                return this.selectedDocumentList.length === 0
            },
            galleryList() {
                var arrTemp = []
                this.galleryListFull.forEach(function (arrayItem) {
                    arrTemp.push(arrayItem.route)
                });
                return arrTemp
            },
            galleryListComputed: {
                get() {
                    return this.galleryListFull
                },
                set(value) {
                    this.galleryListFull = value
                }
            },
            hasPermissionView() {
                if (this.c_permission_v === null) {
                    return true
                }
                return this.$store.getters['user/getPermission'](this.c_permission_v)
            },
            hasPermissionEdit() {
                if (this.c_permission_e === null) {
                    return true
                }

                return this.checkPermission(this.c_permission_e)
            },
            taggable() {
                return this.object_type > 0 && this.tag_type > 0 && (this.tag_permission_v === null || this.checkPermission(this.tag_permission_v))
            }
        },
        methods: {
            deleteSelected() {
                this.actionState = true
                deleteDocumentList(this.ObjectId, this.DocumentTypeId, this.selectedDocumentList).then(response => {
                    this.galleryListFull = response.data
                    this.selectedDocumentList = []
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.actionState = false
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                    this.actionState = false
                })
            },
            removeItemFromList(id) {
                deleteDocument(this.ObjectId, this.DocumentTypeId, id).then((response) => {
                        this.galleryListFull = response.data
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }
                )
            },
            updateList(data) {
                this.galleryListFull = data
            },
            updateTextfield(value, img_id) {
                this.galleryListFull.forEach((item, index) => {
                    if (item.id === img_id) {
                        this.galleryListFull[index].description = value
                        this.textEdited = true
                    }
                })
            },
            downloadSelected() {
                this.actionState = true

                downloadListOfDocuments(this.ObjectId, this.DocumentTypeId, this.selectedDocumentList, this.objectName).then(() => {
                    this.actionState = false
                    this.selectedDocumentList = []
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                    this.actionState = false
                })

            },
            fetchList() {
                getDocumentList(this.ObjectId, this.DocumentTypeId).then((response) => {
                    this.galleryListFull = response.data
                })
            },
            changeOrder(data) {
                this.submit(false)
            },
            submit(notifySuccees = true) {
                this.galleryListFull.forEach((item, index) => {
                    this.galleryListFull[index].order_number = index + 1
                })

                updateDocumentList(this.ObjectId, this.DocumentTypeId, {list: this.galleryListFull}).then((response) => {
                        this.galleryListFull = response.data

                        if (notifySuccees) {
                            toast({
                                'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 3000
                            })
                        }

                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }
                )

            },
            editCheckedList(docId, event) {
                if (!this.selectedDocumentList.includes(docId) && event) {
                    this.selectedDocumentList.push(docId)
                    return
                }

                if (this.selectedDocumentList.includes(docId) && !event) {
                    let index = this.selectedDocumentList.indexOf(docId)
                    if (index !== -1) this.selectedDocumentList.splice(index, 1)
                }
            },
            selectAll() {

                this.$refs.gallery_dropdown_action.hide(false)
                if (this.selectedDocumentList.length === this.galleryListFull.length) {
                    this.selectedDocumentList = []
                } else {
                    this.galleryListFull.forEach(element => {
                        if (!this.selectedDocumentList.includes(element.id)) {
                            this.selectedDocumentList.push(element.id)
                        }
                    })
                }
            },
            duplicateDocuments(request) {
                copyDocuments(this.ObjectId, this.DocumentTypeId, request).then((response) => {
                    this.galleryListFull = response.data
                    this.selectedDocumentList = []
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                })
            }
        },
        watch: {
            ObjectId: {
                handler() {
                    this.fetchList()
                },
                immediate: true
            }
        },
        created() {
            //this.fetchList()
        }
    }
</script>

<style scoped>
    .list-complete-item {
        padding: 4px;
        margin-top: 4px;
        border: solid 1px;
        transition: all 1s;
    }

    .list-complete-enter, .list-complete-leave-active {
        opacity: 0;
    }

</style>
