<template>
    <div>
        <revenue-manager-setup-property-filter @search="setFilter"
                                               :loading="loading"
        ></revenue-manager-setup-property-filter>
        <revenue-manager-setup-property-list @busyToggled="toggleBusy"
                                             :filter="filter"
        ></revenue-manager-setup-property-list>
    </div>
</template>

<script>
import RevenueManagerSetupPropertyFilter
    from "@/components/revenue_manager/setup/properties/RevenueManagerSetupPropertyFilter";
import RevenueManagerSetupPropertyList
    from "@/components/revenue_manager/setup/properties/RevenueManagerSetupPropertyList";

export default {
    name: "Index",
    components: {
        RevenueManagerSetupPropertyFilter,
        RevenueManagerSetupPropertyList,
    },
    data() {
        return {
            loading: false,
            filter: {}
        }
    },
    methods: {
        setFilter(values) {
            this.filter = values
        },
        toggleBusy($event) {
            this.loading = $event
        }
    }
}
</script>

<style scoped>

</style>
