<template>
    <b-form @submit.prevent="save">
        <template v-for="(tag, tagIndex) in list">
            <b-row>
                <b-col>
                    <form-header>{{ tag.label }}</form-header>
                </b-col>
            </b-row>
            <b-table small :items="tag.policies" :fields="fields" md="3">

                <template v-slot:foc_cancelation="data">
                <span v-if="data.item.foc_cancelation === 1">
                    <i class="fa fa-check text-success" aria-hidden="true"></i>
                </span>
                    <span v-else>
                    <i class="fa fa-times fa-fw text-danger" aria-hidden="true"></i>
                </span>
                </template>
                <template v-slot:HEAD_selectableCheckbox="data">
                    <div class="tableSelectableCheckbox">
                        <b-checkbox
                            @change="selectAll($event, tagIndex)"
                            :value="true"
                            :unchecked-value="false"
                            :checked="selectAllGroupChecked(tagIndex)"
                            :disabled="selectAllGroupDisabled(tagIndex)"
                            :inline="true">
                        </b-checkbox>
                    </div>
                </template>
                <template v-slot:selectableCheckbox="data">
                    <div class="tableSelectableCheckbox">
                        <b-checkbox :ref="'system_policy_checkbox'+ data.item.id"
                                    :id="'system_policy_checkbox'+ data.item.id"
                                    :disabled="data.item.linked"
                                    @change="setSelectedItem($event, data.item)" v-model="selectedTagGroup"
                                    :value="data.item.id" :inline="true"
                        ></b-checkbox>
                    </div>
                </template>
            </b-table>
        </template>
        <app-button :loading="saving">{{ $t('APPLY_POLICY') }}</app-button>
    </b-form>
</template>

<script>
import {getPropertySystemCancelationPolicies} from '@/services/property/index'
import AppButton from '@/components/app/AppButton/AppButtonSubmit'
import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import {toast} from '@/shared/plugins/toastr'
import {applyPropertyCancelationPolicy} from "@/services/property";
import {EventBus, GE_LOADER_HIDE, GE_LOADER_SHOW} from "@/shared/EventBus";
import FormHeader from "@/components/app/form/FormHeader";

export default {
    name: "CancelationPolicySystemList",
    mixins: [getErrorMessage],
    data() {
        return {
            list: [],
            fields: [
                {key: 'selectableCheckbox', label: ''},
                {key: 'id', label: this.$t('ID'), class: 'w-10'},
                {key: 'label', label: this.$t('NAME')},
                {key: 'foc_cancelation', label: this.$t("FOC_RESERVATION_CANCELATION")},

            ],
            saving: false,
            selectedCancellationPolicy: null,
            selectedTagGroup: [],
        }
    },
    components: {
        AppButton, FormHeader
    },
    methods: {
        setSelectedItem($event, el) {
            let index = this.selectedTagGroup.indexOf(el.id)
            if (index >= 0) {
                this.selectedTagGroup.splice(index, 1)
            }
            if ($event) {
                this.selectedTagGroup.push($event)
            }
        },
        selectAll($event, tagIndex) {
            this.list[tagIndex].policies.forEach(policy => {
                this.setSelectedItem(!$event || policy.linked ? false : policy.id, policy)
            })
        },
        save() {
            this.saving = true
            applyPropertyCancelationPolicy(this.$route.params.id, {items: this.selectedTagGroup}).then(() => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
                this.saving = false
                this.$emit('save')
            }, error => {
                this.saving = false
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            })
        },
        selectAllGroupChecked(tagIndex) {
            if (this.list.length < 1) {
                return false
            }
            let disabled = true
            let checked = true
            this.list[tagIndex].policies.forEach(policy => {
                if (!policy.linked) {
                    checked = checked && this.selectedTagGroup.indexOf(policy.id) > -1
                    disabled = false
                }
            })
            return checked && !disabled
        },
        selectAllGroupDisabled(tagIndex) {
            if (this.list.length < 1) {
                return false
            }
            let disabled = true
            this.list[tagIndex].policies.forEach(policy => {
                if (!policy.linked) {
                    disabled = false
                }
            })
            return disabled
        },
    },
    created() {
        EventBus.$emit(GE_LOADER_SHOW)
        setTimeout(() => {
            EventBus.$emit(GE_LOADER_HIDE)
        }, 3000)
        getPropertySystemCancelationPolicies(this.$route.params.id).then(response => {
            this.list = response.data
        }, () => {
            this.list = []
        })
    }
}
</script>

<style scoped>
</style>
