<template>
    <div>
        <calendar-wrapper-test :display_work_mode="SINGLE_UNIT"
        ></calendar-wrapper-test>
    </div>
</template>

<script>

import {SINGLE_UNIT} from "@/shared/constants";
import CalendarWrapperTest from "@/components/calendar/CalendarWraperTest";

export default {
    name: "Index",
    components: {CalendarWrapperTest},
    data() {
        return {
            SINGLE_UNIT,
        }
    },

    computed: {
        companyId() {
            return this.$store.getters['user/getCompany']
        }
    }
}
</script>

<style scoped>

</style>
