<template>
    <form>
        <b-row>
            <b-col lg="6" cols="12" class="mb-4">
                <label>
                    {{ setupData[TITLE].label }}
                </label>
                <i id="title" class="fa fa-question-circle-o tooltip-help pull-right mr-3"></i>
                <b-tooltip target="title" triggers="hover" placement="top">
                    {{ $t('TOOLTIP_WEBSITE_WIZARD_TITLE') }}
                </b-tooltip>

                <app-input-control class="mb-2" :error-object="validationObject[TITLE]">
                    <template v-slot:input="data">
                        <b-form-input v-model="formData[TITLE].en" :state="data.state"></b-form-input>
                    </template>
                </app-input-control>

                <app-translation-list :language-list="supportedLanguages"
                                      @update="formData[TITLE]=$event"></app-translation-list>
            </b-col>

            <b-col lg="6" cols="12" class="mb-4">
                <label>
                    {{ setupData[SUBTITLE].label }}
                </label>
                <i id="subtitle" class="fa fa-question-circle-o tooltip-help pull-right mr-3"></i>
                <b-tooltip target="subtitle" triggers="hover" placement="top">
                    {{ $t('TOOLTIP_WEBSITE_WIZARD_SUBTITLE') }}
                </b-tooltip>

                <b-form-input v-model="formData[SUBTITLE].en" class="mb-2"></b-form-input>
                <app-translation-list :language-list="supportedLanguages"
                                      @update="formData[SUBTITLE]=$event"></app-translation-list>
            </b-col>
        </b-row>

        <b-row>
            <template v-for="field in ['hero_image', 'logo']">
                <b-col lg="6" cols="12" class="mb-4">
                    <label>
                        {{ $t(`${field.toUpperCase()}`) }}
                    </label>
                    <i v-if="field === 'hero_image'" :id="field" class="fa fa-question-circle-o tooltip-help pull-right mr-3"></i>
                    <b-tooltip v-if="field === 'hero_image'" :target="field" triggers="hover" placement="top">
                        {{ $t('TOOLTIP_WEBSITE_WIZARD_HERO') }}
                    </b-tooltip>

                    <div class="d-flex justify-content-between align-items-center" :style="container(field)">
                        <div v-if="preview[field]" style="width:100%; height:200px" class="mt-4 mb-4 ml-3"
                             :style="getImageStyle(preview[field])"></div>
                        <b-form-file v-else
                                     :value="formData[field]"
                                     @input="file => prepareImage(file, field)"
                                     :state="formData[field] ? true : null"
                                     accept="image/*"
                                     :placeholder="$t('UPLOAD_TEXT')"
                                     :drop-placeholder="$t('DROP')">
                        </b-form-file>
                        <app-button
                            v-if="formData[field]"
                            class="ml-3 mr-2 action_button"
                            button_type="delete"
                            :show_text="false"
                            variant="link"
                            @click="formData[field]=null;preview[field]=null">
                        </app-button>
                    </div>
                </b-col>
            </template>
        </b-row>
    </form>
</template>

<script>
import {fileHelpers} from "@/mixins/shared/helpers";
import AppButton from "@/components/app/AppButton/AppButton";
import AppTranslationList from "@/components/app/translation/AppTranslationList";
import AppInputControl from "@/components/app/AppInputControl";
import {WEBSITE_TITLE} from "@/shared/error_codes";

const TITLE = 31
const SUBTITLE = 32
const paramConstants = {
    TITLE,
    SUBTITLE,
}

export default {
    name: "WebsiteWizardKeyVisualsForm",
    components: { AppInputControl, AppTranslationList, AppButton},
    mixins: [fileHelpers],
    props: {
        setupData: {
            type: Object,
            required: true
        },
        supportedLanguages: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            ...paramConstants,
            formData: {
                [TITLE]: {en: null},
                [SUBTITLE]: {en: null},
                logo: null,
                hero_image: null,
            },
            preview: {
                hero_image: null,
                logo: null,
            },
            validationObject: {
                [TITLE]: {
                    input: [WEBSITE_TITLE]
                }
            }
        }
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler(data) {
                this.$emit('update', {key_visuals: data})
            }
        },
        supportedLanguages: {
            immediate: true,
            handler(newValues, oldValues) {
                // Add translations for languages added to list
                let added = []
                if (!oldValues || newValues.length > oldValues.length) {
                    added = !oldValues ? newValues : newValues.filter(el => !oldValues.includes(el))
                }

                // Remove translations for languages removed from list
                let removed = []
                if (oldValues && newValues.length < oldValues.length) {
                    removed = oldValues.filter(el => !newValues.includes(el))
                }

                Array.from([TITLE, SUBTITLE]).forEach(field => {
                    removed.forEach(lang => delete this.formData[field][lang])
                    added.forEach(lang => {
                        this.$set(this.formData[field], lang, null)
                    })
                })
            }
        },
    },
    methods: {
        prepareImage(file, field) {
            if (!file || !file.type.startsWith('image/')) {
                return this.$nextTick(() => {
                    this.formData[field] = null
                })
            }

            this.formData[field] = file
            this.toBase64(file).then(encodedFile => {
                this.preview[field] = encodedFile
            })
        },
        getImageStyle(url) {
            return {background: `url(${url}) no-repeat center / contain`}
        },
        container(field){
            if(this.preview[field]){
                return {
                    'border': '1px solid #e4e7ea',
                    'border-radius': ' 5px'
                }
            }
        }
    },
}
</script>

<style scoped>

</style>
