<template>
    <transition name="fade">
        <div key="form" v-if="!eventTemplateId || (eventTemplateId && formData.id)">
            <b-form @submit.prevent="save">
                <b-row>
                    <b-col class="mb-4">
                        <app-input-control :error-object="validationErrorObject.label">
                            <template #input="data">
                                <label>{{ $t('NAME') }}</label>
                                <b-form-input
                                    v-model="formData.label"
                                    :state="data.state">
                                </b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col xl="4" md="6" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.event">
                            <template #input="data">
                                <label>{{ $t('EVENT') }}</label>
                                <app-select
                                    @input="formData.recipient_type = null"
                                    :options="events"
                                    return-type="object"
                                    text-field="label"
                                    v-model="formData.event"
                                    :search-empty-item="false"
                                    :state="data.state">
                                </app-select>
                            </template>
                        </app-input-control>
                    </b-col>

                    <b-col xl="4" md="6" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.contact_channel">
                            <template #input="data">
                                <label>{{ $t('CHANNEL') }}</label>
                                <app-select
                                    @input="selectedChannel"
                                    v-model="formData.contact_channel"
                                    :disabled="!formData.event || contactChannels.length<2"
                                    :options="contactChannels"
                                    return-type="object"
                                    text-field="label"
                                    :search-empty-item="false"
                                    :state="data.state">
                                </app-select>
                            </template>
                        </app-input-control>
                    </b-col>

                    <b-col xl="4" md="6" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.recipient_type">
                            <template v-slot:input="data">
                                <label>{{ $t('RECIPIENT') }}</label>
                                <app-select
                                    :disabled="(!formData.event && formData.contact_channel) || filteredRecipients.length<2"
                                    v-model="formData.recipient_type"
                                    :options="filteredRecipients"
                                    return-type="object"
                                    text-field="label"
                                    :search-empty-item="false"
                                    :state="data.state">
                                </app-select>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>

                <form-header>{{ $t('RULES') }}</form-header>

                <b-row>
                    <template v-for="type in ['active_for', 'inactive_for']">
                        <b-col md="6" class="mb-4">
                            <label>{{ $t(type.toUpperCase()) }}</label>
                            <b-list-group>
                                <b-list-group-item
                                    v-for="(item, index) in formData[type]"
                                    class="d-flex align-items-center justify-content-between">
                                    <div>
                                        {{ item.label }}
                                        <b-badge class="ml-2" variant="primary">{{
                                                $t(item.type.toUpperCase())
                                            }}
                                        </b-badge>
                                    </div>

                                    <app-button
                                        @click="formData[type].splice(index, 1)"
                                        button_type="delete"
                                        :show_text="false"
                                        variant="link"
                                        class="action_button">
                                    </app-button>
                                </b-list-group-item>
                            </b-list-group>

                            <div class="d-flex" :class="{'mt-4': formData[type].length}">
                                <app-select
                                    class="w-100"
                                    search-input :search-empty-item="false"
                                    v-model="activeRuleForm[type]"
                                    return-type="object"
                                    text-field="label"
                                    :options="distributionsAndChannels">
                                    <template #text="data">
                                        <template v-if="data.item">
                                            {{ data.item.label }} ({{ data.item.type_label }})
                                        </template>
                                    </template>
                                </app-select>

                                <app-button
                                    @click="formData[type].push(activeRuleForm[type]);activeRuleForm[type]=null"
                                    class="ml-2"
                                    :disabled="!activeRuleForm[type] || !isActiveRuleUsable(activeRuleForm[type])">
                                    {{ $t('ADD_ITEM') }}
                                </app-button>
                            </div>
                        </b-col>
                    </template>
                </b-row>

                <form-header>{{ $t('SCHEDULED_FOR') }}</form-header>

                <b-row v-if="formData.send_points.length">
                    <b-col class="mb-4">
                        <b-list-group>
                            <b-list-group-item
                                v-for="(item, index) in formData.send_points"
                                class="d-flex align-items-center justify-content-between">
                                <div>
                                    {{ formatSendPointLabel(item) }}
                                </div>

                                <app-button
                                    @click="formData.send_points.splice(index, 1)"
                                    button_type="delete"
                                    :show_text="false"
                                    variant="link"
                                    class="action_button">
                                </app-button>
                            </b-list-group-item>
                        </b-list-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="mb-4" xl="3">
                        <label>{{ $t('WHEN') }}</label>
                        <app-select
                            @input="sendPointForm.days = null;sendPointForm.hourAndMinute = null;sendPointForm.condition = {id: null, label: null}"
                            text-field="label"
                            return-type="object"
                            v-model="sendPointForm.type"
                            :options="filteredSendPointTypes"
                            :search-empty-item="false">
                        </app-select>
                    </b-col>

                    <b-col class="mb-4" xl="3">
                        <label>{{ $t('NUM_DAYS') }}</label>
                        <app-number-input
                            :disabled="sendPointForm.type && sendPointForm.type.id === EVENT_SEND_POINT_TYPE_ON_EVENT"
                            v-model="sendPointForm.days" :min="0">
                        </app-number-input>
                    </b-col>

                    <b-col class="mb-4" xl="3">
                        <label>{{ $t('TIME') }}</label>
                        <time-picker
                            :disabled="sendPointForm.type && sendPointForm.type.id === EVENT_SEND_POINT_TYPE_ON_EVENT"
                            v-model="sendPointForm.hourAndMinute">
                        </time-picker>
                    </b-col>

                    <b-col class="mb-4" xl="3">
                        <label>{{ $t('CONDITION') }}</label>
                        <app-select
                            :disabled="sendPointForm.type && sendPointForm.type.id === EVENT_SEND_POINT_TYPE_ON_EVENT"
                            text-field="label"
                            return-type="object"
                            v-model="sendPointForm.condition"
                            :options="conditions">
                        </app-select>
                    </b-col>

                    <b-col class="mb-4 align-self-end justify-content-end d-flex">
                        <app-button
                            @click="addSendPoint(sendPointForm)"
                            :disabled="isAddSendPointDisabled">
                            {{ $t('ADD_ITEM') }}
                        </app-button>
                    </b-col>
                </b-row>

                <b-row class="mb-3">
                    <b-col class="d-flex justify-content-between align-items-center">
                        <form-header>{{ $t('CONTENT') }}
                        </form-header>
                        <app-button @click="previewTemplate"
                                    :disabled="!(formData.content && formData.subject  && formData.recipient_type)">
                            {{ $t("PREVIEW") }}
                        </app-button>
                    </b-col>
                </b-row>


                <b-row v-if="placeholderCategories.length">
                    <b-col class="mb-4">
                        <div class="border rounded">
                            <div class="d-flex justify-content-center align-items-center pt-1 pb-1 cursor-pointer"
                                 :class="{'border-bottom': placeholderDisplayState}"
                                 @click="placeholderDisplayState = !placeholderDisplayState">
                                <span class="ml-auto">Placeholders</span>
                                <i
                                    class="ml-auto mr-2 text-primary"
                                    :class="`fa fa-caret-${placeholderDisplayState ? 'up' : 'down'}`">
                                </i>
                            </div>
                            <b-collapse id="placeholders" v-model="placeholderDisplayState">
                                <div class="m-3">
                                    <template v-for="(category, categoryIndex) in placeholderCategories">
                                        <div v-if="category.placeholders.length">
                                            <label>{{ category.label }}</label>
                                            <div>
                                                <b-badge
                                                    @click="insertPlaceholder(placeholder.value)"
                                                    v-for="placeholder in category.placeholders" pill
                                                    class="mt-1 mb-1 mr-1 p-2 cursor-pointer" variant="primary">
                                                    {{ placeholder.label }}
                                                </b-badge>
                                            </div>
                                            <hr v-if="categoryIndex !== placeholderCategories.length-1">
                                        </div>
                                    </template>
                                </div>
                            </b-collapse>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <b-form-group>
                            <app-input-control :error-object="validationErrorObject.subject">
                                <template v-slot:input="data">
                                    <app-translation-input
                                        type="text"
                                        :label="$t('SUBJECT')"
                                        v-model="formData.subject"
                                        :state="data.state"
                                        :languages="supportedLanguages"
                                        :lang_id="formData.subject_lang_id"
                                        :insert-placeholder-value="subjectPlaceholder"
                                        @insertPlaceholder="insertSubjectPlaceholder"
                                        @blur="cursorField = 'subject'">
                                    </app-translation-input>
                                </template>
                            </app-input-control>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="mb-4">
                        <b-form-group>
                            <app-input-control :error-object="validationErrorObject.content">
                                <template v-slot:input="data">
                                    <app-translation-input
                                        component="rich-text"
                                        :label="$t('MESSAGE')"
                                        v-model="formData.content"
                                        :state="data.state"
                                        :languages="supportedLanguages"
                                        :rich-text-options="wysiwygEditorOptions"
                                        :max-height="true"
                                        :lang_id="formData.content_lang_id"
                                        @focus="cursorField='content'"
                                        @blur="cursorField='content'"
                                        ref="contentEditor"
                                        :insert-placeholder-value="placeholder"
                                        :disabled="contentEditorDisabled">
                                    </app-translation-input>
                                </template>
                            </app-input-control>

                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <app-button-submit :loading="saving"></app-button-submit>
                    </b-col>

                    <b-col v-if="eventTemplateId" class="d-flex justify-content-end">
                        <app-button-delete
                            @click="deleteDialogState = true">
                        </app-button-delete>

                        <app-confirmation-dialog
                            :show="deleteDialogState"
                            @confirm="_delete"
                            :delete_title="true"
                            @cancel="deleteDialogState=false">
                            <template v-if="eventTemplateTypeId === EVENT_TEMPLATE_TYPE_CONFIGURATION_TEMPLATE">
                                <b-form>
                                    <label>
                                        {{ $t('DELETE_EVENT_TEMPLATE_DIALOG') }}
                                    </label>

                                    <b-form-group>
                                        <b-form-radio v-model="deleteLinkedTemplatesFlag" :value="false">
                                            {{ $t('UNLINK_LINKED_TEMPLATES') }}
                                        </b-form-radio>
                                        <b-form-radio v-model="deleteLinkedTemplatesFlag" :value="true">
                                            {{ $t('DELETE_LINKED_TEMPLATES') }}
                                        </b-form-radio>
                                    </b-form-group>
                                </b-form>
                            </template>
                        </app-confirmation-dialog>
                    </b-col>
                </b-row>
            </b-form>
            <app-aside v-model="templatePreviewState">
                <template slot="header">
                    <app-object-header :name="$t('PREVIEW')"></app-object-header>
                </template>
                <preview-template-sidebar :previewData="previewData"></preview-template-sidebar>
            </app-aside>
        </div>
        <div key="loader" v-else class="absolute-center">
            <b-spinner variant="primary"></b-spinner>
        </div>
    </transition>
</template>

<script>
import {
    createEventTemplate, deleteEventTemplate,
    fetchEventTemplate, getEventCondition,
    getEventPlaceholders,
    getEvents,
    getEventSendPointTypes, previewEventTemplateMail,
    updateEventTemplate
} from "@/services/event";
import {
    ET_LABEL_REQUIRED,
    ET_EVENT_REQUIRED,
    ET_RECIPIENT_TYPE_REQUIRED,
    ET_SUBJECT_REQUIRED,
    ET_CONTENT_REQUIRED,
    ET_CONTACT_CHANNEL_REQUIRED,
} from "@/shared/error_codes";
import {unionBy as _unionBy} from 'lodash';
import {getChannelList, getDistributionList} from "@/services/distribution";
import {
    BOOKING_ENGINE,
    EVENT_SEND_POINT_TYPE_ON_EVENT,
    EVENT_TEMPLATE_TYPE_CONFIGURATION_TEMPLATE, MOBILE_APP
} from "@/shared/constants";
import moment from 'moment'
import {dataFormatters} from "@/mixins/shared/helpers";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {getSystemLanguageList} from "@/services/system";
import {cloneDeep} from "lodash";
import {getContactChannelList} from "@/services/owner";

// treba poslat prazan objekt da se omoguci sve opcije
const wysiwygEditorOptions = {
    // modules: {
    //     toolbar: {
    //         container: [
    //             'bold', 'italic', 'link', {script: 'super'}, {script: 'sub'}, 'strike', 'underline', 'clean',
    //             {header: [1, 2, 3, 4, 5, 6, false]}, {list: 'ordered'}, {list: 'bullet'},
    //             {align: []}, {direction: 'rtl'}
    //         ],
    //         handlers: {}
    //     },
    // },
    // formats: [
    //     'bold', 'italic', 'link', 'script', 'strike', 'underline', 'clean', 'header', 'list', 'direction', 'align'
    // ]
}

export default {
    name: "EventTemplateForm",
    mixins: [dataFormatters, getErrorMessage],
    components: {
        "AppTranslationInput": () => import("@/components/app/translation/AppTranslationInput"),
        "AppConfirmationDialog": () => import("@/components/app/form/AppConfirmationDialog"),
        "AppButtonDelete": () => import("@/components/app/AppButton/AppButtonDelete"),
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "TimePicker": () => import("@/components/app/datetime/TimePicker"),
        "AppNumberInput": () => import("@/components/app/form/AppNumberInput"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "AppWysiwygEditor": () => import("@/components/app/AppWysiwygEditor"),
        "FormHeader": () => import("@/components/app/form/FormHeader"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "AppInputControl": () => import("@/components/app/AppInputControl"),
        "PreviewTemplateSidebar": () => import("@/components/event/PreviewTemplateSidebar"),
        "AppAside": () => import("@/components/app/form/AppAside"),
        "AppObjectHeader": () => import("@/components/app/AppObjectHeader")
    },
    props: {
        eventTemplateId: {
            type: Number,
            default: null
        },
        /**
         * REQUIRED IF - eventTemplateId IS NULL
         */
        eventTemplateTypeId: {
            type: Number
        },
        propertyId: {
            type: Number,
            default: null
        },
        eventId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            EVENT_SEND_POINT_TYPE_ON_EVENT,
            wysiwygEditorOptions,
            formData: {
                id: null,
                type: null,
                label: null,
                event: null,
                linked_to: null,
                contact_channel: null,
                recipient_type: null,
                active: false,
                send_points: [],
                active_for: [],
                inactive_for: [],
                subject: null,
                content: null,
                condition: null
            },
            validationErrorObject: {
                label: ET_LABEL_REQUIRED,
                event: ET_EVENT_REQUIRED,
                recipient_type: ET_RECIPIENT_TYPE_REQUIRED,
                subject: ET_SUBJECT_REQUIRED,
                content: {}, // ET_CONTENT_REQUIRED,
                contact_channel: ET_CONTACT_CHANNEL_REQUIRED,
            },
            activeRuleForm: {
                active_for: null,
                inactive_for: null,
            },
            sendPointForm: {
                days: null,
                hourAndMinute: null,
                type: null,
                condition: {id: null, label: null}
            },
            contentEditorDisabled: true,
            events: [],
            sendPointTypes: [],
            channels: [],
            distributions: [],
            placeholderCategories: [],
            placeholderDisplayState: false,
            cursorField: 'subject',
            saving: false,
            deleteDialogState: false,
            deleteLinkedTemplatesFlag: false,
            EVENT_TEMPLATE_TYPE_CONFIGURATION_TEMPLATE,
            templatePreviewState: false,
            previewData: null,
            supportedLanguages: [],
            placeholder: null,
            subjectPlaceholder: null,
            contactChannels: [],
            conditions: [],
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        filteredChannels() {
            let filteredChannels = this.formData.event ? this.formData.event.supported_channels : []
            if (!this.eventTemplateId && filteredChannels.length === 1) {
                this.formData.contact_channel = filteredChannels[0]
            }

            return filteredChannels
        },
        filteredEvents() {
            return this.formData.contact_channel && this.events.length
                ? this.events.filter(el => {
                    return el.supported_channels.find(el => this.formData.contact_channel.id === el.id)
                })
                : []
        },
        filteredRecipients() {
            let filteredRecipients = this.formData.event && this.formData.contact_channel && this.filteredEvents.length
                ? this.filteredEvents.find(el => {
                    return el.id === this.formData.event.id
                }).supported_channels.find(el => {
                    return el.id === this.formData.contact_channel.id
                }).supported_recipients
                : []

            if (filteredRecipients.length === 1) {
                this.formData.recipient_type = filteredRecipients[0]
            }

            return filteredRecipients
        },
        distributionsAndChannels() {
            // List of all distributions and channels which have
            // not already been added to the active/inactive list
            return this.distributions
                .concat(this.channels)
                .filter(el => {
                    return !this.formData.active_for.find(active => {
                        return active.id === el.id && active.type === el.type
                    }) && !this.formData.inactive_for.find(inactive => {
                        return inactive.id === el.id && inactive.type === el.type
                    })
                })
        },
        isAddSendPointDisabled() {
            if (this.sendPointForm.type && this.sendPointForm.type.id === EVENT_SEND_POINT_TYPE_ON_EVENT) {
                return false
            } else {
                return this.sendPointForm.days === null || !this.sendPointForm.hourAndMinute || !this.sendPointForm.type
            }
        },
        filteredSendPointTypes() {
            // List of send point types excluding `on event` type if used already
            const onEvent = this.formData.send_points.find(el => el.type.id === EVENT_SEND_POINT_TYPE_ON_EVENT)
            return !onEvent ? this.sendPointTypes : this.sendPointTypes.filter(el => el.id !== EVENT_SEND_POINT_TYPE_ON_EVENT)
        },
    },
    watch: {
        'formData.event': {
            immediate: true,
            handler(event) {
                if (!event) {
                    this.placeholderCategories = []
                    return
                }

                getEventPlaceholders(event.id).then(response => {
                    this.placeholderCategories = response.data
                    this.$nextTick(() => {
                        this.placeholderDisplayState = true
                    })
                })

                getEventCondition({event: event.id}).then(response => {
                    this.conditions = response.data
                })
                this.getContactChannels()
            }
        },
    },
    methods: {
        isActiveRuleUsable(activeRule) {
            return this.distributionsAndChannels.find(el => {
                return el.id === activeRule.id && el.type === activeRule.type
            })
        },
        formatSendPointLabel({type, days, hour, minute, condition}) {
            if (type.id === EVENT_SEND_POINT_TYPE_ON_EVENT) {
                return type.label
            } else {
                const time = moment().set({hour, minute}).format('LT')
                let label = `${days} ${this.$tc('COUNTABLE.DAY', days)} ${type.label} ${this.$t('AT')} ${time}`.toLowerCase()

                if (condition && condition.label) {
                    label += " - " + condition.label
                }
                return label
            }
        },
        addSendPoint({type, days, hourAndMinute, condition}) {
            const [hour, minute] = hourAndMinute ? hourAndMinute.split(':') : [null, null]

            this.formData.send_points.push({
                type, days, hour, minute, condition
            })

            this.sendPointForm = {
                days: null, hourAndMinute: null, type: null, condition: {id: null, label: null}
            }
        },
        insertPlaceholder(value) {
            if (this.cursorField === 'subject') {
                this.subjectPlaceholder = cloneDeep(value)
                this.$nextTick(() => {
                    this.subjectPlaceholder = null
                })
            } else {
                this.placeholder = cloneDeep(value);
                this.$nextTick(() => {
                    this.placeholder = null
                })
            }
        },
        insertSubjectPlaceholder(args) {
            const currentValue = this.formData.subject || ''
            this.formData.subject = `${currentValue.slice(0, args.focus_location)}${args.placeholder_value}${currentValue.slice(args.focus_location)}`
        },
        save() {
            this.saving = true

            const data = this.formatObjectKeyNamePairs(this.formData, {
                includedFields: ['contact_channel', 'recipient_type', 'type', 'event']
            })

            data.company = this.company.id
            data.property = this.propertyId
            data.type = this.eventTemplateTypeId

            const savePromise = this.eventTemplateId
                ? updateEventTemplate(this.eventTemplateId, data)
                : createEventTemplate(data)

            savePromise
                .then(() => {
                    this.$emit('saved')
                })
                .catch(error => this.showErrorMessages(error, this.validationErrorObject))
                .finally(() => this.saving = false)
        },
        _delete() {
            this.deleteDialogState = false
            this.saving = true
            deleteEventTemplate(this.eventTemplateId, {delete_linked: this.deleteLinkedTemplatesFlag})
                .then(() => {
                    this.$emit('saved')
                })
                .catch(error => this.showErrorMessages(error))
                .finally(() => this.saving = false)
        },
        previewTemplate() {
            let req = {
                send_to: this.formData.recipient_type.id,
                subject: this.formData.subject,
                content: this.formData.content,
                event_id: this.formData.event.id,
                company: this.company.id
            }

            previewEventTemplateMail(req).then(res => {
                this.previewData = res.data
            })
            this.templatePreviewState = true
        },
        getContactChannels() {
            if (!this.eventTemplateId) {
                this.formData.contact_channel = null
            }
            let req = {
                excluded: MOBILE_APP,
                event: this.formData.event ? this.formData.event.id : null
            }
            getContactChannelList(req).then(res => {
                this.contactChannels = res.data
                if (!this.eventTemplateId && this.contactChannels.length === 1) {
                    this.formData.contact_channel = this.contactChannels[0]
                }
            }, () => {
                this.contactChannels = []
            })
        },
        selectedChannel() {
            if (!this.eventTemplateId) {
                this.formData.recipient_type = null
            }
        }
    },
    created() {
        getEventSendPointTypes().then(response => this.sendPointTypes = response.data)

        getDistributionList({company: this.company.id, sorted: true})
            .then(response => this.distributions = response.data.map(el => (
                {id: el.id, label: el.name, type: 'distribution', type_label:this.$t('DISTRIBUTION')}
            )))

        getChannelList({
            company: this.company.id,
            excluded: [BOOKING_ENGINE]
        })
            .then(response => this.channels = response.data.map(el => (
                {id: el.id, label: el.name, type: 'channel',type_label: this.$t('SALES_CHANNEL')}
            )))

        getEvents({company: this.company.id}).then(response => {
            this.events = response.data
            if (this.eventId) {
                this.formData.event = this.events.find(el =>
                    el.id === this.eventId
                )
            }
        })


        if (this.eventTemplateId) {
            fetchEventTemplate(this.eventTemplateId).then(response => {
                this.formData = response.data

                /**
                 * Bugfix for this issue, we mitigate it by disabling editor until load
                 * https://github.com/zenoamaro/react-quill/issues/317
                 * @type {boolean}
                 */
                this.contentEditorDisabled = false
            })
        } else {
            this.contentEditorDisabled = false
        }

        getSystemLanguageList().then(response => {
            response.data.forEach(el => {
                this.supportedLanguages.push(el.name)
            })
        })

        this.getContactChannels()
    }
}
</script>

<style scoped>

</style>
