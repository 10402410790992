<template>
    <b-card v-cloak class="property-wrapper">
        <template slot="header">
            <div class="d-flex justify-content-between flex-column flex-md-row">
                <div>
                    <h2>{{ $t("DISTRIBUTION_SETUP") }}</h2>
                </div>

                <div v-if="distribution">
                    <div class="header-caption pl-0 pl-md-2 mt-3 mt-md-0">
                        {{ $t("DISTRIBUTION") }}
                    </div>
                    <app-quick-link
                        :value="distribution.id"
                        :routes="distributionLinks">
                    </app-quick-link>
                </div>
            </div>

            <navigation
                :nav-elements="navElements"
                :back="backButton">
            </navigation>
        </template>

        <router-view>
        </router-view>
    </b-card>
</template>

<script>
import AppQuickLink from "@/components/app/AppQuickLink";
import Navigation from "@/components/app/navigation/Navigation";
import {getDistribution, getDistributionList} from "@/services/distribution";
import routeNames from "@/router/routeNames";

export default {
    name: "Index",
    components: {
        Navigation,
        AppQuickLink,
    },
    data() {
        return {
            distribution: null,
            distributionList: [],
        }
    },
    methods: {
        async getDistributionList() {
            const request = {
                company: this.company.id,
                for_reservation_process: 1,
            }
            await getDistributionList(request).then(response => {
                this.distributionList = response.data
            });
        }
    },
    computed: {
        navElements() {
            return [
                {route: {name: routeNames.RN_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION_PROPERTY}},
                {route: {name: routeNames.RN_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION_UNIT}},
            ]
        },
        backButton() {
            return {name: routeNames.RN_SETUP_FEES_PLATFORM_FEE}
        },
        company() {
            return this.$store.getters["user/getCurrentCompany"]
        },
        distributionLinks() {
            return this.distributionList.map(distribution => {
                return {
                    id: distribution.id,
                    name: this.$route.name,
                    label: distribution.name,
                    params: {
                        distribution_id: distribution.id,
                    },
                }
            })
        },
    },
    watch: {
        "$route.params.distribution_id": {
            handler(value) {
                if (value) {
                    getDistribution(value).then((response) => {
                        this.distribution = response.data
                    })
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.getDistributionList()
    },
}
</script>

<style scoped>

</style>
