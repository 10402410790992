<template>
<div>
    <b-dropdown size="sm"  variant="primary">
        <template slot="button-content">
            <i class="fa fa-cogs fa-fw" aria-hidden="true"></i>
            {{currentDistribution ? currentDistribution.name :""}}
        </template>
        <b-dropdown-item :key="link.id" :variant="link.id == $route.params.distribution_id ? 'primary' : null"
                         @click="change(link.id)"
                         v-for="link in getDistributionList" href="#">{{link.name}}
        </b-dropdown-item>
    </b-dropdown>
</div>
</template>

<script>
  export default {
    name: "distribution-quick-link",
    data(){
      return {
        list:[]
      }
    },
    computed:{
      currentDistribution() {
        return this.$store.getters['channel_manager/getDistributionById'](Number(this.$route.params.distribution_id))
      },
      getDistributionList(){
        let distributionList =  this.$store.getters['channel_manager/getDistributionList']
        let distributions = []
        if(distributionList.length > 0){
          for (let distributionId of distributionList){
            let distribution = this.$store.getters['channel_manager/getDistributionById'](distributionId)
            distributions.push(distribution)
          }
        }
        return distributions
      },
        getCompanyId(){
            return this.$store.getters['user/getCompany']
        }
    },
    methods: {
      change(value) {
        this.$router.push({name: this.$route.name, params: {distribution_id: value}})
      }
    },
    watch: {
      '$route.params.id': {
        handler(value) {
          this.$store.dispatch('channel_manager/actionSetFilterDistributionList', {company: Number(this.getCompanyId())})
        },

      }
    }
  }
</script>

<style scoped>

</style>
