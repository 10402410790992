<template>
  <b-row class="mt-4 mb-4">
    <b-col md="5">
      <b-input v-model="mappingPropertyData.property_eid"></b-input>
    </b-col>
    <b-col md="2">
      <delete-property-mapping v-if="edited === false" :c_permission="c_permission_property_edit"  :button_type="deleteState ? 'loading': 'delete'"
                               @click.native.prevent="showDeleteDialog=true"></delete-property-mapping>

      <submit-property-map v-else @click.native="save"></submit-property-map>

    </b-col>
    <property-mapping-delete-dialog :show="showDeleteDialog" @confirm="deleteMapping"
                                    @cancel="showDeleteDialog=false" :delete_title="true"></property-mapping-delete-dialog>
  </b-row>
</template>

<script>
  import SubmitPropertyMap from "@/components/app/AppButton/AppButtonSubmit"
  import {
   C_PROPERTY_CM_PM_PROPERTY_E
  } from "@/shared/component_permission";

  import DeletePropertyMapping from '@/components/app/AppButton/AppButtonDelete'
  import PropertyMappingDeleteDialog from '@/components/app/form/AppConfirmationDialog'
  export default {
    name: "property-product-management-edit",
    data() {
      return {
        mappingPropertyData: {
          id:null,
          property_eid: null
        },
        showDeleteDialog:false,
        deleteState: false,
        c_permission_property_edit:C_PROPERTY_CM_PM_PROPERTY_E,
      }
    },
    props:{
      mappingProperty:{
        type:Object,
        default: () => {
          return {
            id: null,
            property_eid: null,
          }
        }
      }
    },
    components: {
      SubmitPropertyMap,
      DeletePropertyMapping,
      PropertyMappingDeleteDialog
    },
    computed:{
      edited(){
        if ((this.mappingPropertyData.property_eid !== this.mappingProperty.property_eid) || (!this.mappingPropertyData.property_eid )) {
          return true
        }
        return false
      },
      propertyObject() {
        return this.$store.getters['property/getProperty']
      },
    },
    methods: {
      save() {
        this.$emit('saveMapping', this.mappingPropertyData.property_eid)
      },
      deleteMapping(){
        this.showDeleteDialog = false
        this.$emit('deleteMapping', this.mappingPropertyData.id)
      }

    },
    created(){
      this.mappingPropertyData.id = this.mappingProperty.id
      this.mappingPropertyData.property_eid = this.mappingProperty.property_eid
    }

  }
</script>

<style scoped>

</style>
