<template>
    <h5 :class="headerClass">
        <slot></slot>
    </h5>
</template>

<script>
    export default {
        name: "FormHeader",
        props: {
            headerClass: {
                type: String,
                default: 'mb-4 mt-4'
            }
        }
    }
</script>

<style scoped>

</style>
