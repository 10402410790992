import { render, staticRenderFns } from "./UserAccessFilter.vue?vue&type=template&id=bb6adbae&scoped=true&"
import script from "./UserAccessFilter.vue?vue&type=script&lang=js&"
export * from "./UserAccessFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb6adbae",
  null
  
)

export default component.exports