<template functional>
    <div class="main_calendar_test__cell main_calendar_test__holder--content_pricing_cell"
         @click.shift.exact="listeners.drag"
         @click.exact="listeners.startEndDrag"
         @mouseover.shift="listeners.currentDrag"
         :class="{'main_calendar__bg-hover': props.highlighted, ...props.cellData.lastDayOfMonthClass}"
    >
        {{props.cellData.price}}
        <div v-if="props.cellData.starting_price" class="justify-content-end pb-4 pr-1 main_calendar_test_numeric-indicator" style="font-size: 0.5rem">
        <span>
            {{props.cellData.starting_price}}
        </span>
            </div>
    </div>
</template>

<script>

    export default {
        name: "TableRatesCell",
        props: {
            cellData: {
                type: Object
            },
            highlighted: {
                type: Boolean
            }

        }

    }
</script>

<style scoped>

</style>
