<template>
    <form class="filter" autocomplete="off">
        <b-row>
            <b-col md="4" class="mb-4">
                <app-input-control :error-object="NO_TAG_ITEM_SELECTED">
                    <template v-slot:input="data">
                        <label>{{$t('TAG')}}</label>
                        <app-select text-field="label" :options="tagOptions"
                                    v-model="selectedTags" mode="multiselect" :state="data.state">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>
            <div class="w-100"></div>
            <b-col md="2" class="mb-3" v-if="mode !== 'withoutSave'">
                <app-button :disabled="loading" :loading="loading"
                                   @click="save">{{$t("SAVE") }}
                </app-button>
            </b-col>
        </b-row>
        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="continueAction"
            @cancel="confirmation_dialog_state=false"
            :title="$t('CUSTOM_TAGGING.TITLE')">
            <p>{{$t('CUSTOM_TAGGING.MSG')}}</p>
            <p>{{$t('CUSTOM_TAGGING.MSG_CONF')}}</p>
        </app-confirmation-dialog>
    </form>
</template>

<script>

import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButton from "@/components/app/AppButton/AppButton";
import AppInputControl from "@/components/app/AppInputControl";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {bulkSaveEntityTags, getAllTags, getExistingTagsList} from "@/services/tag";
import {OBJECT_TYPE_PROPERTY, OBJECT_TYPE_UNIT, TAG_TYPE_CUSTOM} from '@/shared/constants'
import {NO_TAG_ITEM_SELECTED} from '@/shared/error_codes'
import {notifySuccess} from "@/shared/plugins/toastr";
import {getWebsiteTags} from "@/services/direct_booking/website";
import {EventBus} from "@/shared/EventBus";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "CustomTaggingForm",
    mixins:[getErrorMessage],
    components:{
        AppSelect,
        AppButtonSubmit,
        AppInputControl,
        AppConfirmationDialog,
        AppButton
    },

    props:{
        selected:{
            type:Array
        },
        distribution_id:{
            type: Number,
            default: null
        },
        unit_id:{
            type:Number,
            default: null
        },
        mode:{
            type:String,
            default: null
        }
    },

    data(){
        return{
            selectedTags: [],
            tagOptions: [],
            selectedUnits: [],
            loading: false,
            company: this.$store.getters['user/getCurrentCompany'],
            confirmation_dialog_state: false,
            isSame: true,
            checkTagList: false,
            NO_TAG_ITEM_SELECTED
        }
    },

    methods:{
        save(){
            if (this.isSame === false){
                this.confirmation_dialog_state = true
            }else{
                this.loading = true
                bulkSaveEntityTags(OBJECT_TYPE_UNIT, {entity_id: this.selectedUnits, list:this.selectedTags, website_tags:true}).then(response => {
                    notifySuccess()
                    this.$emit('customSaved')
                    this.loading = false
                }, error => {
                    this.loading = false
                    this.showErrorMessages(error)
                })
            }
        },
        continueAction(){
            this.confirmation_dialog_state = false
            this.isSame = true
            this.save()
        },
    },
    watch:{
        checkTagList:{
            handler(){
                if (this.checkTagList){
                    getAllTags(TAG_TYPE_CUSTOM, {company: this.company.id, children_only: true}).then(response => {
                        this.tagOptions = response.data
                    })
                }
            }
        }
    },
    created() {
        getWebsiteTags({distribution_id: this.distribution_id, tag_type:TAG_TYPE_CUSTOM, company_id:this.company.id}).then(response => {
            if (response.data.length > 0){
                this.tagOptions = response.data
            }else {
                this.checkTagList = true
            }
        })
        if (this.unit_id){
            this.selectedUnits = [this.unit_id]
        }else{
            this.selected.forEach(el => {
                this.selectedUnits.push(el.id)
            })
        }
        getExistingTagsList(OBJECT_TYPE_UNIT, {entity_id: this.selectedUnits, tag_type_id: TAG_TYPE_CUSTOM}).then(response => {
            let data = response.data.length
            // if (data > 0){
            //     this.isSame = false
            // }
        })
    },
    mounted(){
        EventBus.$on('saveCustomTaggingForm', () => {
            this.save()
        })
    }

}
</script>

<style scoped>

</style>
