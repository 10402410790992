<template>
    <div>
        <promo-category-table></promo-category-table>
    </div>
</template>

<script>

import PromoCategoryTable from "@/components/direct_booking/promo_codes/category/PromoCategoryTable";

export default {
    name: "Index",
    components:{PromoCategoryTable},
}
</script>

<style scoped>

</style>
