<template>
    <form @submit.prevent="search" @reset.prevent="reset" class="filter">
        <b-row>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('NUMBER')}} / {{$t('DESCRIPTION')}}</label>
                <b-input v-model="filter.keyword"></b-input>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('TYPE')}}</label>
                <app-select mode="multiselect"
                            :options="invoiceSubtypeList"
                            v-model="filter.invoice_subtype"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("_DATE")}}</label>
                <app-select :options="dateOptions" v-model="dateOptionValue"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("FROM")}}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="fromDate" :max-date="toDate"></app-date-picker>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("TO")}}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="toDate" :min-date="fromDate"
                                 :from-date="fromDate"></app-date-picker>
            </b-col>

            <b-col class="mb-3" xl="2" lg="4" md="6">
                <label>{{$t('STATUS')}}</label>
                <app-select mode="multiselect"
                            :options="statusList"
                            v-model="filter.status" text-field="label"></app-select>
            </b-col>

            <app-access-control xl="2" lg="4" md="6" @AccessControlEvent="addToAccessControlCounter"
                                :access_control_context="{function:C_INVOICE_FIELDS_PAYMENT_TYPE,  key:access_control_fetch_key}" @updated="setSelectable">
                <label>{{$t('PAYMENT_TYPE')}}</label>
                <app-select mode="multiselect" :options="paymentTypeList"
                            v-model="filter.payment_type"
                            text-field="label">
                </app-select>
            </app-access-control>
            <b-col md="3" class="filter-submit-wrapper mb-3">
                <app-button-submit class="" :inline="true" :loading="loading" button_type="search">
                    {{$t("SEARCH") }}
                </app-button-submit>
                <app-button-reset :loading="loading" :inline="true" class="pl-2 ml-2"></app-button-reset>
            </b-col>
        </b-row>
    </form>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {cloneDeep} from "lodash";
    import {EventBus} from "@/shared/EventBus";
    import {INVOICE_STATUS_PAID, INVOICE_STATUS_COMPLETED, INVOICE_STATUS_CANCELED} from '@/shared/constants'
    import {F_HAS_OWN_CASH_REGISTER} from "@/shared/function_permission";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
    import {getInvoiceTypeList, getPaymentType} from "@/services/finance/setup";
    import AppDatePicker from "@/components/app/datetime/AppDatePicker";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {C_INVOICE_FIELDS_PAYMENT_TYPE} from "@/shared/component_permission";
    import {AC_INVOICE_NEW} from "@/mixins/AccessControl/AccessControlEnumeration";


    export default {
        name: "OwnerInvoiceFilter",
        components: {AppAccessControl, AppDatePicker, AppButtonReset, AppSelect, AppButtonSubmit},
        mixins: [AccessControlComponent],
        data() {
            return {
                filter: {
                    status: [],
                    invoice_subtype: [],
                    payment_type: [],
                    owner_export: true //override prilikom odabira filtera za export
                },
                initialFilter: {},
                loading: false,
                invoiceSubtypeList: [],
                dateOptionValue: null,
                fromDate: null,
                toDate: null,
                dateOptions: [
                    {id: 'date_created', name: this.$t('DATE_CREATED')},
                    {id: 'delivery_date', name: this.$t('DELIVERY_DATE')},
                    {id: 'due_date', name: this.$t('DUE_DATE')}
                ],
                access_control_fetch_key: AC_INVOICE_NEW,
                access_control_components: 1,
                access_control_general_context: null,
                C_INVOICE_FIELDS_PAYMENT_TYPE,
                paymentTypeList: [],
            }
        },
        computed: {
            statusList() {
                let list = [
                    {
                        id: INVOICE_STATUS_PAID,
                        label: this.$t("PAID")
                    },
                    {
                        id: INVOICE_STATUS_COMPLETED,
                        label: this.$t("UNPAID")
                    },
                ]

                if (this.checkPermission(F_HAS_OWN_CASH_REGISTER)) {
                    list.push({
                        id: INVOICE_STATUS_CANCELED,
                        label: this.$t("CANCELED")
                    })
                }

                return list
            },
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            dateObject() {
                return this.dateOptionValue ? {
                    [`${this.dateOptionValue}_from`]: this.fromDate,
                    [`${this.dateOptionValue}_to`]: this.toDate,
                } : {}
            }
        },
        watch: {
            status(value) {
                if (value) {
                    this.filter.status = value.id
                }
            },
            dateOptionValue(value, oldValue) {
                if (oldValue !== null) {
                    this.fromDate = null
                    this.toDate = null
                }
            },
        },
        methods: {
            search() {
                EventBus.$emit('invoiceSearch', {...this.filter, ...this.dateObject})
            },
            reset() {
                this.filter = cloneDeep(this.initialFilter)
                this.dateOptionValue = null
                this.search()
            },
            setSelectable(data) {
                // POSTAVLJANJE JE LI EXPORTABLE LISTA  OVISNO O TOME JE LI ETREND ILI NIJE
                // znam da je užas živi, ali ovako smo izbjegli pozivanje istog endpointa dva puta (tablica i filter)
                // TODO refaktorirat
                this.$emit('selectableChanged', data.visible)
            }
        },
        created() {
            this.access_control_general_context = {
                company: this.company.id
            }

            this.initialFilter = cloneDeep(this.filter)
            EventBus.$on('invoiceLoading', (value) => {
                this.loading = value
            })
            getInvoiceTypeList({
                company: this.company.id
            }).then(response => {
                this.invoiceSubtypeList = response.data.sort((a, b) => a.name.localeCompare(b.name))
            })

            getPaymentType({company: this.company.id}).then(response => {
                this.paymentTypeList = response.data
            })
        },
        beforeDestroy() {
            EventBus.$off('invoiceLoading')
        }
    }
</script>

<style scoped>

</style>
