<template>
    <div v-if="actions !== null && hasPermissionView">
        <b-form @submit.prevent="saveAction">
            <b-row>
                <b-col cols="7" class="mb-4" :key="questionIndex" v-for="(question, questionIndex) in actions.questions">
                    <label>{{question.label}}</label>
                    <app-select v-model="question.value" :options="actions.solutions" text-field="label"></app-select>
                </b-col>
            </b-row>
            <save-cc-action-button :c_permission="c_permission_e" :button_type="saveState ? 'loading' : 'save'"></save-cc-action-button>
        </b-form>
    </div>
</template>

<script>

    import SaveCcActionButton from '@/components/app/AppButton/AppButtonSubmit'
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {getPropertyDeclinedCcActions, setPropertyDeclinedCcActions} from '@/services/property/index'
    import {C_PROPERTY_DECLINED_CC_E, C_PROPERTY_DECLINED_CC_V} from "@/shared/component_permission";
    import AppSelect from "@/components/app/AppSelect/AppSelect";

    export default {
        name: "Index",
        mixins: [getErrorMessage],
        components: {
            AppSelect,
            SaveCcActionButton
        },
        data() {
            return {
                saveState: false,
                c_permission_e: C_PROPERTY_DECLINED_CC_E,
                actions: {
                    solutions: [],
                    questions: []
                }
            }
        },
        created() {
            getPropertyDeclinedCcActions(this.$route.params.id).then((response) => {
                this.actions = response.data
            })
        },
        methods: {
            saveAction() {
                this.saveState = true
                setPropertyDeclinedCcActions(this.$route.params.id, {questions: this.actions.questions}).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.DELETE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.DELETE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.DELETE_ERROR.TITLE'),
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 2000
                        })
                    }
                ).finally(
                    this.saveState = false
                )
            }
        },
        computed: {
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_DECLINED_CC_V)
            }
        }
    }
</script>

<style scoped>

</style>
