import http from '../http'
import {
    PROPERTY_OWNER_LIST_URL,
    PROPERTY_OWNER_URL,
    PROPERTY_NEW_OWNER_CONTACT_URL,
    PROPERTY_OWNER_TYPE_URL,
    PROPERTY_OWNER_CHANNEL_URL,
    PROPERTY_EDIT_NEW_OWNER_CONTACT_URL,
    API_URL
} from '@/services/endpoints'

export function getPropertyOwners(id) {
    return http.request({
        url: PROPERTY_OWNER_LIST_URL(id),
        method: 'get',
    })
}

export function getOwner(propertyId, ownerId) {
    return http.request({
        url: PROPERTY_OWNER_URL(propertyId, ownerId),
        method: 'get',
    })
}

export function createOwner(propertyId, data) {
    return http.request({
        url: PROPERTY_OWNER_URL(propertyId, null),
        method: 'post',
        data: data
    })
}

export function updateOwner(propertyId, ownerId, data) {
    return http.request({
        url: PROPERTY_OWNER_URL(propertyId, ownerId),
        method: 'put',
        data: data
    })
}

export function deleteOwner(propertyId, ownerId) {
    return http.request({
        url: PROPERTY_OWNER_URL(propertyId, ownerId),
        method: 'delete'
    })
}

export function getOwnerContactList(propertyId, ownerId) {
    return http.request({
        url: PROPERTY_OWNER_URL(propertyId, ownerId) + '/contacts',
        method: 'get',
    })
}

export function updateOwnerContact(propertyId, ownerId, contactId, data) {
    return http.request({
        url: PROPERTY_OWNER_URL(propertyId, ownerId) + '/contacts/' + contactId,
        method: 'put',
        data: data
    })
}
export function deleteOwnerContact(propertyId, ownerId, contactId) {
    return http.request({
        url: PROPERTY_OWNER_URL(propertyId, ownerId) + '/contacts/' + contactId,
        method: 'delete',
    })
}

export function createOwnerUser(propertyId, ownerId, data) {
    return http.request({
        url: PROPERTY_OWNER_URL(propertyId, ownerId) + '/user',
        method: 'post',
        data: data
    })
}

export function resetOwnerUser(propertyId, ownerId) {
    return http.request({
        url: PROPERTY_OWNER_URL(propertyId, ownerId) + '/user/reset',
        method: 'post',
    })
}
export function getNewOwnerContactList(params){
  return http.request({
    url:PROPERTY_NEW_OWNER_CONTACT_URL,
    method:'get',
    params:params
  })
}

export function getContactTypeList(params) {
  return http.request({
    url:PROPERTY_OWNER_TYPE_URL,
    method:'get',
    params:params
  })
}
export function  getContactChannelList(params) {
  return http.request({
    url:PROPERTY_OWNER_CHANNEL_URL,
    method:'get',
    params:params
  })
}
export function createNewContact(data){
  return http.request({
    url:PROPERTY_NEW_OWNER_CONTACT_URL,
    method:'post',
    data: data
  })
}
export function deleteNewContact(contactId) {
  return http.request({
    url:PROPERTY_EDIT_NEW_OWNER_CONTACT_URL(contactId),
    method:'delete',

  })
}
export function updateNewContact(contactId, postData) {
  return http.request({
    url:PROPERTY_EDIT_NEW_OWNER_CONTACT_URL(contactId),
    method:'put',
    data:postData
  })
}

export function getOwnerList(params) {
    return http.request({
        url: API_URL + '/owner/list',
        method: 'get',
        params:params
    })
}

export function getPaginatedOwnerList(params) {
    return http.request({
        url: API_URL + '/owner/paginate-list',
        method: 'get',
        params:params
    })
}
