<template>
    <marketplace-grid-list></marketplace-grid-list>
</template>

<script>

import MarketplaceGridList from "@/components/direct_booking/marketplace/list/MarketplaceGridList";

export default {
    name: "Index",
    components:{MarketplaceGridList}
}
</script>

<style scoped>

</style>
