<template>
    <div class="dashboard-wrapper">
        <b-row>
            <b-col lg="6" xl="6" class="col-xxl-8">
                <b-row>
                    <b-col class="col-xxl-6" v-if="checkPermission(C_TOTAL_INCOME_V)">
                        <dashboard-income :property="Number($route.params.id)"></dashboard-income>
                    </b-col>
                    <b-col class="col-xxl-6" v-if="checkPermission(C_NIGHTS_V)">
                        <dashboard-number-of-nights :property="Number($route.params.id)"></dashboard-number-of-nights>
                    </b-col>
                    <b-col cols="12" v-if="checkPermission(C_CHECKIN_RESERVATIONS_V)">
                        <dashboard-checkin-reservation-list
                            :property="Number($route.params.id)" :company="company"></dashboard-checkin-reservation-list>
                    </b-col>
                    <b-col cols="12" v-if="checkPermission(C_CHECKOUT_RESERVATIONS_V)">
                        <dashboard-checkout-reservation-list
                            :property="Number($route.params.id)" :company="company"></dashboard-checkout-reservation-list>
                    </b-col>

                    <b-col cols="12" v-if="checkPermission(C_LATEST_RESERVATIONS_V)">
                        <dashboard-latest-reservation-list
                            :property="Number($route.params.id)" :company="company"></dashboard-latest-reservation-list>
                    </b-col>
                </b-row>
            </b-col>
            <b-col>
                <b-row>
                    <b-col cols="12">
                        <property-button-grid @AccessControlEvent="addToAccessControlCounter()"
                                              :property-id="Number($route.params.id)"></property-button-grid>
                    </b-col>
                    <app-access-control tag="b-col" cols="12" @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{function:C_PROPERTY_STATUS_V, key:AC_PROPERTY_OVERVIEW}">
                        <property-status v-if="showStatusComponent && showStatus"
                                         @savingPrice="priceLoader"></property-status>
                    </app-access-control>

                    <app-access-control tag="b-col" cols="12" @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{function:C_PROPERTY_PDF_V, key:AC_PROPERTY_OVERVIEW}">
                        <property-pdf  v-if="showPdfComponent"></property-pdf>
                    </app-access-control>
                </b-row>
            </b-col>
            <loading :show="showLoading" :label="label" :overlay="overlay"/>
        </b-row>
    </div>
</template>

<script>
    import routeNames from '@/router/routeNames'
    import PropertyStatus from '@/components/property/status/PropertyStatus'
    import PropertyPdf from '@/components/property/basic/pdf/PropertyPdf'
    import loading from 'vue-full-loading'
    import {
        C_PROPERTY_STATUS_V,
        C_PROPERTY_PDF_V,
        C_TOTAL_INCOME_V,
        C_NIGHTS_V,
        C_CHECKIN_RESERVATIONS_V,
        C_CHECKOUT_RESERVATIONS_V,
        C_LATEST_RESERVATIONS_V,
    } from "@/shared/component_permission";
    import FormHeader from "@/components/app/form/FormHeader";
    import PropertyButtonGrid from "@/components/property/overview/PropertyButtonGrid";
    import DashboardIncome from "@/components/dashboard/shared/DashboardIncome";
    import {AC_PROPERTY_OVERVIEW} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import DashboardNumberOfNights from "@/components/dashboard/shared/DashboardNumberOfNights";
    import DashboardCheckinReservationList from "@/components/dashboard/shared/DashboardCheckinReservationList";
    import DashboardCheckoutReservationList from "@/components/dashboard/shared/DashboardCheckoutReservationList";
    import DashboardLatestReservationList from "@/components/dashboard/shared/DashboardLatestReservationList";

    export default {
        name: "Index",
        components: {
            DashboardLatestReservationList,
            DashboardCheckoutReservationList,
            DashboardCheckinReservationList,
            DashboardNumberOfNights,
            AppAccessControl,
            DashboardIncome,
            PropertyButtonGrid,
            PropertyStatus, PropertyPdf, loading, FormHeader
        },
        mixins: [AccessControlComponent],
        data() {
            return {
                showStatus: true,
                showLoading: false,
                label: 'Please wait ...',
                overlay: true,
                C_TOTAL_INCOME_V,
                C_CHECKIN_RESERVATIONS_V,
                C_CHECKOUT_RESERVATIONS_V,
                C_LATEST_RESERVATIONS_V,
                C_NIGHTS_V,
                C_PROPERTY_STATUS_V,
                access_control_fetch_key: AC_PROPERTY_OVERVIEW,
                access_control_components: 3,
                AC_PROPERTY_OVERVIEW,
                C_PROPERTY_PDF_V
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },

            showPdfComponent() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_PDF_V)

            },
            showStatusComponent() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_STATUS_V)

            }
        },
        methods: {
            priceLoader(value) {
                this.showLoading = value
            }
        },
        created(){
            this.access_control_general_context = {
                property: this.$route.params.id,
                company: this.$store.getters['user/getCompany']
            }
        }
    }
</script>

<style scoped>

</style>
