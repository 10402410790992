<template>
    <div>
        <cancellation-policy-list v-if="checkPermission(C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_V)"
                                  class="mt-3"
                                  :cancellationPolicyList="cancellationPolicyList"
                                  :loading="loading"
                                  :system_policy="true"
                                  @removeTag="getCancellationPolicies"
                                  @editPolicy="editSidebar">
        </cancellation-policy-list>
        <AppAside v-model="editSidebarState">
            <template v-if="edit_id !== null"
                      slot="header">
                {{$t("EDIT_POLICY_SIDEBAR_TITLE")}}
            </template>
            <template v-else
                      slot="header">
                {{$t("NEW_POLICY_SIDEBAR_TITLE")}}
            </template>
            <cancellation-policy :system_policy="true"
                                :edit_id="edit_id"
                                 @savePolicy="savePolicy"
                                 @deletePolicy="deletePolicy">
            </cancellation-policy>
        </AppAside>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import CancellationPolicyList from "@/components/property/cancelation_policies/policy/CancelationPolicyList";
    import AppAside from "@/components/app/form/AppAside";
    import CancellationPolicy from '@/components/property/cancelation_policies/policy/CancelationPolicyItemEdit';
    import {deleteSystemCancellationPolicy, getSystemCancellationPolicies} from "@/services/company";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {
        C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_V
    } from "@/shared/component_permission";
    import {EventBus, GE_CANCELLATION_POLICY_NEW} from "@/shared/EventBus";

    export default {
        name: "Index",
        components: {
            AppButton,
            CancellationPolicyList,
            AppAside,
            CancellationPolicy
        },
        mixins: [getErrorMessage],
        data() {
            return {
                cancellationPolicyList: [],
                editSidebarState: false,
                edit_id: null,
                loading: true,
                C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_V,
            }
        },
        methods: {
            editSidebar(id = null) {
                this.edit_id = id
                this.editSidebarState = true
            },
            getCancellationPolicies() {
                getSystemCancellationPolicies(this.$store.getters[`user/getCompany`]).then(response => {
                    this.cancellationPolicyList = response.data
                }, () => {
                    this.cancellationPolicyList = []
                }).finally(() => {
                    this.loading = false
                })
            },
            savePolicy() {
                this.getCancellationPolicies()
            },
            deletePolicy(id) {
                deleteSystemCancellationPolicy(this.$store.getters[`user/getCompany`], id).then(response => {
                    this.cancellationPolicyList = response.data
                    this.editSidebarState = false
                    notifySuccess()
                }, error => {
                    this.showErrorMessages(error)
                })
            }
        },
        created() {
            this.getCancellationPolicies()
        },
        mounted() {
            EventBus.$on(GE_CANCELLATION_POLICY_NEW, () => {
                this.editSidebar()
            })
        },
        beforeDestroy() {
            EventBus.$off(GE_CANCELLATION_POLICY_NEW)
        }
    }
</script>

<style scoped>

</style>
