<template>
    <form @submit.prevent="search"  class="filter">
        <b-row >

            <b-col md="2" class="mb-3">
                <label>{{ $t('NAME') }}</label>
                <b-input v-model="filter.title" type="text"></b-input>
            </b-col>
			 <b-col md="2" class="mb-3">
                <label>{{ $t("TYPE") }}</label>
                <app-select mode="multiselect"  :options="unit_type_options" text-field="label" v-model="filter.unit_type">
                </app-select>

            </b-col>
            <template v-if="cities_count > 1">
                <b-col md="2" class="mb-3" v-if="preloadCityList">
                    <label>{{$t("CITY")}}</label>
                    <app-select mode="multiselect" :options="cityList"  v-model="filter.city" ></app-select>
                </b-col>
                <b-col v-else lg="2" class="mb-3" >
                    <label>{{$t("CITY")}}</label>
                    <app-select mode="search" :search="searchCity" v-model="city" :search-clear="true"></app-select>
                </b-col>
            </template>
   
            <b-col md="2" class="mb-3">
                <label>{{$t('NUMBER_BEDROOMS')}}</label>
                <app-select value-field="value"  text-field="text" :options="num_of_bedroom_options" v-model="filter.bedroom_no"></app-select>
            </b-col>
            <b-col md="3" class="d-flex align-items-baseline">
                <app-button-submit class="filter-submit-wrapper" :inline="true" :disabled="loading" :loading="loaderSubmit" variant="primary"
                                   button_type="search">
                    {{$t("SEARCH") }}
                </app-button-submit>
                <app-button-reset @click="resetForm"  :disabled="loading" :loading="loaderReset" :inline="true"  class="pl-2 ml-2 filter-submit-wrapper" ></app-button-reset>
            </b-col>

        </b-row>
    </form>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import {cloneDeep, pickBy} from "lodash";
import {getUnitTypeList} from "@/services/unit";
import {getCityList} from "@/services/location";
import {DEFAULT_PAGINATION_LIMIT, LS_PRESETS} from "@/shared/constants";
import {getPropertyList} from "@/services/property";
export default {
    name: "RevenueManagerSimilarListingFilter",
    components: {AppNumberInput, AppButtonReset, AppButtonSubmit, AppSelect},
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            cities_count:0,
            preloadCityList:false,
            cityList:[],
            filter:{
                title:null,
                unit_type:[],
                bedroom_no:null,
                city:[]
            },
            city:null,
            loaderSubmit:false,
            loaderReset:false,
            reset:0,
            unit_type_options:[
                {id:1,label:"apartments"},{id:14,label:"bed in dormitory"},{id:16,label:"boat"},{id:12,label:"bungalow"},{id:22,label:"caravan"},{id:18,label:"chalet"},{id:17,label:"cottage"},{id:4,label:"country house"},{id:15,label:"dormitory room"},{id:2,label:"double room"},{id:20,label:"empty spot"},{id:10,label:"family"},{id:21,label:"glamp"},{id:13,label:"holiday home"},{id:23,label:"mobile home"},{id:8,label:"quadruple room"},{id:6,label:"single room"},{id:5,label:"studio"},{id:11,label:"suite"},{id:19,label:"tent"},{id:7,label:"triple room"},{id:9,label:"twin room"},{id:3,label:"villa"}
            ],
            num_of_bedroom_options:[
                {value:1,text:1},
                {value:2,text:2},
                {value:3,text:3},
                {value:4,text:4},
                {value:5,text:5},
                {value:6,text:6},
                {value:7,text:7},
                {value:8,text:8},
                {value:9,text:9},
                {value:10,text:10},
            ]
        }
    },
    methods:{
        getCities(){
            getCityList({
                user_cities: true,
            }).then(response=>{
                this.cityList = response.data
            })
        },
        searchCity(value){
            return getCityList({
                name: value,
            })
        },
        searchType(value) {
            return getUnitTypeList()
        },
        search(){
            this.reset =0
            if(this.city){
                this.filter = {...this.filter, ...{city:this.city.id}}
            }
            this.$emit('search', pickBy(this.filter, item => item !== null))
        },
        resetForm(){
            this.reset = 1
            this.filter.unit_type = []
            this.filter.title = null
            this.filter.bedroom_no = null
            this.filter.city = []
            this.city = null
            this.$emit('search', this.filter)
        }
    },
    watch:{
        loading: {
            handler(value) {
                if (this.reset) {
                    this.loaderReset = value
                } else {
                    this.loaderSubmit = value
                }
            }
        }
    },
    created() {
        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)
            if (storagePresets.hasOwnProperty('cities')) {
                this.cities_count = storagePresets.cities
                if (storagePresets.cities <= DEFAULT_PAGINATION_LIMIT) {
                    this.getCities()
                    this.preloadCityList = true
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
