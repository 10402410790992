<template>
    <div>
        <form @submit.prevent="search" class="filter" @reset.prevent="resetForm" v-if="countryList.length>0">
            <b-row>
                <b-col md="2" class="mb-3">
                    <label>{{ $t('COUNTRY') }}</label>
                    <app-select v-model="filter.country"
                                :options="countryList"
                                text-field="label"
                                mode="multiselect">
                    </app-select>
                </b-col>
                <b-col md="2" class="mb-3" v-if="regionList.length">
                    <label>{{ $t('REGION') }}</label>
                    <app-select v-model="filter.parent_tag"
                                :options="regionList"
                                text-field="label"
                                mode="multiselect">
                    </app-select>
                </b-col>
                <b-col md="2" class="mb-3">
                    <label>{{ $t('FILTER') }}</label>
                    <app-select v-model="filter.active_website_tag"
                                :options="statusList"
                                text-field="label"
                                mode="select">
                    </app-select>
                </b-col>
                <b-col md="3" class="mb-3 d-flex align-items-baseline">
                    <app-button-submit class="filter-submit-wrapper" :inline="true" :disabled="loading"
                                       :loading="loaderSubmit"
                                       variant="primary"
                                       button_type="search">{{ $t("SEARCH") }}
                    </app-button-submit>
                    <app-button-reset :inline="true" class="pl-2 ml-2" :disabled="loading"
                                      :loading="loaderReset"></app-button-reset>
                </b-col>
            </b-row>
        </form>
    </div>
</template>

<script>

    import {
        TAG_DESTINATION_TYPE_COUNTRY,
        TAG_DESTINATION_TYPE_REGION,
        TAG_TYPE_DESTINATION
    } from "@/shared/constants";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
    import {getAllTags} from "@/services/tag";
    import {cloneDeep} from "lodash";

    export default {
        name: "WebsiteTagFilter",
        components: {AppButtonReset, AppButtonSubmit, AppSelect},
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loaderReset: false,
                loaderSubmit: false,
                regionList: [],
                reset: 0,
                statusList: [
                    {id: 1, label: this.$t('ACTIVE')},
                    {id: 0, label: this.$t('INACTIVE')}
                ],
                countryList: [],
                filter: {
                    parent_tag: [],
                    country: [],
                    active_website_tag: null
                },
            }
        },
        methods: {
            search() {
                this.reset = 0
                this.$emit('search', {...this.filter, websiteId: this.website.id, company: this.company.id})
            },
            getCountry() {
                getAllTags(TAG_TYPE_DESTINATION, {
                    company: this.website.company_id,
                    destination_type: TAG_DESTINATION_TYPE_COUNTRY,
                }).then(response => {
                    if (response.data.length > 0) {
                        this.countryList = response.data
                    } else {
                        this.$emit("emptyCountryList")
                    }
                })
            },
            getRegion() {
                let country = {}

                if (this.filter && this.filter.country) {
                    country = {parent_tag: this.filter.country}
                }
                let req = {
                    company: this.website.company_id,
                    destination_type: TAG_DESTINATION_TYPE_REGION,
                    ...country
                }

                getAllTags(TAG_TYPE_DESTINATION, req).then(response => {
                    if (response.data.length > 0) {
                        this.regionList = response.data
                    } else {
                        this.regionList = true
                    }
                })
            },
            resetForm() {
                this.reset = 1
                this.filter.parent_tag = []
                this.filter.country = []
                this.filter.active_website_tag = null
                this.$emit('search', {...this.filter, websiteId: this.website.id, company: this.company.id})
            },
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            website() {
                return this.$store.getters['website/getWebsite']
            },
        },
        created() {
            this.getCountry()
            this.getRegion()
        },
        watch: {
            loading: {
                handler(value) {
                    if (this.reset) {
                        this.loaderReset = value
                    } else {
                        this.loaderSubmit = value
                    }
                }
            },
            filter: {
                handler() {
                    this.getRegion()
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>
