<template>
    <div v-if="no_data">
        <app-no-data></app-no-data>
    </div>
    <div v-else-if="hasPermissionView || taggedImages.length || data_visible">
        <gallery :images="galleryList" :index="index" @close="index = null">
            <template #close>
                <i class="fa fa-close" style="color: white"></i>
            </template>
        </gallery>
        <b-checkbox @change="selectAll"
                    v-if="data_visible"
                    class="document-checkbox" v-model="checked">
            {{this.selectedDocumentList.length ===
            this.taggedImages.length ? $t("DESELECT") : $t("SELECT_ALL")}}
        </b-checkbox>
        <app-access-control  :access_control_context="{function:c_permission_e,key:ac_key}"
                             @AccessControlEvent="addToAccessControlCounter()">
            <template v-slot="props">
                <draggable v-model="taggedImages" @change="changeOrder" class="row">
                    <GalleryItem ref="galleryItem"
                                 :delete-disabled="props.disabled"
                                :key="img.id" v-for="(img, imgIndex) in taggedImages"
                                @removeDocument="deleteFromPropertyGallery(img.id)"
                                @imageClicked="index=imgIndex"
                                @copyCheck="editCheckedList(img.id, $event)"
                                :checkboxSelectedList="selectedDocumentList"
                                :disabled="actionState || !hasPermissionEdit"
                                :DocumentTypeId="typeDocument"
                                :textAreaShow="false"
                                :unit-name="img.unitName"
                                :item="img">
                    </GalleryItem>
                </draggable>
            </template>
        </app-access-control>
        <app-access-control tag="span"  :access_control_context="{function:c_permission_e,key:ac_key}"
                            @AccessControlEvent="addToAccessControlCounter()">
            <template v-slot:default="props">
                <b-tooltip v-if="props.disabled" target="deleteFromProperty" placement="top"
                           :title="tooltip_message"></b-tooltip>
                <app-button v-if="data_visible" id="deleteFromProperty" :disabled="selectedDocumentList.length === 0 || props.disabled" @click="triggerDeleteDialog" class="pull-right mr-3">
                    {{ $t("DELETE_FROM_PROPERTY_GALLERY") }}</app-button>
            </template>
        </app-access-control>
        <app-confirmation-dialog
            :title="$t('REMOVE_CONFIRMATION')"
            :message="$t('REMOVE_FROM_PROPERTY_GALLERY')"
            :show="deleteDialogState"
            @confirm="deleteSelected"
            @cancel="deleteDialogState=false">
        </app-confirmation-dialog>
    </div>
</template>

<script>

import draggable from 'vuedraggable'
import GalleryItem from '@/components/app/gallery/GalleryItem'
import AppButton from '@/components/app/AppButton/AppButton'
import VueGallery from "vue-gallery";
import AppAccessControl from "@/components/app/AppAccessControl";
import AppNoData from "@/components/app/AppNoData";
import {deleteEntityTags, deleteEntityTagsList} from "@/services/tag";
import {getPropertyTaggedImages, postPropertyTaggedImages} from "@/services/property";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {OBJECT_TYPE_DOCUMENT, TAG_PROPERTY_GALLERY, TYPE_UNIT_PHOTO} from "@/shared/constants";
import {notifySuccess} from "@/shared/plugins/toastr";

export default {
    name: "TaggedGalleryProperty",

    components:{
        AppAccessControl,
        draggable,
        GalleryItem,
        AppButton,
        'gallery': VueGallery,
        AppNoData,
        "AppConfirmationDialog": () => import("@/components/app/form/AppConfirmationDialog"),
    },

    props:{
        c_permission_e: {
            default: null,
            type: Number
        },
        c_permission_v: {
            default: null,
            type: Number
        },
        ac_key:{
            type:String
        },
    },

    mixins: [getErrorMessage,AccessControlComponent],

    data(){
        return {
            deleteDialogState: false,
            index: null,
            taggedImages: [],
            sortTaggedImages: [],
            typeDocument: TYPE_UNIT_PHOTO,
            checked: false,
            selectedDocumentList: [],
            actionState: false,
            access_control_components: 2,
            no_data: false,
            data_visible: false
        }
    },
    created() {
        this.access_control_general_context = {
            property: this.$route.params.id,
            company: this.$store.getters['user/getCompany']
        }
        this.access_control_fetch_key = this.ac_key
        this.actionState = true
        getPropertyTaggedImages(this.$route.params.id).then((response) => {
            if (response.data.length > 0){
                this.data_visible = true
                this.no_data = false
            }else {
                this.no_data = true
                this.data_visible = false
            }
            let data = response.data
            this.sortTaggedImages = data
            data.forEach(element => {
                this.taggedImages.push({
                    "id": element.image.id,
                    "name": element.label,
                    "order_number": element.order_number,
                    "route": element.image.link,
                    "type": "image/jpeg",
                    "entity_id": element.id,
                    "thumb": element.image.thumb,
                    "unitName": element.unit_name
                })
            })
        })
    },

    computed:{
        galleryList() {
            let arrTemp = []
            this.taggedImages.forEach(function (arrayItem) {
                arrTemp.push(arrayItem.route)
            });
            return arrTemp
        },
        tooltip_message(){
            return this.$t('WRONG_STATUS')
        }
    },

    methods:{
        hasPermissionEdit() {
            if (this.c_permission_e === null) {
                return true
            }
            return this.checkPermission(this.c_permission_e)
        },
        hasPermissionView() {
            if (this.c_permission_v === null) {
                return true
            }
            return this.$store.getters['user/getPermission'](this.c_permission_v)
        },
        changeOrder(data) {
            this.taggedImages.forEach((item, index) => {
                this.taggedImages[index].order_number = index + 1
            })
            postPropertyTaggedImages(this.$route.params.id, {
                list: this.taggedImages.map((el) => {
                    return {
                        id: el.entity_id,
                        image: {
                            id: el.id,
                            link: el.route,
                            thumb: el.thumb,
                        },
                        label: el.name,
                        order_number: el.order_number,
                        unit_name: el.unitName
                    }
                })
            })
        },
        deleteFromPropertyGallery(id){
            deleteEntityTags(OBJECT_TYPE_DOCUMENT, id, [TAG_PROPERTY_GALLERY]).then(() => {
                this.taggedImages.splice(this.taggedImages.findIndex(item => item.id === id),1)
                notifySuccess()
                if(this.taggedImages.length < 1){
                    this.no_data = true
                    this.data_visible = false
                }else{
                    this.no_data = false
                    this.data_visible = true
                }
            })
        },
        triggerDeleteDialog() {
            if (!this.deleteDisabled) {
                this.deleteDialogState=true
            }
        },
        deleteSelected(){
            this.deleteDialogState=false
            deleteEntityTagsList(OBJECT_TYPE_DOCUMENT, TAG_PROPERTY_GALLERY, this.selectedDocumentList).then(() => {
                this.selectedDocumentList.forEach(el => {
                    this.taggedImages.splice(this.taggedImages.findIndex(item => item.id === el),1)
                })
                notifySuccess()
                if(this.taggedImages.length < 1){
                    this.no_data = true
                    this.data_visible = false
                }else{
                    this.no_data = false
                    this.data_visible = true
                }
            })
        },
        selectAll(){
            if (this.selectedDocumentList.length === this.taggedImages.length) {
                this.selectedDocumentList = []
            } else {
                this.taggedImages.forEach(element => {
                    if (!this.selectedDocumentList.includes(element.id)) {
                        this.selectedDocumentList.push(element.id)
                    }
                })
            }
        },
        editCheckedList(docId, event) {
            if (!this.selectedDocumentList.includes(docId) && event) {
                this.selectedDocumentList.push(docId)
                return
            }
            if (this.selectedDocumentList.includes(docId) && !event) {
                let index = this.selectedDocumentList.indexOf(docId)
                if (index !== -1) this.selectedDocumentList.splice(index, 1)
            }
        },
    },
    watch:{
      taggedImages:{
          handler(){
              if (this.taggedImages < 1) {
                  this.$emit('closeAsideModal')
              }
          }
      },
        selectedDocumentList:{
          handler(){
              if (this.selectedDocumentList.length === this.taggedImages.length){
                  this.checked = true
              }
          }
        }
    }
}
</script>

<style scoped>

.label_gallery{
    color: #329194;
    opacity: 0.7;
    font-size: 13px;
    margin-top: 0 !important;
    line-height: 16px;
}

.document-checkbox{
    color: var(--color-text-primary);
}

</style>
