<template>
    <div>
        <b-row>
            <b-col class="mb-3 d-flex justify-content-end align-items-baseline">
                <app-button button_type="new" variant="primary" @click="edit({})" :c_permission="C_SETUP_FINANCE_VAT_NEW">
                    {{ $t("NEW_VAT_RATE") }}
                </app-button>
            </b-col>
        </b-row>

        <vat-setup-filter @search="search" :loading="loading"></vat-setup-filter>
<!--        <vat-setup-list v-if="tableItems.length" :tableItems="tableItems" @edit="edit" :loading="loading"></vat-setup-list>-->
        <app-table v-if="tableItems.length"
                  :busy="loading"
                  :items="tableItems"
                  :fields="fields">
            <template v-slot:amount="data">
                {{data.item.amount}}%
            </template>

            <template v-slot:actions="data">
                <app-button variant="link"
                            icon="edit"
                            :show_text="false"
                            @click="edit(data.item)">
                </app-button>
            </template>
        </app-table>
        <app-no-data v-else-if="!tableItems.length && !loading"></app-no-data>
        <app-pagination :listCount="total" v-model="filter.page" :last-page="lastPage"></app-pagination>

        <app-aside v-model="sidebarState">
            <template slot="header">{{ sidebarHeader }}</template>
            <vat-setup-form :vat-object="vatObject"
                            @created="create"
                            @updated="update"
                            @deleted="_delete">
            </vat-setup-form>
        </app-aside>
    </div>
</template>

<script>
    import VatSetupList from "@/components/finance/setup/vat/VatSetupList";
    import {deleteVatRate, getVatList} from "@/services/finance/setup";
    import FormHeader from "@/components/app/form/FormHeader";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppAside from "@/components/app/form/AppAside";
    import VatSetupForm from "@/components/finance/setup/vat/VatSetupForm";
    import AppPagination from "@/components/app/filter/AppPagination";
    import VatSetupFilter from "@/components/finance/setup/vat/VatSetupFilter";
    import AppNoData from "@/components/app/AppNoData";
    import AppTable from "@/components/app/AppTable";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {C_SETUP_FINANCE_VAT_NEW} from "@/shared/component_permission";

    export default {
        name: "Index",
        components: {
            AppTable,
            AppNoData,
            VatSetupFilter, AppPagination, VatSetupForm, AppButton, VatSetupList, FormHeader, AppAside},
        mixins: [getErrorMessage],
        data() {
            return {
                tableItems: [],
                loading: false,
                vatObject: null,
                sidebarState: false,
                total: null,
                lastPage: null,
                filter: {page: 1},
                fields: [
                    {key: 'label', label: this.$t('NAME')},
                    {key: 'amount', label: this.$t('AMOUNT')},
                    {key: 'actions', label: '', class: 'text-right'}
                ],
                C_SETUP_FINANCE_VAT_NEW
            }
        },
        computed: {
            companyId() {
                return this.$store.getters['user/getCompany']
            },
            sidebarHeader() {
                return this.vatObject && this.vatObject.id ? this.$t('EDIT_VAT_RATE') : this.$t('NEW_VAT_RATE')
            }
        },
        methods: {
            edit(vatObject) {
                this.vatObject = vatObject
                this.vatObject.company = this.companyId
                this.sidebarState = true
            },
            create(object) {
                this.tableItems.unshift(object)
                this.sidebarState = false
            },
            update(object) {
                this.$set(this.tableItems, this.tableItems.findIndex(x => x.id === object.id), object)
                this.sidebarState = false
            },
            _delete(object) {
                this.loading = true
                deleteVatRate(object.id).then(() => {
                    notifySuccess()
                    this.tableItems.splice(this.tableItems.findIndex(x => x.id === object.id), 1)
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.loading = false
                    this.sidebarState = false
                })
            },
            getList() {
                this.loading = true
                getVatList({company: this.companyId, ...this.filter}).then(response => {
                    this.total = response.data.total
                    this.lastPage = response.data.last_page
                    this.tableItems = response.data.items
                }).finally(() => this.loading = false)
            },
            search(filter) {
                this.filter = {...filter}
            }
        },
        watch: {
            filter: {
                handler() {
                    this.getList()
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>
