<template>
    <div class="mini_calendar_holder"
         :class="{'availability_calendar_wrapper-loader':showLoader}"
    >
        <year-calendar-item-test v-for="(monthObject) in monthRange"
                                 :key="monthObject.key"
                                 :year="monthObject.year"
                                 :month="monthObject.month"
                                 :firstMonth="firstMonth"
                                 :unit="unitData"
                                 :parent-unit="parentUnit"
                                 :property="property"
                                 :isContigent="isContigent"
                                 :availability="availabilityList"
                                 :reservations="reservationsList"
                                 :show_channel_data="show_channel_data_value"
                                 :shiftKeyValue="shiftKeyValue"
                                 :currentDragDateProp="currentDragDateProp"
                                 :dragPeriodStartProp="dragPeriodStartProp"
                                 :clickedUnitRowIndex="clickedUnitRowIndex"
                                 :definedUnitRowIndex="0"
        ></year-calendar-item-test>
        <loading :show="showLoading" :overlay="overlay"></loading>
    </div>
</template>

<script>
import moment from 'moment'
import YearCalendarItemTest from "@/components/unit/calendar_opt/Table/YearCalendar/YearCalendarItemTest";
import  {has} from 'lodash'
import {C_RESERVATION_ACCOMMODATION_BOOKING_SITE} from "@/shared/component_permission";
import {fetchAccessControlData} from "@/services/access";
import {AC_FEATURE_SETUP} from "@/mixins/AccessControl/AccessControlEnumeration";
import CalendarAside from "@/components/unit/calendar_opt/Aside/Calendar/CalendarAside";
import loading from 'vue-full-loading'

export default {
    name: "YearCalendarHolderTest",
    components: {YearCalendarItemTest, loading, CalendarAside},
    props: {
        unit: {
            type: Object
        },
        property: {
            type: Object
        },
        parentUnit: {
            type: Object
        },
        year: {
            type: Number
        },
        month: {
            type: Number
        },
        number_of_months: {
            type: Number
        },
        show_channel_data: {
            type: Boolean,
            default: null
        },
        isContigent: {
            type: Boolean
        },
        showLoader:{
            type: Boolean,
            default: false
        },
        shiftKeyValue: {
            type: Boolean,
            default: false
        },
        clickedUnitRowIndex:{
            type:Number,
            default:null
        },
        currentDragDateProp: {
            type: String,
            default: null
        },
        dragPeriodStartProp: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            availability: {},
            reservations: {},
            show_channel_data_response_value:false,
            showLoading:false,
            overlay: true,
        }
    },
    computed: {
        show_channel_data_value(){
            if (this.show_channel_data === null) {
                return this.show_channel_data_response_value
            }
            return this.show_channel_data
        },
        availabilityList(){
            let return_list = []
            Object.entries(this.availability).forEach(([date, availability]) => {
                return_list[date] = availability
            })
            return {...return_list}
        },
        reservationsList(){
            let return_list = []
            Object.entries(this.reservations).forEach(([reservation_id, reservation]) => {
                if (reservation.hasOwnProperty("id")) {
                    return_list.push(reservation)
                }
            })
            return return_list
        },
        firstMonth(){
            return moment().set({year: this.year, month: this.month}).startOf('month').format("YYYY-MM")
        },
        monthRange(){
            let return_list = []
            let currentDate = moment().set({year: this.year, month: this.month}).startOf('month').format("YYYY-MM-DD")

            return_list.push({
                key: moment(currentDate).format('YYYY-MM'),
                year: moment(currentDate).year(),
                month: moment(currentDate).month()
            })

            if (this.number_of_months && this.number_of_months > 0) {
                for (let month_increment = 1; month_increment < this.number_of_months; month_increment++) {
                    currentDate = moment(currentDate).add(1, 'months')
                    return_list.push({
                        key: moment(currentDate).format('YYYY-MM'),
                        year: moment(currentDate).year(),
                        month: moment(currentDate).month()
                    })
                }
            }

            return return_list
        },
        unitData() {
            return {
                ...this.unit || {},
                data: [],
                reservation: []
            }
        },
    },
    methods: {
        prepareCalendarData() {
            if (has(this.unit, 'data')) {
                this.availability = this.unit.data;
            }
            if (has(this.unit, 'reservation')) {
                this.reservations = this.unit.reservation;
            }
        },
    },
    mounted() {
        if (this.show_channel_data === null) {
            let request = {
                context: {
                    company: this.$store.getters['user/getCompany']
                },
                data: []
            }
            request.data.push({
                uid: C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
                function: C_RESERVATION_ACCOMMODATION_BOOKING_SITE
            })
            fetchAccessControlData(AC_FEATURE_SETUP, request).then(response => {
                this.show_channel_data_response_value = response.data[C_RESERVATION_ACCOMMODATION_BOOKING_SITE].visible
            })
        }
    },
    watch: {
        'monthRange': {
            handler(id) {
                this.availability = {}
                this.reservations = {}
                this.prepareCalendarData()
            },
            immediate: true
        },
        'unit.id': {
            handler(id) {
                this.availability = {}
                this.reservations = {}
                this.prepareCalendarData()
            },
            immediate: true
        },
        'unit': {
            handler(unit) {
                this.availability = {}
                this.reservations = {}
                this.prepareCalendarData()
            },
            immediate: false,
            deep: true
        }
    },
}
</script>

<style scoped>

</style>
