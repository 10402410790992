<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="loading">
            <b-spinner variant="primary"></b-spinner>
        </div>
        <template v-else>
            <b-form v-if="data.hasOwnProperty('sales_period') && data.sales_period.length >0 " @submit.prevent="save">
                <b-row class="mb-4">
                    <b-col>
                        <b-form-group v-if="mode !== 'deviation_setup'" class="pull-left display-control">
                            <label>{{ $t('DISPLAY') }}</label>
                            <app-select v-model="display_general"
                                        :search-empty-item="false"
                                        :options="computedDisplayOptions"
                                        :no-tab-index="true"
                                        value-field="value"
                                        text-field="text"
                                        @input="setGeneralDisplayShow"
                            ></app-select>
                        </b-form-group>

                        <b-tooltip v-if="read_only" triggers="hover"
                                   :target="'tooltip-target-msg_revenue_save'"
                                   :title="read_only_message"
                        ></b-tooltip>
                        <app-button-submit id="tooltip-target-msg_revenue_save"
                                           class="pt-2 pull-right"
                                           :loading="loader"
                                           :disabled="read_only"
                        ></app-button-submit>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col :lg="mode === 'deviation_setup' ? 6 : 4" class="pr-1 pb-0" :key="periodIndex"
                           v-for="(period, periodIndex) in data.sales_period">
                        <b-card class="mb-3" body-class="p-0">
                            <b-list-group class="repeater-2">
                                <b-list-group-item class="list-group-item-title font-weight-bold">
                                    <div class="d-flex flex-wrap justify-content-between">
                                        <div class="d-flex justify-content-start mt-date">
                                            <h5 class="title mr-1 font-weight-bold">
                                                {{ period.period_start | periodDate }}
                                                -
                                                {{ period.period_end | periodDate }}
                                            </h5>
                                        </div>

                                        <div class="d-flex justify-content-center" v-if="mode !== 'deviation_setup'">
                                            <app-select v-model="display[period.id]"
                                                        :options="computedDisplayOptions"
                                                        :search-empty-item="false"
                                                        :no-tab-index="true"
                                                        value-field="value"
                                                        text-field="text"
                                                        class="display-control"
                                            ></app-select>
                                        </div>

                                        <div class="d-flex justify-content-end">

                                        </div>
                                    </div>
                                </b-list-group-item>
                                <b-list-group-item
                                    v-if="display[period.id] === 0 && bookingWindowMinimumPriceList.length > 0">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                            <tr>
                                                <th colspan="2">{{ $t('BOOKING_WINDOW') }}</th>
                                                <th class="text-center">{{ $t('RATES') }}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <template v-for="(booking_window, bookingWindowIndex) in bookingWindowMinimumPriceList">
                                                <tr>
                                                    <td class="bg-light"> {{ booking_window.title }}</td>
                                                    <td class="bg-light"></td>
                                                    <td width="60%" class="mb-0 mt-0 pb-2 pt-2">
                                                        <div class="d-flex d-inline-flex">
                                                            <b-input-group :prepend="currency">
                                                                <b-form-input v-model="price_item[period.id+'_'+booking_window.id]"
                                                                              :tabIndex="(periodIndex+1)+'_'+(bookingWindowIndex+1)+'price'"
                                                                              :disabled="read_only"
                                                                              type="number"
                                                                              @input="applyToPriceChildren(period.id, booking_window.id, $event)"
                                                                              @keypress="check"
                                                                ></b-form-input>
                                                            </b-input-group>
                                                            <b-input-group prepend="%" class="ml-2">
                                                                <b-form-input
                                                                    v-model="price_item_percent[period.id+'_'+booking_window.id]"
                                                                    :tabIndex="(periodIndex+1)+'_'+(bookingWindowIndex+1)+'_percent'"
                                                                    :disabled="read_only"
                                                                    type="number"
                                                                    @input="applyToPricePercentChildren(period.id,booking_window.id, $event)"
                                                                ></b-form-input>
                                                            </b-input-group>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr :key="booking_window.id+'_'+child.id"
                                                    v-if="booking_window.child_booking_windows.length > 0"
                                                    v-for="child in booking_window.child_booking_windows">
                                                    <td>{{ child.title }}</td>
                                                    <td></td>
                                                    <td class="mb-0 mt-0 pb-2 pt-2">
                                                        <b-input-group :prepend="currency">
                                                            <b-form-input
                                                                v-model="price_item[period.id+'_'+child.id]"
                                                                :disabled="read_only"
                                                                :class="!saved && initial_price_item[period.id+'_'+child.id] === null && defaultPrice[period.id] === price_item[period.id+'_'+child.id] ? 'default-price-color' : ''"
                                                                type="number"
                                                                @keypress="check"
                                                            ></b-form-input>
                                                        </b-input-group>
                                                    </td>
                                                </tr>
                                            </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-list-group-item>
                                <b-list-group-item
                                    v-if="display[period.id] === 1 && bookingWindowMinStayList.length > 0">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                            <tr>
                                                <th colspan="2">{{ $t('BOOKING_WINDOW') }}</th>
                                                <th class="text-center">{{ $t('MIN_STAY') }}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <template v-for="(booking_window, bookingWindowIndex) in bookingWindowMinStayList">
                                                <tr>
                                                    <td class="bg-light"> {{ booking_window.title }}</td>
                                                    <td class="bg-light"></td>
                                                    <td class="mb-0 mt-0 pb-2 pt-2">
                                                        <b-form-input v-model="min_stay_item[period.id+'_'+booking_window.id]"
                                                                      :tabIndex="(periodIndex+1)+'_'+(bookingWindowIndex+1)"
                                                                      :disabled="read_only"
                                                                      type="number"
                                                                      @input="applyToMinStayChildren(period.id,booking_window.id, $event)"
                                                        ></b-form-input>
                                                    </td>
                                                </tr>
                                                <tr :key="booking_window.id+'_'+child.id"
                                                    v-if="booking_window.child_booking_windows.length > 0"
                                                    v-for="child in booking_window.child_booking_windows">
                                                    <td>{{ child.title }}</td>
                                                    <td></td>
                                                    <td class="mb-0 mt-0 pb-2 pt-2">
                                                        <b-form-input
                                                            v-model="min_stay_item[period.id+'_'+child.id]"
                                                            :class-input="!saved && initial_min_stay_item[period.id+'_'+child.id] === null && defaultRestriction[period.id] === min_stay_item[period.id+'_'+child.id] ? 'default-price-color' : ''"
                                                            :disabled="read_only"
                                                        ></b-form-input>
                                                    </td>
                                                </tr>
                                            </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-list-group-item>
                                <b-list-group-item
                                    v-if="display[period.id] === 2 && bookingWindowMinimumPriceList.length > 0">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                            <tr>
                                                <th style="min-width: 8rem">{{ $t('BOOKING_WINDOW') }}</th>
                                                <th class="text-center">{{ $t('NOTIFICATION_TYPE') }}</th>
                                                <th class="text-center">{{ $t('HIGH_AVAILABILITY') }}</th>
                                                <th class="text-center">{{ $t('ALLOWED_RATE_DECREASE') }}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <template v-for="(booking_window, bookingWindowIndex) in bookingWindowMinimumPriceList">
                                                <tr>
                                                    <td class="bg-light"> {{ booking_window.title }}</td>
                                                    <td>
                                                        <app-select
                                                            v-model="logSetup[period.id + '_' + booking_window.id]"
                                                            :options="notificationTypeOptions"
                                                            :disabled="read_only"
                                                            :no-tab-index="true"
                                                            text-field="label"
                                                            value-field="key"
                                                            @input="applyToLogSetupChildren(period.id, booking_window.id, $event)"
                                                        ></app-select>
                                                    </td>
                                                    <td class="mb-0 mt-0 pb-2 pt-2">
                                                        <b-input-group prepend="%">
                                                            <b-form-input
                                                                v-model="deviation_setup[period.id+'_'+booking_window.id + '_'+AVAILABILITY_DEVIATION_TYPE]"
                                                                :tabIndex="(periodIndex+1)+'_'+(bookingWindowIndex+1)+'_deviation_'+AVAILABILITY_DEVIATION_TYPE"
                                                                :disabled="read_only"
                                                                type="number"
                                                                @input="applyToDeviationChildren(period.id,booking_window.id,AVAILABILITY_DEVIATION_TYPE, $event)"
                                                            ></b-form-input>
                                                        </b-input-group>
                                                    </td>
                                                    <td class="mb-0 mt-0 pb-2 pt-2">
                                                        <b-input-group prepend="%">
                                                            <b-form-input
                                                                v-model="deviation_setup[period.id+'_'+booking_window.id +'_'+RATE_DEVIATION_TYPE]"
                                                                :tabIndex="(periodIndex+1)+'_'+(bookingWindowIndex+1)+'_deviation_'+RATE_DEVIATION_TYPE"
                                                                :disabled="read_only"
                                                                type="number"
                                                                @input="applyToDeviationChildren(period.id,booking_window.id,RATE_DEVIATION_TYPE, $event)"
                                                            ></b-form-input>
                                                        </b-input-group>
                                                    </td>
                                                </tr>
                                                <tr :key="booking_window.id+'_'+child.id"
                                                    v-if="booking_window.child_booking_windows.length > 0"
                                                    v-for="child in booking_window.child_booking_windows">
                                                    <td>{{ child.title }}</td>
                                                    <td class="mb-0 mt-0 pb-2 pt-2">
                                                        <app-select
                                                            v-model="logSetup[period.id + '_' + child.id]"
                                                            :options="notificationTypeOptions"
                                                            :disabled="read_only"
                                                            :no-tab-index="true"
                                                            text-field="label"
                                                            value-field="key"
                                                        ></app-select>
                                                    </td>
                                                    <td class="mb-0 mt-0 pb-2 pt-2">
                                                        <b-input-group prepend="%">
                                                            <b-form-input v-model="deviation_setup[period.id+'_'+child.id+'_'+AVAILABILITY_DEVIATION_TYPE]"
                                                                          :disabled="read_only"
                                                                          type="number"
                                                                          prepend="%"
                                                                          @keypress="check"
                                                            ></b-form-input>
                                                        </b-input-group>
                                                    </td>
                                                    <td class="mb-0 mt-0 pb-2 pt-2">
                                                        <b-input-group prepend="%">
                                                            <b-form-input v-model="deviation_setup[period.id+'_'+child.id+'_'+RATE_DEVIATION_TYPE]"
                                                                          :disabled="read_only"
                                                                          type="number"
                                                                          prepend="%"
                                                                          @keypress="check"
                                                            ></b-form-input>
                                                        </b-input-group>
                                                    </td>
                                                </tr>
                                            </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-list-group-item>
                            </b-list-group>
                        </b-card>
                    </b-col>
                </b-row>
            </b-form>
        </template>
    </div>
</template>

<script>

import AppNumberInput from "@/components/app/form/AppNumberInput";
import {getUnitRevenueManagerSetup, setRevenueManagerSetup} from "@/services/unit";
import AppButton from "@/components/app/AppButton/AppButton";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import {C_UNIT_REVENUE_MANAGER_MIN_VALUES_SAVE, C_UNIT_CALENDAR_PERIODS_E} from "@/shared/component_permission";
import AppNoData from "@/components/app/AppNoData";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {EventBus, GE_LOADER_HIDE, GE_LOADER_SHOW} from "@/shared/EventBus";
import {AVAILABILITY_DEVIATION_TYPE, RATE_DEVIATION_TYPE, WORKFLOW_DIRECT_BOOKER} from "@/shared/constants";
import {getBulkCalendar} from "@/services/pricing";

export default {
    name: "UnitBulkRevenueManager",
    components: {AppSelect, AppNoData, AppButtonSubmit, AppButton, AppNumberInput},
    mixins: [getErrorMessage],
    props: {
        unit_id: {
            type: Number
        },
        property_id: {
            type: Number
        },
        defaultLos: {
            type: Number,
        },
        defaultOccupancy: {
            type: Number,
        },
        primaryRatePlan: {
            type: Object
        },
        mode: {
            type: String,
            default: 'all'
        },
        read_only: {
            type: Boolean,
            default: false
        },
        read_only_message: {
            type: String
        },
    },
    data() {
        return {
            loading: false,
            data: {},
            price_item: {},
            initial_price_item: {},
            price_item_percent: {},
            min_stay_item: {},
            initial_min_stay_item: {},
            min_reservation_value: {},
            min_reservation_stay: {},
            deviation_setup: {},
            display: {},
            deviation_setup_type: [],
            display_general: 0,
            loader: false,
            C_UNIT_REVENUE_MANAGER_MIN_VALUES_SAVE,
            C_UNIT_CALENDAR_PERIODS_E,
            AVAILABILITY_DEVIATION_TYPE,
            RATE_DEVIATION_TYPE,
            saved: false,
            defaultPrice: {},
            defaultRestriction: {},
            notificationTypeOptions: [],
            logSetup: {},
        }
    },
    computed: {
        computedDisplayOptions() {
            if (this.company && this.company.workflow_id === WORKFLOW_DIRECT_BOOKER && this.mode === 'all') {
                return [
                    {text: this.$t('RATES'), value: 0},
                    {text: this.$t('MIN_STAY'), value: 1},
                    {text: this.$t('DEVIATION'), value: 2},
                ]
            } else {
                return [
                    {text: this.$t('RATES'), value: 0},
                    {text: this.$t('MIN_STAY'), value: 1},
                ]
            }
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        currency() {
            if (typeof this.company !== 'undefined' && this.company !== null && this.company.hasOwnProperty('working_currency')) {
                return this.company.working_currency.code
            } else {
                return ''
            }
        },
        bookingWindowMinimumPriceList() {
            return Object.keys(this.data).length > 0 && this.data.hasOwnProperty('booking_window') && this.data.booking_window.length > 0 ? this.data.booking_window.filter(el => el.booking_window_setup.booking_window_setup_type.id === 1) : []
        },
        bookingWindowMinStayList() {
            return Object.keys(this.data).length > 0 && this.data.hasOwnProperty('booking_window') && this.data.booking_window.length > 0 ? this.data.booking_window.filter(el => el.booking_window_setup.booking_window_setup_type.id === 2) : []
        },
    },
    methods: {
        applyToDeviationChildren(period_id, booking_window_id, type, value) {
            if (!value) {
                return
            }

            const bookingWindow = this.data.booking_window.find(el => Number(el.id) === booking_window_id)
            const children_ids = bookingWindow.child_booking_windows.map(el => {
                return el.id
            })

            for (const child_id of children_ids) {
                const key = period_id + '_' + child_id + '_' + type
                this.deviation_setup[key] = value
            }
        },
        applyToPriceChildren(period_id, booking_window_id, value) {
            if (!value) {
                return
            }

            this.price_item_percent[period_id + '_' + booking_window_id] = null
            const bookingWindow = this.data.booking_window.find(el => Number(el.id) === booking_window_id)
            const children_ids = bookingWindow.child_booking_windows.map(el => {
                return el.id

            })
            for (const child_id of children_ids) {
                const key = period_id + '_' + child_id
                this.price_item[key] = value
            }
        },
        applyToPricePercentChildren(period_id, booking_window_id, value) {
            if (value === null) {
                value = 0
            }

            const base_price = this.defaultPrice[period_id]
            if (base_price) {
                this.price_item[period_id + '_' + booking_window_id] = null
                const bookingWindow = this.data.booking_window.find(el => Number(el.id) === booking_window_id)
                const children_ids = bookingWindow.child_booking_windows.map(el => {
                    return el.id

                })
                for (const child_id of children_ids) {
                    const key = period_id + '_' + child_id
                    const calculateValue = (base_price * value / 100) + base_price
                    this.price_item[key] = Math.ceil(calculateValue)
                }
            }
        },
        applyToMinStayChildren(period_id, booking_window_id, value) {
            if (!value) {
                return
            }

            const bookingWindow = this.data.booking_window.find(el => Number(el.id) === booking_window_id)
            const children_ids = bookingWindow.child_booking_windows.map(el => {
                return el.id

            })
            for (const child_id of children_ids) {
                const key = period_id + '_' + child_id
                this.min_stay_item[key] = value
            }
        },
        applyToLogSetupChildren(periodId, bookingWindowId, value) {
            if (!value) {
                return
            }
            const bookingWindow = this.data.booking_window.find((el) => Number(el.id) === bookingWindowId)
            const childrenIdList = bookingWindow.child_booking_windows.map((el) => {
                return el.id

            })
            for (const childId of childrenIdList) {
                const key = periodId + '_' + childId
                this.$set(this.logSetup, key, value)
            }
        },
        clearGroupInputs() {
            if (this.data.hasOwnProperty('sales_period') && this.data.sales_period.length > 0 && this.data.hasOwnProperty('booking_window') && this.data.booking_window.length > 0) {
                this.data.sales_period.forEach(salesPeriod => {
                    this.data.booking_window.forEach(bookingWindow => {
                        const key = salesPeriod.id + '_' + bookingWindow.id
                        if (this.deviation_setup.hasOwnProperty(key + '_' + AVAILABILITY_DEVIATION_TYPE) && this.deviation_setup[key + '_' + AVAILABILITY_DEVIATION_TYPE] !== null) {
                            this.$set(this.deviation_setup, key + '_' + AVAILABILITY_DEVIATION_TYPE, null)
                        }
                        if (this.deviation_setup.hasOwnProperty(key + '_' + RATE_DEVIATION_TYPE) && this.deviation_setup[key + '_' + RATE_DEVIATION_TYPE] !== null) {
                            this.$set(this.deviation_setup, key + '_' + RATE_DEVIATION_TYPE, null)
                        }
                        if (this.price_item.hasOwnProperty(key) && this.price_item[key] !== null) {
                            this.$set(this.price_item, key, null)
                        }
                        if (this.price_item_percent.hasOwnProperty(key) && this.price_item_percent[key] !== null) {
                            this.$set(this.price_item_percent, key, null)
                        }
                        if (this.min_stay_item.hasOwnProperty(key) && this.min_stay_item[key] !== null) {
                            this.$set(this.min_stay_item, key, null)
                        }
                    })
                })
            }
        },
        save() {
            this.clearGroupInputs()
            let requestObject = {}
            let pricing_minimum_setup = []
            let minimum_stay_minimum_setup = []
            let booking_window_ids = []
            let deviation_setup = []
            let logSetup = []
            this.data.booking_window.forEach(bookingWindow => {
                const children_ids = bookingWindow.child_booking_windows.map(el => {
                    return el.id

                })
                booking_window_ids = [...booking_window_ids, ...children_ids]
            })
            if (Object.keys(this.price_item).length > 0) {
                for (const booking_window_id of booking_window_ids) {
                    for (const period of this.data.sales_period) {
                        const key = period.id + '_' + booking_window_id

                        if (this.price_item[key]) {
                            pricing_minimum_setup.push({
                                sales_period_id: period.id,
                                booking_window_id: booking_window_id,
                                value: this.price_item[key]
                            })
                        }
                        if (this.min_stay_item[key]) {
                            minimum_stay_minimum_setup.push({
                                sales_period_id: period.id,
                                booking_window_id: booking_window_id,
                                value: this.min_stay_item[key]
                            })
                        }

                    }
                }
            }
            if (Object.keys(this.deviation_setup).length > 0) {
                for (const period of this.data.sales_period) {
                    for (const booking_window_id of booking_window_ids) {
                        for (const type of this.deviation_setup_type) {
                            const key = period.id + '_' + booking_window_id + '_' + type.id
                            if (this.deviation_setup[key]) {
                                deviation_setup.push({
                                    sales_period: period.id,
                                    booking_window: booking_window_id,
                                    type: type.id,
                                    value: this.deviation_setup[key]
                                })
                            }
                        }
                    }
                }
            }

            if (Object.keys(this.logSetup).length > 0) {
                for (const period of this.data.sales_period) {
                    for (const booking_window_id of booking_window_ids) {
                        const key = period.id + '_' + booking_window_id
                        if (this.logSetup.hasOwnProperty(key) && this.logSetup[key]) {
                            logSetup.push({
                                sales_period: period.id,
                                booking_window: booking_window_id,
                                log_action: this.logSetup[key]
                            })
                        }
                    }
                }
            }

            if (this.mode === 'secured_values') {
                if (pricing_minimum_setup.length > 0) {
                    requestObject = {...requestObject, ...{pricing_minimum_setup}}
                }
                if (minimum_stay_minimum_setup.length > 0) {
                    requestObject = {...requestObject, ...{minimum_stay_minimum_setup}}
                }
            }
            if (this.mode === 'deviation_setup') {
                if (deviation_setup.length > 0) {
                    requestObject = {...requestObject, ...{deviation_setup}}
                }
                if (logSetup.length > 0) {
                    requestObject = {...requestObject, ...{log_setup: logSetup}}
                }
            }
            if (this.mode === 'all') {
                if (pricing_minimum_setup.length > 0) {
                    requestObject = {...requestObject, ...{pricing_minimum_setup}}
                }
                if (minimum_stay_minimum_setup.length > 0) {
                    requestObject = {...requestObject, ...{minimum_stay_minimum_setup}}
                }
                if (deviation_setup.length > 0) {
                    requestObject = {...requestObject, ...{deviation_setup}}
                }
                if (logSetup.length > 0) {
                    requestObject = {...requestObject, ...{log_setup: logSetup}}
                }
            }

            if (Object.keys(requestObject).length === 0) {
                toast({
                    'title': '',
                    'message': this.$t('NO_CHANGE'),
                    'type': 'error',
                    'timeout': 3000
                })
                return
            }
            this.loader = true

            setRevenueManagerSetup(this.unit_id, requestObject).then(response => {
                toast({
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
                this.saved = true
            }, error => {
                toast({
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(() => {
                this.loader = false
            })
        },
        getRevenueManagerMinimalValues(unit_id) {
            if (this.mode === 'all') {
                EventBus.$emit(GE_LOADER_SHOW)
            }

            getUnitRevenueManagerSetup(unit_id).then(response => {
                this.setData(response)
            }).finally(() => {
                if (this.mode === 'all') {
                    EventBus.$emit(GE_LOADER_HIDE)
                }
                this.loading = false
            })
        },
        check(event) {
            if (!/\d/.test(event.key) && event.key !== '.') {
                return event.preventDefault()
            }
        },
        setDeviationSetup(data) {
            if (data && typeof data === 'object') {
                if (data.hasOwnProperty('sales_period') && data.sales_period) {
                    for (const period of data.sales_period) {
                        this.$set(this.display, period.id, 2)
                        if (this.bookingWindowMinimumPriceList.length > 0) {
                            const default_price = this.defaultPrice[period.id]
                            for (const bookingWindow of this.bookingWindowMinimumPriceList) {
                                const key = period.id + '_' + bookingWindow.id
                                this.$set(this.price_item, key, null)
                                this.$set(this.price_item_percent, key, null)
                                if (bookingWindow.hasOwnProperty('child_booking_windows') && bookingWindow.child_booking_windows.length > 0) {
                                    for (const childBookingWindow of bookingWindow.child_booking_windows) {
                                        const keyChild = period.id + '_' + +childBookingWindow.id

                                        if (this.deviation_setup_type.length > 0) {
                                            this.deviation_setup_type.forEach(deviation_type => {
                                                const keyDeviationSetup = keyChild + '_' + deviation_type.id
                                                if (data.hasOwnProperty('deviation_setup') && data.deviation_setup.length > 0) {
                                                    const deviationSetup = data.deviation_setup.find(el => el.sales_period === period.id && el.booking_window === childBookingWindow.id && el.type === deviation_type.id)
                                                    value = deviationSetup ? deviationSetup.value : null
                                                }
                                                this.$set(this.deviation_setup, keyDeviationSetup, value)
                                            })

                                        }

                                        let logAction = null
                                        if (data.hasOwnProperty('log_setup') && Array.isArray(data.log_setup) && data.log_setup.length > 0) {
                                            const logSetup = data.log_setup.find((el) => el.sales_period === period.id && el.booking_window === childBookingWindow.id)
                                            logAction = logSetup && typeof logSetup === 'object' && logSetup.hasOwnProperty('log_action') ? logSetup.log_action : null
                                        }
                                        this.$set(this.logSetup, keyChild, logAction)

                                        let value = null
                                        if (data.hasOwnProperty('pricing_minimum_setup') && data.pricing_minimum_setup.length > 0) {
                                            const priceMinValueObject = data.pricing_minimum_setup.find(el => el.sales_period_id === period.id && el.booking_window_id === childBookingWindow.id)
                                            value = priceMinValueObject ? priceMinValueObject.value : null
                                            this.min_reservation_value[period.id] = priceMinValueObject && priceMinValueObject.hasOwnProperty('minimum_reservation_value') ? priceMinValueObject.minimum_reservation_value : null
                                        }
                                        const amount = value ? value : default_price
                                        this.$set(this.initial_price_item, keyChild, value)
                                        this.$set(this.price_item, keyChild, amount)
                                    }
                                }
                            }
                        }
                    }
                }
                if (data.hasOwnProperty('log_action') && Array.isArray(data.log_action)) {
                    this.notificationTypeOptions = data.log_action
                } else {
                    this.notificationTypeOptions = []
                }
            }
        },
        setSecuredValues(data) {
            const displayOption = this.computedDisplayOptions.find(el => el.value === this.display_general)
            if (typeof displayOption === 'undefined' || [0, 1].includes(this.display_general) === false) {
                this.display_general = 0
            }
            if (data && typeof data === 'object') {
                if (data.hasOwnProperty('sales_period') && data.sales_period) {
                    for (const period of data.sales_period) {
                        this.$set(this.display, period.id, this.display_general)
                        if (this.bookingWindowMinimumPriceList.length > 0) {
                            const default_price = this.defaultPrice[period.id]
                            for (const bookingWindow of this.bookingWindowMinimumPriceList) {
                                const key = period.id + '_' + bookingWindow.id
                                this.$set(this.price_item, key, null)
                                this.$set(this.price_item_percent, key, null)
                                if (bookingWindow.hasOwnProperty('child_booking_windows') && bookingWindow.child_booking_windows.length > 0) {
                                    for (const childBookingWindow of bookingWindow.child_booking_windows) {
                                        const keyChild = period.id + '_' + +childBookingWindow.id
                                        let value = null
                                        if (data.hasOwnProperty('pricing_minimum_setup') && data.pricing_minimum_setup.length > 0) {
                                            const priceMinValueObject = data.pricing_minimum_setup.find(el => el.sales_period_id === period.id && el.booking_window_id === childBookingWindow.id)
                                            value = priceMinValueObject ? priceMinValueObject.value : null
                                            this.min_reservation_value[period.id] = priceMinValueObject && priceMinValueObject.hasOwnProperty('minimum_reservation_value') ? priceMinValueObject.minimum_reservation_value : null
                                        }

                                        const amount = value ? value : default_price
                                        this.$set(this.initial_price_item, keyChild, value)
                                        this.$set(this.price_item, keyChild, amount)
                                    }
                                }
                            }
                        }
                        if (this.bookingWindowMinStayList.length > 0) {
                            const min_stay_default = this.defaultRestriction[period.id]
                            for (const bookingWindowMinStay of this.bookingWindowMinStayList) {
                                const key = period.id + '_' + bookingWindowMinStay.id
                                this.$set(this.min_stay_item, key, null)
                                if (bookingWindowMinStay.hasOwnProperty('child_booking_windows') && bookingWindowMinStay.child_booking_windows.length > 0) {
                                    for (const childBookingWindow of bookingWindowMinStay.child_booking_windows) {
                                        const keyChild = period.id + '_' + +childBookingWindow.id
                                        let value = null
                                        if (data.hasOwnProperty('minimum_stay_minimum_setup') && data.minimum_stay_minimum_setup.length > 0) {
                                            const minStayValueObject = data.minimum_stay_minimum_setup.find(el => el.sales_period_id === period.id && el.booking_window_id === childBookingWindow.id)
                                            value = minStayValueObject ? minStayValueObject.value : null
                                            this.min_reservation_stay[period.id] = minStayValueObject ? minStayValueObject.minimum_reservation_value : null
                                        }
                                        const val = value ? value : min_stay_default
                                        this.$set(this.initial_min_stay_item, keyChild, value)
                                        this.$set(this.min_stay_item, keyChild, val)

                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        setData(response) {
            this.data = response.data
            if (this.mode === 'deviation_setup') {
                this.deviation_setup_type = response.data.hasOwnProperty('deviation_setup_type') ? response.data.deviation_setup_type : []
                this.setDeviationSetup(response.data)
            }
            if (this.mode === 'secured_values') {
                this.setSecuredValues(response.data)
            }
        },
        setGeneralDisplayShow(val) {
            if (Object.keys(this.display).length > 0) {
                for (const [key, value] of Object.entries(this.display)) {
                    this.$set(this.display, key, val)
                }
            }
        }
    },
    watch: {
        unit_id: {
            handler(value) {
                if (value) {
                    this.loading = true
                    this.$nextTick(() => {
                        getBulkCalendar(this.property_id, {unit: value}).then(response => {
                            let price_list = []
                            if (response.data.length > 0) {
                                for (const periodData of response.data) {
                                    if (periodData.hasOwnProperty('prices')) {
                                        const price = periodData.prices.find(el => el.los_id === this.defaultLos && el.occupancy_id === this.defaultOccupancy)
                                        if (price) {
                                            this.$set(this.defaultPrice, periodData.id, price.value)
                                        }
                                    }
                                    if (periodData.hasOwnProperty('restrictions') && periodData.restrictions.length > 0) {
                                        for (const restriction of periodData.restrictions) {
                                            if (Number(restriction.rate_plan_id) === Number(this.primaryRatePlan.id) && Number(restriction.id) === 1) {
                                                this.$set(this.defaultRestriction, periodData.id, restriction.value)
                                            }
                                        }
                                    }
                                }
                            }
                            this.getRevenueManagerMinimalValues(value)
                        })
                    })
                }
            }, immediate: true
        },
    }
}
</script>

<style>
.mt-date {
    margin-top: 0.42rem;
}

.display-control {
    width: 8.4rem;
}

.default-price-color {
    color: red;
}
</style>
