<template>
    <form>
        <b-row class="mb-4">
            <b-col lg="12">
                <app-input-control :error-object="validation.name">
                    <template v-slot:input="data">
                        <label>{{$t('NAME')}}</label>
                        <b-input v-model="name" :state="data.state"></b-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <b-col lg="12">
                <app-input-control :error-object="validation.color">
                    <template v-slot:input="data">
                        <label>{{$t('COLOR')}}</label>
                        <b-input v-model="color" type="color" :state="data.state"></b-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <b-col lg="12">
                <app-input-control :error-object="validation.category">
                    <template v-slot:input="data">
                        <label>{{$t('CATEGORY')}}</label>
                        <app-select v-model="category"
                                    :options="categoryOptions"
                                    mode="select"
                                    text-field="label"
                        ></app-select>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button @click="save" :loading="loading">{{$t('SAVE')}}</app-button>
            </b-col>
        </b-row>
    </form>
</template>

<script>

import AppButton from "@/components/app/AppButton/AppButton";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppInputControl from "@/components/app/AppInputControl";
import {getAllThemeCategories, saveThemeColor} from "@/services/appearance";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {APPEARANCE_VALIDATION_COLOR_EMPTY, APPEARANCE_VALIDATION_NAME_EMPTY, APPEARANCE_VALIDATION_CATEGORY_EMPTY} from "@/shared/error_codes";

export default {
    name: "AppearanceNewColorForm",
    mixins: [getErrorMessage],
    components:{AppButton,AppSelect, AppInputControl},
    data(){
        return{
            name: null,
            category: null,
            color: '#000000',
            categoryOptions:[],
            loading: false,
            validation:{
                name: APPEARANCE_VALIDATION_NAME_EMPTY,
                color: APPEARANCE_VALIDATION_COLOR_EMPTY,
                category: APPEARANCE_VALIDATION_CATEGORY_EMPTY
            }
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods:{
        save(){
            this.loading = true
            saveThemeColor({name: this.name, color: this.color, company_id: this.company.id, category: this.category}).then(response => {
                notifySuccess()
                this.loading = false
                this.$emit('color_saved')
            }, error => {
                this.showErrorMessages(error, this.validation)
                this.loading = false
            })
        }
    },
    created() {
        getAllThemeCategories({company_id: this.company.id}).then(response => {
            this.categoryOptions = response.data
        })
    }
}
</script>

<style scoped>

</style>
