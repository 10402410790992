<template>
    <div v-if="formLoaded && distributionSetup !== null">
        <booking-setup-form v-if="distributionSetup.distribution === BOOKING"
                            ref="distribution_specific_general_setup"
                            :distribution="distribution"
                            :setup-parameters="distributionSetup.setup_parameter_list"
                            :setup-saving="setupSaving"
                            :close-on-signed-contract="closeIfContractSigned"
                            @invite="getSetupParameterList"
                            @resendInvite="closePanel"
                            @saveSetup="saveDistributionGeneralSetup"
                            @close="closePanel"
        ></booking-setup-form>
        <home-away-standard-setup-form v-else-if="distributionSetup.communication_type === CM_COMMUNICATION_TYPE_HOME_AWAY"
                                       ref="distribution_specific_general_setup"
                                       :distribution="distribution"
                                       :integrate="integrate"
                                       :setup-parameters="distributionSetup.setup_parameter_list"
                                       :setup-saving="setupSaving"
                                       @saveSetup="saveDistributionGeneralSetup">
        </home-away-standard-setup-form>
    </div>
</template>

<script>
import {
    getDistributionSetupParameterList,
    updateDistributionSetupParameters
} from "@/services/channel_manager";
import {
    BOOKING,
    CM_COMMUNICATION_TYPE_HOME_AWAY,
} from "@/shared/constants";
import {notifySuccess} from "@/shared/plugins/toastr";
import BookingSetupForm
    from "@/components/channel_manager/setup/general_setup/BookingGeneralSetupForm";
import HomeAwayStandardSetupForm
    from "@/components/channel_manager/setup/general_setup/HomeAwayStandardGeneralSetupForm";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "DistributionGeneralSetupForm",
    components: {
        HomeAwayStandardSetupForm,
        BookingSetupForm,
    },
    mixins: [getErrorMessage],
    props: {
        distribution: {
            type: Number,
        },
        company: {
            type: Number,
        },
        integrate: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            distributionSetup: null,
            formLoaded: false,
            setupSaving: false,
            closeIfContractSigned: false,
            BOOKING,
            CM_COMMUNICATION_TYPE_HOME_AWAY,
        }
    },
    methods: {
        saveDistributionGeneralSetup(setupParameterList) {
            this.setupSaving = true
            let requestObject = {
                distribution: this.distribution,
                company: this.company,
                integrate: this.integrate,
                setup_parameter_list: setupParameterList
            }

            if (this.distribution === BOOKING) {
                this.updateBookingSetupParameterList(requestObject)
                return
            }

            updateDistributionSetupParameters(requestObject).then(() => {
                notifySuccess()
                this.$emit('setupSaved')
            }, (error) => {
                this.showErrorMessages(error, this.$refs.distribution_specific_general_setup.$data.validationObject)
            }).finally(() => {
                this.setupSaving = false
            })

        },
        getSetupParameterList() {
            this.formLoaded = false
            getDistributionSetupParameterList({
                distribution: this.distribution,
                company: this.company,
                level: 'company',
                fill_default_setup_parameter_values: 1,
                omit_check_all_parameters: 1,
            }).then(response => {
                this.distributionSetup = response.data
            }).finally(() => {
                this.formLoaded = true
            })
        },
        updateBookingSetupParameterList(requestObject)
        {
            updateDistributionSetupParameters(requestObject).then(() => {
                notifySuccess()
                this.getSetupParameterList()
                this.$emit('setupSaved', {close_panel: false})
                this.closeIfContractSigned = true
            }, (error) => {
                this.showErrorMessages(error, this.$refs.distribution_specific_general_setup.$data.validationObject)
            }).finally(() => {
                this.setupSaving = false
            })
        },
        closePanel(args = null)
        {
            if (args !== null && typeof args === 'object' && args.hasOwnProperty('reload') && args.reload) {
                this.$emit('setupSaved')
                return
            }

            this.$emit('close')
        }
    },
    created() {
        this.getSetupParameterList()
    },
}
</script>

<style scoped>

</style>
