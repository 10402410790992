<template>
    <b-card v-cloak class="property-wrapper">
        <template slot="header">
            <h2 class="property-wrapper-padding">{{ $t('SETUP') }}</h2>

            <app-button v-if="min_stay_activated"
                        :loading="min_stay_loader"
                        @click="deactivateConfirmation(ACTIVE_REVENUE_MANAGER_MINIMUM_STAY)"
                        variant="primary"
                        class="pull-right ml-3"
            >{{ $t('DEACTIVATE_RM_MIN_STAY_MODULE') }}
            </app-button>

            <app-button v-if="rate_activated"
                        :loading="rate_loader"
                        @click="deactivateConfirmation(ACTIVE_REVENUE_MANAGER_RATES)"
                        variant="primary"
                        class="pull-right"
            >{{ $t('DEACTIVATE_RM_RATE_MODULE') }}
            </app-button>

            <app-confirmation-dialog v-if="deactivation_mode === ACTIVE_REVENUE_MANAGER_RATES"
                                    :show="confirmationDeactivate"
                                     @confirm="deactivation"
                                     :title="deactivateConfirmationTitle"
                                     @cancel="confirmationDeactivate = false"
            >{{ $t('DEACTIVATE_RM_AUTOMATIC_RATES') }}
            </app-confirmation-dialog>
            <app-confirmation-dialog v-if="deactivation_mode === ACTIVE_REVENUE_MANAGER_MINIMUM_STAY"
                                    :show="confirmationDeactivate"
                                     @confirm="deactivation"
                                     :title="deactivateConfirmationTitle"
                                     @cancel="confirmationDeactivate = false"
            >{{ $t('DEACTIVATE_RM_AUTOMATIC_MIN_STAY') }}
            </app-confirmation-dialog>

            <Navigation :nav-elements="navElements">
            </Navigation>
        </template>
        <router-view>
        </router-view>
    </b-card>
</template>

<script>
import routeNames from "@/router/routeNames";
import AppButton from "@/components/app/AppButton/AppButton";
import Navigation from "@/components/app/navigation/Navigation";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {getRevenueManagerSetup, saveRevenueManagerSetup} from "@/services/revenue_manager";
import {ACTIVE_REVENUE_MANAGER_RATES, ACTIVE_REVENUE_MANAGER_MINIMUM_STAY} from "@/shared/constants";
import {toast} from "@/shared/plugins/toastr";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "Index",
    components: {
        AppButton,
        Navigation,
        AppConfirmationDialog,
    },
    data() {
        return {
            ACTIVE_REVENUE_MANAGER_RATES,
            ACTIVE_REVENUE_MANAGER_MINIMUM_STAY,
            fetch: false,
            rate_activated: false,
            min_stay_activated: false,
            rate_loader: false,
            min_stay_loader: false,
            confirmationDeactivate: false,
            deactivation_mode: null,
        }
    },
    computed: {
        navElements() {
            return [
                {
                    route: {name: routeNames.RN_REVENUE_MANAGER_SETUP_ACTION_PRICE}
                },
                {
                    route: {name: routeNames.RN_REVENUE_MANAGER_SETUP_ACTION_MIN_STAY},
                },
                {
                    route: {name: routeNames.RN_REVENUE_MANAGER_SETUP_PROPERTIES},
                },
            ]
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        deactivateConfirmationTitle() {
            if (this.deactivation_mode === ACTIVE_REVENUE_MANAGER_RATES) {
                return this.$t('DEACTIVATION_CONFIRMATION_RATES')
            }
            if (this.deactivation_mode === ACTIVE_REVENUE_MANAGER_MINIMUM_STAY) {
                return this.$t('DEACTIVATION_CONFIRMATION_MIN_STAY')
            }
            return ''
        },
    },
    methods: {
        deactivateConfirmation(mode) {
            this.deactivation_mode = mode
            this.confirmationDeactivate = true
        },
        deactivation() {
            const mode = this.deactivation_mode
            let request = {
                setup_list: [
                    {
                        setup_parameter: mode,
                        property: null,
                        unit: null,
                        company: this.company.id,
                        value: 0
                    },
                ]
            }
            if (mode === ACTIVE_REVENUE_MANAGER_RATES) {
                this.rate_loader = true
            }
            if (mode === ACTIVE_REVENUE_MANAGER_MINIMUM_STAY) {
                this.min_stay_loader = true
            }
            saveRevenueManagerSetup(request).then(response => {
                this.activated = 1
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
                this.loader = false
                EventBus.$emit('gl_reload_rm_setup')
                if (mode === ACTIVE_REVENUE_MANAGER_RATES) {
                    this.rate_activated = false
                }
                if (mode === ACTIVE_REVENUE_MANAGER_MINIMUM_STAY) {
                    this.min_stay_activated = false
                }
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(() => {
                if (mode === ACTIVE_REVENUE_MANAGER_RATES) {
                    this.rate_loader = false
                }
                if (mode === ACTIVE_REVENUE_MANAGER_MINIMUM_STAY) {
                    this.min_stay_loader = false
                }
                this.deactivation_mode = null
                this.confirmationDeactivate = false
            })
        },
        getRevenueSetup() {
            getRevenueManagerSetup({
                company: this.company.id,
                setup_parameter: [ACTIVE_REVENUE_MANAGER_RATES, ACTIVE_REVENUE_MANAGER_MINIMUM_STAY]
            }).then(response => {
                if (response.data.length > 0) {
                    for (const setupObject of response.data) {
                        if (setupObject.setup_parameter.id === ACTIVE_REVENUE_MANAGER_RATES) {
                            this.rate_activated = Number(setupObject.values.final) === 1
                        }
                        if (setupObject.setup_parameter.id === ACTIVE_REVENUE_MANAGER_MINIMUM_STAY) {
                            this.min_stay_activated = Number(setupObject.values.final) === 1
                        }
                    }
                }
            }).finally(() => {
                this.fetch = true
            })
        }
    },
    mounted() {
        EventBus.$on('ge_deactivation_action', () => {
            this.getRevenueSetup()
        })
    },
    beforeDestroy() {
        EventBus.$off('ge_deactivation_action')
    },
    created() {
        this.getRevenueSetup()
    }
}
</script>

<style scoped>

</style>
