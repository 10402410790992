<template>
    <div>
        <app-table-v2 :fields="computedFields"
                      :exportColumns="exportColumns"
                      :selectable="true"
                      :paginated="false"
                      :provider="getData"
                      :default-limit="defaultLimit"
                      :show-limit="showLimit"
                      :filter="{}"
                      :http="{methods: 'GET', url: null}"
                      :search-event="SEARCH_EVENT"
                      :search-on-create="true">
            <template v-slot:created_date="data">
                <small class="text-muted d-block">
                    {{ data.item.created_date | time }}
                </small>
                <div class="ws-no-wrap">
                    {{ data.item.created_date | date }}
                </div>
            </template>

            <template v-slot:cc="data">
                <small class="text-muted d-block">
                    ####{{ data.item.cc_digits }}
                </small>
                {{ data.item.cc_type.name }}
            </template>

            <template v-slot:actions="data">
                <template v-if="allowedActions[data.item.id].length">
                    <app-button :key="data.item.id" v-for="action in allowedActions[data.item.id]"
                                variant="link" :icon="icons[action]" :show_text="false"
                                @click="$emit('openTransactionForm', {transaction: data.item, action})"></app-button>
                </template>
            </template>
        </app-table-v2>
    </div>
</template>

<script>
    import AppTableV2 from "@/components/app/AppTableV2";
    import AppButton from "@/components/app/AppButton/AppButton";
    import moment from "moment";
    import {
        F_CALCULATION_TRANSACTION_CAPTURE,
        F_CALCULATION_TRANSACTION_REFUND,
        F_CALCULATION_TRANSACTION_VOID
    } from "@/shared/function_permission";

    const icons = {
        [F_CALCULATION_TRANSACTION_CAPTURE]: 'handshake',
        [F_CALCULATION_TRANSACTION_REFUND]: 'undo',
        [F_CALCULATION_TRANSACTION_VOID]: 'cancel'
    }

    export default {
        name: "CompanyCalculationTransactionList",
        components: {AppButton, AppTableV2},
        props: {
            defaultLimit: {
                type: Number,
                default: 100
            },
            showLimit: {
                type: Boolean,
                default: false
            },
            allowedActions: {
                type: Object,
                required: true
            },
            tableData: {
                type: Array,
                default: []
            },
        },
        data() {
            return {
                fields: [
                    {key: 'id', label: this.$t('ID')},
                    {key: 'order_number', label: this.$t('NUMBER')},
                    {key: 'cc', label: this.$t('CC_DATA')},
                    {key: 'status.label', label: this.$t('STATUS')},
                    {key: 'type.label', label: this.$t('TYPE')},
                    {key: 'amount_f', label: this.$t('AMOUNT'), tdClass: 'ws-no-wrap text-right'},
                    {key: 'created_date', label: this.$t('CREATED_AT')},
                ],
                exportColumns: [
                    {value: 'id', text: this.$t('ID')},
                    {value: 'order_number', text: this.$t('NUMBER')},
                    {value: 'cc_f', text: this.$t('CC_DATA')},
                    {value: 'status.label', text: this.$t('STATUS')},
                    {value: 'type.label', text: this.$t('TYPE')},
                    {value: 'amount_f', text: this.$t('AMOUNT'), tdClass: 'ws-no-wrap text-right'},
                    {value: 'created_date_f', text: this.$t('CREATED_AT')},
                ],
                SEARCH_EVENT: 'getCalculationTransactions',
                icons,
            }
        },
        computed: {
            computedFields() {
                return this.fields
            },
            computedTableData() {
                return this.tableData.map((item, index) => {
                    const prev = this.tableData[index-1]
                    let _rowVariant = item.status.hasOwnProperty('code') && item.status.code !== '0000' ? ' table-text-danger' : ''
                    _rowVariant += prev && prev.order_number === item.order_number ? ' table-link-border' : ''
                    let cc_f = '####' + item.cc_digits + (item.cc_type.hasOwnProperty('name') && item.cc_type.name ? ' (' + item.cc_type.name + ')': '')
                    let created_date_f = item.created_date ? moment(item.created_date).format('LLL') : ''
                    return {
                        ...item,
                        cc_f,
                        created_date_f,
                        _rowVariant,
                        _cellVariants: {id: (prev && prev.order_number === item.order_number) ? 'link' : ''}
                    }
                })
            },
        },
        methods: {
            getData(req) {
                return new Promise((resolve, reject) => {
                    resolve({
                        data: this.computedTableData
                    });
                })
            },
        },
        created() {
            let showActions = Object.entries(this.allowedActions).reduce((acc, [key, value]) => {
                return value.length > 0 || acc
            }, false)
            if (showActions) {
                this.fields.push({key: 'actions', label: '', class: 'text-right'})
            }
        },
    }
</script>

<style scoped>

</style>
