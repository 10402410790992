<template>
    <div>
        <div class="suggestion-table-container">
            <app-table-v2
                :fields="computedFields"
                :provider="dataProvider"
                :default-limit="defaultLimit"
                :show-limit="showLimit"
                @busyToggled="toggleBusy"
                :search-event="SEARCH_EVENT">
                <template v-slot:unit="data">
                    {{data.item.unit.name}}
                </template>

                <template v-slot:date="data">
                    {{data.item.date | date}}
                </template>

                <template v-slot:HEAD_recommended_price="data">
                    <div class="d-flex align-items-end">
                        <b-checkbox v-model="priceCBState" @change="selectAllForType($event, type_price)" size="sm"
                                    style="padding-top: 9px"></b-checkbox>
                        <span>{{$t('RECOMMENDED_PRICE')}}</span>
                    </div>
                </template>

                <template v-slot:recommended_price="data">
                    <template v-if="formData[data.item.price_id]">
                        <div class="d-flex align-items-center mb-2 mt-2">
                            <b-checkbox
                                :key="`cb_${formData[data.item.price_id]}`"
                                v-if="statusListObject[formData[data.item.price_id].status].editable"
                                v-model="formData[data.item.price_id].checked"
                                :value="1"
                                :unchecked-value="0" @change="verifyCheckAllState(type_price)">
                            </b-checkbox>

                            <b-checkbox v-else v-visible="false"></b-checkbox>

                            <div>
                                <small class="d-block"
                                       :class="statusListObject[formData[data.item.price_id].status].class">
                                    {{statusListObject[formData[data.item.price_id].status].label}}
                                </small>

                                <app-number-input
                                    v-model="formData[data.item.price_id].value"
                                    allow-decimal
                                    :min="0"
                                    align="right"
                                    :append="!!formData[data.item.price_id].value"
                                    :disabled="!statusListObject[formData[data.item.price_id].status].editable">
                                    <template v-slot:append>
                                        <span :class="Math.abs(calculateDifference(formData[data.item.price_id].value, data.item.current_price)) >= 30 ? 'text-danger' : ''">
                                            {{calculateDifference(formData[data.item.price_id].value, data.item.current_price)}}%
                                        </span>
                                    </template>
                                </app-number-input>
                            </div>
                        </div>
                    </template>
                </template>

                <template v-slot:current_min_stay_formatted="data">
                    <template v-if="data.item.current_min_stay">
                        {{data.item.current_min_stay}} {{ data.item.current_min_stay > 1 ? $t('NIGHTS').toLowerCase() :
                        $t('NIGHT').toLowerCase()}}
                    </template>
                </template>

                <template v-slot:HEAD_recommended_min_stay="data">
                    <div class="d-flex align-items-end">
                        <b-checkbox v-model="minStayCBState" @change="selectAllForType($event, type_min_stay)" size="sm"
                                    style="padding-top: 9px"></b-checkbox>
                        <span>{{$t('RECOMMENDED_MIN_STAY')}}</span>
                    </div>
                </template>

                <template v-slot:recommended_min_stay="data">
                    <template v-if="formData[data.item.min_stay_id]">
                        <div class="d-flex align-items-center mb-2 mt-2">
                            <b-checkbox
                                v-if="statusListObject[formData[data.item.min_stay_id].status].editable"
                                v-model="formData[data.item.min_stay_id].checked"
                                :value="1"
                                :unchecked-value="0" @input="verifyCheckAllState(type_min_stay)">
                            </b-checkbox>

                            <b-checkbox v-else v-visible="false"></b-checkbox>

                            <div>
                                <small class="d-block"
                                       :class="statusListObject[formData[data.item.min_stay_id].status].class">
                                    {{statusListObject[formData[data.item.min_stay_id].status].label}}
                                </small>

                                <app-select
                                    :options="getMinStayOptions(data.item.recommended_min_stay)"
                                    v-model="formData[data.item.min_stay_id].value"
                                    :search-empty-item="false"
                                    text-field="label"
                                    :disabled="!statusListObject[formData[data.item.min_stay_id].status].editable">
                                </app-select>
                            </div>
                        </div>
                    </template>
                </template>
            </app-table-v2>
        </div>

        <transition name="fade" v-if="hasChecked">
            <div class="suggestion-button-container position-fixed">
                <app-button @click="save(action_accept)" :loading="saving">{{$t('ACCEPT')}}</app-button>
                <app-button class="ml-3" variant="danger" @click="save(action_decline)" :loading="saving">
                    {{$t('DECLINE')}}
                </app-button>
            </div>
        </transition>
    </div>
</template>

<script>
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppTableV2 from "@/components/app/AppTableV2";
    import {cloneDeep, uniqBy} from "lodash";
    import {getRateManagerSuggestions, saveRateManagerSuggestions} from "@/services/rate_manager";
    import {EventBus} from "@/shared/EventBus";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppNoData from "@/components/app/AppNoData";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {
        MIN_STAY,
        RM_SUGGESTION_STATUS_ACTIVE,
        RM_SUGGESTION_STATUS_CHANGED,
        RM_SUGGESTION_STATUS_DECLINED,
    } from '@/shared/constants';
    import {C_RATE_MANAGER_SUGGESTIONS_CHANGED_E} from "@/shared/component_permission";

    const type_price = 1
    const type_min_stay = 2

    const types = {
        [type_price]: 'price',
        [type_min_stay]: 'min_stay'
    }

    const action_accept = 1
    const action_decline = 2

    export default {
        name: "RateManagerSuggestionTable",
        components: {AppNoData, AppButtonSubmit, AppSelect, AppNumberInput, AppButton, AppTableV2},
        mixins: [getErrorMessage],
        props: {
            defaultLimit: {
                type: Number,
                default: 999
            },
            showLimit: {
                type: Boolean,
                default: false
            },
            initialSearch: {
                type: Object,
                default: null,
            },
            omittedFields: {
                type: Array,
                default: () => []
            },
            selected: {
                type: Array,
                default: () => []
            },
            selectAll: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                fields: [
                    {key: 'unit', label: this.$t('UNIT'), class: 'w-25'},
                    {key: 'date', label: this.$t('_DATE')},
                    {key: 'current_price_formatted', label: this.$t('CURRENT_PRICE'), tdClass: 'text-right'},
                    {key: 'recommended_price', label: this.$t('RECOMMENDED_PRICE'), tdClass: 'pt-0 pb-0'},
                    {key: 'current_min_stay_formatted', label: this.$t('CURRENT_MIN_STAY')},
                    {key: 'recommended_min_stay', label: this.$t('RECOMMENDED_MIN_STAY'), tdClass: 'pt-0 pb-0'},
                ],
                SEARCH_EVENT: 'getSuggestions',
                formData: null,
                unitList: [],
                statusList: [
                    {id: RM_SUGGESTION_STATUS_ACTIVE, label: this.$t('ACTIVE')},
                    {id: RM_SUGGESTION_STATUS_CHANGED, label: this.$t('CHANGED')},
                    {id: RM_SUGGESTION_STATUS_DECLINED, label: this.$t('DECLINED')},
                ],
                type_price,
                type_min_stay,
                filter: {
                    unit: [],
                    status: [],
                },
                suggestionList: [],
                initialSuggestionList: [],
                loading: false,
                firstSearch: true,
                priceCBState: false,
                minStayCBState: false,
                action_accept,
                action_decline,
                saving: false
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            computedFields() {
                let fields = cloneDeep(this.fields.filter(field => !this.omittedFields.includes(field.key)))
                if (this.suggestionList.length) {
                    return (!this.suggestionList.some(item => item.type === type_min_stay))
                        ? fields.filter(field => field.key !== `current_${types[type_min_stay]}_formatted` && field.key !== `recommended_${types[type_min_stay]}`)
                        : fields;
                } else {
                    return fields
                }
            },
            hasChecked() {
                return this.formData ? Object.entries(this.formData).reduce((acc, [key, item]) => {
                    return acc || item.checked;
                }, false) : false
            },
            statusListObject() {
                return this.statusList.reduce((acc, status) => {
                    acc[status.id] = {
                        label: status.label,
                        class: status.id === RM_SUGGESTION_STATUS_DECLINED ? 'text-danger' : 'text-primary',
                        editable: status.id !== RM_SUGGESTION_STATUS_DECLINED
                            && !(status.id === RM_SUGGESTION_STATUS_CHANGED && !this.checkPermission(C_RATE_MANAGER_SUGGESTIONS_CHANGED_E))
                    }
                    return acc
                }, {})
            }
        },
        methods: {
            search() {
                this.priceCBState = false
                this.minStayCBState = false
                EventBus.$emit(this.SEARCH_EVENT)
            },
            reset() {
                this.filter.unit = []
                this.filter.status = []
                this.priceCBState = false
                this.minStayCBState = false
                EventBus.$emit(this.SEARCH_EVENT)
            },
            save(action) {
                this.saving = true
                const data = Object.values(this.formData)
                    .filter(el => el.checked && !(action === action_accept && !el.value))
                    .map(el => {
                        return {
                            id: el.id,
                            value: el.value,
                            action
                        }
                    })
                saveRateManagerSuggestions({suggestions: data}).then(() => {
                    notifySuccess()
                    this.$emit('close')
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.saving = false
                })
            },
            dataProvider(req) {
                const promise = (this.firstSearch)
                    ? getRateManagerSuggestions({
                        page: req.currentPage,
                        limit: req.perPage,
                        ...this.filter,
                        company: this.company.id,
                        order_by: 'id',
                        order_by_direction: 'desc'
                    })
                    : Promise.resolve(this.initialSuggestionList.filter(el => {
                        return (!this.filter.unit.length || this.filter.unit.includes(el.unit.id) )
                            && (!this.filter.status.length || this.filter.status.includes(el.status))
                    }))
                return promise.then(response => {
                    const items = this.firstSearch ? response.data.items : response

                    if (this.firstSearch) {
                        this.initialSuggestionList = items
                    }

                    this.suggestionList = items
                    this.formData = this.createFormData(items)
                    this.firstSearch = false
                    this.verifyCheckAllState(type_price)
                    this.verifyCheckAllState(type_min_stay)

                    return {
                        data: {
                            page: 1,
                            total: items.length,
                            items: this.formatForDisplay(items)
                        }
                    }
                })
            },
            toggleBusy($event) {
                this.loading = $event
                EventBus.$emit('loadingSuggestions', $event)
            },
            createFormData(items) {
                return items.reduce((acc, el) => {
                    let value

                    if (el.status === RM_SUGGESTION_STATUS_CHANGED) {
                        value = (el.updated_value !== null) ? Number(el.updated_value) : null
                    } else {
                        value = (el.recommended_value !== null) ? Number(el.recommended_value) : null
                    }

                    acc[el.id] = {
                        id: el.id,
                        value,
                        status: el.status,
                        type: el.type,
                        checked: this.selectAll ? 1 : this.selected.includes(el.id) ? 1 : 0
                    }
                    return acc
                }, {})
            },
            formatForDisplay(items) {
                let data = {}
                items.forEach(item => {
                    if (!data[item.date]) {
                        data[item.date] = {}
                    }

                    if (!data[item.date][item.unit.id]) {
                        data[item.date][item.unit.id] = {unit: item.unit, date: item.date}
                    }

                    data[item.date][item.unit.id] = {
                        ...data[item.date][item.unit.id],
                        [`${types[item.type]}_id`]: item.id,
                        [`current_${types[item.type]}`]: Number(item.current_value),
                        [`current_${types[item.type]}_formatted`]: item.current_value_formatted,
                        [`recommended_${types[item.type]}`]: Number(item.recommended_value),
                    }
                })

                return Object.entries(data)
                    .map(([key, value]) => Object.values(value))
                    .flat()
                    .sort((a, b) => {
                        const unitCompare = a.unit.name.localeCompare(b.unit.name)

                        if (unitCompare) {
                            return unitCompare
                        } else {
                            return new Date(a.date) - new Date(b.date)
                        }
                    })
            },
            calculateDifference(suggested, current) {
                return suggested ? Number(-((current - suggested) / current * 100).toFixed(2)) : null
            },
            getMinStayOptions(recommendedMinStay) {
                let options = []
                for (let i = recommendedMinStay; i > 0; i--) {
                    options.push({
                        id: i,
                        label: i
                    })
                }
                return options
            },
            selectAllForType(value, type) {
                for (let [key, item] of Object.entries(this.formData)) {
                    if (item.type === type && this.statusListObject[item.status].editable) {
                        item.checked = value ? 1 : 0
                    }
                }
            },
            verifyCheckAllState(type) {
                this.$nextTick(() => {
                    const state = Object.entries(this.formData).reduce((acc, [key, item]) => {
                        return item.type === type && this.statusListObject[item.status].editable ? acc && item.checked : acc;
                    }, true)

                    if (type === type_min_stay) {
                        this.minStayCBState = !!state
                    } else {
                        this.priceCBState = !!state
                    }
                })
            }
        },
        mounted() {
            if (this.initialSearch) {
                this.filter = cloneDeep(this.initialSearch)
                EventBus.$emit(this.SEARCH_EVENT)
            }

            EventBus.$on('searchSuggestions', filter => {
                this.filter = cloneDeep(filter)
                EventBus.$emit(this.SEARCH_EVENT)
            })
        },
        beforeDestroy() {
            EventBus.$off('searchSuggestions')
        }
    }
</script>

<style lang="scss" scoped>
    .suggestion-button-container {
        bottom: 0;
        z-index: 1337;
        transform: translateY(-25px);
    }

    .suggestion-table-container {
        max-height: 70vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
            height: 1rem;
            width: 0.5rem;
        }

        &::-webkit-scrollbar-track {
            background-color: #e1e1e1;
        }

        &::-webkit-scrollbar-thumb {
            background: #c0c0c0;
            border-radius: 15px;
            border: none;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
</style>
