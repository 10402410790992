<template>
    <div class="property-wrapper mb-0 res-no-border ">
        <div class="card-header review-header">
            <div class="header-caption">{{$t('REVIEW')}}</div>
            <h2 class="property-wrapper-padding mb-2">
                {{review.id}}
            </h2>
            <div class="header-info">
                {{review.published.user.name}} |
                {{distribution_name}} |
                {{review.status.name}} |
                {{review.object.name}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReviewAccommodationHeader",
        props:{
            review:{
                type:Object
            },
            distribution_name:{
                type:String,

            }
        },
    }
</script>

<style scoped>
.review-header{
    padding-left: 0;
}
.res-no-border {
    box-shadow: none;
}

.res-no-border > div {
    border: none !important;
    padding-left: 0;
}
</style>
