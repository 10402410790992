<template>
    <v-datepicker
        :disabled="disabled"
        width="100%"
        :input-class="state=== false ? 'form-control is-invalid' :' form-control'"
        type="time"
        confirm
        :clearable="clearable"
        @confirm="change"
        @clear="change(null)"
        :minute-step="minuteStep"
        value-type="format"
        format="HH:mm"
        placeholder=""
        :value="valueComputed">
        <template slot="calendar-icon">
            <i class="fa fa-clock-o fa-fw clock-icon" aria-hidden="true"></i>
        </template>
    </v-datepicker>
</template>

<script>
    import DatePicker from 'vue2-datepicker'

    export default {
        name: "TimePicker",
        props: {
            value: {
                default: null
            },
            state: {
                type: Boolean,
                default: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            minuteStep:{
                type:Number,
                default: 10
            },
            clearable: {
                type: Boolean,
                default: false
            }
        },
        components: {
            'v-datepicker': DatePicker
        },
        data() {
            return {}
        },
        methods: {
            change(val) {
                if (val !== '') {
                    this.$emit('input', val)
                } else {
                    this.$emit('input', '00:00')
                }
            }
        },
        computed: {
            valueComputed() {
                if (this.value === null) {
                    return this.value
                }

                return this.value
            }
        }
    }
</script>

<style scoped>
    .clock-icon {
        position: absolute;
        top: 12px;
        color: #bbb;
    }
</style>
