<template>
    <b-form @submit.prevent="createTransaction" v-if="loaded">
        <b-row>
            <b-col md="6" class="mb-4" v-if="objectType === OBJECT_TYPE_ACCOMMODATION_RESERVATION">
                <label>{{$t('STATUS')}}</label>
                <app-select :options="statusList"
                            v-model="formData.next_status"
                            text-field="label"
                            :search-empty-item="false"></app-select>
            </b-col>
            <b-col md="6" class="mb-4">
                <app-input-control :error-object="validation.amount">
                    <template v-slot:input="data">
                        <label>{{ $t('AMOUNT') }}</label>
                        <app-number-input
                            v-model="formData.amount"
                            allow-decimal
                            :append="company.main_currency.code"
                            :min="0"
                            :state="data.state"
                            align="right">
                        </app-number-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>

        <b-row v-if="objectType === OBJECT_TYPE_ACCOMMODATION_RESERVATION">
            <b-col md="6" class="mb-4 d-flex">
                <b-checkbox v-model="formData.guest_invoice" :value="1" :unchecked-value="0" switch>
                    {{$t('GUEST_INVOICE')}}
                </b-checkbox>

                <b-checkbox v-model="formData.owner_invoice" :value="1" :unchecked-value="0" switch class="ml-5">
                    {{$t('OWNER_INVOICE')}}
                </b-checkbox>
            </b-col>
        </b-row>

        <b-row v-if="objectType === OBJECT_TYPE_ACCOMMODATION_RESERVATION">
            <b-col xl="4" md="12" class="mb-4">
                <label>{{ $t('_DATE') }} & {{ $t('TIME').toLowerCase() }} (CET)</label>
                <app-date-picker
                    :value="transactionExecutionMomentDate ? transactionExecutionMomentDate.toDate() : null"
                    :min-date="new Date()"
                    @input="setTransactionDate">
                </app-date-picker>
            </b-col>

            <b-col xl="2" md="12" class="mb-4">
                <label>&zwnj;</label>
                <time-picker
                    :value="transactionExecutionMomentDate ? transactionExecutionMomentDate.format('HH:mm') : null"
                    @input="setTransactionTime">
                </time-picker>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <app-button-submit :loading="saving" :disabled="saveDisabled"></app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {cloneDeep} from "lodash";
    import {createAccommodationReservationTransaction} from "@/services/accommodation_reservation";
    import {
        RES_STATUS_FINANCE_LIST,
        OBJECT_TYPE_ACCOMMODATION_RESERVATION,
        OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE,
        PG_ACTION_AUTHORIZE,
        PG_ACTION_PURCHASE,
        PG_ACTION_CAPTURE,
        PG_ACTION_REFUND,
        PG_ACTION_VOID,
    } from "@/shared/constants";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {notifySuccess, toast} from "@/shared/plugins/toastr";
    import {fetchStatusList} from "@/services/status";
    import AppDatePicker from "@/components/app/datetime/AppDatePicker";
    import TimePicker from "@/components/app/datetime/TimePicker";
    import moment from "moment";
    import AppInputControl from "@/components/app/AppInputControl";
    import {PG_AMOUNT_LEQUAL_ZERO} from "@/shared/error_codes";
    import {
        authorizePaymentGatewayTransaction,
        purchasePaymentGatewayTransaction,
        capturePaymentGatewayTransaction,
        refundPaymentGatewayTransaction,
        voidPaymentGatewayTransaction,
    } from "@/services/payment_gateway";

    const typeEndpointMapping = {
        [OBJECT_TYPE_ACCOMMODATION_RESERVATION]: createAccommodationReservationTransaction
    }
    const pgActionMap = {
        // [PG_ACTION_PAYMENT_ORDER]: {endpoint: PG_ACTION_PAYMENT_ORDER, initial: null},
        [PG_ACTION_AUTHORIZE]: {endpoint: authorizePaymentGatewayTransaction, initial: true},
        [PG_ACTION_PURCHASE]: {endpoint: purchasePaymentGatewayTransaction, initial: true},
        [PG_ACTION_CAPTURE]: {endpoint: capturePaymentGatewayTransaction, initial: false},
        [PG_ACTION_REFUND]: {endpoint: refundPaymentGatewayTransaction, initial: false},
        [PG_ACTION_VOID]: {endpoint: voidPaymentGatewayTransaction, initial: false},
    }

    export default {
        name: "TransactionForm",
        components: {AppInputControl, AppButtonSubmit, AppNumberInput, AppSelect, AppDatePicker, TimePicker},
        mixins: [getErrorMessage],
        props: {
            objectType: {
                type: Number,
                required: true
            },
            objectId: {
                type: Number,
                required: true
            },
            transactionObject: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                OBJECT_TYPE_ACCOMMODATION_RESERVATION,
                formData: {
                    amount: null,
                    pg_action: null,
                },
                saving: false,
                statusList: [],
                loaded: false,
                validation: {
                    amount: {
                        input: [
                            PG_AMOUNT_LEQUAL_ZERO
                        ],
                    },
                },
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            transactionExecutionMomentDate() {
                return this.formData.ts_execute && moment.unix(this.formData.ts_execute)
            },
            saveDisabled() {
                return this.formData.amount === undefined || this.formData.amount === null || Number(this.formData.amount) <= 0
            },
        },
        methods: {
            createTransaction() {
                this.saving = true
                this.formData.amount = Number(this.formData.amount)
                if (typeEndpointMapping.hasOwnProperty(this.objectType)) {
                    typeEndpointMapping[this.objectType](this.objectId, this.formData).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.TRANSACTION_SCHEDULED_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.TRANSACTION_SCHEDULED_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 10000 // 10 sec
                        })
                        this.$emit('close')
                    }, (error) => {
                        this.showErrorMessages(error, this.validation)
                    }).finally(() => this.saving = false)
                } else if (this.transactionObject.hasOwnProperty('pg_action') && this.transactionObject.pg_action && pgActionMap.hasOwnProperty(this.transactionObject.pg_action)) {
                    const transactionEndpoint = pgActionMap[this.transactionObject.pg_action].endpoint
                    const isInitial = pgActionMap[this.transactionObject.pg_action].initial
                    if (isInitial) {
                        transactionEndpoint(this.formData).then(() => {
                            toast({
                                'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 10000 // 10 sec
                            })
                            this.$emit('close')
                        }, (error) => {
                            this.showErrorMessages(error, this.validation)
                        }).finally(() => this.saving = false)
                    } else {
                        const transactionId = this.formData.transaction || null
                        transactionEndpoint(transactionId, this.formData).then(() => {
                            toast({
                                'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 10000 // 10 sec
                            })
                            this.$emit('close')
                        }, (error) => {
                            this.showErrorMessages(error, this.validation)
                        }).finally(() => this.saving = false)
                    }
                } else {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('VALIDATION_FAILED'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    this.saving = false
                }
            },
            setTransactionTime(time) {
                const date = this.transactionExecutionMomentDate ? this.transactionExecutionMomentDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
                let selectedUnixTime = moment(`${date} ${time}`).unix()
                this.formData.ts_execute = this.getUnixTime(selectedUnixTime)
            },
            setTransactionDate(date) {
                const time = this.transactionExecutionMomentDate ? this.transactionExecutionMomentDate.format('HH:mm') : '00:00'
                let selectedUnixTime = moment(`${date} ${time}`).unix()
                this.formData.ts_execute = this.getUnixTime(selectedUnixTime)
            },
            getUnixTime(selectedUnixTime) {
                let currentTime = moment().startOf('minute')
                let currentUnixTime = currentTime.unix()
                if (selectedUnixTime < currentUnixTime) {
                    selectedUnixTime = currentUnixTime
                    let remainder = 10 - (currentTime.minute() % 10)
                    if (remainder !== 0) {
                        currentTime = moment(currentTime).add(remainder, 'minutes')
                        selectedUnixTime = currentTime.unix()
                    }
                }
                return selectedUnixTime
            },
        },
        created() {
            this.formData = cloneDeep(this.transactionObject)

            if (this.objectType === OBJECT_TYPE_ACCOMMODATION_RESERVATION) {
                this.$set(this.formData, 'owner_invoice', 0)
                this.$set(this.formData, 'guest_invoice', 1)
                this.$set(this.formData, 'ts_execute', null)

                fetchStatusList(OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE).then(response => {
                    this.statusList = response.data.filter(status => RES_STATUS_FINANCE_LIST.includes(Number(status.id)))

                    if (!this.formData.next_status) {
                        this.$set(this.formData, 'next_status', this.statusList[0].id)
                    }
                    this.loaded = true
                }, error => this.showErrorMessages(error))
            } else {
                this.$set(this.formData, 'object_type', this.objectType)
                this.$set(this.formData, 'object', this.objectId)
                this.loaded = true
            }
        }
    }
</script>

<style scoped>

</style>
