<template>
    <div>
        <app-table-v2 :fields="computedFields"
                      :provider="calculatedData"
                      @busyToggled="toggleBusy"
                      :default-limit="defaultLimit"
                      :show-limit="false"
                      :search-event="searchEventName"
                      :paginated="false"
                      :listPath="listPath"
        >

            <template v-slot:id="data">
                <template v-if="data.item.id">
                    {{data.item.id}}
                </template>
            </template>

            <template v-slot:unit="data">
                <template v-if="data.item.name">
                    {{data.item.name}}
                </template>
            </template>

            <template v-slot:property="data">
                <template v-if="data.item.property.name">
                    {{data.item.property.name}}
                </template>
            </template>

            <template v-slot:label="data">
                <template v-if="data.item.label">
                    {{data.item.label}}
                </template>
            </template>

            <template v-slot:edit="data">
                <app-button
                    variant="link"
                    button_type="edit"
                    :show_text="false"
                    @click="editUnit(data.item)">
                </app-button>
            </template>
        </app-table-v2>

        <app-aside v-model="asideCancellationPanelState">
            <template v-if="object_id !== null"
                      slot="header">
                {{$t("EDIT_POLICY_SIDEBAR_TITLE")}}
            </template>
            <template v-else
                      slot="header">
                {{$t("NEW_POLICY_SIDEBAR_TITLE")}}
            </template>
            <cancellation-policy :edit_id="object_id"
                                 :property_id="property_id"
                                 :system_policy="system_policy"
                                 @savePolicy="savePolicy"
                                 @deletePolicy="deletePolicy">
            </cancellation-policy>
        </app-aside>

        <app-aside v-model="asidePeriodPanelState" v-if="checkPermission(C_UNIT_CALENDAR_PERIODS_V)">
            <template slot="header">
                {{$t("EDIT_SALES_PERIOD_SIDEBAR_TITLE")}}
            </template>
            <period-list :unit_id="object_id" @update="removeRow(object_id)"></period-list>
        </app-aside>
        <app-aside v-model="asidePanelState">
            <b-tabs nav-class="tab_navigation-horizontal" content-class="tab_content-horizontal" pills
                    v-model="showRangesSetupTab" justified>
                <b-tab v-if="checkPermission(C_UNIT_CALENDAR_LOS_V) && this.filter.validation_list===2"
                       :title="$t('LOS')">
                    <unit-los-list @update="removeRow(object_id)" :los-list="los_list" :unit="unit"></unit-los-list>
                </b-tab>
                <b-tab v-if="checkPermission(C_UNIT_CALENDAR_OCCUPANCY_V) && this.filter.validation_list===2"
                       :title="$t('OCCUPANCY')">
                    <unit-occupancy-list @update="removeRow(object_id)" :occupancy-list="occupancy_list"
                                         :unit="unit"></unit-occupancy-list>
                </b-tab>
            </b-tabs>
        </app-aside>
    </div>
</template>

<script>
    import AppTableV2 from "@/components/app/AppTableV2";
    import AppAside from '@/components/app/form/AppAside'
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {getValidations} from "@/services/health_monitor";
    import {EventBus, GE_LOADER_HIDE, GE_LOADER_SHOW} from "@/shared/EventBus";
    import {cloneDeep} from "lodash";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {
        C_UNIT_CALENDAR_PERIODS_V,
        C_UNIT_CALENDAR_LOS_V,
        C_UNIT_CALENDAR_OCCUPANCY_V,
    } from "@/shared/component_permission";
    import PeriodList from "@/components/unit/bulk_calendar/period/PeriodList";
    import UnitLosList from "@/components/unit/calendar/forms/los/UnitLosList";
    import UnitOccupancyList from "@/components/unit/calendar/forms/occupancy/UnitOccupancyList";
    import CancellationPolicy from "@/components/property/cancelation_policies/policy/CancelationPolicyItemEdit";
    import {deletePropertyCancelationPolicy} from "@/services/property";
    import {toast} from "@/shared/plugins/toastr";
    import {getPricingLosList, getPricingOccupancyList} from "@/services/pricing";


    export default {
        name: "HealthMonitorTable",
        components: {UnitOccupancyList, UnitLosList, PeriodList, AppButton, AppTableV2, AppAside, CancellationPolicy},
        mixins: [getErrorMessage],
        props: {
            defaultLimit: {
                type: Number,
                default: 20
            },
            showLimit: {
                type: Boolean,
                default: true
            },
            searchEventName: {
                type: String,
                default: 'getHealthReports'
            }
        },
        data() {
            return {
                tableFields: [
                    {key: 'id', label: this.$t('ID')},
                    {key: 'unit', label: this.$t('UNIT')},
                    {key: 'label', label: this.$t('EDIT_POLICY_SIDEBAR_TITLE')},
                    {key: 'property', label: this.$t('PROPERTY')},
                    {key: 'edit', label: '', tdClass: 'text-right'},

                ],
                responseData: [],
                omittedFields: [],
                listPath: 'validation_list',
                asidePanelState: false,
                asideCancellationPanelState: false,
                asidePeriodPanelState: false,
                fetchData: false,
                unit: null,
                object_id: null,
                property_id: null,
                system_policy: false,
                filter: {
                    validation_list: []
                },
                los_list: null,
                occupancy_list: null,
                validation: 1,
                C_UNIT_CALENDAR_PERIODS_V,
                C_UNIT_CALENDAR_LOS_V,
                C_UNIT_CALENDAR_OCCUPANCY_V,
                showRangesSetupTab: 0,
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            computedFields() {
                return this.tableFields.filter(el => !this.omittedFields.includes(el.key))
            },
        },
        methods: {
            removeRow(object_id) {
                this.responseData.then(response => {
                    let dataObject = response
                    if (this.filter.validation_list === 1 || this.filter.validation_list === 2) {

                        let req = {
                            ...this.filter,
                            property: this.property_id,
                            order_by: 'id',
                            order_by_direction: 'desc',
                        }

                        getValidations(req).then(response => {
                            if (response.data.length === 0) {

                                let filter_validations_list = dataObject.data[0].validation_list.filter(function (obj) {
                                    return obj.id !== object_id;
                                });

                                dataObject.data[0].validation_list = filter_validations_list
                                dataObject = Promise.resolve(dataObject)
                                this.responseData = dataObject

                                if (filter_validations_list.length === 0) {
                                    this.getData(this.filter)
                                }
                                EventBus.$emit(this.searchEventName)

                                this.asidePanelState = false
                                this.asideCancellationPanelState = false
                                this.asidePeriodPanelState = false
                            } else {
                                if (this.filter.validation_list === 2) {
                                    getPricingLosList({unit: object_id}).then(responseLos => {
                                        this.los_list = responseLos.data

                                        getPricingOccupancyList({unit: object_id}).then(responseOcc => {
                                            this.occupancy_list = responseOcc.data

                                            let index = dataObject.data[0].validation_list.findIndex(element => element.id === object_id)
                                            dataObject.data[0].validation_list[index].los_list = this.los_list
                                            dataObject.data[0].validation_list[index].occupancy_list = this.occupancy_list

                                            dataObject = Promise.resolve(dataObject)
                                            this.responseData = dataObject

                                            EventBus.$emit(this.searchEventName)
                                        })
                                    })
                                }
                            }
                        })
                    } else {
                        this.responseData.then(response => {
                            //let dataObject = response
                            let filter_validations_list = dataObject.data[0].validation_list.filter(function (obj) {
                                return obj.id !== object_id;
                            });


                            dataObject.data[0].validation_list = filter_validations_list
                            dataObject = Promise.resolve(dataObject)
                            this.responseData = dataObject

                            if (filter_validations_list.length === 0) {
                                this.getData(this.filter)
                            }
                            EventBus.$emit(this.searchEventName)

                            this.asidePanelState = false
                            this.asideCancellationPanelState = false
                            this.asidePeriodPanelState = false
                        })
                    }
                })
            },
            calculatedData() {
                return this.responseData
            },
            getData(filter) {
                let req = {
                    limit: 50,
                    ...this.filter,
                    company: this.company.id,
                    order_by: 'id',
                    order_by_direction: 'desc',
                }

                this.responseData = getValidations(req)
                return this.responseData
            },
            toggleBusy($event) {
                EventBus.$emit('loadingHealthReports', $event)
            },
            editUnit(data) {
                this.object_id = data.id  // ovo je opceniti id za sve
                if (this.filter.validation_list === 3) {
                    this.system_policy = false
                    this.property_id = data.property.id
                    this.asideCancellationPanelState = true
                }

                if (this.filter.validation_list === 4) {
                    this.system_policy = true
                    this.object_id = data.id
                    this.asideCancellationPanelState = true
                }

                if (this.filter.validation_list === 2) {
                    this.$store.dispatch('actionSetUnit', data.id).then((response) => {
                        this.unit = response.data
                        this.property_id=data.property.id
                        this.los_list = data.los_list
                        this.occupancy_list = data.occupancy_list
                        this.asidePanelState = true
                    })
                }
                if (this.filter.validation_list === 1) {
                    this.property_id=data.property.id
                    this.$store.dispatch('pricing/actionSetSalePeriodList', {unit: data.id})
                    this.asidePeriodPanelState = true
                }

            },
            savePolicy() {
                this.removeRow(this.object_id)
            },
            deletePolicy(id) {
                deletePropertyCancelationPolicy(this.object_id, id).then(response => {
                    toast({
                        'title': "Policy Deleted",
                        'message': "",
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.removeRow(this.object_id)
                }, error => {
                    this.showErrorMessages(error)
                })
            }
        },
        mounted() {
            EventBus.$on('searchHealthReports', filter => {
                this.omittedFields = [];
                if (filter) {
                    this.filter = cloneDeep(filter)
                    this.validation = (filter.validation_list) ? filter.validation_list : this.validation
                    if (filter.validation_list === 3) {
                        this.omittedFields.push('unit')
                    } else if (filter.validation_list === 4) {
                        this.omittedFields.push('unit')
                        this.omittedFields.push('property')
                    } else {
                        this.omittedFields.push('label')
                    }

                    this.getData(filter)
                }
                EventBus.$emit(this.searchEventName)
            })


        },
        beforeDestroy() {
            EventBus.$off('searchHealthReports')
        }
    }
</script>

<style scoped>

</style>
