import moment from 'moment'
import {has, get, cloneDeep, size} from "lodash";
import {fetchCalendarData} from '@/services/unit/index'

export const CalendarMixin = {
    data() {
        return {
            calendarActions: {
                availability: 'availability',
                rate: 'rate',
                restriction: 'restriction',
                standard_report: 'standard_report',
                on_the_fly_report: 'on_the_fly_report',
                reservation: 'reservation',
                additional_data: 'additional_data',
                rate_plan: 'rate_plan',
                restriction_min_stay:'restriction_min_stay',
                rate_default:'rate_default'
            },

            additional_data: {
                los: [],
                occupancy: [],
                rate_plan: [],
                contigent: [],
            },
            calendar_values: {
                reservation: [],
                availability: {},
                pricing: {},
                pricing_defaults: {},
                restriction: {},
                suggestion: {},
            },
            loaded:false
        }
    },
    computed: {
        nonPrimaryRatePlans() {
            return this.additional_data.rate_plan.filter(item => item.id !== this.unit.primary_rate_plan.id)
        },
        contigentUnits() {
            return this.additional_data.contigent.filter(item => item.id !== this.unit.id)
        },
        mainUnit() {
            return this.additional_data.contigent.find(item => item.id === this.unit.id)
        }
    },
    methods: {
        fetchData({date_from, date_to, params, reset}) {

            if (has(params, 'actions')) {
                if (params.actions.includes(this.calendarActions.rate)) {
                    this.rateListLoaded = true
                }

                if (params.actions.includes(this.calendarActions.restriction)) {
                    this.restrictionListLoaded = true
                }
                if (params.actions.includes(this.calendarActions.restriction_min_stay)) {
                    this.restrictionMinStayListLoaded = true
                }
                if (params.actions.includes(this.calendarActions.rate_default)) {
                    this.rateDefaultListLoaded = true
                }
            }

            fetchCalendarData(this.unit.id, {...{date_from: date_from, date_to: date_to}, ...params}).then(response => {

                if(params.hasOwnProperty('actions')){
                    if(params.actions.includes('reservation') && reset){
                        this.calendar_values.reservation = []
                    }
                }
                this.formatAdditionalData(response)
                this.formatFetchedData(response)
            }).finally(()=>{
                setTimeout(()=>{
                    this.loaded = true
                },200)

            })
        },

        formatAdditionalData(response) {
            Object.keys(this.additional_data).forEach(key => {
                if (has(response, 'data.additional_data.' + key) && get(response, 'data.additional_data.' + key).length) {
                    this.additional_data[key] = cloneDeep(get(response, 'data.additional_data.' + key))
                }
            })
        },
        formatFetchedData(response) {
            if (has(response, 'data.period_list') && get(response, 'data.period_list').length) {
                let list = {}
                response.data.period_list.forEach(period => {
                    this.formatReservations(period)
                    this.formatAvailability(period)
                    this.formatPricing(period)
                    this.formatPricingDefaults(period)
                    this.formatRestrictions(period)
                    this.formatOnTheFlySuggestions(period)
                    this.formatSuggestions(period)
                })
            }
        },
        formatAvailability(period) {
            if (has(period, 'data.availability') && get(period, 'data.availability') && size(get(period, 'data.availability'))) {
                const availabilityObject = period.data.availability
                const currentDate = moment(period.from)
                const stopDate = moment(period.to)
                const tempObject = {}
                const notelist = availabilityObject.hasOwnProperty('note') ? availabilityObject.note : []
                const openForSale = availabilityObject.hasOwnProperty('open_for_sale') ? availabilityObject.open_for_sale : null
                while (currentDate <= stopDate) {
                    tempObject[currentDate.format('YYYY-MM-DD')] = {available_unit: availabilityObject.available_unit, note:notelist, open_for_sale: openForSale}
                    currentDate.add(1, 'days');
                }
                this.calendar_values.availability = {...this.calendar_values.availability, ...tempObject}
            }
        },
        formatReservations(period) {
            if (has(period, 'data.reservation') && get(period, 'data.reservation') && size(get(period, 'data.reservation'))) {
                let list = {}
                this.calendar_values.reservation.forEach(res => {
                    list[res.id] = res
                })

                let object = get(period, 'data.reservation')
                object.forEach(res1 => {
                    list[res1.id] = res1
                })

                this.calendar_values.reservation = []
                for (const property in list) {
                    this.calendar_values.reservation.push(list[property])
                }

            }
        },
        formatPricing(period) {
            if (has(period, 'data.pricing') && get(period, 'data.pricing') && size(get(period, 'data.pricing'))) {
                let object = get(period, 'data.pricing')

                let pricingItemList = {}
                object.forEach(pricingItem => {
                    Object.keys(pricingItem.amount).forEach(rPlanId => {
                        pricingItemList[`${rPlanId}-${pricingItem.los}-${pricingItem.occupancy}`] = pricingItem.amount[rPlanId]
                    })
                })
                let currentDate = moment(period.from)
                const stopDate = moment(period.to)
                while (currentDate <= stopDate) {
                    let formattedDate = currentDate.format("YYYY-MM-DD")

                    this.$set(this.calendar_values.pricing, formattedDate, {
                        ...this.calendar_values.pricing[formattedDate] ? this.calendar_values.pricing[formattedDate] : {},
                        ...pricingItemList
                    })
                    currentDate = moment(currentDate).add(1, 'days');
                }
            }
        },
        formatPricingDefaults(period) {
            if (has(period, 'data.pricing_defaults') && get(period, 'data.pricing_defaults') && size(get(period, 'data.pricing_defaults'))) {
                let object = get(period, 'data.pricing_defaults')

                let pricingDefaultsList = {}
                object.forEach(pricingItem => {
                    this.$set(pricingDefaultsList, `${pricingItem.los}-${pricingItem.occupancy}`, pricingItem.amount)
                })
                let currentDate = moment(period.from)
                const stopDate = moment(period.to)
                while (currentDate <= stopDate) {
                    let formattedDate = currentDate.format("YYYY-MM-DD")
                    this.$set(this.calendar_values.pricing_defaults, formattedDate, {
                        ...(this.calendar_values.pricing_defaults[formattedDate] ? this.calendar_values.pricing_defaults[formattedDate] : {}),
                        ...pricingDefaultsList
                    })
                    currentDate = moment(currentDate).add(1, 'days');
                }
            }
        },
        formatRestrictions(period) {


            if (has(period, 'data.restriction') && get(period, 'data.restriction') && size(get(period, 'data.restriction'))) {
                let object = get(period, 'data.restriction')

                let restrictionItemList = {}
                object.forEach(restItem => {
                    Object.keys(restItem.value).forEach(rPlanId => {
                        restrictionItemList[`${rPlanId}-${restItem.id}`] = restItem.value[rPlanId]
                    })
                })
                let currentDate = moment(period.from)
                const stopDate = moment(period.to)
                while (currentDate <= stopDate) {
                    let formattedDate = currentDate.format("YYYY-MM-DD")

                    this.$set(this.calendar_values.restriction, formattedDate, {
                        ...this.calendar_values.restriction[formattedDate] ? this.calendar_values.restriction[formattedDate] : {},
                        ...restrictionItemList
                    })
                    currentDate = moment(currentDate).add(1, 'days');
                }
            }
        },
        formatSuggestions(period) {
            if (has(period, 'data.suggestion') && get(period, 'data.suggestion') && size(get(period, 'data.suggestion'))) {
                let list = {}
                let object = {edit: true}
                get(period, 'data.suggestion').forEach(el => {
                    object['type_' + el.type] = el.recommended
                    object['id_' + el.type] = el.id
                    object.reportId = el.report_id
                })
                let currentDate = moment(period.from)
                const stopDate = moment(period.to)
                while (currentDate <= stopDate) {
                    list[currentDate.format("YYYY-MM-DD")] = object
                    currentDate = moment(currentDate).add(1, 'days');
                }

                this.calendar_values.suggestion = {...this.calendar_values.suggestion, ...list}
            }
        },
        formatOnTheFlySuggestions(period) {
            if (has(period, 'data.on_the_fly_suggestion.suggestion') && get(period, 'data.on_the_fly_suggestion.suggestion') && size(get(period, 'data.on_the_fly_suggestion.suggestion'))) {
                let list = {}
                let object = {edit: false}
                if (has(period, 'data.on_the_fly_suggestion.data')) {
                    object['statistics'] = get(period, 'data.on_the_fly_suggestion.data')
                }
                get(period, 'data.on_the_fly_suggestion.suggestion').forEach(el => {
                    object['type_' + el.type] = el.recommended
                    object['id_' + el.type] = null

                    object.reportId = null
                })
                let currentDate = moment(period.from)
                const stopDate = moment(period.to)
                while (currentDate <= stopDate) {
                    list[currentDate.format("YYYY-MM-DD")] = object
                    currentDate = moment(currentDate).add(1, 'days');
                }

                this.calendar_values.suggestion = {...this.calendar_values.suggestion, ...list}
            }
        },
    }

}
