<template>
    <b-form @submit.prevent="save" class="guest_backoffice_setup" v-if="checkPermission(C_SETUP_GUEST_BACKOFFICE_V)">
        <div v-for="category in categories" v-if="items && items.length">
            <form-header>{{ category.label }}</form-header>
            <b-row>
                <b-col xxl="2" xl="3" lg="4" md="6" class="mb-4" v-for="item in items"
                       v-if="item.type === 'bool' && checkIfItemIsVisible(item) && checkIfItemBelongsToCategory(category.id, item.id)">
                    <label>{{ item.label }}</label>
                    <app-select
                        :options="yesNoOptions"
                        v-model="item.value"
                        @input="setAdditionalItemData(item)"/>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col xxl="4" xl="6" lg="8" md="12" class="mb-4" v-for="item in items"
                       v-if="item.type === 'text' && checkIfItemIsVisible(item) && checkIfItemBelongsToCategory(category.id, item.id)">
                    <b-row class="align-items-end justify-content-between ml-0">
                        <label class="mr-xl-5">{{ item.label }}</label>

                        <div class="d-flex align-items-end justify-content-end">
                            <label class="ml-5">{{$t('DISABLED')}}</label>
                            <b-form-checkbox
                                v-model="disableTextArea[item.id]"
                                :checked-value="true" :unchecked-value="false">
                            </b-form-checkbox>
                        </div>
                    </b-row>
                    <b-form-group>
                        <app-input-control>
                            <template v-slot:input="data">
                                <app-translation-input
                                    component="rich-text"
                                    v-model="item.value"
                                    :state="data.state"
                                    :rich-text-options="wysiwygEditorOptions"
                                    :max-height="true"
                                    ref="contentEditor"
                                    @focus="cursorField='content'"
                                    @blur="cursorField='content'"
                                    :disabled="disableTextArea.hasOwnProperty(item.id) ? disableTextArea[item.id] : false">
                                </app-translation-input>
                            </template>
                        </app-input-control>
                    </b-form-group>
                </b-col>
            </b-row>
            <template v-for="item in items">
                <link-list
                    :ref="'link_list_' + item.id"
                    v-if="item.type === 'custom' && checkIfItemIsVisible(item) && checkIfItemBelongsToCategory(category.id, item.id)"
                    :label="item.label" :link-list="item.value && Array.isArray(item.value) ? item.value : []"
                    :social-link-list="item.allowed_values"
                    :add-new-item="true"
                    :disabled="item.value === null"
                    @update="setItem($event, item)"
                ></link-list>
            </template>
        </div>
        <b-row v-if="items && items.length">
            <b-col>
                <app-button-submit
                    v-if="checkPermission(C_SETUP_GUEST_BACKOFFICE_E)"
                    :inline="true" :loading="saving">
                    {{ $t("SAVE") }}
                </app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import {getGuestBackofficeSetup, setGuestBackofficeSetup} from "@/services/guest_backoffice";
import {C_SETUP_GUEST_BACKOFFICE_V, C_SETUP_GUEST_BACKOFFICE_E} from "@/shared/component_permission";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {notifySuccess} from "@/shared/plugins/toastr";
import {listHelpers} from "@/mixins/shared/helpers";


const SHOW_TRANSFERS = 148;
const SHOW_ACTIVITIES = 149;
const SHOW_ARRIVAL_TIME = 150;
const SHOW_CHECK_IN_INFORMATION = 151;
const SHOW_FAQ = 152;
const SHOW_FOOTER = 153;
const SHOW_FOOTER_FRANCHISE = 154;
const COOKIES_CONTENT = 155;
const FOOTER_CONTENT = 156;
const FOOTER_SOCIAL_LINKS = 157;
const FOOTER_LINKS = 158;
const SHOW_FOOTER_LOGO = 161;
const SHOW_FOOTER_COMPANY_DATA = 162;
const wysiwygEditorOptions = {};

const paramConstants = {
    SHOW_TRANSFERS, SHOW_ACTIVITIES, SHOW_ARRIVAL_TIME,
    SHOW_CHECK_IN_INFORMATION, SHOW_FAQ, SHOW_FOOTER,
    SHOW_FOOTER_FRANCHISE, COOKIES_CONTENT, FOOTER_CONTENT,
    FOOTER_SOCIAL_LINKS, FOOTER_LINKS, SHOW_FOOTER_LOGO,
    SHOW_FOOTER_COMPANY_DATA, wysiwygEditorOptions
}

export default {
    name: "GuestBackofficeForm",
    mixins: [getErrorMessage, listHelpers],
    components: {
        "LinkList": () => import("@/components/app/links/LinkList"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "FormHeader": () => import("@/components/app/form/FormHeader"),
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "draggable": () => import("vuedraggable"),
        "AppTranslationInput": () => import("@/components/app/translation/AppTranslationInput"),
        "AppWysiwygEditor": () => import("@/components/app/AppWysiwygEditor"),
        "AppInputControl": () => import("@/components/app/AppInputControl"),
    },
    props: {
        entity_type: {
            type: String,
            default: 'company',
            validator: value => {
                return (["system", "company", "property", "unit"]).includes(value)
            },
        },
    },
    data() {
        return {
            ...paramConstants,
            C_SETUP_GUEST_BACKOFFICE_E,
            C_SETUP_GUEST_BACKOFFICE_V,
            items: [],
            categories: [
                {
                    id: 1,
                    label: this.$t('ADDITIONAL_INFO'),
                    items: [SHOW_ARRIVAL_TIME, SHOW_CHECK_IN_INFORMATION]
                },
                {
                    id: 2,
                    label: this.$t('AMENITIES'),
                    items: [SHOW_TRANSFERS, SHOW_ACTIVITIES, SHOW_FAQ]
                },
                {
                    id: 3,
                    label: this.$t('COOKIES'),
                    items: [COOKIES_CONTENT]
                },
                {
                    id: 4,
                    label: this.$t('FOOTER'),
                    items: [SHOW_FOOTER, SHOW_FOOTER_FRANCHISE, SHOW_FOOTER_LOGO, FOOTER_CONTENT, FOOTER_SOCIAL_LINKS, FOOTER_LINKS, SHOW_FOOTER_COMPANY_DATA]
                },
                {
                    id: 5,
                    label: this.$t('GENERAL')
                },
            ],
            hide_items: [
                {
                    id: SHOW_FOOTER,
                    items: [SHOW_FOOTER_FRANCHISE, SHOW_FOOTER_LOGO, FOOTER_CONTENT, FOOTER_SOCIAL_LINKS, FOOTER_LINKS, SHOW_FOOTER_COMPANY_DATA]
                }
            ],
            saving: false,
            disableTextArea: {},
            wysiwygEditorOptions,
            cursorField: 'subject',
        }
    },
    computed: {
        computedItems() {
            return this.items
        },
        entityId() {
            let entity_id = null
            if (this.entity_type !== 'system') {
                if (this.entity_type === 'company') {
                    entity_id = this.company.id
                } else if (this.entity_type === 'property') {
                    entity_id = this.$route.params.id
                } else if (this.entity_type === 'unit') {
                    entity_id = this.$route.params.unit_id
                }
            }
            return entity_id
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods: {
        getData() {
            getGuestBackofficeSetup({
                entity_type: this.entity_type,
                entity_id: this.entityId
            }).then(response => {
                this.items = (response.data);
                this.items.forEach((item) => {
                    this.setAdditionalItemData(item)
                })
            })
        },
        setAdditionalItemData(item) {
            if (item.type === 'bool') {
                let hideItem = this.hide_items.find((hide_item) => item.id === hide_item.id)
                if (hideItem && hideItem.items && hideItem.items.length) {
                    if (item.value === 0) {
                        hideItem.items.forEach(hide_item_id => {
                            let itemIndex = this.items.findIndex((find_item) => hide_item_id === find_item.id)
                            if (itemIndex > -1) this.items[itemIndex].visible = 0
                        })
                    } else if (item.value === 1 || item.value === null) {
                        hideItem.items.forEach(hide_item_id => {
                            let itemIndex = this.items.findIndex((find_item) => hide_item_id === find_item.id)
                            if (itemIndex > -1) this.items[itemIndex].visible = 1
                        })
                    }
                }
            }
            if (item.value === null && (item.type === 'text' || item.type === 'custom')) {
                this.$set(this.disableTextArea, item.id, true)
            }
        },
        checkIfItemIsVisible(item) {
            if (item.hasOwnProperty('visible')) {
                return item.visible
            }
            return true
        },
        checkIfItemBelongsToCategory(categoryId, itemId) {
            const lastCategory = this.categories[this.categories.length - 1].id
            const categoryIndex = this.categories.findIndex((category) => category.id === categoryId)
            const category = categoryIndex > -1 ? this.categories[categoryIndex] : null
            if (categoryId !== lastCategory) {
                return category.items.findIndex((item) => itemId === item) > -1
            }
            let returnCheck = false
            this.categories.forEach((category) => {
                if (category.id !== lastCategory) {
                    category.items.forEach((item) => {
                        if (itemId === item) {
                            returnCheck = true
                        }
                    })
                }
            })
            return !returnCheck
        },
        save() {
            this.saving = true
            let requestItems = []
            this.items.forEach((item) => {
                if (this.checkIfItemIsVisible(item)) {
                    if (this.disableTextArea.hasOwnProperty(item.id) && this.disableTextArea[item.id]) {
                        item.value = null
                    }
                    let linkListItemReference = 'link_list_' + item.id
                    if (
                        //accessing this.$refs[linkListItemReference][0] because ref is set inside v-for
                        this.$refs[linkListItemReference] &&
                        this.$refs[linkListItemReference][0] &&
                        this.$refs[linkListItemReference][0].$data
                    ) {
                        if (
                            this.$refs[linkListItemReference][0].$data.hasOwnProperty('disableNewItem') &&
                            this.$refs[linkListItemReference][0].$data.disableNewItem
                        ) {
                            item.value = null
                        } else if (
                            this.$refs[linkListItemReference][0].$data.hasOwnProperty('linkList_') &&
                            this.$refs[linkListItemReference][0].$data.linkList_
                        ) {
                            item.value = this.$refs[linkListItemReference][0].$data.linkList_
                        }
                    }
                    requestItems.push(item)
                }
            })
            setGuestBackofficeSetup({
                entity_type: this.entity_type,
                entity_id: this.entityId,
                items: requestItems
            }).then(response => {
                this.$emit('update', response.data)
                notifySuccess()
                this.items = response.data
                this.items.forEach((item) => {
                    this.setAdditionalItemData(item)
                })
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => this.saving = false)
        },
        setItem(itemValue, item) {
            const index = this.items.findIndex((element) => element.id === item.id)
            this.$set(this.items[index], 'value', itemValue)
        }
    },

    created() {
        this.getData()
    }
}
</script>

<style>
.guest_backoffice_setup .ql-disabled {
    background-color: #e4e7ea;
    opacity: 0.7;
}
</style>
