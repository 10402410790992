<template>

    <b-input-group :prepend="currency">
        <b-form-input    @keypress="check"  class="text-right" :tabIndex="los.default === 1 && occupancy.default === 1 ? los.id+'_'+occupancy.id+'_'+indexTab : null" type="number" v-model="priceValue" @input="calculatePrice"></b-form-input>
    </b-input-group>

</template>

<script>
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    export default {
        name: "input-price-element",
        components: {AppNumberInput},
        props: {
            statistic:{
              type:Object
            },
            occupancy: {
                type: Object
            },
            los: {
                type: Object
            },
            period:{
                type:Object
            },
            indexTab:{
              type:Number
            },
            currency: {
                type: String,
                default: 'EUR'
            },
            value: {
                type: String
            }
        },
        computed:{

            price() {
                return this.$store.getters['pricing/getPriceById'](this.period.id + '_' + this.los.id + '_' + this.occupancy.id)
            },
            priceValue: {

                get() {
                    if (typeof this.price !== 'undefined') {
                        return this.price.value
                    } else {
                        return 0;
                    }
                },
                set(value) {
                    const price_id =this.period.id + '_' + this.los.id + '_' + this.occupancy.id
                    if (price_id) {
                        this.$store.commit('pricing/setPriceValue', {id: price_id, value})
                    }
                }
            },
        },
        methods: {
            check(event) {
                if (!/\d/.test(event.key) && event.key !== '.'){
                    return event.preventDefault()
                }
            },
            calculatePrice(value) {
                if(!value){
                    return
                }
                if (this.los.default && this.occupancy.default) {
                    const price_id =this.period.id + '_' + this.los.id + '_' + this.occupancy.id
                    this.$store.dispatch('pricing/actionSetCalculatePrice', {
                        value: value,
                        defaultPriceId: price_id,
                        periodId: this.price.period_id
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>

