<template>
    <b-card v-if="propertyList.length > 0" body-class="d-flex">
        <div class="d-flex flex-grow-1 flex-column">
            <span class="header-2 mb-5">{{$t("PROPERTY_DOCUMENT")}}</span>
            <template v-for="(property) in propertyList">
                <b-row :key="property.id">
                    <b-col xs="10" class="mt-1">
                        {{property.name}}
                    </b-col>
                    <b-col xs="2">
                        <b-button size="default" v-if="property.document_list.length > 0" :key="index" v-for="(pdf, index) in property.document_list"
                                  class="app-button-btn mr-3 btn-pdf action_button pull-right"
                                  :href="pdf.route + '?version=' + pdf.id"
                                  target="_blank"
                                  variant="link"
                        >
                            <i class="fa fa-file-pdf-o fa-fw mr-1 btn-pdf-i"
                               aria-hidden="true">
                            </i>
                            <template v-if="property.document_list.length > 1">
                                {{pdf.description}}
                            </template>
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </div>
    </b-card>
</template>

<script>
    import {getPropertyDocument} from "@/services/dashboard";

    export default {
        name: "PropertyPdfList",
        data() {
            return {
                propertyList: [],
            }
        },
        created() {
            getPropertyDocument().then((response) => {
                this.propertyList = response.data;
            })
        },
    }
</script>

<style scoped>

</style>
