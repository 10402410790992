<template>
    <b-row>

        <b-col cols="12">
            <b-table responsive hover :items="list" :fields="fields">

                <template v-slot:is_registered="data">
                    <span v-if="data.item.is_registered">
                        {{$t("NOTIFICATIONS.SAVE_SUCCESS.TITLE")}}
                    </span>
                    <span v-else>
                        {{$t("NOTIFICATIONS.DELETE_ERROR.TITLE")}}
                    </span>
                </template>
                <template v-slot:year="data">
                    {{data.item.month}} / {{data.item.year}}
                </template>
                <template v-slot:timestamp="data">
                    <small class="text-muted d-block">
                        {{data.item.ts_insert | time}}
                    </small>
                    {{data.item.ts_insert | date}}
                </template>
                <template v-slot:edit="data">

                    <app-button v-if="data.item.pdf" :href="data.item.pdf" target="_blank" variant="link"
                                button_type="pdf"
                                :show_text="false"></app-button>
                </template>
            </b-table>

            <app-pagination :listCount="total" :per-page="limit" v-model="currentPage" :last-page="lastPage" @input="getData"></app-pagination>
            <app-aside v-model="aside">
                <template slot="header">{{$t("GUEST_MONTHLY_REPORT")}}</template>
                <GuestMonthlyReportForm :property_id="Number($route.params.id)"
                                       @save="getData(1)"
                                        @load="getData(1)"></GuestMonthlyReportForm>
            </app-aside>
        </b-col>
    </b-row>
</template>

<script>
    import {getMonthlyReport} from '@/services/guest/index'
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppAside from "@/components/app/form/AppAside";
    import GuestMonthlyReportForm from "@/components/guest/monthly_report/GuestMonthlyReportForm";
    import {cloneDeep} from 'lodash'
    import {EventBus, GE_OPEN_AJPES_MONTHLY_REPORT_ASIDE} from "@/shared/EventBus";
    import AppPagination from "@/components/app/filter/AppPagination";

    export default {
        name: "guest-monthly-report-table",
        components: {AppPagination, GuestMonthlyReportForm, AppButton, AppAside},
        data() {
            return {
                currentPage: 1,
                lastPage: 1,
                total: 0,
                limit: 10,
                aside: false,
                form_data: null,
                list: [],
                fields: [
                    {key: 'id', label: this.$t('ID')},
                    {key: 'year', label: this.$t('MONTH') + ' / ' + this.$t('YEAR')},
                    {key: 'status.label', label: this.$t('STATUS')},
                    {key: 'beds_additional', label: this.$t('NUM_OF_AUX_BEDS')},
                    {key: 'units_sold', label: this.$t('UNITS_SOLD')},
                    {key: 'days_open_in_month', label: this.$t('NUM_OPEN_DAYS')},
                    {key: 'is_registered', label: this.$t('REGISTRATION_STATUS')},
                    {key: 'timestamp', label: this.$t('CREATED_AT'), tdClass:'p-0'},
                    {key: 'edit', label: "", class: "text-right"},
                ]
            }
        },
        methods: {
            getData(page = null) {
                this.aside = false
                if(page != null){
                    this.currentPage = page
                }
                getMonthlyReport(this.$route.params.id, {
                    page: page ? page : this.currentPage,
                    limit: this.limit
                }).then(res => {
                    this.total = res.data.total
                    this.list = res.data.items
                    this.lastPage = res.data.last_page
                }, () => {
                    this.list = []
                })
            },
        },
        watch: {
            '$route.params.id': {
                handler() {
                    this.getData()
                },
                immediate: true
            }
        },
        created() {
            EventBus.$on(GE_OPEN_AJPES_MONTHLY_REPORT_ASIDE, () => {
                this.aside = true
            })
        }
    }
</script>

<style scoped>

</style>
