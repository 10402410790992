<template>
    <form>
        <b-row>
            <b-col md="12" lg="6" class="mb-4">
                <label>{{ $t('NAME') }}</label>
                <app-input-control :error-object="validationObject.name">
                    <template v-slot:input="data">
                        <b-input v-model="formData.name" :state="data.state"></b-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="12" lg="6" class="mb-4">
                <label>{{ $t('SLUG') }}</label>
                <app-input-control :error-object="validationObject.slug">
                    <template v-slot:input="data">
                        <b-input-group>
                            <template #append>
                                <b-input-group-text>{{ DB_DOMAIN }}</b-input-group-text>
                            </template>
                            <b-form-input v-model="formData.slug" :state="data.state"></b-form-input>

                        </b-input-group>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="3" class="mb-4">
                <label>{{ setupData[SUPPORTED_LANGUAGES].label }}</label>
                <app-input-control :error-object="validationObject[SUPPORTED_LANGUAGES]">
                    <template v-slot:input="data">
                        <app-select
                            mode="multiselect" :state="data.state"
                            v-model=formData[SUPPORTED_LANGUAGES] @input="onSupportedLangInput"
                            :options="allowedLanguageList">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="3" class="mb-4">
                <label>{{ setupData[DEFAULT_LANGUAGE].label }}</label>
                <app-input-control :error-object="validationObject[DEFAULT_LANGUAGE]">
                    <template v-slot:input="data">
                        <app-select
                            :disabled="!supportedLanguageList.length" :state="data.state"
                            v-model="formData[DEFAULT_LANGUAGE]" set-first
                            :options="supportedLanguageList"
                            :search-empty-item="false">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="3" class="mb-4">
                <label>{{ setupData[CURRENCY].label }}</label>
                <app-input-control :error-object="validationObject[CURRENCY]">
                    <template v-slot:input="data">
                        <app-select
                            v-model="formData[CURRENCY].id" text-field="currency_code" :state="data.state"
                            :options="setupData[CURRENCY].allowed_values" :search-empty-item="false" @input="emptyExchangeRate(formData[CURRENCY])">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="3" class="mb-4" v-if="company.working_currency.id !== formData[CURRENCY].id">
                <label>{{ $t('EXCHANGE_RATE') }}</label>
                <b-form-input type="number"
                              step="0.000001"
                              v-model="formData[CURRENCY].exchange_rate">
                </b-form-input>
            </b-col>

        </b-row>

        <b-row>
            <b-col md="6" lg="4" class="mb-4">
                <label>{{ setupData[LOCATION_TOP_LEVEL].label }}</label>
                <app-input-control :error-object="validationObject[LOCATION_TOP_LEVEL]">
                    <template v-slot:input="data">
                        <app-select
                            :state="data.state" text-field="label"
                            v-model="formData[LOCATION_TOP_LEVEL]" set-first
                            :options="setupData[LOCATION_TOP_LEVEL].allowed_values"
                            :search-empty-item="false">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>

        <b-row>
            <div class="d-flex align-items-end">
                <label class="mr-3 font-weight-bold">{{ setupData[INSTANT_BOOK_ENABLED].label }}</label>
                <b-form-checkbox
                    v-model="formData[INSTANT_BOOK_ENABLED]"
                    :value="1" :unchecked-value="0">
                </b-form-checkbox>
            </div>
        </b-row>
    </form>
</template>

<script>

import AppSelect from "@/components/app/AppSelect/AppSelect";
import FormHeader from "@/components/app/form/FormHeader";
import {
    WEBSITE_SUBDOMAIN_ALREADY_EXISTS,
    WEBSITE_NAME,
    WEBSITE_SUBDOMAIN,
    WEBSITE_SUPPORTED_LANGUAGES,
    WEBSITE_DEFAULT_LANGUAGE,
    WEBSITE_CURRENCY,
    WEBSITE_NAME_ALREADY_EXISTS,
    WEBSITE_LOCATION_TOP_LEVEL
} from "@/shared/error_codes"
import AppInputControl from "@/components/app/AppInputControl";
import {DB_DOMAIN} from "@/shared/constants";

const SUPPORTED_LANGUAGES = 37
const DEFAULT_LANGUAGE = 42
const CURRENCY = 58
const INSTANT_BOOK_ENABLED = 78
const LOCATION_TOP_LEVEL = 60

const paramConstants = {
    SUPPORTED_LANGUAGES,
    DEFAULT_LANGUAGE,
    CURRENCY,
    INSTANT_BOOK_ENABLED,
    LOCATION_TOP_LEVEL
}

export default {
    name: "WebsiteWizardGeneralForm",
    components: {AppInputControl, FormHeader, AppSelect},
    props: {
        setupData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            DB_DOMAIN,
            ...paramConstants,
            formData: {
                template: 1,
                name: null,
                slug: null,
                [SUPPORTED_LANGUAGES]: [],
                [DEFAULT_LANGUAGE]: null,
                [CURRENCY]: {id: null, exchange_rate: null},
                [INSTANT_BOOK_ENABLED]: 1,
                [LOCATION_TOP_LEVEL]: null,
            },
            validationObject: {
                name: {
                    input: [WEBSITE_NAME, WEBSITE_NAME_ALREADY_EXISTS],
                },
                slug: {
                    input: [WEBSITE_SUBDOMAIN, WEBSITE_SUBDOMAIN_ALREADY_EXISTS],
                },
                [SUPPORTED_LANGUAGES]: {
                    input: [WEBSITE_SUPPORTED_LANGUAGES]
                },
                [DEFAULT_LANGUAGE]: {
                    input: [WEBSITE_DEFAULT_LANGUAGE]
                },
                [CURRENCY]: {
                    input: [WEBSITE_CURRENCY]
                },
                [LOCATION_TOP_LEVEL]: {
                    input: [WEBSITE_LOCATION_TOP_LEVEL]
                },
            }
        }
    },
    computed: {
        allowedLanguageList() {
            return this.setupData[SUPPORTED_LANGUAGES].allowed_values.map(lang => ({
                id: lang, name: this.$t(`LANGUAGES.${lang}`)
            }))
        },
        supportedLanguageList() {
            return this.formData[SUPPORTED_LANGUAGES].map(e => ({id: e, name: this.$t(`LANGUAGES.${e}`)}))
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler(data) {
                this.$emit('update', {general: data})
            }
        }
    },
    methods: {
        onSupportedLangInput(input) {
            if (!input.length) this.formData[DEFAULT_LANGUAGE] = null
        },
        emptyExchangeRate(data){
            if (this.company.working_currency.id === data.id){
                data.exchange_rate = null
            }
        }
    },
    created() {
        if (this.setupData[CURRENCY] && this.setupData[CURRENCY].default_value) {
            this.formData[CURRENCY].id = this.setupData[CURRENCY].default_value
        }
        if (this.setupData[SUPPORTED_LANGUAGES] && this.setupData[SUPPORTED_LANGUAGES].default_value) {
            this.formData[SUPPORTED_LANGUAGES] = [this.setupData[SUPPORTED_LANGUAGES].default_value]
        }
    }
}
</script>

<style scoped>

</style>
