import {
    createUnitInventory,
    createUnitRoom, deleteUnitInventory,
    deleteUnitRoom,
    getUnitRoomAndInventory,
    updateUnitRoom,
    updateUnitInventory, getUnitRoomAndInventoryOptions
} from "@/services/unit";

const state = {
    list: [],
    amenity_options: null
}

const getters = {
    getUnitAmenity(state) {
        return state.list
    },
    getRoom: state => room_id => state.list.find(el => el.id === room_id),
    getAmenityOptions(state) {
        return state.amenity_options
    }
}

const mutations = {
    setUnitAmenity(state, payload) {
        state.list = payload
    },
    setRoom(state, payload) {

        const index = state.list.findIndex(block => block.id === payload.id)
        if (index > -1) {
            state.list.splice(index, 1, payload)
        } else {
            state.list.push(payload)
        }
    },
    setNewInventory(state, {room_id, payload}) {
        const roomIndex = state.list.findIndex(block => block.id === room_id)
        if (roomIndex > -1) {
            state.list[roomIndex]['inventory'].push(payload)
        }
    },
    setAmenityOptions(state, payload) {
        state.amenity_options = payload
    }
}

const actions = {
    actionSetUnitAmenity({commit}, payload) {
        return new Promise((resolve, reject) => {
            getUnitRoomAndInventory(payload).then(response => {

                commit('setUnitAmenity', response.data)
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    actionCreateUnitRoom({commit}, {unit_id, data}) {
        return new Promise((resolve, reject) => {
            createUnitRoom(unit_id, data).then(response => {
                commit('setRoom', response.data)
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    actionUpdateUnitRoom({commit}, {unit_id, room_id, data}) {
        return new Promise((resolve, reject) => {
            updateUnitRoom(unit_id, room_id, data).then(response => {
                commit('setRoom', response.data)
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    actionDeleteUnitRoom({commit}, {unit_id, room_id}) {
        return new Promise((resolve, reject) => {
            deleteUnitRoom(unit_id, room_id).then(response => {
                commit('setUnitAmenity', response.data)
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    actionCreateUnitRoomInventory({commit}, {unit_id, room_id, data}) {
        return new Promise((resolve, reject) => {
            createUnitInventory(unit_id, room_id, data).then(response => {
                commit('setNewInventory', {room_id: room_id, payload: response.data})
                //setNewInventory
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    actionUpdateUnitRoomInventory({commit}, {unit_id, room_id, inventory_id, data}) {
        return new Promise((resolve, reject) => {
            updateUnitInventory(unit_id, room_id, inventory_id, data).then(response => {
                commit('setRoom', response.data)
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    actionDeleteUnitRoomInventory({commit}, {unit_id, room_id, inventory_id}) {
        return new Promise((resolve, reject) => {
            deleteUnitInventory(unit_id, room_id, inventory_id).then(response => {
                commit('setRoom', response.data)
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    actionGetUnitAmenityOptions({commit, state}, unit_id) {
        //return []
        return new Promise((resolve, reject) => {
            if (state.amenity_options === null) {
                getUnitRoomAndInventoryOptions(unit_id).then(response => {
                    commit('setAmenityOptions', response.data)
                    resolve(response)
                }, error => {
                    reject(error)
                })
            } else {
                resolve(state.amenity_options)
            }
        })
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
