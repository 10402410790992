<template>
    <form @submit.prevent="search" class="filter" autocomplete="off">
        <b-row>
            <b-col md="4" lg="2" class="mb-3">
                <label>{{$t("GUEST_NAME")}}</label>
                <b-input v-model="filter.name"></b-input>
            </b-col>

            <b-col md="4" lg="2" class="mb-3">
                <label>{{$t("STATUS")}}</label>
                <app-select mode="multiselect" :options="statusList" text-field="name"
                            v-model="filter.status"></app-select>
            </b-col>

            <b-col md="2">
                <label>{{$t("_DATE")}}</label>
                <app-select :options="dateOptions" v-model="dateOptionValue"></app-select>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("FROM")}}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="fromDate" :max-date="toDate"></app-date-picker>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("TO")}}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="toDate" :min-date="fromDate" :from-date="fromDate"></app-date-picker>
            </b-col>

            <b-col md="3" class="mb-3">
                <app-submit class="filter-submit-wrapper" :inline="true" :disabled="busy" :loading="loaderSubmit" variant="primary"
                            button_type="search">{{ $t("SEARCH") }}
                </app-submit>
                <app-button-reset @click="resetForm" :disabled="busy" :loading="loaderReset" :inline="true"  class="pl-2 ml-2" ></app-button-reset>

            </b-col>
        </b-row>

    </form>
</template>

<script>
    import {EventBus} from "@/shared/EventBus";
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppDatePicker from "@/components/app/datetime/AppDatePicker";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";

    export default {
        name: "ActivityReservationFilter",
        components: {AppButtonReset, AppDatePicker, AppSelect, AppDateRangePicker, AppSubmit},
        props: {
            busy: {
                type: Boolean
            },
        },
        data() {
            return {
                filter: {
                    name: null,
                    status: []
                },
                dateOptions: [
                    {id: 'activity', name: this.$t('ACTIVITY')},
                    {id: 'insert', name: this.$t('BOOKED_AT')},
                ],
                dateOptionValue: null,
                fromDate: null,
                toDate: null,
                dateObject: {},
                loaderReset:false,
                loaderSubmit:false,
                reset:0
            }
        },
        computed: {
            statusList() {
                return [
                    {id: "CONFIRMED", name: this.$t("STATUS_CONFIRMED")},
                    {id: "CANCELED", name: this.$t("STATUS_CANCELED")}
                ]
            },
        },
        methods: {
            search() {
                this.reset = 0
                EventBus.$emit('filterReservationActivities', {...this.filter, ...this.dateObject})
            },
            resetForm() {
                this.reset = 1
                this.filter.name = null
                this.dateObject = {}
                this.dateOptionValue = null
                this.filter.status = []
                EventBus.$emit('filterReservationActivities', {...this.filter, ...this.dateObject})
            }
        },
        watch: {
            fromDate(value) {
                if (this.dateOptionValue) {
                    this.dateObject[this.dateOptionValue + '_from_date'] = value
                }
            },
            toDate(value) {
                if (this.dateOptionValue) {
                    this.dateObject[this.dateOptionValue + '_to_date'] = value
                }
            },
            dateOptionValue(value, oldValue) {
                if (oldValue !== undefined) {
                    this.fromDate = null
                    this.toDate = null
                    this.dateObject = {}
                }
            },
            busy:{
                handler(value){
                    if(this.reset){
                        this.loaderReset = value
                    } else {
                        this.loaderSubmit =value
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
