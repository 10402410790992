<template>
    <tr>
        <td>
            <app-select v-model="selected.condition_type"
                        value-field="id"
                        text-field="label"
                        :options="selectConditionType"
                        :search-empty-item="false"
                        @input="$emit('conditionChange')">
            </app-select>
        </td>
        <td>
            <app-select v-model="selected.day"
                        value-field="id"
                        text-field="label"
                        :options="selectDay"
                        :search-empty-item="false"
                        @input="$emit('conditionChange')">
            </app-select>
        </td>
        <td>
            <app-select v-model="selected.day_type"
                        value-field="id"
                        text-field="label"
                        :options="selectDayType"
                        :search-empty-item="false"
                        @input="$emit('conditionChange')">
            </app-select>

        </td>
        <td>
            <app-select v-model="selected.charge"
                        value-field="id"
                        text-field="label"
                        :options="selectCharge"
                        :search-empty-item="false"
                        @input="$emit('conditionChange')">
            </app-select>
        </td>
        <td class="text-right">
            <AppButton @click="deleteCondition"
                       button_type="delete"
                       :show_text="false"
                       variant="link">
            </AppButton>
        </td>
    </tr>
</template>

<script>
    import AppButton from '@/components/app/AppButton/AppButton'
    import AppSelect from "@/components/app/AppSelect/AppSelect";

    export default {
        name: "CancelationConditionItem",
        props: {
            policy: {
                type: Object,
                required: true
            },
            system_policy: {
                type: Boolean,
                default: false
            },
            condition: {
                type: Object,
                required: true
            },
            selectConditionType: {
                type: Array
            },
            selectDay: {
                type: Array
            },
            selectDayType: {
                type: Array
            },
            selectCharge: {
                type: Array
            }
        },
        components: {
            AppSelect,
            AppButton
        },
        data() {
            return {
                selected: {
                    id: null,
                    condition_type: null,
                    day: null,
                    day_type: null,
                    charge: null,
                    deleted: null
                }
            }
        },
        watch: {
            condition: {
                handler(value) {
                    this.selected.id = value.id
                    this.selected.condition_type = this.condition.condition_type
                    this.selected.day = this.condition.day
                    this.selected.day_type = this.condition.day_type
                    this.selected.charge = this.condition.charge
                    this.selected.deleted = this.condition.deleted
                },
                immediate: true,
                deep: true
            }
        },
        methods: {
            deleteCondition() {
                this.selected.deleted = 1
                this.$emit('deleteCondition', this.selected)
            }
        }
    }
</script>

<style scoped>

</style>
