<template>
    <app-contact-list
        :object="Number($route.params.id)"
        :object-type="OBJECT_TYPE_PROPERTY"
        :c-permission-v="C_PROPERTY_CONTACTS_V" :c-permission-e="C_PROPERTY_CONTACTS_E"></app-contact-list>
</template>

<script>
    import AppContactList from "@/components/app/contacts/AppContactList";
    import {OBJECT_TYPE_PROPERTY} from "@/shared/constants";
    import {C_PROPERTY_CONTACTS_V, C_PROPERTY_CONTACTS_E} from "@/shared/component_permission";

    export default {
        name: "Index",
        components: {AppContactList},
        data() {
            return {
                OBJECT_TYPE_PROPERTY,
                C_PROPERTY_CONTACTS_V,
                C_PROPERTY_CONTACTS_E
            }
        }
    }
</script>

<style scoped>

</style>
