<template>
    <div>
        <div class="d-flex justify-content-end mb-4">
            <app-button button_type="new" @click="openTemplateForm(null)">
                {{ isTemplate ? $t('NEW_COMMUNICATION_RULE_TEMPLATE') : $t('NEW_COMMUNICATION_RULE') }}
            </app-button>
        </div>

        <b-tabs v-model="currentTab" content-class="tab_content" pills vertical v-if="eventData.length">
            <b-tab :key="index" v-for="(event, index) in eventData" @click="eventId=event.id">
                <template #title>
                    {{ event.label }}
                </template>

                <template v-if="showPropertyTemplate">
                    <event-template-table
                        :loading="loading"
                        :items="event.data.property"
                        :paginate="false"
                        :search-on-create="true"
                        :omitted-fields="allOmittedFields"
                        :showSearchMessages="false"
                        @edit="openTemplateForm"
                        @preview="openPreviewForm"
                        @linkEvent="openLinkForm"
                        @unlinkEvent="openUnlinkModal">
                    </event-template-table>
                </template>

                <form-header v-if="showPropertyTemplate && event.data.company.length" header-class="mb-4 mt-4">
                    {{ $t('ORGANIZATION') }}
                </form-header>
                <event-template-table
                    v-if="event.data.company.length"
                    :loading="loading"
                    :items="event.data.company"
                    :paginate="false"
                    :search-on-create="true"
                    :omitted-fields="allOmittedFields"
                    :showSearchMessages="false"
                    @edit="openTemplateForm"
                    @preview="openPreviewForm"
                    @linkEvent="openLinkForm"
                    @unlinkEvent="openUnlinkModal">
                </event-template-table>

                <app-no-data v-if="!event.data.company.length && !event.data.property.length">
                </app-no-data>
            </b-tab>
        </b-tabs>

        <app-aside v-model="templateFormState">
            <template #header>
                <app-object-header
                    :name="selectedTemplate ? isTemplate ? $t('COMMUNICATION_RULE_TEMPLATE') : $t('COMMUNICATION_RULE'): isTemplate ? $t('NEW_COMMUNICATION_RULE_TEMPLATE') : $t('NEW_COMMUNICATION_RULE')">
                </app-object-header>
            </template>

            <event-template-form
                @saved="onSaved"
                :event-template-id="selectedTemplate && selectedTemplate.id"
                :event-template-type-id="eventTemplateTypeId"
                :event-id="eventId"
                :property-id="propertyId">
            </event-template-form>
        </app-aside>

        <app-aside v-model="templatePreviewState">
            <template slot="header">
                <app-object-header :name="$t('PREVIEW')"></app-object-header>
            </template>

            <preview-template-sidebar :previewData="previewData"></preview-template-sidebar>
        </app-aside>

        <app-aside v-model="linkModal"
                   :widths="['col-lg-4','col-sm-12', '40%']">
            <template slot="header">
                <app-object-header :label="$t('EVENT')"
                                   :name="eventTemplate && eventTemplate.label"></app-object-header>
            </template>

            <event-linking-form :event="eventTemplate" @save="linkModal = false"></event-linking-form>
        </app-aside>

        <app-confirmation-dialog
            :show="unlinkModal"
            :title="$t('EVENT')"
            @cancel="unlinkModal = false"
            @confirm="unlinkTemplate">
            {{ $t('UNLINK_SYSTEM_EMAIL_TEMPLATE_CONFIRMATION_MSG') }}
        </app-confirmation-dialog>
    </div>
</template>

<script>
import {previewEventTemplateMail, unlinkEventTemplate} from "@/services/event";
import {notifySuccess} from "@/shared/plugins/toastr";
import {EventBus, GE_TABLE_REFRESH_SEARCH} from "@/shared/EventBus";

export default {
    name: "EventTabs",
    components: {
        "AppNoData": () => import("@/components/app/AppNoData"),
        "EventTemplateForm": () => import("@/components/event/EventTemplateForm"),
        "PreviewTemplateSidebar": () => import("@/components/event/PreviewTemplateSidebar"),
        "FormHeader": () => import("@/components/app/form/FormHeader"),
        "AppObjectHeader": () => import("@/components/app/AppObjectHeader"),
        "AppAside": () => import("@/components/app/form/AppAside"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "EventTemplateTable": () => import("@/components/event/EventTemplateTable"),
        "EventLinkingForm": () => import("@/components/event/EventLinkingForm"),
        "AppConfirmationDialog": () => import("@/components/app/form/AppConfirmationDialog")
    },
    props: {
        eventData: {
            type: Array,
            default: () => []
        },
        eventTemplateTypeId: {
            type: Number,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        showPropertyTemplate: {
            type: Boolean,
            default: false
        },
        propertyId: {
            type: Number,
            default: null
        },
        omittedFields: {
            type: Array,
            default: () => []
        },
        templateFormLabel: {
            type: String,
            default: null
        },
        isTemplate:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            omittedFields_: ['event', 'property'],
            templateFormState: false,
            currentTab: null,
            selectedTemplate: null,
            templatePreviewState: false,
            previewData: null,
            eventTemplate: {label: null},
            linkModal: false,
            unlinkModal: false,
            eventId: null
        }
    },
    computed: {
        allOmittedFields() {
            return this.omittedFields_.concat(this.omittedFields)
        }
    },
    watch: {
        eventData: {
            handler(value) {
                if(value.length){
                        this.eventId = value[0].id
                }
            }
        }
    },
    methods: {
        openTemplateForm(template = null) {
            this.selectedTemplate = template
            this.templateFormState = true
        },
        onSaved() {
            this.templateFormState = false
            this.selectedTemplate = null
            this.$emit('saved')
        },
        openPreviewForm(data) {
            this.previewData = null
            this.templatePreviewState = true
            previewEventTemplateMail({event_template_id: data.id}).then(res => {
                this.previewData = res.data
            })
        },
        openUnlinkModal(data) {
            this.eventTemplate = data
            this.unlinkModal = true
        },
        unlinkTemplate() {
            if (!this.eventTemplate && !this.template.id) return

            unlinkEventTemplate(this.eventTemplate.id).then(() => {
                notifySuccess()
                this.$emit('saved')
                this.unlinkModal = false
                this.$emit('saved')
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
                this.unlinkModal = false
                this.eventTemplate = {label: null}
                EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
            })
        },
        openLinkForm(data) {
            this.eventTemplate = data
            this.linkModal = true
        },
    },
    created() {

    }
}
</script>

<style scoped>

</style>
