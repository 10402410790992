
<script>
    import {Doughnut, mixins} from 'vue-chartjs'
    const { reactiveProp } = mixins

    export default {
        extends: Doughnut,
        mixins: [reactiveProp],
        props: {
            chartData: {
                type: Object
            }
        },
        name: "chart-doughnut",
        data() {
            return {
                options: {
                    responsive: true, maintainAspectRatio: true, legend: {display: false},tooltips:{enabled:false}, cutoutPercentage: 80,
                }
            }
        },
        async mounted() {
            await this.renderChart(this.chartData,this.options
            )
        }
    }
</script>

<style scoped>

</style>
