<template>
    <ical-unit-table>

    </ical-unit-table>
</template>

<script>
    import IcalUnitTable from "@/components/property/ical/IcalUnitTable";
    export default {
        name: "Index",
        components: {
            IcalUnitTable
        },
    }
</script>

<style scoped>

</style>
