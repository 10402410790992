import { render, staticRenderFns } from "./PropertyAllBookingSitesForm.vue?vue&type=template&id=630c8fec&scoped=true&"
import script from "./PropertyAllBookingSitesForm.vue?vue&type=script&lang=js&"
export * from "./PropertyAllBookingSitesForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630c8fec",
  null
  
)

export default component.exports