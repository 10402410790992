<template>
    <div v-if="checkPermission(C_RESERVATION_ACCOMMODATION_GUEST_LIST_V)">


        <guest-list-table
            @openGuestSideBar="guest_sidebar_state=true"
            :from-reservation="true"
            :search-on-create="true"
            :filter="filter"
            :has-filter="false"
            :disabledNewGuest="disableNewGuest"
            :errorResponseMessage="errorResponseMessage"
        ></guest-list-table>

        <app-aside :body-scroll="showScroll" :widths="['col-sm-6']" v-model="guest_sidebar_state">
            <template slot="header">{{$t("GUEST")}}</template>
            <guest-new-form  :formData="formData" :reservation="reservation" @search="updateGuestList"></guest-new-form>
        </app-aside>
    </div>
</template>

<script>
    import GuestListTable from '@/components/guest/list/GuestListTable'
    import GuestListFilter from '@/components/guest/list/GuestFilter'
    import GuestListPagination from '@/components/app/filter/AppPagination'
    import {getGuestList} from "@/services/guest";
    import {isEmpty} from 'lodash'
    import {
        C_RESERVATION_ACCOMMODATION_GUEST_LIST_E,
        C_RESERVATION_ACCOMMODATION_GUEST_LIST_V
    } from "@/shared/component_permission";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppAside from "@/components/app/form/AppAside"
    import GuestNewForm from "@/components/guest/forms/GuestNewForm";
    import {getGuestFormData} from '@/services/guest/index'
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import routeNames from '@/router/routeNames'
    import {EventBus, GE_TABLE_SEARCH} from "@/shared/EventBus";

    export default {
        name: "Index",
        components: {
            GuestNewForm,
            AppButton,
            GuestListTable, GuestListFilter, GuestListPagination, AppAside
        },
        props: {
            reservation: {
                type: Object,
                required: true
            },
            showScroll:{
                type:Boolean,
                default:true
            }
        },
        mixins: [getErrorMessage],
        data() {
            return {
                C_RESERVATION_ACCOMMODATION_GUEST_LIST_V,
                C_RESERVATION_ACCOMMODATION_GUEST_LIST_E,


                guest_sidebar_state: false,
                formData: {},
                disableNewGuest: false,
                errorResponseMessage: null,
                routeNames
            }
        },
        computed: {
            filter() {
                return {reservation: this.reservation.reservation_code}
            }
        },
        methods: {
            getGuestListData(filter) {
                let req = {
                    ...this.filter,
                    ...{
                        reservation: this.reservation.reservation_code,
                        page: filter.currentPage,
                        limit: filter.perPage
                    }
                }

                return getGuestList(req)
            },
            updateGuestList() {
                EventBus.$emit(GE_TABLE_SEARCH)
            },
            fetchFormData(reservation_id) {
                getGuestFormData({reservation: reservation_id, lang: this.$i18n.locale}).then(response => {
                    this.formData = response.data
                }, error => {
                    if (error.response.data.error_list.length) {
                        error.response.data.error_list.forEach(el => {

                            this.errorResponseMessage = el.message

                        })
                    }
                    this.disableNewGuest = true
                })
            },
        },
        watch: {
            'reservation.id': {
                handler(value) {
                    this.fetchFormData(value)
                },
                immediate: true
            }
        },
        created() {
            this.$emit('created', true)
        }
    }
</script>

<style scoped>

</style>
