<template>
    <b-form class="quicklink_dropdown_form">
        <app-select class="quick-link-select" @input="navigateTo" :value="value" :options="routes"
                    :value-field="option_id"
                    :text-field="option_name" :search-empty-item="false">
        </app-select>
    </b-form>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";

export default {
    name: "AppQuickLink",
    components: {AppSelect},
    props: {
        value: {
            type: Number | String
        },
        text: {
            type: String,
            default: ""
        },
        routes: {
            type: Object | Array
        },
        variant: {
            type: String,
            default: 'primary'
        },
        option_id: {
            type: String,
            default: 'id'
        },
        option_name: {
            type: String,
            default: 'label'
        },
    },
    methods: {
        navigateTo(routeId) {
            let elementFound = this.routes.find(el => el[this.option_id] === routeId)

            if (elementFound) {
                this.$router.push(elementFound)
            }
        }
    },
    data() {
        return {
            selectedValue: null
        }
    }
}
</script>

<style scoped>
.quick-link-select {
    min-width: 16rem;
    max-width: 31rem;
}
</style>
