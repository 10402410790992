<template>
    <b-form @submit.prevent="submitForm" v-if="contact">
        <b-row>
            <b-col sm="12" class="mb-3">
                <label>{{$t('TYPE')}}</label>
                <b-form-input id="contactType" :value="contactData.contact_type.label" type="text"
                              disabled></b-form-input>
            </b-col>

            <b-col sm="12" class="mb-3">
                <label>{{$t('NAME')}}</label>
                <b-form-input id="contactName" v-model="contactData.name" type="text"
                              autocomplete="name"></b-form-input>
                <validation-description :show="$v.contactData.name.$dirty && $v.contactData.name.$invalid">
                    {{$t('FIELD_MANDATORY')}}
                </validation-description>
            </b-col>
            <b-col sm="12" class="mb-3">
                <label>{{$t('PHONE')}}</label>
                <b-form-input id="contactPhone1" v-model="contactData.phone" type="text"></b-form-input>
                <validation-description :show="$v.contactData.phone.$dirty && $v.contactData.phone.$invalid">
                    {{$t('FIELD_MANDATORY')}}
                </validation-description>
            </b-col>
            <b-col sm="12" class="mb-3">
                <label>{{$t('PHONE')}} 2</label>
                <b-form-input id="contactPhone1" v-model="contactData.phone2" type="text"></b-form-input>
            </b-col>

            <b-col sm="12" class="mb-3">
                <label>Email</label>
                <b-form-input id="contactEmail" type="email" v-model="contactData.email"
                          ></b-form-input>
                <validation-description :show="$v.contactData.email.$dirty && $v.contactData.email.$invalid">
                    {{$t('FIELD_MANDATORY')}}
                </validation-description>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <contact-submit :loading="saveState"
                                @submitClick="submitForm"></contact-submit>
            </b-col>
            <b-col v-if="!isNew">
                <contact-delete :loading="deleteState" @click="deleteDialogState=true"
                                classes="pull-right text-danger"></contact-delete>
            </b-col>
        </b-row>

        <contact-delete-dialog :show="deleteDialogState" @confirm="deleteContact"
                               @cancel="deleteDialogState=false" :delete_title="true">
        </contact-delete-dialog>

    </b-form>

</template>

<script>

    import ContactSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import ContactDelete from '@/components/app/AppButton/AppButtonDelete'
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import ContactDeleteDialog from '@/components/app/form/AppConfirmationDialog'

    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import {required, email as email_validation} from 'vuelidate/lib/validators'

    export default {
        name: "OwnerContactForm",
        components: {
            ContactSubmit, ValidationDescription, ContactDelete, ContactDeleteDialog
        },
        mixins: [getErrorMessage],
        props: ["contact"],
        data() {
            return {
                contactData: Object.assign({}, this.contact),
                saveState: false,
                deleteDialogState: false,
                deleteState: false
            }
        },
        methods: {
            submitForm() {

                this.$v.$touch()
                if (this.$v.$error) {
                    toast({
                        'title': "",
                        'message': this.$t('VALIDATION_FAILED'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }

                this.saveState = true

                let object = {
                    propertyId: this.$route.params.id,
                    ownerId: this.$route.params.owner_id,
                    contactTypeId: this.contactData.contact_type.id,
                    data: {
                        name: this.contactData.name,
                        phone: this.contactData.phone,
                        phone2: this.contactData.phone2,
                        email: this.contactData.email
                    }
                }

                this.$store.dispatch('owner/actionUpdateOwnerContact', object).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 2000
                        })
                    }
                ).finally(this.saveState = false)

                this.$emit('closeConcatAside')
            },
            deleteContact() {
                this.$emit('closeConcatAside')

                this.deleteDialogState = true
                this.deleteState = true
                let object = {
                    propertyId: this.$route.params.id,
                    ownerId: this.$route.params.owner_id,
                    contactTypeId: this.contactData.contact_type.id,
                }

                this.$store.dispatch('owner/actionDeleteOwnerContact', object).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.DELETE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.DELETE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.DELETE_ERROR.TITLE'),
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 2000
                        })
                    }
                ).finally(this.deleteState = false)
            }
        },
        validations: {
            contactData: {
                name: {
                    required
                },
                phone: {
                    required
                },
                email: {
                    required, email_validation
                }
            }
        },
        computed: {
            isNew() {
                return this.contact.name === null && this.contact.phone === null && this.contact.email === null
            }
        }
    }
</script>

<style scoped>

</style>
