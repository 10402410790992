<template>

    <b-dropdown class="multicheckbox-form-button" :disabled="disabled" :dropup="dropup" :no-caret="no_caret" boundary="viewport" :size="size"
                :text="text">
        <b-dropdown-form class="multicheckbox-form">
            <div v-if="loading" class="d-flex justify-content-center mb-2 mt-2">
                <b-spinner variant="primary"></b-spinner>
            </div>

            <b-form-group style="overflow-y: auto;" class="mb-2 mt-2 pl-1" v-else :label="checkbox_label">

                <b-form-checkbox v-model="check_all_value" @input="checkAll" v-if="select_all_show">Select all
                </b-form-checkbox>
                <b-dropdown-divider v-if="select_all_show"></b-dropdown-divider>
                <b-form-checkbox-group stacked @change="emitChange" @input="emitInput" :value-field="value_field"
                                       :text-field="text_field"
                                       :checked="value" :options="options">
                </b-form-checkbox-group>
            </b-form-group>
        </b-dropdown-form>
    </b-dropdown>


</template>

<script>
    export default {
        name: "AppDropdownCheckbox",
        props: {
            options: {
                type: Array | Object,
                default: () => {
                    return []
                }
            },
            value: {
                type: Array,
                default: () => {
                    return []
                }
            },
            loading: {
                type: Boolean,
                default: false
            },
            value_field: {
                type: String,
                default: "id"
            },
            text_field: {
                type: String,
                default: "label"
            },
            checkbox_label: {
                type: String,
                default: null
            },
            size: {
                type: String,
                default: "sm"
            },
            dropup: {
                type: Boolean,
                default: false
            },
            no_caret: {
                type: Boolean,
                default: false
            },
            select_all: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                check_all_value: false
            }
        },
        computed: {
            text() {
                return this.$t("NUM_SELECTED", {value: this.value.length})
            },
            select_all_show() {
                return this.select_all
                    && (
                        (Array.isArray(this.options) && this.options.length > 1)
                        || (this.options === Object(this.options) && Object.keys(this.options).length > 1)
                    )
            }
        },
        watch: {
            value(val) {
                this.check_all_value = !(val.length < 1 || val.length !== this.options.length)
            }
        },
        methods: {
            emitInput(val) {
                if(val.length === 0) {
                    this.check_all_value = false
                }
                this.$emit("input", val)

            },
            emitChange(val) {
                this.$emit("change", val)
            },
            checkAll(val) {
                let selected = []
                if (val) {
                    if (Array.isArray(this.options)) {
                        this.options.forEach(item => {
                            selected.push(item[this.value_field])
                        })
                    } else {
                        for (const itemId in this.options) {
                            selected.push(this.options[itemId][this.value_field])
                        }
                    }
                    this.$emit('change', selected)
                    this.$emit('input', selected)
                } else if (this.options.length === this.value.length) {
                    this.$emit('input', []);
                    this.$emit('change', [])
                }

            }
        }
    }
</script>

<style scoped>
    .multicheckbox-form {
        white-space: nowrap;
    }

    .multicheckbox-form-button{
        display: block;
    }
</style>
