<template>
    <div>
        <reservation-process-data-transition
            :parameter_id="SETUP_PARAMETER_DATA_TRANSITION_AFTER_EVENT" :company_id="company.id"></reservation-process-data-transition>
    </div>
</template>

<script>

import {SETUP_PARAMETER_DATA_TRANSITION_AFTER_EVENT} from "@/shared/constants";
import ReservationSetupParameter from "@/components/reservation/setup/ReservationSetupParameter";
import ReservationProcessDataTransition from "@/components/reservation/setup/ReservationProcessDataTransition";

export default {
    name: "Index",
    components: {ReservationProcessDataTransition, ReservationSetupParameter},
    data() {
        return {
            SETUP_PARAMETER_DATA_TRANSITION_AFTER_EVENT
        }
    },
    computed:{
        company(){
            return this.$store.getters['user/getCurrentCompany']
        }
    },
}
</script>

<style scoped>

</style>
