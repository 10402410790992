<template>
    <b-tabs v-if="reservation && accessControlData" v-model="activeTab" nav-class="tab_navigation-horizontal"
            content-class="tab_content-horizontal" pills justified>
        <b-tab :lazy="!loaded[R_RESERVATION_ACCOMMODATION_GENERAL]" v-if="hasPermission(R_RESERVATION_ACCOMMODATION_GENERAL)"
               :title="$t('GENERAL')">
            <reservation-accommodation-general
                @created="setLoaded(R_RESERVATION_ACCOMMODATION_GENERAL)"
                :reservation="reservation"
                @update="update"
                @saved="saved"
                @reloadScheduler="setLoaded(R_RESERVATION_ACCOMMODATION_SCHEDULER, false)"
                @reloadFinance="setLoaded(R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD, false)"
                :object-type="OBJECT_TYPE_ACCOMMODATION_RESERVATION">
            </reservation-accommodation-general>
        </b-tab>

        <b-tab :lazy="!loaded[R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD]" v-if="hasPermission(R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD)"
               :title="$t('FINANCE')">
            <reservation-accommodation-finance-dashboard
                @created="setLoaded(R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD)"
                @update="update"
                @reloadScheduler="setLoaded(R_RESERVATION_ACCOMMODATION_SCHEDULER, false)"
                @reloadSetup="setLoaded(R_RESERVATION_ACCOMMODATION_SETUP, false)"
                :reservation="reservation"
                @reloadReservation="reloadReservation = true">
            </reservation-accommodation-finance-dashboard>
        </b-tab>

        <b-tab :lazy="!loaded[R_RESERVATION_ACCOMMODATION_NOTES]" v-if="hasPermission(R_RESERVATION_ACCOMMODATION_NOTES)"
               :title="$t('NOTES')">
            <reservation-accommodation-notes
                @created="setLoaded(R_RESERVATION_ACCOMMODATION_NOTES)"
                :reservation="reservation"
                :object-type="OBJECT_TYPE_ACCOMMODATION_RESERVATION"
                @reloadReservation="reloadReservation = true">
            </reservation-accommodation-notes>
        </b-tab>

        <b-tab :lazy="!loaded[R_RESERVATION_ACCOMMODATION_GUESTS]" v-if="hasPermission(R_RESERVATION_ACCOMMODATION_GUESTS)"
               :title="$t('GUESTS')">
            <reservation-accommodation-guests
                @created="setLoaded(R_RESERVATION_ACCOMMODATION_GUESTS)"
                :showScroll="false"
                :reservation="reservation">
            </reservation-accommodation-guests>
        </b-tab>

        <b-tab :lazy="!loaded[R_RESERVATION_ACCOMMODATION_INBOX]" v-if="hasPermission(R_RESERVATION_ACCOMMODATION_INBOX)"
               :title="$t('INBOX')">
            <reservation-accommodation-inbox
                @created="setLoaded(R_RESERVATION_ACCOMMODATION_INBOX)"
                :reservation="reservation">
            </reservation-accommodation-inbox>
        </b-tab>

        <b-tab :lazy="!loaded[R_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES]" v-if="hasPermission(R_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES)"
               :title="$t('CONNECTED_SERVICES')">
            <reservation-accommodation-connected-services
                @created="setLoaded(R_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES)"
                :reservation="reservation">
            </reservation-accommodation-connected-services>
        </b-tab>

        <b-tab :lazy="!loaded[R_RESERVATION_ACCOMMODATION_SETUP]" v-if="hasPermission(R_RESERVATION_ACCOMMODATION_SETUP)"
               :title="$t('SETUP')">
            <reservation-accommodation-setup
                @created="setLoaded(R_RESERVATION_ACCOMMODATION_SETUP)"
                @setupSaved="setLoaded(R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD, false); setLoaded(R_RESERVATION_ACCOMMODATION_SCHEDULER, false);"
                :reservation-id="reservation.id">
            </reservation-accommodation-setup>
        </b-tab>

        <b-tab :lazy="!loaded[R_RESERVATION_ACCOMMODATION_SCHEDULER]" v-if="hasPermission(R_RESERVATION_ACCOMMODATION_SCHEDULER)"
               :title="$t('EVENTS')">
            <reservation-accommodation-scheduler
                @created="setLoaded(R_RESERVATION_ACCOMMODATION_SCHEDULER)"
                :reservation="reservation">
            </reservation-accommodation-scheduler>
        </b-tab>
    </b-tabs>
</template>

<script>
    import {fetchAccommodationReservation} from "@/services/accommodation_reservation";
    import routeNames from "@/router/routeNames";
    import {OBJECT_TYPE_ACCOMMODATION_RESERVATION} from "@/shared/constants";
    import {
        R_RESERVATION_ACCOMMODATION_GENERAL,
        R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD,
        R_RESERVATION_ACCOMMODATION_NOTES,
        R_RESERVATION_ACCOMMODATION_GUESTS,
        R_RESERVATION_ACCOMMODATION_INBOX,
        R_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES,
        R_RESERVATION_ACCOMMODATION_SCHEDULER,
        R_RESERVATION_ACCOMMODATION_SETUP,
    } from '@/shared/route_permission';
    import {fetchAccessControlData} from "@/services/access";
    import {AC_ACCOMMODATION_RESERVATION_NAVIGATION} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {cloneDeep} from "lodash";

    const permissions = {
        R_RESERVATION_ACCOMMODATION_GENERAL,
        R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD,
        R_RESERVATION_ACCOMMODATION_NOTES,
        R_RESERVATION_ACCOMMODATION_GUESTS,
        R_RESERVATION_ACCOMMODATION_INBOX,
        R_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES,
        R_RESERVATION_ACCOMMODATION_SCHEDULER,
        R_RESERVATION_ACCOMMODATION_SETUP
    }

    export default {
        name: "ReservationDetails",
        components: {
            'ReservationAccommodationSetup': () => import('@/components/reservation/accommodation/forms/ReservationAccommodationSetup'),
            'ReservationAccommodationScheduler': () => import('@/components/reservation/accommodation/modules/ReservationAccommodationScheduler'),
            'ReservationAccommodationGeneral': () => import('@/components/reservation/accommodation/forms/ReservationAccommodationGeneral'),
            'ReservationAccommodationFinanceDashboard': () => import('@/views/reservation/accommodation/finance_dashboard/Index'),
            'ReservationAccommodationNotes': () => import('@/views/reservation/accommodation/notes/Index'),
            'ReservationAccommodationGuests': () => import('@/views/reservation/accommodation/guests/Index'),
            'ReservationAccommodationInbox': () => import('@/views/reservation/accommodation/inbox/Index.vue'),
            'ReservationAccommodationConnectedServices': () => import('@/views/reservation/accommodation/connected_services/Index.vue'),
            'Navigation': () => import('@/components/app/navigation/Navigation')
        },
        props: {
            reservationId: {
                type: Number,
            },
            reservationObject:{
              type:Object
            },
            tab: {
                type: String,
            }
        },
        data() {
            return {
                OBJECT_TYPE_ACCOMMODATION_RESERVATION,
                reservation: null,
                tabs: [
                    {name: 'general', route: {name: routeNames.RN_RESERVATION_ACCOMMODATION_GENERAL}},
                    {name: 'finance', route: {name: routeNames.RN_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD},},
                    {name: 'notes', route: {name: routeNames.RN_RESERVATION_ACCOMMODATION_NOTES}},
                    {name: 'guests', route: {name: routeNames.RN_RESERVATION_ACCOMMODATION_GUESTS}},
                    {name: 'inbox', route: {name: routeNames.RN_RESERVATION_ACCOMMODATION_INBOX}},
                    {name: 'services', route: {name: routeNames.RN_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES}},
                    {name: 'scheduler', route: {name: routeNames.RN_RESERVATION_ACCOMMODATION_SCHEDULER}},
                ],
                ...permissions,
                activeTab: 0,
                accessControlData: null,
                loaded: {},
                reloadReservation: false,
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            computedTabs() {
                return this.tabs.reduce((acc, el) => {
                    const permissionId = this.$router.resolve(el.route).route.meta.permission_id
                    if (this.checkPermission(permissionId)) {
                        acc.push(el)
                    }
                    return acc
                }, [])
            }
        },
        watch: {
            // '$route.params.id': {
            //     handler(value) {
            //         fetchAccommodationReservation(value).then(res => {
            //             this.reservation = res.data
            //         })
            //     },
            //     immediate: true
            // },
            activeTab: {
                handler(value) {
                    if (this.reloadReservation) {
                        const generalTabName = "general"
                        const generalTabIndex = this.computedTabs.findIndex(el => el.name === generalTabName)
                        if (generalTabIndex === value) {
                            let accommodationReservationId = this.reservationId ? this.reservationId : null
                            accommodationReservationId = !accommodationReservationId && this.reservationObject && typeof this.reservationObject === "object" && this.reservationObject.hasOwnProperty("id") ? this.reservationObject.id : accommodationReservationId
                            if (!accommodationReservationId) {
                                return
                            }
                            this.fetchAccommodationReservation(accommodationReservationId)
                            this.reloadReservation = false
                        }
                    }
                }
            },
        },
        methods: {
            update(data) {
                this.reservation = {...this.reservation, ...data}
                this.$emit('update', this.reservation)
            },
            saved(data){
                this.reservation = {...this.reservation, ...data}
                this.$emit('saved', this.reservation)
            },
            hasPermission(permissionId) {
                //todo move permission check to access control
                return this.checkPermission(permissionId) && this.accessControlData[permissionId].visible
            },
            setLoaded(tabPermission, value = true) {
                this.$set(this.loaded, tabPermission, value)
            },
            fetchAccommodationReservation(accommodationReservationId) {
                fetchAccommodationReservation(accommodationReservationId, {withOwner: true}).then((response) => {
                    this.reservation = response.data
                    this.$emit("update", this.reservation)
                })
            }
        },
        created() {
            fetchAccessControlData(AC_ACCOMMODATION_RESERVATION_NAVIGATION, {
                key: AC_ACCOMMODATION_RESERVATION_NAVIGATION,
                context: {reservation: this.reservationId || this.reservationObject.id},
                data: Object.entries(permissions).map(([k, v]) => ({uid: v, function: v}))
            }).then(response => {
                this.accessControlData = response.data
            })

            if (this.reservationId) {
                this.fetchAccommodationReservation(this.reservationId)
            } else if(!this.reservationId && this.reservationObject){
                this.reservation = this.reservationObject
                this.$emit('update', this.reservation)
            }
            if (this.tab) {
                let tabIndex = this.computedTabs.findIndex(el => el.name === this.tab)
                this.activeTab = tabIndex > -1 ? tabIndex : this.activeTab
            }
        }
    }
</script>

<style scoped>

</style>
