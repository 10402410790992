<template>
    <div>
        <website-unit-type-table @onGetData="hasData=!!$event" :websiteId="website.id" :reload-on-create="reloadTable">
            <template #noData>
                <app-no-data v-if="!hasData">
                    <app-button class="col-1" button_type="new" @click="newUnitTypeAside=true">
                        {{ $t('NEW_UNIT_TYPE') }}
                    </app-button>
                    <app-button
                        :access_control_context="{function:C_WEBSITE_COPY_UNIT_TYPE,key:AC_WEBSITE}"
                        @AccessControlEvent="addToAccessControlCounter()"
                        :loading="copyingUnitType"
                        @click="copyUnitTypeModal=true">
                        {{ $t('COPY_UNIT_TYPE') }}
                    </app-button>
                </app-no-data>
            </template>
        </website-unit-type-table>
        <app-aside v-model="newUnitTypeAside" :widths="['col-lg-4','col-sm-12', '40%']">
            <template slot="header">
                <app-object-header
                    :name="$t('NEW_UNIT_TYPE')">
                </app-object-header>
            </template>
            <website-new-unit-type :website-id="website.id" @closeUnitType="closeNewUnitType"></website-new-unit-type>
        </app-aside>
        <app-confirmation-dialog :show="copyUnitTypeModal"
                                 :title="$t('COPY_UNIT_TYPE')"
                                 @confirm="copyUnitType"
                                 @cancel="copyUnitTypeModal=false">
            {{$t('COPY_UNIT_TYPE_CONFIRMATION_TEXT')}}
        </app-confirmation-dialog>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import WebsiteUnitTypeTable from "@/components/direct_booking/website/unit_type/WebsiteUnitTypeTable";
    import WebsiteNewUnitType from "@/components/direct_booking/website/unit_type/WebsiteNewUnitType";
    import AppNoData from "@/components/app/AppNoData";
    import {copyUnitType} from "@/services/direct_booking/website";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppObjectHeader from "@/components/app/AppObjectHeader";
    import AppAside from "@/components/app/form/AppAside";
    import {EventBus} from "@/shared/EventBus";
    import {C_WEBSITE_COPY_AMENITY_CATEGORIES, C_WEBSITE_COPY_UNIT_TYPE, C_WEBSITE_TYPE_NEW_V} from "@/shared/component_permission";
    import {AC_WEBSITE} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";

    export default {
        name: "Index",
        mixins: [AccessControlComponent,getErrorMessage],
        components: {
            AppConfirmationDialog,
            AppButtonSubmit, WebsiteUnitTypeTable, AppButton, AppObjectHeader, AppAside, WebsiteNewUnitType,AppNoData},
        data() {
            return {
                C_WEBSITE_COPY_UNIT_TYPE,
                C_WEBSITE_TYPE_NEW_V,
                AC_WEBSITE,
                access_control_fetch_key: AC_WEBSITE,
                access_control_components:1,
                newUnitType: false,
                reloadTable: false,
                hasData: false,
                copyingUnitType: false,
                copyUnitTypeModal: false
            }
        },
        computed:{
            website(){
                return this.$store.getters['website/getWebsite']
            },
            newUnitTypeAside: {
                get() {
                    return this.$store.state.website.newUnitTypeAside
                },
                set(value) {
                    this.$store.commit('website/setNewUnitTypeAside', value)
                }
            }
        },
        methods: {
            closeNewUnitType(){
                this.newUnitTypeAside = false
                this.reloadTable = !this.reloadTable
            },
            copyUnitType() {
                this.copyingUnitType = true

                copyUnitType(Number(this.$route.params.id)).then(() => {
                    notifySuccess()
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(()=>{
                    this.copyingUnitType = false
                    this.copyUnitTypeModal = false
                    this.reloadTable = !this.reloadTable
                })
            },
        },
        watch:{
            hasData:{
                handler(){
                    if (this.hasData){
                        this.$store.commit('website/setUnitTypeData', true)
                        this.$store.commit('website/setNewUnitTypeData', true)
                    }
                }
            }
        },
        created(){
            this.access_control_general_context = {
                company: this.company.id
            }
        },
        mounted() {
            EventBus.$on('copyWebsiteUnitType', filter => {
                this.copyUnitTypeModal = true
            })
        },
        beforeDestroy() {
            EventBus.$off('copyWebsiteUnitType')
        }
    }
</script>

<style scoped>

</style>
