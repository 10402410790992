<template>
    <div v-if="hasPermission">

        <b-table responsive hover :fields="fields" :items="poiList">
            <template v-slot:distance="data">
                {{data.item.distance}} {{data.item.distance_type}}
            </template>
            <template v-slot:edit="data">
                <poi-edit          :access_control_context="{function:c_permission_edit,key:AC_PROPERTY_POI}"
                                   @AccessControlEvent="addToAccessControlCounter()" :show_text="false" variant="link" button_type="edit" :c_permission="c_permission_edit"
                          @click="editPoi(data.index)"></poi-edit>
            </template>
        </b-table>
        <app-aside v-model="poiAsidePanelState">
            <template slot="header">{{poiItemEditing ? $t('POI_SINGLE') + ': ' + poiItemEditing.label : ''}}</template>
            <PoiForm @poiSaved="updateList" :poiItem="poiItemEditing"></PoiForm>
        </app-aside>
    </div>
</template>

<script>

    // import PoiEdit from '@/components/app/AppEdit'
    import PoiEdit from '@/components/app/AppButton/AppButton'
    import AppAside from '@/components/app/form/AppAside'
    import PoiForm from '@/components/property/poi/PropertyPoiForm'
    import {getPropertyPoi} from '@/services/property/index'
    import {C_PROPERTY_POI_V, C_PROPERTY_POI_E} from "@/shared/component_permission";
    import {AC_PROPERTY_POI} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

    export default {
        name: "Index",
        mixins:[AccessControlComponent],
        components: {
            AppAside, PoiForm, PoiEdit
        },
        data() {
            return {
                fields: [
                    {key: 'id', label: this.$t('ID')},
                    {key: 'label', label: this.$t('NAME')},
                    {key: 'distance', label: this.$t('DISTANCE')},
                    {key: 'description', label: this.$t('DESCRIPTION')},
                    {key: 'edit', label: '', tdClass: 'text-right'},
                ],
                poiList: [],
                poiAsidePanelState: false,
                poiItemEditing: null,
                c_permission_edit: C_PROPERTY_POI_E,
                AC_PROPERTY_POI,
                access_control_fetch_key:AC_PROPERTY_POI
            }
        },
        methods: {
            editPoi(poiItemIndex) {
                this.poiItemEditing = this.poiList[poiItemIndex]
                this.poiAsidePanelState = true
            },
            updateList(list) {
                this.poiList = list
                this.poiAsidePanelState = false
            }
        },
        created() {
            getPropertyPoi(this.$route.params.id).then(response => {
                this.poiList = response.data
                this.access_control_components = response.data.length
            }, () => {
                this.poiList = []
            })
            this.access_control_general_context = {
                property: this.$route.params.id,
                company: this.$store.getters['user/getCompany']
            }
        },
        computed: {
            hasPermission() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_POI_V)
            }
        }
    }
</script>

<style scoped>

</style>
