<template>
  <b-form-group>
    <label>{{$t('DISTRIBUTION')}}</label>
    <b-select :disabled="disabledSelect" @change="updateValue($event)" :value="distribution">
      <option :value="null">-</option>
      <option :key="distributionIndex" v-for="(distribution, distributionIndex) in distributionList"
              :value="distribution.id">{{distribution.name}}
      </option>
    </b-select>
  </b-form-group>
</template>

<script>
  import ValidationDescription from '@/components/app/form/AppValidationDescription'
  import {SYSTEM_STANDARD_RATE, SYSTEM_NON_REFUNDABLE_RATE} from "@/shared/constants";

  export default {
    name: "distribution",
    props: {
      parent_rate_id: {
        type: Number,
        default: null
      },
      ratePlanItem: {
        type: Object,
        default: () => {
          return {
            id: null,
            name: null,
            linked_type: null,
            linked_value: null,
            cancellation_policy: [{id: null, name: null}],
            parent: [{id: null, name: null}],
            distribution:{id:null, name:null}
          }
        }
      }
    },
    data(){
      return {
        SYSTEM_NON_REFUNDABLE_RATE,
        SYSTEM_STANDARD_RATE,
      edited:false,
      }
    },
    components:{
      ValidationDescription
    },
    computed: {
      disabledSelect(){
        if(this.ratePlanItem.type === SYSTEM_STANDARD_RATE || this.ratePlanItem.type === SYSTEM_NON_REFUNDABLE_RATE){
         return true
        }
        if(!this.parentRatePlan){
          return false
        }
        if(this.parentRatePlan.type === SYSTEM_STANDARD_RATE || this.parentRatePlan.type === SYSTEM_NON_REFUNDABLE_RATE){
          return false
        } else {
          return true
        }
      },
      distributionList() {
        let distributionList = []
        let list = this.$store.getters['pricing/getDistributionsList']
        if (list.length > 0) {
          for (let id of list) {
            let distribution = this.$store.getters['pricing/getDistributionById'](id)
            distributionList.push(distribution)
          }
        }
        return distributionList
      },
      parentRatePlan() {
        if (this.parent_rate_id) {
          return this.$store.getters['pricing/getRatePlanById'](this.parent_rate_id)
        } else if(this.ratePlanItem.id){
          return this.$store.getters['pricing/getRatePlanById'](this.ratePlanItem.parent.id)
        }
        return null
      },
      distribution() {

        if(this.parentRatePlan){
          if(this.parentRatePlan.type === SYSTEM_STANDARD_RATE || this.parentRatePlan.type === SYSTEM_NON_REFUNDABLE_RATE){
           return this.ratePlanItem.distribution.id
          } else {
            this.$emit('updateDistribution', this.parentRatePlan.distribution.id)
            return this.parentRatePlan.distribution.id
          }
        }

        return null
      }
      },
      methods: {
        updateValue(value) {
          this.edited = true
          this.$emit('updateDistribution', value)
        }
      },
      created() {

        if (this.distributionList.length === 0) {
          this.$store.dispatch('pricing/actionSetDistributionLst')
        }
      }
    }
</script>

<style scoped>

</style>
