<template>

    <b-card>
        <div class="d-flex justify-content-start align-items-center" v-if="loading">
            <b-spinner variant="primary"></b-spinner>
        </div>
        <div class="d-flex justify-content-between" v-else>
            <div class="d-flex flex-column justify-content-between">
                <div class="header-2">{{$t("TOTAL_INCOME")}}</div>
                <div class="header-1">{{income}}</div>
            </div>
            <i class="fa fa-line-chart fa-fw large-icon " aria-hidden="true"></i>
        </div>
    </b-card>
</template>

<script>
    import {getIncome} from '@/services/dashboard/index'
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {EventBus, GE_ADDITIONAL_SETUP_WIZARD_FINISHED} from "@/shared/EventBus";
    import {C_TOTAL_INCOME_V} from "@/shared/component_permission";

    export default {
        name: "DashboardIncome",
        components: {AppSelect},
        props: {
            company: {
                type: Number,
            },
            property: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                loading: false,
                income: 0,
            }
        },
        methods: {
            getData() {
                this.loading = true
                getIncome(this.company, this.property ? this.property : null).then(response => {
                    if (response.data.hasOwnProperty('income')) {
                        this.income = response.data.income
                    }
                    this.loading = false
                })
            }
        },
        watch: {
            property: {
                handler() {
                    this.getData()
                }
            }
        },
        mounted() {
            this.getData()
        },
        created() {
            EventBus.$on(GE_ADDITIONAL_SETUP_WIZARD_FINISHED, () => {
                this.getData()
            })
        }
    }
</script>

<style scoped>

</style>
