<template>
    <div>
        <partner-table @generatedPaymentOrder="$emit('generatedPaymentOrder')"></partner-table>
    </div>
</template>

<script>
    import PartnerTable from "@/components/finance/payment_orders/partner/list/PartnerTable";
    import AppAside from "@/components/app/form/AppAside";
    import PartnerInvoiceTable from "@/components/finance/payment_orders/partner/invoices/list/PartnerInvoiceTable";

    export default {
        name: "PaymentOrderPartnersSideForm",
        components: {
            PartnerTable,
            AppAside,
            PartnerInvoiceTable
        }
    }
</script>

<style scoped>

</style>
