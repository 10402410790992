<template>
    <div>
        <transition name="fade">
            <div class="app-list-spinner" v-if="isBusy">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
        </transition>

        <b-row v-if="computedTableData.length">
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label class="label">{{$t('SHOW_DEBTORS')}}</label>
                <app-select :options="yesNoOptions" v-model="filter.withDebtors"
                            :search-empty-item="false"></app-select>
            </b-col>
        </b-row>

        <transition name="fade">
            <b-table v-if="computedTableData.length" :hover="true" :responsive="true" class="partner-table-container mb-3"
                     :items="computedTableData" :fields="tableFields" :busy="isBusy">
                <template v-slot:select_partner="data">
                    <b-checkbox v-if="data.item.selectable" v-model="data.item.selected"
                                :indeterminate="data.item.indeterminate"
                                @change="(value) => {setInvoiceSelectStates(value, data.item); checkSelectStates(null); }"></b-checkbox>
                </template>

                <template v-slot:HEAD_select_partner="data">
                    <div class="tableSelectableCheckbox">
                        <b-checkbox v-model="selectAllPartnersValue"
                                    @change="selectAllPartners" :inline="true">
                        </b-checkbox>
                    </div>
                </template>

                <template v-slot:full_name="data">
                <span v-if="!data.item.invalid_partner_list.length">
                    {{data.item.name}} {{data.item.last_name}}
                </span>
                    <template v-else>
                    <span class="text-danger" :id="`key_${data.item.name}_${data.item.last_name}`">
                        {{data.item.name}} {{data.item.last_name}}
                    </span>
                        <app-button variant="link"
                                    button_type="edit"
                                    :show_text="false"
                                    @click="editPartners(data.item.invalid_partner_list)">
                        </app-button>
                        <b-popover triggers="hover"
                                   :target="`key_${data.item.name}_${data.item.last_name}`">
                            <span v-html="getInvalidMessage(data.item)"></span>
                        </b-popover>
                    </template>
                    <!--                <template v-else>-->

                    <!--                    <b-popover v-else-if="!data.item.oib_valid"-->
                    <!--                               triggers="hover"-->
                    <!--                               :target="'key_' + data.item.entity_id">{{$t('INVALID_PERSONAL_ID_NUMBER')}}-->
                    <!--                    </b-popover>-->
                    <!--                    <b-popover v-else-if="!data.item.iban_valid"-->
                    <!--                               triggers="hover"-->
                    <!--                               :target="'key_' + data.item.entity_id">{{$t('INVALID_IBAN')}}-->
                    <!--                    </b-popover>-->

                    <!--                    <span class="text-danger" :id="'key_' + data.item.entity_id">-->
                    <!--                        {{data.item.name}} {{data.item.last_name}}-->
                    <!--                    </span>-->


                    <!--                </template>-->
                </template>

                <template v-slot:payout="data">
                    <div v-if="data.item.payout_invoice_list.length">
                        <small class="d-block text-muted" v-if="data.item.selectable">
                            ({{$t('NUM_SELECTED', {value: `${data.item.payout_invoice_list.filter(i =>
                            i.selected).length}/${data.item.payout_invoice_list.length}`})}})
                        </small>
                        <b-link
                            @click="previewPartnerInvoices(data.item, data.item.payout_invoice_list, data.item.selectable)">
                            {{data.item.payout_amount.toFixed(2)}}
                        </b-link>
                    </div>

                    <span v-else>
                    {{data.item.payout_amount.toFixed(2)}}
                </span>
                </template>

                <template v-slot:debt="data">
                    <b-link v-if="data.item.debt_invoice_list.length"
                            @click="previewPartnerInvoices(data.item, data.item.debt_invoice_list, false)">
                        {{data.item.debt_amount.toFixed(2)}}
                    </b-link>
                    <span v-else>
                    {{data.item.debt_amount.toFixed(2)}}
                </span>
                </template>
            </b-table>
        </transition>


        <b-row v-if="searchDone && computedTableData.length" class="pull-right">
            <b-col md="12" class="d-flex flex-row align-items-start">
                <div class="d-block" style="min-width: 100px">
                    <span>{{$t('TOTAL')}}: {{totals.payout.toFixed(2)}}</span>
                    <small class="text-muted d-block">
                        {{$t('INVOICES')}}:
                        <b-link v-if="selected.length"
                                @click="previewPartnerInvoices({payout_invoice_list: selected}, false)">
                            {{selected.length}}
                        </b-link>
                        <template v-else>
                            {{selected.length}}
                        </template>
                    </small>
                </div>

                <div class="ml-3">
                    <app-button :loading="saving" :disabled="!selected.length"
                                @click="save">
                        {{$t('GENERATE')}}
                    </app-button>
                </div>
            </b-col>
        </b-row>

        <app-search-data v-if="!searchDone && !isBusy && !computedTableData.length"></app-search-data>
        <app-no-data v-if="!computedTableData.length && searchDone" :show-tip="false"></app-no-data>

        <app-aside v-model="partnerFormAsideState">
            <template v-for="(partner, index) in partnerObjectList">
                <form-header v-if="partnerObjectList.length > 1">{{$t('CLIENT')}} #{{index+1}}</form-header>
                <partner-form :partner-object="partner" @saved="updatePartnerData"></partner-form>
            </template>
        </app-aside>

        <app-aside v-model="partnerInvoicesSideListState" :widths="['col-lg-6', 'col-sm-12', '50%']">
            <template slot="header" v-if="asidePartner">
                <app-object-header :label="$t('INVOICES')"
                                   :name="`${asidePartner.name} ${asidePartner.last_name}`">
                </app-object-header>
            </template>

            <template slot="header" v-else>
                {{$t('INVOICES')}}
            </template>

            <partner-invoice-table :table-data="asideInvoices"
                                   @select="checkSelectStates(asidePartner)"
                                   :selectable="selectable">
            </partner-invoice-table>
        </app-aside>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppSearchData from "@/components/app/AppSearchData";
    import AppNoData from "@/components/app/AppNoData";
    import AppPagination from "@/components/app/filter/AppPagination";
    import routeNames from "@/router/routeNames";
    import {isEmpty} from "lodash";
    import {
        generateNewPaymentOrder,
        getPaymentOrderPartnerList
    } from "@/services/finance";
    import {notifySuccess, toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import PartnerForm from "@/components/finance/setup/partner/PartnerForm";
    import AppAside from "@/components/app/form/AppAside";
    import PartnerInvoiceTable from "@/components/finance/payment_orders/partner/invoices/list/PartnerInvoiceTable";
    import AppObjectHeader from "@/components/app/AppObjectHeader";
    import FormHeader from "@/components/app/form/FormHeader";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {listHelpers} from "@/mixins/shared/helpers";

    export default {
        name: "PartnerTable",
        mixins: [getErrorMessage, listHelpers],
        components: {
            AppSelect,
            FormHeader,
            AppObjectHeader,
            PartnerInvoiceTable,
            PartnerForm,
            AppButton,
            AppSearchData,
            AppNoData,
            AppPagination,
            AppAside
        },
        data() {
            return {
                routeNames,
                tableData: [],
                tableFields: [
                    {key: 'select_partner', label: '', class: 'text-left'},
                    {key: 'full_name', label: this.$i18n.t('NAME')},
                    {key: 'payout', label: this.$i18n.t('PAYOUT'), class: 'text-right w-25'},
                    {key: 'debt', label: this.$i18n.t('DEBT'), class: 'text-right'},
                    {key: 'actions', label: '', class: 'text-right'}
                ],
                filter: {
                    withDebtors: 1,
                },
                isBusy: false,
                searchDone: false,
                saving: false,
                selectedPartners: [],
                selectAllPartnersValue: false,
                partnerFormAsideState: false,
                partnerObjectList: null,
                partnerInvoicesSideListState: false,
                asideInvoices: [],
                selectable: false,
                asidePartner: null
            }
        },
        computed: {
            currentPage: {
                get() {
                    return this.filter.page
                },
                set(value) {
                    this.filter.page = value
                }
            },
            computedTableData() {
                return this.filter.withDebtors === 0 ? this.tableData.filter(i => !i.debt_amount) : this.tableData
            },
            selected() {
                return this.computedTableData.reduce((acc, el) => {
                    if (el.selectable) {
                        acc = acc.concat(el.payout_invoice_list.filter(i => i.selected))
                    }
                    return acc
                }, [])

                // return this.tableData.filter(el => el.selected)
            },
            totals() {
                return this.selected.reduce((acc, el) => {
                    acc.payout += el.amount
                    // acc.debt += el.debt_amount
                    acc.payout_count++
                    // acc.debt_count += el.debt_count
                    return acc
                }, {payout: 0, debt: 0, payout_count: 0, debt_count: 0})
            }
        },
        methods: {
            isSelectable(item) {
                return !item.invalid_partner_list.length
            },
            getTableData() {
                this.isBusy = true
                getPaymentOrderPartnerList().then(response => {
                    this.selectAllPartnersValue = false
                    this.tableData = response.data
                        .map(item => ({
                            ...item,
                            selectable: this.isSelectable(item),
                            selected: false,
                            indeterminate: false,
                            payout_invoice_list: item.payout_invoice_list.map(invoice => ({
                                ...invoice,
                                selected: false
                            }))
                        })).sort((a, b) => (a.name.localeCompare(b.name)))
                    this.isBusy = false
                    this.searchDone = true
                })
            },
            save() {
                this.saving = true
                generateNewPaymentOrder({
                    company: this.$store.getters[`user/getCompany`],
                    invoice: this.selected.map(el => el.id)
                }).then(() => {
                    notifySuccess()
                    this.$emit('generatedPaymentOrder')
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.saving = false
                })

            },
            selectAllPartners(value) {
                for (let item of this.computedTableData) {
                    if (item.selectable) {
                        item.selected = value
                        this.setInvoiceSelectStates(value, item)
                    }
                }
            },
            checkSelectStates(partner) {
                this.$nextTick(() => {
                    if (partner) {
                        //todo figure out why indeterminate icon is not updating in the dom
                        const selectedCount = partner.payout_invoice_list.filter(i => i.selected).length
                        if (!(selectedCount === 0 || selectedCount === partner.payout_invoice_list.length)) {
                            partner.indeterminate = true
                        } else {
                            partner.indeterminate = false
                            partner.selected = selectedCount !== 0
                        }
                    }

                    this.selectAllPartnersValue = this.computedTableData.reduce((acc, item) => {
                        return item.selectable ? acc && item.selected : acc;
                    }, true)
                })
            },
            setInvoiceSelectStates(value, partner) {
                partner.payout_invoice_list.forEach(invoice => {
                    invoice.selected = value
                })
            },
            editPartners(partnerList) {
                this.partnerObjectList = partnerList.map(p => ({id: p.id}))
                this.partnerFormAsideState = true
            },
            updatePartnerData(partner) {
                let tablePartnerIndex = this.computedTableData.findIndex(el => (el.invalid_partner_list.map(p => p.id).includes(partner.id)))
                let partnerIndex = this.computedTableData[tablePartnerIndex].invalid_partner_list.findIndex(el => el.id === partner.id)
                this.computedTableData[tablePartnerIndex].invalid_partner_list.splice(partnerIndex, 1)
                if (!this.computedTableData[tablePartnerIndex].invalid_partner_list.length) {
                    this.computedTableData[tablePartnerIndex].selectable = true
                    this.partnerFormAsideState = false
                } else {
                    this.partnerObjectList = this.computedTableData[tablePartnerIndex].invalid_partner_list.map(p => ({id: p.id}))
                }
            },
            previewPartnerInvoices(partner, invoices, selectable) {
                if (partner.name) {
                    this.asidePartner = partner
                } else {
                    this.asidePartner = null
                }
                this.asideInvoices = invoices
                this.selectable = selectable
                this.partnerInvoicesSideListState = true
            },
            getInvalidMessage(item) {
                return item.invalid_partner_list.reduce((acc, el) => {
                    if (el.oib) acc += this.$t('INVALID_PERSONAL_ID_NUMBER') + '<br>'
                    if (el.iban) acc += this.$t('INVALID_IBAN') + '<br>'
                    return acc
                }, '')
            }
        },
        created() {
            this.getTableData()
        }
    }
</script>

<style lang="scss" scoped>
    .partner-table-container {
        max-height: 65vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
            height: 1rem;
            width: 0.5rem;
        }

        &::-webkit-scrollbar-track {
            background-color: #e1e1e1;
        }

        &::-webkit-scrollbar-thumb {
            background: #c0c0c0;
            border-radius: 15px;
            border: none;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .app-list-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
    }

    .fade-enter-active {
        transition: opacity 1s
    }

    .fade-leave-active {
        transition: opacity .1s
    }

    .fade-enter, .fade-leave-to {
        opacity: 0
    }
</style>
