<template>
    <tr>
        <td colspan="3" class="text-center" v-if="!addNewItem">
            <app-button button_type="new" @click="addNewItem=true">{{$t("NEW")}}</app-button>
        </td>
        <template v-else>
            <td>
                <app-select v-model="values.type_id" text-field="label" :options="options.inventory"></app-select>
            </td>
            <td>
                <b-form-input v-model="values.amount" type="number"></b-form-input>
            </td>
            <td class="text-right">
                <app-button :loading="saving" v-if="inventory.id === null || change" @click="save"
                                :disabled="saveDisabled"></app-button>

                <app-button button_type="delete" :show_text="false" variant="link" :loading="saving" v-if="inventory.id !== null && change === false" @click="showDeleteDialog=true"></app-button>

            </td>
            <delete-dialog :show="showDeleteDialog" @confirm="deleteInventory"
                           @cancel="showDeleteDialog=false" :delete_title="true"></delete-dialog>
        </template>


    </tr>
</template>

<script>
    import AppButton from '@/components/app/AppButton/AppButton'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {toast} from '@/shared/plugins/toastr'
    import DeleteInventoryButton from '@/components/app/AppDelete'
    import DeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    export default {
        name: "UnitRoomInventoryForm",
        components: {
            AppButtonDelete,
            AppButton,
            AppSelect,
            DeleteInventoryButton, DeleteDialog
        },
        mixins: [getErrorMessage],
        props: {
            inventory: {
                type: Object,
                default: () => {
                    return {
                        id: null,
                        type_id: null,
                        amount: null
                    }
                }
            },
            room_id: {
                type: Number
            }
        },
        data() {
            return {
                values: {
                    id: null,
                    type_id: null,
                    amount: null
                },
                showDeleteDialog: false,
                change: false,
                saving: false,
                addNewItem: false
            }
        },
        computed: {
            options() {
                return this.$store.getters['amenity/getAmenityOptions']
            },
            saveDisabled() {
                return Number(this.values.amount) <= 0 || this.values.type_id === null || this.change === false
            }
        },
        methods: {
            save() {
                if (this.values.id === null) {
                    this.createInventory()
                    this.addNewItem = false
                } else {
                    this.updateInventory()
                }


            },
            updateInventory() {
                this.saving = true
                this.$store.dispatch('amenity/actionUpdateUnitRoomInventory', {
                    unit_id: this.$route.params.unit_id,
                    room_id: this.room_id,
                    inventory_id: this.values.id,
                    data: {
                        type_id: this.values.type_id,
                        amount: this.values.amount,
                    }
                }).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                        this.saving = false
                        this.change = false
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 2000
                        })
                        this.saving = false
                    }
                )
            },
            createInventory() {
                this.saving = true
                this.$store.dispatch('amenity/actionCreateUnitRoomInventory', {
                    unit_id: this.$route.params.unit_id,
                    room_id: this.room_id,
                    data: {
                        type_id: this.values.type_id,
                        amount: this.values.amount,
                    }
                }).then(() => {
                        this.$emit("clearForm")
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                        this.saving = false
                        this.change = false
                        this.values.type_id = null
                        this.values.amount = null
                    }, error => {
                        this.saving = false
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 2000
                        })
                    }
                )
            },
            deleteInventory() {
                this.saving = true
                this.showDeleteDialog = false
                this.$store.dispatch('amenity/actionDeleteUnitRoomInventory', {
                    unit_id: this.$route.params.unit_id,
                    room_id: this.room_id,
                    inventory_id: this.values.id
                }).then(() => {
                        this.saving = false
                        this.$emit('inventoryDeleted')
                    }, error => {
                        this.saving = false
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }
                )


            }
        },
        created() {
            this.values = JSON.parse(JSON.stringify(this.inventory))
            if(this.values.id){
                this.addNewItem = true
            }
            //this.values = this.inventory
        },
        watch: {
            inventory: {
                handler(newValue) {
                    //this.values = JSON.parse(JSON.stringify(this.newValue))
                    this.change = false
                },
                deep: true
            },
            'values.type_id': function (newVal) {
                if (newVal !== this.inventory.type_id) {
                    this.change = true
                }
            },
            'values.amount': function (newVal) {
                if (newVal !== this.inventory.amount) {
                    this.change = true
                }
            },

            room_id() {
                this.values = JSON.parse(JSON.stringify(this.inventory))
                this.change = false
            }
        }
    }
</script>

<style scoped>

</style>
