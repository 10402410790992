<template>
    <div>
        <website-amenity-categories-table :websiteId="Number($route.params.id)"
                                          :refesh="refresh"
                                          @noResultsInCategories="setAppNoData"></website-amenity-categories-table>
        <app-no-data v-if="appNoData">
            <app-button v-if="checkPermission(C_WEBSITE_AMENITY_CATEGORY_NEW_V)" class="col-1" button_type="new"
                        @click="openNewCategoryAside">
                {{ $t('NEW_AMENITY_CATEGORY') }}
            </app-button>
            <app-button
                :access_control_context="{function:C_WEBSITE_COPY_AMENITY_CATEGORIES,key:AC_WEBSITE}"
                @AccessControlEvent="addToAccessControlCounter()"
                @click="confirmation_dialog_state=true">
                {{ $t('COPY_CATEGORIES') }}
            </app-button>
        </app-no-data>
        <app-aside v-model="newAmenityCategory"  :widths="['col-lg-4','col-sm-12', '40%']">
            <template slot="header">
                <app-object-header
                    :name="$t('NEW_AMENITY_CATEGORY')">
                </app-object-header>
            </template>
            <new-website-category :websiteId="Number($route.params.id)"
                                  @saved="saved"></new-website-category>
        </app-aside>
        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="copyAmenities"
            @cancel="confirmation_dialog_state = false"
            :title="$t('COPY_CATEGORIES')">
            {{ $t('COPY_AMENITY_CATEGORIES_CONFIRMATION_TITLE') }}
        </app-confirmation-dialog>
    </div>
</template>

<script>
import WebsiteAmenityCategoriesTable
    from "@/components/direct_booking/website/amenities/WebsiteAmenityCategoriesTable";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import NewWebsiteCategory from "@/components/direct_booking/website/amenities/NewWebsiteCategory";
import AppNoData from "@/components/app/AppNoData";
import {copyAmenities} from "@/services/direct_booking/website";
import {notifySuccess} from "@/shared/plugins/toastr";
import {EventBus} from "@/shared/EventBus";
import {AC_WEBSITE} from "@/mixins/AccessControl/AccessControlEnumeration";
import {C_WEBSITE_COPY_AMENITY_CATEGORIES, C_WEBSITE_AMENITY_CATEGORY_NEW_V} from "@/shared/component_permission";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";

export default {
    name: "Index",
    mixins: [AccessControlComponent],
    components: {
        AppConfirmationDialog,
        NewWebsiteCategory, AppObjectHeader, AppButton, WebsiteAmenityCategoriesTable, AppAside, AppNoData
    },
    data() {
        return {
            C_WEBSITE_COPY_AMENITY_CATEGORIES,
            AC_WEBSITE, C_WEBSITE_AMENITY_CATEGORY_NEW_V,
            confirmation_dialog_state: false,
            refresh: false,
            appNoData: false,
            access_control_fetch_key: AC_WEBSITE,
            access_control_components: 1
        }
    },
    methods: {
        saved() {
            this.newAmenityCategory = false
            this.refresh = !this.refresh
        },
        openNewCategoryAside(data) {
            this.newAmenityCategory = true;
        },
        copyAmenities() {
            this.loading = true;
            copyAmenities(Number(this.$route.params.id)).then(() => {
                notifySuccess()
                EventBus.$emit('reloadWebsiteCategoryTable')
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
                this.confirmation_dialog_state = false
            })
        },
        setAppNoData(value) {
            this.appNoData = value
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        newAmenityCategory: {
            get() {
                return this.$store.state.website.newAmenityCategory
            },
            set(value) {
                this.$store.commit('website/setNewAmenityCategoryAsideState', value)
            }
        }
    },
    created() {
        this.access_control_general_context = {
            company: this.company.id
        }
    }
}
</script>

<style scoped>

</style>
