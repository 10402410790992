<template>
    <tr>
        <td>
            <app-select
                v-model="selectedOccupancy.default"
                :options="yesNoOptions"
                :search-empty-item="false">
            </app-select>
        </td>
        <td>
            <app-number-input
                :min="1"
                v-model="selectedOccupancy.min_guests">
            </app-number-input>
        </td>
        <td>
            <app-number-input
                :min="1"
                :max="this.maximumGuests"
                v-model="selectedOccupancy.max_guests">
            </app-number-input>
        </td>
        <td>
            <app-number-input
                :allow-decimal="true"
                v-model="selectedOccupancy.discount"
                v-show="!isDefault">
            </app-number-input>
        </td>
        <td>
            <app-select
                v-model="selectedOccupancy.discount_type"
                :options="selectDiscountTypeOptions"
                value-field="value"
                text-field="text"
                v-show="previewRelationType"
                :search-empty-item="false">
            </app-select>
        </td>
        <td class="text-right">
            <app-button
                :access_control_context="{function: C_UNIT_CALENDAR_OCCUPANCY_E, key: AC_UNIT_OCCUPANCY}"
                @AccessControlEvent="addToAccessControlCounter()"
                button_type="new"
                @click="save"
                :loading="loading"
                :disabled="addNewButtonDisabled">
                {{ $t("ADD_ITEM") }}
            </app-button>
        </td>
    </tr>
</template>

<script>
import AppButton from '@/components/app/AppButton/AppButton'
import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import AppNumberInput from "@/components/app/form/AppNumberInput";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_UNIT_OCCUPANCY} from "@/mixins/AccessControl/AccessControlEnumeration";
import {C_UNIT_CALENDAR_OCCUPANCY_E} from "@/shared/component_permission";
import {listHelpers} from "@/mixins/shared/helpers";
import {FIX, PERC} from "@/shared/constants";

export default {
    name: "UnitOccupancyListItemNew",
    components: {
        AppNumberInput,
        AppButton,
        AppSelect
    },
    mixins: [
        getErrorMessage,
        AccessControlComponent,
        listHelpers,
    ],
    props: {
        loading: {
            type: Boolean
        },
        maximumGuests: {
            type: Number,
            default: Number.POSITIVE_INFINITY,
            validator: value => {
                return value === Number.POSITIVE_INFINITY || (Number.isInteger(value) && value > 0)
            },
        },
    },
    data() {
        return {

            AC_UNIT_OCCUPANCY,
            C_UNIT_CALENDAR_OCCUPANCY_E,
            access_control_components: 1,
            selectDiscountTypeOptions: [
                {value: PERC, text: "%"},
                {value: FIX, text: "fix"},
            ],
            selectedOccupancy: {
                min_guests: null,
                max_guests: null,
                discount: null,
                discount_type: null,
                default: 0
            },
        }
    },
    computed: {
        isDefault() {
            return this.selectedOccupancy.default === 1
        },
        previewRelationType() {
            return !this.isDefault && !Number.isNaN(this.selectedOccupancy.discount) && Number(this.selectedOccupancy.discount) === this.selectedOccupancy.discount
        },
        addNewButtonDisabled() {
            return this.selectedOccupancy.default === null || !(!!this.selectedOccupancy.min_guests || !!this.selectedOccupancy.max_guests)
        }
    },
    methods: {
        save() {
            this.$emit("save", this.selectedOccupancy)
        }
    },
    watch: {
        loading: {
            handler(value) {
                if (!value) {
                    this.selectedOccupancy.default = 0
                    this.selectedOccupancy.min_guests = null
                    this.selectedOccupancy.max_guests = null
                    this.selectedOccupancy.discount = null
                    this.selectedOccupancy.discount_type = null
                }
            }
        },
        "selectedOccupancy.default": {
            handler(value) {
                if (value === 1) {
                    this.selectedOccupancy.discount = null
                    this.selectedOccupancy.discount_type = null
                }
            }
        },
        "selectedOccupancy.discount": {
            handler(value) {
                if (Number.isNaN(value) || Number(value) !== value) {
                    this.selectedOccupancy.discount_type = null
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
