<template>
    <div
            ref="calendar_scroll_container"
        class="main_calendar__holder--content_empty main_calendar--background_gray main_calendar-bb"
        :class="{'main_calendar-bb_green': !show}"
    >
        <div

            :class="{'main_calendar-br_green': Number(date.format('D')) === date.daysInMonth()}"
            v-for="date in dateList">
        </div>
    </div>
</template>

<script>
    import {CalendarRowMixin} from "@/mixins/calendar/CalendarRowMixin";
    export default {
        name: "TableDateEmptyRow",
        mixins:[CalendarRowMixin],
        props: {
            scrollLeftPosition:{
                type: Number
            },
            show: {
                type: Boolean,
                default: false
            },
            dateList: {
                type: Array
            }
        }
    }
</script>

<style scoped>

</style>
