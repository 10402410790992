<template>
    <div class="messages_list_item" :class="classList">

        <div class="messages_list_item_text">
            <div class="messages_list_item_text_name">
                <template v-if="message.sender.name">
                    {{message.sender.name}}
                </template>
                <template v-else>
                    <i class="fa fa-info-circle" ></i>
                </template>


            </div>
            <div class="attachment_container" v-if="message.attachment">
                <b-img class="attachment_photo" @click="$emit('preview', message.id)" :src="message.attachment"></b-img>
            </div>
            <span v-else v-html="sanitizeText(message.text)"></span>
            <div class="messages_list_item_text_time">
                {{message.ts_created|unixHumanize}}
            </div>
        </div>

    </div>
</template>

<script>

    import {get} from 'lodash'
    import {MESSAGE_STATUS_UNREAD} from '@/shared/constants'
    import {textFormatters} from "@/mixins/shared/helpers";
    export default {
        name: "InboxMessageItem",
        mixins: [textFormatters],
        props: {
            message: {
                type: Object
            },
            isLastUnread:{
                type:Boolean,
                default: false
            }
        },
        computed: {
            classList() {
                return {
                    right: get(this.message, 'sender.is_host') === true,
                    left: get(this.message, 'sender.is_host') === false,
                    info: get(this.message, 'sender.id') === null,
                    attachment: get(this.message, 'attachment') !== null,
                    unread: get(this.message, 'status.id') === MESSAGE_STATUS_UNREAD,
                    last_unread: this.isLastUnread
                }
            }
        }
    }
</script>

<style scoped>

</style>
