<template>
    <div class="app flex-row align-items-center">
        <div class="container">
            <b-row class="justify-content-center">
                <b-col md="6" sm="8">
                    <b-card no-body class="p-0">
                        <b-card-header class="pl-0">

                            <span>{{$t('EASE_OF_BUSINESS')}}</span>
                            <h1>{{$t('SIGN_UP')}}</h1>
                        </b-card-header>
                        <b-card-body class="p-0">
                            <b-form autocomplete="off" @submit.prevent="registerUser">
                                <b-form-group class="mb-3">

                                    <app-input-control :error-object="validationErrorObject.name">
                                        <template v-slot:input="data">
                                            <b-input-group>
                                                <b-input-group-prepend>
                                                    <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                                                </b-input-group-prepend>
                                                <b-form-input v-model="object.name" type="text" name="some_name"
                                                              :state="data.state"
                                                              :placeholder="$t('FULL_NAME')"/>
                                            </b-input-group>
                                        </template>
                                    </app-input-control>

                                </b-form-group>

                                <b-form-group class="mb-3">
                                    <app-input-control :error-object="validationErrorObject.email">
                                        <template v-slot:input="data">
                                            <b-input-group>
                                                <b-input-group-prepend>
                                                    <b-input-group-text>@</b-input-group-text>
                                                </b-input-group-prepend>
                                                <b-form-input :state="data.state" v-model="object.email" type="text"
                                                              class="form-control" autocomplete="none"
                                                              :placeholder="$t('EMAIL')"/>
                                            </b-input-group>
                                        </template>
                                    </app-input-control>
                                </b-form-group>
                                <b-form-group class="mb-3">
                                    <app-input-control :error-object="validationErrorObject.phone">
                                        <template v-slot:input="data">
                                            <b-input-group>
                                                <b-input-group-prepend>
                                                    <b-input-group-text><i class="icon-phone"></i></b-input-group-text>
                                                </b-input-group-prepend>
                                                <b-form-input v-model="object.phone" type="text" name="some_phone"
                                                              :state="data.state"
                                                              :placeholder="$t('PHONE_MOBILE')"/>
                                            </b-input-group>
                                        </template>
                                    </app-input-control>
                                </b-form-group>

                                <b-form-group class="mb-3">
                                    <app-input-control :error-object="validationErrorObject.password">
                                        <template v-slot:input="data">
                                            <b-input-group>
                                                <b-input-group-prepend>
                                                    <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                                                </b-input-group-prepend>
                                                <b-form-input v-model="object.password" type="password"
                                                              name="some_password" :state="data.state"
                                                              autocomplete="none"
                                                              :placeholder="$t('PASSWORD')"/>

                                            </b-input-group>
                                        </template>
                                    </app-input-control>
                                </b-form-group>
                                <b-form-group class="mb-3">
                                    <app-input-control :error-object="validationErrorObject.repeat_password">
                                        <template v-slot:input="data">
                                            <b-input-group>
                                                <b-input-group-prepend>
                                                    <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                                                </b-input-group-prepend>
                                                <b-form-input v-model="object.repeat_password" type="password"
                                                              name="some_repeat_password" :state="data.state"
                                                              class="form-control"
                                                              :placeholder="$t('REPEAT_PASSWORD')"/>
                                            </b-input-group>
                                        </template>
                                    </app-input-control>
                                </b-form-group>

                                <b-row class="mb-3">
                                    <b-col cols="4">
                                        <app-submit button-class="button-min-width" :disabled="!agree || maintenance"
                                                    :loading="showLoading">{{$t('REGISTER')}}
                                        </app-submit>
                                    </b-col>
                                    <b-col cols="8">
                                        <p class="text-right">
                                            <a :href="redirectToLogin" target='_top' class="ml-2">{{$t('ALREADY_REGISTERED')}}</a>
                                        </p>
                                    </b-col>
                                </b-row>
                            </b-form>

                            <div v-if="!agree">

                                <input type="checkbox" id="consent_check"
                                       style="margin-right: 0; margin-left: 0; margin-bottom: 0;"
                                       v-model="agree">
                                <label class="login-input" v-html="$t('REGISTER_PERMISSION_MESSAGE',{link_about_us:linkAboutUs, link_privacy_policy:linkPrivacyPolicy, link_terms_conditions:LinkTermsAndCondition})"></label>
                            </div>
                            <b-row class="mt-4">
                                <b-col>
                                    <b-alert variant="danger" :show="maintenance">
                                        {{$t('MAINTENANCE_MESSAGE')}}
                                    </b-alert>
                                </b-col>
                            </b-row>
                            <b-row class="mt-3">
                                <b-col v-if="showSuccessMessage" class="noty_close_with_button">
                                    <div role="alert" class="alert alert-success">
                                        <h5>{{$t('NOTIFICATIONS.REGISTER_SUCCESS.TITLE')}}</h5>
                                        <span data-notify="message">{{$t('NOTIFICATIONS.REGISTER_SUCCESS.MESSAGE',{email})}}</span>
                                    </div>
                                </b-col>
                                <b-col v-if="showError">
                                    <div role="alert" class="alert alert-danger">
                                        <span data-notify="message">{{$t('EMAIL_EXIST')}}</span>
                                    </div>

                                </b-col>
                            </b-row>

                        </b-card-body>

                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import {register} from "@/services/access";
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import {
        COM_NAME_VALIDATION_FAIL,
        USER_VALIDATION_FAILED_EMAIL,
        USER_VALIDATION_FAILED_PASSWORD_MIN_LENGTH,
        USER_VALIDATION_FAILED_PASSWORD_MUST_CONTAIN_NUMBERS_AND_LETTERS,
        USER_VALIDATION_FAILED_PASSWORD_MUST_CONTAIN_NUMBER_UPPERCASE_AND_LOWERCASE_LETTER,
        USER_VALIDATION_FAILED_PASSWORD_NOT_SAME,
        USER_VALIDATION_FAILED_PHONE,
        USER_VALIDATION_FAILED_EMAIL_ALREADY_EXIST,
        USER_EMAIL_REQUIRED,
        USER_VALIDATION_FAILED_REPEAT_PASSWORD_REQUIRED

    } from "@/shared/error_codes";
    import AppInputControl from "@/components/app/AppInputControl";
    import {
        LinkAboutUs_en,
        LinkAboutUs_hr,
        LinkPrivacyPolicy_en,
        LinkPrivacyPolicy_hr,
        LinkPrivacyPolicy_me,
        LinkPrivacyPolicy_si,
        LinkPrivacyPolicy_sr,

        LinkTermsAndCondition_en,
        LinkTermsAndCondition_hr,
        LinkTermsAndCondition_me,
        LinkTermsAndCondition_si,
        LinkTermsAndCondition_sr,

        LoginPageHr, LoginPageEn,
        LoginPageEs, LoginPageDa,
        LoginPageDe, LoginPageIt,
        LoginPageNl, LoginPageFallback
    } from "@/shared/constants";

    export default {
        components: {
            AppInputControl,
            AppSubmit, ValidationDescription
        },
        name: 'register',
        mixins: [getErrorMessage],


        data() {
            return {
                maintenance:false,
                object: {
                    name: null,
                    phone: null,
                    email: null,
                    password: null,
                    repeat_password: null,
                },
                email: null,
                agree: 0,
                errorMessage: "",
                showSuccessMessage: false,
                validationErrorObject: {
                    name: {
                        input: [COM_NAME_VALIDATION_FAIL]
                    },
                    email: {
                        input: [USER_VALIDATION_FAILED_EMAIL, USER_EMAIL_REQUIRED],

                    },
                    password: [USER_VALIDATION_FAILED_PASSWORD_MIN_LENGTH,
                            USER_VALIDATION_FAILED_PASSWORD_MUST_CONTAIN_NUMBER_UPPERCASE_AND_LOWERCASE_LETTER,
                            USER_VALIDATION_FAILED_PASSWORD_MUST_CONTAIN_NUMBERS_AND_LETTERS]
                    ,
                    repeat_password: {
                        input: [USER_VALIDATION_FAILED_PASSWORD_NOT_SAME, USER_VALIDATION_FAILED_REPEAT_PASSWORD_REQUIRED]
                    },
                    phone: {
                        input: [USER_VALIDATION_FAILED_PHONE]
                    },

                },
                showLoading: false,
                showError: false
            }
        },

        computed: {
            redirectToLogin() {
                if (this.$i18n.locale == 'hr') {
                    return LoginPageHr
                }
                if (this.$i18n.locale == 'en'){
                    return LoginPageEn
                }
                if (this.$i18n.locale == 'es'){
                    return LoginPageEs
                }
                if (this.$i18n.locale == 'da'){
                    return LoginPageDa
                }
                if (this.$i18n.locale == 'de'){
                    return LoginPageDe
                }
                if (this.$i18n.locale == 'it'){
                    return LoginPageIt
                }
                if (this.$i18n.locale == 'nl'){
                    return LoginPageNl
                }
                return LoginPageFallback + this.$i18n.locale
            },
            linkAboutUs() {
                if(this.$i18n.locale == 'hr'){
                    return LinkAboutUs_hr
                } else {
                    return LinkAboutUs_en
                }
            },
            linkPrivacyPolicy(){
                if(this.$i18n.locale == 'hr'){
                    return LinkPrivacyPolicy_hr
                }
                if(this.$i18n.locale =='en'){
                    return LinkPrivacyPolicy_en
                }
                if(this.$i18n.locale =='si'){
                    return LinkPrivacyPolicy_si
                }
                if(this.$i18n.locale =='me'){
                    return LinkPrivacyPolicy_me
                }
                if(this.$i18n.locale =='sr'){
                    return LinkPrivacyPolicy_sr
                }
            },
            LinkTermsAndCondition(){
                if(this.$i18n.locale == 'hr'){
                   return  LinkTermsAndCondition_hr
                }
                if(this.$i18n.locale =='en'){
                    return LinkTermsAndCondition_en
                }
                if(this.$i18n.locale =='si'){
                    return LinkTermsAndCondition_si
                }
                if(this.$i18n.locale =='me'){
                    return LinkTermsAndCondition_me
                }
                if(this.$i18n.locale =='sr'){
                    return LinkTermsAndCondition_sr
                }
            }
        },
            methods: {
                registerUser() {

                    gtag('event', 'registration_form_submit')

                    this.showLoading = true
                    this.showSuccessMessage = false

                    this.object.lang = this.$i18n.locale
                    this.email = this.object.email

                    register(this.object).then(() => {
                        this.showSuccessMessage = true
                        this.showLoading = false
                        this.showError = false
                        this.clearObject()

                        gtag('event', 'registration_success')
                        fbq('track', 'CompleteRegistration', {status: true})
                    }, error => {
                        this.showErrorMessages(error, this.validationErrorObject)
                        this.handleErrorMessage(error)
                        this.object.password = null
                        this.object.repeat_password = null
                        this.showSuccessMessage = false
                        this.showLoading = false

                        gtag('event', 'registration_failed')
                        fbq('track', 'CompleteRegistration', {status: false})
                    })
                },
                clearObject() {
                    this.object.name = null
                    this.object.repeat_password = null
                    this.object.password = null
                    this.object.email = null
                    this.object.phone = null
                    this.agree = 0
                },
                handleErrorMessage(error) {
                    if (error.response.data.error_list.length) {
                        for (let errorObject of error.response.data.error_list) {
                            if (errorObject.code === USER_VALIDATION_FAILED_EMAIL_ALREADY_EXIST.code) {
                                this.showError = true
                            }
                        }
                    }
                }
            },
            watch: {
                '$route.query.lang': {
                    handler(value) {
                        if(value === 'sl'){
                            this.$i18n.locale = 'si'
                        }  else  {
                            this.$i18n.locale = value ? value  : 'en'
                        }
                    },
                    immediate: true
                }
            },

            beforeRouteEnter(to, from, next) {
                document.body.classList.add('page-login');
                next()
            }

        }
</script>
<style scoped>
    .login-input {
        display: inline-block;
        font-weight: normal;
        font-size: 12px;
        text-align: justify;
        padding: 0;
        background: none;
        cursor: pointer;
    }
</style>
