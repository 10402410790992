<template>
    <UnitDmgDepositForm v-if="hasPermissionView" :damageDeposit="damageDeposit"></UnitDmgDepositForm>
</template>
<script>
    import UnitDmgDepositForm from '@/components/unit/damage_deposit/UnitDmgDepositForm';
    import {C_UNIT_DAMAGE_DEPOSIT_V} from "@/shared/component_permission";
    import FormHeader from "@/components/app/form/FormHeader";
    import {ACTION_SET_UNIT_DAMAGE_DEPOSIT} from "@/store/store_types";
    import {cloneDeep} from "lodash";

    export default {
        name: "Index",
        data() {
            return {
                damageDeposit: null
            }

        },
        components: {
            FormHeader,
            UnitDmgDepositForm,
        },
        computed: {
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](C_UNIT_DAMAGE_DEPOSIT_V)
            }
        },
        watch: {
            '$route.params.unit_id': {
                handler(value) {
                    this.damageDeposit = null
                    this.$store.dispatch(ACTION_SET_UNIT_DAMAGE_DEPOSIT, value).then(response => {
                        this.damageDeposit = cloneDeep(response.data)

                    }, () => {
                        this.damageDeposit = null
                    })
                },
                immediate: true
            }
        }
    }
</script>
