<template>
    <b-card class="main-section-card">
        <template slot="header">
            <h2>{{ $t("INVOICES") }}</h2>
            <app-button v-if="checkPermission(F_INVOICE_CREATE) && setupList && setupList.length && !empty_initial_list" button_type="new" variant="primary"
                        class="pull-right" @click="()=>{asidePanelState=true}">
                {{ $t("INVOICE_NEW") }}
            </app-button>
        </template>
        <app-no-data v-if="empty_initial_list" :heading="$t('MODULE_NOT_ACTIVE')"
                     :tip="$t('MODULE_NEED_PROPERTIES')" :show-tip="true">
            <new-button v-if="checkPermission(R_PROPERTY_NEW)" button_type="new" variant="primary"
                        class="pull-right ml-3" @click="property_aside = true">
                {{ $t('NEW_PROPERTY') }}
            </new-button>
            <app-button
                :access_control_context="{function: C_PROPERTY_IMPORT_FROM_OTA, key: access_control_fetch_key}"
                @AccessControlEvent="addToAccessControlCounter()"
                class="pull-right ml-3"
                @click="importPropertyModalState = true">
                {{ $t("IMPORT_FROM_OTA") }}
            </app-button>
        </app-no-data>
        <invoice-filter v-if="!empty_initial_list">
        </invoice-filter>
        <invoice-list
            v-if="!empty_initial_list"
            :search-on-create="false">
        </invoice-list>

        <app-aside v-model="asidePanelState" :header-classes="[]">
            <template slot="header">{{ $t("CREATE_INVOICE") }}</template>
            <invoice-form :setup-list="setupList"
                          :creation-location="ICL_INVOICE_LIST"
                          @created="reloadData"
                          @closed="()=>asidePanelState=false">
            </invoice-form>
        </app-aside>

        <b-modal
            v-model="importPropertyModalState"
            :title="$t('IMPORTANT')">
            <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "Booking.com"}) }}</p>
            <ul>
                <li>{{ $t("IMPORT_PROPERTY_FROM_BOOKING_DISCLAIMER_1") }}</li>
                <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
            </ul>
            <app-button
                @click="importFromDistribution(BOOKING)">
                {{ $t("IMPORT") }}
            </app-button>
            <br/>
            <br/>
            <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "AirBnB"}) }}</p>
            <ul>
                <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_1") }}</li>
                <li>{{
                        $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_2", {
                            "min_image_width": 2048,
                            "min_image_height": 1536
                        })
                    }}
                </li>
                <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_3") }}</li>
                <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
            </ul>
            <app-button
                @click="importFromDistribution(AIRBNB)">
                {{ $t("IMPORT") }}
            </app-button>
            <template slot="modal-footer">
                <template slot="modal-ok">
                </template>
            </template>
        </b-modal>
        <app-aside :widths="['col-sm-12', '40%']" v-model="property_aside">
            <template slot="header">{{ $t('NEW_PROPERTY') }}</template>
            <property-wizard></property-wizard>
        </app-aside>
        <app-aside
            v-model="importPropertyWizardState"
            :widths="['col-lg-12', '100%']">
            <template slot="header">{{ $t('IMPORT_FROM_OTA') }}</template>
            <import-property-wizard
                @importProperty="closeImportWizard"
                @close="closeImportWizard"
                :distribution="importDistribution"
                :entity="importEntity">
            </import-property-wizard>
        </app-aside>
    </b-card>

</template>

<script>
import InvoiceList from "@/components/finance/invoices/InvoiceList";
import InvoiceFilter from "@/components/finance/invoices/InvoiceFilter";
import {getManualInvoiceSetupList} from "@/services/finance/setup";
import {AIRBNB, BOOKING, DEFAULT_PAGINATION_LIMIT, ICL_INVOICE_LIST, LS_PRESETS} from "@/shared/constants";
import AppButton from "@/components/app/AppButton/AppButton";
import {F_INVOICE_CREATE} from "@/shared/function_permission";
import InvoiceForm from "@/components/reservation/accommodation/forms/InvoiceForm";
import AppAside from "@/components/app/form/AppAside";
import {C_PROPERTY_IMPORT_FROM_OTA, R_PROPERTY_NEW} from "@/shared/route_permission";
import {AC_PROPERTY_IMPORT} from "@/mixins/AccessControl/AccessControlEnumeration";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import PropertyWizard from "@/components/property/new/PropertyWizard";
import NewButton from "@/components/app/AppButton/AppButton";
import ImportPropertyWizard from "@/components/property/import/ImportPropertyWizard";
import AppNoData from "@/components/app/AppNoData";

export default {
    name: "Index",
    components: {
        InvoiceForm, AppButton, InvoiceFilter, InvoiceList, AppAside,
        PropertyWizard,
        NewButton, ImportPropertyWizard, AppNoData
    },
    mixins: [AccessControlComponent],
    data() {
        return {
            F_INVOICE_CREATE,
            ICL_INVOICE_LIST,
            setupList: [],
            asidePanelState: false,
            R_PROPERTY_NEW,
            C_PROPERTY_IMPORT_FROM_OTA,
            property_aside: false,
            empty_initial_list: false,
            importPropertyModalState: false,
            importPropertyWizardState: false,
            BOOKING,
            AIRBNB,
            importDistribution: null,
            importEntity: null,
            access_control_components: 1,
            access_control_fetch_key: AC_PROPERTY_IMPORT,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods: {
        reloadData() {
            this.asidePanelState = false
            this.fetchSetup()
        },
        fetchSetup() {
            getManualInvoiceSetupList({creation_location: ICL_INVOICE_LIST}).then(response => {
                this.setupList = response.data
            }).catch(error => {
                // this.showErrorMessages(error)
            })
        },
        importPropertyTermsConfirmed() {
            this.importPropertyModalState = false
            this.importPropertyWizardState = true
        },
        importFromDistribution(distributionId) {
            this.importDistribution = distributionId
            this.importPropertyTermsConfirmed()
        },
        closeImportWizard() {
            this.importPropertyWizardState = false
            this.importDistribution = null
            this.importEntity = null
            this.$router.replace({"query": null})
        },
    },
    watch: {
        "$route.query": {
            handler(queryParams) {
                const queryParamKeys = Object.keys(queryParams)
                if (queryParamKeys.length > 0) {
                    if (queryParamKeys.indexOf("import") >= 0 && Number(queryParams.import) === 1 && queryParamKeys.indexOf("one_time_error") < 0) {
                        const distributionId = queryParamKeys.indexOf("distribution") >= 0 ? Number(queryParams.distribution) : null
                        if (distributionId === AIRBNB) {
                            const hostId = queryParamKeys.indexOf("host") >= 0 ? queryParams.host : null
                            if (hostId) {
                                this.importDistribution = AIRBNB
                                this.importEntity = hostId
                                this.importPropertyWizardState = true
                            }
                        }
                    }
                }
            },
            immediate: true,
        },
    },
    created() {
        this.fetchSetup()
        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)

            if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                if (storagePresets.properties === 0) {
                    this.empty_initial_list = true
                }
            }
        }
        this.access_control_general_context = {
            company: this.company.id
        }
    }
}
</script>

<style scoped>

</style>
