<template>
    <tr>
        <td>
            <app-date-range-picker format="DD.MM" v-model="selectedPeriod.range" :min-date="firstDayCurrentYear" :max-date="endDayCurrentYear"></app-date-range-picker>
        </td>
        <td class="text-right">
            <AppButton :c_permission="!system_sales_period ? C_UNIT_CALENDAR_PERIODS_E : C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_E"
                       button_type="delete"
                       :show_text="false"
                       @click="deletePeriod"
                       variant="link">
            </AppButton>
        </td>
    </tr>
</template>

<script>

    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import PeriodItem from '@/components/unit/bulk_calendar/period/PeriodItemNew'
    import {C_UNIT_CALENDAR_PERIODS_E, C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_E} from "@/shared/component_permission";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {cloneDeep} from 'lodash'
    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
    import moment from 'moment'

    export default {
        name: "period-item",
        props: {
            period: {
                type: Object,
                default: () => {
                    return {
                        id: null,
                        period_start: null,
                        period_end: null,
                        deleted: null
                    }
                }
            },
            system_sales_period: {
                type: Boolean,
                default: false
            }
        },
        components: {
            AppDateRangePicker,
            AppButton,
            PeriodItem
        },
        mixins: [getErrorMessage],
        data() {
            return {
                currentYear: moment().format('Y'),
                selectedPeriod: {
                    id: null,
                    range: {
                        start: null,
                        end: null
                    },
                    deleted: null
                },
                saving: false,
                C_UNIT_CALENDAR_PERIODS_E,
                C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_E
            }
        },
        computed: {
            firstDayCurrentYear() {
                return moment().startOf('year').format('YYYY-MM-DD')
            },
            endDayCurrentYear() {

                return moment().endOf('year').format('YYYY-MM-DD')
            }
        },
        methods: {
            deletePeriod() {
                this.selectedPeriod.deleted = 1
                this.$emit('delPeriod', this.selectedPeriod)
            },
            formatDate(value) {

                if (value) {
                    let currentYear = new Date().getFullYear()
                    value = value + '/' + currentYear
                    let date = new Date(value)
                    return moment(date).format('Y-MM-DD')
                }
            },
            delPeriod(id) {
                this.$emit('delPeriod', id)
            },
            formatDateBulk(value) {
                if (value) {
                    let date = new Date(value)
                    return moment(date).format('MM/DD')
                }
            },
        },
        watch: {
            period: {
                handler(value) {
                    if (value) {
                        this.selectedPeriod = cloneDeep({
                            id: this.period.id,
                            range: {
                                start: new moment(this.currentYear + '/' + this.period.period_start, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                                end: new moment(this.currentYear + '/' + this.period.period_end, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                            },
                            deleted: this.period.deleted
                        })
                    }
                },
                deep: true,
                immediate: true
            }
        }

    }
</script>

<style scoped>

</style>
