<template>
    <div>
        <label>
            {{ title }}
        </label>

        <i :id="'image_info_icon' + _uid" v-if="tooltip" class="fa fa-question-circle-o tooltip-help pull-right mr-3"></i>

        <b-tooltip v-if="tooltip" :target="'image_info_icon' + _uid" triggers="hover" placement="top">
            {{ tooltip }}
        </b-tooltip>

        <div class="d-flex justify-content-between align-items-center" :style="container">
            <div v-if="preview_" style="width:100%; height:200px" class="mt-4 mb-4 ml-3"
                 :style="getImageStyle(preview_)"></div>
            <b-form-file v-else ref="fileInput"
                         :value="image_"
                         @input="$emit('input', $event)"
                         :state="image_ ? true : null"
                         accept="image/*"
                         :placeholder="$t('UPLOAD_TEXT')"
                         :drop-placeholder="$t('DROP')">
            </b-form-file>

            <app-button
                v-if="image_ && enableDelete"
                class="ml-3 mr-2 action_button"
                show_text="false"
                button_type="delete"
                :show_text="false"
                variant="link"
                @click="$emit('input', null)">
            </app-button>
        </div>

        <div class="mt-3" v-if="imageSearch && !image_">
            <app-button ref="imagePickerButton" @click="imagePickerState = !imagePickerState">
                {{ $t('PICK_FROM_GALLERY') }}
            </app-button>

            <div v-show="imagePickerState" ref="imagePicker" class="image-picker">
                <div ref="imagePickerArrow" class="image-picker-arrow"></div>

                <b-form-input
                    ref="imagePickerSearch"
                    v-model="search"
                    @input="searchImages"
                    class="mb-2"
                    :state="stateValidation"
                    :placeholder="`${$t('FILTER_BY_DESCRIPTION')}...`">
                </b-form-input>

                <div class="image-container" ref="imageContainer">
                    <template v-if="loading">
                        <b-spinner class="spinner" variant="primary"></b-spinner>
                    </template>

                    <template v-else v-for="(image, index) in imageList">
                        <div class="image-item" @click="pickImage(image)"
                             :class="{'w-100': (index === (imageList.length-1) && imageList.length % 2 === 1)}">
                            <div class="image-item--image" :style="{backgroundImage: `url(${image.thumbnail})`}"></div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import {fileHelpers} from "@/mixins/shared/helpers";
import {createPopper} from '@popperjs/core';

export default {
    name: "AppImageInput",
    components: {AppButton},
    mixins: [fileHelpers],
    props: {
        image: {
            type: String | Number | File,
            default: null
        },
        preview: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        tooltip: {
            type: String,
            default: null
        },
        enableDelete: {
            type: Boolean,
            default: true
        },
        imageSearch: {
            type: Function,
            default: null
        },
        stateValidation:{
            type: Boolean,
            default: null
        }
    },
    data() {
        return {
            title: this.label || this.$t('IMAGE'),
            image_: null,
            preview_: null,
            imagePickerState: false,
            imageList: [],
            loading: false,
            search: null,
            searchTimeout: null,
        }
    },
    computed: {
        container() {
            if (this.preview_) {
                return {
                    'border': '1px solid #e4e7ea',
                    'border-radius': ' 5px'
                }
            }
        }
    },
    watch: {
        image: {
            immediate: true,
            handler(image) {
                if (image) {
                    if (image instanceof File) {
                        this.prepareImage(image)
                    } else {
                        this.image_ = image
                    }
                } else {
                    this.image_ = null
                    this.preview_ = null
                }
            }
        },
        preview: {
            immediate: true,
            handler(preview) {
                this.preview_ = preview
            }
        },
        imagePickerState(state) {
            if (state) {
                this.initializePopper()
                document.addEventListener("click", this.handlePopperClose)
                if (!this.imageList.length) {
                    this.getImages()
                }
                this.$nextTick(() => {
                    this.$refs.imagePickerSearch.focus()
                })
            } else {
                this.$refs.imageContainer.scrollTop = 0
                document.removeEventListener("click", this.handlePopperClose)
            }
        }
    },
    methods: {
        prepareImage(file) {
            if (!file || !file.type.startsWith('image/')) {
                return this.$nextTick(() => {
                    this.image_ = null
                })
            }

            this.image_ = file
            this.toBase64(file).then(encodedFile => {
                this.preview_ = encodedFile
            })
        },
        getImageStyle(url) {
            return {background: `url(${url}) no-repeat center / contain`}
        },
        getImages(description = null) {
            this.loading = true
            return this.imageSearch(description).then(response => {
                this.imageList = response.data
            }).finally(() => this.loading = false)
        },
        searchImages() {
            if (this.searchTimeout) clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(() => {
                this.getImages(this.search)
            }, 500)
        },
        pickImage(imageObject) {
            this.imagePickerState = false
            this.preview_ = imageObject.thumbnail
            this.$emit('input', imageObject.id)
        },
        handlePopperClose(event) {
            if (this.imagePickerState && !this.$refs.imagePicker.contains(event.target) && !this.$refs.imagePickerButton.$el.contains(event.target)) {
                this.imagePickerState = false
            }
        },
        initializePopper() {
            createPopper(this.$refs.imagePickerButton.$el, this.$refs.imagePicker, {
                placement: "bottom-start",
                offset: 20,
                modifiers: [
                    {
                        name: 'arrow',
                        options: {
                            element: this.$refs.imagePickerArrow
                        }
                    },
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 16]
                        }
                    },
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: ['top', 'right'],
                        },
                    },
                ]
            })
        }
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handlePopperClose)
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";

.image-picker {
    position: relative;
    z-index: 1019;
    width: 400px;
    height: 461px;
    background-color: white;
    border: 1px solid $theme_border_color_light;
    border-radius: 5px;
    padding: 0.5em;
    -webkit-box-shadow: 7px 7px 13px 0 rgba(50, 50, 50, 0.2);
    -moz-box-shadow: 7px 7px 13px 0 rgba(50, 50, 50, 0.2);
    box-shadow: 7px 7px 13px 0 rgba(50, 50, 50, 0.2);

    .image-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        height: 400px;
        overflow-y: auto;
        border-radius: 5px;
        justify-content: center;

        &::-webkit-scrollbar {
            height: 1rem;
            width: 0.35rem;
        }

        &::-webkit-scrollbar-track {
            background-color: $theme_border_color_light;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: $theme_action_color_tinted;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border: none;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: $theme_action_color;
        }

        .spinner {
            display: flex;
            align-self: center;
        }

        .image-item {
            display: block;
            height: 200px;
            width: 50%;
            cursor: pointer;
            overflow: hidden;

            &--image {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                transition: all 0.3s;

                &:hover {
                    transform: scale(1.2);
                }
            }
        }
    }

    .image-picker-arrow, .image-picker-arrow:before {
        position: absolute;
        width: 15px;
        height: 15px;
        background: inherit;
        border: 1px solid $theme_border_color_light;
        z-index: -1;
    }

    .image-picker-arrow {
        visibility: hidden;
    }

    .image-picker-arrow:before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
    }

    &[data-popper-placement^='top'] > .image-picker-arrow {
        bottom: -7px;

        &:before {
            border-top: none;
            border-left: none;
        }
    }

    &[data-popper-placement^='bottom'] > .image-picker-arrow {
        top: -10px;

        &:before {
            border-right: none;
            border-bottom: none;
        }
    }

    &[data-popper-placement^='left'] > .image-picker-arrow {
        right: -8px;

        &:before {
            border-bottom: none;
            border-left: none;
        }
    }

    &[data-popper-placement^='right'] > .image-picker-arrow {
        left: -8px;

        &:before {
            border-top: none;
            border-right: none;
        }
    }
}
</style>
