<template>
    <form class="filter" @submit.prevent="search(false)" @reset.prevent="resetForm">
        <b-row>
            <b-col md="2" class="mb-3">
                <label>{{ $t('TYPE') }}</label>
                <app-select value-field="key" :options="type_options" text-field="label"
                            v-model="filter.type"></app-select>
            </b-col>
            <b-col md="2" class="mb-3" v-if="filter.type === 'minimum_stay'">
                <label>{{ $t('DISTRIBUTION') }}</label>
                <app-select value-field="id" :options="distribution_options" text-field="name"
                            v-model="filter.distribution"></app-select>
            </b-col>
            <b-col lg="2" class="mb-3" >
                <label>{{ $t('PROPERTY') }}</label>

                <app-select
                    v-if="revenueManagerLog"
                    mode="multiselect" :options="propertyList" set-first
                    v-model="property"></app-select>
                <app-select v-else-if="property_count  <= DEFAULT_PAGINATION_LIMIT" :disabled="propertyList.length < 2" v-model="property" :options="propertyList"></app-select>
                <app-select v-else mode="search" :search="searchProperty" v-model="property"></app-select>

            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{ $t('UNIT') }}</label>

                <app-select mode="multiselect" :disabled="options_unit.length < 2" :options="options_unit"
                            text-field="name" v-model="filter.unit"></app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{ $t('PERIOD') }}</label>
                <app-date-range-picker :max-date-diff="90" v-model="period"></app-date-range-picker>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{ $t('LAST_CHANGE') }}</label>
                <app-date-range-picker :max-date-diff="90" v-model="create_interval"></app-date-range-picker>

            </b-col>

            <b-col md="2" class="mb-3">

                <b-form-checkbox v-model="filter.own_changes" style="margin-top: 1.8rem">
                    {{ $t('SHOW_ONLY_MY_CHANGES') }}
                </b-form-checkbox>
            </b-col>
            <b-col md="3" class="d-flex align-items-baseline">
                <app-button-submit :disabled="disabledSearch" :loading="loading" class="filter-submit-wrapper"
                                   :inline="true" variant="primary" button_type="search">
                    {{ $t("SEARCH") }}
                </app-button-submit>
                <app-button-reset :disabled="disabledSearch || loading" :inline="true"
                                  class="pl-2 ml-2 filter-submit-wrapper"></app-button-reset>
            </b-col>

        </b-row>
    </form>
</template>

<script>
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import {DEFAULT_PAGINATION_LIMIT, LS_PRESETS} from "@/shared/constants";
import {getPresets} from "@/services/user";
import {getPropertyList} from "@/services/property";
import {getUnitList} from "@/services/unit";
import {pickBy} from "lodash";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import {getDistributionList} from "@/services/distribution";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "LogFilter",
    components: {AppNumberInput, AppDateRangePicker, AppDatePicker, AppSelect, AppButtonReset, AppButtonSubmit},
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        revenueManagerLog: {
            type: Boolean,
            default: false
        },
        property_count:{
            type:Number,
        }
    },
    data() {
        return {
            DEFAULT_PAGINATION_LIMIT,
            create_interval:{
                start:null,
                end:null
            },
            period: {
                start: null,
                end: null
            },
            filter: {
                unit: null,
                user: null,
                type: null,
                distribution: null,
                own_changes: null
            },
            property: null,
            type_options: [
                {key: 'rates', label: this.$t('RATES')},
                {key: 'minimum_stay', label: this.$t('MIN_STAY')},
            ],
            propertyList: [],
            options_unit: [],
            distribution_options: [],
            preloadPropertyList: false,
            show_only_table:false,
            unit_ids:[]
        }
    },
    methods: {
        searchProperty(value) {

            return getPropertyList({name: value, company_id: this.company.id}).then(response => {
                return {data: response.data.items}
            })
        },
        search(revenue_manager_log) {

            this.reset = 0
            let request = this.filter
            if (revenue_manager_log) {
                this.$emit('search', {revenue_manager_log: true})
            } else {

                this.$router.replace({'query': null});
                if (typeof this.property === 'object') {
                   request = {...request, ...{property: this.property.id}}
                } else {
                    request = {...request, ...{property: this.property}}
                }
                if(this.period.start !== null){
                    const period = {
                        from:this.period.start,
                        to:this.period.end
                    }
                    request = {...request,...{period}}
                }
                if(this.create_interval.start !== null){
                    const create_interval = {
                        from:this.create_interval.start,
                        to:this.create_interval.end,
                    }
                    request = {...request,...{create_interval}}
                }

                this.$emit('search', pickBy(request, item => item !== null))
            }

        },
        resetForm() {
            this.reset = 1
            this.property = null
            this.filter.type = null
            this.filter.unit = null
            this.period = {
                start: null,
                end: null
            }
            this.filter.user = null
            this.create_interval.start = null
            this.create_interval.end = null
            this.$emit('search', this.filter)
        },
        setUnitList(value) {
            this.options_unit = []
            let property_id = null
            if (Array.isArray(value) && value.length > 0 || typeof  value === 'number') {
                property_id = value
            } else {
                property_id = value && value.hasOwnProperty('id') ? value.id : null
            }
            if (property_id !== null) {
                getUnitList({contigent: 0, property_id: property_id}).then(response => {
                    if(response.data.items.length === 1){
                        if(this.property_count === 1){
                            EventBus.$emit('re_rm_log_show_only_table',true)
                        }

                        this.filter.unit = [response.data.items[0].id]
                    } else {
                        this.filter.unit = []
                    }
                    if(this.revenueManagerLog){
                        this.filter.unit = this.unit_ids
                    }
                    this.options_unit = response.data.items
                })
            }

        },
        setPropertyList(){
            getPropertyList({company_id: this.company.id, limit:500}).then(response => {
                this.$nextTick(() => {
                    const data = response.data && response.data.hasOwnProperty('items') ? response.data.items : []
                    if (data.length === 1) {
                        this.property = data[0].id
                        this.setUnitList([this.property])
                    }
                    this.propertyList = data.map(el => {
                        return {id: el.id, name: el.name}
                    })
                })
            })
        }
    },
    computed: {
        disabledSearch() {
            return this.filter.type === null || this.property === null || this.filter.unit === null ||  ((this.period.start === null || this.period.end === null) && (this.create_interval.start === null || this.create_interval.end === null))
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    mounted() {


        EventBus.$on('ge_revenue_log_set_filter', filterData => {
            if (filterData.hasOwnProperty('unit') && Array.isArray(filterData.unit) && filterData.unit.length > 0) {
                this.unit_ids = filterData.unit.map(el => {
                    return el.id
                })
                this.options_unit = []

                if (filterData.unit.length > 0) {

                    if (filterData.unit.length === 1) {
                        if (this.property_count === 1) {
                            EventBus.$emit('re_rm_log_show_only_table', true)
                        }
                    }
                    this.options_unit = filterData.unit
                    if (this.revenueManagerLog) {
                        this.filter.unit = this.unit_ids
                    }
                }
            }
            if (filterData.hasOwnProperty('type')) {
                this.filter.type = filterData.type
            }
            if (filterData.hasOwnProperty('create_interval') && typeof filterData.create_interval === 'object' && Object.keys(filterData.create_interval).length > 0){
                if(filterData.create_interval.hasOwnProperty('from')){
                    this.create_interval.start = filterData.create_interval.from
                }
                if(filterData.create_interval.hasOwnProperty('to')){
                    this.create_interval.end = filterData.create_interval.to
                }
            }
            if (filterData.hasOwnProperty('period') && typeof filterData.period === 'object' && Object.keys(filterData.period).length > 0){
                if(filterData.period.hasOwnProperty('from')){
                    this.period.start = filterData.period.from
                }
                if(filterData.period.hasOwnProperty('to')){
                    this.period.end = filterData.period.end
                }
            }
            if (filterData.hasOwnProperty('property') &&  Array.isArray(filterData.property) && filterData.property.length > 0){

                this.property = filterData.property.map(el=>{
                            return el.id
                })

            }

        })
    },
    beforeDestroy() {
        EventBus.$off('ge_revenue_log_set_filter')
    },
    watch: {
        revenueManagerLog: {
            handler(value) {
                if (value) {
                    this.setPropertyList()
                    this.search(true)
                }
            }, immediate: true
        },
        property_count:{
          handler(value){
              if(value <= DEFAULT_PAGINATION_LIMIT){
                this.setPropertyList()
              }
          } ,immediate:true
        },
        property: {
            handler(value) {
                if(!this.revenueManagerLog){
                    this.setUnitList(value)
                }

            }
        },
        'filter.type': {
            handler(value) {
                if (value === 'minimum_stay') {
                    getDistributionList({company: this.company.id, for_reservation: 1, sorted: 1}).then(res => {
                        this.distribution_options = res.data
                    })

                }
            }
        },
    }
}
</script>

<style scoped>

</style>
