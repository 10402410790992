<template>
    <multi-select :disabled="disabled" class="multiAddonClass" :value="value" label="name" :multiple="false" :options="options" :loading="loading" :searchable="true"
                  track-by="id"
                  :placeholder="$t('SEARCH')"
                  :selectLabel="$t('APPLY')"
                  :deselectLabel="$t('CLEAR')"
                  @search-change="onSearch"
                  @input="onSelect">
        <span slot="noResult">{{$t("NO_DATA")}}</span>
        <span slot="noOptions">{{$t("NO_DATA")}}</span>
    </multi-select>
</template>

<script>
    import MultiSelect from 'vue-multiselect'
    import _debounce from 'lodash/debounce'
    import {getCityList} from '@/services/location/index'

    export default {
        name: "CitySelect",
        props: {
            value: {
                default: null
            },
            filter_country: {
                default: null
            },
            disabled: {
                default: false
            }
        },
        components: {
            MultiSelect
        },
        data() {
            return {
                options: [],
                loading: false
            }
        },
        methods: {
            onSearch: _debounce(function (search) {
                if (search === "") {
                    return
                }

                this.loading = true
                getCityList({
                    name: search,
                    country: this.filter_country.id ? this.filter_country.id : null
                }).then(response => {
                    this.options = response.data
                    this.loading = false
                })
            }, 500),
            onSelect(val) {
                if(val === null){
                    this.$emit("input", {id: null, name: null})
                } else {
                    this.$emit("input", val)
                }
            }
        },
        watch: {
            filter_country(){
                this.options = []
            }
        },
        created(){
            if(this.value !== null){
                this.options.push(this.value)
            }
        }
    }
</script>

<style>
    .multiAddonClass {
        min-height: 20px;
    }
    .multiAddonClass. .multiselect__single{
        min-height: 15px;
        line-height: 15px;
    }
    .multiAddonClass. > .multiselect__tags, .multiselect__current{
        min-height: 20px;
    }
</style>
