<template>
    <div>
        <b-form @submit.prevent="onFormSubmit" autocomplete="off">
            <b-row>
                <b-col class="mb-4">
                    <label>{{ $t("UNITS") }}</label>
                    <app-select mode="multiselect"
                                v-model="selectedUnits"
                                :options="unitList">
                    </app-select>
                </b-col>
            </b-row>
            <template v-if="status === 'update'" >
                <b-row>
                    <b-col lg="8">
                        <b-row>
                            <b-col>
                                <form-header>
                                    {{ $t("RATES") }}
                                    <b-checkbox
                                        v-model="copyRates"
                                        switch
                                        inline
                                        class="ml-2"
                                        :unchecked-value="false"
                                        :value="true">
                                    </b-checkbox>
                                </form-header>
                            </b-col>
                        </b-row>
                        <b-row v-if="copyRates">
                            <b-col>
                                <div>
                                    <label>{{ $t("SELECT_YEARS_TO_APPLY_CHANGES") }}</label>
                                </div>
                                <b-form-checkbox
                                    inline
                                    id="select_all_rates_year"
                                    name="select_all_rates_year"
                                    @change="toggleRatesYears"
                                    :checked="allRatesYearsSelected()"
                                    :value="true"
                                    :unchecked-value="false">
                                    {{ $t("SELECT_ALL") }}
                                </b-form-checkbox>
                                <b-form-checkbox-group
                                    id="rates-year-checkbox"
                                    class="ml-4"
                                    stacked
                                    value-field="id"
                                    text-field="name"
                                    v-model="selectedRatesYears"
                                    :options="years"
                                ></b-form-checkbox-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="8">
                        <b-row>
                            <b-col>
                                <form-header>
                                    {{ $t("RESTRICTIONS") }}
                                    <b-checkbox
                                        v-model="copyRestrictions"
                                        switch
                                        inline
                                        class="ml-2"
                                        :unchecked-value="false"
                                        :value="true">
                                    </b-checkbox>
                                </form-header>
                            </b-col>
                        </b-row>
                        <b-row  v-if="copyRestrictions">
                            <b-col class="mb-4">
                                <div>
                                    <label>{{ $t("SELECT_YEARS_TO_APPLY_CHANGES") }}</label>
                                </div>
                                <b-form-checkbox
                                    inline
                                    id="select_all_restriction_year"
                                    name="select_all_rates_year"
                                    @change="toggleRestrictionsYears"
                                    :checked="allRestrictionsYearsSelected()"
                                    :value="true"
                                    :unchecked-value="false">
                                     {{ $t("SELECT_ALL") }}
                                </b-form-checkbox>
                                <b-form-checkbox-group
                                    id="restrictions-year-checkbox"
                                    class="ml-4"
                                    stacked
                                    value-field="id"
                                    text-field="name"
                                    v-model="selectedRestrictionsYears"
                                    :options="years"
                                ></b-form-checkbox-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </template>
            <template v-else>
                <b-row>
                    <b-col class="mb-4">
                        <div>
                            <label>{{ $t("SELECT_YEARS_TO_APPLY_CHANGES") }}</label>
                        </div>
                        <b-form-checkbox
                            inline
                            id="select_all_year"
                            name="select_all_year"
                            aria-describedby="years"
                            aria-controls="years"
                            @change="toggleYears"
                            :checked="allYearsSelected()"
                            :value="true"
                            :unchecked-value="false">
                            {{ $t("SELECT_ALL") }}
                        </b-form-checkbox>
                        <b-form-checkbox-group
                            id="year-checkbox"
                            class="ml-4"
                            stacked
                            value-field="id"
                            text-field="name"
                            v-model="selectedYears"
                            :options="years"
                            name="years"
                        ></b-form-checkbox-group>
                    </b-col>
                </b-row>
            </template>
            <b-row>
                <b-col>
                    <app-button-submit
                        :loading="loading"
                        :disabled="selectedUnits.length === 0">
                        {{ $t("SAVE") }}
                    </app-button-submit>
                </b-col>
            </b-row>
        </b-form>
        <app-confirmation-dialog :title="$t('SAVE_CONFIRMATION')"
                                 :show="displaySaveConfirmation"
                                 @confirm="onConfirm"
                                 @cancel="displaySaveConfirmation = false">
            {{ $t("BULK_CALENDAR_SAVE_MESSAGE") }}
        </app-confirmation-dialog>
    </div>
</template>

<script>
import {getUnitList} from "@/services/unit";
import moment from 'moment';
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {copyBulkCalendar} from "@/services/property";
import FormHeader from "@/components/app/form/FormHeader";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";

export default {
    name: "BulkCalendarCopyForm",
    components: {AppConfirmationDialog, FormHeader, AppButtonSubmit, AppSelect},
    mixins: [getErrorMessage],
    props: {
        unitId: {
            type: Number,
        },
        propertyId: {
            type: Number,
        },
        status: {
            type: String,
            default: "update",
            validator: value => {
                return (["acquisition", "update"]).indexOf(value) > -1
            },
        },
    },
    data() {
        return {
            loading: false,
            displaySaveConfirmation: false,
            unitList: [],
            selectedUnits: [],
            selectedYears: [],
            selectedRatesYears: [],
            selectedRestrictionsYears: [],
            copyRates: false,
            copyRestrictions: false,
        }
    },
    computed: {
        years() {
            let years = []

            years.push({
                id: (Number(new moment().format('YYYY'))).toString(),
                name: Number(new moment().format('YYYY'))
            })

            for (let i = 0; i < 2; i++) {
                years.push({
                    id: (Number(new moment().format('YYYY')) + 1 + i).toString(),
                    name: Number(new moment().format('YYYY')) + 1 + i
                })
            }

            return years
        },
    },
    methods: {
        onFormSubmit() {
            /*this.displaySaveConfirmation = true
            return*/

            this.loading = true

            const request = this.prepareRequest()

            copyBulkCalendar(this.propertyId, request).then(() => {
                notifySuccess()
                this.$emit("saved")
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
            })
        },
        onConfirm() {
            const request = this.prepareRequest()

            copyBulkCalendar(this.propertyId, request).then(() => {
                notifySuccess()
                this.$emit("saved")
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.displaySaveConfirmation = false
            })
        },
        prepareRequest() {
            let request = {
                source_unit: this.unitId,
                destination_units: this.selectedUnits,
            }

            if (this.status === "update") {
                let options = []
                if (this.copyRates) {
                    options.push({
                        option: "rate",
                        destination_years: this.selectedRatesYears,
                    })
                }
                if (this.copyRestrictions) {
                    options.push({
                        option: "restriction",
                        destination_years: this.selectedRestrictionsYears,
                    })
                }
                request.options = options
            } else {
                request.destination_years = this.selectedYears
            }

            return request
        },
        getUnitList() {
            let request = {
                property_id: this.propertyId,
                contigent: 0,
                limit: 999,
                excluding_id: this.unitId,
            }
            getUnitList(request).then(response => {
                this.unitList = response.data.items
            }, () => {
                this.unitList = []
            })
        },
        toggleYears(event) {
            this.selectedYears.splice(0)
            if (event) {
                this.years.forEach(year => {
                    this.selectedYears.push(year.id)
                })
            }
        },
        allYearsSelected() {
            return this.selectedYears.length === this.years.length
        },
        toggleRatesYears(event) {
            this.selectedRatesYears.splice(0)
            if (event) {
                this.years.forEach(year => {
                    this.selectedRatesYears.push(year.id)
                })
            }
        },
        allRatesYearsSelected() {
            return this.selectedRatesYears.length === this.years.length
        },
        toggleRestrictionsYears(event) {
            this.selectedRestrictionsYears.splice(0)
            if (event) {
                this.years.forEach(year => {
                    this.selectedRestrictionsYears.push(year.id)
                })
            }
        },
        allRestrictionsYearsSelected() {
            return this.selectedRestrictionsYears.length === this.years.length
        },
    },
    created() {
        this.getUnitList()
    },
}
</script>

<style scoped>

</style>
