<template>
    <company-feature-setup-form
        :company="company"
        @update="updateFormData">
    </company-feature-setup-form>
</template>

<script>
import CompanyFeatureSetupForm from "@/components/company/CompanyFeatureSetupForm";

export default {
    name: "Index",
    components: {CompanyFeatureSetupForm},
    props: {
        company: {
            type: Object
        }
    },
    methods:{
        updateFormData(data){
            this.$emit('update', data)
        }
    }
}
</script>

<style scoped>

</style>
