<template>
    <div v-if="formDataLoaded" v-show="formLoaded">
        <b-row class="mt-4" v-if="existingLegalEntityId === null">
            <b-col>
                <app-access-control
                    :access_control_context="{function: CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_HAS_LEGAL_ENTITY_ID, key: access_control_fetch_key}"
                    @AccessControlEvent="addToAccessControlCounter()" @updated="formLoaded = true">
                    <form>
                        <div class="header-2 mb-3 text-center">
                            {{ $t('EXISTING_LEGAL_ENTITY_ID') }} ?
                        </div>
                        <b-form-radio-group
                            v-model="existingLegalEntityId"
                            :options="existingLegalEntityIdOptions"
                            id="radios-object_exist"
                            name="radios-object_exist"
                            stacked
                            class="main_calendar-custom-radio text-center">
                        </b-form-radio-group>
                    </form>
                </app-access-control>
            </b-col>
        </b-row>
        <form autocomplete="off">
            <template v-if="existingLegalEntityId !== null && existingLegalEntityId">
                <b-row>
                    <b-col>
                        <app-input-control :error-object="validationObject.existing_legal_entity_id"
                                           :listening-for-event="existingLegalEntityId !== null && existingLegalEntityId">
                            <template v-slot:input="data">
                                <label>{{ $t('LEGAL_ENTITY_ID') }}</label>
                                <b-input :state="data.state"
                                         v-model="formData.legal_entity.value.legal_entity_id"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col>
<!--                        <b-tooltip target="email-exists"
                                   placement="top"
                                   triggers="hover">
                            {{ $t('LEGAL_ENTITY_EMAIL_MSG') }}
                        </b-tooltip>-->
                        <app-input-control :error-object="validationObject.existing_legal_contact_email"
                                           :listening-for-event="existingLegalEntityId !== null && existingLegalEntityId">
                            <template v-slot:input="data">
                                <label>{{ $t('LEGAL_CONTACT_EMAIL') }}</label>
<!--                                <div class="float-right">
                                    <i
                                        class="fa fa-question-circle-o action_button tooltip-help mr-3"
                                        style="cursor:pointer" id="email-exists">
                                    </i>
                                </div>-->
                                <b-input :state="data.state" type="email"
                                         v-model="formData.legal_entity.value.legal_contact_email"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col>
                        <app-button @click="saveExisting" :loading="existingLoading">{{ $t('SAVE') }}</app-button>
                    </b-col>
                </b-row>
            </template>
            <template v-else-if="existingLegalEntityId !== null && !existingLegalEntityId">
                <b-row v-if="contractNotSigned">
                    <b-col cols="12">
                        <form-header header-class="p-0 mb-4">
                            <b-badge variant="danger">{{ $t('CONTRACT_NOT_SIGNED') }}
                            </b-badge>
                        </form-header>
                    </b-col>
                </b-row>
                <b-row>
                    <app-access-control
                        :access_control_context="{function: CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_LEGAL_CONTACT_NAME, key: access_control_fetch_key}"
                        lg="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                        @updated="formLoaded = true">
                        <template v-slot:default="props">
                            <app-input-control :error-object="validationObject.legal_contact_name"
                                               :listening-for-event="existingLegalEntityId !== null && !existingLegalEntityId">
                                <template v-slot:input="data">
                                    <label>{{ $t('LEGAL_CONTACT_NAME') }}</label>
                                    <b-input v-model="formData.legal_entity.value.legal_contact_name"
                                             :state="data.state" :disabled="props.disabled"></b-input>
                                </template>
                            </app-input-control>
                        </template>
                    </app-access-control>
                    <app-access-control
                        :access_control_context="{function: CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_LEGAL_CONTACT_EMAIL, key: access_control_fetch_key}"
                        lg="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()">
                        <template v-slot:default="props">
                            <app-input-control :error-object="validationObject.legal_contact_email"
                                               :listening-for-event="existingLegalEntityId !== null && !existingLegalEntityId">
                                <template v-slot:input="data">
                                    <label>{{ $t('LEGAL_CONTACT_EMAIL') }}</label>
                                    <b-input v-model="formData.legal_entity.value.legal_contact_email"
                                             :state="data.state" :disabled="props.disabled"></b-input>
                                </template>
                            </app-input-control>
                        </template>
                    </app-access-control>
                    <app-access-control
                        :access_control_context="{function: CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_LEGAL_NAME, key: access_control_fetch_key}"
                        lg="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()">
                        <template v-slot:default="props">
                            <label>{{ $t('LEGAL_NAME') }}</label>
                            <b-input v-model="formData.legal_entity.value.legal_name"
                                     :disabled="props.disabled"></b-input>
                        </template>
                    </app-access-control>
                    <app-access-control
                        :access_control_context="{function: CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_PREFERRED_LANGUAGE, key: access_control_fetch_key}"
                        lg="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()">
                        <template v-slot:default="props">
                            <app-input-control :error-object="validationObject.preferred_language"
                                               :listening-for-event="existingLegalEntityId !== null && !existingLegalEntityId">
                                <template v-slot:input="data">
                                    <label>{{ $t('PREFERRED_LANGUAGE') }}</label>
                                    <app-select v-model="formData.legal_entity.value.preferred_language"
                                                :options="supportedLanguages"
                                                text-field="label"
                                                :search-empty-item="false"
                                                :state="data.state"
                                                :disabled="props.disabled"
                                    ></app-select>
                                </template>
                            </app-input-control>
                        </template>
                    </app-access-control>
                    <app-access-control
                        :access_control_context="{function: CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_WEBSITE, key: access_control_fetch_key}"
                        lg="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()">
                        <template v-slot:default="props">
                            <label>{{ $t('WEBSITE') }}</label>
                            <b-input v-model="formData.legal_entity.value.website" :disabled="props.disabled"></b-input>
                        </template>
                    </app-access-control>
                </b-row>
                <b-row>
                    <app-access-control
                        :access_control_context="{function: CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_RESEND_PARTNER_INVITE, key: access_control_fetch_key}"
                        @AccessControlEvent="addToAccessControlCounter()">
                        <app-button :loading="resendLoading" @click="resend">{{ $t('RESEND_REGISTRATION_LINK') }}
                        </app-button>
                    </app-access-control>
                </b-row>
                <b-row>
                    <app-access-control
                        :access_control_context="{function: CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_INVITE_PARTNER, key: access_control_fetch_key}"
                        @AccessControlEvent="addToAccessControlCounter()">
                        <app-button :loading="inviteLoading" @click="invite">{{ $t('REGISTER') }}</app-button>
                    </app-access-control>
                </b-row>
            </template>
        </form>
    </div>
</template>

<script>

import AppButton from "@/components/app/AppButton/AppButton";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppAccessControl from "@/components/app/AppAccessControl";
import AppInputControl from "@/components/app/AppInputControl";
import {
    resendBookingInvite,
    sendBookingInvite,
} from "@/services/channel_manager";
import {
    SETUP_PARAMETER_LEGAL_ENTITY,
} from "@/shared/constants";
import {
    AC_CHANNEL_MANAGER_SETUP_PARAMETER,
    AC_CHANNEL_MANAGER_SETUP_PARAMETER_EDIT
} from "@/mixins/AccessControl/AccessControlEnumeration";
import {
    CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_LEGAL_CONTACT_EMAIL,
    CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_LEGAL_CONTACT_NAME,
    CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_LEGAL_NAME,
    CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_PREFERRED_LANGUAGE,
    CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_WEBSITE,
    CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_INVITE_PARTNER,
    CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_RESEND_PARTNER_INVITE,
    CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_HAS_LEGAL_ENTITY_ID
} from "@/shared/component_permission";
import {
    CM_LEGAL_ENTITY_LEGAL_CONTACT_EMAIL_VALIDATION_FAILED,
    CM_LEGAL_ENTITY_LEGAL_CONTACT_NAME_VALIDATION_FAILED,
    CM_LEGAL_ENTITY_PREFERRED_LANGUAGE_VALIDATION_FAILED,
    CM_LEGAL_ENTITY_ID_VALIDATION_FAILED
} from "@/shared/error_codes";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {getSystemLanguageList} from "@/services/system";
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import {cloneDeep, omit as _omit} from "lodash";
import FormHeader from "@/components/app/form/FormHeader";

const SETUP_PARAMETER_LIST = {
    [SETUP_PARAMETER_LEGAL_ENTITY]: 'legal_entity',
};

export default {
    name: "BookingGeneralSetupForm",
    mixins: [AccessControlComponent, getErrorMessage],
    props: {
        distribution: {
            type: Number,
            default: null
        },
        setupParameters: {
            type: Array,
            default() {
                return []
            },
        },
        setupSaving: {
            type: Boolean,
            default: false,
        },
        closeOnSignedContract: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        FormHeader,
        AppButton,
        AppAccessControl,
        AppSelect,
        AppInputControl
    },
    data() {
        return {
            existingLegalEntityId: null,
            existingLegalEntityIdOptions: [
                {value: true, text: this.$t('YES')},
                {value: false, text: this.$t('NO')}
            ],
            formData: {
                legal_entity: {},
            },
            initialFormData: null,
            formDataLoaded: false,
            formLoaded: false,
            existingLoading: false,
            resendLoading: false,
            inviteLoading: false,
            supportedLanguages: [],
            access_control_fetch_key: AC_CHANNEL_MANAGER_SETUP_PARAMETER_EDIT,
            AC_CHANNEL_MANAGER_SETUP_PARAMETER,
            CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_LEGAL_CONTACT_EMAIL,
            CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_LEGAL_CONTACT_NAME,
            CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_LEGAL_NAME,
            CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_PREFERRED_LANGUAGE,
            CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_WEBSITE,
            CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_INVITE_PARTNER,
            CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_RESEND_PARTNER_INVITE,
            CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_HAS_LEGAL_ENTITY_ID,
            validationObject: {
                legal_contact_name: CM_LEGAL_ENTITY_LEGAL_CONTACT_NAME_VALIDATION_FAILED,
                legal_contact_email: CM_LEGAL_ENTITY_LEGAL_CONTACT_EMAIL_VALIDATION_FAILED,
                preferred_language: CM_LEGAL_ENTITY_PREFERRED_LANGUAGE_VALIDATION_FAILED,
                existing_legal_entity_id: CM_LEGAL_ENTITY_ID_VALIDATION_FAILED,
                existing_legal_contact_email: CM_LEGAL_ENTITY_LEGAL_CONTACT_EMAIL_VALIDATION_FAILED,
            },
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        contractNotSigned() {
            return this.formData.legal_entity.hasOwnProperty('value')
                && this.formData.legal_entity.value.legal_entity_id !== null
                && (this.formData.legal_entity.value.contract_signed === null
                    || this.formData.legal_entity.value.contract_signed === false
                    || this.formData.legal_entity.value.contract_signed === 0)
        }
    },
    watch: {
        setupParameters: {
            handler() {
                this.setSetupParameterValues()
            },
            immediate: true,
            deep: true,
        },
        setupSaving: {
            handler(value) {
                this.existingLoading = value
            },
            immediate: true,
        },
        formDataLoaded: {
            handler(value) {
                if (value) {
                    this.initialFormData = cloneDeep(this.formData)
                    if (this.closeOnSignedContract && this.formData.legal_entity.hasOwnProperty('value') && this.formData.legal_entity.value.hasOwnProperty('contract_signed')
                        && (this.formData.legal_entity.value.contract_signed === true || this.formData.legal_entity.value.contract_signed === 1)) {
                        this.$emit('close', {reload: true})
                    }
                }
            },
            immediate: true,
        },
        initialFormData: {
            handler(formData) {
                if (formData !== null && typeof formData === 'object' && formData.hasOwnProperty('legal_entity') && formData.legal_entity.hasOwnProperty('value')) {
                    let legalEntity = formData.legal_entity.value
                    if (legalEntity !== null && legalEntity.hasOwnProperty('legal_entity_id') && legalEntity.legal_entity_id !== null) {
                        this.existingLegalEntityId = false
                    }
                }
            },
            immediate: true,
        },
        existingLegalEntityId: {
            handler(value) {
                this.formLoaded = false
                this.access_control_components = value === null ? 1 : value === false ? 7 : 0
                if (this.access_control_components === 0) {
                    this.formLoaded = true
                }
            },
            immediate: true,
        },
    },
    methods: {
        saveExisting() {
            this.existingLoading = true
            let setupParameterList = [];
            for (const [key, setupObject] of Object.entries(this.formData)) {
                if (setupObject.parameter_id !== null) {
                    let setupParameterRequestItem = {
                        'parameter_id': setupObject.parameter_id,
                        'entity': setupObject.hasOwnProperty('entity') && setupObject.entity !== null ? setupObject.entity : null,
                        'value': setupObject.value,
                    }
                    setupParameterList.push(setupParameterRequestItem)
                }
            }

            this.$emit('saveSetup', setupParameterList)
        },
        resend() {
            this.resendLoading = true
            resendBookingInvite({legal_contact_email: this.formData.legal_entity.value.legal_contact_email}).then(() => {
                notifySuccess()
                this.$emit('resendInvite')
            }, error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.resendLoading = false
            })
        },
        invite() {
            this.inviteLoading = true
            let data = {
                company: this.company.id,
                ..._omit(this.formData.legal_entity.value, ['legal_entity_id', 'contract_signed']),
            }
            sendBookingInvite(data).then(() => {
                toast({
                    'message': this.$t('NOTIFICATIONS.BOOKING_PARTNER_INVITATION_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 6000,
                })
                this.$emit('invite')
            }, error => {
                this.showErrorMessages(error, this.validationObject)
            }).finally(() => {
                this.inviteLoading = false
            })
        },
        setSetupParameterValues() {
            this.formDataLoaded = false;
            this.setupParameters.forEach(el => {
                if (el.hasOwnProperty('parameter_id') &&
                    Object.keys(SETUP_PARAMETER_LIST).indexOf(String(el.parameter_id)) > -1 &&
                    el.hasOwnProperty('values')) {
                    this.$set(this.formData[SETUP_PARAMETER_LIST[el.parameter_id]], 'parameter_id', el.parameter_id)
                    this.$set(this.formData[SETUP_PARAMETER_LIST[el.parameter_id]], 'label', el.label)
                    this.$set(this.formData[SETUP_PARAMETER_LIST[el.parameter_id]], 'options', el.options)
                    if (el.values !== null && typeof el.values === 'object') {
                        for (const [entity, values_data] of Object.entries(el.values)) {
                            this.$set(this.formData[SETUP_PARAMETER_LIST[el.parameter_id]], 'entity', entity)
                            values_data.forEach(value_data => {
                                if (value_data.hasOwnProperty('value')) {
                                    this.$set(this.formData[SETUP_PARAMETER_LIST[el.parameter_id]], 'value', value_data.value)
                                    if (el.parameter_id === SETUP_PARAMETER_LEGAL_ENTITY && value_data.value.preferred_language === null && value_data.value.legal_entity_id === null) {
                                        this.$set(this.formData[SETUP_PARAMETER_LIST[el.parameter_id]].value, 'preferred_language', this.company.base_language)
                                    }
                                }
                            })
                        }
                    } else {
                        this.$set(this.formData[SETUP_PARAMETER_LIST[el.parameter_id]], 'value', null)
                    }
                }
            })

            this.formDataLoaded = true
        },
    },
    created() {
        this.access_control_general_context = {
            distribution: this.distribution,
            company: this.company.id,
        }
        getSystemLanguageList().then(response => {
            response.data.forEach(el => {
                this.supportedLanguages.push({id: el.name, label: el.name})
            })
        })
    },
}
</script>

<style scoped>

</style>
