<template>
    <website-general-form></website-general-form>
</template>
<script>
import WebsiteGeneralForm from "@/components/direct_booking/website/basic/WebsiteGeneralForm";
export default {
    name: "Index",
    components: {WebsiteGeneralForm}
}
</script>

<style scoped>

</style>
