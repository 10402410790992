import http from '../http'

import {
    GUEST_URL,
    GUEST_ACCESS_DATA_URL,
    GUEST_FORM_DATA_URL, ACCOMMODATION_RESERVATION_OLD, DOCUMENT_URL
} from '@/services/endpoints'

export function getGuestList(params) {
    return http.request({
        url: GUEST_URL + '-list',
        method: 'get',
        params
    })
}

export function getGuestFormData(params) {
    return http.request({
        url: GUEST_FORM_DATA_URL,
        method: 'get',
        params: params
    })
}

export function getGuestById(id) {
    return http.request({
        url: GUEST_URL + '/' + id,
        method: 'get',
    })
}

export function createGuest(postData) {
    return http.request({
        url: GUEST_URL,
        method: 'post',
        data: postData
    })
}

export function updateGuest(id, postData) {

    return http.request({
        url: GUEST_URL + '/' + id,
        method: 'put',
        data: postData
    })
}

export function registerGuest(id, postData) {
    return http.request({
        url: GUEST_URL + '/' + id + '/register',
        method: 'put',
        data: postData
    })
}

export function deleteGuest(id) {
    return http.request({
        url: GUEST_URL + '/' + id,
        method: 'delete'
    })
}

export function validateMonthlyReport(id) {
    return http.request({
        url: GUEST_URL + '/monthly-report/' + id + '/validate',
        method: 'get'
    })
}

export function getMonthlyReport(id, params) {
    return http.request({
        url: GUEST_URL + '/monthly-report/' + id + '/list',
        method: 'get',
        params: params
    })
}

export function getMonthlyReportOptions(id, params) {
    return http.request({
        url: GUEST_URL + '/monthly-report/' + id + '/options',
        method: 'get',
        params: params
    })
}

export function setMonthlyReport(id, data) {
    return http.request({
        url: GUEST_URL + '/monthly-report/' + id,
        method: 'put',
        data: data
    })
}
export function getGuestAccessData(property_id,params) {
    return http.request({
        url:GUEST_ACCESS_DATA_URL(property_id),
        method:'get',
        params:params
    })

}
export function setGuestAccessData(property_id,postData){
    return http.request({
        url:GUEST_ACCESS_DATA_URL(property_id),
        method:'put',
        data:postData
    })
}
export function deleteAccessData(property_id, guest_access_id) {
    return http.request({
        url:GUEST_ACCESS_DATA_URL(property_id)+'/'+guest_access_id,
        method:'delete',
    })
}
export function deleteGuestImage(guest_id,id){
        return http.request({
            url: GUEST_URL + '/' + guest_id+'/image/'+id,
            method: 'delete',
        })

}

export function guestExportList(data, filetype){
    return http.request({
        url: GUEST_URL + `/export?${filetype}`,
        method: 'post',
        responseType: 'blob',
        data: data
    }).then((response) => {
        let blob = new Blob([response], {type: 'application/'+filetype});

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `export.${filetype}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
}


