import http from "@/services/http";
import {REVIEW_URL} from "@/services/endpoints";

export function getReviewList(params) {
    return http.request({
        url: REVIEW_URL + '/list',
        method: 'GET',
        params
    })
}
export function getReviewStatusList(params) {
    return http.request({
        url: REVIEW_URL + '/status-list',
        method: 'GET',
        params
    })
}

export function getReviewById(id){
    return http.request({
        url: REVIEW_URL + '/'+id,
        method: 'GET',
    })
}
export function respondReview(id, data){
    return http.request({
        url: REVIEW_URL + '/'+id+'/respond',
        method: 'POST',
        data
    })
}
export function writeReview(id,data){
    return http.request({
        url: REVIEW_URL + '/'+id+'/write',
        method: 'POST',
        data
    })
}
