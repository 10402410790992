<template>
    <div>
        <app-table-v2 class="reservation_table" :items="tableData" :fields="computedFields" :provider="getData" :default-limit="defaultLimit" :show-limit="showLimit"
                      @busyToggled="toggleBusy"
                      :selectable="paymentOrder.status === 4"
                      :http="{methods:'GET', url:INVOICE_LIST_URL}"
                      :filter="filter"
                      :search-on-create="searchOnCreate"
                      :maxExportLimit="5000" :search-event="searchEventName"
                      :deselect-event="EVENT_TABLE_DESELECT_ALL">
            <template v-slot:reservations="data">
                <template v-if="data.item.reservations.length === 1">
                    <b-link @click="() => {reservationAsideObject={id: data.item.reservations[0].id};reservationAsideState=true}">{{data.item.reservations[0].distribution_booking_id}}
                    </b-link>
                </template>
                <template v-else-if="data.item.reservations.length > 1">
                    <b-link :to="{name: routeNames.RN_RESERVATION_ACCOMMODATION_LIST, query: {invoice: data.item.id}}" target="_blank">{{$t('SHOW_ALL')}}</b-link>
                </template>
                <template v-else>
                    {{''}}
                </template>
            </template>

            <template v-slot:invoice_type="data">
                <small class="text-muted d-block">
                    {{data.item.system_invoice_type.label}}
                </small>
                {{ data.item.invoice_type.name }}
            </template>

            <template v-slot:created_date="data">
                <small class="text-muted d-block">
                    {{data.item.created_date | time}}
                </small>
                <div class="ws-no-wrap">
                    {{data.item.created_date | date}}
                </div>
            </template>

            <template v-slot:invoicee="data">
                <small class="text-muted d-block" v-if="data.item.invoicee.hasOwnProperty('type')">{{data.item.invoicee.type}}</small>
                {{ data.item.invoicee.first_name }} {{ data.item.invoicee.last_name }}
                <small v-if="data.item.invoicee.hasOwnProperty('property')" class="text-muted d-block">{{data.item.invoicee.property}}</small>
            </template>

            <template v-slot:delivery_date="data">
                {{ data.item.delivery_date | date }}
            </template>

            <template v-slot:due_date="data">
                {{ data.item.due_date | date }}
            </template>

            <template v-slot:amount_gross="data">
                {{ data.item.amount_gross }}
            </template>

            <template v-slot:status="data">
                {{ simpleStatus ? simplifyStatus(data.item.status) : data.item.status.label }}
            </template>
            <template v-slot:tableNoData="props">
                <app-no-data :show-tip="false" :heading="$t('NO_SEARCH_RESULTS')">
                </app-no-data>
            </template>
            <template v-slot:selectedContainer="data">
                    <app-button @click="removePaymentOrderId = true"
                                class="pull-right mr-3 ">{{ $t('REMOVE_FROM_PAYMENT_ORDER') }}
                    </app-button>
                <b-modal :title="$t('REMOVE_FROM_PAYMENT_ORDER')" v-model="removePaymentOrderId">
                    <span  class="text-muted d-flex justify-content-left">
                        {{$t('REMOVE_FROM_PAYMENT_ORDER_WARNING')}}
                    </span>
                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <div v-if="data.totalRows > 1 && data.totalRows > data.selected.length">
                                    <span v-if="data.selected.length === 1" class="d-block text-muted font-xs">({{$t("ITEM_SELECTED", {value: data.selected.length})}})</span>
                                    <span v-if="data.selected.length > 1" class="d-block text-muted font-xs">({{$t("ITEMS_SELECTED", {value: data.selected.length})}})</span>
                            </div>
                            <app-button :loading="removing_ids"  @click="removeFromPaymentOrder(data)" class="mr-3">
                                {{$t('REMOVE_FROM_PAYMENT_ORDER')}}
                            </app-button>
                        </div>
                    </template>
                </b-modal>
            </template>
        </app-table-v2>
        <app-aside v-model="reservationAsideState" :widths="['col-lg-12','col-sm-12', '100%']" full-width
                   header-styles="position: sticky; top: 0; z-index: 999; background-color:#fff">
            <template slot="header">
                <reservation-accommodation-header :reservation="reservationAsideObject"></reservation-accommodation-header>
            </template>
            <reservation-details :reservation-id="reservationAsideObject.id" tab="general" @update="reservationAsideObject=$event"></reservation-details>
        </app-aside>
    </div>
</template>

<script>
import AppSearchData from "@/components/app/AppSearchData";
import AppNoData from "@/components/app/AppNoData";
import {getInvoiceList, removeInvoicePaymentOrder} from "@/services/finance";
import AppButton from "@/components/app/AppButton/AppButton";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppTable from "@/components/app/AppTable";
import {EventBus, GE_LOADER_HIDE, GE_LOADER_SHOW} from "@/shared/EventBus";
import {cloneDeep} from "lodash";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppPagination from "@/components/app/filter/AppPagination";
import AppTableV2 from "@/components/app/AppTableV2";
import {INVOICE_LIST_URL} from "@/shared/constants";
import AppAside from "@/components/app/form/AppAside";
import InvoiceDetails from "@/components/finance/invoices/InvoiceDetails";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import routeNames from "@/router/routeNames";
import ReservationAccommodationHeader from "@/components/reservation/accommodation/ReservationAccommodationHeader";
import ReservationDetails from "@/components/reservation/accommodation/ReservationDetails";
import {R_RESERVATION_ACCOMMODATION_GENERAL, R_RESERVATION_ACCOMMODATION_LIST} from "@/shared/route_permission";
import {fileHelpers} from "@/mixins/shared/helpers";
import {AC_INVOICE_NEW} from "@/mixins/AccessControl/AccessControlEnumeration";
import AppAccessControl from "@/components/app/AppAccessControl";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

export default {
    name: "PaymentOrderInvoiceList",
    components: {
        AppAccessControl,
        ReservationDetails,
        ReservationAccommodationHeader,
        AppObjectHeader,
        InvoiceDetails,
        AppTableV2, AppPagination, AppTable, AppButton, AppNoData, AppSearchData, AppConfirmationDialog, AppAside},
    mixins: [AccessControlComponent,getErrorMessage,fileHelpers],
    props: {
        searchCriteria: {
            type: Object,
            default() {
                return {}
            }
        },
        searchOnCreate: {
            type: Boolean,
            default: true
        },
        simpleStatus: {
            type: Boolean,
            default: false
        },
        omittedFields: {
            type: Array,
            default() {
                return []
            }
        },
        defaultLimit: {
            type: Number,
            default: 10
        },
        showLimit: {
            type: Boolean,
            default: true
        },
        searchEventName: {
            type: String,
            default: 'getInvoiceList'
        },
        paymentOrderId: {
            type: Number
        },
        paymentOrder: {
            type: Object
        },
    },
    data() {
        return {
            tableData: [],
            fields: [
                {key: 'id', label: 'Id'},
                {key: 'hr_number', label: this.$t('NUMBER'), class: 'ws-no-wrap'},
                {key: 'description', label: this.$t('DESCRIPTION')},
                {
                    key: 'reservations',
                    label: this.$t('RESERVATION_CODE'),
                    hasPermission: () => (this.checkPermission(R_RESERVATION_ACCOMMODATION_GENERAL) && this.checkPermission(R_RESERVATION_ACCOMMODATION_LIST))
                },
                {key: 'invoice_type', label: this.$t('TYPE'), tdClass: ''},
                {key: 'invoicee', label: this.$t('RECIPIENT'), class: 'w-10'},
                {key: 'amount_gross', label: this.$t('AMOUNT'), tdClass: 'ws-no-wrap text-right'},
                {key: 'status', label: this.$t('STATUS')},
                {
                    key: 'delivery_date',
                    label: this.$t('DELIVERY_DATE'),
                    tdClass: 'ws-no-wrap ',
                    sortable: true
                }, /////////////////
                {key: 'due_date', label: this.$t('DUE_DATE'), tdClass: 'ws-no-wrap', sortable: true}, ////////////////////////////
                {key: 'created_date', label: this.$t('DATE_CREATED'), sortable: true, tdClass: ''}
            ],
            filter: {},
            removePaymentOrderId: false,
            removing_ids: false,
            INVOICE_LIST_URL,
            routeNames,
            reservationAsideObject: {},
            reservationAsideState: false,
            AC_INVOICE_NEW,
            access_control_fetch_key: AC_INVOICE_NEW,
            access_control_components: 1,
            access_control_general_context: null,
            EVENT_TABLE_DESELECT_ALL: 'deselectData'
        }
    },
    computed: {
        computedFields() {
            return this.fields.filter(el => !this.omittedFields.includes(el.key) && (!el.hasPermission || el.hasPermission()))
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    watch: {
        filter: {
            handler() {
                EventBus.$emit(this.searchEventName)
            },
            deep: true
        }
    },
    methods: {
        getData(filter) {
            let req = {
                page: filter.currentPage,
                limit: filter.perPage,
                order_by: filter.sortBy,
                order_by_direction: filter.sortDirection,
                company: this.company.id,
                payment_order: this.paymentOrderId,
                ...this.filter,
            }
            return getInvoiceList(req)

        },
        simplifyStatus(status) {
            if (Array.prototype.includes.call(STATUS_UNPAID_LIST, status.id)) {
                return this.$t('UNPAID')
            } else {
                return status.label
            }
        },
        removeFromPaymentOrder(data){
            this.removing_ids = true
            let requestData
            requestData = {invoice: data.selected.map(el => el.id)}
            removeInvoicePaymentOrder(this.paymentOrderId,{invoice: requestData.invoice }).then((response) => {
                notifySuccess()
                EventBus.$emit(GE_LOADER_SHOW)
                this.$emit('paymentOrderRemoved')
                this.removePaymentOrderId = false
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.removePaymentOrderId = false
                this.removing_ids = false
                EventBus.$emit(GE_LOADER_HIDE)
                EventBus.$emit(this.EVENT_TABLE_DESELECT_ALL)
            })
        },
        search(filter) {
            this.filter = cloneDeep(filter)
        },
        toggleBusy($event) {
            EventBus.$emit('invoiceLoading', $event)
        },
    },
    mounted() {
        EventBus.$on('invoiceSearch', (filter) => {
            this.search(filter)
        })
    },
    created() {
        this.access_control_components = 1
        this.access_control_general_context = {
            company: this.company.id
        }
        if (this.searchOnCreate) {
            this.filter = cloneDeep(this.searchCriteria)
        }
    },
    beforeDestroy() {
        if (![routeNames.RN_INVOICE_LIST, routeNames.RN_OWNER_INVOICE_LIST].includes(this.$route.name)) {
            EventBus.$off('invoiceSearch')
        }
    }
}
</script>

<style scoped>

</style>
