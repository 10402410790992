<template>
    <b-form @submit.prevent>
        <form-wizard :key="componentFormKey" ref="wizard_activation_distribution" :startIndex="currentIndex" transition="fade-in"
                     color=var(--color-primary) errorColor=var(--color-warning)
                     name="distributionPropertyWizard" title="" subtitle=""
                     @on-validate="handleNextStep"
                     @on-complete="activate">
            <tab-content name="object" :title="$t('TYPE')">
                <b-row>
                        <app-access-control tag="b-col" class="d-flex justify-content-center" @AccessControlEvent="addToAccessControlCounter()"
                                            :access_control_context="{key: access_control_fetch_key, function: F_CM_CONNECT_EXISTING_PROPERTY}">
                            <template v-slot:default="props">
                        <b-form-group>
                            <div class="header-2 mb-3">
                                {{$t('CONNECTING_EXISTING_PROPERTY')}} ?
                            </div>

                                    <b-form-radio-group
                                        :disabled="props.disabled"
                                        v-model="object_exist"
                                        :options="object_exist_options"
                                        id="radios-object_exist"
                                        name="radios-object_exist"
                                        stacked
                                        class="main_calendar-custom-radio text-center">
                                    </b-form-radio-group>
                        </b-form-group>
                                </template>
                            </app-access-control>
                </b-row>
            </tab-content>
            <tab-content v-if="distribution_has_setup.includes(distribution.id)" name="pricing_mode" :title="$t('SETUP')" :before-change="() => validate('setup')">
                <b-row>
                    <b-col cols="12">
                        <div class="header-2 mb-3  text-center" v-if="statusObject.hasOwnProperty('name')">
                            {{statusObject.name}}
                        </div>
                        <div class="header-3 mb-3  text-center" v-if="statusObject.hasOwnProperty('status')">
                            {{statusObject.status}}
                        </div>
                        <div class=" header-3 mb-3 text-center" v-if="mapping_exist"><span
                            class="fa fa-exclamation-triangle fa-fw fa mr-1"></span>{{$t('MAPPING_ALREADY_EXIST')}}
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <app-access-control xl="4" lg="4" md="6" @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{key: access_control_fetch_key, company:company_id,
                 function: C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_PRICING_TYPE_V}">
                        <template v-slot:default="props">
                            <app-input-control :error-object="validationErrorObject.setup.pricing_mode">
                                <template v-slot:input="data">
                                    <app-select :set-first="true" :disabled="props.disabled" :label="$t('PRICING_TYPE')"
                                                :state="data.state" v-model="pricing_mode" :options="setup_parameters"
                                    ></app-select>
                                </template>
                            </app-input-control>
                        </template>
                    </app-access-control>
                    <app-access-control xl="4" lg="4" md="6" @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{key: access_control_fetch_key, company:company_id, distribution:distribution.id,function: C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_PAYMENT_TYPE_V}">
                        <template v-slot:default="props">
                            <app-input-control :error-object="validationErrorObject.setup.payment_mode">
                                <template v-slot:input="data">
                                    <app-select :set-first="true" :label="$t('PAYMENT_TYPE')"
                                                v-model="payment_mode"
                                                :options="setup_parameters_payment_mode"
                                                :state="data.state"
                                    ></app-select>
                                </template>

                            </app-input-control>

                        </template>
                    </app-access-control>
                </b-row>


            </tab-content>
            <tab-content v-if="object_exist" name="mapping" :title="$t('MAPPING')"
                         :before-change="() => validate('mapping')">
                <b-row>
                    <b-col cols="12">
                        <div class="text-center header-2 mb-3" v-if="statusObject.hasOwnProperty('name')">
                            {{statusObject.name}}
                        </div>
                        <div class="header-3 mb-3  text-center" v-if="statusObject.hasOwnProperty('status')">
                            {{statusObject.status}}
                        </div>
                        <div class="text-center header-3" v-if="mapping_exist"><span
                            class="fa fa-exclamation-triangle fa-fw fa mr-1"></span>{{$t('MAPPING_ALREADY_EXIST')}}
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col xl="4" lg="4" md="6">
                        <app-input-control :error-object="validationErrorObject.mapping.property_eid">
                            <template v-slot:input="data">
                                <b-form-group class="center">
                                    <label>{{$t('EXTERNAL_ID')}}</label>
                                    <b-input :state="data.state" @change="updateEid" :value="property_eid">
                                    </b-input>
                                </b-form-group>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
                <b-col sm="12" lg="12" v-if="distribution.id === HOTEL_BEDS || distribution.id === AGODA || status_eid !== null " :key="index" v-for="(unit, index) in unit_list">
                    <app-access-control
                        tag="div"
                        @AccessControlEvent="addToAccessControlCounter()"
                        :access_control_context="{key: access_control_fetch_key, unit:unit.id, distribution:distribution.id,function:C_PROPERTY_CM_PM_UNIT_ACTIVATE_TEST_V}">
                        <template v-slot:default="props">
                            <b-card class="repeater-1 w-100">
                                <div slot="header">
                                    <repeater-header class="mb-3">
                                        {{unit.name}}
                                        <b-checkbox
                                            v-b-tooltip.hover
                                            :title="props.messages.length ? props.messages[0].message : ''"
                                            inline
                                            type="checkbox"
                                            :id="'activate_key_'+unit.id"
                                            :unchecked-value="0"
                                            :value="1"
                                            @input="updateUniActivate(index, $event)"
                                            v-model="unit_activation[index].active"
                                            switch
                                            class="pull-right"
                                            :disabled="props.disabled"
                                        ></b-checkbox>
                                    </repeater-header>
                                </div>
                                <b-row v-if="unit_activation[index].active" class="mb-3">
                                    <b-col xl="4" lg="4" md="6">
                                        <app-select :key="unit.id" :label="$t('UNIT')"
                                                    v-model="unit_activation[index].unit_eid"
                                                    :options="externalUnitList"></app-select>
                                    </b-col>
                                </b-row>
                                <b-row v-if="unit_activation[index].unit_eid">
                                    <b-col>
                                        <unit-mapping-item v-if="unit_activation[index].active" :unit="unit"
                                                           :key="unit.id"
                                                           :property_id="property_id"
                                                           :unit_eid="unit_activation[index].unit_eid"
                                                           :distribution="distribution"
                                                           :property_eid="property_eid"
                                                           @updateUnitRatePlan="updateUnitRatePlanMapping(index, $event)">
                                        </unit-mapping-item>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </template>
                    </app-access-control>

                    <b-col>
                    </b-col>
                </b-col>
            </tab-content>
            <tab-content v-if="!object_exist" name="create" :title="$t('CREATE')">
                <b-row>
                    <b-col class="d-flex justify-content-center">
                        <b-form-group>
                            <div class="header-2 mb-3">
                                {{$t('CREATE_PROPERTY')}} ?
                            </div>
                            <b-form-radio-group
                                v-model="create_property"
                                :options="create_property_options"
                                id="radios-create-property"
                                name="radios-create-property"
                                stacked
                                class="main_calendar-custom-radio text-center">
                            </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="create_property">
                    <b-col cols="4">
                        <b-form-group>
                            <label>{{$t('HEADLINE')}}</label>
                            <b-input v-model="property_headline"></b-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </tab-content>
            <tab-content v-if="object_exist && distribution.id === BOOKING && licencing !== null && tabsLoaded"
                         name="licences" :title="$t('REQUIRED_LICENCES')">
               <BookingLicenceForm :licence="licencing">
               </BookingLicenceForm>
            </tab-content>
            <tab-content v-if="object_exist && (distribution.id === BOOKING || distribution.id === EXPEDIA ) && hasPermissionPullReservation && tabsLoaded" name="additional" :title="$t('ADDITIONAL_OPTIONS')">
                <div>
                    <b-row class="d-flex justify-content-center">
                        <b-form-group >
                            <b-form-checkbox  v-model="pull_previous_reservations"
                                              :value="1"
                                              :unchecked-value="0" class=" pr-3 mr-3">{{$t("IMPORT_FUTURE_RESERVATIONS")}}
                            </b-form-checkbox>
                        </b-form-group>
                    </b-row>
                    <b-row class="d-flex justify-content-center" >
                     <b-col lg="8" class="d-flex align-items-center disclaimer-text">
                         <span class="text-center" v-html="$t('PULL_PREVIOUS_RESERVATIONS_DISCLAIMER_TEXT')"></span>
                     </b-col>
                    </b-row>

                </div>
            </tab-content>

            <button class="btn btn-outline-primary" slot="prev">{{$t('PREVIOUS')}}</button>
            <button class="btn btn-primary" slot="next" :disabled="loader">
                <i v-if="loader "
                   class="mr-1 fa fa-spinner fa-spin fa-fw"
                   aria-hidden="true">
                </i>
                {{$t('NEXT')}}
            </button>
            <button class="btn btn-primary" :disabled="loader" slot="finish">
                <i v-if="loader "
                   class="mr-1 fa fa-spinner fa-spin fa-fw"
                   aria-hidden="true">
                </i>
                {{$t('FINISH')}}
            </button>
        </form-wizard>
        <loading :show="show_loader"></loading>
    </b-form>
</template>

<script>

import AppSelect from "@/components/app/AppSelect/AppSelect";
import {FormWizard, TabContent} from 'vue-form-wizard'
import {
    getDistributionPropertyStatus,
    getDistributionSetupParameter,
    validationActivateProperty,
    getChannelUnitList,
    getChannelRatePlanList, getBookingLicences
} from "@/services/channel_manager/index";
import {toast} from "@/shared/plugins/toastr";
import ConfirmationDialog from '@/components/app/form/AppConfirmationDialog'
import AppButton from "@/components/app/AppButton/AppButton";
import AppInputControl from "@/components/app/AppInputControl";
import {
    MAPPING_PROPERTY_EID,
    CM_PRICING_MODE_VALIDATION_FAIL,
    CM_PROPERTY_STATUS_NOT_EXIST,
    CM_PAYMENT_MODE_VALIDATION_FAIL
} from "@/shared/error_codes";
import {EventBus, V_ERROR} from "@/shared/EventBus";
import AppAccessControl from "@/components/app/AppAccessControl";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_PROPERTY_ACTIVATION_WIZARD} from "@/mixins/AccessControl/AccessControlEnumeration";
import {
    C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_PRICING_TYPE_V,
    C_PROPERTY_CM_PM_UNIT_ACTIVATE_TEST_V,
    C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_PAYMENT_TYPE_V,
    F_CM_CONNECT_EXISTING_PROPERTY,
    F_CM_PULL_PROPERTY_RESERVATIONS, C_PROPERTY_DECLINED_CC_V,

} from "@/shared/component_permission";
import FormHeader from "@/components/app/form/FormHeader";
import {
    EXPEDIA,
    BOOKING,
    PRICING_MODEL,
    PAYMENT_TYPE,
    HOTEL_BEDS,
    DIRECT_BOOKER_COMPANY,
    AGODA
} from "@/shared/constants";
import {
    getUnitPaginatedList
} from '@/services/unit/index'
import Loading from "vue-full-loading/src/Loading";
import RepeaterHeader from "@/components/app/form/RepeaterHeader";
import UnitMappingItem from "@/components/channel_manager/wizard/UnitMappingItem";
import BookingLicenceForm from "@/components/channel_manager/wizard/DistributionActivationWizardBookingLicenceForm";

export default {
    components: {
        BookingLicenceForm,
        UnitMappingItem,
        RepeaterHeader,
        Loading,
        FormHeader,
        AppAccessControl,
        AppInputControl,
        AppButton,
        AppSelect, FormWizard, TabContent, ConfirmationDialog
    },
    name: "distribution-activation-wizard",
    mixins: [AccessControlComponent],
    props: {
        distribution: {
            type: Object,
        },
        property_id: {
            type: Number
        },
        loader: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show_loader: false,
            distribution_has_setup:[BOOKING,EXPEDIA],
            fields: [
                {key: 'id', label: 'Id'},
                {key: 'name', label: this.$t('UNIT'),},
                {key: 'mapping', label: this.$t('MAPPING'), tdClass: 'mb-0 mt-0 pb-2 pt-2 w-25 '},
                {key: 'activate', label: this.$t('ACTIVATE')}
            ],
            ratePlanFields: [
                {key: 'id'},
                {key: 'name', label: this.$t('RATE_PLAN')},
                {key: 'mapping', label: this.$t('MAPPING')}
            ],
            C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_PRICING_TYPE_V,
            C_PROPERTY_CM_PM_UNIT_ACTIVATE_TEST_V,
            C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_PAYMENT_TYPE_V,
            F_CM_CONNECT_EXISTING_PROPERTY,
            F_CM_PULL_PROPERTY_RESERVATIONS,
            DIRECT_BOOKER_COMPANY,
            currentIndex: 0,
            object_exist: 0,
            componentFormKey:1,
            object_exist_options: [
                {value: 1, text: this.$t('YES')},
                {value: 0, text: this.$t('NO')}
            ],
            create_property_options: [
                {value: 1, text: this.$t('YES')},
                {value: 0, text: this.$t('NO')}
            ],
            create_property: 1,
            property_eid: null,
            pricing_mode: null,
            activate_unit_list: [],
            setup_parameters: [],
            status_eid: null,
            statusObject: {},
            mapping_exist: false,
            property_headline: null,
            unit_map: null,
            payment_mode: null,
            setup_parameters_payment_mode: [],
            unit_list: [],
            externalUnitList: [],
            externalRatePlanList: [],
            unit_activation: [],
            // unitActivationUnwatch: null,
            pull_previous_reservations:null,
            access_control_fetch_key: AC_PROPERTY_ACTIVATION_WIZARD,
            HOTEL_BEDS,BOOKING,AGODA,EXPEDIA,
            validationErrorObject: {
                mapping: {
                    property_eid: {
                        input: [MAPPING_PROPERTY_EID]
                    },
                    status_eid: {
                        toast: [CM_PROPERTY_STATUS_NOT_EXIST]
                    }

                },
                setup: {
                    pricing_mode: {
                        input: [CM_PRICING_MODE_VALIDATION_FAIL]
                    },
                    payment_mode: {
                        input: [CM_PAYMENT_MODE_VALIDATION_FAIL]
                    }
                },

            },
            licencing: null,
            tabsLoaded: true,
        }
    },
    computed: {
        company_id() {
            return this.$store.getters['user/getCompany']
        },
        disabledExistingProperty(){
            if(Number(this.distribution.id) === EXPEDIA &&  this.propertyObject.company.id !== 1 || Number(this.distribution.id) === HOTEL_BEDS) {
                return true
            }
            return false
        },
        propertyObject(){
            return this.$store.getters['property/getProperty']
        },

        hasPermissionPullReservation() {
                return this.$store.getters['user/getPermission'](F_CM_PULL_PROPERTY_RESERVATIONS)
        }


    },
    methods: {
        updateUniActivate(index, value) {
            this.unit_activation[index].active = value
            if (!value) {
                this.unit_activation[index].unit_eid = null
            }
        },
        updateUnitRatePlanMapping(index, ratePlanListObject) {

            this.unit_activation[index].rate_plan_mapping = ratePlanListObject
        },

        updateEid(value) {
            this.property_eid = value
            if (!this.property_eid) {
                return
            }
            this.setUnitList()
            this.setChannelUnitList()
            if(this.distribution.id === HOTEL_BEDS || this.distribution.id === AGODA){
                return
            }
            this.getPropertyStatus()
            if (this.distribution.id === BOOKING) {
                this.getLicencesInformationForBooking()
            }


        },
        getPropertyStatus() {
            this.show_loader = true
            getDistributionPropertyStatus({
                distribution: this.distribution.id,
                property_eid: this.property_eid
            }).then(response => {
                this.show_loader = false
                this.statusObject = response.data
                this.status_eid = response.data.status
                this.mapping_exist = response.data.mapping_exist
                this.setChannelUnitList()
                this.setUnitList()
            }, error => {
                this.show_loader = false
                this.statusObject = {},
                    this.status_eid = null,
                    this.mapping_exist = false
                toast({
                    'title': "Action  fail",
                    'message': this.getErrorMessage(error),
                    'type': 'error',
                    'timeout': 2000
                })
            })

        },
        activate() {
            this.$emit('activate', this.prepareRequest())
        },
        validate(step) {
            return validationActivateProperty(this.prepareRequest()).then(() => {
                return true
            }, error => {
                if (this.validationErrorObject.hasOwnProperty(step)) {
                    return this.serverStepValidation(this.validationErrorObject[step], error.response.data.error_list)
                } else {
                    toast({
                        'title': this.$t('NOTIFICATIONS.ERROR'),
                        'message': this.$t('ERROR_ACTIVATION'),
                        'type': 'error',
                        'timeout': 3000
                    })
                }
            })
        },
        serverStepValidation(stepObject, errorList) {

            if (Array.isArray(errorList) && errorList.length) {
                const serverErrorCodes = errorList.map(list => Number(list.code))
                for (const [filedName, errorObject] of Object.entries(stepObject)) {
                    for (const [type, typeErrors] of Object.entries(errorObject)) {
                        for (let error of typeErrors) {
                            if (error.code === CM_PROPERTY_STATUS_NOT_EXIST.code) {
                                toast({
                                    'title': this.$t('NOTIFICATIONS.ERROR'),
                                    'message': this.$t('ERROR_ACTIVATION'),
                                    'type': 'error',
                                    'timeout': 3000
                                })
                            }
                            if (serverErrorCodes.includes(error.code)) {
                                return false
                            }

                        }
                    }
                }
            }
            return true
        },
        prepareRequest() {
            let requestObject = {
                "distribution": this.distribution.id,
                "property": this.property_id,
                "status_eid": this.status_eid,
                "pricing_mode": this.pricing_mode,
                "property_eid": this.property_eid,
                "existing_property": this.object_exist,
                "create_property": this.create_property,
                "activation_unit_list": this.unit_activation,
                "property_headline": this.property_headline,
                "payment_mode": this.payment_mode,
                "pull_previous_reservations":this.pull_previous_reservations
            }
            return requestObject
        },

        handleNextStep(isValid, tabIndex) {
            if (!isValid) {
                return
            }
            EventBus.$emit(V_ERROR, [])
            this.currentIndex = tabIndex++
        },

        getErrorMessage(error) {
            let msg = []
            if (error.response.data.error_list.length) {
                error.response.data.error_list.forEach(el => {
                    msg.push(el.Message)
                })
            }
            return msg
        },
        setDistributionSetupParameters(distribution_id) {

            getDistributionSetupParameter({
                distribution: distribution_id,
                company: this.company_id
            }).then(response => {
                if (response.data.length > 0) {
                    response.data.forEach(el => {
                        if (el.id === PRICING_MODEL) {
                            this.setup_parameters = el.allowed
                        }
                        if (el.id === PAYMENT_TYPE) {
                            this.setup_parameters_payment_mode = el.allowed
                        }
                    })
                }
            })
        },
        setUnitList() {
            this.show_loader = true
            getUnitPaginatedList({
                page: 1,
                limit: 1000,
                contigent: 0,
                property_id: this.property_id
            }).then(response => {
                this.unit_list = response.data.items
                this.access_control_components = this.unit_list.length
                if (response.data.items.length > 0) {
                    response.data.items.forEach(item => {
                        this.unit_activation.push({
                            unit: item.id,
                            active: 0,
                            unit_eid: null,
                            rate_plan_mapping: []
                        })
                    })
                }
            }, () => {
                this.unit_list = []
                this.unit_activation = []
            }).finally(()=> {
                this.show_loader = false
            })
        },
        setChannelUnitList() {
            this.show_loader = true
            this.externalUnitList = []
            getChannelUnitList({
                distribution: this.distribution.id,
                property: this.property_id,
                property_eid: this.property_eid
            }).then(response => {
                if (response.data.length > 0) {
                    response.data.forEach(item => {
                        let responseObject = {
                            id: item.id,
                            name: item.name + ' (' + item.id + ')'
                        }
                        let index = this.externalUnitList.findIndex(el => el.id === responseObject.id && el.name === responseObject.name)
                        if (index > -1) {
                            this.externalUnitList.splice(index, 1)
                        }
                        this.externalUnitList.push(responseObject)
                    })
                }
            }, () => {
                this.externalUnitList = []
            }).finally(()=> {
                this.show_loader = false
            })
        },
        getChannelUnitRatePlan() {
            this.externalRatePlanList = []
            getChannelRatePlanList({
                distribution: this.distribution.id,
                property: this.property_id,
                property_eid: this.property_eid
            }).then(response => {
                if (response.data.length > 0) {
                    for (let item of response.data) {
                        let object = {
                            id: item.id,
                            name: item.name + ' (' + item.id + ')',
                            external_name: item.name
                        }
                        this.externalRatePlanList.push(object)
                    }
                }
            })
        },
        getLicencesInformationForBooking()
        {
            /*if (typeof this.unitActivationUnwatch === 'function') {
                this.unitActivationUnwatch()
            }*/

            let requestObject = {
                property_eid: this.property_eid
            }
            getBookingLicences(requestObject).then(response => {
                this.licencing = response.data
                if (this.licencing !== null && typeof this.licencing === 'object' && this.licencing.hasOwnProperty('level') && this.licencing.level !== 'property') {
                    this.licencing = null
                    /*this.unitActivationUnwatch = this.$watch('unit_activation', (value) => {
                        if (value.length > 0) {
                            let unitEidList = {}
                            value.forEach(element => {
                                unitEidList[String(element.unit)] = element.unit_eid
                            })
                        }
                    })*/
                }
            }, () => {
                this.licencing = null
            }).finally(() => {
                if (this.licencing !== null) {
                    this.tabsLoaded = false
                    this.$nextTick(() => {
                        this.tabsLoaded = true
                    })
                }
            })
        },
    },
    watch: {

        distribution: {
            handler(distributionObject) {
                this.setDistributionSetupParameters(distributionObject.id)
                if (distributionObject.id === EXPEDIA || distributionObject.id === HOTEL_BEDS || distributionObject.id === AGODA) {
                    this.object_exist = 1
                }
                this.access_control_general_context = {
                    company: this.company_id,
                    distribution: distributionObject.id
                }
                this.access_control_components = distributionObject.id === HOTEL_BEDS || distributionObject.id === AGODA ? 1 : 3


            },
            immediate: true
        },
        setup_parameters: {
            handler(list) {
                if (list.length === 1) {
                    let object = list[0]
                    this.pricing_mode = object.hasOwnProperty('id') ? object.id : null
                }
            },
            immediate: true
        },
        object_exist: {
            handler(value) {
                this.property_eid = null
                this.statusObject = {}
                this.mapping_exist = false
                this.unit_activation = []
                this.property_headline = null
                this.status_eid = null
                this.access_control_components = this.distribution.id === HOTEL_BEDS ? 1 : 4
            }
        },
        create_property: {
            handler(value) {

                this.headline = null
            }
        },

    },

}
</script>

<style scoped>

</style>
