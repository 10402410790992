<template>
    <div>
        <b-form @submit.prevent="submit">
            <b-row>
                <b-col class="mb-4">
                    <label>{{ $t('LABEL_VALUE') }}</label>
                    <b-input-group>
                        <b-input type="number"
                                 v-model.number="adjust_value"
                        ></b-input>
                        <b-input-group-append class="custom-number-input-control">
                            <app-select
                                v-model="adjust_value_type"
                                :options="adjustValueTypeOptions"
                                :search-empty-item="false"
                                value-field="value"
                                text-field="text"
                            ></app-select>
                        </b-input-group-append>
                    </b-input-group>
                    <validation-description
                        :show="adjustValueState === false || adjustPercentValueState === false">
                        <span v-if="adjustPercentValueState === false">{{ $t('V.MIN_VALUE', {value: percent_min}) }}</span>
                        <span v-else>{{ $t('V.NUMERIC') }}</span>
                    </validation-description>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="mb-4">
                    <div><label>{{ $t('SELECT_PERIODS_TO_APPLY_CHANGES') }}</label></div>
                    <b-form-checkbox
                        v-model="select_all_period"
                        aria-describedby="periods"
                        aria-controls="periods"
                        @change="togglePeriods"
                        :checked="true"
                        :unchecked-value="false">
                        {{ $t('SELECT_ALL') }}
                    </b-form-checkbox>
                    <b-form-checkbox-group
                        class="ml-4"
                        stacked
                        value-field="id"
                        text-field="name"
                        name="periods"
                        v-model="periods"
                        :options="periodsComputed"
                    ></b-form-checkbox-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="mb-4">
                    <div><label>{{ $t('SELECT_YEARS_TO_APPLY_CHANGES') }}</label></div>
                    <b-form-checkbox
                        v-model="select_all_year"
                        aria-describedby="years"
                        aria-controls="years"
                        @change="toggleYears"
                        :checked="true"
                        :unchecked-value="false">
                        {{ $t('SELECT_ALL') }}
                    </b-form-checkbox>
                    <b-form-checkbox-group
                        class="ml-4"
                        stacked
                        value-field="id"
                        text-field="name"
                        name="years"
                        v-model="years"
                        :options="yearsComputed"
                    ></b-form-checkbox-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-tooltip v-if="!(adjustValueState === false || adjustPercentValueState === false) && periods.length === 0"
                               :title="$t('PERIODS') + ': ' + $t('NUM_SELECTED', {value: 0})"
                               placement="top"
                               target="saveButton"
                    ></b-tooltip>
                    <b-tooltip v-else-if="periods.length > 0 && adjust_value === null"
                               :title="$t('NO_CHANGE')"
                               placement="top"
                               target="saveButton"
                    ></b-tooltip>
                    <app-button-submit :disabled="periods.length === 0 || adjust_value === null || adjustValueState === false || adjustPercentValueState === false"
                                       id="saveButton">
                        {{ $t('SAVE') }}
                    </app-button-submit>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import moment from 'moment';
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import ValidationDescription from '@/components/app/form/AppValidationDescription'
import FormHeader from "@/components/app/form/FormHeader";
import AppButton from "@/components/app/AppButton/AppButton";
import {FIX, PERC} from "@/shared/constants";

export default {
    name: "BulkCalendarSaveAdjustedForm",
    components: {AppButton, FormHeader, ValidationDescription, AppButtonSubmit, AppSelect},
    props: {
        periodList: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data() {
        return {
            adjust_value: null,
            adjust_value_type: PERC,
            adjustValueTypeOptions: [
                {value: PERC, text: '%'},
                {value: FIX, text: 'fix'},
            ],
            years_in_future: 3,
            years: [],
            select_all_year: false,
            periods: [],
            select_all_period: false,
            loading: false,
            percent_min: -99,
        }
    },
    computed: {
        adjustValueState() {
            return this.adjust_value === null || (this.adjust_value.toString().length > 0 && this.adjust_value.toString() !== '0') ? null : false
        },
        adjustPercentValueState() {
            if (this.adjust_value === null || this.adjust_value_type !== PERC) {
                return null
            }
            return (this.adjust_value.toString().length > 0 && this.adjust_value.toString() !== '0' && Number(this.adjust_value) < Number(this.percent_min)) ? false : null
        },
        yearsComputed() {
            let current_year = Number(new moment().format('YYYY'))
            let years = []
            years.push({
                id: current_year.toString(),
                name: current_year
            })
            for (let i = 0; i < this.years_in_future; i++) {
                let future_year = current_year + 1 + i
                years.push({
                    id: future_year.toString(),
                    name: future_year
                })
            }
            return years
        },
        periodsComputed() {
            let periods = []
            const period_ids_list = this.$store.getters['pricing/getPeriodList']
            for (let periodId of period_ids_list) {
                let period = this.$store.getters['pricing/getPeriodById'](periodId);
                if (period.deleted === 'undefined' || !period.deleted) {
                    period.name = this.formatD(period.period_start) + ' - ' + this.formatD(period.period_end)
                    period.deleted = 0
                    periods.push(period)
                }
            }
            return periods
        }
    },
    methods: {
        submit() {
            let prepareRequest = {
                adjust_value_type: this.adjust_value_type,
                adjust_value: this.adjust_value,
                years: this.years,
                sales_periods: this.periods,
            }
            this.$emit('openConfirmationSaveAdjusted', prepareRequest)
        },
        toggleYears() {
            this.years = []
            if (!this.select_all_year) {
                Object.keys(this.yearsComputed).forEach(key => {
                    this.years.push(this.yearsComputed[key].id)
                })
            }
        },
        togglePeriods() {
            this.periods = []
            if (!this.select_all_period) {
                Object.keys(this.periodsComputed).forEach(key => {
                    this.periods.push(this.periodsComputed[key].id)
                })
            }
        },
        formatD(value) {
            if (value) {
                const format = this.$t('DATE.DATE_FORMAT_SANS_YEAR')
                if (typeof value === 'number') {
                    return moment.unix(value).format(format)
                }

                if (String(value).length === 5) {
                    return moment(String(value), 'MM/DD').format(format)
                }

                return moment(String(value)).format(format)
            }
        },
    },
    watch: {
        adjust_value(newVal, oldVal) {
            if (typeof newVal === 'string') {
                this.adjust_value = newVal.trim() !== '' && newVal.trim() !== '0' ? Number(newVal.trim()) : null
            } else if (newVal !== null && newVal !== oldVal) {
                this.adjust_value = newVal.toString().trim() !== '' && newVal.toString().trim() !== '0' ? Number(newVal.toString().trim()) : null
            }
        },
        years(newVal, oldVal) {
            // Handle changes in individual flavour checkboxes
            if (newVal.length === 0) {
                this.select_all_year = false
            } else {
                this.select_all_year = newVal.length === this.yearsComputed.length;
            }
        },
        periods(newVal, oldVal) {
            // Handle changes in individual flavour checkboxes
            if (newVal.length === 0) {
                this.select_all_period = false
            } else {
                this.select_all_period = newVal.length === this.periodsComputed.length;
            }
        },
    },
    created() {
        let current_year = Number(new moment().format('YYYY'))
        for (let i = 0; i < this.years_in_future; i++) {
            let future_year = current_year + 1 + i
            this.years.push(future_year.toString())
        }

        this.select_all_period = false
        this.togglePeriods()
    }
}
</script>

<style scoped>

</style>
