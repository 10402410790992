<template>
    <appearance-color-category-table></appearance-color-category-table>
</template>

<script>

import AppearanceColorCategoryTable from "@/components/appearance/color_category/AppearanceColorCategoryTable";

export default {
    name: "Index",
    components:{AppearanceColorCategoryTable}
}
</script>

<style scoped>

</style>
