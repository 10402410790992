<template>
    <div>
        <app-table-v2 :fields="tableFields" :provider="getData" @busyToggled="toggleBusy">
            <template v-slot:distribution="data">
                {{getDistributionNameById(data.item.distribution)}}
            </template>
            <template v-slot:type="data">
                {{data.item.from_guest ?  $t('GUEST_TO_HOST') : $t('HOST_TO_GUEST')}}
            </template>
            <template v-slot:>

            </template>
            <template v-slot:edit="data">
                <app-button
                    @click="editReview(data.item.id)"
                    :show_text="false"
                    variant="link"
                    icon="edit">
                </app-button>
            </template>
        </app-table-v2>
        <app-aside :widths="['col-sm-6']" v-model="review_aside_panel">
            <template slot="header">
                <review-accommodation-header :review="reviewItem" :distribution_name="getDistributionNameById(reviewItem.distribution)"></review-accommodation-header>
            </template>
            <accommodation-review-form @closeSideBar="review_aside_panel=false" :distributionList="distributionList" :review="reviewItem"></accommodation-review-form>
        </app-aside>
    </div>
</template>

<script>
    import AppAside from '@/components/app/form/AppAside'
    import AppTableV2 from "@/components/app/AppTableV2";
    import {getReviewList, getReviewById} from "@/services/review";
    import {EventBus, GE_TABLE_SEARCH} from "@/shared/EventBus";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AccommodationReviewForm from "@/components/review/accommodation/form/AccommodationReviewForm";
    import ReviewAccommodationHeader from "@/components/review/accommodation/form/ReviewAccommodationHeader";

    export default {
        name: "AccommodationReviewTable",
        props: {
            filter: {
                type: Object
            },
            distributionList:{
                type:Array
            }
        },
        components: {ReviewAccommodationHeader, AccommodationReviewForm, AppButton, AppTableV2,AppAside},
        data() {
            return {
                tableFields: [
                    {key: 'id', label: this.$i18n.t('ID')},
                    {key: 'distribution', label: this.$i18n.t('DISTRIBUTION')},
                    {key: 'object.name', label: this.$i18n.t('PROPERTY')},
                    {key: 'reservation_code', label: this.$i18n.t('RESERVATION_CODE')},
                    {key: 'type', label: this.$i18n.t('TYPE')},
                    {key: 'score', label: this.$i18n.t('SCORE')},
                    {key: 'status.name', label: this.$i18n.t('STATUS')},
                    {key: 'edit', label: "", class: 'text-right'},
                ],
                review_aside_panel:false,
                reviewItem:{}
            }
        },
        methods: {
            toggleBusy($event) {

                this.$emit('busyToggled', $event)
            },
            getData(filter) {
                let req = {
                    ...this.filter,
                    ...{
                        page: filter.currentPage,
                        limit: filter.perPage,
                    }
                }
                return getReviewList(req)
            },
            getDistributionNameById(distribution_id){
                if(!distribution_id){
                    return
                }
                if (this.distributionList.length){
                    let object = this.distributionList.find(el => el.id === distribution_id)
                    return object.name
                }
            },
        editReview(review_id){
            getReviewById(review_id).then(response =>{
                this.reviewItem = response.data
                this.review_aside_panel = true
            })
        }
        },
        watch: {
            filter: {
                handler(value) {
                    EventBus.$emit(GE_TABLE_SEARCH)
                },
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>
