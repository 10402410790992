<template>
    <div>

        <b-form autocomplete="off" @submit.prevent="setAccessData">
            <b-row class="mb-4">
                <b-col sm="12" md="6" lg="4">
                    <app-input-control :error-object="validationErrorObject.facility_code">
                        <template v-slot:input="data">
                            <label>{{ $t('FACILITY_CODE') }}</label>
                            <b-form-input v-model="objectAccessData.facility_code" type="text"></b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col sm="12" md="6" lg="4">
                    <app-input-control :error-object="validationErrorObject.guest_checkin_number">
                        <template v-slot:input="data">
                            <label>{{ $t('NEXT_GUEST_NUMBER') }}</label>
                            <b-form-input v-model="objectAccessData.guest_checkin_number" :disabled="isDisable"
                                          type="text"></b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <app-submit :loading="data_loaded" :disabled="disableSave"></app-submit>
                </b-col>
                <b-col md="6" v-if="objectAccessData.hasOwnProperty('id') && objectAccessData.id !== null">
                    <app-button-delete class="pull-right" @click="deleteDialogShow=true"></app-button-delete>
                </b-col>
                <delete-confirm :show="deleteDialogShow" @confirm="deleteConfirm"
                                @cancel="deleteDialogShow=false" :delete_title="true"></delete-confirm>
            </b-row>
        </b-form>
        <template v-if="this.objectAccessData.hasOwnProperty('id') && objectAccessData.id ">
            <b-row>
                <b-col>
                    <form-header>{{ $t('GUEST_MONTHLY_REPORTS') }}</form-header>
                </b-col>
            </b-row>
            <guest-monthly-report-table></guest-monthly-report-table>
        </template>
    </div>
</template>

<script>
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import GuestMonthlyReportTable from "@/components/guest/monthly_report/GuestMonthlyReportTable";
    import FormHeader from "@/components/app/form/FormHeader";
    import {getGuestAccessData} from "@/services/guest";
    import {AjpesSlo} from "@/shared/constants";
    import AppInputControl from "@/components/app/AppInputControl";
    import {
        GUEST_ACCESS_DATA_FACILITY_CODE_VALIDATION_FAILED,
        GUEST_ACCESS_DATA_GUEST_CHECKIN_NUMBER_VALIDATION_FAILED
    } from "@/shared/error_codes";
    import {toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import DeleteConfirm from '@/components/app/form/AppConfirmationDialog'
    import {cloneDeep} from "lodash";

    export default {
        props: {
            property: {
                type: Object
            },
            data_loaded: {
                type: Boolean,
                default: false
            },
            deleted_flag: {
                type: Boolean,
                default: false
            },
            accessData: {
                type: Object
            }

        },
        components: {
            AppInputControl,
            FormHeader,
            GuestMonthlyReportTable,
            AppSubmit,
            AppButtonDelete,
            DeleteConfirm
        },
        name: "guest-check-in-ajpes",
        mixins: [getErrorMessage],
        data() {
            return {
                objectAccessData: {},
                validationErrorObject: {
                    facility_code: {
                        input: [GUEST_ACCESS_DATA_FACILITY_CODE_VALIDATION_FAILED]
                    },
                    guest_checkin_number: {
                        input: [GUEST_ACCESS_DATA_GUEST_CHECKIN_NUMBER_VALIDATION_FAILED]
                    },
                },
                disableSave: false,
                deleteDialogShow: false
            }

        },
        methods: {
            setAccessData() {
                this.$emit('setData', this.objectAccessData)
            },
            deleteConfirm() {
                this.$emit('deleteData', this.objectAccessData.id)
                this.deleteDialogShow = false
            },
            resetData() {
                this.objectAccessData.id = null
                this.objectAccessData.facility_code = null
                this.objectAccessData.connected = null
                this.objectAccessData.guest_checkin_number = 1
            }
        },
        computed: {
            isDisable() {
                return !(this.objectAccessData.connected == null || this.objectAccessData.connected === 0)
            }
        },

        watch: {
            '$route.params.id': {
                handler(value) {
                    getGuestAccessData(value, {connection: AjpesSlo}).then(response => {
                            this.disableSave = false
                            this.objectAccessData = {connection: AjpesSlo, ...response.data}
                            this.$store.commit('property/setAjpesConnection', this.objectAccessData)
                        }, error => {
                            this.disableSave = true
                            this.facility_code = null
                            toast({
                                'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                                'message': this.getMessages(error),
                                'type': 'error',
                                'timeout': 3000
                            })
                        }
                    )
                },
                immediate: true
            },
            deleted_flag: {
                handler(value) {
                    if (value) {
                        this.resetData()
                    }
                }
            },
            accessData: {
                handler(value) {
                    this.objectAccessData = {connection: AjpesSlo, ...cloneDeep(value)}
                }
            }
        },
    }
</script>

<style scoped>

</style>
