<template>
    <div class="mini_calendar--cell position-relative mini_calendar--availability-cell"
         :style="{ 'grid-column': columnPosition + '/ span ' + day.colspan } "
         :class="classList">

        <div v-if="startOfWeekOrMonth" :class="{
                    'mini_calendar--status':day.hasReservationOnDayBefore,
                    'mini_calendar--background_primary ':day.hasReservationOnDayBefore,
                    'mini_calendar--status-end_of_res':day.hasReservationOnDayBefore}"
             class="justify-content-start pull-left">
        </div>
        <div v-if="day.reservation"
             class=" mini_calendar--status mini_calendar--background_primary cell"
             :class="{
                    'mini_calendar--status-start-res-before':day.reservation.check_in_date === availabilityItemKey && day.isCheckOutThisWeek,
                    'mini_calendar--status-start-res_before-no-check-out':day.reservation.check_in_date === availabilityItemKey && !day.isCheckOutThisWeek,
                    'mini_calendar--status-end':day.isCheckOutThisWeek,
                    'mini_calendar--reservation':day.isCheckOutThisWeek && !day.isCheckInThisWeek}"

             :id="`popover-reservation-${day.reservation.id}`"
             :style="`width: ${calculateWidth};`">

            <div v-if="day.isCheckInThisWeek" class="justify-content-start main_calendar_test-guest--icon">
                <b-img fluid :src="day.reservation.channel_icon"></b-img>
            </div>
            <div v-if="!day.isCheckInThisWeek" class="justify-content-end align-items-center pl-3" :style="`width: ${day.colspan*2.5}rem;`">
                {{ day.date.format('D') }}
            </div>
            <div v-for="span1 in calcColspan" class="justify-content-end align-items-center pl-3" :style="`width: ${day.colspan*2.5}rem;`">
                {{ day.date.add(1,'day').format('D') }}
            </div>

        </div>
        <template v-if="!day.reservation">
            <div v-if="isContigent && isAvailable && !day.reservation"
                 class="justify-content-start pb-4 ml-1 mini_calendar_numeric-indicator">
                <span>{{ contigentLabel }}</span>
            </div>

            <div v-if="!isContigent && isAvailable && !day.reservation && !day.availabilityObject.open_for_sale"
                 class="justify-content-start pb-4 cell ">
                <i class="fa fa-circle ml-1 mr-1 mini_calendar_indicator-open"
                   style="font-size: 0.3rem"></i>
            </div>
            <i v-if="!isAvailable && !day.reservation && day.availabilityObject.open_for_sale"
               class="justify-content-end pb-4 cell pull-right ">
                <i class="fa fa-circle ml-1 mr-1 mini_calendar_indicator-open pull-right"
                   style="font-size: 0.3rem"></i>
            </i>
            <div v-if="!isContigent && isAvailable && !day.reservation"
                 class="justify-content-end ml-1 cell pull-right ">
                <i class="fa fa-circle ml-1 mr-1 mini_calendar_indicator-padding pull-right"
                   style="font-size: 0.3rem"></i>
            </div>
            <div v-if="startOfWeekOrMonth" :class="{
                    'mini_calendar--status':day.hasReservationOnDayBefore,
                    'mini_calendar--background_primary ':day.hasReservationOnDayBefore,
                    'mini_calendar--status-end_of_res':day.hasReservationOnDayBefore}">

            </div>
            <div class="justify-content-center mini_calendar--status availability-cell">
                <div>{{ day.date.format('D') }}</div>
            </div>


            <!--            <b-popover-->
            <!--                v-if="day.reservation"-->
            <!--                placement="auto"-->
            <!--                container="my-container_calendar"-->
            <!--                class="calendar-popover"-->
            <!--                :show="show"-->
            <!--                :target="`popover-reservation-${day.reservation.id}`">-->
            <!--                <template v-slot:title>{{ day.reservation.formatted_check_in_date }} - -->
            <!--                    {{ day.reservation.formatted_check_out_date }}-->
            <!--                </template>-->
            <!--                <b-list-group>-->
            <!--                    <p class="d-flex justify-content-between align-items-center">-->
            <!--                        {{ day.translations.RESERVATION_CODE }}: {{ day.reservation.distribution_booking_id }}-->
            <!--                    </p>-->
            <!--                    <p v-if="day.show_channel_data" class="d-flex justify-content-between align-items-center">-->
            <!--                        {{ day.translations.SALES_CHANNEL }}: {{ day.reservation.channel.name }}-->
            <!--                    </p>-->
            <!--                    <p class="d-flex justify-content-between align-items-center">-->
            <!--                        {{ day.translations.GUEST }}: {{ day.reservation.first_name }} {{ day.reservation.last_name }}-->
            <!--                    </p>-->
            <!--                    <p class="d-flex justify-content-between align-items-center">-->
            <!--                        {{ day.translations.ADULTS }} / {{ day.translations.CHILDREN }} / {{ day.translations.BABIES }}:-->
            <!--                        {{ day.reservation.adult_no }} / {{ day.reservation.child_no }} /-->
            <!--                        {{ day.reservation.baby_no }}-->
            <!--                    </p>-->
            <!--                    <p class="d-flex justify-content-between align-items-center">-->
            <!--                        {{ day.translations.TOTAL }}: {{ day.reservation.channel_total }}-->
            <!--                    </p>-->
            <!--                    <p v-if="day.reservation.check_in_time"-->
            <!--                       class="d-flex justify-content-between align-items-center">-->
            <!--                        {{ day.translations.ARRIVAL_TIME }}: {{ day.reservation.formatted_check_in_time }}-->
            <!--                    </p>-->
            <!--                    <p class="d-flex justify-content-between align-items-center">-->
            <!--                        {{ day.translations.BOOKED_AT }}: {{ day.reservation.formatted_created_date }}-->

            <!--                    </p>-->
            <!--                </b-list-group>-->
            <!--            </b-popover>-->

        </template>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: "YearCalendarItemCell",
        props: {
            unit: {
                type: Object
            },
            day: {
                type: Object
            },
            days: {
                type: Array
            },
            dayIndex: {
                type: Number
            },
            availability: {
                type: Object
            },
            roomStatus: {
                type: Object
            },
            isContigent: {
                type: Boolean
            },
            column: {
                type: Number,
            }
        },
        data() {
            return {
                show: false,
            }
        },
        methods: {

        },
        computed: {
            startOfWeekOrMonth(){
                return (this.day.date.isoWeekday()===1 || this.day.date.format('D')==='1') && this.day.hasReservationOnDayBefore
            },
            calculateWidth(){
                if(!this.day.isCheckInThisWeek && !this.day.isCheckOutThisWeek){
                    return '100%'
                } else if(this.day.isCheckInThisWeek && !this.day.isCheckOutThisWeek){
                    let width=(this.day.colspan*2.5-0.3);
                    return `${width}rem`
                }
                else {
                    return `${this.day.colspan*2.5+0.1}rem`
                }
            },
            calcColspan() {
                if(this.day.colspan===1) return 0
                return this.day.colspan - 1
            },
            isAvailable() {
                if (this.isContigent) {
                    if (this.availabilityItem && this.availabilityItem.available_unit.length)
                        return true
                } else {
                    if (this.availabilityItem && this.availabilityItem.available_unit.includes(this.unit.id))
                        return true
                }
                return false
            },
            isCheckIn() {
                if (this.day.reservation) {
                    return this.day.reservation.check_in_date === this.availabilityItemKey
                }
                return false
            },
            isCheckOut() {
                if (this.day.reservation) {
                    return this.day.reservation.check_out_date === (moment(this.availabilityItemKey).add(1, 'day').format("YYYY-MM-DD"))
                }
                return false
            },
            availabilityItemKey() {
                return this.day.date.format("YYYY-MM-DD")
            },
            availabilityItem() {
                return this.availability[this.availabilityItemKey]
            },
            roomStatusItem() {
                return this.roomStatus[this.availabilityItemKey]
            },
            columnPosition() {
                return this.day.date.isoWeekday()
                // if (this.dayIndex === 0) {
                //     return this.days[0].isoWeekday()
                // }
            },
            contigentLabel() {
                if (this.availabilityItem && this.isContigent) {
                    return this.availabilityItem.available_unit.length > 0 ? this.availabilityItem.available_unit.length : ''
                }
                return null
            },
            classList() {

                let list = []
                if(this.day.reservation)
                {
                    return
                }
                if (this.day.availabilityObject.open_for_sale === 0) {
                    list.push('closed')
                    return list
                }

                if (this.availabilityItem) {
                    if (this.isContigent) {
                        if (!this.availabilityItem.available_unit.length) {
                            if (!this.day.reservation) {
                                list.push('closed')
                            }
                        }
                    } else {
                        if (!this.availabilityItem.available_unit.includes(this.unit.id)) {
                            if (!this.day.reservation) {
                                list.push('closed')
                            }
                        }
                    }
                }
                return list
            },
            popoverConfig() {
                // Both title and content specified as a function in this example
                // and will be called the each time the popover is opened
                return {
                    html: true,
                    title: () => {
                        // Note this is called only when the popover is opened
                        return this.day.reservation.formatted_check_in_date + ' - ' + this.day.reservation.formatted_check_out_date
                    },
                    content: () => {
                        let content = '<p>' + this.day.translations.RESERVATION_CODE + ': ' + this.day.reservation.distribution_booking_id + '</p>'
                        if (this.day.show_channel_data) {
                            content = content + '<p>' + this.day.translations.SALES_CHANNEL + ': ' + this.day.reservation.channel.name + '</p>'
                        }

                        content = content +
                            '<p>' + this.day.translations.GUEST + ' / ' + this.day.translations.CHILDREN + ' / ' +
                            this.day.translations.BABIES + ' : ' + this.day.reservation.adult_no + ' / ' +
                            this.day.reservation.child_no + ' / ' + this.day.reservation.baby_no + '</p>' +
                            '<p>' + this.day.translations.TOTAL + ': ' + this.day.reservation.channel_total + '</p>' +
                            '<p>' + this.day.translations.ARRIVAL_TIME + ': ' + this.day.reservation.formatted_check_in_time + '</p>' +
                            '<p>' + this.day.translations.BOOKED_AT + ': ' + this.day.reservation.formatted_created_date + '</p>'
                        return content
                        // return 'The date is:<br><em>' + new Date() + '</em>'
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
