<template>
    <div>

        <b-form @submit.prevent autocomplete="off" v-if="dataLoaded">
            <form-wizard ref="wizard" :startIndex="currentIndex" transition="fade-in" color=var(--color-primary) errorColor=var(--color-warning)
                         name="create_new_homeaway_4_2_standard_distribution" title="" subtitle=""
                         @on-validate="handleNextStep"
                         @on-complete="createDistribution">

                <tab-content name="general" :title="$t('GENERAL')" :before-change="() => validate('general')">
                    <b-row>
                        <b-col lg="4" class="mb-4">
                            <app-input-control :error-object="validationErrorObject.general.name">
                                <template v-slot:input="data">
                                    <label>{{ $t('NAME') }}</label>
                                    <b-input v-model="formData.general.name" :state="data.state">
                                    </b-input>
                                </template>
                            </app-input-control>
                        </b-col>
                    </b-row>
                    <b-row class="d-flex justify-content-center">
                        <b-col lg="4" class="mb-4"
                               v-for="(description, index) in settableDescriptionList"
                               :key="index">
                            <label>{{ description.label }}</label>
                            <app-select mode="multiselect"
                                        v-model="formData.general[description.id]"
                                        :options="descriptionList"
                                        value-field="id"
                                        text-field="label">
                            </app-select>
                        </b-col>
                    </b-row>
                </tab-content>

                <tab-content name="integration" :title="$t('INTEGRATION')"
                             :before-change="() => validate('integration')">
                    <b-row class="d-flex justify-content-center">
                        <b-col lg="4" class="mb-4">
                            <app-input-control :error-object="validationErrorObject.integration.advertiser_id">
                                <template v-slot:input="data">
                                    <label>{{ $t('ADVERTISER_ID') }}</label>
                                    <b-input v-model="formData.integration.advertiser_id" :state="data.state">
                                    </b-input>
                                </template>
                            </app-input-control>
                        </b-col>
                        <b-col lg="4" class="mb-4">
                            <app-input-control :error-object="validationErrorObject.integration.merchant_name">
                                <template v-slot:input="data">
                                    <label>{{ $t('MERCHANT_NAME') }}</label>
                                    <b-input v-model="formData.integration.merchant_name" :state="data.state">
                                    </b-input>
                                </template>
                            </app-input-control>
                        </b-col>
                        <b-col lg="4" class="mb-4">
                            <b-tooltip class="tooltiptext"
                                       target="rental_agreement_info"
                                       placement="top"
                                       triggers="hover">
                                {{ $t('RENTAL_AGREEMENT_TOOLTIP_MSG') }}
                            </b-tooltip>
                            <app-input-control :error-object="validationErrorObject.integration.rental_agreement">
                                <template v-slot:input="data">
                                    <label>{{ $t('RENTAL_AGREEMENT') }}</label>
                                    <i class="fa fa-question-circle-o ml-1 pull-right tooltip-help  mr-3 mt-1"
                                       id="rental_agreement_info"></i>
                                    <b-input v-model="formData.integration.rental_agreement" :state="data.state">
                                    </b-input>
                                </template>
                            </app-input-control>
                        </b-col>
                    </b-row>
                </tab-content>
                <tab-content name="unit_activation_rules" :title="$t('UNIT_ACTIVATION_RULES')"
                             :before-change="() => validate('unit_validation_rules')">
                    <unit-activation-rules :unit-activation-setup-object="defaultUnitActivationRules"
                                           :enable-workflow-options="false"
                                           @update="updateFormData">
                    </unit-activation-rules>
                </tab-content>

                <button class="btn btn-outline-primary" slot="prev">{{ $t('PREVIOUS') }}</button>
                <button class="btn btn-primary" slot="next" :disabled="loading">
                    <i v-if="loading"
                       class="mr-1 fa fa-spinner fa-spin fa-fw"
                       aria-hidden="true">
                    </i>
                    {{ $t('NEXT') }}
                </button>
                <button class="btn btn-primary" slot="finish" :disabled="loading">
                    <i v-if="loading"
                       class="mr-1 fa fa-spinner fa-spin fa-fw"
                       aria-hidden="true">
                    </i>
                    {{ $t('FINISH') }}
                </button>

            </form-wizard>
        </b-form>

        <!--        <app-wizard-complete>
                </app-wizard-complete>-->

    </div>
</template>

<script>
import AppWizardComplete from "@/components/app/Wizard/AppWizardComplete";
import AppInputControl from "@/components/app/AppInputControl";
import {FormWizard, TabContent} from 'vue-form-wizard'
import {EventBus, V_ERROR} from "@/shared/EventBus";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {getDescriptionList} from "@/services/description";
import {CM_COMMUNICATION_TYPE_HOME_AWAY, SETUP_PARAMETER_UNIT_ACTIVATION_RULES} from "@/shared/constants";
import {createDistribution, fetchDistributionDefaultSetup, validateCreateDistribution} from "@/services/distribution";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {notifySuccess} from "@/shared/plugins/toastr";
import {
    CM_ADVERTISER_ID_NOT_VALID,
    CM_ADVERTISER_ID_REQUIRED,
    CM_MERCHANT_NAME_MAX_LENGTH_VALIDATION_FAILED,
    CM_MERCHANT_NAME_REQUIRED,
    DISTRIBUTION_NAME_MIN_LENGTH_VALIDATION_FAILED,
    DISTRIBUTION_NAME_REQUIRED,
    CM_RENTAL_AGREEMENT_REQUIRED
} from "@/shared/error_codes";
import UnitActivationRules from "@/components/direct_booking/marketplace/MarketplaceUnitActivationRules";
import {getMarketplaceUnitSetup} from "@/services/marketplace";

export default {
    name: "HomeAwayStandardDistributionCreationWizard",
    components: {
        UnitActivationRules,
        AppSelect,
        FormWizard,
        TabContent,
        AppInputControl,
        AppWizardComplete
    },
    mixins: [getErrorMessage],
    data() {
        return {
            currentIndex: 0,
            settableDescriptionList: [],
            descriptionList: [],
            defaultUnitActivationRules: null,
            formData: {
                general: {
                    name: null,
                    main_description: [],
                    accommodation_summary: [],
                    location: [],
                    other_activities: [],
                    unique_benefits: [],
                    owner_story: [],
                },
                integration: {
                    advertiser_id: null,
                    merchant_name: null,
                    rental_agreement: null,
                },
                unit_activation_rules: {},
            },
            validationErrorObject: {
                general: {
                    name: {
                        input: [
                            DISTRIBUTION_NAME_REQUIRED,
                            DISTRIBUTION_NAME_MIN_LENGTH_VALIDATION_FAILED,
                        ],
                    },
                },
                integration: {
                    advertiser_id: {
                        input: [
                            CM_ADVERTISER_ID_REQUIRED,
                            CM_ADVERTISER_ID_NOT_VALID,
                        ],
                    },
                    merchant_name: {
                        input: [
                            CM_MERCHANT_NAME_REQUIRED,
                            CM_MERCHANT_NAME_MAX_LENGTH_VALIDATION_FAILED,
                        ],
                    },
                    rental_agreement: {
                        input: [
                            CM_RENTAL_AGREEMENT_REQUIRED,
                        ],
                    },
                },
            },
            defaultSetupLoaded: false,
            descriptionsLoaded: false,
            defaultUnitActivationRulesLoaded: false,
            loading: false,
        }
    },
    computed: {
        dataLoaded() {
            return this.defaultSetupLoaded && this.descriptionsLoaded && this.defaultUnitActivationRulesLoaded
        },
        company() {
            return this.$store.getters['user/getCompany']
        },
    },
    methods: {
        handleNextStep(isValid, tabIndex) {
            if (!isValid) {
                return
            }
            EventBus.$emit(V_ERROR, [])
            this.currentIndex = tabIndex++
        },
        validate(step) {
            this.loading = true
            let request = {
                communication_type: CM_COMMUNICATION_TYPE_HOME_AWAY,
                step: step,
                ...this.formData[step]
            }
            return validateCreateDistribution(request).then(() => {
                return true
            }, (error) => {
                this.showErrorMessages(error, this.validationErrorObject[step])
                return false
            }).finally(() => {
                this.loading = false
            })
        },
        createDistribution() {
            this.loading = true
            let request = {
                company: this.company,
                communication_type: CM_COMMUNICATION_TYPE_HOME_AWAY,
                ...this.formData.general,
                ...this.formData.integration,
                ...this.formData.unit_activation_rules,
                insert_mappings: {
                    unit_type: 1,
                    amenity: 1,
                    inventory: 1,
                    poi: 1
                }
            }
            createDistribution(request).then(() => {
                notifySuccess()
                this.$emit('distributionCreated')
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
            })
        },
        getListOfDescriptions() {
            let request = {home_away: 1}
            getDescriptionList(request).then(response => {
                this.descriptionList = response.data
            }, () => {
                this.descriptionList = []
            }).finally(() => {
                this.descriptionsLoaded = true
            })
        },
        getDefaultSetup() {
            let request = {communication_type: CM_COMMUNICATION_TYPE_HOME_AWAY}
            fetchDistributionDefaultSetup(request).then(response => {
                let defaultSetup = response.data
                if (defaultSetup.description_list) {
                    defaultSetup.description_list.forEach((description) => {
                        this.settableDescriptionList.push({id: description.id, label: description.label})
                        if (this.formData.general.hasOwnProperty(description.id)) {
                            this.formData.general[description.id] = description.source_list
                        }
                    })
                }
            }, () => {
                this.settableDescriptionList = []
            }).finally(() => {
                this.defaultSetupLoaded = true
            })
        },
        getDefaultUnitActivationRules() {
            let request = {default_setup: true, communication_type: CM_COMMUNICATION_TYPE_HOME_AWAY}
            getMarketplaceUnitSetup(request).then((response) => {
                this.defaultUnitActivationRules = response.data
            }, () => {
                this.defaultUnitActivationRules = null
            }).finally(() => {
                this.defaultUnitActivationRulesLoaded = true
            })
        },
        updateFormData(args) {
            this.formData = {...this.formData, ...args}
        },
    },
    created() {
        this.getListOfDescriptions()
        this.getDefaultSetup()
        this.getDefaultUnitActivationRules()
    },
}
</script>

<style scoped>

</style>
