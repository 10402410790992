<template>
    <b-card v-cloak class="property-wrapper" >
        <template slot="header">
            <h2 class="property-wrapper-padding">{{$t('COMPETITORS')}}</h2>
            <app-button @click="EventBus.$emit(GE_REVENUE_MANAGER_FILTER_NEW)" v-if="$route.name === routeNames.RN_REVENUE_MANAGER_SIMILAR_LISTING_FILTER" button_type="new" variant="primary"
                        class="pull-right">
                {{ $t("NEW_GROUP") }}
            </app-button>
            <Navigation  :nav-elements="navElements">
            </Navigation>

        </template>
        <router-view>
        </router-view>

    </b-card>

</template>

<script>

import Navigation from "@/components/app/navigation/Navigation";
import routeNames from "@/router/routeNames";
import AppButton from "@/components/app/AppButton/AppButton";
import {EventBus,GE_REVENUE_MANAGER_FILTER_NEW} from '@/shared/EventBus';

export default {
    components: {AppButton, Navigation},
    data(){
        return {
            routeNames,
            EventBus,
            GE_REVENUE_MANAGER_FILTER_NEW
        }
    },
    computed:{
        navElements() {
            return [
                {
                    route: {name: routeNames.RN_REVENUE_MANAGER_SIMILAR_LISTING_FILTER},

                },
                {
                    route: {name: routeNames.RN_REVENUE_MANAGER_SIMILAR_LISTING_UNIT},

                },


            ]
        },
    }
}
</script>

<style scoped>

</style>
