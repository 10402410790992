<template>
    <div>
        <appearance-form v-if="websiteId" :app-client-type-id="APP_CLIENT_TYPE_WEBSITE" :app-client-id="websiteId"></appearance-form>
    </div>
</template>

<script>

import AppearanceForm from "@/components/appearance/AppearanceForm";
import {APP_CLIENT_TYPE_WEBSITE} from "@/shared/constants";

export default {
    name: "Index",
    components:{AppearanceForm},
    data() {
        return {
            APP_CLIENT_TYPE_WEBSITE,
            websiteId: null,
        }
    },
    watch: {
        '$route.params.id': {
            handler(websiteId) {
                if (websiteId) {
                    this.websiteId = Number(websiteId)
                }
            },
            immediate: true,
        },
    }
}
</script>

<style scoped>

</style>
