<template>
    <b-card v-if="reviewItem"   class="repeater-1">
        <div slot="header">
            <b-row class="mb-2">
                <b-col lg="6">
                    {{reviewItem.name}}
                </b-col>
                <b-col lg="6">
                    {{reviewItem.score}}
                </b-col>
            </b-row>
        </div>
<!--        <b-row v-if="reviewItem.text">
            <quill-editor>{{reviewItem.text}}</quill-editor>
        </b-row>-->
    </b-card>
</template>

<script>
    import {cloneDeep, has, get} from 'lodash'
    import {quillEditor} from 'vue-quill-editor'
    export default {
        name: "AccommodationReviewItem",
        props:{
            reviewItem:{
                type:Object
            }
        },
        components:{quillEditor},
        methods:{
            _has(object, path) {
                return has(object, path)
            },
        }

    }
</script>

<style scoped>

</style>
