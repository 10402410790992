import http from '../http'

export function fetchCancellationPolicy(id) {
    return http.request({
        url: '/adminapi/cancelation-policies/' + id,
        method: 'get'
    })
}

export function updateCancellationConditionList(id, postData) {
    return http.request({
        url: '/adminapi/cancelation-policies/' + id + '/condition-list',
        method: 'post',
        data: postData
    })
}

export function fetchCancellationPolicyText(params) {
    return http.request({
        url: '/adminapi/cancelation-policies/text',
        method: 'get',
        params
    })
}

export function getPossibleSystemPolicies(params) {
    return http.request({
        url: '/adminapi/possible-system-cancelation-policies',
        method: 'get',
        params
    })
}

export function linkSystemPolicy(id, data) {
    return http.request({
        url: '/adminapi/cancelation-policies/' + id + '/link-system-policy',
        method: 'put',
        data: data
    })
}
