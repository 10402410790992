<template>
    <b-card class="property-wrapper">
        <template slot="header">
            <div class="d-flex justify-content-between flex-column flex-md-row">
                <div>
                    <div class="header-caption">{{$t('PROPERTY')}}</div>
                    <h2>{{property.name}}</h2>
                </div>

                <div v-if="distribution">
                    <div class="header-caption pl-0 pl-md-2 mt-3 mt-md-0">{{$t('DISTRIBUTION')}}</div>
                    <app-quick-link :value="distribution.id" :routes="distributionQuickList"></app-quick-link>
                </div>
            </div>

            <navigation :back="backButtonRoute" :nav-elements="list"></navigation>
        </template>

        <page-transition transition-type="slide">
            <router-view></router-view>
        </page-transition>
    </b-card>
</template>

<script>
    import PageTransition from '@/components/app/transition/AppTransition'
    import routeNames from '@/router/routeNames'
    import DistributionQuickLink from '@/components/channel_manager/distribution/DistributionQuickLink'
    import Navigation from "@/components/app/navigation/Navigation";
    import AppQuickLink from "@/components/app/AppQuickLink";


    export default {
        name: "index",
        components: {
            AppQuickLink,
            Navigation,
            PageTransition,
            DistributionQuickLink
        },
        data() {
            return {
                list: [
                    {
                        route: {name: routeNames.RN_PROPERTY_CHANNEL_MANAGER_PRODUCT_MANAGEMENT},
                    },
                    {
                        route: {name: routeNames.RN_PROPERTY_CHANNEL_MANAGER_SYNCHRONIZATION_LOG},
                    },
                    {
                         route: {name: routeNames.RN_PROPERTY_CHANNEL_MANAGER_SETUP},
                    }
                ],
                distributions: []
            }
        },
        computed: {
            distribution() {
                return this.$store.getters['channel_manager/getDistributionById'](this.$route.params.distribution_id)
            },
            distributionQuickList() {
                return this.distributions.map(distribution => {
                    return {
                        id: distribution.id,
                        name: this.$route.name,
                        label: distribution.name,
                        params: {distribution_id: distribution.id}
                    }
                })
            },
            property() {
                return this.$store.getters['property/getProperty']
            },
            backButtonRoute() {
                return {name: routeNames.RN_PROPERTY_CHANNEL_MANAGER_DISTRIBUTION, params: {id: this.$route.params.id}}
            },
            getCompanyId() {
                return this.$store.getters['user/getCompany']
            }
        },
        watch: {
            '$route.params.id': {
                handler(value) {
                    if (!this.property.hasOwnProperty('id') || this.property.id !== Number(value)) {
                        this.$store.dispatch('property/actionSetProperty', value)
                    }

                    this.$store.dispatch('channel_manager/actionSetFilterDistributionList', {company: Number(this.getCompanyId)}).then(() => {
                        let distributionList = this.$store.getters['channel_manager/getDistributionList']
                        this.distributions = []
                        if (distributionList.length > 0) {
                            for (let distributionId of distributionList) {
                                let distribution = this.$store.getters['channel_manager/getDistributionById'](distributionId)
                                this.distributions.push(distribution)
                            }
                        }
                    })
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
