<template>
    <form v-if="importData" autocomplete="off">
        <template v-if="dataLoaded">
            <b-row>
                <b-col lg="12">
                    <b-form-checkbox
                        id="check_all_options"
                        name="check_all_options"
                        :disabled="distribution === BOOKING && !importData.connection_approved"
                        :checked="allOptionsChecked()"
                        @change="toggleAllPossibleOptions($event)">
                        {{ $t('SELECT_ALL') }}
                    </b-form-checkbox>
                    <hr>
                </b-col>
            </b-row>
            <template v-if="importData.rate_plan_list.length > 0"
                      v-for="(ratePlan, ratePlanIndex) in importData.rate_plan_list">
                <b-row :class="ratePlan.unit_list.length === 1 ? 'd-flex align-items-end mb-4' : 'd-flex align-items-end mb-2'">
                    <b-col lg="3" class="mb-2">
                        <b-form-checkbox
                            :id="'rate_plan_' + ratePlan.external_id + '_check_all_units'"
                            :name="'rate_plan_' + ratePlan.external_id + '_check_all_units'"
                            :checked="ratePlanUnitsChecked(ratePlan)"
                            @change="toggleRatePlanUnits(ratePlan, $event)">
                            {{ ratePlan.name }}
                        </b-form-checkbox>
                    </b-col>
                    <b-col lg="4" class="mb-2">
                        <label>
                            {{ $t("LINKED_VALUE") }}
                        </label>
                        <b-input-group>
                            <b-form-input
                                v-model="formData.rate_plans[ratePlan.external_id].linked_calc_value">
                            </b-form-input>
                            <b-input-group-append>
                                <app-select
                                    v-model="formData.rate_plans[ratePlan.external_id].linked_calc_type"
                                    :search-empty-item="false"
                                    :options="calculationTypeList">
                                </app-select>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                    <b-col lg="4" class="mb-2">
                        <app-select
                            :label="$t('TYPE')"
                            v-model="formData.rate_plans[ratePlan.external_id].type"
                            :options="ratePlanTypeList"
                            text-field="label">
                        </app-select>
                    </b-col>
                </b-row>
                <b-row :class="ratePlanIndex === importData.rate_plan_list.length - 1 ? 'mb-4' : 'mb-2'">
                    <b-col lg="12" v-if="ratePlan.unit_list.length > 1">
                        <div class="position-relative">
                        <span
                            :class="expanded[ratePlan.external_id] ? 'icon-expanded-dropdown-down' : 'icon-collapsed-dropdown-up'"
                            @click="toggleUnitsSelection(ratePlan.external_id)">
                        </span>
                            <b-form-checkbox
                                :id="'rate_plan_' + ratePlan.external_id + '_units'"
                                :name="'rate_plan_' + ratePlan.external_id + '_units'"
                                :checked="ratePlanUnitsChecked(ratePlan)"
                                @change="toggleRatePlanUnits(ratePlan, $event)"
                                class="ml-4">
                                {{ $t("UNITS") }}
                            </b-form-checkbox>
                        </div>
                    </b-col>
                    <template v-if="ratePlan.unit_list.length === 1 || expanded[ratePlan.external_id]">
                        <b-col lg="12">
                            <div :class="ratePlan.unit_list.length > 1 ? 'ml-4' : ''">
                                <b-form-checkbox-group
                                    :id="'rate_plan_' + ratePlan.external_id + '_unit_group'"
                                    :name="'rate_plan_' + ratePlan.external_id + '_unit_group'"
                                    class="ml-4"
                                    stacked
                                    v-model="formData.rate_plans[ratePlan.external_id].unit_list"
                                    :options="ratePlan.unit_list"
                                    value-field="external_id"
                                    text-field="name">
                                </b-form-checkbox-group>
                            </div>
                        </b-col>
                    </template>
                </b-row>
            </template>
            <b-row>
                <b-col lg="12">
                    <b-form-checkbox
                        v-for="(importOption, importOptionIndex) in additionalImportOptionList"
                        :key="'import_option_' + importOption.id"
                        :id="'import_option_' + importOption.id"
                        :name="'import_option_' + importOption.id"
                        :checked="formData.additional_options.hasOwnProperty(importOption.id) ? formData.additional_options[importOption.id] : false"
                        @change="toggleAdditionalImportOption(importOption.id, $event)"
                        :disabled="additionalImportOptionDisabled(importOption.id)">
                        {{ importOption.name }}
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </template>
        <template v-else>
            <b-row class="d-flex justify-content-center">
                <b-spinner
                    variant="primary">
                </b-spinner>
            </b-row>
        </template>
    </form>
</template>

<script>
import {AIRBNB, BOOKING} from "@/shared/constants";
import _isEqual from "lodash/isEqual";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {getRatePlanType} from "@/services/pricing";

const FORM_DATA_APPLICABLE_IMPORT_DATA_KEY_LIST = []

export default {
    name: "ImportPropertyWizardOptionsForm",
    components: {AppSelect},
    data() {
        return {
            formData: {
                rate_plans: {},
                additional_options: {},
            },
            validationObject: {},
            dataLoaded: false,
            ratePlanTypeList: [],
            calculationTypeList: [
                {id: "perc", name: "%"},
                {id: "fix", name: "fix"},
            ],
            ratePlanTypeListLoaded: false,
            expanded: {},
            AIRBNB,
            BOOKING,
        }
    },
    props: {
        distribution: {
            type: Number,
            default: null,
        },
        importData: {
            type: Object,
        },
        listenForError: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        additionalImportOptionList() {
            let optionList = [
                {id: "general_data", name: this.$i18n.t("GENERAL_DATA"), order: 2},
                {id: "amenities", name: this.$i18n.t("AMENITIES"), order: 4},
                {id: "reservations", name: this.$i18n.t("RESERVATIONS"), order: 6},
            ]

            if (this.importData.rate_plan_list.length > 0) {
                optionList.push({id: "cancellation_policies", name: this.$i18n.t("CANCELLATION_POLICIES"), order: 1})
            }
            if (this.distribution === AIRBNB) {
                optionList.push({id: "descriptions", name: this.$i18n.t("DESCRIPTIONS"), order: 3})
                optionList.push({id: "images", name: this.$i18n.t("IMAGES"), order: 5})
            }

            return optionList.sort((a, b) => {
                return a.order - b.order
            })
        },
    },
    watch: {
        formData: {
            handler(value) {
                this.$emit("update", {options: value})
            },
            deep: true,
            immediate: true,
        },
        importData: {
            handler(value) {
                this.expanded = {}
                this.resetFormData()
                if (value && typeof value === "object") {
                    this.updateFormData(value)
                    this.getRatePlanTypeList(value)
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        updateFormData(importData) {
            for (const [key, value] of Object.entries(importData)) {
                if (FORM_DATA_APPLICABLE_IMPORT_DATA_KEY_LIST.indexOf(key) > -1) {
                    this.$set(this.formData, key, value)
                }
            }
            if (importData.hasOwnProperty("rate_plan_list") && importData.rate_plan_list.length > 0) {
                for (let ratePlan of importData.rate_plan_list) {
                    this.$set(this.formData.rate_plans, ratePlan.external_id, {})
                    this.$set(this.formData.rate_plans[ratePlan.external_id], "unit_list", [])
                    this.$set(this.formData.rate_plans[ratePlan.external_id], "type", ratePlan.type)
                    this.$set(this.formData.rate_plans[ratePlan.external_id], "linked_calc_value", ratePlan.linked_calc_value)
                    this.$set(this.formData.rate_plans[ratePlan.external_id], "linked_calc_type", ratePlan.linked_calc_type ? ratePlan.linked_calc_type : "perc")
                }
            }
        },
        getRatePlanTypeList(importData) {
            if (!importData.hasOwnProperty("rate_plan_list") || importData.rate_plan_list.length === 0 || this.ratePlanTypeListLoaded) {
                this.dataLoaded = true
                return
            }

            this.dataLoaded = false
            getRatePlanType().then((response) => {
                this.ratePlanTypeList = response.data
                this.ratePlanTypeListLoaded = true
            }).finally(() => {
                this.dataLoaded = true
            })
        },
        resetFormData() {
            this.$set(this.formData, "rate_plans", {})
            this.$set(this.formData, "additional_options", {})
        },
        setSelectedUnit(unitId) {
            if (this.formData.selected_units.length > 0) {
                this.formData.selected_units.splice(0)
            }
            this.formData.selected_units.push(unitId)
        },
        ratePlanUnitsChecked(ratePlan) {
            if (!this.formData.rate_plans.hasOwnProperty(ratePlan.external_id) || !this.formData.rate_plans[ratePlan.external_id].hasOwnProperty("unit_list")) {
                return false
            }
            return ratePlan.unit_list.length === this.formData.rate_plans[ratePlan.external_id].unit_list.length
        },
        allOptionsChecked() {
            for (let option of this.additionalImportOptionList) {
                if (!this.formData.additional_options.hasOwnProperty(option.id) || !this.formData.additional_options[option.id]) {
                    return false
                }
            }
            for (let ratePlan of this.importData.rate_plan_list) {
                if (!this.ratePlanUnitsChecked(ratePlan)) {
                    return false
                }
            }
            return true
        },
        toggleRatePlanUnits(ratePlan, event) {
            this.$set(this.formData.rate_plans[ratePlan.external_id], "unit_list", [])
            if (event) {
                for (let unit of ratePlan.unit_list) {
                    this.formData.rate_plans[ratePlan.external_id].unit_list.push(unit.external_id)
                }
            }
        },
        toggleAdditionalImportOption(importOptionId, event) {
            this.$set(this.formData.additional_options, importOptionId, event)
        },
        toggleAllPossibleOptions(event) {
            for (let option of this.additionalImportOptionList) {
                this.toggleAdditionalImportOption(option.id, event)
            }
            for (let ratePlan of this.importData.rate_plan_list) {
                this.toggleRatePlanUnits(ratePlan, event)
            }
        },
        toggleUnitsSelection(ratePlanEid) {
            if (!this.expanded.hasOwnProperty(ratePlanEid)) {
                this.$set(this.expanded, ratePlanEid, true)
                return
            }
            this.$set(this.expanded, ratePlanEid, !this.expanded[ratePlanEid])
        },
        additionalImportOptionDisabled(importOptionId) {
            return this.distribution === BOOKING && !this.importData.connection_approved && importOptionId !== "cancellation_policies"
        },
    },
}
</script>

<style scoped>
.icon-expanded-dropdown-down:before {
    font-family: "FontAwesome";
    font-size: .7rem;
    position: absolute;
    left: .5rem;
    top: 56%;
    transform: translateY(-50%);
    color: #6F6EFE;
    font-weight: normal;
    content: "\f078";
}

.icon-expanded-dropdown-down:hover:before {
    color: #5352C2;
    cursor: pointer;
}

.icon-collapsed-dropdown-up:before {
    font-family: "FontAwesome";
    font-size: .7rem;
    position: absolute;
    left: .5rem;
    top: 56%;
    transform: translateY(-50%);
    color: #6F6EFE;
    font-weight: normal;
    content: "\f054";
}

.icon-collapsed-dropdown-up:hover:before {
    color: #5352C2;
    cursor: pointer;
}
</style>
