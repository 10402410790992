<template>

    <b-card class="mb-3" body-class="p-0">
        <b-list-group class="repeater-2">
            <b-list-group-item class="list-group-item-title font-weight-bold">
            <div class="d-flex">
                <h5 class="title mr-1 font-weight-bold">{{period.period_start|periodDate}} - {{period.period_end|periodDate}}</h5>
                <app-access-control tag="span"    @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function:C_UNIT_CALENDAR_PERIODS_E,key:AC_BULK_CALENDAR}">

                    <template v-slot:default="props">
                        <b-tooltip v-if="props.messages.length"
                                   triggers="hover"
                                   :title="props.messages[0].message"  :target="'tooltip-target-msg_'+periodId">
                        </b-tooltip>

                        <app-button
                            :id="'tooltip-target-msg_'+periodId"
                            variant="link"
                            :disabled="props.disabled"
                            button_type="edit"
                            :show_text="false"
                            class=" action_button"
                            @click="$emit('openSideBar')"
                        >
                        </app-button>
                    </template>
                </app-access-control>
                <app-button
                    variant="link"
                    :font_awesome_5="true"
                    button_type="slider"
                    :show_text="false"
                    class="ml-1 action_button"
                    v-if="checkPermission(C_UNIT_CALENDAR_DAILY_ADJUSTMENT_V)"
                    @click="$emit('openDailyAdjustment',periodId)"
                >
                </app-button>

                <app-access-control tag="span"   @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function:C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_V,key:AC_BULK_CALENDAR}">
                    <template v-slot:default="props">
                        <b-popover tabindex="-1" :target="`periodStatisticPopover-${period.id+'_statistic_price'}`"
                                   :title="$t('SUGGESTION_BASED_ON')"
                                   placement="top"
                                   triggers="hover focus">
                            <p>{{$t('NUM_RESERVATIONS')}}: <template v-if="statistic && statistic.reservation_year"> ({{ statistic.reservation_year }})</template>:
                                {{statistic ? statistic.num_of_reservations : 0}}
                            </p>
                            <p>{{$t('NUMBER_OF_BOOKED_DAYS')}}: {{statistic ? statistic.days_reserved : null}}</p>
                            <p>{{$t('AVERAGE_OCCUPANCY')}}: {{statistic ? statistic.avg_guests : null}}</p>
                            <p>{{$t('AVERAGE_LENGTH_OF_STAY')}}: {{statistic ? statistic.avg_stay : null}}</p>

                            <p>{{$t('PRICE_FROM_PRICE_LIST')}}: <template v-if="statistic && statistic.pricelist_year"> ({{statistic.pricelist_year}})</template>: {{statistic ? statistic.price_from_pricelist : null}}</p>
                            <p>{{$t('MIN_STAY_FROM_PRICE_LIST')}}: <template v-if="statistic && statistic.pricelist_year"> ({{statistic.pricelist_year}})</template>: {{statistic ? statistic.min_stay_from_pricelist : null}}</p>
                        </b-popover>
                        <app-button
                            button_type="statistics" variant="link" class="pull-right static-button"  :show_text="false" :id="`periodStatisticPopover-${period.id+'_statistic_price'}`"></app-button>
                    </template>
                </app-access-control>

            </div>

            </b-list-group-item>
            <b-list-group-item>
                <div class="table-responsive">
                    <table class="table">
                    <thead>
                    <tr>
                        <th v-if="losList.length > 1">{{$t("LENGTH_OF_STAY")}}</th>
                        <th v-if="occupancyList.length > 1">{{$t("OCCUPANY_PERSONS")}}</th>
                        <th class="text-center">{{$t('PRICE')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="los in losList">
                        <tr :key="los.id + '_' + occupancy.id"
                            v-for="occupancy in occupancyList">
                            <td v-if="losList.length > 1" :class="los.default && occupancy.default ? 'bg-light' : ''">
                                {{los.min_stay}}
                                <span v-if="los.min_stay === los.max_stay"></span>
                                <span v-else-if="los.max_stay"> - {{los.max_stay}}</span>
                                <span v-else>+</span>
                            </td>
                            <td  v-if="occupancyList.length > 1" :class="los.default && occupancy.default ? 'bg-light' : ''">
                                {{occupancy.min_guests}}

                                <span v-if="occupancy.min_guests === occupancy.max_guests"></span>
                                <span v-else-if="occupancy.max_guests"> - {{occupancy.max_guests}}</span>
                                <span v-else>+</span>
                            </td>

                            <td class="mb-0 mt-0 pb-2 pt-2">
                               <input-price-element :statistic="statistic" :los="los" :occupancy="occupancy" :period="period" :indexTab="keyIndex" :currency="currency"></input-price-element>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
                </div>
            </b-list-group-item>
        </b-list-group>
    </b-card>

</template>
<style scoped>
    .static-button{
        position: absolute;
        right: 1.3rem;
    }
    .fa-action{
        cursor: pointer;
        font-size: 0.78rem;
        padding-top: 1.2px;
    }
</style>
<script>
    import UnitBulkCalendarRestriction from '@/components/unit/bulk_calendar/restriction/UnitBulkCalendarRestriction'
    import UnitBulkCalendarStatistics from '@/components/unit/bulk_calendar/statisticcs/UnitBulkCalendarRmStatistics'
    import AppButton from '@/components/app/AppButton/AppButton'
    import InputPriceElement from "@/components/unit/bulk_calendar/pricing/InputPriceElement";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_BULK_CALENDAR} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_V,C_UNIT_CALENDAR_PERIODS_E,C_UNIT_CALENDAR_DAILY_ADJUSTMENT_V} from "@/shared/component_permission";
    import AppAccessControl from "@/components/app/AppAccessControl";

    export default {
        name: "unit-bulk-calendar-price-panel",
        mixins:[AccessControlComponent],
        props: {
            ratePlanList: {
                type: Array
            },
            periodId: {
                type:Number
            },
            losList: {
                type: Array
            },
            occupancyList: {
                type: Array
            },
            restrictions: {
                type: Array
            },
            keyIndex: {
                type: Number
            },
            currency:{
                type:String
            }
        },
        data() {
            return {
                priceItems: {},
                price:null,
                rmSuggestionPopoverShow: false,
                AC_BULK_CALENDAR,
                C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_V,
                C_UNIT_CALENDAR_PERIODS_E,
                access_control_components:2,
                C_UNIT_CALENDAR_DAILY_ADJUSTMENT_V
            }
        },
        components: {
            AppAccessControl,
            InputPriceElement,
            UnitBulkCalendarRestriction,
            UnitBulkCalendarStatistics,
            AppButton
        },
        computed: {

            period() {
                return this.$store.getters['pricing/getPeriodById'](this.periodId)
            },
            statistic() {
                let data = this.$store.getters['pricing/getStatisticById'](this.periodId)
                if (typeof data === "undefined") {
                    return null
                } else {
                    return data
                }
            }
        },
    }
</script>

