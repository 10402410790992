<template>
    <div>
        <b-row>
            <b-col class="mb-3 d-flex justify-content-end align-items-baseline">
                <app-button button_type="new"
                            variant="primary"
                            @click="edit({})"
                            :c_permission="C_SETUP_FINANCE_INVOICE_CREATION_NEW">
                    {{ $t("NEW_INVOICE_CREATION_SETUP") }}
                </app-button>
            </b-col>
        </b-row>

        <app-table v-if="tableItems.length"
                   :busy="loading"
                   :items="tableItems"
                   :fields="fields">
            <template v-slot:invoice_type="data">
                {{data.item.invoice_type.label}}
            </template>

            <template v-slot:creation_location="data">
                {{data.item.creation_location.label}}
            </template>

            <template v-slot:actions="data">
                <app-button variant="link"
                            icon="edit"
                            :show_text="false"
                            @click="edit(data.item)">
                </app-button>
            </template>
        </app-table>
        <app-no-data v-else-if="!tableItems.length && !loading"></app-no-data>

        <app-pagination :listCount="total" v-model="filter.page" :last-page="lastPage"></app-pagination>

        <app-aside v-model="sidebarState" :widths="['col-lg-6', 'col-sm-12', '40%']">
            <template slot="header">{{ sidebarHeader }}</template>
            <invoice-creation-setup-form :setup-object="setupObject"
                                         @created="create"
                                         @updated="update"
                                         @deleted="_delete">
            </invoice-creation-setup-form>
        </app-aside>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppTable from "@/components/app/AppTable";
    import {deleteInvoiceCreationSetup, getInvoiceCreationSetupList} from "@/services/finance/setup";
    import AppPagination from "@/components/app/filter/AppPagination";
    import InvoiceCreationSetupForm from "@/components/finance/setup/invoice_creation_setup/InvoiceCreationSetupForm";
    import AppAside from "@/components/app/form/AppAside";
    import AppNoData from "@/components/app/AppNoData";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {C_SETUP_FINANCE_INVOICE_CREATION_NEW} from "@/shared/component_permission";

    export default {
        name: "Index",
        components: {AppNoData, InvoiceCreationSetupForm, AppPagination, AppTable, AppButton, AppAside},
        data() {
            return {
                loading: false,
                sidebarState: false,
                setupObject: null,
                tableItems: [],
                total: null,
                lastPage: null,
                filter: {page: 1},
                fields: [
                    {key: 'invoice_type', label: this.$t('TYPE')},
                    {key: 'creation_location', label: this.$t('CREATION_LOCATION')},
                    {key: 'description', label: this.$t('DESCRIPTION')},
                    {key: 'actions', label: '', class: 'text-right'}
                ],
                C_SETUP_FINANCE_INVOICE_CREATION_NEW
            }
        },
        computed: {
            sidebarHeader() {
                return this.setupObject && this.setupObject.id ? this.$t('EDIT_INVOICE_CREATION_SETUP') : this.$t('NEW_INVOICE_CREATION_SETUP')
            },
            companyId() {
                return this.$store.getters['user/getCompany']
            },
        },
        methods: {
            edit(setupObject) {
                this.setupObject = setupObject
                this.sidebarState = true
            },
            create(object) {
                this.setupObject = object
                this.tableItems.unshift(object)
            },
            update(object) {
                this.$set(this.tableItems, this.tableItems.findIndex(x => x.id === object.id), object)
                this.sidebarState = false
            },
            _delete(object) {
                this.loading = true
                deleteInvoiceCreationSetup(object.id).then(() => {
                    this.tableItems.splice(this.tableItems.findIndex(x => x.id === object.id), 1)
                    notifySuccess()
                    if (this.page > 1) {
                        this.page = 1
                    } else {
                        this.search(this.filter, this.page)
                    }
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.loading = false
                    this.sidebarState = false
                })
            },
            getList() {
                this.loading = true
                getInvoiceCreationSetupList({company: this.companyId, ...this.filter}).then(response => {
                    this.total = response.data.total
                    this.lastPage = response.data.last_page
                    this.tableItems = response.data.items
                }).finally(() => this.loading = false)
            },
            search(filter) {
                this.filter = {...filter}

            }
        },
        watch: {
            'filter.page': {
                handler() {
                    this.getList()
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
