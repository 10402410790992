<template>
    <div v-if="loaded">
        <template v-for="paymentMessageItem in tableData">

            <div>
                <h5 class="text-center">
                    {{paymentMessageItem.item.payer_name}}
                </h5>
            </div>


            <div class="mb-2" v-for="invoice in paymentMessageItem.invoice_list">
                <b-table :items="invoice.items" :fields="fields" fixed responsive foot-clone tfoot-tr-class="">
                    <template v-slot:HEAD_item_number="data">
                        <b>{{invoice.partner.name}} {{invoice.partner.last_name}}</b>
                    </template>

                    <template v-slot:HEAD_description="data">
                        <b>{{invoice.description}}</b>
                    </template>

                    <template v-slot:description="data">
                        {{articleListObject[data.item.article].name}}
                    </template>

                    <template v-slot:vat="data">
                        {{vatListObject[data.item.vat].name}}
                    </template>

                    <template v-slot:unit_price="data">
                        {{(getNett(data.item) / data.item.quantity).toFixed(2)}}
                    </template>

                    <template v-slot:nett="data">
                        {{(getNett(data.item)).toFixed(2)}}
                    </template>

                    <template v-for="field in noFooterFields" v-slot:[getFootSlotName(field)]="data">
                        {{''}}
                    </template>

                    <template v-slot:FOOT_nett="data">
                        {{(calcTotalNett(invoice.items)).toFixed(2)}}
                    </template>

                    <template v-slot:FOOT_gross="data">
                        {{calcTotalGross(invoice.items)}}
                    </template>
                </b-table>
            </div>

        </template>

    </div>
</template>

<script>
    import {getArticleList, getVatList} from "@/services/finance/setup";
    import {cloneDeep} from "lodash";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";

    export default {
        name: "PaymentMessageInvoiceListTable",
        mixins: [getErrorMessage],
        props: {
            tableData: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                fields: [
                    {key: 'item_number', label: '#'},
                    {key: 'description', label: this.$t('DESCRIPTION'), class: 'w-25'},
                    {key: 'quantity', label: this.$t('QUANTITY')},
                    {key: 'unit_of_measurement', label: this.$t('UNIT_OF_MEASUREMENT')},
                    {key: 'unit_price', label: this.$t('UNIT_PRICE'), class: 'text-right'},
                    {key: 'vat', label: this.$t('VAT'), class: 'text-right'},
                    {key: 'nett', label: this.$t('NETT_AMOUNT'), class: 'text-right'},
                    {key: 'gross', label: this.$t('TOTAL'), class: 'text-right'},
                ],
                vatListObject: null,
                articleListObject: null,
                loaded: false,
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            noFooterFields() {
                return this.fields.filter(el => !['nett', 'gross'].includes(el.key))
            }
        },
        methods: {
            getNett(item) {
                return item.gross - Number(item.gross - (item.gross / (1 + (this.vatListObject[item.vat].amount / 100)))).toFixed(2)
            },
            getFootSlotName(field) {
                return `FOOT_${field.key}`
            },
            calcTotalNett(items) {
                return items.reduce((acc, item) => {
                    acc += this.getNett(item)
                    return acc
                }, 0)
            },
            calcTotalGross(items) {
                return items.reduce((acc, item) => {
                    acc += Number(item.gross)
                    return acc
                }, 0)
            }
        },
        created() {
            Promise.all([
                getVatList({company: this.company.id, limit: 999}),
                getArticleList({company: this.company.id, limit: 999}),
            ]).then(responses => {
                // [this.vatList, this.articleList] = responses.map(r => r.data.items);
                this.vatListObject = responses[0].data.items.map(i => {
                    return {
                        id: i.id,
                        name: `${i.label} ( ${i.amount}% )`,
                        amount: i.amount
                    }
                }).reduce((acc, el) => {
                    acc[el.id] = el
                    return acc
                }, {})

                this.articleListObject = responses[1].data.items.reduce((acc, el) => {
                    acc[el.id] = el
                    return acc
                }, {})

            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loaded = true
            })
        }
    }
</script>

<style scoped>

</style>
