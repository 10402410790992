<template>
    <div v-if="system_templates">
        <div v-if="checkPermission(C_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES_V) && templates">
            <b-row v-if="templates.length > 0">
                <b-col sm="12" :key="index" v-for="(template, index) in templates">
                    <b-card class="repeater-1">
                        <div slot="header" class="mb-2">
                            <b-row>
                                <b-col>
                                    <repeater-header class="d-flex align-items-center">
                                        {{template.name}}
                                        <edit-button class="action_button"
                                                     :show_text="false"
                                                     variant="link"
                                                     button_type="edit"
                                                     :v_show="checkPermission(C_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES_E)"
                                                     @click="editTemplate(template.id)">
                                        </edit-button>
                                    </repeater-header>
                                </b-col>
                            </b-row>
                        </div>
                        <b-table responsive small hover :fields="fields"
                                 :items="templateObjectToArray(template)">
                        </b-table>
                    </b-card>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col>
                    <app-no-data :tip="$t('TIP.NEW_TEMPLATE')">
                    </app-no-data>
                </b-col>
            </b-row>
        </div>
    </div>
    <div v-else>
        <div v-if="checkPermission(C_PROPERTY_EVENT_TEMPLATE_V) && templates">
            <b-row v-if="templates.length > 0">
                <b-col sm="12" :key="index" v-for="(template, index) in templates">
                    <b-card class="repeater-1">
                        <div slot="header">
                            <b-row>
                                <b-col>
                                    <repeater-header class="d-flex align-items-center">
                                        {{template.name}}
                                        <edit-button
                                                     class="action_button"
                                                     :show_text="false"
                                                     variant="link"
                                                     button_type="edit"
                                                     :v_show="checkPermission(C_PROPERTY_EVENT_TEMPLATE_E)"
                                                     @click="editTemplate(template.id)">
                                        </edit-button>
                                        <edit-button v-if="template.system_template && template.system_template.event_template_id"
                                                     :v_show="checkPermission(C_PROPERTY_EVENT_TEMPLATE_UNLINK_SYSTEM_TEMPLATE)"
                                                     class="action_button"
                                                     :show_text="false"
                                                     variant="link"
                                                     button_type="expand"
                                                     @click="confirmUnlinkSystemTemplate(template.id)">
                                        </edit-button>
                                        <edit-button v-else-if="!(template.system_template && template.system_template.event_template_id) && possibleSystemTemplateList.length > 0"
                                                     :v_show="checkPermission(C_PROPERTY_EVENT_TEMPLATE_LINK_SYSTEM_TEMPLATE)"
                                                     class="action_button"
                                                     :show_text="false"
                                                     variant="link"
                                                     button_type="compress"
                                                     @click="$emit('linkTemplate', template.id)">
                                        </edit-button>
                                    </repeater-header>
                                </b-col>
                            </b-row>
                        </div>
                        <b-row class="mb-2">
                            <b-col>
                                <div class="pl-3">
                                    {{$t("ACTIVE")}}
                                    <b-form-checkbox :disabled="!checkPermission(C_PROPERTY_EVENT_TEMPLATE_ACTIVATE)"
                                                     :id="'active_' + template.id"
                                                     :name="'active_' + template.id"
                                                     inline
                                                     switch
                                                     :unchecked-value="0"
                                                     :value="1"
                                                     :checked="template.active"
                                                     @change="updateActive($event, template.id)">
                                    </b-form-checkbox>
                                </div>
                            </b-col>
                        </b-row>
                        <b-table responsive small hover :fields="fields"
                                 :items="templateObjectToArray(template)">
                        </b-table>
                    </b-card>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col>
                    <app-no-data :tip="$t('TIP.NEW_OR_APPLY_TEMPLATE')">
                    </app-no-data>
                </b-col>
            </b-row>
        </div>
        <app-confirmation-dialog :show="showUnlinkSystemTemplateConfirmationDialog"
                                 :title="$t('UNLINK_CONFIRMATION_TITLE')"
                                 @confirm="unlinkSystemTemplate"
                                 @cancel="showUnlinkSystemTemplateConfirmationDialog = false">
            {{$t('UNLINK_SYSTEM_EMAIL_TEMPLATE_CONFIRMATION_MSG')}}
        </app-confirmation-dialog>
    </div>
</template>

<script>
    import RepeaterHeader from "@/components/app/form/RepeaterHeader";
    import EditButton from '@/components/app/AppButton/AppButton';
    import AppNoData from "@/components/app/AppNoData";
    import {getEventMailTemplatesList, updateEventTemplateActiveParam} from "@/services/property";
    import {
        C_PROPERTY_EVENT_TEMPLATE_V,
        C_PROPERTY_EVENT_TEMPLATE_E,
        C_PROPERTY_EVENT_TEMPLATE_ACTIVATE,
        C_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES_E,
        C_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES_V,
        C_PROPERTY_EVENT_TEMPLATE_LINK_SYSTEM_TEMPLATE,
        C_PROPERTY_EVENT_TEMPLATE_UNLINK_SYSTEM_TEMPLATE
    } from "@/shared/component_permission";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {toast} from "@/shared/plugins/toastr";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import {linkSystemEventTemplate} from "@/services/event_template";

    export default {
        name: "EventTemplateList",
        mixins: [getErrorMessage],
        components: {
            AppConfirmationDialog,
            RepeaterHeader,
            EditButton,
            AppNoData
        },
        data() {
            return {
                C_PROPERTY_EVENT_TEMPLATE_V,
                C_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES_V,
                C_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES_E,
                C_PROPERTY_EVENT_TEMPLATE_ACTIVATE,
                C_PROPERTY_EVENT_TEMPLATE_E,
                C_PROPERTY_EVENT_TEMPLATE_LINK_SYSTEM_TEMPLATE,
                C_PROPERTY_EVENT_TEMPLATE_UNLINK_SYSTEM_TEMPLATE,
                fields: [
                    {key: 'event.label', label: this.$t('ON_EVENT')},
                    {key: 'send_to.label', label: this.$t('SEND_TO')}
                ],
                template_id: null,
                showUnlinkSystemTemplateConfirmationDialog: false
            }
        },
        props: {
            templates: {
                type: Array,
                default: null
            },
            system_templates: {
                type: Boolean,
                default: false
            },
            possibleSystemTemplateList: {
                type: Array,
                default() {
                    return []
                }
            },
        },
        methods: {
            getData(id) {
                getEventMailTemplatesList(id).then(res => {
                    this.templates = res.data;
                })
            },
            editTemplate(template_id) {
                this.$emit("editTemplate", template_id);
            },
            updateActive(e, template_id) {
                updateEventTemplateActiveParam(this.$route.params.id,
                    template_id,
                    JSON.stringify({active: e})
                ).then(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }, error => {
                    this.showErrorMessages(error)
                    document.getElementById('active_' + template_id).checked = !e
                })
            },
            templateObjectToArray(templateObject) {
                let templateArray = []
                templateArray.push(templateObject)
                return templateArray
            },
            confirmUnlinkSystemTemplate(template_id) {
                this.template_id = template_id
                this.showUnlinkSystemTemplateConfirmationDialog = true
            },
            unlinkSystemTemplate() {
                linkSystemEventTemplate(this.template_id, {unlink: 1}).then(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.$emit('unlinkTemplate')
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.showUnlinkSystemTemplateConfirmationDialog = false
                })
            },
        }
    }
</script>

<style scoped>

</style>
