<template>
    <b-card no-body>
        <b-card-header header-class="border-bottom-0 d-flex align-items-baseline">
            <div>
                <span class="header-2">{{$t("OWNER")}}</span>
            </div>
        </b-card-header>

        <b-card-body class="read-only">
            <div class="items">
                <div class="item">
                    <div class="item-label">{{$t("NAME")}}:</div>
                    <div class="item-content">{{reservation.owner.name}}</div>
                </div>

                <div class="item">
                    <div class="item-label">{{$t("SELF_ISSUE")}}:</div>
                    <div class="item-content">
                        <template v-if="reservation.owner.self_issue === 1">{{$t("YES")}}</template>
                        <template v-else>{{$t("NO")}}</template>
                    </div>
                </div>

                <form-header v-if="reservation.owner.contacts_finance.email.length || reservation.owner.contacts_finance.phone.length">{{$t('CONTACTS')}}</form-header>

                <div class="item" v-if="reservation.owner.contacts_finance.email.length">
                    <div class="item-label">{{$t('FINANCE')}} (email):</div>
                    <div class="item-content">
                        <ul>
                            <li v-for="contact in reservation.owner.contacts_finance.email">
                                {{contact.description}} {{contact.description ? '/' : ''}} {{contact.contact}}
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="item" v-if="reservation.owner.contacts_finance.phone.length">
                    <div class="item-label">{{$t('FINANCE')}} (tel):</div>
                    <div class="item-content">
                        <ul>
                            <li v-for="contact in reservation.owner.contacts_finance.phone">
                                {{contact.description}} {{contact.description ? '/' : ''}} {{contact.contact}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
    import FormHeader from "@/components/app/form/FormHeader";
    export default {
        name: "OwnerModule",
        components: {FormHeader},
        props: {
            reservation: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
