<template>
    <b-form @submit.prevent="save" >
        <b-row>
            <b-col md="6" class="mb-4">
                <app-input-control :error-object="validationObject.checkin_date">
                    <template v-slot:input="data">
                <label>{{$t('CHECK_IN')}} - {{$t('CHECK_OUT')}}</label>
                <app-date-range-picker :state="data.state" v-model="period"></app-date-range-picker>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" class="mb-4">
                <app-input-control :error-object="validationObject.unit">
                    <template v-slot:input="data">
                <label>{{$t('UNIT')}}</label>
                <app-select :state="data.state" v-model="saveObject.unit" :options="unit_list"></app-select>
                    </template>
                </app-input-control>
            </b-col>
            <b-col md="6" class="mb-4">
                <label>{{$t('ADULTS')}}</label>
                <app-number-input v-model="saveObject.adult_no"></app-number-input>
            </b-col>
            <b-col md="6" class="mb-4">
                <label>{{$t('CHILDREN')}}</label>
                <app-number-input v-model="saveObject.child_no"></app-number-input>
            </b-col>
            <b-col md="6" class="mb-4">
                <label>{{$t('BABIES')}}</label>
                <app-number-input v-model="saveObject.baby_no"></app-number-input>
            </b-col>
            <b-col md="6" class="mb-4">
                <app-input-control :error-object="validationObject.total_price">
                    <template v-slot:input="data">
                <label>{{$t('PRICE')}}</label>
                <app-number-input :append="currency" :state="data.state" v-model="saveObject.total_price"></app-number-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <app-button-submit :loading="saving"></app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import {createSpecialOffer} from "@/services/inbox";
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {getUnitAvailableList, getUnitPaginatedList} from "@/services/unit";
import {getUnitMappingList} from "@/services/channel_manager";
import {
    TAG_ALREADY_EXIST,
    TAG_LABEL_VALIDATION_FAIL,
    TH_MESSAGE_THREAD_CREATE_SPECIAL_OFFER_DATE_FROM_VALIDATION_FAILED,
    TH_MESSAGE_THREAD_CREATE_SPECIAL_OFFER_DATE_TO_VALIDATION_FAILED,
    TH_MESSAGE_THREAD_CREATE_SPECIAL_OFFER_MAPPING_UNIT_DOES_NOT_EXIST,
    TH_MESSAGE_THREAD_CREATE_SPECIAL_OFFER_PRICE_GREATER_THAN_ZERO,
    TH_MESSAGE_THREAD_CREATE_SPECIAL_OFFER_UNIT_ID_VALIDATION_FAILED
} from "@/shared/error_codes";
import AppInputControl from "@/components/app/AppInputControl";
export default {
    name: "InboxCreateSpecialOffer",
    components: {AppInputControl, AppDateRangePicker, AppNumberInput, AppSelect, AppDatePicker, AppButtonSubmit},
    props:{
        threadObject:{
            type:Object,
        }
    },
    mixins: [getErrorMessage],
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        currency() {
            return this.company.hasOwnProperty('working_currency') ? this.company.working_currency.code : null
        },
    },
    data() {
        return {
            saving:false,
            period:{
                start:null,
                end:null
            },
            saveObject:{
                unit:null,
                checkin_date:null,
                checkout_date:null,
                adult_no:null,
                child_no:null,
                baby_no:null,
                total_price:null

            },
            unit_list:[],
            validationObject: {
                unit:{
                    input:[TH_MESSAGE_THREAD_CREATE_SPECIAL_OFFER_UNIT_ID_VALIDATION_FAILED,TH_MESSAGE_THREAD_CREATE_SPECIAL_OFFER_MAPPING_UNIT_DOES_NOT_EXIST]
                },
                checkin_date: {
                    input: [TH_MESSAGE_THREAD_CREATE_SPECIAL_OFFER_DATE_FROM_VALIDATION_FAILED],
                },
                total_price:[TH_MESSAGE_THREAD_CREATE_SPECIAL_OFFER_PRICE_GREATER_THAN_ZERO]
            }
        }
    },
    methods:{
        save(){
            if(this.period.start !== null){
                this.saveObject.checkin_date = this.period.start
                this.saveObject.checkout_date = this.period.end
            }
            this.saving = true
            createSpecialOffer(this.threadObject.id,this.saveObject).then(()=>{
                notifySuccess()
            },error=>{
                this.showErrorMessages(error,this.validationObject)

            }).finally(()=>{
                this.saving = false
            })
        }
    },
    watch:{
        threadObject:{
            handler(object){
                if(Object.keys(object).length > 0 && object.hasOwnProperty('property')){
                    getUnitPaginatedList({property_id:object.property.id}).then(response=>{
                        this.unit_list = response.data.items
                        //todo
           /*             let clearList = []
                       const unitList =  response.data.items
                       const unit_ids = response.data.items.map(el=>{
                           return el.id
                       })
                        getUnitMappingList({unit: unit_ids, distribution:object.distribution.id}).then(response => {
                            for(const mapping of response.data){
                                if(!unit_ids.includes(mapping.unit_id)){

                                }
                            }
                            this.unitMappingList = response.data
                        })*/
                    })

                }
                if(object.hasOwnProperty('unit')){
                    this.saveObject.unit = object.unit.hasOwnProperty('id') ? object.unit.id : null
                }

            },immediate:true
        }
    }
}
</script>

<style scoped>

</style>
