<template>
    <div>
        <company-whitelabel-form
            :company="companyObject"
        ></company-whitelabel-form>
    </div>
</template>

<script>
import CompanyWhitelabelForm from "@/components/company/forms/CompanyWhitelabelForm";
import {fetchCompany} from "@/services/company";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "Index",
    components: {CompanyWhitelabelForm},
    mixins: [getErrorMessage],
    data() {
        return {
            companyObject: null,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods: {
        getCompany() {
            fetchCompany(this.company.id).then(response => {
                this.companyObject = response.data
            }).catch(error => {
                this.showErrorMessages(error)
            })
        }
    },
    created() {
        this.getCompany()
    }
}
</script>

<style scoped>

</style>
