<template>
    <b-form @submit.prevent="save">
        <div class="mb-3 newFilter">
            <app-button v-if="newItemVisible" button_type="new" @click="addItem()">{{ $t('NEW_ITEM') }}</app-button>
        </div>
        <b-row>
            <b-col cols="12" lg="6">
                <draggable v-model="filterData" handle=".handle">
                    <div v-for="(field, index) in filterData" :key="index" class="mb-2">
                        <div class="d-flex align-items-center">
                            <b-button block variant="outline-primary" class="text-left"
                                      @click="expendRow(field)">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <i class="fa fa-bars mr-2 handle" style="cursor: move"></i>
                                        <b-badge pill variant="primary" class="mr-1 number-badge">
                                            {{ index + 1 }}
                                        </b-badge>
                                        {{ field.label }}
                                    </div>
                                    <i :class="`fa fa-caret-${field.visible ? 'up' : 'down'}`"></i>
                                </div>
                            </b-button>

                            <app-button
                                class="ml-2"
                                button_type="delete"
                                :show_text="false"
                                variant="link"
                                @click="removeFieldItem(filterData, index)">
                            </app-button>
                        </div>

                        <b-collapse v-model="field.visible" :id="`${field}-${index}`">
                            <div class="ml-3">
                                <b-row class="mt-3">
                                    <b-col cols="12" lg="6" class="mb-3 mb-lg-0">
                                        <app-input-control :error-object="validation.id">
                                            <template v-slot:input="data">
                                                <label>{{ $t('TYPE') }}</label>
                                                <b-form-input type="text"
                                                              :value="field.aid"
                                                              v-if="field.disabled"
                                                              :disabled="field.disabled">
                                                </b-form-input>
                                                <app-select v-else :options="filterType" v-model="field.aid"
                                                            :state="data.state"
                                                            :search-empty-item="false" text-field="id"></app-select>
                                            </template>
                                        </app-input-control>
                                    </b-col>

                                    <b-col cols="12" lg="6">
                                        <app-translation-input
                                            :label="$t('TITLE')"
                                            :context-label="$t('FILTERS')"
                                            v-model="field.label"
                                            :lang_id="field.id"
                                            :languages="website.supported_languages">
                                        </app-translation-input>
                                    </b-col>
                                </b-row>

                                <form-header>{{ $t('OPTIONS') }}</form-header>

                                <draggable v-model="field.options" handle=".handleO">
                                    <div v-for="(option, index) in field.options" class="mb-3" :key="index">
                                        <div class="d-flex justify-content-between align-items-center"
                                             style="max-width: 50rem">
                                            <b-button block variant="outline-primary" class="text-left"
                                                      @click="option.visible=!option.visible">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="fa fa-bars mr-2 handleO" style="cursor: move"></i>
                                                        <b-badge pill variant="primary" class="mr-1 number-badge">
                                                            {{ index + 1 }}
                                                        </b-badge>
                                                        <span v-if="field.aid === 'price'">
                                                                {{ priceOptions[index].min }}{{
                                                                priceOptions[index].max
                                                            }}
                                                            </span>
                                                        <span v-else-if="field.aid === 'type'">{{
                                                                option.value.length > 1
                                                                    ? option.label
                                                                    : option.value.length ? option.value[0].label : '' | capitalize
                                                            }}</span>
                                                        <span v-else-if="field.aid === 'amenities'">{{
                                                                getLabel(amenities, option.value)
                                                            }}</span>
                                                        <span v-else-if="field.aid === 'bedrooms'">{{
                                                                getBedroomLabel(option.value, option.or_more)
                                                            }}</span>
                                                        <span v-else-if="field.aid === 'bathrooms'">{{
                                                                getBedroomLabel(option.value, option.or_more)
                                                            }}</span>
                                                        <span v-else>{{ option.value }}</span>
                                                    </div>
                                                    <i :class="`fa fa-caret-${option.visible ? 'up' : 'down'}`"></i>
                                                </div>
                                            </b-button>
                                            <app-button
                                                class="ml-2"
                                                button_type="delete"
                                                :show_text="false"
                                                variant="link"
                                                @click="removeOptionsItem(field.options, index)">
                                            </app-button>
                                        </div>
                                        <b-collapse v-model="option.visible" :id="`${option}-${index}`">
                                            <template v-if="field.aid === 'type'">
                                                <b-row class="mt-3">
                                                    <b-col cols="12" xl="6" class="mb-3">
                                                        <app-input-control :error-object="validation.type">
                                                            <template v-slot:input="data">
                                                                <label>{{ $t('UNIT_TYPE') }}</label>
                                                                <app-select
                                                                    mode="multiselect"
                                                                    :options="unitTypes"
                                                                    text-field="label"
                                                                    :search-empty-item="false"
                                                                    :state="data.state"
                                                                    :value="option.value.map(el => el.id)"
                                                                    @input="input => setMultiselectValues(input, option, unitTypes)">
                                                                </app-select>
                                                            </template>
                                                        </app-input-control>
                                                    </b-col>

                                                    <b-col cols="12" xl="6" class="mb-3" v-if="option.value.length > 1">
                                                        <app-translation-input
                                                            :disabled="option.value.length < 2"
                                                            v-model="option.label"
                                                            :lang_id="option.lang_id"
                                                            :languages="website.supported_languages"
                                                            :label="$t('LABEL')">
                                                        </app-translation-input>
                                                    </b-col>
                                                </b-row>
                                            </template>

                                            <b-col v-if="field.aid === 'amenities'" cols="6" xl="6" class="mb-3 mt-3">
                                                <app-input-control :error-object="validation.amenities">
                                                    <template v-slot:input="data">
                                                        <label>{{ $t('AMENITIES') }}</label>
                                                        <app-select v-if="amenitiesSearch"
                                                                    mode="search"
                                                                    :options="amenities"
                                                                    text-field="label"
                                                                    :search-empty-item="false"
                                                                    v-model="option.value"
                                                                    :search="searchAmenities"
                                                                    :state="data.state"
                                                        ></app-select>
                                                        <app-select v-else :options="amenities" text-field="label"
                                                                    :search-empty-item="false" :state="data.state"
                                                                    v-model="option.value"></app-select>
                                                    </template>
                                                </app-input-control>
                                            </b-col>
                                            <div v-if="field.aid === 'price'">
                                                <b-row class="mt-3 pl-3">
                                                    <b-col class="mb-3 col-lg-4">
                                                        <app-input-control :error-object="validation.price">
                                                            <template v-slot:input="data">
                                                                <label>{{ $t('FROM') }}</label>
                                                                <b-form-input :state="data.state" type="number"
                                                                              :value="getPriceOption('min', option.value)"
                                                                              @input="value => setPriceOption('min', value, option)">
                                                                </b-form-input>
                                                            </template>
                                                        </app-input-control>
                                                    </b-col>
                                                    <b-col class="mb-3 col-lg-4">
                                                        <app-input-control :error-object="validation.price">
                                                            <template v-slot:input="data">
                                                                <label>{{ $t('TO') }}</label>
                                                                <b-form-input :state="data.state" type="number"
                                                                              :value="getPriceOption('max', option.value)"
                                                                              @input="value => setPriceOption('max', value, option)">
                                                                </b-form-input>
                                                            </template>
                                                        </app-input-control>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <div v-if="field.aid === 'bedrooms' || field.aid === 'bathrooms'"
                                                 class="mb-3 col-md-8">
                                                <label class="mt-3">{{
                                                        field.aid === 'bedrooms' ? $t('BEDROOMS') : $t('BATHROOMS')
                                                    }}</label>
                                                <b-row class="mb-3">
                                                    <b-col md="8">
                                                        <app-input-control
                                                            :error-object="field.aid === 'bedrooms' ? validation.bedrooms : validation.bathrooms">
                                                            <template v-slot:input="data">
                                                                <b-form-input type="number" v-model="option.value"
                                                                              :state="data.state"></b-form-input>
                                                            </template>
                                                        </app-input-control>
                                                    </b-col>
                                                    <b-col
                                                        class="d-flex justify-content-between align-items-center pl-0">
                                                        <b-form-checkbox @change="changeOrMore(option.or_more, $event)"
                                                                         v-model="option.or_more">{{ $t('OR_MORE') }}
                                                        </b-form-checkbox>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-collapse>
                                    </div>
                                </draggable>

                                <app-button
                                    class="d-block mb-3"
                                    button_type="new"
                                    @click="addOptionItem(field.options, field.aid)">
                                    {{ $t('NEW_ITEM') }}
                                </app-button>

                            </div>
                        </b-collapse>
                    </div>
                </draggable>
            </b-col>
        </b-row>

        <b-row class="mt-3">
            <b-col>
                <app-button-submit :loading="loading"></app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import {fetchAmenities, fetchPageFilters, getUnitType, updatePageFilters} from "@/services/direct_booking/website";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppInputControl from "@/components/app/AppInputControl";
import {
    WEBSITE_FILTERS_ID_NOT_FOUND,
    WEBSITE_FILTERS_PRICE_EMPTY,
    WEBSITE_FILTERS_PRICE_FROM_NOT_FOUND,
    WEBSITE_FILTERS_PRICE_TO_NOT_FOUND,
    WEBSITE_FILTERS_BEDROOMS_EMPTY,
    WEBSITE_FILTERS_BATHROOMS_EMPTY,
    WEBSITE_FILTERS_TYPE_EMPTY,
    WEBSITE_FILTERS_AMENITIES_EMPTY,
} from "@/shared/error_codes";

export default {
    name: "WebsitePageFiltersForm",
    mixins: [getErrorMessage],
    components: {
        AppInputControl,
        "AppTranslationInput": () => import("@/components/app/translation/AppTranslationInput"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "FormHeader": () => import("@/components/app/form/FormHeader"),
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "draggable": () => import("vuedraggable")
    },

    data() {
        return {
            filterData: [],
            unitTypes: [],
            amenities: [],
            selectedType: null,
            collapseVisible: false,
            newItemVisible: false,
            newOption: false,
            loading: false,
            changedLang: [],
            filterType: [
                {id: 'price'},
                {id: 'type'},
                {id: 'bedrooms'},
                {id: 'bathrooms'},
                {id: 'amenities'},
            ],
            priceFrom: 0,
            priceTo: 0,
            selectedUnitType: null,
            selectedAmenities: null,
            selectedBedroom: null,
            selectedBathroom: null,
            orMoreStatus: false,
            newFrom: null,
            newTo: null,
            newBathBed: null,
            addPlus: false,
            newAmenity: null,
            newUnitType: null,
            newItemDisabled: false,
            amenitiesSearch: false,
            validation: {
                id: WEBSITE_FILTERS_ID_NOT_FOUND,
                price: [WEBSITE_FILTERS_PRICE_EMPTY, WEBSITE_FILTERS_PRICE_FROM_NOT_FOUND, WEBSITE_FILTERS_PRICE_TO_NOT_FOUND],
                bedrooms: WEBSITE_FILTERS_BEDROOMS_EMPTY,
                bathrooms: WEBSITE_FILTERS_BATHROOMS_EMPTY,
                type: WEBSITE_FILTERS_TYPE_EMPTY,
                amenities: WEBSITE_FILTERS_AMENITIES_EMPTY
            }
        }
    },

    computed: {
        website() {
            return this.$store.getters['website/getWebsite']
        },
        priceOptions: {
            get() {
                const priceRangeFilter = this.filterData.find(el => el.aid === 'price')
                if (priceRangeFilter) {
                    return priceRangeFilter.options.map(option => {
                        if (option.value !== null) {
                            const min = this.website.currency.symbol + option.value.split(",")[0]
                            const max = option.value.split(",")[1] === '0' ? '+' : '— ' + this.website.currency.symbol + option.value.split(",")[1]
                            return {
                                visible: option.visible,
                                min,
                                max
                            }
                        } else {
                            return {
                                visible: option.visible,
                                min: null, max: null
                            }
                        }
                    })
                }
            },
            set(values) {
                const index = this.filterData.findIndex(el => el.aid === 'price')
                this.filterData[index].options = values.reduce((result, current) => {
                    const value = `${current.min ? current.min : '0'},${current.max ? current.max : '0'}`
                    result.push({
                        value: value,
                        visible: current.visible
                    })
                    return result
                }, [])
            }
        },
    },

    methods: {
        setMultiselectValues(input, option, list) {
            option.value = list.filter(el => input.includes(el.id)).map(({id, label}) => ({id, label}))
        },
        getData() {
            fetchPageFilters(this.website.id).then(response => {
                this.filterData = response.data
                if (response.data.length < 5) {
                    this.newItemVisible = true
                }
                response.data.forEach(el => {
                    this.filterType = this.filterType.filter(obj => obj.id !== el.aid);
                })
            }, error => {
                this.showErrorMessages(error)
            })
        },
        save() {
            this.loading = true
            let data = this.prepareForSave()
            let lang_list = this.changedLang
            updatePageFilters(this.website.id, {data, lang_list}).then(response => {
                notifySuccess()
                this.getData()
                this.loading = false
            }, error => {
                this.loading = false
                this.showErrorMessages(error, this.validation)
            })
        },
        removeFieldItem(list, index) {
            list.splice(index, 1);
            this.selectedType = null
            list.length < 5 ? this.newItemVisible = true : this.newItemVisible = false
        },
        removeOptionsItem(list, index) {
            list.splice(index, 1);
            this.selectedType = null
        },
        prepareForSave() {
            let finalData = []
            this.changedLang = []
            this.filterData.forEach(el => {
                let options = []
                this.changedLang.push({id: el.id, labelEn: el.label, aid: el.aid})
                el.options.forEach(opt => {
                    if (el.aid === 'amenities') {
                        options.push({value: !opt.value || !isNaN(opt.value) ? opt.value : opt.value.id})
                    } else if (el.aid === 'type') {
                        if (!(opt.value.length > 1 && !opt.label)) {
                            options.push({
                                value: opt.value.map(value => value.id).join(','),
                                lang_id: opt.value.length > 1 ? opt.lang_id : null,
                                label: opt.label
                            })
                        }
                    } else if (el.aid === 'bedrooms' || el.aid === 'bathrooms') {
                        options.push({value: opt.or_more ? opt.value + '+' : opt.value})
                    } else {
                        options.push({value: opt.value})
                    }
                })
                finalData.push({
                    id: el.aid,
                    type: el.aid === 'bedrooms' || el.aid === 'bathrooms' ? 'AND' : 'OR',
                    lang_id: el.id,
                    options: options.filter(function (e) {
                        return e != null;
                    })
                })
            })
            return finalData
        },
        addItem() {
            this.filterData.forEach(el => el.visible = false)
            this.filterData.push({
                aid: null, id: null, label: null, options: [],
                type: null, visible: true, disabled: false
            })
            this.filterData.length < 5 ? this.newItemVisible = true : this.newItemVisible = false
        },
        addOptionItem(list, aid) {
            list.forEach(el => el.visible = false)
            if (aid === 'price') {
                list.push({value: null, visible: true, or_more: false})
            }
            if (aid === 'bathrooms' || aid === 'bedrooms') {
                list.push({value: null, visible: true, or_more: false})
            }
            if (aid === 'amenities') {
                list.push({value: null, visible: true, or_more: false})
            }
            if (aid === 'type') {
                list.push({value: [], lang_id: null, label: null, visible: true, or_more: false})
            }
        },
        expendRow(list) {
            list.visible = !list.visible
        },
        getLabel(list, id) {
            const find = list.find(el => el.id === id)
            return find ? find.label : ""
        },
        getPriceOption(boundary, value) {
            return value !== null && value !== undefined && value.includes(',') ? value.split(',')[boundary === 'min' ? 0 : 1] : null
        },
        setPriceOption(boundary, value, option) {
            if (value !== null && value !== undefined) {
                if (option.value) {
                    const v = option.value.split(',')
                    if (boundary === 'min') {
                        v[0] = value
                        option.value = v.join()
                    } else {
                        v[1] = value
                        option.value = v.join()
                    }
                } else {
                    if (boundary === 'min') {
                        option.value = `${value},0`
                    } else {
                        option.value = `0,${value}`
                    }
                }

            }
        },
        setCheckbox(option, event) {
            if (event) {
                option.value = option.value + '+'
            } else {
                option.value = option.value.includes('+') ? option.value.split('+')[0] : option.value
            }
        },
        getBedroomLabel(option, or_more) {
            return or_more ? option + ' +' : option
        },
        changeOrMore(or_more, event) {
            or_more = event
        },
        searchAmenities(value) {
            return fetchAmenities(this.website.id, {name: value}).then(response => {
                return {data: response.data}
            })
        }
    },

    created() {
        getUnitType(this.website.id).then(response => {
            this.unitTypes = response.data
        }, error => {
            this.showErrorMessages(error)
        })
        fetchAmenities(this.website.id).then(response => {
            this.amenities = response.data
            response.data.length > 10 ? this.amenitiesSearch = true : this.amenitiesSearch = false
        }, error => {
            this.showErrorMessages(error)
        })
        this.getData()
    },

    watch: {
        filterData: {
            handler() {
                this.filterType = [
                    {id: 'price'},
                    {id: 'type'},
                    {id: 'bedrooms'},
                    {id: 'bathrooms'},
                    {id: 'amenities'},
                ]

                this.filterData.forEach(el => {
                    this.filterType = this.filterType.filter(obj => obj.id !== el.aid);
                })
            }
        }
    }


}
</script>

<style scoped>

.newFilter {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}

</style>
