<template>
    <div>
        <b-form @submit.prevent="save">
            <b-row>
                <b-col>
                    <label>{{$t('NAME')}}</label>
                    <b-form-input type="text" v-model="group_name"></b-form-input>
                    <validation-description :show="$v.group_name.$dirty && $v.group_name.$invalid">
                        {{$t('FIELD_MANDATORY')}}
                    </validation-description>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mt-3">
                    <submit-button :loading="groupNameSave"
                                   :disabled="$v.group_name.$invalid || !edited"></submit-button>
                </b-col>
            </b-row>
        </b-form>
        <b-row>


                <app-access-control cols="12" class="mt-4"
                                    :access_control_context="{function:C_UNIT_CONTINGENT_SETUP_E,key:AC_UNIT_CONTINGENT}"
                                    @AccessControlEvent="addToAccessControlCounter()">
                    <template v-slot="props">

                        <table class="table">
                            <thead v-if="contingent_unit_list.length > 0">
                            <tr>
                                <th colspan="2" scope="col">{{$t('NAME')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <root-item :disabled="props.disabled" :tooltip_message="props.messages.length > 0 ? props.messages[0].message : ''" v-if="unit" :unit="unit"></root-item>
                            <contingent-item :loading="loadingSave" :key="index"
                                             :disabled="props.disabled" :tooltip_message="props.messages.length > 0 ? props.messages[0].message : ''"
                                             v-for="(contingent, index) in contingent_unit_list"
                                             :contingent="contingent" @updateContingent="updateContingent"
                                             @deleteContingentUnit="deleteContingentUnit"></contingent-item>
                            <contingent-new-item :disabled="props.disabled" :tooltip_message="props.messages.length > 0 ? props.messages[0].message : ''" @addContingents="addNewContingents"></contingent-new-item>
                            </tbody>
                        </table>
                    </template>
                </app-access-control>

        </b-row>
    </div>
</template>

<script>

    import SubmitButton from '@/components/app/AppButton/AppButtonSubmit'
    import DeleteButton from '@/components/app/AppButton/AppButtonDelete'
    import ContingentItem from '@/components/unit/contingent/ContingentItem'
    import ContingentNewItem from '@/components/unit/contingent/ContingentNewItem'
    import {toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {updateSoloUnitType, createSoloUnitType, updateName} from '@/services/unit/index'
    import {setContingentUnits, deleteUnit} from '@/services/unit/index'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import {
        required, minLength
    } from 'vuelidate/lib/validators'
    import RootItem from "@/components/unit/contingent/RootItem";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_UNIT_CONTINGENT} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {C_UNIT_CONTINGENT_SETUP_E} from "@/shared/component_permission";

    export default {
        name: "unit-contingent-form",
        props: {
            contingent_unit_list: {
                type: Array
            },
            solo_unit_type: {
                type: Object

            }
        },
        mixins: [getErrorMessage,AccessControlComponent],
        data() {
            return {
                loadingSave: false,
                groupNameSave: false,
                group_name: null,
                number_of_units: null,
                fields: [

                    {key: 'name', label: this.$t('NAME')},
                    {key: 'edit', label: '', tdClass: 'text-right'},
                ],
                access_control_fetch_key:AC_UNIT_CONTINGENT,
                AC_UNIT_CONTINGENT,
                C_UNIT_CONTINGENT_SETUP_E
            }
        },
        components: {
            AppAccessControl,
            RootItem,
            SubmitButton,
            DeleteButton,
            ContingentItem,
            ContingentNewItem,
            ValidationDescription
        },
        computed: {
            edited() {
                return this.group_name !== this.solo_unit_type.type_name
            },
            unit() {
                return this.$store.getters['getUnit']
            },
        },
        methods: {
            deleteContingentUnit(value) {
                deleteUnit(value.id).then(response => {
                    if (response.data.success) {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.DELETE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })
                        this.$emit('updateContingentList')
                    }
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.DELETE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.DELETE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                })
            },
            addNewContingents(value) {

                setContingentUnits(Number(this.$route.params.unit_id), {number_of_units: value}).then(response => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.$emit('updateContingentList', response.data.items)
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                })
            },
            save() {
                if (this.solo_unit_type.id) {
                    this.updateSoloType()
                } else {
                    this.createSoloType()
                }
            },
            createSoloType() {
                this.groupNameSave = true
                createSoloUnitType(Number(this.$route.params.unit_id), {name: this.group_name}).then(response => {
                    this.$emit('updateSolo', response.data.type_name)
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(() => {
                    this.groupNameSave = false
                })
            },


            updateSoloType() {
                this.groupNameSave = true
                updateSoloUnitType(this.solo_unit_type.id, Number(this.$route.params.unit_id), {name: this.group_name}).then(response => {
                    this.$emit('updateSolo', response.data.type_name)
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(() => {
                    this.groupNameSave = false
                })

            },
            updateContingent(value) {
                this.loadingSave = value.id
                updateName(value.id, {name: value.name}).then(() => {
                    this.$emit('updateContingentList')
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.loadingSave = false

                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                    this.loadingSave = false

                })
            }
        },
        validations: {
            group_name: {
                required,
                minLength: minLength(2)
            }
        },
        created() {
            this.group_name = this.solo_unit_type.type_name
                this.access_control_general_context = {
                    unit: this.$route.params.unit_id,
                    company: this.$store.getters['user/getCompany']
                }
        }
    }
</script>

<style scoped>

</style>
