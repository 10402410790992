<template>
    <div>
        <form @submit.prevent="search" class="filter" @reset.prevent="resetForm" autocomplete="off">
            <b-row>
                <b-col md="2" class="mb-3">
                    <label>{{$t('NAME')}}</label>
                    <b-input v-model="name" type="text"></b-input>
                </b-col>

                <b-col md="2" class="mb-3">
                    <app-select :label="$t('DISTRIBUTION')" mode="multiselect" v-model="distributions"
                                :options="distribution_list"
                                :search-clear="true"></app-select>
                </b-col>
                <b-col md="3" class="mb-3">
                    <app-button-submit class="filter-submit-wrapper" :inline="true" :disabled="searchLoader"
                                       :loading="loading" variant="primary" button_type="search">
                        {{$t("SEARCH") }}
                    </app-button-submit>
                    <app-button-reset :loading="loaderReset" :disabled="searchLoader" :inline="true"
                                      class="pl-2 ml-2"></app-button-reset>

                </b-col>
                <b-col md="5">
                    <app-button
                        :key="'new_rate_plan_key_' + getSystemNonRef"
                        :access_control_context="{function:C_UNIT_RATE_PLAN_NEW_E,unit:unit_id,key:AC_RATE_PLAN}"
                        @AccessControlEvent="addToAccessControlCounter()"
                        class="pull-right filter-submit-wrapper mr-3"
                        variant="primary" button_type="new"
                        @click="newRatePlanPanelState">{{$t("NEW_RATE_PLAN")}}
                    </app-button>
                    <app-button
                        :key="'apply_rate_plans_key_' + getSystemNonRef"
                        :access_control_context="{function:C_UNIT_RATE_PLAN_APPLY_E,unit: unit_id, key:AC_RATE_PLAN}"
                        @AccessControlEvent="addToAccessControlCounter()"
                        button_type="new"
                        class="pull-right mr-3 filter-submit-wrapper" variant="primary"
                        @click="copyRatePlanState">
                        {{$t('APPLY_RATE_PLAN')}}
                    </app-button>
                    <app-button :key="'turn_on_non_ref_key_' + getSystemNonRef"
                                :loading="loadingAddNrf"
                                :v_show="checkPermission(C_UNIT_RATE_PLAN_TURN_ON_NRF_E)"
                                :access_control_context="{function:C_UNIT_RATE_PLAN_TURN_ON_NRF_E,unit: unit_id, key:AC_RATE_PLAN}"
                                @AccessControlEvent="addToAccessControlCounter()"
                                class="pull-right filter-submit-wrapper mr-3"
                                @click="addSystemNonRef"> {{$t('TURN_ON_NON_REF')}}
                    </app-button>
                    <app-button :key="'turn_on_non_ref_modal_key_' + getSystemNonRef"
                                :loading="loadingAddNrf"
                                :v_show="checkPermission(C_UNIT_RATE_PLAN_TURN_ON_NRF_MODAL_E)"
                                :access_control_context="{function: C_UNIT_RATE_PLAN_TURN_ON_NRF_MODAL_E, unit: unit_id, key: AC_RATE_PLAN}"
                                @AccessControlEvent="addToAccessControlCounter()"
                                class="pull-right filter-submit-wrapper mr-3"
                                @click="addSystemNonRefConfirmation">
                        {{$t('TURN_ON_NON_REF')}}
                    </app-button>
                    <app-button :key="'copy_rate_plan_restrictions_key_' + getSystemNonRef"
                                :v_show="checkPermission(C_UNIT_RATE_PLAN_COPY_RESTRICTIONS_E)"
                                :access_control_context="{function: C_UNIT_RATE_PLAN_COPY_RESTRICTIONS_E, unit: unit_id, company: company, key: AC_RATE_PLAN}"
                                @AccessControlEvent="addToAccessControlCounter()"
                                class="pull-right filter-submit-wrapper mr-3"
                                @click="copyRestrictionsPanelState">
                        {{$t('COPY_RESTRICTIONS')}}
                    </app-button>
                </b-col>
                <app-confirmation-dialog :show="showAddNonRefConfirmation"
                                         :title="$t('ADD_NRF_SYSTEM_RATE_PLAN_CONFIRMATION_TITLE')"
                                         @confirm="addSystemNonRef(true)"
                                         @cancel="showAddNonRefConfirmation = false">
                    <b-form-group>
                        <b-form-checkbox :unchecked-value="0"
                                         :value="1"
                                         v-model="applyRestrictions">
                            {{$t('APPLY_DEFAULT_RESTRICTIONS_FROM_STANDARD_RATE_PLAN')}}
                        </b-form-checkbox>
                    </b-form-group>
                </app-confirmation-dialog>
            </b-row>

        </form>
    </div>
</template>

<script>

    import AppButton from "@/components/app/AppButton/AppButton";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppAside from '@/components/app/form/AppAside';
    import {AC_RATE_PLAN} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {
        C_UNIT_RATE_PLAN_APPLY_E,
        C_UNIT_RATE_PLAN_NEW_E,
        C_UNIT_RATE_PLAN_TURN_ON_NRF_E,
        C_UNIT_RATE_PLAN_TURN_ON_NRF_MODAL_E,
        C_UNIT_RATE_PLAN_COPY_RESTRICTIONS_E
    } from "@/shared/component_permission";
    import {SYSTEM_NON_REFUNDABLE_RATE, SYSTEM_STANDARD_RATE} from "@/shared/constants";
    import {
        EventBus,
        GE_RATE_PLAN_NRF_SIDE_PANEL,
        GE_RATE_PLAN_COPY_SIDE_PANEL,
        GE_RATE_PLAN_NEW_SIDE_PANEL, GE_RATE_PLAN_COPY_RESTRICTIONS_WIZARD_SIDE_PANEL
    } from "@/shared/EventBus";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {getDistributionList} from "@/services/distribution";
    import {cloneDeep} from "lodash";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";


    export default {
        name: "RatePlanListFilter",
        mixins: [AccessControlComponent],
        components: {
            AppConfirmationDialog,
            AppSelect, AppButtonSubmit, AppButtonReset, AppAccessControl, AppButton, AppAside
        },
        props: {
            unit_id:{
                type:Number,
                default:null
            },
        },
        data() {
            return {
                AC_RATE_PLAN,
                C_UNIT_RATE_PLAN_TURN_ON_NRF_E,
                C_UNIT_RATE_PLAN_TURN_ON_NRF_MODAL_E,
                C_UNIT_RATE_PLAN_APPLY_E,
                C_UNIT_RATE_PLAN_NEW_E,
                C_UNIT_RATE_PLAN_COPY_RESTRICTIONS_E,
                access_control_fetch_key: AC_RATE_PLAN,
                SYSTEM_STANDARD_RATE,
                SYSTEM_NON_REFUNDABLE_RATE,
                loadingAddNrf: false,
                EVENT_TABLE_SEARCH: 'getRatePlanData',
                distribution_list: [],
                loading: false,
                loaderReset: false,
                searchLoader: false,
                showAddNonRefConfirmation: false,
                applyRestrictions: null,
                access_control_components: 5,
            }

        },
        methods: {
            searchDistribution() {
                getDistributionList({company: this.company, for_reservation: 1, sorted: 1}).then(res => {
                    this.distribution_list = res.data
                })
            },
            resetForm() {
                this.loaderReset = true
                this.name = ''
                this.distributions = []
                EventBus.$emit('filterRatePlan', {
                    distribution: this.distributions,
                    name: this.name,
                })
            },
            search(filter) {
                this.filter = filter
                EventBus.$emit('filterRatePlan', {
                    distribution: this.distributions,
                    name: this.name
                })
            },
            newRatePlanPanelState() {
                EventBus.$emit(GE_RATE_PLAN_NEW_SIDE_PANEL)
            },
            copyRatePlanState() {
                EventBus.$emit(GE_RATE_PLAN_COPY_SIDE_PANEL)
            },
            addSystemNonRef(restrictions_confirmation = false) {
                if (!restrictions_confirmation) {
                    EventBus.$emit(GE_RATE_PLAN_NRF_SIDE_PANEL, {
                        unit: Number(this.unit_id),
                        type: SYSTEM_NON_REFUNDABLE_RATE
                    })
                } else {
                    EventBus.$emit(GE_RATE_PLAN_NRF_SIDE_PANEL, {
                        unit: Number(this.unit_id),
                        type: SYSTEM_NON_REFUNDABLE_RATE,
                        apply_restrictions: this.applyRestrictions
                    })
                }
            },
            addSystemNonRefConfirmation() {
                this.applyRestrictions = 1
                this.showAddNonRefConfirmation = true
            },
            copyRestrictionsPanelState() {
                EventBus.$emit(GE_RATE_PLAN_COPY_RESTRICTIONS_WIZARD_SIDE_PANEL)
            },

        },
        computed: {
            company() {
                return this.$store.getters['user/getCompany']
            },
            property() {
                return this.$store.getters['property/getProperty']
            },
            distributions: {
                get() {
                    let filter = this.$store.getters['pricing/getSearchFilter']
                    return filter.hasOwnProperty('distribution') && filter.distribution ? filter.distribution : []
                },
                set(value) {
                    this.$store.commit('pricing/setSearchFilterItem', {item: 'distribution', value})
                }
            },
            name: {
                get() {
                    let filter = this.$store.getters['pricing/getSearchFilter']
                    return filter.hasOwnProperty('distribution') && filter.name ? filter.name : null
                },
                set(value) {
                    this.$store.commit('pricing/setSearchFilterItem', {item: 'name', value})
                }
            },
            getSystemNonRef() {
                return this.$store.getters['getSystemNonRef']
            },

        },
        watch: {
            '$route.params.id': {
                handler(property_id) {
                    if (!this.property.hasOwnProperty('id')) {
                        this.$store.dispatch('property/actionSetProperty', property_id)
                    }
                },
                immediate: true
            },
            unit_id: {
                handler(value) {
                    this.$nextTick(() =>{
                        EventBus.$emit('filterRatePlan', {
                            distribution: this.distributions,
                            name: this.name,
                            unit:value
                        })
                    })
                },
                immediate: true
            },
        },

        mounted() {
            this.initialFilter = cloneDeep(this.filter)
            EventBus.$on('loadingRatePlanList', (value) => {
                this.loading = value
                this.loaderReset = false
            })
            EventBus.$on('loadingNrf', (value) => {
                this.loadingAddNrf = value
                if (!value) {
                    this.showAddNonRefConfirmation = false
                }
            })
        },
        beforeDestroy() {
            EventBus.$off('loadingRatePlanList')
            EventBus.$off('loadingNrf')
        },
        created() {
            this.searchDistribution()
        },
    }
</script>

<style scoped>

</style>
