<template>
    <form v-if="!filterHidden" @submit.prevent="search" @reset.prevent="resetForm" class="filter" autocomplete="off">
        <b-row>
            <b-col md="2" class="mb-3">
                <label>{{$t("FIRST_NAME")}}</label>
                <b-input v-model="filter.first_name" type="text"></b-input>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("LAST_NAME")}}</label>
                <b-input v-model="filter.last_name" type="text"></b-input>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("USERNAME_EMAIL")}}</label>
                <b-input v-model="filter.username" type="text"></b-input>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("ROLE")}}</label>
                <app-select mode="multiselect"
                            :options="rolesList"
                            text-field="name"
                            v-model="filter.role">
                </app-select>
            </b-col>

            <b-col>
                <app-submit class="filter-submit-wrapper" :inline="true" :disabled="loading" :loading="loaderSubmit" variant="primary" button_type="search">
                    {{$t("SEARCH") }}
                </app-submit>
                <app-button-reset :disabled="loading" :loading="loaderReset" :inline="true"  class="pl-2 ml-2" ></app-button-reset>
            </b-col>
        </b-row>

    </form>
</template>

<script>
    import {pickBy, cloneDeep} from 'lodash'
    import AppSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getUserRolesList} from "@/services/user";
    import {DEFAULT_PAGINATION_LIMIT, LS_PRESETS} from "@/shared/constants";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";

    export default {
        name: "UserFilter",
        components: {
            AppButtonReset,
            AppSubmit,
            AppSelect
        },
        data() {
            return {
                filter: {
                    page: 1,
                    limit: 10,
                    first_name: null,
                    last_name: null,
                    username: null,
                    role: [],
                    company: null
                },
                rolesList: [],
                initialFilter: {},
                filterHidden: false,
                reset:0,
                loaderSubmit:false,
                loaderReset:false
            }
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            role(value) {
                this.filter.role = value.hasOwnProperty('id') ? value.id : null
            },
            loading:{
                handler(value){
                    if(this.reset){
                        this.loaderReset = value
                    } else {
                        this.loaderSubmit = value
                    }
                }
            }
        },
        methods: {
            search() {
                this.reset = 0
                this.$emit('search', pickBy(this.filter, item => item !== null))
            },
            resetForm() {
                this.reset = 1
                this.filter = cloneDeep(this.initialFilter)
                this.$emit('search', pickBy(this.filter, item => item !== null))
                this.roles = []
            }
        },
        created() {
            this.filter.company = this.$store.getters[`user/getCompany`];
            this.initialFilter = cloneDeep(this.filter);
            getUserRolesList({company_id: this.$store.getters[`user/getCompany`]}).then(response => {
                this.rolesList = response.data;
            }, () => {
                this.filter.role = [];
            })


            let storagePresets = localStorage.getItem(LS_PRESETS)
            if (storagePresets) {
                storagePresets = JSON.parse(storagePresets)
                if (storagePresets.hasOwnProperty('users') && storagePresets.users <= DEFAULT_PAGINATION_LIMIT) {

                    this.filterHidden = true
                    this.search()
                }
            }
        }
    }
</script>

<style scoped>

</style>
