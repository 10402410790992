<template>
    <b-form v-if="checkPermission(C_UNIT_DEFAULT_RESTRICTIONS_V)" @submit.prevent="save">
        <b-row v-if="title">
            <b-col>
                <form-header>{{ title }}</form-header>
            </b-col>
        </b-row>

        <b-row>
            <b-col lg="4" class="mb-4" :key="restriction.id" v-if="restriction.id < 4" v-for="(restriction) in restrictions">
                <component
                        :restriction="restriction"
                        v-model="formData[restriction.id]"
                        :allowed_values="restriction.allowed_values.allowed_values"
                        :is="restriction.allowed_values.element_type + 'Element'">
                </component>

            </b-col>
        </b-row>
        <b-row :key="restriction.id+'_res_key'"  v-if="restriction.id > 3" v-for="(restriction) in restrictions">
            <b-col  class="mb-4">
                <component
                    :restriction="restriction"
                    v-model="formData[restriction.id]"
                    :allowed_values="restriction.allowed_values.allowed_values"
                    :is="restriction.allowed_values.element_type + 'Element'">
                </component>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <save-button :c_permission="c_permission_unit_default_restriction_e"
                             :disabled="!saveButtonState"></save-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import SelectRestrictionFormElement
        from "@/components/unit/calendar/forms/restriction/SelectRestrictionFormElement";
    import CheckboxRestrictionFormElement
        from "@/components/unit/calendar/forms/restriction/CheckboxRestrictionFormElement";
    import {cloneDeep, find, isEqual, isNull, reduce, filter} from "lodash";
    import SaveButton from "@/components/app/AppButton/AppButtonSubmit";
    import {getRestrictionDefaultList, updateDefaultRestrictions} from "@/services/pricing/restriction";
    import {toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import Routes from '@/router/routeNames'
    import {C_UNIT_DEFAULT_RESTRICTIONS_V, C_UNIT_DEFAULT_RESTRICTIONS_E} from "@/shared/component_permission";
    import {SALES_ENABLED} from "@/shared/constants";
    import FormHeader from "@/components/app/form/FormHeader";

    export default {
        name: "DefaultRestrictionsForm",
        components: {
            FormHeader,
            'selectElement': SelectRestrictionFormElement,
            'checkboxElement': CheckboxRestrictionFormElement,
            SaveButton
        },
        mixins: [getErrorMessage],
        data() {
            return {
                formData: {},
                initialFormData: {},
                saveButtonState: true,
                c_permission_unit_default_restriction_e: C_UNIT_DEFAULT_RESTRICTIONS_E,
                C_UNIT_DEFAULT_RESTRICTIONS_V
            }
        },
        props: {
            title: {
                type: String,
                default: null
            },
            context: {
                type: Object,
                validator(value) {
                    return value.hasOwnProperty('object_type') && value.hasOwnProperty('object_id');
                }
            }
        },
        computed: {
            // RESTRICTION SALES_ENABLED IS REMOVED
            restrictions() {
                let r = this.$store.getters[`shared/getRestrictions`]
                return r ? filter(r, (r) => r.id !== SALES_ENABLED) : null
            }
        },
        watch: {
            context: {
                handler(value) {
                    this.formData = {}
                    this.saveButtonState = false
                    this.mapValuesToRestrictions(value.object_type, value.object_id, this.restrictions)
                }
            }
        },
        methods: {
            hasPermissionShow() {
                return this.$store.getters['user/getPermission'](C_UNIT_DEFAULT_RESTRICTIONS_V)
            },
            mapValuesToRestrictions(object_type, object_id, restrictions) {
                getRestrictionDefaultList({[object_type]: object_id}).then(res => {
                    this.formData = reduce(restrictions, (result, restriction) => {
                        let resObj = find(res.data, o => o.id === restriction.id)
                        result[restriction.id] = resObj ? resObj.value : null
                        return result
                    }, {})
                    this.$nextTick(() => {
                        this.initialFormData = cloneDeep(this.formData)
                    })
                    this.saveButtonState = true
                })
            },
            save() {
                if (!isEqual(this.initialFormData, this.formData)) {
                    this.saveButtonState = false
                    updateDefaultRestrictions({
                        object_type: this.context.object_type,
                        object_id: this.context.object_id,
                        data: this.formData
                    }).then(res => {
                        this.initialFormData = cloneDeep(this.formData)
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 2000
                        })
                    }).finally(() => {
                        this.saveButtonState = true
                    })
                } else {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NO_CHANGE'),
                        'type': 'error',
                        'timeout': 2000
                    })
                }
            }
        },
        created() {
            this.saveButtonState = false
            if (isNull(this.restrictions)) {
                this.$store.dispatch(`shared/actionSetRestrictions`).then(res => {
                    this.mapValuesToRestrictions(this.context.object_type, this.context.object_id, res);
                })
            } else {
                this.mapValuesToRestrictions(this.context.object_type, this.context.object_id, this.restrictions);
            }
        }
    }
</script>

<style scoped>

</style>
