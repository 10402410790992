<template>

        <b-pagination
            class="app_pagination"
            v-if="listCount > 0 && lastPage > 1"
            size="sm"
            :hide-goto-end-buttons="true"

            :total-rows="listCount"
            :per-page="perPage"
            @change="eventInput"
            :value="value">
            <template v-slot:prev-text>
                <i class="fa fa-angle-left" aria-hidden="true"></i>
            </template>
            <template v-slot:next-text>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </template>


        </b-pagination>

</template>

<script>
    export default {
        name: "app-pagination",
        props: {
            listCount: {
                type: Number,
                default: 0
            },
            perPage: {
                type: Number,
                default: 10
            },
            value: {
                default: 1,
                type:Number
            },
            lastPage: {
                type: Number,
                default: 1
            }
        },
        methods: {
            eventInput(data) {
                this.$emit('input', data)
            }
        }
    }
</script>

<style scoped>

</style>
