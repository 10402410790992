import { render, staticRenderFns } from "./DashboardOpenInvoice.vue?vue&type=template&id=21d176fa&scoped=true&"
import script from "./DashboardOpenInvoice.vue?vue&type=script&lang=js&"
export * from "./DashboardOpenInvoice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21d176fa",
  null
  
)

export default component.exports