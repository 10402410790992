<template>
    <div>
        <app-table-v2 :provider="getData" :fields="fields"
                      refresh-event="refreshAppearanceFontTable" :search-on-create="true">
            <template v-slot:theme_font_source="data">
                <span v-if="data.item.theme_font_source">{{data.item.theme_font_source.name}}</span>
                <span v-else>{{$t('SYSTEM')}}</span>
            </template>
            <template v-slot:edit="data">
                <b-tooltip v-if="!data.item.company_id" :target="'edit_button_' + data.item.id"
                           placement="top"
                           :title="$t('SYSTEM_FONT_MSG')"></b-tooltip>
                <app-button variant="link"
                            button_type="edit"
                            :id="'edit_button_' + data.item.id"
                            :disabled="!data.item.company_id"
                            @click="openEditFont(data.item)"
                            :show_text="false">
                </app-button>
            </template>
        </app-table-v2>
        <app-aside v-model="newFontAside">
            <template slot="header"><app-object-header :name="$t('NEW_FONT')"></app-object-header> </template>
            <template v-slot="props">
                <appearance-new-font-form @font-saved="closeNewFontAside"></appearance-new-font-form>
            </template>
        </app-aside>
        <app-aside v-model="editFontAside">
            <template slot="header"><app-object-header :label="fontName" :name="$t('EDIT_FONT')"></app-object-header></template>
            <template v-slot="props">
                <appearance-edit-font-form :font="font" @edit-saved="closeEditAside"></appearance-edit-font-form>
            </template>
        </app-aside>
    </div>
</template>

<script>

import AppTableV2 from "@/components/app/AppTableV2";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import AppearanceNewFontForm from "@/components/appearance/font/AppearanceNewFontForm";
import AppearanceEditFontForm from "@/components/appearance/font/AppearanceEditFontForm";
import {getThemeFonts} from "@/services/appearance";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "AppearanceFontTable",
    components:{AppTableV2, AppButton, AppAside, AppObjectHeader, AppearanceNewFontForm, AppearanceEditFontForm},
    data(){
        return {
            fields:[
                {key: 'id', label: this.$t('ID'),},
                {key: 'name', label: this.$t('NAME')},
                {key: 'label', label: this.$t('LABEL')},
                {key: 'theme_font_source', label: this.$t('FONT_SOURCE')},
                {key: 'edit', label: '', class:'text-right'}
            ],
            newFontAside: false,
            editFontAside: false,
            fontName: null,
            font: null
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods:{
        getData(filter){
            let req = {
                ...{
                    page: filter.currentPage,
                    perPage: filter.perPage,
                    order_by: filter.sortBy,
                    company_id: this.company.id
                }
            }
            return getThemeFonts(req)
        },
        openEditFont(data){
            this.fontName = data.label
            this.font = data
            this.editFontAside = true
        },
        closeNewFontAside(){
            this.newFontAside = false
            EventBus.$emit('refreshAppearanceFontTable')
        },
        closeEditAside(){
            this.editFontAside = false
            EventBus.$emit('refreshAppearanceFontTable')
        }
    },
    created() {
        EventBus.$on('AddNewFont', () => {
            this.newFontAside = true
        })
    }
}
</script>

<style scoped>

</style>
