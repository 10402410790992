<template>
    <div>
        <b-form @submit.prevent="save">
            <b-row>
                <b-col md="12" class="mb-3">
                    <label>{{$t('MONTH')}}</label>
                    <app-select :options="monthYearOptions" v-model="period" return-type="object"
                                :search-empty-item="false"
                                text-field="label">
                    </app-select>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <app-button-submit :loading="saving"></app-button-submit>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {createSubscriptionStatement} from "@/services/company";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import moment from "moment"

    const MAX_BACKWARDS_MONTHS = 5

    export default {
        name: "CompanyCalculationGenerateForm",
        components: {AppSelect, AppButtonSubmit},
        mixins: [getErrorMessage],
        props: {
            company: {
                type: Number,
            },
        },
        data() {
            return {
                saving: false,
                period: null,
            }
        },
        computed: {
            monthYearOptions() {
                let momentObject = moment()
                let list = []
                for (let i = 0; i <= MAX_BACKWARDS_MONTHS; i++) {
                    momentObject.subtract({month: 1})
                    list.push({
                        id: i,
                        label: `${momentObject.format('MM')}/${momentObject.year()}`,
                        start: momentObject.startOf('month').format('YYYY-MM-DD'),
                        end: momentObject.endOf('month').format('YYYY-MM-DD')
                    })
                }
                return list
            },
        },
        methods: {
            save() {
                this.saving = true
                createSubscriptionStatement(this.company, {
                    start_date: this.period.start,
                    end_date: this.period.end
                }).then(() => {
                    notifySuccess()
                    this.$emit('created')
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => this.saving = false)
            },
        },
        created() {
            this.period = this.monthYearOptions[0]
        }
    }
</script>

<style scoped>

</style>
