<template functional>

    <div class="main_calendar_test--cell position-relative main_calendar_test--availability-cell"
         :class="{'main_calendar_test__bg-hover': props.highlighted && !props.cellData.reservation, 'main_calendar__bg-hover_note':props.cellData.note_highlight.value, ...props.cellData.lastDayOfMonthClass}"
         :style="{'grid-column': props.column + '/ span ' + props.cellData.colspan}">
        <b-popover v-if="props.cellData.reservation && props.cellData.showResPopup.show"
                   container="my-container_calendar"
                   class="calendar-popover"
                   :show.sync="props.cellData.showResPopup.show"
                   :no-fade="true"
                   placement="left"
                   :target="`popover-reservation-${props.cellData.reservation.id}`">
            <template v-slot:title>{{props.cellData.reservation.formatted_check_in_date}} - {{props.cellData.reservation.formatted_check_out_date}}</template>
            <b-list-group>
                <p class="d-flex justify-content-between align-items-center">
                    {{ props.cellData.translations.RESERVATION_CODE }}: {{ props.cellData.reservation.distribution_booking_id }}
                </p>
                <p v-if="props.show_channel_data" class="d-flex justify-content-between align-items-center">
                    {{ props.cellData.translations.SALES_CHANNEL }}: {{ props.cellData.reservation.channel.name }}
                </p>
                <p class="d-flex justify-content-between align-items-center">
                    {{ props.cellData.translations.GUEST }}: {{ props.cellData.reservation.first_name }} {{ props.cellData.reservation.last_name }}
                </p>
                <p class="d-flex justify-content-between align-items-center">
                    {{ props.cellData.translations.ADULTS }} / {{ props.cellData.translations.CHILDREN }} / {{ props.cellData.translations.BABIES }}:
                    {{ props.cellData.reservation.adult_no }} / {{ props.cellData.reservation.child_no }} /
                    {{ props.cellData.reservation.baby_no }}
                </p>
                <p class="d-flex justify-content-between align-items-center">
                    {{ props.cellData.translations.TOTAL }}: {{ props.cellData.reservation.channel_total }}
                </p>
                <p v-if="props.cellData.reservation.check_in_time"
                   class="d-flex justify-content-between align-items-center">
                    {{ props.cellData.translations.ARRIVAL_TIME }}: {{ props.cellData.reservation.formatted_check_in_time }}
                </p>
                <p class="d-flex justify-content-between align-items-center">
                    {{ props.cellData.translations.BOOKED_AT }}: {{ props.cellData.reservation.formatted_created_date }}

                </p>
                <p class="d-flex justify-content-between align-items-center">
                    {{ props.cellData.translations.TAG }}: {{ props.cellData.reservation.tags }}
                </p>
            </b-list-group>

        </b-popover>
        <div v-if="props.cellData.reservation"
             @click="listeners.showReservation"
             @click.shift.exact="listeners.drag"
             @mouseover.shift="listeners.currentDrag"
             @mouseenter.stop="listeners.mouseEnter"
             @mouseleave="listeners.mouseLeave"

             :class="{'main_calendar_test_reservation_before':props.cellData.hasReservationOnDayBefore && props.column > 1, 'main_calendar_test_reservation':!props.cellData.hasReservationOnDayBefore && props.column > 1,'main_calendar_test_first-column':props.column === 1}">
            <div   class="main_calendar_test--status main_calendar--background_primary main_calendar_test--status-start main_calendar_test--status-end" :style="`width: ${props.cellData.width}rem;`"  :id="`popover-reservation-${props.cellData.reservation.id}`">
                <div class="main_calendar_test-guest--icon">
                    <b-img fluid :src="props.cellData.reservation.channel_icon"></b-img>
                </div>

                <div class="main_calendar-guest--name"  :style="`width:${props.cellData.guest_width}rem`">
                    {{ props.cellData.reservation.first_name ? props.cellData.reservation.first_name.substr(0, 1) : '' }}.
                        {{props.cellData.reservation.last_name}}

                </div>
                <div v-if="props.cellData.reservation.tags" class="align-content-center mr-2 mt-1">
                    <i class="fa fa-tag" style="color: white;"></i>
                </div>
            </div>
        </div>
        <template v-else>
                <div v-if="props.cellData.showNumericSpan.available_count"  class="justify-content-start pb-4 pl-1 main_calendar_test_numeric-indicator">
                    <span v-if="!props.cellData.isContigent">
                        {{props.cellData.availabilityObject.available_count}}
                    </span>
                    <i  v-else class="fa fa-circle mr-1 availability_calendar_indicator-open" style="font-size: 0.2rem"></i>
                </div>
                <div v-if="props.cellData.showNumericSpan.room_status" class="justify-content-end pb-4 pr-1 main_calendar_test_numeric-indicator" style="font-size: 0.2rem">
                    <i  v-if="props.cellData.availabilityObject.open_for_sale" class="fa fa-circle ml-1 main_calendar_test_indicator-open pull-right" style="font-size: 0.2rem"></i>
                </div>
            <div  class="justify-content-center main_calendar--status"
                  :id="`popover-note-${props.cellData.unit}-${props.column}-${props.cellData.note ? props.cellData.note.user_id : 0}`"
                  :class="{...props.cellData.availabilityClasses}"
                  @click.shift.exact="listeners.drag"
                  @click.exact="listeners.startEndDrag"
                  @mouseover.shift="listeners.currentDrag"
                  @mouseenter.stop="listeners.mouseEnterNote"
                  @mouseleave="listeners.mouseLeaveNote">
                    <i  v-if="props.cellData.showOpenIndicator"
                        class="fa fa-circle m-0 main_calendar_test_indicator-open"></i>

                    <i  v-else class="fa fa-circle m-0 main_calendar_test_indicator-close" ></i>
                <b-popover
                    v-if="props.cellData.note && props.cellData.showResPopup.show"
                    container="my-container_calendar_note"
                    class="calendar-popover"
                    :show.sync="props.cellData.showResPopup.show"
                    :no-fade="true"
                    placement="top"
                    :target="`popover-note-${props.cellData.unit}-${props.column}-${props.cellData.note.user_id}`">
                    <template v-slot:title> {{ props.cellData.note.period_start_foramtted }} - {{ props.cellData.note.period_end_foramtted }}</template>
                    <b-list-group>
                        <p class="d-flex justify-content-between align-items-center">
                            {{props.cellData.translations.NOTE}}: {{props.cellData.note.note}}
                        </p>
                        <p>{{props.cellData.translations.USER}}: {{props.cellData.note.user_first_name}} {{props.cellData.note.user_last_name}}</p>
                        <p class="d-flex justify-content-between align-items-center">
                            {{props.cellData.translations.CLOSED_AT}}: {{props.cellData.note.timestamp_formatted}}
                        </p>
                    </b-list-group>
                </b-popover>
            </div>
        </template>

    </div>


</template>

<script>
import AppIndicator from "@/components/app/indicators/AppIndicator";
export default {
    name: "TableAvailabilityContentCell",
    components: {AppIndicator},
    props: {
        dragPeriod: {
            type: Object
        },
        currentDragDate: {
            type: Object,
            default: null
        },
        column: {
            type: Number,
        },
        cellData: {
            type: Object,
        },
        highlighted: {
            type: Boolean
        },
        show:{
            type:Boolean
        },
        noteHighLight:{
            type:Object
        },
        show_channel_data:{
            type:Boolean,
            default:true
        }
    }
}
</script>

<style scoped>

</style>
