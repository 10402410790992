import http from "@/services/http";
import {FINANCE_PAYMENT_MESSAGE_URL} from "@/services/endpoints";

export function fetchPaymentMessage(id) {
    return http.request({
        url: FINANCE_PAYMENT_MESSAGE_URL + '/' + id,
        method: 'GET',
    })
}

export function getPaymentMessageList(params) {
    return http.request({
        url: FINANCE_PAYMENT_MESSAGE_URL,
        method: 'GET',
        params: params
    })
}

export function uploadPaymentMessage(data) {
    return http.request({
        url: FINANCE_PAYMENT_MESSAGE_URL,
        method: 'POST',
        data
    })
}
export function editPaymentMessage(id, data) {
    return http.request({
        url: FINANCE_PAYMENT_MESSAGE_URL + '/' + id,
        method: 'PUT',
        data: data
    })
}

export function getPaymentMessageReservationList(params) {
    return http.request({
        url: FINANCE_PAYMENT_MESSAGE_URL + '/get-reservations',
        method: 'GET',
        params
    })
}

export function getMappedPaymentMessageReservations(paymentMessageId) {
    return http.request({
        url: FINANCE_PAYMENT_MESSAGE_URL + '/' + paymentMessageId + '/get-mapping',
        method: 'GET',
        params: {payment_message: paymentMessageId}
    })
}

export function mapReservationToPaymentMessageItem(paymentMessageId,data) {
    return http.request({
        url: FINANCE_PAYMENT_MESSAGE_URL + '/' + paymentMessageId + '/map',
        method: 'POST',
        data
    })
}

export function unmapReservationFromPaymentMessageItem(paymentMessageId, reservationId) {
    return http.request({
        url: FINANCE_PAYMENT_MESSAGE_URL + '/' + paymentMessageId + '/map',
        method: 'DELETE',
        data: {payment_message: paymentMessageId, reservation: reservationId}
    })
}

export function getPaymentMessageAutoMapSuggestions(paymentMessageId, params) {
    return http.request({
        url: FINANCE_PAYMENT_MESSAGE_URL + '/' + paymentMessageId + '/auto-map-get',
        method: 'POST',
        params: {payment_message: paymentMessageId, ...params}
    })
}
export function autoMapFile(paymentMessageId,data){
    return http.request({
        url: FINANCE_PAYMENT_MESSAGE_URL + '/' + paymentMessageId + '/auto-map-file',
        method: 'POST',
        data
    })
}
export function completePaymentMessageItems(paymentMessageId, paymentMessageItems) {
    return http.request({
        url: FINANCE_PAYMENT_MESSAGE_URL + '/' + paymentMessageId + '/set-item-completed',
        method: 'POST',
        data: {payment_message: paymentMessageId, item: paymentMessageItems}
    })
}

export function getPaymentMessageInvoiceList(paymentMessageId) {
    return http.request({
        url: FINANCE_PAYMENT_MESSAGE_URL + '/' + paymentMessageId + '/invoice-list',
        method: 'GET',
        params: {payment_message: paymentMessageId}
    })
}

export function createPaymentMessageInvoice(paymentMessageId, invoice_list) {
    return http.request({
        url: FINANCE_PAYMENT_MESSAGE_URL + '/' + paymentMessageId + '/invoice-list',
        method: 'POST',
        data: {payment_message: paymentMessageId, invoice_list}
    })
}

export function generatePaymentMessageXML(paymentMessageId) {
    return http.request({
        url: FINANCE_PAYMENT_MESSAGE_URL + '/' + paymentMessageId + '/generate-xml-message',
        method: 'GET',
        responseType: 'blob',
        data: {payment_message: paymentMessageId}
    })
}
