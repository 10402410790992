<template>
    <div>
        <b-row>
            <b-col class="mb-4">
                <app-button :c_permission="C_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES_NEW"
                            variant="primary" button_type="new"
                            @click="editSidebar(null)"
                            class="pull-right">{{$t('EMAIL_TEMPLATE_NEW')}}
                </app-button>
            </b-col>
        </b-row>
        <event-template-list class="mt-3"
                             :templates="templates"
                             :system_templates="true"
                             @editTemplate="editSidebar">
        </event-template-list>
        <app-aside v-model="templateSidebarState">
            <template v-if="edit_id === null" slot="header">{{$t("EMAIL_TEMPLATE_NEW")}}</template>
            <template v-else slot="header">{{$t("EMAIL_TEMPLATE")}}</template>
            <event-template-form :template_id="edit_id"
                                 :system_template="true"
                                 @save="save">
            </event-template-form>
        </app-aside>
    </div>
</template>

<script>
    import AppButton from '@/components/app/AppButton/AppButton';
    import EventTemplateList from "@/components/event/EventTemplateList";
    import AppAside from '@/components/app/form/AppAside';
    import {getSystemEventTemplatesList} from "@/services/event_template";
    import {C_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES_NEW} from "@/shared/component_permission";

    export default {
        name: "Index",
        components: {
            AppButton,
            EventTemplateList,
            AppAside,
        },
        data() {
            return {
                edit_id: null,
                templateSidebarState: false,
                templates: null,
                C_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES_NEW
            }
        },
        methods: {
            getTemplates() {
                getSystemEventTemplatesList({
                    company_id: this.$store.getters[`user/getCompany`]
                }).then(response => {
                    this.templates = response.data
                })
            },
            editSidebar(id) {
                this.edit_id = id;
                this.templateSidebarState = true;
            },
            save(template) {
                this.getTemplates()
                this.templateSidebarState = false;
            }
        },
        created() {
            this.getTemplates()
        }
    }
</script>

<style scoped>

</style>
