<template>
    <form v-if="importData && dataPrepared" autocomplete="off">
        <b-row class="d-flex justify-content-center">
            <b-col md="6" lg="4" class="mb-4">
                <label>{{ $t("UNITS") }}</label>
                <app-input-control :error-object="validationObject.unit_list"
                                   :listening-for-event="listenForError">
                    <template v-slot:input="data">
                        <app-select
                            v-if="importData.unit_list.length > 1"
                            :state="data.state"
                            mode="multiselect"
                            value-field="external_id"
                            text-field="display_name"
                            v-model="formData.selected_units"
                            :search-empty-item="false"
                            :options="importData.unit_list">
                        </app-select>
                        <app-select
                            v-else
                            :state="data.state"
                            set-first
                            value-field="external_id"
                            text-field="display_name"
                            disabled
                            :value="formData.selected_units.length === 1 ? formData.selected_units[0] : null"
                            @input="setSelectedUnit"
                            :search-empty-item="false"
                            :options="importData.unit_list">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <template v-if="distribution === AIRBNB">
            <b-row class="d-flex justify-content-center">
                <b-col md="6" lg="4" class="mb-4">
                    <label>{{ $t("IMPORT_AS") }}</label>
                    <app-input-control :error-object="validationObject.import_mode"
                                       :listening-for-event="listenForError">
                        <template v-slot:input="data">
                            <app-select
                                :state="data.state"
                                v-model="formData.import_mode"
                                :search-empty-item="false"
                                :options="importModeList">
                            </app-select>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center"
                   v-if="formData.import_mode === 'units_of_existing_property' && preloadedPropertyList !== null">
                <b-col md="6" lg="4" class="mb-4">
                    <label>{{ $t("PROPERTY") }}</label>
                    <app-input-control :error-object="validationObject.property"
                                       :listening-for-event="listenForError">
                        <template v-slot:input="data">
                            <app-select
                                v-if="preloadedPropertyList"
                                :options="propertyList"
                                :state="data.state"
                                set-first
                                v-model="formData.property_id">
                            </app-select>
                            <app-select
                                v-else
                                mode="search"
                                :search="searchProperty"
                                :state="data.state"
                                set-first
                                :value="property"
                                @input="setPropertyId">
                            </app-select>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
        </template>
        <loading :show="loading"></loading>
    </form>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {AIRBNB, DEFAULT_PAGINATION_LIMIT, LS_PRESETS} from "@/shared/constants";
import AppInputControl from "@/components/app/AppInputControl";
import Loading from "vue-full-loading/src/Loading";
import {getPropertyList} from "@/services/property";
import {
    PROPERTY_IMPORT_EXISTING_PROPERTY_EMPTY,
    PROPERTY_IMPORT_EXISTING_PROPERTY_STATUS_VALIDATION_FAILED, PROPERTY_IMPORT_EXISTING_UNIT,
    // PROPERTY_IMPORT_EXISTING_UNIT,
    PROPERTY_IMPORT_IMPORT_MODE_EMPTY,
    PROPERTY_IMPORT_SELECTED_UNITS_EMPTY, PROPERTY_IMPORT_UNIT_NOT_UNIQUE_EXTERNAL_ID,
    // PROPERTY_IMPORT_UNIT_NOT_UNIQUE_EXTERNAL_ID
} from "@/shared/error_codes";

const FORM_DATA_APPLICABLE_IMPORT_DATA_KEY_LIST = [
    "unit_list"
]

export default {
    name: "ImportPropertyWizardUnitsForm",
    components: {AppInputControl, AppSelect, Loading},
    data() {
        return {
            formData: {
                selected_units: [],
                import_mode: null,
                property_id: null,
            },
            validationObject: {
                unit_list: {
                    input: [
                        PROPERTY_IMPORT_SELECTED_UNITS_EMPTY,
                    ],
                    toast: [
                        PROPERTY_IMPORT_EXISTING_UNIT,
                        PROPERTY_IMPORT_UNIT_NOT_UNIQUE_EXTERNAL_ID,
                    ],
                },
                import_mode: {
                    input: [
                        PROPERTY_IMPORT_IMPORT_MODE_EMPTY
                    ],
                },
                property: {
                    input: [
                        PROPERTY_IMPORT_EXISTING_PROPERTY_EMPTY,
                        PROPERTY_IMPORT_EXISTING_PROPERTY_STATUS_VALIDATION_FAILED,
                    ],
                },
            },
            importModeList: [
                {id: "new_property", name: this.$i18n.t("NEW_PROPERTY")},
                {id: "units_of_existing_property", name: this.$i18n.t("NEW_UNITS_OF_EXISTING_PROPERTY")},
            ],
            preloadedPropertyList: null,
            propertyList: [],
            property: {
                id: null,
                name: null,
            },
            AIRBNB,
            dataPrepared: false,
        }
    },
    props: {
        distribution: {
            type: Number,
            default: null,
        },
        importData: {
            type: Object,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        listenForError: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        company() {
            return this.$store.getters["user/getCurrentCompany"]
        },
    },
    watch: {
        formData: {
            handler(value) {
                this.$emit("update", {units: value})
            },
            deep: true,
            immediate: true,
        },
        "formData.import_mode": {
            handler(value) {
                this.$set(this.formData, "property_id", null)
                this.property = {id: null, name: null}
                this.$emit("updateFetchedDataForStep", {exclude_steps: ["units", "source"]})
                if (value === "units_of_existing_property") {
                    if (this.preloadedPropertyList === null) {
                        let storagePresets = localStorage.getItem(LS_PRESETS)
                        if (storagePresets) {
                            storagePresets = JSON.parse(storagePresets)
                            if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                                this.preloadedPropertyList = true
                                this.searchProperty().then(response => {
                                    this.propertyList = response.data
                                })
                            }
                        }
                        this.preloadedPropertyList = this.preloadedPropertyList === null ? false : this.preloadedPropertyList
                    }
                }
            },
        },
        "formData.property_id": {
            handler(value) {
                if (value) {
                    const request = this.prepareRequest()
                    this.$emit("fetchProperty", request)
                } else {
                    this.$emit("unsetFetchedDataProperties", {
                        properties: ["property"],
                        update_steps_data: {exclude_steps: ["units", "source"]}
                    })
                }
            },
        },
        "formData.selected_units": {
            handler() {
                this.$emit("updateFetchedDataForStep", {exclude_steps: ["units", "source"]})
            },
            deep: true,
        },
        importData: {
            handler(value) {
                this.dataPrepared = false
                if (value && typeof value === "object") {
                    this.updateFormData(value)
                }
                this.$nextTick(() => {
                    this.dataPrepared = true
                })
            },
            deep: true,
        },
        "importData.unit_list": {
            handler() {
                this.resetFormData()
            },
            deep: true,
        },
    },
    methods: {
        updateFormData(importData) {
            for (const [key, value] of Object.entries(importData)) {
                if (FORM_DATA_APPLICABLE_IMPORT_DATA_KEY_LIST.indexOf(key) > -1) {
                    this.$set(this.formData, key, value)
                }
            }
        },
        resetFormData() {
            this.formData.selected_units.splice(0)
            this.$set(this.formData, "import_mode", null)
            this.$set(this.formData, "property_id", null)
        },
        setSelectedUnit(unitId) {
            if (this.formData.selected_units.length > 0) {
                this.formData.selected_units.splice(0)
            }
            this.formData.selected_units.push(unitId)
        },
        searchProperty(value) {
            return getPropertyList({
                name: value,
                company_id: this.company.id,
            }).then(response => {
                return {data: response.data.items}
            })
        },
        setPropertyId(value) {
            if (!value || typeof value !== "object" || !value.hasOwnProperty("id")) {
                this.$set(this.formData, "property_id", null)
                this.set(this.property, "id", null)
                this.set(this.property, "name", null)
                return
            }

            this.$set(this.formData, "property_id", value.id)
            this.property = value
        },
        prepareRequest() {
            return {
                property: this.formData.property_id,
            }
        },
    },
}
</script>

<style scoped>

</style>
