<template>
    <div>
        <app-table-v2 @fetched="updateAccessControl" v-if="Object.keys(filter).length" :provider="getData"
                      :fields="computedFields" @busyToggled="toggleBusy"
                      :search-on-create="searchOnCreate">
            <template v-slot:id="data">
                <small v-if="companyHasAdsIntegrated === true && data.item.hasOwnProperty('external_id') && data.item.external_id" :title="$t('EXTERNAL_ID')" class="text-muted d-block">
                    {{data.item.external_id}}
                </small>
                {{data.item.id}}
            </template>
            <template v-slot:name="data">
                <div v-if="isSetupReservationProcessDistributionUnitRoute || isSetupFeesPlatformFeeDistributionUnitRoute">
                    <small class="text-muted d-block">
                        <b-link
                            :to="{name: routeNames.RM_PROPERTY_UNIT_BASIC, params: {id: data.item.property.id, unit_id: data.item.parent_unit}}"
                            target="_blank">
                            {{ data.item.unit_type.name }},
                        </b-link>
                        <b-link :to="{name: routeNames.RN_PROPERTY_BASIC, params: {id: data.item.property.id}}"
                                target="_blank">
                            {{ data.item.property.name }}
                        </b-link>
                    </small>
                    <b-link
                        :to="{name: routeNames.RM_PROPERTY_UNIT_BASIC, params: {id: data.item.property.id, unit_id: data.item.id}}"
                        target="_blank">
                        {{ data.item.name }}
                    </b-link>
                </div>
                <div v-else>
                    <span
                        v-if="data.item.contigent.parent > 0">
                        {{ data.item.name }}
                    <br>{{ $t('PARENT_UNIT') }}: {{ getParentName(data.item.contigent.parent) }}
                    </span>
                    <span v-else>{{ data.item.name }}</span>
                </div>
            </template>
            <template v-slot:edit="data">
                <app-button v-if="isSetupReservationProcessDistributionUnitRoute || isSetupFeesPlatformFeeDistributionUnitRoute"
                            variant="link"
                            button_type="edit"
                            :show_text="false"
                            @click="openAside(data)"
                >
                </app-button>
                <app-button v-else
                            variant="link"
                            button_type="edit"
                            :show_text="false"
                            :to="{name: routeNames.RM_PROPERTY_UNIT_BASIC, params:{unit_id: data.item.id}}"></app-button>
            </template>
            <template v-slot:tableNoData="props">
                <app-no-data :show-tip="false">
                    <app-button button_type="new" @click="openUnitWizard"
                                :access_control_context="{function:C_PROPERTY_NAVIGATION_HEADER_BUTTON,key:AC_PROPERTY_NO_DATA_BUTTON_KEY}"
                                @AccessControlEvent="addToAccessControlCounter()">
                        {{ $t('NEW_UNIT') }}
                    </app-button>
                </app-no-data>
            </template>
        </app-table-v2>
        <app-aside
            v-if="distribution"
            v-model="showAside"
            :widths="isSetupFeesPlatformFeeDistributionUnitRoute ? ['col-lg-12','col-sm-12', '100%'] : ['col-lg-5', 'col-sm-12', '40%']"
            :full-width="isSetupFeesPlatformFeeDistributionUnitRoute">
            <template slot="header">
                <app-object-header
                    :name="asideHeader"
                    :label="$t('UNIT')">
                </app-object-header>
            </template>
            <template v-if="channelList.length" slot="header_action_button">
                <div class="aside__header--action_button col-4 mr-3">
                    <label class="label">{{ $t('CHANNEL') }}</label>
                    <app-select mode="select" :options="channelList" v-model="selectedChannel" value-field="id" text-field="label" :search-empty-item="false"></app-select>
                </div>
            </template>
            <distribution-reservation-sidebar
                v-if="isSetupReservationProcessDistributionUnitRoute"
                :distribution_id="selectedChannel ? null : distribution.id"
                :distribution_context="selectedChannel ? distribution.id : null"
                :company_id="company.id"
                @saved="showAside=false"
                :unit_id="unit_id">
            </distribution-reservation-sidebar>
            <platform-fee-edit-form
                v-else-if="isSetupFeesPlatformFeeDistributionUnitRoute"
                :distribution-id="selectedChannel ? null : distribution.id"
                :channel-id="selectedChannel"
                :channel-manager-distribution-id="selectedChannel ? distribution.id : null"
                preset-current-year
                :object-id="unit_id"
                :object-type-id="OBJECT_TYPE_UNIT">
            </platform-fee-edit-form>
        </app-aside>
    </div>
</template>

<script>
    import routeNames from '@/router/routeNames'
    import AppNoData from "@/components/app/AppNoData";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {GE_OPEN_UNIT_WIZARD, EventBus, GE_TABLE_SEARCH} from "@/shared/EventBus";
    import AppTableV2 from "@/components/app/AppTableV2";
    import {getUnitList} from '@/services/unit/index'
    import {cloneDeep} from 'lodash'
    import {AC_PROPERTY_NO_DATA_BUTTON_KEY} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {C_PROPERTY_NAVIGATION_HEADER_BUTTON} from "@/shared/component_permission";
    import AppObjectHeader from "@/components/app/AppObjectHeader";
    import DistributionReservationSidebar
        from "@/components/channel_manager/distribution/DistributionReservationSidebar";
    import AppAside from "@/components/app/form/AppAside";
    import {getDistributionChannelList} from "@/services/distribution";
    import {DISTRIBUTION_MANAGERS, OBJECT_TYPE_UNIT} from "@/shared/constants";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import PlatformFeeEditForm from "@/components/fee/platform_fee/PlatformFeeEditForm";

    export default {
        name: "UnitListTable",
        components: {
            PlatformFeeEditForm,
            AppSelect,
            DistributionReservationSidebar, AppObjectHeader, AppTableV2, AppButton, AppNoData, AppAside},
        mixins:[AccessControlComponent],
        props:{
            distribution:{
                type: Object,
                default: () => {
                    return {
                        id: null,
                        name: null
                    }
                }
            },
            searchOnCreate: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                access_control_fetch_key:AC_PROPERTY_NO_DATA_BUTTON_KEY,
                AC_PROPERTY_NO_DATA_BUTTON_KEY,
                C_PROPERTY_NAVIGATION_HEADER_BUTTON,
                omittedFields: [],
                filter: {},
                routeNames,
                fields: [
                    {key: 'id', label: this.$t('ID'),},
                    {key: 'name', label: this.$t('NAME')},
                    {key: 'type', label: this.$t('TYPE')},
                    {key: 'unit_type.name', label: this.$t('GROUP_NAME')},
                    {key: 'contigent.is_contigent', label: this.$t('NUMBER_OF_UNITS')},
                    {key: 'max_guests', label: this.$t('MAX_GUESTS')},
                    {key: 'number_bedrooms', label: this.$t('NUMBER_OF_BEDROOMS')},
                    {key: 'owner.name', label: this.$t('OWNER')},
                    {key: 'edit', label: '', class: 'text-right'}
                ],
                filterEmpty: true,
                showAside: false,
                unit_id: null,
                asideHeader: null,
                selectedChannel: null,
                channelList: [],
                OBJECT_TYPE_UNIT,
            }
        },
        methods: {
            updateAccessControl(items){
                this.access_control_components = items.length === 0 ? 1 : 0
            },
            getData(filter) {

                let req = {
                    ...this.filter,
                    ...{
                        page: filter.currentPage,
                        limit: filter.perPage,
                        order_by: filter.sortBy,
                        order_by_direction: filter.sortDirection
                    }
                }


                return getUnitList(req)

            },
            toggleBusy($event) {

                EventBus.$emit("SearchPropertyUnitListLoading", $event)
            },
            getParentName(findId) {
                return this.list.find(item => item.id === findId).name
            },
            checkFilterEmpty({name, type, owner}) {
                return !(name || type || owner)
            },
            openUnitWizard() {
                EventBus.$emit(GE_OPEN_UNIT_WIZARD)
            },
            openAside(data){
                this.unit_id = data.item.id
                this.asideHeader = data.item.name
                this.showAside = true
            },
            getChannelList() {
                getDistributionChannelList({distribution_id: this.distribution.id}).then(response => {
                    this.channelList = response.data
                    this.selectedChannel = this.channelList[Object.keys(this.channelList)[0]].id
                })
            },
        },
        watch: {
            filter() {
                EventBus.$emit(GE_TABLE_SEARCH)
            },
            "distribution.id": {
                handler(value) {
                    if (!!value) {
                        if (DISTRIBUTION_MANAGERS.indexOf(value) >= 0) {
                            this.getChannelList()
                        } else {
                            this.selectedChannel = null
                            this.channelList = []
                        }
                    } else {
                        this.selectedChannel = null
                        this.channelList = []
                    }
                },
                immediate: true,
            },
        },
        computed:{
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            companyHasAdsIntegrated() {
                return !!(this.company && this.company.hasOwnProperty('ads_integrated') && this.company.ads_integrated)
            },
            computedFields() {
                return this.fields.filter(el => !this.omittedFields.includes(el.key))
            },
            isSetupReservationProcessDistributionUnitRoute() {
                return this.$route.name === routeNames.RN_SYSTEM_DISTRIBUTION_UNIT
            },
            isSetupFeesPlatformFeeDistributionUnitRoute() {
                return this.$route.name === routeNames.RN_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION_UNIT
            },
        },
        mounted() {
            this.$emit("ready")
        },
        created() {
            if(this.$route.name === routeNames.RN_SYSTEM_DISTRIBUTION_UNIT){
                this.omittedFields.push('unit_type.name')
            }
            EventBus.$on("SearchPropertyUnitList", filter => {
                this.filter = cloneDeep(filter)
            })
            this.access_control_general_context = {
                property: this.$route.params.id,
                company: this.$store.getters['user/getCompany']
            }
        }
    }
</script>

<style scoped>
</style>
