<template>
    <span class="header-button-list">
        <template v-if="$route.name === routes.RN_COMPANY_SUBSCRIPTION">
            <app-button :id="'extend_contract_' + company.id"
                        :key="'extend_contract_' + company.id"
                        :v_show="checkPermission(C_COMPANY_SUBSCRIPTION_EXTEND_CONTRACT)"
                        class="pull-right ml-3"
                        :access_control_context="{function: C_COMPANY_SUBSCRIPTION_EXTEND_CONTRACT, key: AC_SUBSCRIPTION}"
                        @AccessControlEvent="addToAccessControlCounter()"
                        @click="EventBusNotify(GE_SUBSCRIPTION_CONTRACT, false)">
                {{ $t('EXTEND_CONTRACT') }}
            </app-button>

            <app-button :id="'sign_contract_' + company.id"
                        :key="'sign_contract_' + company.id"
                        :v_show="checkPermission(C_COMPANY_SUBSCRIPTION_SIGN_CONTRACT)"
                        class="pull-right ml-3"
                        :access_control_context="{function: C_COMPANY_SUBSCRIPTION_SIGN_CONTRACT, key: AC_SUBSCRIPTION}"
                        @AccessControlEvent="addToAccessControlCounter()"
                        @click="EventBusNotify(GE_SUBSCRIPTION_CONTRACT, true)">
                {{ $t('SIGN_CONTRACT') }}
            </app-button>

             <app-button :id="'extend_demo_subscription_' + company.id"
                         :key="'extend_demo_subscription_' + company.id"
                         :v_show="checkPermission(C_COMPANY_SUBSCRIPTION_EXTEND_DEMO)"
                         class="pull-right ml-3"
                         :access_control_context="{function: C_COMPANY_SUBSCRIPTION_EXTEND_DEMO, key: AC_SUBSCRIPTION}"
                         @AccessControlEvent="addToAccessControlCounter()"
                         @click="EventBusNotify(GE_EXTEND_DEMO_SUBSCRIPTION, false)">
                {{ $t("EXTEND_DEMO") }}
            </app-button>

            <app-button :id="'reactivate_demo_subscription_' + company.id"
                        :key="'reactivate_demo_subscription_' + company.id"
                        :v_show="checkPermission(C_COMPANY_SUBSCRIPTION_REACTIVATE_DEMO)"
                        class="pull-right ml-3"
                        :access_control_context="{function: C_COMPANY_SUBSCRIPTION_REACTIVATE_DEMO, key: AC_SUBSCRIPTION}"
                        @AccessControlEvent="addToAccessControlCounter()"
                        @click="EventBusNotify(GE_EXTEND_DEMO_SUBSCRIPTION, true)">
                {{ $t("REACTIVATE_DEMO") }}
            </app-button>

            <app-button :id="'activate_demo_subscription_' + company.id"
                        :key="'activate_demo_subscription_' + company.id"
                        :v_show="checkPermission(C_COMPANY_SUBSCRIPTION_ACTIVATE_DEMO)"
                        class="pull-right ml-3"
                        :access_control_context="{function: C_COMPANY_SUBSCRIPTION_ACTIVATE_DEMO, key: AC_SUBSCRIPTION}"
                        @AccessControlEvent="addToAccessControlCounter()"
                        @click="activateDemo"
                        :loading="activateDemoLoading">
                {{ $t('ACTIVATE_DEMO') }}
            </app-button>
        </template>

        <template v-if="$route.name === routes.RN_COMPANY_INVOICE_LIST">
            <app-button :id="'payment_type_subscription_' + company.id"
                        :key="'payment_type_subscription_' + company.id"
                        :v_show="checkPermission(C_COMPANY_SUBSCRIPTION_PAYMENT_TYPE_V)"
                        class="pull-right ml-3"
                        @click="EventBusNotify(GE_SUBSCRIPTION_PAYMENT_TYPE, true)">
                {{ $t('PAYMENT_TYPE') }}
            </app-button>

            <app-button :id="'invoice_creation_setup_subscription_' + company.id"
                        :key="'invoice_creation_setup_subscription_' + company.id"
                        :v_show="checkPermission(C_COMPANY_SUBSCRIPTION_INVOICE_CREATION_SETUP_V)"
                        class="pull-right ml-3"
                        @click="EventBusNotify(GE_SUBSCRIPTION_INVOICE_CREATION_SETUP, true)">
                {{ $t('INVOICE_CREATION_SETUP') }}
            </app-button>

            <app-button :id="'calculation_create_subscription_' + company.id"
                        :key="'calculation_create_subscription_' + company.id"
                        :v_show="checkPermission(C_COMPANY_SUBSCRIPTION_CALCULATION_CREATE)"
                        class="pull-right ml-3"
                        @click="EventBusNotify(GE_SUBSCRIPTION_CALCULATION_CREATE, true)">
                {{ $t('NEW_STATEMENT') }}
            </app-button>
        </template>
    </span>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import routes from '@/router/routeNames'
import {
    C_COMPANY_SUBSCRIPTION_E,
    C_COMPANY_SUBSCRIPTION_ACTIVATE_DEMO,
    C_COMPANY_SUBSCRIPTION_REACTIVATE_DEMO,
    C_COMPANY_SUBSCRIPTION_EXTEND_DEMO,
    C_COMPANY_SUBSCRIPTION_SIGN_CONTRACT,
    C_COMPANY_SUBSCRIPTION_EXTEND_CONTRACT,
    C_COMPANY_SUBSCRIPTION_PAYMENT_TYPE_V,
    C_COMPANY_SUBSCRIPTION_INVOICE_CREATION_SETUP_V,
    C_COMPANY_SUBSCRIPTION_CALCULATION_CREATE,
} from "@/shared/component_permission";
import {
    EventBus,
    GE_ACTIVATE_DEMO_SUBSCRIPTION,
    GE_EXTEND_DEMO_SUBSCRIPTION,
    GE_SUBSCRIPTION_CONTRACT,
    GE_SUBSCRIPTION_PAYMENT_TYPE,
    GE_SUBSCRIPTION_INVOICE_CREATION_SETUP,
    GE_SUBSCRIPTION_CALCULATION_CREATE,
} from "@/shared/EventBus";
import {AC_SUBSCRIPTION} from "@/mixins/AccessControl/AccessControlEnumeration";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {activateDemoSubscription} from "@/services/company";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "HeaderButtonCompany",
    components: {AppButton},
    mixins: [AccessControlComponent, getErrorMessage],
    data() {
        return {
            routes,
            C_COMPANY_SUBSCRIPTION_E,
            C_COMPANY_SUBSCRIPTION_ACTIVATE_DEMO,
            C_COMPANY_SUBSCRIPTION_REACTIVATE_DEMO,
            C_COMPANY_SUBSCRIPTION_EXTEND_DEMO,
            C_COMPANY_SUBSCRIPTION_SIGN_CONTRACT,
            C_COMPANY_SUBSCRIPTION_EXTEND_CONTRACT,
            C_COMPANY_SUBSCRIPTION_PAYMENT_TYPE_V,
            C_COMPANY_SUBSCRIPTION_INVOICE_CREATION_SETUP_V,
            C_COMPANY_SUBSCRIPTION_CALCULATION_CREATE,
            GE_ACTIVATE_DEMO_SUBSCRIPTION,
            GE_EXTEND_DEMO_SUBSCRIPTION,
            GE_SUBSCRIPTION_CONTRACT,
            GE_SUBSCRIPTION_PAYMENT_TYPE,
            GE_SUBSCRIPTION_INVOICE_CREATION_SETUP,
            GE_SUBSCRIPTION_CALCULATION_CREATE,
            AC_SUBSCRIPTION,
            access_control_components: 5,
            access_control_fetch_key: AC_SUBSCRIPTION,
            activateDemoLoading: false,
            access_control_general_context: {
                company: this.company.id
            }
        }
    },
    props: {
        company: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    methods: {
        EventBusNotify(eventName, args = null) {
            if (args === null) {
                EventBus.$emit(eventName)
            } else {
                EventBus.$emit(eventName, args)
            }
        },
        activateDemo() {
            this.activateDemoLoading = true
            activateDemoSubscription(this.company.id).then(() => {
                notifySuccess()
                this.fetchAccessControlData(AC_SUBSCRIPTION)
                this.EventBusNotify(GE_ACTIVATE_DEMO_SUBSCRIPTION)
            }, error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.activateDemoLoading = false
            })
        },
    },
    mounted() {
        EventBus.$on(GE_EXTEND_DEMO_SUBSCRIPTION, () => {
            this.fetchAccessControlData(AC_SUBSCRIPTION)
        })
        EventBus.$on(GE_SUBSCRIPTION_CONTRACT, () => {
            this.fetchAccessControlData(AC_SUBSCRIPTION)
        })
    }
}
</script>

<style scoped>

</style>
