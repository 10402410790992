<template>
    <div>
    <app-table-v2 @fetched="updateAccessControl" :provider="getData" :fields="fields" @busyToggled="toggleBusy"
                  selectable id="unit-activation-table">
        <template v-slot:name="data">
            <div v-if="data.item.unit_disabled && checkPermission(R_DIRECT_BOOKING_WEBSITE_UNIT_TABLE)">
                <small class="text-muted d-block">
                    <b-link :to="{name: routeNames.RM_PROPERTY_UNIT_BASIC, params: {id: data.item.property.id, unit_id: data.item.parent_unit}}"
                            target="_blank">
                        {{data.item.unit_type.name}},
                    </b-link>
                    <b-link :to="{name: routeNames.RN_PROPERTY_BASIC, params: {id: data.item.property.id}}"
                            target="_blank">
                        {{data.item.property.name}}
                    </b-link>
                </small>
                <b-link :to="{name: routeNames.RM_PROPERTY_UNIT_BASIC, params: {id: data.item.property.id, unit_id: data.item.id}}"
                        target="_blank">
                    {{data.item.name}}
                </b-link>
            </div>
            <div v-else>
                <small class="text-muted d-block">
                    <span>
                        {{data.item.unit_type.name}},
                    </span>
                    <span>
                        {{data.item.property.name}}
                    </span>
                </small>
                <span>
                    {{data.item.name}}
                </span>
            </div>
        </template>
        <template v-slot:destination="data">
            <small class="text-muted d-block" v-if="data.item.country_tag && data.item.region_tag">
                {{data.item.region_tag.label}}, {{data.item.country_tag.label}}
            </small>
            <label v-if="data.item.has_destination_tag">{{data.item.has_destination_tag.label}}</label>
        </template>
        <template v-slot:city="data">
            <small class="text-muted d-block">
                {{data.item.country.name}}
            </small>
            <label>{{data.item.city.city}}</label>
        </template>
        <template v-slot:tag="data">
            <div v-if="data.item.hasCustomTag">
                <b-badge
                    variant="primary" pill
                    v-for="(item, key) in data.item.hasCustomTag"
                    :key="key"
                    class="mr-1"
                    style="cursor: pointer">
                    {{ item.label }}
                    <i @click="showDeleteCustomTagDialog(item, data)"
                       class="fa fa-times fa-fw ml-1"
                       aria-hidden="true"></i>
                </b-badge>
            </div>
        </template>
        <template v-slot:status="data">
            <b-badge variant="primary" pill
                     class="ml-1"
                     style="cursor: pointer"
                     :key="key"
                     v-for="(transition, key) in data.item.transition"
                     @click="openConfirmationDialog(transition, data.item)">
                {{transition.label}}
            </b-badge>
            <b-badge v-if="data.item.transition.length < 1 && data.item.status.label"
                     variant="primary" pill class="ml-1">
                {{data.item.status.label}}
            </b-badge>
            <span v-else></span>

        </template>
        <template v-slot:is-active="data">
            <b-checkbox
                switch
                @change="editItem(data.item, $event)"
                :disabled="data.item.activate_disabled"
                v-model="data.item.activeCheck">
            </b-checkbox>
        </template>
        <template v-slot:link="data">
            <b-link v-if="data.item.activeCheck"
                    :href="'https://'+website.slug+'/vacation-rentals/'+data.item.alias+'-'+data.item.id"
                    target="_blank">
                <i class="fa fa-globe" aria-hidden="true"></i>
            </b-link>
            <i v-else class="fa fa-globe" style="color: #7B8C9C" aria-hidden="true"></i>
            <app-button
                variant="link"
                v-if="hasPermission(C_UNIT_ACTIVATION_EDIT_V)"
                button_type="edit"
                :show_text="false"
                class="ml-4"
                @click="openEditAside(data.item)">
            </app-button>
        </template>
        <template v-slot:selectedContainer="props">
            <app-button @click="activateForDistribution(props.selected)" v-if="selectedButtonVisible(props.selected)">{{$t('ACTIVATE')}}</app-button>
            <app-button @click="deactivateForDistribution(props.selected)" class="deactivate_unit" v-if="selectedButtonVisible(props.selected)">{{$t('DEACTIVATE')}}</app-button>
            <app-button @click="openDestinationTagging(props.selected)" class="destination_tag">{{$t('SET_DESTINATION')}}</app-button>
            <app-button @click="openCustomTagging(props.selected)" class="custom_tag">{{$t('TAG')}}</app-button>
        </template>
    </app-table-v2>
        <app-aside v-model="activateUnit" :widths="['col-sm-12', '50%']">
            <template slot="header" v-if="titleVisible"><app-object-header :label="website.slug" :name="$t('UNIT_ACTIVATION')"></app-object-header></template>
            <template v-slot="props">
                <activation-wizard
                      :selected-data="selectedUnits"
                      :distribution-id="website.distribution.id"
                      :website-id="website.id"
                      :company-id="website.company_id"
                      @finishActivation="showTitle"
                      @removeSelected="removeSelected"
                ></activation-wizard>
            </template>
        </app-aside>
        <app-aside v-model="destinationTagM" :widths="['col-lg-5', 'col-sm-12', '40%']">
            <template slot="header">
                <app-object-header :label="selectedUnitName" :name="$t('SET_DESTINATION')" :description="website.slug"></app-object-header>
            </template>
            <template v-slot="props">
                <destination-tagging-form :selected="selectedUnits"
                                          :distribution_id="website.distribution.id"
                                          :company_id="company.id"
                                          @destinationSaved="closeDestinationTagging"
                ></destination-tagging-form>
            </template>
        </app-aside>
        <app-aside v-model="customTagM" :widths="['col-lg-3', 'col-sm-12', '40%']">
            <template slot="header">{{$t('TAG_UNITS')}}</template>
            <template v-slot="props">
                <app-tag-entity-form :selected-items="selectedUnits"
                                     :tag-types="[TAG_TYPE_CUSTOM]"
                                     :object-type="OBJECT_TYPE_UNIT"
                                     :selected-check-box="tagged"
                                     @saved="closeCustomTagging"
                ></app-tag-entity-form>
            </template>
        </app-aside>
        <app-aside v-model="singleActivation" :widths="['col-lg-5', 'col-sm-12', '50%']">
            <template slot="header"><app-object-header :label="selectedUnitName" :name="$t('UNIT_ACTIVATION')" :description="website.slug"></app-object-header></template>
            <template v-slot="props">
                <single-activation-form @singleActivated="closeSingleActivation"
                                        :object-id="selectedUnitId"
                                        :distribution_id="website.distribution.id"
                                        :object_id="object_id"
                                        :company_id="company.id"
                ></single-activation-form>
            </template>
        </app-aside>
        <app-aside v-model="statusAside">
            <template slot="header"><app-object-header :name="statusLabel"></app-object-header> </template>
            <template v-slot="props">
                <resolve-request-form :distribution_id="website.distribution.id" :status_eid="statusEid"></resolve-request-form>
            </template>
        </app-aside>

        <app-aside v-model="unitRulesAside">
            <template slot="header"><app-object-header :label="website.slug" :name="$t('ACTIVATION_RULES')"></app-object-header> </template>
            <template v-slot="props">
                <marketplace-unit-activation-rules :distribution-id="website.distribution.id"
                                                   :company-id="company.id"
                                                   mode="saveSystem"
                                                   @unit_setup_saved="closeUnitChangeRules"
                ></marketplace-unit-activation-rules>
            </template>
        </app-aside>

        <app-aside v-model="unitEditAside">
            <template slot="header"><app-object-header :label="unitName" :name="$t('EDIT_UNIT')"></app-object-header></template>
            <template v-slot="props">
                <unit-edit-form :unit_id="unit_id" :distribution_id="website.distribution.id" @save="unitEditSaved"></unit-edit-form>
            </template>
        </app-aside>

        <app-confirmation-dialog :show="deleteCustomTagConfirmDialog"
                                 :delete_title="true"
                                 @confirm="deleteTagMapping()"
                                 @cancel="deleteCustomTagConfirmDialog = false">
        </app-confirmation-dialog>

        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="continueAction"
            @cancel="cancelAction"
            :title="$t('DEACTIVATE_TITLE')">
            <p>{{$t('DEACTIVATE_UNIT_MESSAGE',{website_domain})}}</p>
        </app-confirmation-dialog>

        <app-confirmation-dialog
            :show="change_status_confirmation"
            @confirm="changeStatus"
            @cancel="change_status_confirmation = false"
            :title="status_title">
            <p>{{status_message}}</p>
        </app-confirmation-dialog>
    </div>
</template>

<script>
import routeNames from '@/router/routeNames'
import AppNoData from "@/components/app/AppNoData";
import AppButton from "@/components/app/AppButton/AppButton";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import {EventBus, GE_TABLE_REFRESH_SEARCH, GE_TABLE_DESELECT_ALL} from "@/shared/EventBus";
import AppTableV2 from "@/components/app/AppTableV2";
import AppAside from '@/components/app/form/AppAside'
import DestinationTaggingForm from "@/components/direct_booking/website/unit_activation/DestinationTaggingForm";
import CustomTaggingForm from "@/components/direct_booking/website/unit_activation/CustomTaggingForm";
import SingleActivationForm from "@/components/direct_booking/website/unit_activation/SingleActivationForm";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import ResolveRequestForm from "@/components/direct_booking/website/unit_activation/ResolveRequestForm";
import MarketplaceUnitActivationRules from "@/components/direct_booking/marketplace/MarketplaceUnitActivationRules";
import UnitEditForm from "@/components/direct_booking/website/unit_activation/UnitEditForm";
import {
    changeUnitStatus,
    getActiveUnitList,
    updateUnitActivation
} from "@/services/direct_booking/website";
import {AC_PROPERTY_NO_DATA_BUTTON_KEY} from "@/mixins/AccessControl/AccessControlEnumeration";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {
    C_PROPERTY_NAVIGATION_HEADER_BUTTON,
    C_UNIT_ACTIVATION_BUTTON, C_UNIT_ACTIVATION_EDIT_V
} from "@/shared/component_permission";
import {notifySuccess} from "@/shared/plugins/toastr";
import AppTagEntityForm from "@/components/app/tag/AppTagEntityForm";
import { OBJECT_TYPE_UNIT, TAG_TYPE_CUSTOM} from "@/shared/constants";
import {deleteEntityTags} from "@/services/tag";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {R_DIRECT_BOOKING_WEBSITE_UNIT_TABLE} from "@/shared/route_permission";

export default {
    name: "UnitActivationTable",
    components: {
        AppTagEntityForm,
        AppTableV2,
        AppButton,
        AppNoData,
        AppAside,
        DestinationTaggingForm,
        CustomTaggingForm,
        SingleActivationForm,
        AppConfirmationDialog,
        AppObjectHeader,
        ResolveRequestForm,
        MarketplaceUnitActivationRules,
        UnitEditForm,
        ActivationWizard: () => import('@/components/direct_booking/website/unit_activation/ActivationWizard')
    },
    mixins:[AccessControlComponent, getErrorMessage],
    props:{
        filter: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {
            access_control_fetch_key:AC_PROPERTY_NO_DATA_BUTTON_KEY,
            AC_PROPERTY_NO_DATA_BUTTON_KEY,
            C_PROPERTY_NAVIGATION_HEADER_BUTTON,
            C_UNIT_ACTIVATION_BUTTON,
            TAG_TYPE_CUSTOM,
            OBJECT_TYPE_UNIT,
            R_DIRECT_BOOKING_WEBSITE_UNIT_TABLE,
            C_UNIT_ACTIVATION_EDIT_V,
            routeNames,
            fields: [
                {key: 'id', label: this.$t('ID'),},
                {key: 'name', label: this.$t('NAME')},
                {key: 'type', label: this.$t('TYPE')},
                {key: 'city', label: this.$t('CITY')},
                {key: 'destination', label:this.$t('DESTINATION')},
                {key: 'tag', label:this.$t('TAG')},
                {key: 'status', label: this.$t('STATUS')},
                {key: 'is-active', label: this.$t('ACTIVE')},
                {key: 'link', label: '',class: 'text-right'},
            ],
            filterEmpty: true,
            selectedUnits: [],
            destinationTagM: false,
            customTagM: false,
            activateUnit:false,
            singleActivation: false,
            selectedUnitId: null,
            selectedUnitName: null,
            activationButtonDisabled: false,
            confirmation_dialog_state:false,
            deactivateConfirm: false,
            updateData: null,
            deactivateList:[],
            titleVisible: true,
            website_domain: this.$store.getters['website/getWebsite'].slug,
            tagged: null,
            deleteCustomTagConfirmDialog: false,
            tagId: null,
            entityId: null,
            statusAside: false,
            statusLabel: null,
            statusEid: null,
            object_id: null,
            change_status_confirmation: false,
            status_title: null,
            status_message: null,
            statusTransition:[],
            unit_id: null,
            unitRulesAside: false,
            unitEditAside: false,
            unitName: null,
            propertyName: null,
            tableKey: 0
        }
    },
    computed:{
        website(){
            return this.$store.getters['website/getWebsite']
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        hasPermission() {
            return this.$store.getters['user/getPermission']
        }
    },
    methods: {
        updateAccessControl(items){
            this.access_control_components = items.length === 0 ? 1 : 0
        },
        getData(filter) {
            let req = {
                ...this.filter,
                ...{
                    page: filter.currentPage,
                    perPage: filter.perPage,
                    order_by: filter.sortBy,
                    order_by_direction: filter.sortDirection,
                    pg_active: true,
                    show_all: true
                }
            }
            return getActiveUnitList(this.website.distribution.id, req)

        },
        toggleBusy($event) {
            this.$emit('busyToggled',$event)
        },
        getParentName(findId) {
            return this.list.find(item => item.id === findId).name
        },
        checkFilterEmpty({name, type, owner}) {
            return !(name || type || owner)
        },
        activateForDistribution(selected){
            if (selected.length === 1){
                this.singleActivation = true
                this.selectedUnitName = selected[0].name
                this.selectedUnitId = selected[0].id
            }else {
                this.selectedUnits = selected
                this.activateUnit = true
            }
        },
        deactivateForDistribution(selected){
            this.deactivateList = []
            selected.forEach(el => {
                this.deactivateList.push(el.id)
            })
            this.confirmation_dialog_state = true
            this.updateData = {unit: this.deactivateList, value: false}
        },
        openAside(){
            this.$store.commit('website/setActivationAsideState', true)
        },
        removeSelected(){
            this.selectedUnits = []
        },
        openDestinationTagging(selected){
            this.destinationTagM = true
            if (selected.length === 1){
                selected.forEach(el => {this.selectedUnitName = el.name})
            }else{
                this.selectedUnitName = null
            }
            this.selectedUnits = selected
        },
        closeDestinationTagging(){
            this.destinationTagM = false
            EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
            EventBus.$emit(GE_TABLE_DESELECT_ALL)
        },
        openCustomTagging(selected){
            this.customTagM = true
            this.selectedUnits = selected.map(el => el.id);

            let mapTagged = {}

            mapTagged[TAG_TYPE_CUSTOM] = []
            if (selected.length === 1) {
                selected.map(el => {
                    el.hasCustomTag.map(el1 => {
                        if (!mapTagged[TAG_TYPE_CUSTOM].includes(el1.id))
                            mapTagged[TAG_TYPE_CUSTOM].push(el1.id)
                    })
                });
            }

            this.tagged = mapTagged

        },
        closeCustomTagging(){
            this.customTagM = false
            EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
            EventBus.$emit(GE_TABLE_DESELECT_ALL)
        },
        closeSingleActivation(){
            this.singleActivation = false
            EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
            EventBus.$emit(GE_TABLE_DESELECT_ALL)
        },
        editItem(item, event){
            if (event){
                this.singleActivation = true
                this.selectedUnitId = item.id
                this.selectedUnitName = item.name
            }else {
                this.confirmation_dialog_state = true
                this.updateData = {unit: [item.id], value: event}
            }
        },
        continueAction(){
            updateUnitActivation(this.website.id, this.updateData).then(response => {
                notifySuccess()
                this.updateData = null
                EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
                EventBus.$emit(GE_TABLE_DESELECT_ALL)
            })
            this.confirmation_dialog_state = false
        },
        cancelAction(){
            this.confirmation_dialog_state = false
        },
        showTitle(){
            this.titleVisible = false
            EventBus.$emit(GE_TABLE_DESELECT_ALL)
        },
        showDeleteCustomTagDialog(item,data){
            this.tagId = item.id
            this.entityId = data.item.id
            this.deleteCustomTagConfirmDialog = true
        },
        deleteTagMapping() {
            deleteEntityTags(OBJECT_TYPE_UNIT, this.entityId, [this.tagId]).then((response) => {
                notifySuccess()
                EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.deleteCustomTagConfirmDialog = false
            })
        },
        openStatus(status, item){
            this.statusLabel = status.label
            this.statusAside = true
            this.statusEid = status.status_eid
            this.object_id = item.id
        },
        openConfirmationDialog(transition, item){
            this.statusTransition = []
            this.statusTransition = transition
            this.status_message = this.$t('CHANGE_STATUS_MSG')
            this.status_title = this.$t('CHANGE_STATUS_TITLE')
            this.unit_id = item.id
            this.change_status_confirmation = true
        },
        changeStatus(){
            changeUnitStatus({distribution_id: this.website.distribution.id, unit_id: this.unit_id, next_status: this.statusTransition.next_status}).then(response => {
                notifySuccess()
                EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
                this.change_status_confirmation = false
            }, error => {
                this.change_status_confirmation = false
                this.showErrorMessages(error)
            })
        },
        closeUnitChangeRules(){
            this.unitRulesAside = false
        },
        selectedButtonVisible(selected){
            let check = true
            selected.forEach(el => {
                el.activate_disabled === true ? check = false : ''
            })
            return check
        },
        openEditAside(item){
            this.unit_id = item.id
            this.unitName = item.name
            this.propertyName = item.property.name
            this.unitEditAside = true
        },
        unitEditSaved(){
            this.unitEditAside = false
            EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
        }
    },
    watch: {
        filter: {
            handler() {
                EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
            },
            deep: true
        },
        singleActivation:{
            handler(){
                if (!this.singleActivation){
                    EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
                }
            }
        },
        activateUnit:{
            handler(){
                if (!this.activateUnit){
                    EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
                }
            }
        },
        unitRulesAside:{
            handler(){
                if (this.unitRulesAside === false){
                    EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
                }
            }
        }


    },
    mounted() {
        EventBus.$on('gl_close_wizard', ()=>{
            this.activateUnit = false
        })
        EventBus.$on('editUnitActivationRules', ()=>{
            this.unitRulesAside = true
        })
        this.$emit("ready")
    },
    beforeDestroy() {
        EventBus.$off('gl_close_wizard')
        EventBus.$off('editUnitActivationRules')
    },
    created() {
        this.access_control_general_context = {
            property: this.$route.params.id,
            company: this.$store.getters['user/getCompany']
        }
    }
}
</script>

<style scoped>

.active{
    color: #6f6efe;
}

.destination_tag{
    padding-left: 1rem;
}

.custom_tag{
    padding-left: 1rem;
}

.deactivate_unit{
    padding-left: 1rem;
}

</style>
