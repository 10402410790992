import http from '../http'
import {
    HEADLINE_URL,
    DISTRIBUTION_LOOKUP_URL,
    DISTRIBUTION_LIST_URL,
    HEADLINE_MAPPING_URL,
    DISTRIBUTION_URL,
    DISTRIBUTION_DEFAULT_SETUP_URL, DISTRIBUTION_VALIDATION_URL
} from '@/services/endpoints'

export function getDistributionManagers(params = {}) {
    return http.request({
        url: DISTRIBUTION_LOOKUP_URL,
        method: 'get',
        params: params
    })
}
export function getDistributionManagersTest(params = {}) {
    return http.request({
        url: DISTRIBUTION_LOOKUP_URL+'-test',
        method: 'get',
        params: params
    })
}

export function getHeadlineList(id, typeId) {
    return http.request({
        url: HEADLINE_URL + '-list',
        method: 'post',
        data: {
            object_id: id,
            type_id: typeId
        }
    })
}

export function fetchHeadline(id) {
    return http.request({
        url: HEADLINE_URL + '/' + id,
        method: 'get',
    })
}

// data = {object_id, type_id, name}
export function createHeadline(data) {
    return http.request({
        url: HEADLINE_URL,
        method: 'post',
        data: data
    })
}

//data = {object_id, type_id, name}
export function updateHeadline(id, data) {
    return http.request({
        url: HEADLINE_URL + '/'+ id,
        method: 'put',
        data: data
    })
}

//data = {object_id, type_id}
export function deleteHeadline(id, data) {
    return http.request({
        url: HEADLINE_URL + '/'+ id,
        method: 'delete',
        data: data
    })
}

export function createHeadlineMapping(postData) {
    return http.request({
        url: HEADLINE_MAPPING_URL,
        method: 'post',
        data:postData
    })
}

export function deleteHeadlineMapping(id, distribution_manger_id) {
    return http.request({
        url: `${HEADLINE_URL}/${id}/mapping/${distribution_manger_id}`,
        method: 'delete'
    })
}
export function getDistributionList(params) {
  return http.request({
    url:DISTRIBUTION_LIST_URL,
    method:'get',
    params:params
  })

}

export function getChannelList(params) {
    return http.request({
        url: 'adminapi/channel',
        method: 'GET',
        params
    })
}

export function updateHeadlineMapping(postData){
  return http.request({
    url:HEADLINE_MAPPING_URL,
    method:'put',
    data:postData
  })
}
export function getIntegrationDistributionList(params){
    return http.request({
        url: 'adminapi/distribution/integration-list',
        method: 'GET',
        params
    })
}

export function createDistribution(data) {
    return http.request({
        url: DISTRIBUTION_URL,
        method: 'post',
        data: data
    })
}

export function fetchDistributionDefaultSetup(params) {
    return http.request({
        url: DISTRIBUTION_DEFAULT_SETUP_URL,
        method: 'get',
        params
    })
}

export function getDistributionReservationParams(params) {
    return http.request({
        url: DISTRIBUTION_URL +'/reservation-setup',
        method: 'get',
        params
    })
}

export function getDistribution(id) {
    return http.request({
        url:'/adminapi/distribution/' + id ,
        method:'get',
    })
}

export function validateCreateDistribution(data) {
    return http.request({
        url: DISTRIBUTION_VALIDATION_URL,
        method: 'post',
        data: data
    })
}

export function getDistributionChannelList(params) {
    return http.request({
        url: 'adminapi/channel/distribution',
        method: 'GET',
        params
    })
}
