<template>
    <div v-if="formReady">
        <!--
        <b-row>
            <b-col>
                <form-header>{{ $t("SEND_TO") }}</form-header>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="pull-right">
                <app-button class="mb-4 pull-right" :loading="loading">
                    {{ $t("PDF") }}
                </app-button>
                <app-button class="mb-4 mr-3 pull-right" :loading="loading">
                    {{ $t("XLS") }}
                </app-button>

                <app-button class="mb-4 mr-3 pull-right" :loading="loading">
                    {{ $t("E_MAIL") }}
                </app-button>
            </b-col>
        </b-row>
        <hr class="p-0 m-0 mb-1">
        -->
        <b-row>
            <b-col>
                <form-header>{{ $t("IMPORT") }}</form-header>
            </b-col>
        </b-row>
        <template v-if="importEnabled">
            <b-row>
                <b-col>
                    <b-form-file v-model="file"
                                 @input="saveAside = true"
                                 accept=".xlsx, .xls, .ods"
                                 class="mb-4">
                    </b-form-file>
                </b-col>
            </b-row>
        </template>
        <b-row>
            <b-col>
                <app-button class="mb-4 pull-right" @click="exportTemplate" :loading="loading">
                    {{ $t("CREATE_TEMPLATE") }}
                </app-button>
            </b-col>
        </b-row>
        <app-aside :widths="['col-lg-4', 'col-sm-12', '40%']" v-model="saveAside" @input="$emit('input',false)">
            <template slot="header">
                <app-object-header :name="$t('ADDITIONAL_SAVE_OPTIONS')">
                </app-object-header>
            </template>
            <bulk-calendar-save-form @openConfirmationSave="openSaveConfirmation">
            </bulk-calendar-save-form>
        </app-aside>
        <app-confirmation-dialog :title="$t('SAVE_CONFIRMATION')"
                                 :show="displaySaveConfirmation"
                                 @confirm="save"
                                 @cancel="displaySaveConfirmation = false">
            {{ $t('IMPORT_PRICES_CONFIRMATION_MESSAGE') }}
        </app-confirmation-dialog>
    </div>
</template>

<script>

import {exportPricingTemplate, importDailyPricesFromFile, importPricesFromFile} from "@/services/pricing";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {AC_BULK_CALENDAR, AC_PROPERTY_GALLERY} from "@/mixins/AccessControl/AccessControlEnumeration";
import {
    C_PROPERTY_CALENDAR_PRICES_IMPORT_EXPORT_IMPORT_PRICES,
    C_UNIT_CALENDAR_PRICE_IMPORT
} from "@/shared/component_permission";
import {fetchAccessControlData} from "@/services/access";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";

export default {
    name: "ImportExportPricesForm",
    components: {
        AppConfirmationDialog,
        "AppAside": () => import("@/components/app/form/AppAside"),
        "BulkCalendarSaveForm": () => import("@/components/unit/bulk_calendar/form/BulkCalendarSaveForm"),
        "FormHeader": () => import("@/components/app/form/FormHeader"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "AppObjectHeader": () => import("@/components/app/AppObjectHeader"),
    },
    mixins: [getErrorMessage],
    props: {
        unit: {
            type: Object,
            default() {
                return null
            },
        },
        unitList: {
            type: Array,
            default() {
                return []
            },
        },
        property: {
            type: Object,
            default() {
                return null
            },
        },
        mode: {
            type: String,
            default: 'bulk-calendar',
            validator: value => {
                return (['bulk-calendar', 'unit-calendar']).includes(value)
            },
        },
    },
    data() {
        return {
            loading: false,
            saveAside: false,
            file: null,
            AC_BULK_CALENDAR,
            C_UNIT_CALENDAR_PRICE_IMPORT,
            showImport: false,
            yearList: [],
            displaySaveConfirmation: false,
        }
    },
    methods: {
        exportTemplate() {
            this.loading = true
            const name = this.$t("IMPORT_PRICES_TEMPLATE") + (this.mode === 'bulk-calendar'
                ? this.unit.solo_unit_type.type_name.split(' ').join('_')
                : this.getPropertyName().split(' ').join('_'))
            const request = this.mode === 'bulk-calendar'
                ? {unit: this.unit.id}
                : {unit: this.getUnitIdList(), daily_pricing: 1, default_period_fallback: 1}
            exportPricingTemplate(request, name).then(() => {
                notifySuccess()
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
            })
        },
        save() {
            const formData = new FormData()
            formData.append('pricing', this.file)
            const unitList = this.getUnitIdList()
            unitList.forEach((unitId, index) => {
                formData.append('unit[' + index + ']', unitId)
            })
            if (this.yearList.length > 0) {
                this.yearList.forEach((year, index) => {
                    formData.append('years[' + index + ']', year)
                })
            }
            const promise = this.mode === 'bulk-calendar' ? importPricesFromFile(formData) : importDailyPricesFromFile(formData)
            promise.then(() => {
                notifySuccess()
                let args = this.mode === 'bulk-calendar' ? {unit: this.unit.id} : {unit_list: this.getUnitIdList()}
                this.$emit('pricesImported', args)
                this.saveAside = false
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.displaySaveConfirmation = false
            })
        },
        input() {
            this.saveAside = false
        },
        getDataForAC() {
            return {
                key: AC_BULK_CALENDAR,
                context: {company: this.company.id, property: this.getPropertyId()},
                data: [
                    {uid: `import`, function: C_UNIT_CALENDAR_PRICE_IMPORT},
                ]
            }
        },
        openSaveConfirmation(payload) {
            this.yearList = payload.years
            this.displaySaveConfirmation = true
        },
        getUnitIdList() {
            return this.unitList.length > 0 ? this.unitList : [this.unit.id]
        },
        getPropertyId() {
            return this.property !== null && this.property.hasOwnProperty('id')
                ? this.property.id
                : (this.unit !== null && this.unit.hasOwnProperty('property')
                && this.unit.property !== null && this.unit.property.hasOwnProperty('id')
                    ? this.unit.property.id : 0)
        },
        getPropertyName() {
            return this.property !== null && this.property.hasOwnProperty('name')
                ? this.property.name
                : (this.unit !== null && this.unit.hasOwnProperty('property')
                && this.unit.property !== null && this.unit.property.hasOwnProperty('name')
                    ? this.unit.property.name : '')
        },
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        importEnabled() {
            return this.mode === 'bulk-calendar' ? this.showImport : this.checkPermission(C_PROPERTY_CALENDAR_PRICES_IMPORT_EXPORT_IMPORT_PRICES)
        },
        formReady() {
            return (this.unit !== null && this.unit.hasOwnProperty('id')) || this.unitList.length > 0
        },
    },
    created() {
        if (this.mode === 'bulk-calendar') {
            fetchAccessControlData(AC_BULK_CALENDAR, this.getDataForAC()).then(response => {
                this.showImport = response.data.import.visible
            })
        }
    },
}
</script>

<style scoped>

</style>
