<template>
    <div v-if="hasPermissionView" class="animated fadeIn">
        <rate-plan-list-filter :unit_id="unit_id" ></rate-plan-list-filter>

        <rate-plan-list-table :unit_id="unit_id"></rate-plan-list-table>
    </div>
</template>

<script>

    import RatePlanListFilter from "@/components/unit/rate_plan/RatePlanListFilter";
    import RatePlanListTable from "@/components/unit/rate_plan/RatePlanListTable";
    import {
        C_UNIT_RATE_PLAN_V
    } from "@/shared/component_permission";

    export default {
        name: "index",
        data() {
            return {
                unit_id:null,
            }
        },
        components: {
            RatePlanListTable,
            RatePlanListFilter,
        },
        computed: {
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](C_UNIT_RATE_PLAN_V)
            },
        },
        watch: {
            '$route.params.unit_id': {
                handler(value) {
                    this.unit_id = Number(value)

                },
                immediate: true
            },
        }
    }
</script>
