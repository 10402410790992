<template>
    <div>
        <inbox-table :filter="filter"></inbox-table>
    </div>
</template>

<script>
    import InboxTable from "@/components/inbox/InboxTable";
    export default {
        name: "Index",
        components: {InboxTable},
        props: {
            reservation: {
                type: Object,
                required: true
            }
        },
        computed:{
            filter() {
                return {reservation: this.reservation.id}
            }
        },
        created() {
          this.$emit('created', true)
        }

    }
</script>

<style scoped>

</style>
