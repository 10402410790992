<template>
    <b-card v-cloak class="property-wrapper">
        <template slot="header">
            <div class="d-flex justify-content-between mb-0">
                <div>
                    <h2 class="property-wrapper-padding">{{ $t('RESERVATION_NOTE_SETUP') }}</h2>
                </div>
            </div>

            <navigation :key="'reservation_process_note_setup'+componentKey"
                        :nav-elements="navElements">
            </navigation>
        </template>

        <router-view>
        </router-view>
    </b-card>
</template>

<script>
import Navigation from "@/components/app/navigation/Navigation";
import {getDistributionList} from "@/services/distribution";
import routeNames from "@/router/routeNames";
export default {
    name: "Index.vue",
    components: {Navigation},
    data() {
        return {
            componentKey: 1,
            distribution: null,
            distributionList: []
        }
    },
    methods: {
        async getDistributions() {
            await getDistributionList({company: this.company.id}).then(response => {
                this.distributionList = response.data
            });
        }
    },
    computed: {
        navElements() {
            return [
                {route: {name: routeNames.RN_SYSTEM_RESERVATION_PROCESS_NOTE_SETUP_COMPANY}},
                {route: {name: routeNames.RN_SYSTEM_RESERVATION_PROCESS_NOTE_SETUP_PROPERTY}},
                // {route: {name: routeNames.RN_SYSTEM_RESERVATION_PROCESS_NOTE_SETUP_RATE_PLAN}},
            ]
        },
        backButton() {
            return {name: routeNames.RN_SETUP_GENERAL}
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    watch: {
    },
    mounted() {
        this.$router.push({name: routeNames.RN_SYSTEM_RESERVATION_PROCESS_NOTE_SETUP_COMPANY})
    }
}
</script>

<style scoped>

</style>
