<template>
    <b-card class="mb-3"  body-class="p-0">
        <b-list-group class="repeater-2">
            <b-list-group-item class="list-group-item-title font-weight-bold">
                <div class="d-flex">
                    <h5 class="title mr-1 font-weight-bold">{{period.period_start|periodDate}} - {{period.period_end|periodDate}}</h5>
                    <app-access-control tag="span"    @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{function:C_UNIT_CALENDAR_PERIODS_E,key:AC_BULK_CALENDAR}">
                        <template v-slot:default="props">
                            <b-tooltip v-if="props.messages.length"
                                       triggers="hover"
                                       :title="props.messages[0].message"  :target="'tooltip-target-msg_restiction_'+periodId">
                            </b-tooltip>
                            <app-button
                                :id="'tooltip-target-msg_restiction_'+periodId"
                                :disabled="props.disabled"
                                variant="link"
                                button_type="edit"
                                :show_text="false"
                                class=" action_button"
                                @click="$emit('openSideBar')"
                            >
                            </app-button>
                        </template>
                    </app-access-control>
                    <app-access-control tag="span"   @AccessControlEvent="addToAccessControlCounter()"
                                          :access_control_context="{function:C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_V,key:AC_BULK_CALENDAR}">
                        <template v-slot:default="props">
                        <b-popover tabindex="-1" :target="`periodStatisticPopover-${period.id+'_statistic_restriction'}`"
                                   :title="$t('SUGGESTION_BASED_ON')"
                                   placement="top"
                                   triggers="hover focus">
                            <p>{{$t('NUM_RESERVATIONS')}}: <template v-if="statistic && statistic.reservation_year"> ({{ statistic.reservation_year }})</template>:
                                {{statistic ? statistic.num_of_reservations : 0}}
                            </p>
                            <p>{{$t('NUMBER_OF_BOOKED_DAYS')}}: {{statistic ? statistic.days_reserved : null}}</p>
                            <p>{{$t('AVERAGE_OCCUPANCY')}}: {{statistic ? statistic.avg_guests : null}}</p>
                            <p>{{$t('AVERAGE_LENGTH_OF_STAY')}}: {{statistic ? statistic.avg_stay : null}}</p>

                            <p>{{$t('PRICE_FROM_PRICE_LIST')}}: <template v-if="statistic && statistic.pricelist_year"> ({{statistic.pricelist_year}})</template>: {{statistic ? statistic.price_from_pricelist : null}}</p>
                            <p>{{$t('MIN_STAY_FROM_PRICE_LIST')}}: <template v-if="statistic && statistic.pricelist_year"> ({{statistic.pricelist_year}})</template>: {{statistic ? statistic.min_stay_from_pricelist : null}}</p>
                        </b-popover>
                        <app-button
                            button_type="statistics" variant="link" class="pull-right static-button"  :show_text="false" :id="`periodStatisticPopover-${period.id+'_statistic_restriction'}`"></app-button>
                        </template>
                    </app-access-control>

                </div>


            </b-list-group-item>
            <b-list-group-item>
               <unit-bulk-calendar-restriction :period="period" :restrictions="restrictions" :selectedRatePlan="selectedRatePlan"></unit-bulk-calendar-restriction>
            </b-list-group-item>
        </b-list-group>
    </b-card>
</template>

<script>
    import moment from 'moment'
    import UnitBulkCalendarRestriction from "@/components/unit/bulk_calendar/restriction/UnitBulkCalendarRestriction"
    import AppButton from "@/components/app/AppButton/AppButton";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_BULK_CALENDAR} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_V} from "@/shared/component_permission";
    import {C_UNIT_CALENDAR_PERIODS_E} from '@/shared/component_permission';
    import AppAccessControl from "@/components/app/AppAccessControl";
    import i18n from "@/locale";
    export default {

        name: "unit-bulk-calendar-restriction-panel",
        data(){
          return {
              C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_V,
              C_UNIT_CALENDAR_PERIODS_E,
              AC_BULK_CALENDAR,
              access_control_components:2
          }
        },
        mixins:[AccessControlComponent],
        components:{
            AppAccessControl,
            AppButton,
            UnitBulkCalendarRestriction
        },
        props:{
            ratePlanList: {
                type: Array
            },
            periodId: {
                type:Number
            },
            restrictions: {
                type: Array
            },
            selectedRatePlan:{
                type:Object,

            }
        },
        computed:{
            period() {
                return this.$store.getters['pricing/getPeriodById'](this.periodId)
            },
            statistic() {
                let data = this.$store.getters['pricing/getStatisticById'](this.periodId)
                if (typeof data === "undefined") {
                    return null
                } else {
                    return data
                }
            }
        },
    }
</script>

<style scoped>
    .static-button{
        position: absolute;
        right: 1.3rem;
    }
</style>
