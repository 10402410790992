<template>
    <div>
        <app-table-v2 class="reservation_table" :items="tableData" :fields="computedFields" :provider="getData" :default-limit="defaultLimit" :show-limit="showLimit"
                      @busyToggled="toggleBusy"
                      :selectable="true"
                      :http="{methods:'GET', url:INVOICE_LIST_URL}"
                      :exportColumns="computedExportColumns"
                      :filter="filter"
                      :search-on-create="searchOnCreate"
                      :maxExportLimit="5000" :search-event="searchEventName">
            <template v-slot:reservations="data">
                <template v-if="data.item.reservations.length === 1">
                    <b-link @click="() => {reservationAsideObject={id: data.item.reservations[0].id};reservationAsideState=true}">{{data.item.reservations[0].distribution_booking_id}}
                    </b-link>
                </template>
                <template v-else-if="data.item.reservations.length > 1">
                    <b-link :to="{name: routeNames.RN_RESERVATION_ACCOMMODATION_LIST, query: {invoice: data.item.id}}" target="_blank">{{$t('SHOW_ALL')}}</b-link>
                </template>
                <template v-else>
                    {{''}}
                </template>
            </template>

            <template v-slot:invoice_type="data">
                <small class="text-muted d-block">
                    {{data.item.system_invoice_type.label}}
                </small>
                {{ data.item.invoice_type.name }}
            </template>

            <template v-slot:created_date="data">
                <small class="text-muted d-block">
                    {{data.item.created_date | time}}
                </small>
                <div class="ws-no-wrap">
                    {{data.item.created_date | date}}
                </div>
            </template>

            <template v-slot:invoicee="data">
                <small class="text-muted d-block" v-if="data.item.invoicee.hasOwnProperty('type')">{{data.item.invoicee.type}}</small>
                {{ data.item.invoicee.first_name }} {{ data.item.invoicee.last_name }}
                <small v-if="data.item.invoicee.hasOwnProperty('property')" class="text-muted d-block">{{data.item.invoicee.property}}</small>
            </template>

            <template v-slot:delivery_date="data">
                {{ data.item.delivery_date | date }}
            </template>

            <template v-slot:due_date="data">
                {{ data.item.due_date | date }}
            </template>

            <template v-slot:amount_gross="data">
                {{ data.item.amount_gross }}
            </template>

            <template v-slot:status="data">
                {{ simpleStatus ? simplifyStatus(data.item.status) : data.item.status.label }}
            </template>

            <template v-slot:actions="data">
                <app-button
                    v-if="data.item.action_permissions.download"
                    variant="link"
                    button_type="pdf"
                    :show_text="false"
                    @click="downloadPDF(data.item.id)">
                </app-button>

<!--                <app-button v-if="data.item.action_permissions.cancel && !notCancelableList.includes(data.item.status.id)"-->
<!--                            variant="link"-->
<!--                            icon="cancel"-->
<!--                            :show_text="false"-->
<!--                            @click="openConfirmationDialog(ACTION_CANCEL, data.item.id)">-->
<!--                </app-button>-->

                <app-button v-if="data.item.action_permissions.resend"
                            variant="link"
                            icon="send"
                            :show_text="false"
                            @click="openConfirmationDialog(ACTION_RESEND, data.item.id, data.item.invoicee)">
                </app-button>

                <app-button
                    variant="link"
                    icon="edit"
                    :show_text="false"
                    @click="openDetails(data.item)">
                </app-button>
            </template>
            <template v-slot:tableNoData="props">
                <app-no-data :show-tip="false" :heading="$t('NO_SEARCH_RESULTS')">
                </app-no-data>
            </template>
            <template v-slot:selectedContainerOptions="data">

                <app-access-control @AccessControlEvent="addToAccessControlCounter()" class="pr-0"
                    :access_control_context="{function:F_INVOICE_REPORT_DOWNLOAD,  key:access_control_fetch_key}">
                    <app-button @click="exportReportModal = true"
                                class="pull-right ml-3 ">{{ $t('FINANCIAL_REPORT') }}
                    </app-button>
                </app-access-control>
                <b-modal :title="$t('DOWNLOAD')" v-model="exportReportModal">
                    <small  class="text-muted d-flex justify-content-center">
                        {{$t('EXPORT_MAX_LIMIT_WARNING', {num:5000})}}
                    </small>
                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <div v-if="data.totalRows > 1 && data.totalRows > data.selected.length">
                                <b-checkbox v-model="selectAllForPdfInvoice">
                                    {{$t("APPLY_TO_ALL_NUMBER", {number:data.totalRows})}}
                                    <span v-if="data.selected.length === 1" class="d-block text-muted font-xs">({{$t("ITEM_SELECTED", {value: data.selected.length})}})</span>
                                    <span v-if="data.selected.length > 1" class="d-block text-muted font-xs">({{$t("ITEMS_SELECTED", {value: data.selected.length})}})</span>
                                </b-checkbox>

                            </div>
                            <app-button :loading="exporting_xls"  @click="exportReportXls(data)" class="mr-3">
                                {{$t('DOWNLOAD')}}
                            </app-button>
                        </div>
                    </template>
                </b-modal>

                <app-button v-if="checkPermission(F_INVOICE_DOWNLOAD)" @click="exportPdfModal = true" class="pull-right ml-3 mr-3">{{$t('DOWNLOAD')}}</app-button>
                <b-modal :title="$t('DOWNLOAD')" v-model="exportPdfModal">
                    <small  class="text-muted d-flex justify-content-center">
                        {{$t('EXPORT_MAX_LIMIT_WARNING', {num:5000})}}
                    </small>
                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <div v-if="data.totalRows > 1 && data.totalRows > data.selected.length">
                                <b-checkbox v-model="selectAllForPdfInvoice">
                                    {{$t("APPLY_TO_ALL_NUMBER", {number:data.totalRows})}}
                                    <span v-if="data.selected.length === 1" class="d-block text-muted font-xs">({{$t("ITEM_SELECTED", {value: data.selected.length})}})</span>
                                    <span v-if="data.selected.length > 1" class="d-block text-muted font-xs">({{$t("ITEMS_SELECTED", {value: data.selected.length})}})</span>
                                </b-checkbox>

                            </div>
                            <app-button :loading="exporting_pdf"  @click="exportPdf(data)" class="mr-3">
                               {{$t('DOWNLOAD')}}
                            </app-button>
                        </div>
                    </template>
                </b-modal>
            </template>
        </app-table-v2>

        <app-confirmation-dialog
            :title="confirmationTitle"
            :show="dialogState"
            @confirm="callAction(dialogAction, dialogObjectId)"
            :variant="dialogAction === ACTION_CANCEL ? 'danger': 'primary'"
            @cancel="dialogState=false">
            {{dialogTitle}}
        </app-confirmation-dialog>

        <app-aside v-model="invoiceDetailsAsideState">
            <template slot="header">
                <app-object-header :label="$t('INVOICE')"
                                   :name="invoiceDetailsObject && invoiceDetailsObject.hr_number"
                                   :description="invoiceDetailsObject && invoiceDetailsObject.description">
                </app-object-header>
            </template>
            <invoice-details :invoice-id="invoiceDetailsObject && invoiceDetailsObject.id" @close="closeDetails"></invoice-details>
        </app-aside>

        <app-aside v-model="reservationAsideState" :widths="['col-lg-12','col-sm-12', '100%']" full-width
                   header-styles="position: sticky; top: 0; z-index: 999; background-color:#fff">
            <template slot="header">
                <reservation-accommodation-header :reservation="reservationAsideObject"></reservation-accommodation-header>
            </template>
            <reservation-details :reservation-id="reservationAsideObject.id" tab="general" @update="reservationAsideObject=$event"></reservation-details>
        </app-aside>
    </div>
</template>

<script>
    import AppSearchData from "@/components/app/AppSearchData";
    import AppNoData from "@/components/app/AppNoData";
    import {
        cancelInvoice,
        downloadInvoicePDF, downloadReportXls,
        exportPdfInvoice,
        getInvoiceList,
        sendInvoiceNotice
    } from "@/services/finance";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import AppTable from "@/components/app/AppTable";
    import {EventBus, GE_TABLE_REFRESH_SEARCH, GE_TABLE_SEARCH} from "@/shared/EventBus";
    import {cloneDeep} from "lodash";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import AppPagination from "@/components/app/filter/AppPagination";
    import AppTableV2 from "@/components/app/AppTableV2";
    import {INVOICE_LIST_URL} from "@/shared/constants";
    import AppAside from "@/components/app/form/AppAside";
    import InvoiceDetails from "@/components/finance/invoices/InvoiceDetails";
    import AppObjectHeader from "@/components/app/AppObjectHeader";
    import routeNames from "@/router/routeNames";
    import ReservationAccommodationHeader from "@/components/reservation/accommodation/ReservationAccommodationHeader";
    import ReservationDetails from "@/components/reservation/accommodation/ReservationDetails";
    import {R_RESERVATION_ACCOMMODATION_GENERAL, R_RESERVATION_ACCOMMODATION_LIST} from "@/shared/route_permission";
    import {F_INVOICE_DOWNLOAD,F_INVOICE_REPORT_DOWNLOAD} from "@/shared/function_permission";
    import moment from "moment";
    import {fileHelpers} from "@/mixins/shared/helpers";
    import {downloadGenericExportFile} from "@/services/document";
    import {AC_INVOICE_NEW} from "@/mixins/AccessControl/AccessControlEnumeration";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

    const [CREATED, PROCESSING, PROCESSED, COMPLETED, PAID, CANCELED] = [1, 2, 3, 4, 5, 6]
    const STATUS_UNPAID_LIST = [CREATED, PROCESSING, PROCESSED, COMPLETED];

    const ACTION_CANCEL = 1
    const ACTION_RESEND = 2

    const actions = {
        // [ACTION_CANCEL]: cancelInvoice,
        [ACTION_RESEND]: sendInvoiceNotice
    }

    export default {
        name: "InvoicesListTable",
        components: {
            AppAccessControl,
            ReservationDetails,
            ReservationAccommodationHeader,
            AppObjectHeader,
            InvoiceDetails,
            AppTableV2, AppPagination, AppTable, AppButton, AppNoData, AppSearchData, AppConfirmationDialog, AppAside},
        mixins: [AccessControlComponent,getErrorMessage,fileHelpers],
        props: {
            searchCriteria: {
                type: Object,
                default() {
                    return {}
                }
            },
            searchOnCreate: {
                type: Boolean,
                default: true
            },
            simpleStatus: {
                type: Boolean,
                default: false
            },
            omittedFields: {
                type: Array,
                default() {
                    return []
                }
            },
            defaultLimit: {
                type: Number,
                default: 10
            },
            showLimit: {
                type: Boolean,
                default: true
            },
            searchEventName: {
                type: String,
                default: 'getInvoiceList'
            }
        },
        data() {
            return {
                F_INVOICE_DOWNLOAD,
                F_INVOICE_REPORT_DOWNLOAD,
                tableData: [],
                exportPdfModal:false,
                exportReportModal:false,
                exporting_pdf:false,
                exporting_xls:false,
                fields: [
                    {key: 'id', label: 'Id'},
                    {key: 'hr_number', label: this.$t('NUMBER'), class: 'ws-no-wrap'},
                    {key: 'description', label: this.$t('DESCRIPTION')},
                    {
                        key: 'reservations',
                        label: this.$t('RESERVATION_CODE'),
                        hasPermission: () => (this.checkPermission(R_RESERVATION_ACCOMMODATION_GENERAL) && this.checkPermission(R_RESERVATION_ACCOMMODATION_LIST))
                    },
                    {key: 'invoice_type', label: this.$t('TYPE'), tdClass: ''},
                    {key: 'invoicee', label: this.$t('RECIPIENT'), class: 'w-10'},
                    {key: 'amount_gross', label: this.$t('AMOUNT'), tdClass: 'ws-no-wrap text-right'},
                    {key: 'status', label: this.$t('STATUS')},
                    {
                        key: 'delivery_date',
                        label: this.$t('DELIVERY_DATE'),
                        tdClass: 'ws-no-wrap ',
                        sortable: true
                    }, /////////////////
                    {key: 'due_date', label: this.$t('DUE_DATE'), tdClass: 'ws-no-wrap', sortable: true}, ////////////////////////////
                    {key: 'created_date', label: this.$t('DATE_CREATED'), sortable: true, tdClass: ''},
                    {key: 'actions', label: '', class: 'ws-no-wrap text-right'},
                ],
                exportColumns:[
                    {value: 'id', text: 'Id'},
                    {value: 'hr_number', text: this.$t('NUMBER')},
                    {value: 'description', text: this.$t('DESCRIPTION')},
                    {value: 'invoice_type.name', text: this.$t('TYPE')},
                    {value: 'invoicee.first_name', text: this.$t('RECIPIENT')},
                    {value: 'amount_gross', text: this.$t('AMOUNT')},
                    {value: 'status.label', text: this.$t('STATUS')},
                    {value: 'delivery_date', text: this.$t('DELIVERY_DATE')},
                    {value: 'due_date', text: this.$t('DUE_DATE')},
                    {value: 'created_date', text: this.$t('DATE_CREATED')}
                ],
                filter: {},
                ACTION_CANCEL, ACTION_RESEND,
                dialogAction: null,
                dialogObjectId: null,
                dialogState: false,
                dialogTitle: '',
                confirmationTitle:'',
                notCancelableList: [CREATED, PROCESSING, CANCELED],
                INVOICE_LIST_URL,
                invoiceDetailsAsideState: false,
                invoiceDetailsObject: null,
                routeNames,
                reservationAsideObject: {},
                reservationAsideState: false,
                selectAllForPdfInvoice:false,
                AC_INVOICE_NEW,
                access_control_fetch_key: AC_INVOICE_NEW,
                access_control_components: 1,
                access_control_general_context: null,
            }
        },
        computed: {
            computedFields() {
                return this.fields.filter(el => !this.omittedFields.includes(el.key) && (!el.hasPermission || el.hasPermission()))
            },
            computedExportColumns(){
                return this.exportColumns.filter(el => !this.omittedFields.includes(el.value))
            },
            company() {
                return this.$store.getters['user/getCurrentCompany']
            }
        },
        watch: {
            filter: {
                handler() {
                    EventBus.$emit(this.searchEventName)
                },
                deep: true
            }
        },
        methods: {
            exportPdf(data){
                let requestData
                if (this.selectAllForPdfInvoice) {
                    requestData = {...this.filter, limit: 5000}
                } else {
                    requestData = {invoice: data.selected.map(el => el.id)}
                }

                this.exporting_pdf = true
                exportPdfInvoice(requestData).then(response => {
                    if (response) {
                        this.handleFileDownload(response, `${this.$t('INVOICES')}_${moment(new Date()).format('YYYY-MM-DD')}`, 'zip')
                    }

                    this.exportPdfModal = false
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => this.exporting_pdf = false)


            },
            exportReportXls(data){
                this.exporting_xls = true
                let requestData

                if (this.selectAllForPdfInvoice) {
                    requestData = {filter:{...this.filter, limit: 5000}}
                } else {
                    requestData = {data: data.selected}
                }


                downloadReportXls(requestData).then(response => {
                    if (response) {
                        this.handleFileDownload(response, `${this.$t('INVOICES')}_${moment(new Date()).format('YYYY-MM-DD')}`,'xlsx')
                    }

                    this.exportReportModal = false
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.exporting_xls = false
                })
            },
            getData(filter) {
                let req = {
                    page: filter.currentPage,
                    limit: filter.perPage,
                    order_by: filter.sortBy,
                    order_by_direction: filter.sortDirection,
                    company: this.company.id,
                    ...this.filter,
                }
                return getInvoiceList(req)
            },
            simplifyStatus(status) {
                if (Array.prototype.includes.call(STATUS_UNPAID_LIST, status.id)) {
                    return this.$t('UNPAID')
                } else {
                    return status.label
                }
            },
            downloadPDF(id) {
                downloadInvoicePDF(id).then(response => {
                    const url = window.URL.createObjectURL(response);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${id}.pdf`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }).catch((error) => {
                    this.showErrorMessages(error)
                })
            },
            openConfirmationDialog(action, id, partner = {}) {
                this.dialogAction = action
                this.dialogTitle = action === ACTION_CANCEL ? this.$t('INVOICE_CANCEL_DIALOG', {value: id}) : this.$t('INVOICE_RESEND_DIALOG', {
                    id,
                    email: partner.email,
                    interpolation: {escapeValue: false}
                })
                this.confirmationTitle = action  === ACTION_CANCEL ? this.$t('INVOICE_CANCEL_CONFIRMATION') : this.$t('INVOICE_DELIVERY_CONFIRMATION')
                this.dialogObjectId = id
                this.dialogState = true
            },
            callAction(action, id) {
                actions[action](id).then(() => {
                    notifySuccess()
                }, (error) => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.dialogState = false
                    if (action === ACTION_CANCEL) {
                        this.search(this.filter)
                    }
                })
            },
            search(filter) {
                this.filter = cloneDeep(filter)
            },
            toggleBusy($event) {
                EventBus.$emit('invoiceLoading', $event)
            },
            openDetails(invoice) {
                this.invoiceDetailsObject = invoice
                this.invoiceDetailsAsideState = true
            },
            closeDetails() {
                this.invoiceDetailsObject = null
                this.invoiceDetailsAsideState = false
                EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
            }
        },
        mounted() {
            EventBus.$on('invoiceSearch', (filter) => {
                this.search(filter)
            })
        },
        created() {
            this.access_control_components = 1
            this.access_control_general_context = {
                company: this.company.id
            }

            if (this.searchOnCreate) {
                this.filter = cloneDeep(this.searchCriteria)
            }
        },
        beforeDestroy() {
            if (![routeNames.RN_INVOICE_LIST, routeNames.RN_OWNER_INVOICE_LIST].includes(this.$route.name)) {
                EventBus.$off('invoiceSearch')
            }
        }
    }
</script>

<style scoped>

</style>
