<template>
    <span class="header-button-list">
        <app-button
            :disabled="disabled"
            :message="message"
            class="pull-right ml-3"
            :key="'nav_button_'+navButtonIndex"
            v-for="(navButton, navButtonIndex) in nav_button_list"
            :to="navButton.to"
            :c_permission="navButton.permission"
            :loading="navButton.loading"
            :button_type="navButton.button_type">
            {{navButton.label}}
        </app-button>

        <app-button
            v-if="hasPermission(button.render_permission)"
            :v_show="button_list(button)"
            @AccessControlEvent="addToAccessControlCounter"
            :access_control_context="addContext(button)"
            :disabled="disabled"
            :message="message"
            class="pull-right ml-3"
            :key="'button_ev_'+navButtonIndex"
            v-for="(button, navButtonIndex) in buttons"
            :c_permission="button.permission"
            :loading="button.loading"
            @click="EventBusNotify(button.event)"
            :button_type="button.button_type">
            {{button.label}}
        </app-button>
    </span>
</template>

<script>
    import AppButton from '@/components/app/AppButton/AppButton'
    import routes from '@/router/routeNames'
    import {
        R_PROPERTY_OWNER_NEW,
        R_PROPERTY_UNIT_NEW
    } from "@/shared/route_permission";
    import {
        EventBus,
        GE_OPEN_NEW_ACC_RES_ASIDE,
        GE_OPEN_OWNER_WIZARD,
        GE_OPEN_UNIT_WIZARD,
        GE_OPEN_AJPES_MONTHLY_REPORT_ASIDE,
        GE_CALENDAR_OPEN_DISPLAY_SETTINGS_ASIDE,
        GE_CALENDAR_OPEN_GROUP_EDIT_ASIDE,
        GE_CALENDAR_OPEN_SYNC_ASIDE,
        GE_CALENDAR_OPEN_SYNC_LOADING,
        GE_CALENDAR_OPEN_IMPORT_EXPORT_PRICE_LIST_ASIDE, GE_CANCELLATION_POLICY_NEW, GE_CANCELLATION_POLICY_APPLY,
    } from "@/shared/EventBus";
    import {
        C_PROPERTY_NAVIGATION_HEADER_BUTTON,
        C_RESERVATION_ACCOMMODATION_NEW,
        C_PROPERTY_CALENDAR_BULK_V,
        C_PROPERTY_CALENDAR_SYNC_E,
        C_PROPERTY_CALENDAR_SYNC_V,
        C_PROPERTY_CALENDAR_AVAILIBILITY_V,
        C_PROPERTY_CALENDAR_PRICES_V,
        C_PROPERTY_CALENDAR_RESTRICTIONS_V,
        C_PROPERTY_CALENDAR_RM_SUGGESTION_V,
        C_PROPERTY_CALENDAR_ROOM_STATUS_V,
        C_PROPERTY_CALENDAR_PRICES_IMPORT_EXPORT_V,
        C_PROPERTY_CANCELLATION_POLICY_NEW,
        C_PROPERTY_CANCELLATION_POLICY_APPLY_SYSTEM_POLICY
    } from "@/shared/component_permission";
    import {fetchAccessControlData} from "@/services/access";
    import {
        AC_PROPERTY_CANCELLATION_POLICY_KEY,
        AC_PROPERTY_HEADER_BUTTON_KEY
    } from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";


    export default {
        name: "HeaderButton",
        components: {AppButton},
        mixins: [AccessControlComponent],
        data() {
            return {
                access_control_fetch_key: AC_PROPERTY_HEADER_BUTTON_KEY,
                access_control_components: 2,
                ac_response: null,
                component_id: null,
                sync_loading: false,
                buttons: [
                    {
                        route_name: routes.RN_PROPERTY_OWNER_LIST,
                        permission: R_PROPERTY_OWNER_NEW,
                        label: this.$t("NEW_OWNER"),
                        button_type: 'new',
                        event: GE_OPEN_OWNER_WIZARD,
                        access_control_context: {
                            function: C_PROPERTY_NAVIGATION_HEADER_BUTTON,
                            key: AC_PROPERTY_HEADER_BUTTON_KEY
                        },
                        condition: (ownerCount) => ownerCount > 0
                    },
                    {
                        route_name: routes.RN_PROPERTY_UNIT_LIST,
                        permission: R_PROPERTY_UNIT_NEW,
                        label: this.$t("NEW_UNIT"),
                        button_type: 'new',
                        event: GE_OPEN_UNIT_WIZARD,
                        access_control_context: {
                            function: C_PROPERTY_NAVIGATION_HEADER_BUTTON,
                            key: AC_PROPERTY_HEADER_BUTTON_KEY
                        },
                        condition: (unitCount) => unitCount > 0
                    },
                    {
                        render_permission: C_PROPERTY_CALENDAR_SYNC_E,
                        route_name: routes.RN_PROPERTY_CALENDAR,
                        label: this.$t("SYNC"),
                        button_type: 'main',
                        event: GE_CALENDAR_OPEN_SYNC_ASIDE,
                        /*access_control_context: {
                            function: C_PROPERTY_CALENDAR_SYNC_V,
                            key: AC_PROPERTY_HEADER_BUTTON_KEY
                        },*/
                    },
                    {
                        render_permission: C_PROPERTY_CALENDAR_PRICES_IMPORT_EXPORT_V,
                        route_name: routes.RN_PROPERTY_CALENDAR,
                        label: this.$t("PRICE_LIST"),
                        button_type: 'main',
                        event: GE_CALENDAR_OPEN_IMPORT_EXPORT_PRICE_LIST_ASIDE,
                    },
                    {
                        route_name: routes.RN_PROPERTY_CALENDAR,
                        label: this.$t("EDIT"),
                        button_type: 'main',
                        event: GE_CALENDAR_OPEN_GROUP_EDIT_ASIDE,
                        access_control_context: {
                            function: C_PROPERTY_CALENDAR_BULK_V,
                            key: AC_PROPERTY_HEADER_BUTTON_KEY
                        },
                    },
                    {
                        route_name: routes.RN_PROPERTY_GUEST_CHECKIN,
                        permission: null,
                        label: this.$t("NEW_MONTHLY_REPORT"),
                        button_type: 'new',
                        event: GE_OPEN_AJPES_MONTHLY_REPORT_ASIDE,
                        condition: (data) => data !== null
                    },
                    {
                        route_name: routes.RN_PROPERTY_CANCELATION_POLICIES,
                        permission: C_PROPERTY_CANCELLATION_POLICY_NEW,
                        label: this.$t("NEW_POLICY"),
                        button_type: "new",
                        event: GE_CANCELLATION_POLICY_NEW,
                        access_control_context: {
                            function: C_PROPERTY_CANCELLATION_POLICY_NEW,
                            key: AC_PROPERTY_HEADER_BUTTON_KEY,
                        },
                        condition: (propertyCancellationPolicyCount) => propertyCancellationPolicyCount > 0,
                    },
                    {
                        route_name: routes.RN_PROPERTY_CANCELATION_POLICIES,
                        permission: C_PROPERTY_CANCELLATION_POLICY_APPLY_SYSTEM_POLICY,
                        label: this.$t("APPLY_POLICY"),
                        button_type: "new",
                        event: GE_CANCELLATION_POLICY_APPLY,
                        access_control_context: {
                            function: C_PROPERTY_CANCELLATION_POLICY_APPLY_SYSTEM_POLICY,
                            key: AC_PROPERTY_HEADER_BUTTON_KEY,
                        },
                        condition: (propertyCancellationPolicyCount) => propertyCancellationPolicyCount > 0,
                    },
                ],

                nav_buttons: [
                    // {
                    //     route_name: [routes.RN_PROPERTY_UNIT_LIST],
                    //     to: {name: routes.RN_PROPERTY_UNIT_NEW},
                    //     permission: R_PROPERTY_UNIT_NEW,
                    //     label: this.$t("NEW_UNIT"),
                    //     button_type: 'new',
                    //         access_control_context: {
                    //     function: C_PROPERTY_NAVIGATION_HEADER_BUTTON,
                    //     key: AC_PROPERTY_HEADER_BUTTON_KEY
                    // },
                    //     condition: (unitCount) => unitCount > 0
                    // }
                ]
            }
        },
        computed: {
            property() {
                return this.$store.getters['property/getProperty']
            },
            ajpes_connection() {
                // has to go through the store because fetching is in another component
                return this.$store.getters['property/getAjpesConnection']
            },
            cancellationPolicies() {
                return this.$store.getters["property/getCancellationPolicies"]
            },
            disabled() {
                if (this.ac_response && this.ac_response[this._uid]) {
                    let acObject = this.ac_response[this._uid]
                    if (this.$route.name === routes.RN_PROPERTY_UNIT_LIST || this.$route.name === routes.RN_PROPERTY_OWNER_LIST && acObject) {
                        return acObject.disabled
                    }
                }
            },
            message() {
                if (this.ac_response && this.ac_response[this._uid]) {
                    let acObject = this.ac_response[this._uid]
                    if (this.$route.name === routes.RN_PROPERTY_UNIT_LIST || this.$route.name === routes.RN_PROPERTY_OWNER_LIST && acObject && acObject.hasOwnProperty('messages') && acObject.messages.length > 0) {
                        return typeof acObject.messages[0] !== 'undefined' && acObject.messages[0].hasOwnProperty('message') ? acObject.messages[0].message : null
                    }
                }
            },

            availibilityViewPermission() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_AVAILIBILITY_V)
            },
            pricesViewPermission() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_PRICES_V)
            },
            restrictionsViewPermission() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_RESTRICTIONS_V)
            },
            rmSuggestionViewPermission() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_RM_SUGGESTION_V)
            },
            roomStatusViewPermission() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_ROOM_STATUS_V)
            },
            view_options() {
                let options = []
                if (this.availibilityViewPermission) {
                    options.push({id: C_PROPERTY_CALENDAR_AVAILIBILITY_V, label: this.$t("AVAILABILITY")})
                }
                if (this.roomStatusViewPermission) {
                    options.push({id: C_PROPERTY_CALENDAR_ROOM_STATUS_V, label: this.$t("ROOM_STATUS")})
                }
                if (this.pricesViewPermission) {
                    options.push({id: C_PROPERTY_CALENDAR_PRICES_V, label: this.$t("RATES")})
                }
                if (this.restrictionsViewPermission) {
                    options.push({id: C_PROPERTY_CALENDAR_RESTRICTIONS_V, label: this.$t("RESTRICTIONS")})
                }

                if (this.workflowDB && this.rmSuggestionViewPermission) {
                    options.push({id: C_PROPERTY_CALENDAR_RM_SUGGESTION_V, label: this.$t("RATE_MANAGER")})
                }

                return options
            },
        },

        methods: {
            button_list(btn) {

                let condition = true
                if (btn.hasOwnProperty('condition')) {
                    if (this.$route.name === routes.RN_PROPERTY_UNIT_LIST) {
                        condition = btn.condition(this.property.unit_count_list.total_count)
                    }
                    if (this.$route.name === routes.RN_PROPERTY_OWNER_LIST) {
                        condition = btn.condition(this.property.owner_count)
                    }

                    if (this.$route.name === routes.RN_PROPERTY_GUEST_CHECKIN) {
                        condition = !!this.ajpes_connection
                    }

                    if (this.$route.name === routes.RN_PROPERTY_CALENDAR) {
                        condition = btn.condition(this.view_options.length)
                    }

                    if (this.$route.name === routes.RN_PROPERTY_CANCELATION_POLICIES) {
                        condition = btn.condition(this.cancellationPolicies.length)
                    }
                }
                if(this.$route.name === routes.RN_PROPERTY_CALENDAR_OPT_TEST){
                    if(btn.event  === GE_CALENDAR_OPEN_DISPLAY_SETTINGS_ASIDE ){
                        return false //todo
                        // remove after merge test calendar_old
                    }
                   return  btn.route_name === routes.RN_PROPERTY_CALENDAR
                } else {
                    return btn.route_name === this.$route.name && condition
                }
            },
            nav_button_list(btn) {
                if (this.nav_buttons.length === 0) {
                    return []
                }
                let condition = true
                if (btn.hasOwnProperty('condition')) {
                    if (this.$route.name === routes.RN_PROPERTY_UNIT_LIST) {
                        condition = btn.condition(this.property.unit_count_list.total_count)
                    }
                }
            },
            hasPermission(value) {
                if (value) {
                    return this.$store.getters['user/getPermission'](value)
                }
                return true
            },
            EventBusNotify(eventName) {
                EventBus.$emit(eventName)
            },
            addContext(buttonObject) {
                return buttonObject.hasOwnProperty('access_control_context') ? buttonObject.access_control_context : null

            },
        },
        created() {

            this.access_control_general_context = {
                property: this.property.id,
                company: this.$store.getters['user/getCompany']
            }

        },
        async mounted() {
            EventBus.$on(GE_CALENDAR_OPEN_SYNC_LOADING, (value) => {
                const buttonIndex = this.buttons.findIndex(el => el.event === GE_CALENDAR_OPEN_SYNC_ASIDE)
                this.$set(this.buttons[buttonIndex], 'loading', value);
            })

        }
    }
</script>

<style scoped>

</style>
