<template>
    <div v-if="font && external">
        <form>
            <b-row class="mt-4">
                <b-col lg="6">
                    <label>{{$t('FONT_SOURCE')}}</label>
                    <app-select :options="typeOptions"
                                v-model="fontType"
                                text-field="label"
                                :search-empty-item="false"></app-select>
                </b-col>
            </b-row>
        </form>
        <form v-if="fontType === 2" :key="external_key">
            <b-row class="mt-4">
                <b-col>
                    <app-translation-input :languages="supportedLanguages" v-model="external.label"
                                           :label="$t('NAME')"
                                           :lang_id="external.lang_id"></app-translation-input>
                </b-col>
                <b-col>
                    <app-input-control :error-object="validation.external_source">
                        <template v-slot:input="data">
                            <label>{{$t('SOURCE')}}</label>
                            <app-select v-model="external.font_source"
                                        :options="sourceOptions"
                                        text-field="name"
                                        :search-empty-item="false"
                                        :state="data.state"
                            ></app-select>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <app-input-control :error-object="validation.external">
                        <template v-slot:input="data">
                            <label>{{$t('FONT')}}</label>
                            <app-select v-model="external.external"
                                        :disabled="external.source===null"
                                        text-field="label"
                                        mode="search"
                                        :search-empty-item="false"
                                        return-type="object"
                                        :search="searchExternalFonts"
                                        :state="data.state"
                            ></app-select>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col>
                    <app-input-control :error-object="validation.external_fallback">
                        <template v-slot:input="data">
                            <label>{{$t('FONT_FALLBACK')}}</label>
                            <app-select :options="systemFonts"
                                        v-model="external.fallback"
                                        :search-empty-item="false"
                                        text-field="name"
                                        :state="data.state"
                            ></app-select>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
        </form>
        <form v-else :key="system_key">
            <b-row class="mt-4">
                <b-col>
                    <app-translation-input :languages="supportedLanguages" v-model="external.label"
                                           :label="$t('NAME')"
                                           :lang_id="external.lang_id"></app-translation-input>
                </b-col>
                <b-col>
                    <app-input-control :error-object="validation.system_font">
                        <template v-slot:input="data">
                            <label>{{ $t('FONT') }}</label>
                            <app-select :options="systemFonts"
                                        v-model="external.system_font"
                                        :search-empty-item="false"
                                        text-field="name"
                                        return-type="object"
                                        :state="data.state"
                            ></app-select>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
        </form>
        <b-row class="mt-4">
            <b-col>
                <app-button @click="saveFont" :loading="saveLoading">{{ $t('SAVE') }}</app-button>
            </b-col>
<!--            <b-col lg="6" class="d-flex flex-lg-row-reverse">-->
<!--                <app-button-delete @click="deleteDialogState=true"></app-button-delete>-->
<!--            </b-col>-->
        </b-row>
        <app-confirmation-dialog
            :show="deleteDialogState"
            @confirm="deleteFont"
            @cancel="deleteDialogState=false"
            :delete_title="true"
        ></app-confirmation-dialog>
    </div>
</template>

<script>

import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButton from "@/components/app/AppButton/AppButton";
import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppInputControl from "@/components/app/AppInputControl";
import {
    deleteThemeFont,
    editThemeFont,
    fetchThemeFont,
    getThemeExternalFonts,
    getThemeFontSources,
    getThemeSystemFonts
} from "@/services/appearance";
import {getSystemLanguageList, updateTranslations} from "@/services/system";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {
    APPEARANCE_EXTERNAL_FONT_EXTERNAL_NOT_FOUND,
    APPEARANCE_EXTERNAL_FONT_FALLBACK_NOT_FOUND,
    APPEARANCE_EXTERNAL_FONT_SOURCE_NOT_FOUND,
    APPEARANCE_SYSTEM_FONT_FONT_NOT_FOUND
} from "@/shared/error_codes";

export default {
    name: "AppearanceEditFontForm",
    components:{AppSelect, AppButton, AppTranslationInput, AppButtonDelete, AppConfirmationDialog, AppInputControl},
    mixins:[getErrorMessage],
    props:{
        font:{
            type: Object,
            default: null
        }
    },
    data(){
        return{
            systemFonts: [],
            sourceOptions: [],
            saveLoading: false,
            deleteDialogState: false,
            external: [],
            supportedLanguages: [],
            typeOptions:[
                {id: 1, label: this.$t('SYSTEM')},
                {id: 2, label: this.$t('EXTERNAL')}
            ],
            fontType: this.font.theme_font_source === null ? 1 : 2,
            validation: {
                system_font: APPEARANCE_SYSTEM_FONT_FONT_NOT_FOUND,
                external_source: APPEARANCE_EXTERNAL_FONT_SOURCE_NOT_FOUND,
                external_fallback: APPEARANCE_EXTERNAL_FONT_FALLBACK_NOT_FOUND,
                external: APPEARANCE_EXTERNAL_FONT_EXTERNAL_NOT_FOUND
            },
            system_key: 0,
            external_key: 0,
        }
    },
    methods:{
        searchExternalFonts(value){
            return getThemeExternalFonts({source_id: this.external.font_source, name: value}).then(response => {
                return {data: response.data}
            })
        },
        saveFont(){
            this.saveLoading = true
            editThemeFont(this.font.id, this.prepareData()).then(response => {
                this.saveLoading = false
                notifySuccess()
                updateTranslations(this.external.lang_id, {"en": this.external.label}).then(response => {
                }, error => {
                    this.showErrorMessages(error)
                })
                this.$emit('edit-saved')
            }, error => {
                this.saveLoading = false
                this.showErrorMessages(error, this.validation)
            })
        },
        deleteFont(){
            deleteThemeFont(this.font.id).then(response => {
                notifySuccess()
                this.$emit('edit-saved')
                this.deleteDialogState = false
            }, error => {
                this.deleteDialogState = false
                this.showErrorMessages(error)
            })
        },
        prepareData(){
            if (this.fontType === 1){
                return {
                    external: null,
                    fallback: null,
                    font_source: null,
                    id: this.external.id,
                    label: this.external.label,
                    lang_id: this.external.lang_id,
                    name: this.external.name,
                    system_font: this.external.system_font,
                    font_type: this.fontType
                }
            }else {
                return {
                    ...this.external,
                    font_type: this.fontType
                }
            }
        }
    },
    created() {
        getThemeSystemFonts().then(response => {
            this.systemFonts = response.data
        })
        getThemeFontSources().then(response => {
            this.sourceOptions = response.data
        })
        fetchThemeFont(this.font.id).then(response => {
            this.external = response.data
        })
        getSystemLanguageList().then(response => {
            response.data.forEach(el => {this.supportedLanguages.push(el.name)})
        })
    },
    watch:{
        fontType:{
            handler(){
                this.system_key += 1
                this.external_key += 1
            }
        }
    }
}
</script>

<style scoped>

</style>
