<template>
    <div v-if="setupParameter">
        <div v-if="setupParameter.complex_object.type === 'bool'">
            <b-form-checkbox unchecked-value="0" value="1"
                             v-model="setupParameter.value_bool" switch> {{ parameter.label }}
            </b-form-checkbox>
        </div>
        <div v-if="setupParameter.complex_object.type === 'select_bool'">
            <label>{{ setupParameter.label }}</label>
            <app-select mode="select"
                        v-model="setupParameter.value_bool"
                        :options="setupParameter.complex_object.allowed_values"
                        value-field="id"
                        text-field="label">
            </app-select>
        </div>
        <div v-if="setupParameter.complex_object.type === 'text'">
            <label>{{ setupParameter.label }}</label>
            <b-form-input v-model="setupParameter.value_text"></b-form-input>
        </div>
        <div v-if="setupParameter.complex_object.type==='textarea'">
            <label>{{ setupParameter.label }}</label>
            <b-form-textarea
                rows="4"
                v-model="parameter.value">
            </b-form-textarea>
        </div>
        <div v-if="setupParameter.complex_object.type === 'select'">
            <label>{{ setupParameter.label }}</label>
            <app-select mode="select"
                        v-model="setupParameter.value_text"
                        :options="setupParameter.complex_object.allowed_values"
                        value-field="id"
                        text-field="label">
            </app-select>
        </div>
        <div v-if="setupParameter.complex_object.type==='multiselect'">
            <label>{{ setupParameter.label }}</label>
            <app-select mode="multiselect"
                        v-model="setupParameter.value_text"
                        :options="setupParameter.complex_object.allowed_values"
                        value-field="id"
                        text-field="label">
            </app-select>
        </div>
        <div v-if="setupParameter.complex_object.type === 'custom'">
            <form-header>{{ setupParameter.complex_object.label }}</form-header>
            <b-row>
                <b-col v-for="(item, index) in setupParameter.complex_object.objects" lg="6" md="6" class="mb-4">
                    <distribution-reservation-parameter-type-item :itemType="item"
                                                                  @update="setObjectItem($event, index)"
                                                                  :supported_languages="supportedLanguages">
                    </distribution-reservation-parameter-type-item>
                </b-col>
            </b-row>
        </div>
        <div v-if="setupParameter.complex_object.type === 'composite'">
            <b-row>
                <b-col class="d-flex justify-content-between align-items-baseline">
                    <form-header>{{ setupParameter.complex_object.label }}</form-header>
                    <app-button @click="addNewItem(setupParameter)" button_type="new" class="pull-right">
                        {{ $t('NEW_ITEM') }}
                    </app-button>
                </b-col>
            </b-row>

            <template v-for="(items,index) in setupParameter.complex_object.objects">
                <b-row>
                    <b-col>
                        <b-col class="mb-4 d-flex justify-content-between align-items-center pr-0">
                            <span class="font-weight-bold">{{ $t('ITEM') }} #{{ index + 1 }}</span>
                            <app-button-delete @click="unsetParam(items,index)"></app-button-delete>
                        </b-col>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-for="(item, itemIndex) in items" lg="6" md="6" class="mb-4">
                        <distribution-reservation-parameter-type-item :itemType="item"
                                                                      @update="setObjectItem($event, index, itemIndex)"
                                                                      :supported_languages="supportedLanguages">
                        </distribution-reservation-parameter-type-item>
                    </b-col>
                </b-row>
                <hr class="mt-0 mb-4">
            </template>
        </div>


        <div v-if="setupParameter.complex_object.type === 'composite_custom'">
            <b-row>
                <b-col class="d-flex justify-content-between align-items-baseline">
                    <form-header>{{ setupParameter.complex_object.label }}</form-header>
                    <app-button @click="addNewItem(setupParameter)" button_type="new" class="pull-right">
                        {{ $t('NEW_ITEM') }}
                    </app-button>
                </b-col>
            </b-row>

            <template v-for="(items,index) in setupParameter.complex_object.objects">
                <b-row>
                    <b-col>
                        <b-col class="mb-4 d-flex justify-content-between">
                            <span class="font-weight-bold">{{ $t('ITEM') }} #{{ index + 1 }}</span>
                            <app-button-delete v-if="index > 0" @click="unsetParam(items,index)"></app-button-delete>
                        </b-col>
                    </b-col>
                </b-row>
                <b-row>
                    <template v-for="(item, itemIndex) in items">
                        <template v-for="(subItem,subIndex) in item">
                            <b-col v-if="subItem.type && subItem.type==='custom'" lg="12" md="12">
                                <b-row>
                                    <b-col v-for="subItem1 in subItem.objects" lg="6" md="6" class="mb-4">
                                        <distribution-reservation-parameter-type-item :itemType="subItem1"
                                                                                      @update="setObjectItem($event, index, itemIndex, subIndex)"
                                                                                      :supported_languages="supportedLanguages">
                                        </distribution-reservation-parameter-type-item>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col v-else md="6" class="mb-4">
                                <distribution-reservation-parameter-type-item :itemType="subItem"
                                                                              :supported_languages="supportedLanguages">
                                </distribution-reservation-parameter-type-item>
                            </b-col>
                        </template>
                    </template>
                </b-row>
                <hr class="mt-0 mb-4">
            </template>
        </div>
    </div>
</template>

<script>


import {cloneDeep} from "lodash";

export default {
    name: "DistributionReservationParameterItem",
    components: {
        "AppButtonDelete": () => import("@/components/app/AppButton/AppButtonDelete"),
        "FormHeader": () => import("@/components/app/form/FormHeader"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "DistributionReservationParameterTypeItem": () => import("@/components/channel_manager/distribution/DistributionReservationParameterTypeItem"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
    },
    data() {
        return {
            showAside: false,
            reservationParams: null,
            saving: false,
            applyList: [],
            test: null,
            setupParameter: null,
        }
    },
    props: {
        parameter: {
            type: Object,
            default() {
                return null
            }
        },
        supportedLanguages: {
            type: Array,
            default() {
                return []
            }
        },
    },
    methods: {
        addNewItem(value) {
            let item = cloneDeep(value.complex_object.template)
            value.complex_object.objects.push(item)
        },
        unsetParam(item,index){
            this.setupParameter.complex_object.objects.splice(index, 1)
        },
        setObjectItem(objectItem, index, itemIndex = null, subItemIndex = null) {
            if (subItemIndex !== null) {
                this.$set(this.setupParameter.complex_object.objects[index][itemIndex], subItemIndex, objectItem)
            } else if (itemIndex !== null) {
                this.$set(this.setupParameter.complex_object.objects[index], itemIndex, objectItem)
            } else {
                this.$set(this.setupParameter.complex_object.objects, index, objectItem)
            }
        },
    },
    watch: {
        parameter: {
            handler(setupParameter) {
                if (setupParameter !== null) {
                    this.setupParameter = setupParameter
                } else {
                    setupParameter = null
                }
            },
            immediate: true,
            deep: true,
        },
        setupParameter: {
            handler(value) {
                if (value) {
                    this.$emit('update', value)
                }
            },
            immediate: true,
            deep: true,
        }
    },
}
</script>

<style scoped>

</style>
