<template>
    <app-aside :main-classes="['p-0 aside-calendar']" v-model="opened" :widths="['col-lg-5','col-sm-12', '40%']">
        <template slot="header">{{formHeader}}</template>

        <template>

                <b-row class="mb-2">
                    <b-col lg="12">
                        <div class="table-wrapper">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th class="table-header-period-range" scope="col">{{ $t('PERIOD') }}</th>
                                    <th class="table-header-actions text-right" scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <period-item :disabled="true" @delPeriod="date_ranges.splice(index, 1)"
                                             :key="index" v-for="(period,index) in date_ranges"
                                             :period="period"
                                             :max-date="maxDate"
                                             ref="period_items" :disabled-dates="disabledDates"></period-item>
                                <period-item-new @save="addPeriod"
                                                 :max-date="maxDate"
                                                 :from-date="fromDate"
                                                 :disabled-dates="disabledDates"></period-item-new>
                                </tbody>
                            </table>
                        </div>
                    </b-col>

                </b-row>
            <b-form>
                <b-row>
                    <b-col>
                        <label>{{$t('WEEKDAY_APPLY_CHANGES_TO')}}</label>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col class="d-flex">
                        <b-form-checkbox v-model="selected_days_all" :inline="true" :value="true"
                                         :unchecked-value="false" class="b-r-1 pr-1 mr-1">{{$t("SELECT_ALL")}}
                        </b-form-checkbox>
                        <b-form-checkbox-group v-model="selected_days"  :options="options"
                                                name="" class="ml-0"></b-form-checkbox-group>
                    </b-col>
                </b-row>
            </b-form>

            <b-row>
                <b-col>

                    <b-tabs v-model="tab" nav-class="tab_navigation-horizontal" content-class="tab_content-horizontal"
                            pills align="left">
                        <b-tab :title="contigentMode || contigentUnit === null ? $t('AVAILABILITY_AND_UNIT_STATUS') : $t('AVAILABILITY') " v-if="previewAvailability" :key="0">
                            <form-header v-if="contigentMode || contigentUnit === null" :headerClass="'mt-0 mb-4'">{{$t('AVAILABILITY')}}</form-header>
                            <calendar-aside-availability
                                    class="mb-4"
                                    :default-values="defaultValues"
                                    :date-ranges="date_ranges"
                                    :root-unit="rootUnit"
                                    :contigent-unit="contigentUnit"
                                    :contigent-mode="contigentMode"
                                    :allowed-weekday="selected_days"
                            ></calendar-aside-availability>
                            <template v-if="contigentUnit === null && previewRoomStatus">
                                <hr style="margin-bottom: 2.15rem;">
                                <form-header :headerClass="'mt-4 mb-4'">{{$t('ROOM_STATUS')}}</form-header>
                                <calendar-aside-room-status class="mt-4" :unit="rootUnit" :date-ranges="date_ranges"
                                                            :default-values="defaultValues"
                                                            :allowed-weekday="selected_days"
                                ></calendar-aside-room-status>
                            </template>
                        </b-tab>
                        <template v-if="contigentUnit === null">
                            <b-tab :title="$t('RATES')" v-if="previewPrices" :key="2">
                                <calendar-aside-rates
                                        :unit="rootUnit"
                                        :los="losList"
                                        :date-ranges="date_ranges"
                                        :ratePlan="ratePlan"
                                        :occupancy="occupancyList"
                                        :default-values="defaultValues"
                                        :allowed-weekday="selected_days"
                                ></calendar-aside-rates>
                            </b-tab>
                            <b-tab :title="$t('RESTRICTIONS')" v-if="previewRestrictions" :key="3">

                                <calendar-aside-restrictions :unit="rootUnit" :date-ranges="date_ranges"
                                                             :default-values="defaultValues" :rate-plan="ratePlan"
                                                             :rate-plans="ratePlanList" :allowed-weekday="selected_days"></calendar-aside-restrictions>
                            </b-tab>
                        </template>
                    </b-tabs>
                </b-col>
            </b-row>
            <b-row class="mt-5" v-if="previewNewReservation && tab === 0">
                <b-col class="d-flex justify-content-center">
                    <app-button v-if="checkPermission(C_RESERVATION_ACCOMMODATION_NEW) " button_type="new" class="mr-3" variant="primary" @click="openNewReservation">{{
                        $t("NEW_RESERVATION") }}
                    </app-button>
                </b-col>
            </b-row>
        </template>

    </app-aside>
</template>

<script>
    import AppAside from "@/components/app/form/AppAside";
    import {
        EventBus,
        GE_CALENDAR_OPEN_AVAILABILITY,
        GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR,
        GE_CALENDAR_OPEN_AVAILABILITY_T,
        GE_CALENDAR_OPEN_PRICES,
        GE_CALENDAR_OPEN_RESTRICTIONS,
        GE_CALENDAR_OPEN_ROOM_STATUS,
        GE_LOADER_HIDE,
        GE_LOADER_SHOW,
        GE_OPEN_NEW_ACC_RES_ASIDE
    } from "@/shared/EventBus";
    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
    import moment from 'moment'
    import AppButton from "@/components/app/AppButton/AppButton";
    import CalendarAsideAvailability from "@/components/unit/calendar_opt/Aside/Calendar/CalendarAsideAvailability";
    import CalendarAsideRoomStatus from "@/components/unit/calendar_opt/Aside/Calendar/CalendarAsideRoomStatus";
    import CalendarAsideRates from "@/components/unit/calendar_opt/Aside/Calendar/CalendarAsideRates";
    import CalendarAsideRestrictions from "@/components/unit/calendar_opt/Aside/Calendar/CalendarAsideRestrictions";
    import {getUnitCalendarValues} from "@/services/unit";
    import {
        C_PROPERTY_CALENDAR_AVAILIBILITY_V,
        C_PROPERTY_CALENDAR_PRICES_V,
        C_PROPERTY_CALENDAR_RESTRICTIONS_V,
        C_PROPERTY_CALENDAR_RM_SUGGESTION_V,
        C_PROPERTY_CALENDAR_ROOM_STATUS_V,
        C_PROPERTY_CALENDAR_RESTRICTIONS_E,
        C_PROPERTY_CALENDAR_PRICES_E,
        C_PROPERTY_CALENDAR_AVAILIBILITY_E,
        C_PROPERTY_CALENDAR_ROOM_STATUS_E,
        C_RESERVATION_ACCOMMODATION_NEW
    } from "@/shared/component_permission";
    import FormHeader from "@/components/app/form/FormHeader";
    import PeriodItem from "@/components/app/Period/PeriodItem";
    import PeriodItemNew from "@/components/app/Period/PeriodItemNew";

    export default {
        name: "CalendarAside",
        components: {
            PeriodItemNew,
            PeriodItem,
            FormHeader,
            CalendarAsideRestrictions,
            CalendarAsideRates,
            CalendarAsideRoomStatus, CalendarAsideAvailability, AppButton, AppDateRangePicker, AppAside
        },
        props: {
            mode:{
                type:String,
                default:'property_calendar'
            },
            rootUnit: {
                type: Object
            },
            los: {
                type: Array
            },
            occupancy: {
                type: Array
            },
            elements: {
                type: Array
            },
            ratePlans: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        data() {
            return {
                ratePlanList:[],
                losList:[],
                occupancyList:[],
                ratePlan: null,
                contigentUnit: null,
                contigentMode: true,
                defaultValues: null,
                opened: false,
                tab: null,
                tabs: [
                    C_PROPERTY_CALENDAR_AVAILIBILITY_V,
                    C_PROPERTY_CALENDAR_PRICES_V,
                    C_PROPERTY_CALENDAR_RESTRICTIONS_V,
                    C_PROPERTY_CALENDAR_RESTRICTIONS_E
                ],
                current_date_range: {
                    start: null,
                    end: null
                },
                date_ranges: [],
                fields: [
                    {key: 'date', label: this.$t('SELECTED_PERIODS')},
                    {key: 'delete', label: "", class: 'text-right'},
                ],
                C_RESERVATION_ACCOMMODATION_NEW,
                selected_days:[],
                selected_days_all:true
            }
        },
        computed: {
            options() {
                let data = []
                for (const value of [1,2,3,4,5,6,0]) {
                    data.push({text: this.getWeekday(value), value: Number(value)})
                }

                return data
            },
            maxDate(){
                const currentYear = new Date().getFullYear()
                let maxDate =  (currentYear + 3) + '-12-31'
                return new Date(maxDate)
            },
            previewNewReservation(){

                if(this.defaultValues && this.defaultValues.hasOwnProperty('reservation')){
                    return this.defaultValues.reservation.allowed
                }
                return false


            },
                formHeader() {
                if (this.contigentUnit && this.contigentUnit.name) {
                    return this.contigentUnit.name
                }
                if (this.rootUnit && this.rootUnit.unit_type && this.rootUnit.unit_type.name) {
                    return this.rootUnit.unit_type.name
                }
                return ''
            },
            disabledDates() {
                return this.date_ranges.map(item => {
                    return {
                        start: moment(item.start).toDate(),
                        end: moment(item.end).toDate(),
                    }
                })
            },
            fromDate() {

                if (this.date_ranges.length > 0) {
                    let lastItem = this.date_ranges[this.date_ranges.length - 1]
                    if (lastItem.end) {
                        return new moment(lastItem.end).add(1, 'day').toDate()
                    }
                }

                return undefined
            },
            previewAvailability() {
                return this.elements.includes(C_PROPERTY_CALENDAR_AVAILIBILITY_V) && this.checkPermission(C_PROPERTY_CALENDAR_AVAILIBILITY_E)
            },
            previewRoomStatus() {
                return this.elements.includes(C_PROPERTY_CALENDAR_ROOM_STATUS_V) && this.checkPermission(C_PROPERTY_CALENDAR_ROOM_STATUS_E)
            },
            previewPrices() {
                return this.elements.includes(C_PROPERTY_CALENDAR_PRICES_V) && this.checkPermission(C_PROPERTY_CALENDAR_PRICES_E)
            },
            previewRestrictions() {
                return this.elements.includes(C_PROPERTY_CALENDAR_RESTRICTIONS_V) && this.checkPermission(C_PROPERTY_CALENDAR_RESTRICTIONS_E)
            },
            previewSuggestions() {
                return this.elements.includes(C_PROPERTY_CALENDAR_RM_SUGGESTION_V)
            },
            shownElements() {
                return this.tabs.filter(tab => this.elements.includes(tab))
            },
            CalValuesReqData() {
                return this.elements.map(el => {
                    if (el === C_PROPERTY_CALENDAR_AVAILIBILITY_V) {
                        return 'availability'
                    }
                    if (el === C_PROPERTY_CALENDAR_PRICES_V) {
                        return 'rates'
                    }
                    if (el === C_PROPERTY_CALENDAR_RESTRICTIONS_V) {
                        return 'restrictions'
                    }
                })
            }
        },
        methods: {
            addPeriod(period) {
                this.date_ranges.push({
                    start: period.start,
                    end: period.end,
                })
            },
            getWeekday(number) {
                switch (Number(number)) {
                    case 1:
                        return this.$t('DATE.DAYS.MONDAY')
                    case 2:
                        // code block
                        return this.$t('DATE.DAYS.TUESDAY')
                    case 3:
                        return this.$t('DATE.DAYS.WEDNESDAY')
                    case 4:
                        return this.$t('DATE.DAYS.THURSDAY')
                    case 5:
                        return this.$t('DATE.DAYS.FRIDAY')
                    case 6:
                        return this.$t('DATE.DAYS.SATURDAY')
                    case 0:
                        return this.$t('DATE.DAYS.SUNDAY')
                }
            },
            openNewReservation(){
                let reqNewReservation = {
                    unit_id: this.contigentMode ? null : this.contigentUnit ? this.contigentUnit.id : this.rootUnit.id,
                    property:this.$route.params.id ? this.$route.params.id : this.rootUnit.hasOwnProperty('property_id') ? this.rootUnit.property_id : null,
                    dateRange:this.date_ranges[0]
                }
                EventBus.$emit(GE_OPEN_NEW_ACC_RES_ASIDE,reqNewReservation)
                this.opened = false
            },
            updateRange(val) {
                this.date_ranges.push(val)
                this.current_date_range = {
                    start: null,
                    end: null
                }
            },
            setTabIndex(tabValue) {

              this.tab = this.shownElements.findIndex(el => el === tabValue)

            }
        },
        watch: {
            date_ranges: {
                handler(value) {
                    if (value.length) {
                        this.CalValuesReqData.push('additional_data')
                        this.CalValuesReqData.push('room_status')
                        if(this.mode === 'main_calendar'){
                            this.CalValuesReqData.push('rate_plans')
                        }
                        EventBus.$emit(GE_LOADER_SHOW)
                        getUnitCalendarValues(
                            this.contigentUnit ? this.contigentUnit.id : this.rootUnit.id,
                            {
                                periods: value,
                                contigent: this.contigentMode,
                                rate_plan: this.ratePlan,
                                show: this.CalValuesReqData
                            })
                            .then(response => {
                                if(this.mode=== 'main_calendar'){
                                    this.ratePlanList = response.data.rate_plan_list
                                    this.losList = response.data.los
                                    this.occupancyList = response.data.occupancy
                                } else {
                                    this.ratePlanList = this.ratePlans
                                    this.losList = this.los
                                    this.occupancyList = this.occupancy
                                }
                                this.defaultValues = response.data
                            }).finally(() => {
                            EventBus.$emit(GE_LOADER_HIDE)
                        })
                    }
                },
                immediate: true,
                deep: true
            },
            selected_days_all:{
                handler(value){
                    if(value){
                        this.selected_days = [1,2,3,4,5,6,0]
                    } else {
                        this.selected_days = []
                    }
                },immediate:true
            }
        },
        created() {
            if (this.checkPermission(C_PROPERTY_CALENDAR_ROOM_STATUS_E)) {
                EventBus.$on(GE_CALENDAR_OPEN_ROOM_STATUS, (data) => {
                    if (!data && typeof  data !== 'object' && !data.hasOwnProperty('unitId') && !this.rootUnit && !this.rootUnit.hasOwnProperty('id')) {
                        return false
                    }
                if (data.unitId === this.rootUnit.id) {
                    if (data.hasOwnProperty('dateRange')) {
                        this.date_ranges = []
                        this.date_ranges.push(data.dateRange)
                    }
                    this.contigentMode = true
                    this.contigentUnit = null
                    this.setTabIndex(C_PROPERTY_CALENDAR_ROOM_STATUS_V)
                        this.ratePlan = this.rootUnit.primary_rate_plan.id
                    this.opened = true
                }
                })
            }


            if (this.checkPermission(C_PROPERTY_CALENDAR_AVAILIBILITY_E)) {
                EventBus.$on(GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR, (data) => {
                    if (!data && typeof  data !== 'object' && !data.hasOwnProperty('unitId') && !this.rootUnit && !this.rootUnit.hasOwnProperty('id')) {
                        return false
                    }
                    if (data.unitId === this.rootUnit.id) {
                        if (data.hasOwnProperty('contigentUnit')) {
                            this.contigentUnit = data.contigentUnit
                        } else {
                            this.contigentUnit = null
                        }

                        if (data.hasOwnProperty('contigentMode')) {
                            this.contigentMode = data.contigentMode
                        } else {
                            this.contigentMode = true
                        }

                        this.setTabIndex(C_PROPERTY_CALENDAR_AVAILIBILITY_V)

                        this.date_ranges = []
                        if (data.hasOwnProperty('dateRange')) {
                            this.date_ranges = []
                            this.date_ranges.push(data.dateRange)
                        }

                        if (this.rootUnit.hasOwnProperty("primary_rate_plan")) {
                            this.ratePlan = this.rootUnit.primary_rate_plan.id
                        }

                        this.opened = true
                    }

                })
            }

            if (this.checkPermission(C_PROPERTY_CALENDAR_PRICES_E)) {
                EventBus.$on(GE_CALENDAR_OPEN_PRICES, (data) => {
                    if (!data && typeof  data !== 'object' && !data.hasOwnProperty('unitId') && !this.rootUnit && !this.rootUnit.hasOwnProperty('id')) {
                        return false
                    }
                    if (data.unitId === this.rootUnit.id) {
                        if (data.hasOwnProperty('dateRange')) {
                            this.date_ranges = []
                            this.date_ranges.push(data.dateRange)
                        }

                        this.contigentUnit = null
                            this.ratePlan = this.rootUnit.primary_rate_plan.id
                        this.setTabIndex(C_PROPERTY_CALENDAR_PRICES_V)
                        if (data.hasOwnProperty('contigentMode')) {
                            this.contigentMode = data.contigentMode
                        } else {
                            this.contigentMode = true
                        }
                        this.opened = true
                    }

                })
            }


            if (this.checkPermission(C_PROPERTY_CALENDAR_RESTRICTIONS_E)) {
                this.$store.dispatch(`shared/actionSetRestrictions`)
                EventBus.$on(GE_CALENDAR_OPEN_RESTRICTIONS, (data) => {
                    if (!data && typeof data !== 'object' && !data.hasOwnProperty('unitId') && !this.rootUnit && !this.rootUnit.hasOwnProperty('id')) {
                        return false
                    }
                    if (data.unitId === this.rootUnit.id) {
                        if (data.hasOwnProperty('dateRange')) {
                            this.date_ranges = []
                            this.date_ranges.push(data.dateRange)
                        }

                        if (data.hasOwnProperty('ratePlan')) {
                            this.ratePlan = data.ratePlan
                        } else {
                                this.ratePlan = this.rootUnit.primary_rate_plan.id
                            }
                        this.setTabIndex(C_PROPERTY_CALENDAR_RESTRICTIONS_V)
                        if (data.hasOwnProperty('contigentMode')) {
                            this.contigentMode = data.contigentMode
                        } else {
                            this.contigentMode = true
                        }
                        this.contigentUnit = null
                        this.opened = true
                    }

                })
            }


        },
        beforeDestroy(){
            EventBus.$off(GE_CALENDAR_OPEN_AVAILABILITY)
            EventBus.$off(GE_CALENDAR_OPEN_PRICES)
        }

    }
</script>

<style scoped>

</style>
