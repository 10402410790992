<template>
    <div class="availability_content_grid"
         :style="`grid-template-columns: repeat(${dates.length}, 2.4rem)`">

        <property-calendar-item-contigent-cell-test
            v-if="contigent && cellData.showCell"
            v-for="(cellData, index) in cellObjectList"
            :key="`${index}_${_uid}`"
            @startEndDrag="dragStartEnd(cellData.date)"
            @drag="multiComponentDrag(cellData.date)"
            @currentDrag="multiComponentDragCurrent(cellData.date)"
            :highlighted="isHighlighted(cellData.date)"
            :cell-data="cellData">
        </property-calendar-item-contigent-cell-test>

        <property-calendar-item-cell-test
            v-if="!contigent && cellData.showCell"
            v-for="(cellData, index) in cellObjectList"
            :key="`${index}_${_uid+1}`"
            :highlighted="isHighlighted(cellData.date)"
            @showReservation="showReservation(cellData)"
            @startEndDrag="dragStartEnd(cellData.date)"
            @drag="multiComponentDrag(cellData.date)"
            @currentDrag="multiComponentDragCurrent(cellData.date)"
            @mouseEnterNote="mouseEnterNote(cellData)"
            @mouseLeaveNote="mouseLeaveNote(cellData)"
            @mouseEnter="mouseEnter(cellData)"
            @mouseLeave="mouseLeave(cellData)"
            :noteHighLight="isNoteHighlighted(cellData)"
            :isFirstDayOfFirstMonth="isFirstDayOfFirstMonth(cellData)"
            :isCheckIn="isCheckIn(cellData)"
            :cell-data="cellData"
            :show_channel_data="show_channel_data_value">
        </property-calendar-item-cell-test>
    </div>
</template>

<script>
import {AvailabilityScrollMixin} from "@/mixins/calendar/AvailabilityScrollMixin";
import {
    EventBus,
    GE_CALENDAR_LOAD_AVAILABILITY,
    GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY,
    GE_CALENDAR_OPEN_RESERVATIONS,
} from "@/shared/EventBus";
import {
    C_RESERVATION_ACCOMMODATION_BOOKING_SITE
} from '@/shared/component_permission';
import moment from 'moment'
import {DISTRIBUTION_PHOTO} from "@/services/endpoints";
import {MIN_STAY, SALES_ENABLED} from "@/shared/constants";
import i18n from "@/locale";
import {fetchNote} from "@/services/unit";
import {fetchAccessControlData} from "@/services/access";
import {AC_FEATURE_SETUP} from "@/mixins/AccessControl/AccessControlEnumeration";
import PropertyCalendarItemContigentCellTest
    from "@/components/property/calendar/PropertyCalendarItemContigentCellTest";
import PropertyCalendarItemCellTest from "@/components/property/calendar/PropertyCalendarItemCellTest";
import {MULTI_UNIT_VIEW} from "@/shared/constants";

export default {
    name: "PropertyCalendarContentTest",
    mixins: [AvailabilityScrollMixin],
    components: {
        PropertyCalendarItemCellTest,
        PropertyCalendarItemContigentCellTest,
    },
    props: {
        unitData: {
            type: Object
        },
        reservations: {
            type: Object
        },
        dates: {
            type: Array
        },
        contigent: {
            type: Boolean
        },
        dateRange: {
            type: Object
        },
        isRoot: {
            type: Boolean
        },
        parentUnit: {
            type: Object
        },
        property: {
            type: Object
        },
        display: {
            type:Number,
            default:null
        },
        show_channel_data:{
            type:Boolean,
            default:null
        },
        display_work_mode:{
            type: Number,
            required: true
        },
        isFirstMonth: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
            show_channel_data_response_value:false,
            cellObjectList: [],
            MULTI_UNIT_VIEW
        }
    },
    computed: {
        show_channel_data_value() {
            if (this.show_channel_data === null) {
                return this.show_channel_data_response_value
            }
            return this.show_channel_data
        },
        isContigent() {
            return !this.isRoot
        },
        reservationList() {
            return this.reservations && typeof this.reservations === 'object' ?  Object.values(this.reservations) : []
        },
        count_contingent_units(){
            if(this.parentUnit.hasOwnProperty('contigent') && this.parentUnit.contigent){
                return Object.keys(this.parentUnit.contigent).length + 1
            }
            return null
        }
    },
    methods: {
        isFirstDayOfFirstMonth(cellData) {
            return (this.isFirstMonth && cellData.date.format('D') === '1')
        },
        isCheckIn(cellData) {
            if (this.display_work_mode === MULTI_UNIT_VIEW) {
                return true
            }
            if (cellData.reservation) {
                return cellData.reservation.check_in_date === cellData.date.format('YYYY-MM-DD')
            }
            return false
        },
        mouseEnter(cellData){
            cellData.showResPopup.enter = true
            setTimeout(()=>{
                cellData.showResPopup.show = cellData.showResPopup.enter

            },500)
        },
        mouseLeave(cellData){
            cellData.showResPopup.enter = false
            cellData.showResPopup.show = false
        },
        showReservation(cellData){
            cellData.showResPopup.enter = false
            cellData.showResPopup.show = false
            if (this.shiftKeyValue) {
                return
            }
            EventBus.$emit(GE_CALENDAR_OPEN_RESERVATIONS, cellData.reservation)
        },
        getTranslations(){
            return {
                RESERVATION_CODE: this.$t('RESERVATION_CODE'),
                SALES_CHANNEL:this.$t('SALES_CHANNEL'),
                GUEST:this.$t('GUEST'),
                ADULTS:this.$t('ADULTS'),
                CHILDREN:this.$t('CHILDREN'),
                BABIES:this.$t('BABIES'),
                TOTAL:this.$t('TOTAL'),
                ARRIVAL_TIME:this.$t('ARRIVAL_TIME'),
                BOOKED_AT:this.$t('BOOKED_AT'),
                UPDATED_AT:this.$t('UPDATED_AT'),
                NOTE:this.$t('NOTE'),
                CLOSED_AT:this.$t('CLOSED_AT'),
                USER:this.$t('USER'),
                TAG:this.$t('TAG')
            }
        },
        mouseEnterNote(cellData){
            cellData.showNotePopup.enter = true
            setTimeout(()=>{
                if(cellData.showNotePopup.enter && cellData.note_highlight.key && !this.shiftKeyValue){
                    this.cellObjectList.forEach(el =>{
                        if(el.note_highlight.key === cellData.note_highlight.key){
                            el.note_highlight.value = true
                        } else {
                            el.note_highlight.value = false
                        }
                    })
                    if(cellData.note && cellData.note.hasOwnProperty('period_start') && cellData.note.hasOwnProperty('period_end')){
                        fetchNote(this.unit.id,{start:cellData.note.period_start,end:cellData.note.period_end}).then(response=>{
                            cellData.note.us = response.data.created_by
                            if(response.data){
                                cellData.note = this.formatNote(response.data)
                                cellData.showNotePopup.show = cellData.showNotePopup.enter && cellData.note_highlight.key !== null
                            }
                        })
                    }
                }
            },500)
        },
        mouseLeaveNote(cellData){
            cellData.showNotePopup.enter = false
            cellData.showNotePopup.show = false

            this.cellObjectList.forEach(el =>{
                el.note_highlight.value = false
            })
        },
        isNoteHighlighted(data){
            const object =  {key:null, value:null}
            if(data.note && this.cellObjectList.length > 0){
                this.cellObjectList.forEach(el => {

                    if(el.note === data.note ) {
                        object.key = data.note.period_start + '_' + data.note.period_end + '_' + this.unit.id
                        object.value = data.showNotePopup.show
                    }
                })
            }
            return object
        },
        openAvailabilityAside() {
            if (this.dragPeriod.start > this.dragPeriod.end) {
                this.dragPeriod = {
                    start: this.dragPeriod.end,
                    end: this.dragPeriod.start
                }
            }

            let eventData = {
               unitId: this.isContigent ? this.parentUnit.id : this.unit.id,
                rootUnit: this.isContigent ? this.parentUnit : this.unit,
                contigentMode: this.contigent,
                contigentUnit: this.isContigent ? this.unit : null,
                property: this.property,
                tab: 0,
                dateRange: {
                    start: this.dragPeriod.start.format("YYYY-MM-DD"),
                    end: this.dragPeriod.end.format("YYYY-MM-DD")
                }
            }
            EventBus.$emit(GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY, eventData)
            this.dragPeriod.start = null
            this.dragPeriod.end = null
            this.currentDragDate = null
        },
        getCellObjectList(dates) {
            return dates.map((date, cellIndex) => {
                const dayBefore = moment(date).subtract(1, 'days')
                const dayAfter = moment(date).add(1, 'days')
                const dateF = date.format('YYYY-MM-DD')
                const reservationDataObject = this.contigent ? {} : this.getCellObjectReservationData(date, dayBefore, dayAfter, cellIndex)
                const colspan = this.contigent ? 1 : this.getCellObjectColumnSpan(reservationDataObject, date, cellIndex)
                const showCell = this.contigent ? !!this.unitData[dateF] : (colspan > 0 && this.unitData[dateF])
                const note = !this.contigent && typeof this.unitData[dateF] === 'object'  && !this.unitData[dateF].availability.available && this.unitData[dateF].availability.hasOwnProperty('note') ? this.unitData[dateF].availability.note : null
                const column = cellIndex + 1
                const hasReservationOnDayBefore =reservationDataObject ? reservationDataObject.hasReservationOnDayBefore : 0
                return {
                    date,
                    showCell,
                    colspan,
                    column,
                    popup_title: reservationDataObject.reservation ? reservationDataObject.reservation .check_in_date+ ' - ' + reservationDataObject.reservation .check_out_date : null,
                    reservation: reservationDataObject.reservation,
                    lastDayOfMonthClass: {'availability_calendar-br_green': date.date() === date.daysInMonth()},
                    hasReservationOnDayBefore,
                    hasReservationOnDayAfter: reservationDataObject ? reservationDataObject.hasReservationOnDayAfter : 0,
                    unit_data: this.unitData[dateF] ? this.unitData[dateF] : null,
                    room_status: this.unitData[dateF] ? typeof this.unitData[dateF].restrictions === 'object' && Object.keys(this.unitData[dateF].restrictions).length > 0 ? this.getRoomStatus(this.unitData[dateF].restrictions) : null : null,
                    min_stay: this.unitData[dateF] ? typeof this.unitData[dateF].restrictions === 'object' && Object.keys(this.unitData[dateF].restrictions).length > 0 ? this.getMinStay(this.unitData[dateF].restrictions) : null : null,
                    isRoot:this.isRoot,
                    note:this.formatNote(note),
                    note_highlight: this.unitData[dateF] ? this.setNoteKey(this.unitData[dateF].availability, note) : null,
                    showResPopup:{show:false,enter:false},
                    showNotePopup:{show:false,enter:false},
                    widthGuest: this.widthGuest(hasReservationOnDayBefore,reservationDataObject.reservation, colspan,column),
                    translations: this.getTranslations(),
                    display:this.display
                }
            })
        },
        widthGuest(hasReservationOnDayBefore,reservation, colspan, column) {
            let width = 0
            if (reservation &&  reservation.num_nights === 1) {
                width = (colspan * 2.4) + 0.2
                return width
            } else if (column === 1 && reservation) {
                width = (colspan * 2.4) + 0.2
                return width
            } else {
                width = (colspan * 2.4) + 0.2
                return width
            }
        },
        formatNote(note){
            if(note){
                note.timestamp_formatted = note.hasOwnProperty('timestamp') ? this.formatTimeStamp(note.timestamp) : null
                note.period_start_foramtted =this.formatDate(note.period_start)
                note.period_end_foramtted =this.formatDate(note.period_end)
            }

            return note
        },
        setNoteKey(av, note){
            return note && !av.available ? {key:this.unit.id + '_' + note.period_start  + '_' +note.period_end,value:false} : {key:null, value:false}
        },
        getRoomStatus(restriction){
            return typeof restriction === 'object' && Object.keys(restriction).length > 0 && restriction[SALES_ENABLED] ? restriction[SALES_ENABLED].value: null
        },
        getMinStay(restriction){
            return typeof restriction === 'object' && Object.keys(restriction).length > 0 && restriction[MIN_STAY] ? restriction[MIN_STAY].value: null
        },
        formatTime(value){
            let format = i18n.t('DATE.HOUR_MIN_FORMAT')

            if (format === null) {
                format = "HH:mm"
            }

            if (value) {
                if (typeof value === 'number') {
                    return moment.unix(value).format(format)
                } else if (typeof value === 'string') {
                    if ((new Date(value)).getTime() > 0) {
                        return moment(value).format(format)
                    } else {
                        return moment(value, "HH:mm").format(format)
                    }
                }
            } else {
                return value
            }
        },
        formatDate(value){
            const  year = true
            if (!value) return

            let format = (year) ? i18n.t('DATE.DATE_FORMAT') : i18n.t('DATE.DATE_FORMAT_SANS_YEAR')

            if (format === null) {
                format = "YYYY-MM-DD"
            }

            if(typeof value === 'number'){
                return moment.unix(value).format(format)
            }

            return moment(String(value)).format(format)
        },
        formatTimeStamp(value){
            let format = i18n.t('DATE.TIMESTAMP_FORMAT')
            if (format === null) {
                format = "YYYY-MM-DD HH:mm"
            }
            return moment(String(value)).format(format)
        },
        getCellObjectReservationData(date, dayBefore, dayAfter, cellIndex) {
            const reservationDataObject = {
                reservation: null,
                hasReservationOnDayBefore: null,
                hasReservationOnDayAfter: null,
                hasReservationOnDay: null,
                hasOneNightReservation:null
            }

            let reservation = null

            if (cellIndex === 0) {
                reservation = this.reservationList.find(r => date >= moment(r.check_in_date) && date < moment(r.check_out_date))
            } else {
                reservation = this.reservationList.find(r => date.isSame(moment(r.check_in_date), 'day'))
            }

            if (reservation) {
                if (reservation.distribution) {
                    reservation.channel_icon = DISTRIBUTION_PHOTO(reservation.distribution)
                } else {
                    reservation.channel_icon = DISTRIBUTION_PHOTO(0)
                }
                reservation.formatted_check_in_time = this.formatTime(reservation.check_in_time)
                reservation.formatted_check_in_date = this.formatDate(reservation.check_in_date)
                reservation.formatted_check_out_date = this.formatDate(reservation.check_out_date)
                reservation.formatted_created_date = this.formatDate(reservation.created_date) + ' ' + this.formatTime(reservation.created_date)

                reservation.formatted_name = `${(reservation.first_name ? reservation.first_name.substr(0, 1) + '. ' : '')}${reservation.last_name}`
                reservation.formated_name_count = reservation.formatted_name.length
                reservation.tags = reservation.tag ? reservation.tag.map(value => value.label).join(', ') : ""
            }

            reservationDataObject.reservation = reservation
            reservationDataObject.hasReservationOnDayBefore = !!this.reservationList.find(r => (dayBefore >= moment(r.check_in_date) && dayBefore < moment(r.check_out_date)))
            reservationDataObject.hasReservationOnDayAfter = !!this.reservationList.find(r => (dayAfter >= moment(r.check_in_date) && dayAfter < moment(r.check_out_date)))
            reservationDataObject.hasReservationOnDay = !!this.reservationList.find(r => (date >= moment(r.check_in_date) && date < moment(r.check_out_date)))

            return reservationDataObject
        },
        getCellObjectColumnSpan({reservation, hasReservationOnDay}, date, cellIndex) {
            if (reservation && reservation.hasOwnProperty('num_nights')) {
                const checkInDate = moment(reservation.check_in_date)
                const checkOutDate = moment(reservation.check_out_date)
                const numberOfCells = this.dates.length + 1
                if (cellIndex === 0 && date > checkInDate) {
                    if (reservation.num_nights > numberOfCells && checkOutDate > this.dates[this.dates.length - 1]) {
                        return numberOfCells - (cellIndex + 1)
                    } else {
                        return reservation.num_nights - date.diff(checkInDate, 'days')
                    }
                }

                if (((cellIndex + 1) + reservation.num_nights) >= numberOfCells) {
                    return numberOfCells - (cellIndex + 1)
                }

                return reservation.num_nights
            }

            if (hasReservationOnDay && !reservation) {
                return 0
            }

            return 1
        },
        getGuestClasses(avBefore,{reservation}, cellIndex, colspan){
            let  hasReservationOnDayAfterRes = false
            if(reservation){
                const  dayAfterRes = moment(reservation.check_out_date).add(1,'days')
                hasReservationOnDayAfterRes = reservation ? !!this.reservationList.find(r => (dayAfterRes >= moment(r.check_in_date) && dayAfterRes < moment(r.check_out_date))) :false
            }
            let column = cellIndex + 1
            if(column > 1 && colspan ===1){
                return 'availability_calendar-guest_res-one-day'
            }
            if(reservation && hasReservationOnDayAfterRes){
                return 'availability_calendar-guest--next-res'
            }
            return 'availability_calendar-guest--next-availability'
        },
        getReservationClasses(avBefore,{hasReservationOnDayBefore,hasReservationOnDayAfter}){
            return !this.contigent && avBefore ? {
                '':hasReservationOnDayBefore,
                'availability_calendar--status-start-item_available-before-res': avBefore.available && !hasReservationOnDayBefore,
                'availability_calendar--status-start-item_not_available-before-res': !avBefore.available && !hasReservationOnDayBefore,
                'availability_calendar_reservation':!hasReservationOnDayAfter

            } : {

            }
        },
    },
    watch: {
        shiftKeyValue(val) {
            if (val === false) {
                this.dragPeriod.start = null
                this.dragPeriod.end = null
                this.currentDragDate = null
            }
        },
        property: {
            deep: true,
            immediate: true,
            handler() {
                this.cellObjectList = this.getCellObjectList(this.dates)
            }
        }
    },
    mounted() {
        EventBus.$on(GE_CALENDAR_LOAD_AVAILABILITY, (data) => {
            if (data.hasOwnProperty('unitId') && (
                (this.unit.hasOwnProperty('id') && this.unit.id === data.unitId) ||
                (this.unit.hasOwnProperty('contigent') && this.unit.contigent && this.unit.contigent.hasOwnProperty('parent') && this.unit.contigent.parent === data.unitId)
            )) {
                if (data.hasOwnProperty('all') && data.all === true) {
                    this.getData(false)
                } else if (data.hasOwnProperty('contigentUnitId') && this.unit.hasOwnProperty('id') && data.contigentUnitId === this.unit.id) {
                    this.getData(false)
                }
            }
        })

        if (this.show_channel_data === null) {
            let request = {
                context:{
                    company: this.$store.getters['user/getCompany']
                },
                data:[]
            }
            request.data.push({
                uid: C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
                function: C_RESERVATION_ACCOMMODATION_BOOKING_SITE
            })
            fetchAccessControlData(AC_FEATURE_SETUP, request).then(response => {
                this.show_channel_data_response_value = response.data[C_RESERVATION_ACCOMMODATION_BOOKING_SITE].visible
            })
        }
    },
}
</script>

<style scoped>

</style>
