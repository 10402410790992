<template>
    <form @submit.prevent="search"  class="filter">
        <b-row>
            <b-col md="2" class="mb-3">
                <label>{{$t("NAME")}}</label>
               <b-input v-model="filter.name"></b-input>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{ $t("TYPE") }}</label>
                <app-select :options="unit_type_options" mode="multiselect" value-field="id" text-field="label" v-model="filter.unit_type">
                </app-select>

            </b-col>
            <template v-if="cities_count > 1">
                <b-col md="2" class="mb-3" v-if="preloadCityList">
                    <label>{{$t("CITY")}}</label>
                    <app-select mode="multiselect" :options="cityList"  v-model="filter.city" ></app-select>
                </b-col>
                <b-col v-else lg="2" class="mb-3" >
                    <label>{{$t("CITY")}}</label>
                    <app-select mode="search" :search="searchCity" v-model="filter.city" :search-clear="true"></app-select>
                </b-col>
            </template>


            <b-col md="2" class="mb-3" >
                <label>{{$t("GROUP")}}</label>

                <app-select mode="search" text-field="title" value-field="id" :search="searchFilter" v-model="filter.filterObject">

                </app-select>
            </b-col>
            <b-col lg="2" class="mb-3" >
                <label>{{$t("ASSIGNED_TO_GROUP")}}</label>

                <app-select :options="dialog_options" text-field="name" value-field="id" v-model="filter.in_group">

                </app-select>
            </b-col>
            <b-col xl="2" lg="3" class="d-flex align-items-baseline">
                <app-button-submit class="filter-submit-wrapper" :inline="true" :disabled="loading"
                                   :loading="loaderSubmit" variant="primary"
                                   button_type="search">
                    {{ $t("SEARCH") }}
                </app-button-submit>
                <app-button-reset @click="resetForm" :disabled="loading" :loading="loaderReset" :inline="true"
                                  class="pl-2 ml-2 filter-submit-wrapper"></app-button-reset>
            </b-col>

        </b-row>
    </form>
</template>

<script>
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {DEFAULT_PAGINATION_LIMIT, LS_PRESETS,} from "@/shared/constants";
import {getPropertyList} from "@/services/property";
import {getCityList} from "@/services/location";
import {pickBy} from "lodash";
import {getRmFilterUnit} from "@/services/revenue_manager";

export default {
    name: "RevenueManagerSimilarUnitFilter",
    components: {AppSelect, AppButtonReset, AppButtonSubmit},
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return{
            DEFAULT_PAGINATION_LIMIT,
            loaderReset:false,
            loaderSubmit:false,
            filter:{
                property:null,
                city:null,
                name:null,
                unit_type:[],
                in_group:null,
                filterObject:null
            },
            property_count:0,
            cities_count:0,
            propertyList:[],
            preloadPropertyList:false,
            preloadCityList:false,
            cityList:[],
            reset:0,
            unit_type_options:[
                {id:1,label:"apartments"},{id:14,label:"bed in dormitory"},{id:16,label:"boat"},{id:12,label:"bungalow"},{id:22,label:"caravan"},{id:18,label:"chalet"},{id:17,label:"cottage"},{id:4,label:"country house"},{id:15,label:"dormitory room"},{id:2,label:"double room"},{id:20,label:"empty spot"},{id:10,label:"family"},{id:21,label:"glamp"},{id:13,label:"holiday home"},{id:23,label:"mobile home"},{id:8,label:"quadruple room"},{id:6,label:"single room"},{id:5,label:"studio"},{id:11,label:"suite"},{id:19,label:"tent"},{id:7,label:"triple room"},{id:9,label:"twin room"},{id:3,label:"villa"}
            ],
            dialog_options:[
                {id:1,name:this.$t('YES')},
                {id:0,name:this.$t('NO')},
            ],

        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods: {
        searchFilter(value){
            const req = {
                title:value,
                page: 1,
                perPage: 100,
                company: this.company.id
            }
            return getRmFilterUnit(req).then(response => ({data: response.data.items}))
        },
        searchProperty(value){
            const request = {name:value, company_id:this.company.id}
            return getPropertyList(request).then(response => {
                return {data:response.data.items}
            })
        },
        searchCity(value){
            return getCityList({
                name: value,
            })
        },
        getCities(){
            getCityList({
                user_cities: true,
            }).then(response=>{
                this.cityList = response.data
            })
        },
        search() {
            this.reset =0
            let request = {
                property:null,
                city:null,
                name:this.filter.name,
                type:null,
                rm_unit_filter:null,
                filter_id:null
            }
            if(this.filter.hasOwnProperty('property')){
                request.property = this.filter.property !== null && typeof this.filter.property === 'object' ? this.filter.property.id : this.filter.property
            }
            if( this.preloadCityList){
                request.city = this.filter.citylo
            } else {
                request.city = this.filter.city !== null && typeof this.filter.city === 'object' ? this.filter.city.id : this.filter.city
            }
            if(this.filter.hasOwnProperty('unit_type')){
                request.type = this.filter.unit_type
            }
            if(this.filter.in_group !== null){
                request.rm_filter = this.filter.in_group
            }
            if(this.filter.filterObject && this.filter.filterObject.hasOwnProperty('id')){
                request.filter_id = this.filter.filterObject.id
            }
            request = pickBy(request, item => item !== null)
            this.$emit('searchForm',request )
        },
        resetForm() {
            this.reset = 1
            this.filter.city = null
            this.filter.property = null
            this.filter.name = null
            this.filter.filterObject = null
            this.filter.in_group = null
            this.filter.unit_type = []
            this.$emit('searchForm', this.filter)
        }

    },
    watch:{
        loading: {
            handler(value) {
                if (this.reset) {
                    this.loaderReset = value
                } else {
                    this.loaderSubmit = value
                }
            }
        }
    },
    created() {
        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)
            this.property_count = storagePresets.hasOwnProperty('properties') ? storagePresets.properties : null

            if(this.property_count <= DEFAULT_PAGINATION_LIMIT){
                this.filter.property = null
                this.preloadPropertyList = true

                getPropertyList({ company_id:this.company.id}).then(response => {
                    this.$nextTick(()=>{
                        const data = response.data &&  response.data.hasOwnProperty('items') ? response.data.items : []
                        this.propertyList = data.map(el=>{
                            return {id:el.id,name:el.name}
                        })
                    })

                })
            }
            if (storagePresets.hasOwnProperty('cities')) {
                this.cities_count = storagePresets.cities
                if (storagePresets.cities <= DEFAULT_PAGINATION_LIMIT) {
                    this.getCities()
                    this.preloadCityList = true
                }
            }

        }
    }

}
</script>

<style scoped>

</style>
