import http from '../http'
import {BOOKING_ENGINE} from "@/services/endpoints";

export function getBEStatus(params) {
    return http.request({
        url: BOOKING_ENGINE + '/activation-status',
        method: 'GET',
        params
    })
}

export function toggleBEStatus(data) {
    return http.request({
        url: BOOKING_ENGINE + '/activation-toggle',
        method: 'POST',
        data
    })
}

export function toggleUnitStatus(id, data) {
    return http.request({
        url: BOOKING_ENGINE + '/' + id + '/activate',
        method: 'POST',
        data
    })
}

export function getBESetupParameters(data) {
    return http.request({
        url: BOOKING_ENGINE + '/setup-parameters',
        method: 'POST',
        data
    })
}

export function updateBESetupParameters(data) {
    return http.request({
        url: BOOKING_ENGINE + '/setup-parameters',
        method: 'PUT',
        data
    })
}
