<template>
    <div>
        <b-table v-if="checkPermission(C_PROPERTY_ICAL_V) && units.length"
                 hover
                 responsive
                 :items="units"
                 :fields="fields">
            <template v-slot:action_buttons="data">
                <app-button variant="link"
                            button_type="copy"
                            :show_text="false"
                            @click="copyToClipboard(data.item.ical)">
                </app-button>
            </template>
        </b-table>
        <app-no-data v-if="units.length === 0 && !loader" :show-tip="false">
        </app-no-data>
    </div>

</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import {getUnitsICal} from "@/services/property";
    import {toast} from "@/shared/plugins/toastr";
    import {C_PROPERTY_ICAL_V} from "@/shared/component_permission";
    import AppNoData from "@/components/app/AppNoData";

    export default {
        name: "ICalUnitTable",
        components: {
            AppNoData,
            AppButton
        },
        data() {
            return {
                units: [],
                fields: [
                    {key: 'id', label: this.$t('ID')},
                    {key: 'name', label: this.$t('NAME')},
                    {key: 'ical', label: this.$t('ICAL')},
                    {key: 'action_buttons', label: '', class: 'text-right'}
                ],
                loader:false,
                C_PROPERTY_ICAL_V
            }
        },
        watch: {
            '$route.params.id' : {
                handler(value) {
                    this.getTableData(value)
                },
                immediate: true
            }
        },
        methods: {
            getTableData(property_id) {
                this.loader = true
                getUnitsICal(property_id).then(res => {
                    this.units = res.data
                }).finally(()=> {
                    this.loader = false
                })
            },
            copyToClipboard(iCal) {
                if (window.clipboardData && window.clipboardData.setData) {
                    return this.clipboardData.setData("Text", iCal)
                }
                else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                    let input = document.createElement("input")
                    document.body.appendChild(input)
                    input.visibility = 'hidden'
                    input.value = iCal
                    input.style.position = "fixed"
                    input.focus()
                    input.select()
                    try {
                        document.execCommand("copy")  // Security exception may be thrown by some browsers.
                        toast({
                            //title: this.$t('NOTIFICATIONS.COPY_TO_CLIPBOARD_SUCCESS.TITLE'),
                            message: this.$t('NOTIFICATIONS.COPY_TO_CLIPBOARD_SUCCESS.MESSAGE'),
                            type: 'success',
                            timeout: 3000
                        })
                        return true
                    }
                    catch (ex) {
                        console.warn("Copy to clipboard failed.", ex)
                        toast({
                            //title: this.$t('NOTIFICATIONS.COPY_TO_CLIPBOARD_FAIL.TITLE'),
                            message: this.$t('NOTIFICATIONS.COPY_TO_CLIPBOARD_FAIL.MESSAGE'),
                            type: 'success',
                            timeout: 3000
                        })
                        return false
                    }
                    finally {
                        document.body.removeChild(input)
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
