<template>
    <div>
        <app-table-v2
            :provider="getData" :fields="fields"
            :show-limit="true"
            refresh-event="searchWebsiteTags"
            :selectable="checkPermission(C_WEBSITE_ACTIVATE_TAG_E)"
            :showSearchMessages="!show_tip"
            @busyToggled="toggleBusy">

            <template v-slot:active="data">
                <i v-if="data.item.active" class="fa fa-check  action_button" aria-hidden="true"></i>
                <i v-else class="fa fa-times action_button" aria-hidden="true"></i>
            </template>
            <template v-slot:delete="data">

                <b-checkbox
                    v-if="checkPermission(C_WEBSITE_ACTIVATE_TAG_E)"
                    switch
                    :id="'activate_key_' + data.item.id"
                    @change="editItem(data.item, $event)"
                    v-model="data.item.active">
                </b-checkbox>
            </template>
            <template v-slot:selectedContainer="data">
                <app-button v-if="checkPermission(C_WEBSITE_ACTIVATE_TAG_E)" @click="save(data.selected)" :loading="saving">{{ $t('ACTIVATE') }}</app-button>
                <app-button v-if="checkPermission(C_WEBSITE_ACTIVATE_TAG_E)" @click="deleteItems(data.selected)" :loading="deleting" class="ml-3">{{
                        $t('DEACTIVATE')
                    }}
                </app-button>
            </template>
        </app-table-v2>
        <app-confirmation-dialog :show="confirmDialog" @cancel="confirmDialog=false"
                                 @confirm="saveData"></app-confirmation-dialog>
        <app-no-data v-if="show_tip" :show-tip="true" :heading="$t('MODULE_NOT_ACTIVE')" :tip="$t('NEED_DESTINATION_TAG')"></app-no-data>
    </div>
</template>

<script>
    import AppTableV2 from "@/components/app/AppTableV2";
    import {STATUS_CONFIRMED, TAG_DESTINATION_TYPE_CITY} from "@/shared/constants";
    import {getTagList, saveTagList} from "@/services/direct_booking/website";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {EventBus} from "@/shared/EventBus";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import {cloneDeep} from "lodash";
    import AppNoData from "@/components/app/AppNoData";
    import {C_WEBSITE_ACTIVATE_TAG_E} from "@/shared/component_permission";

    export default {
        name: "WebsiteTagTable",
        components: {AppNoData, AppConfirmationDialog, AppButton, AppTableV2},
        mixins: [getErrorMessage],
        props: {
            filter: {
                type: Object
            },
            show_tip:{
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                C_WEBSITE_ACTIVATE_TAG_E,
                saving: false,
                deleting: false,
                saveList: [],
                confirmDialog: false,
                fields: [
                    {key: 'id', label: this.$t("ID")},
                    {key: 'city', label: this.$t('CITY')},
                    {key: 'region', label: this.$t("REGION")},
                    {key: 'country', label: this.$t("COUNTRY")},
                    {key: 'delete', label: this.$t("ACTIVE"),tdClass: 'action_button_w'}
                ]
            }
        },
        methods: {
            getData(filter) {
                let addFields = {}
                if (this.filter.active_website_tag) addFields.active_website_tag = this.filter.active_website_tag


                let req = {
                    perPage: filter.perPage,
                    page: filter.currentPage,
                    destination_type: TAG_DESTINATION_TYPE_CITY,
                    ...this.filter
                }
                return getTagList(this.filter.websiteId, req)
            },
            editItem(data, event) {
                this.$nextTick(() => {
                    this.saving = true
                    data.deleted = event ? 0 : 1
                    this.saveList.push(data)
                    this.saveData()
                })
            },
            deleteItems(selected) {
                this.deleting = true
                this.saveList = selected.map(({deleted, ...rest}) => ({...rest, deleted: 1}));

                let even = this.saveList.some((el) => el.active === true)
                if (even) {
                    this.confirmDialog = true
                } else {
                    this.saveData()
                }
            },
            saveData() {
                saveTagList(this.website.id, {list: this.saveList}).then(() => {
                    notifySuccess()
                    EventBus.$emit('searchWebsiteTags')
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.saving = false
                    this.confirmDialog = false
                    this.deleting = false
                })
            },
            save(selected) {
                this.saving = true
                this.saveList = selected.map(({deleted, ...rest}) => ({...rest, deleted: 0}));
                this.saveData()
            },
            toggleBusy($event) {
                this.$emit("busyToggled", $event)
            },
        },
        computed: {
            website() {
                return this.$store.getters['website/getWebsite']
            }
        },
        watch: {
            filter: {
                handler() {
                    EventBus.$emit('searchWebsiteTags')
                },
                deep: true
            },
        }
    }
</script>

<style scoped>

</style>
