<template>
    <tr>
        <td :class="{'pl-0': wide_display}">
            <app-date-range-picker format="MM/DD" v-model="selectedPeriod.range" :min-date="firstDayCurrentYear" :max-date="endDayCurrentYear"></app-date-range-picker>
        </td>
        <td :class="{'pl-0': wide_display, 'pr-0': wide_display}">
            <b-form-input  v-model="selectedPeriod.value" :step="0.01" :disabled="disabled"> </b-form-input>
        </td>
        <td :class="{'pr-0': wide_display}">
            <AppButton
                       button_type="delete"
                       :show_text="false"
                       @click="deletePeriod"
                       variant="link">
            </AppButton>
        </td>
    </tr>
</template>

<script>

import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import PeriodRateMaskItemNew from "@/components/pricing/rate_plan/period/PeriodRateMaskItemNew";
import {C_UNIT_CALENDAR_PERIODS_E, C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_E} from "@/shared/component_permission";
import AppButton from "@/components/app/AppButton/AppButton";
import {cloneDeep} from 'lodash'
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import moment from 'moment'

export default {
    name: "PeriodRateMaskItem",
    props: {
        period: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    period_start: null,
                    period_end: null,
                    value: null,
                    deleted: null
                }
            }
        },
        wide_display: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components: {
        AppDateRangePicker,
        AppButton,
        PeriodRateMaskItemNew
    },
    mixins: [getErrorMessage],
    data() {
        return {
            currentYear: moment().format('Y'),
            selectedPeriod: {
                id: null,
                range: {
                    start: null,
                    end: null
                },
                value: null,
                deleted: null
            },
            saving: false,
            C_UNIT_CALENDAR_PERIODS_E,
            C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_E
        }
    },
    computed: {
        firstDayCurrentYear() {
            return moment().startOf('year').format('YYYY-MM-DD')
        },
        endDayCurrentYear() {
            return moment().endOf('year').format('YYYY-MM-DD')
        }
    },
    methods: {
        deletePeriod() {
            this.selectedPeriod.deleted = 1
            this.$emit('delPeriod', this.selectedPeriod)
        },
        formatDate(value) {
            if (value) {
                let date = new Date(value)
                return moment(date).format('MM/DD')
            }
        },
        delPeriod(id) {
            this.$emit('delPeriod', id)
        },
        preparePeriod(period) {
            let formattedPeriod = cloneDeep(period)
            const periodStart = !period.range.start ? null : moment(period.range.start, 'YYYY-MM-DD').format('MM/DD')
            const periodEnd = !period.range.end ? null : moment(period.range.end, 'YYYY-MM-DD').format('MM/DD')
            this.$set(formattedPeriod, 'period_start', periodStart)
            this.$set(formattedPeriod, 'period_end', periodEnd)
            this.$set(formattedPeriod, 'value', period.value)
            this.$delete(formattedPeriod, 'range')

            return formattedPeriod
        },
    },
    watch: {
        period: {
            handler(value) {
                if (value) {
                    this.selectedPeriod = cloneDeep({
                        id: this.period.id,
                        range: {
                            // start: new moment(this.currentYear + '/' + this.period.period_start, 'YYYY-MM-DD').format('MM-DD'),
                            // end: new moment(this.currentYear + '/' + this.period.period_end, 'YYYY-MM-DD').format('MM-DD'),
                            start: this.period.period_start,
                            end: this.period.period_end,
                        },
                        value: this.period.value,
                        deleted: this.period.deleted
                    })
                }
            },
            deep: true,
            immediate: true
        },
        selectedPeriod: {
            handler(value) {
                this.$emit("update", this.preparePeriod(value))
            },
            deep: true,
        }
    }

}
</script>

<style scoped>

</style>
