<template>
    <div>
        <b-form @submit.prevent="save" autocomplete="off">
            <b-row>
                <b-col md="6" class="mb-4">
                    <label>{{ $t("FROM_YEAR") }}</label>
                    <app-select
                        v-model="yearFrom"
                        :options="yearsFromComputed">
                    </app-select>
                </b-col>
                <b-col md="6" class="mb-4">
                    <label>{{ $t("TO_YEAR") }}</label>
                    <app-select
                        mode="multiselect"
                        v-model="yearsTo"
                        :options="yearsToComputed">
                    </app-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <label>
                        {{ isDistributionDefined ? $t("DISTRIBUTION") : $t("SALES_CHANNEL") }}
                    </label>
                    <b-checkbox
                        class="ml-3"
                        inline
                        switch
                        @change="toggleAllDistributionsChannels"
                        :checked="allDistributionsChannelsSelected">
                    </b-checkbox>
                </b-col>
            </b-row>
            <b-row v-if="isDistributionDefined && !!distributionList">
                <b-col md="4" lg="4"
                       v-for="(distribution, distributionIndex) in distributionList">
                    <b-checkbox
                        :key="distributionIndex"
                        switch
                        v-model="selectedDistributionList"
                        :value="distribution.id"
                        :disabled="distributionId === distribution.id">
                        {{ distribution.name }}
                    </b-checkbox>
                </b-col>
            </b-row>
            <b-row v-else-if="!isDistributionDefined && !!channelList">
                <b-col md="4" lg="4"
                       v-for="(channel, channelIndex) in channelList">
                    <b-checkbox
                        :key="channelIndex"
                        switch
                        v-model="selectedChannelList"
                        :value="channel.id"
                        :disabled="channelId === channel.id">
                        {{ channel.label }}
                    </b-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mt-4">
                    <app-button-submit
                        :loading="saving"
                        :disabled="!yearFrom || yearsTo.length === 0 || !distributionChannelEntitySelected">
                        {{ $t("COPY") }}
                    </app-button-submit>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import moment from "moment";

export default {
    name: "PlatformFeeCopyForm",
    components: {
        AppButtonSubmit,
        AppSelect,
    },
    props: {
        saving: {
            type: Boolean,
            required: true,
        },
        distributionId: {
            type: Number,
            default: null,
            validator: (value) => {
                return value === null || (Number.isInteger(value) && value > 0)
            },
        },
        channelId: {
            type: Number,
            default: null,
            validator: (value) => {
                return value === null || (Number.isInteger(value) && value > 0)
            },
        },
        distributionList: {
            type: Array | Object,
            default: () => {
                return null
            },
        },
        channelList: {
            type: Array | Object,
            default: () => {
                return null
            },
        },
    },
    data() {
        return {
            yearFrom: null,
            yearsTo: [],
            selectedDistributionList: [],
            selectedChannelList: [],
        }
    },
    computed: {
        yearsFromComputed() {
            return [...Array(4)].map((a, b) => {
                return {
                    id: (Number(new moment().format('YYYY')) - 1 + b).toString(),
                    name: Number(new moment().format('YYYY')) - 1 + b
                }
            })
        },
        yearsToComputed() {
            return [...Array(3)].map((a, b) => {
                return {
                    id: (Number(new moment().format('YYYY')) + b).toString(),
                    name: Number(new moment().format('YYYY')) + b
                }
            })
        },
        isDistributionDefined() {
            return !!this.distributionId
        },
        distributionChannelEntitySelected() {
            return this.isDistributionDefined ? this.selectedDistributionList.length > 0 : this.selectedChannelList.length > 0
        },
        allDistributionsChannelsSelected() {
            return this.isDistributionDefined ? Array.isArray(this.distributionList) && this.selectedDistributionList.length === this.distributionList.length : Array.isArray(this.channelList) && this.selectedChannelList.length === this.channelList.length
        },
    },
    methods: {
        save() {
            let payload = {
                source_year: this.yearFrom,
                destination_years: this.yearsTo,
            }
            if (this.isDistributionDefined) {
                this.$set(payload, "destination_distributions", this.selectedDistributionList)
            } else {
                this.$set(payload, "destination_channels", this.selectedDistributionList)
            }
            this.$emit("save", payload)
        },
        toggleAllDistributionsChannels(event) {
            if (this.isDistributionDefined) {
                this.toggleAllDistributions(event)
            } else {
                this.toggleAllChannels(event)
            }
        },
        toggleAllDistributions(event) {
            this.selectedDistributionList.splice(0)
            this.selectedDistributionList.push(this.distributionId)
            if (event) {
                this.distributionList.forEach((distribution) => {
                    if (distribution.id !== this.distributionId) {
                        this.selectedDistributionList.push(distribution.id)
                    }
                })
            }
        },
        toggleAllChannels(event) {
            this.selectedChannelList.splice(0)
            this.selectedChannelList.push(this.channelId)
            if (event) {
                this.channelList.forEach((channel) => {
                    if (channel.id !== this.channelId) {
                        this.selectedChannelList.push(channel.id)
                    }
                })
            }
        },
    },
    created() {
        if (this.isDistributionDefined) {
            this.selectedDistributionList.push(this.distributionId)
        } else {
            this.selectedChannelList.push(this.channelId)
        }
    },
}
</script>

<style scoped>

</style>
