<template>
    <b-form v-if="unitTypeList.length && hasPermissionShow" @submit.prevent="save">
        <b-row>
            <b-col md="6" class="mb-4">
                <app-input-control :error-object="validationErrorObject.name">
                    <template v-slot:input="data">
                        <label>{{$t('NAME')}}</label>
                        <b-input :state="data.state" id="unitName" v-model="dataObject.name" type="text"></b-input>
                    </template>
                </app-input-control>
            </b-col>
            <app-access-control class="mb-4" cols="3"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{unit: dataObject.id,function:C_UNIT_BASIC_TYPE_V, key: AC_UNIT_GENERAL}">
                <app-input-control :error-object="validationErrorObject.type">
                    <template v-slot:input="data">
                        <label>{{$t('TYPE')}}</label>
                        <app-select :state="data.state" v-model="dataObject.unit_type.id" text-field="label" :options="unitTypeList"></app-select>
                    </template>
                </app-input-control>

            </app-access-control>

            <b-col md="3" class="mb-4">
                <app-input-control :error-object="validationErrorObject.owner">
                    <template v-slot:input="data">
                        <label>{{$t('OWNER')}}</label>
                        <app-select :disabled="disableOwner" :state="data.state" v-model="dataObject.owner.id" :options="ownerList"></app-select>
                    </template>
                </app-input-control>

            </b-col>
        </b-row>
        <b-row>
            <app-access-control class="mb-4" cols="3"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{unit: dataObject.id,function:C_UNIT_BASIC_SIZE_V, key: AC_UNIT_GENERAL}">
                <app-input-control :error-object="validationErrorObject.size">
                    <template v-slot:input="data">
                        <label>{{$t('SIZE')}}</label>
                        <b-input-group>
                            <b-input  :state="data.state" v-model="dataObject.size"></b-input>
                            <b-input-group-append>
                                <b-input-group-text>m<sup>2</sup></b-input-group-text>
                            </b-input-group-append>
                        </b-input-group>
                    </template>
                </app-input-control>
            </app-access-control>
            <app-access-control class="mb-4" cols="3"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{unit: dataObject.id,function:C_UNIT_BASIC_NUM_BEDROOMS_V, key: AC_UNIT_GENERAL}">
                <app-input-control :error-object="validationErrorObject.number_of_bedrooms">
                    <template v-slot:input="data">
                        <label>{{$t('NUMBER_BEDROOMS')}}</label>
                        <b-input :state="data.state" id="unitBedrooms" v-model="dataObject.number_bedrooms" ></b-input>
                    </template>
                </app-input-control>
            </app-access-control>

            <b-col md="3" class="mb-4">
                <app-input-control :error-object="validationErrorObject.floor_level">
                    <template v-slot:input="data">
                        <label>{{$t('FLOOR_LEVEL')}}</label>
                        <b-input :state="data.state" id="unitFloor" v-model="dataObject.floor" ></b-input>
                    </template>
                </app-input-control>

            </b-col>

        </b-row>
        <b-row>
            <app-access-control class="mb-4" cols="3"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{unit: dataObject.id,function:C_UNIT_BASIC_MAX_ADULTS_V, key: AC_UNIT_GENERAL}">
                <app-input-control :error-object="validationErrorObject.max_adults">
                    <template v-slot:input="data">
                        <label>{{$t('MAX_ADULTS')}}</label>
                        <b-input :state="data.state" id="maxUnitAdultGuests" v-model.number="dataObject.max_adults" ></b-input>
                    </template>
                </app-input-control>

            </app-access-control>
            <app-access-control class="mb-4" cols="3"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{unit: dataObject.id,function:C_UNIT_BASIC_MAX_ADULTS_V, key: AC_UNIT_GENERAL}">
                <app-input-control :error-object="validationErrorObject.max_children">
                    <template v-slot:input="data">
                        <label>{{$t('MAX_CHILDREN')}}</label>
                        <b-input :state="data.state" id="maxUnitChildrenGuests" v-model.number="dataObject.max_children"></b-input>
                    </template>
                </app-input-control>

            </app-access-control>
            <app-access-control class="mb-4" cols="3"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{unit: dataObject.id,function:C_UNIT_BASIC_MAX_BABIES_V, key: AC_UNIT_GENERAL}">
                <app-input-control :error-object="validationErrorObject.max_babies">
                    <template v-slot:input="data">
                        <label>{{$t('MAX_BABIES')}}</label>
                        <b-input :state="data.state" id="maxUnitBabiesGuests" v-model.number="dataObject.max_babies" ></b-input>
                    </template>
                </app-input-control>
            </app-access-control>

            <app-access-control class="mb-4" cols="3"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{unit: dataObject.id,function:C_UNIT_BASIC_MAX_GUESTS_V, key: AC_UNIT_GENERAL}">
                <app-input-control :error-object="validationErrorObject.max_guests">
                    <template v-slot:input="data">
                        <label>{{$t('MAX_GUESTS')}}</label>
                        <b-input :state="data.state" :disabled="true" id="maxUnitGuests" v-model.number="dataObject.max_guests" ></b-input>
                    </template>
                </app-input-control>

            </app-access-control>

        </b-row>
        <b-row>
            <b-col class="mb-4">
                <b-checkbox unchecked-value="0" value="1" v-model.number="dataObject.full_property_management" switch>
                    {{$t('FPM')}}
                </b-checkbox>
            </b-col>
        </b-row>
        <app-access-control tag="div"
                            @AccessControlEvent="addToAccessControlCounter()"
                            :access_control_context="{unit: dataObject.id,function:C_UNIT_LOCATION_V, key: AC_UNIT_GENERAL}">
            <b-row>
                <b-col>
                    <form-header>{{$t("LOCATION")}}</form-header>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.address">
                        <template v-slot:input="data">
                            <label>{{$t('ADDRESS')}}</label>
                            <b-form-input :state="data.state" type="text" v-model="dataObject.location.address"></b-form-input>
                        </template>
                    </app-input-control>

                </b-col>
                <b-col md="6" lg="4" xl="1" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.address_no">
                        <template v-slot:input="data">
                            <label>{{$t('STREET_NUMBER')}}</label>
                            <b-form-input :state="data.state" type="text" v-model="dataObject.location.address_no"></b-form-input>
                        </template>
                    </app-input-control>


                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <form-header>{{$t("GEOLOCATION")}}</form-header>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="4" xl="2" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.latitude">
                        <template v-slot:input="data">
                            <label>{{$t('LATITUDE')}}</label>
                            <b-form-input :state="data.state" lazy v-model="dataObject.location.latitude"></b-form-input>
                        </template>
                    </app-input-control>


                </b-col>
                <b-col md="4" xl="2" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.longitude">
                        <template v-slot:input="data">
                            <label>{{$t('LONGITUDE')}}</label>
                            <b-form-input :state="data.state" lazy v-model="dataObject.location.longitude"></b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mb-4">
                    <g-map :lat="dataObject.location.latitude" :lng="dataObject.location.longitude"
                           @gmapLocationChange="updateFormLocation"></g-map>
                </b-col>
            </b-row>
        </app-access-control>

        <b-row>
            <b-col class="d-flex justify-content-between">
                <submitBasic
                    :access_control_context="{function:C_UNIT_BASIC_E,key:AC_UNIT_GENERAL}"
                    @AccessControlEvent="addToAccessControlCounter()"
                             :button_type="saveButtonState ? 'loading': 'save'"></submitBasic>

            <app-access-control tag="div" @AccessControlEvent="addToAccessControlCounter()" :access_control_context="{function:C_UNIT_BASIC_D, key: AC_UNIT_GENERAL}">
                <template v-slot:default="props">

                    <b-tooltip v-if="props.messages.length"
                               class="mb-5"
                               triggers="hover"
                               :title="props.messages[0].message" :target="'key_'+dataObject.id">
                    </b-tooltip>
                    <div>
                        <delete-button :id="'key_'+dataObject.id" :disabled="props.disabled" :c_permission="C_UNIT_BASIC_D" tooltip-placement="left" @click="showDeleteDialog=true"
                                       v-if="dataObject.id"></delete-button>
                    </div>

                </template>


            </app-access-control>

                <delete-dialog v-if="dataObject.id" :show="showDeleteDialog" @confirm="deleteUnit"
                               @cancel="showDeleteDialog=false" :delete_title="true"></delete-dialog>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>

    import submitBasic from '@/components/app/AppButton/AppButtonSubmit'
    import DeleteButton from '@/components/app/AppButton/AppButtonDelete'
    import DeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import {getPropertyOwners} from '@/services/owner/index'
    import {getUnitTypeList} from '@/services/unit/index'
    import {toast} from '@/shared/plugins/toastr'
    import Routes from '@/router/routeNames'
    import {C_UNIT_BASIC_E, C_UNIT_BASIC_V, C_UNIT_NEW_V, C_UNIT_BASIC_D} from "@/shared/component_permission";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import FormHeader from "@/components/app/form/FormHeader";
    import GMap from '@/components/app/map/GoogleMap'
    import AppInputControl from "@/components/app/AppInputControl";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {
        C_UNIT_BASIC_TYPE_V,
        C_UNIT_BASIC_MAX_ADULTS_V,
        C_UNIT_BASIC_MAX_BABIES_V,
        C_UNIT_BASIC_SIZE_V,
        C_UNIT_BASIC_NUM_BEDROOMS_V,
        C_UNIT_BASIC_FLOOR_LEVEL_V,
        C_UNIT_BASIC_MAX_GUESTS_V,
        C_UNIT_LOCATION_V
    } from "@/shared/component_permission";
    import {AC_UNIT_GENERAL} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {

        PROPERTY_DATA_NAME_VALIDATION_FAILED,
        PROPERTY_DATA_UNIT_TYPE_VALIDATION_FAILED,
        PROPERTY_DATA_NUMBER_BEDROOMS_VALIDATION_FAILED,
        UNIT_DATA_OWNER_IS_MANDATORY_VALIDATION_FAILED,
        PROPERTY_DATA_SIZE_VALIDATION_FAILED,
        PROPERTY_DATA_FLOOR_VALIDATION_FAILED,
        PROPERTY_DATA_MAX_ADULTS_VALIDATION_FAILED,
        PROPERTY_DATA_MAX_BABIES_VALIDATION_FAILED,
        PROPERTY_DATA_MAX_CHILDREN_VALIDATION_FAILED,
        PROPERTY_DATA_MAX_GUESTS_VALIDATION_FAILED,
        UNIT_DATA_LATITUDE_VALIDATION_FAILED,
        UNIT_DATA_LONGITUDE_VALIDATION_FAILED,
        UNIT_DATA_ADDRESS_NO_VALIDATION_FAILED,
        UNIT_DATA_ADDRESS_VALIDATION_FAILED,
        UNIT_DATA_INVALID_LATITUDE_VALIDATION_FAILED,
        UNIT_DATA_INVALID_LONGITUDE_VALIDATION_FAILED,
        PROPERTY_DATA_MAX_ADULTS_MANDATORY_FIELD,
        PROPERTY_DATA_MAX_GUESTS_MANDATORY_FIELD,
        PROPERTY_DATA_SIZE_MANDATORY_FIELD,
        PROPERTY_DATA_FLOOR_MANDATORY_FIELD,
        PROPERTY_DATA_NUMBER_BEDROOMS_MANDATORY_FIELD,
        PROPERTY_DATA_MAX_BABIES_MANDATORY_FIELD,
        PROPERTY_DATA_MAX_CHILDREN_MANDATORY_FIELD


    } from "@/shared/error_codes";

    export default {
        name: "UnitBasicForm",
        mixins:[AccessControlComponent],
        props: {
            dataObject: {
                type: Object,
                default: () => {
                    return {
                        "id": null,
                        "name": null,
                        "max_guests": null,
                        "max_adults": null,
                        "max_children": null,
                        "max_babies": null,
                        "property": {
                            "id": null,
                            "name": null
                        },
                        "location": {
                            "address": null,
                            "address_no": null,
                            "latitude": null,
                            "longitude": null
                        },
                        "owner": {
                            "id": null,
                            "name": null
                        },
                        "unit_type": {
                            "id": null,
                            "label": null
                        },
                        "floor": null,
                        "size": null,
                        "full_property_management": 0,
                        "number_bedrooms": null
                    }
                }
            }
        },
        components: {
            AppAccessControl,
            AppInputControl,
            AppSelect,
            submitBasic, DeleteButton, DeleteDialog, FormHeader, GMap
        },
        data() {
            return {
                disableOwner:false,
                owners: [],
                unitTypes: [],
                saveButtonState: false,
                showDeleteDialog: false,
                access_control_fetch_key: AC_UNIT_GENERAL,
                access_control_components: 10,
                C_UNIT_BASIC_D,
                C_UNIT_BASIC_TYPE_V,
                C_UNIT_BASIC_MAX_ADULTS_V,
                C_UNIT_BASIC_MAX_BABIES_V,
                C_UNIT_BASIC_SIZE_V,
                C_UNIT_BASIC_NUM_BEDROOMS_V,
                C_UNIT_BASIC_FLOOR_LEVEL_V,
                AC_UNIT_GENERAL,
                C_UNIT_BASIC_MAX_GUESTS_V,
                C_UNIT_LOCATION_V,
                C_UNIT_BASIC_E,
                validationErrorObject:{

                    name:{
                        input:[PROPERTY_DATA_NAME_VALIDATION_FAILED]
                    },
                    type: {
                        input:[PROPERTY_DATA_UNIT_TYPE_VALIDATION_FAILED]
                    },
                    owner: {
                        input:[UNIT_DATA_OWNER_IS_MANDATORY_VALIDATION_FAILED]
                    },

                    number_of_bedrooms:{
                        input:[PROPERTY_DATA_NUMBER_BEDROOMS_VALIDATION_FAILED,PROPERTY_DATA_NUMBER_BEDROOMS_MANDATORY_FIELD]
                    },
                    floor_level: {
                        input:[PROPERTY_DATA_FLOOR_VALIDATION_FAILED,PROPERTY_DATA_FLOOR_MANDATORY_FIELD]
                    },
                    size: {
                        input:[PROPERTY_DATA_SIZE_VALIDATION_FAILED,PROPERTY_DATA_SIZE_MANDATORY_FIELD]
                    },
                    max_adults:{
                        input:[PROPERTY_DATA_MAX_ADULTS_VALIDATION_FAILED,PROPERTY_DATA_MAX_ADULTS_MANDATORY_FIELD]
                    },
                    max_children:{
                        input:[PROPERTY_DATA_MAX_CHILDREN_VALIDATION_FAILED,PROPERTY_DATA_MAX_CHILDREN_MANDATORY_FIELD]
                    },
                    max_babies:{
                        input:[PROPERTY_DATA_MAX_BABIES_VALIDATION_FAILED,PROPERTY_DATA_MAX_BABIES_MANDATORY_FIELD]
                    },
                    max_guests:{
                        input:[PROPERTY_DATA_MAX_GUESTS_VALIDATION_FAILED,PROPERTY_DATA_MAX_GUESTS_MANDATORY_FIELD]
                    },


                    address: {
                        input:[UNIT_DATA_ADDRESS_VALIDATION_FAILED]
                    },
                    address_no: {
                        input:[UNIT_DATA_ADDRESS_NO_VALIDATION_FAILED]
                    },
                    latitude: {
                        input:[UNIT_DATA_LATITUDE_VALIDATION_FAILED, UNIT_DATA_INVALID_LATITUDE_VALIDATION_FAILED]
                    },
                    longitude:{
                        input:[UNIT_DATA_LONGITUDE_VALIDATION_FAILED, UNIT_DATA_INVALID_LONGITUDE_VALIDATION_FAILED]
                    },



                }

            }
        },
        computed: {
            ownerList() {
                return this.owners
            },
            unitTypeList() {
                return this.unitTypes
            },
            hasPermissionShow() {
                if (this.$route.name === Routes.RN_PROPERTY_UNIT_NEW) {
                    return this.$store.getters['user/getPermission'](C_UNIT_NEW_V)
                } else {
                    return this.$store.getters['user/getPermission'](C_UNIT_BASIC_V)
                }
            },
            permissionEdit() {
                if (this.$route.name === Routes.RM_PROPERTY_UNIT_BASIC) {
                    return C_UNIT_BASIC_E
                }
                return null
            },

        },
        methods: {
            updateFormLocation(data) {
                this.dataObject.location.latitude = data.latitude
                this.dataObject.location.longitude = data.longitude

                if (
                    (this.dataObject.location.address === '' || this.dataObject.location.address === null) &&
                    (this.dataObject.location.address_no === '' || this.dataObject.location.address_no === null)
                ) {
                    this.dataObject.location.address = data.street
                    this.dataObject.location.address_no = data.street_number
                }
            },
            save() {

                this.$emit('unitBasicSubmit', {data:this.dataObject, validation:this.validationErrorObject})
            },
            deleteUnit() {
                this.$emit('deleteUnit', this.dataObject.id)
            }
        },
        watch: {
            'dataObject.max_adults': {
                handler(value) {
                    this.dataObject.max_guests = Number(value) + Number(this.dataObject.max_children)
                },
                immediate: true
            },
            'dataObject.max_children': {
                handler(value) {
                    this.dataObject.max_guests = Number(value) + Number(this.dataObject.max_adults)
                }
            }
        },
        created() {
            getPropertyOwners(this.$route.params.id).then(response => {
                this.owners = response.data
                if( this.owners.length === 1){
                    this.dataObject.owner.id = this.owners[0].id
                    this.disableOwner = true
                }
            }, () => {
                this.owners = []
            })

            getUnitTypeList(this.$route.params.id).then(response => {
                this.unitTypes = response.data
            }, () => {
                this.unitTypes = []
            })
            this.access_control_general_context = {
                unit: this.dataObject.id,
                company: this.$store.getters['user/getCompany']
            }
        }
    }
</script>

<style scoped>

</style>
