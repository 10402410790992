<template>
    <b-card v-cloak class="property-wrapper" v-if="property.hasOwnProperty('id')" :key="property.id">
        <template slot="header">
            <div class="header-caption">{{ $t('PROPERTY') }}</div>
            <h2 class="property-wrapper-padding">{{ property.name }}</h2>
            <app-button v-if="checkPermission(C_RESERVATION_ACCOMMODATION_NEW) && $route.name === routeNames.RN_PROPERTY_CALENDAR"
            button_type="new" class="ml-3 pull-right" variant="primary"  @click="res_aside=true">{{
                    $t("NEW_RESERVATION") }}
            </app-button>
            <header-button></header-button>
            <Navigation :access_control_context="{key:AC_PROPERTY_NAVIGATION}"
                        :ac-fetch-key="AC_PROPERTY_NAVIGATION"
                        :context="generalContext"
                        :nav-elements="navElements"></Navigation>
        </template>
        <app-aside v-model="res_aside">
            <template slot="header">
                {{$t('NEW_RESERVATION')}}
            </template>
            <new-accommodation-reservation-form-test @created="createdReservation"
                                                     :redirect="false"
                                                     :property-id="property.id"
                                                     :unit-id="unitId">
            </new-accommodation-reservation-form-test>
        </app-aside>
        <page-transition transition-type="slide">
            <router-view v-cloak></router-view>
        </page-transition>
    </b-card>
</template>

<script>
import Navigation from '@/components/app/navigation/Navigation';
import AppButton from "@/components/app/AppButton/AppButton";
import PageTransition from '@/components/app/transition/AppTransition'
import routeNames from "@/router/routeNames";
import HeaderButton from "@/components/app/header_button/HeaderButton";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_PROPERTY_GENERAL} from "@/mixins/AccessControl/AccessControlEnumeration";
import {
    AC_PROPERTY_NAVIGATION,
} from "@/mixins/AccessControl/AccessControlEnumeration";
import {
    C_PROPERTY_NAVIGATION_BOOKING_SITES_V,
    C_PROPERTY_NAVIGATION_PROMOTIONS_V,
    C_PROPERTY_NAVIGATION_CRM_V,
    C_PROPERTY_NAVIGATION_OWNER_RATE_MANAGER,
    C_PROPERTY_NAVIGATION_GALLERY_V,
    C_PROPERTY_NAVIGATION_OLD_GALLERY_V,
    C_RESERVATION_ACCOMMODATION_NEW
} from "@/shared/component_permission";
import {
    R_PROPERTY_TRANSFERS
} from '@/shared/route_permission'
import AppAccessControl from "@/components/app/AppAccessControl";
import NewAccommodationReservationFormTest
    from "@/components/reservation/accommodation/forms/NewAccommodationReservationFormTest";
import {EventBus, GE_CALENDAR_REFRESH_DATA} from "@/shared/EventBus";
import AppAside from "@/components/app/form/AppAside";

export default {
    name: "Index",
    components: {
        AppAccessControl,
        HeaderButton,
        Navigation, PageTransition, AppButton,
        NewAccommodationReservationFormTest,
        AppAside
    },
    mixins: [AccessControlComponent],
    data() {
        return {
            // monthlyReportHidden: true,
            AC_PROPERTY_NAVIGATION,
            AC_PROPERTY_GENERAL,
            res_aside: false,
            unitId: null,
            routeNames,
            C_RESERVATION_ACCOMMODATION_NEW
        }
    },
    computed: {
        property() {
            return this.$store.getters['property/getProperty']
        },
        company_id() {
            return this.$store.getters['user/getCompany']
        },
        generalContext() {
            return {
                property: this.$route.params.id,
                company: this.$store.getters['user/getCompany']
            }
        },
        navElements() {
            return [
                {
                    route: {name: routeNames.RN_PROPERTY_OVERVIEW},

                },
                {
                    route: {name: routeNames.RN_PROPERTY_BASIC},

                },
                {
                    label: this.$t(`POLICIES`),
                    children: [
                        {
                            route: {name: routeNames.RN_PROPERTY_CANCELATION_POLICIES},
                        },
                        {
                            route: {name: routeNames.RN_PROPERTY_POLICY_OTHER},
                        },
                    ]
                },
                {
                    route: {name: routeNames.RN_PROPERTY_GALLERY},
                    access_control_context: {
                        company: this.company_id,
                        function: C_PROPERTY_NAVIGATION_OLD_GALLERY_V,
                        key: AC_PROPERTY_NAVIGATION
                    }
                },
                {
                    route: {name: routeNames.RN_NEW_PROPERTY_GALLERY},
                    access_control_context: {
                        company: this.company_id,
                        function: C_PROPERTY_NAVIGATION_GALLERY_V,
                        key: AC_PROPERTY_NAVIGATION
                    }
                },
                {
                    route: {name: routeNames.RN_PROPERTY_POI},
                },
                {
                    route: {name: routeNames.RN_PROPERTY_DESCRIPTIONS},
                },
                {
                    route: {name: routeNames.RN_PROPERTY_OWNER_LIST},
                },

                {
                    route: {name: routeNames.RN_PROPERTY_UNIT_LIST},
                },
                {
                    route: {name: routeNames.RN_PROPERTY_AMENITIES},
                },
                {
                    route: {name: routeNames.RN_PROPERTY_CALENDAR},
                },

                {
                    route: {name: routeNames.RN_PROPERTY_OWNER_RATE_MANAGER},
                    access_control_context: {
                        company: this.company_id,
                        function: C_PROPERTY_NAVIGATION_OWNER_RATE_MANAGER,
                        key: AC_PROPERTY_NAVIGATION
                    }
                },
                {
                    route: {name: routeNames.RN_PROPERTY_CHANNEL_MANAGER_DISTRIBUTION},
                    // access_control_context: {company: this.company_id, function:C_PROPERTY_NAVIGATION_CM_V,  key: AC_PROPERTY_NAVIGATION}
                },
                {
                    route: {name: routeNames.RN_PROPERTY_REVIEW},
                },
                {
                    label: this.$t('INTEGRATIONS'),
                    children: [
                        {
                            route: {name: routeNames.RN_PROPERTY_GUEST_CHECKIN},

                        },
                        {
                            route: {name: routeNames.RN_PROPERTY_BOOKING_ENGINE},
                        },
                        {
                            route: {name: routeNames.RN_PROPERTY_ICAL},
                        }

                    ]
                },
                {
                    label: this.$t('OTHER'),
                    children: [
                        {
                            route: {name: routeNames.RN_PROPERTY_BOOKING_SITES},
                            access_control_context: {
                                company: this.company_id,
                                function: C_PROPERTY_NAVIGATION_BOOKING_SITES_V,
                                key: AC_PROPERTY_NAVIGATION
                            }
                        },
                        {
                            route: {name: routeNames.RN_PROPERTY_DECLINED_CC_ACTIONS},
                            access_control_context: {
                                company: this.company_id,
                                function: C_PROPERTY_NAVIGATION_BOOKING_SITES_V,
                                key: AC_PROPERTY_NAVIGATION
                            }

                        },
                        {
                            route: {name: routeNames.RN_PROPERTY_CONTACTS},
                        },
                        {
                            route: {name: routeNames.RN_PROPERTY_USER_ACCESS},
                        },
                        {
                            route: {name: routeNames.RN_PROPERTY_TRANSFER},
                            access_control_context: {
                                company: this.company_id,
                                function: R_PROPERTY_TRANSFERS,
                                key: AC_PROPERTY_NAVIGATION
                            }
                        },
                        {
                            route: {name: routeNames.RN_PROPERTY_PROMOTIONS},
                            access_control_context: {
                                company: this.company_id,
                                function: C_PROPERTY_NAVIGATION_PROMOTIONS_V,
                                key: AC_PROPERTY_NAVIGATION
                            }
                        },
                        // {
                        //     route: {name: routeNames.RN_PROPERTY_RATE_MANAGER},
                        //     access_control_context: {company: this.company_id, function:C_PROPERTY_NAVIGATION_RATE_MANAGER_V, key: AC_PROPERTY_NAVIGATION}
                        //
                        // },
                        {
                            route: {name: routeNames.RN_PROPERTY_CRM},
                            access_control_context: {
                                company: this.company_id,
                                function: C_PROPERTY_NAVIGATION_CRM_V,
                                key: AC_PROPERTY_NAVIGATION
                            }
                        },
                        // {
                        //     route: {
                        //         name: routeNames.RN_PROPERTY_GUEST_MONTHLY_REPORT,
                        //     },
                        //     hide: this.monthlyReportHidden
                        // },
                        {
                            route: {name: routeNames.RN_PROPERTY_EVENT_TEMPLATES},
                            //access_control_context: {company: this.company_id, function:C_PROPERTY_NAVIGATION_EMAIL_TEMPLATES_V,  key: AC_PROPERTY_NAVIGATION}
                        },
                        {
                            route: {name: routeNames.RN_PROPERTY_DEFAULT_RATES_RESTRICTIONS},
                        },
                        {
                            route: {name: routeNames.RN_PROPERTY_SETTINGS},
                        },
                    ]
                }
            ]
        },
    },
    methods: {
        createdReservation(reservation) {
            let unitId =  reservation.unit.parent ? reservation.unit.parent : reservation.unit.id
            EventBus.$emit(GE_CALENDAR_REFRESH_DATA,{unitId})
            this.res_aside = false
        },
    },
    created() {
        this.access_control_general_context = {
            property: this.$route.params.id,
            company: this.$store.getters['user/getCompany']
        }
    },
    watch: {
        '$route.params.id': {
            async handler(id) {

                await this.$store.dispatch('property/actionSetProperty', id);

                // await validateMonthlyReport(id).then(response => {
                //     this.monthlyReportHidden = !response.data
                // }, () => {
                //     this.monthlyReportHidden = true
                // })
            },
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
