import {
  getPropertyOwners,
  getOwner,
  getOwnerContactList,
  createOwner,
  updateOwner,
  deleteOwner,
  updateOwnerContact,
  deleteOwnerContact,
  createOwnerUser,
  resetOwnerUser,
  getNewOwnerContactList,
  getContactTypeList
} from "@/services/owner/index";
import {getContactChannelList} from "@/services/owner";

const state = {
  list: [],
  owner: {},
  contactList: [],
  newContactList: [],
  contactTypeList: [],
  contactChannelList:[],
}

const getters = {
  getOwnerList(state) {
    return state.list
  },
  getOwner(state) {
    return state.owner
  },
  getOwnerContactList(state) {
    return state.contactList
  },
  getOwnerNewContactList(state) {
    return state.newContactList
  },
  getContactTypeList(state) {
    return state.contactTypeList
  },
  getContactChannelList(state){
    return state.contactChannelList
  },

}

const mutations = {
  setOwnerList(state, payload) {
    state.list = payload
  },
  setOwner(state, payload) {
    state.owner = payload
  },
  setOwnerContactList(state, payload) {
    state.contactList = payload
  },
  setOwnerNewContactList(state, payload) {
    state.newContactList = payload
  },
  setContactTypeList(state, payload){
    state.contactTypeList = payload
  },
  setContactChannelList(state, payload){
    state.contactChannelList = payload
  },

}


const actions = {
  actionSetOwnerList({commit}, id) {
    return new Promise((resolve, reject) => {
      getPropertyOwners(id).then(response => {
        commit('setOwnerList', response.data)
        resolve(response);
      }, error => {
        reject(error);
      })
    })
  },
  actionSetOwner({commit}, payload) {
    return new Promise((resolve, reject) => {
      getOwner(payload.propertyId, payload.ownerId).then(response => {
        commit('setOwner', response.data)
        resolve(response);
      }, error => {
        reject(error);
      })
    })
  },
  actionUpdateOwner({commit}, payload) {
    return new Promise((resolve, reject) => {
      updateOwner(payload.propertyId, payload.ownerId, payload.data).then(response => {
        commit('setOwner', response.data)
        resolve(response);
      }, error => {
        reject(error);
      })
    })
  },
  actionCreateOwner({commit}, payload) {
    return new Promise((resolve, reject) => {
      createOwner(payload.propertyId, payload.data).then(response => {
        commit('setOwner', response.data)
        resolve(response);
      }, error => {
        reject(error);
      })
    })
  },
  actionDeleteOwner({commit}, payload) {
    return new Promise((resolve, reject) => {
      deleteOwner(payload.propertyId, payload.ownerId).then(response => {
        commit('setOwner', response.data)
        resolve(response);
      }, error => {
        reject(error);
      })
    })
  },
  actionSetOwnerContactList({commit}, payload) {
    return new Promise((resolve, reject) => {
      getOwnerContactList(payload.propertyId, payload.ownerId).then(response => {
        commit('setOwnerContactList', response.data)
        resolve(response);
      }, error => {
        reject(error);
      })
    })
  },
  actionUpdateOwnerContact({commit}, payload) {
    return new Promise((resolve, reject) => {
      updateOwnerContact(payload.propertyId, payload.ownerId, payload.contactTypeId, payload.data).then(response => {
        commit('setOwnerContactList', response.data)
        resolve(response);
      }, error => {
        reject(error);
      })
    })
  },
  actionDeleteOwnerContact({commit}, payload) {
    return new Promise((resolve, reject) => {
      deleteOwnerContact(payload.propertyId, payload.ownerId, payload.contactTypeId).then(response => {
        commit('setOwnerContactList', response.data)
        resolve(response);
      }, error => {
        reject(error);
      })
    })
  },
  actionCreateOwnerUser({commit}, payload) {
    return new Promise((resolve, reject) => {
      createOwnerUser(payload.propertyId, payload.ownerId, payload.data).then(response => {
        commit('setOwner', response.data)
        resolve(response);
      }, error => {
        reject(error);
      })
    })
  },
  actionResetOwnerUser({commit}, payload) {
    return new Promise((resolve, reject) => {
      resetOwnerUser(payload.propertyId, payload.ownerId).then(response => {
        commit('setOwner', response.data)
        resolve(response);
      }, error => {
        reject(error);
      })
    })
  },
  actionSetNewOwnerContactList({commit}, payload) {
    return new Promise((resolve, reject) => {
      getNewOwnerContactList(payload).then(response => {
        commit('setOwnerNewContactList', response.data)
        resolve(response);
      }, error => {
        reject(error);
      })
    })
  },
  actionSetContactTypeList({commit}, payload){
    return new Promise((resolve, reject) => {
      getContactTypeList(payload).then(response => {
        commit('setContactTypeList', response.data)
        resolve(response);
      }, error => {
        reject(error);
      })
    })
  },
  actionSetContactChannelList({commit}, payload){
    return new Promise((resolve, reject) => {
      getContactChannelList(payload).then(response => {
        commit('setContactChannelList', response.data)
        resolve(response);
      }, error => {
        reject(error);
      })
    })
  },


}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
