<template>
    <b-form @submit.prevent="saveBookingSite">
        <b-row>
            <b-col sm="6">
                <b-form-group>
                    <label>{{$t("USERNAME")}}</label>
                    <b-form-input v-model="username" type="text" id="username"></b-form-input>
                </b-form-group>
            </b-col>

            <b-col sm="6">
                <b-form-group>
                    <label>{{$t("PASSWORD")}}</label>
                    <b-form-input v-model="password" type="text" id="password"></b-form-input>
                </b-form-group>
            </b-col>

            <b-col sm="12">
                <b-form-group>
                    <label>{{$t("URL")}}</label>
                    <b-form-input v-model="url" type="text" id="url"></b-form-input>
                </b-form-group>
            </b-col>

            <b-col sm="12">
                <b-form-group>
                    <label>{{$t("DESCRIPTION")}}</label>
                    <b-form-textarea v-model="description" :rows="3"></b-form-textarea>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col sm="6">
                <booking-site-submit :loading="saveState"></booking-site-submit>
            </b-col>
            <b-col sm="6">
                <app-button-delete @click="showDeleteDialog=true" :loading="deleteState" class="pull-right"></app-button-delete>

            </b-col>
        </b-row>
        <site-delete-dialog :show="showDeleteDialog" @confirm="deleteBookingSite"
                            @cancel="showDeleteDialog=false" :delete_title="true"></site-delete-dialog>
    </b-form>
</template>

<script>
    import BookingSiteSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import BookingSiteDelete from '@/components/app/AppDelete'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {toast} from '@/shared/plugins/toastr'
    import SiteDeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import {deletePropertyBookingSites, updatePropertyBookingSites} from '@/services/property/index'
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";

    export default {
        name: "PropertyBookingSiteForm",
        components: {
            AppButtonDelete,
            BookingSiteSubmit, BookingSiteDelete, SiteDeleteDialog
        },
        mixins: [getErrorMessage],
        props: ["siteItem"],
        data() {
            return {
                page: this.siteItem.page,
                username: this.siteItem.username,
                password: this.siteItem.password,
                url: this.siteItem.url,
                description: this.siteItem.description,

                saveState: false,
                showDeleteDialog: false,
                deleteState: false
            }
        },
        methods: {
            saveBookingSite() {
                this.saveState = true
                updatePropertyBookingSites(this.$route.params.id, {
                    data: [{
                        id: this.siteItem.id,
                        page: this.page,
                        username: this.username,
                        password: this.password,
                        url: this.url,
                        description: this.description
                    }]
                }).then((response) => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })

                        this.$emit('siteSaved', response.data)
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }
                ).finally(this.saveState = false)
            },

            deleteBookingSite() {
                this.deleteState = true
                this.showDeleteDialog = false
                deletePropertyBookingSites(this.$route.params.id, {id: this.siteItem.id}).then((response) => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.DELETE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.DELETE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })

                        this.$emit('siteSaved', response.data)
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }
                ).finally(this.deleteState = false)
            }
        }
    }
</script>

<style scoped>

</style>
