
import {
  getDistributionList,
} from "@/services/distribution/index";
import {normalize, schema} from "normalizr";

const state = {
  data: {
    entities: {
      distribution: {
          id:null,
          active:null
      },
    },
    result: {
      distribution: [
      ],
    }
  }
}

const getters = {
  getDistributionList(state) {
    return state.data.result.distribution
  },
  getDistributionById:(state) => (id) =>{
    return state.data.entities.distribution[id]
  }
}

const mutations = {
  setSchema(state, {data, schema}) {
    state.data.entities[schema] = data.entities[schema]
    state.data.result[schema] = data.result[schema]
  },
    setDistributionActive(state,{id, value}){
      state.data.entities.distribution[id].active = value
    }
}


const actions = {
  actionSetFilterDistributionList({commit}, payload) {
    return new Promise((resolve, reject) => {
      getDistributionList(payload)
        .then(response => {
            const distribution = new schema.Entity('distribution');
            const distributionSchema = {distribution: [distribution]}
            const normalizedData = normalize({distribution: response.data}, distributionSchema);
            commit('setSchema', {data: normalizedData, schema: 'distribution'})
            resolve(response)
          },
          error => {
            reject(error)
          })
    })
  },

}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
