<template>
    <b-form>
        <b-row class="mb-4">
            <b-col>
                <app-translation-input
                    :label="$t('NAME')"
                    :context-label="$t('AMENITY')"
                    v-model="amenity.label"
                    :languages="amenity.supportedLanguages"
                    :lang_id="amenity.lang_id">
                </app-translation-input>
            </b-col>
        </b-row>

        <b-row class="mb-4">
            <b-col>
                <app-button class="mb-4" @click="saveAmenity" :loading="loading">{{ $t('SAVE') }}</app-button>
                <app-button-delete class="mb-4 pull-right" @click="displayConfirmationDialog=true" :loading="deleteItem">{{
                        $t('DELETE')
                    }}
                </app-button-delete>
            </b-col>
        </b-row>

        <!--         TODO maknut table head    -->
        <table style="width: 100%" class="table">
            <thead>
            <tr>
                <th>{{ $t('MAPPING') }}</th>
            </tr>
            </thead>
        </table>


        <b-row class="mb-4">
            <b-col>
                <app-item-list v-for="map in amenity.mapping" :key="map.id"
                               :item-list="true" :item-object="map" v-if="map.deleted===0"
                               @deleteItem="deleteMapping"
                ></app-item-list>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="8">
                <app-select class="mb-4" mode="search" :search="searchAmenity" v-model="newAmenity">
                    <template v-slot:text="data">
                        {{ data.item.label }} {{ data.item.mapped }}
                    </template>
                </app-select>
            </b-col>
            <b-col cols="4">
                <app-button button_type="new"
                            @click="addAmenity"
                            class="pull-right">
                    {{ $t('ADD_ITEM') }}
                </app-button>
            </b-col>
        </b-row>
        <app-button @click="saveMapping" :loading="saveMapp">{{ $t('SAVE') }}</app-button>

        <app-confirmation-dialog :show="displayConfirmationDialog"
                                 :delete_title="true"
                                 @confirm="deleteAmenity"
                                 @cancel="displayConfirmationDialog = false">
        </app-confirmation-dialog>
    </b-form>
</template>

<script>
    import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
    import {getAmenity, getAmenityMapping, mapAmenity, updateAmenity} from "@/services/direct_booking/website";
    import {getTranslateList} from "@/services/system";
    import AppItemList from "@/components/app/AppItemList";
    import AppSearch from "@/components/app/search/AppSearch";
    import AppSearchData from "@/components/app/AppSearchData";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import http from "@/services/http";
    import {DB_WEBSITE_URL} from "@/services/endpoints";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";

    export default {
        name: "WebsiteAmenity",
        mixins: [getErrorMessage],
        components: {
            AppConfirmationDialog,
            AppButtonDelete, AppButton, AppSelect, AppSearchData, AppSearch, AppItemList, AppTranslationInput},
        props: {
            amenityId: {
                type: Number,
                default: null,
            },
            websiteId: {
                type: Number,
                default: null,
            },
            categoryId: {
                type: Number,
                default: null,
            }
        },
        data() {
            return {
                amenity: {},
                newAmenity: {},
                loading: false,
                deleteItem: false,
                saveMapp: false,
                displayConfirmationDialog: false
            }
        },
        methods: {
            getData() {
                getAmenity(this.websiteId, this.categoryId, this.amenityId).then(response => {
                    this.amenity = response.data
                })
            },
            deleteAmenity() {
                this.deleteItem = true
                this.amenity.deleted = 1
                updateAmenity(this.websiteId, this.categoryId, this.amenityId, this.amenity).then(() => {
                    notifySuccess()
                    this.$emit('saved')
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => this.deleteItem = false)
            },
            deleteMapping(data) {
                let index = this.amenity.mapping.findIndex(item => item.id === data.id);
                this.amenity.mapping[index].deleted = 1;
                this.amenity.mapping[index].edited = 1;

                if (data.new && data.new === 1) {
                    this.amenity.mapping = this.amenity.mapping.filter(item =>
                        item.id !== data.id
                    )
                }
            },
            searchAmenity(value) {
                return getAmenityMapping(this.websiteId, {name: value})
            },
            addAmenity() {
                let search = this.amenity.mapping.find(el => el.amenity_id === this.newAmenity.id)

                if (!search) {
                    let newAmenity = {
                        id: 'index_' + this.amenity.mapping.length,
                        amenity_id: this.newAmenity.id,
                        amenity_eid: this.amenity.id,
                        label: this.newAmenity.label,
                        distribution_manager_id: this.website.distribution.id,
                        deleted: 0,
                        edited: 1,
                        new: 1
                    }
                    this.amenity.mapping.push(newAmenity)
                }
                this.newAmenity = {}
            },
            saveMapping() {
                this.saveMapp = true;
                let filteredAmenities = this.amenity.mapping.filter(items => items.edited && items.edited === 1)
                mapAmenity(this.websiteId, {list: filteredAmenities}).then(() => {
                    notifySuccess()
                    this.$emit('saved')
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => this.saveMapp = false)

            },
            saveAmenity() {
                this.loading = true;
                updateAmenity(this.websiteId, this.categoryId, this.amenityId, this.amenity).then(() => {
                    notifySuccess()
                    this.$emit('saved')
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => this.loading = false)
            }
        },
        computed: {
            website() {
                return this.$store.getters['website/getWebsite']
            },
        },
        created() {
            this.getData()
        }
    }
</script>

<style scoped>

</style>
