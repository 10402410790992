<template>
    <b-card v-cloak class="card action-section-card">
        <template slot="header">
            <h2>{{ $t('BOOKING_WINDOWS') }}</h2>
            <AppButton
                v-if="list.length > 0"
                button_type="new"
                variant="primary"
                @click="openAside"
                class="pull-right">{{$t('NEW_BOOKING_WINDOW_SETUP')}}
            </AppButton>
        </template>

        <revenue-manager-setup-booking-window-table class="mt-4" @data="setList"></revenue-manager-setup-booking-window-table>
        <app-aside v-model="aside">
            <template v-slot:header>
                {{header}}
            </template>
            <revenue-manger-booking-window-form @changeHeader="updateHeader" :type_booking_window="type" mode="new"></revenue-manger-booking-window-form>
        </app-aside>

    </b-card>
</template>

<script>
import RevenueManagerSetupBookingWindowTable
    from "@/components/revenue_manager/type/RevenueManagerSetupBookingWindowTable";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import RevenueMangerBookingWindowForm
    from "@/components/revenue_manager/booking_window/RevenueManagerBookingWindowForm";

export default {
name: "Index",
    data(){
        return {
            aside:false,
            type:null,
            list:[],
            header:this.$t('NEW_BOOKING_WINDOW_SETUP')
        }
    },
    methods:{
        setList(value){
            this.list = value
        },
        updateHeader(){
          this.header = this.$t('BOOKING_WINDOW_SETUP')
        },
        openAside(){
            this.aside=true;
            this.header = this.$t('NEW_BOOKING_WINDOW_SETUP')
        }
    },
    components: {RevenueManagerSetupBookingWindowTable, AppButton, AppAside, RevenueMangerBookingWindowForm},
    watch:{
        '$route.query.type':{
            handler(value){
                if(value){
                    this.$nextTick(()=>{
                        this.type = value
                        this.aside = true
                    })
                }
            },immediate:true
        }
    }
}
</script>

<style scoped>

</style>
