<template>

    <div v-if="ratePlans !== null">
        <form @submit.prevent="save">
            <b-row class="mb-4">
                <b-col lg="6">
                    <label>
                        {{$t("RATE_PLAN")}}
                    </label>
                    <app-select v-model="rate_plan_id" :options="ratePlansOptGroup">
                    </app-select>
                </b-col>
            </b-row>
            <b-row class="mb-4" :key="restriction.id" v-for="(restriction) in restrictions">
                <b-col :lg="closedOnArray.includes(restriction.id) ? 12 : 6">
                    <component :disabled="disabledRestriction(restriction.id)"
                               @input="setUpdate($event, restriction.id)"
                               v-if="fields.hasOwnProperty(restriction.id)"
                               :restriction="restriction"
                               v-model="fields[restriction.id]['value']"
                               :allowed_values="restriction.allowed_values.allowed_values"
                               :has_tooltip_message="disabledRestriction(restriction.id)" :message="$t('RESTRICTION_NOT_ALLOWED_CHANGE',{rate_plan_name: parentRatePlanName})"
                               :is="restriction.allowed_values.element_type + 'Element'"></component>

                </b-col>
                <b-col lg="6"  :class="closedOnArray.includes(restriction.id) ?'mt-4' : ''"  v-if="changes.includes(restriction.id) && filteredRatePlanListByRestriction(restriction.id).length > 0">
                    <label>{{$t("APPLY_TO_RATE_PLANS")}}</label>
                    <app-select  :disabled="rate_plan_id === null" mode="multiselect" :value="getRatePlanRestrictionValue(restriction.id)" @input="updateRatePlanRestriction($event,restriction.id)"
                                :options="filteredRatePlanListByRestriction(restriction.id)" value-field="id" text-field="name"></app-select>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <app-submit :loading="loading_btn" :c_permission="c_edit"
                                :disabled="rate_plan_id === null || dateRanges.length === 0 || !changesValid"></app-submit>
                </b-col>
            </b-row>
        </form>
    </div>

</template>

<script>

    import SelectRestrictionFormElement from '@/components/unit/calendar/forms/restriction/SelectRestrictionFormElement'
    import CheckboxRestrictionFormElement
        from '@/components/unit/calendar/forms/restriction/CheckboxRestrictionFormElement'
    import AppSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import AppValidationDescription from '@/components/app/form/AppValidationDescription'
    import AppDropdownCheckbox from '@/components/app/form/AppDropdownCheckbox'

    import {
        getUnitCalendarValues,
        setUnitRestrictions,
    } from "@/services/unit";
    import {normalize, schema} from "normalizr";
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {toast} from '@/shared/plugins/toastr'
    import moment from 'moment'
    import {C_PROPERTY_CALENDAR_RESTRICTIONS_E} from "@/shared/component_permission";
    import {
        SALES_ENABLED,
        CLOSED_ON_ARRIVAL,
        CLOSED_ON_DEPARTURE,
        MIN_STAY,
        MAX_STAY,
        RELEASE_PERIOD, SYSTEM_STANDARD_RATE
    } from "@/shared/constants";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {each, has, get, cloneDeep, values} from 'lodash'
    import {
        EventBus,
        GE_CALENDAR_LOAD_RESTRICTIONS,
        GE_LOADER_HIDE,
        GE_LOADER_SHOW
    } from "@/shared/EventBus";


    export default {
        name: "CalendarAsideRestrictions",
        components: {
            AppSelect,
            'selectElement': SelectRestrictionFormElement,
            'checkboxElement': CheckboxRestrictionFormElement,
            AppSubmit,
            AppValidationDescription,
            AppDropdownCheckbox
        },
        mixins: [getErrorMessage],
        props: {
            ratePlans: {
                type: Array,
                default (){
                    return []
                }
            },
            ratePlan: {
                type: Number,
                default: null
            },
            unit: {
                type: Object
            },
            dateRanges: {
                type: Array
            },
            defaultValues: {
                type: Object,
                default: null
            },
            has_tooltip_message:{
                type:Boolean,
                default:false
            },
            message:{
                type:String,
            },
            allowedWeekday:{
                type:Array
            }
        },
        data() {
            return {
                rate_plan_id: null,
                closedOnArray: [
                    CLOSED_ON_ARRIVAL,
                    CLOSED_ON_DEPARTURE,
                ],
                ratePlanObject:null,
                fields: {},
                fields_change: {},
                changes: [],
                loading_btn: false,
                restriction_keys: [],
                formEdited: false,
                min_stay_rate:[],
                max_stay_rate:[],
                release_period_rate:[],
                closed_on_arrival_rate:[],
                closed_on_departure_rate:[],

                add_rate_plans: {
                },
                c_edit: C_PROPERTY_CALENDAR_RESTRICTIONS_E,
                restrictionDefaultValues: {
                    MIN_STAY: {
                        id: MIN_STAY,
                        value: null,
                    },
                    MAX_STAY: {
                        id: MAX_STAY,
                        value: null,
                    },
                    RELEASE_PERIOD: {
                        id: RELEASE_PERIOD,
                        value: null,
                    },
                    CLOSED_ON_ARRIVAL: {
                        id: CLOSED_ON_ARRIVAL,
                        value: null,
                    },
                    CLOSED_ON_DEPARTURE: {
                        id: CLOSED_ON_DEPARTURE,
                        value: null,
                    },

                },
                min_stay_rate_plan_options:[],
                max_stay_rate_plan_options:[],
                release_period_rate_plan_options:[],
                closed_on_departure_rate_plan_options:[],
                closed_on_arrival_rate_plan_options:[]
            }
        },
        methods: {
            resetList(){
                this.min_stay_rate_plan_options =[],
                    this.max_stay_rate_plan_options =[],
                    this.release_period_rate_plan_options =[],
                    this.closed_on_departure_rate_plan_options =[]
                this.closed_on_arrival_rate_plan_options =[]
                this.min_stay_rate = []
                this.max_stay_rate = []
                this.release_period_rate = []
                this.closed_on_arrival_rate = []
                this.closed_on_departure_rate = []
                this.changes = []
            },
            getRatePlanRestrictionValue(restriction_id){
                if(restriction_id === MIN_STAY){
                    return  this.min_stay_rate
                }
                if(restriction_id === MAX_STAY){
                    return  this.max_stay_rate
                }
                if(restriction_id === RELEASE_PERIOD){
                    return  this.release_period_rate
                }
                if(restriction_id === CLOSED_ON_ARRIVAL){
                    return  this.closed_on_arrival_rate
                }
                if(restriction_id === CLOSED_ON_DEPARTURE){
                    return  this.closed_on_departure_rate
                }
            },
            updateRatePlanRestriction(value,restriction_id){

                if(restriction_id === MIN_STAY){

                    return  this.min_stay_rate = value
                }
                if(restriction_id === MAX_STAY){
                    return  this.max_stay_rate = value
                }
                if(restriction_id === RELEASE_PERIOD){
                    return  this.release_period_rate = value
                }
                if(restriction_id === CLOSED_ON_ARRIVAL){
                    return  this.closed_on_arrival_rate = value
                }
                if(restriction_id === CLOSED_ON_DEPARTURE){
                    return  this.closed_on_departure_rate = value
                }
            },
            setUpdate(value, restrictionId) {
                if (value === null && restrictionId !== MAX_STAY) {
                    let index = this.changes.findIndex(restrictionId)
                    if (index >= 0) {
                        this.changes.splice(index, 1)
                    }
                } else {
                    if (!this.changes.includes(Number(restrictionId))) {
                        this.changes.push(Number(restrictionId))
                    }
                }
            },
            formatFields() {
                this.fields = {}
                if(this.restrictions && typeof this.restrictions === 'object' && Object.keys(this.restrictions).length > 0){
                    for (let itemId in this.restrictions) {
                        if (this.restrictions.hasOwnProperty(itemId)) {
                            if (itemId == CLOSED_ON_ARRIVAL || itemId == CLOSED_ON_DEPARTURE) {
                                this.$set(this.fields, itemId, {id: itemId, value: "0"})
                            } else {
                                this.$set(this.fields, itemId, {id: itemId, value: null})
                            }

                        }
                    }
                }

            },
            filteredRatePlanListByRestriction(restriction_id){

                if(typeof this.defaultValues !== 'object' && !this.defaultValues.hasOwnProperty('additional_data')){
                    return  []
                }
                if(restriction_id === MIN_STAY){
                    return  this.min_stay_rate_plan_options
                }
                if(restriction_id === MAX_STAY){
                    return  this.max_stay_rate_plan_options
                }
                if(restriction_id === RELEASE_PERIOD){

                    return  this.release_period_rate_plan_options
                }
                if(restriction_id === CLOSED_ON_ARRIVAL){

                    return  this.closed_on_arrival_rate_plan_options
                }
                if(restriction_id === CLOSED_ON_DEPARTURE){
                    return  this.closed_on_departure_rate_plan_options
                }

            },
            setDefaultList(defaultValues){
                if(typeof defaultValues !== 'object' &&!defaultValues.hasOwnProperty('additional_data')){
                    return  []
                }
                const restrictions = this.$store.getters[`shared/getRestrictions`]
                if(typeof restrictions === 'object' && Object.keys(restrictions).length > 0){
                    for (const [key, restriction] of Object.entries(restrictions)) {
                        if(restriction.id === MIN_STAY){
                            this.min_stay_rate_plan_options = this.mapRatePlans(defaultValues.additional_data.min_stay.available_rate_plan_list)
                            this.min_stay_rate = defaultValues.additional_data.min_stay.checked ? defaultValues.additional_data.min_stay.available_rate_plan_list.map(el =>{
                                return el.id
                            }) : []
                        }
                        if(restriction.id === MAX_STAY){
                            this.max_stay_rate_plan_options = this.mapRatePlans(defaultValues.additional_data.max_stay.available_rate_plan_list)
                            this.max_stay_rate = defaultValues.additional_data.max_stay.checked ? defaultValues.additional_data.max_stay.available_rate_plan_list.map(el=>{
                                return el.id
                            }) : []

                        }
                        if(restriction.id === RELEASE_PERIOD){
                            this.release_period_rate_plan_options = this.mapRatePlans(defaultValues.additional_data.release_period.available_rate_plan_list)
                            if(defaultValues.additional_data.release_period.checked){
                                this.release_period_rate = []
                                this.release_period_rate  = defaultValues.additional_data.release_period.available_rate_plan_list.map(el=>{
                                    return el.id
                                })
                            } else if(defaultValues.additional_data.release_period.checked_standard_rate_plan){
                                this.release_period_rate = []
                                this.release_period_rate =  defaultValues.additional_data.release_period.available_rate_plan_list.filter(el=> el.type === SYSTEM_STANDARD_RATE).map(el=>{
                                    return  el.id
                                })
                            }

                        }
                        if(restriction.id === CLOSED_ON_ARRIVAL){
                            this.closed_on_arrival_rate_plan_options = this.mapRatePlans(defaultValues.additional_data.closed_on_arrival.available_rate_plan_list)
                            this.closed_on_arrival_rate = defaultValues.additional_data.closed_on_arrival.checked ? defaultValues.additional_data.closed_on_arrival.available_rate_plan_list.map(el=>{
                                return el.id
                            }) : []
                        }
                        if(restriction.id === CLOSED_ON_DEPARTURE){
                            this.closed_on_departure_rate_plan_options = this.mapRatePlans(defaultValues.additional_data.closed_on_departure.available_rate_plan_list)
                            this.closed_on_departure_rate = defaultValues.additional_data.closed_on_departure.checked ? defaultValues.additional_data.closed_on_departure.available_rate_plan_list.map(el=>{
                                return el.id
                            }) : []
                        }

                    }
                }

            },
            mapRatePlans(ratePlans) {

                if (ratePlans.length > 0) {
                    return ratePlans.map(el => {
                        return {
                            id: el.id,
                            name: el.distribution.name ? el.name + ' (' + el.distribution.name + ')' : el.name
                        }
                    })
                }
                return []
            },
            save() {
                if (!this.changes.length) {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NO_CHANGE'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }
                let restrictionItems = []

                for (let itemId in this.fields) {
                    if (this.changes.includes(Number(itemId)) && this.fields.hasOwnProperty(itemId)){
                        if(Number(itemId) === MIN_STAY){
                            restrictionItems.push({
                                rate_plan: this.rate_plan_id,
                                restriction: itemId,
                                value: this.fields[itemId].value
                            })

                            if (this.min_stay_rate.length > 0) {
                                this.min_stay_rate.forEach(rateId => {
                                    restrictionItems.push({
                                        rate_plan: rateId,
                                        restriction: itemId,
                                        value: this.fields[itemId].value
                                    })
                                })
                            }
                        }
                        if(Number(itemId) === MAX_STAY){
                            restrictionItems.push({
                                rate_plan: this.rate_plan_id,
                                restriction: itemId,
                                value: this.fields[itemId].value
                            })

                            if (this.max_stay_rate.length > 0) {
                                this.max_stay_rate.forEach(rateId => {
                                    restrictionItems.push({
                                        rate_plan: rateId,
                                        restriction: itemId,
                                        value: this.fields[itemId].value
                                    })
                                })
                            }
                        }

                        if(Number(itemId) === RELEASE_PERIOD){
                            restrictionItems.push({
                                rate_plan: this.rate_plan_id,
                                restriction: itemId,
                                value: this.fields[itemId].value
                            })

                            if (this.release_period_rate.length > 0) {
                                this.release_period_rate.forEach(rateId => {
                                    restrictionItems.push({
                                        rate_plan: rateId,
                                        restriction: itemId,
                                        value: this.fields[itemId].value
                                    })
                                })
                            }
                        }

                        if(Number(itemId) === CLOSED_ON_ARRIVAL){
                            restrictionItems.push({
                                rate_plan: this.rate_plan_id,
                                restriction: itemId,
                                value: this.fields[itemId].value
                            })

                            if (this.closed_on_arrival_rate.length > 0) {
                                this.closed_on_arrival_rate.forEach(rateId => {
                                    restrictionItems.push({
                                        rate_plan: rateId,
                                        restriction: itemId,
                                        value: this.fields[itemId].value
                                    })
                                })
                            }
                        }
                        if(Number(itemId) === CLOSED_ON_DEPARTURE){
                            restrictionItems.push({
                                rate_plan: this.rate_plan_id,
                                restriction: itemId,
                                value: this.fields[itemId].value
                            })

                            if (this.closed_on_departure_rate.length > 0) {
                                this.closed_on_departure_rate.forEach(rateId => {
                                    restrictionItems.push({
                                        rate_plan: rateId,
                                        restriction: itemId,
                                        value: this.fields[itemId].value
                                    })
                                })
                            }
                        }

                    }
                }

                let request = {
                    restrictions: [],
                    allowed_weekday:this.allowedWeekday
                }

                this.dateRanges.forEach(periodItem => {
                    request.restrictions.push({
                        date_from: periodItem.start,
                        date_to: periodItem.end,
                        restrictions: restrictionItems
                    })
                })

                this.loading_btn = true
                setUnitRestrictions(this.unit.id, request).then(() => {

                        this.changes = []
                        EventBus.$emit(GE_CALENDAR_LOAD_RESTRICTIONS, {
                            unitId: this.unit.id,
                            ratePlan: this.rate_plan_id
                        })
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })

                        this.loading_btn = false

                    },
                    error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                        this.loading_btn = false
                    })
            },
            filterRatePlanChildren(parentRatePlanId = null) {

                let finalArray = this.ratePlans.filter(rPlan => rPlan.parent_rate_plan === parentRatePlanId).map(item => {
                    return {
                        id: item.id,
                        apply_min_stay: item.apply_min_stay,
                        label: item.name + (item.distribution_name ? ` (${item.distribution_name})` : ''),
                    }
                })

                if (finalArray.length) {
                    finalArray.forEach(rPlanRec => {
                        finalArray = [...finalArray, ...this.filterRatePlanChildren(rPlanRec.id)]
                    })
                }

                return finalArray



            },
            disabledRestriction(restriction_id) {
                if (this.rate_plan_id === null) {
                    return true
                }
                if (this.ratePlanObject) {
                    if (restriction_id === MIN_STAY) {
                        return this.ratePlanObject.inherit_minimum_stay
                    }
                    if (restriction_id === MAX_STAY) {
                        return this.ratePlanObject.inherit_maximum_stay
                    }
                    if (restriction_id === RELEASE_PERIOD) {
                        return this.ratePlanObject.inherit_release_period
                    }
                    if (restriction_id === CLOSED_ON_ARRIVAL) {
                        return this.ratePlanObject.inherit_check_in_days
                    }
                    if (restriction_id === CLOSED_ON_DEPARTURE) {
                        return this.ratePlanObject.inherit_check_out_days
                    }
                }


                return false
            }
        },
        computed: {
            ratePlanChildren() {
                let referenceArray = []

                if (this.rate_plan_id === this.unit.primary_rate_plan.id) {
                    referenceArray = this.filterRatePlanChildren(null)
                } else {
                    referenceArray = this.filterRatePlanChildren(this.rate_plan_id)
                }


                return referenceArray.filter(rPlan => rPlan.id !== this.rate_plan_id)
            },
            parentRatePlanName(){

                return this.defaultValues && typeof  this.defaultValues === 'object' && this.defaultValues.hasOwnProperty('rate_plan') && this.defaultValues.rate_plan &&  this.defaultValues.rate_plan.parent_rate_plan && this.defaultValues.rate_plan.parent_rate_plan.hasOwnProperty('name') ?  this.defaultValues.rate_plan.parent_rate_plan.name :  null
            },
            changesValid() {
                let val = false
                if (typeof this.fields === 'object' && Object.keys(this.fields).length > 0) {
                    Object.keys(this.fields).forEach(restrictionId => {
                        if (restrictionId == CLOSED_ON_ARRIVAL || restrictionId == CLOSED_ON_DEPARTURE) {
                            if (this.fields[restrictionId].value != 0) {
                                val = true
                            }
                        } else {
                            if (this.fields[restrictionId].value !== null) {
                                val = true
                            }
                        }
                    })
                }

                return val
            },

            restrictions() {
                const restrictionList = this.$store.getters[`shared/getRestrictions`]
                let filtered = {}
                if(typeof restrictionList === 'object' && Object.keys(restrictionList).length > 0){
                    for (const restrId in restrictionList) {
                        if (Number(restrId) === SALES_ENABLED || !restrictionList.hasOwnProperty(restrId)) {
                            continue
                        }
                        filtered[restrId] = restrictionList[restrId]
                    }
                }
                return filtered
            },

            ratePlansOptGroup() {
                let list = {}
                const other = this.$t("OTHER")

                if(this.ratePlans && this.ratePlans.length > 0){
                    this.ratePlans.forEach(currRatePlan => {
                        if (currRatePlan.distribution_name === null) {
                            if (!list.hasOwnProperty(other)) {
                                list[other] = []
                            }
                            list[other].push(currRatePlan)
                        } else {
                            if (!list.hasOwnProperty(currRatePlan.distribution_name)) {
                                list[currRatePlan.distribution_name] = []
                            }
                            list[currRatePlan.distribution_name].push(currRatePlan)
                        }
                    })
                }


                return list
            },

        },
        watch: {
            ratePlanChildren: {
                handler(list) {
                    this.add_rate_plans = list.filter(element => element.apply_min_stay === 1).map(element => element.id)
                },
                deep: true
            },
            restrictions: {
                handler(value) {
                    if (typeof value === 'object' && Object.keys(value).length > 0) {
                        this.formatFields()
                    }
                },
                deep: true,
                immediate: true
            },
            rate_plan_id: {
                handler(newval, oldval) {
                    this.add_rate_plans = []
                    this.resetList()

                    if(oldval && newval){
                        EventBus.$emit(GE_LOADER_SHOW)
                        getUnitCalendarValues(this.unit.id, {
                            periods: this.dateRanges,
                            rate_plan: newval,
                            show: ['restrictions','additional_data']
                        }).then(response => {
                            if (has(response, 'data.restriction')) {
                                this.restrictionDefaultValues = cloneDeep(response.data.restriction)
                            }
                            if(has(response, 'data.rate_plan')){
                                this.ratePlanObject = cloneDeep(response.data.rate_plan)
                            }
                            if(has(response,'data.additional_data')){
                                this.setDefaultList(response.data)
                            }

                        }).finally(() => {
                            EventBus.$emit(GE_LOADER_HIDE)
                        })
                    }
                }
            },

            defaultValues: {
                handler(defaultValue) {
                    if (defaultValue && defaultValue.hasOwnProperty('restriction')) {
                        this.restrictionDefaultValues = cloneDeep(defaultValue.restriction)
                    }
                    if(defaultValue && defaultValue.hasOwnProperty('rate_plan')){
                        this.ratePlanObject = cloneDeep(defaultValue.rate_plan)
                    }
                    if(defaultValue && defaultValue.hasOwnProperty('additional_data')){
                        this.setDefaultList(defaultValue)
                    }


                },
                immediate: true,
                deep: true
            },
            restrictionDefaultValues: {
                handler(values) {
                    each(values, (restriction, restrictionId) => {
                        if (has(this.fields, `${restrictionId}.value`)) {
                            this.fields[restrictionId]['value'] = restriction.value
                        }
                    })
                },
                immediate: true,
                deep: true
            }

        },
        created() {
            this.rate_plan_id = cloneDeep(this.ratePlan)

        }
    }
</script>

<style scoped>

</style>
