<template>
    <b-form @submit.prevent="save" v-if="checkPermission(C_USER_ROLES_V)">
        <b-row v-if="userData.role">
            <b-col class="mb-4">
                <b-form-checkbox-group
                    value-field="id"
                    text-field="name"
                    id="user_roles"
                    name="user_roles"
                    switches
                    stacked
                    v-model="roles"
                    :options="possibleRoles">
                </b-form-checkbox-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button-submit v-if="checkPermission(C_USER_ROLES_E)"
                                   :loading="updating"
                                   :disabled="!roles.length || disableSave">
                </app-button-submit>
            </b-col>
        </b-row>
        <app-confirmation-dialog
            v-if="userData !== null && userData.hasOwnProperty('account_manager')"
            :show="dialogState"
            @confirm="unlinkProperties"
            @cancel="dialogState = false; updating = false"
            :delete_title="true">
            {{ $t('ACCOUNT_MANAGER_ROLE_DELETE_DIALOG', {properties: userData.account_manager.properties_count}) }}
        </app-confirmation-dialog>
    </b-form>
</template>

<script>
    import AppButtonSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import {C_USER_ROLES_V} from "@/shared/component_permission";
    import {C_USER_ROLES_E} from '@/shared/component_permission'
    import FormHeader from "@/components/app/form/FormHeader";
    import {getPossibleRoles, updateUserRoles} from "@/services/user";
    import {cloneDeep} from "lodash";
    import {notifySuccess, toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {
        ROLE_ACCOUNT_MANAGER_NEW,
        ROLE_CHECKIN,
        ROLE_CLEANING,
        ROLE_PROPERTY_MANAGER_NEW,
        ROLE_PROPERTY_OWNER_NEW
    } from "@/shared/constants";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import {unlinkPropertiesAccountManager} from "@/services/acc_manager";

    export default {
        name: "UserRolesForm",
        mixins: [getErrorMessage],
        data() {
            return {
                roles: [],
                possibleRoles: [],
                C_USER_ROLES_V,
                C_USER_ROLES_E,
                userData: null,
                updating: false,
                changedRoles: [],
                dialogState: false
            }
        },
        props: {
            userProp: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        watch: {
            userProp: {
                handler(val) {
                    this.userData = cloneDeep(val);
                    this.roles = this.initialRoles();
                },
                deep: true,
                immediate: true
            },
            roles: {
                handler(value) {
                    if ((value.indexOf(ROLE_PROPERTY_MANAGER_NEW) > -1 || value.indexOf(ROLE_PROPERTY_OWNER_NEW)) > -1 && this.roles.length > 1) {
                        const pmIndex = value.indexOf(ROLE_PROPERTY_MANAGER_NEW)
                        const poIndex = value.indexOf(ROLE_PROPERTY_OWNER_NEW)
                        const onlyRole = pmIndex > poIndex ? ROLE_PROPERTY_MANAGER_NEW : ROLE_PROPERTY_OWNER_NEW

                        this.$nextTick(() => {
                            this.roles = this.roles.filter(el => {
                                return el === onlyRole
                            })
                        })
                    }
                }
            }
        },
        components: {
            AppConfirmationDialog,
            FormHeader,
            AppButtonSubmit
        },
        created() {
            this.getPossibleRolesList()
        },
        methods: {
            getPossibleRolesList() {
                getPossibleRoles({company_id: this.$store.getters[`user/getCompany`]}).then(res => {
                    this.possibleRoles = res.data
                })
            },
            save() {
                this.updating = true
                this.changedRoles = this.getChangedRoles();
                let propertyManager = this.changedRoles.find(el => el.id === ROLE_ACCOUNT_MANAGER_NEW);
                if (propertyManager && propertyManager.deleted === 1 && this.userData.hasOwnProperty('account_manager')) {
                    this.dialogState = true
                } else {
                    this.saveRoles()
                }
            },
            unlinkProperties() {
                unlinkPropertiesAccountManager(this.userData.account_manager.id).then(() => {
                    notifySuccess()
                    this.saveRoles()
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.updating = false;
                    this.dialogState = false
                })
            },
            saveRoles(){
                this.updating = true
                let data = {roles: JSON.stringify(this.changedRoles)};
                updateUserRoles(this.userData.id, data).then(res => {
                    this.$emit('update', res.data);
                    notifySuccess()
                }, (error) => {
                    this.roles = this.initialRoles();
                    this.showErrorMessages(error);
                }).finally(() => {
                    this.updating = false;
                })
            },
            initialRoles() {
                let roles = [];
                this.userData.role.forEach(function (role) {
                    roles.push(role.id);
                });
                return roles;
            },
            getChangedRoles() {
                let changedRoles = [];
                let initialRoles = this.initialRoles();
                let differences = initialRoles.filter(x => !this.roles.includes(x));
                differences.forEach(function (difference) {
                    changedRoles.push({id: difference, deleted: 1});
                });
                differences = this.roles.filter(x => !initialRoles.includes(x));
                differences.forEach(function (difference) {
                    changedRoles.push({id: difference, deleted: 0});
                });

                return changedRoles;
            }
        },
        computed:{
            disableSave() {
                return this.getChangedRoles().length === 0
            }
        }
    }
</script>

<style scoped>

</style>
