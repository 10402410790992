import http from "@/services/http";
import {CALCULATION_URL} from "@/services/endpoints";

export function getOwnerCalculationList(params) {
    return http.request({
        url: CALCULATION_URL,
        method: 'GET',
        params
    })
}

export function getCalculationStatusList(params) {
    return http.request({
        url: CALCULATION_URL + '/status',
        method: 'GET',
        params
    })
}

export function fetchOwnerCalculation(id) {
    return http.request({
        url: CALCULATION_URL + '/' + id,
        method: 'GET',
    })
}

export function updateOwnerCalculation(id, data) {
    return http.request({
        url: CALCULATION_URL + '/' + id,
        method: 'PUT',
        data
    })
}

export function downloadOwnerCalculationXLS(id) {
    return http.request({
        url: CALCULATION_URL + '/' + id + '/xls',
        method: 'GET',
        responseType: 'blob'
    })
}

export function groupNotifyCalculation(data) {
    return http.request({
        url: CALCULATION_URL + '/group-notify',
        method: 'POST',
        data
    })
}

export function updateCalculationStatus(statusString, id) {
    return http.request({
        url: `${CALCULATION_URL}/${id}/${statusString}`,
        method: 'PUT'
    })
}

export function createOwnerCalculation(data) {
    return http.request({
        url: `${CALCULATION_URL}/generate`,
        method: 'POST',
        data
    })
}
