<template>
    <div>
            <guest-form-evisitor  v-if="formData.connection_id === EVisitorCro"
                                  :formData="formData" :guestObject="guest" :save_loading = "save_loading" :checkin_loading="checkin_loading"
                                  @saveGuest="create"
                                  @registerGuest="register"
                                  @deleteGuest="deleteGuest"
                                  @showDeleteDialog="updateDeleteDialog"
                                  :show-delete-dialog="showDeleteDialog"
                                  :edit_guest="false">
            </guest-form-evisitor>
            <guest-form-id-reader v-else-if="formData.connection_id === IdReaderSrb"
                                  :formData="formData" :guestObject="guest"  :save_loading = "save_loading" :checkin_loading="checkin_loading"
                                  @saveGuest="create" @registerGuest="register"
                                  @deleteGuest="deleteGuest"
                                  @showDeleteDialog="updateDeleteDialog"
                                  :show-delete-dialog="showDeleteDialog"
                                  :edit_guest="false">
            </guest-form-id-reader>
            <guest-form-ajpes v-else-if="formData.connection_id === AjpesSlo"
                              :formData="formData" :guestObject="guest" :edit_guest="false"  :save_loading = "save_loading" :checkin_loading="checkin_loading"
                              @saveGuest="create"
                              @deleteGuest="deleteGuest"
                              @showDeleteDialog="updateDeleteDialog"
                              :show-delete-dialog="showDeleteDialog"
                              @registerGuest="register">
            </guest-form-ajpes>
    </div>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit"
    import TimePicker from "@/components/app/datetime/TimePicker"
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {createGuest, deleteGuest, updateGuest} from '@/services/guest/index'
    import {EVisitorCro, AjpesSlo, IdReaderSrb} from "@/shared/constants";
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import AppDatePicker from "@/components/app/datetime/AppDatePicker";
    import AppButton from "@/components/app/AppButton/AppButton"
    import GuestFormIdReader from "@/components/guest/forms/form_per_connection/GuestFormIdReader";
    import GuestFormAjpes from "@/components/guest/forms/form_per_connection/GuestFormAjpes";
    import GuestFormEvisitor from "@/components/guest/forms/form_per_connection/GuestFormEvisitor"
    import {cloneDeep} from "lodash";

    export default {
        name: "guest-new-form",
        props: {
            reservation: {
                type: Object,
                required: true
            },
            formData:{
                type:Object,
                required:true
            }
        },
        mixins: [getErrorMessage],
        data() {
            return {
                guest: {
                    id:null,
                    first_name: null,
                    last_name: null,
                    date_of_birth: null,
                    email: null,
                    phone: null,
                    check_in_date: null,
                    check_out_date: null,
                    check_in_time: null,
                    check_out_time: null,
                    country_of_birth: null,
                    country_of_residence: null,
                    city_of_residence: null,
                    citizenship: null,
                    gender: null,
                    document_type: null,
                    document_number: null,
                    arrival_organisation: null,
                    payment_category: null,
                    border_crossing: null,
                    reason_to_stay: null,
                    reservation: null,
                    reservation_data:{
                        unit:{
                            id:null,
                            name:null
                        },
                        distribution:{
                            id:null,
                            name:null
                        },
                        check_in_date:null,
                        check_out_date:null,
                        num_guest:null
                    }
                },
                initialGuestObject:{

                },
                save_loading:false,
                checkin_loading:false,
                showDeleteDialog: false,
                EVisitorCro,
                AjpesSlo,
                IdReaderSrb
            }
        },
        components: {
            GuestFormAjpes,
            GuestFormIdReader,
            GuestFormEvisitor,
            AppDatePicker, AppButtonSubmit, TimePicker, AppSelect, AppButton
        },
        methods: {
            updateDeleteDialog(value){
                this.showDeleteDialog = value
            },
            deleteGuest(id) {
                deleteGuest(id).then(() => {
                    this.guest = this.initialGuestObject
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.$emit('search')
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 3000
                    })
                }).finally(()=>{
                    this.showDeleteDialog = false
                })
            },
            create(data) {
                this.save_loading = true
                data.guestObject.register_flag = 0
                createGuest(data.guestObject).then(() => {
                    this.guest = this.initialGuestObject
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.$emit('search')
                    this.guest = cloneDeep(this.initialGuestObject)
                }, error => {
                    this.showErrorMessages(error, data.validation)

                }).finally(()=>{
                    this.save_loading = false
                })
            },
            register(data) {

                if(this.guest.id !== null){
                    let request  = data.guestObject
                    request.register_guest = 1
                    this.checkin_loading = true
                    updateGuest(this.guest.id, request).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                        this.guest = cloneDeep(this.initialGuestObject)
                        this.$emit('updateGuest')
                        this.$emit('search')
                    }, error => {
                        this.guest.id =  error.response.data.hasOwnProperty('data') && error.response.data.data.hasOwnProperty('id') ? error.response.data.data.id : null
                        this.showErrorMessages(error, data.validation)
                        if(this.guest.id !== null){
                            this.$emit('search')
                        }
                    }).finally(()=>{
                        this.checkin_loading = false
                    })
                } else {
                    this.checkin_loading = true
                    data.guestObject.register = 1
                    data.guestObject.register_flag =1
                    createGuest(data.guestObject).then(() => {
                        this.guest = this.initialGuestObject
                        this.checkin_loading = false
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                        this.guest = cloneDeep(this.initialGuestObject)
                        this.$emit('search')
                    }, error => {
                        this.guest.id =  error.response.data.hasOwnProperty('data') && error.response.data.data.hasOwnProperty('id') ? error.response.data.data.id : null
                        this.showErrorMessages(error, data.validation)
                        this.$emit('search')
                    }).finally(()=>{
                        this.checkin_loading = false
                    })
                }

            },
            setReservationData(value){
                this.guest.reservation = value.id
                this.guest.check_in_date = value.check_in_date
                this.guest.check_out_date = value.check_out_date
                this.guest.check_in_time = value.check_in_time
                this.guest.reservation_data.num_guest = value.adult_no
                this.guest.reservation_data.reservation_check_in_date = value.check_in_date
                this.guest.reservation_data.reservation_check_out_date = value.check_out_date
                this.guest.reservation_data.num_guest = value.adult_no + value.child_no + value.baby_no
                this.initialGuestObject = cloneDeep(this.guest)
            }
        },
        watch: {
            reservation: {
                handler(value) {
                    this.setReservationData(value)
                },
                immediate: true,
            }
        }
    }
</script>

<style scoped>

</style>
