<template>

    <div class="availability_calendar_wrapper" :class="{'availability_calendar_wrapper-loader':showLoading}" >

            <div ref="availability_unit_type" class="availability_property_wrapper availability_property_wrapper-full " v-if="showProperty" >
                <div class="availability_rows">
                    <property-side-bar-unit-item :showPropertyItem="showPropertyItem"   :display="display" :selected-units="selectedUnits"  :property="property" :dateRange="dateRange" @showContigent="showContigent" :key="unit.id" v-for="unit in units" :unit="unit">

                    </property-side-bar-unit-item>
                </div>
            </div>

        <div class="availability_wrapper"  ref="calendar_scroller">
            <div class="availability_rows">

                <property-calendar-item
                    :ac_response_value="ac_response_value"
                    :display="display"
                    :scrollLeftPosition="scrollLeftPosition"
                    :shiftKeyValue="shiftKeyValue"
                    :key="unit.id"
                    :unit="unit"
                    :dates="dateArray"
                    :unit-data="unit_data"
                    :reservations="reservations"
                    :showContigentList="objectList"
                    :property="property"
                    v-for="unit in units"></property-calendar-item>
            </div>
        </div>
    </div>

</template>

<script>
    import _ from 'lodash'
    import PropertyCalendarItem from "@/components/property/calendar/PropertyCalendarItem";
    import PropertyCalendarDates from "@/components/property/calendar/PropertyCalendarDates";
    import PropertyCalendarScroller from "@/components/property/calendar/PropertyCalendarScroller";
    import AppButton from "@/components/app/AppButton/AppButton";

    import AppAside from "@/components/app/form/AppAside";
    import {C_PROPERTY_CALENDAR_AVAILIBILITY_V} from "@/shared/component_permission";
    import InboxReservation from "@/components/inbox/InboxReservation";
    import CalendarAside from "@/components/unit/calendar_opt/Aside/Calendar/CalendarAside";
    import ReservationAccommodationHeader from "@/components/reservation/accommodation/ReservationAccommodationHeader";
    import ReservationDetails from "@/components/reservation/accommodation/ReservationDetails";
    import PropertyCalendarSidebarItem from "@/components/property/calendar/PropertyCalendarSidebarItem";
    import PropertySideBarUnitItem from "@/components/property/calendar/PropertySideBarUnitItem";
    import {AvailabilityScrollMixin} from "@/mixins/calendar/AvailabilityScrollMixin";
    import router from "@/router";
    import routeName from "@/router/routeNames";

    export default {
        name: "PropertyCalendarWrapper",
        mixins: [AvailabilityScrollMixin],
        components: {
            PropertySideBarUnitItem,
            PropertyCalendarSidebarItem,
            ReservationDetails,
            ReservationAccommodationHeader,
            CalendarAside,
            InboxReservation,
            AppAside,
            AppButton, PropertyCalendarScroller, PropertyCalendarDates, PropertyCalendarItem
        },
        data() {
            return {
                loading: false,
                units: {},
                unit_data:{},
                reservations: {},
                filter: {
                    currentPage: 0,
                    limit: 5,
                    sortBy: "id",
                    sortDirection: "ASC",
                },
                total: 0,
                last_page: null,
                aside_reservation: false,
                reservation: null,
                rootUnit: null,
                C_PROPERTY_CALENDAR_AVAILIBILITY_V: C_PROPERTY_CALENDAR_AVAILIBILITY_V,
                showLoadMore: false,
                objectList:[],
                shortNameCharacters: 14,

            }
        },
        props: {
            ac_response_value:{
                type:Boolean,
                default:true
            },
            display: {
                type:Number,
                default:0
            },
            selectedUnits:{
              type:Array,
              default(){
                  return []
              }
            },
            showLoading:{
                type:Boolean,
                default:false
            },
            property: {
                type: Object
            },
            dateArray: {
                type: Array,
                default: []
            },

            scrollLeftPosition: {
                type: Number,
                default: 0
            },
            shiftKeyValue:{
                type:Boolean,
                default:true
            },
            dateRange:{
                type:Object,
            },
            showPropertyItem:{
                type:Boolean,
                default:true
            },
            index:{
                type:Number
            }
        },
        computed: {
            showProperty(){
               return this.$props.property.hasOwnProperty('units') && Object.keys(this.$props.property.units).length > 0
            },
            tooltipTarget(){
                return `tooltip_full_property_name-${this.property.id}`
            },
            shortPropertyName() {
                if (this.showShortPropertyName) {
                    return this.property.name.substr(0, this.shortNameCharacters) + '...'
                }
                return this.property.name
            },
            showShortPropertyName() {
                return this.property.name.length > this.shortNameCharacters
            },
            company_id() {
                return this.$store.getters['user/getCompany']
            },



        },
        methods: {
            redirectToPropertyCalendar(){
                let routerData = router.resolve({name: routeName.RN_PROPERTY_CALENDAR, params: {id: this.property.id},query:{start:this.dateRange.start,end:this.dateRange.end}})
                window.open(routerData.href,'_blank')
            },
            showContigent(object){

                if(this.objectList.length > 0 ){
               let index =  this.objectList.findIndex(el=>{
                        return object.id === el.id
                    })
                    if(index > -1){
                        this.objectList[index].value = object.value
                    } else {
                        this.objectList.push((object))
                    }
                } else {
                    this.objectList.push((object))
                }
            },
            formatResponse(items) {
                this.units = {...this.units, ...this.formatUnitList(items)}

            },
            formatUnitList(list) {
                let formattedList = {}

                _.forEach(list, (value, key) => {

                    let newObject = {
                        id: value["id"],
                        name: value["name"],
                        unit_type: {name: value["type"]},
                        primary_rate_plan:value.additional_data  ? value.additional_data["primary_rate_plan"] : null,
                        los:value.additional_data ? value.additional_data["default_los"] :{},
                        occupancy: value.additional_data ? value.additional_data["default_occupancy"] : {},
                        property_id:this.property.id,
                        tags: value.additional_data.hasOwnProperty('tags') ? value.additional_data.tags : [],
                        show:value["show"]


                    }
                    if (_.has(value, ["contigent"])) {
                        newObject["contigent"] = this.formatUnitList(value["contigent"])
                    }

                    formattedList[key] = newObject

                    if (_.has(value, ["reservation"])) {
                        this.formatReservationList(value)
                    }
                    if (_.has(value, ["data"])) {
                        this.formatDataList(value)
                    }
                });

                return formattedList
            },
            formatReservationList(object) {
                let reservationObjectList = {}
                if(typeof object.reservation  === 'object' && Object.keys(object.reservation).length > 0){
                    reservationObjectList = object.reservation
                }
                this.reservations[object.id] = reservationObjectList
            },
            formatDataList(object) {
                    this.unit_data[object.id] =  object.data


            },
            formatRatesList(object){
              let rateObject = {}
                if(object.default_rates  && typeof object.default_rates  === 'object' && Object.keys(object.default_rates).length > 0){
                    rateObject = object.default_rates
                }
                this.rates[object.id] =  rateObject
            },
            formatRestrictionList(object){
                let restrictionObject = {}
                if(typeof object.restrictions  === 'object' && Object.keys(object.restrictions).length > 0){
                    restrictionObject = object.restrictions
                }
                this.restrictions[object.id] =  restrictionObject
            },
            handleHorizontalScroll($event) {
                this.scrollLeftPosition = $event
            },
        },
        watch:{
            property:{
                handler(object){
                    this.formatResponse(object.units)
                }, immediate:true,
                    deep:true
            }
        },


    }
</script>

<style scoped>
    .res-no-border {
        box-shadow: none;
    }

    .res-no-border > div {
        border: none !important;
        padding-left: 0;
    }
</style>
