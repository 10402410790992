<template>
    <div>
        <b-form @submit.prevent="save">
            <b-row>
                <b-col md="6" class="mb-4">
                    <app-input-control :error-object="validation.invoice_type">
                        <template v-slot:input="data">
                            <label>{{ $t('TYPE') }}</label>
                            <app-select mode="search"
                                        v-model="formData.invoice_type"
                                        :search="searchInvoiceType"
                                        search-on-create :state="data.state">
                                <template v-slot:text="data">
                                    {{data.item.name || data.item.label}}
                                </template>
                            </app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" class="mb-4">
                    <app-input-control :error-object="validation.creation_location">
                        <template v-slot:input="data">
                            <label>{{ $t('CREATION_LOCATION') }}</label>
                            <app-select :options="optionLists.creation_location_list"
                                        text-field="label"
                                        v-model="formData.creation_location.id" :state="data.state">
                            </app-select>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="12" class="mb-4">
                    <app-input-control :error-object="validation.description">
                        <template v-slot:input="data">
                            <label>{{ $t('DESCRIPTION') }}</label>
                            <b-input v-model="formData.description" :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="mb-4">
                    <app-button-submit :loading="saving"
                                       :c_permission="C_SETUP_FINANCE_INVOICE_CREATION_E">
                    </app-button-submit>
                </b-col>

                <b-col md="6" class="mb-4">
                    <app-button-delete
                        v-if="setupObject.id"
                        class="pull-right"
                        :c_permission="C_SETUP_FINANCE_INVOICE_CREATION_E"
                        @click="() => {
                            deleteDialogObjectId = setupObject.id
                            deleteContext = INVOICE
                            deleteDialogState = true
                        }">
                    </app-button-delete>
                </b-col>
            </b-row>

            <template v-if="setupObject.id">
                <b-table :items="formData.items" :fields="fields" fixed>
                    <template v-slot:total_var="data">
                        <app-select :options="optionLists.total_var_list" v-model="data.item.total_var"
                                    text-field="label"></app-select>
                    </template>

                    <template v-slot:vat_system_var="data">
                        <app-select :options="optionLists.vat_system_var_list" v-model="data.item.vat_system_var"
                                    text-field="label"></app-select>
                    </template>

                    <template v-slot:quantity_var="data">
                        <app-select :options="optionLists.quantity_var_list" v-model="data.item.quantity_var"
                                    text-field="label"></app-select>
                    </template>

                    <template v-slot:article="data">
                        <app-select :options="articleList" search-on-create v-model="data.item.article.id"
                                    text-field="label"></app-select>
                    </template>

                    <template v-slot:in_vat_system="data">
                        <app-select :options="vatList" search-on-create v-model="data.item.in_vat_system.id"
                                    text-field="label"></app-select>
                    </template>

                    <template v-slot:not_in_vat_system="data">
                        <app-select :options="vatList" search-on-create v-model="data.item.not_in_vat_system.id"
                                    text-field="label"></app-select>
                    </template>

                    <template v-slot:actions="data">
                        <app-button v-if="itemWasChanged(data.item)"
                                    :c_permission="C_SETUP_FINANCE_INVOICE_CREATION_E"
                                    @click="saveItem(data.item, data.index)">
                        </app-button>

                        <app-button v-else variant="link"
                                    :c_permission="C_SETUP_FINANCE_INVOICE_CREATION_E"
                                    icon="delete"
                                    :show_text="false"
                                    @click="() => {
                                    deleteDialogObjectId = data.item.id
                                    deleteDialogObjectIndex = data.index
                                    deleteContext = INVOICE_ITEM
                                    deleteDialogState = true
                                }">
                        </app-button>
                    </template>
                </b-table>

                <b-row>
                    <b-col class="mb-4 d-flex justify-content-center align-items-baseline">
                        <app-button button_type="new" variant="primary" @click="addNewItem">
                            {{ $t("NEW_ITEM") }}
                        </app-button>
                    </b-col>
                </b-row>
            </template>
        </b-form>

        <app-confirmation-dialog
            :show="deleteDialogState"
            @confirm="_delete"
            :delete_title="true"
            @cancel="deleteDialogState=false">
        </app-confirmation-dialog>
    </div>
</template>

<script>
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import AppInputControl from "@/components/app/AppInputControl";
    import {
        createInvoiceCreationSetup,
        fetchInvoiceCreationSetup,
        updateInvoiceCreationSetup,
        getArticleList,
        getInvoiceCreationOptions,
        getInvoiceTypeList,
        getVatList,
        deleteInvoiceItemCreationSetup,
        createInvoiceItemCreationSetup,
        updateInvoiceItemCreationSetup
    } from "@/services/finance/setup";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {cloneDeep, isEqual} from "lodash";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {
        INVOICE_CREATION_SETUP_CREATION_LOCATION_REQUIRED,
        INVOICE_CREATION_SETUP_INVOICE_TYPE_REQUIRED
    } from "@/shared/error_codes";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import {C_SETUP_FINANCE_INVOICE_CREATION_E} from "@/shared/component_permission";

    const INVOICE = 1
    const INVOICE_ITEM = 2

    export default {
        name: "InvoiceCreationSetupForm",
        components: {AppButtonDelete, AppConfirmationDialog, AppButtonSubmit, AppButton, AppSelect, AppInputControl},
        mixins: [getErrorMessage],
        props: {
            setupObject: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                formData: {
                    invoice_type: {id: null, label: null},
                    creation_location: {id: null, label: null},
                    items: []
                },
                initialFormData: null,
                validation: {
                    invoice_type: {
                        input: [INVOICE_CREATION_SETUP_INVOICE_TYPE_REQUIRED]
                    },
                    creation_location: {
                        input: [INVOICE_CREATION_SETUP_CREATION_LOCATION_REQUIRED]
                    }
                },
                optionLists: {},
                articleList: [],
                vatList: [],
                fields: [
                    {key: 'total_var', label: this.$t('TOTAL_SOURCE')},
                    {key: 'vat_system_var', label: this.$t('VAT_SYSTEM_SOURCE')},
                    {key: 'quantity_var', label: this.$t('QUANTITY_SOURCE')},
                    {key: 'article', label: this.$t('DESCRIPTION')},
                    {key: 'in_vat_system', label: this.$t('IN_VAT_SYSTEM')},
                    {key: 'not_in_vat_system', label: this.$t('NOT_IN_VAT_SYSTEM')},
                    {key: 'actions', label: '', class: 'text-center'}
                ],
                deleteDialogState: false,
                deleteDialogObjectId: null,
                deleteContext: null,
                deleteDialogObjectIndex: null,
                INVOICE, INVOICE_ITEM,
                saving: false,
                C_SETUP_FINANCE_INVOICE_CREATION_E
            }
        },
        computed: {
            companyId() {
                return this.$store.getters['user/getCompany']
            },
        },
        methods: {
            save() {
                this.saving = true
                const savePromise = !this.setupObject.id
                    ? createInvoiceCreationSetup(this.formData)
                    : updateInvoiceCreationSetup(this.setupObject.id, this.formData)
                savePromise.then(response => {
                    !this.setupObject.id ? this.$emit('created', response.data) : this.$emit('updated', response.data)
                    this.initialFormData = cloneDeep(this.formData)
                    notifySuccess()
                }).catch(error => {
                    this.showErrorMessages(error, this.validation)
                }).finally(() => this.saving = false)
            },
            _delete() {
                if (this.deleteContext === INVOICE) {
                    this.$emit('deleted', this.setupObject)
                } else {
                    this.saving = true
                    deleteInvoiceItemCreationSetup(this.deleteDialogObjectId).then(() => {
                        this.formData.items.splice(this.deleteDialogObjectIndex, 1)
                        this.initialFormData = cloneDeep(this.formData)
                        notifySuccess()
                    }).catch(error => {
                        this.showErrorMessages(error, this.validation)
                    }).finally(() => {
                        this.deleteDialogState = false
                        this.saving = false
                    })
                }
            },
            saveItem(item, index) {
                this.saving = true
                const savePromise = !item.id
                    ? createInvoiceItemCreationSetup(item)
                    : updateInvoiceItemCreationSetup(item.id, item)
                savePromise.then(response => {
                    this.$set(this.formData.items, index, response.data)
                    this.initialFormData = cloneDeep(this.formData)
                    notifySuccess()
                }).catch(error => {
                    this.showErrorMessages(error, this.validation)
                }).finally(() => {
                    this.saving = false
                })
            },
            addNewItem() {
                this.formData.items.push({
                    invoice_creation_setup_id: this.setupObject.id,
                    article: {id: null, label: null},
                    in_vat_system: {id: null, label: null},
                    not_in_vat_system: {id: null, label: null},
                    quantity_var: null, total_var: null, vat_system_var: null
                })
            },
            searchInvoiceType() {
                return getInvoiceTypeList({company: this.companyId})
            },
            itemWasChanged(item) {
                if (!item.id) {
                    return true
                }
                return !isEqual(item, this.initialFormData.items.find(i => Number(i.id) === Number(item.id)))
            }
        },
        created() {
            this.formData.company = this.companyId

            getArticleList({company: this.companyId, limit: 999}).then(response => {
                this.articleList = response.data.items.map(el => {
                    return {
                        id: el.id,
                        label: el.name
                    }
                })
            })

            getVatList({company: this.companyId, limit: 999}).then(response => {
                this.vatList = response.data.items.map(el => {
                    return {
                        id: el.id,
                        label: el.label
                    }
                })
            })

            getInvoiceCreationOptions().then(response => {
                this.optionLists = response.data
            })

            if (this.setupObject.id) {
                fetchInvoiceCreationSetup(this.setupObject.id).then(response => {
                    this.formData = Object.assign({}, this.formData, response.data)
                    this.initialFormData = cloneDeep(this.formData)
                })
            } else {
                this.initialFormData = cloneDeep(this.formData)
            }
        }
    }
</script>

<style scoped>
    .max-width {
        max-width: 5px;
    }
</style>
