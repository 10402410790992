<template>
    <div>
        <div class="d-flex justify-content-start align-items-center" v-if="loading">
            <b-spinner variant="primary"></b-spinner>
        </div>
        <b-table v-else :items="list" :fields="fields">
            <template v-slot:value="data">
                <span style="line-height: 1.5rem;" v-html="parseNewLine(data.item.value)"></span>

            </template>
            <template v-slot:user="data">
                {{ data.item.user.first_name }} {{ data.item.user.last_name }}
            </template>
            <template v-slot:timestamp="data">
                {{ data.item.timestamp|timestamp }}
            </template>
        </b-table>
    </div>

</template>

<script>
import {getInventoryLogByDate} from "@/services/unit";

export default {
    name: "LogListPerDay",
    data() {
        return {
            loading: false,
            list: [],
            field_rates:[
                {key: 'value', label: this.$t('RATES')},
                {key: 'user', label: this.$t('USER')},
                {key: 'timestamp', label: this.$t('LAST_CHANGE')},
                {key: 'edit', label: ''},
            ],
            field_min_stay:[
                {key: 'value', label: this.$t('MIN_STAY')},
                {key: 'user', label: this.$t('USER')},
                {key: 'timestamp', label: this.$t('LAST_CHANGE')},
                {key: 'edit', label: ''},
            ],
            fields:[]

        }
    },
    props: {
        unit_id: {
            type: Number,
        },
        date: {
            type: String
        },
        type: {
            type: String,
            default: 'rates'
        },
        filter: {
            type: Object
        }
    },
    methods: {
        parseNewLine(string) {
            return this.$options.filters.newLineToLineBreak(string)
        },
    },
    created() {
        this.loading = true
        getInventoryLogByDate(this.date, {unit: this.unit_id, type: this.type, ...this.filter}).then(response => {
            this.list = response.data.date_list
            this.loading = false
        })
    },
    watch: {
        type: {
            handler(value) {
                if (value === 'rates') {
                    this.fields = this.field_rates
                } else {

                    this.fields = this.field_min_stay
                }
            },immediate:true
        }
    }
}
</script>

<style scoped>

</style>
