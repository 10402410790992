<template>
    <b-nav-item exact :active="active" v-if="checkPermissionForRoute() && isVisible"
                :to="navElement.route">
        <i v-if="navElement.icon" :class="navElement.icon" aria-hidden="true"></i>
        {{ label }}
    </b-nav-item>
</template>

<script>
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

    export default {
        name: "NavigationItem",
        mixins: [AccessControlComponent],
        props: {
            navElement: {
                type: Object,
            },
            access_control_context: {
                type: Object,
                default: null
            },
        },
        computed: {
            active() {
                return this.$route.matched.filter(item => item.name === this.navElement.route.name).length > 0
            },
            route() {
                return this.$router.resolve({name: this.navElement.route.name}).route
            },
            label() {
                if (this.route.meta.t) {
                    return this.$t(this.route.meta.t)
                } else if (this.route.meta.label) {
                    return this.route.meta.label
                } else {
                    return ""
                }
            },
            isVisible() {
                return this.AccessControlVisible
            },
            isDisabled() {
                return this.AccessControlDisabled
            },
        },
        methods: {
            checkPermissionForRoute() {
                const permissionId = this.route.meta.permission_id
                if (typeof permissionId === "undefined" || permissionId === null) {
                    return true
                }
                return this.checkPermission(permissionId)
            }
        }
    }
</script>

<style scoped>

</style>
