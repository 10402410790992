import Vue from 'vue'
import VueI18n from 'vue-i18n'

import hr from './lang/hr.json'
import en from './lang/en.json'
import si from './lang/si.json'
import es from './lang/es.json'
import sr from './lang/sr.json'
import it from './lang/it.json'
import ba from './lang/ba.json'
import no from './lang/no.json'
import da from './lang/da.json'
import de from './lang/de.json'
import nl from './lang/nl.json'


Vue.use(VueI18n)

const messages = {
    hr: hr,
    en: en,
    si: si,
    ba: ba,
    es: es,
    sr: sr,
    it: it,
    no: no,
    da: da,
    de: de,
    nl: nl,
}

const locale = localStorage.getItem('language') ? localStorage.getItem('language') : 'en'

const i18n = new VueI18n({
    locale: locale, // set locale
    fallbackLocale: 'en',
    messages: messages,
    pluralizationRules: {
        hr: slavicRuleset,
        ru: slavicRuleset,
        es: notOneRule,
        it: notOneRule,
        pt: notOneRule,
        fr: greaterThanOneRule,
        pl: polishRuleset,
        me: slavicRuleset,
        sr: slavicRuleset,
        si: slovenianRuleset,
        sq: notOneRule,
        de: notOneRule,
        mk: macedonianRuleset,
        no: notOneRule,
        sk: slovakCzechRuleset,
        bs: slavicRuleset,
        el: notOneRule
    },
    // missing: (locale, key, vm, values)  => {
    //     let fallback = require('@/locale/lang/en')
    //     let missingVals = localStorage.getItem('miss') ? localStorage.getItem('miss') : '{}'
    //     missingVals = JSON.parse(missingVals)
    //     missingVals[key] = fallback[key]
    //     localStorage.setItem('miss', JSON.stringify(missingVals))
    // }
})

/**
 * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @param choicesLength {number} an overall amount of available choices
 * @returns a final choice index to select plural word by
 */
function slavicRuleset(choice, choicesLength) {
    if (choice % 10 === 1 && choice !== 11) {
        return 0
    } else if ([2, 3, 4].includes(choice % 10) && ![12, 13, 14].includes(choice)) {
        return 1
    } else {
        return choicesLength - 1
    }
}

function macedonianRuleset(choice, choicesLength) {
    if (choice % 10 === 1 && choice !== 11) {
        return 0
    } else {
        return choicesLength - 1
    }
}

function notOneRule(choice, choicesLength) {
    return choice === 1 ? 0 : choicesLength - 1
}

function greaterThanOneRule(choice, choicesLength) {
    return choice <= 1 ? 0 : choicesLength - 1
}

function polishRuleset(choice, choicesLength) {
    return (choice === 1 ? 0 : choice % 10 >= 2 && choice % 10 <= 4 && (choice % 100 < 10 || choice % 100 >= 20) ? 1 : choicesLength - 1)
}

function slovenianRuleset(choice, choicesLength) {
    return (choice % 100 === 1 ? 0 : choice % 100 === 2 ? 1 : choice % 100 === 3 || choice % 100 === 4 ? 2 : choicesLength - 1);
}

function slovakCzechRuleset(choice, choicesLength) {
    return (choice === 1) ? 0 : (choice >= 2 && choice <= 4) ? 1 : choicesLength - 1;
}

export default i18n




