import {getMagicLinkToken, getToken} from '@/services/auth/index'
import {TOKEN, REFRESH_TOKEN, TOKEN_EXPIRES} from '@/shared/constants'
import i18n from "@/locale";


const state = {
    token: localStorage.getItem(TOKEN),
}

const mutations = {
    setToken(state, token) {
        state.token = token
    },
}

const actions = {
    actionSetLogoutTimer({commit}, expirationTime) {
        setTimeout(() => {
            commit('setToken', null)
        }, expirationTime)
    },
    actionLogin: ({commit}, authData) => {
        return new Promise((resolve, reject) => {
            getToken(authData).then(response => {
                const now = new Date()
                const expirationDate = new Date(now.getTime() + response.expires_in)
                localStorage.setItem(TOKEN, response.access_token)
                localStorage.setItem(REFRESH_TOKEN, response.refresh_token)
                localStorage.setItem(TOKEN_EXPIRES, expirationDate)
                commit('setToken', response.access_token)

                return resolve(response);
            }).catch((error) => {
                return reject(error);
            })
        })
    },
    actionMagicLinkLogin: ({commit}, token) => {


        return new Promise((resolve, reject) => {
            getMagicLinkToken(token).then(response => {
                const now = new Date()
                const expirationDate = new Date(now.getTime() + response.expires_in)
                localStorage.setItem(TOKEN, response.access_token)
                localStorage.setItem(REFRESH_TOKEN, response.refresh_token)
                localStorage.setItem(TOKEN_EXPIRES, expirationDate)
                commit('setToken', response.access_token)

                resolve(response);
            }).catch((error) => {
                reject(error);
            })
        })
    },
    actionLogout({commit}) {
        commit('setToken', null)
        localStorage.clear();
        if(process.env.VUE_APP_LOGOUT_LINK === 'https://booker-tools.com'){
            if (i18n.locale === 'hr') {
                // promijeniti kad se popravi link
                window.location.href =  process.env.VUE_APP_LOGOUT_LINK + '/login'
            } else {
                window.location.href = process.env.VUE_APP_LOGOUT_LINK + '/login/'
            }
        } else {
            window.location.href = process.env.VUE_APP_LOGOUT_LINK
        }
    },
    actionAutoLogin({commit}) {

        const token = localStorage.getItem(TOKEN)
        if (token) {
            commit('setToken', token)
            return true
        }
        if (state.token === null) {
            return false
        }
        return true

    }
}

const getters = {
    getAuthStatus: function () {
        return state.token !== null
    },
    getToken: function () {
        return state.token
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}
