<template>
    <div>
        <owner-reservation-setup :setup_parameter="SETUP_PARAMETER_OWNER_STATEMENT_SETUP"></owner-reservation-setup>
    </div>
</template>

<script>

import {SETUP_PARAMETER_OWNER_STATEMENT_SETUP} from "@/shared/constants";

export default {
    name: "Index",
    components: {
        "OwnerReservationSetup": () => import("@/components/owner/OwnerReservationSetup"),
    },
    data() {
        return {
            SETUP_PARAMETER_OWNER_STATEMENT_SETUP
        }
    },
}
</script>

<style scoped>

</style>
