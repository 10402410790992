<template>
    <div class="dashboard-wrapper">
        <b-row v-if="checkPermission(C_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES_TRANSFERS_V)">
            <b-col>
                <b-card no-body>
                    <b-card-header
                        header-class="border-bottom-0 d-flex flex-wrap justify-content-between align-items-center">
                        <span class="header-2">{{$t("TRANSFERS")}}</span>
                    </b-card-header>
                    <b-card-body>
                        <transfer-list-table :show-limit="false" :search-options="{reservationId: reservation.id}"
                                             :search-on-create="true"></transfer-list-table>

                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <b-row v-if="checkPermission(C_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES_ACTIVITIES_V)">
            <b-col>
                <b-card no-body>
                    <b-card-header
                        header-class="border-bottom-0 d-flex flex-wrap justify-content-between align-items-center">
                        <span class="header-2">{{$t("ACTIVITIES")}}</span>
                    </b-card-header>
                    <b-card-body>
                        <activity-reservation-table :show-limit="false" :search-options="{reservation: reservation.id}"
                                             :search-on-create="true"></activity-reservation-table>

                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import TransferListTable from "@/components/transfer/list/TransferListTable";
    import {
        C_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES_ACTIVITIES_V,
        C_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES_TRANSFERS_V
    } from "@/shared/component_permission";
    import ActivityReservationTable from "@/components/reservation/activity/list/ActivityReservationTable";

    export default {
        name: "Index",
        components: {ActivityReservationTable, TransferListTable},
        props: {
            reservation: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data(){
            return {
                C_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES_TRANSFERS_V,
                C_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES_ACTIVITIES_V
            }
        },
        created() {
            this.$emit('created', true)
        }
    }
</script>

<style scoped>

</style>
