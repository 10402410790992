<template>
    <form v-if="formType === 'DESCRIPTION'">
        <div v-if="description || tos">
            <b-row class="mt-4 mb-4" v-if="description !== null">
                <b-col>
                    <label>{{$t('DESCRIPTION')}}</label>
                    <b-form-textarea
                        v-model="description"
                        max-rows="15"
                    ></b-form-textarea>
                </b-col>
            </b-row>
            <b-row v-if="tos !== null">
                <b-col>
                    <label>{{$t('TERMS_OF_SERVICE')}}</label>
                    <b-form-textarea
                        v-model="tos"
                        max-rows="15"
                    ></b-form-textarea>
                </b-col>
            </b-row>
        </div>
        <app-no-data v-else-if="descriptionCheck && tosCheck" :heading="$t('NO_DESCRIPTION')"></app-no-data>
    </form>
    <form v-else-if="activation_rules.city || activation_rules.country">
        <div v-if="activation_rules.city.length > 0 || activation_rules.country.length > 0">
            <b-row>
                <b-col>
                    <label>{{ $t('MARKETPLACE_ACTIVATION_RULES.CITY_REQUIREMENTS') }}</label>
                </b-col>
                <b-col>
                <span v-for="(city, key) in activation_rules.city">
                    {{city}}
                    <span v-if="key+1 !== activation_rules.city.length">, </span>
                </span>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <label>{{$t('MARKETPLACE_ACTIVATION_RULES.COUNTRY_REQUIREMENTS')}}</label>
                </b-col>
                <b-col>
                <span v-for="(country, key) in activation_rules.country">
                    {{country}}
                    <span v-if="key+1 !== activation_rules.country.length">, </span>
                </span>
                </b-col>
            </b-row>
        </div>
        <app-no-data v-else :heading="$t('NO_RULES')"></app-no-data>
    </form>
</template>

<script>
import {getMarketplaceSetupValidation} from "@/services/marketplace";
import {MARKETPLACE_TOS, MARKETPLACE_WEBSITE_ACTIVATION, MARKETPLACE_DESCRIPTION} from "@/shared/constants";
import AppNoData from "@/components/app/AppNoData";

export default {
    name: "MarketplaceDescriptiveForm",
    components:{AppNoData},
    props:{
        formType:{
            type: String,
            default: ''
        },
        websiteId:{
            type: Number,
            default: null
        },
        companyId:{
            type: Number,
            default: null
        }
    },
    data(){
        return{
            tos: null,
            description: null,
            website_activation: [],
            activation_rules:[],
            descriptionCheck: false,
            tosCheck: false
        }
    },
    methods:{},
    created() {
        getMarketplaceSetupValidation({website_id: this.websiteId, company_id: this.companyId}).then(response => {
            response.data.forEach(el => {
                el.id === MARKETPLACE_DESCRIPTION ? this.description = el.lang : ''
                el.id === MARKETPLACE_TOS ? this.tos = el.lang : ''
                el.id === MARKETPLACE_WEBSITE_ACTIVATION ? this.website_activation = el : ''
                el.id === MARKETPLACE_WEBSITE_ACTIVATION ? this.activation_rules = el.activation_rules : ''
                el.id === MARKETPLACE_DESCRIPTION ? el.lang === null ? this.descriptionCheck = true : '' : ''
                el.id === MARKETPLACE_TOS ? el.lang === null ? this.tosCheck = true : '' : ''
            })
        })
    }
}
</script>

<style scoped>

</style>
