<template>
    <div class="mt-5">
        <table class="table">
            <thead>
            <tr>
                <th>{{$t('FEED_NAME')}}</th>
                <th>{{$t('FEED_URL')}}</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="mt-5 mb-4" v-if="icalValue.length" v-for="(ical, index) in icalValue">
                    <td>
                        <b-input v-model="ical.link_name" @change="changeFeedName(ical.link_name)"></b-input>
                    </td>
                    <td style="width: 55%">
                        <b-input :disabled="!!ical.order_number" v-model="ical.link_url"></b-input>
                    </td>
                    <td class="pt-3">
                        <b-tooltip v-if="ical.order_number" :target="'import_button_' + index" triggers="hover" placement="top">
                            <span>{{$t('LAST_MANUAL_IMPORT')}}: {{ ical.last_manually_imported_at }}</span><br>
                            <span>{{$t('LAST_AUTOMATIC_IMPORT')}}: {{ical.last_imported_at}}</span>
                        </b-tooltip>
                        <app-button class="ml-5"
                                    icon="refresh"
                                    :id="'import_button_' + index"
                                    @click="importIcal(ical.order_number, ical)"
                                    :loading="ical.loading"
                                    :show_text="false"
                                    variant="link"
                                    v-if="ical.order_number"
                        ></app-button>
                        <app-button
                            class="ml-2 action_button pull-right"
                            button_type="delete"
                            :show_text="false"
                            variant="link"
                            @click="deleteItem(index)">
                        </app-button>
                    </td>
            </tr>
            <tr>
                <td>
                    <b-input v-model="name" @change="setDisabled"></b-input>
                </td>
                <td>
                    <b-input v-model="url" @change="setDisabled"></b-input>
                </td>
                <td class="pull-right">
                    <app-button :disabled="disabledAddNew" @click="addItem">{{$t('ADD_ITEM')}}</app-button>
                </td>
            </tr>
            </tbody>
        </table>
        <b-row class="mt-3">
            <b-col>
                <app-button :loading="loading" :disabled="disabledSave" @click="save">{{$t('SAVE')}}</app-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {getSetupParameterList, pullIcalFeed, updateSetupParameters} from "@/services/channel_manager";
import AppButton from "@/components/app/AppButton/AppButton";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {SETUP_PARAMETER_IMPORT_ICAL} from "@/shared/constants";
import {cloneDeep} from "lodash";

export default {
    name: "UnitManagementIcalExportForm",
    components:{AppButton},
    mixins:[getErrorMessage],
    props:{
        unit_id:{
            type: Number,
            default: null
        },
        distribution_id:{
            type: Number,
            default: null
        }
    },
    computed:{
        property() {
            return this.$store.getters['property/getProperty']
        },
        disabledAddNew(){
            return !(this.name && this.url);
        }
    },
    data(){
        return {
            icalValue: [],
            loading: false,
            loadingImport: false,
            SETUP_PARAMETER_IMPORT_ICAL,
            name: null,
            url: null,
            disabledSave: true
        }
    },
    methods:{
        addItem(){
            this.icalValue.push({
                link_name: this.name, link_url: this.url, order_number: null
            })
            this.name = null
            this.url = null
            this.disabledSave = false
        },
        setDisabled(){
            (this.name || this.url) ? this.disabledSave = true : this.disabledSave = false
        },
        changeFeedName(link_name){
            if(link_name === ''){
                this.disabledSave = true
            }else if (this.name === null || this.url === null){
                this.disabledSave = false
            }
        },
        deleteItem(index){
            this.icalValue.splice(index, 1);
            this.disabledSave = false
        },
        save(){
            this.icalValue.forEach(el => {
                delete el['loading']
            })
            let data = {
                distribution: this.distribution_id,
                parameter: this.SETUP_PARAMETER_IMPORT_ICAL,
                unit: [
                    {
                        id: this.unit_id,
                        value: JSON.stringify(this.icalValue)
                    }
                ]
            }
            this.loading = true
            updateSetupParameters({data: [data]}).then(() => {
                notifySuccess()
                this.loading = false
                this.$emit('import_saved')
            }, error => {
                this.showErrorMessages(error)
                this.loading = false
            })
        },
        importIcal(order_number, ical){
            ical.loading = true
            pullIcalFeed({unit: this.unit_id, order_number: order_number}).then(response => {
                notifySuccess()
                ical.loading = false
                this.getParameterList()
            }, error => {
                this.showErrorMessages(error)
                ical.loading = false
            })
        },
        getParameterList(){
            getSetupParameterList({
                level: 'unit',
                distribution: [this.$route.params.distribution_id],
                property: this.property.id,
                setup_parameter: this.SETUP_PARAMETER_IMPORT_ICAL,
                unit: [this.unit_id]
            }).then(response => {
                if (response.data){
                    response.data[0].parameters.forEach(el => {
                        if(el.parameter_id === 100 && el.values.unit){
                            let data = JSON.parse(el.values.unit.value)
                            data.forEach(obj => {obj.loading = false})
                            this.icalValue = data
                        }
                    })
                }
            })
        }
    },
    created() {
        this.getParameterList()
    }
}
</script>

<style scoped>

</style>
