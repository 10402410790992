<template>
    <form @submit.prevent="search" @reset.prevent="reset" class="filter">
        <b-row class="align-items-end">
            <app-access-control tag="b-col" xl="2" lg="4" md="6" class="mb-3" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{function:C_PROPERTY_LIST_FILTER_ACM_V, company: company.id, key:AC_PROPERTY_FILTER}">
                <app-select :label="$t('ACCOUNT_MANAGER')" mode="multiselect"
                            :options="acmList"
                            v-model="filter.account_manager" @input="searchProperties">
                </app-select>

            </app-access-control>

            <b-col xl="2" lg="4" md="6" class="mb-3" v-if="company.workflow_id === WORKFLOW_DIRECT_BOOKER">
                <label>{{$t('PROPERTY')}}</label>
                <app-select :disabled="!propertyList.length"
                            :options="propertyList"
                            v-model="filter.property" :search-input="true">
                </app-select>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3" v-if=" company.workflow_id === WORKFLOW_BOOKER_TOOLS">
                <label>{{$t('PROPERTY')}}</label>
                <app-select
                            :options="propertyList"
                            v-model="filter.property" >
                </app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('OWNER')}}</label>
                <b-input v-model="filter.owner_name"></b-input>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("_DATE")}}</label>
                <app-select :options="dateOptions" v-model="dateOptionValue"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("FROM")}}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="fromDate" :max-date="toDate"></app-date-picker>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("TO")}}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="toDate" :min-date="fromDate"
                                 :from-date="fromDate"></app-date-picker>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('STATUS')}}</label>
                <app-select mode="multiselect" text-field="label"
                            :options="statusList"
                            v-model="filter.status">
                </app-select>
            </b-col>

            <b-col xl="4" lg="4" md="6" class="mb-3">
                <app-button-submit :inline="true" :disabled="loading" :loading="submit_loader" variant="primary" button_type="search">
                    {{$t("SEARCH") }}
                </app-button-submit>
                <app-button-reset :disabled="loading" :loading="reset_loader" :inline="true"  class="pl-2 ml-2"></app-button-reset>
            </b-col>
        </b-row>
    </form>
</template>

<script>
    import {EventBus} from "@/shared/EventBus";
    import {cloneDeep, debounce} from "lodash";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getList, getList as getAccountManagerList} from "@/services/acc_manager";
    import {getPropertyList} from "@/services/property";
    import {getCalculationStatusList} from "@/services/finance/owner_calculation";
    import AppDatePicker from "@/components/app/datetime/AppDatePicker";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {C_PROPERTY_LIST_FILTER_ACM_V} from "@/shared/component_permission";
    import {AC_PROPERTY_FILTER} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {WORKFLOW_DIRECT_BOOKER,WORKFLOW_BOOKER_TOOLS} from '@/shared/constants';
    export default {
        name: "OwnerCalculationFilter",
        components: {AppAccessControl, AppButtonReset, AppDatePicker, AppSelect, AppButtonSubmit},
        mixins: [AccessControlComponent],
        data() {
            return {
                WORKFLOW_DIRECT_BOOKER,
                WORKFLOW_BOOKER_TOOLS,
                C_PROPERTY_LIST_FILTER_ACM_V,
                AC_PROPERTY_FILTER,
                access_control_fetch_key: AC_PROPERTY_FILTER,
                access_control_components: 1,
                acmList:[],
                initialFilter: null,
                filter: {
                    account_manager: [],
                    property: [],
                    owner_name: null,
                    status: [],
                },
                loading: false,
                propertyList: [],
                statusList: [],
                dateOptions: [
                    {id: 'start', name: this.$t('START')},
                    {id: 'end', name: this.$t('END')},
                    {id: 'insert', name: this.$t('DATE_CREATED')}
                ],
                dateOptionValue: null,
                fromDate: null,
                toDate: null,
                reset_form:0,
                reset_loader:false,
                submit_loader:false
            }
        },
        watch: {
            dateOptionValue() {
                this.fromDate = this.toDate = null
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            dateObject() {
                return this.dateOptionValue ? {
                    [`${this.dateOptionValue}_from_date`]: this.fromDate,
                    [`${this.dateOptionValue}_to_date`]: this.toDate,
                } : {};
            }
        },
        methods: {
            searchAccManager(value) {
                return getList({
                    company_id: this.$store.getters['user/getCompany'],
                })
            },
            search() {
                this.reset_form = 0
                EventBus.$emit('searchCalculations', {
                    ...this.filter, ...this.dateObject,
                })
            },
            reset() {
                this.reset_form = 1
                this.filter = cloneDeep(this.initialFilter)
                this.dateOptionValue = this.fromDate = this.toDate = null
                EventBus.$emit('searchCalculations', {
                    ...this.filter, ...this.dateObject,
                })
            },
            searchProperties: debounce(function () {
                if (this.filter.account_manager.length) {
                    getPropertyList({
                        account_manager: this.filter.account_manager,
                        company: this.company.id,
                        limit: 9999,
                        order_by: 'name'
                    }).then(response => {
                        this.propertyList = response.data.items
                    })
                } else {
                    this.propertyList = []
                    this.filter.property = []
                }
            }, 800)
        },
        created() {
            getAccountManagerList({company_id: this.company.id}).then(response => {
                this.acmList = response.data
            })
            getCalculationStatusList().then(response => {
                this.statusList = response.data
            })
            if(this.company.workflow_id === WORKFLOW_BOOKER_TOOLS){
                getPropertyList({
                    company: this.company.id,
                    limit: 9999,
                    order_by: 'name'
                }).then(response => {
                    this.propertyList = response.data.items
                })
            }
        },
        mounted() {
            this.initialFilter = cloneDeep(this.filter)
            EventBus.$on('loading', (value) => {
                this.loading = value
                if(this.reset_form === 1){
                    this.reset_loader = value
                } else {
                    this.submit_loader = value
                }
            })
        },
        beforeDestroy() {
            EventBus.$off('loading')
        }
    }
</script>

<style scoped>

</style>
