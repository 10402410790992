<template>
    <div class="pr-3 pl-3 pb-4" style="border-radius: 5px; border: 1px solid #EAEAEA">
        <b-row>
            <b-col>
                <form-header>{{ $t('TEMPLATE_CONTENT') }}</form-header>
            </b-col>
        </b-row>

        <b-row>
            <template v-for="([property, propertyConfig]) in templateConfigWithoutLists">
                <b-col cols="12" :xl="getXlColWidth(propertyConfig)" class="mb-4">
                    <label v-if="labelIsSeparate(propertyConfig.type)">
                        {{ propertyConfig.label }}
                    </label>

                    <component
                        :is="getComponent(propertyConfig)"
                        :checked="formData[property]"
                        :value="getValue(formData[property], propertyConfig)"
                        :unchecked-value="false"
                        @input="value => setValue(formData, property, propertyConfig, value)"
                        @update="value => setValue(formData, property, propertyConfig, value)"
                        @updateTranslations="formData[property] = $event"
                        :label="propertyConfig.label"
                        :image="formData[property] && formData[property].id"
                        :preview="formData[property] && formData[property].url"
                        :sidebar-description="propertyConfig.rtf ? '' : null"
                        :context-label="$t('PAGE_CONTENT')"
                        :translations="formData[property]"
                        :languages="website.supported_languages"
                        :component="propertyConfig.rtf ? `rich-text` : `textarea`"
                        :options="propertyConfig.allowed_values"
                        text-field="label"
                        :search-empty-item="!propertyConfig.required"
                        :image-search="getWebsiteGalleryImages"
                        :rich-text-options="propertyConfig.rtf ? getRichTextOptions(propertyConfig.rtf) : null"
                        :rows="5"
                        :allow-decimal="!!propertyConfig.allow_decimal"
                        :min="propertyConfig.min || 0"
                        switch>
                    </component>
                </b-col>
            </template>
        </b-row>

        <template v-for="([property, listItemConfig]) in templateConfigLists">
            <hr>

            <b-row>
                <b-col class="d-flex justify-content-between align-items-center">
                    <form-header>{{ listItemConfig.label }}</form-header>
                    <div>
                        <app-button button_type="new" @click="addItem(formData[property], listItemConfig.properties)">
                            {{ $t('NEW_ITEM') }}
                        </app-button>
                    </div>
                </b-col>
            </b-row>

            <draggable v-model="formData[property]" handle=".handle">
                <div v-for="(item, index) in formData[property]" :key="index" class="mb-4">
                    <div class="d-flex align-items-center">
                        <b-button block variant="outline-primary" class="text-left" @click="item.visible=!item.visible">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <i class="fa fa-bars mr-2 handle" style="cursor: move"></i>

                                    <b-badge pill variant="primary" class="mr-1 number-badge">
                                        {{ index + 1 }}
                                    </b-badge>
                                    {{ $t('ITEM') }}
                                </div>
                                <i :class="`fa fa-caret-${item.visible ? 'up' : 'down'}`"></i>
                            </div>
                        </b-button>

                        <app-button
                            class="ml-2"
                            button_type="delete"
                            :show_text="false"
                            variant="link"
                            @click="removeItem(formData[property], index)">
                        </app-button>
                    </div>

                    <b-collapse v-model="item.visible" :id="`item-${index}`">
                        <b-row>
                            <template
                                v-for="([itemProperty, itemPropertyValues]) in Object.entries(listItemConfig.properties)">
                                <b-col cols="12" :xl="getXlColWidth(itemPropertyValues)" class="mt-4">
                                    <label v-if="labelIsSeparate(itemPropertyValues.type)">
                                        {{ itemPropertyValues.label }}
                                    </label>

                                    <component
                                        :is="getComponent(itemPropertyValues)"
                                        :checked="item[itemProperty]"
                                        :value="getValue(item[itemProperty], itemPropertyValues)"
                                        :unchecked-value="false"
                                        @input="value => setValue(item, itemProperty, itemPropertyValues, value)"
                                        @update="value => setValue(item, itemProperty, itemPropertyValues, value)"
                                        @updateTranslations="item[itemProperty] = $event"
                                        :label="itemPropertyValues.label"
                                        :image="item[itemProperty] && item[itemProperty].id"
                                        :preview="item[itemProperty] && item[itemProperty].url"
                                        :context-label="$t('PAGE_CONTENT')"
                                        :translations="item[itemProperty]"
                                        :languages="website.supported_languages"
                                        :component="itemPropertyValues.rtf ? `rich-text` : `textarea`"
                                        :options="itemPropertyValues.allowed_values"
                                        text-field="label"
                                        :search-empty-item="!itemPropertyValues.required"
                                        :image-search="getWebsiteGalleryImages"
                                        :rows="5"
                                        :allow-decimal="!!itemPropertyValues.allow_decimal"
                                        :min="itemPropertyValues.min || 0"
                                        switch>
                                    </component>
                                </b-col>
                            </template>
                        </b-row>
                    </b-collapse>
                </div>
            </draggable>
        </template>
    </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _omit from 'lodash/omit'
import {getDocumentList} from "@/services/document";
import {TYPE_WEBSITE_GALLERY} from "@/shared/constants";

const VALUE_TYPE_LIST = "list"
const VALUE_TYPE_SELECT = "select"
const VALUE_TYPE_LANG = "lang"
const VALUE_TYPE_TEXT = "text"
const VALUE_TYPE_DOCUMENT = "document"
const VALUE_TYPE_BOOL = "bool"
const VALUE_TYPE_NUMBER = "number"

const VALUE_TYPE_COMPONENT = {
    [VALUE_TYPE_TEXT]: 'b-form-textarea',
    [VALUE_TYPE_LANG]: 'app-translation-input',
    [VALUE_TYPE_DOCUMENT]: 'app-image-input',
    [VALUE_TYPE_SELECT]: 'app-select',
    [VALUE_TYPE_BOOL]: 'b-form-checkbox',
    [VALUE_TYPE_NUMBER]: 'app-number-input'
}

const TOOLBAR_INLINE = ['bold', 'italic', 'link', {script: 'super'}, {script: 'sub'}, 'strike', 'underline', 'clean']
const FORMATS_INLINE = ['bold', 'italic', 'link', 'script', 'strike', 'underline', 'clean']
const TOOLBAR_BLOCK = [{header: [1, 2, 3, 4, 5, 6, false]}, {list: 'ordered'}, {list: 'bullet'}, {direction: 'rtl'}]
const FORMATS_BLOCK = ['header', 'list', 'direction']
const RTF_FORMATS = {
    inline: {
        toolbar: TOOLBAR_INLINE,
        formats: FORMATS_INLINE
    },
    block: {
        toolbar: TOOLBAR_BLOCK,
        formats: FORMATS_BLOCK
    }
}

export default {
    name: "WebsitePageHtmlTemplateForm",
    components: {
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "FormHeader": () => import("@/components/app/form/FormHeader"),
        "AppTranslationInput": () => import("@/components/app/translation/AppTranslationInput"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "AppImageInput": () => import("@/components/app/AppImageInput"),
        "AppNumberInput": () => import("@/components/app/form/AppNumberInput"),
        "draggable": () => import("vuedraggable")
    },
    props: {
        templateConfig: {
            type: Object,
            required: true,
        },
        templateData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            formData: null,
            VALUE_TYPE_LANG,
            VALUE_TYPE_TEXT,
        }
    },
    computed: {
        website() {
            return this.$store.getters['website/getWebsite']
        },
        templateConfigWithoutLists() {
            return Object.entries(this.templateConfig).filter(([key, value]) => value.type !== VALUE_TYPE_LIST)
        },
        templateConfigLists() {
            return Object.entries(this.templateConfig).filter(([key, value]) => value.type === VALUE_TYPE_LIST)
        }
    },
    watch: {
        templateConfig: {
            immediate: true,
            deep: true,
            handler(config) {
                if (this.templateData) {
                    this.formData = _cloneDeep(this.templateData)
                } else {
                    this.formData = this.setDefaultsFromProperties(config)
                }
            }
        },
        formData: {
            immediate: true,
            deep: true,
            handler(data) {
                this.$emit('update', this.cleanupFormData(data))
            }
        }
    },
    methods: {
        setDefaultsFromProperties(properties) {
            return Object.entries(properties).reduce((result, [key, value]) => {
                if (value.type === VALUE_TYPE_LIST) {
                    result[key] = []
                } else if (value.type === VALUE_TYPE_LANG) {
                    result[key] = this.expandLangObject()
                } else if (value.type === VALUE_TYPE_SELECT) {
                    result[key] = value.default_value || null
                } else if (value.type === VALUE_TYPE_BOOL) {
                    this.$nextTick(() => {
                        this.$set(result, key, value.default_value || false)
                    })
                } else {
                    result[key] = value.default_value || null
                }
                return result
            }, {})
        },
        // make an object with all supported languages or expand an existing object with missing languages
        expandLangObject(object = {}) {
            return this.website.supported_languages.reduce((result, language) => {
                if (!result.hasOwnProperty(language)) {
                    result[language] = null
                }
                return result
            }, object)
        },
        addItem(list, properties) {
            list.forEach(item => item.visible = false)
            list.push({...this.setDefaultsFromProperties(properties), visible: true})
        },
        removeItem(list, index) {
            list.splice(index, 1)
        },
        getComponent(propertyConfig) {
            return VALUE_TYPE_COMPONENT[propertyConfig.type]
        },
        getValue(property, propertyConfig) {
            return propertyConfig.type === VALUE_TYPE_LANG
                ? property.en
                : propertyConfig.type === VALUE_TYPE_BOOL
                    ? true
                    : property
        },
        setValue(object, property, propertyConfig, value) {
            switch (propertyConfig.type) {
                case VALUE_TYPE_LANG:
                    object[property].en = value
                    break;
                case VALUE_TYPE_DOCUMENT:
                    object[property] = {id: value}
                    break;
                default:
                    object[property] = value
                    break;
            }
        },
        //remove `visible` from list items
        cleanupFormData(data) {
            return Object.entries(data).reduce((result, [key, value]) => {
                if (Array.isArray(value)) {
                    result[key] = value.map(el => _omit(el, 'visible'))
                } else {
                    result[key] = value
                }
                return result
            }, {})
        },
        // [inline, block]
        getRichTextOptions(elements) {
            let toolbar = []
            let formats = []

            elements.forEach(el => {
                toolbar = [...toolbar, ...RTF_FORMATS[el].toolbar]
                formats = [...formats, ...RTF_FORMATS[el].formats]
            })

            return {
                modules: {
                    toolbar: {
                        container: toolbar,
                        handlers: {}
                    },
                },
                formats
            }
        },
        // Set input field width on big screen here  according to any parameters
        getXlColWidth(propertyConfig) {
            return propertyConfig.rtf && propertyConfig.rtf.includes('block')
                ? 6
                : [VALUE_TYPE_BOOL, VALUE_TYPE_NUMBER].includes(propertyConfig.type)
                    ? 2
                    : 3
        },
        getWebsiteGalleryImages(description) {
            return getDocumentList(this.website.id, TYPE_WEBSITE_GALLERY, {description})
        },
        labelIsSeparate(type) {
            return [VALUE_TYPE_BOOL, VALUE_TYPE_NUMBER, VALUE_TYPE_TEXT].includes(type)
        }
    }
}
</script>

<style scoped>

</style>
