<template>
    <div class="content">
        <b-row>
            <b-col lg="6" xl="6" class="col-xxl-8">
                <slot name="body"></slot>
            </b-col>
            <b-col>
                <slot name="sidebar"></slot>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "GeneralLayout",
        props: {
            company: {
                type: Object
            }
        }
    }
</script>

<style scoped>

</style>
