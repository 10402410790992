<template>
    <b-dropdown :size="size" :variant="variant" no-caret :id="component_id"
                :name="component_id"
                :boundary="boundary"
                :disabled="disabled"
                :popper-opts="popperOpts"
                @shown="focusInput"
                @hidden="$emit('hidden')"
                block
                :toggle-class="(state === false) ? 'border-danger' : ''">
        <template slot="button-content">
          <slot name="text">
            <span>{{text}}</span>
          </slot>
            <i class="fa fa-chevron-down fa-fw" aria-hidden="true"></i>
        </template>

        <b-container>
            <b-row v-if="showSearch">
                <b-col class="dropdown_field--checkbox mt-2">
                    <b-dropdown-form class="p-0">
                        <b-input autofocus class="mt-2" :ref="component_input_id" :id="component_input_id"
                                 size="default" v-model="keyword"></b-input>
                    </b-dropdown-form>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="dropdown_field--checkbox mt-2">
                    <b-form-checkbox v-if="computedOptions.length > 1" v-model="select_all_value" :unchecked-value="false"
                                     :id="component_id + '_all'" :name="component_id + '_all'"
                                     @change="toggleAll">
                        {{$t("SELECT_ALL")}}
                    </b-form-checkbox>
                </b-col>
            </b-row>

            <hr v-if="computedOptions.length > 1" class="mt-2 mb-2">

            <b-row>
                <b-col class="dropdown_field--options dropdown_field--checkbox pb-3">
                    <b-form-checkbox-group v-if="!group" stacked :value-field="ValueField" :text-field="TextField"
                                           :options="computedOptions"
                                           @input="toggle"
                                           :id="component_id + '_items'" :name="component_id + '_items'"
                                           v-model="selected"></b-form-checkbox-group>

                    <div v-else v-for="(option, index) in options">
                        <b-dropdown-header id="dropdown-header-label" v-if="options.length > 1"
                                           :class="groupSelectAll ? 'pl-0' : ''">
                            <b-form-checkbox
                                v-if="groupSelectAll"
                                class="pt-0 pb-0"
                                :checked="allItemsInGroupChecked(index)"
                                :unchecked-value="false"
                                :value="true"
                                :id="component_id + '_group_' + index + '_all'"
                                :name="component_id + '_group_' + index + '_all'"
                                @change="toggleAllItemsInGroup(index, $event)">
                                {{ option[TextField] }}
                            </b-form-checkbox>
                            <template v-else>
                                {{ option[TextField] }}
                            </template>
                        </b-dropdown-header>

                        <b-form-checkbox-group stacked
                                               v-model="selected"
                                               :id="component_id + '_items_' + index"
                                               :name="component_id + '_items_' + index"
                                               :value-field="ValueField"
                                               :text-field="TextField"
                                               :checked="value"
                                               :options="option[group]">
                        </b-form-checkbox-group>

                        <br v-if="options.length > 1">
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </b-dropdown>
</template>

<script>
import _isEqual from 'lodash/isEqual'
import {cloneDeep as _cloneDeep} from "lodash"
    export default {
        name: "AppMultiSelect",
        props: {
            value: {
                type: Array
            },
            options: {
                type: Array
            },
            ValueField: {
                type: String,
                default: 'id'
            },
            TextField: {
                type: String,
                default: 'name'
            },
            variant: {
                type: String
            },
            size: {
                type: String
            },
            group: {
                type: String
            },
            disabled: {
                type: Boolean
            },
            boundary: {
                type: String
            },
            state: {
                default: null
            },
            popperOpts: {
                type: Object
            },
            searchInput: {
                type: Boolean,
            },
            setFirst: {
                type: Boolean
            },
            groupSelectAll: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                select_all_value: null,
                selected: [],
                keyword: "",
            }
        },
        computed: {
            component_id() {
                return 'multi_select_' + this._uid
            },
            component_input_id() {
                return 'multi_select_auto_input_' + this._uid
            },
            text() {
                if (this.selected && this.selected.length && this.options.length) {
                    if (this.selected.length === 1) {
                        if (this.group) {
                            return this.options.map(option => option[this.group]).flat().find(el => el[this.ValueField] === this.selected[0])[this.TextField]
                        } else {
                            const el =  this.options.find(el => el[this.ValueField] === this.selected[0])
                            return el ? el[this.TextField] : ''
                        }
                    } else {
                        return this.$t("NUM_SELECTED", {value: this.selected.length})
                    }
                } else {
                    return ''
                }
            },
            optionsChildren() {
                return this.options.map(option => option[this.group].map(child => child[this.ValueField])).flat()
            },
            showSearch() {
                if (this.searchInput && !this.group) {
                    if (Array.isArray(this.options)) {
                        return this.options.length > 10
                    } else {
                        let number = 0
                        Object.keys(this.options).forEach(element => {
                            if (Array.isArray(this.options[element])) {
                                number += this.options[element].length
                            }
                        })
                        return number > 10
                    }
                } else {
                    return false
                }
            },
            computedOptions() {
                if (this.searchInput) {
                    if (this.keyword) {
                        return this.options.filter(el => el[this.TextField].toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1)
                    } else {
                        return this.options.filter(el => this.selected.includes(el[this.ValueField]))
                    }
                } else {
                    return this.options
                }
            }
        },
        methods: {
            toggleAll(val) {
                if (this.group && val) {
                    this.selected = _cloneDeep(this.optionsChildren)
                } else if (val) {
                    this.selected = this.computedOptions.map(item => {
                        if (item[this.ValueField] !== undefined) {
                            return item[this.ValueField]
                        }
                        return item
                    })
                } else {
                    this.selected = []
                }
            },
            toggle(data) {
                this.SelectAllCheck()
                this.$emit('input', data)
                this.focusInput()
            },
            SelectAllCheck() {
                if (this.value && this.value.length > 0) {
                    if (this.group && this.selected.length === this.optionsChildren.length) {
                        this.select_all_value = true
                    } else if (!this.group && this.selected.length === this.computedOptions.length) {
                        this.select_all_value = true
                    } else {
                        this.select_all_value = false
                    }
                } else {
                    this.select_all_value = false
                }
            },
            focusInput() {
                this.$nextTick(() => {
                    if (this.showSearch) {
                        this.$refs[this.component_input_id].focus()
                    }
                });
            },
            toggleAllItemsInGroup(index, event) {
                if (!this.group || !this.groupSelectAll || this.options.length < index + 1 || !this.options[index] || typeof this.options[index] !== "object" || !this.options[index].hasOwnProperty(this.group)) {
                    return
                }
                let items = this.options[index][this.group]
                items.forEach(item => {
                    let itemId = item[this.ValueField]
                    const index = this.selected.indexOf(itemId)
                    if (event && index < 0) {
                        this.selected.push(itemId)
                    } else if (!event && index >= 0) {
                        this.selected.splice(index, 1)
                    }
                })
                this.SelectAllCheck()
            },
            allItemsInGroupChecked(index) {
                if (!this.group || !this.groupSelectAll || this.options.length < index + 1 || !this.options[index] || typeof this.options[index] !== "object" || !this.options[index].hasOwnProperty(this.group)) {
                    return false
                }
                let items = this.options[index][this.group]
                let allChecked = true
                items.forEach(item => {
                    let itemId = item[this.ValueField]
                    const index = this.selected.indexOf(itemId)
                    allChecked = allChecked && index >= 0
                })
                return allChecked
            },
        },
        watch: {
            value: {
                handler(val, prev) {
                    if (!_isEqual(val, prev)) {
                        this.selected = val
                        this.SelectAllCheck()
                    }
                },
                immediate: true
            },
            selected(val) {
                if (this.group) {
                    this.$emit('input', val)
                }
            },
            options: {
                immediate: true,
                handler() {
                    if (this.setFirst) {
                        if (this.group && this.optionsChildren.length === 1) {
                            this.selected = [this.optionsChildren[0]]
                        } else if (!this.group && this.options.length === 1) {
                            this.selected = [this.options[0][this.ValueField]]
                            this.$emit('input', this.selected)
                        }
                    }
                }
            },
            keyword(value) {
                this.SelectAllCheck()
            }
        },
    }
</script>

<style scoped>

</style>
