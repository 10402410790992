export const CalendarRowMixin = {
    props: {
        scrollLeftPosition: {
            type: Number
        }
    },
    watch: {
        scrollLeftPosition: {
            handler(val) {

                this.$nextTick(() => {
                    if (this.$refs.calendar_scroll_container) {
                        if(Array.isArray(this.$refs.calendar_scroll_container)){
                            this.$refs.calendar_scroll_container.forEach(el => {
                                el.scrollLeft = val;
                            })
                        }else{
                            this.$refs.calendar_scroll_container.scrollLeft = val;
                        }

                    }
                })
            },
            immediate: true
        }
    }

}
