<template>
    <VueSlideoutPanel
        :value="value"
        :count="1"
        :widths="widths"
        :disableEscClick="false"
        :class="!scroll ? 'non-scroll' : ''"
        :classes="mainClasses"
        closeHtml=""
        @close="handleWindowCloseAside(true)">
        <b-container class="aside" :class="computedContainerClasses">
            <b-row :class="headerClasses" :style="headerStyles">
                <b-col cols="12" class="aside__header">
                    <h2 style="width: 95%">
                        <slot name="header"></slot>
                    </h2>

                    <slot name="header_action_button"></slot>

                    <a @click.prevent="handleWindowCloseAside(true)" class="btn-close aside__header--close pl-3" href="#"
                       v-if="!disable_close">
                        <i class='fa fa-times-thin aside__close--icon' aria-hidden='true'></i>
                    </a>
                </b-col>
            </b-row>
            <slot></slot>
        </b-container>
    </VueSlideoutPanel>
</template>

<script>
    // docs on https://lyollix.github.io/vue-slideout-panel-demo/
    import VueSlideoutPanel from 'vue-slideout-panel'
    import { v4 as uuidv4 } from 'uuid'
    import {cloneDeep} from "lodash";
    import {EventBus} from "@/shared/EventBus";

    export default {
        name: "AppAside",
        components: {
            VueSlideoutPanel
        },
        props: {
            value: {
                default: false,
                type: Boolean
            },
            widths: {
                default: () => {
                    return ['col-lg-5', 'col-sm-12', '40%']
                }
            },
            disable_close: {
                default: false,
                type: Boolean
            },
            headerClasses: {
                type: Array,
                default: () => {
                    return ['mb-3']
                }
            },
            headerStyles: {
                type: String,
                default: ''
            },
            containerClasses: {
                type: Array,
                default: () => {
                    return []
                }
            },
            fullWidth: {
                type: Boolean,
                default: false
            },
            scroll: {
                type: Boolean,
                default: true
            },
            bodyScroll: {
                type: Boolean,
                default: true
            },
            mainClasses: {
                type: Array,
                default: () => {
                    return ['p-0']
                }
            }
        },
        data() {
            return {
                uid: null,
                attribute_name: 'data-app-aside-list',
            }
        },
        computed: {
            computedContainerClasses() {
                let classes = cloneDeep(this.containerClasses)

                if (this.fullWidth) {
                    classes.push('no-max-width', 'w-90')
                }

                //todo fix this and remove all scroll prop logic,
                // unnecessary component pollution with specific component logic
                // workaround for inbox messages
                if (!this.scroll) {
                    classes.push('no-scroll')
                }

                return classes;
            }
        },
        watch: {
            value: {
                handler(val) {
                    this.setUid()
                    if (val) {
                        this.handleWindowOpenAside()
                        document.body.classList.add('no-scroll')
                    } else {
                        this.handleWindowCloseAside(false)
                    }
                },
                immediate: true,
            }
        },
        methods: {
            onEscapeKeypress(e) {
                if (!this.disable_close) {
                    if (e.keyCode === 27) {
                        let appAsideList = this.parseWindowAppAsideList()
                        if (appAsideList && appAsideList.length) {
                            const lastAppAsideUid = appAsideList[appAsideList.length - 1]
                            if (lastAppAsideUid && lastAppAsideUid === this.uid) {
                                this.handleWindowCloseAside(true)
                            }
                        }
                    }
                }
            },
            setWindowAppAsideList(appAsideList) {
                document.body.setAttribute(this.attribute_name, JSON.stringify(appAsideList))
            },
            parseWindowAppAsideList() {
                const appAsideListString = document.body.getAttribute(this.attribute_name) || '[]'
                return JSON.parse(appAsideListString)
            },
            handleWindowOpenAside() {
                let appAsideList = this.parseWindowAppAsideList()
                if (!appAsideList.includes(this.uid)) {
                    appAsideList.push(this.uid)
                    this.setWindowAppAsideList(appAsideList)
                }
                document.body.style.overflow = 'hidden'
            },
            handleWindowCloseAside(emit = true) {
                if (emit) {
                    this.$emit('input', false)
                }
                let appAsideList = this.parseWindowAppAsideList()
                if (appAsideList.includes(this.uid)) {
                    appAsideList = appAsideList.filter(uid => uid !== this.uid)
                    this.setWindowAppAsideList(appAsideList)
                }
                if (appAsideList.length === 0) {
                    document.body.style.overflow = 'visible'
                    if (this.bodyScroll) {
                        document.body.classList.remove('no-scroll')
                    }
                }
            },
            setUid() {
                if (this.uid === null) {
                    // this.uid = uuidv4()
                    this.uid = this._uid
                }
            },
        },
        created() {
            this.setUid()
            document.addEventListener('keydown', this.onEscapeKeypress)
        },
        destroyed() {
            this.handleWindowCloseAside(false)
            document.removeEventListener('keydown', this.onEscapeKeypress)
            if (this.bodyScroll) {
                document.body.classList.remove('no-scroll')
            }
        },
        beforeRouteLeave(to, from, next) {
            this.setWindowAppAsideList([])
            next()
        },
    }
</script>

<style>
    .no-scroll {
        overflow: hidden;
    }

    .non-scroll section.panel.default {
        height: 120%;
        overflow: hidden;
    }

    .blackout {
        z-index: 3000 !important;
    }
</style>
