<template>
    <div v-show="!loading" :key="'policy_list_' + cancellationPolicyList.length">
        <b-row v-if="cancellationPolicyList.length">
            <b-col sm="12" :key="index" v-for="(policy, index) in cancellationPolicyList">
                <b-card class="repeater-1">
                    <div slot="header">
                        <b-row>
                            <b-col>
                                <repeater-header class="d-flex align-items-center">
                                    {{ policy.label }}
                                    <edit-policy-button v-if="system_policy"
                                                        :v_show="checkPermission(C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_E)"
                                                        class="action_button"
                                                        :show_text="false"
                                                        variant="link"
                                                        button_type="edit"
                                                        @click="$emit('editPolicy', policy.id)">
                                    </edit-policy-button>
                                    <edit-policy-button v-else
                                                        :v_show="checkPermission(C_PROPERTY_CANCELATION_POLICY_E)"
                                                        :access_control_context="{function: C_PROPERTY_CANCELATION_POLICY_E, key: AC_PROPERTY_CANCELLATION_POLICY_KEY}"
                                                        @AccessControlEvent="addToAccessControlCounter()"
                                                        class="action_button"
                                                        :show_text="false"
                                                        variant="link"
                                                        button_type="edit"
                                                        @click="$emit('editPolicy', policy.id)">
                                    </edit-policy-button>
                                    <template v-if="!system_policy">
                                        <app-button v-if="policy.system_policy && policy.system_policy.id"
                                                    :v_show="checkPermission(C_PROPERTY_CANCELATION_POLICY_UNLINK_SYSTEM_POLICY)"
                                                    :access_control_context="{function: C_PROPERTY_CANCELATION_POLICY_UNLINK_SYSTEM_POLICY, key: AC_PROPERTY_CANCELLATION_POLICY_KEY}"
                                                    @AccessControlEvent="addToAccessControlCounter()"
                                                    class="action_button"
                                                    :show_text="false"
                                                    variant="link"
                                                    button_type="expand"
                                                    @click="unsetSystemPolicy(policy.id)">
                                        </app-button>
                                        <app-button v-else-if="!policy.system_policy || !policy.system_policy.id"
                                                    :v_show="checkPermission(C_PROPERTY_CANCELATION_POLICY_LINK_SYSTEM_POLICY)"
                                                    :access_control_context="{function: C_PROPERTY_CANCELATION_POLICY_LINK_SYSTEM_POLICY, key: AC_PROPERTY_CANCELLATION_POLICY_KEY}"
                                                    @AccessControlEvent="addToAccessControlCounter()"
                                                    class="action_button"
                                                    :show_text="false"
                                                    variant="link"
                                                    button_type="compress"
                                                    @click="$emit('linkPolicy', policy.id)">
                                        </app-button>

                                    </template>
                                </repeater-header>
                            </b-col>
                            <b-col class="ml-1">
                                <b-badge v-if="policy.tag && policy.tag.id" variant="primary" class="pull-right cursor-pointer">
                                    {{ policy.tag.label }}
                                    <i v-if="checkPermission(C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_TAG)"
                                       :id="'delete_tag'+policy.tag.id" @click="triggerDeleteTag(policy.id)"
                                       class="fa fa-shading fa-times fa-fw p-0 m-0 tag-delete"
                                       aria-hidden="true"> </i>
                                </b-badge>
                            </b-col>
                        </b-row>
                    </div>
                    <b-row class="mb-2">
                        <b-col>
                            <div class="pl-3">
                                {{ $t("FOC_RESERVATION_CANCELATION") }}
                                <i :class="policy.foc_cancelation ? 'fa fa-check text-success' : 'fa fa-times fa-fw text-danger'"
                                   aria-hidden="true"></i>
                            </div>
                        </b-col>
                    </b-row>
                    <b-table responsive small hover :fields="fields" :items="policy.conditions">
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col>
                <no-data :showTip="false">
                    <app-button
                        :access_control_context="{function:C_PROPERTY_CANCELLATION_POLICY_NEW,key:AC_PROPERTY_CANCELLATION_POLICY_KEY}"
                        @AccessControlEvent="addToAccessControlCounter()"
                        @click="$emit('openNewPolicy')"
                        button_type="new"
                        variant="primary"
                        class="pull-right">{{ $t('NEW_POLICY') }}
                    </app-button>
                    <app-button
                        :access_control_context="{function:C_PROPERTY_CANCELLATION_POLICY_APPLY_SYSTEM_POLICY,key:AC_PROPERTY_CANCELLATION_POLICY_KEY}"
                        @AccessControlEvent="addToAccessControlCounter()"
                        @click="$emit('openSysPolicyPanel')"
                        button_type="new"
                        variant="primary"
                        class="pull-right mr-3">
                        {{ $t('APPLY_POLICY') }}
                    </app-button>
                </no-data>
            </b-col>
        </b-row>
        <app-confirmation-dialog :show="unlinkPolicyDialogState"
                                 @confirm="unlinkSystemPolicy"
                                 @cancel="unlinkPolicyDialogState = false"
                                 :title="$t('UNLINK_CONFIRMATION_TITLE')">
            {{ $t("UNLINK_SYSTEM_POLICY_CONFIRMATION_MSG") }}
        </app-confirmation-dialog>
        <app-confirmation-dialog
            :show="deleteCustomTagConfirmDialog"
            :title="$t('REMOVE_TAG_CONFIRMATION')"
            :message="$t('REMOVE_TAG_MSG')"
            @confirm="deleteTag"
            @cancel="deleteCustomTagConfirmDialog=false">
        </app-confirmation-dialog>

    </div>
</template>

<script>
import CancelationConditionList from '@/components/property/cancelation_policies/condition/CancelationConditionList'
import CancelationPolicyItemEdit from "@/components/property/cancelation_policies/policy/CancelationPolicyItemEdit";
import editPolicyButton from '@/components/app/AppButton/AppButton'
import NoData from '@/components/app/AppNoData'
import {
    C_PROPERTY_CANCELATION_POLICY_E,
    C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_E,
    C_PROPERTY_CANCELATION_POLICY_LINK_SYSTEM_POLICY,
    C_PROPERTY_CANCELATION_POLICY_UNLINK_SYSTEM_POLICY,
    C_PROPERTY_CANCELLATION_POLICY_NEW,
    C_PROPERTY_CANCELLATION_POLICY_APPLY_SYSTEM_POLICY,
    C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_TAG
} from "@/shared/component_permission";
import FormHeader from "@/components/app/form/FormHeader";
import AppButton from "@/components/app/AppButton/AppButton";
import RepeaterHeader from "@/components/app/form/RepeaterHeader";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_PROPERTY_CANCELLATION_POLICY_KEY} from "@/mixins/AccessControl/AccessControlEnumeration";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {linkSystemPolicy} from "@/services/cancellation_policy";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {removeTagsForEntityList} from "@/services/tag";
import {
    OBJECT_TYPE_CANCELLATION_POLICY,
    OBJECT_TYPE_DOCUMENT,
    OBJECT_TYPE_TAG,
    TAG_TYPE_CANCELLATION_POLICY,
    TAG_TYPE_IMAGE
} from "@/shared/constants";
import {EventBus, GE_LOADER_HIDE, GE_LOADER_SHOW} from "@/shared/EventBus";


export default {
    name: "CancelationPolicyList",
    mixins: [AccessControlComponent, getErrorMessage],
    components: {
        AppConfirmationDialog,
        AppButton,
        RepeaterHeader,
        FormHeader,
        CancelationConditionList, editPolicyButton, NoData, CancelationPolicyItemEdit
    },
    data() {
        return {
            C_PROPERTY_CANCELATION_POLICY_E,
            C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_E,
            C_PROPERTY_CANCELATION_POLICY_LINK_SYSTEM_POLICY,
            C_PROPERTY_CANCELATION_POLICY_UNLINK_SYSTEM_POLICY,
            C_PROPERTY_CANCELLATION_POLICY_NEW,
            C_PROPERTY_CANCELLATION_POLICY_APPLY_SYSTEM_POLICY,
            C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_TAG,
            fields: [
                {key: 'condition_type.label', label: this.$t('CANCELLATION_CONDITION'), class: 'pl-3'},
                {key: 'day.label', label: this.$t('NUM_DAYS')},
                {key: 'day_type.label', label: this.$t('WHEN')},
                {key: 'charge.label', label: this.$t('CHARGE')},
            ],
            AC_PROPERTY_CANCELLATION_POLICY_KEY,
            policy_id: null,
            unlinkPolicyDialogState: false,
            systemPolicy: null,
            deleteCustomTagConfirmDialog: false
        }
    },
    props: {
        cancellationPolicyList: {
            type: Array,
            default: null
        },
        loading: {
            type: Boolean,
            default: true
        },
        system_policy: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        cancellationPolicyList: {
            handler() {
                this.access_control_components = this.system_policy ? 0 : (this.cancellationPolicyList.length > 0 ? this.cancellationPolicyList.length * 2 : 2)
            },
            deep: true,
        }
    },
    methods: {
        triggerDeleteTag(policyId) {
            this.deleteCustomTagConfirmDialog = true
            this.policy_id = policyId
        },
        deleteTag() {
            removeTagsForEntityList(OBJECT_TYPE_CANCELLATION_POLICY, TAG_TYPE_CANCELLATION_POLICY, [this.policy_id]).then(() => {
                notifySuccess()
                EventBus.$emit(GE_LOADER_SHOW)
                this.$emit('removeTag')

            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.deleteCustomTagConfirmDialog = false
                EventBus.$emit(GE_LOADER_HIDE)
            })
        },
        unsetSystemPolicy(policyId) {
            this.policy_id = policyId
            this.unlinkPolicyDialogState = true
        },
        unlinkSystemPolicy() {
            linkSystemPolicy(this.policy_id, {unlink: 1}).then(() => {
                notifySuccess()
                this.$emit('unlinkPolicy')
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.unlinkPolicyDialogState = false
            })
        }
    }
}
</script>

<style scoped>

</style>
