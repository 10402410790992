<template>
    <div>
        <app-button-submit class="search_overlay--button" @click="searchClick" button_type="search" variant="link">
            {{$t("SEARCH")}}
        </app-button-submit>
        <div class="search_overlay" :class="{'search_overlay--show' : overlay}">
            <div class="search_overlay--header">
                <div class="input_holder">
                    <b-spinner variant="primary" v-if="propertyLoading || reservationLoading"></b-spinner>
                    <i class="search_icon fa fa-search fa-fw" v-else></i>
                    <input
                        :placeholder="$t('SEARCH_SYSTEM_BY')"
                        type="text" ref="search"
                        class="search_overlay--search_input"
                        v-model="searchKeyword"
                        @input="onSearch">
                </div>
                <i class='fa fa-times-thin fa-5x search_overlay--close' @click="searchClose" aria-hidden='true'></i>
            </div>

            <template v-if="!showInitialSearchScreen">
                <b-row class="search_overlay--content"
                       v-if="(!reservationLoading && !propertyLoading) && (!propertyTotalRows && !reservationTotalRows) && !property">
                    <b-col class="search_overlay--content--icon">
                        <app-no-data :heading="$t('NO_SEARCH_RESULTS')"></app-no-data>
                    </b-col>
                </b-row>
                <b-row v-else class="search_overlay--content mt-5">
                    <template>
                        <b-col v-if="propertyTotalRows || property" class="pr-5">
                            <div v-if="property && property.hasOwnProperty('id') && property.id" class="d-flex align-items-baseline mb-4">
                                <h2 class="mr-3">{{ $t("INVENTORY") }} ({{ $t("BY_PROPERTY_ID") }})</h2>
                            </div>
                            <div v-if="property && property.hasOwnProperty('id') && property.id" class="align-items-baseline mb-5">
                                <ul class="pl-0">
                                    <app-search-property-item :keyword="searchKeyword" :key="property.id"
                                                              @close="searchClose"
                                                              :property="property"
                                    ></app-search-property-item>
                                </ul>
                            </div>
                            <div v-if="propertyTotalRows" class="d-flex align-items-baseline mb-4">
                                <h2 class="mr-3">{{$t("INVENTORY")}}</h2>

                            </div>
                            <div>
                                <ul class="pl-0">
                                    <app-search-property-item :keyword="searchKeyword" :key="propertyItem.id"
                                                              @close="searchClose"
                                                              :property="propertyItem"
                                                              v-for="propertyItem in list"></app-search-property-item>
                                </ul>
                                <app-pagination :listCount="propertyTotalRows" v-model="propertyPg"
                                                @input="getData"
                                                :per-page="propertyLimit"
                                                :last-page="propertyLastPage"></app-pagination>
                            </div>
                        </b-col>
                        <b-col v-if="reservationTotalRows" class="pl-5">
                            <div class="d-flex align-items-baseline mb-4">
                                <h2 class="mr-3">{{$t("RESERVATIONS")}}</h2>
                                <small v-if="!reservationLoading" class="results">{{$t("RESULTS", {number:
                                    reservationTotalRows})}}</small>
                            </div>
                            <div>
                                <ul class="pl-0">
                                    <app-search-reservation-item :keyword="searchKeyword" :key="reservation.id"
                                                                 :reservation="reservation"
                                                                 @close="searchClose"
                                                                 v-for="reservation in reservationList"></app-search-reservation-item>
                                </ul>
                                <app-pagination :listCount="reservationTotalRows"
                                                v-model="reservationPg"
                                                @input="getReservationData"
                                                :per-page="reservationLimit"
                                                :last-page="reservationLastPage"></app-pagination>
                            </div>
                        </b-col>
                    </template>
                </b-row>
            </template>
        </div>
    </div>
</template>

<script>
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import _debounce from 'lodash/debounce'
import {getPropertyList, propertyQuickSearch} from '@/services/property/index'
import {getAccommodationReservationList} from "@/services/accommodation_reservation";
import routes from '@/router/routeNames'
import AppSearchPropertyItem from "@/components/app/search/property/AppSearchPropertyItem";
import AppPagination from "@/components/app/filter/AppPagination";
import AppSearchReservationItem from "@/components/app/search/reservation/AppSearchReservationItem";
import axios from 'axios'
import AppNoData from "@/components/app/AppNoData";
import AppSearchData from "@/components/app/AppSearchData";

export default {
    name: "AppSearch",
    components: {
        AppSearchData,
        AppNoData, AppSearchReservationItem, AppPagination, AppSearchPropertyItem, AppButtonSubmit
    },
    data() {
        return {
            searchKeyword: '',
            overlay: false,
            RN_PROPERTY_OVERVIEW: routes.RN_PROPERTY_OVERVIEW,
            list: [],
            property: null,
            propertyPg: 1,
            propertyLimit: 5,
            propertyTotalRows: null,
            propertyLastPage: null,
            propertyLoading: false,
            reservationList: [],
            reservationPg: 1,
            reservationLimit: 5,
            reservationTotalRows: null,
            reservationLastPage: null,
            reservationLoading: false,
            cancelProperty: null,
            cancelReservation: null,
            showInitialSearchScreen: true
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        companyId() {
            if (this.company && this.company.hasOwnProperty('id')) {
                return this.company.id
            }
            return null
        }
    },
    methods: {
        searchClick() {
            this.overlay = true
            this.searchKeyword = null
            this.showInitialSearchScreen = true

            this.list = []
            this.reservationList = []
            this.propertyTotalRows = null
            this.reservationTotalRows = null
            this.property = null

            document.body.classList.add('no-scroll')
            this.focusElement()

        },
        searchClose() {
            this.overlay = false
            document.body.classList.remove('no-scroll')
        },
        handleKeyPress(e) {

            if (e.keyCode === 27) {
                // esc key
                this.searchClose()
            } else {
                this.focusElement()
            }
        },
        focusElement() {
            this.$nextTick(() => this.$refs.search.focus())
        },
        onSearch: _debounce(function () {
            this.showInitialSearchScreen = false
            this.propertyPg = 1
            this.reservationPg = 1
            this.cancelSearch()
            this.getData()
            this.getReservationData()
            this.getPropertyById()
        }, 500),
        getData() {
            this.focusElement()
            if (this.searchKeyword !== '') {
                this.propertyLoading = true
                this.cancelProperty = axios.CancelToken.source();
                propertyQuickSearch({
                    page: this.propertyPg,
                    limit: this.propertyLimit,
                    name: this.searchKeyword,
                    company: this.companyId
                }, this.cancelProperty.token).then(response => {
                    this.list = response.data.items
                    this.propertyTotalRows = response.data.total
                    this.propertyPg = response.data.page
                    this.propertyLastPage = response.data.last_page
                    this.cancelProperty = null
                    this.$nextTick(() => {
                        this.propertyLoading = false
                    })
                }, () => {
                    this.list = []
                    this.cancelProperty = null

                    this.$nextTick(() => {
                        this.propertyLoading = false
                    })
                })
            }
        },
        getPropertyById() {
            this.focusElement()
            if (this.searchKeyword !== '' && !isNaN(this.searchKeyword)){
                this.propertyLoading = true
                this.cancelProperty = axios.CancelToken.source();
                getPropertyList({
                    property: this.searchKeyword,
                    company: this.companyId,
                    quick_overview: 1
                }, this.cancelProperty.token).then(response => {
                    this.property = response.data.items.length === 1 ? response.data.items[0] : null
                    this.cancelProperty = null
                    this.$nextTick(() => {
                        this.propertyLoading = false
                    })
                }, () => {
                    this.property = null
                    this.cancelProperty = null

                    this.$nextTick(() => {
                        this.propertyLoading = false
                    })
                })
            }
            else this.property = null
        },
        getReservationData() {
            this.focusElement()
            if (this.searchKeyword !== '') {
                this.cancelReservation = axios.CancelToken.source()
                this.reservationLoading = true
                getAccommodationReservationList({
                    keyword: this.searchKeyword,
                    page: this.reservationPg,
                    perPage: this.reservationLimit,
                    company: this.companyId
                }, this.cancelReservation.token).then(response => {
                    this.reservationList = response.data.items
                    this.reservationTotalRows = response.data.total
                    this.reservationLastPage = response.data.last_page
                    this.cancelReservation = null
                    this.$nextTick(() => {
                        this.reservationLoading = false
                    })
                }, () => {
                    this.reservationList = []
                    this.cancelReservation = null
                    this.$nextTick(() => {
                        this.reservationLoading = false
                    })
                })
            }

        },
        cancelSearch() {
            if (this.cancelProperty) {
                this.cancelProperty.cancel('Start new search, stop active search');
            }
            if (this.cancelReservation) {
                this.cancelReservation.cancel('Start new search, stop active search');
            }
        }
    },

    mounted() {
        document.addEventListener('keydown', this.handleKeyPress);

    },
    destroyed() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
}
</script>

<style scoped>

</style>
