<template>
    <tr>
        <td>
            <app-select
                v-model="selectedLos.default"
                :options="yesNoOptions"
                :search-empty-item="false">
            </app-select>
        </td>
        <td>
            <app-number-input
                :min="1"
                v-model="selectedLos.min_stay">
            </app-number-input>
        </td>
        <td>
            <app-number-input
                :min="1"
                v-model="selectedLos.max_stay">
            </app-number-input>
        </td>
        <td>
            <app-number-input
                :allow-decimal="true"
                v-model="selectedLos.discount"
                v-show="!isDefault">
            </app-number-input>
        </td>
        <td>
            <app-select
                v-model="selectedLos.discount_type"
                :options="selectDiscountTypeOptions"
                value-field="value"
                text-field="text"
                v-show="previewRelationType"
                :search-empty-item="false"></app-select>
        </td>
        <td class="text-right">
            <save-los
                :access_control_context="{function: C_UNIT_CALENDAR_LOS_E, key: AC_UNIT_LOS}"
                @AccessControlEvent="addToAccessControlCounter()"
                button_type="new"
                @click="save"
                :loading="loading"
                :disabled="addNewButtonDisabled">
                {{ $t("ADD_ITEM") }}
            </save-los>
        </td>
    </tr>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import SaveLos from '@/components/app/AppButton/AppButton'
import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import {toast} from '@/shared/plugins/toastr'
import AppNumberInput from "@/components/app/form/AppNumberInput";
import {C_UNIT_CALENDAR_LOS_E} from "@/shared/component_permission";
import {AC_UNIT_LOS} from "@/mixins/AccessControl/AccessControlEnumeration";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {listHelpers} from "@/mixins/shared/helpers";
import {FIX, PERC} from "@/shared/constants";

export default {
    name: "UnitLosListItemNew",
    props: {
        loading: {
            type: Boolean
        }
    },
    components: {
        AppNumberInput,
        SaveLos,
        AppSelect,
    },
    mixins: [
        getErrorMessage,
        AccessControlComponent,
        listHelpers,
    ],
    data() {
        return {
            AC_UNIT_LOS,
            C_UNIT_CALENDAR_LOS_E,
            access_control_components: 1,
            selectedLos: {
                min_stay: null,
                max_stay: null,
                discount: null,
                discount_type: null,
                default: 0,
            },
            selectDiscountTypeOptions: [
                {value: PERC, text: "%"},
                {value: FIX, text: "fix"},
            ],
        }
    },
    computed: {
        isDefault() {
            return this.selectedLos.default === 1
        },
        previewRelationType() {
            return !this.isDefault && !Number.isNaN(this.selectedLos.discount) && Number(this.selectedLos.discount) === this.selectedLos.discount
        },
        addNewButtonDisabled() {
            return this.selectedLos.default === null || !this.selectedLos.min_stay
        }
    },
    methods: {
        save() {
            this.$emit("save", this.selectedLos)
        }
    },
    watch: {
        loading: {
            handler(value) {
                if (!value) {
                    this.selectedLos.min_stay = null
                    this.selectedLos.max_stay = null
                    this.selectedLos.discount = null
                    this.selectedLos.discount_type = null
                    this.selectedLos.default = 0
                }
            }
        },
        "selectedLos.default": {
            handler(value) {
                if (value === 1) {
                    this.selectedLos.discount = null
                    this.selectedLos.discount_type = null
                }
            }
        },
        "selectedLos.discount": {
            handler(value) {
                if (Number.isNaN(value) || Number(value) !== value) {
                    this.selectedLos.discount_type = null
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
