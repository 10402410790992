<template>

    <tr>
        <td style="width: 100%">
            <app-input-control :error-object="validationErrorObject.name">
                <template v-slot:input="data">
                    <b-input v-model="name"></b-input>
                </template>
            </app-input-control>
        </td>
        <td class="text-right">
            <app-button :disabled="disabled" :message="tooltip_message" :loading="loadingIcon" @click.native="submit" v-if="edited"></app-button>
        </td>
    </tr>
</template>

<script>
    import AppValidationDescription from "@/components/app/form/AppValidationDescription";
    import AppButton from "@/components/app/AppButton/AppButton";

    import {toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {PROPERTY_DATA_NAME_VALIDATION_FAILED} from "@/shared/error_codes";
    import AppInputControl from "@/components/app/AppInputControl";
    import {updateName} from "@/services/unit";
    import {EventBus, GE_UPDATE_QUICK_UNIT_LIST} from "@/shared/EventBus";


    export default {
        name: "RootItem",
        components: {AppInputControl, AppButton, AppValidationDescription},
        props: {
            unit: {
                type: Object
            },
            disabled:{
                type:Boolean,
                default:false
            },
            tooltip_message:{
                type:String
            }
        },
        mixins: [getErrorMessage],
        data() {
            return {
                name: null,
                loadingIcon: false,
                validationErrorObject: {
                    name: {
                        input: [PROPERTY_DATA_NAME_VALIDATION_FAILED]
                    }
                }
            }
        },
        computed: {
            edited() {
                return this.name !== this.unit.name
            }
        },
        methods: {
            submit() {
                this.loadingIcon = true

                updateName(this.unit.id, {name: this.name}).then(response => {

                    this.$store.commit('setUnit', response.data)
                    // quick link editing
                    EventBus.$emit(GE_UPDATE_QUICK_UNIT_LIST, response.data)
                    this.loadingIcon = false

                }, error => {
                    this.loadingIcon = false
                    this.showErrorMessages(error, this.validationErrorObject)
                })




            }
        },
        watch: {
            'unit.name': {
                handler(val) {
                    this.name = val
                },
                immediate: true
            }
        },
    }
</script>

<style scoped>

</style>
