<template>

    <app-table-v2 class="reservation_table"
                  search-event="getReservationActivities"
                  :fields="fields"
                  :provider="getData"
                  selectable :generic-download-fields="genericDownloadFields"
                  :show-limit="showLimit" @busyToggled="$emit('busyToggled', $event)">
        <template v-slot:date="data">
            <small class="text-muted d-block">{{data.item.activity_time | time}}</small>
            {{data.item.activity_date | date}}
        </template>

        <template v-slot:guest_name="data">
            {{data.item.guest_first_name}}
            {{data.item.guest_last_name}}
        </template>

        <template v-slot:pickup="data">
            <small class="text-muted d-block">{{data.item.pickup.time | time}}</small>
            {{data.item.pickup.name}}
        </template>

        <template v-slot:created_at="data">
            <small class="text-muted d-block">{{data.item.created_at|time}}</small>
            {{data.item.created_at | date}}
        </template>

        <template v-slot:actions="data">
            <app-button
                variant="link"
                button_type="pdf"
                :show_text="false"
                :disabled="downloading"
                @click="downloadPDF(data.item.id, data.item.pdf_hash)">
            </app-button>
        </template>
    </app-table-v2>
</template>

<script>
    import AppTableV2 from "@/components/app/AppTableV2";
    import {EventBus} from "@/shared/EventBus";
    import {cloneDeep} from 'lodash'
    import {downloadActivityConfirmationPdf, getActivityReservationList} from "@/services/activity";
    import AppButton from "@/components/app/AppButton/AppButton";

    export default {
        name: "ActivityReservationTable",
        components: {AppButton, AppTableV2},
        props: {
            searchOnCreate: {
                type: Boolean,
                default: false
            },
            searchOptions: {
                type: Object,
                default: null
            },
            showLimit: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                filter: {},
                genericDownloadFields: [
                    {key: 'id', label: this.$t("ID")},
                    {key: 'status', label: this.$t("STATUS")},
                    {key: 'activity_date', label: this.$t("_DATE")},
                    {key: 'guest_full_name', label: this.$t("GUEST_NAME")},
                    {key: 'message', label: this.$t("MESSAGE")},
                    {key: 'pickup.name', label: this.$t("PICKUP")},
                    {key: 'total', label: this.$t("TOTAL")},
                    {key: 'discount', label: this.$t("DISCOUNT")},
                    {key: 'commission', label: this.$t("COMMISSION")},
                    {key: 'created_at', label: this.$t("CREATED_AT")},
                ],
                downloading: false,
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            fields() {
                return [
                    {key: 'id', label: this.$t("ID")},
                    {key: 'status', label: this.$t("STATUS")},
                    {key: 'date', label: this.$t("_DATE"), sortable: true},
                    {key: 'guest_name', label: this.$t("GUEST_NAME")},
                    {key: 'message', label: this.$t("MESSAGE"), tdClass: 'remove_padding w-25'},
                    {key: 'pickup', label: this.$t("PICKUP")},
                    {key: 'total', label: this.$t("TOTAL")},
                    {key: 'discount', label: this.$t("DISCOUNT")},
                    {key: 'commission', label: this.$t("COMMISSION")},
                    {key: 'created_at', label: this.$t("CREATED_AT"), sortable: true},
                    {key: 'actions', label: ""},
                ]
            }
        },
        methods: {
            getData(filter) {
                let req = {
                    page: filter.currentPage,
                    perPage: filter.perPage,
                    order_by: filter.sortBy,
                    order_by_direction: filter.sortDirection,
                    company: this.company.id,
                    ...this.filter,
                }
                return getActivityReservationList(req).then(response => {
                    return {
                        data: {
                            ...response.data,
                            items: response.data.items.map(item => {
                                return {
                                    ...item,
                                    guest_full_name: item.guest_first_name + '\n' + item.guest_last_name
                                }
                            })
                        }
                    }

                })
            },
            downloadPDF(id, pdf_hash) {
                this.downloading = true
                downloadActivityConfirmationPdf(id, pdf_hash).then(response => {
                    const url = window.URL.createObjectURL(response);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${id}.pdf`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }).catch((error) => {
                    this.showErrorMessages(error)
                }).finally(() => this.downloading = false)
            },
        },
        mounted() {
            if (this.searchOptions !== null) {
                this.filter = {...this.filter, ...this.searchOptions}
            }

            if (this.searchOnCreate) {
                EventBus.$emit("getReservationActivities")
            }

            EventBus.$on('filterReservationActivities', (filter) => {
                this.filter = cloneDeep(filter)
                EventBus.$emit("getReservationActivities")
            })
        },
        beforeDestroy() {
            EventBus.$off('filterReservationActivities')
        }
    }
</script>

<style scoped>

</style>
