<template>

        <div v-if="ratePlans !== null">
            <form @submit.prevent="save">
                <b-row class="mb-4">
                    <b-col lg="6">
                        <label>
                            {{$t("RATE_PLAN")}}
                        </label>
                        <app-select v-model="rate_plan_id" :options="ratePlansOptGroup">
                        </app-select>
                    </b-col>
                    <b-col lg="6" v-if="ratePlanChildren.length">
                        <label>{{$t("APPLY_TO_RATE_PLANS")}}</label>
                        <app-select :disabled="rate_plan_id === null" mode="multiselect" v-model="add_rate_plans"
                                    :options="ratePlanChildren" value-field="id" text-field="label"></app-select>
                    </b-col>
                </b-row>
                <b-row class="mb-4" :key="restriction.id" v-for="(restriction) in restrictions">
                    <b-col :lg="closedOnArray.includes(restriction.id) ? 12 : 6">

                        <component :disabled="rate_plan_id === null"
                                   @input="setUpdate($event, restriction.id)"
                                   v-if="fields.hasOwnProperty(restriction.id)"
                                   :restriction="restriction"
                                   v-model="fields[restriction.id]['value']"
                                   :allowed_values="restriction.allowed_values.allowed_values"
                                   :is="restriction.allowed_values.element_type + 'Element'"></component>

                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col>
                        <app-submit :loading="loading_btn" :c_permission="c_edit"
                                    :disabled="rate_plan_id === null || dateRanges.length === 0 || !changesValid"></app-submit>
                    </b-col>
                </b-row>
            </form>
        </div>

</template>

<script>

    import SelectRestrictionFormElement from '@/components/unit/calendar/forms/restriction/SelectRestrictionFormElement'
    import CheckboxRestrictionFormElement
        from '@/components/unit/calendar/forms/restriction/CheckboxRestrictionFormElement'
    import AppSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import AppValidationDescription from '@/components/app/form/AppValidationDescription'
    import AppDropdownCheckbox from '@/components/app/form/AppDropdownCheckbox'

    import {getUnitCalendarValues, getUnitRestrictions, setUnitRestrictions} from "@/services/unit";
    import {normalize, schema} from "normalizr";
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {toast} from '@/shared/plugins/toastr'
    import moment from 'moment'
    import {C_PROPERTY_CALENDAR_RESTRICTIONS_E} from "@/shared/component_permission";
    import {
        SALES_ENABLED,
        CLOSED_ON_ARRIVAL,
        CLOSED_ON_DEPARTURE,
        MIN_STAY,
        MAX_STAY,
        RELEASE_PERIOD
    } from "@/shared/constants";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {each, has, get, cloneDeep, values} from 'lodash'
    import {EventBus, GE_CALENDAR_LOAD_RESTRICTIONS, GE_LOADER_HIDE, GE_LOADER_SHOW} from "@/shared/EventBus";


    export default {
        name: "CalendarAsideRestrictions",
        components: {
            AppSelect,
            'selectElement': SelectRestrictionFormElement,
            'checkboxElement': CheckboxRestrictionFormElement,
            AppSubmit,
            AppValidationDescription,
            AppDropdownCheckbox
        },
        mixins: [getErrorMessage],
        props: {
            ratePlans: {
                type: Array
            },
            ratePlan: {
                type: Number,
                default: null
            },
            unit: {
                type: Object
            },
            dateRanges: {
                type: Array
            },
            defaultValues: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                rate_plan_id: null,
                closedOnArray: [
                    CLOSED_ON_ARRIVAL,
                    CLOSED_ON_DEPARTURE,
                ],
                fields: {},
                fields_change: {},
                changes: [],
                loading_btn: false,
                restriction_keys: [],
                formEdited: false,
                add_rate_plans: [],
                c_edit: C_PROPERTY_CALENDAR_RESTRICTIONS_E,
                restrictionDefaultValues: {
                    MIN_STAY: {
                        id: MIN_STAY,
                        value: null,
                    },
                    MAX_STAY: {
                        id: MAX_STAY,
                        value: null,
                    },
                    RELEASE_PERIOD: {
                        id: RELEASE_PERIOD,
                        value: null,
                    },
                    CLOSED_ON_ARRIVAL: {
                        id: CLOSED_ON_ARRIVAL,
                        value: null,
                    },
                    CLOSED_ON_DEPARTURE: {
                        id: CLOSED_ON_DEPARTURE,
                        value: null,
                    },

                }
            }
        },
        methods: {
            setUpdate(value, restrictionId) {

                if (value === null) {
                    let index = this.changes.findIndex(restrictionId)
                    if (index >= 0) {
                        this.changes.splice(index, 1)
                    }
                } else {
                    if (!this.changes.includes(Number(restrictionId))) {
                        this.changes.push(Number(restrictionId))
                    }
                }

            },
            formatFields() {
                this.fields = {}
                for (let itemId in this.restrictions) {
                    if (this.restrictions.hasOwnProperty(itemId)) {
                        if (itemId == CLOSED_ON_ARRIVAL || itemId == CLOSED_ON_DEPARTURE) {
                            this.$set(this.fields, itemId, {id: itemId, value: "0"})
                        } else {
                            this.$set(this.fields, itemId, {id: itemId, value: null})
                        }

                    }
                }
            },
            save() {
                if (!this.changes.length) {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NO_CHANGE'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }

                let restrictionItems = []
                for (let itemId in this.fields) {
                    if (this.changes.includes(Number(itemId)) && this.fields.hasOwnProperty(itemId)) {
                        if (itemId == CLOSED_ON_ARRIVAL || itemId == CLOSED_ON_DEPARTURE) {
                            restrictionItems.push({
                                rate_plan: this.rate_plan_id,
                                restriction: itemId,
                                value: Number(this.fields[itemId].value)
                            })

                            if (this.add_rate_plans.length > 0) {
                                this.add_rate_plans.forEach(rateId => {
                                    restrictionItems.push({
                                        rate_plan: rateId,
                                        restriction: itemId,
                                        value: Number(this.fields[itemId].value)
                                    })
                                })
                            }

                        } else {
                            if (this.fields[itemId].value !== null) {
                                restrictionItems.push({
                                    rate_plan: this.rate_plan_id,
                                    restriction: itemId,
                                    value: Number(this.fields[itemId].value)
                                })

                                if (this.add_rate_plans.length > 0) {
                                    this.add_rate_plans.forEach(rateId => {
                                        restrictionItems.push({
                                            rate_plan: rateId,
                                            restriction: itemId,
                                            value: Number(this.fields[itemId].value)
                                        })
                                    })
                                }
                            }
                        }
                    }
                }

                let request = {
                    restrictions: []
                }

                this.dateRanges.forEach(periodItem => {
                    request.restrictions.push({
                        date_from: periodItem.start,
                        date_to: periodItem.end,
                        restrictions: restrictionItems
                    })
                })

                this.loading_btn = true
                setUnitRestrictions(this.unit.id, request).then(() => {

                        this.changes = []
                        EventBus.$emit(GE_CALENDAR_LOAD_RESTRICTIONS, {
                            unitId: this.unit.id,
                            ratePlan: this.rate_plan_id
                        })
                        // this.$store.dispatch(`calendar/unit_calendar_${this.unit_id}/actionSetRestrictions`)


                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })

                        this.loading_btn = false

                    },
                    error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                        this.loading_btn = false
                    })
            },
            filterRatePlanChildren(parentRatePlanId = null) {


                let finalArray = this.ratePlans.filter(rPlan => rPlan.parent.id === parentRatePlanId).map(item => {
                    return {
                        id: item.id,
                        apply_min_stay: item.apply_min_stay,
                        label: item.name + (item.distribution.name ? ` (${item.distribution.name})` : ''),
                    }
                })

                if (finalArray.length) {
                    finalArray.forEach(rPlanRec => {
                        finalArray = [...finalArray, ...this.filterRatePlanChildren(rPlanRec.id)]
                    })
                }

                return finalArray

            }
        },
        computed: {
            changesValid() {
                let val = false
                Object.keys(this.fields).forEach(restrictionId => {
                    if (restrictionId == CLOSED_ON_ARRIVAL || restrictionId == CLOSED_ON_DEPARTURE) {
                        if (this.fields[restrictionId].value != 0) {
                            val = true
                        }
                    } else {
                        if (this.fields[restrictionId].value !== null) {
                            val = true
                        }
                    }
                })
                return val
            },
            restrictions() {
                const restrictionList = this.$store.getters[`calendar/getRestrictions`]
                let filtered = {}
                for (const restrId in restrictionList) {
                    if (Number(restrId) === SALES_ENABLED || !restrictionList.hasOwnProperty(restrId)) {
                        continue
                    }
                    filtered[restrId] = restrictionList[restrId]
                }

                return filtered
            },
            ratePlanChildren() {
                let referenceArray = []

                if (this.rate_plan_id === this.unit.primary_rate_plan.id) {
                    referenceArray = this.filterRatePlanChildren(null)
                } else {
                    referenceArray = this.filterRatePlanChildren(this.rate_plan_id)
                }


                return referenceArray.filter(rPlan => rPlan.id !== this.rate_plan_id)
            },
            ratePlansOptGroup() {
                let list = {}
                const other = this.$t("OTHER")
                if (this.ratePlans && this.ratePlans.length > 0) {
                    this.ratePlans.forEach(currRatePlan => {
                        if (currRatePlan.distribution.name === null) {
                            if (!list.hasOwnProperty(other)) {
                                list[other] = []
                            }
                            list[other].push(currRatePlan)
                        } else {
                            if (!list.hasOwnProperty(currRatePlan.distribution.name)) {
                                list[currRatePlan.distribution.name] = []
                            }
                            list[currRatePlan.distribution.name].push(currRatePlan)
                        }
                    })
                }


                return list
            }
        },
        watch: {
            restrictions: {
                handler(value) {
                    if (value) {
                        this.formatFields()
                    }
                },
                deep: true,
                immediate: true
            },
            rate_plan_id: {
                handler(newval, oldval) {
                    this.add_rate_plans = []


                    if(oldval && newval){
                        EventBus.$emit(GE_LOADER_SHOW)
                        getUnitCalendarValues(this.unit.id, {
                            periods: this.dateRanges,
                            rate_plan: newval,
                            show: ['restrictions']
                        }).then(response => {
                            if (has(response, 'data.restriction')) {
                                this.restrictionDefaultValues = cloneDeep(response.data.restriction)
                            }

                        }).finally(() => {
                            EventBus.$emit(GE_LOADER_HIDE)
                        })
                    }
                }
            },
            ratePlanChildren: {
                handler(list) {
                    this.add_rate_plans = list.filter(element => element.apply_min_stay === 1).map(element => element.id)
                },
                deep: true
            },
            defaultValues: {
                handler(defaultValue) {
                    if (defaultValue && defaultValue.hasOwnProperty('restriction')) {
                        this.restrictionDefaultValues = cloneDeep(defaultValue.restriction)
                    }
                },
                immediate: true,
                deep: true
            },
            restrictionDefaultValues: {
                handler(values) {
                    each(values, (restriction, restrictionId) => {
                        if (has(this.fields, `${restrictionId}.value`)) {
                            this.fields[restrictionId]['value'] = restriction.value
                        }
                    })
                },
                immediate: true,
                deep: true
            }

        },
        created() {
            this.rate_plan_id = cloneDeep(this.ratePlan)
        }
    }
</script>

<style scoped>

</style>
