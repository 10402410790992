<template>
    <div>
        <app-table-v2
            :provider="getData"
            @busyToggled="toggleBusy"
            :fields="fields">

            <template v-slot:edit="data">
                <app-button v-if="distribution_id"
                            @click="openAside(data)"
                            variant="link"
                            button_type="edit"
                            :show_text="false">
                </app-button>
                <app-button v-if="setup_parameter && setup_parameter !== SETUP_PARAMETER_DATA_TRANSITION_AFTER_EVENT"
                            @click="openParameter(data)"
                            variant="link"
                            button_type="edit"
                            :show_text="false">
                </app-button>
                <app-button v-if="setup_parameter && setup_parameter === SETUP_PARAMETER_DATA_TRANSITION_AFTER_EVENT"
                            @click="openDataTransition(data)"
                            variant="link"
                            button_type="edit"
                            :show_text="false">
                </app-button>
            </template>
        </app-table-v2>
        <app-aside v-model="showSetupParameters">
            <template slot="header">
                <app-object-header
                    :name="asideHeader"
                    :label="$t('OWNER')">
                </app-object-header>
            </template>
            <template v-if="channelList.length" slot="header_action_button">
                <div class="aside__header--action_button col-4 mr-3">
                    <label class="label">{{ $t('CHANNEL') }}</label>
                    <app-select mode="select" :options="channelList" v-model="selectedChannel" value-field="id" text-field="label" :search-empty-item="false"></app-select>
                </div>
            </template>
            <reservation-setup-parameter :parameter_id="setup_parameter" :owner_id="ownerId" :context_company="company.id" @saved="showSetupParameters=false"></reservation-setup-parameter>
        </app-aside>
        <app-aside v-model="dataTransitionSidebar">
            <template slot="header">
                <app-object-header
                    :name="asideHeader"
                    :label="$t('OWNER')">
                </app-object-header>
            </template>
            <reservation-process-data-transition :parameter_id="setup_parameter" :owner_id="ownerId" @saved="dataTransitionSidebar=false"></reservation-process-data-transition>
        </app-aside>
        <app-aside v-model="showAside">
            <template slot="header">
                <app-object-header
                    :name="asideHeader"
                    :label="$t('OWNER')">
                </app-object-header>
            </template>
            <template v-if="channelList.length" slot="header_action_button">
                <div class="aside__header--action_button col-4 mr-3">
                    <label class="label">{{ $t('CHANNEL') }}</label>
                    <app-select mode="select" :options="channelList" v-model="selectedChannel" value-field="id" text-field="label" :search-empty-item="false"></app-select>
                </div>
            </template>
            <distribution-reservation-sidebar :distribution_id="selectedChannel ? null : distribution_id" :distribution_context="selectedChannel ? distribution_id : null"
                                              @saved="showAside=false"
                                              :owner_id="ownerId"></distribution-reservation-sidebar>
        </app-aside>
    </div>
</template>

<script>
import routeNames from "@/router/routeNames";
import {R_PROPERTY_OWNER_BASIC} from "@/shared/route_permission";
import {getPaginatedOwnerList} from "@/services/owner";
import {EventBus, GE_TABLE_SEARCH} from "@/shared/EventBus";
import { getDistributionChannelList} from "@/services/distribution";
import {DISTRIBUTION_MANAGERS, SETUP_PARAMETER_DATA_TRANSITION_AFTER_EVENT} from "@/shared/constants";


export default {
    name: "OwnerTable",
    components: {
        "ReservationProcessDataTransition": () => import("@/components/reservation/setup/ReservationProcessDataTransition"),
        "ReservationSetupParameter": () => import("@/components/reservation/setup/ReservationSetupParameter"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "DistributionReservationSidebar": () => import("@/components/channel_manager/distribution/DistributionReservationSidebar"),
        "AppObjectHeader": () => import("@/components/app/AppObjectHeader"),
        "AppTableV2": () => import("@/components/app/AppTableV2"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "AppAside": () => import("@/components/app/form/AppAside")
    },
    props: {
        filter: {
            type: Object
        },
        distribution_id: {
            type: Number,
            default: null
        },
        setup_parameter: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            routeNames,
            r_permission_basic: R_PROPERTY_OWNER_BASIC,
            SETUP_PARAMETER_DATA_TRANSITION_AFTER_EVENT,
            fields: [
                {key: 'id', label: this.$t('ID')},
                {key: 'full_name', label: this.$t('FIRST_NAME_LAST_NAME')},
                {key: 'oib', label: this.$t('VAT_NUMBER')},
                {key: 'registration_number', label: this.$t('REGISTRATION_NUMBER')},
                {key: 'person_type', label: this.$t('ENTITY_TYPE')},
                {key: 'self_issue', label: this.$t('SELF_ISSUE')},
                {key: 'vat_system', label: this.$t('REGISTERED_FOR_VAT')},
                {key: 'edit', label: '', tdClass: 'text-right'},
            ],
            loading: true,
            list: [],
            showAside: false,
            ownerId: null,
            asideHeader: null,
            distribution: { id: null, name: null},
            selectedChannel: null,
            channelList: [],
            showSetupParameters: false,
            dataTransitionSidebar: false
        }
    },
    methods: {
        getData(filter) {
            let req = {
                ...this.filter,
                ...{
                    page: filter.currentPage,
                    perPage: filter.perPage,
                    order_by: filter.sortBy,
                    order_by_direction: filter.sortDirection
                }
            }
            return getPaginatedOwnerList(req)
        },
        toggleBusy($event) {
            this.$emit("busyToggled", $event)
        },
        openAside(data) {
            this.asideHeader = data.item.full_name
            this.ownerId = data.item.id
            this.showAside = true
        },
        openParameter(data) {
            this.asideHeader = data.item.full_name
            this.ownerId = data.item.id
            this.showSetupParameters = true
        },
        openDataTransition(data) {
            this.asideHeader = data.item.full_name
            this.ownerId = data.item.id
            this.dataTransitionSidebar = true
        },
        getChannelList() {
            getDistributionChannelList({distribution_id: this.distribution_id}).then(response => {
                this.channelList = response.data
                this.selectedChannel = this.channelList[Object.keys(this.channelList)[0]].id
            })
        },
    },
    watch: {
        filter: {
            handler() {
                EventBus.$emit(GE_TABLE_SEARCH)
            },
            deep: true
        },
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    created() {
        if (DISTRIBUTION_MANAGERS.includes(this.distribution_id)) {
            this.getChannelList()
        }
    },
    mounted() {

    }
}
</script>

<style scoped>

</style>
