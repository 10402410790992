<template>
    <transition name="fade" v-if="loaded">
        <div>
            <div class="cc-timer" :class="{'slide-in': loaded && chdId}">{{countdown}}</div>
            <b-form @submit.prevent="save">
                <b-row v-if="maximumRequirements">
                    <b-col>
                        <form-header>{{$t('CARDHOLDER_DATA')}}</form-header>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col :md="maximumRequirements ? 6 : 12" class="mb-4">
                        <app-input-control :error-object="validation.ch_name">
                            <template v-slot:input="data">
                                <label>{{ $t('FULL_NAME') }}</label>
                                <b-input v-model="formData.ch_name" :state="data.state" :disabled="!edit"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>

                    <b-col md="6" class="mb-4" v-if="maximumRequirements">
                        <app-input-control :error-object="validation.ch_address">
                            <template v-slot:input="data">
                                <label>{{ $t('ADDRESS') }}</label>
                                <b-input v-model="formData.ch_address" :state="data.state" :disabled="!edit"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>

                <b-row v-if="maximumRequirements">
                    <b-col md="6" class="mb-4">
                        <app-input-control :error-object="validation.ch_city">
                            <template v-slot:input="data">
                                <label>{{ $t('CITY') }}</label>
                                <b-input v-model="formData.ch_city" :state="data.state" :disabled="!edit"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>

                    <b-col md="6" class="mb-4">
                        <app-input-control :error-object="validation.ch_country">
                            <template v-slot:input="data">
                                <label>{{ $t('COUNTRY') }}</label>
                                <b-input v-model="formData.ch_country" :state="data.state" :disabled="!edit"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>

                <b-row v-if="maximumRequirements">
                    <b-col>
                        <form-header>{{$t('CC_DATA')}}</form-header>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="4" class="mb-4" v-if="maximumRequirements">
                        <app-input-control :error-object="validation.type">
                            <template v-slot:input="data">
                                <label>{{ $t('CC_TYPE') }}</label>
                                <app-select :options="ccTypeList"
                                            value-field="key"
                                            text-field="label"
                                            v-model="formData.type"
                                            :state="data.state"
                                            :disabled="!edit"
                                            :search-empty-item="false">
                                </app-select>
                            </template>
                        </app-input-control>
                    </b-col>

                    <b-col :md="maximumRequirements ? 8 : 12" class="mb-4">
                        <app-input-control :error-object="validation.number">
                            <template v-slot:input="data">
                                <label>{{ $t('CC_NUMBER') }}</label>
                                <b-input v-model="formData.number" :state="data.state" :disabled="!edit"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="6" class="mb-4">
                        <app-input-control :error-object="validation.cc_expiration_date">
                            <template v-slot:input="data">
                                <label>{{$t('EXPIRATION_DATE')}}</label>
                                <div class="d-flex">
                                    <app-select class="cc-expiration-input"
                                                :options="expYearList"
                                                v-model="expYear"
                                                value-field="key"
                                                text-field="label"
                                                :disabled="!edit"
                                                :state="data.state">
                                    </app-select>

                                    <span class="cc-expiration-slash">/</span>

                                    <app-select class="cc-expiration-input"
                                                :options="expMonthList"
                                                v-model="expMonth"
                                                value-field="key"
                                                text-field="label"
                                                :disabled="!edit || !expMonthList.length"
                                                :state="data.state">
                                    </app-select>
                                </div>
                            </template>
                        </app-input-control>
                    </b-col>

                    <b-col md="6" class="mb-4">
                        <app-input-control :error-object="validation.cvv">
                            <template v-slot:input="data">
                                <label>CVV</label>
                                <b-input v-model="formData.cvv" :state="data.state" :disabled="!edit"></b-input>
                            </template>
                        </app-input-control>

                    </b-col>
                </b-row>

                <b-row v-if="edit">
                    <b-col>
                        <app-button-submit :loading="saving"></app-button-submit>
                    </b-col>
                </b-row>
            </b-form>
        </div>
    </transition>
    <div v-else class="cc-spinner">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
</template>

<script>
    import AppInputControl from "@/components/app/AppInputControl";
    import FormHeader from "@/components/app/form/FormHeader";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getCreditCardTypes, getCreditCard, saveCreditCard, updateCreditCard} from "@/services/finance";
    import {getAccommodationReservationCCData, saveAccommodationReservationCCData} from "@/services/accommodation_reservation";
    import {OBJECT_TYPE_ACCOMMODATION_RESERVATION} from "@/shared/constants";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {
        CC_EMPTY_CVV,
        CC_EMPTY_EXPIRATION_DATE,
        CC_EMPTY_NAME,
        CC_EMPTY_NUMBER,
        CC_EMPTY_TYPE
    } from "@/shared/error_codes";


    const NOW = new Date()
    const MONTH_LIST = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    const YEAR_LIST = getYearList()
    const [VIEW_TIMER, EDIT_TIMER] = [30, 60]

    function getYearList() {
        let list = []
        for (let i = NOW.getFullYear(); i <= NOW.getFullYear() + 10; i++) {
            list.push(i)
        }
        return list
    }

    export default {
        name: "CreditCardForm",
        components: {AppButtonSubmit, AppSelect, FormHeader, AppInputControl},
        mixins: [getErrorMessage],
        props: {
            edit: {
                type: Boolean,
                required: true
            },
            objectType: {
                required: true
            },
            objectId: {
                required: true
            },
            chdId: {
                type: Number,
                default: null
            },
            chdData: {
                type: Object,
                default: null
            },
            maximumRequirements: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                loaded: false,
                ccTypeList: [],
                expMonthList: [],
                formData: {
                    ch_name: null,
                    ch_address: null,
                    ch_city: null,
                    ch_country: null,
                    type: null,
                    number: null,
                    cc_expiration_date: null,
                    cvv: null,
                },
                validation: {
                    ch_name: CC_EMPTY_NAME,
                    ch_address: {},
                    ch_city: {},
                    ch_country: {},
                    type: CC_EMPTY_TYPE,
                    number: CC_EMPTY_NUMBER,
                    cc_expiration_date: CC_EMPTY_EXPIRATION_DATE,
                    cvv: CC_EMPTY_CVV,
                },
                expYear: null,
                expMonth: null,
                saving: false,
                countdown: null,
            }
        },
        computed: {
            expYearList() {
                return YEAR_LIST.map(year => {
                    return {key: year, label: year}
                })
            },
            ccExpirationDate() {
                if (this.expYear && this.expMonth) {
                    return `${this.expMonth}/${this.expYear}`
                } else {
                    return null
                }
            }
        },
        watch: {
            expYear: {
                immediate: true,
                handler(value) {
                    if (value) {
                        if (YEAR_LIST.indexOf(value) === 0) {
                            this.expMonthList = MONTH_LIST
                                .filter(month => parseInt(month) >= (NOW.getMonth() + 1))
                                .map(month => {
                                    return {key: month, label: month}
                                })
                        } else {
                            this.expMonthList = MONTH_LIST.map(month => {
                                return {key: month, label: month}
                            })
                        }
                    } else {
                        this.expMonthList = []
                    }
                }
            }
        },
        methods: {
            save() {
                if (this.objectType === OBJECT_TYPE_ACCOMMODATION_RESERVATION) {
                    this.saving = true
                    saveAccommodationReservationCCData(this.objectId, {
                        ...this.formData,
                        cc_expiration_date: this.ccExpirationDate
                    }).then(response => {
                        notifySuccess()
                        this.chdId ? this.$emit('updated') : this.$emit('created', response.data)
                    }).catch(error => {
                        this.showErrorMessages(error, this.validation)
                    }).finally(() => this.saving = false)
                } else {
                    if (this.chdId) {
                        this.saving = true
                        updateCreditCard(this.chdId, {
                            ...this.formData,
                            cc_expiration_date: this.ccExpirationDate,
                            object_type: this.objectType,
                            object: this.objectId,
                        }).then(response => {
                            notifySuccess()
                            this.$emit('updated', response.data)
                        }).catch(error => {
                            this.showErrorMessages(error, this.validation)
                        }).finally(() => this.saving = false)
                    } else {
                        this.saving = true
                        saveCreditCard({
                            ...this.formData,
                            cc_expiration_date: this.ccExpirationDate,
                            object_type: this.objectType,
                            object: this.objectId,
                        }).then(response => {
                            notifySuccess()
                            this.$emit('created', response.data)
                        }).catch(error => {
                            this.showErrorMessages(error, this.validation)
                        }).finally(() => this.saving = false)
                    }
                }
            },
            startCountdownToClose(numSeconds) {
                this.countdown = numSeconds
                let timer = setInterval(() => {
                    this.countdown--
                    if (this.countdown === 0) {
                        clearInterval(timer)
                        this.$emit('close')
                    }
                }, 1000)
            }
        },
        created() {
            getCreditCardTypes().then(response => {
                this.ccTypeList = response.data
            })

            if (this.objectType === OBJECT_TYPE_ACCOMMODATION_RESERVATION) {
                if (this.chdId) {
                    getAccommodationReservationCCData(this.objectId).then(response => {
                        if (!response.data.id) {
                            this.$emit('close')
                            return
                        }
                        const {type, cc_expiration_date, ...rest} = response.data
                        this.formData = {...this.formData, ...rest}
                        this.formData.type = type.key
                        ;([this.expMonth, this.expYear] = cc_expiration_date.split('/'))
                        // ne diraj ;
                        // https://stackoverflow.com/questions/27386234/object-destructuring-without-var/56068605#56068605
                        this.startCountdownToClose(this.edit ? EDIT_TIMER : VIEW_TIMER)
                        this.loaded = true
                    }).catch(error => {
                        this.showErrorMessages(error)
                        this.$emit('close')
                    })
                } else {
                    this.formData.reservation = this.objectId
                    this.loaded = true
                }
            } else {
                if (this.chdId) {
                    if (this.chdData === null) {
                        getCreditCard(this.chdId).then(response => {
                            if (!response.data.id) {
                                this.$emit('close')
                                return
                            }
                            const {type, cc_expiration_date, ...rest} = response.data
                            this.formData = {...this.formData, ...rest}
                            this.formData.type = type.key
                            ;([this.expMonth, this.expYear] = cc_expiration_date.split('/'))
                            // ne diraj ;
                            // https://stackoverflow.com/questions/27386234/object-destructuring-without-var/56068605#56068605
                            this.startCountdownToClose(this.edit ? EDIT_TIMER : VIEW_TIMER)
                            this.loaded = true
                        }).catch(error => {
                            this.showErrorMessages(error)
                            this.$emit('close')
                        })
                    } else {
                        this.formData = {...this.formData, ...this.chdData}
                        this.startCountdownToClose(this.edit ? EDIT_TIMER : VIEW_TIMER)
                        this.loaded = true
                    }
                } else {
                    this.loaded = true
                }
            }
        }
    }
</script>

<style scoped>
    .cc-expiration-input {
        width: 43%;
    }

    .cc-expiration-slash {
        width: 14%;
        align-self: center;
        text-align: center;
    }

    .cc-timer {
        position: absolute;
        bottom: 0;
        right: 0;
        text-align: center;
        width: 60px;
        padding: 8px 0;
        background: #FB6B41;
        color: white;
        border-top-left-radius: 10px;
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
    }

    .slide-in {
        animation: 0.5s slide-in forwards;
        -webkit-animation: 0.5s slide-in forwards;
    }

    .cc-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
    }

    @keyframes slide-in {
        100% {
            transform: translateX(0%);
        }
    }

    @-webkit-keyframes slide-in {
        100% {
            -webkit-transform: translateX(0%);
        }
    }

</style>
