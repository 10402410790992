<template>
    <form @submit.prevent="search" class="filter" autocomplete="off">
        <b-row>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('NAME')}}</label>
                <b-form-input type="text" v-model="filter.name"></b-form-input>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('CODE')}}</label>
                <b-form-input type="text" v-model="filter.code"></b-form-input>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('CATEGORY')}}</label>
                <app-select :options="categories"
                            v-model="filter.category"
                            :search-empty-item="false"
                            mode="multiselect"
                ></app-select>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('FOR_DATES')}}</label>
                <app-date-range-picker v-model="periodUse"></app-date-range-picker>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('VALID_THROUGH')}}</label>
                <app-date-range-picker v-model="periodValid"></app-date-range-picker>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3" v-if="websiteTable">
                <label>{{$t('FILTER')}}</label>
                <app-select :options="activeOptions"
                            v-model="filter.active_website"
                            text-field="label"
                            :search-empty-item="false"
                            mode="select"
                ></app-select>
            </b-col>
            <b-col xl="4" lg="4" md="6" class="mb-3">
                <app-button-submit class="filter-submit-wrapper" :inline="true" :disabled="loading" :loading="loaderSubmit"
                            variant="primary"
                            button_type="search">{{ $t("SEARCH") }}
                </app-button-submit>

                <app-button-reset @click="resetForm" :disabled="loading" :loading="loaderReset" :inline="true"
                                  class="pl-2 ml-2"></app-button-reset>
            </b-col>
        </b-row>
    </form>
</template>

<script>

import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import {fetchPromoCategories} from "@/services/promo_codes";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";

export default {
    name: "PromoCodesFilter",
    components:{AppButtonSubmit, AppButtonReset, AppSelect, AppDatePicker, AppDateRangePicker},
    mixins:[getErrorMessage],
    props:{
        loading:{
            type: Boolean,
            default: false
        },
        websiteTable:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            loaderSubmit: false,
            loaderReset: false,
            categories: [],
            reset: 0,
            activeOptions:[
                {id: 1, label:this.$t('ACTIVE')},
                {id: 2, label:this.$t('INACTIVE')},
            ],
            filter:{
                code: null,
                name: null,
                category: [],
                for_date_start: null,
                for_date_end: null,
                valid_date_start: null,
                valid_date_end: null,
                active_website: null
            },
        }
    },
    computed:{
        periodUse: {
            get() {
                return {
                    start: this.filter.for_date_start,
                    end: this.filter.for_date_end
                }
            },
            set({start, end}) {
                this.filter.for_date_start = start
                this.filter.for_date_end = end
            }
        },
        periodValid: {
            get() {
                return {
                    start: this.filter.valid_date_start,
                    end: this.filter.valid_date_end
                }
            },
            set({start, end}) {
                this.filter.valid_date_start = start
                this.filter.valid_date_end = end
            }
        },
    },
    methods:{
        search(){
            this.reset = 0
            this.$emit('search', {...this.filter})
        },
        resetForm(){
            this.reset = 1
            this.filter.code = null
            this.filter.category = []
            this.filter.for_date_start = null
            this.filter.for_date_end = null
            this.filter.valid_date_start = null
            this.filter.valid_date_end = null
            this.filter.active_website = null
            this.filter.name = null
            this.$emit('search', {...this.filter})
        },
    },
    created(){
        fetchPromoCategories().then(response => {
            this.categories = response.data
        }, error => {
            this.showErrorMessages(error)
        })
    },
    watch:{
        loading: {
            handler(value) {
                if (this.reset) {
                    this.loaderReset = value
                } else {
                    this.loaderSubmit = value
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
