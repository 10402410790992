<template>
    <li class="b-b-1 mb-3 pb-3">
        <div class="d-flex justify-content-between">
            <div>
                <span class="font-weight-bolder b-r-1 mr-2 pr-2" v-html="highlightResCode"></span>

                <span class="b-r-1 mr-2 pr-2">
                    <b-link v-if="checkPermission(R_PROPERTY_CALENDAR_DEV) && reservation.unit.id && reservation.property.id"
                                            :to="getCalendarLink(reservation)"
                                            target="_blank">
                    {{ reservation.check_in_date | date(false) }} - {{ reservation.check_out_date | date }}
                    </b-link>
                    <template v-else>
                        {{ reservation.check_in_date | date(false) }} - {{ reservation.check_out_date | date }}
                    </template>
                </span>
                <span class="b-r-1 mr-2 pr-2">
                    <b-link
                        v-if="checkPermission(C_RESERVATION_ACCOMMODATION_MODULES_INFO) && reservation.unit.id"
                        :to="{name: routes.RN_RESERVATION_ACCOMMODATION_LIST, query:{reservation: reservation.id, tab: 'info'}}">
                        {{ reservation.guest.name }}
                    </b-link>
                    <template v-else>
                        {{ reservation.guest.name }}
                    </template>
                </span>
                <span class="b-r-1 mr-2 pr-2"
                      v-if="reservation.hasOwnProperty('channel') && reservation.channel && reservation.channel.hasOwnProperty('name')">
                    {{ reservation.channel.name }}
                </span>
                <span class="b-r-1 mr-2 pr-2">{{ reservation.status.name }}</span>
                <span class="b-r-1 mr-2 pr-2">
                    <b-link v-if="checkPermission(R_PROPERTY_BASIC) && reservation.property.id"
                            :to="{name: routes.RN_PROPERTY_BASIC, params: {id: reservation.property.id}}"
                            target="_blank">
                        {{ reservation.property.name }}
                    </b-link>
                    <template v-else>
                        {{ reservation.property.name }}
                    </template>
                </span>
                <span>
                    <b-link v-if="checkPermission(R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD)"
                            :to="{name: routes.RN_RESERVATION_ACCOMMODATION_LIST, query:{reservation: reservation.id, tab: 'finance'}}">
                        {{ reservation.company_total }}
                    </b-link>
                    <template v-else>
                        {{ reservation.company_total }}
                    </template>
                </span>
            </div>
            <div class="ws-no-wrap">
                <!--                <app-button v-if="checkPermission(R_RESERVATION_ACCOMMODATION_LIST)" variant="link"-->
                <!--                            :button_type="null"-->

                <!--                            @click="$emit('close')"-->
                <!--                            class="action_button ml-2"-->
                <!--                            :to="{name: routes.RN_RESERVATION_ACCOMMODATION_LIST, query:{reservation: reservation.id}}">-->
                <!--                    <i class="fa fa-list" aria-hidden="true"></i>-->
                <!--                </app-button>-->
                <app-button v-if="checkPermission(R_RESERVATION_ACCOMMODATION_GENERAL)" variant="link"
                            :button_type="null"
                            @click="$emit('close')"
                            class="action_button ml-2"
                            :to="{name: routes.RN_RESERVATION_ACCOMMODATION_LIST, query:{reservation: reservation.id,reservation_code:reservation.reservation_code, tab: 'general'}}">
                    {{ $t("OVERVIEW") }}
                </app-button>

                <app-button v-if="checkPermission(C_RESERVATION_ACCOMMODATION_MODULES_INFO) && reservation.unit.id"
                            variant="link"
                            :button_type="null"
                            @click="$emit('close')"
                            class="action_button b-l-1 ml-2 pl-2"
                            :to="{name: routes.RN_RESERVATION_ACCOMMODATION_LIST, query:{reservation: reservation.id ,reservation_code:reservation.reservation_code, tab: 'info'}}">
                    Info
                </app-button>
            </div>
        </div>
    </li>
</template>

<script>
import routes from '@/router/routeNames'
import AppButton from "@/components/app/AppButton/AppButton";
import {
    R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD,
    R_RESERVATION_ACCOMMODATION_GENERAL,
    R_RESERVATION_ACCOMMODATION_LIST,
    R_PROPERTY_BASIC, R_PROPERTY_CALENDAR_DEV
} from "@/shared/route_permission";
import {C_RESERVATION_ACCOMMODATION_MODULES_INFO} from "@/shared/component_permission";
import {WORKFLOW_DIRECT_BOOKER} from "@/shared/constants";
import routeNames from "@/router/routeNames";
import moment from 'moment';

export default {
    name: "AppSearchReservationItem",
    components: {AppButton},

    props: {
        reservation: {
            type: Object
        },
        keyword: {
            type: String
        }
    },
    data() {
        return {
            routes: routes,
            R_RESERVATION_ACCOMMODATION_GENERAL: R_RESERVATION_ACCOMMODATION_GENERAL,
            R_RESERVATION_ACCOMMODATION_LIST: R_RESERVATION_ACCOMMODATION_LIST,
            C_RESERVATION_ACCOMMODATION_MODULES_INFO,
            R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD,
            R_PROPERTY_BASIC,
            WORKFLOW_DIRECT_BOOKER,
            R_PROPERTY_CALENDAR_DEV,
        }
    },
    methods: {
        getCalendarLink(reservation) {
            return {
                name: routes.RN_PROPERTY_CALENDAR,
                params: {id: reservation.property.id},
                query: {
                    unit: reservation.unit.parent_id ? reservation.unit.parent_id : reservation.unit.id,
                    start: moment(reservation.check_in_date).subtract(15, 'days').format('YYYY-MM-DD'),
                    end: moment(reservation.check_in_date).add(15, 'days').format('YYYY-MM-DD')
                }
            }
        }
    },
    computed: {
        highlightResCode() {
            return this.reservation.reservation_code;
        },
        currentCompany() {
            return this.$store.getters['user/getCurrentCompany']
        }
    }
}
</script>

<style scoped>

</style>
