<template>
    <form @submit.prevent="search" @reset.prevent="reset" class="filter">
        <b-row class="align-items-end">
            <b-col md="6" lg="4" xl="2" class="mb-3">
                <label>{{$t('NAME')}}</label>
                <b-input v-model="filter.name" type="text"></b-input>
            </b-col>

            <b-col md="6" lg="4" xl="2" class="mb-3">
                <label>{{ $t('SYSTEM_INVOICE_TYPE') }}</label>
                <app-select :options="systemInvoiceTypeList"
                            v-model="filter.system_invoice_type"
                            text-field="label">
                </app-select>
            </b-col>

            <b-col class="mb-3">
                <app-button-submit :inline="true" :loading="loading" variant="primary" button_type="search">
                    {{$t("SEARCH") }}
                </app-button-submit>
                <app-button-reset :disabled="loading" :inline="true"  class="pl-2 ml-2"  ></app-button-reset>

            </b-col>
        </b-row>
    </form>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {getSystemInvoiceTypeList} from "@/services/finance/setup";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";

    export default {
        name: "InvoiceTypeSetupFilter",
        components: {AppButtonReset, AppSelect, AppButtonSubmit},
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                filter: {
                    page: 1
                },
                systemInvoiceTypeList: []
            }
        },
        methods: {
            search() {
                this.$emit('search', this.filter)
            },
            reset() {
                this.filter = {page: 1}
                this.search()
            }
        },
        created() {
            getSystemInvoiceTypeList().then(response => {
                this.systemInvoiceTypeList = response.data
            })
        }
    }
</script>

<style scoped>

</style>
