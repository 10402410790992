<template>
    <div v-if="cPermissionV === null || checkPermission(cPermissionV)">
        <b-row v-if="list.length">
            <b-col class="mb-4 text-right">
                <app-button :c_permission="cPermissionE" @click="showNewContactForm" button_type="new">
                    {{$t("NEW_CONTACT")}}
                </app-button>
            </b-col>
        </b-row>
        <b-tabs v-if="list.length && !loader" v-model="currentTab" content-class="tab_content" pills vertical>
            <b-tab :key="contactIndex" v-for="(contact, contactIndex)  in list">
                <template slot="title">
                    {{contact.description}} ({{contact.channel_label}})
                </template>
                <b-row>
                    <b-col cols="12">
                        <repeater-header class="d-flex align-items-center">
                            <span>{{contact.description}}</span>
                            <app-button class="action_button" :c_permission="cPermissionE" :show_text="false"
                                        variant="link"

                                        button_type="edit"
                                        @click="showContactForm(contact)">&nbsp;
                            </app-button>
                        </repeater-header>
                    </b-col>
                    <b-col cols="12" class="mb-3">
                        <span class="pl-3">{{contact.channel_label}} : {{contact.contact}}</span>
                    </b-col>
                </b-row>
                <app-contact-object-type-list class="mb-4"
                                              @reload="reload"
                                              :contact="contact"

                                              :cPermissionE="cPermissionE"
                                              :object-type="contact.object_type"></app-contact-object-type-list>
            </b-tab>
        </b-tabs>
        <app-no-data v-else-if="list.length === 0 && !loader" :show-tip="false">
            <app-button :c_permission="cPermissionE" @click="showNewContactForm" button_type="new">
                {{$t("NEW_CONTACT")}}
            </app-button>
        </app-no-data>
        <app-aside v-model="showAside">
            <template slot="header">{{$t('CONTACT')}}</template>
            <app-contact-edit @reload="reload" :object-type="objectType" :object="object"
                              :channel-list="channel_list" :contact="editContact"></app-contact-edit>
        </app-aside>
    </div>
</template>

<script>

    import {getContactList} from "@/services/contacts/index";
    import {getContactChannelList} from "@/services/owner/index";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppContactObjectTypeList from "@/components/app/contacts/AppContactObjectTypeList";
    import AppContactEdit from "@/components/app/contacts/AppContactEdit";
    import FormHeader from "@/components/app/form/FormHeader";
    import AppButton from "@/components/app/AppButton/AppButton";
    import RepeaterHeader from "@/components/app/form/RepeaterHeader";
    import AppAside from "@/components/app/form/AppAside";
    import AppNoData from "@/components/app/AppNoData";

    export default {
        name: "AppContactList",
        components: {
            AppNoData,
            RepeaterHeader,
            AppButton,
            FormHeader,
            AppContactEdit,
            AppContactObjectTypeList,
            AppButtonSubmit,
            AppAside
        },
        props: {
            objectType: {
                type: Number,
            },
            object: {
                type: Number,
            },
            cPermissionV: {
                type: Number,
                default: null
            },
            cPermissionE: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                list: [],
                channel_list: [],
                showAside: false,
                editContact: null,
                currentTab: 0,
                loader:false
            }
        },
        methods: {
            reload(contact) {
                this.getList(this.object, contact)
                this.showAside = false
            },
            showNewContactForm() {
                this.showAside = true
                this.editContact = null
            },
            showContactForm(contact) {
                this.showAside = true
                this.editContact = contact
            },
            getList(objectId, contact) {
                this.loader = true
                getContactList(objectId, this.objectType).then(response => {
                    if (response.data.hasOwnProperty('contacts')) {
                        this.list = response.data.contacts
                        if (contact && contact.hasOwnProperty('id')) {
                            this.$nextTick(this.currentTab = this.list.findIndex(contactListItem => contactListItem.id === contact.id))
                        }
                    }
                }, () => {
                    this.list = []
                }).finally(()=>{
                    this.loader = false
                })
            },
            save() {

            }
        },
        watch: {
            object: {
                handler(value) {
                    this.getList(value, false)
                },
                immediate: true
            }
        },
        created() {
            getContactChannelList().then(response => {
                this.channel_list = response.data
            }, () => {
                this.channel_list = []
            })
        }
    }
</script>

<style scoped>

</style>
