<template>
    <div v-if="!loading && hasPermissionView">
            <edit-button v-if="list.length" button_type="new" variant="primary" :c_permission="c_permission_e"
                         @click="allSitesAsidePanelState=true" class="pull-right mb-4">{{$t("NEW_SALES_CHANNEL")}}
            </edit-button>
        <div v-if="list.length === 0 ">
            <nodata  :show-tip="false">
                <edit-button button_type="new" variant="primary" :c_permission="c_permission_e"
                             @click="allSitesAsidePanelState=true" class="pull-right mb-4">{{$t("NEW_SALES_CHANNEL")}}
                </edit-button>
            </nodata>
        </div>
        <b-table responsive hover v-if="list.length > 0" :fields="fields" :items="list">
            <template v-slot:edit="data">
                <edit-button :show_text="false" button_type="edit" variant="link" :c_permission="c_permission_e"
                             @click="editBookingSite(data.index)"></edit-button>
            </template>
        </b-table>
        <AppAside :widths="['col-lg-4', 'col-sm-12']" v-model="bookingSiteAsidePanelState">
            <template v-if="bookingSiteItemEditing" slot="header">{{$t("SALES_CHANNEL")}}: {{bookingSiteItemEditing.label}}</template>
            <BookingSiteForm @siteSaved="updateList"
                             :siteItem="bookingSiteItemEditing"></BookingSiteForm>
        </AppAside>

        <AppAside :widths="['col-lg-3', 'col-sm-12']" v-model="allSitesAsidePanelState">
            <template slot="header">{{$t("NEW_SALES_CHANNEL")}}</template>
            <all-booking-sites-form @allSitesSaved="updateList"></all-booking-sites-form>
        </AppAside>
    </div>
</template>

<script>
    import AppAside from '@/components/app/form/AppAside'

    import BookingSiteForm from '@/components/property/booking_sites/PropertyBookingSiteForm'
    import AllBookingSitesForm from '@/components/property/booking_sites/PropertyAllBookingSitesForm'
    import EditButton from '@/components/app/AppButton/AppButton'
    import Nodata from '@/components/app/AppNoData'
    import {
        getPropertyBookingSites,
    } from '@/services/property/index'
    import {C_PROPERTY_BOOKING_SITES_V, C_PROPERTY_BOOKING_SITES_E} from "@/shared/component_permission";

    export default {
        name: "Index",
        components: {
            AppAside,
            BookingSiteForm,
            AllBookingSitesForm,
            EditButton,
            Nodata
        },
        data() {
            return {
                fields: [
                    {key: 'id', label: this.$t('ID')},
                    {key: 'label', label: this.$t('NAME')},
                    // {key: 'page', label: this.$t('PAGE')},
                    {key: 'username', label: this.$t('USERNAME')},
                    {key: 'password', label: this.$t('PASSWORD')},
                    {key: 'url', label: this.$t('URL')},
                    {key: 'description', label: this.$t('DESCRIPTION')},
                    {key: 'edit', label: '',tdClass: 'text-right'},
                ],
                bookingSiteAsidePanelState: false,
                allSitesAsidePanelState: false,
                bookingSiteItemEditing: false,
                list: [],
                loading: true,
                c_permission_v: C_PROPERTY_BOOKING_SITES_V,
                c_permission_e: C_PROPERTY_BOOKING_SITES_E,
            }
        },

        methods: {
            editBookingSite(siteItemIndex) {
                this.bookingSiteItemEditing = this.list[siteItemIndex]
                this.bookingSiteAsidePanelState = true
            },
            updateList(list) {
                this.allSitesAsidePanelState = false
                this.bookingSiteAsidePanelState = false
                this.list = list
            }
        },
        created() {
            getPropertyBookingSites(this.$route.params.id).then(response => {
                this.list = response.data
                this.loading = false
            }, () => {
                this.list = []
                this.loading = false
            })
        },
        computed: {
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](this.c_permission_v)
            }
        }
    }
</script>

<style scoped>

</style>
