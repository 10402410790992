import { render, staticRenderFns } from "./AppearanceEditFontForm.vue?vue&type=template&id=212a4fb2&scoped=true&"
import script from "./AppearanceEditFontForm.vue?vue&type=script&lang=js&"
export * from "./AppearanceEditFontForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "212a4fb2",
  null
  
)

export default component.exports