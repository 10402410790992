import Vue from 'vue'

const state = {
    component_list_response: {},
    component_list: {},
    component_key_list: {}
}
const mutations = {
    setComponent(state, data) {
        state.component_list = {...state.component_list, [data.uid]: data}

    },
    setComponentKey(state, data) {
        if (!state.component_key_list.hasOwnProperty(data.key)) {
            state.component_key_list = {...state.component_key_list, [data.key]: []}
        }
        state.component_key_list[data.key].push(data.uid)
    },
    appendToComponentResponse(state, data) {

        state.component_list_response = {...state.component_list_response, ...data}
    },
    clearListByKey(state, key) {
        if (state.component_key_list.hasOwnProperty(key) && state.component_key_list[key].length) {
            state.component_key_list[key].forEach(uid => {
                let strUid = uid.toString()
                if (state.component_list_response.hasOwnProperty(strUid)) {
                    Vue.delete(state.component_list_response, strUid)
                }

                if (state.component_list.hasOwnProperty(strUid)) {
                    Vue.delete(state.component_list, strUid)
                }
            })
        }
        //Vue.delete(state.component_list_response, uid)
    },
    clearComponentKeyList(state, key) {
        Vue.delete(state.component_key_list, key)
    },
}
const getters = {
    getComponentList(state) {
        return state.component_list
    },
    getComponentItems: (state) => (key) => {
        let returnArray = []
        if (!state.component_key_list.hasOwnProperty(key)) {
            return returnArray
        }
        state.component_key_list[key].forEach(itemUid => {
            if (state.component_list.hasOwnProperty(itemUid)) {
                returnArray.push(state.component_list[itemUid])
            }
        })
        return returnArray
    },
    isDisabled: (state) => (uid) => {
        if (state.component_list_response.hasOwnProperty(uid) && state.component_list_response[uid].hasOwnProperty('disabled')) {
            return state.component_list_response[uid].disabled
        }
        return false
    },
    isVisible: (state) => (uid) => {
        if (!state.component_list_response.hasOwnProperty(uid)) {
            return null
        }

        if (state.component_list_response.hasOwnProperty(uid) && state.component_list_response[uid].hasOwnProperty('visible')) {
            return state.component_list_response[uid].visible
        }
        return true
    },
    message: (state) => (uid) => {
        if (state.component_list_response.hasOwnProperty(uid) && state.component_list_response[uid].hasOwnProperty('messages')) {
            return state.component_list_response[uid].messages
        }
        return []
    }
}
const actions = {
    actionSetComponent({commit}, data) {
        commit('setComponent', data)
        commit('setComponentKey', data)
    },
    actionSetComponentResponseData({commit}, data) {
        commit('appendToComponentResponse', data)
    },
    actionClear({commit}, key) {

        commit('clearListByKey', key.toString())
        commit('clearComponentKeyList', key.toString())
    }
}

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true
}
