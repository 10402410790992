<template>
    <div>
        <app-table-v2 :provider="getTableData" :fields="fields" search-on-create>
            <template v-slot:path="data">
                /{{data.item.path}}
            </template>

            <template v-for="key in ['title', 'description']" v-slot:[key]="data">
                <template v-if="data.item[key]">
                    <template v-for="(translation, locale) in data.item[key]">
                        <b-badge
                            variant="primary" pill
                            :id="`${data.item.id}-${locale}-${key}`"
                            class="mr-1"
                            style="cursor: pointer">
                            {{ locale.toUpperCase() }}
                        </b-badge>
                        <b-tooltip :target="`${data.item.id}-${locale}-${key}`" placement="top" triggers="hover">
                            {{ translation }}
                        </b-tooltip>
                    </template>
                </template>
            </template>

            <template v-for="navKey in ['is_in_header_navigation', 'is_in_footer_navigation']" v-slot:[navKey]="data">
                <span v-if="data.item[navKey] === null">N/A</span>
                <i v-else-if="data.item[navKey]" class="fa fa-check text-success" aria-hidden="true"></i>
                <i v-else class="fa fa-ban text-danger" aria-hidden="true"></i>
            </template>

            <template v-slot:actions="data">
                <app-button variant="link"
                            button_type="edit"
                            :show_text="false"
                            @click="goToPage(data.item.id)">
                </app-button>
            </template>
        </app-table-v2>
    </div>
</template>

<script>
import AppTableV2 from "@/components/app/AppTableV2";
import AppButton from "@/components/app/AppButton/AppButton";
import routeNames from "@/router/routeNames";
import {getWebsitePageList} from "@/services/direct_booking/website";

export default {
    name: "WebsitePageList",
    components: {AppButton, AppTableV2},
    data() {
        return {
            fields: [
                {key: 'path', label: this.$t('ROUTE')},
                {key: 'type.label', label: this.$t('TYPE')},
                {key: 'title', label: this.$t('TITLE')},
                {key: 'description', label: this.$t('DESCRIPTION')},
                {key: 'is_in_header_navigation', label: this.$t('HEADER_NAVIGATION')},
                {key: 'is_in_footer_navigation', label: this.$t('FOOTER_NAVIGATION')},
                {key: 'actions', label: '', class: 'text-right'}
            ],
        }
    },
    methods: {
        getTableData(filter) {
            return getWebsitePageList(this.$route.params.id, {
                page: filter.currentPage,
                per_page: filter.perPage
            })
        },
        goToPage(page_id) {
            this.$router.push({name: routeNames.RN_DIRECT_BOOKING_WEBSITE_PAGE, params: {page_id}})
        }
    }
}
</script>

<style scoped>

</style>
