<template>
    <b-form @submit.prevent="save">
        <b-row class="mt-3 mb-3">
            <b-col>
                <label>{{$t('NAME')}}</label>
                <b-form-input type="text" v-model="name"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button @click="save" :loading="loader">{{$t('SAVE')}}</app-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import {createPromoCategory} from "@/services/promo_codes";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppButton from "@/components/app/AppButton/AppButton";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "PromoCategoryNew",
    components:{AppButton},
    mixins:[getErrorMessage],
    data(){
        return{
            name: null,
            loader: false,
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods:{
        save(){
            this.loader = true
            createPromoCategory({name: this.name, company: this.company.id}).then(response => {
                notifySuccess()
                this.loader = false
                EventBus.$emit('reloadPromoCategory')
                this.$emit('categoryCreated')
            }, error => {
                this.showErrorMessages(error)
                this.loader = false
            })
        }
    }
}
</script>

<style scoped>

</style>
