<template>
    <div
        ref="calendar_scroll_container"
        class="main_calendar_test__holder--content_dates main_calendar_test--background_gray main_calendar_test-bb animated fadeIn">
        <template v-if="list">
            <table-unit-status-content-cell
                v-for="(cellData, cellIndex) in cellObjectList" :key="cellIndex"
                :cell-data="cellData"
                @startEndDrag="dragStartEnd(cellData.date)"
                @drag="drag(cellData.date)"
                @currentDrag="dragCurrent(cellData.date)"
                :highlighted="isHighlighted(cellData.date)">
            </table-unit-status-content-cell>
        </template>
    </div>
</template>


<script>
import moment from 'moment'
import TableUnitStatusContentCell
    from "@/components/unit/calendar_opt_old/Table/UnitStatus/TableUnitStatusContentCell";
import {EventBus, GE_CALENDAR_OPEN_ROOM_STATUS} from "@/shared/EventBus";
import {CalendarRowMixin} from "@/mixins/calendar/CalendarRowMixin";

export default {
    name: "TableUnitStatusContent",
    components: {TableUnitStatusContentCell},
    mixins: [CalendarRowMixin],
    props: {

        list: {
            type: Object
        },
        unit: {
            type: Object
        },
        dateRange: {
            type: Object
        },
        dateList: {
            type: Array
        },
        shiftKeyValue: {
            type: Boolean
        }
    },
    data() {
        return {
            currentDragDate: null,
            dragPeriod: {
                start: null,
                end: null
            },
            cellObjectList: [],
        }
    },
    computed: {
        momentDateRange() {
            return {
                start: moment(this.dateRange.start),
                end: moment(this.dateRange.end)
            }
        }
    },
    methods: {
        dragCurrent(date) {
            this.currentDragDate = date
        },
        drag(date) {
            if (!this.dragPeriod.start) {
                this.dragPeriod.start = date
            } else if (!this.dragPeriod.end) {
                this.dragPeriod.end = date
                this.openRoomStatus()
            }
        },
        dragStartEnd(date) {
            this.dragPeriod.start = date
            this.dragPeriod.end = date
            this.openRoomStatus()
        },
        openRoomStatus() {
            if (this.dragPeriod.start > this.dragPeriod.end) {
                this.dragPeriod = {
                    start: this.dragPeriod.end,
                    end: this.dragPeriod.start
                }
            }
            EventBus.$emit(GE_CALENDAR_OPEN_ROOM_STATUS, {
                unitId: this.unit.id,
                dateRange: {
                    start: this.dragPeriod.start.format("YYYY-MM-DD"),
                    end: this.dragPeriod.end.format("YYYY-MM-DD")
                }
            })
            this.dragPeriod.start = null
            this.dragPeriod.end = null
            this.currentDragDate = null
        },
        getCellObjectData(dates) {
            const roomStatusValues = dates.map(date => {
                return this.list[date.format('YYYY-MM-DD')]
            })
            return dates.map((date, index) => {
                return {
                    date,
                    column: index + 1,
                    classList: this.getClassList(date, roomStatusValues[index], roomStatusValues[index - 1], roomStatusValues[index + 1]),
                    lastDayOfMonthClass:  date.date() === date.daysInMonth()
                }
            })
        },
        getClassList(date, value, valueBefore, valueAfter) {
            return {
                'main_calendar_test--background_primary-3': !!value,
                'main_calendar_test--background_warning': !value,
                'main_calendar_test--status-start': (value !== valueBefore) || date.isSame(this.momentDateRange.start, 'day'),
                'main_calendar_test--status-end': (value !== valueAfter) || date.isSame(this.momentDateRange.end, 'day')
            }
        },
        isHighlighted(date) {
            return (this.shiftKeyValue && this.dragPeriod.start && this.currentDragDate)
                && ((date >= this.dragPeriod.start && date <= this.currentDragDate) ||
                    (date <= this.dragPeriod.start && date >= this.currentDragDate)
                )
        },
    },
    watch: {
        shiftKeyValue(val) {

            if (val === false) {
                this.dragPeriod.start = null
                this.dragPeriod.end = null
                this.currentDragDate = null
            }
        },
        list: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.cellObjectList = this.getCellObjectData(this.dateList)
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
