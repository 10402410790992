<template>
    <div class="availability_calendar--cell d-flex justify-content-center">
        <span class="availability_calendar-year" :class="!isOverflown ? 'mb-3' : ''">{{currentYear}}

    </span>
    </div>
</template>

<script>

import {C_UNIT_CALENDAR_YEARLY_VIEW_V} from "@/shared/component_permission";
import {MONTHLY_VIEW, MULTI_UNIT_VIEW} from "@/shared/constants";

export default {
    name: "CalendarYearCellTest",
    props: {
        dateList: {
            type: Array
        },
        scrollLeftPosition: {
            type: Number
        },
        isOverflown: {
            type:Boolean,
            default:false
        },
        display_work_mode:{
            type: Number,
            required: true
        }
    },
    data() {
        return {
            currentYear: "2020",
            MULTI_UNIT_VIEW
        }
    },
    computed: {
        pixelsWide() {
            return 2.87 * 16
        },
        currentIndex() {
            return parseInt(this.scrollLeftPosition / this.pixelsWide)
        },
        classList() {
            if (this.checkPermission(C_UNIT_CALENDAR_YEARLY_VIEW_V)) {
                return ['main_calendar-color_action', 'main_calendar--poiner']
            }
            return 'text-muted'
        }
    },
    methods: {
        calculateYear() {
            if (this.dateList.length > 0 && this.dateList.indexOf(this.currentIndex) === -1) {
                let currYearMoment = this.dateList[this.currentIndex]
              if(this.display_work_mode === MULTI_UNIT_VIEW) {
                this.currentYear = currYearMoment ? currYearMoment.format("YYYY") : "2020"
              }else if(this.display_work_mode === MONTHLY_VIEW)
              {this.currentYear = currYearMoment ? currYearMoment.format("MMMM, YYYY") : "2020"}
            }
        }
    },
    watch: {
        scrollLeftPosition: {
            handler() {
                this.calculateYear()
            },
            immediate: true
        },
        dateList:{
            handler(dates){
                this.calculateYear()
            },deep:true
        }
    }
}
</script>

<style scoped>

</style>
