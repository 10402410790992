import {
    EventBus,
    GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_CURRENT_PROP,
    GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_PERIOD_START,
    GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_UNIT_ROW_INDEX,
} from "@/shared/EventBus";
import moment from "moment";

export const AvailabilityScrollMixin = {
    props: {
        unit: {
            type: Object
        },
        parentUnit: {
            type: Object
        },
        scrollLeftPosition: {
            type: Number,
            default: 0
        },
        shiftKeyValue: {
            type: Boolean,
            default: false
        },
        currentDragDateProp: {
            type: String,
            default: null
        },
        dragPeriodStartProp: {
            type: String,
            default: null
        },
        definedUnitRowIndex:{
            type:Number,
            default:null
        },
        clickedUnitRowIndex:{
            type:Number,
            default:null
        },
    },
    data() {
        return {
            startScrollDrag: false,
            lastRegisteredMovement: 0,
            dragXStartPosition: 0,
            currentDragDate: null,
            dragPeriod: {
                start: null,
                end: null,
            }
        }
    },
    methods: {
        dragCurrent(date) {
            this.currentDragDate = date
        },
        drag(date) {
            if (!this.dragPeriod.start) {
                this.dragPeriod.start = date
            } else if (!this.dragPeriod.end) {
                this.dragPeriod.end = date
                this.openAvailabilityAside()
            }
        },
        dragStartEnd(date) {
            this.dragPeriod.start = date
            this.dragPeriod.end = date
            this.openAvailabilityAside()
        },
        startScrollDragEvent($event) {
            this.dragXStartPosition = $event.clientX
            this.startScrollDrag = true
        },
        endScrollDragEvent() {
            this.startScrollDrag = false
        },
        handleScrollDragEvent($event) {
            if (!this.startScrollDrag) {
                return false
            }
            this.$refs.av_cal_row.scrollLeft = this.$refs.av_cal_row.scrollLeft + ($event.clientX - this.dragXStartPosition);
        },
        multiComponentDragCurrent(date) {
            this.currentDragDate = date
            EventBus.$emit(GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_CURRENT_PROP, date)
        },
        multiComponentDrag(date) {
            if (!this.dragPeriod.start || (this.definedUnitRowIndex !== null && this.clickedUnitRowIndex !== null && this.definedUnitRowIndex !== this.clickedUnitRowIndex)) {
                this.dragPeriod.start = date
                EventBus.$emit(GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_PERIOD_START, date)
                EventBus.$emit(GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_UNIT_ROW_INDEX, this.definedUnitRowIndex)
            } else if (!this.dragPeriod.end) {
                this.dragPeriod.end = date
                this.openAvailabilityAside()
            }
        },
        isHighlighted(date) {
            return (this.shiftKeyValue && this.dragPeriod && this.dragPeriod.start && this.currentDragDate)
                &&
                (
                    (this.definedUnitRowIndex === null) ||
                    (this.definedUnitRowIndex === this.clickedUnitRowIndex && this.clickedUnitRowIndex !== null)
                )
                &&
                (
                    (date >= this.dragPeriod.start && date <= this.currentDragDate) ||
                    (date <= this.dragPeriod.start && date >= this.currentDragDate)
                )
        },
    },
    watch: {
        shiftKeyValue(val) {
            if (val === false) {
                this.dragPeriod.start = null
                this.dragPeriod.end = null
                this.currentDragDate = null
            }
        },
        scrollLeftPosition: {
            handler(val) {
                this.$nextTick(() => {
                    if (this.$refs.calendar_scroller) {
                        if (Array.isArray(this.$refs.calendar_scroller)) {
                            this.$refs.calendar_scroller.forEach(el => {
                                el.scrollLeft = val;
                            })
                        } else {
                            this.$refs.calendar_scroller.scrollLeft = val;
                        }
                    }
                })
            }
        },
        currentDragDateProp: {
            handler(val) {
                if (val !== null) {
                    this.currentDragDate = moment(val)
                } else {
                    this.currentDragDate = null
                }
            }, immediate: true
        },
        dragPeriodStartProp: {
            handler(val) {
                if (val !== null) {
                    this.dragPeriod.start = moment(val)
                } else {
                    this.dragPeriod.start = null
                }
            }, immediate: true
        },
    }
}
