<template>
    <div>
        <b-table :items="tableItems" :fields="tableFields" :busy="loading">
            <template v-slot:amount="data">
                {{data.item.amount}}%
            </template>

            <template v-slot:action_buttons="data">
                <app-button variant="link"
                            button_type="edit"
                            :show_text="false"
                            @click="$emit('edit', data.item)">
                </app-button>
            </template>
        </b-table>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";

    export default {
        name: "VatSetupList",
        components: {AppButton},
        props: {
            tableItems: {
                type: Array,
                default: []
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                tableFields: [
                    {key: 'label', label: this.$t('NAME')},
                    {key: 'amount', label: this.$t('AMOUNT')},
                    {key: 'actions', label: '', class: 'text-right'}
                ]
            }
        }
    }
</script>

<style scoped>

</style>
