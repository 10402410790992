<template>


      <ol class="breadcrumb">
          <li class="breadcrumb-item" :key="index" v-for="(routeObject, index) in routeRecords">
              <span class="active" v-if="isLast(index)">{{ getName(routeObject) }}</span>
              <router-link :to="routeObject" v-else>{{ getName(routeObject) }}</router-link>
          </li>

      </ol>


</template>

<script>
    export default {
        name: "AppBreadcrumb",
        computed: {
            list() {
                return this.$route.matched
            },
            routeRecords: function () {
                return this.list.filter((route) => {

                    return (typeof route.meta.show === 'undefined' || route.meta.show) && route.meta.t
                })
            }
        },
        methods: {
            getName (item) {
                return item.meta && item.meta.t ? this.$t(item.meta.t) : item.name || null
            },
            isLast (index) {
                return index === this.routeRecords.length - 1
            }
        }
    }
</script>

<style scss>

</style>
