import http from '../http'
import {
    CURRENCY_URL,
    FINANCE_CASH_REGISTER_SETUP_URL,
    FINANCE_PARTNER_MAPPING_URL,
    FINANCE_PARTNER_LIST_URL,
    FINANCE_URL, INVOICE_URL, FINANCE_PAYMENT_ORDER_URL,
    FINANCE_CASH_REGISTER_LIST_URL, FINANCE_USER_CASH_REGISTER_SETUP,FINANCE_USER_CASH_REGISTER_ENTITY_SETUP
} from '@/services/endpoints'

export function getCashRegisterSetup(params){
  return http.request({
    url:FINANCE_CASH_REGISTER_SETUP_URL,
    method: 'GET',
    params:params,
  })
}

export function getFinancePartnerList(params) {
  return http.request({
    url:FINANCE_PARTNER_LIST_URL,
    method: 'GET',
    params:params
  })
}

export function updateFinancePartnerMapping(id, postData) {
  return http.request({
    url:FINANCE_PARTNER_MAPPING_URL(id),
    method: 'PUT',
    data:postData
  })
}

export function getCurrencyList() {
    return http.request({
        url: CURRENCY_URL,
        method: 'GET'
    })
}

export function getInvoiceList(params) {
    return http.request({
        url: FINANCE_URL + '/invoice',
        method: 'GET',
        params
    })
}

export function getOwnerInvoiceList(params) {
    return http.request({
        url: FINANCE_URL + '/owner-invoice',
        method: 'GET',
        params
    })
}

export function downloadInvoicePDF(id) {
    return http.request({
        url: INVOICE_URL + '/' + id + '/pdf',
        method: 'GET',
        responseType: 'blob', // important,
    })
}

export function downloadReportXls(data) {
    return http.request({
        url: INVOICE_URL + '/export/xls',
        method: 'POST',
        responseType: 'blob', // important,
        data
    })
}

export function fetchInvoice(id) {
    return http.request({
        url: `${FINANCE_URL}/invoice/${id}`,
        method: 'GET'
    })
}

export function createInvoice(creation_location, object_id, data) {
    return http.request({
        url: INVOICE_URL,
        method: 'POST',
        data: {creation_location, object_id, ...data}
    })
}

export function cancelInvoice(id) {
    return http.request({
        url: INVOICE_URL + '/' + id + '/cancel',
        method: 'PUT',
    })
}

export function payInvoice(id) {
    return http.request({
        url: INVOICE_URL + '/' + id + '/pay',
        method: 'PUT',
    })
}

export function exportInvoiceXLS(data) {
    return http.request({
        url: INVOICE_URL + '/export/xls',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
export function exportPdfInvoice(data) {
    return http.request({
        url: INVOICE_URL + '/pdf',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

export function sendInvoiceNotice(id) {
    return http.request({
        url: FINANCE_URL + '/invoice/' + id + '/notify',
        method: 'POST'
    })
}

export function getCreditCardTypes() {
    return http.request({
        url: FINANCE_URL + '/credit-card-types',
        method: 'GET'
    })
}

export function saveCreditCard(data) {
    return http.request({
        url: FINANCE_URL + '/credit-card',
        method: 'POST',
        data: data
    })
}

export function getCreditCard(id) {
    return http.request({
        url: FINANCE_URL + '/credit-card/' + id,
        method: 'GET'
    })
}

export function updateCreditCard(id, data) {
    return http.request({
        url: FINANCE_URL + '/credit-card/' + id,
        method: 'PUT',
        data: data
    })
}

export function getPaymentOrderList(params) {
    return http.request({
        url: FINANCE_PAYMENT_ORDER_URL + '/paginated-list',
        method: 'GET',
        params
    })
}

export function paymentOrderDownloadXml(paymentOrderId) {
    return http.request({
        url: FINANCE_PAYMENT_ORDER_URL + '/' + paymentOrderId + '/download-xml',
        method: 'GET'
    })
}

export function paymentOrderDownloadXls(paymentOrderId) {
    return http.request({
        url: FINANCE_PAYMENT_ORDER_URL + '/' + paymentOrderId + '/download-xls',
        method: 'GET',
        responseType: 'blob'
    })
}

export function fetchPaymentOrder(paymentOrderId) {
    return http.request({
        url: FINANCE_PAYMENT_ORDER_URL + '/' + paymentOrderId,
        method: 'GET'
    })
}

export function editPaymentOrder(paymentOrderId, data) {
    return http.request({
        url: FINANCE_PAYMENT_ORDER_URL + '/' + paymentOrderId,
        method: 'PUT',
        data: data
    })
}
export function removeInvoicePaymentOrder(paymentOrderId, data) {
    return http.request({
        url: FINANCE_PAYMENT_ORDER_URL + '/' + paymentOrderId + '/remove-invoice',
        method: 'PUT',
        data: data
    })
}
export function regeneratePaymentOrder(paymentOrderId) {
    return http.request({
        url: FINANCE_PAYMENT_ORDER_URL + '/' + paymentOrderId + '/generate',
        method: 'POST'
    })
}

export function getPaymentOrderPartnerList() {
    return http.request({
        url: FINANCE_PAYMENT_ORDER_URL + '/partner-list',
        method: 'GET',
    })
}

export function generateNewPaymentOrder(data) {
    return http.request({
        url: FINANCE_PAYMENT_ORDER_URL,
        method: 'POST',
        data: data
    })
}

export function checkPaymentOrder(paymentOrderId) {
    return http.request({
        url: FINANCE_PAYMENT_ORDER_URL + '/' + paymentOrderId + '/check',
        method: 'POST',
        responseType: 'blob',
    })
}

export function getCashRegisterList(){
    return http.request({
        url: FINANCE_CASH_REGISTER_LIST_URL,
        method: 'GET',

    })
}
export function createCashRegisterSetup(data){
    return http.request({
        url: FINANCE_USER_CASH_REGISTER_SETUP,
        method: 'POST',
        data: data
    })
}
export function updateCashRegisterSetup(data){
    return http.request({
        url: FINANCE_USER_CASH_REGISTER_SETUP,
        method: 'PUT',
        data: data
    })
}
export function fetchCashRegisterSetup(params){
    return http.request({
        url: FINANCE_USER_CASH_REGISTER_SETUP,
        method: 'GET',
        params: params
    })
}
export function saveCashRegisterEntitySetup(register_setup_id, data){
    return http.request({
        url: FINANCE_USER_CASH_REGISTER_ENTITY_SETUP(register_setup_id),
        method: 'POST',
        data: data
    })
}
export function getCashRegisterEntitySetup(register_setup_id){
    return http.request({
        url: FINANCE_USER_CASH_REGISTER_ENTITY_SETUP(register_setup_id),
        method: 'GET',
    })
}

export function getFinancePaymentType(params){
    return http.request({
        url: INVOICE_URL + '/payment-type',
        method: 'GET',
        params
    })
}
