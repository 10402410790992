import {normalize, schema} from "normalizr";


import {

    getPricingLosList,
    createPricingLos,
    updatePricingLos,
    deletePricingLos,

    getPricingOccupancyList,
    createPricingOccupancy,
    updatePricingOccupancy,
    deletePricingOccupancy,

    getSalesPeriodList,
    getBulkCalendar,
    savePriceInPrpSetup,
    setPeriodsFromSuggestion,
    updatePeriods,
    deletePeriod,
    createPeriod,
    updatePeriod,

    getPricingRatePlanList,
    createPricingRatePlan,
    updatePricingRatePlan,
    deletePricingRatePlan,
    copyRatePlans,
    setBulkCalendar,
    updatePeriodList,
} from "@/services/pricing";
import {
    FIX,
    PERC,
    MIN_STAY,
    MAX_STAY,
    RELEASE_PERIOD,
    CLOSED_ON_ARRIVAL,
    CLOSED_ON_DEPARTURE, SALES_ENABLED,
    SYSTEM_STANDARD_RATE,
    SYSTEM_NON_REFUNDABLE_RATE
} from "@/shared/constants";
import moment from 'moment'

import {getDistributionManagers} from "@/services/distribution";

const state = {
    data: {
        entities: {
            los: {},
            period: {},
            occupancy: {},
            price: {},
            restriction: {},
            rate_plan: {},
            system_rate_plan: {},
            distribution: {},
            statistics: {}
        },
        result: {
            los: [],
            period: [],
            occupancy: [],
            price: [],
            restriction: [],
            rate_plan: [],
            system_rate_plan: [],
            distribution: [],
            statistics: []
        }
    },
    search: {
        list: {
            list_count: 0,
            list_items: [],
            last_page: 0
        },
        filter: {
            page: 1,
            limit: 10,
            unit: null,
            name: '',
            distribution: null,
            profile: null,
        }
    }
}
const getters = {
    getLosList(state) {
        return state.data.result.los
    },
    getLosById: (state) => (id) => {
        return state.data.entities.los[id]
    },

    getOccupancyList(state) {
        return state.data.result.occupancy
    },
    getOccupancyById: (state) => (id) => {
        return state.data.entities.occupancy[id]
    },

    getPeriodList(state) {
        return state.data.result.period
    },
    getStatisticList(state){
        return state.data.result.statistics
    },
    getPeriodById: (state) => (id) => {
        return state.data.entities.period[id]
    },
    getStatisticById: (state) => (id) => {
        return state.data.entities.statistics[id]
    },

    getPriceById: (state) => (id) => {
        return state.data.entities.price[id]
    },
    getPriceList(state) {
        return state.data.result.price
    },
    getRestrictionList(state) {
        return state.data.result.restriction
    },
    getRestrictionById: (state) => (id) => {
        return state.data.entities.restriction[id]
    },
    getRatePlanList(state) {
        return state.data.result.rate_plan
    },
    getSystemRatePlanList(state) {
        return state.data.result.system_rate_plan
    },
    getRatePlanById: (state) => (id) => {
        return state.data.entities.rate_plan[id]
    },
    getSystemRatePlanById: (state) => (id) => {
        return state.data.entities.system_rate_plan[id]
    },
    getDistributionsList(state) {
        return state.data.result.distribution
    },
    getDistributionById: (state) => (id) => {
        return state.data.entities.distribution[id]
    },
    getSearchFilter(state) {
        return state.search.filter
    },
    getSearchList(state) {
        return state.search.list.list_items
    },
    getSearchListCount(state) {
        return state.search.list.list_count
    },
    getSearchListLastPage(state) {
        return state.search.list.last_page
    }
}
const mutations = {

    setSchema(state, {data, schema}) {
        state.data.entities[schema] = data.entities[schema]
        state.data.result[schema] = data.result[schema]
    },

    setLosMinStay(state, {id, value}) {
        state.data.entities.los[id].min_stay = value
    },
    setLosMaxStay(state, {id, value}) {
        state.data.entities.los[id].max_stay = value
    },
    setLosDefault(state, {id, value}) {
        state.data.entities.los[id].default = value
    },
    setLosDiscount(state, {id, value}) {
        state.data.entities.los[id].discount = value
    },
    setLosDiscountType(state, {id, value}) {
        state.data.entities.los[id].discount_type = value
    },

    setOccupancyMinGuests(state, {id, value}) {
        state.data.entities.occupancy[id].min_guests = value
    },
    setOccupancyMaxGuests(state, {id, value}) {
        state.data.entities.occupancy[id].max_guests = value
    },
    setOccupancyDefault(state, {id, value}) {
        state.data.entities.occupancy[id].default = value
    },
    setOccupancyDiscount(state, {id, value}) {
        state.data.entities.occupancy[id].discount = value
    },
    setOccupancyDiscountType(state, {id, value}) {
        state.data.entities.occupancy[id].discount_type = value
    },

    createPeriod(state, {id}) {
        let object = {
            id: id,
            period_start: null,
            period_end: null
        }
        if (!state.data.entities.period) {

            state.data.entities.period = {'new': object}
        } else {
            state.data.entities.period[id] = object
        }

        state.data.result.period.push(id)

    },
    setPeriodStart(state, {id, value}) {
        state.data.entities.period[id].period_start = value
    },
    setPeriodEnd(state, {id, value}) {
        state.data.entities.period[id].period_end = value
    },
    deletePeriod(state, {id}) {
        state.data.entities.period[id].deleted = 1
    },

    setPriceValue(state, {id, value}) {
        state.data.entities.price[id].value = value
    },
    setRestrictionValue(state, {id, value}) {

        state.data.entities.restriction[id].value = value
    },

    setRatePlanName(state, {id, value}) {
        state.data.entities.rate_plan[id].name = value
    },

    setSearchListCount(state, count) {
        state.search.list.list_count = count
    },
    setSearchFilterItem(state, {item, value}) {
        state.search.filter[item] = value
    },
    setSearchList(state, list) {
        state.search.list.list_items = list
    },
    setSearchListLastPage(state, page) {
        state.search.list.last_page = page
    },

}

const actions = {

    actionSetSalePeriodList({commit}, payload) {
        return new Promise((resolve, reject) => {
            getSalesPeriodList(payload)
                .then(response => {
                        const period = new schema.Entity('period');
                        const periodSchema = {period: [period]}
                        const normalizedData = normalize({period: response.data}, periodSchema);
                        commit('setSchema', {data: normalizedData, schema: 'period'})
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })

    },
    actionUpdatePeriod({commit}, {id, period_start, period_end}) {
        commit('setPeriodStart', {id, value: period_start})
        commit('setPeriodEnd', {id, value: period_end})
    },
    actionDeletePeriod({commit}, payload) {
        return new Promise((resolve, reject) => {
            deletePeriod(payload.unit, payload.id)
                .then(response => {
                        const period = new schema.Entity('period');
                        const periodSchema = {period: [period]}
                        const normalizedData = normalize({period: response.data}, periodSchema);
                        commit('setSchema', {data: normalizedData, schema: 'period'})
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })

        })
    },
    actionCreatePeriod({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            createPeriod(payload.unit, payload.data)
                .then(response => {
                        const period = new schema.Entity('period');
                        const periodSchema = {period: [period]}
                        const normalizedData = normalize({period: response.data}, periodSchema);
                        commit('setSchema', {data: normalizedData, schema: 'period'})
                        dispatch('actionSetPriceList')
                        dispatch('actionSetRestrictionList')
                        dispatch('actionSetStatistics')
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })

        })
    },
    actionSavePeriod({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            updatePeriod(payload.unit, payload.data)
                .then(response => {
                        const period = new schema.Entity('period');
                        const periodSchema = {period: [period]}
                        const normalizedData = normalize({period: response.data}, periodSchema);
                        commit('setSchema', {data: normalizedData, schema: 'period'})
                        dispatch('actionSetPriceList')
                        dispatch('actionSetRestrictionList')
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })

        })
    },
    actionSavePeriodList({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            updatePeriodList({unit: payload.unit, period_list: payload.period_list})
                .then(response => {
                        const period = new schema.Entity('period');
                        /**/
                        const periodSchema = {period: [period]}
                        const normalizedData = normalize({period: response.data}, periodSchema);
                        commit('setSchema', {data: normalizedData, schema: 'period'})
                        dispatch('actionSetRestrictionList')
                        dispatch('actionSetPriceList')
                        dispatch('actionSetStatistics')
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })

        })
    },
    actionApplyPricingPeriodSuggestion({commit, dispatch}, {unit_id, sales_period_setup_id}) {
        return new Promise((resolve, reject) => {
            setPeriodsFromSuggestion(unit_id, sales_period_setup_id)
                .then(response => {
                        const period = new schema.Entity('period');
                        /**/
                        const periodSchema = {period: [period]}
                        const normalizedData = normalize({period: response.data}, periodSchema);
                        commit('setSchema', {data: normalizedData, schema: 'period'})
                        dispatch('actionSetRestrictionList')
                        dispatch('actionSetPriceList')
                        dispatch('actionSetStatistics')
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })
    },
    actionSavePricingPeriods({state, commit}, id) {
        let data = []
        for (let periodId of state.data.result.period) {
            let period = state.data.entities.period[periodId]

            if (typeof period.id === 'string') {
                if (period.id.includes('new')) {
                    period.id = null
                }
            }
            data.push(period)


        }
        return new Promise((resolve, reject) => {
            updatePeriods(id, {data})
                .then(response => {

                        const period = new schema.Entity('period');
                        const periodSchema = {period: [period]}
                        const normalizedData = normalize({period: response.data}, periodSchema);
                        commit('setSchema', {data: normalizedData, schema: 'period'})
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })

    },

    actionSetLosList({commit}, payload) {
        return new Promise((resolve, reject) => {
            getPricingLosList(payload)
                .then(response => {
                        const los = new schema.Entity('los');
                        const losSchema = {los: [los]}
                        const normalizedData = normalize({los: response.data}, losSchema);
                        commit('setSchema', {data: normalizedData, schema: 'los'})
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })
    },
    actionCreatePricingLos({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            createPricingLos(payload.los)
                .then(response => {
                        const los = new schema.Entity('los');
                        const losSchema = {los: [los]}
                        const normalizedData = normalize({los: response.data}, losSchema);
                        commit('setSchema', {data: normalizedData, schema: 'los'})
                        dispatch('actionSetPriceList')
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })
    },
    actionUpdatePricingLos({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            let postData = {
                min_stay: payload.los.min_stay,
                max_stay: payload.los.max_stay,
                discount: payload.los.discount,
                discount_type: payload.los.discount_type,
                default: payload.los.default,
                unit: payload.unit,
            }

            updatePricingLos(payload.id, postData)
                .then(response => {
                        const los = new schema.Entity('los');
                        const losSchema = {los: [los]}
                        const normalizedData = normalize({los: response.data}, losSchema);
                        commit('setSchema', {data: normalizedData, schema: 'los'})
                        dispatch('actionSetPriceList')
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })
    },
    actionDeletePricingLos({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            deletePricingLos(payload.id, payload)
                .then(response => {
                        const los = new schema.Entity('los');
                        const losSchema = {los: [los]}
                        const normalizedData = normalize({los: response.data}, losSchema);
                        commit('setSchema', {data: normalizedData, schema: 'los'})
                        dispatch('actionSetPriceList')
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })
    },

    actionSetOccupancyList({commit}, payload) {
        return new Promise((resolve, reject) => {
            getPricingOccupancyList(payload)
                .then(response => {
                        const occupancy = new schema.Entity('occupancy');
                        const occupancySchema = {occupancy: [occupancy]}
                        const normalizedData = normalize({occupancy: response.data}, occupancySchema);
                        commit('setSchema', {data: normalizedData, schema: 'occupancy'})
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })
    },
    actionCreatePricingOccupancy({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            createPricingOccupancy(payload.occupancy)
                .then(response => {
                        const occupancy = new schema.Entity('occupancy');
                        const occupancySchema = {occupancy: [occupancy]}
                        const normalizedData = normalize({occupancy: response.data}, occupancySchema);
                        commit('setSchema', {data: normalizedData, schema: 'occupancy'})
                        dispatch('actionSetPriceList')
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })
    },
    actionUpdatePricingOccupancy({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            updatePricingOccupancy(payload.id, payload.objectOccupancy)
                .then(response => {
                        const occupancy = new schema.Entity('occupancy');
                        const occupancySchema = {occupancy: [occupancy]}
                        const normalizedData = normalize({occupancy: response.data}, occupancySchema);
                        commit('setSchema', {data: normalizedData, schema: 'occupancy'})
                        dispatch('actionSetPriceList')
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })
    },
    actionDeletePricingOccupancy({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            deletePricingOccupancy(payload.id, payload)
                .then(response => {
                        const occupancy = new schema.Entity('occupancy');
                        const occupancySchema = {occupancy: [occupancy]}
                        const normalizedData = normalize({occupancy: response.data}, occupancySchema);
                        commit('setSchema', {data: normalizedData, schema: 'occupancy'})
                        dispatch('actionSetPriceList')
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })
    },
    actionSetSystemRatePlan({commit}, payload) {
        return new Promise((resolve, reject) => {
            getPricingRatePlanList(payload)
                .then(response => {
                        const system_rate_plan = new schema.Entity('system_rate_plan');
                        const systemRatePlanSchema = {system_rate_plan: [system_rate_plan]}
                        const normalizedData = normalize({system_rate_plan: response.data.items}, systemRatePlanSchema);
                        commit('setSchema', {data: normalizedData, schema: 'system_rate_plan'})
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })
    },
    actionSetPriceList({commit, state}) {

        let priceData = []
        if (state.data.result.period.length > 0 && state.data.result.los.length > 0 && state.data.result.occupancy.length > 0) {
            for (let period of state.data.result.period) {
                for (let los of state.data.result.los) {

                    for (let occupancy of state.data.result.occupancy) {
                        let priceObject = {
                            id: period + '_' + los + '_' + occupancy,
                            period_id: period,
                            los_id: los,
                            occupancy_id: occupancy,
                            value: 0,
                        }
                        priceData.push(priceObject)
                    }
                }
            }
            const price = new schema.Entity('price')
            const priceSchema = {price: [price]}
            const normalizedData = normalize({price: priceData}, priceSchema)
            commit('setSchema', {data: normalizedData, schema: 'price'})
        }


    },
    actionSetStatistics({commit, state}) {
        let statisticsData = []
        if (state.data.result.period.length > 0) {
            for (let periodId of state.data.result.period) {
                let statisticObject = {
                    id: periodId,
                    num_of_reservations: null,
                    avg_guests: null,
                    days_reserved: null,
                    recommended_price: null,
                    price_from_pricelist: null,
                    pricelist_year: null,
                    reservation_year: null,
                    min_stay_from_pricelist: null,
                    avg_stay: null,
                }
                statisticsData.push(statisticObject)
            }
        }
        const statistics = new schema.Entity('statistics')
        const statisticsSchema = {statistics: [statistics]}
        const normalizedStatisticsData = normalize({statistics: statisticsData}, statisticsSchema)
        commit('setSchema', {data: normalizedStatisticsData, schema: 'statistics'})
    },
    actionSetCalculatePrice({state}, payload) {

        let newValue = 0
        for (let priceId of state.data.result.price) {
            if (state.data.entities.price[priceId].period_id === payload.periodId && priceId !== payload.defaultPriceId) {

                //CALCULATE PER OCCUPANCY
                let occupancyId = state.data.entities.price[priceId].occupancy_id
                let occupancy = state.data.entities.occupancy[occupancyId]
                if (typeof occupancy !== 'undefined') {
                    if (occupancy.discount && occupancy.discount_type) {
                        if (occupancy.discount_type === PERC) {
                            newValue = Number(payload.value) + (Number(payload.value) / 100) * Number(occupancy.discount);
                        }
                        if (occupancy.discount_type === FIX) {

                            newValue = Number(payload.value) + Number(occupancy.discount);
                        }
                    } else {
                        newValue = payload.value
                    }
                }

                // CALCULATE PER LOS
                let amount = !newValue ? payload.value : newValue

                let losId = state.data.entities.price[priceId].los_id
                let los = state.data.entities.los[losId]
                if (typeof los !== 'undefined') {
                    // LOS
                    if (los) {
                        if (los.discount && los.discount_type) {

                            if (los.discount_type === PERC) {

                                amount = Number(amount) + (Number(amount) / 100) * Number(los.discount);

                            }
                            if (los.discount_type === FIX) {

                                amount = Number(amount) + Number(los.discount);
                            }
                        }
                    }
                }
                state.data.entities.price[priceId].value = Math.ceil(amount)
            }
        }
    },
    actionSetRestrictionList({commit, state, rootGetters}) {

        let restrictionData = []
        for (let periodId of state.data.result.period) {
            for (let rate_plan_id of state.data.result.system_rate_plan) {
                let ratePlan = rootGetters['pricing/getSystemRatePlanById'](rate_plan_id)

                let minStayObject = {
                    id: periodId + '_' + MIN_STAY + '_' + rate_plan_id,
                    type: MIN_STAY,
                    rate_plan_id: rate_plan_id,
                    parent_rate_plan_id:null,
                    value: null,
                    period_id: periodId
                }
                restrictionData.push(minStayObject)
                let maxStayObject = {
                    id: periodId + '_' + MAX_STAY + '_' + rate_plan_id,
                    type: MAX_STAY,
                    rate_plan_id: rate_plan_id,
                    parent_rate_plan_id:null,
                    value: null,
                    period_id: periodId
                }
                restrictionData.push(maxStayObject)
                let releasePeriodObject = {
                    id: periodId + '_' + RELEASE_PERIOD + '_' + rate_plan_id,
                    type: RELEASE_PERIOD,
                    rate_plan_id: rate_plan_id,
                    parent_rate_plan_id:null,
                    value: null,
                    period_id: periodId,
                }
                restrictionData.push(releasePeriodObject)

                let closedOnArrival = {
                    id: periodId + '_' + CLOSED_ON_ARRIVAL + '_' + rate_plan_id,
                    type: CLOSED_ON_ARRIVAL,
                    rate_plan_id: rate_plan_id,
                    parent_rate_plan_id:null,
                    value: [],
                    period_id: periodId
                }
                restrictionData.push(closedOnArrival)
                let closedOnDeparture = {
                    id: periodId + '_' + CLOSED_ON_DEPARTURE + '_' + rate_plan_id,
                    type: CLOSED_ON_DEPARTURE,
                    rate_plan_id: rate_plan_id,
                    parent_rate_plan_id:null,
                    value: [],
                    period_id: periodId
                }
                restrictionData.push(closedOnDeparture)
                if (ratePlan && ratePlan.hasOwnProperty('rate_plan_category') && ratePlan.rate_plan_category === SYSTEM_STANDARD_RATE) {
                    let salesEnabled = {
                        id: periodId + '_' + SALES_ENABLED + '_' + rate_plan_id,
                        type: SALES_ENABLED,
                        rate_plan_id: rate_plan_id,
                        parent_rate_plan_id:null,
                        value: 1,
                        period_id: periodId
                    }
                    restrictionData.push(salesEnabled)
                }
            }
        }

        const restriction = new schema.Entity('restriction')
        const restrictionSchema = {restriction: [restriction]}
        const normalizedData = normalize({restriction: restrictionData}, restrictionSchema)
        commit('setSchema', {data: normalizedData, schema: 'restriction'})
    },
    actionSetPriceListFromPrpSetup({dispatch, rootGetters}, payload) {

        return new Promise((resolve, reject) => {
            getBulkCalendar(payload.property, {unit: payload.unit})
                .then(response => {
                        let statistic_list = rootGetters['pricing/getStatisticList'] ? rootGetters['pricing/getStatisticList'] : []
                        if (response.data.length > 0 && statistic_list.length > 0) {
                            for (let periodPrp of response.data) {
                                let statistic_id = periodPrp.id
                                let statisticObject = rootGetters['pricing/getStatisticById'](statistic_id)

                                if(typeof statisticObject !== 'undefined'){
                                    statisticObject.num_of_reservations = periodPrp.statistics.num_of_reservations
                                    statisticObject.days_reserved = periodPrp.statistics.days_reserved
                                    statisticObject.avg_guests = periodPrp.statistics.avg_guests
                                    statisticObject.avg_stay = periodPrp.statistics.avg_stay
                                    statisticObject.price_from_pricelist = periodPrp.statistics.price_from_pricelist
                                    statisticObject.pricelist_year = periodPrp.statistics.pricelist_year
                                    statisticObject.recommended_price = periodPrp.statistics.recommended_price
                                    statisticObject.reservation_year = periodPrp.statistics.reservation_year
                                    statisticObject.min_stay_from_pricelist = periodPrp.statistics.min_stay_from_pricelist
                                }
                                if (periodPrp.restrictions.length > 0) {
                                    for (let restrictionPrp of periodPrp.restrictions) {
                                        let restriction_id = periodPrp.id + '_' + restrictionPrp.id + '_' + restrictionPrp.rate_plan_id
                                        let restrictionObject = rootGetters['pricing/getRestrictionById'](restriction_id)
                                        if (typeof restrictionObject !== 'undefined') {
                                            restrictionObject.value = restrictionPrp.value
                                            restrictionObject.parent_rate_plan_id = restrictionPrp.parent_rate_plan_id
                                        }
                                    }
                                }
                                for (let pricePrp of periodPrp.prices) {
                                    let price_id = periodPrp.id + '_' + pricePrp.los_id + '_' + pricePrp.occupancy_id
                                    let priceObject = rootGetters['pricing/getPriceById'](price_id)
                                    if (typeof priceObject !== 'undefined') {
                                        priceObject.value = Math.ceil(pricePrp.value)
                                    }
                                }
                            }
                        }
                        resolve(response)
                    },
                    error => {
                        dispatch('actionSetPriceList')
                        dispatch('actionSetRestrictionList')
                        reject(error)
                    })
        })
    },
    actionSavePricingToPrpSetup({commit, state, dispatch}, payload) {
        let data = []
        let statisticObject = {
            num_of_reservations: null,
            days_reserved: null,
            avg_guests: null,
            avg_stay: null,
            price_from_pricelist: null,
            min_stay_from_pricelist: null,
            pricelist_year: null,
            reservation_year: null,
            recommended_price: null,
        }
        for (let periodId of state.data.result.period) {
            let period = state.data.entities.period[periodId]
            if (state.data.result.statistics.length > 0) {
                statisticObject = state.data.entities.statistics[periodId]
            }

            let pricingObject = {
                period_id: periodId,
                period_start: period.period_start,
                period_end: period.period_end,

                number_of_reservations: statisticObject.num_of_reservations,
                days_reserved: statisticObject.days_reserved,
                avg_guests: statisticObject.avg_guests,
                avg_stay: statisticObject.avg_stay,
                price_from_pricelist: statisticObject.price_from_pricelist,
                min_stay_from_pricelist: statisticObject.min_stay_from_pricelist,
                pricelist_year: statisticObject.pricelist_year,
                reservation_year: statisticObject.reservation_year,
                recommended_price: statisticObject.recommended_price,
                price: [],
                restrictions: []
            }

            for (let priceId of state.data.result.price) {
                let price = state.data.entities.price[priceId]
                if (price.period_id === periodId) {

                    let priceObject = {
                        los_id: price.los_id,
                        occupancy_id: price.occupancy_id,
                        value: Math.ceil(price.value)
                    }
                    pricingObject.price.push(priceObject)

                }
            }
            for (let restrictionId of state.data.result.restriction) {
                let restriction = state.data.entities.restriction[restrictionId]
                if (restriction.parent_rate_plan_id){
                    continue
                }
                if (restriction.period_id === periodId) {
                    if(Number(restrictionId) === MAX_STAY && (Array.isArray(restriction.value) && restriction.value.length === 0 || restriction.value === "")){
                        restriction.value = null
                    }
                    let restrictionObject = {
                        id: restriction.type,
                        rate_plan: restriction.rate_plan_id,
                        parent_rate_plan_id: restriction.parent_rate_plan_id,
                        value: restriction.value
                    }
                    pricingObject.restrictions.push(restrictionObject)
                }
            }
            data.push(pricingObject)
        }

        let postData = {
            years:payload.years,
            unit: payload.unit,
            sync_options:payload.sync_options,
            data: data,
        }
        let priceData = []
        let restrictionData = []
        return new Promise((resolve, reject) => {
            setBulkCalendar(payload.property, postData)
                .then(response => {
                        if (response.data.length > 0) {
                            for (let periodPrp of response.data) {
                                for (let pricePrp of periodPrp.prices) {
                                    let priceObject = {
                                        id: periodPrp.id + '_' + pricePrp.los_id + '_' + pricePrp.occupancy_id,
                                        period_id: periodPrp.id,
                                        los_id: pricePrp.los_id,
                                        occupancy_id: pricePrp.occupancy_id,
                                        value: pricePrp.value,
                                    }
                                    priceData.push(priceObject)
                                }
                                for (let restrictionPrp of periodPrp.restrictions) {
                                    let restrictionObject = {
                                        id: periodPrp.id + '_' + restrictionPrp.id + '_' + restrictionPrp.rate_plan_id,
                                        period_id: periodPrp.id,
                                        type: restrictionPrp.id,
                                        rate_plan_id: restrictionPrp.rate_plan_id,
                                        parent_rate_plan_id: restrictionPrp.parent_rate_plan_id,
                                        value: restrictionPrp.value
                                    }
                                    restrictionData.push(restrictionObject)
                                }
                            }
                            const price = new schema.Entity('price')
                            const priceSchema = {price: [price]}
                            const normalizedData = normalize({price: priceData}, priceSchema)
                            commit('setSchema', {data: normalizedData, schema: 'price'})

                            const restriction = new schema.Entity('restriction')
                            const restrictionSchema = {restriction: [restriction]}
                            const normalizedRestrictionData = normalize({restriction: restrictionData}, restrictionSchema)
                            commit('setSchema', {data: normalizedRestrictionData, schema: 'restriction'})
                        } else {
                            dispatch('actionSetPriceList')
                            dispatch('actionSetRestrictionList')

                        }

                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })

    },
    actionSetRmSuggestion({commit, state, dispatch}, suggestion) {

        if (suggestion.length === 0) {
            return
        }
        let statisticsData = []
        for (let data of suggestion) {
            let date_from = new Date(data.date_from)
            let period_start = moment(date_from).format('MM/DD')
            let date_to = new Date(data.date_to)
            let period_end = moment(date_to).format('MM/DD')

            for (let periodId of state.data.result.period) {
                let period = state.data.entities.period[periodId]
                if (period.period_start === period_start && period.period_end === period_end) {

                    let statisticObject = {
                        id: periodId,
                        num_of_reservations: data.calculation_data.reservation_count,
                        avg_guests: data.calculation_data.avg_guests,
                        days_reserved: data.calculation_data.reservation_stay_total,
                        recommended_price: data.price.recommended,
                        price_from_pricelist: data.price.current,
                        pricelist_year: data.price.year,
                        reservation_year: data.calculation_data.year_reservation,
                        min_stay_from_pricelist: null,
                        avg_stay: null,
                    }

                    for (let priceId of state.data.result.price) {
                        let price = state.data.entities.price[priceId]

                        if (price.period_id === period.id) {
                            if (data.price.los === price.los_id && data.price.occupancy === price.occupancy_id) {
                                let priceValue = data.price.recommended ? data.price.recommended : data.price.current
                                if (priceValue) {
                                    let priceData = {
                                        value: Math.ceil(priceValue),
                                        defaultPriceId: data.price.los + '_' + data.price.occupancy + '_' + periodId,
                                        periodId: periodId
                                    }
                                    dispatch('actionSetCalculatePrice', priceData)
                                }
                            }

                        }
                    }
                    for (let restrictionId of state.data.result.restriction) {
                        let restriction = state.data.entities.restriction[restrictionId]
                        if (restriction.period_id === period.id) {
                            for (let restrictionSuggestion of data.restriction) {
                                if (restrictionSuggestion.restriction === 1) {
                                    statisticObject.avg_stay = restrictionSuggestion.recommended
                                    statisticObject.min_stay_from_pricelist = restrictionSuggestion.current
                                }
                            }
                        }
                    }
                    statisticsData.push(statisticObject)
                }
            }
        }
        const statistics = new schema.Entity('statistics')
        const statisticsSchema = {statistics: [statistics]}
        const normalizedStatisticsData = normalize({statistics: statisticsData}, statisticsSchema)
        commit('setSchema', {data: normalizedStatisticsData, schema: 'statistics'})
    },

    actionSearchRatePlan({commit, state}) {
        return new Promise((resolve, reject) => {
            getPricingRatePlanList(state.search.filter)
                .then((response) => {
                    const rate_plan = new schema.Entity('rate_plan');
                    const ratePlanSchema = {rate_plan: [rate_plan]}
                    const normalizedData = normalize({rate_plan: response.data.items}, ratePlanSchema);
                    commit('setSchema', {data: normalizedData, schema: 'rate_plan'})

                    commit('setSearchList', response.data.items)
                    commit('setSearchListCount', response.data.total)
                    commit('setSearchListLastPage', response.data.last_page)
                    resolve(response)
                }).catch(error => {
                reject(error)
            })
        })
    },
    actionCreatePricingRatePlan({commit}, payload) {
        return new Promise((resolve, reject) => {
            createPricingRatePlan(payload.object)
                .then(response => {
                        const rate_plan = new schema.Entity('rate_plan');
                        const ratePlanSchema = {rate_plan: [rate_plan]}
                        const normalizedData = normalize({rate_plan: response.data.items}, ratePlanSchema);
                        commit('setSchema', {data: normalizedData, schema: 'rate_plan'})

                        commit('setSearchList', response.data.items)
                        commit('setSearchListCount', response.data.total)
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })
    },
    actionUpdatePricingRatePlan({commit}, payload) {
        return new Promise((resolve, reject) => {
            updatePricingRatePlan(payload.id, payload.object)
                .then(response => {
                        const rate_plan = new schema.Entity('rate_plan');
                        const ratePlanSchema = {rate_plan: [rate_plan]}
                        const normalizedData = normalize({rate_plan: response.data.items}, ratePlanSchema);
                        commit('setSchema', {data: normalizedData, schema: 'rate_plan'})

                        commit('setSearchList', response.data.items)
                        commit('setSearchListCount', response.data.total)
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })
    },
    actionDeletePricingRatePlan({commit}, payload) {
        return new Promise((resolve, reject) => {
            deletePricingRatePlan(payload.id, payload)
                .then(response => {
                        const rate_plan = new schema.Entity('rate_plan');
                        const ratePlanSchema = {rate_plan: [rate_plan]}
                        const normalizedData = normalize({rate_plan: response.data.items}, ratePlanSchema);
                        commit('setSchema', {data: normalizedData, schema: 'rate_plan'})

                        commit('setSearchList', response.data.items)
                        commit('setSearchListCount', response.data.total)
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })
    },
    actionCopyRatePlans({commit}, payload) {
        return new Promise((resolve, reject) => {
            copyRatePlans(payload)
                .then(response => {
                        const rate_plan = new schema.Entity('rate_plan');
                        const ratePlanSchema = {rate_plan: [rate_plan]}
                        const normalizedData = normalize({rate_plan: response.data.items}, ratePlanSchema);
                        commit('setSchema', {data: normalizedData, schema: 'rate_plan'})

                        commit('setSearchList', response.data.items)
                        commit('setSearchListCount', response.data.total)
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })
    },
    actionSetDistributionLst({commit}) {
        return new Promise((resolve, reject) => {
            getDistributionManagers()
                .then(response => {
                        const distribution = new schema.Entity('distribution');
                        const distributionSchema = {distribution: [distribution]}
                        const normalizedData = normalize({distribution: response.data}, distributionSchema);
                        commit('setSchema', {data: normalizedData, schema: 'distribution'})
                        resolve(response)
                    },
                    error => {
                        reject(error)
                    })
        })
    },
    actionApplyToAll({state}, payload) {
        for (let id  of state.data.result.restriction) {
            let restriction = state.data.entities.restriction[id]
            if (restriction.type === payload.type && restriction.rate_plan_id === payload.selected_rate_plan) {
                restriction.value = payload.value
            }
        }
    },
    actionApplyRestrictionFromStandard({state, rootGetters}) {

        let standardRatePlan = {id: null, type: null}
        let nonRefRatePlan = {id: null, type: null}
        for (let rate_plan_id of state.data.result.system_rate_plan) {
            let systemRatePlan = rootGetters['pricing/getSystemRatePlanById'](rate_plan_id)
            if (systemRatePlan.rate_plan_category === SYSTEM_STANDARD_RATE) {
                standardRatePlan = systemRatePlan
            } else if (systemRatePlan.rate_plan_category === SYSTEM_NON_REFUNDABLE_RATE) {
                nonRefRatePlan = systemRatePlan
            }
        }

        let restrictionsStandardRate = []
        for (let id  of state.data.result.restriction) {
            let restriction = state.data.entities.restriction[id]
            if (restriction.rate_plan_id === standardRatePlan.id) {
                restrictionsStandardRate.push(restriction)
            }
        }
        for (let id  of state.data.result.restriction) {
            let restriction = state.data.entities.restriction[id]
            for (let restrictionStandard of restrictionsStandardRate) {
                if (restriction.rate_plan_id === nonRefRatePlan.id && restriction.period_id === restrictionStandard.period_id && restriction.type === restrictionStandard.type && restriction.type !== SALES_ENABLED) {
                    restriction.value = restrictionStandard.value
                }
            }
        }
    },

    actionResetRatePlanSearch({commit}) {
        commit("setSearchFilterItem", {item: "page", value: 1})
        commit("setSearchFilterItem", {item: "limit", value: 10})
        commit("setSearchFilterItem", {item: "name", value: null})
        commit("setSearchFilterItem", {item: "distribution", value: null})
        commit("setSearchFilterItem", {item: "charge_profile", value: null})
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters

}
