<template>
    <b-form @submit.prevent="save" autocomplete="off" :novalidate="true">
        <b-row v-if="unitList.length > 0 && loaded">
            <b-col class="mb-4">
                <table class="table b-table">
                    <thead>
                        <th colspan="2">{{ $t('UNIT') }}</th>
                        <th v-if="submodule === ACTIVE_REVENUE_MANAGER_RATES">
                            {{ $t('SECURED_VALUE_DECREASE') }} (%)
                        </th>
                        <th v-else>{{ $t('SECURED_VALUE') }}</th>
                        <th v-if="submodule === ACTIVE_REVENUE_MANAGER_RATES">
                            {{ $t('ALLOW_RATE_INCREASE') }}
                        </th>
                        <th class="text-right">{{ $t('ACTIVE') }}</th>
                    </thead>
                    <tbody>
                    <template v-for="(unitObject, unitIndex) in unitList">
                        <tr :key="unitObject.id">
                            <td colspan="2">{{ unitObject.title }}</td>
                            <td>
                                <b-tooltip v-if="!read_only && disabledUnit(unitObject)"
                                           triggers="hover"
                                           :title="unitObject.error_list"
                                           :target="'activation_rm_input_'+unitObject.id"
                                ></b-tooltip>
                                <b-tooltip v-else-if="!read_only && activation_unit[unitObject.id] === 0"
                                           triggers="hover"
                                           :title="$t('INACTIVE')"
                                           :target="'activation_rm_input_'+unitObject.id"
                                ></b-tooltip>
                                <div :id="'activation_rm_input_'+unitObject.id">
                                    <b-input type="number" step="1"
                                             :key="'component_'+componentFormNumberInputKey"
                                             :disabled="disabledUnitFields(unitObject) || activation_unit[unitObject.id] === 0"
                                             v-model.number="$v.unitList.$each[unitIndex].secured_value.$model"
                                             @change="(value) => { if (value === null || value === '') $v.unitList.$each[unitIndex].secured_value.$model = null; setEnabledSave(); }"
                                             @input="(value) => { if (value === null || value === '') $v.unitList.$each[unitIndex].secured_value.$model = null; setEnabledSave(); }"
                                    ></b-input>
                                    <validation-description
                                        :show="$v.unitList.$each[unitIndex].secured_value.$invalid">
                                        <span v-if="!$v.unitList.$each[unitIndex].secured_value.integer">{{ $t('V.NUMERIC') }}. </span>
                                        <span>{{ $t('V.VALUE_BETWEEN', {min: number_min, max: number_max}) }}</span>
                                    </validation-description>
                                </div>
                            </td>
                            <td v-if="submodule === ACTIVE_REVENUE_MANAGER_RATES">
                                <b-tooltip v-if="!read_only && disabledUnit(unitObject)"
                                           triggers="hover"
                                           :title="unitObject.error_list"
                                           :target="'activation_rm_allow_rate_increase_'+unitObject.id"
                                ></b-tooltip>
                                <b-tooltip v-else-if="read_only"
                                           triggers="hover"
                                           :title="read_only_message"
                                           :target="'activation_rm_allow_rate_increase_'+unitObject.id"
                                ></b-tooltip>
                                <b-tooltip v-else-if="!read_only && activation_unit[unitObject.id] === 0"
                                           triggers="hover"
                                           :title="$t('INACTIVE')"
                                           :target="'activation_rm_allow_rate_increase_'+unitObject.id"
                                ></b-tooltip>
                                <div :id="'activation_rm_allow_rate_increase_'+unitObject.id" class="w-25 pull-left">
                                    <b-form-checkbox
                                        value-field="id"
                                        :unchecked-value="0"
                                        :value="1"
                                        stacked
                                        switch
                                        v-model="allow_rate_increase[unitObject.id]"
                                        :key="'component_'+componentFormNumberInputKey"
                                        :disabled="disabledUnitFields(unitObject) || activation_unit[unitObject.id] === 0"
                                        @change="setEnabledSave()"
                                        @input="setEnabledSave()"
                                    ></b-form-checkbox>
                                </div>
                            </td>
                            <td>
                                <b-tooltip v-if="!read_only && disabledUnit(unitObject)"
                                           triggers="hover"
                                           :title="unitObject.error_list"
                                           :target="'activation_rme_'+unitObject.id"
                                ></b-tooltip>
                                <b-tooltip v-else-if="!read_only && disabled[unitObject.id] && activation_unit[unitObject.id] === 1"
                                           triggers="hover"
                                           :title="$t('UNIT_DEACTIVATION_NOT_POSSIBLE')"
                                           :target="'activation_rme_'+unitObject.id"
                                ></b-tooltip>
                                <b-tooltip v-else-if="read_only"
                                           triggers="hover"
                                           :title="read_only_message"
                                           :target="'activation_rme_'+unitObject.id"
                                ></b-tooltip>
                                <div :id="'activation_rme_'+unitObject.id" class="w-25 pull-right">
                                    <b-form-checkbox
                                        class="w-25"
                                        value-field="id"
                                        :unchecked-value="0"
                                        :value="1"
                                        stacked
                                        switch
                                        v-model="activation_unit[unitObject.id]"
                                        :key="unitObject.id"
                                        :disabled="disabledUnitFields(unitObject) || disabled[unitObject.id]"
                                        @change="setEnabledSave(true)"
                                        @input="setEnabledSave(true)"
                                    ></b-form-checkbox>
                                </div>
                            </td>
                        </tr>
                        <template v-if="submodule === ACTIVE_REVENUE_MANAGER_RATES && unitObject.hasOwnProperty('sales_period_setup') && unitObject.sales_period_setup && unitObject.sales_period_setup.length">
                            <tr :key="unitObject.id+'_periods_header'">
                                <td></td>
                                <td class="bg-light">{{ $t('PERIOD') }}</td>
                                <td class="bg-light">
                                    {{ $t('HIGH_AVAILABILITY') }} (%)
                                    <b-tooltip v-if="!read_only && activation_unit[unitObject.id] === 0"
                                               triggers="hover"
                                               :title="$t('INACTIVE')"
                                               :target="'activation_rme_unit_high_availability_'+unitObject.id"
                                    ></b-tooltip>
                                    <div :id="'activation_rme_unit_high_availability_'+unitObject.id">
                                        <b-input type="number" step="1"
                                                 :key="'component_'+componentFormNumberInputKey"
                                                 :disabled="disabledUnitFields(unitObject) || activation_unit[unitObject.id] === 0"
                                                 v-model.number="$v.unitListGroups.$each[unitIndex].high_availability.$model"
                                                 @change="setUnitSalePeriodsHighAvailabilityPercentValues(unitIndex, unitObject, $event)"
                                                 @input="setUnitSalePeriodsHighAvailabilityPercentValues(unitIndex, unitObject, $event)"
                                        ></b-input>
                                        <validation-description
                                            :show="$v.unitListGroups.$each[unitIndex].high_availability.$dirty && $v.unitListGroups.$each[unitIndex].high_availability.$invalid">
                                            <span v-if="!$v.unitListGroups.$each[unitIndex].high_availability.integer">{{ $t('V.NUMERIC') }}. </span>
                                            <span>{{ $t('V.VALUE_BETWEEN', {min: number_min, max: number_max}) }}</span>
                                        </validation-description>
                                    </div>
                                </td>
                                <td class="bg-light" colspan="2">
                                    {{ $t('ALLOWED_RATE_DECREASE') }}
                                    <b-tooltip v-if="!read_only && activation_unit[unitObject.id] === 0"
                                               triggers="hover"
                                               :title="$t('INACTIVE')"
                                               :target="'activation_rme_unit_allowed_rate_decrease_'+unitObject.id"
                                    ></b-tooltip>
                                    <div :id="'activation_rme_unit_allowed_rate_decrease_'+unitObject.id">
                                        <b-input type="number" step="1"
                                                 :key="'component_'+componentFormNumberInputKey"
                                                 :disabled="disabledUnitFields(unitObject) || activation_unit[unitObject.id] === 0"
                                                 v-model.number="$v.unitListGroups.$each[unitIndex].allowed_rate_decrease.$model"
                                                 @change="setUnitSalePeriodsAllowedRateDecreasePercentValues(unitIndex, unitObject, $event)"
                                                 @input="setUnitSalePeriodsAllowedRateDecreasePercentValues(unitIndex, unitObject, $event)"
                                        ></b-input>
                                        <validation-description
                                            :show="$v.unitListGroups.$each[unitIndex].allowed_rate_decrease.$dirty && $v.unitListGroups.$each[unitIndex].allowed_rate_decrease.$invalid">
                                            <span v-if="!$v.unitListGroups.$each[unitIndex].allowed_rate_decrease.integer">{{ $t('V.NUMERIC') }}. </span>
                                            <span>{{ $t('V.VALUE_BETWEEN', {min: number_min, max: number_max}) }}</span>
                                        </validation-description>
                                    </div>
                                </td>
                            </tr>
                            <tr :key="unitObject.id+'_period_'+salesPeriod.id" v-for="(salesPeriod, salesPeriodIndex) in unitObject.sales_period_setup">
                                <td></td>
                                <td>{{ salesPeriod.period_start | periodDate }} - {{ salesPeriod.period_end | periodDate }}</td>
                                <td>
                                    <b-tooltip v-if="!read_only && activation_unit[unitObject.id] === 0"
                                               triggers="hover"
                                               :title="$t('INACTIVE')"
                                               :target="'activation_rme_high_availability_'+unitObject.id+'_'+salesPeriod.id"
                                    ></b-tooltip>
                                    <div :id="'activation_rme_high_availability_'+unitObject.id+'_'+salesPeriod.id">
                                        <b-input type="number" step="1"
                                                 :key="'component_'+componentFormNumberInputKey"
                                                 :id="'activation_rme_high_availability_'+unitObject.id+'_'+salesPeriod.id"
                                                 :tabIndex="(unitIndex+1)+'_'+(salesPeriodIndex+1)+'_rm_input_unit_high_availability'"
                                                 :disabled="disabledUnitFields(unitObject) || activation_unit[unitObject.id] === 0"
                                                 v-model.number="$v.unitList.$each[unitIndex].sales_period_setup.$each[salesPeriodIndex].high_availability.$model"
                                                 @change="setEnabledSave()"
                                                 @input="setEnabledSave()"
                                        ></b-input>
                                        <validation-description
                                            :show="$v.unitList.$each[unitIndex].sales_period_setup.$each[salesPeriodIndex].high_availability.$invalid">
                                            <span v-if="!$v.unitList.$each[unitIndex].sales_period_setup.$each[salesPeriodIndex].high_availability.integer">{{ $t('V.NUMERIC') }}. </span>
                                            <span>{{ $t('V.VALUE_BETWEEN', {min: number_min, max: number_max}) }}</span>
                                        </validation-description>
                                    </div>
                                </td>
                                <td colspan="2">
                                    <b-tooltip v-if="!read_only && activation_unit[unitObject.id] === 0"
                                               triggers="hover"
                                               :title="$t('INACTIVE')"
                                               :target="'activation_rme_allowed_rate_decrease_'+unitObject.id+'_'+salesPeriod.id"
                                    ></b-tooltip>
                                    <div :id="'activation_rme_allowed_rate_decrease_'+unitObject.id+'_'+salesPeriod.id">
                                        <b-input type="number" step="1"
                                                 :key="'component_'+componentFormNumberInputKey"
                                                 :tabIndex="(unitIndex+1)+'_'+(salesPeriodIndex+1)+'_rm_input_unit_rate_decrease_allowed'"
                                                 :disabled="disabledUnitFields(unitObject) || activation_unit[unitObject.id] === 0"
                                                 v-model.number="$v.unitList.$each[unitIndex].sales_period_setup.$each[salesPeriodIndex].allowed_rate_decrease.$model"
                                                 @change="setEnabledSave()"
                                                 @input="setEnabledSave()"
                                        ></b-input>
                                        <validation-description
                                            :show="$v.unitList.$each[unitIndex].sales_period_setup.$each[salesPeriodIndex].allowed_rate_decrease.$invalid">
                                            <span v-if="!$v.unitList.$each[unitIndex].sales_period_setup.$each[salesPeriodIndex].allowed_rate_decrease.integer">{{ $t('V.NUMERIC') }}. </span>
                                            <span>{{ $t('V.VALUE_BETWEEN', {min: number_min, max: number_max}) }}</span>
                                        </validation-description>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                    </tbody>
                </table>
            </b-col>
        </b-row>

        <b-row v-if="unitList.length > 0 && loaded">
            <b-col>
                <b-tooltip v-if="read_only"
                           :title="read_only_message"
                           placement="top"
                           target="saveButton"
                ></b-tooltip>
                <b-tooltip v-else-if="!read_only && $v.unitList.$error === true"
                           :title="$t('VALIDATION_FAILED')"
                           placement="top"
                           target="saveButton"
                ></b-tooltip>
                <b-tooltip v-else-if="!read_only && enabledSave === false"
                           :title="$t('NO_CHANGE')"
                           placement="top"
                           target="saveButton"
                ></b-tooltip>
                <app-button-submit :loading="loader"
                                   :inline="true"
                                   :disabled="read_only || enabledSave === false || $v.unitList.$error === true"
                                   id="saveButton"
                ></app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import ValidationDescription from '@/components/app/form/AppValidationDescription'
import {integer, required, between} from 'vuelidate/lib/validators'
import _debounce from "lodash/debounce";
import {
    getRevenueManagerActivateProperty,
    getRevenueManagerPropertyActivationData,
    setRevenueManagerActivateProperty
} from "@/services/revenue_manager";
import {EventBus} from "@/shared/EventBus";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {
    ACTIVE_REVENUE_MANAGER_RATES,
    ACTIVE_REVENUE_MANAGER_RATES_EXTERNAL,
    DIRECT_BOOKER_COMPANY,
} from "@/shared/constants";

export default {
    name: "RevenueManagerSetupRatesActivation",
    components: {AppButtonSubmit, ValidationDescription},
    props: {
        property_ids: {
            type: Array,
            default() {
                return []
            }
        },
        submodule: {
            type: Number
        },
        mode: {
            type: String
        },
        read_only: {
            type: Boolean,
            default: false
        },
        read_only_message: {
            type: String
        },
    },
    mixins: [getErrorMessage],
    data() {
        return {
            ACTIVE_REVENUE_MANAGER_RATES,
            unitList: [],
            unitListGroups: [],
            activation_unit: {},
            disabled: {},
            allow_rate_increase: {},
            sales_period_setup_high_availability: {},
            sales_period_setup_allowed_rate_decrease: {},
            enabledSave: false,
            loaded: false,
            loader: false,
            number_min: 0,
            number_max: 99,
            componentFormNumberInputKey: 0,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        submoduleComputed() {
            if (this.submodule === ACTIVE_REVENUE_MANAGER_RATES && this.company.id === DIRECT_BOOKER_COMPANY) {
                return ACTIVE_REVENUE_MANAGER_RATES_EXTERNAL
            }
            return this.submodule
        },
        validationRules () {
            let rulesObject = {}
            if (this.unitList.length > 0) {
                const percentRule = {
                    integer,
                    between: between(this.number_min, this.number_max)
                }

                const unitListEachRules = {
                    secured_value: percentRule,
                }

                if (this.submodule === ACTIVE_REVENUE_MANAGER_RATES) {
                    unitListEachRules.sales_period_setup = {
                        $each: {
                            high_availability: percentRule,
                            allowed_rate_decrease: percentRule,
                        }
                    }

                    rulesObject.unitListGroups = {
                        required,
                        $each: {
                            high_availability: percentRule,
                            allowed_rate_decrease: percentRule,
                        }
                    }
                }

                rulesObject.unitList = {
                    required,
                    $each: unitListEachRules
                }
            }
            return rulesObject
        }
    },
    validations() {
        return this.validationRules
    },
    methods: {
        clearGroupInputs() {
            if (this.unitList.length > 0) {
                for (const unitObject of this.unitList) {
                    const unitIndex = this.unitList.findIndex(unit => Number(unit.id) === Number(unitObject.id))
                    if (unitIndex > -1) {
                        if (this.submodule === ACTIVE_REVENUE_MANAGER_RATES && this.unitListGroups.length > 0 && this.unitListGroups.hasOwnProperty(unitIndex)) {
                            this.$set(this.unitListGroups[unitIndex], 'high_availability', null)
                            this.$set(this.unitListGroups[unitIndex], 'allowed_rate_decrease', null)
                        }
                    }
                }
            }
        },
        save() {
            this.clearGroupInputs()
            this.$v.unitList.$touch();
            if ((this.$v.unitList.$error)) {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('VALIDATION_FAILED'),
                    'type': 'error',
                    'timeout': 6000
                })
                return
            }

            this.loader = true
            const request = {
                submodule: this.submoduleComputed,
                property: []
            }
            for (const property_id of this.property_ids) {
                let reqPropertyObject = {
                    id: property_id,
                    unit: []
                }
                if (this.unitList.length > 0) {
                    for (const unitObject of this.unitList) {
                        if (!unitObject.hasOwnProperty('property_id') || Number(unitObject.property_id) === Number(property_id)) {
                            if (this.unitEnabledSave(unitObject)) {
                                let reqUnitObject = {
                                    id: unitObject.id,
                                    secured_value: unitObject.hasOwnProperty('secured_value') && unitObject.secured_value !== null ? Number(unitObject.secured_value) : null,
                                }
                                if (this.submodule === ACTIVE_REVENUE_MANAGER_RATES) {
                                    reqUnitObject.allow_rate_increase = Number(this.allow_rate_increase[unitObject.id])
                                    reqUnitObject.sales_period_setup = unitObject.sales_period_setup.map(salesPeriod => {
                                        return {
                                            sales_period: salesPeriod.id,
                                            high_availability: Number(salesPeriod.high_availability || 0),
                                            allowed_rate_decrease: Number(salesPeriod.allowed_rate_decrease || 0),
                                        }
                                    })
                                }
                                reqPropertyObject.unit.push(reqUnitObject)
                            }
                        }
                    }
                }
                request.property.push(reqPropertyObject)
            }
            setRevenueManagerActivateProperty(request).then(response => {
                this.$emit('close')
                EventBus.$emit('gl_revenue_setup_refresh_property')
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })
            }).finally(() => {
                this.loader = false
            })
        },
        setEnabledSave(activation = false) {
            this.$v.unitList.$touch();
            let enabled = false
            if (!this.read_only && this.unitList.length > 0) {
                for (const unitObject of this.unitList) {
                    if (!enabled && this.unitEnabledSave(unitObject)) {
                        enabled = true
                    }
                }
            }
            this.enabledSave = enabled
            if (activation) {
                this.$nextTick(() => {
                    this.forceReRender()
                })
            }
        },
        unitEnabledSave(unitObject) {
            return !this.read_only
                && Number(this.activation_unit[unitObject.id]) === 1
                && !this.disabledUnit(unitObject)
                && (!this.disabled[unitObject.id] || (this.disabled[unitObject.id]) && this.changedUnit(unitObject))
        },
        disabledUnitFields(unitObject) {
            return this.read_only
                || (this.disabled[unitObject.id] && Number(this.activation_unit[unitObject.id]) === 0)
                || this.disabledUnit(unitObject)
        },
        disabledUnit(unitObject) {
            return !(typeof unitObject.error_list === 'object' && unitObject.error_list.length === 0)
        },
        changedUnit(unitObject) {
            let changed = false
            if (unitObject.hasOwnProperty('secured_value') && unitObject.secured_value !== null) {
                changed = true
            }
            if (!changed && this.submodule === ACTIVE_REVENUE_MANAGER_RATES) {
                if (unitObject.hasOwnProperty('allow_rate_increase') && Number(this.allow_rate_increase[unitObject.id]) !== Number(unitObject.allow_rate_increase || 0)) {
                    changed = true
                }
                if (!changed && unitObject.hasOwnProperty('sales_period_setup') && unitObject.sales_period_setup && unitObject.sales_period_setup.length) {
                    unitObject.sales_period_setup.forEach(salesPeriod => {
                        if (!changed) {
                            const sales_period_key = unitObject.id + '_' + salesPeriod.id
                            if (Number(this.sales_period_setup_high_availability[sales_period_key]) !== Number(salesPeriod.high_availability || 0)) {
                                changed = true
                            }
                            if (Number(this.sales_period_setup_allowed_rate_decrease[sales_period_key]) !== Number(salesPeriod.allowed_rate_decrease || 0)) {
                                changed = true
                            }
                        }
                    })
                }
            }
            return changed
        },
        forceReRender() {
            this.componentFormNumberInputKey += 1
        },
        setUnitSalePeriodsHighAvailabilityPercentValues: _debounce(function (unitIndex, unitObject, value) {
            if (unitObject.hasOwnProperty('sales_period_setup') && unitObject.sales_period_setup && unitObject.sales_period_setup.length) {
                if (!(value === null || value === undefined || value === "" || value === " " || value === "-")) {
                    if (Number(value) >= this.number_min && Number(value) <= this.number_max) {
                        unitObject.sales_period_setup.forEach((salesPeriod, salesPeriodIndex) => {
                            this.$set(this.unitList[unitIndex].sales_period_setup[salesPeriodIndex], 'high_availability', Number(value))
                        })
                    }
                    this.setEnabledSave()
                }
            }
        }, 500),
        setUnitSalePeriodsAllowedRateDecreasePercentValues: _debounce(function (unitIndex, unitObject, value) {
            if (unitObject.hasOwnProperty('sales_period_setup') && unitObject.sales_period_setup && unitObject.sales_period_setup.length) {
                if (!(value === null || value === undefined || value === "" || value === " " || value === "-")) {
                    if (Number(value) >= this.number_min && Number(value) <= this.number_max) {
                        unitObject.sales_period_setup.forEach((salesPeriod, salesPeriodIndex) => {
                            this.$set(this.unitList[unitIndex].sales_period_setup[salesPeriodIndex], 'allowed_rate_decrease', Number(value))
                        })
                    }
                    this.setEnabledSave()
                }
            }
        }, 500),
        loadFormData() {
            this.unitList = []
            this.unitListGroups = []
            this.activation_unit = {}
            this.disabled = {}
            this.allow_rate_increase = {}
            this.sales_period_setup_high_availability = {}
            this.sales_period_setup_allowed_rate_decrease = {}
            this.enabledSave = false
            this.loaded = false
            this.loader = false

            if (this.property_ids.length > 0) {
                getRevenueManagerActivateProperty({property: this.property_ids, submodule: this.submoduleComputed}).then(response => {
                    this.unitList = response.data
                    if (response.data.length > 0) {
                        for (const unitObject of response.data) {
                            const activated = ((unitObject.active === null && typeof unitObject.error_list === 'object' && unitObject.error_list.length === 0) || Number(unitObject.active) === 1) ? 1 : 0
                            this.activation_unit[unitObject.id] = activated
                            if (this.mode === 'new') {
                                this.disabled[unitObject.id] = false
                            } else {
                                if ((unitObject.active === null && typeof unitObject.error_list === 'object' && unitObject.error_list.length === 0) || Number(unitObject.active) === 1) {
                                    this.disabled[unitObject.id] = true
                                } else {
                                    this.disabled[unitObject.id] = Number(unitObject.active) === 1
                                }
                            }

                            const unitIndex = this.unitList.findIndex(unit => Number(unit.id) === Number(unitObject.id))
                            if (unitIndex > -1) {
                                this.$set(this.unitList[unitIndex], 'activated', !!activated)
                                this.$set(this.unitList[unitIndex], 'secured_value', null)
                            }
                        }
                        if (this.submodule === ACTIVE_REVENUE_MANAGER_RATES && this.property_ids.length === 1) {
                            const property_id = this.property_ids.find(el => true)
                            getRevenueManagerPropertyActivationData(property_id).then(response => {
                                if (response.data.hasOwnProperty('units') && response.data.units && response.data.units.length) {
                                    for (const unitObject of response.data.units) {
                                        this.allow_rate_increase[unitObject.id] = unitObject.allow_rate_increase
                                        if (unitObject.sales_period_setup && unitObject.sales_period_setup.length > 0) {
                                            unitObject.sales_period_setup.forEach(salesPeriod => {
                                                const sales_period_key = unitObject.id + '_' + salesPeriod.id
                                                this.sales_period_setup_high_availability[sales_period_key] = salesPeriod.high_availability
                                                this.sales_period_setup_allowed_rate_decrease[sales_period_key] = salesPeriod.allowed_rate_decrease
                                            })
                                        }

                                        const unit_index = this.unitList.findIndex(unit => Number(unit.id) === Number(unitObject.id))
                                        if (unit_index > -1) {
                                            this.$set(this.unitList[unit_index], 'property_id', property_id)
                                            this.$set(this.unitList[unit_index], 'allow_rate_increase', unitObject.allow_rate_increase)
                                            this.$set(this.unitList[unit_index], 'sales_period_setup', unitObject.sales_period_setup)

                                            this.$set(this.unitListGroups, unit_index, {
                                                high_availability: '',
                                                allowed_rate_decrease: '',
                                            })
                                        }
                                    }
                                }
                                this.loaded = true
                            }, error => {
                                this.showErrorMessages(error)
                                this.$emit('close')
                            })
                        } else {
                            this.loaded = true
                        }
                    } else {
                        this.$emit('close')
                    }
                }, error => {
                    this.showErrorMessages(error)
                    this.$emit('close')
                })
            } else {
                this.$emit('close')
            }
        }
    },
    watch: {
        property_ids: {
            handler() {
                this.loadFormData()
            }, immediate: true
        },
    }
}
</script>

<style scoped>

</style>
