<template>
    <div>
        <b-row>
            <b-col class="mb-4">
                <AppButton :c_permission="C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_NEW"
                           button_type="new"
                           variant="primary"
                           @click="editSidebar(null)"
                           class="pull-right">
                    {{$t('NEW_SALES_PERIOD')}}
                </AppButton>
            </b-col>
        </b-row>
        <sales-periods-list v-if="checkPermission(C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_V)"
                            class="mt-3"
                            :salesPeriodsList="salesPeriodsList"
                            :loading="loading"
                            @editSalesPeriod="editSidebar">
        </sales-periods-list>
        <AppAside v-model="editSidebarState">
            <template v-if="edit_id !== null"
                      slot="header">
                {{$t("EDIT_SALES_PERIOD_SIDEBAR_TITLE")}}
            </template>
            <template v-else
                      slot="header">
                {{$t("NEW_SALES_PERIOD_SIDEBAR_TITLE")}}
            </template>
            <sales-period-edit-form :edit_id="edit_id"
                                    @save="getSalesPeriods">
            </sales-period-edit-form>
        </AppAside>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import SalesPeriodsList from "@/components/system_templates/sales_periods/SalesPeriodsList";
    import AppAside from "@/components/app/form/AppAside";
    import SalesPeriodEditForm from "@/components/system_templates/sales_periods/SalesPeriodEditForm";
    import {getSystemSalesPeriods} from "@/services/company";
    import {
        C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_NEW,
        C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_V
    } from "@/shared/component_permission";

    export default {
        name: "Index",
        components: {
            AppButton,
            SalesPeriodsList,
            AppAside,
            SalesPeriodEditForm
        },
        data() {
            return {
                salesPeriodsList: [],
                edit_id: null,
                loading: true,
                editSidebarState: false,
                C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_V,
                C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_NEW
            }
        },
        methods: {
            getSalesPeriods() {
                getSystemSalesPeriods(this.$store.getters[`user/getCompany`]).then(response => {
                    this.salesPeriodsList = response.data
                }, () => {
                    this.salesPeriodsList = []
                }).finally(() => {
                    this.loading = false
                })
            },
            editSidebar(id) {
                this.edit_id = id
                this.editSidebarState = true
            }
        },
        created() {
           this.getSalesPeriods()
        }
    }
</script>

<style scoped>

</style>
