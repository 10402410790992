<template>
    <div>
        <b-form>
            <b-row>
                <b-col cols="12" class="mb-3">
                    <label>{{$t('LENGTH_OF_STAY')}}</label>
                    <app-select></app-select>
                </b-col>
                <b-col cols="12" class="mb-3">
                    <label>{{$t('OCCUPANCY')}}</label>
                    <app-select></app-select>
                </b-col>
                <b-col cols="12" class="mb-3">
                    <label>{{$t('PERIOD_START')}}</label>
                    <app-date-picker v-model="period_start"></app-date-picker>
                </b-col>
                <b-col cols="12" class="mb-3">
                    <label>{{$t('PERIOD_END')}}</label>
                    <app-date-picker v-model="period_end"></app-date-picker>
                </b-col>
                <b-col cols="12" class="mb-4">
                    <label>Until date</label>
                    <app-date-picker v-model="until_date"></app-date-picker>
                </b-col>
                <b-col cols="12" class="mb-4">
                    <label>Lock</label>
                    <app-select></app-select>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col >
                    <app-button-submit></app-button-submit>
                </b-col>
            </b-row>

        </b-form>
    </div>
</template>

<script>
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
export default {
name: "PriceLockForm",
    components: {AppButtonSubmit, AppSelect, AppDatePicker},
    data(){
        return {
            period_start:null,
            period_end:null,
            until_date:null
        }
    },
}
</script>

<style scoped>

</style>
