<template>
    <div class="tree-view-wrapper">
        <tree-view-item class="tree-view-item-root" :data="parsedData" :max-depth="maxDepth"
                        :currentDepth="0"></tree-view-item>
    </div>
</template>

<script>
    import {isArray, isPlainObject, map} from "lodash";
    import TreeViewItem from "@/components/channel_manager/synchronization_log/tree_view/TreeViewItem";

    export default {
        name: "tree-view",
        props: ["data", "max-depth"],
        components: {
            TreeViewItem
        },
        methods: {

            transformValue: function (valueToTransform, keyForValue) {
                return {
                    key: keyForValue,
                    type: "value",
                    value: valueToTransform
                }
            },

            generateChildrenFromCollection: function (collection) {
                return map(collection, (value, keyOrIndex) => {
                    if (this.isObject(value)) {
                        return this.transformObject(value, keyOrIndex);
                    }
                    if (this.isArray(value)) {
                        return this.transformArray(value, keyOrIndex);
                    }
                    if (this.isValue(value)) {
                        return this.transformValue(value, keyOrIndex);
                    }
                });
            },

            transformArray: function (arrayToTransform, keyForArray) {
                return {
                    key: keyForArray,
                    type: "array",
                    children: this.generateChildrenFromCollection(arrayToTransform)
                }
            },

            transformObject: function (objectToTransform, keyForObject, isRootObject = false) {
                return {
                    key: keyForObject,
                    type: "object",
                    isRoot: isRootObject,
                    children: this.generateChildrenFromCollection(objectToTransform)
                }
            },

            isObject: function (value) {
                return isPlainObject(value);
            },

            isArray: function (value) {
                return isArray(value);
            },

            isValue: function (value) {
                return !this.isObject(value) && !this.isArray(value);
            }
        },
        computed: {
            parsedData: function () {
                return this.transformObject(this.data, "root", true);
            }
        }
    }
</script>

<style scoped>


    .tree-view-item-root {
        margin-left: 0;
    }

</style>
