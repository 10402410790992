import http from '../http'
import {
    CHANNEL_MANAGER_EXTERNAL_UNIT_LIST_URL,
    CHANNEL_MANAGER_EXTERNAL_RATE_PLAN_LIST_URL,
    DISTRIBUTION_PROPERTY_MAP_DELETE_URL,
    DISTRIBUTION_PROPERTY_MAP_URL,
    DISTRIBUTION_RATE_MAP_URL,
    UNIT_MAPPING_URL,
    DISTRIBUTION_RATE_PLAN_MAP_DELETE,
    CHANNEL_MANAGER_UNIT_PUBLISH,
    CHANNEL_MANAGER_PROPERTY_STATUS,
    CHANNEL_MANAGER_PROPERTY_SYNC,
    CHANNEL_MANAGER_OBJECT_STATUS,
    CHANNEL_MANAGER_DISTRIBUTION_QUEUE_URL,
    CHANNEL_MANAGER_PROPERTY_CREATE,
    CHANNEL_MANAGER_DISTRIBUTION_QUEUE_FILTER_URL,
    CHANNEL_MANAGER_UNIT_CREATE,
    CHANNEL_MANAGER_CM_UNIT_SYNC,
    CHANNEL_MANAGER_PROPERTY_PUBLISH,
    CHANNEL_MANAGER_DISTRIBUTION_SETUP_PARAMETERS,
    CHANNEL_MANAGER_ACTIVATION_PROPERTY_URL,
    CHANNEL_MANAGER_ACTIVATION_UNIT_URL,
    CHANNEL_MANAGER_ACTIVATION_UNIT_SETUP_VALIDATION_URL,
    DASHBOARD,
    UNIT_MAPPING_LIST_URL,
    CHANNEL_MANAGER,
    CHANNEL_MANAGER_DISTRIBUTION_SETUP_PARAMETER_LIST,
    CHANNEL_MANAGER_PULL_FEED,
    CHANNEL_MANAGER_BOOKING_INVITE_URL,
    CHANNEL_MANAGER_BOOKING_RESEND_INVITE_URL,
    CHANNEL_MANAGER_GET_LICENCES_URL,
    CHANNEL_MANAGER_PROPERTY_DOWNLOAD_GALLERY_URL, CHANNEL_MANAGER_STATUS_OBJECT_LIST_URL
} from '@/services/endpoints'


export function getChannelUnitList(params) {
    return http.request({
        url: CHANNEL_MANAGER_EXTERNAL_UNIT_LIST_URL,
        method: 'get',
        params: params
    })
}

export function getChannelRatePlanList(params) {
    return http.request({
        url: CHANNEL_MANAGER_EXTERNAL_RATE_PLAN_LIST_URL,
        method: 'get',
        params: params
    })
}

export function publishUnit(postData) {
    return http.request({
        url: CHANNEL_MANAGER_UNIT_PUBLISH,
        method: 'post',
        data: postData
    })
}

export function getDistributionUnitRateMap(id, unitId) {
    return http.request({
        url: DISTRIBUTION_RATE_MAP_URL(id, unitId),
        method: 'get',

    })
}

export function getDistributionUnitMap(unitId, params) {

    return http.request({
        url: UNIT_MAPPING_URL(unitId),
        method: 'get',
        params: params
    })
}
export function getUnitMappingList(params){
    return http.request({
        url: UNIT_MAPPING_LIST_URL,
        method: 'get',
        params: params
    })
}

export function getDistributionPropertyMapping(distributionId, propertyId) {
    return http.request({
        url: DISTRIBUTION_PROPERTY_MAP_URL(distributionId, propertyId),
        method: 'get',
    })
}

export function saveDistributionPropertyMapping(distribution_id, propertyId, data) {
    return http.request({
        url: DISTRIBUTION_PROPERTY_MAP_URL(distribution_id, propertyId),
        method: 'put',
        data: data
    })
}

export function deleteDistributionPropertyMapping(distributionId, id) {
    return http.request({
        url: DISTRIBUTION_PROPERTY_MAP_DELETE_URL(distributionId, id),
        method: 'delete',
    })
}

export function saveDistributionUnitMapping(unitId, data) {
    return http.request({
        url: UNIT_MAPPING_URL(unitId),
        method: 'put',
        data: data

    })
}

export function deleteDistributionUnitMapping(unitId, data) {
    return http.request({
        url: UNIT_MAPPING_URL(unitId),
        method: 'delete',
        data: data
    })
}

export function saveDistributionRatePlanMapping(distributionId, unitId, data) {
    return http.request({
        url: DISTRIBUTION_RATE_MAP_URL(distributionId, unitId),
        method: 'put',
        data: data

    })

}

export function deleteDistribuionRatePlanMapping(distributionId, id, data) {
    return http.request({
        url: DISTRIBUTION_RATE_PLAN_MAP_DELETE(distributionId, id),
        method: 'delete',
        data: data
    })
}

export function setPropertyCmStatus(data) {
    return http.request({
        url: CHANNEL_MANAGER_PROPERTY_STATUS,
        method: 'post',
        data: data
    })
}

export function getStatus(params) {
    return http.request({
        url: `${CHANNEL_MANAGER}/status`,
        method: 'get',
        params: params
    })
}

export function getObjectStatus(params) {
    return http.request({
        url: CHANNEL_MANAGER_OBJECT_STATUS,
        method: 'get',
        params: params
    })
}
export function getObjectStatusList(params) {
    return http.request({
        url: CHANNEL_MANAGER_STATUS_OBJECT_LIST_URL,
        method: 'get',
        params: params
    })
}

export function getQueueList(data) {
    return http.request({
        url: CHANNEL_MANAGER_DISTRIBUTION_QUEUE_URL,
        method: 'post',
        data: data
    })
}
export function getQueueListData(data) {
    return http.request({
        url: CHANNEL_MANAGER_DISTRIBUTION_QUEUE_URL+'-list',
        method: 'post',
        data: data
    })
}
export function updateQueueList(data) {
    return http.request({
        url: CHANNEL_MANAGER_DISTRIBUTION_QUEUE_URL+'-list',
        method: 'put',
        data: data
    })
}
export function getQueueDetails(params) {
    return http.request({
        url: CHANNEL_MANAGER_DISTRIBUTION_QUEUE_URL+'-details',
        method: 'get',
        params: params
    })
}

export function getQueueFilterData(params) {
    return http.request({
        url: CHANNEL_MANAGER_DISTRIBUTION_QUEUE_FILTER_URL,
        method: 'get',
        params: params
    })
}

export function getSetupParameters(data) {
    return http.request({
        url: CHANNEL_MANAGER_DISTRIBUTION_SETUP_PARAMETERS,
        method: 'post',
        data: data
    })
}
export function getSetupParameterList(data){
    return http.request({
        url: CHANNEL_MANAGER_DISTRIBUTION_SETUP_PARAMETER_LIST,
        method: 'post',
        data: data
    })
}
export function updateSetupParameters(data) {
    return http.request({
        url: CHANNEL_MANAGER_DISTRIBUTION_SETUP_PARAMETERS,
        method: 'put',
        data: data
    })
}

export function unitCreate(postData) {
    return http.request({
        url: CHANNEL_MANAGER_UNIT_CREATE,
        method: 'post',
        data: postData
    })
}

export function unitCmSync(postData) {
    return http.request({
        url: CHANNEL_MANAGER_CM_UNIT_SYNC,
        method: 'post',
        data: postData
    })
}

export function propertySync(postData) {
    return http.request({
        url: CHANNEL_MANAGER_PROPERTY_SYNC,
        method: 'post',
        data: postData
    })
}

export function propertyCreate(postData) {
    return http.request({
        url: CHANNEL_MANAGER_PROPERTY_CREATE,
        method: 'post',
        data: postData
    })
}

export function publishProperty(postData) {
    return http.request({
        url: CHANNEL_MANAGER_PROPERTY_PUBLISH,
        method: 'post',
        data: postData
    })
}

export function getPropertyActivationStatusList(postData) {
    return http.request({
        url: CHANNEL_MANAGER_ACTIVATION_PROPERTY_URL + '/status-list',
        method: 'post',
        data: postData
    })
}

export function getPropertyActivationStatus(params) {
    return http.request({
        url: CHANNEL_MANAGER_ACTIVATION_PROPERTY_URL + '/status',
        method: 'get',
        params: params
    })
}


export function disablePropertyActivation(postData) {
    return http.request({
        url: CHANNEL_MANAGER_ACTIVATION_PROPERTY_URL + '/disable',
        method: 'put',
        data: postData
    })
}


export function getUnitActivationStatusList(postData) {
    return http.request({
        url: CHANNEL_MANAGER_ACTIVATION_UNIT_URL + '/status-list',
        method: 'post',
        data: postData
    })
}
export function disableUnitActivation(postData) {
    return http.request({
        url: CHANNEL_MANAGER_ACTIVATION_UNIT_URL + '/disable',
        method: 'put',
        data: postData
    })
}
export function getDashboardUnitList(postData) {
    return http.request({
        url: DASHBOARD + '/unit-status',
        method: 'post',
        data: postData
    })
}
export function getDistributionPropertyStatus(params) {
    return http.request({
        url: CHANNEL_MANAGER + '/property/product-status',
        method: 'get',
        params: params
    })
}
export function getDistributionSetupParameter(params){

    return http.request({
        url:CHANNEL_MANAGER+'/distribution-setup-parameters',
        method:'get',
        params:params
    })
}
export function activateProperty(postData){
    return http.request({
        url: CHANNEL_MANAGER_ACTIVATION_PROPERTY_URL + '/enable-new',
        method: 'put',
        data: postData
    })
}
export function validationActivateProperty(postData) {
    return http.request({
        url:CHANNEL_MANAGER_ACTIVATION_PROPERTY_URL + '/enable-validation',
        method:'post',
        data:postData
    })
}
export function validationActivateUnit(postData) {
    return http.request({
        url:CHANNEL_MANAGER_ACTIVATION_UNIT_URL + '/enable-validation',
        method:'post',
        data:postData
    })
}
export function activateUnit(postData) {
    return http.request({
        url:CHANNEL_MANAGER_ACTIVATION_UNIT_URL + '/enable-new',
        method:'put',
        data:postData
    })

}

export function updateDistributionSetupParameters(data) {
    return http.request({
        url: CHANNEL_MANAGER + '/distribution-setup-parameters',
        method: 'put',
        data: data
    })
}

export function setupUnitActivationValidation(data) {
    return http.request({
        url: CHANNEL_MANAGER_ACTIVATION_UNIT_SETUP_VALIDATION_URL,
        method: 'post',
        data:data
    })
}

export function pullIcalFeed(params){
    return http.request({
        url: CHANNEL_MANAGER_PULL_FEED,
        method: 'get',
        params: params
    })
}

export function getDistributionSetupParameterList(params) {
    return http.request({
        url: CHANNEL_MANAGER + '/distribution-setup-parameter-list',
        method: 'get',
        params: params
    })
}

export function sendBookingInvite(data) {
    return http.request({
        url: CHANNEL_MANAGER_BOOKING_INVITE_URL,
        method: 'post',
        data: data
    })
}

export function resendBookingInvite(data) {
    return http.request({
        url: CHANNEL_MANAGER_BOOKING_RESEND_INVITE_URL,
        method: 'post',
        data: data
    })
}

export function getBookingLicences(params) {
    return http.request({
        url: CHANNEL_MANAGER_GET_LICENCES_URL,
        method: 'get',
        params: params
    })
}

export function downloadPropertyDistributionGallery(params, filename) {
    return http.request({
        url: CHANNEL_MANAGER_PROPERTY_DOWNLOAD_GALLERY_URL,
        responseType: 'blob',
        method: 'get',
        params
    }).then((response) => {
        let blob = new Blob([response], {type: 'application/zip'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename + '.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
}
