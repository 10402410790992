import { render, staticRenderFns } from "./MultiselectRestrictionFormElement.vue?vue&type=template&id=ca455d32&scoped=true&"
import script from "./MultiselectRestrictionFormElement.vue?vue&type=script&lang=js&"
export * from "./MultiselectRestrictionFormElement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca455d32",
  null
  
)

export default component.exports