<template>
    <div>
        <b-card class="main-section-card">
            <div slot="header">
                <h2>{{ $t("USERS") }}</h2>
                <app-button
                    v-if="checkPermission(R_USER_NEW)"
                    button_type="new" variant="primary"
                    @click="user_sidebar_state=true"
                    class="pull-right">
                    {{$t('USER_NEW')}}
                </app-button>
            </div>

            <user-filter @search="search"
                         :loading="loading"></user-filter>
            <user-list-table :filter="filter" @isLoading="isLoading"></user-list-table>
            <app-aside v-model="user_sidebar_state">
                <template slot="header">{{$t("USER_NEW")}}</template>
                <user-new-form
                    @addUser="getNewUser">
                </user-new-form>
            </app-aside>
        </b-card>
    </div>
</template>

<script>
    import AppButton from '@/components/app/AppButton/AppButton';
    import AppAside from "@/components/app/form/AppAside";
    import UserListTable from "@/components/user/list/UserListTable";
    import UserFilter from "@/components/user/list/UserFilter";
    import UserNewForm from '@/components/user/new/UserNewForm';
    import routeNames from "@/router/routeNames";
    import {R_USER_NEW} from "@/shared/route_permission";
    import {cloneDeep, isEmpty} from "lodash";

    export default {
        name: "Index",
        components: {
            UserFilter,
            UserListTable,
            AppButton,
            AppAside,
            UserNewForm
        },
        data() {
            return {
                filter: {},
                routeNames,
                user_sidebar_state: false,
                R_USER_NEW,
                loading: false
            }
        },
        methods: {
            getNewUser(user) {
                this.user_sidebar_state = false
                if (isEmpty(this.filter)) {
                    this.filter.page = 1;
                    this.filter.limit = 10;
                    this.filter.company = this.$store.getters[`user/getCompany`];
                }
                this.search(this.filter)
                //this.$router.push({name: routeNames.RN_USER_ROLES, params: {id: user.id}})
            },
            search(value) {
                this.filter = cloneDeep(value)
                this.loading = true
            },
            isLoading(value) {
                this.loading = value
            }
        }
    }
</script>

<style scoped>

</style>
