<template>
    <b-col>
        <b-table small responsive hover :fields="fields" :items="mappingRateData"></b-table>
    </b-col>
</template>

<script>
    export default {
      name: "rate-plan-product_management",
        props:{
          mappingRateData:{
            type:Array
          }
        },
      data(){
          return{
            fields: [

              {key: "rate_plan_name", label: this.$t('RATE_PLAN'), class: "w-25"},
              {key: "rate_plan_eid", label: this.$t('RATE_PLAN_EXTERNAL_ID'), class: "w-25"},
              {key: "rate_plan_external_name", label: this.$t('RATE_PLAN_EXTERNAL_NAME'), class: "w-25"},
            ],
          }
      }
    }
</script>

<style scoped>

</style>
