import http from '../http'
import {CONTACT} from '@/services/endpoints'

export function getContactList(objectId, documentTypeId) {
    return http.request({
        url: CONTACT,
        method: 'get',
        params: {
            object: objectId,
            object_type: documentTypeId,
        }
    })
}

export function updateContact(contactId, data) {
    return http.request({
        url: CONTACT + `/${contactId}`,
        method: 'put',
        data: data
    })
}

export function createContact(data) {
    return http.request({
        url: CONTACT,
        method: 'post',
        data: data
    })
}
export function deleteContact(contactId) {
    return http.request({
        url: CONTACT + `/${contactId}`,
        method: 'delete'
    })
}

export function updateContactMapping(contactId, data) {
    return http.request({
        url: CONTACT + `/${contactId}/map`,
        method: 'put',
        data: data
    })
}
