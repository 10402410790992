<template>
    <div>
        <property-product-management></property-product-management>
    </div>
</template>
<script>

    import PropertyProductManagement from '@/components/channel_manager/product_management/PropertyProductManagement'


    export default {
        name: "index",
        components: {
            PropertyProductManagement,
        }
    }
</script>
<style scoped>

</style>
