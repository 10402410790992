<template>
    <tagged-gallery-property
           :c_permission_e="c_permission_e"
           :ac_key="AC_PROPERTY_GALLERY"
           :c_permission_v="c_permission_v"
    ></tagged-gallery-property>
</template>

<script>

import TaggedGalleryProperty from "@/components/app/gallery/TaggedGalleryProperty";
import {C_PROPERTY_GALLERY_E, C_PROPERTY_GALLERY_V} from "@/shared/component_permission";
import {AC_PROPERTY_GALLERY} from "@/mixins/AccessControl/AccessControlEnumeration";

export default {
    name: "PropertyGallery",
    components:{
        TaggedGalleryProperty
    },

    data(){
        return{
            c_permission_e: C_PROPERTY_GALLERY_E,
            c_permission_v: C_PROPERTY_GALLERY_V,
            AC_PROPERTY_GALLERY
        }
    }
}
</script>

<style scoped>

</style>
