<template>
    <div v-if="isVisible" :class="inline ? 'display-inline' : ''">
        <b-tooltip v-if="!hasPermission || hasMessage" :target="compnentId" :placement="tooltipPlacement"
                   :title="tooltip_message"></b-tooltip>
        <span :id="compnentId">
        <b-button :size="size"
                  class="app-button-submit btn-default"
                  :class="ButtonClass"
                  type="submit"
                  @click="$emit('click')"
                  :variant="variant"
                  :disabled="isDisabled">
            <i v-if="icon" :class="icon" aria-hidden="true"></i>
            <slot>{{$t('SAVE')}}</slot>
        </b-button>
        </span>
    </div>

</template>

<script>
    import AppValidationDescription from '@/components/app/form/AppValidationDescription'
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

    export default {
        name: "AppButtonSubmit",
        mixins:[AccessControlComponent],
        components: {
            AppValidationDescription
        },
        props: {
            id: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            variant: {
                type: String,
                default: "primary"
            },
            button_type: {
                type: String,
                default: "submit"
            },
            c_permission: {
                type: Number,
                default: null
            },
            loading: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: null
            },
            inline: {
                type: Boolean,
                default: false
            },
            ButtonClass: {
                type: String,
                default: ""
            },
            tooltipPlacement:{
                type: String,
                default: "top"
            },
            access_control_context: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                compnentId: null,
                icons: {
                    loading: "fa fa-spinner fa-spin",
                    search: "fa fa-search fa-fw",
                }
            }
        },
        computed: {
            icon() {
                if (this.loading) {
                    return this.icons.loading
                }
                if (this.search) {
                    return this.icons.search
                }

                if (this.icons[this.button_type]) {
                    return this.icons[this.button_type]
                }

                return this.icons.save
            },
            hasPermission() {
                if (this.c_permission !== null) {
                    return this.$store.getters['user/getPermission'](this.c_permission)
                }
                return true
            },
            isDisabled() {
                return this.disabled || this.loading || !this.hasPermission || this.AccessControlDisabled
            },
            isVisible() {
                return this.AccessControlVisible
            },
            hasMessage(){
                return this.AccessControlMessage ? this.AccessControlMessage[0]: null
            },
            tooltip_message(){
                return this.hasMessage ? this.AccessControlMessage[0].message : this.$t('NO_PERMISSION', {value:this.c_permission})
            }
        },
        created() {
            this.compnentId = this.id
            if (this.compnentId === null) {
                this.compnentId = 'SubmitButton_' + this._uid
            }
        }
    }
</script>

<style scoped>
    .app-button-submit {
        white-space: nowrap;
    }

    .display-inline {
        display: inline-block;
    }
</style>
