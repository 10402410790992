<template>
    <b-card class="main-section-card">
        <div slot="header">
            <h2>{{ $t("RATE_MANAGER_OVERVIEW") }}</h2>
        </div>
        <rate-manager-report-filter></rate-manager-report-filter>
        <rate-manager-report-table @showSuggestions="showSuggestions"></rate-manager-report-table>

        <app-aside v-model="suggestionAsideState"
                   :widths="['col-lg-12','col-sm-12', '100%']"
                   :header-classes="[]" full-width>
            <rate-manager-suggestion-filter
                :property-id="propertyId"
                :report-id="reportId">
            </rate-manager-suggestion-filter>

            <rate-manager-suggestion-table
                :initial-search="{report: reportId}"
                @close="hideSuggestions">
            </rate-manager-suggestion-table>
        </app-aside>
    </b-card>
</template>

<script>
    import RateManagerSuggestionTable from "@/components/rate_manager/suggestions/RateManagerSuggestionTable";
    import AppAside from "@/components/app/form/AppAside";
    import RateManagerReportTable from "@/components/rate_manager/overview/RateManagerReportTable";
    import RateManagerReportFilter from "@/components/rate_manager/overview/RateManagerReportFilter";
    import {EventBus} from "@/shared/EventBus";
    import RateManagerSuggestionFilter from "@/components/rate_manager/suggestions/RateManagerSuggestionFilter";

    export default {
        name: "Index",
        components: {
            RateManagerSuggestionFilter,
            RateManagerReportTable,
            RateManagerSuggestionTable,
            RateManagerReportFilter,
            AppAside
        },
        data() {
            return {
                suggestionAsideState: false,
                reportId: null,
                propertyId: null,
            }
        },
        methods: {
            showSuggestions({reportId, propertyId}) {
                this.reportId = reportId
                this.propertyId = propertyId
                this.suggestionAsideState = true
            },
            hideSuggestions() {
                EventBus.$emit('searchReports')
                this.reportId = null
                this.propertyId = null
                this.suggestionAsideState = false
            }
        }
    }
</script>

<style scoped>

</style>
