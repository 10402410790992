import http from '../http'
import {REGISTER_URL, USER_PASSWORD_URL} from '@/services/endpoints'
import {getAccessControlUrl} from "@/mixins/AccessControl/AccessControlEnumeration";




export function register(postData){
    return http.request({
        url:REGISTER_URL,
        method:'post',
        data:postData
    })
}
export function confirmRegistration(company_id, token_hash){
    return http.request({
        url:REGISTER_URL +'/'+company_id+'/'+token_hash,
        method:'put',
    })
}

export function userPasswordReset(postData){
    return http.request({
        url:USER_PASSWORD_URL+'/reset',
        method:'post',
        data:postData
    })
}
export function getUserForPasswordChange(user_id, token_hash,params){
    return http.request({
        url:USER_PASSWORD_URL+'/change/'+user_id+'/'+token_hash,
        method:'get',
        params:params
    })
}
export function userPasswordChange(user_id, token_hash, postData){
    return http.request({
        url:USER_PASSWORD_URL+'/change/'+user_id+'/'+token_hash,
        method:'put',
        data:postData
    })
}

export function userUpdateExpiredPassword(user_id,postData){
    return http.request({
        url: USER_PASSWORD_URL + '/change/expired/' + user_id,
        method:'put',
        data: postData
    })
}

export function fetchAccessControlData(key, data) {
    return http.request({
        url: getAccessControlUrl(key),
        method: 'POST',
        data
    })
}
