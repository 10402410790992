<template>
    <div v-if="fetch">
        <revenue-manager-setup-action-table
            v-if="activated === 1"
            @openNewAction="aside = true"
            mode="company"
            :type_action="ACTION_TYPE_MIN_STAY"
            :object_id="company.id"
        ></revenue-manager-setup-action-table>
        <template v-else>
            <app-no-data :heading="$t('MODULE_NOT_ACTIVE')">
                <app-button :id="'price_activation_'+ACTION_TYPE_MIN_STAY"
                            :loading="loader"
                            class="mt-5"
                            @click="activation"
                >{{ $t('ACTIVATE') }}
                </app-button>
            </app-no-data>
        </template>
    </div>
</template>

<script>
import AppNoData from "@/components/app/AppNoData";
import AppButton from "@/components/app/AppButton/AppButton";
import RevenueManagerSetupActionTable from "@/components/revenue_manager/setup/RevenueManagerSetupActionTable";
import {EventBus} from "@/shared/EventBus";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {toast} from "@/shared/plugins/toastr";
import {
    getRevenueManagerSetup,
    revenueManagerCompanyActivate,
    saveRevenueManagerSetup
} from "@/services/revenue_manager";
import {
    ACTION_TYPE_MIN_STAY,
    ACTIVE_REVENUE_MANAGER_MINIMUM_STAY,
} from "@/shared/constants";

export default {
    name: "Index",
    data() {
        return {
            ACTION_TYPE_MIN_STAY,
            aside: false,
            fetch: false,
            activated: null,
            count: 0,
            loader: false,
        }
    },
    mixins: [getErrorMessage],
    components: {
        AppNoData,
        AppButton,
        RevenueManagerSetupActionTable,
    },
    methods: {
        getSetup() {
            getRevenueManagerSetup({
                company: this.company.id,
                setup_parameter: [ACTIVE_REVENUE_MANAGER_MINIMUM_STAY]
            }).then(response => {
                if (response.data.length === 1) {
                    const setupParameterObject = response.data[0]
                    if (setupParameterObject.hasOwnProperty('values')) {
                        this.activated = Number(setupParameterObject.values.final)
                    }
                }
            }).finally(() => {
                this.fetch = true
            })
        },
        activation() {
            if (this.activated === null) {
                this.initialActivate()
            } else {
                this.activateRevenueManager()
            }
        },
        initialActivate() {
            revenueManagerCompanyActivate({
                company: this.company.id,
                submodule: ACTIVE_REVENUE_MANAGER_MINIMUM_STAY
            }).then(() => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
                EventBus.$emit('ge_reload_action_table')
                EventBus.$emit('ge_deactivation_action')
                this.activated = 1
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(() => {
                this.loader = false
            })
        },
        activateRevenueManager() {
            this.loader = true
            let request = {
                setup_list: [
                    {
                        setup_parameter: ACTIVE_REVENUE_MANAGER_MINIMUM_STAY,
                        property: null,
                        unit: null,
                        company: this.company.id,
                        value: 1
                    },
                ]
            }
            saveRevenueManagerSetup(request).then(response => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
                this.loader = false
                EventBus.$emit('ge_reload_action_table')
                EventBus.$emit('ge_deactivation_action')
                this.activated = 1
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(() => {
                this.loader = false
            })
        },
        reload() {
            this.aside = false
            EventBus.$emit('ge_reload_action_table')
        },
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    mounted() {
        EventBus.$on('gl_count_actions', (count) => {
            this.count = count
        })
        EventBus.$on('gl_reload_rm_setup', () => {
            this.getSetup()
        })
    },
    beforeDestroy() {
        EventBus.$off('gl_count_actions')
        EventBus.$off('ge_reload_action_table')
        EventBus.$off('gl_reload_rm_setup')
    },
    created() {
        this.getSetup()
    }
}
</script>

<style scoped>

</style>
