<template>
    <div  class="animated fadeIn">
        <rate-plan-filter @search="setFilter" :loading="loading"></rate-plan-filter>
        <rate-plan-table  @busyToggled="toggleBusy" :filter="filter" :setup_parameter="setup_parameter"></rate-plan-table>
    </div>
</template>

<script>
export default {
    name: "RatePlanSetup",
    components: {
        "RatePlanFilter":() => import("@/components/rate_plan/list/RatePlanFilter"),
        "RatePlanTable":() => import("@/components/rate_plan/list/RatePlanTable")
    },
    props: {
        setup_parameter: {
            type: Number,
            default: null
        }
    },
    data(){
        return{
            show_tip:false,
            loading:false,
            filter: null,
        }
    },
    methods: {
        setFilter(values) {
            this.filter = values
        },
        toggleBusy($event) {
            this.loading = $event
        }
    },
}
</script>

<style scoped>

</style>
