<template>
    <div>
        <div v-if="review" class="review_data">
            <div class="header b-b-1 mb-1 pb-3">
                <h6>{{$t("PROPERTY")}}</h6>
                <h5 v-if="_has(review, 'object.name')">{{review.object.name}}</h5>
                <ul>
                    <li v-if="_has(review, 'from_guest')">
                        {{$t("TYPE")}}: {{review.from_guest ? $t('GUEST_TO_HOST') : $t('HOST_TO_GUEST')}}
                    </li>
                </ul>
            </div>
        </div>
        <b-row class="mb-4">
            <b-col sm="6" class="mb-4">

                <div v-if="review" class="review_data mb-4">

                    <div class="items">
                        <div class="item">
                            <div>{{$t("DISTRIBUTION")}}</div>
                            <div>
                                {{getDistributionNameById(review.distribution)}}
                            </div>
                        </div>
                        <div class="item">
                            <div>{{$t("RESERVATION_CODE")}}</div>
                            <div>
                                {{review.reservation_code}}
                            </div>
                        </div>
                        <div class="item" v-if="_has(review,'published.user.name')" >
                            <div>{{review.from_guest ? $t("GUEST") : $t('HOST')}}</div>
                            <div>
                                {{review.published.user.name}}
                            </div>
                        </div>
                        <div class="item">
                            <div>{{$t("STATUS")}}</div>
                            <div>
                                {{review.status.name}}
                            </div>
                        </div>

                        <div class="item" v-if="_has(review,'details.headline')">
                            <div>{{$t("HEADLINE")}}</div>
                            <div>
                                {{review.details.headline}}
                            </div>
                        </div>

                        <div class="item">
                            <div>{{$t("SCORE")}}</div>
                            <div>
                                {{review.score}}
                            </div>
                        </div>
                        <div class="item">
                            <div>{{$t("EXPIRES_AT")}}</div>
                            <div>
                                {{review.details.expires_at|date}}
                            </div>
                        </div>
                        <div class="item" v-if="review.details.is_reviewee_recommended">
                            <div>{{review.from_guest ? $t('HOST_RECOMMENDED') : $t('GUEST_RECOMMENDED')}}</div>
                            <div> {{review.details.is_reviewee_recommended ? $t('YES') : $t('NO')}}</div>
                        </div>
                    </div>
                </div>


            </b-col>

            <b-col sm="6" class="mb-4" v-if="!review.allowed.write_enable">
                <template v-if="_has(review,'details.public_review')">
                    <label class="label">{{$t('PUBLIC_REVIEW')}}</label>
                    <b-form-textarea no-resize class="finance-qe mb-4" disabled :value="review.details.public_review" style="background: white" :rows="3"></b-form-textarea>
                </template>
               <template v-if="_has(review,'details.private_feedback')">
                   <label class="label">{{$t('PRIVATE_FEEDBACK')}}</label>
                   <b-form-textarea no-resize class="finance-qe mb-4" disabled :value="review.details.private_feedback" style="background: white" :rows="3"></b-form-textarea>
               </template>
                <template  v-if="_has(review,'details.positive')">
                    <label class="label">{{$t('POSITIVE')}}</label>
                    <b-form-textarea no-resize class="finance-qe mb-4" disabled :value="review.details.positive" style="background: white" :rows="3"></b-form-textarea>
                </template>
                <template v-if="_has(review,'details.negative')">
                    <label class="label">{{$t('NEGATIVE')}}</label>
                    <b-form-textarea no-resize class="finance-qe mb-4" disabled :value="review.details.negative" style="background: white" :rows="3"></b-form-textarea>
                </template>
            </b-col>

            <b-col v-else sm="6" class="mb-4">

            </b-col>
                <b-col sm="6" class="mb-4" :key="index" v-for="(review_item, index) in review.items">
                    <label class="label">{{review_item.name}}  </label><span class="pull-right ml-2"><star-rating  read-only :increment="review.distribution === BOOKING  ?  0.01 : 1"  :star-size="15" :max-rating="starSize" :rating="ratingScore(review_item.score)"></star-rating></span>
                    <b-form-textarea v-if="review_item.text"  no-resize class="finance-qe" :rows="1" disabled :value="review_item.text"
                                      style="background: white"></b-form-textarea>
                </b-col>

            <b-col sm="12" class="mb-4">
                <b-form @submit.prevent="respond" v-if="review.allowed.respond_enable">
                    <b-row>
                        <b-col sm="12" class="pull-right" :key="index"
                               v-for="(item,index) in review.allowed.respond_data">
                            <label>{{$t('RESPONSE')}}</label>
                            <component class="mb-4" :id="item.id" :is="getInput(item.input)" :v-bind="item.type"
                                       v-model="formData[item.id]"></component>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="6">
                            <app-button-submit
                                :disabled="!formData.hasOwnProperty('reviewee_response') && !formData.reviewee_response"
                                :loading="respondLoading">{{$t('RESPOND')}}
                            </app-button-submit>
                        </b-col>
                    </b-row>

                </b-form>
                <b-form @submit.prevent="write" v-else-if="review.allowed.write_enable">
                    <b-row>
                        <b-col sm="6" class="pull-right" :key="index" v-for="(item,index) in review.allowed.write_data" v-if="item.id === 'is_reviewee_recommended'">
                            <label>{{review.from_guest ? $t('HOST_RECOMMENDED') : $t('GUEST_RECOMMENDED')}}</label>
                            <component class="mb-4" :id="item.id" :is="getInput(item.input)" :v-bind="item.type" v-model="formWriteData[item.id]" :options="options"></component>
                        </b-col>
                    </b-row>
                        <b-row>
                            <b-col sm="6" class="pull-right" :key="index" v-for="(item,index) in review.allowed.write_data" v-if="item.id !== 'is_reviewee_recommended'">
                                <label>{{$t(item.id.toUpperCase())}}</label>
                                <component class="mb-4" :id="item.id" :is="getInput(item.input)" :v-bind="item.type" v-model="formWriteData[item.id]" :options="options"></component>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="6" class="mb-4" v-if="review.allowed.write_enable && review.allowed.write_items.length > 0" :key="index" v-for="(review_item, index) in review.allowed.write_items">
                               <div>
                                   <label class="label">{{review_item.name}}  </label><star-rating class="pull-right mb-1" :max-rating="starSize"  :star-size="15" v-model="formWriteItems[index].score"></star-rating>
                               </div>
                                <b-form-textarea  no-resize class="finance-qe"  v-model="formWriteItems[index].text"
                                                  style="background: white"></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="6">
                                <app-button-submit :loading="writeLoading">{{$t('SEND')}}</app-button-submit>
                            </b-col>
                        </b-row>
                    </b-form>
                <hr>
            </b-col>

            <b-col sm="12" class="mb-4" v-if="review.details.reviewee_response">
                <label class="label">{{review.from_guest ? $t('RESPONSE_OF_HOST') : $t('RESPONSE_OF_GUEST')}}</label>
                <b-form-textarea style="background: white" no-resize class="finance-qe" :rows="1" disabled :value="review.details.reviewee_response"></b-form-textarea>

            </b-col>
            <b-col sm="6" v-if="review.details.reviewee_response">
                <label class="label">{{$t('RESPONDED_AT')}}</label>
                <b-input disabled :value="review.details.responded_at|timestamp"></b-input>
            </b-col>
        </b-row>

    </div>
</template>

<script>
    import {BOOKING} from '@/shared/constants';
    import AccommodationReviewItem from "@/components/review/accommodation/form/AccommodationReviewItem";
    import {has} from "lodash";
    import {quillEditor} from 'vue-quill-editor'
    import AppButton from "@/components/app/AppButton/AppButton";
    import {respondReview, writeReview} from "@/services/review";
    import {notifySuccess, toast} from "@/shared/plugins/toastr";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import StarRating from 'vue-star-rating'
    import {AIRBNB} from "@/shared/constants";
    import {EventBus, GE_TABLE_SEARCH} from "@/shared/EventBus";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";

    export default {
        name: "AccommodationReviewForm",
        mixins:[getErrorMessage],
        components: {
            AppButtonSubmit,
            AppButton,
            AccommodationReviewItem,
            quillEditor,
            AppSelect,
            StarRating
        },
        props: {
            review: {
                type: Object,
                default() {
                    return {

                    }
                }
            },
            distributionList: {
                type: Array
            }
        },
        computed:{
          starSize(){
              return this.review.distribution === BOOKING ? 10 : 5
          },

        },
        data() {
            return {
                formData: {},
                formWriteData:{},
                formWriteItems:[],
                respondLoading: false,
                writeLoading: false,
                options:[
                    {id:1, name:this.$t('YES')},
                    {id:0, name:this.$t('NO')}
                ],
                score:null,
                BOOKING
            }
        },
        methods: {
            _has(object, path) {
                return has(object, path)
            },
            respond() {
                this.respondLoading = true
                respondReview(this.review.id, this.formData).then(() => {
                    notifySuccess()
                    EventBus.$emit(GE_TABLE_SEARCH)
                    this.$emit('closeSideBar')
                }, error=>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(() => {
                    this.respondLoading = false
                })
            },
            write() {
                this.writeLoading = true
                let req = {
                ...this.formWriteData,
                  ...{items:this.formWriteItems}
                }
                writeReview(this.review.id,req).then(() => {
                    notifySuccess()
                    EventBus.$emit(GE_TABLE_SEARCH)
                    this.$emit('closeSideBar')
                }, error=>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(() => {
                    this.writeLoading = false

                })
            },
            getDistributionNameById(distribution_id) {
                if (!distribution_id) {
                    return
                }
                if (this.distributionList.length) {
                    let object = this.distributionList.find(el => el.id === distribution_id)
                    return object.name
                }
            },
            getInput(value) {
                if (value === 'textarea') {
                    return 'b-textarea'
                }
                if(value === 'select'){
                    return  'app-select'
                }
            },
            ratingScore(value){
                if(this.review.distribution === BOOKING){
                   return  parseFloat(value);
                }
                return  Number(value)
            }

        },
        watch:{
            review:{
                handler(reviewObject){
                    if(reviewObject.distribution === AIRBNB && !reviewObject.from_guest && this._has(reviewObject,'allowed.write_items')){
                        if(Array.isArray(reviewObject.allowed.write_items) && reviewObject.allowed.write_items.length > 0){
                            reviewObject.allowed.write_items.forEach(el=> {
                                let reqObject = {
                                    id:el.id,
                                    text:null,
                                    score:null
                                }
                                this.formWriteItems.push(reqObject)
                            })
                        }
                    }
                }, immediate:true
            }
        }

    }
</script>

<style scoped type="scss">

</style>
