<template>
    <div class="availability_calendar--sidebar">
        <div class="availability_rows" :class="{'availability_calendar_wrapper-loader':showLoading}">

            <div class="availability_row availability_calendar_no-border">

                <div class="availability_sidebar   availability_calendar-bt d-flex align-items-center justify-content-center">
                    <div class="availability_sidebar-select-all">
                        <b-form-checkbox  v-model="select_all" :unchecked-value="false" :value="true" @change="updateSelectAll" size="sm" :class="overflow ? 'mt-3': 'mt-2'"></b-form-checkbox>
                    </div>
                    <calendar-year-cell class="availability_sidebar-name" :isOverflown="overflow" :date-list="dates" :scroll-left-position="scrollLeftPosition"></calendar-year-cell>
                </div>
                <div class="availability_content_grid availability_calendar-bt " ref="calendar_scroller">
                    <property-calendar-date-cell :key="dateIndex" :index="dateIndex" :scrollLeftPosition="scrollLeftPosition" :date="date" v-for="(date, dateIndex) in dates"></property-calendar-date-cell>
                </div>
            </div>

            <div class="availability_row_scroller">
                <div class="scroller_sidebar">
                    <div v-if="overflow" class="scroller_sidebar-select">

                    </div>
                </div>
            <div
                class="main_calendar__holder--content-scroll"
                ref="calendar_scroll_container"
                @scroll="onScroll">
                <div class="availability_calendar-bb" :key="date.start+'_date_'+index"   v-for="(date, index) in dates">
                </div>
            </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {AvailabilityScrollMixin} from "@/mixins/calendar/AvailabilityScrollMixin";
    import PropertyCalendarDateCell from "@/components/property/calendar/PropertyCalendarDateCell";
    import TableYearCell from "@/components/unit/calendar_opt/Table/Dates/TableYearCell";
    import CalendarYearCell from "@/components/property/calendar/CalendarYearCell";
    import {
        EventBus,
        GE_CALENDAR_LOAD_ADD_MONTH,
        GE_CALENDAR_LOAD_AVAILABILITY, GE_CALENDAR_SELECT_ALL_UNIT,
        GE_CALENDAR_SELECT_UNIT
    } from "@/shared/EventBus";
    export default {
        name: "PropertyCalendarDates",
        mixins:[AvailabilityScrollMixin],
        components: {CalendarYearCell, TableYearCell, PropertyCalendarDateCell},
        props:{
            selectedUnits:{
                type:Array,

            },
            dates:{
                type: Array
            },
            unit:{
                type:Object
            },
            showPropertyItem:{
                type:Boolean,
                default:true
            },
            scrollLeftPosition: {
                type: Number,
                default: 0
            },
            loadMore:{
                type:Object,
                default(){
                    return{
                        value:null,
                        load:null
                    }
                }
            },
            showLoading:{
                type:Boolean,
                default:false
            }

        },
        data(){
          return{
              select_all:false,
              dateIndex:null,
              start:null,
              valueC:0,
              initial:true,
              calculatedPosition:null,
              overflow:true
          }
        },
        computed:{
            lastIndexDate(){
                return this.dates.length >  0 ? this.dates.length - 1 : null
            },
            pixelsWide() {
                return 2.87 * 16
            },
            startingPosition() {
                return this.pixelsWide * this.lastIndexDate
            },

        },
        methods: {
            updateSelectAll(value){

                this.select_all = value
                EventBus.$emit(GE_CALENDAR_SELECT_ALL_UNIT,{value})

            },
            onScroll($event) {
                this.showMoreHandler()
                this.$emit('scroll', $event.target.scrollLeft)
            },
            loadMoreEvent() {
                EventBus.$emit(GE_CALENDAR_LOAD_ADD_MONTH, {unitId: null})
                EventBus.$emit(GE_CALENDAR_LOAD_AVAILABILITY)

            },
            isOverflown() {
                let element = document.querySelector('.main_calendar__holder--content-scroll')
                if (element) {
                    this.overflow =  element.scrollWidth > element.clientWidth
                    return
                }
                this.overflow = true
            },
            showMoreHandler() {
                if (this.$refs.calendar_scroll_container) {
                    if ((this.$refs.calendar_scroll_container.scrollWidth === this.$refs.calendar_scroll_container.clientWidth) || (this.$refs.calendar_scroll_container.scrollLeft === (this.$refs.calendar_scroll_container.scrollWidth - this.$refs.calendar_scroll_container.clientWidth))) {
                        this.$emit('showLoadMore', true)
                    } else {
                        this.$emit('showLoadMore', false)
                    }
                }
            },
        },
        watch:{
            selectedUnits:{
              handler(select_units){
                  if(select_units.length === 0){
                      this.select_all =  false
                  }
                  if(select_units.length === 1){
                      this.select_all = select_units[0].hasOwnProperty('select_all') && select_units[0].select_all
                  }
              }, immediate:true
            },
           loadMore:{
              handler(object){
                        if(object.value && !object.load){
                           this.calculatedPosition =  this.pixelsWide * this.lastIndexDate
                        }

                      if(object.value && object.load){
                          this.$emit('updateScrollLeft', this.calculatedPosition)
                          document.querySelector('.main_calendar__holder--content-scroll').scrollLeft = this.calculatedPosition
                      }


              }
          },
            dates:{
               handler(value){
                   this.$nextTick(()=>{
                       this.isOverflown()
                       this.showMoreHandler()
                   })

               },immediate:true
            }
        },

    }
</script>

<style scoped>
    .no-border {
        border-bottom: none!important
    }

</style>
