<template>
    <b-form @submit.prevent>
        <form-wizard :startIndex="currentIndex" transition="fade-in" color=var(--color-primary) errorColor=var(--color-warning)
                     name="ownerWizard" title="" subtitle=""
                     @on-validate="handleNextStep"
                     @on-complete="save">
            <tab-content name="basic" :title="$t('BASIC')" :before-change="() => validate('basic')">

                <b-row>
                    <b-col md="4" class="mb-4">
                        <label>{{$t("FIRST_NAME_LAST_NAME")}}</label>
                        <b-input v-model="basic.name"></b-input>
                        <validation-description :show="$v.basic.$dirty && $v.basic.name.$invalid">
                            {{$t("FIELD_MANDATORY")}}
                        </validation-description>
                    </b-col>
                    <b-col md="4" class="mb-4">
                        <label>{{$t('VAT_NUMBER')}}</label>
                        <b-input v-model="basic.oib"></b-input>
                    </b-col>
                    <b-col md="4" class="mb-4">
                        <label>{{$t('COUNTRY')}}</label>
                        <app-select mode="search" :search="searchCountry" v-model="basic.location.country"
                                    :search-clear="true"></app-select>
                        <validation-description :show="$v.basic.$dirty && $v.basic.location.country.id.$invalid">
                            {{$t("FIELD_MANDATORY")}}
                        </validation-description>
                    </b-col>
                    <b-col md="4" class="mb-4">
                        <label>{{$t('ADDRESS')}}</label>
                        <b-input v-model="basic.location.address"></b-input>
                        <validation-description :show="$v.basic.$dirty && $v.basic.location.address.$invalid">
                            {{$t("FIELD_MANDATORY")}}
                        </validation-description>
                    </b-col>
                    <b-col md="4" class="mb-4">
                        <label>{{$t('ZIP')}}</label>
                        <b-input v-model="basic.location.zip"></b-input>
                        <validation-description :show="$v.basic.$dirty && $v.basic.location.zip.$invalid">
                            {{$t("FIELD_MANDATORY")}}
                        </validation-description>
                    </b-col>
                    <b-col md="4" class="mb-4">
                        <label>{{$t('CITY')}}</label>
                        <b-input v-model="basic.location.city"></b-input>

                        <validation-description :show="$v.basic.$dirty && $v.basic.location.city.$invalid">
                            {{$t("FIELD_MANDATORY")}}
                        </validation-description>
                    </b-col>
                </b-row>


            </tab-content>
            <tab-content name="finance" :title="$t('FINANCE')"
                         :before-change="() => validate('finance')">

                <b-row>
                    <b-col md="4" class="mb-4">
                        <b-form-group>
                            <label>{{$t('ENTITY_TYPE')}}</label>
                            <app-select v-model="finance.person_type.id" :options="finance_options"></app-select>

                            <validation-description :show="$v.finance.$dirty && $v.finance.person_type.id.$invalid">
                                {{$t("FIELD_MANDATORY")}}
                            </validation-description>
                        </b-form-group>

                    </b-col>
                    <b-col md="4" class="mb-4">
                        <label>IBAN</label>
                        <b-input v-model="finance.iban"></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6" md="4" lg="2" class="mb-4">
                        <b-checkbox unchecked-value="0" value="1" v-model="finance.self_issue" switch>
                            {{$t('SELF_ISSUE')}}
                        </b-checkbox>
                    </b-col>
                    <b-col sm="6" md="4" lg="2" class="mb-4">
                        <b-checkbox unchecked-value="0" value="1" v-model="finance.vat_system" switch>
                            {{$t('VAT_SYSTEM')}}
                        </b-checkbox>
                    </b-col>
                </b-row>
            </tab-content>

            <button class="btn btn-outline-primary" slot="prev">{{$t('PREVIOUS')}}</button>
            <button class="btn btn-primary" slot="next">{{$t('NEXT')}}</button>
            <button class="btn btn-primary" slot="finish">{{$t('FINISH')}}</button>
        </form-wizard>
    </b-form>
</template>

<script>
    import {getCityList, getCountries} from "@/services/location";
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import {toast} from '@/shared/plugins/toastr'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import {required, minLength, decimal, minValue, maxValue} from 'vuelidate/lib/validators'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {C_OWNER_NEW_V} from '@/shared/component_permission'
    import CountrySelect from '@/components/location/CountrySelect'
    import CitySelect from '@/components/location/CitySelect'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import routeNames from '@/router/routeNames'
    import AppSelect from "@/components/app/AppSelect/AppSelect";


    export default {
        name: "owner-wizard",
        components: {
            AppSelect,
            FormWizard,
            TabContent,
            ValidationDescription,
            CountrySelect,
            CitySelect
        },
        props: {
            propertyId: {
                type: Number
            }
        },
        mixins: [getErrorMessage],
        data() {
            return {
                finance_options: [
                    {id: 1, name: this.$t('NATURAL_ENTITY')},
                    {id: 2, name: this.$t('LEGAL_ENTITY')},

                ],
                routeNames,
                currentIndex: 0,
                basic: {
                    name: null,
                    oib: null,
                    registration_number: null,
                    location: {
                        address: null,
                        city: null,
                        zip: null,
                        country: {
                            id: null,
                            name: null
                        }
                    },

                },
                finance: {
                    person_type: {
                        id: null,
                        label: null
                    },
                    iban: null,
                    self_issue: 0,
                    vat_system: 0,
                },
                saveButtonState: false
            }
        },
        methods: {
            searchCountry(value) {
                return getCountries({
                    name: value
                })
            },
            searchCity(value) {

                return getCityList({
                    name: value,
                    country: this.basic.location.country.hasOwnProperty('id') ? this.basic.location.country.id : null
                })
            },
            updateCountry(value) {
                this.basic.location.country = value
            },
            validate(validation) {
                this.$v[validation].$touch()
                return !this.$v[validation].$invalid

            },
            save() {

                let owner = {
                    name: this.basic.name,
                    oib: this.basic.oib,
                    registration_number: this.basic.registration_number,
                    location: this.basic.location,
                    person_type: this.finance.person_type,
                    iban: this.finance.iban,
                    self_issue: this.finance.self_issue,
                    vat_system: this.finance.vat_system

                }

                this.saveButtonState = true
                this.$store.dispatch('owner/actionCreateOwner', {
                    propertyId: this.propertyId,
                    data: owner
                }).then(response => {


                    toast({
                        'title': "Owner created",
                        'message': "Owner creation success",
                        'type': 'success',
                        'timeout': 2000
                    })
                    localStorage.removeItem('owner_wizard_temp')
                    this.saveButtonState = false
                    this.$router.push({name: routeNames.RN_PROPERTY_OWNER_BASIC, params: {owner_id: response.data.id}})
                }, error => {
                    this.saveButtonState = false
                    toast({
                        'title': "Creation failed",
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 2000
                    })
                })

            },
            handleNextStep(isValid, tabIndex) {
                if (!isValid) {
                    toast({
                        'title': this.$t('VALIDATION_FAILED'),
                        'message': this.$t('VALIDATION_FAILED'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }
                this.currentIndex = tabIndex++
                localStorage.setItem('owner_wizard_temp', JSON.stringify(this.$data))
            },
        },
        computed: {
            saveDisabled() {
                return this.basic.name === null
                    || this.basic.location.address === null
                    || this.basic.location.city === null
                    || this.basic.location.zip === null
                    || this.basic.location.country.id === null
                    || this.finance.person_type.id === null
            },
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](C_OWNER_NEW_V)
            }
        },
        validations: {
            basic: {
                name: {required},
                location: {
                    country: {
                        id: {required}
                    },
                    city: {required},
                    address: {required},
                    zip: {required}
                },
            },
            finance: {
                person_type: {
                    id: {required}
                }

            },
        }
    }
</script>

<style scoped>

</style>
