<template>

    <div  v-if="hasViewPermission">
        <b-collapse visible :id="'filter'">
            <queue-filter :hasProperty="true"  @search="setFilter" :loading="loading"></queue-filter>
        </b-collapse>
        <queue-list :filter="filter" @busyToggled="toggleBusy" ></queue-list>
    </div>
</template>

<script>

    import QueueFilter from '@/components/channel_manager/synchronization_log/CmQueueFilter'
    import QueueList from '@/components/channel_manager/synchronization_log/QueueList2'
    import {C_PROPERTY_CM_PM_QUEUE_V} from "@/shared/component_permission";
    import {getQueueListData} from "@/services/channel_manager";
    import AppPagination from "@/components/app/filter/AppPagination";


    export default {
        name: "Index",
        components: {
            AppPagination,
            QueueList,
            QueueFilter
        },
        data() {
            return {
                tableData: {
                    items: [],
                    total: 0
                },
                filter:null,
                currentPage: 1,
                lastPage: 0,
                loading: false,

            }
        },

        computed: {
            hasViewPermission() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_CM_PM_QUEUE_V)
            }
        },
        watch: {
            currentPage(val) {
                this.filter.page = val
                this.getTableData(this.filter)
            }
        },
        methods: {
            setFilter(values) {
                this.filter = values
            },
            toggleBusy($event) {
                this.loading = $event
            },

        }
    }
</script>

<style scoped>

</style>
