<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between align-items-center">
                <form-header>{{ $t('CONDITION') }}</form-header>
                <app-button-delete @click="$emit('delete')" button_type="new">
                </app-button-delete>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-for="item in parameter.condition" lg="6" md="6" class="mb-4">
                <distribution-reservation-parameter-type-item :itemType="item">
                </distribution-reservation-parameter-type-item>
            </b-col>
        </b-row>
        <form-header>{{ $t('ACTION') }}</form-header>
        <b-row>
            <b-col v-for="item in parameter.action" lg="6" md="6" class="mb-4">
                <distribution-reservation-parameter-type-item :itemType="item">
                </distribution-reservation-parameter-type-item>
            </b-col>
        </b-row>
        <hr class="mt-0 mb-4">
    </div>
</template>

<script>


import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";

export default {
    name: "ReservationProcessDataTransitionPayment",
    components: {
        AppButtonDelete,
        "DistributionReservationParameterTypeItem": () => import("@/components/channel_manager/distribution/DistributionReservationParameterTypeItem"),
        "AppNumberInput": () => import("@/components/app/form/AppNumberInput"),
        "FormHeader": () => import("@/components/app/form/FormHeader"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect")
    },
    props: {
        parameter: {
            type: Object
        },
        template: {
            type: Object
        }
    },
    data() {
        return {
            event: {value: null, label: null}
        }
    },
}
</script>

<style scoped>

</style>
