<template>
    <div>
        <b-form v-if="!loading && list.length > 0 && hasPermissionView && property.unit_count_list.total_count  > 0" @submit.prevent="save">
            <b-tabs content-class="tab_content" pills vertical>
                <b-tab :title="amenityCategory.label" :key="amenityCategoryIndex1"
                       v-for="(amenityCategory, amenityCategoryIndex1) in list">
                    <b-card-group columns class="column-4">
                        <b-card :key="amenityIndex1" v-for="(amenity, amenityIndex1) in amenityCategory.amenities">
                            <b-list-group class="repeater-2">
                                <b-list-group-item class="list-group-item-title font-weight-bold">
                                    <form-header>{{amenity.label}}
                                        <b-form-checkbox :unchecked-value="0"
                                                         :value="1"
                                                         inline
                                                         class="pull-right m-0 p-0"
                                                         @change="selectAll(amenityCategoryIndex1, amenityIndex1, $event)">
                                        </b-form-checkbox>
                                    </form-header>
                                </b-list-group-item>
                                <b-list-group-item v-for="(amenityUnit, unitIndex) in amenity.units" :key="unitIndex">
                                    <b-form-checkbox
                                        @change="addChangeLogByIndex(amenityCategoryIndex1,  unitIndex, amenityIndex1, $event)"
                                        :id="'amenity_' + amenity.id + '_unit_' + amenityUnit.id"
                                        :name="'amenity_' + amenity.id + '_unit_' + amenityUnit.id"
                                        :unchecked-value="0"
                                        :value="1"
                                        v-model="list[amenityCategoryIndex1].amenities[amenityIndex1].units[unitIndex].value">
                                        {{amenityUnit.name}}
                                    </b-form-checkbox>


                                </b-list-group-item>


                            </b-list-group>
                        </b-card>
                    </b-card-group>


                </b-tab>
            </b-tabs>
            <b-row>
                <b-col class="mt-4">
                    <submit-button @AccessControlEvent="addToAccessControlCounter()"
                                   :access_control_context="{function:c_permission_edit, key: AC_PROPERTY_AMENITIES}"></submit-button>

                </b-col>
            </b-row>
        </b-form>

        <app-no-data :key="2" v-else-if="property.unit_count_list.total_count === 0" :heading="$t('MODULE_NOT_ACTIVE')" :tip="$t('MODULE_NEED_UNITS')" :show-tip="true">
            <app-button button_type="new" @click="openUnitWizard">
                {{ $t('NEW_UNIT') }}
            </app-button>
        </app-no-data>
        <app-aside :widths="['col-sm-12', '40%']" v-model="aside">
            <template slot="header">{{$t('NEW_UNIT')}}</template>
            <unit-wizard v-if="property" :property="property"></unit-wizard>
        </app-aside>

    </div>

</template>

<script>

    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {getPropertyAmenityList, updatePropertyAmenityList} from '@/services/property/index'

    import SubmitButton from '@/components/app/AppButton/AppButtonSubmit'
    import {C_PROPERTY_AMENITY_V, C_PROPERTY_AMENITY_E} from "@/shared/component_permission";
    import FormHeader from "@/components/app/form/FormHeader";
    import AppNoData from "@/components/app/AppNoData";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {GE_OPEN_UNIT_WIZARD, EventBus} from "@/shared/EventBus";
    import UnitWizard from "@/components/unit/new/UnitWizard";
    import AppAside from '@/components/app/form/AppAside'
    import {AC_PROPERTY_AMENITIES} from "@/mixins/AccessControl/AccessControlEnumeration";
    import AppAccessControl from "@/components/app/AppAccessControl";

    export default {
        name: "Index",
        components: {
            UnitWizard,
            FormHeader,
            SubmitButton,
            AppButton,
            AppNoData,
            AppAside
        },
        mixins: [getErrorMessage,AppAccessControl],
        data() {
            return {
                list: [],
                editList: [],
                loading: true,
                c_permission_edit: C_PROPERTY_AMENITY_E,
                aside: false,
                AC_PROPERTY_AMENITIES,
                access_control_fetch_key:AC_PROPERTY_AMENITIES,
                access_control_components:1
            }
        },

        methods: {
            openUnitWizard(){
                EventBus.$emit(GE_OPEN_UNIT_WIZARD)
            },
            save() {
                if (this.editList.length === 0) {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NO_CHANGE'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }

                updatePropertyAmenityList(this.$route.params.id, {list: this.editList}).then(() => {
                        //this.$store.dispatch(ACTION_UPDATE_PROPERTY_AMENITY, object).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })

                        this.editList = []

                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }
                )
            },
            addChangeLogByIndex(categoryIndex, unitIndex, amenityIndex, event) {

                let deleted = event === 1 ? 0 : 1
                let amenityId = this.list[categoryIndex]['amenities'][amenityIndex]['id']
                let unitId = this.list[categoryIndex]['amenities'][amenityIndex]['units'][unitIndex]['id']

                // find index if there is already the same element in array and remove it
                let index = this.editList.findIndex(element => element.unit_id === unitId && element.amenity_id === amenityId)
                if (index > -1) {
                    this.editList.splice(index, 1)
                }

                // adding to update
                this.editList.push({unit_id: unitId, amenity_id: amenityId, deleted: deleted})
            },
            selectAll(categoryIndex, amenityIndex, event) {
                let forLength = this.list[categoryIndex]['amenities'][amenityIndex]['units'].length
                for (let i = 0; i < forLength; i++) {
                    this.addChangeLogByIndex(categoryIndex, i, amenityIndex, event)
                    this.list[categoryIndex]['amenities'][amenityIndex]['units'][i]['value'] = event
                }
            }
        },
        mounted() {
            EventBus.$on(GE_OPEN_UNIT_WIZARD, () => {
                this.aside = true
            })
        },
        created() {
            this.access_control_general_context = {
                property: this.$route.params.id,
                company: this.$store.getters['user/getCompany']
            }
            getPropertyAmenityList(this.$route.params.id).then(response => {
                this.list = response.data
                this.loading = false
            }, () => {
                this.list = []
                this.loading = false
            })
        },
        computed: {
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_AMENITY_V)
            },
            property() {
                return this.$store.getters['property/getProperty']
            },
        }
    }
</script>

<style>
    .tab-height {
        height: 50vh;
        overflow-y: auto;
    }
</style>
