<template>
    <div>
        <revenue-manager-similar-unit-filter @searchForm="setFilter" :loading="loading">></revenue-manager-similar-unit-filter>
        <revenue-manager-similar-unit-list @busyToggled="toggleBusy" :filter="filter"></revenue-manager-similar-unit-list>
    </div>
</template>

<script>
import RevenueManagerSimilarUnitList
    from "@/components/revenue_manager/similar_listing/unit/RevenueManagerSimilarUnitList";
import RevenueManagerSimilarUnitFilter
    from "@/components/revenue_manager/similar_listing/unit/RevenueManagerSimilarUnitFilter";

export default {
    name: "Index",
    components: {RevenueManagerSimilarUnitFilter, RevenueManagerSimilarUnitList},
    data() {
        return {
            loading:false,
            filter:{},
            aside:false
        }
    },
    methods:{
        setFilter(values) {
            this.filter = values
        },
        toggleBusy($event) {
            this.loading = $event
        }
    }
}
</script>

<style scoped>

</style>
