<template>
    <b-button style="white-space: nowrap;" :class="classes" @click.prevent="$emit('click')" size="sm" :variant="variant" :disabled="loading">
        <i class="appDelete" :class="{'fa fa-times fa-fw' : !loading, 'fa fa-spinner fa-spin fa-fw' : loading}"
           aria-hidden="true"></i>
        <slot>{{$t('DELETE')}}</slot>
    </b-button>
</template>

<script>
    export default {
        name: "AppDelete",
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            variant: {
                type: String,
                default: "link"
            },
            classes: {
                type: String,
                default: "text-danger"
            }
        }
    }
</script>

<style scoped>
</style>
