<template>
    <div>
        <b-card class="main-section-card">
            <div slot="header">
                <h2>{{$t('REVIEWS')}}</h2>
            </div>
            <accommodation-review-filter :distribution-list="distributionList" @search="setFilter" :loading="loading"></accommodation-review-filter>
            <accommodation-review-table :distribution-list="distributionList"  @busyToggled="toggleBusy" :filter="filter"></accommodation-review-table>
        </b-card>
    </div>
</template>

<script>
    import AccommodationReviewFilter from "@/components/review/accommodation/list/AccommodationReviewFilter";
    import AccommodationReviewTable from "@/components/review/accommodation/list/AccommodationReviewTable";
    import AppAside from "@/components/app/form/AppAside";
    import {cloneDeep} from "lodash";
    import {getDistributionList} from "@/services/distribution";
    export default {
        name: "Index",
        components: {AccommodationReviewTable, AccommodationReviewFilter,AppAside},
        data(){
            return{
                filter: null,
                loading: false,
                distributionList:[]
            }
        },
        methods: {
            setFilter(values) {
                this.filter = cloneDeep(values)
            },
            toggleBusy($event) {
                this.loading = $event
            }
        },
        created(){
            getDistributionList({company: 1, for_reservation: 1, sorted: 1}).then(res => {
                this.distributionList = res.data
            })
        }
    }
</script>

<style scoped>

</style>
