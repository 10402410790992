import http from "@/services/http";
import {
    REVENUE_MANAGER_UNIT_ID_URL,
    BOOKING_WINDOW,
    BOOKING_WINDOW_SETUP_TYPE,
    BOOKING_WINDOW_SETUP, REVENUE_MANAGER_FILTER_UNIT
} from "@/services/endpoints";

export function getUnitRevenueManagerSetup(unit_id){
    return http.request({
        url:REVENUE_MANAGER_UNIT_ID_URL(unit_id) + '/revenue-manager-setup',
        method: 'get'
    })
}

export function getRevenueBookingWindow(params){
    return http.request({
        url:BOOKING_WINDOW,
        method: 'get',
        params
    })
}
export function setRootBookingWindow(data){
    return http.request({
        url:BOOKING_WINDOW,
        method: 'put',
        data

    })
}
export function getChildBookingWindow(booking_window_id){
    return http.request({
        url:BOOKING_WINDOW+'/'+booking_window_id,
        method: 'get',

    })
}
export function setChildBookingWindow(booking_window_id,data){
    return http.request({
        url:BOOKING_WINDOW+'/'+booking_window_id,
        method: 'put',
        data

    })
}


export function getBookingWindowSetupType(){
    return http.request({
        url:BOOKING_WINDOW_SETUP_TYPE,
        method: 'get',

    })
}
export function getBookingWindowSetup(params){
    return http.request({
        url:BOOKING_WINDOW_SETUP,
        method: 'get',
        params

    })
}
export function createBookingWindowSetup(data){
    return http.request({
        url:BOOKING_WINDOW_SETUP,
        method: 'post',
        data

    })
}
export function updateBookingWindowSetup(booking_window_setup, data){
    return http.request({
        url:BOOKING_WINDOW_SETUP+'/'+booking_window_setup,
        method: 'put',
        data

    })
}
export function activateBookingWindowSetup(booking_window_setup,data){
    return http.request({
        url:BOOKING_WINDOW_SETUP+'/'+booking_window_setup+'/activate',
        method: 'put',
        data
    })

}
export function deleteBookingWindowSetup(booking_window_setup){
    return http.request({
        url:BOOKING_WINDOW_SETUP+'/'+booking_window_setup,
        method: 'delete',
    })
}

export function getActionSetup(params){
    return http.request({
        url:'/adminapi/revenue-manager/setup/action-setup',
        method: 'get',
        params

    })
}
export function setActionSetup(data){
    return http.request({
        url:'/adminapi/revenue-manager/setup/action-setup',
        method: 'put',
        data

    })
}
export function getConditions(params){
    return http.request({
        url:'/adminapi/revenue-manager/setup/condition',
        method: 'get',
        params

    })
}
export function getActions(params){
    return http.request({
        url:'/adminapi/revenue-manager/setup/action',
        method: 'get',
        params

    })
}
export function getRevenueActionSetup(params){
    return http.request({
        url:'/adminapi/revenue-manager/setup/action',
        method: 'get',
        params

    })
}
export  function saveRevenueManagerSetup(data){
    return http.request({
        url:'/adminapi/revenue-manager/setup',
        method: 'put',
        data

    })
}
export  function getRevenueManagerSetup(params){
    return http.request({
        url:'/adminapi/revenue-manager/setup',
        method: 'get',
        params

    })
}
export function getRmFilterUnit(params){
    return http.request({
        url:REVENUE_MANAGER_FILTER_UNIT,
        method: 'get',
        params

    })
}
export  function  getRmFilterUnitById(filter_id, params){
    return http.request({
        url:REVENUE_MANAGER_FILTER_UNIT + '/'+filter_id,
        method: 'get',
        params

    })
}

export function createRmFilterUnit(data){
    return http.request({
        url:REVENUE_MANAGER_FILTER_UNIT,
        method: 'post',
        data

    })
}
export function updateRmFilterUnit(filter_id,data){
    return http.request({
        url:REVENUE_MANAGER_FILTER_UNIT + '/'+filter_id,
        method: 'put',
        data

    })
}
export function deleteRmFilterUnit(filter_id){
    return http.request({
        url:REVENUE_MANAGER_FILTER_UNIT + '/'+filter_id,
        method: 'delete',

    })
}
export function searchRmUnits(params){
    return http.request({
        url:REVENUE_MANAGER_FILTER_UNIT+'/search',
        method: 'get',
        params
    })
}

export function rmFilterSetUnits(filter_id,data){
    return http.request({
        url:REVENUE_MANAGER_FILTER_UNIT+'/'+filter_id+'/set-unit-list',
        method: 'put',
        data
    })
}
export function revenueManagerCompanyActivate(data){
    return http.request({
        url:'/adminapi/revenue-manager/setup/activate-company',
        method: 'post',
        data
    })
}
export function getRevenueManagerSetupPropertyList(params){
    return http.request({
        url:'/adminapi/revenue-manager/setup/property',
        method: 'get',
        params
    })
}

export function getRevenueManagerActivateProperty(params){
    return http.request({
        url:'/adminapi/revenue-manager/setup/activate-property',
        method: 'get',
        params
    })
}
export function setRevenueManagerActivateProperty(data){
    return http.request({
        url:'/adminapi/revenue-manager/setup/activate-property',
        method: 'post',
        data
    })
}
export function getRevenueManagerPropertyActivationData(property_id){
    return http.request({
        url: '/adminapi/revenue-manager/setup/property-activation-data/' + property_id,
        method: 'get'
    })
}
export function copyRevenueManagerUnitSetup(data){
    return http.request({
        url: '/adminapi/revenue-manager/setup/copy-unit-setup',
        method: 'post',
        data
    })
}
