<template>
    <b-form @submit.prevent>
        <form-wizard ref="wizard" :startIndex="currentIndex" transition="fade-in" color=var(--color-primary)
                     errorColor=var(--color-warning)
                     name="propertyWizard" title="" subtitle=""
                     @on-validate="handleNextStep"
                     @on-complete="save"
                     v-if="finish === false">

            <tab-content v-if="selectedData !== undefined" name="units" :title="$t('UNITS')"
                         :before-change="() => validateSelected()">
                <h4 v-if="selectedData.length > 1">{{ $t("ITEMS_SELECTED", {value: selectedData.length}) }}</h4>
                <h4 v-else-if="selectedData.length === 1">{{ $t("ITEM_SELECTED") }}</h4>
            </tab-content>
            <tab-content v-else name="search" :title="$t('SEARCH')" :before-change="() => validateSelect()">
                <form @submit.prevent="setList" class="filter"
                      @reset.prevent="resetForm">
                    <b-row>
                        <b-col md="2" class="mb-3">
                            <label>{{ $t('TYPE') }}</label>
                            <app-select v-model="type" text-field="label" mode="multiselect"
                                        :options="typeOptions" :search-empty-item="false"></app-select>
                        </b-col>
                        <b-col md="2" class="mb-3">
                            <label>{{ $t('COUNTRY') }}</label>
                            <app-select mode="multiselect" text-field="name"
                                        :options="countryList" v-model="country">
                            </app-select>
                        </b-col>
                        <b-col md="2" class="mb-3">
                            <label>{{ $t('CITY') }}</label>
                            <app-select mode="multiselect" text-field="name"
                                        v-model="city" :options="cityList">
                            </app-select>
                        </b-col>
                        <b-col md="2" class="mb-3">
                            <label>{{ $t('PROPERTY') }}</label>
                            <app-select v-if="propertySearch" mode="search" v-model="property" text-field="name"
                                        :search="searchProperty" :search-clear="true"
                                        :options="propertyList"></app-select>
                            <app-select v-else mode="multiselect" text-field="name"
                                        :options="propertyList" v-model="property">
                            </app-select>
                        </b-col>
                        <b-col md="2" class="mb-3">
                            <label>{{ $t('COUNTRY_DESTINATION') }}</label>
                            <app-select text-field="label" :options="countryTagOptions"
                                        v-model="countryTag" mode="multiselect">
                            </app-select>
                        </b-col>
                        <b-col md="2" class="mb-3">
                            <label>{{ $t('REGION_DESTINATION') }}</label>
                            <app-select text-field="label" :options="regionTagOptions"
                                        v-model="regionTag" mode="multiselect">
                            </app-select>
                        </b-col>
                        <b-col md="2" class="mb-3">
                            <label>{{ $t('CITY_DESTINATION') }}</label>
                            <app-select text-field="label" :options="destinationTagOptions"
                                        v-model="destinationTag" mode="multiselect">
                            </app-select>
                        </b-col>
                        <b-col md="2" class="mb-3">
                            <label>{{ $t('TAG') }}</label>
                            <app-select text-field="label" :options="customTagOptions"
                                        v-model="customTag" mode="multiselect">
                            </app-select>
                        </b-col>
                        <b-col md="2" class="mb-3">
                            <label>{{ $t('FILTER') }}</label>
                            <app-select v-model="activeSelect"
                                        :options="activeList"
                                        text-field="label"
                                        :search-empty-item="false"
                                        mode="select">
                            </app-select>
                        </b-col>
                        <b-col md="3" class="mb-3 d-flex align-items-baseline">
                            <app-button-submit class="filter-submit-wrapper" :inline="true" :disabled="loading"
                                               :loading="loaderSubmit" variant="primary"
                                               button_type="search">{{
                                    $t("SEARCH")
                                }}
                            </app-button-submit>
                            <app-button-reset :inline="true" class="pl-2 ml-2" :disabled="loading"
                                              :loading="loaderReset"></app-button-reset>
                        </b-col>
                    </b-row>
                    <div v-if="selectedUnitNumber > 0">
                        <h4 v-if="selectedUnitNumber > 1">{{ $t("ITEMS_SELECTED", {value: selectedUnitNumber}) }}</h4>
                        <h4 v-else-if="selectedUnitNumber === 1">{{ $t("ITEM_SELECTED") }}</h4>
                    </div>
                    <div v-else-if="no_search">
                        <app-no-data></app-no-data>
                    </div>
                </form>
            </tab-content>
            <tab-content name="rate" :title="$t('RATE_PLANS')">
                <form v-if="standardRateV">
                    <b-card class="repeater-1">
                        <div slot="header">
                            <b-row>
                                <b-col>
                                    <repeater-header class="mb-3">
                                        {{ $t('STANDARD_RATE') }}
                                        <i v-if="addNonRef === true" @click="removeRate(1)"
                                           class="fa fa-trash-o fa-fw trash_style" aria-hidden="true"></i>
                                    </repeater-header>
                                </b-col>
                            </b-row>
                        </div>
                        <b-row>
                            <b-col tag="b-col" xl="3" lg="3" md="6" class="mb-4">
                                <app-input-control :error-object="validate.name">
                                    <template v-slot:input="data">
                                        <label>{{ $t('NAME') }}</label>
                                        <b-input type="text" v-model="standard.name" :state="data.state"></b-input>
                                    </template>
                                </app-input-control>
                            </b-col>
                            <b-col tag="b-col" sm="4" class="mb-4">
                                <app-input-control :error-object="validate.linked_value">
                                    <template v-slot:input="data">
                                        <label>{{ $t('LINKED_VALUE') }}
                                            <a class="cursor-pointer"
                                               v-if="seasonalRateMaskEnabled"
                                               @click="toggleSeasonalRateMaskStandard">({{ $t('SEASONAL') }})</a>
                                        </label>
                                        <b-input-group>
                                            <b-form-input
                                                :disabled="standard.mask_setup.length > 1 || yearPeriodStandard"
                                                :state="data.state"
                                                v-model="standard.mask_setup.length > 1 || yearPeriodStandard ? null : standard.linked_value"></b-form-input>
                                            <b-input-group-append>
                                                <app-select v-model="standard.linked_type" :search-empty-item="false"
                                                            :options="relationTypeOptions"></app-select>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <period-rate-mask-list
                                            :mask-setup="standard.mask_setup"
                                            v-if="seasonalRateMaskVisibleStandard === true"
                                            @update="setMaskSetupStandard">
                                        </period-rate-mask-list>
                                    </template>
                                </app-input-control>
                            </b-col>
                            <b-col tag="b-col" xl="3" lg="3" md="6" class="mb-4">
                                <app-input-control :error-object="validate.cancellation_policy">
                                    <template v-slot:input="data">
                                        <label>{{ $t('CANCELLATION_POLICY') }}</label>
                                        <app-select :options="cancellationP" text-field="label"
                                                    v-model="standard.cancellation_policy"
                                                    :state="data.state">
                                        </app-select>
                                    </template>
                                </app-input-control>
                            </b-col>
                        </b-row>
                        <p>
                            <b>{{ $t('INHERIT_FROM_LINKED_RATE_PLAN') }}</b>
                        </p>
                        <b-form-group>
                            <b-checkbox v-for="(restriction, index) in restriction_setup_list"
                                        v-if="restriction.id !== SALES_ENABLED" :key="index"
                                        switch
                                        v-model="standard.inherit_restrictions"
                                        :value="restriction.id"
                            >{{ restriction.label }}
                            </b-checkbox>
                        </b-form-group>
                    </b-card>
                </form>
                <!--                <hr v-if="addNonRef || standardRateV === false">-->
                <form v-if="addNonRef" class="non-ref">
                    <b-card class="repeater-1">
                        <div slot="header">
                            <b-row>
                                <b-col>
                                    <repeater-header class="mb-3">
                                        {{ $t('NON_REFUNDABLE_RATE') }}
                                        <i v-if="standardRateV === true" @click="removeRate(2)"
                                           class="fa fa-trash-o fa-fw trash_style" aria-hidden="true"></i>
                                    </repeater-header>
                                </b-col>
                            </b-row>
                        </div>
                        <b-row>
                            <b-col tag="b-col" xl="3" lg="3" md="6" class="mb-4">
                                <app-input-control :error-object="validate.name_non_ref">
                                    <template v-slot:input="data">
                                        <label>{{ $t('NAME') }}</label>
                                        <b-input :state="data.state" type="text" v-model="nonRef.name"></b-input>
                                    </template>
                                </app-input-control>
                            </b-col>
                            <b-col tag="b-col" sm="4" class="mb-4">
                                <app-input-control :error-object="validate.linked_value_non_ref">
                                    <template v-slot:input="data">
                                        <label>{{ $t('LINKED_VALUE') }}
                                            <a class="cursor-pointer"
                                               v-if="seasonalRateMaskEnabled"
                                               @click="toggleSeasonalRateMaskNonRef">({{ $t('SEASONAL') }})</a>
                                        </label>
                                        <b-input-group>
                                            <b-form-input :disabled="nonRef.mask_setup.length > 1 || yearPeriodNonRef"
                                                          :state="data.state"
                                                          v-model="nonRef.mask_setup.length > 1 || yearPeriodNonRef ? null : nonRef.linked_value"></b-form-input>
                                            <b-input-group-append>
                                                <app-select v-model="nonRef.linked_type" :search-empty-item="false"
                                                            :options="relationTypeOptions"></app-select>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <period-rate-mask-list
                                            :mask-setup="nonRef.mask_setup"
                                            v-if="seasonalRateMaskVisibleNonRef === true"
                                            @update="setMaskSetupNonRef">
                                        </period-rate-mask-list>
                                    </template>
                                </app-input-control>
                            </b-col>
                            <b-col tag="b-col" xl="3" lg="3" md="6" class="mb-4">
                                <app-input-control :error-object="validate.cancellation_policy_non_ref">
                                    <template v-slot:input="data">
                                        <label>{{ $t('CANCELLATION_POLICY') }}</label>
                                        <app-select :state="data.state" :options="cancellationP" text-field="label"
                                                    v-model="nonRef.cancellation_policy">
                                        </app-select>
                                    </template>
                                </app-input-control>
                            </b-col>
                        </b-row>
                        <p>
                            <b>{{ $t('INHERIT_FROM_LINKED_RATE_PLAN') }}</b>
                        </p>
                        <b-form-group>
                            <b-checkbox v-for="(restriction, index) in restriction_setup_list"
                                        v-if="restriction.id !== SALES_ENABLED" :key="index"
                                        switch
                                        v-model="nonRef.inherit_restrictions"
                                        :value="restriction.id"
                            >{{ restriction.label }}
                            </b-checkbox>
                        </b-form-group>
                    </b-card>
                </form>
                <div class="addButton">
                    <app-button v-if="standardRateV === false" @click="activateStandard">{{ $t('TURN_ON_STANDARD') }}
                    </app-button>
                    <app-button v-else-if="addNonRef === false" @click="activateNonRef">{{ $t('TURN_ON_NON_REF') }}
                    </app-button>
                </div>
            </tab-content>
            <button class="btn btn-outline-primary" slot="prev" :disabled="loadingFinnish">{{ $t('PREVIOUS') }}</button>
            <button class="btn btn-primary" slot="next">
                {{ $t('NEXT') }}
            </button>
            <button class="btn btn-primary" slot="finish" :disabled="loadingFinnish">
                <i v-if="loadingFinnish"
                   class="mr-1 fa fa-spinner fa-spin fa-fw"
                   aria-hidden="true">
                </i>
                {{ $t('FINISH') }}
            </button>
        </form-wizard>
        <app-wizard-complete v-if="finish" class="wizard-complete"
                             @close="$store.commit('website/setActivationAsideState', false)"
                             :show-close-button="false">
            <template v-slot:title>
                <b-img class="search_init_icon" src="/img/icons/document_icon.png"></b-img>
            </template>
            <template v-slot:body>
                <h4 style="padding-top: 2rem" class="text-primary font-weight-light">{{selectedData ? selectedData.length : selectedUnitData.length }}
                    {{ $t('ACTIVATED_UNITS') }}</h4>
            </template>
        </app-wizard-complete>
    </b-form>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import {EventBus, V_ERROR} from "@/shared/EventBus";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButton from "@/components/app/AppButton/AppButton";
import AppInputControl from "@/components/app/AppInputControl";
import FormHeader from "@/components/app/form/FormHeader";
import AppWizardComplete from "@/components/app/Wizard/AppWizardComplete";
import ValidationDescription from '@/components/app/form/AppValidationDescription'
import RepeaterHeader from "@/components/app/form/RepeaterHeader";
import AppNoData from "@/components/app/AppNoData";
import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import {getCityList, getCountries} from "@/services/location";
import {getActiveUnitList, getWebsiteCompanyList, setActivationUnit} from "@/services/direct_booking/website";
import {getAllUnitTypeList, getUnitList} from "@/services/unit";
import {getAllTags, getTagsPaginated} from "@/services/tag";
import {
    CLOSED_ON_ARRIVAL, CLOSED_ON_DEPARTURE,
    MAX_STAY,
    MIN_STAY,
    RELEASE_PERIOD,
    TAG_TYPE_CUSTOM,
    TAG_TYPE_DESTINATION,
    SALES_ENABLED,
    TAG_DESTINATION_TYPE_CITY, TAG_DESTINATION_TYPE_COUNTRY, TAG_DESTINATION_TYPE_REGION
} from "@/shared/constants";
import {getPropertyList} from "@/services/property";
import {toast} from "@/shared/plugins/toastr";
import {getRestrictionList} from "@/services/pricing/restriction";
import {getChargeProfileList} from "@/services/pricing";
import {getSystemCancellationPolicies} from "@/services/company";
import {
    WE_ACTIVATION_UNIT_CANCELLATION_POLICY_EMPTY,
    WE_ACTIVATION_UNIT_LINKED_VALUE_EMPTY,
    WE_ACTIVATION_UNIT_LINKED_VALUE_NON_REF,
    WE_ACTIVATION_UNIT_CANCELLATION_POLICY_NON_REF,
    WE_ACTIVATION_UNIT_PLAN_NAME_STANDARD,
    WE_ACTIVATION_UNIT_PLAN_NAME_NON_REF,
    WE_ACTIVATION_UNIT_LINKED_VALUE_NOT_NUMBER,
    WE_ACTIVATION_UNIT_LINKED_VALUE_NON_REF_NOT_NUMBER,
} from "@/shared/error_codes";
import {AC_BULK_RATE_PLAN, AC_RATE_PLAN_FORM} from "@/mixins/AccessControl/AccessControlEnumeration";
import {notifySuccess} from "@/shared/plugins/toastr";
import PeriodRateMaskList from "@/components/pricing/rate_plan/period/PeriodRateMaskList";
import moment from "moment";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import AppAccessControl from "@/components/app/AppAccessControl";
import {C_UNIT_RATE_PLAN_MASK_SETUP} from "@/shared/component_permission";
import {fetchAccessControlData} from "@/services/access";


export default {
    name: "ActivationWizard",
    components: {
        FormWizard,
        TabContent,
        AppSelect,
        AppButtonReset,
        AppButtonSubmit,
        FormHeader,
        AppButton,
        AppInputControl,
        ValidationDescription,
        AppWizardComplete,
        RepeaterHeader,
        AppNoData, PeriodRateMaskList, AppAccessControl
    },
    mixins: [getErrorMessage, AccessControlComponent],
    props: {
        selectedData: {
            type: Array
        },
        distributionId: {
            type: Number
        },
        websiteId: {
            type: Number
        },
        companyId: {
            type: Number,
            default: null
        }
    },

    data() {
        return {
            currentIndex: 0,
            selectedUnitNumber: 0,
            no_search: false,
            addNonRef: false,
            standardRateV: true,
            loading: false,
            loadingFinnish: false,
            loaderReset: false,
            loaderSubmit: false,
            selected: [],
            countryList: [],
            countryTagOptions: [],
            regionTagOptions: [],
            regionTag: [],
            countryTag: [],
            partnersOptions: [],
            propertySearch: false,
            setDestinationList: [
                {id: 1, label: this.$t('ACTIVE')},
                {id: 0, label: this.$t('INACTIVE')},
                {id: 2, label: this.$t('DESTINATION_ASSIGNED')},
                {id: 3, label: this.$t('DESTINATION_NOT_ASSIGNED')}
            ],
            setDestination: [],
            activeList: [
                {id: 1, label: this.$t('ACTIVE')},
                {id: 0, label: this.$t('INACTIVE')},
                {id: 2, label: this.$t('DESTINATION_ASSIGNED')},
                {id: 3, label: this.$t('DESTINATION_NOT_ASSIGNED')}
            ],
            activeSelect: null,
            typeOptions: [],
            cityList: [],
            propertyList: [],
            unitList: [],
            destinationTagOptions: [],
            customTagOptions: [],
            country: [],
            city: [],
            type: [],
            property: [],
            unit: [],
            destinationTag: [],
            customTag: [],
            selectedUnitData: [],
            restriction_setup_list: [],
            relationTypeOptions: [
                {id: 1, name: '%'},
                {id: 2, name: 'fix'}
            ],
            linked_type_standard: null,
            linked_type_non: null,
            cancellationP: [],
            standard: {
                name: null,
                linked_type: 1,
                linked_value: null,
                cancellation_policy: null,
                inherit_restrictions: [],
                rate_plan_type: {
                    id: 1,
                    name: 'Standard rate'
                },
                rate_plan_id: null,
                mask_setup: []
            },
            nonRef: {
                name: null,
                linked_type: 1,
                linked_value: null,
                cancellation_policy: null,
                inherit_restrictions: [],
                rate_plan_type: {
                    id: 2,
                    name: 'Non-refundable rate'
                },
                rate_plan_id: null,
                mask_setup: []
            },
            SALES_ENABLED,
            validate: {
                name: WE_ACTIVATION_UNIT_PLAN_NAME_STANDARD,
                linked_value: [WE_ACTIVATION_UNIT_LINKED_VALUE_EMPTY, WE_ACTIVATION_UNIT_LINKED_VALUE_NOT_NUMBER],
                cancellation_policy: WE_ACTIVATION_UNIT_CANCELLATION_POLICY_EMPTY,
                linked_value_non_ref: [WE_ACTIVATION_UNIT_LINKED_VALUE_NON_REF, WE_ACTIVATION_UNIT_LINKED_VALUE_NON_REF_NOT_NUMBER],
                cancellation_policy_non_ref: WE_ACTIVATION_UNIT_CANCELLATION_POLICY_NON_REF,
                name_non_ref: WE_ACTIVATION_UNIT_PLAN_NAME_NON_REF,
            },
            finish: false,
            AC_BULK_RATE_PLAN, AC_RATE_PLAN_FORM,
            access_control_fetch_key: AC_RATE_PLAN_FORM,
            access_control_components: 1,
            chargeProfilesV: false,
            TAG_DESTINATION_TYPE_CITY,
            allCompanies: [],
            partners: [],
            seasonalRateMaskVisibleStandard: false,
            seasonalRateMaskVisibleNonRef: false,
            C_UNIT_RATE_PLAN_MASK_SETUP,
            seasonalRateMaskEnabled: false
        }
    },
    computed: {
        company() {
            if (this.companyId !== null) {
                return this.companyId
            }
            return this.$store.getters['user/getCompany']
        },
        firstDayCurrentYear() {
            return moment().startOf('year').format('MM/DD')
        },
        endDayCurrentYear() {
            return moment().endOf('year').format('MM/DD')
        },
        yearPeriodStandard() {
            if (
                this.standard.mask_setup.length === 1 &&
                (((this.standard.mask_setup[0].period_start > this.firstDayCurrentYear) &&
                        (this.standard.mask_setup[0].period_end < this.endDayCurrentYear)) ||
                    ((this.standard.mask_setup[0].period_start === this.firstDayCurrentYear) &&
                        (this.standard.mask_setup[0].period_end < this.endDayCurrentYear)) ||
                    ((this.standard.mask_setup[0].period_start > this.firstDayCurrentYear) &&
                        (this.standard.mask_setup[0].period_end === this.endDayCurrentYear)))
            ) {
                return true
            }
            return false
        },
        yearPeriodNonRef() {
            if (
                this.nonRef.mask_setup.length === 1 &&
                (((this.nonRef.mask_setup[0].period_start > this.firstDayCurrentYear) &&
                        (this.nonRef.mask_setup[0].period_end < this.endDayCurrentYear)) ||
                    ((this.nonRef.mask_setup[0].period_start === this.firstDayCurrentYear) &&
                        (this.nonRef.mask_setup[0].period_end < this.endDayCurrentYear)) ||
                    ((this.nonRef.mask_setup[0].period_start > this.firstDayCurrentYear) &&
                        (this.nonRef.mask_setup[0].period_end === this.endDayCurrentYear)))
            ) {
                return true
            }
            return false
        },
    },

    methods: {
        toggleSeasonalRateMaskStandard() {
            this.seasonalRateMaskVisibleStandard = !this.seasonalRateMaskVisibleStandard
        },
        toggleSeasonalRateMaskNonRef() {
            this.seasonalRateMaskVisibleNonRef = !this.seasonalRateMaskVisibleNonRef
        },
        setMaskSetupStandard(maskSetup) {
            this.$set(this.standard, 'mask_setup', maskSetup)
        },
        setMaskSetupNonRef(maskSetup) {
            this.$set(this.nonRef, 'mask_setup', maskSetup)
        },
        getSeasonalRateMaskEnabledRequest() {
            return {
                data: [
                    {
                        uid: `seasonal_rate_mask_enabled`,
                        function: this.C_UNIT_RATE_PLAN_MASK_SETUP,
                        distribution: this.distributionId,
                        company: this.company
                    },
                ]
            }
        },
        handleNextStep(isValid, tabIndex) {
            if (!isValid) {
                return
            }
            fetchAccessControlData(this.AC_RATE_PLAN_FORM, this.getSeasonalRateMaskEnabledRequest()).then(response => {
                this.seasonalRateMaskEnabled = response.data['seasonal_rate_mask_enabled'].visible
            })
            EventBus.$emit(V_ERROR, [])
            this.currentIndex = tabIndex++
            localStorage.setItem('unit_activation_wizard_temp', JSON.stringify(this.$data))
        },
        save() {
            let activation_data = this.prepareForRequest()
            this.loadingFinnish = true
            setActivationUnit(this.distributionId, activation_data).then(response => {
                notifySuccess()
                this.finish = true
                this.$emit('finishActivation')
                this.loadingFinnish = false
            }, error => {
                this.loadingFinnish = false
                this.showErrorMessages(error, this.validate)
            })
        },
        setList() {
            this.loaderReset ? this.loaderSubmit = false : this.loaderSubmit = true
            this.loading = true
            let list = {
                country: this.country,
                city: this.city,
                property_id: this.propertySearch ? this.property.id : this.property,
                id: this.unit,
                destination: this.destinationTag,
                region: this.regionTag,
                countries: this.countryTag,
                custom: this.customTag,
                type: this.type,
                active: this.activeSelect,
                company: this.company,
                pg_active: true,
            }
            getActiveUnitList(this.distributionId, list).then(response => {
                this.selectedUnitNumber = response.data.items.length
                response.data.items.length < 1 ? this.no_search = true : this.no_search = false
                this.selectedUnitData = response.data.items
                this.loaderReset = false
                this.loaderSubmit = false
                this.loading = false
            }, error => {
                this.loaderSubmit = false
                this.loaderReset = false
                this.loading = false
                this.showErrorMessages(error)
            })
        },
        setRatePlans() {
        },
        resetForm() {
            this.loaderReset = true
            this.country = []
            this.city = []
            this.property = []
            this.unit = []
            this.destinationTag = []
            this.customTag = []
            this.selectedUnitNumber = 0
            this.selectedUnitData = []
            this.selectedData = []
            this.type = []
            this.setDestination = []
            this.activeSelect = null
            this.no_search = false
            setTimeout(() => {
                this.loaderReset = false;
            }, 1000)
        },
        searchCountry() {
            getCountries({user_country: true}).then(response => {
                this.countryList = response.data
            }, error => {
                this.showErrorMessages(error)
            })
        },
        searchCity() {
            getCityList({user_cities: true}).then(response => {
                this.cityList = response.data
            })
        },
        searchProperty(value) {
            return getPropertyList({
                name: value,
                company_id: this.$store.getters['user/getCurrentCompany'].id,
                city: this.city,
                country: this.country
            }).then(response => {
                return {data: response.data.items}
            })
        },
        searchUnit() {
            getUnitList().then(response => {
                this.unitList = response.data.items
            }, error => {
                this.showErrorMessages(error)
            })
        },
        getTags(type) {
            getAllTags(type, {company: this.company}).then(response => {
                if (type === TAG_TYPE_DESTINATION) {
                    this.destinationTagOptions = response.data
                }
                if (type === TAG_TYPE_CUSTOM) {
                    this.customTagOptions = response.data
                }
            }, error => {
                this.showErrorMessages(error)
            })
        },
        validateSelect() {
            if (this.selectedUnitData < 1) {
                this.errorMessage()
                return false
            } else {
                return true
            }
        },
        validateSelected() {
            if (this.selectedData === undefined) {
                this.errorMessage()
                return false
            } else {
                return true
            }
        },
        errorMessage() {
            toast({
                'title': this.$t('NOTIFICATIONS.ERROR'),
                'message': this.$t('ERROR_EMPTY'),
                'type': 'error',
                'timeout': 3000
            })
        },
        activateNonRef() {
            this.addNonRef = true
        },
        activateStandard() {
            this.standardRateV = true
        },
        removeRate(index) {
            if (index === 1) {
                this.standardRateV = false
            } else {
                this.addNonRef = false
                this.resetNonRefForm()
            }
        },
        resetNonRefForm() {
            this.nonRef.name = null
            this.nonRef.cancellation_policy = null
            this.nonRef.linked_type = 1
            this.nonRef.linked_value = null
            this.nonRef.rate_plan_type.id = 2
            this.nonRef.rate_plan_type.name = 'Non-refundable rate'
        },
        getChProfiles(value) {
            return getChargeProfileList({
                name: value,
                company: this.company
            }, error => {
                this.showErrorMessages(error)
            })
        },
        prepareRestrictionRequest(requestObject) {
            let restriction = {
                inherit_minimum_stay: false,
                inherit_maximum_stay: false,
                inherit_release_period: false,
                inherit_check_in_days: false,
                inherit_check_out_days: false
            }
            if (requestObject.hasOwnProperty('inherit_restrictions') && requestObject.inherit_restrictions.length > 0) {
                requestObject.inherit_restrictions.forEach(restriction_id => {
                    if (restriction_id === MIN_STAY) {
                        restriction.inherit_minimum_stay = true
                    }
                    if (restriction_id === MAX_STAY) {
                        restriction.inherit_maximum_stay = true
                    }
                    if (restriction_id === RELEASE_PERIOD) {
                        restriction.inherit_release_period = true
                    }
                    if (restriction_id === CLOSED_ON_ARRIVAL) {
                        restriction.inherit_check_in_days = true
                    }
                    if (restriction_id === CLOSED_ON_DEPARTURE) {
                        restriction.inherit_check_out_days = true
                    }
                })
            }
            return restriction
        },
        prepareForRequest() {
            let rate_plans = []
            let units = []
            let nonRefRestrictions = this.prepareRestrictionRequest(this.nonRef)
            let standardRestrictions = this.prepareRestrictionRequest(this.standard)
            if (this.addNonRef) {
                let nonRefData = {
                    plan_name: this.nonRef.name,
                    cancellation_policy: this.nonRef.cancellation_policy,
                    linked_type: this.nonRef.linked_type,
                    linked_value: this.nonRef.linked_value,
                    rate_plan_type: this.nonRef.rate_plan_type.id,
                    company_id: this.company,
                    rate_plan_id: this.nonRef.rate_plan_id,
                    ...nonRefRestrictions,
                    ...{mask_setup: this.prepareMaskSetupNonRef(this.nonRef)}
                }
                rate_plans.push(nonRefData)
            }
            if (this.standardRateV) {
                let standardRateData = {
                    plan_name: this.standard.name,
                    cancellation_policy: this.standard.cancellation_policy,
                    linked_type: this.standard.linked_type,
                    linked_value: this.standard.linked_value,
                    rate_plan_type: this.standard.rate_plan_type.id,
                    company_id: this.company,
                    rate_plan_id: this.standard.rate_plan_id,
                    ...standardRestrictions,
                    ...{mask_setup: this.prepareMaskSetupStandard(this.standard)}
                }
                rate_plans.push(standardRateData)
            }
            if (this.selectedData === undefined) {
                this.selectedUnitData.forEach(el => {
                    units.push(el.id)
                })
            } else {
                this.selectedData.forEach(el => {
                    units.push(el.id)
                })
            }

            return {units, rate_plans}
        },
        prepareMaskSetupStandard(requestObject) {
            let maskSetup = []
            if (requestObject.hasOwnProperty('mask_setup') && Array.isArray(requestObject.mask_setup) && requestObject.mask_setup.length > 0) {
                requestObject.mask_setup.forEach(maskSetupPeriod => {
                    maskSetup.push({
                        period_start: maskSetupPeriod.period_start,
                        period_end: maskSetupPeriod.period_end,
                        value_type_id: this.standard.linked_type === 2 ? 2 : 1,
                        value: maskSetupPeriod.period_start === this.firstDayCurrentYear && maskSetupPeriod.period_end  === this.endDayCurrentYear ? this.standard.linked_value :  maskSetupPeriod.value
                    })
                })
                return maskSetup
            }
        },
        prepareMaskSetupNonRef(requestObject) {
            let maskSetup = []
            if (requestObject.hasOwnProperty('mask_setup') && Array.isArray(requestObject.mask_setup) && requestObject.mask_setup.length > 0) {
                requestObject.mask_setup.forEach(maskSetupPeriod => {
                    maskSetup.push({
                        period_start: maskSetupPeriod.period_start,
                        period_end: maskSetupPeriod.period_end,
                        value_type_id: this.nonRef.linked_type === 2 ? 2 : 1,
                        value: maskSetupPeriod.period_start === this.firstDayCurrentYear && maskSetupPeriod.period_end  === this.endDayCurrentYear ? this.nonRef.linked_value :  maskSetupPeriod.value
                    })
                })
                return maskSetup
            }
        },
        resetPolicyForm() {
            this.standard.name = null
            this.standard.linked_type = 'perc'
            this.standard.linked_value = null
            this.standard.cancellation_policy = null

            this.nonRef.name = null
            this.nonRef.linked_type = 'perc'
            this.nonRef.linked_value = null
            this.nonRef.cancellation_policy = null

        },
        addNewItem() {
            this.resetForm()
            this.finish = false
            this.$emit('removeSelected')
            this.resetPolicyForm()
        },
    },
    created() {
        this.getTags(TAG_TYPE_CUSTOM)
        this.searchCountry()
        this.searchCity()
        this.searchUnit()
        this.standard.inherit_restrictions.push(MIN_STAY, MAX_STAY, RELEASE_PERIOD, CLOSED_ON_ARRIVAL, CLOSED_ON_DEPARTURE)
        this.nonRef.inherit_restrictions.push(MIN_STAY, MAX_STAY, RELEASE_PERIOD, CLOSED_ON_ARRIVAL, CLOSED_ON_DEPARTURE)
        getRestrictionList().then(response => {
            this.restriction_setup_list = response.data
        }, error => {
            this.showErrorMessages(error)
        })
        getSystemCancellationPolicies(this.company).then(response => {
            this.cancellationP = response.data
        }, error => {
            this.showErrorMessages(error)
        })
        getPropertyList({
            company_id: this.$store.getters['user/getCurrentCompany'].id,
            city: this.city,
            country: this.country
        }).then(response => {
            const data = response.data && response.data.hasOwnProperty('items') ? response.data.items : []
            this.propertyList = data.map(el => {
                return {id: el.id, name: el.name}
            })

        }, error => {
            this.showErrorMessages(error)
        })
        getAllUnitTypeList().then(response => {
            this.typeOptions = response.data
        }, () => {
            this.typeOptions = []
        })
        let req = {
            page: 1,
            perPage: 10,
            destination_type: this.TAG_DESTINATION_TYPE_CITY,
            company: this.company,
            tag_type: TAG_TYPE_DESTINATION,
        }

        getTagsPaginated(req).then(response => {
            this.destinationTagOptions = response.data.items
        }, error => {
            this.showErrorMessages(error)
        })

        let destinationTypes = [TAG_DESTINATION_TYPE_CITY, TAG_DESTINATION_TYPE_COUNTRY, TAG_DESTINATION_TYPE_REGION]

        destinationTypes.forEach(el => {
            let req = {
                page: 1,
                perPage: 10,
                destination_type: el,
                company: this.company,
                tag_type: TAG_TYPE_DESTINATION
            }

            getTagsPaginated(req).then(response => {
                if (el === TAG_DESTINATION_TYPE_CITY) {
                    this.destinationTagOptions = response.data.items
                }
                if (el === TAG_DESTINATION_TYPE_COUNTRY) {
                    this.countryTagOptions = response.data.items
                }
                if (el === TAG_DESTINATION_TYPE_REGION) {
                    this.regionTagOptions = response.data.items
                }
            })
        })

        getWebsiteCompanyList(this.$route.params.id).then(response => {
            this.partnersOptions = response.data
            this.allCompanies = []
            response.data.forEach(el => {
                this.allCompanies.push(el.id)
            })
        })

        let localProperty = JSON.parse(localStorage.getItem('presets'))
        if (localProperty.properties !== null) {
            if (localProperty.properties > 10) {
                this.propertySearch = true
            }
        } else {
            this.propertySearch = true
        }
    },
    watch: {
        country: {
            handler() {
                getCityList({user_cities: true, country: this.country}).then(response => {
                    this.cityList = response.data
                }, error => {
                    this.showErrorMessages(error)
                })
                getPropertyList({
                    company_id: this.$store.getters['user/getCurrentCompany'].id,
                    country: this.country
                }).then(response => {
                    this.$nextTick(() => {
                        const data = response.data && response.data.hasOwnProperty('items') ? response.data.items : []
                        this.propertyList = data.map(el => {
                            return {id: el.id, name: el.name}
                        })
                        this.propertySearch = response.data.items.length >= 11;
                    })
                }, error => {
                    this.showErrorMessages(error)
                })
            }
        },
        city: {
            handler() {
                getPropertyList({
                    company_id: this.$store.getters['user/getCurrentCompany'].id,
                    city: this.city
                }).then(response => {
                    this.$nextTick(() => {
                        const data = response.data && response.data.hasOwnProperty('items') ? response.data.items : []
                        this.propertyList = data.map(el => {
                            return {id: el.id, name: el.name}
                        })
                        this.propertySearch = response.data.items.length >= 11;
                    })
                }, error => {
                    this.showErrorMessages(error)
                })
            }
        },
        countryTag: {
            handler(value) {
                let req = {
                    page: 1,
                    perPage: 10,
                    destination_type: TAG_DESTINATION_TYPE_REGION,
                    company: this.company.id,
                    country_parent_id: value,
                    tag_type: TAG_TYPE_DESTINATION,
                }
                getTagsPaginated(req).then(response => {
                    this.regionTagOptions = response.data.items
                }, error => {
                    this.showErrorMessages(error)
                })
            }
        },
        regionTag: {
            handler(value) {
                let req = {
                    page: 1,
                    perPage: 10,
                    destination_type: TAG_DESTINATION_TYPE_CITY,
                    company: this.company.id,
                    region_parent_id: value,
                    tag_type: TAG_TYPE_DESTINATION,
                }
                getTagsPaginated(req).then(response => {
                    this.destinationTagOptions = response.data.items
                }, error => {
                    this.showErrorMessages(error)
                })
            }
        }

    }
}
</script>

<style scoped>

.non-ref {
    padding-top: 0.2rem;
}

.trash_style {
    cursor: pointer;
    color: #6f6efe;
}

.addButton {
    float: left;
}

.search_init_icon {
    height: auto;
    width: 12rem;
}

.wizard-complete {
    padding-top: 12%;
}

</style>
