<template>
    <div>
        <b-form @submit.prevent="createPromotion">
            <b-row class="mb-3">
                <b-col sm="6">
                    <b-form-group>
                        <label> {{$t('NAME')}}</label>
                        <b-form-input type="text" id="name" name="name"
                                      placeholder="Enter name"
                                      v-model="name"
                                      @change="$v.name.$touch"
                                      :state="$v.name.$dirty && $v.name.$invalid ? false : null"

                        ></b-form-input>
                        <validation-description :show="$v.name.$dirty && $v.name.$invalid">
                            Min input: 5 characters
                        </validation-description>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>{{$t('DISCOUNT')}}</label>
                        <b-form-input id="discount" name="discount"
                                      v-model="discount"
                                      @change="$v.discount.$touch"
                                      :state="$v.discount.$dirty && $v.discount.$invalid ? false : null"
                        ></b-form-input>
                        <validation-description :show="$v.discount.$dirty && $v.discount.$invalid">
                            {{$t('FIELD_NUMERIC')}}
                        </validation-description>
                    </b-form-group>
                </b-col>

                <b-col sm="2">
                    <b-form-group>
                        <label>{{$t('TYPE')}}</label>
                        <app-select :options="discountTypeOptions" v-model="discountType"
                                    @change="$v.discountType.$touch"></app-select>
                        <validation-description :show="$v.discountType.$dirty && $v.discountType.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>{{$t('MIN_DAYS_BEFORE_CHECK_IN')}}</label>
                        <b-form-input id="min_days_advance" name="min_days_advance"
                                      v-model="minDaysAdvance"
                                      @change="$v.minDaysAdvance.$touch"
                                      :state="$v.minDaysAdvance.$dirty && $v.minDaysAdvance.$invalid ? false : null"
                        ></b-form-input>
                        <validation-description :show="$v.minDaysAdvance.$dirty && $v.minDaysAdvance.$invalid">
                            {{$t('FIELD_NUMERIC')}}
                        </validation-description>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>{{$t('MAX_DAYS_BEFORE_CHECK_IN')}}</label>
                        <b-form-input id="max_days_advance" name="max_days_advance"
                                      v-model="maxDaysAdvance"
                                      @change="$v.maxDaysAdvance.$touch"
                                      :state="$v.maxDaysAdvance.$dirty && $v.maxDaysAdvance.$invalid ? false : null"

                        ></b-form-input>
                        <validation-description :show="$v.maxDaysAdvance.$dirty && $v.maxDaysAdvance.$invalid">
                            {{$t('FIELD_NUMERIC')}}
                        </validation-description>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label> {{$t('CHECK_IN_FROM_TO')}} </label>
                        <app-date-range-picker v-model="checkInDate"></app-date-range-picker>
                        <validation-description :show="$v.checkInDate.$dirty && $v.checkInDate.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="6">
                    <promotion-submit></promotion-submit>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
    import {PROMOTION_LAST_MINUTE} from "@/shared/constants"
    import PromotionSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import {toast} from '@/shared/plugins/toastr'
    import {required, minLength, minValue, numeric, integer} from 'vuelidate/lib/validators'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import moment from 'moment'
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";

    export default {
        name: "promotion-last-minut-new-form",
        data() {
            return {
                today: moment(new Date()).format('YYYY-MM-DD'),
                discountTypeOptions: [
                    {id: 1, name: '%'},
                    {id: 2, name: 'fix'},

                ],

                name: 'Last Minute',
                discount: null,
                discountType: null,
                minDaysAdvance: null,
                maxDaysAdvance: null,
                checkInDate: {
                    start: null,
                    end: null
                }

            }
        },
        validations: {
            name: {
                required,
                minLength: minLength(5),
            },
            discount: {
                required,
                integer
            },
            discountType: {
                required,
            },

            minDaysAdvance: {
                required, numeric
            },
            maxDaysAdvance: {
                required,
                numeric
            },
            checkInDate: {
                required
            }

        },
        computed: {
            inputState() {
                if (!this.selectedValue) {
                    return {
                        type: 'is-danger',
                        message: 'Date required.',
                    };
                }
                return {
                    type: 'is-primary',
                    message: '',
                };
            },
        },
        components: {
            AppDateRangePicker,
            PromotionSubmit,
            ValidationDescription,
            AppSelect

        },
        methods: {
            createPromotion() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    toast({
                        'title': "",
                        'message': this.$t('VALIDATION_FAILED'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return

                }
                let data = {
                    name: this.name,
                    discount: this.discount,
                    promotion_type: PROMOTION_LAST_MINUTE,
                    discount_type: this.discountType,
                    checkin_date_from: moment(this.checkInDate.start).format('YYYY-MM-DD'),
                    checkin_date_to: moment(this.checkInDate.end).format('YYYY-MM-DD'),
                    min_days_advance: this.minDaysAdvance,
                    max_days_advance: this.maxDaysAdvance,
                }
                this.$store.dispatch('property/actionCreatePropertyPromotion', data)
                    .then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                        this.$emit('promotionSaved')
                    }, error => {
                        toast({
                            'title': "Action fail",
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 2000
                        })
                    })
            },
        }
    }
</script>

<style scoped>

</style>
