<template>
    <div>
        <table class="table" v-if="system_policy || !(policy.system_policy && policy.system_policy.id)">
            <thead>
            <tr>
                <th>{{$t('CANCELLATION_CONDITION')}}</th>
                <th>{{$t('NUM_DAYS')}}</th>
                <th>{{$t('WHEN')}}</th>
                <th>{{$t('CHARGE')}}</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
                <cancelation-condition-item
                        v-if="condOptionsLoaded"
                        v-show="condition.deleted !== 1"
                        :selectConditionType="selectConditionType"
                        :selectDay="selectDay"
                        :selectDayType="selectDayType"
                        :selectCharge="selectCharge"
                        :policy="policy"
                        :system_policy="system_policy"
                        :condition="condition"
                        @saveCondition="saveCondition"
                        @deleteCondition="removeCondition"
                        @conditionChange="reloadText"
                        :key="condition.id + '_' + condition.deleted"
                        v-for="condition in cancellation_condition_list"
                        ref="condition_items">
                </cancelation-condition-item>

                <cancelation-condition-item-new
                        v-if="condOptionsLoaded"
                        :selectConditionType="selectConditionType"
                        :selectDay="selectDay"
                        :selectDayType="selectDayType"
                        :selectCharge="selectCharge"
                        :policy="policy"
                        :system_policy="system_policy"
                        :loading="loadingAddNew"
                        @saveCondition="addCondition">
                </cancelation-condition-item-new>
            </tbody>
        </table>

        <b-row>
            <b-col class="mt-3">
                <submit-button v-if="system_policy || !(policy.system_policy && policy.system_policy.id)"
                               :c_permission="!system_policy ? C_PROPERTY_CANCELATION_POLICY_E : C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_E"
                               @click="confirmationDialogState = true"></submit-button>
            </b-col>
        </b-row>

        <app-confirmation-dialog
            :show="confirmationDialogState"
            :loading="saving"
            @confirm="save"
            @cancel="confirmationDialogState=false"
            :title="$t('SAVE_CONFIRMATION')">
            {{system_policy && policy.linked_policies.length ? $t('SYSTEM_POLICY_LINKED_POLICIES_UPDATE_CONFIRMATION_MSG', {number: policy.linked_policies.length}) : $t('CANCELLATION_POLICY_UPDATE_CONFIRMATION_MSG')}}
        </app-confirmation-dialog>
    </div>
</template>

<script>
    import CancelationConditionItem from '@/components/property/cancelation_policies/condition/CancelationConditionItem'
    import CancelationConditionItemNew
        from '@/components/property/cancelation_policies/condition/CancelationConditionItemNew'
    import SubmitButton from "@/components/app/AppButton/AppButtonSubmit";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import {getPropertyCancelationPolicyOptions} from '@/services/property'
    import {getSystemCancellationPolicyOptions} from "@/services/company";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {C_PROPERTY_CANCELATION_POLICY_E, C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_E} from "@/shared/component_permission";
    import {updateCancellationConditionList} from "@/services/cancellation_policy";
    import {notifySuccess, toast} from "@/shared/plugins/toastr";
    import CancellationPolicyText from "@/components/property/cancelation_policies/policy/CancellationPolicyText";

    export default {
        name: "CancelationConditionList",
        props: {
            policy: {
                type: Object,
                required: true
            },
            system_policy: {
                type: Boolean,
                default: false
            },
            property_id: {
                type: Number,
                default: null
            }
        },
        components: {
            CancellationPolicyText,
            CancelationConditionItem, CancelationConditionItemNew,
            SubmitButton, AppConfirmationDialog
        },
        mixins: [getErrorMessage],
        data(){
            return {
                selectConditionType: [],
                selectDay: [],
                selectDayType: [],
                selectCharge: [],
                condOptionsLoaded: false,
                confirmationDialogState: false,
                saving: false,
                loadingAddNew: false,
                cancellation_condition_list: [],
                C_PROPERTY_CANCELATION_POLICY_E,
                C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_E,
            }
        },
        methods: {
            saveCondition(policy) {
                this.$emit('saveCondition', policy)
            },
            removeCondition(cancellation_condition) {
                let index = this.cancellation_condition_list.findIndex(element => element.id === cancellation_condition.id)
                if (index > -1) {
                    this.$set(this.cancellation_condition_list[index], 'deleted', 1)
                }
                this.reloadText()
            },
            addCondition(cancellation_condition) {
                this.loadingAddNew = true
                let cancellation_condition_id = 'index_' + this.cancellation_condition_list.length
                this.cancellation_condition_list.push({
                    id: cancellation_condition_id,
                    condition_type: cancellation_condition.condition_type,
                    day: cancellation_condition.day,
                    day_type: cancellation_condition.day_type,
                    charge: cancellation_condition.charge,
                    deleted: 0
                })
                this.$nextTick(() => this.loadingAddNew = false)
                this.$nextTick(() => this.reloadText())
            },
            save() {
                this.saving = true
                let cancellation_conditions_request = []
                if (this.$refs.condition_items) {
                    this.$refs.condition_items.forEach(condition_item => {
                        let cancellation_condition_data = condition_item.$data.selected
                        if (isNaN(cancellation_condition_data.id)) {
                            if (cancellation_condition_data.deleted === 0) {
                                cancellation_conditions_request.push(cancellation_condition_data)
                            }
                        } else {
                            cancellation_conditions_request.push(cancellation_condition_data)
                        }
                    })
                }
                updateCancellationConditionList(this.policy.id, {condition_list: cancellation_conditions_request})
                    .then(response => {
                            this.$emit("updateConditions", response.data)
                            notifySuccess()
                        },
                        error => {
                            this.showErrorMessages(error)
                        }).finally(() => {
                    this.saving = false
                    this.confirmationDialogState = false
                })
            },
            reloadText() {
                let cancellation_condition_list = []
                if (this.$refs.condition_items) {
                    this.$refs.condition_items.forEach(condition_item => {
                        let cancellation_condition_data = condition_item.$data.selected
                        if (cancellation_condition_data.deleted === 0) {
                            cancellation_condition_list.push(cancellation_condition_data)
                        }
                    })
                }
                this.$emit('reloadText', cancellation_condition_list)
            }
        },
        watch: {
            'policy.conditions': {
                handler(value) {
                    if (value && value.length) {
                        this.cancellation_condition_list = []
                        value.forEach(cancellation_condition => {
                            let conditionObject = {
                                id: cancellation_condition.id,
                                condition_type: cancellation_condition.condition_type.id,
                                day: cancellation_condition.day.id,
                                day_type: cancellation_condition.day_type.id,
                                charge: cancellation_condition.charge.id,
                                deleted: 0
                            }
                            this.cancellation_condition_list.push(conditionObject)
                        })
                    }
                },
                immediate: true,
                deep: true
            },
            'policy.foc_cancelation': {
                handler() {
                    this.reloadText()
                }
            },
        },
        created() {
            if (this.system_policy) {
                getSystemCancellationPolicyOptions(this.$store.getters[`user/getCompany`]).then(response => {
                        this.selectCharge = response.data.charge
                        this.selectConditionType = response.data.condition_type
                        this.selectDay = response.data.day
                        this.selectDayType = response.data.day_type
                        this.condOptionsLoaded = true
                    },
                    () => {
                        this.selectCharge = []
                        this.selectConditionType = []
                        this.selectDay = []
                        this.selectDayType = []
                    }
                )
            } else {
                let property_id = this.property_id ? this.property_id : this.$route.params.id
                getPropertyCancelationPolicyOptions(property_id).then(response => {
                        this.selectCharge = response.data.charge
                        this.selectConditionType = response.data.condition_type
                        this.selectDay = response.data.day
                        this.selectDayType = response.data.day_type
                        this.condOptionsLoaded = true
                    },
                    () => {
                        this.selectCharge = []
                        this.selectConditionType = []
                        this.selectDay = []
                        this.selectDayType = []
                    }
                )
            }
        }
    }
</script>

<style scoped>

</style>
