<template>
    <form @submit.prevent="save" class="filter" autocomplete="off" v-if="formLoading">
        <div class="d-inline-block">
            <div v-if="distribution_id !== HOMEAWAY_PMSC">
                <b-row>
                    <b-col>
                        <form-header>{{$t('DESTINATION')}}</form-header>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" lg="10">
                        <destination-tagging-form :distribution_id="distribution_id"
                                                  :company_id="company_id"
                                                  :selected="[{'id':objectId}]"
                                                  mode="withoutSave"
                        ></destination-tagging-form>
                    </b-col>
                </b-row>
            </div>
            <b-row>
                <b-col>
                    <form-header>{{$t('RATE_PLAN')}}</form-header>
                </b-col>
            </b-row>
            <div v-show="standardRateV && standardVisible" class="standard-div mt-2 pt-0">
                <b-card class="repeater-1">
                    <div slot="header">
                        <b-row>
                            <b-col>
                                <repeater-header class="mb-3">
                                    {{$t('STANDARD_RATE')}}
                                    <i v-if="addNonRef === true" @click="removeRate(1)" class="fa fa-trash-o fa-fw trash_style" aria-hidden="true"></i>
                                </repeater-header>
                            </b-col>
                        </b-row>
                    </div>
                    <b-row>
                        <b-col cols="5" lg="5" md="5" class="mb-4">
                            <app-input-control :error-object="validate.name">
                                <template v-slot:input="data">
                                    <label>{{$t('NAME')}}</label>
                                    <b-input type="text" v-model="standard.name" :state="data.state"></b-input>
                                </template>
                            </app-input-control>
                        </b-col>
                        <b-col cols="5" lg="5" md="5" class="mb-4">
                            <app-input-control :error-object="validate.linked_value">
                                <template v-slot:input="data">
                                    <label>{{$t('LINKED_VALUE')}}
                                        <app-access-control  tag="span" :access_control_context="{function:C_UNIT_RATE_PLAN_MASK_SETUP,unit:objectId, distribution: distribution_id, company: company, key: AC_RATE_PLAN_FORM}"
                                                             @AccessControlEvent="addToAccessControlCounter()">
                                        <a class="cursor-pointer" @click="toggleSeasonalRateMaskStandard">({{$t('SEASONAL')}})</a>
                                        </app-access-control>
                                    </label>
                                    <b-input-group>
                                        <b-form-input
                                            :disabled="standard.mask_setup.length > 1 || yearPeriodStandard"
                                            :state="data.state" v-model="standard.mask_setup.length > 1 || yearPeriodStandard ? null : standard.linked_value"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <app-select v-model="standard.linked_type" :search-empty-item="false"
                                                        :options="relationTypeOptions"></app-select>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <period-rate-mask-list
                                        :mask-setup="standard.mask_setup"
                                        v-if="seasonalRateMaskVisibleStandard === true"
                                        @update="setMaskSetupStandard">
                                    </period-rate-mask-list>
                                </template>
                            </app-input-control>
                        </b-col>
                        <b-col cols="5" lg="5" md="5" class="mb-4">
                            <app-input-control :error-object="validate.cancellation_policy">
                                <template v-slot:input="data">
                                    <label>{{$t('CANCELLATION_POLICY')}}</label>
                                    <app-select :options="cancellationP" text-field="label" v-model="standard.cancellation_policy"
                                                :state="data.state">
                                    </app-select>
                                </template>
                            </app-input-control>
                        </b-col>
                    </b-row>
                    <p>
                        <b>{{$t('INHERIT_FROM_LINKED_RATE_PLAN')}}</b>
                    </p>
                    <b-form-group>
                        <b-checkbox  v-for="(restriction, index) in restriction_setup_list" v-if="restriction.id !== SALES_ENABLED" :key="index"
                                     switch
                                     v-model="standard.inherit_restrictions"
                                     :value="restriction.id"
                        >{{restriction.label}}
                        </b-checkbox>
                    </b-form-group>
                </b-card>
            </div>
            <div v-show="addNonRef && nonRefVisible" class="non-ref">
                <b-card class="repeater-1">
                    <div slot="header">
                        <b-row>
                            <b-col>
                                <repeater-header class="mb-3">
                                    {{$t('NON_REFUNDABLE_RATE')}}
                                    <i v-if="standardRateV === true" @click="removeRate(2)" class="fa fa-trash-o fa-fw trash_style" aria-hidden="true"></i>
                                </repeater-header>
                            </b-col>
                        </b-row>
                    </div>
                    <b-row>
                        <b-col cols="5" lg="5" md="5" class="mb-4">
                            <app-input-control :error-object="validate.name_non_ref">
                                <template v-slot:input="data">
                                    <label>{{$t('NAME')}}</label>
                                    <b-input :state="data.state" type="text" v-model="nonRef.name"></b-input>
                                </template>
                            </app-input-control>
                        </b-col>
                        <b-col cols="5" lg="5" md="5" class="mb-4">
                            <app-input-control :error-object="validate.linked_value_non_ref">
                                <template v-slot:input="data">
                                    <label>{{$t('LINKED_VALUE')}}
                                        <app-access-control  tag="span" :access_control_context="{function:C_UNIT_RATE_PLAN_MASK_SETUP,unit: objectId, distribution: distribution_id, company: company, key: AC_RATE_PLAN_FORM}"
                                                             @AccessControlEvent="addToAccessControlCounter()">
                                            <a class="cursor-pointer" @click="toggleSeasonalRateMaskNonRef">({{$t('SEASONAL')}})</a>
                                        </app-access-control>
                                    </label>
                                    <b-input-group >
                                        <b-form-input
                                            :disabled="nonRef.mask_setup.length > 1 || yearPeriodNonRef"
                                            :state="data.state" v-model="nonRef.mask_setup.length > 1 || yearPeriodNonRef ? null : nonRef.linked_value"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <app-select v-model="nonRef.linked_type" :search-empty-item="false"
                                                        :options="relationTypeOptions"></app-select>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <period-rate-mask-list
                                        :mask-setup="nonRef.mask_setup"
                                        v-if="seasonalRateMaskVisibleNonRef === true"
                                        @update="setMaskSetupNonRef">
                                    </period-rate-mask-list>
                                </template>
                            </app-input-control>
                        </b-col>
                        <b-col cols="5" lg="5" md="5" class="mb-4">
                            <app-input-control :error-object="validate.cancellation_policy_non_ref">
                                <template v-slot:input="data">
                                    <label>{{$t('CANCELLATION_POLICY')}}</label>
                                    <app-select :state="data.state" :options="cancellationP" text-field="label" v-model="nonRef.cancellation_policy">
                                    </app-select>
                                </template>
                            </app-input-control>
                        </b-col>
                    </b-row>
                    <p>
                        <b>{{$t('INHERIT_FROM_LINKED_RATE_PLAN')}}</b>
                    </p>
                    <b-form-group>
                        <b-checkbox  v-for="(restriction, index) in restriction_setup_list" v-if="restriction.id !== SALES_ENABLED" :key="index"
                                     switch
                                     v-model="nonRef.inherit_restrictions"
                                     :value="restriction.id"
                        >{{restriction.label}}
                        </b-checkbox>
                    </b-form-group>
                </b-card>
            </div>
            <div class="addButton">
                <app-button v-if="standardRateV === false && nonRefVisible && standardVisible" @click="activateStandard">{{$t('TURN_ON_STANDARD')}}</app-button>
                <app-button v-else-if="addNonRef === false && nonRefVisible && standardVisible" @click="activateNonRef">{{$t('TURN_ON_NON_REF')}}</app-button>
            </div>
        </div>
        <div class="filter-submit-wrapper">
            <app-button @click="save" :inline="true" :loading="loadingFinnish"
                        button_type="submit">
                {{ $t("ACTIVATE") }}
            </app-button>
        </div>
    </form>
</template>

<script>

import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import RepeaterHeader from "@/components/app/form/RepeaterHeader";
import AppInputControl from "@/components/app/AppInputControl";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButton from "@/components/app/AppButton/AppButton";
import FormHeader from "@/components/app/form/FormHeader";
import DestinationTaggingForm from "@/components/direct_booking/website/unit_activation/DestinationTaggingForm";
import {fetchUnitRatePlan, setActivationUnit} from "@/services/direct_booking/website";
import {getSystemCancellationPolicies} from "@/services/company";
import {fetchAccessControlData} from "@/services/access";
import {getChargeProfileList} from "@/services/pricing";
import {getRestrictionList} from "@/services/pricing/restriction";
import {
    CLOSED_ON_ARRIVAL,
    CLOSED_ON_DEPARTURE,
    MAX_STAY,
    MIN_STAY,
    RELEASE_PERIOD,
    SALES_ENABLED,
    TAG_DESTINATION_TYPE_CITY,
    TAG_DESTINATION_TYPE_COUNTRY,
    TAG_DESTINATION_TYPE_REGION,
    TAG_TYPE_DESTINATION,
    HOMEAWAY_PMSC,
} from "@/shared/constants";
import {
    WE_ACTIVATION_UNIT_PLAN_NAME_STANDARD,
    WE_ACTIVATION_UNIT_CANCELLATION_POLICY_EMPTY,
    WE_ACTIVATION_UNIT_LINKED_VALUE_EMPTY,
    WE_ACTIVATION_UNIT_LINKED_VALUE_NON_REF,
    WE_ACTIVATION_UNIT_CANCELLATION_POLICY_NON_REF,
    WE_ACTIVATION_UNIT_PLAN_NAME_NON_REF,
    WE_ACTIVATION_UNIT_LINKED_VALUE_NOT_NUMBER,
    WE_ACTIVATION_UNIT_LINKED_VALUE_NON_REF_NOT_NUMBER,
    WE_ACTIVATION_UNIT_LINKED_VALUE_PERCENTAGE_VALUE, WE_ACTIVATION_UNIT_LINKED_VALUE_PERCENTAGE_VALUE_NON_REF
} from "@/shared/error_codes";
import {
    C_WEBSITE_NON_REF_EXISTS,
    C_UNIT_RATE_PLAN_MASK_SETUP,
} from "@/shared/component_permission";
import {AC_BULK_RATE_PLAN, AC_RATE_PLAN_FORM} from "@/mixins/AccessControl/AccessControlEnumeration";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {getTagsPaginated} from "@/services/tag";
import {setupUnitActivationValidation} from "@/services/channel_manager";
import {EventBus} from "@/shared/EventBus";
import PeriodRateMaskList from "@/components/pricing/rate_plan/period/PeriodRateMaskList";
import moment from "moment";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import AppAccessControl from "@/components/app/AppAccessControl";

export default {
    name: "SingleActivationForm",
    props:{
        objectId:{
            type: Number,
            default: null
        },
        distribution_id:{
            type: Number,
            default: null
        },
        company_id: {
            type: Number,
            default: null
        }
    },
    mixins:[getErrorMessage, AccessControlComponent],
    components:{AppButtonSubmit,RepeaterHeader,AppInputControl,AppSelect, AppButton, FormHeader, DestinationTaggingForm, PeriodRateMaskList, AppAccessControl},
    data(){
        return{
            ratePlans: [],
            addNonRef:false,
            chargeProfilesV: false,
            standardRateV: false,
            loadingFinnish: false,
            nonRefVisible: false,
            standardVisible: false,
            chargeProfileOptions: [],
            selectedTag: null,
            tagOptions: [],
            countryTagOptions: [],
            regionTagOptions: [],
            countryTag: null,
            regionTag: null,
            relationTypeOptions: [
                {id: 1, name: '%'},
                {id: 2, name: 'fix'}
            ],
            cancellationP:[],
            restriction_setup_list:[],
            standard:{
                name: null,
                linked_type: 1,
                linked_value: null,
                cancellation_policy: null,
                inherit_restrictions: [],
                rate_plan_type: {
                    id: 1,
                    name: 'Standard rate'
                },
                rate_plan_id: null,
                mask_setup: []
            },
            nonRef:{
                name: null,
                linked_type: 1,
                linked_value: null,
                cancellation_policy: null,
                inherit_restrictions: [],
                rate_plan_type: {
                    id: 2,
                    name: 'Non-refundable rate'
                },
                rate_plan_id: null,
                mask_setup: []
            },
            SALES_ENABLED,
            validate:{
                name: WE_ACTIVATION_UNIT_PLAN_NAME_STANDARD,
                linked_value: [WE_ACTIVATION_UNIT_LINKED_VALUE_EMPTY, WE_ACTIVATION_UNIT_LINKED_VALUE_NOT_NUMBER, WE_ACTIVATION_UNIT_LINKED_VALUE_PERCENTAGE_VALUE],
                cancellation_policy: WE_ACTIVATION_UNIT_CANCELLATION_POLICY_EMPTY,
                linked_value_non_ref: [WE_ACTIVATION_UNIT_LINKED_VALUE_NON_REF, WE_ACTIVATION_UNIT_LINKED_VALUE_NON_REF_NOT_NUMBER, WE_ACTIVATION_UNIT_LINKED_VALUE_PERCENTAGE_VALUE_NON_REF],
                cancellation_policy_non_ref:WE_ACTIVATION_UNIT_CANCELLATION_POLICY_NON_REF,
                name_non_ref: WE_ACTIVATION_UNIT_PLAN_NAME_NON_REF,
            },
            AC_BULK_RATE_PLAN, AC_RATE_PLAN_FORM,
            access_control_fetch_key: AC_RATE_PLAN_FORM,
            access_control_components: 1,
            C_UNIT_RATE_PLAN_MASK_SETUP,
            C_WEBSITE_NON_REF_EXISTS,
            HOMEAWAY_PMSC,
            formLoading: false,
            seasonalRateMaskVisibleStandard:false,
            seasonalRateMaskVisibleNonRef:false,
        }
    },
    computed:{
        firstDayCurrentYear() {
            return moment().startOf('year').format('MM/DD')
        },
        endDayCurrentYear() {
            return moment().endOf('year').format('MM/DD')
        },
        yearPeriodStandard(){
            if (
                this.standard.mask_setup.length === 1 &&
                (((this.standard.mask_setup[0].period_start > this.firstDayCurrentYear) &&
                        (this.standard.mask_setup[0].period_end < this.endDayCurrentYear)) ||
                    ((this.standard.mask_setup[0].period_start === this.firstDayCurrentYear) &&
                        (this.standard.mask_setup[0].period_end < this.endDayCurrentYear)) ||
                    ((this.standard.mask_setup[0].period_start > this.firstDayCurrentYear) &&
                        (this.standard.mask_setup[0].period_end === this.endDayCurrentYear)))
            ){
                return true
            }
            return false
        },
        yearPeriodNonRef(){
            if (
                this.nonRef.mask_setup.length === 1 &&
                (((this.nonRef.mask_setup[0].period_start > this.firstDayCurrentYear) &&
                        (this.nonRef.mask_setup[0].period_end < this.endDayCurrentYear)) ||
                    ((this.nonRef.mask_setup[0].period_start === this.firstDayCurrentYear) &&
                        (this.nonRef.mask_setup[0].period_end < this.endDayCurrentYear)) ||
                    ((this.nonRef.mask_setup[0].period_start > this.firstDayCurrentYear) &&
                        (this.nonRef.mask_setup[0].period_end === this.endDayCurrentYear)))
            ){
                return true
            }
            return false
        },
        website(){
            return this.$store.getters['website/getWebsite']
        },
        company(){
            return this.$store.getters['user/getCompany']
        }
    },
    methods:{
        toggleSeasonalRateMaskStandard() {
            this.seasonalRateMaskVisibleStandard = !this.seasonalRateMaskVisibleStandard
        },
        toggleSeasonalRateMaskNonRef() {
            this.seasonalRateMaskVisibleNonRef = !this.seasonalRateMaskVisibleNonRef
        },
        setMaskSetupStandard(maskSetup) {
            this.$set(this.standard, 'mask_setup', maskSetup)
        },
        setMaskSetupNonRef(maskSetup) {
            this.$set(this.nonRef, 'mask_setup', maskSetup)
        },
        save(){
            this.loadingFinnish = true
            let activation_data = this.prepareForRequest()
            setupUnitActivationValidation({distribution: this.distribution_id, unit: this.objectId}).then(response => {
                setActivationUnit(this.distribution_id, activation_data).then(response => {
                    notifySuccess()
                    this.$emit('singleActivated')
                    this.loadingFinnish = false
                }, error => {
                    this.loadingFinnish = false
                    this.showErrorMessages(error, this.validate)
                    this.loadingFinnish = false
                })
                if (this.distribution_id !== HOMEAWAY_PMSC){
                    // saveTagsForPropertyDestination({unit_list: [this.objectId], object_type: OBJECT_TYPE_PROPERTY, tag_id: this.selectedTag, override_validation: true})
                    EventBus.$emit('saveActivationDestinationTagging')
                }
            },error => {
                this.loadingFinnish = false
                this.showErrorMessages(error)
            })
        },
        activateNonRef(){
            this.addNonRef = true
        },
        activateStandard(){
            this.standardRateV = true
        },
        removeRate(index){
            if (index === 1){
                this.standardRateV = false
            }else{
                this.addNonRef = false
                this.resetNonRefForm()
            }
        },
        resetNonRefForm(){
            this.nonRef.name = null
            this.nonRef.cancellation_policy = null

            this.nonRef.linked_type = 1
            this.nonRef.linked_value = null
            this.nonRef.rate_plan_type.id = 2
            this.nonRef.rate_plan_type.name = 'Non-refundable rate'
        },
        getChProfiles(value) {
            return getChargeProfileList({
                name: value,
                company: this.company
            }, error => {
                this.showErrorMessages(error)
            })
        },
        nonRefRateData(){
            return{
                key:this.AC_BULK_RATE_PLAN,
                unit: this.objectId,
                data: [
                    {uid: `non_ref_data`, function: this.C_WEBSITE_NON_REF_EXISTS},
                ]
            }
        },
        prepareRestrictionRequest(requestObject){
            let restriction = {
                inherit_minimum_stay:false,
                inherit_maximum_stay:false,
                inherit_release_period:false,
                inherit_check_in_days:false,
                inherit_check_out_days:false
            }
            if(requestObject.hasOwnProperty('inherit_restrictions') && requestObject.inherit_restrictions.length > 0){
                requestObject.inherit_restrictions.forEach(restriction_id =>{
                    if(restriction_id === MIN_STAY){
                        restriction.inherit_minimum_stay = true
                    }
                    if(restriction_id === MAX_STAY){
                        restriction.inherit_maximum_stay = true
                    }
                    if(restriction_id === RELEASE_PERIOD){
                        restriction.inherit_release_period = true
                    }
                    if(restriction_id === CLOSED_ON_ARRIVAL){
                        restriction.inherit_check_in_days = true
                    }
                    if (restriction_id === CLOSED_ON_DEPARTURE){
                        restriction.inherit_check_out_days = true
                    }
                })
            }
            return restriction
        },
        prepareForRequest(){
            let rate_plans = []
            let units = [this.objectId]
            let nonRefRestrictions = this.prepareRestrictionRequest(this.nonRef)
            let standardRestrictions = this.prepareRestrictionRequest(this.standard)
            if(this.addNonRef){
                let nonRefData = {
                    plan_name: this.nonRef.name,
                    cancellation_policy: this.nonRef.cancellation_policy,
                    linked_type: this.nonRef.linked_type,
                    linked_value: this.nonRef.linked_value,
                    rate_plan_type: this.nonRef.rate_plan_type.id,
                    company_id: this.company,
                    rate_plan_id: this.nonRef.rate_plan_id,
                    ...nonRefRestrictions,
                    ...{mask_setup: this.prepareMaskSetupNonRef(this.nonRef)}
                }
                rate_plans.push(nonRefData)
            }
            if(this.standardRateV){
                let standardRateData = {
                    plan_name: this.standard.name,
                    cancellation_policy: this.standard.cancellation_policy,
                    linked_type: this.standard.linked_type,
                    linked_value: this.standard.linked_value,
                    rate_plan_type: this.standard.rate_plan_type.id,
                    company_id: this.company,
                    rate_plan_id: this.standard.rate_plan_id,
                    ...standardRestrictions,
                    ...{mask_setup: this.prepareMaskSetupStandard(this.standard)}
                }
                rate_plans.push(standardRateData)
            }
            return {units, rate_plans,}
        },
        prepareMaskSetupStandard(requestObject) {
            let maskSetup = []
            if (requestObject.hasOwnProperty('mask_setup') && Array.isArray(requestObject.mask_setup) && requestObject.mask_setup.length > 0) {
                requestObject.mask_setup.forEach(maskSetupPeriod => {
                    maskSetup.push({
                        period_start: maskSetupPeriod.period_start,
                        period_end: maskSetupPeriod.period_end,
                        value_type_id: this.standard.linked_type === 2 ? 2 : 1,
                        value: maskSetupPeriod.period_start === this.firstDayCurrentYear && maskSetupPeriod.period_end  === this.endDayCurrentYear ? this.standard.linked_value :  maskSetupPeriod.value
                    })
                })
                return maskSetup
            }
        },
        prepareMaskSetupNonRef(requestObject) {
            let maskSetup = []
            if (requestObject.hasOwnProperty('mask_setup') && Array.isArray(requestObject.mask_setup) && requestObject.mask_setup.length > 0) {
                requestObject.mask_setup.forEach(maskSetupPeriod => {
                    maskSetup.push({
                        period_start: maskSetupPeriod.period_start,
                        period_end: maskSetupPeriod.period_end,
                        value_type_id: this.nonRef.linked_type === 2 ? 2 : 1,
                        value: maskSetupPeriod.period_start === this.firstDayCurrentYear && maskSetupPeriod.period_end  === this.endDayCurrentYear ? this.nonRef.linked_value :  maskSetupPeriod.value
                    })
                })
                return maskSetup
            }
        },
    },
    created(){
        fetchUnitRatePlan(this.distribution_id, {unit: this.objectId}).then(response => {
            this.ratePlans = response.data
            if (response.data.length > 0){
                response.data.forEach(el => {
                    if (el.rate_plan_type_id === 1){
                        this.standardRateV = true
                        this.standard.name = el.plan_name
                        this.standard.linked_value = el.linked_calc_value
                        this.standard.linked_type = el.linked_calc_type === 'perc' ? 1 : 2
                        this.standard.cancellation_policy = el.cancelation_preference_id
                        el.minimum_stay_rate_plan_id ? this.standard.inherit_restrictions.push(MIN_STAY) : ''
                        el.maximum_stay_rate_plan_id ? this.standard.inherit_restrictions.push(MAX_STAY) : ''
                        el.release_period_rate_plan_id ? this.standard.inherit_restrictions.push(RELEASE_PERIOD) : ''
                        el.arrival_days_rate_plan_id ? this.standard.inherit_restrictions.push(CLOSED_ON_ARRIVAL) : ''
                        el.departure_days_rate_plan_id ? this.standard.inherit_restrictions.push(CLOSED_ON_DEPARTURE) : ''
                        this.standard.rate_plan_id = el.id
                        this.standard.mask_setup = el.mask_setup
                    }else{
                        this.standard.inherit_restrictions.push(MIN_STAY,MAX_STAY, RELEASE_PERIOD, CLOSED_ON_ARRIVAL,CLOSED_ON_DEPARTURE)
                    }
                    if (el.rate_plan_type_id === 2){
                        this.addNonRef = true
                        this.nonRef.name = el.plan_name
                        this.nonRef.linked_value = el.linked_calc_value
                        this.nonRef.linked_type = el.linked_calc_type === 'perc' ? 1 : 2
                        this.nonRef.cancellation_policy = el.cancelation_preference_id
                        el.minimum_stay_rate_plan_id ? this.nonRef.inherit_restrictions.push(MIN_STAY) : ''
                        el.maximum_stay_rate_plan_id ? this.nonRef.inherit_restrictions.push(MAX_STAY) : ''
                        el.release_period_rate_plan_id ? this.nonRef.inherit_restrictions.push(RELEASE_PERIOD) : ''
                        el.arrival_days_rate_plan_id ? this.nonRef.inherit_restrictions.push(CLOSED_ON_ARRIVAL) : ''
                        el.departure_days_rate_plan_id ? this.nonRef.inherit_restrictions.push(CLOSED_ON_DEPARTURE) : ''
                        this.nonRef.rate_plan_id = el.id
                        this.nonRef.mask_setup = el.mask_setup
                    }else{
                        this.nonRef.inherit_restrictions.push(MIN_STAY,MAX_STAY, RELEASE_PERIOD, CLOSED_ON_ARRIVAL,CLOSED_ON_DEPARTURE)
                    }
                })
            }else{
                this.standardRateV = true
                this.standard.inherit_restrictions.push(MIN_STAY,MAX_STAY, RELEASE_PERIOD, CLOSED_ON_ARRIVAL,CLOSED_ON_DEPARTURE)
                this.nonRef.inherit_restrictions.push(MIN_STAY,MAX_STAY, RELEASE_PERIOD, CLOSED_ON_ARRIVAL,CLOSED_ON_DEPARTURE)
            }
            this.formLoading = true
        }, error => {
            this.showErrorMessages(error)
        })
        getSystemCancellationPolicies(this.company).then(response =>{
            this.cancellationP = response.data
        }, error => {
            this.showErrorMessages(error)
        })
        fetchAccessControlData(this.AC_BULK_RATE_PLAN, this.nonRefRateData()).then(response => {
            this.nonRefVisible = response.data.non_ref_data.visible
            this.standardVisible = response.data.non_ref_data.disabled
        })
        getRestrictionList().then(response => {
            this.restriction_setup_list = response.data
        }, error => {
            this.showErrorMessages(error)
        })
        let req = {
            page: 1,
            perPage: 999,
            destination_type: TAG_DESTINATION_TYPE_CITY,
            company: this.company_id,
            tag_type: TAG_TYPE_DESTINATION,
        }

        getTagsPaginated(req).then(response => {
            this.tagOptions = response.data.items
        })

        let destinationTypes = [TAG_DESTINATION_TYPE_CITY, TAG_DESTINATION_TYPE_COUNTRY, TAG_DESTINATION_TYPE_REGION]

        destinationTypes.forEach(el => {
            let req = {
                page: 1,
                perPage: 999,
                destination_type: el,
                company: this.company_id,
                tag_type: TAG_TYPE_DESTINATION,
            }

            getTagsPaginated(req).then(response => {
                if (el === TAG_DESTINATION_TYPE_CITY){this.tagOptions = response.data.items}
                if (el === TAG_DESTINATION_TYPE_COUNTRY){this.countryTagOptions = response.data.items}
                if (el === TAG_DESTINATION_TYPE_REGION){this.regionTagOptions = response.data.items}
            })
        })

    },
    watch:{
        countryTag:{
            handler(value){
                let req = {
                    page: 1,
                    perPage: 999,
                    destination_type: TAG_DESTINATION_TYPE_REGION,
                    company: this.company_id,
                    country_parent_id: value,
                    tag_type: TAG_TYPE_DESTINATION,
                }
                getTagsPaginated(req).then(response => {
                    this.regionTagOptions = response.data.items
                })

                let reqCity = {
                    page: 1,
                    perPage: 999,
                    destination_type: TAG_DESTINATION_TYPE_CITY,
                    company: this.company_id,
                    country: value,
                    tag_type: TAG_TYPE_DESTINATION,
                }
                getTagsPaginated(reqCity).then(response => {
                    this.tagOptions = response.data.items
                })
            }
        },
        regionTag:{
            handler(value){
                let req = {
                    page: 1,
                    perPage: 999,
                    destination_type: TAG_DESTINATION_TYPE_CITY,
                    company: this.company_id,
                    region_parent_id: value,
                    tag_type: TAG_TYPE_DESTINATION,
                }
                getTagsPaginated(req).then(response => {
                    this.tagOptions = response.data.items
                })
            }
        },
    },
    beforeDestroy() {
        EventBus.$off('saveActivationDestinationTagging')
    }
}
</script>

<style scoped>

.non-ref{
    padding-top: 0.2rem;
}

.trash_style{
    cursor: pointer;
    color: #6f6efe;
}

.addButton{
    float: left;
}

.standard-div{
    padding-top: 1.5rem;
    width: 52rem;
}

</style>
