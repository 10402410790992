<template>
    <div v-if="calculationObject">


        <b-form @submit.prevent="save">
            <!--            <b-row>-->
            <!--                <b-col md="3" class="mb-4">-->
            <!--                    <label>{{$t('SALES_CHANNEL')}}</label>-->
            <!--                    <app-select :options="channelList" v-model="channel" :search-empty-item="false"></app-select>-->
            <!--                </b-col>-->
            <!--            </b-row>-->

            <b-row v-if="statusList.find(s => s.id === CALCULATION_STATUS_FOR_RECALCULATION)">
                <b-col>
                    <b-link :to="{name: routeNames.RN_RESERVATION_ACCOMMODATION_LIST, query: queryParams}"
                            target="_blank">
                        {{$t('CONNECTED_RESERVATIONS')}}
                    </b-link>
                </b-col>
            </b-row>

            <form-header>{{$t('STATUS')}}</form-header>

            <b-row>
                <b-col>
                    <app-button v-for="(status, index) of statusList"
                                :key="index"
                                v-if="statusFunctionStrings[status.id]"
                                @click="openStatusDialog(status)"
                                :loading="saving"
                                :class="index ? 'ml-3' : ''">
                        {{status.transition_label}}
                    </app-button>
                </b-col>
            </b-row>

            <form-header v-if="calculationItems.length">{{$t('ITEMS')}}</form-header>


            <template v-for="(item, index) of calculationItems">
                <b-row>
                    <b-col class="mb-3 d-flex justify-content-between align-items-baseline item-header">
                        <span>{{$t('ITEM')}} #{{index+1}}</span>
                        <app-button variant="link" class="action_button" @click="goToReservations(item.id)">
                            {{$t('PREVIEW')}}
                        </app-button>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="6" xl="3" class="mb-1">
                        <label>{{$t('SALES_CHANNEL')}}</label>
                        <b-input :value="item.channel.name" disabled></b-input>
                    </b-col>

                    <b-col md="6" xl="3" class="mb-1">
                        <label>{{$t('AGENCY_COMMISSION')}}</label>
                        <app-number-input :value="item.agency_total + item.agency_vat" align="right" allow-decimal
                                          disabled></app-number-input>
                    </b-col>

                    <b-col md="6" xl="3" class="mb-1">
                        <label class="text-nowrap">{{$t('SALES_CHANNEL_COMMISSION')}}</label>
                        <app-number-input :value="item.channel_total + item.channel_vat" align="right" allow-decimal
                                          disabled></app-number-input>
                    </b-col>

                    <b-col md="6" xl="3" class="mb-1">
                        <label>{{$t('EXCHANGE_RATE')}}</label>
                        <app-number-input v-model="item.exchange_rate" :decimals="9" allow-decimal
                                          align="right"></app-number-input>
                    </b-col>
                </b-row>

                <hr>
            </template>

            <b-row v-if="calculationItems.length">
                <b-col md="3">
                    <app-button-submit :loading="saving"></app-button-submit>
                </b-col>
            </b-row>
        </b-form>

        <app-status-change-confirmation-dialog
            v-model="statusDialogState"
            :current="calculationObject.status.label"
            :next="nextStatusLabel" @confirm="updateStatus" @cancel="statusDialogState=false">
        </app-status-change-confirmation-dialog>
    </div>
</template>

<script>
    import {
        fetchOwnerCalculation,
        getCalculationStatusList,
        updateOwnerCalculation, updateCalculationStatus
    } from "@/services/finance/owner_calculation";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import AppButton from "@/components/app/AppButton/AppButton";
    import routeNames from "@/router/routeNames";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import FormHeader from "@/components/app/form/FormHeader";
    import AppStatusChangeConfirmationDialog from "@/components/app/AppStatusChangeConfirmationDialog";
    import {
        STATUS_CONFIRMED,
        CALCULATION_STATUS_CANCELED,
        CALCULATION_STATUS_FOR_RECALCULATION,
        CALCULATION_STATUS_READY_FOR_INVOICE,
        CALCULATION_STATUS_CALCULATED,
    } from "@/shared/constants";

    const statusFunctionStrings = {
        [CALCULATION_STATUS_CALCULATED]: 'recalculate',
        [CALCULATION_STATUS_READY_FOR_INVOICE]: 'ready-for-invoice',
        [CALCULATION_STATUS_FOR_RECALCULATION]: 'for-recalculation',
        [CALCULATION_STATUS_CANCELED]: 'cancel',
    }

    export default {
        name: "OwnerCalculationForm",
        components: {
            AppStatusChangeConfirmationDialog, FormHeader, AppButtonSubmit, AppSelect, AppButton, AppNumberInput
        },
        mixins: [getErrorMessage],
        props: {
            calculationId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                calculationObject: null,
                calculationItems: [],
                // channelList: [{id: null, name: this.$t('SHOW_ALL')}],
                routeNames,
                channel: null,
                saving: false,
                statusList: [],
                statusFunctionStrings,
                statusFunction: null,
                statusDialogState: false,
                nextStatusLabel: null,
                CALCULATION_STATUS_FOR_RECALCULATION,
            }
        },
        computed: {
            queryParams() {
                return this.calculationObject ? {
                    date: 'check_out',
                    from: this.calculationObject.start_date,
                    to: this.calculationObject.end_date,
                    status: [STATUS_CONFIRMED],
                    owner: JSON.stringify({
                        id: this.calculationObject.owner.id,
                        full_name: this.calculationObject.owner.name
                    })
                } : {}
            }
        },
        methods: {
            save() {
                this.saving = true
                const items = this.calculationItems.map(item => ({id: item.id, exchange_rate: item.exchange_rate}))
                updateOwnerCalculation(this.calculationId, {items}).then(() => {
                    notifySuccess()
                    this.$emit('close')
                }, error => this.showErrorMessages(error)).finally(() => this.saving = false)
            },
            goToReservations(calculation_item) {
                const routeData = this.$router.resolve({
                    name: routeNames.RN_RESERVATION_ACCOMMODATION_LIST,
                    query: {calculation_item}
                })
                window.open(routeData.href, '_blank')
            },
            openStatusDialog(status) {
                this.statusFunction = statusFunctionStrings[status.id]
                this.nextStatusLabel = status.label
                this.statusDialogState = true
            },
            updateStatus() {
                this.statusDialogState = false
                this.saving = true
                updateCalculationStatus(this.statusFunction, this.calculationId).then(() => {
                    notifySuccess()
                    this.$emit('close')
                }, error => this.showErrorMessages(error)).finally(() => this.saving = false)
            }
        },
        created() {
            getCalculationStatusList({calculation: this.calculationId}).then(response => {
                this.statusList = response.data
            })

            fetchOwnerCalculation(this.calculationId).then(response => {
                this.calculationObject = response.data
                this.calculationItems = this.calculationObject.items

                // if (this.calculationItems.length) {
                //     this.channelList = this.channelList.concat(Object.values(this.calculationItems.reduce((acc, el) => {
                //         acc[el.channel.id] = {id: el.channel.id, name: el.channel.name}
                //         return acc
                //     }, {})))
                // }
            })
        }
    }
</script>

<style scoped>
    .item-header {
        /*margin: 0 0 15px;*/
        margin: 0 12px;
        font-weight: bold;
    }
</style>
