<template>
    <b-card>
        <div class="d-flex justify-content-start align-items-center" v-if="loading">
            <b-spinner variant="primary"></b-spinner>
        </div>
        <div class="d-flex justify-content-between" v-else>
            <div class="d-flex flex-column justify-content-between">
                <div class="header-2">{{$t('CANCEL_RESERVATIONS_WITH_INVOICE')}}</div>
                <b-link
                        :to="{name: routes.RN_RESERVATION_ACCOMMODATION_LIST,query:queryObject}"
                        target= '_blank'
                        class="header-1">{{canceled_reservation}}
                </b-link>
            </div>
        </div>
    </b-card>
</template>

<script>
    import routeNames from "@/router/routeNames";
    import {getReservationCount} from "@/services/dashboard";
    import {
        RES_STATUS_CANCELED,INVOICE_STATUS_CREATED, INVOICE_STATUS_PROCESSING,
        INVOICE_STATUS_PROCESSED, INVOICE_STATUS_COMPLETED, INVOICE_STATUS_PAID,
        RES_STATUS_PAID
    } from "@/shared/constants";
    import moment from 'moment'
    import routes from '@/router/routeNames'

    export default {
        name: "DashboardCanceledReservation",
        data() {
            return {
                INVOICE_STATUS_CREATED, INVOICE_STATUS_PROCESSING,
                INVOICE_STATUS_PROCESSED, INVOICE_STATUS_COMPLETED, INVOICE_STATUS_PAID,
                loading: false,
                total_reservation: 0,
                canceled_reservation: 0,
                routes: routes,
                queryObject: {
                    payment_status: RES_STATUS_PAID,
                    status: RES_STATUS_CANCELED,
                    is_advance: 1,
                    is_canceled:0,
                    date:'check_out',
                    from:moment().subtract(7,'d').format("YYYY-MM-DD"),
                    to:moment().format("YYYY-MM-DD"),
                    invoice_status: [INVOICE_STATUS_CREATED, INVOICE_STATUS_PROCESSING,
                        INVOICE_STATUS_PROCESSED, INVOICE_STATUS_COMPLETED, INVOICE_STATUS_PAID]
                }
            }
        },
        props: {
            company: {
                type: Number,
            },
        },
        methods: {
            open() {
                    this.$router.replace({
                        name: routeNames.RN_RESERVATION_ACCOMMODATION_LIST,
                        query: this.queryObject
                    })
            },
            getData() {
                this.loading = true

                let req = {
                    company: this.company,
                    payment_status: RES_STATUS_PAID,
                    status: RES_STATUS_CANCELED,
                    is_advance: 1,
                    is_canceled:0,
                    check_out_from_date:moment().subtract(7,'d').format("YYYY-MM-DD"),
                    check_out_to_date:moment().format("YYYY-MM-DD"),
                    invoice_status: [INVOICE_STATUS_CREATED, INVOICE_STATUS_PROCESSING,
                        INVOICE_STATUS_PROCESSED, INVOICE_STATUS_COMPLETED, INVOICE_STATUS_PAID]
                }

                getReservationCount(req).then(response => {
                    this.canceled_reservation = response.data.total
                    this.loading = false
                })
            }
        },
        mounted() {
            this.getData()
        }

    }

</script>

<style scoped>

</style>
