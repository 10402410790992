<template>
<div>
    <property-filter :hasFilter="hasFilter" ></property-filter>
    <property-list-table :hasFilter="hasFilter" :empty_initial_list="empty_initial_list"
                         :setup_parameter="setup_parameter" :selectable="false"></property-list-table>
</div>
</template>

<script>


import {DEFAULT_PAGINATION_LIMIT, LS_PRESETS} from "@/shared/constants";

export default {
    name: "PropertyReservationSetup",
    components: {
        "PropertyFilter": () => import("@/components/property/list/PropertyFilter"),
        "PropertyListTable": () => import("@/components/property/list/PropertyListTable")
    },
    props: {
        setup_parameter: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            hasFilter: true,
            empty_initial_list: false,
            distribution: null
        }
    },
    methods: {
        setFilter(values) {
            this.sort = values.sort
            this.filter = values.filter
        },
        toggleBusy($event) {
            this.loading = $event
        },
    },
    created() {
        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)

            if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                if (storagePresets.properties === 0) {
                    this.empty_initial_list = true
                }
                this.hasFilter = false
            }
        }
    },
}
</script>

<style scoped>

</style>
