<template>
    <div>
        <b-row>
            <b-col class="mb-3 d-flex justify-content-end align-items-baseline">
                <app-button button_type="new" variant="primary" @click="edit({})" :c_permission="C_SETUP_FINANCE_INVOICE_TYPES_NEW">
                    {{ $t("NEW_INVOICE_TYPE") }}
                </app-button>
            </b-col>
        </b-row>

        <invoice-type-setup-filter @search="search" :loading="loading"></invoice-type-setup-filter>
        <invoice-type-setup-list v-if="tableItems.length" :tableItems="tableItems" @edit="edit" :loading="loading"></invoice-type-setup-list>
        <app-no-data v-else-if="!tableItems.length && !loading"></app-no-data>
        <app-pagination :listCount="total" v-model="filter.page" :last-page="lastPage"></app-pagination>

        <app-aside v-model="sidebarState">
            <template slot="header">{{ sidebarHeader }}</template>
            <invoice-type-setup-form :invoice-type-object="invoiceTypeObject"
                                @created="create"
                                @updated="update"
                                @deleted="_delete">
            </invoice-type-setup-form>
        </app-aside>
    </div>
</template>

<script>
    import {getInvoiceTypeList} from "@/services/finance/setup";
    import FormHeader from "@/components/app/form/FormHeader";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppAside from "@/components/app/form/AppAside";
    import AppPagination from "@/components/app/filter/AppPagination";
    import AppNoData from "@/components/app/AppNoData";
    import InvoiceTypeSetupFilter from "@/components/finance/setup/invoice_types/InvoiceTypeSetupFilter";
    import InvoiceTypeSetupList from "@/components/finance/setup/invoice_types/InvoiceTypeSetupList";
    import InvoiceTypeSetupForm from "@/components/finance/setup/invoice_types/InvoiceTypeSetupForm";
    import {cloneDeep} from "lodash";
    import {C_SETUP_FINANCE_INVOICE_TYPES_NEW} from "@/shared/component_permission";

    export default {
        name: "Index",
        components: {
            InvoiceTypeSetupForm,
            InvoiceTypeSetupList,
            InvoiceTypeSetupFilter,
            AppNoData,
            AppPagination, AppButton, FormHeader, AppAside},
        data() {
            return {
                tableItems: [],
                loading: false,
                invoiceTypeObject: {},
                sidebarState: false,
                total: null,
                lastPage: null,
                filter: {page: 1},
                C_SETUP_FINANCE_INVOICE_TYPES_NEW
            }
        },
        computed: {
            sidebarHeader() {
                return this.invoiceTypeObject && this.invoiceTypeObject.id ? this.$t('EDIT_INVOICE_TYPE') : this.$t('NEW_INVOICE_TYPE')
            },
            companyId() {
                return this.$store.getters['user/getCompany']
            },
        },
        methods: {
            edit(invoiceTypeObject) {
                if(invoiceTypeObject.hasOwnProperty('disabled_roles') && invoiceTypeObject.disabled_roles.length > 0){
                    invoiceTypeObject.disabled_roles =   invoiceTypeObject.disabled_roles.map(el=>{
                        return el.id
                    })
                }
                this.invoiceTypeObject = cloneDeep(invoiceTypeObject)
                this.sidebarState = true
            },
            create(object) {
                this.tableItems.unshift(object)
                this.sidebarState = false
            },
            update(object) {
                this.$set(this.tableItems, this.tableItems.findIndex(x => x.id === object.id), object)
                this.sidebarState = false
            },
            _delete(objectId) {
                this.tableItems.splice(this.tableItems.findIndex(x => x.id === objectId), 1)
                this.sidebarState = false
            },
            getList() {
                this.loading = true
                getInvoiceTypeList({company: this.companyId, ...this.filter, paginated: true}).then(response => {
                    this.total = response.data.total
                    this.lastPage = response.data.last_page
                    this.tableItems = response.data.items
                }).finally(() => this.loading = false)
            },
            search(filter) {
                this.filter = {...filter}
            }
        },
        watch: {
            filter: {
                handler() {
                    this.getList()
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>
