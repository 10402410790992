<template>
    <b-col md="12" lg="12" class="mb-4">
        <b-table v-if="tableData.length"
                 hover
                 responsive
                 :busy="isBusy"
                 :items="tableData"
                 :fields="fields">
            <template v-slot:radio_buttons="data">
                <b-form-radio
                              v-model="selected"
                              :id="'radio_button_' + data.item.id"
                              :name="'radio_button_' + data.item.id"
                              :value="data.item.id">
                </b-form-radio>
            </template>
        </b-table>
        <template v-if="searchDone && !tableData.length">
            <app-no-data>
            </app-no-data>
        </template>
        <template v-if="!searchDone && !tableData.length">
            <app-search-data></app-search-data>
        </template>
        <app-pagination
            :listCount="totalRows"
            :per-page="filter.limit"
            v-model="currentPage"
            @input="getTableData(filter)"
            :last-page="lastPage">
        </app-pagination>
    </b-col>
</template>

<script>
    import {isEmpty} from "lodash";
    import {getExistingUserAccessList} from "@/services/property";
    import AppNoData from "@/components/app/AppNoData";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import AppSearchData from "@/components/app/AppSearchData";
    import AppPagination from "@/components/app/filter/AppPagination";

    export default {
        name: "UserAccessTable",
        mixins: [getErrorMessage],
        components: {
            AppPagination,
            AppSearchData,
            AppNoData,
            AppButton
        },
        data() {
            return {
                tableData: [],
                totalRows: 0,
                lastPage: 0,
                searchDone: false,
                isBusy: false,
                fields: [
                    {key: 'first_name', label: this.$t('FIRST_NAME')},
                    {key: 'last_name', label: this.$t('LAST_NAME')},
                    {key: 'username', label: this.$t('USERNAME')},
                    {key: 'radio_buttons', label: ''}
                ],
                selected: null,
                userData: null
            }
        },
        computed: {
            currentPage: {
                get() {
                    return this.filter.page
                },
                set(value) {
                    this.filter.page = value
                }
            }
        },
        props: {
            filter: {
                type: Object,
                default: {}
            },
            userUnit: {
                type: Array,
                default: null
            }
        },
        watch: {
            filter: {
                handler(value) {
                    if (isEmpty(value)) {
                        this.tableData = []
                        this.totalRows = 0
                        this.lastPage = 0
                        this.searchDone = false
                        this.$emit('isLoading', false)
                    } else {
                        this.getTableData(value)
                    }
                    this.selected = null
                    this.userData = null
                }
            },
            userUnit: {
                handler(value) {
                    if (value) {
                        if (value.length) {
                            this.tableData = value
                            this.selected = value[0].id
                            this.userData = this.tableData[0]
                            this.totalRows = 1
                            this.lastPage = 1
                            this.$emit('getUserData', this.userData)
                        }
                    } else {
                        this.tableData = []
                        this.selected = null
                    }
                },
                immediate: true
            },
            selected: {
                handler(value) {
                    this.userData = this.tableData.find(x => x.id === value)
                    this.$emit('getUserData', this.userData)
                }
            }
        },
        methods: {
            getTableData(filter) {
                this.isBusy = true
                getExistingUserAccessList(this.$route.params.id, filter).then(res => {
                    this.tableData = res.data.items
                    this.totalRows = res.data.total
                    this.lastPage = res.data.last_page
                    this.searchDone = true
                    this.selected = null

                    if (res.data.items.length === 1) {
                        this.userData = res.data.items[0]
                        this.selected = res.data.items[0].id
                        this.$emit('getUserData', this.userData)
                    }
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.isBusy = false
                    this.$emit('isLoading', false)
                })
            }
        },
        beforeDestroy() {
            this.$emit('destroy', this.user_id)
        }
    }
</script>

<style scoped>

</style>
