<template>
    <div v-if="!loading">
        <template v-if="units.length">
            <b-row>
                <b-col class="mb-4">
                    <app-button :c_permission="C_PROPERTY_USER_ACCESS_USER_NEW"
                        variant="primary" button_type="new"
                        @click="existingUserAccessSidebarState=true"
                        class="pull-right">{{$t('ADD_EXISTING_USER')}}
                    </app-button>
                    <app-button :c_permission="C_PROPERTY_USER_ACCESS_PROPERTY_MANAGER_NEW"
                        variant="primary" button_type="new"
                        @click="newUser([35, 40, 42])"
                        class="pull-right mr-3">{{$t('ADD_NEW_ACCOMMODATION_MANAGER')}}
                    </app-button>
                    <app-button :c_permission="C_PROPERTY_USER_ACCESS_OWNER_NEW"
                        variant="primary" button_type="new"
                        @click="newUser([34])"
                        class="pull-right mr-3">{{$t('ADD_NEW_ACCOMMODATION_OWNER')}}
                    </app-button>
                </b-col>
            </b-row>
            <user-access-list :userData="userData">
            </user-access-list>
            <app-aside v-model="existingUserAccessSidebarState">
                <template slot="header">{{$t("ADD_EXISTING_USER")}}</template>
                <existing-user-access-form
                    :user="user"
                    @addExistingUser="addExistingUser"
                    @destroy="user=null">
                </existing-user-access-form>
            </app-aside>
            <app-aside v-model="addUserAccessSidebarState">
                <template v-if="roles.includes(35) && !roles.includes(34)"
                          slot="header">
                    {{$t("ADD_NEW_ACCOMMODATION_MANAGER")}}
                </template>
                <template v-if="roles.includes(34) && !roles.includes(35)"
                          slot="header">
                    {{$t("ADD_NEW_ACCOMMODATION_OWNER")}}
                </template>
                <user-new-form :roles="roles"
                               @addUser="addUser">
                </user-new-form>
            </app-aside>
        </template>
        <template v-else>
            <app-no-data  :heading="$t('MODULE_NOT_ACTIVE')" :tip="$t('MODULE_NEED_UNITS')" :show-tip="true">
                <app-button button_type="new">
                    {{ $t('NEW_UNIT') }}
                </app-button>
            </app-no-data>
        </template>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import UserAccessList from "@/components/property/UserAccess/UserAccessList";
    import AppNoData from "@/components/app/AppNoData";
    import AppAside from "@/components/app/form/AppAside";
    import UserNewForm from "@/components/user/new/UserNewForm";
    import ExistingUserAccessForm from "@/components/property/UserAccess/ExistingUserAccessForm";
    import {getUnitList} from "@/services/unit";
    import {
        C_PROPERTY_USER_ACCESS_OWNER_NEW,
        C_PROPERTY_USER_ACCESS_PROPERTY_MANAGER_NEW,
        C_PROPERTY_USER_ACCESS_USER_NEW
    } from "@/shared/component_permission";

    export default {
        name: "Index",
        components: {
            AppButton,
            UserAccessList,
            AppNoData,
            AppAside,
            UserNewForm,
            ExistingUserAccessForm
        },
        data() {
            return {
                units: [],
                loading: true,
                existingUserAccessSidebarState: false,
                addUserAccessSidebarState: false,
                roles: [],
                userData: null,
                user: null,
                C_PROPERTY_USER_ACCESS_OWNER_NEW,
                C_PROPERTY_USER_ACCESS_PROPERTY_MANAGER_NEW,
                C_PROPERTY_USER_ACCESS_USER_NEW
            }
        },
        watch: {
            '$route.params.id': {
                handler(value) {
                    this.getUnits(value)
                },
                immediate: true
            }
        },
        methods: {
            getUnits(id) {
                getUnitList({property_id: id}).then(res => {
                    this.units = res.data.items
                    this.loading = false
                })
            },
            newUser(roles) {
                this.roles = roles
                this.addUserAccessSidebarState = true
            },
            addExistingUser(data) {
                this.userData = data
                this.existingUserAccessSidebarState = false
            },
            addUser(data) {
                this.user = data
                this.addUserAccessSidebarState = false
                this.existingUserAccessSidebarState = true
            }
        }
    }
</script>

<style scoped>

</style>
