<template>
    <div>
        <template v-if="isContigent">
            <div class="availability_row availability_calendar--background_gray">
                <property-calendar-sidebar-contigent-item-test :showPropertyItem="showPropertyItem" :display="display"
                                                               :selected-units="selectedUnits" :property="property"
                                                               :dateRange="dateRange" @show="showContingent"
                                                               :show-platform-fee-disclaimer="showPlatformFeeDisclaimer"
                                                               :unit="unit">
                </property-calendar-sidebar-contigent-item-test>

            </div>

            <div v-show="showContigent" class="availability_row " v-if="unit.show">
                <property-calendar-sidebar-item-test :showPropertyItem="showPropertyItem" :display="display"
                                                     :selected-units="selectedUnits" :property="property"
                                                     :dateRange="dateRange" :unit="unit" :contingent_item="true"
                                                     :show-platform-fee-disclaimer="showPlatformFeeDisclaimer">
                </property-calendar-sidebar-item-test>

            </div>
            <div v-show="showContigent" class="availability_row" :key="contigentUnit.id+'_cs'" v-for="contigentUnit in unit.contigent">
                <property-calendar-sidebar-item-test :showPropertyItem="showPropertyItem" :display="display"
                                                     :selected-units="selectedUnits" :property="property"
                                                     :dateRange="dateRange"
                                                     :unit="contigentUnit"
                                                     :contingent_item="true">
                </property-calendar-sidebar-item-test>

            </div>
        </template>
        <template v-else>
            <div class="availability_row availability_calendar--background_gray">
                <property-calendar-sidebar-item-test :showPropertyItem="showPropertyItem" :display="display"
                                                     :selected-units="selectedUnits" :property="property"
                                                     :dateRange="dateRange" :unit="unit"
                                                     :show-platform-fee-disclaimer="showPlatformFeeDisclaimer">
                </property-calendar-sidebar-item-test>
            </div>
        </template>
    </div>
</template>

<script>
import PropertyCalendarSidebarItemTest from "@/components/property/calendar/PropertyCalendarSidebarItemTest";
import PropertyCalendarSidebarContigentItemTest
    from "@/components/property/calendar/PropertyCalendarSidebarContigentItemTest";

export default {
    name: "PropertySideBarUnitItemTest",
    components: {
        PropertyCalendarSidebarContigentItemTest,
        PropertyCalendarSidebarItemTest,
    },
    props: {
        display:{
            type:Number,
            default:0
        },
        selectedUnits:{
            type:Array,
            default(){
                return []
            }
        },
        unit: {
            type: Object
        },
        dates: {
            type: Array
        },
        scrollLeftPosition: {
            type: Number,
            default: 0
        },
        shiftKeyValue: {
            type: Boolean,
            default: false
        },
        dateRange:{
            type:Object,
        },
        property:{
            type:Object
        },
        showPropertyItem:{
            type:Boolean,
            default:true
        },
        showPlatformFeeDisclaimer: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showContigent: false,
            dragPeriod: {
                start: null,
                end: null,
            }
        }
    },
    computed: {
        isContigent() {
            return Object.keys(this.unit.contigent).length > 0
        }
    },
    methods:{
        showContingent(value){
            this.showContigent = value
        }
    },
    watch: {
        showContigent: {
            handler(value) {
                this.$emit('showContigent', {value, id: this.unit.id})
            },
        }
    }
}
</script>

<style scoped>

</style>
