<template>
    <div v-cloak class="no-data-component" v-if="contact">
        <i :class="icon" aria-hidden="true"></i>
        <h3>{{$t('CONTACT_ADMINISTRATOR')}}</h3>
        <h6>{{$t('ADDITIONAL_SETUP_REQUIRED_TO_LOGIN')}}</h6>
        <h6 v-if="contact.email">{{$t('EMAIL')}}: {{contact.email}}</h6>
        <h6 v-if="contact.phone">{{$t('PHONE_MOBILE')}}: {{contact.phone}}</h6>
        <h6 v-if="contact.fax">Fax: {{contact.fax}}</h6>
        <div>
            <app-button @click="$emit('logout')">
                {{$t('LOGOUT')}}
            </app-button>
        </div>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";

    export default {
        name: "AdditionalSetupDisclaimer",
        components: {
            AppButton
        },
        props: {
            contact: {
                type: Object,
                default() {
                    return {
                        email: null,
                        phone: null,
                        fax: null
                    }
                }
            }
        },
        data() {
            return {
                icon: `fa fa-info-circle fa-fw fa-5x`
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../assets/scss/variables";

    .no-data-component {
        margin-top: 2rem;
        text-align: center;

        & i {
            margin-bottom: 1.5rem;
            font-weight: 600;
            color: $theme_color_primary;
        }

        & h3 {
            font-weight: 400;
            margin-bottom: 1rem;
        }

        & h6 {
            color: $theme_color_primary;
            font-weight: 400;
            margin-bottom: 1rem;
        }
    }
</style>

