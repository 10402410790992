<template>

    <div>
        <b-form @submit.prevent="save">
            <b-row>
                <b-col md="6" lg="4" class="mb-4">
                    <label>{{$t('RESERVATION_PERIOD')}}</label>
                    <b-form-input disabled
                                  :value="reservationPeriod"
                                  type="text">
                    </b-form-input>
                </b-col>
                <b-col md="6" lg="4" class="mb-4">
                    <label>{{$t('NUM_GUESTS')}}</label>
                    <b-form-input disabled
                                  :value="guest.reservation_data.num_guest"
                                  type="text">
                    </b-form-input>
                </b-col>
            </b-row>
            <b-row v-if="guest.hasOwnProperty('id')">
                <b-col cols="12">
                    <form-header>
                        {{$t('REGISTER_STATUS')}}
                        <b-badge class="ml-3" v-if="guest.registered" variant="primary">{{$t('REGISTERED')}}
                        </b-badge>
                        <b-badge class="ml-3" v-else variant="danger">{{$t('NOT_REGISTERED')}}
                        </b-badge>
                    </form-header>
                </b-col>
            </b-row>
            <b-row>

                <b-col cols="12">
                    <form-header>{{$t('GUEST_DETAILS')}}</form-header>
                </b-col>


                <b-col md="6" lg="3" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.first_name">
                        <template v-slot:input="data">
                            <label>{{$t('FIRST_NAME')}}</label>
                            <b-form-input type="text" v-model="guest.first_name" :state="data.state"></b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" lg="3" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.last_name">
                        <template v-slot:input="data">
                            <label>{{$t('LAST_NAME')}}</label>
                            <b-form-input type="text" v-model="guest.last_name" :state="data.state">
                            </b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" lg="3" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.gender">
                        <template v-slot:input="data">
                            <label>{{$t('GENDER')}}</label>
                            <app-select v-model="guest.gender" :options="formData.gender_options"
                                        :state="data.state"></app-select>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" lg="3" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.date_of_birth">
                        <template v-slot:input="data">
                            <label>{{$t('DATE_OF_BIRTH')}}</label>
                            <app-date-picker v-model="guest.date_of_birth" :state="data.state"></app-date-picker>
                        </template>
                    </app-input-control>

                </b-col>
                <b-col md="6" lg="3" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.document_type">
                        <template v-slot:input="data">
                            <label>{{$t('DOCUMENT_TYPE')}}</label>
                            <app-select v-model="guest.document_type" :options="formData.document_type"
                                        :state="data.state"></app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" lg="3" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.document_number">
                        <template v-slot:input="data">
                            <label>{{$t('DOCUMENT_NUMBER')}}</label>
                            <b-form-input type="text" v-model="guest.document_number" :state="data.state">
                            </b-form-input>
                        </template>
                    </app-input-control>

                </b-col>

                <b-col md="6" lg="3" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.country_of_residence">
                        <template v-slot:input="data">
                            <label>{{$t('COUNTRY_OF_RESIDENCE')}}</label>
                            <app-select v-model="guest.country_of_residence" :options="formData.country"
                                        :search-on-create="true" :state="data.state"></app-select>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" lg="3" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.payment_category">
                        <template v-slot:input="data">
                            <label>{{$t('TOURIST_TAX_PAYMENT_CATEGORY')}}</label>
                            <app-select v-model="guest.payment_category" :options="formData.payment_category"
                                        :state="data.state"></app-select>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <b-row>

                <b-col md="6" lg="3" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.check_in_date">
                        <template v-slot:input="data">
                            <label>{{$t('CHECKIN_DATE')}}</label>
                            <app-date-picker v-model="guest.check_in_date" :state="data.state"></app-date-picker>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" lg="3" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.check_out_date">
                        <template v-slot:input="data">
                            <label>{{$t('CHECKOUT_DATE')}}</label>
                            <app-date-picker v-model="guest.check_out_date" :state="data.state"></app-date-picker>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" lg="3" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.check_in_time">
                        <template v-slot:input="data">
                            <label>{{$t('CHECKIN_TIME')}}</label>
                            <time-picker v-model="guest.check_in_time" :state="data.state"></time-picker>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" lg="3" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.check_out_time">
                        <template v-slot:input="data">
                            <label>{{$t('CHECKOUT_TIME')}}</label>
                            <time-picker v-model="guest.check_out_time" :state="data.state"></time-picker>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row v-if="guestImages.length">
                <b-col cols="12">
                    <form-header>{{$t('DOCUMENT_PHOTOS')}}</form-header>
                </b-col>
                <guest-photo
                    :key="img.id" v-for="(img, imgIndex) in guestObject.images"
                    :item="img"
                    @imageClicked="index=imgIndex"
                    @removeDocument="deletePhoto(img.id)">
                </guest-photo>
                <gallery :images="guestImages" :index="index" @close="index = null">
                    <template #close>
                        <i class="fa fa-close" style="color: white"></i>
                    </template>
                </gallery>

            </b-row>
            <b-col>
                <b-row>
                    <app-button :loading="checkin_loading" @click="register" v-if="!guest.registered" class="mr-3">
                        {{$t('REGISTER_GUEST')}}
                    </app-button>
                    <app-button-submit :loading="save_loading">{{$t('SAVE')}}</app-button-submit>
                    <b-col v-if="edit_guest || this.guestObject.id !== null">
                        <delete-button class="pull-right" @click="$emit('showDeleteDialog', true)"></delete-button>
                    </b-col>

                </b-row>
            </b-col>
            <delete-dialog :show="showDeleteDialog" @confirm="deleteGuest"
                           @cancel="$emit('showDeleteDialog', false)"  :delete_title="true"></delete-dialog>
        </b-form>

    </div>


</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit"
    import AppButton from "@/components/app/AppButton/AppButton"
    import TimePicker from "@/components/app/datetime/TimePicker"
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {AjpesSlo} from "@/shared/constants";
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import DeleteButton from '@/components/app/AppButton/AppButtonDelete'
    import DeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import AppDatePicker from "@/components/app/datetime/AppDatePicker";
    import moment from 'moment'
    import {
        GUEST_POST_FIRST_NAME_VALIDATION_FAILED,
        GUEST_POST_LAST_NAME_VALIDATION_FAILED,
        GUEST_POST_RESERVATION_VALIDATION_FAILED,
        GUEST_POST_GENDER_VALIDATION_FAILED,
        GUEST_POST_EMAIL_VALIDATION_FAILED,
        GUEST_POST_CHECK_IN_DATE_VALIDATION_FAILED,
        GUEST_POST_CHECK_OUT_DATE_VALIDATION_FAILED,
        GUEST_POST_CHECK_IN_TIME_VALIDATION_FAILED,
        GUEST_POST_CHECK_OUT_TIME_VALIDATION_FAILED,
        GUEST_POST_DATE_OF_BIRTH_VALIDATION_FAILED,
        GUEST_POST_COUNTRY_OF_BIRTH_VALIDATION_FAILED,
        GUEST_POST_COUNTRY_OF_RESIDENCE_VALIDATION_FAILED,
        GUEST_POST_DOCUMENT_TYPE_VALIDATION_FAILED,
        GUEST_POST_DOCUMENT_NUMBER_VALIDATION_FAILED,
        GUEST_POST_PAYMENT_CATEGORY_VALIDATION_FAILED,

        GC_CONN_AJPES_FIRSTNAME_NOT_VALID,
        GC_CONN_AJPES_GENDER_NOT_VALID,
        GC_CONN_AJPES_DATE_OF_BIRTH_NOT_VALID,
        GC_CONN_AJPES_DOCUMENT_NUMBER_NOT_VALID,
        GC_CONN_AJPES_DOCUMENT_TYPE_NOT_VALID,
        GC_CONN_AJPES_COUNTRY_OF_RESIDENCE_NOT_VALID,
        GC_CONN_AJPES_PAYMENT_CATEGORY_NOT_VALID,
        GC_CONN_AJPES_STAY_FROM_NOT_VALID,
        GC_CONN_AJPES_TIME_STAY_FROM_NOT_VALID,

    } from "@/shared/error_codes";
    import AppInputControl from "@/components/app/AppInputControl";
    import {cloneDeep} from 'lodash'
    import FormHeader from "@/components/app/form/FormHeader";
    import VueGallery from 'vue-gallery';
    import GuestPhoto from "@/components/guest/forms/GuestPhoto";


    export default {
        name: "guest-form-ajpes",
        props: {
            guestObject: {
                type: Object
            },
            formData: {
                type: Object
            },
            save_loading: {
                type: Boolean,
                default: false
            },
            checkin_loading: {
                type: Boolean,
                default: false
            },
            edit_guest:{
                type:Boolean,
                default:true
            },
            showDeleteDialog:{
                type:Boolean,
                default:true
            }
        },
        mixins: [getErrorMessage],
        data() {
            return {
                guest: {},
                index: null,
                validationErrorObject: {
                    first_name: [GUEST_POST_FIRST_NAME_VALIDATION_FAILED,GC_CONN_AJPES_FIRSTNAME_NOT_VALID],
                    last_name: GUEST_POST_LAST_NAME_VALIDATION_FAILED,
                    reservation: GUEST_POST_RESERVATION_VALIDATION_FAILED,
                    gender: [GUEST_POST_GENDER_VALIDATION_FAILED,GC_CONN_AJPES_GENDER_NOT_VALID],
                    email: GUEST_POST_EMAIL_VALIDATION_FAILED,
                    date_of_birth: [GUEST_POST_DATE_OF_BIRTH_VALIDATION_FAILED,GC_CONN_AJPES_DATE_OF_BIRTH_NOT_VALID],
                    document_type: [GUEST_POST_DOCUMENT_TYPE_VALIDATION_FAILED,GC_CONN_AJPES_DOCUMENT_TYPE_NOT_VALID],
                    document_number: [GUEST_POST_DOCUMENT_NUMBER_VALIDATION_FAILED, GC_CONN_AJPES_DOCUMENT_NUMBER_NOT_VALID],
                    payment_category: GC_CONN_AJPES_PAYMENT_CATEGORY_NOT_VALID,
                    check_in_date: [GUEST_POST_CHECK_IN_DATE_VALIDATION_FAILED,GC_CONN_AJPES_STAY_FROM_NOT_VALID],
                    check_in_time: [GUEST_POST_CHECK_IN_TIME_VALIDATION_FAILED,GC_CONN_AJPES_TIME_STAY_FROM_NOT_VALID],
                    check_out_date: GUEST_POST_CHECK_OUT_DATE_VALIDATION_FAILED,
                    check_out_time: GUEST_POST_CHECK_OUT_TIME_VALIDATION_FAILED,
                    country_of_birth: GUEST_POST_COUNTRY_OF_BIRTH_VALIDATION_FAILED,
                    country_of_residence: [GUEST_POST_COUNTRY_OF_RESIDENCE_VALIDATION_FAILED,GC_CONN_AJPES_COUNTRY_OF_RESIDENCE_NOT_VALID]
                },
            }
        },
        components: {
            FormHeader,
            AppInputControl,
            AppDatePicker,
            AppButtonSubmit, TimePicker, AppSelect, AppButton, DeleteButton, DeleteDialog,
            'gallery': VueGallery,
            GuestPhoto
        },
        computed: {
            reservationPeriod() {
                return this.formatDate(this.guest.reservation_data.reservation_check_in_date) + ' - ' + this.formatDate(this.guest.reservation_data.reservation_check_out_date)
            },
            guestImages() {
                let arrTempImages = []
                if (this.guestObject.hasOwnProperty('images') && this.guestObject.images.length) {
                    this.guestObject.images.forEach(item => {
                        arrTempImages.push(item.link)
                    })
                }
                return arrTempImages
            }
        },
        methods: {

            save() {
                this.$emit('saveGuest', {guestObject: this.guest,  validation: this.validationErrorObject})
            },

            register() {
                this.$emit('registerGuest', {guestObject: this.guest,  validation: this.validationErrorObject})
            },
            deleteGuest(){
                this.$emit('deleteGuest', this.guestObject.id)
            },
            deletePhoto(id) {
                this.$emit('deletePhoto', id)
            },
            formatDate(value, year = true) {

                let format = (year) ? this.$t('DATE.DATE_FORMAT') : this.$t('DATE.DATE_FORMAT_SANS_YEAR')

                if (format === null) {
                    format = "YYYY-MM-DD"
                }
                return moment(String(value)).format(format)
            },
        },
        watch: {
            guestObject: {
                handler(object) {
                    this.guest = cloneDeep(object)
                    this.guest.connection = AjpesSlo
                },
                immediate: true
            },
            'guest.country_of_residence': {
                handler(value) {
                    this.guest.country_of_birth = value
                    this.guest.citizenship = value
                }
            }
        }
    }
</script>

<style scoped>

</style>

