<template>
    <h5 class="repeater-header"><slot></slot></h5>
</template>

<script>
    export default {
        name: "RepeaterHeader"
    }
</script>

<style scoped>

</style>
