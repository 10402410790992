<template>
    <form
        v-if="importData && dataLoaded && importData.hasOwnProperty('unit_list') && Array.isArray(importData.unit_list) && importData.unit_list.length > 0"
        autocomplete="off">
        <template
            v-if="distribution === BOOKING || importData.import_mode === 'units_of_existing_property' || importData.unit_list.length === 1">
            <b-row>

                <b-col md="6" lg="4" class="mb-4">
                    <app-input-control :error-object="validationObject.property_name"
                                       :listening-for-event="listenForError">
                        <template v-slot:input="data">
                            <label>
                                {{ $t("NAME") }}
                            </label>
                            <b-form-input
                                v-model="formData.property.name"
                                :disabled="importData.existing_property"
                                :state="data.state">
                            </b-form-input>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" lg="4" xl="2" class="mb-4">
                    <app-input-control :error-object="validationObject.property_type"
                                       :listening-for-event="listenForError">
                        <template v-slot:input="data">
                            <label>
                                {{ $t("TYPE") }}
                            </label>
                            <app-select
                                v-model="formData.property.type"
                                :disabled="importData.existing_property"
                                :options="propertyTypeList"
                                text-field="label"
                                :state="data.state">
                            </app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" lg="4" xl="2" class="mb-4">
                    <app-input-control :error-object="validationObject.categorization"
                                       :listening-for-event="listenForError">
                        <template v-slot:input="data">
                            <label>
                                {{ $t("CATEGORIZATION") }}
                            </label>
                            <app-select
                                v-model="formData.property.categorization"
                                :disabled="importData.existing_property"
                                :options="categorizationList"
                                text-field="text"
                                value-field="value"
                                :state="data.state">
                            </app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <template v-if="!formData.property.owner.existing">
                    <b-col md="6" lg="4" class="mb-4">
                        <app-input-control :error-object="validationObject.owner_name"
                                           :listening-for-event="listenForError">
                            <template v-slot:input="data">
                                <label>{{ $t("OWNER_NAME") }}</label>
                                <b-input
                                    v-model="formData.property.owner.name"
                                    :disabled="importData.existing_property"
                                    :state="data.state">
                                </b-input>
                            </template>
                        </app-input-control>
                    </b-col>

                    <b-col md="6" lg="4" class="mb-4">
                        <app-input-control :error-object="validationObject.owner_email"
                                           :listening-for-event="listenForError">
                            <template v-slot:input="data">
                                <label>{{ $t("EMAIL") }}</label>
                                <b-input
                                    v-model="formData.property.owner.email"
                                    :disabled="importData.existing_property"
                                    :state="data.state">
                                </b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </template>

                <b-col md="6" lg="4" xl="2" class="mb-4">
                    <app-input-control :error-object="validationObject.property_units_number"
                                       :listening-for-event="listenForError">
                        <template v-slot:input="data">
                            <label>{{ $t("UNIT_NUMBER") }}</label>
                            <b-form-input
                                type="number"
                                v-model="formData.property.units_number"
                                :state="data.state">
                            </b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <template v-if="importData.hasOwnProperty('existing_property') && !importData.existing_property">
                <b-row>
                    <b-col>
                        <form-header>{{ $t("LOCATION") }}</form-header>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4" class="mb-4">
                        <app-input-control :error-object="validationObject.address"
                                           :listening-for-event="listenForError">
                            <template v-slot:input="data">
                                <label>{{ $t("ADDRESS") }}</label>
                                <b-form-input type="text"
                                              v-model="formData.property.location.address"
                                              :disabled="importData.existing_property"
                                              :state="data.state">
                                </b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>

                    <b-col md="2" class="mb-4">
                        <app-input-control :error-object="validationObject.address_number"
                                           :listening-for-event="listenForError">
                            <template v-slot:input="data">
                                <label>{{ $t("STREET_NUMBER") }}</label>
                                <b-form-input type="text"
                                              :disabled="importData.existing_property"
                                              v-model="formData.property.location.address_number"
                                              :state="data.state">
                                </b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="2" class="mb-4">
                        <app-input-control :error-object="validationObject.postal_code"
                                           :listening-for-event="listenForError">
                            <template v-slot:input="data">
                                <label>{{ $t("ZIP") }}</label>
                                <b-form-input type="text"
                                              :disabled="importData.existing_property"
                                              v-model="formData.property.location.postal_code"
                                              :state="data.state">
                                </b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="6" lg="4" class="mb-4">
                        <app-input-control :error-object="validationObject.city"
                                           :listening-for-event="listenForError">
                            <template v-slot:input="data">
                                <label>{{ $t("CITY") }}</label>
                                <app-select :state="data.state"
                                            :disabled="importData.existing_property"
                                            mode="search"
                                            :search="searchCity"
                                            :value="formData.property.location.city"
                                            @input="setPropertyCity"
                                            :search-clear="true">
                                </app-select>
                            </template>
                        </app-input-control>

                    </b-col>
                    <b-col md="6" lg="4" class="mb-4">
                        <app-input-control :error-object="validationObject.county"
                                           :listening-for-event="listenForError">
                            <template v-slot:input="data">
                                <label>{{ $t("COUNTY") }}</label>
                                <b-input disabled
                                         :value="formData.property.location && formData.property.location.hasOwnProperty('county') && formData.property.location.county ? formData.property.location.county.name : null"
                                         :state="data.state">
                                </b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="6" lg="4" class="mb-4">
                        <app-input-control :error-object="validationObject.country"
                                           :listening-for-event="listenForError">
                            <template v-slot:input="data">
                                <label>{{ $t("COUNTRY") }}</label>
                                <b-input disabled
                                         :value="formData.property.location && formData.property.location.hasOwnProperty('country') && formData.property.location.country ? formData.property.location.country.name : null"
                                         :state="data.state">
                                </b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <form-header>{{ $t("GEOLOCATION") }}</form-header>
                    </b-col>
                </b-row>
                <b-row>

                    <b-col md="4" class="mb-4">
                        <app-input-control :error-object="validationObject.latitude"
                                           :listening-for-event="listenForError">
                            <template v-slot:input="data">
                                <label>{{ $t("LATITUDE") }}</label>
                                <b-form-input lazy
                                              v-model="formData.property.location.latitude"
                                              :disabled="importData.existing_property"
                                              :state="data.state">
                                </b-form-input>
                            </template>
                        </app-input-control>

                    </b-col>
                    <b-col md="4" class="mb-4">
                        <app-input-control :error-object="validationObject.longitude"
                                           :listening-for-event="listenForError">
                            <template v-slot:input="data">
                                <label>{{ $t("LONGITUDE") }}</label>
                                <b-form-input lazy
                                              v-model="formData.property.location.longitude"
                                              :disabled="importData.existing_property"
                                              :state="data.state">
                                </b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
            </template>

            <b-row>
                <b-col>
                    <form-header>
                        {{ importData.unit_list.length === 1 ? $t("UNIT") : $t("UNITS") }}
                    </form-header>
                </b-col>
            </b-row>
            <template v-for="(unit, unitIndex) in importData.unit_list">
                <b-card class="repeater-1">
                    <div slot="header"
                         v-if="importData.unit_list.length > 1">
                        <b-row>
                            <b-col>
                                <repeater-header class="mb-3">
                                    <span
                                        :class="expanded[unit.external_id] ? 'icon-expanded-dropdown-down mr-2' : 'icon-collapsed-dropdown-up mr-2'"
                                        @click="toggleUnitDataSelection(unit.external_id)">
                                    </span>
                                    {{ unit.display_name }}
                                </repeater-header>
                            </b-col>
                        </b-row>
                    </div>

                    <template v-if="importData.unit_list.length === 1 || expanded[unit.external_id]">
                        <b-row>

                            <b-col md="4" class="mb-4">
                                <app-input-control
                                    :listening-for-event="listenForError"
                                    :error-object="validationObject.unit_name"
                                    v-if="importData.unit_list.length === 1">
                                    <template v-slot:input="data">
                                        <label>{{ $t("NAME") }}</label>
                                        <b-input
                                            :state="data.state"
                                            v-model="formData.units[unit.external_id].name">
                                        </b-input>
                                    </template>
                                </app-input-control>
                                <template v-else>
                                    <label>{{ $t("NAME") }}</label>
                                    <b-input
                                        v-model="formData.units[unit.external_id].name">
                                    </b-input>
                                </template>
                            </b-col>

                            <b-col md="3" lg="3" class="mb-4">
                                <app-input-control
                                    :listening-for-event="listenForError"
                                    :error-object="validationObject.unit_type"
                                    v-if="importData.unit_list.length === 1">
                                    <template v-slot:input="data">
                                        <label>{{ $t("TYPE") }}</label>
                                        <app-select
                                            :options="unitTypeList"
                                            text-field="label"
                                            :state="data.state"
                                            v-model="formData.units[unit.external_id].type">
                                        </app-select>
                                    </template>
                                </app-input-control>
                                <template v-else>
                                    <label>{{ $t("TYPE") }}</label>
                                    <app-select
                                        :options="unitTypeList"
                                        text-field="label"
                                        v-model="formData.units[unit.external_id].type">
                                    </app-select>
                                </template>
                            </b-col>

                            <b-col md="3" class="mb-4">
                                <app-input-control
                                    :listening-for-event="listenForError"
                                    :error-object="validationObject.unit_size"
                                    v-if="importData.unit_list.length === 1">
                                    <template v-slot:input="data">
                                        <label>{{ $t("SIZE") }}</label>
                                        <b-input-group>
                                            <b-form-input
                                                :state="data.state"
                                                v-model="formData.units[unit.external_id].size">
                                            </b-form-input>
                                            <b-input-group-append>
                                                <b-input-group-text>m<sup>2</sup></b-input-group-text>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </template>
                                </app-input-control>
                                <template v-else>
                                    <label>{{ $t("SIZE") }}</label>
                                    <b-input-group>
                                        <b-form-input
                                            v-model="formData.units[unit.external_id].size">
                                        </b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>m<sup>2</sup></b-input-group-text>
                                        </b-input-group-append>
                                    </b-input-group>
                                </template>
                            </b-col>

                            <b-col md="3" class="mb-4">
                                <app-input-control
                                    :listening-for-event="listenForError"
                                    :error-object="validationObject.bedrooms_count"
                                    v-if="importData.unit_list.length === 1">
                                    <template v-slot:input="data">
                                        <label>{{ $t("NUMBER_OF_BEDROOMS") }}</label>
                                        <b-input
                                            :state="data.state"
                                            v-model="formData.units[unit.external_id].bedrooms_count">
                                        </b-input>
                                    </template>
                                </app-input-control>
                                <template v-else>
                                    <label>{{ $t("NUMBER_OF_BEDROOMS") }}</label>
                                    <b-input
                                        v-model="formData.units[unit.external_id].bedrooms_count">
                                    </b-input>
                                </template>
                            </b-col>

                            <b-col md="3" class="mb-4">
                                <app-input-control
                                    :listening-for-event="listenForError"
                                    :error-object="validationObject.floor_level"
                                    v-if="importData.unit_list.length === 1">
                                    <template v-slot:input="data">
                                        <label>{{ $t("FLOOR_LEVEL") }}</label>
                                        <b-input
                                            :state="data.state"
                                            v-model="formData.units[unit.external_id].floor_level">
                                        </b-input>
                                    </template>
                                </app-input-control>
                                <template v-else>
                                    <label>{{ $t("FLOOR_LEVEL") }}</label>
                                    <b-input
                                        v-model="formData.units[unit.external_id].floor_level">
                                    </b-input>
                                </template>
                            </b-col>

                            <b-col md="3" class="mb-4" v-if="distribution === BOOKING">
                                <app-input-control
                                    :listening-for-event="listenForError"
                                    :error-object="validationObject.units_number"
                                    v-if="importData.unit_list.length === 1">
                                    <template v-slot:input="data">
                                        <label>{{ $t("NUMBER_OF_UNITS") }}</label>
                                        <b-form-input
                                            :state="data.state"
                                            v-model="formData.units[unit.external_id].units_number">
                                        </b-form-input>
                                    </template>
                                </app-input-control>
                                <template v-else>
                                    <label>{{ $t("NUMBER_OF_UNITS") }}</label>
                                    <b-form-input
                                        v-model="formData.units[unit.external_id].units_number">
                                    </b-form-input>
                                </template>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="3" class="mb-4">
                                <app-input-control
                                    :listening-for-event="listenForError"
                                    :error-object="validationObject.max_adults"
                                    v-if="importData.unit_list.length === 1">
                                    <template v-slot:input="data">
                                        <label>{{ $t("MAX_ADULTS") }}</label>
                                        <b-form-input
                                            :state="data.state"
                                            :min="1"
                                            v-model="formData.units[unit.external_id].max_adults">
                                        </b-form-input>
                                    </template>
                                </app-input-control>
                                <template v-else>
                                    <label>{{ $t("MAX_ADULTS") }}</label>
                                    <b-form-input
                                        v-model="formData.units[unit.external_id].max_adults">
                                    </b-form-input>
                                </template>
                            </b-col>

                            <b-col md="3" class="mb-4">
                                <app-input-control
                                    :listening-for-event="listenForError"
                                    :error-object="validationObject.max_children"
                                    v-if="importData.unit_list.length === 1">
                                    <template v-slot:input="data">
                                        <label>{{ $t("MAX_CHILDREN") }}</label>
                                        <b-form-input
                                            :state="data.state"
                                            :min="1"
                                            v-model="formData.units[unit.external_id].max_children">
                                        </b-form-input>
                                    </template>
                                </app-input-control>
                                <template v-else>
                                    <label>{{ $t("MAX_CHILDREN") }}</label>
                                    <b-form-input
                                        v-model="formData.units[unit.external_id].max_children">
                                    </b-form-input>
                                </template>
                            </b-col>

                            <b-col md="3" class="mb-4">
                                <app-input-control
                                    :listening-for-event="listenForError"
                                    :error-object="validationObject.max_babies"
                                    v-if="importData.unit_list.length === 1">
                                    <template v-slot:input="data">
                                        <label>{{ $t("MAX_BABIES") }}</label>
                                        <b-form-input
                                            :state="data.state"
                                            :min="1"
                                            v-model="formData.units[unit.external_id].max_babies">
                                        </b-form-input>
                                    </template>
                                </app-input-control>
                                <template v-else>
                                    <label>{{ $t("MAX_BABIES") }}</label>
                                    <b-form-input
                                        v-model="formData.units[unit.external_id].max_babies">
                                    </b-form-input>
                                </template>
                            </b-col>
                        </b-row>
                    </template>
                </b-card>
            </template>
        </template>
        <template
            v-else-if="importData.import_mode"
            v-for="(unit, unitIndex) in importData.unit_list">
            <b-card class="repeater-1">
                <div slot="header">
                    <b-row>
                        <b-col>
                            <repeater-header class="mb-4">
                                    <span
                                        :class="expanded[unit.external_id] ? 'icon-expanded-dropdown-down mr-2' : 'icon-collapsed-dropdown-up mr-2'"
                                        @click="toggleUnitDataSelection(unit.external_id)">
                                    </span>
                                {{ unit.display_name }}
                            </repeater-header>
                        </b-col>
                    </b-row>
                </div>

                <template v-if="expanded[unit.external_id]">
                    <b-row>
                        <b-col md="6" lg="4" class="mb-4">
                            <label>
                                {{ $t("NAME") }}
                            </label>
                            <b-form-input
                                v-model="formData.units[unit.external_id].property.name">
                            </b-form-input>
                        </b-col>

                        <b-col md="6" lg="4" xl="2" class="mb-4">
                            <label>
                                {{ $t("TYPE") }}
                            </label>
                            <app-select
                                v-model="formData.units[unit.external_id].property.type"
                                :options="propertyTypeList"
                                text-field="label">
                            </app-select>
                        </b-col>

                        <b-col md="6" lg="4" xl="2" class="mb-4">
                            <label>
                                {{ $t("CATEGORIZATION") }}
                            </label>
                            <app-select
                                v-model="formData.units[unit.external_id].property.categorization"
                                :options="categorizationList"
                                text-field="text"
                                value-field="value">
                            </app-select>
                        </b-col>

                        <b-col md="6" lg="4" class="mb-4">
                            <label>{{ $t("OWNER_NAME") }}</label>
                            <b-input
                                v-model="formData.units[unit.external_id].property.owner.name">
                            </b-input>
                        </b-col>


                        <b-col md="6" lg="4" class="mb-4">
                            <label>{{ $t("EMAIL") }}</label>
                            <b-input
                                v-model="formData.units[unit.external_id].property.owner.email">
                            </b-input>
                        </b-col>

                        <b-col md="6" lg="4" xl="2" class="mb-4">
                            <label>{{ $t("UNIT_NUMBER") }}</label>
                            <b-form-input
                                type="number"
                                v-model="formData.units[unit.external_id].property.units_number">
                            </b-form-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <form-header>{{ $t("LOCATION") }}</form-header>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="4" class="mb-4">
                            <label>{{ $t("ADDRESS") }}</label>
                            <b-form-input type="text"
                                          v-model="formData.units[unit.external_id].property.location.address">
                            </b-form-input>
                        </b-col>

                        <b-col md="2" class="mb-4">
                            <label>{{ $t("STREET_NUMBER") }}</label>
                            <b-form-input type="text"
                                          v-model="formData.units[unit.external_id].property.location.address_number">
                            </b-form-input>
                        </b-col>
                        <b-col md="2" class="mb-4">
                            <label>{{ $t("ZIP") }}</label>
                            <b-form-input type="text"
                                          v-model="formData.units[unit.external_id].property.location.postal_code">
                            </b-form-input>
                        </b-col>
                        <b-col md="6" lg="4" class="mb-4">
                            <label>{{ $t("CITY") }}</label>
                            <app-select mode="search"
                                        :search="searchCity"
                                        :value="formData.units[unit.external_id].property.location.city"
                                        @input="setUnitCity($event, unit.external_id)"
                                        :search-clear="true">
                            </app-select>
                        </b-col>
                        <b-col md="6" lg="4" class="mb-4">
                            <label>{{ $t("COUNTY") }}</label>
                            <b-input disabled
                                     :value="formData.units[unit.external_id].property.location && formData.units[unit.external_id].property.location.hasOwnProperty('county') && formData.units[unit.external_id].property.location.county ? formData.units[unit.external_id].property.location.county.name : null">
                            </b-input>
                        </b-col>
                        <b-col md="6" lg="4" class="mb-4">
                            <label>{{ $t("COUNTRY") }}</label>
                            <b-input disabled
                                     :value="formData.units[unit.external_id].property.location && formData.units[unit.external_id].property.location.hasOwnProperty('country') && formData.units[unit.external_id].property.location.country ? formData.units[unit.external_id].property.location.country.name : null">
                            </b-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <form-header>{{ $t("GEOLOCATION") }}</form-header>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="4" class="mb-4">
                            <label>{{ $t("LATITUDE") }}</label>
                            <b-form-input lazy
                                          v-model="formData.units[unit.external_id].property.location.latitude">
                            </b-form-input>
                        </b-col>
                        <b-col md="4" class="mb-4">
                            <label>{{ $t("LONGITUDE") }}</label>
                            <b-form-input lazy
                                          v-model="formData.units[unit.external_id].property.location.longitude">
                            </b-form-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <form-header>
                                {{ $t("UNIT") }}
                            </form-header>
                        </b-col>
                    </b-row>

                    <b-row>

                        <b-col md="6" lg="4" class="mb-4">
                            <label>{{ $t("NAME") }}</label>
                            <b-input
                                v-model="formData.units[unit.external_id].name">
                            </b-input>
                        </b-col>

                        <b-col md="6" lg="3" xl="2" class="mb-4">
                            <label>{{ $t("TYPE") }}</label>
                            <app-select
                                :options="unitTypeList"
                                text-field="label"
                                v-model="formData.units[unit.external_id].type">
                            </app-select>
                        </b-col>

                        <b-col md="6" lg="3" xl="2" class="mb-4">
                            <label>{{ $t("SIZE") }}</label>
                            <b-input-group>
                                <b-form-input
                                    v-model="formData.units[unit.external_id].size">
                                </b-form-input>
                                <b-input-group-append>
                                    <b-input-group-text>m<sup>2</sup></b-input-group-text>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>

                        <b-col md="6" lg="3" xl="2" class="mb-4">
                            <label>{{ $t("NUMBER_OF_BEDROOMS") }}</label>
                            <b-input
                                v-model="formData.units[unit.external_id].bedrooms_count">
                            </b-input>
                        </b-col>

                        <b-col md="6" lg="3" xl="2" class="mb-4">
                            <label>{{ $t("FLOOR_LEVEL") }}</label>
                            <b-input
                                v-model="formData.units[unit.external_id].floor_level">
                            </b-input>
                        </b-col>

                    </b-row>

                    <b-row>
                        <b-col md="3" class="mb-4">
                            <label>{{ $t("MAX_ADULTS") }}</label>
                            <b-form-input
                                v-model="formData.units[unit.external_id].max_adults">
                            </b-form-input>
                        </b-col>

                        <b-col md="3" class="mb-4">
                            <label>{{ $t("MAX_CHILDREN") }}</label>
                            <b-form-input
                                v-model="formData.units[unit.external_id].max_children">
                            </b-form-input>
                        </b-col>

                        <b-col md="3" class="mb-4">
                            <label>{{ $t("MAX_BABIES") }}</label>
                            <b-form-input
                                v-model="formData.units[unit.external_id].max_babies">
                            </b-form-input>
                        </b-col>
                    </b-row>
                </template>
            </b-card>
        </template>
    </form>
</template>

<script>
import {AIRBNB, BOOKING} from "@/shared/constants";
import FormHeader from "@/components/app/form/FormHeader";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppInputControl from "@/components/app/AppInputControl";
import RepeaterHeader from "@/components/app/form/RepeaterHeader";
import {getPropertyTypeList} from "@/services/property";
import {getUnitTypeList} from "@/services/marketplace";
import {dataFormatters} from "@/mixins/shared/helpers";
import {getCityMapping} from "@/services/location";
import _cloneDeep from "lodash/cloneDeep";
import {
    OWNER_VALIDATION_CONTACT_EMAIL_VALIDATION_FAIL,
    OWNER_VALIDATION_NAME_VALIDATION_FAIL,
    PROPERTY_DATA_ADDRESS_NO_VALIDATON_FAILED,
    PROPERTY_DATA_ADDRESS_VALIDATION_FAILED,
    PROPERTY_DATA_CATEGORIZATION_VALIDATION_FAILED,
    PROPERTY_DATA_CITY_VALIDATION_FAILED,
    PROPERTY_DATA_COUNTRY_VALIDATION_FAILED,
    PROPERTY_DATA_COUNTY_VALIDATION_FAILED,
    PROPERTY_DATA_FLOOR_VALIDATION_FAILED,
    PROPERTY_DATA_INVALID_LATITUDE_VALIDATION_FAILED,
    PROPERTY_DATA_INVALID_LONGITUDE_VALIDATION_FAILED,
    PROPERTY_DATA_MAX_ADULTS_VALIDATION_FAILED, PROPERTY_DATA_MAX_BABIES_VALIDATION_FAILED,
    PROPERTY_DATA_MAX_CHILDREN_VALIDATION_FAILED,
    PROPERTY_DATA_NAME_VALIDATION_FAILED,
    PROPERTY_DATA_NUMBER_BEDROOMS_VALIDATION_FAILED,
    PROPERTY_DATA_POSTAL_NUMBER_VALIDATION_FAILED,
    PROPERTY_DATA_PROPERTY_TYPE_VALIDATION_FAILED,
    PROPERTY_DATA_SIZE_VALIDATION_FAILED, PROPERTY_DATA_UNIT_NUMBER_VALIDATION_FAILED_SERVER_MESSAGE,
    PROPERTY_DATA_UNIT_TYPE_VALIDATION_FAILED,
    UNIT_DATA_NAME_VALIDATION_FAILED, UNIT_DATA_NUMBER_OF_UNIT_VALIDATION_FAILED
} from "@/shared/error_codes";

const FORM_DATA_APPLICABLE_IMPORT_DATA_KEY_LIST = [
    "import_mode",
    "existing_property",
    "property_id",
]

export default {
    name: "ImportPropertyWizardMandatoryDataForm",
    components: {RepeaterHeader, AppInputControl, AppSelect, FormHeader},
    mixins: [dataFormatters],
    data() {
        return {
            formData: {
                property: null,
                units: {},
            },
            propertyTypeList: [],
            categorizationList: [
                {value: 1, text: "1 *"},
                {value: 2, text: "2 *"},
                {value: 3, text: "3 *"},
                {value: 4, text: "4 *"},
                {value: 5, text: "5 *"},
            ],
            unitTypeList: [],
            expanded: {},
            propertyTypeListLoaded: false,
            unitTypeListLoaded: false,
            dataPrepared: false,
            BOOKING,
            AIRBNB,
            validationObject: {},
        }
    },
    props: {
        distribution: {
            type: Number,
            default: null,
        },
        importData: {
            type: Object,
        },
        listenForError: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        dataLoaded() {
            return this.propertyTypeListLoaded && this.unitTypeListLoaded && this.dataPrepared
        },
    },
    watch: {
        formData: {
            handler(value) {
                this.$emit("update", {mandatory_data: this.prepareFormData(value)})
            },
            deep: true,
            immediate: true,
        },
        importData: {
            handler(value) {
                this.dataPrepared = false
                this.expanded = {}
                this.resetFormData()
                if (value && typeof value === "object") {
                    this.updateFormData(value)
                    this.setValidationObject(value)
                }
                this.$nextTick(() => {
                    this.dataPrepared = true
                })
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        updateFormData(importData) {
            for (const [key, value] of Object.entries(importData)) {
                if (FORM_DATA_APPLICABLE_IMPORT_DATA_KEY_LIST.indexOf(key) > -1) {
                    this.$set(this.formData, key, value)
                }
            }

            if (this.distribution === BOOKING || importData.import_mode === "units_of_existing_property" || importData.unit_list.length === 1) {
                if (!this.formData.property) {
                    this.$set(this.formData, "property", {})
                    this.$set(this.formData.property, "owner", {})
                    this.$set(this.formData.property, "location", {})
                }
                if (importData.hasOwnProperty("property") && importData.property) {
                    this.$set(this.formData.property, "external_id", importData.property.external_id)
                    this.$set(this.formData.property, "name", importData.property.name)
                    this.$set(this.formData.property, "type", importData.property.type)
                    this.$set(this.formData.property, "categorization", importData.property.categorization)
                    this.$set(this.formData.property, "units_number", importData.property.units_number)
                    if (importData.property.hasOwnProperty("owner") && this.importData.property.owner) {
                        this.$set(this.formData.property.owner, "existing", importData.property.owner.existing)
                        this.$set(this.formData.property.owner, "name", importData.property.owner.name)
                        this.$set(this.formData.property.owner, "email", importData.property.owner.email)
                    }
                    if (importData.property.hasOwnProperty("location") && this.importData.property.location) {
                        this.$set(this.formData.property.location, "city", importData.property.location.city)
                        this.$set(this.formData.property.location, "country", importData.property.location.country)
                        this.$set(this.formData.property.location, "region", importData.property.location.region)
                        this.$set(this.formData.property.location, "county", importData.property.location.county)
                        this.$set(this.formData.property.location, "latitude", importData.property.location.latitude)
                        this.$set(this.formData.property.location, "longitude", importData.property.location.longitude)
                        this.$set(this.formData.property.location, "address", importData.property.location.address)
                        this.$set(this.formData.property.location, "address_number", importData.property.location.address_number)
                        this.$set(this.formData.property.location, "postal_code", importData.property.location.postal_code)
                    }
                }
            }

            if (importData.hasOwnProperty("unit_list") && Array.isArray(importData.unit_list) && importData.unit_list.length > 0) {
                importData.unit_list.forEach(unit => {
                    this.$set(this.formData.units, unit.external_id, {})
                    if (importData.import_mode === "new_property" && importData.unit_list.length > 1) {
                        this.$set(this.formData.units[unit.external_id], "property", {})
                        this.$set(this.formData.units[unit.external_id].property, "owner", {})
                        this.$set(this.formData.units[unit.external_id].property, "location", {})
                        this.$set(this.formData.units[unit.external_id].property, "units_number", 1)
                        if (unit.hasOwnProperty("location") && unit.location) {
                            this.$set(this.formData.units[unit.external_id].property.location, "city", unit.location.city)
                            this.$set(this.formData.units[unit.external_id].property.location, "country", unit.location.country)
                            this.$set(this.formData.units[unit.external_id].property.location, "region", unit.location.region)
                            this.$set(this.formData.units[unit.external_id].property.location, "county", unit.location.county)
                            this.$set(this.formData.units[unit.external_id].property.location, "latitude", unit.location.latitude)
                            this.$set(this.formData.units[unit.external_id].property.location, "longitude", unit.location.longitude)
                            this.$set(this.formData.units[unit.external_id].property.location, "address", unit.location.address)
                            this.$set(this.formData.units[unit.external_id].property.location, "address_number", unit.location.address_number)
                            this.$set(this.formData.units[unit.external_id].property.location, "postal_code", unit.location.postal_code)
                        }
                        if (unit.hasOwnProperty("property") && unit.property) {
                            this.$set(this.formData.units[unit.external_id].property, "name", unit.property.name)
                            this.$set(this.formData.units[unit.external_id].property, "type", unit.property.type)
                        }
                        if (unit.hasOwnProperty("owner") && unit.owner) {
                            this.$set(this.formData.units[unit.external_id].property.owner, "existing", unit.owner.existing)
                            this.$set(this.formData.units[unit.external_id].property.owner, "name", unit.owner.name)
                            this.$set(this.formData.units[unit.external_id].property.owner, "email", unit.owner.email)
                        }
                    } else if (importData.import_mode === "new_property" && importData.unit_list.length === 1) {
                        if (unit.hasOwnProperty("location") && unit.location) {
                            this.$set(this.formData.property.location, "city", unit.location.city)
                            this.$set(this.formData.property.location, "region", unit.location.region)
                            this.$set(this.formData.property.location, "county", unit.location.county)
                            this.$set(this.formData.property.location, "country", unit.location.country)
                            this.$set(this.formData.property.location, "latitude", unit.location.latitude)
                            this.$set(this.formData.property.location, "longitude", unit.location.longitude)
                            this.$set(this.formData.property.location, "address", unit.location.address)
                            this.$set(this.formData.property.location, "address_number", unit.location.address_number)
                            this.$set(this.formData.property.location, "postal_code", unit.location.postal_code)
                        }
                        if (unit.hasOwnProperty("property") && unit.property) {
                            this.$set(this.formData.property, "name", unit.property.name)
                            this.$set(this.formData.property, "type", unit.property.type)
                        }
                        if (unit.hasOwnProperty("owner") && unit.owner) {
                            this.$set(this.formData.property.owner, "existing", unit.owner.existing)
                            this.$set(this.formData.property.owner, "name", unit.owner.name)
                            this.$set(this.formData.property.owner, "email", unit.owner.email)
                        }
                        this.$set(this.formData.property, "units_number", 1)
                    }
                    this.$set(this.formData.units[unit.external_id], "name", unit.name)
                    this.$set(this.formData.units[unit.external_id], "type", unit.type)
                    this.$set(this.formData.units[unit.external_id], "size", unit.size)
                    this.$set(this.formData.units[unit.external_id], "bedrooms_count", unit.bedrooms_count)
                    this.$set(this.formData.units[unit.external_id], "floor_level", unit.floor_level)
                    if (this.distribution === BOOKING) {
                        this.$set(this.formData.units[unit.external_id], "units_number", unit.hasOwnProperty("units_number") ? unit.units_number : null)
                    }
                    this.$set(this.formData.units[unit.external_id], "max_adults", unit.max_adults)
                    this.$set(this.formData.units[unit.external_id], "max_children", unit.max_children)
                    this.$set(this.formData.units[unit.external_id], "max_babies", unit.max_babies)
                })
            }
        },
        setValidationObject(importData) {
            this.validationObject = {}

            if (this.distribution === BOOKING || importData.import_mode === 'units_of_existing_property' || importData.unit_list.length === 1) {
                this.$set(this.validationObject, "property_name", {input: [PROPERTY_DATA_NAME_VALIDATION_FAILED]})
                this.$set(this.validationObject, "categorization", {input: [PROPERTY_DATA_CATEGORIZATION_VALIDATION_FAILED]})
                this.$set(this.validationObject, "property_type", {input: [PROPERTY_DATA_PROPERTY_TYPE_VALIDATION_FAILED]})
                this.$set(this.validationObject, "address", {input: [PROPERTY_DATA_ADDRESS_VALIDATION_FAILED]})
                this.$set(this.validationObject, "address_number", {input: [PROPERTY_DATA_ADDRESS_NO_VALIDATON_FAILED]})
                this.$set(this.validationObject, "postal_code", {input: [PROPERTY_DATA_POSTAL_NUMBER_VALIDATION_FAILED]})
                this.$set(this.validationObject, "city", {input: [PROPERTY_DATA_CITY_VALIDATION_FAILED]})
                this.$set(this.validationObject, "county", {input: [PROPERTY_DATA_COUNTY_VALIDATION_FAILED]})
                this.$set(this.validationObject, "country", {input: [PROPERTY_DATA_COUNTRY_VALIDATION_FAILED]})
                this.$set(this.validationObject, "latitude", {input: [PROPERTY_DATA_INVALID_LATITUDE_VALIDATION_FAILED]})
                this.$set(this.validationObject, "longitude", {input: [PROPERTY_DATA_INVALID_LONGITUDE_VALIDATION_FAILED]})
                this.$set(this.validationObject, "owner_name", {input: [OWNER_VALIDATION_NAME_VALIDATION_FAIL]})
                this.$set(this.validationObject, "owner_email", {input: [OWNER_VALIDATION_CONTACT_EMAIL_VALIDATION_FAIL]})
                this.$set(this.validationObject, "property_units_number", {input: [PROPERTY_DATA_UNIT_NUMBER_VALIDATION_FAILED_SERVER_MESSAGE]})
            }
            if (importData.unit_list.length === 1) {
                this.$set(this.validationObject, "unit_name", {input: [UNIT_DATA_NAME_VALIDATION_FAILED]})
                this.$set(this.validationObject, "unit_type", {input: [PROPERTY_DATA_UNIT_TYPE_VALIDATION_FAILED]})
                this.$set(this.validationObject, "unit_size", {input: [PROPERTY_DATA_SIZE_VALIDATION_FAILED]})
                this.$set(this.validationObject, "bedrooms_count", {input: [PROPERTY_DATA_NUMBER_BEDROOMS_VALIDATION_FAILED]})
                this.$set(this.validationObject, "floor_level", {input: [PROPERTY_DATA_FLOOR_VALIDATION_FAILED]})
                this.$set(this.validationObject, "units_number", {input: [UNIT_DATA_NUMBER_OF_UNIT_VALIDATION_FAILED]})
                this.$set(this.validationObject, "max_adults", {input: [PROPERTY_DATA_MAX_ADULTS_VALIDATION_FAILED]})
                this.$set(this.validationObject, "max_children", {input: [PROPERTY_DATA_MAX_CHILDREN_VALIDATION_FAILED]})
                this.$set(this.validationObject, "max_babies", {input: [PROPERTY_DATA_MAX_BABIES_VALIDATION_FAILED]})
            }
        },
        resetFormData() {
            this.$set(this.formData, "property", null)
            this.$set(this.formData, "units", {})
        },
        toggleUnitDataSelection(unitEid) {
            if (!this.expanded.hasOwnProperty(unitEid)) {
                this.$set(this.expanded, unitEid, true)
                return
            }
            this.$set(this.expanded, unitEid, !this.expanded[unitEid])
        },
        prepareFormData(formData) {
            let formattedFormData = _cloneDeep(formData)
            if (formattedFormData.hasOwnProperty("property") && formattedFormData.property && formattedFormData.property.hasOwnProperty("location") && formattedFormData.property.location) {
                this.$set(formattedFormData.property, "location", this.formatObjectKeyNamePairs(formattedFormData.property.location, {
                        includedFields: ['city', 'country', 'county', 'region']
                    })
                )
            }

            if (formattedFormData.units) {
                for (const [unitEid, unit] of Object.entries(formattedFormData.units)) {
                    if (unit.hasOwnProperty("property") && unit.property && unit.property.hasOwnProperty("location") && unit.property.location) {
                        this.$set(formattedFormData.units[unitEid].property, "location", this.formatObjectKeyNamePairs(formattedFormData.units[unitEid].property.location, {
                                includedFields: ['city', 'country', 'county', 'region']
                            })
                        )
                    }
                }
            }

            return formattedFormData
        },
        searchCity(value) {
            return getCityMapping({
                name: value
            })
        },
        setPropertyCity(value) {
            if (value && value.hasOwnProperty("id") && value.id) {
                this.$set(this.formData.property.location, "city", value)
                if (value.hasOwnProperty("country")) {
                    this.$set(this.formData.property.location, "country", value.country)
                }
                if (value.hasOwnProperty("county")) {
                    this.$set(this.formData.property.location, "county", value.county)
                }
                if (value.hasOwnProperty("region")) {
                    this.$set(this.formData.property.location, "region", value.region)
                }
            } else if (value && value.hasOwnProperty("id") && !value.id) {
                this.$set(this.formData.property.location, "city", {id: null, name: null})
                this.$set(this.formData.property.location, "country", {id: null, name: null})
                this.$set(this.formData.property.location, "county", {id: null, name: null})
                this.$set(this.formData.property.location, "region", {id: null, name: null})
            }
        },
        setUnitCity(value, unit_eid) {
            if (value && value.hasOwnProperty("id") && value.id) {
                this.$set(this.formData.unit[unit_eid].property.location, "city", value)
                if (value.hasOwnProperty("country")) {
                    this.$set(this.formData.unit[unit_eid].property.location, "country", value.country)
                }
                if (value.hasOwnProperty("county")) {
                    this.$set(this.formData.unit[unit_eid].property.location, "county", value.county)
                }
                if (value.hasOwnProperty("region")) {
                    this.$set(this.formData.unit[unit_eid].property.location, "region", value.region)
                }
            } else if (value && value.hasOwnProperty("id") && !value.id) {
                this.$set(this.formData.unit[unit_eid].property.location, "city", {id: null, name: null})
                this.$set(this.formData.unit[unit_eid].property.location, "country", {id: null, name: null})
                this.$set(this.formData.unit[unit_eid].property.location, "county", {id: null, name: null})
                this.$set(this.formData.unit[unit_eid].property.location, "region", {id: null, name: null})
            }
        },
    },
    created() {
        getPropertyTypeList().then((response) => {
            this.propertyTypeList = response.data
        }, () => {
            this.propertyTypeList = []
        }).finally(() => {
            this.propertyTypeListLoaded = true
        })
        getUnitTypeList().then((response) => {
            this.unitTypeList = response.data
        }, () => {
            this.unitTypeList = []
        }).finally(() => {
            this.unitTypeListLoaded = true
        })
    },
}
</script>

<style scoped>
.icon-expanded-dropdown-down:before {
    font-family: "FontAwesome";
    font-size: .875rem;
    position: absolute;
    left: .5rem;
    top: 26%;
    transform: translateY(-50%);
    color: #6F6EFE;
    font-weight: normal;
    content: "\f078";
}

.icon-expanded-dropdown-down:hover:before {
    color: #5352C2;
    cursor: pointer;
}

.icon-collapsed-dropdown-up:before {
    font-family: "FontAwesome";
    font-size: .875rem;
    position: absolute;
    left: .5rem;
    top: 26%;
    transform: translateY(-50%);
    color: #6F6EFE;
    font-weight: normal;
    content: "\f054";
}

.icon-collapsed-dropdown-up:hover:before {
    color: #5352C2;
    cursor: pointer;
}
</style>
