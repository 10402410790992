<template>
    <div>
        <b-form @submit.prevent="save" v-if="formData.company">
            <b-row v-if="filteredPartnerTypeList.length">
                <b-col xl="4" md="6" class="mb-4">
                    <app-input-control :listening-for-event="listenForError" :error-object="validation.type">
                        <template v-slot:input="data">
                            <label>{{ $t('CLIENT_TYPE') }}</label>
                            <app-select :options="filteredPartnerTypeList" :search-empty-item="true" :state="data.state"
                                        text-field="label" v-model="formData.partner_type"></app-select>
                        </template>
                    </app-input-control>

                </b-col>
            </b-row>

            <b-row>
                <b-col xl="4" md="6" class="mb-4">
                    <app-input-control :listening-for-event="listenForError" :error-object="validation.name">
                        <template v-slot:input="data">
                            <label>{{ $t('FIRST_NAME') }}</label>
                            <b-input type="text" v-model="formData.name" :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col xl="4" md="6" class="mb-4">
                    <label>{{ $t('LAST_NAME') }}</label>
                    <b-input type="text" v-model="formData.last_name"></b-input>
                </b-col>

                <b-col xl="4" md="6" class="mb-4">
                    <app-input-control :listening-for-event="listenForError" :error-object="validation.email">
                        <template v-slot:input="data">
                            <label>{{ $t('EMAIL') }}</label>
                            <b-input type="text" v-model="formData.email" :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <b-row>
                <b-col xl="4" md="6" class="mb-4">
                    <label>{{ $t('COUNTRY') }}</label>
                    <app-select mode="search" :search="searchCountry" v-model="country"
                                :search-clear="true"></app-select>
                </b-col>

                <b-col xl="4" md="6" class="mb-4">
                    <app-input-control :listening-for-event="listenForError" :error-object="validation.city">
                        <template v-slot:input="data">
                            <label>{{ $t('CITY') }}</label>
                            <app-select mode="search" :search="searchCity" v-model="city" text-field="name"
                                        :state="data.state"
                                        :search-clear="true">
                            </app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col xl="4" md="6" class="mb-4">
                    <app-input-control :listening-for-event="listenForError" :error-object="validation.address">
                        <template v-slot:input="data">
                            <label>{{ $t('ADDRESS') }}</label>
                            <b-input type="text" v-model="formData.address" :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <b-row>
                <b-col xl="4" md="6" class="mb-4">
                    <app-input-control :listening-for-event="listenForError" :error-object="validation.oib">
                        <template v-slot:input="data">
                            <label>{{$t("PERSONAL_ID_NUMBER")}}</label>
                            <b-input type="text" v-model="formData.oib" :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col xl="4" md="6" class="mb-4">
                    <app-input-control :listening-for-event="listenForError" :error-object="validation.vat">
                        <template v-slot:input="data">
                            <label>{{ $t('VAT_NUMBER') }}</label>
                            <b-input type="text" v-model="formData.vat_number" :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col xl="4" md="6" class="mb-4">
                    <app-input-control :listening-for-event="listenForError" :error-object="validation.iban">
                        <template v-slot:input="data">
                            <label>{{$t('IBAN')}}</label>
                            <b-input type="text" v-model="formData.iban" :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </b-col>

                <app-access-control xl="4" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter"
                                    :access_control_context="{key: access_control_fetch_key, function: C_SETUP_FINANCE_PARTNERS_FIELDS_EID}">
                    <label>{{$t('EXTERNAL_ID')}}</label>
                    <b-input type="text" v-model="formData.eid"></b-input>
                </app-access-control>
            </b-row>

            <template v-if="!hasInvoiceeType || !hasEntity">
                <form-header>{{$t('ENTITY_MAPPING')}}</form-header>

                <b-row>
                    <b-col xl="4" mb="6" class="mb-4" v-if="!hasInvoiceeType">
                        <label>{{$t('ENTITY_TYPE')}}</label>
                        <app-select :options="invoiceeTypeList"
                                    v-model="formData.invoicee_type"
                                    :search-empty-item="false" text-field="label">
                        </app-select>
                    </b-col>

                    <b-col xl="4" mb="6" class="mb-4" v-if="!hasEntity">
                        <label>{{$t('ENTITY')}}</label>
                        <app-select v-if="!formData.invoicee_type" disabled></app-select>
                        <app-select v-else mode="search"
                                    :search="invoiceeFunctionMap[formData.invoicee_type]._function"
                                    :search-on-create="searchOnCreateInvoiceeTypeList.includes(formData.invoicee_type)"
                                    v-model="entity"
                                    :key="entityKey">
                            <template v-slot:text="data">
                                <template v-if="data.item">
                                    {{invoiceeFunctionMap[formData.invoicee_type].text(data.item)}}
                                </template>
                            </template>
                        </app-select>
                    </b-col>
                </b-row>
            </template>

            <b-row>
                <b-col class="mb-4">
                    <app-button-submit :loading="saving"></app-button-submit>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {createPartner, fetchPartner, getInvoiceeTypeList, updatePartner} from "@/services/finance/partner";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getCityList, getCountries} from "@/services/location";
    import {getPartnerTypeList} from "@/services/finance/partner";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {
        ICL_RESERVATION_PREVIEW,
        INVOICEE_TYPE_BT_OWNER, INVOICEE_TYPE_CHANNEL, INVOICEE_TYPE_DISTRIBUTION,
        INVOICEE_TYPE_GUEST,
        INVOICEE_TYPE_OWNER, WORKFLOW_BOOKER_TOOLS
    } from "@/shared/constants";
    import {
        IN_CLIENT_ADDRESS, IN_CLIENT_CITY,
        IN_CLIENT_EMAIL, IN_CLIENT_IBAN,
        IN_CLIENT_NAME,
        IN_CLIENT_OIB, IN_CLIENT_TYPE,
        IN_CLIENT_VAT
    } from "@/shared/error_codes";
    import AppInputControl from "@/components/app/AppInputControl";
    import {cloneDeep} from "lodash";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {C_SETUP_FINANCE_PARTNERS_FIELDS_EID} from "@/shared/component_permission";
    import {AC_PARTNER} from "@/mixins/AccessControl/AccessControlEnumeration";
    import FormHeader from "@/components/app/form/FormHeader";
    import {getOwnerList} from "@/services/owner";
    import {getUserList} from "@/services/user";
    import {getChannelList, getDistributionList} from "@/services/distribution";
    import {getCompanyList} from "@/services/company";
    import isObject from 'lodash/isObject'


    const btInvoiceeList = [INVOICEE_TYPE_BT_OWNER, INVOICEE_TYPE_DISTRIBUTION]

    export default {
        name: "PartnerForm",
        components: {
            FormHeader,
            AppAccessControl,
            AppInputControl, AppSelect, AppConfirmationDialog, AppButtonDelete, AppButtonSubmit
        },
        mixins: [getErrorMessage, AccessControlComponent],
        props: {
            partnerObject: {
                type: Object,
                required: true
            },
            invoiceeTypeId: {
                type: Number,
                default: null
            },
            creationLocation: {
                type: Number,
                default: null
            },
            contextObject: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            availablePartnerTypes: {
                type: Array,
                default: () => []
            },
            primaryPartnerType: {
                type: Number,
            }
        },
        data() {
            return {
                city: {
                    id: null,
                    name: null
                },
                country: {
                    id: null,
                    name: null
                },
                partnerTypeList: [],
                formData: {partner_type: null, invoicee_type: null},
                saving: false,
                validation: {
                    name: IN_CLIENT_NAME,
                    email: IN_CLIENT_EMAIL,
                    oib: IN_CLIENT_OIB,
                    vat: IN_CLIENT_VAT,
                    address: IN_CLIENT_ADDRESS,
                    city: IN_CLIENT_CITY,
                    type: IN_CLIENT_TYPE,
                    iban: IN_CLIENT_IBAN,
                },
                listenForError: false,
                C_SETUP_FINANCE_PARTNERS_FIELDS_EID,
                access_control_fetch_key: AC_PARTNER,
                access_control_components: 1,
                access_control_general_context: null,
                invoiceeTypeList: [],
                entityKey: 1,
                entity: {},
                hasInvoiceeType: true,
                hasEntity: true,
                searchOnCreateInvoiceeTypeList: [
                    INVOICEE_TYPE_DISTRIBUTION,
                    INVOICEE_TYPE_CHANNEL,
                ],
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            filteredPartnerTypeList() {
                if (this.availablePartnerTypes.length) {
                    return this.partnerTypeList.filter(partner => this.availablePartnerTypes.includes(partner.id))
                } else {
                    return this.partnerTypeList
                }
            },
            invoiceeFunctionMap() {
                let list = [
                    {
                        id: INVOICEE_TYPE_BT_OWNER,
                        _function: (value) => getCompanyList({
                            name: value,
                            workflow: WORKFLOW_BOOKER_TOOLS,
                            limit: 10
                        }).then(r => ({data: r.data.items})),
                        text: (item) => item.name,
                    },
                    {
                        id: INVOICEE_TYPE_GUEST,
                        _function: (value) => getUserList({
                            name: value,
                            company: this.company.id,
                            is_guest: true,
                            limit: 10
                        }).then(r => ({data: r.data.items})),
                        text: (item) => item.first_name ? item.first_name + ' ' + item.last_name : '',
                    },
                    {
                        id: INVOICEE_TYPE_OWNER,
                        _function: (value) => getOwnerList({name: value, company: this.company.id, limit: 10}),
                        text: (item) => item.full_name,
                    },
                    {
                        id: INVOICEE_TYPE_DISTRIBUTION,
                        _function: () => getDistributionList({company: this.company.id}),
                        text: (item) => item.name
                    },
                    {
                        id: INVOICEE_TYPE_CHANNEL,
                        _function: () => getChannelList({company: this.company.id, with_icons: 0}),
                        text: (item) => item.name
                    },
                ]

                if (this.company.workflow_id === WORKFLOW_BOOKER_TOOLS) {
                    list = list.filter(el => btInvoiceeList.includes(el.id))
                }

                return list.reduce((acc, el) => {
                    acc[el.id] = el
                    return acc
                }, {})
            }
        },
        watch: {
            city(value) {
                if (value && value.id) {
                    this.formData.city = value.id
                } else {
                    this.formData.city = null
                }
            },
            'formData.invoicee_type': {
                handler() {
                    // reload component options
                    this.entityKey++
                    this.entity = {id: null, name: null}
                }
            },
            entity: {
                handler(value) {
                    if (this.hasInvoiceeType) {
                        return
                    }
                    if (isObject(value) && value.id) {
                        this.formData.entity = value.id
                    } else {
                        this.formData.entity = null
                    }
                }
            }
        },
        methods: {
            save() {
                this.listenForError = true
                this.saving = true
                const savePromise = !this.partnerObject.id
                    ? createPartner(this.formData)
                    : updatePartner(this.partnerObject.id, this.formData);
                savePromise.then(response => {
                    this.$emit('saved', response.data)
                    notifySuccess()
                }).catch(error => {
                    this.showErrorMessages(error, this.validation)
                }).finally(() => {
                    this.listenForError = false
                    this.saving = false
                })
            },
            searchCountry(value) {
                return getCountries({
                    name: value
                })
            },
            searchCity(value) {
                return getCityList({
                    name: value,
                    country: this.country.id
                })
            }
        },
        created() {
            if (!this.invoiceeTypeId) {
                getInvoiceeTypeList().then(response => {
                    this.invoiceeTypeList = response.data
                })
            }

            this.access_control_general_context = {
                company: this.company.id
            }

            this.formData.partner_type = this.primaryPartnerType
            const promises = []
            promises.push(getPartnerTypeList())
            if (this.partnerObject.id) {
                promises.push(fetchPartner(this.partnerObject.id))
            } else {
                this.formData = {...this.formData, ...cloneDeep(this.partnerObject)}
                this.city = this.formData.city
            }
            Promise.all(promises).then(([r1, r2]) => {
                this.partnerTypeList = r1.data
                if (r2) {
                    this.formData = r2.data
                    this.formData.partner_type = r2.data.partner_type.id
                    this.formData.invoicee_type = r2.data.invoicee_type.id
                    this.country = r2.data.city.country
                    this.city = {
                        id: r2.data.city.id,
                        name: r2.data.city.label
                    }
                }
            }).finally(() => {
                if (this.invoiceeTypeId) {
                    this.$set(this.formData, 'invoicee_type', this.invoiceeTypeId)
                }
                this.$set(this.formData, 'company', this.company.id)

                if (!this.formData.invoicee_type) {
                    this.hasInvoiceeType = false
                }
                if (!this.formData.entity) {
                    this.hasEntity = false
                }
            })
        }
    }
</script>

<style scoped>

</style>
