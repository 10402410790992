import i18n from "@/locale";


export const MOMENT_LOCALE_MAPPINGS = {
    en: 'en',
    hr: 'hr',
    si: 'sl',
    sr: 'sr',
    es: 'es'
}

// Vuex constants
export const TOKEN = "token"
export const REFRESH_TOKEN = "refresh_token"
export const TOKEN_EXPIRES = "expires"
export const USER_DATA = "userData"
export const LS_PRESETS = "presets"

export const DEFAULT_LANGUAGE = 'en'

export const DEFAULT_PAGINATION_LIMIT = 10
export const DEFAULT_UNIT_NUMBER_LIMIT = 5

// Input select
export const APP_SELECT_OPTIONS = [
    {id: 1, name: i18n.t('YES')},
    {id: 0, name: i18n.t('NO')}
]


// Document types
export const TYPE_OWNER_ID_SCAN = 1
export const TYPE_OWNER_RJESENJE = 2
export const TYPE_OWNER_CONTRACT = 3
export const TYPE_UNIT_PHOTO = 4
export const TYPE_ACTIVITY_PHOTO = 5
export const TYPE_DRIVER_COMPANY_PHOTO = 6
export const TYPE_DRIVER_COMPANY_PHOTO2 = 10
export const TYPE_DRIVER_OWNER_PHOTO = 7
export const TYPE_PROFILE_PHOTO = 8
export const TYPE_TAG_PHOTO = 9
export const TYPE_ACTIVITY_PHOTO_THUMB = 11
export const TYPE_UNIT_PHOTO_THUMB = 12
export const TYPE_UNIT_PHOTO_LARGE = 13
export const TYPE_ACTIVITY_PHOTO_LARGE = 14
export const TYPE_PROPERTY_PHOTO = 15
export const TYPE_UNIT_CONFIRMATION_EMAIL = 16
export const TYPE_PROPERTY_PDF = 17
export const GUEST_IDENTIFICATION_PHOTO = 19
export const TYPE_COMPANY_LOGO = 21
export const TYPE_WEBSITE_LOGO = 26
export const TYPE_WEBSITE_FAVICON = 27
export const TYPE_WEBSITE_HERO_IMAGE = 28
export const TYPE_WEBSITE_GALLERY = 29
export const TYPE_COMPANY_LOGO_ALTERNATIVE = 32

// Object types
export const OBJECT_TYPE_CALCULATION = 1
export const OBJECT_TYPE_UNIT = 6
export const OBJECT_TYPE_PROPERTY = 10
export const OBJECT_TYPE_ACCOMMODATION_RESERVATION = 11
export const OBJECT_TYPE_COMPANY = 19
export const OBJECT_TYPE_OWNER = 21
export const OBJECT_TYPE_DOCUMENT = 25
export const OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE = 31
export const OBJECT_TYPE_MESSAGE_THREAD = 32
export const OBJECT_TYPE_MESSAGE = 33
export const OBJECT_TYPE_TAG = 35
export const OBJECT_TYPE_SUBSCRIPTION = 36
export const OBJECT_TYPE_NOTIFICATION = 38
export const OBJECT_TYPE_CANCELLATION_POLICY = 42


// unit descriptions
export const UNIT_DESCRIPTION = "unit_summary"
export const SPACE_DESCRIPTION = "space_description"
export const GUEST_ACCESS_AND_INVENTORY = "guest_access_and_inventory"
export const HOUSE_RULES = "house_rules"
export const GUEST_INTERACTION = "guest_interaction"
export const POOL = "pool"
export const GETTING_THERE = "getting_there"
export const NOTES = "notes"

// type promotions

export const PROMOTION_EARLY_BOOK = 1
export const PROMOTION_LAST_MINUTE = 2

// type rate plan
export const SYSTEM_STANDARD_RATE = 1
export const SYSTEM_NON_REFUNDABLE_RATE = 2

//Restriction Type
export const MIN_STAY = 1
export const MAX_STAY = 2
export const RELEASE_PERIOD = 3
export const CLOSED_ON_ARRIVAL = 4
export const CLOSED_ON_DEPARTURE = 5
export const SALES_ENABLED = 6

// relation type
export const PERC = 1
export const FIX = 2

// distribution
export const EXPEDIA = 3
export const BOOKING = 4
export const AIRBNB = 5
export const TRIP_ADVISOR = 7
//export const DIRECT_BOOKER_DISTRIBUTION = 8
export const HOME_AWAY = 10
export const HOSTEL_WORLD = 11
export const HOTEL_BEDS = 17
export const E_DOMIZIL = 18
export const AGODA = 19
export const ICAL = 21
export const NEXTPAX = 24
export const HOMEAWAY_PMSC = 96;
export const SYSTEM_DISTRIBUTION = 153;

// CM communication type
export const CM_COMMUNICATION_TYPE_HOME_AWAY = 4
export const CM_COMMUNICATION_TYPE_DIRECT_BOOKING = 5

// CM rate plan communication type
export const CM_RATE_PLAN_COMMUNICATION_TYPE_OUTBOUND = 1
export const CM_RATE_PLAN_COMMUNICATION_TYPE_INBOUND = 2

// payment gateways
export const MONRI = 1
export const WSPAY = 2
export const PCIBOOKINGUPG = 3
export const STRIPE = 4

export const PG_ACTION_AUTHORIZE = 35;
export const PG_ACTION_PURCHASE = 34;
export const PG_ACTION_CAPTURE = 36;
export const PG_ACTION_REFUND = 37;
export const PG_ACTION_VOID = 38;
export const PG_ACTION_PAYMENT_ORDER = 77;

export const STATUS_PROPERTY_IN_ACQUISITION = 1
export const STATUS_PROPERTY_COMPLETE_ACQUISITION = 2
export const STATUS_CREATED = 3
export const STATUS_DELETED = 4
export const STATUS_ACTIVE = 5
export const STATUS_PROPERTY_UPDATE = 6
export const STATUS_PROPERTY_COOPERATION_COMPLETED = 7
export const STATUS_PROPERTY_INACTIVE = 8
export const STATUS_PROPERTY_UPDATE_COMPLETE = 23
export const STATUS_CONFIRMED = 26
export const STATUS_HOLD = 28
export const STATUS_PROPERTY_ACQUISITION_ON_HOLD = 84

export const STATUS_TRANSITION_COMPLETE_ACQUISITION = 37
export const STATUS_TRANSITION_UPDATE_COMPLETE = 289


// Tags
export const TAG_TYPE_DESTINATION = 1
export const TAG_TYPE_CUSTOM = 2
export const TAG_TYPE_IMAGE = 9
export const TAG_TYPE_RESERVATION = 11
export const TAG_TYPE_CANCELLATION_POLICY = 12

export const TAG_DESTINATION_TYPE_COUNTRY = 'country'
export const TAG_DESTINATION_TYPE_REGION = 'region'
export const TAG_DESTINATION_TYPE_CITY = 'city'

// Company
export const TENANT_TYPE_COMMERCIAL = 2
export const TENANT_TYPE_FRANCHISE = 1
export const PERSON_TYPE_LIST = [
    {id: 1, label: 'NATURAL_ENTITY'},
    {id: 2, label: 'LEGAL_ENTITY'},
]

export const HAS_CHANNEL_DATA = [
    EXPEDIA,
    HOME_AWAY,
    NEXTPAX
];

export const DISTRIBUTION_MANAGERS = [
    NEXTPAX,
    SYSTEM_DISTRIBUTION,
];

export const WHITELABEL_ADVANCE = 2
// RateManager
export const REPORT_TYPE_ACQUISITION = 2

// Finance
export const INVOICEE_TYPE_CHANNEL = 5
export const INVOICEE_TYPE_DISTRIBUTION = 4
export const INVOICEE_TYPE_BT_OWNER = 3
export const INVOICEE_TYPE_GUEST = 2
export const INVOICEE_TYPE_OWNER = 1
export const COMMISSION_TYPE_PERCENT = 1
export const COMMISSION_TYPE_FIXED = 2
export const COMMISSION_TYPE_PLATFORM_FEE = 3
export const CALC_TYPE_UNIT_TO_TOTAL = 1
export const CALC_TYPE_TOTAL_TO_UNIT = 2
export const INVOICEE_FILTER_LIST = [INVOICEE_TYPE_OWNER, INVOICEE_TYPE_GUEST, INVOICEE_TYPE_BT_OWNER]

// Calculation type
export const CALCULATION_TYPE_OWNER = 1
export const CALCULATION_TYPE_MONTHLY_SYSTEM_USAGE = 2

export const CALCULATION_STATUS_CALCULATED = 1
export const CALCULATION_STATUS_READY_FOR_INVOICE = 2
export const CALCULATION_STATUS_FOR_RECALCULATION = 4
export const CALCULATION_STATUS_CANCELED = 5

// Payment type
export const SUBSCRIPTION_PAYMENT_TYPE_PAYMENT_LINK = null // only used for subscriptions
export const PAYMENT_TYPE_BANK_TRANSFER = 1
export const PAYMENT_TYPE_RECURRING_CARD_PAYMENT = 2

// Booking engine setup parameters
export const BE_SP_ACTIVE = 3
export const BE_SP_DISPLAY_THEME = 4

// Setup Parameter Value Types
export const SP_VALUE_TEXT = 1
export const SP_VALUE_BOOL = 2
export const SP_VALUE_OPTION = 3

export const EVisitorCro = 1
export const AjpesSlo = 2
export const IdReaderSrb = 3

// Invoice Creation Location
export const ICL_RESERVATION_PREVIEW = 1
export const ICL_INVOICE_LIST = 5
export const OWNER_INVOICE_FILTER_LIST = [4, 5]
export const INVOICE_STATUS_CREATED = 1;
export const INVOICE_STATUS_PROCESSING = 2;
export const INVOICE_STATUS_PROCESSED = 3;
export const INVOICE_STATUS_COMPLETED = 4;
export const INVOICE_STATUS_PAID = 5;
export const INVOICE_STATUS_CANCELED = 6;

export const INVOICE_TYPE_FISCAL = 2;

// URL Login page
export const LoginPageEn = "https://booker-tools.com/login"
export const LoginPageHr = "https://booker-tools.com/prijava"
export const LoginPageEs = "https://booker-tools.com/iniciar-sesion"
export const LoginPageDa = "https://booker-tools.com/log-pa"
export const LoginPageDe = "https://booker-tools.com/anmeldung"
export const LoginPageIt = "https://booker-tools.com/accedi"
export const LoginPageNl = "https://booker-tools.com/inloggen"
export const LoginPageFallback = "https://app.booker-tools.com/login?lang="

export const TestDomainLoginPageHr = "https://app.direct-booker.com/w-login"

export const RegisterPageEn = "https://booker-tools.com/register"
export const RegisterPageHr = "https://booker-tools.com/registracija"
export const RegisterPageEs = "https://booker-tools.com/registro"
export const RegisterPageDa = "https://booker-tools.com/gentag-adgangskode"
export const RegisterPageDe = "https://booker-tools.com/registrieren"
export const RegisterPageIt = "https://booker-tools.com/registra"
export const RegisterPageNl = "https://booker-tools.com/registeren"
export const RegisterPageFallback = "https://app.booker-tools.com/register?lang="

export const ForgotPasswordPageEn = "https://booker-tools.com/forgot-password"
export const ForgotPasswordPageHr = "https://booker-tools.com/zaboravljena-lozinka"
export const ForgotPasswordPageEs = "https://booker-tools.com/contrasena-olvidada"
export const ForgotPasswordPageDa = "https://booker-tools.com/glemt-adgangskode"
export const ForgotPasswordPageDe = "https://booker-tools.com/vergessenes-passwort"
export const ForgotPasswordPageIt = "https://booker-tools.com/password-dimenticata"
export const ForgotPasswordPageNl = "https://booker-tools.com/wachtwoord-vergeten"
export const ForgotPasswordPageFallback = "https://app.booker-tools.com/password/reset?lang="


export const LinkAboutUs_hr = "https://booker-tools.com/about-us/"
export const LinkAboutUs_en = "https://booker-tools.com/about-us/"

export const LinkPrivacyPolicy_en = "https://dev.booker-tools.com/en/privacy-policy"
export const LinkPrivacyPolicy_hr = "https://dev.booker-tools.com/pravila-privatnosti"
export const LinkPrivacyPolicy_si = "https://dev.booker-tools.com/si/politika-zasebnosti"
export const LinkPrivacyPolicy_me = "https://dev.booker-tools.com/me/pravila-privatnosti"
export const LinkPrivacyPolicy_sr = "https://dev.booker-tools.com/sr/pravila-privatnosti"

export const LinkTermsAndCondition_en = "https://dev.booker-tools.com/en/terms-and-conditions"
export const LinkTermsAndCondition_hr = "https://dev.booker-tools.com/uvjeti-poslovanja"
export const LinkTermsAndCondition_si = "https://dev.booker-tools.com/si/pravila-in-pogoji"
export const LinkTermsAndCondition_me = "https://dev.booker-tools.com/me/uvjeti-poslovanja"
export const LinkTermsAndCondition_sr = "https://dev.booker-tools.com/sr/uvjeti-poslovanja"

export const ADMIN_CMS = "https://admin.direct-booker.com/"

export const BOOKER_TOOLS_DOMAIN = "app.booker-tools.com"
export const BOOKER_TOOLS_TEST_DOMAIN = "app-test.booker-tools.com"

//countries
export const CROATIA = 51
export const SERBIA = 184
export const SLOVENIA = 189

// Reservation status
export const RES_STATUS_CANCELED = 27
export const RES_STATUS_CANCELED_HOLD = 29
export const RES_STATUS_UNKNOWN = 30
export const RES_STATUS_UNKNOWN_COMPLETED = 31
export const RES_STATUS_FILTERED_LIST = [RES_STATUS_CANCELED, RES_STATUS_CANCELED_HOLD, RES_STATUS_UNKNOWN, RES_STATUS_UNKNOWN_COMPLETED]

// AR Finance Status
export const RES_STATUS_PAID = 46
export const RES_STATUS_UNPAID = 47
export const RES_STATUS_PARTIALLY_PAID = 49
export const RES_STATUS_FINANCE_LIST = [RES_STATUS_PAID, RES_STATUS_PARTIALLY_PAID, RES_STATUS_UNPAID]

// Reservation note types
export const RES_NOTE_TYPE_GUEST_COMMENT = 2
export const RES_NOTE_TYPE_NOTE = 3
export const RES_NOTE_TYPE_HOST = 4
export const RES_NOTE_TYPE_PRIVATE = 5
export const RES_NOTE_TYPE_RES_COMMENT = 6

// Reservation scheduler actions
export const RES_SCHEDULER_ACTION_NOTIFICATION = 1
export const RES_SCHEDULER_ACTION_TRANSACTION = 2

// Reservation scheduler statuses
export const RESERVATION_SCHEDULER_STATUS_NON_EXECUTABLE = 80

// workflow enumeration
export const WORKFLOW_BOOKER_TOOLS = 1;
export const WORKFLOW_DIRECT_BOOKER = 2;


//user role
export const ROLE_ACCOUNT_MANAGER_NEW = 30
export const ROLE_PROPERTY_OWNER_NEW = 34
export const ROLE_PROPERTY_MANAGER_NEW = 35
export const ROLE_CLEANING = 40
export const ROLE_CHECKIN = 42

// channel
export const CHANNEL_OWNER_RESERVATION = 82
export const BOOKING_ENGINE = 113
export const RATE_MANAGER_IFRAME = process.env.VUE_APP_API_URL + "/adminapi/rate-manager-iframe?uid="

// evisitor
export const EVISITOR_DESCRIPTION_IMG_URL = "https://admin.direct-booker.com/media/evisitor/evisitor_upute_-_oznaka_objekta_strelice.png"


// setup parameter
export const PRICING_MODEL = 14
export const CONNECTION_TYPE = 13
export const PAYMENT_TYPE = 18
export const LOCATION_OVERRIDE = 80

// rate manager
// suggestion statuses
export const RM_SUGGESTION_STATUS_ACTIVE = 1
export const RM_SUGGESTION_STATUS_CHANGED = 2
export const RM_SUGGESTION_STATUS_DECLINED = 3
export const RM_SUGGESTION_STATUS_ACCEPTED = 4

//Contact queue notif channel
export const NOTIFICATION_CHANNEL_MAIL = 1
export const NOTIFICATION_CHANNEL_APLICATION = 2

// contact action list
export const CONTACT_ACTION_RESERVATION_NEW = 1;
export const CONTACT_ACTION_RESERVATION_UPDATE = 2;
export const CONTACT_ACTION_RESERVATION_ARRIVAL_TIME = 4;
export const CONTACT_ACTION_CALCULATION_OVERVIEW = 14;

// contact recipient type
export const CONTACT_RECIPENT_PDF = 2
export const CONTACT_RECIPENT_GUEST = 3
export const CO_RECIPIENT_TYPE_CONTACT_LIST = 2
export const CO_RECIPIENT_TYPE_ACCOUNT_MANAGER = 1
export const CO_RECIPIENT_TYPE_USERS_WITH_UNIT_ACCESS = 9
export const CO_RECIPIENT_TYPE_USERS_WITH_COMPANY_UNITS_ACCESS = 10


// message status
export const MESSAGE_STATUS_UNREAD = 50
export const MESSAGE_STATUS_READ = 51
export const MESSAGE_STATUS_CREATED = 52

export const GUEST_LIST_URL = process.env.VUE_APP_API_URL + "/adminapi/guest-list"
export const PROPERTY_LIST_URL = process.env.VUE_APP_API_URL + "/adminapi/property-list"
export const COMPANY_LIST_URL = process.env.VUE_APP_API_URL + "/adminapi/company"
export const INVOICE_LIST_URL = process.env.VUE_APP_API_URL + "/adminapi/finance/invoice"
export const TRANSACTION_LIST_URL = process.env.VUE_APP_API_URL + "/adminapi/transaction"
export const PAYOUT_LIST_URL = process.env.VUE_APP_API_URL + "/adminapi/bookings/pbb"
//cm queue
export const CM_QUEUE_STATUS_ERROR = 21
export const CM_QUEUE_STATUS_WARNING = 25
export const CM_QUEUE_TYPE_EXECUTABLE = 1


//company
export const DIRECT_BOOKER_COMPANY = 1
export const IVAN_BOGOJE_COMPANY = 386
export const IVAN_BOGOJE_2_COMPANY = 2120


//type parameter

export const SETUP_PARAMETER_INTEGRATED = 7
export const SETUP_PARAMETER_API_NAME = 19
export const SETUP_PARAMETER_LEGAL_ENTITY = 20
export const SETUP_PARAMETER_LOGIN_DETAILS = 11
export const SETUP_PARAMETER_CHAIN_CODE = 22
export const SETUP_PARAMETER_ADVERTISER_ID = 25
export const SETUP_PARAMETER_MERCHANT_NAME = 26
export const SETUP_PARAMETER_RENTAL_AGREEMENT = 28
export const SETUP_PARAMETER_HOME_AWAY_4_2_SETUP = 49
export const SETUP_PARAMETER_UNIT_ACTIVATION_RULES = 97
export const SETUP_PARAMETER_IMPORT_ICAL = 100

export const THREAD_OBJECT_TYPE_RESERVATION = 2

// Subscription package
export const SUBSCRIPTION_PACKAGE_DEMO = 1
export const SUBSCRIPTION_PACKAGE_PER_RESERVATION = 3
export const SUBSCRIPTION_PACKAGE_FLAT_PER_UNIT = 62

//property gallery
export const TAG_PROPERTY_GALLERY = 424

//revenue manager
export const ACTIVE_REVENUE_MANAGER = 40;
export const ACTIVE_REVENUE_MANAGER_RATES = 38;
export const ACTIVE_REVENUE_MANAGER_MINIMUM_STAY = 39;
export const ACTIVE_REVENUE_MANAGER_RATES_EXTERNAL = 164;

//action
export const ACTION_TYPE_MIN_PRICE = 1
export const ACTION_TYPE_MIN_STAY = 2

//deviation setup type
export const AVAILABILITY_DEVIATION_TYPE = 1
export const RATE_DEVIATION_TYPE = 2

export const WEBSITE_PAGE_TYPE_CUSTOM = 4

//direct booking
export const DB_DOMAIN = '.booker-tools.com'
export const WEBSITE_SETUP_PARAMETER_INQUIRY = 72
export const WEBSITE_SETUP_PARAMETER_INSTANT_BOOKING = 78
export const WEBSITE_SETUP_PARAMETER_CREDIT_CARD_ADDRESS_ENABLED = 177
export const WEBSITE_SETUP_PARAMETER_CREDIT_CARD_ADDRESS_REQUIRED = 178

//promo codes
export const PROMO_CODE_WEBSITE = 1
export const PROMO_CODE_PROPERTY = 2
export const PROMO_CODE_UNIT = 3
export const PROMO_CODE_UNIT_TYPE = 4
export const PROMO_CODE_COUNTRY = 5
export const PROMO_CODE_REGION = 6
export const PROMO_CODE_CITY = 7

//marketplace
export const MARKETPLACE_WEBSITE_ACTIVATION = 'website_activation'
export const MARKETPLACE_UNIT_ACTIVATION = 'unit_activation'
export const MARKETPLACE_DESCRIPTION = 'description'
export const MARKETPLACE_TOS = 'tos'
export const MARKETPLACE_WORKFLOW = [{id: 'pending_approval', label: i18n.t('PENDING_APPROVAL')}, {id: 'approval', label: i18n.t('APPROVAL')}]
export const PENDING_EID = 'Pending'
export const ACCEPTED_EID = 'Accepted'
export const DECLINED_EID = 'Declined'
export const MPL_DESCRIPTION = 'description'
export const MPL_IMAGE = 'image'
export const MPL_RATE_PLAN = 'rate_plan'
export const MPL_UNIT_TYPE = 'unit_type'
export const MPL_WORKFLOW = 'workflow'

//appearance
export const APPEARANCE_TYPE_COLOR = 1
export const APPEARANCE_TYPE_FONT = 2

// setup parameters

export const SETUP_PARAMETER_CURRENCY_SOURCE = 108
export const SETUP_PARAMETER_OWNER_STATEMENT_SETUP = 121
export const SETUP_PARAMETER_RESERVATION_NOTE_SETUP = 122
export const SETUP_PARAMETER_DATA_TRANSITION_AFTER_EVENT = 119


// event template
export const EVENT_TEMPLATE_TYPE_CONFIGURATION = 1
export const EVENT_TEMPLATE_TYPE_CONFIGURATION_TEMPLATE = 2

// event send point types
export const EVENT_SEND_POINT_TYPE_ON_EVENT = 1

// contact channel
export const CONTACT_CHANNEL_MOBILE_APP = 6
export const CONTACT_CHANNEL_E_MAIL = 1
export const CONTACT_CHANNEL_MESSAGE = 7

// rate plan category
export const RATE_PLAN_CATEGORY_SYSTEM_DEFAULT = 1
// export const RATE_PLAN_CATEGORY_SYSTEM = 2
export const RATE_PLAN_CATEGORY_DISTRIBUTION = 3

// rate plan type
export const RATE_PLAN_TYPE_STANDARD = 1
export const RATE_PLAN_TYPE_NON_REFUNDABLE = 2

// client type
export const APP_CLIENT_TYPE_WEBSITE = 1
export const APP_CLIENT_TYPE_BOOKER_TOOLS =2
export const APP_CLIENT_TYPE_GUEST_BACKOFFICE =3

// service
export const SERVICE_DIRECT_BOOKING_ID = 6

// calendar work mode
export const MULTI_UNIT_VIEW = 1
export const MONTHLY_VIEW = 2
export const SINGLE_UNIT = 3

// window width
export const MIN_WINDOW_WIDTH = 1200

    // display setup
    export const DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT = 'reservation_export'
    export const DISPLAY_SETUP_LOCATION_FINANCE_DETAILS = 'finance-module'
    export const DISPLAY_SETUP_RESERVATION_GROUP_MAIN = 1
    export const DISPLAY_SETUP_RESERVATION_GROUP_FINANCE = 2
    export const DISPLAY_SETUP_RESERVATION_GROUPS = [
            {value: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, text: 'GENERAL'},
            {value: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, text: 'FINANCE'},
        ]
export const DISPLAY_SETUP_RESERVATION = [
    {value: 'reservation_code', text: 'RESERVATION_CODE', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'distribution', text: 'DISTRIBUTION', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'channel', text:'SALES_CHANNEL', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'check_in_date', text: 'CHECKIN_DATE', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'check_in_time', text: 'ARRIVAL_TIME', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'check_out_date', text: 'CHECKOUT_DATE', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'check_out_time', text: 'DEPARTURE_TIME', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'num_nights', text: 'NUMBER_OF_NIGHTS', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'host_note', text: 'RESERVATION_EXPORT_MY_NOTE', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'num_guests', text: 'NUM_GUESTS', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'status', text: 'STATUS', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'property', text: 'PROPERTY', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'unit', text: 'UNIT', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'note', text: 'RESERVATION_EXPORT_AGENCY_NOTE', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'created_date', text:'BOOKED_AT', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'last_change', text: 'LAST_CHANGE', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'guest', text: 'GUEST', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'guest_contact', text: 'GUEST_CONTACT', group: DISPLAY_SETUP_RESERVATION_GROUP_MAIN, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},

    {value: 'total_gross', text: 'TOTAL', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], style: 'bold'},
    {value: 'db_owner_total', text: 'TOTAL', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], style: 'bold'},
    {value: 'total_nett', text: 'TOTAL_NETT', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], style: 'bold'},
    {value: 'owner_part', text: 'OWNER_PART', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], style: 'bold'},
    {value: 'total_costs', text: 'TOTAL_COSTS', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], style: 'bold'},
    {value: 'commission_total', text: 'COMMISSION_TOTAL', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], style: 'bold'},
    {value: 'sales_channel_cost_fee', text: 'SALES_CHANNEL_COST_FEE', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS]},
    {value: 'sales_channel_commission_total', text: 'SALES_CHANNEL_COMMISSION_TOTAL', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], style: 'bold'},
    {value: 'sales_channel_commission', text: 'SALES_CHANNEL_COMMISSION', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], type: 'sales_channel_commission_type'},
    {value: 'sales_channel_vat', text: 'VAT_CHANNEL_COMMISSION', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], type: 'sales_channel_vat_type'},
    {value: 'property_management_fee', text: 'PROPERTY_MANAGEMENT_FEE', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS]},
    {value: 'agency_commission_total', text: 'AGENCY_COMMISSION_TOTAL', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], style: 'bold'},
    {value: 'agency_commission', text: 'AGENCY_COMMISSION', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], type: 'agency_commission_type'},
    {value: 'agency_commission_fix', text: 'FIXED_AGENCY_COMMISSION', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS]},
    {value: 'distribution_commission_total', text: 'DISTRIBUTION_COMMISSION_TOTAL', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], style: 'bold'},
    {value: 'distribution_commission', text: 'DISTRIBUTION_COMMISSION', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], type: 'distribution_commission_type'},
    {value: 'distribution_commission_vat', text: 'VAT_DISTRIBUTION_COMMISSION', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], type: 'distribution_commission_vat_type'},
    {value: 'additional_commission_total', text: 'ADDITIONAL_COMMISSION_TOTAL', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], style: 'bold'},
    {value: 'additional_commission', text: 'ADDITIONAL_COMMISSION', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], type: 'additional_commission_type'},
    {value: 'additional_commission_vat', text: 'VAT_ADDITIONAL_COMMISSION', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], type: 'additional_commission_vat_type'},
    {value: 'transaction_cost_fee', text: 'TRANSACTION_FEE', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS]},
    {value: 'reservation_total', text: 'TOTAL', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS]},
    {value: 'transaction_fee', text: 'TRANSACTION_FEE', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], style: 'bold', type: 'transaction_fee_type'},
    {value: 'platform_fee', text: 'BOOKING_FEE', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT, DISPLAY_SETUP_LOCATION_FINANCE_DETAILS], style: 'bold', type: 'platform_fee_type'},
    {value: 'payment_status', text: 'PAYMENT_STATUS', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
    {value: 'paid_to_owner', text: 'PAID_TO_OWNER', group: DISPLAY_SETUP_RESERVATION_GROUP_FINANCE, locations: [DISPLAY_SETUP_LOCATION_RESERVATION_EXPORT]},
]

export const PAYMENT_ORDER_STATUS_CREATED = 4
export const PAYMENT_ORDER_STATUS_PAID = 5
export const PAYMENT_ORDER_STATUS_CANCELLED = 6
