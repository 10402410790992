<template>
    <div class="main_calendar__cell main_calendar__holder--content_pricing_cell"
         :class="{'main_calendar__bg-hover': highlighted, 'main_calendar-br_green': cellData.lastDayOfMonthClass,'disable-restriction': cellData.disabled}"
         @click.shift.exact="$emit('drag')"
         @click.exact="$emit('startEndDrag')"
         @mouseover.shift="$emit('currentDrag')">

        <span @click.stop="$emit('showSuggestionAside')" v-if="cellData.suggestion && cellData.suggestion.edit"
              class="main_calendar__suggestion">{{ cellData.suggestionValue }}</span>

        <span :id="`popover-fly-${_uid}`" @mouseenter="showPopover=true" @mouseleave="showPopover=false"
              v-else-if="cellData.suggestion && !cellData.suggestion.edit"
              class="main_calendar__suggestion disabled">{{ cellData.suggestionValue }}</span>
        {{ cellData.value }}
        <b-popover
            v-if="cellData.suggestion && !cellData.suggestion.edit && cellData.suggestion.statistics"
            :show.sync="showPopover"
            placement="top"
            :title="$t('BASED_ON')"
            :target="`popover-fly-${_uid}`">

            <b-list-group>
                <b-list-group-item class="d-flex justify-content-between align-items-center p-2">
                    {{ $t("GROUPED_UNIT_COUNT") }}
                    <b-badge class="ml-2" variant="primary" pill>
                        {{ cellData.suggestion.statistics.grouped_unit_count }}
                    </b-badge>
                </b-list-group-item>

                <b-list-group-item class="d-flex justify-content-between align-items-center p-2">
                    {{ $t("NUM_ACTIVE_RESERVATIONS") }}
                    <b-badge class="ml-2" variant="primary" pill>
                        {{ cellData.suggestion.statistics.reservation_count }}
                    </b-badge>
                </b-list-group-item>

                <b-list-group-item class="d-flex justify-content-between align-items-center p-2">
                    {{ $t("AVG_NUM_PERSONS") }}
                    <b-badge class="ml-2" variant="primary" pill>
                        {{ cellData.suggestion.statistics.reservation_guest_avg }}
                    </b-badge>
                </b-list-group-item>

                <b-list-group-item class="d-flex justify-content-between align-items-center p-2">
                    {{ $t("AVG_SOLD_DAYS") }}
                    <b-badge class="ml-2" variant="primary" pill>
                        {{ cellData.suggestion.statistics.reservation_stay_avg }}
                    </b-badge>
                </b-list-group-item>
            </b-list-group>
        </b-popover>
    </div>
</template>

<script>
export default {
    name: "TableRestrictionNumberCell",
    props: {
        cellData: {
            type: Object
        },
        highlighted: {
            type: Boolean
        }
    },
    data() {
        return {
            showPopover: false
        }
    }
}
</script>

<style scoped>
.disable-restriction{
    background:#edeff18f;
    pointer-events: none;
    cursor: default;
}
</style>
