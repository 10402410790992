<template>
    <div>
        <b-row>
            <b-col>
                <label>{{ restriction.label }}</label>
            </b-col>
        </b-row>
        <b-tooltip v-if="has_tooltip_message" :target="component_id"
                   placement="top"
                   :title="message"></b-tooltip>
        <b-row :id="component_id">
            <b-col class="d-flex">
                <b-form-checkbox
                    :disabled="disabled"
                    :inline="inline"
                    @change="selectAllChange"
                    :checked="select_all"
                    :value="true"
                    :unchecked-value="false"
                    class="b-r-1 pr-1 mr-1">
                    {{ $t("SELECT_ALL") }}
                </b-form-checkbox>
                <b-form-checkbox-group
                    :disabled="disabled"
                    @change="change"
                    v-model="selected"
                    :options="options"
                    name=""
                    class="ml-0">
                </b-form-checkbox-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "CheckboxRestrictionFormElement",
    props: {
        allowed_values: {
            type: Object
        },
        value: {
            type: String,
            default: ""
        },
        restriction: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        },
        stacked: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: true
        },
        has_tooltip_message: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
        }
    },
    data() {
        return {
            select_all: false,
            selected: [],
            component_id: null
        }
    },
    methods: {
        getWeekday(number) {
            switch (Number(number)) {
                case 1:
                    return this.$t('DATE.DAYS.MONDAY')
                case 2:
                    return this.$t('DATE.DAYS.TUESDAY')
                case 3:
                    return this.$t('DATE.DAYS.WEDNESDAY')
                case 4:
                    return this.$t('DATE.DAYS.THURSDAY')
                case 5:
                    return this.$t('DATE.DAYS.FRIDAY')
                case 6:
                    return this.$t('DATE.DAYS.SATURDAY')
                case 7:
                    return this.$t('DATE.DAYS.SUNDAY')
            }
        },
        getSelected() {
            this.selected = []
            if (this.value === null || this.value === '') {
                this.selected = [1, 2, 3, 4, 5, 6, 7]
                return
            }
            let tempValue = this.value.split('')
            if (tempValue.length === 0) {
                this.selected = [1, 2, 3, 4, 5, 6, 7]
                return
            }
            tempValue.forEach(item => {
                if (Number(item) > 0) {
                    this.selected.push(Number(item))
                }
            })
        },
        change(item) {
            this.select_all = false
            if (item.length === 0) {
                this.$emit("input", '0')
                return
            }
            this.$emit("input", item.join(""))
        },
        selectAllChange(value) {

            this.select_all = value
            this.selected = []
            if (this.select_all) {
                this.options.forEach(item => {
                    this.selected.push(item.value)
                })
            }

            if (this.selected.length === 0) {
                this.$emit("input", '0')
                return
            }

            this.$emit("input", this.selected.join(""))
        }
    },
    computed: {
        options() {
            let data = []
            for (const key in this.allowed_values) {
                data.push({text: this.getWeekday(key), value: Number(key)})
            }

            return data
        },
    },
    watch: {
        value: {
            handler() {
                this.getSelected()
            },
            immediate: true,
            // deep: true,
        },
        selected: {
            handler(value) {
                this.select_all = value.length === this.options.length
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        if (this.component_id === null) {
            this.component_id = 'CheckboxRestriction_' + this._uid
        }
    }
}
</script>

<style scoped>

</style>
