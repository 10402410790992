import http from "@/services/http";
import {PAYOUT_REPORT_EXPORT_URL, PAYOUT_REPORT_FETCH_URL} from "@/services/endpoints";


export function getPayoutReport(params){
    return http.request({
        url: PAYOUT_REPORT_FETCH_URL,
        method: 'GET',
        params
    })
}

export function payoutReportDownloadXls(params){
    return http.request({
        url: PAYOUT_REPORT_EXPORT_URL,
        method: 'GET',
        responseType: 'blob',
        params
    })
}
