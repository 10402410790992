<template>
    <div v-if="los.length === 0 || occupancy.length === 0">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else class="text-muted">
        <form @submit.prevent="save(false)" v-if="Object.keys(priceItems).length > 0">
            <b-row>
                <b-col>
                    <div v-if="ac_lock_view && !(los.length > 1 || occupancy.length > 1)" class="action_button pointer mb-4" style="cursor: pointer!important"  @click="lockLosAside = true">{{$t('LOCK')}}</div>
                    <span v-if="ac_lock_view && (los.length > 1 || occupancy.length > 1)" class="action_button pointer mb-4" style="cursor: pointer!important"  @click="lockLosAside = true">{{$t('LOCK')}}</span>


                    <b-form-checkbox
                        v-if="los.length > 1 || occupancy.length > 1"
                        v-model="enableCalculate"
                        class="pull-right mb-4"
                        switch
                        inline
                        id="enable_calculate"
                        name="enable_calculate"
                        :value="true"
                        :unchecked-value="false">
                        {{$t('APPLY_INCREASE_DECREASE')}}
                    </b-form-checkbox>
                    <table style="width: 100%" class="table">
                        <thead>
                        <tr>
                            <th v-if="los.length > 1">{{$t("LENGTH_OF_STAY")}}</th>
                            <th v-if="occupancy.length >1">{{$t("OCCUPANY_PERSONS")}}</th>
                            <th class="text-center">{{$t('PRICE')}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="losItem in los">
                            <tr :key="losItem.id + '_' + occupancyItem.id"
                                v-for="occupancyItem in occupancy">
                                <td v-if="los.length > 1" :class="losItem.default && occupancyItem.default ? 'bg-light' : ''">
                                    {{losItem.min_stay}}
                                    <span v-if="losItem.min_stay === losItem.max_stay"></span>
                                    <span v-else-if="losItem.max_stay"> - {{losItem.max_stay}}</span>
                                    <span v-else>+</span>


                                </td>
                                <td v-if="occupancy.length >1" :class="losItem.default && occupancyItem.default ? 'bg-light' : ''">
                                    {{occupancyItem.min_guests}}
                                    <span v-if="occupancyItem.min_guests === occupancyItem.max_guests"></span>
                                    <span v-else-if="occupancyItem.max_guests"> - {{occupancyItem.max_guests}}</span>
                                    <span v-else>+</span>

                                </td>
                                <td class="mb-0 mt-0 pb-2 pt-2" v-if="objectHas(priceItems, `${losItem.id}.${occupancyItem.id}`)">
                                    <app-number-input :min="1" v-if="losItem.default === 1 && occupancyItem.default === 1" :prepend="currency"
                                                      @change="calculatePriceDependancyList($event, losItem, occupancyItem)"
                                                      v-model="priceItems[losItem.id][occupancyItem.id]">
                                    </app-number-input>
                                    <app-number-input :min="1" v-else :prepend="currency"
                                                      v-model="priceItems[losItem.id][occupancyItem.id]">
                                    </app-number-input>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row class="pt-3">
                <b-col class="d-flex justify-content-between">
                    <app-button @click="lockModal = true" v-if="ac_lock_view" :c_permission="c_edit" :disabled="dateRanges.length === 0 || !canBeSaved || allowedWeekday.length === 0">{{$t('SAVE')}}</app-button>
                    <app-submit v-else  :c_permission="c_edit" :disabled="dateRanges.length === 0 || !canBeSaved || allowedWeekday.length === 0"
                                :loading="saving"></app-submit>
                </b-col>

            </b-row>
        </form>
        <app-aside v-model="lockLosAside" full-width :widths="['col-lg-12']">
            <template v-slot:header>
                <div class="property-wrapper mb-0 no-border" >
                    <div class="card-header">
                        <div class="header-caption">{{$t('LOCK')}}</div>
                        <h2 class="property-wrapper-padding mb-2">
                            {{unit.name}}
                        </h2>
                    </div>
                </div>
            </template>
            <los-lock-form :unit_id="unit.id" :los-list="los" :occupancy-list="occupancy"></los-lock-form>
        </app-aside>

        <b-modal :title="$t('LOCK_CONFIRMATION')" v-model="lockModal">

            <b-form>

                <b-row class="mb-2">
                    <b-col lg="12" class="mb-4">
                        <label class="label">{{$t('LOCK_UNTIL')}}</label>
                        <app-date-picker :min-date="new Date()" v-model="until_date"></app-date-picker>
                    </b-col>
                </b-row>
            </b-form>
            <template v-slot:modal-footer>
                <app-button :loading="saving" @click="save(false)">{{$t('SAVE')}}</app-button>
                <app-button :loading="savingWithLock" @click="save(true)"  class="ml-3">{{$t('SAVE_AND_LOCK')}}</app-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import {
    EventBus,
    GE_CALENDAR_LOAD_LOS_AND_OCCUPANCY,
    GE_CALENDAR_LOAD_PRICES,
    GE_CALENDAR_REFRESH_DATA
} from "@/shared/EventBus";
import AppSubmit from '@/components/app/AppButton/AppButtonSubmit'
import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import {toast} from '@/shared/plugins/toastr'
import InputPriceFormElement from '@/components/unit/calendar/forms/price/InputPriceFormElement'
import AppValidationDescription from '@/components/app/form/AppValidationDescription'
import {setUnitPrice} from "@/services/unit";
import {
    C_PROPERTY_CALENDAR_PRICES_E,
    C_PROPERTY_CALENDAR_PRICES_CALCULATE,
    C_REVENUE_MANAGER_LOCK_PRICE_V,
} from "@/shared/component_permission";
import {each, has, cloneDeep, isEqual} from 'lodash'
import AppNumberInput from "@/components/app/form/AppNumberInput";
import {PERC, FIX} from "@/shared/constants";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import LosLockForm from "@/components/logs/LosLockForm";
import PriceLockForm from "@/components/logs/PriceLockForm";
import AppAccessControl from "@/components/app/AppAccessControl";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_CALENDAR_EDIT} from "@/mixins/AccessControl/AccessControlEnumeration";
import {fetchAccessControlData} from "@/services/access";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import {setPriceLock} from "@/services/pricing";


export default {
    name: "CalendarAsideRatesTest",
    props: {
        unit: {
            type: Object
        },
        los: {
            type: Array
        },
        dateRanges: {
            type: Array
        },
        occupancy: {
            type: Array
        },
        defaultValues: {
            type: Object
        },
        ratePlan: {
            type: Number,
            default: null
        },
        allowedWeekday:{
            type:Array
        }
    },
    components: {
        AppDatePicker,
        AppConfirmationDialog,
        AppAccessControl,
        PriceLockForm,
        LosLockForm,
        AppAside,
        AppButton,
        AppNumberInput,
        AppSubmit, InputPriceFormElement, AppValidationDescription
    },
    mixins: [getErrorMessage],
    data() {
        return {
            until_date:null,
            lockModal:false,
            loading: false,
            priceItems: {},
            nonEditablePriceItems: {},
            form_invalid: false,
            saving: false,
            c_edit: C_PROPERTY_CALENDAR_PRICES_E,
            C_PROPERTY_CALENDAR_PRICES_CALCULATE: C_PROPERTY_CALENDAR_PRICES_CALCULATE,C_REVENUE_MANAGER_LOCK_PRICE_V,
            enableCalculate:false,
            lockLosAside:false,
            AC_CALENDAR_EDIT,
            ac_lock_view:false,
            savingWithLock:false
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        property() {
            return this.$store.getters[`property/getProperty`]
        },
        currency() {
            return this.company.hasOwnProperty('working_currency') ? this.company.working_currency.code : null
        },
        canBeSaved() {
            let val = false
            if (isEqual(this.priceItems, this.nonEditablePriceItems)) {
                return val
            }

            Object.keys(this.priceItems).forEach(losId => {
                Object.keys(this.priceItems[losId]).forEach(occupancyId => {
                    if (Number(this.priceItems[losId][occupancyId]) !== null) {
                        val = true
                    }
                })
            })

            return val
        },
        mainPriceItems() {
            let defaultLos = this.los.find(el => {
                return el.default
            })

            let defaultOccupancy = this.occupancy.find(el => {
                return el.default
            })
            let price = {
                los: defaultLos,
                occupancy: defaultOccupancy,
                price: 0
            }

            if (has(this.priceItems, [defaultLos.id, defaultOccupancy.id])) {
                price.price = this.priceItems[defaultLos.id][defaultOccupancy.id]
            }

            return price
        },
    },
    methods: {

        saveLock(){
            let request = {
                items: []

            }
            if (this.dateRanges.length > 0) {
                for (const dateObject of this.dateRanges) {
                    for(const losObject of this.los){
                        for(const occupancyObject of this.occupancy){
                            let reqObject = {
                                lock_period_start: dateObject.start,
                                lock_period_end: dateObject.end,
                                lock_until_date: this.until_date,
                                los:losObject.id,
                                occupancy:occupancyObject.id,
                                set_lock: 1,
                            }
                            request.items.push(reqObject)
                        }
                    }
                }
            }
            setPriceLock(this.unit.id, request).then(() => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })
            }).finally(() => {
                this.until_date = null
                this.lockModal = false
            })
        },
        objectHas(object, path) {
            return has(object, path)
        },
        setDefaultValues() {

            if (this.defaultValues && this.defaultValues.hasOwnProperty('price')) {
                each(this.priceItems, (losList, losId) => {
                    each(losList, (occupancyValue, occId) => {
                        let valueFiltered = this.defaultValues.price.find(item => {
                            return item.los_id === Number(losId) && item.occupancy_id === Number(occId)
                        })

                        if (valueFiltered) {
                            this.priceItems[losId][occId] = valueFiltered.amount ? valueFiltered.amount.toString() : null
                        }
                    })
                })

                this.nonEditablePriceItems = cloneDeep(this.priceItems)

            }
        },
        save(lock) {
            let new_pricing = []
            Object.keys(this.priceItems).forEach(losId => {
                Object.keys(this.priceItems[losId]).forEach(occupancyId => {
                    if (Number(this.priceItems[losId][occupancyId]) > 0) {
                        new_pricing.push({
                            los: losId,
                            occupancy: occupancyId,
                            amount: Number(this.priceItems[losId][occupancyId])
                        })
                    }
                })

            })

            if (new_pricing.length === 0) {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NO_CHANGE'),
                    'type': 'error',
                    'timeout': 6000
                })
                return
            }
            let pricing = []
            this.dateRanges.forEach(dateRange => {
                pricing.push({
                    date_from: dateRange.start,
                    date_to: dateRange.end,
                    pricing: new_pricing
                })
            })


            this.saving = !lock ? true : false
            this.savingWithLock = lock
            setUnitPrice(this.unit.id, {
                pricing: pricing,
                allowed_weekday:this.allowedWeekday
            }).then(() => {
                    if(lock){
                        this.saveLock()
                    }
                    EventBus.$emit(GE_CALENDAR_LOAD_PRICES, {
                        value: null,
                        unitId: this.unit.id
                    })
                    EventBus.$emit(GE_CALENDAR_REFRESH_DATA, {
                        value: null,
                        unitId: this.unit.id
                    })
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.nonEditablePriceItems = cloneDeep(this.priceItems)
                },
                (error) => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })

                }
            ).finally(()=>{
                this.saving = false
                this.savingWithLock = false
                this.lockModal = false
            })
        },
        populateData() {

            this.los.forEach(losItem => {
                if (!this.priceItems.hasOwnProperty(losItem.id)) {
                    this.$set(this.priceItems, losItem.id, {})
                }

                this.occupancy.forEach(occItem => {
                    this.$set(this.priceItems[losItem.id], occItem.id, null)
                })
            })

            this.setDefaultValues()
            this.loading = false

        },

        calculatePriceDependancyList(value, los, occupancy) {
            if(!this.enableCalculate){
                return null
            }
            each(this.priceItems, (losList, losId) => {

                let losObject = this.los.find(el => {
                    return el.id == losId
                })
                each(losList, (occupancyValue, occId) => {
                    let occupancyObject = this.occupancy.find(el => {
                        return el.id == occId
                    })

                    if (occupancyObject && losObject) {

                        this.priceItems[losId][occId] = this.calculatePriceDependancy(losObject, occupancyObject, this.mainPriceItems.price)
                    }
                })
            })
        },

        calculatePriceDependancy(los, occupancy, value) {

            if (this.mainPriceItems.los.id == los.id && this.mainPriceItems.occupancy.id == occupancy.id) {
                return value
            }

            let newValue = null
            if (occupancy.discount && occupancy.discount_type) {
                if (occupancy.discount_type === PERC) {
                    newValue = Number(value) + (Number(value) / 100) * Number(occupancy.discount);
                }
                if (occupancy.discount_type === FIX) {

                    newValue = Number(value) + Number(occupancy.discount);
                }
            } else {
                newValue = value
            }

            let amount = !newValue ? value : newValue

            if (los.discount && los.discount_type) {

                if (los.discount_type === PERC) {

                    amount = Number(amount) + (Number(amount) / 100) * Number(los.discount);

                }
                if (los.discount_type === FIX) {

                    amount = Number(amount) + Number(los.discount);
                }
            }
            return Math.ceil(amount)

        }
    },
    watch: {
        defaultValues: {
            handler() {
                this.setDefaultValues()
            },
            deep: true
        },
        los: {
            handler() {
                this.populateData()
            },
            deep: true
        },
        occupancy: {
            handler() {
                this.populateData()
            },
            deep: true
        },
        enableCalculate:{
            handler(newValue, oldValue){
                if(!oldValue && newValue){
                    //  this.calculateOnChange()
                }
            }
        }

    },
    created() {
        const request = {
            key:AC_CALENDAR_EDIT,
            context: {
                company: this.company.id
            },
            data: [{
                uid: C_REVENUE_MANAGER_LOCK_PRICE_V,
                function: C_REVENUE_MANAGER_LOCK_PRICE_V,
                unit:this.unit.id
            }]
        }

        fetchAccessControlData(AC_CALENDAR_EDIT, request).then(response => {
            if(response.data) {
                this.ac_lock_view = response.data[C_REVENUE_MANAGER_LOCK_PRICE_V].visible
            }

        })
        if (!this.los.length || !this.occupancy.length) {
            EventBus.$emit(GE_CALENDAR_LOAD_LOS_AND_OCCUPANCY, {
                unitId: this.unit.id,
                show: null
            })
        } else {
            this.populateData()
        }
        this.enableCalculate = true
    }
}
</script>

<style scoped  type="text/css">

.no-border {
    box-shadow: none;
}

.no-border > div {
    border: none !important;
    padding-left: 0;
}
</style>
