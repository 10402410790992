<template>
<form v-if="companyData" class="mt-4">
    <div v-for="company in companyData">
        <b-card class="repeater-1">
            <div slot="header">
                <b-row>
                    <b-col>
                        <repeater-header class="mb-3">
                            {{company.name}}
                            <app-button v-if="status_eid === 'Accepted'"
                                        @click="changeRules"
                                        variant="link"
                                        button_type="edit"
                                        :show_text="false"
                                        class="action_button">
                            </app-button>
                        </repeater-header>
                    </b-col>
                </b-row>
            </div>
            <b-row>
                <b-col>
                    <label>{{$t('COUNTRY')}}: </label>
                    {{company.country}}
                </b-col>
                <b-col>
                    <label>{{$t('CITY')}}: </label>
                    {{company.city}}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <label>{{$t('EMAIL')}}: </label>
                    {{company.email}}
                </b-col>
                <b-col>
                    <label>{{$t('PHONE')}}: </label>
                    {{company.phone}}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <label>{{$t('LANGUAGE')}}</label>
                    {{company.language}}
                </b-col>
                <b-col>
                    <label>{{$t('NUMBER_OF_UNITS')}}: </label>
                    {{company.unit_number}}
                </b-col>
            </b-row>
            <b-row class="mb-3 mt-3">
                <b-col class="ml-3">
                    <app-button v-for="transitions in company.cm_transitions"
                                class="mr-3"
                                @click="resolveRequest(transitions, company)"
                                :loading="transitions.loading"
                    >
                        {{transitions.label}}
                    </app-button>
                </b-col>
            </b-row>
        </b-card>
        <app-confirmation-dialog
            v-if="confirmation_dialog_state"
            :show="confirmation_dialog_state"
            :title="$t('ACCEPT_REQUEST_TITLE')">
                {{$t('ACCEPT_REQUEST_MSG', {company_name: company.name})}}
            <div slot="modal-footer">
                <b-row>
                    <div class="mr-4 pr-5">
                        <b-checkbox v-model="changeRulesCheck">{{$t('CHANGE_UNIT_RULES_CONFIRMATION')}}</b-checkbox>
                    </div>
                    <div class="mr-3">
                        <app-button @click="acceptRequest(company)" class="mr-2">{{$t('CONFIRM')}}</app-button>
                        <app-button @click="cancelRuleChange(company)">{{$t('CANCEL')}}</app-button>
                    </div>
                </b-row>
            </div>
        </app-confirmation-dialog>
        <app-confirmation-dialog
            v-if="confirmation_decline_state"
            :show="confirmation_decline_state"
            @confirm="declineRequest(company)"
            @cancel="confirmation_decline_state=false"
            :title="$t('DECLINE_REQUEST_TITLE')">
            {{$t('DECLINE_REQUEST_MSG', {company_name: company.name})}}
        </app-confirmation-dialog>
        <app-confirmation-dialog
            v-if="confirmation_activate_state"
            :show="confirmation_activate_state"
            @confirm="activateRequest(company)"
            @cancel="confirmation_activate_state=false"
            :title="$t('ACTIVATION_REQUEST_TITLE')">
            {{$t('ACTIVATION_REQUEST_MSG', {company_name: company.name})}}
        </app-confirmation-dialog>
        <app-aside v-model="unitRules">
            <template slot="header"><app-object-header :label="website_slug" :name="$t('UNIT_ACTIVATION_RULES')" :description="company.name"></app-object-header></template>
            <template v-slot="props">
                <marketplace-unit-activation-rules :website-id="websiteId"
                                                   :distribution-id="distributionId"
                                                   :company-id="company.id"
                                                   mode="save"
                                                   @unit_setup_saved="closeEditUnitRules(company)"
                ></marketplace-unit-activation-rules>
            </template>
        </app-aside>
    </div>
</form>
</template>

<script>
import {getMarketplaceRequest, resolveRequestEmail, setMarketplaceRequest} from "@/services/marketplace";
import RepeaterHeader from "@/components/app/form/RepeaterHeader";
import AppButton from "@/components/app/AppButton/AppButton";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppAside from "@/components/app/form/AppAside";
import MarketplaceUnitActivationRules from "@/components/direct_booking/marketplace/MarketplaceUnitActivationRules";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import {OBJECT_TYPE_COMPANY} from "@/shared/constants";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "MarketplaceResolveRequestForm",
    mixins:[getErrorMessage],
    components:{
        RepeaterHeader,
        AppButton,
        AppButtonDelete,
        AppConfirmationDialog,
        AppAside,
        MarketplaceUnitActivationRules,
        AppObjectHeader
    },
    props:{
        websiteId:{
            type: Number,
            default: null
        },
        distributionId:{
            type: Number,
            default: null,
        },
        status_eid: {
            type: String,
            default: null,
        },
        website_slug:{
            type: String,
            default: null
        }
    },
    data(){
        return{
            companyData: [],
            company_delete: null,
            acceptLoading: false,
            deleteLoading: false,
            acceptDisabled: false,
            deleteDisabled: false,
            transitionActivate: null,
            unitRules: false,
            confirmation_dialog_state: false,
            confirmation_decline_state: false,
            confirmation_activate_state: false,
            changeRulesCheck: false
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods:{
        getData(){
            getMarketplaceRequest({website_id: this.websiteId, status_eid: this.status_eid}).then(response => {
                this.companyData = response.data
                response.data.length === 0 ? this.$emit('noRequest') : ''
            })
        },
        resolveRequest(transition, company){
            if (transition.label === 'Accept'){
                this.confirmation_dialog_state =true
                this.transitionActivate = transition
            }else if (transition.label === 'Decline'){
                this.confirmation_decline_state  = true
                this.transitionActivate = transition
            }else if(transition.label === 'Activate'){
                this.confirmation_activate_state = true
                this.transitionActivate = transition
            } else{
                transition.loading = true
                setMarketplaceRequest({
                    website_id: this.websiteId,
                    company_id: company.id,
                    next_status_id: transition.next_status,
                    lang_id: transition.lang_id,
                    object_id: OBJECT_TYPE_COMPANY
                }).then(response => {
                    notifySuccess()
                    this.getData()
                    transition.loading = false
                    EventBus.$emit('reloadMarketplaceGeneralTable')
                }, error => {
                    transition.loading = false
                    this.showErrorMessages(error)
                })
            }
        },
        acceptRequest(company){
            if (this.changeRulesCheck === true){
                this.unitRules = true
            }
            this.confirmation_dialog_state = false
            if (this.transitionActivate !== null){
                this.transitionActivate.loading = true
                setMarketplaceRequest({
                    website_id: this.websiteId,
                    company_id: company.id,
                    next_status_id: this.transitionActivate.next_status,
                    lang_id: this.transitionActivate.lang_id,
                    object_id: OBJECT_TYPE_COMPANY
                }).then(response => {
                    notifySuccess()
                    if (this.changeRulesCheck === false){
                        this.getData()
                    }
                    this.transitionActivate.loading = false
                    EventBus.$emit('reloadMarketplaceGeneralTable')
                    resolveRequestEmail({company_to_id: company.id, company_id: this.company.id, website_id: this.websiteId, resolve: 'Accept'})
                }, error => {
                    this.transitionActivate.loading = false
                    this.showErrorMessages(error)
                })
            }
        },
        closeEditUnitRules(company){
            this.unitRules = false
            this.getData()
        },
        cancelRuleChange(company){
            this.confirmation_dialog_state = false
        },
        declineRequest(company){
            this.confirmation_decline_state = false
            this.transitionActivate.loading = true
            setMarketplaceRequest({
                website_id: this.websiteId,
                company_id: company.id,
                next_status_id: this.transitionActivate.next_status,
                lang_id: this.transitionActivate.lang_id,
                object_id: OBJECT_TYPE_COMPANY
            }).then(response => {
                notifySuccess()
                this.getData()
                this.transitionActivate.loading = false
                EventBus.$emit('reloadMarketplaceGeneralTable')
                resolveRequestEmail({company_to_id: company.id, company_id: this.company.id, website_id: this.websiteId, resolve: 'Decline'})
            }, error => {
                this.transitionActivate.loading = false
                this.showErrorMessages(error)
            })
        },
        activateRequest(company){
            this.confirmation_activate_state = false
            this.transitionActivate.loading = true
            setMarketplaceRequest({
                website_id: this.websiteId,
                company_id: company.id,
                next_status_id: this.transitionActivate.next_status,
                lang_id: this.transitionActivate.lang_id,
                object_id: OBJECT_TYPE_COMPANY
            }).then(response => {
                notifySuccess()
                this.getData()
                this.transitionActivate.loading = false
                EventBus.$emit('reloadMarketplaceGeneralTable')
            }, error => {
                this.transitionActivate.loading = false
                this.showErrorMessages(error)
            })
        },
        changeRules(){
            this.unitRules = true
        },
    },
    created() {
        this.getData()
    },
    watch:{
        unitRules:{
            handler(){
                if (this.unitRules === false){
                    this.getData()
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
