<template>
    <div>
        <calendar-wrapper-test :display_work_mode="display_work_mode"
        ></calendar-wrapper-test>
    </div>
</template>

<script>

import {
    LS_PRESETS,
    MULTI_UNIT_VIEW,
    DEFAULT_UNIT_NUMBER_LIMIT,
    MONTHLY_VIEW,
} from "@/shared/constants";
import CalendarWrapperTest from "@/components/calendar/CalendarWraperTest";
import {getPresets} from "@/services/user";

export default {
    name: "Index",
    components: {CalendarWrapperTest},
    data() {
        return {
            MULTI_UNIT_VIEW,
            MONTHLY_VIEW,
            property_count: null,
            root_unit_count: 0,
            display_work_mode: MULTI_UNIT_VIEW,
        }
    },
    computed: {
        companyId() {
            return this.$store.getters['user/getCompany']
        }
    },
    methods: {
        displayWorkMode(storagePresets) {
            if (storagePresets && typeof storagePresets === 'object') {
                if (storagePresets.hasOwnProperty('root_units')) {
                    this.root_unit_count = storagePresets.root_units
                    if (this.root_unit_count > DEFAULT_UNIT_NUMBER_LIMIT) {
                        this.display_work_mode = MULTI_UNIT_VIEW
                    } else {
                        this.display_work_mode = MONTHLY_VIEW
                    }
                }
            }
        }
    },
    mounted() {
        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)
            this.displayWorkMode(storagePresets)
        } else {
            getPresets().then(response => {
                localStorage.setItem(LS_PRESETS, JSON.stringify(response.data))
                this.displayWorkMode(response.data)
            })
        }
    }
}
</script>

<style scoped>

</style>
