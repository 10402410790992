<template>
    <app-translation-input :languages="supportedLanguages"
                           v-model="descriptionObject.value"
                           :translations="translations"
                           :lang_id="translations !== null ? null : description.lang_id"
                           component="textarea"
                           :rows="rows"
                           :max-length="maxLength"
                           no-resize
                           :sidebar-description="translationSidebarDescription"
                           :disabled="disabled"
                           :width="width"
                           v-if="descriptionObject !== null"
                           @input="descriptionChange"
                           @updateTranslations="updateTranslations">
    </app-translation-input>
</template>

<script>
import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
import {cloneDeep} from "lodash";
import {DEFAULT_LANGUAGE} from "@/shared/constants";

export default {
    name: "DescriptionTextArea",
    components: {AppTranslationInput},
    props: {
        description: {
            type: Object,
            default() {
                return null
            },
        },
        rows: {
            type: Number,
            default: 15,
        },
        maxLength: {
            type: Number,
            default: null,
        },
        languageList: {
            type: Array,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        width: {
            default: () => {
                return ['col-lg-5', 'col-sm-12', '40%']
            },
        },
    },
    computed: {
        translations() {
            return null
            return this.descriptionObject.hasOwnProperty('translations') ? this.description.translations : null
        },
        supportedLanguages() {
            let supportedLanguages = []
            if (this.languageList !== null) {
                supportedLanguages = this.languageList
            } else {
                if (this.descriptionObject.hasOwnProperty('translations')
                    && this.descriptionObject.translations !== null) {
                    let translationList = this.descriptionObject.translations
                    Object.keys(translationList).forEach((language) => {
                        if (language !== DEFAULT_LANGUAGE) {
                            supportedLanguages.push(language)
                        }
                    })
                }
            }
            return supportedLanguages
        },
        translationSidebarDescription() {
            return this.descriptionObject !== null
            && this.descriptionObject.hasOwnProperty('label')
            && this.descriptionObject.label !== null
                ? this.descriptionObject.label : ''
        },
    },
    data() {
        return {
            descriptionObject: null,
        }
    },
    methods: {
        descriptionChange() {
            this.$emit('update', this.descriptionObject)
        },
        updateTranslations(translations) {
            if (translations !== null && typeof translations === 'object') {
                for (const [lang, value] of Object.entries(translations)) {
                    this.$set(this.descriptionObject.translations, lang, value)
                }
            }
            this.$emit('update', this.descriptionObject)
        },
    },
    watch: {
        description: {
            handler(value) {
                this.descriptionObject = cloneDeep(value)
            },
            deep: true,
            immediate: true,
        },
    }
}
</script>

<style scoped>

</style>
