import http from "@/services/http";
import {EVENT_TEMPLATE_LINK_SYSTEM_TEMPLATE_URL, EVENT_TEMPLATE_URL} from "@/services/endpoints";

export function getTemplatePlaceholders(eventId) {
    return http.request({
        url: 'adminapi/event/' + eventId + '/placeholders',
        method: 'get'
    })
}

export function previewEventTemplateMail(data) {
    return http.request({
        url: EVENT_TEMPLATE_URL + '/preview',
        method: 'put',
        data: data
    })
}

export function getEventMailRecipient() {
    return http.request({
        url: EVENT_TEMPLATE_URL + '/recipients',
        method: 'get'
    })
}

export function getSystemEventTemplatesList(filter) {
    return http.request({
        url: '/adminapi/system-event-templates',
        method: 'get',
        params: filter
    })
}

export function getEventTemplates(params) {
    return http.request({
        url: EVENT_TEMPLATE_URL,
        method: 'get',
        params
    })
}

export function linkSystemEventTemplate(template_id, data) {
    return http.request({
        url: EVENT_TEMPLATE_LINK_SYSTEM_TEMPLATE_URL(template_id),
        method: 'put',
        data: data
    })
}
