<template>

    <b-row>
        <b-col class="m-5">
            <div>
                <div class="d-flex justify-content-between">
                    <h5 class="main_calendar--header">Unit: dummy unit</h5>
                    <div>
                        <b-button class="mr-3" variant="outline-primary">Options</b-button>
                        <b-button variant="primary">Edit</b-button>
                    </div>
                </div>
                <div class="main_calendar__holder">
                    <div class="main_calendar__holder--sidebar">
                        <div class=""></div>
                        <div
                            class="main_calendar__holder--sidebar-h1 d-flex align-items-center  main_calendar--background_gray main_calendar--dropdown-close">
                            Availability
                        </div>
                        <div class="main_calendar__holder--sidebar-h2 d-flex align-items-center">Apartment Aura - 1
                        </div>
                        <div
                            class="main_calendar__holder--sidebar-h1 d-flex align-items-center main_calendar--background_gray">
                            Unit status
                        </div>
                        <div
                            class="d-flex flex-column justify-content-around align-items-start main_calendar--background_gray">
                            <div class="main_calendar__holder--sidebar-h1">Rates</div>
                            <div class="d-flex justify-content-around w-100">
                                <div class="main_calendar__holder--sidebar-h3">Length of stay</div>
                                <div class="main_calendar__holder--sidebar-h3">Occupancy</div>
                            </div>
                        </div>
                        <div class="main_calendar__holder--sidebar--pricing">
                            <template v-for="los in los_data">
                                <template v-for="occupancy in occupancy_data">
                                    <div class="d-flex align-items-center justify-content-center">{{los.name}}</div>
                                    <div class="d-flex align-items-center justify-content-center">{{occupancy.name}}
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div
                            class="main_calendar__holder--sidebar-h1 d-flex align-items-center main_calendar--background_gray">
                            Restrictions
                        </div>
                    </div>
                    <div class="main_calendar__holder--content">
                        <div class="main_calendar__holder--content_dates">
                            <div class="main_calendar__cell d-flex flex-column justify-content-around"
                                 v-for="date in dateArray">
                                <span>{{date.format('DD')}}</span>
                                <span>{{date.format('dd')}}</span>
                            </div>
                        </div>

                        <div class="main_calendar__holder--content_dates">
                            <div class="main_calendar__cell d-flex flex-column justify-content-around"
                                 v-for="date in dateArray">
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </b-col>
    </b-row>


</template>

<script>
    import moment from 'moment'

    export default {
        name: "DevZoneCalendar",
        data() {
            return {
                columns: 60,
                dates: {
                    start: "2019-12-04",
                    end: "2020-01-25"
                },
                los_data: [
                    {id: 1, name: '1 - 5'},
                    {id: 2, name: '6 - 8'},
                    {id: 3, name: '8 +'},
                ],
                occupancy_data: [
                    {id: 1, name: '1 - 1'},
                    {id: 2, name: '2 - 2'},
                ]
            }
        },
        computed: {
            dateArray() {
                let dateArray = [];
                let currentDate = moment(this.dates.start);
                let stopDate = moment(this.dates.end);
                while (currentDate <= stopDate) {
                    dateArray.push(moment(currentDate))
                    currentDate = moment(currentDate).add(1, 'days');
                }
                return dateArray;
            }
        }
    }
</script>

<style scoped>

</style>
