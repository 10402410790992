<template>
    <div>
        <b-form autocomplete="off" @submit.prevent="setAccessData">
            <b-row class="mb-4">
                <b-col sm="12" md="6" lg="4">
                    <app-input-control :error-object="validationErrorObject.facility_code">
                        <template v-slot:input="data">
                            <label>{{$t('FACILITY_CODE')}}</label>
                            <b-form-input v-model="objectAccessData.facility_code" type="text"></b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col sm="12" md="6" lg="4">
                    <app-input-control :error-object="validationErrorObject.username">
                        <template v-slot:input="data">
                            <label>{{$t('USERNAME')}}</label>
                            <b-form-input v-model="objectAccessData.username" type="text"></b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col sm="12" md="6" lg="4">
                    <app-input-control :error-object="validationErrorObject.password">
                        <template v-slot:input="data">
                            <label>{{$t('PASSWORD')}}</label>
                            <b-form-input v-model="objectAccessData.password" type="password"></b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <app-submit :loading="data_loaded" :disabled="disableSave"></app-submit>
                </b-col>
                <b-col md="6" v-if="objectAccessData.hasOwnProperty('id') && objectAccessData.id !== null">
                    <app-button-delete  class="pull-right" @click="deleteDialogShow=true"></app-button-delete>
                </b-col>
                <delete-confirm :show="deleteDialogShow" @confirm="deleteConfirm" @cancel="deleteDialogShow=false" :delete_title="true"></delete-confirm>
            </b-row>
        </b-form>
    </div>
</template>

<script>
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {getGuestAccessData} from "@/services/guest";
    import {IdReaderSrb} from "@/shared/constants";
    import {
        GUEST_ACCESS_DATA_USERNAME_VALIDATION_FAILED,
        GUEST_ACCESS_DATA_PASSWORD_VALIDATION_FAILED,
        GUEST_ACCESS_DATA_FACILITY_CODE_VALIDATION_FAILED
    } from "@/shared/error_codes";
    import AppInputControl from "@/components/app/AppInputControl";
    import FormHeader from "@/components/app/form/FormHeader";
    import {toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import DeleteConfirm from '@/components/app/form/AppConfirmationDialog'
    import {cloneDeep} from "lodash";


    export default {
        name: "guest-check-in-id-reader",
        props: {
            property: {
                type: Object
            },
            data_loaded:{
                type:Boolean,
                default:false
            },
            deleted_flag:{
                type:Boolean,
                default:false
            },
            accessData:{
                type:Object
            }

        },
        components: {
            FormHeader,
            AppInputControl,
            AppSubmit,
            DeleteConfirm,
            AppButtonDelete

        },
        mixins:[getErrorMessage],

        data(){
            return {
                objectAccessData: {
                    facility_code: null,
                    username: null,
                    password: null,
                },
                validationErrorObject: {
                    facility_code: {
                        input: [GUEST_ACCESS_DATA_FACILITY_CODE_VALIDATION_FAILED]
                    },
                    username: {
                        input: [GUEST_ACCESS_DATA_USERNAME_VALIDATION_FAILED]
                    },
                    password: {
                        input: [GUEST_ACCESS_DATA_PASSWORD_VALIDATION_FAILED]
                    },
                },
                disableSave: false,
                deleteDialogShow:false
            }
        },
        methods:{
            setAccessData(){
                this.$emit('setData', this.objectAccessData)
            },
            resetData(){
                this.objectAccessData.id = null
                this.objectAccessData.facility_code = null
                this.objectAccessData.username = null
                this.objectAccessData.password = null
            },
            deleteConfirm(){
                this.$emit('deleteData', this.objectAccessData.id)
                this.deleteDialogShow = false
            }
        },

        watch:{
            '$route.params.id':{
                handler(value){
                        getGuestAccessData(value,{connection:IdReaderSrb}).then(response => {
                            this.disableSave = false
                            this.objectAccessData = {connection: IdReaderSrb, ...response.data}
                            }, error => {
                                this.resetData()
                                this.disableSave = true
                                toast({
                                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                                    'message': this.getMessages(error),
                                    'type': 'error',
                                    'timeout': 3000
                                })
                    }
                        )
                },
                immediate:true
            },
            deleted_flag:{
                handler(value){
                    if(value){
                        this.resetData()
                    }
                }
            },
            accessData:{
                handler(value){
                    this.objectAccessData = {connection: IdReaderSrb, ...cloneDeep(value)}

                }
            }
        },
    }
</script>

<style scoped>

</style>
