<template>
    <div :class="occupancy.length > 1 && los.length > 1 ? 'main_calendar_test__holder--sidebar--rate-plans' : 'main_calendar_test__holder--sidebar--rate-plans-one-item'" class="animated fadeIn">
        <template v-for="losItem in los">
            <template v-for="occupancyItem in occupancy">

                <div v-if="los.length > 1" class="d-flex align-items-center justify-content-center main_calendar_test-br" :class="{'main_calendar_test--background_gray': losItem.default && occupancyItem.default}">
                    <span class="pr-1">{{losItem.min_stay}}</span>
                    <span v-if="losItem.max_stay && losItem.max_stay > losItem.min_stay"> - {{losItem.max_stay}}</span>
                    <span v-else-if="!losItem.max_stay">+</span>
                    <span class="ml-2"
                          v-if="checkPermission(C_PROPERTY_CALENDAR_PRICES_LOS_LINKED_VALUE) && Number(losItem.default) !== 1 && Number(losItem.discount) !== 0">
                        ({{ Number(losItem.discount_type) === PERC ? (Number(losItem.discount) > 0 ? '+' + losItem.discount + '%' : losItem.discount + '%') : (Number(losItem.discount) > 0 ? '+' + losItem.discount : losItem.discount) }})
                    </span>
                </div>

                <div v-if="occupancy.length > 1" class="d-flex align-items-center justify-content-center" :class="{'main_calendar_test--background_gray': losItem.default && occupancyItem.default}">
                    <span class="pr-1">{{occupancyItem.min_guests}}</span>
                    <span v-if="occupancyItem.max_guests && occupancyItem.max_guests > occupancyItem.min_guests"> - {{occupancyItem.max_guests}}</span>
                    <span v-else-if="!occupancyItem.max_guests">+</span>
                    <span class="ml-2"
                          v-if="checkPermission(C_PROPERTY_CALENDAR_PRICES_OCCUPANCY_LINKED_VALUE) && Number(occupancyItem.default) !== 1 && Number(occupancyItem.discount) !== 0">
                        ({{ Number(occupancyItem.discount_type) === PERC ? (Number(occupancyItem.discount) > 0 ? '+' + occupancyItem.discount + '%' : occupancyItem.discount + '%') : (Number(occupancyItem.discount) > 0 ? '+' + occupancyItem.discount : occupancyItem.discount) }})
                    </span>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import {DISTRIBUTION_PHOTO} from "@/services/endpoints"
    import {has} from 'lodash'
    import {
        C_PROPERTY_CALENDAR_PRICES_LOS_LINKED_VALUE,
        C_PROPERTY_CALENDAR_PRICES_OCCUPANCY_LINKED_VALUE
    } from "@/shared/component_permission";
    import {PERC} from "@/shared/constants";

    export default {
        name: "TableRatePlanSidebarItem",
        props: {
            ratePlan: {
                type: Object
            },
            los: {
                type: Array
            },
            occupancy: {
                type: Array
            }
        },
        computed: {
            asideRows() {
                return {
                    'gridColumn': '1 / 1',
                    'gridRow': `1 / span ${this.los.length * this.occupancy.length}`,
                }
            },
            distributionPhotoUrl() {
                if (has(this.ratePlan, 'distribution')) {
                    return DISTRIBUTION_PHOTO(this.ratePlan.distribution)
                }
                return DISTRIBUTION_PHOTO(0)
            },
            showOnlyIcon(){
                return this.ratePlan.name.length > ((this.los.length * this.occupancy.length) * 6)
            },
            ratePlanTooltipText(){
                if(this.showOnlyIcon){
                    return this.ratePlan.name + ' - ' + this.ratePlan.distribution_name
                }
                return this.ratePlan.distribution_name
            },
            ratePlanNameFormatted(){
                if(this.showOnlyIcon){
                    return this.ratePlan.name.substring(0, ((this.los.length * this.occupancy.length) * 6) - 3) + '...'
                }
                return this.ratePlan.name
            }
        },
        data() {
            return {
                C_PROPERTY_CALENDAR_PRICES_LOS_LINKED_VALUE,
                C_PROPERTY_CALENDAR_PRICES_OCCUPANCY_LINKED_VALUE,
                PERC,
            }
        }
    }
</script>

<style scoped>

</style>
