<template>
    <b-form @submit.prevent="save">
        <b-row class="mt-2">
            <b-col>
                <form-header>{{$t('DEFAULT_RATE')}}</form-header>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="4" lg="4" md="6" class="mb-4">
                <label class="label">{{$t('AMOUNT')}}</label>
                <app-number-input :min="1" v-model="formDefaultValue.amount"></app-number-input>

            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <form-header>{{$t('DEFAULT_RESTRICTIONS')}}</form-header>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="4" class="mb-4" :key="restriction.id" v-if="restriction.id < 4" v-for="(restriction) in restrictions">
                <component
                    :restriction="restriction"
                    v-model="formDataRestrictions[restriction.id]"
                    :allowed_values="restriction.allowed_values.allowed_values"
                    :is="restriction.allowed_values.element_type + 'Element'">
                </component>

            </b-col>
        </b-row>
        <b-row :key="restriction.id+'_res_key'"  v-if="restriction.id > 3" v-for="(restriction) in restrictions">
            <b-col  class="mb-4">
                <component
                    :restriction="restriction"
                    v-model="formDataRestrictions[restriction.id]"
                    :allowed_values="restriction.allowed_values.allowed_values"
                    :is="restriction.allowed_values.element_type + 'Element'">
                </component>
            </b-col>
        </b-row>
            <b-row>
                <b-col>
                    <save-button :loading="loading" :c_permission="c_permission_save"></save-button>
                </b-col>
            </b-row>

    </b-form>
</template>

<script>
    import SaveButton from "@/components/app/AppButton/AppButtonSubmit";
    import FormHeader from "@/components/app/form/FormHeader";
    import {cloneDeep, filter, find, isNull, reduce} from 'lodash'
    import {OBJECT_TYPE_PROPERTY, OBJECT_TYPE_UNIT, SALES_ENABLED} from "@/shared/constants";
    import SelectRestrictionFormElement
        from "@/components/unit/calendar/forms/restriction/SelectRestrictionFormElement";
    import CheckboxRestrictionFormElement
        from "@/components/unit/calendar/forms/restriction/CheckboxRestrictionFormElement";
    import {setInventoryDefaultValues} from "@/services/property";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {
        INVENTORY_DEFAULT_RESTRICTION_MIN_STAY_VALIDATION_FAILED

    } from "@/shared/error_codes";
    export default {
        name: "InventoryDefaultValueForm",
        components: {
            AppNumberInput,
            FormHeader,
            SaveButton,
            'selectElement': SelectRestrictionFormElement,
            'checkboxElement': CheckboxRestrictionFormElement,
        },
        props:{
          object_type:{
              type:Number
          },
          object_id:{
              type:Number
          },
          c_permission_save:{
              type:Number
            },
          defaultValueObject:{
              type:Object
          }
        },
        mixins:[getErrorMessage],
        data(){
          return{
              loading:false,
              formDefaultValue:{},
              formDataRestrictions:{},
              initialRestrictionFormData:{},
          }
        },

        computed:{
            // RESTRICTION SALES_ENABLED IS REMOVED
            restrictions() {
                let r = this.$store.getters[`shared/getRestrictions`]
                return r ? filter(r, (r) => r.id !== SALES_ENABLED) : null
            },
            getObjectTypeString(){
                if(this.object_type === OBJECT_TYPE_PROPERTY){
                    return 'property'
                }
                if(this.object_type === OBJECT_TYPE_UNIT){
                    return 'unit'
                }
                return null
            },

        },
        methods:{
            save(){

                this.loading = true
                let requests = {
                    object_id:this.object_id,
                    object_type: this.getObjectTypeString,
                    restrictions: this.formDataRestrictions,
                    price_amount: this.formDefaultValue.hasOwnProperty('amount')  ? this.formDefaultValue.amount : null
                }
                setInventoryDefaultValues(requests).then(()=>{
                    notifySuccess()
                },error=>{
                    this.showErrorMessages(error)
                }).finally(()=>{
                    this.loading = false
                })

            },
            mapValuesToRestrictions(object_type, object_id, restrictions, restrictionData) {
                this.formDataRestrictions = reduce(restrictions, (result, restriction) => {
                        let resObj = find(restrictionData, o => o.id === restriction.id)
                        result[restriction.id] = resObj ? resObj.value : null
                        return result
                    }, {})
                    this.$nextTick(() => {
                        this.initialRestrictionFormData = cloneDeep(this.formData)
                    })
            },
        },

        watch:{
            'defaultValueObject':{
                handler(object){
                    this.formDefaultValue = cloneDeep(object)
                    if (isNull(this.restrictions)) {
                        this.$store.dispatch(`shared/actionSetRestrictions`).then(res => {
                            this.mapValuesToRestrictions(this.object_type, this.object, res, object.restrictions)
                        })
                    } else {
                        this.mapValuesToRestrictions(this.object_type, this.object, this.restrictions, object.restrictions);
                    }

                },immediate:true,
                  deep:true
            }
        }
    }

</script>

<style scoped>

</style>
