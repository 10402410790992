<template>
    <div v-cloak class="search_message mt-5">
        <b-img class="search_init_icon" src="/img/icons/search_icon.png"></b-img>
        <h4 class="text-primary font-weight-light">{{h3}}</h4>
        <h6 class="font-weight-light mt-2">{{h6}}</h6>
    </div>
</template>

<script>
    export default {
        name: "AppSearchData",
        props: {
            heading: {
                type: String,
                default: null
            },
            text: {
                default: false
            },
        },
        computed: {
            h3() {
                return this.heading !== null ? this.heading : this.$t('NEW_SEARCH_HEADER')
            },
            h6() {
                return this.text === false ? '' : this.text !== null ? this.text : this.$t('NEW_SEARCH_TIP')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .search_message {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & img {
            height: auto;
            width: 12rem;
            margin-bottom: 2rem;
            margin-top: 2rem;
        }

        .text-primary {
            color: var(--color-text-primary) !important;
        }
    }

</style>
