<template>
<form v-if="companies" class="mt-4">
    <div v-for="company in companies">
        <b-card class="repeater-1">
            <div slot="header">
                <b-row>
                    <b-col>
                        <repeater-header class="mb-3">
                            {{company.name}}
                        </repeater-header>
                    </b-col>
                </b-row>
            </div>
            <b-row>
                <b-col v-if="company.county">
                    <label>{{$t('COUNTRY')}}: </label>
                    {{company.country}}
                </b-col>
                <b-col v-if="company.city">
                    <label>{{$t('CITY')}}: </label>
                    {{company.city}}
                </b-col>
            </b-row>
            <b-row>
                <b-col v-if="company.contact">
                    <label>{{$t('CONTACT')}}: </label>
                    {{company.contact}}
                </b-col>
                <b-col v-if="company.currency">
                    <label>{{$t('CURRENCY')}}: </label>
                    {{company.currency}}
                </b-col>
            </b-row>
            <b-row>
                <b-col v-if="company.language">
                    <label>{{$t('LANGUAGE')}}</label>
                    {{company.language}}
                </b-col>
                <b-col>
                    <label>{{$t('NUMBER_OF_UNITS')}}: </label>
                    {{company.unit_number}}
                </b-col>
            </b-row>
            <b-row class="mt-3 mb-3">
                <b-col class="ml-3">
                    <app-button
                        v-for="transition in company.cm_transitions"
                        @click="cancelCompany(transition, company)"
                        class="mr-3"
                        :loading="removeLoading"
                    >{{transition.label}}</app-button>
                    <app-button @click="changeRules">{{$t('UNIT_ACTIVATION_RULES')}}</app-button>
                </b-col>
            </b-row>
        </b-card>
        <app-confirmation-dialog
            v-if="confirmation_dialog_state"
            :show="confirmation_dialog_state"
            @confirm="deleteConfirm"
            @cancel="confirmation_dialog_state=false"
            :title="$t('REMOVE_COMPANY_TITLE')">
            {{$t('REMOVE_COMPANY_MESSAGE', {value: removeCompanyData.name})}}
        </app-confirmation-dialog>
        <app-aside v-model="editUnitRules">
            <template slot="header"><app-object-header :name="$t('UNIT_ACTIVATION_RULES')"></app-object-header></template>
            <template v-slot="props">
                <marketplace-unit-activation-rules :website-id="websiteId"
                                                   :distribution-id="distributionId"
                                                   :company-id="company.id"
                                                   mode="save"
                                                   @unit_setup_saved="closeEditUnitRules"
                ></marketplace-unit-activation-rules>
            </template>
        </app-aside>
    </div>
</form>
</template>

<script>
import {
    fetchMarketplaceWebsiteCompanies,
    deleteMarketplaceWebsiteCompany,
    setMarketplaceRequest
} from "@/services/marketplace";
import RepeaterHeader from "@/components/app/form/RepeaterHeader";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import MarketplaceUnitActivationRules from "@/components/direct_booking/marketplace/MarketplaceUnitActivationRules";
import {OBJECT_TYPE_COMPANY} from "@/shared/constants";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "MarketplaceEditCompaniesForm",
    mixins:[getErrorMessage],
    components:{
        RepeaterHeader,
        AppButton,
        AppConfirmationDialog,
        AppAside,
        AppObjectHeader,
        MarketplaceUnitActivationRules
    },
    props:{
        websiteId:{
            type: Number,
            default: null
        },
        distributionId:{
            type: Number,
            default: null
        }
    },
    data(){
        return{
            companies: [],
            removeCompanyData: null,
            removeLoading: false,
            confirmation_dialog_state: false,
            editUnitRules: false
        }
    },
    methods:{
        getData(){
            fetchMarketplaceWebsiteCompanies({website_id: this.websiteId}).then(response => {
                this.companies = response.data
                response.data.length === 0 ? this.$emit('noCompanies') : ''
            })
        },
        cancelCompany(transition, company){
            this.removeLoading = true
            setMarketplaceRequest({
                website_id: this.websiteId,
                company_id: company.id,
                next_status_id: transition.next_status,
                lang_id: transition.lang_id,
                object_id: OBJECT_TYPE_COMPANY
            }).then(response => {
                notifySuccess()
                this.getData()
                this.removeLoading = false
                EventBus.$emit('reloadMarketplaceGeneralTable')
            }, error => {
                this.removeLoading = false
                this.showErrorMessages(error)
            })
        },
        changeRules(){
            this.editUnitRules = true
        },
        closeEditUnitRules(){
            this.editUnitRules = false
        }
    },
    created() {
        this.getData()
    }
}
</script>

<style scoped>

</style>
