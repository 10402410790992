<template>
    <b-form @submit.prevent="save">
        <b-row class="mb-3">
            <b-col lg="6" class="mb-3">
                <label>{{ $t("_DATE") }}</label>
                <app-date-picker v-model="returnTransferDate"
                                 :min-date="formData.transfer_date"
                ></app-date-picker>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{ $t("TIME") }}</label>
                <time-picker v-model="returnTransferTime"></time-picker>
            </b-col>
        </b-row>
        <b-row class="mb-3">
            <b-col>
                <b-form-checkbox
                    v-model="notifyDriverOfCancellation"
                    :checked-value="true" :unchecked-value="false">
                    {{ $t("TRANSFER_CANCELLATION_NOTIFY") }}
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-3">
                <app-submit :loading="loading" :disabled="saveDisabled"></app-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import {
    setReturnTransferReservation,
} from "@/services/transfer";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import TimePicker from "@/components/app/datetime/TimePicker";
import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButton from "@/components/app/AppButton/AppButton";
import {notifySuccess} from '@/shared/plugins/toastr'
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "TransferReservationForm",
    components: {
        AppButton,
        AppSubmit,
        TimePicker,
        AppDatePicker
    },
    mixins: [getErrorMessage],
    props: {
        transferData: {
            type: Object
        }
    },
    data() {
        return {
            formData: {},
            returnTransferDate: null,
            returnTransferTime: null,
            notifyDriverOfCancellation: false,
            loading: false
        }
    },
    computed: {
        saveDisabled() {
            let disabled = false
            if (!this.returnTransferDate || !this.returnTransferTime) {
                disabled = true
            }
            return disabled
        },
    },
    methods: {
        save() {
            this.loading = true
            setReturnTransferReservation(this.formData.id, {
                date: this.returnTransferDate,
                time: this.returnTransferTime,
                notify_driver_of_cancellation: this.notifyDriverOfCancellation
            }).then(response => {
                notifySuccess()
                this.$emit('addReturnTransfer')
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
            })
        }
    },
    created() {
        this.formData = this.transferData
        if (this.transferData && this.transferData.hasOwnProperty('accommodation_reservation') && this.transferData.accommodation_reservation
            && this.transferData.accommodation_reservation.hasOwnProperty('check_out_date') && this.transferData.accommodation_reservation.check_out_date) {
            this.returnTransferDate = this.transferData.accommodation_reservation.check_out_date
        }
    }
}
</script>

<style scoped>

</style>
