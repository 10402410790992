<template>
    <div>
        <b-table responsive  small :fields="suggestion_fields" :items="suggestion_list">
            <template v-slot:periods="data">
                <app-btn :id="`periodPopover-${data.item.id}`" variant="link">{{$t('PERIODS')}}</app-btn>
                <b-popover :target="`periodPopover-${data.item.id}`"
                           placement="left"
                           triggers="focus hover">
                    <h6 :key="periodIndex" v-for="(period, periodIndex) in data.item.sales_periods">
                        {{period.period_start}}
                        - {{period.period_end}}</h6>
                    <h6 v-if="data.item.sales_periods.length === 0">{{$t('NO_DATA')}}</h6>
                </b-popover>
            </template>
            <template v-slot:edit="data">
                <app-btn :c_permission="C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_E" v-if="data.item.sales_periods.length > 0"
                         @click="showDialog(data.item.id)">
                    {{$t('APPLY')}}
                </app-btn>
            </template>
        </b-table>
        <dialog-box :title="$t('SUGGESTION_PERIODS_TITLE_DIALOG_BOX')"  :show="dialogBox" @confirm="applySuggestion" @cancel="cancelShowDialogBox">
            {{$t('APPLY_SUGGESTION_PERIODS_DIALOG_BOX')}}
        </dialog-box>
    </div>
</template>

<script>

    import AppBtn from '@/components/app/AppButton/AppButton'
    import {getPeriodSuggestions} from '@/services/pricing/index'
    import DialogBox from '@/components/app/form/AppConfirmationDialog'
    import {C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_E} from "@/shared/component_permission";

    export default {
        name: "PeriodSuggestionList",
        components: {
            AppBtn, DialogBox
        },
        props: {
            unit_id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                suggestion_fields: [

                    {key: "name", label: this.$t('NAME')},
                    {key: "periods", label: this.$t('PERIODS')},
                    {key: "edit", label: ''}
                ],
                suggestion_list: [],
                dialogBox: false,
                suggestion_period_id: null,
                collapse: false,
                C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_E
            }
        },
        methods: {
            applySuggestion() {
                this.$store.dispatch('pricing/actionApplyPricingPeriodSuggestion', {
                    unit_id: this.unit_id,
                    sales_period_setup_id: this.suggestion_period_id
                }).then(() => {
                    this.dialogBox = false
                    this.$emit('closeAside')
                    this.suggestion_period_id = null
                })
            },
            showDialog(id) {
                this.suggestion_period_id = id
                this.dialogBox = true
            },
            cancelShowDialogBox() {
                this.dialogBox = false
                this.suggestion_period_id = null
            }
        },
        created() {
            getPeriodSuggestions(this.unit_id).then(response => {
                this.suggestion_list = response.data.length > 0 ? response.data.filter(el=> el.sales_periods.length > 0) : []
            }, () => {
                this.suggestion_list = []
            })
        }
    }
</script>

<style scoped>

</style>
