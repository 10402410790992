<template>
    <div>
        <distribution-list v-if="property.unit_count_list.total_count > 0"></distribution-list>
        <app-no-data v-else-if="property.unit_count_list.total_count === 0" :heading="$t('MODULE_NOT_ACTIVE')" :tip="$t('MODULE_NEED_UNITS')" :show-tip="true">
            <app-button  @AccessControlEvent="addToAccessControlCounter()"
                         :access_control_context="{function:C_PROPERTY_NAVIGATION_HEADER_BUTTON, key:AC_PROPERTY_NO_DATA_BUTTON_KEY}"  button_type="new" @click="openUnitWizard">
                {{ $t('NEW_UNIT') }}
            </app-button>
        </app-no-data>
        <app-aside :widths="['col-sm-12', '40%']" v-model="aside">
            <template slot="header">{{$t('NEW_UNIT')}}</template>
            <unit-wizard v-if="property" :property="property"></unit-wizard>
        </app-aside>
    </div>
</template>

<script>
    import DistributionList from "@/components/channel_manager/distribution/DistributionList"
    import AppNoData from "@/components/app/AppNoData";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppAside from '@/components/app/form/AppAside'
    import UnitWizard from "@/components/unit/new/UnitWizard";
    import {GE_OPEN_UNIT_WIZARD, EventBus} from "@/shared/EventBus";
    import {AC_PROPERTY_NO_DATA_BUTTON_KEY} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {C_PROPERTY_NAVIGATION_HEADER_BUTTON} from "@/shared/component_permission";
    export default {
        name: "index",
        mixins:[AccessControlComponent],
        data() {
            return {
                AC_PROPERTY_NO_DATA_BUTTON_KEY,
                C_PROPERTY_NAVIGATION_HEADER_BUTTON,
                access_control_fetch_key:AC_PROPERTY_NO_DATA_BUTTON_KEY,
                fields: [
                    {key: 'id', label: 'Id'},
                    {key: 'name', label: this.$i18n.t('NAME')},
                ],
                aside: false,
            }
        },
        components: {
            UnitWizard,
            AppButton,
            AppNoData,
            DistributionList,
            AppAside
        },
        computed: {
            property() {
                return this.$store.getters['property/getProperty']
            },
        },
        mounted() {
            EventBus.$on(GE_OPEN_UNIT_WIZARD, () => {
                this.aside = true
            })
        },
        methods: {
            openUnitWizard() {
                EventBus.$emit(GE_OPEN_UNIT_WIZARD)
            }
        },
        watch:{
            property:{
                handler(propertyObject){
                    if(propertyObject.unit_count_list.total_count === 0){
                        this.access_control_components = 1
                        this.access_control_general_context = {
                            property: propertyObject.id,
                            company: this.$store.getters['user/getCompany']
                        }
                    }
                }, immediate:true
            }
        }

    }
</script>

<style scoped>

</style>
