<template>
    <b-card v-cloak class="property-wrapper">

        <template slot="header">
            <div class="d-flex justify-content-between mb-0">
                <div>
                    <div class="header-caption">{{ $t('COUNTRY') }}</div>
                    <h2 class="property-wrapper-padding">{{ tag.label }}</h2>
                </div>

                <app-button v-if="showAddRegion"
                           @click="open"
                           button_type="new"
                           variant="primary"
                           class="pull-right mt-4">{{ $t('NEW_REGION') }}
                </app-button>
                <div v-if="$route.params.region_id">
                    <div class="header-caption pl-0 pl-md-2 mt-3 mt-md-0">{{ $t('REGION') }}</div>
                    <app-quick-link
                        :value="tag_id"
                        :routes="tagQuickList"
                        :label="$route.params.region_id ? $t('REGION') : $t('COUNTRY')"></app-quick-link>
                </div>
            </div>
            <Navigation v-if="routeIsCountry || routeIsRegion" :nav-elements="tag_nav"></Navigation>
            <Navigation v-else :back="backButtonRoute" :nav-elements="tag_nav"></Navigation>
        </template>
        <page-transition transition-type="slide">
            <router-view></router-view>
        </page-transition>
    </b-card>
</template>

<script>
import AppQuickLink from "@/components/app/AppQuickLink";
import Navigation from "@/components/app/navigation/Navigation";
import PageTransition from '@/components/app/transition/AppTransition'
import routeName from "@/router/routeNames";
import routeNames from "@/router/routeNames";
import {getTagsPaginated} from "@/services/tag";
import {TAG_TYPE_DESTINATION, TAG_DESTINATION_TYPE_REGION, TAG_DESTINATION_TYPE_COUNTRY} from "@/shared/constants";
import AppButton from "@/components/app/AppButton/AppButton";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "Index",
    components: {AppButton, Navigation, AppQuickLink, PageTransition},
    data() {
        return {
            TAG_TYPE_DESTINATION, TAG_DESTINATION_TYPE_REGION,
            list: {},
            tag_list: [],
            tag_id: null,
        }
    },
    computed: {
        backButtonRoute() {
            if (this.$route.params.region_id) {
                return {
                    name: routeNames.RN_SETUP_DESTINATION_TAG_REGION,
                    params: {id: this.$route.params.id}
                }
            }
            if (this.$route.params.id) {
                return {
                    name: routeNames.RN_SETUP_DESTINATION_TAG_COUNTRY,
                }
            }
        },
        tag_nav() {
            return this.list
        },
            tagQuickList() {
                return this.tag_list.map(tag => {
                        return {
                            id: tag.id,
                            name: this.$route.name,
                            label: tag.label,
                            params: { region_id: tag.id },
                        }
                })
            },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        tag() {
            return this.$store.getters['tag/getCountryTag']
        },
        showAddRegion(){
            return this.$route.name === routeNames.RN_SETUP_DESTINATION_TAG_REGION && this.$store.state.tag.setRegionButton
        },
        routeIsCountry(){
            return this.$route.name === routeNames.RN_SETUP_DESTINATION_TAG_GENERAL
        },
        routeIsRegion(){
            return this.$route.name === routeNames.RN_SETUP_DESTINATION_TAG_REGION
        }
    },
    methods: {
        getLinks(value, destination_type) {
            let parent_tag = null
            this.tag_id = Number(value)
            if (destination_type === TAG_DESTINATION_TYPE_REGION) {
                parent_tag = {parent_tag: this.$route.params.id}
            }

            let req = {
                perPage: 999,
                destination_type: destination_type,
                company: this.company.id,
                tag_type: TAG_TYPE_DESTINATION,
                ...parent_tag
            }

            return getTagsPaginated(req).then(response => {
                this.tag_list = response.data.items
            }, () => {
                this.tag_list = []
            })
        },
        open(){
            EventBus.$emit('openAddNewTag')
        }
    },
    watch: {
            '$route.params.id': {
                handler(value) {
                    if (value) {
                        this.list = [
                            {
                                route: {name: routeName.RN_SETUP_DESTINATION_TAG_GENERAL}
                            },
                            {
                                route: {name: routeName.RN_SETUP_DESTINATION_TAG_REGION},
                            }
                        ]
                        this.$store.dispatch('tag/actionSetCountryTag',value)
                    }
                },
                immediate: true
            },

            '$route.params.region_id': {
                handler(value) {
                    if (value) {
                        this.list = [
                            {
                                route: { name: routeName.RN_SETUP_DESTINATION_TAG_GENERAL_REGION }
                            },
                            {
                                route: { name: routeName.RN_SETUP_DESTINATION_TAG_CITY },
                            },
                        ]
                        this.getLinks(value, TAG_DESTINATION_TYPE_REGION)
                    } else {
                        this.list = [
                            {
                                route: { name: routeName.RN_SETUP_DESTINATION_TAG_GENERAL }
                            },
                            {
                                route: { name: routeName.RN_SETUP_DESTINATION_TAG_REGION },
                            }
                        ]
                    }

            },
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
