<template>
    <div>
        <revenue-manager-similar-listing-filter  @search="setFilter" :loading="loading"></revenue-manager-similar-listing-filter>
        <RevenueManagerSimilarListingTable @busyToggled="toggleBusy" :filter="filter"></RevenueManagerSimilarListingTable>

    </div>
</template>

<script>
import RevenueManagerSimilarListingTable
    from "@/components/revenue_manager/similar_listing/RevenueManagerSimilarListingTable";
import RevenueManagerSimilarListingFilter
    from "@/components/revenue_manager/similar_listing/RevenueManagerSimilarListingFilter";
import AppAside from "@/components/app/form/AppAside";
export default {
name: "Index",
    components: {AppAside,RevenueManagerSimilarListingFilter, RevenueManagerSimilarListingTable},
    data(){
        return{
            loading:false,
            filter:{},
        }
    },
    methods:{
        setFilter(values) {
            this.filter = values
        },
        toggleBusy($event) {
            this.loading = $event
        }
    }
}
</script>

<style scoped>

</style>
