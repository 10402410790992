import Vue from 'vue'
import VueNotifications from 'vue-notifications'
import miniToastr from 'mini-toastr'
import 'noty/lib/noty.css';
import 'noty/lib/themes/sunset.css';
import Noty from 'noty'

const toastTypes = {
    success: 'success',
    error: 'danger',
    info: 'info',
    warning: 'warning'
}

miniToastr.init({types: toastTypes, allowHtml: true})


const options = {
    success: toast,
    error: toast,
    info: toast,
    warn: toast
}

Vue.use(VueNotifications, options)

export function notifySuccess() {
    let template = `<div role="alert" class="alert alert-success">
                            <span data-notify="message"><i class="fa fa-check" aria-hidden="true"></i></span>
                    </div>`
    new Noty({
            text: "",
            layout: "topRightSmall",
            timeout: 2000,
            closeWith: ["click"],
            callbacks: {
                onTemplate: function () {
                    this.barDom.innerHTML = template
                }
            }
        }
    ).show()
}

export function toast({title, message, type, timeout, time_out_close = true}) {

    let titleTemplate = title ? `<h5>${title}</h5><hr>` : ''

    let template = `<div role="alert" class="alert alert-${toastTypes[type]}">
                            ${titleTemplate}
                            <span data-notify="message">${message}</span>
                    </div>`


    new Noty({
            text: message,
            timeout: timeout ? timeout : time_out_close ? 6000 : 0,
            closeWith: ["button"],
            callbacks: {
                onTemplate: function () {
                    this.barDom.innerHTML = template
                }
            }
        }
    ).show()
    //return miniToastr[type](message, title, timeout, cb)
}

