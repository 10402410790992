<template>
    <div>
        <b-row>
            <b-col class="mb-4">
                <AppButton v-if="showButton && checkPermission(C_WEBSITE_TAG_NEW_V)"
                           @click="open"
                           button_type="new"
                           variant="primary"
                           class="pull-right">{{ $t('NEW_CITY') }}
                </AppButton>
            </b-col>
        </b-row>
        <tag-list :destination_type="TAG_DESTINATION_TYPE_CITY" :parent_tag="parseInt($route.params.region_id)"
                  :refresh="refresh" :tag_type="TAG_TYPE_DESTINATION" @openTagSidebar="openSidebar" :edit_tag_sidebar="true"
                  @openNew="tag_sidebar=true" @showButton="showButton=true"></tag-list>
        <app-aside v-model="tag_sidebar" :widths="['col-lg-4','col-sm-12', '40%']">
            <template slot="header">{{ $t('NEW_CITY') }}</template>
            <add-tag :parent_tag="parseInt($route.params.region_id)" :destination_type="TAG_DESTINATION_TYPE_CITY"
                     :tag_type="TAG_TYPE_DESTINATION" @saved="saved" :label="$t('CITY')"></add-tag>
        </app-aside>
        <app-aside v-model="city_tag_sidebar" :widths="['col-lg-4','col-sm-12', '40%']">
            <template slot="header">{{ $t('CITY') }}</template>
            <city-tag :tag_id="tag.id" @saved="saved"></city-tag>
        </app-aside>
    </div>
</template>

<script>
import TagList from "@/components/tag/list/TagList";
import {TAG_DESTINATION_TYPE_CITY, TAG_TYPE_DESTINATION} from "@/shared/constants";
import AppButton from "@/components/app/AppButton/AppButton";
import AddTag from "@/components/tag/sidebar/AddTag";
import AppAside from "@/components/app/form/AppAside";
import CityTag from "@/components/tag/sidebar/CityTag";
import {C_WEBSITE_TAG_NEW_V} from "@/shared/component_permission";

export default {
    name: "Index",
    components: {CityTag, AddTag, AppButton, TagList, AppAside},
    data() {
        return {
            TAG_DESTINATION_TYPE_CITY, TAG_TYPE_DESTINATION,
            C_WEBSITE_TAG_NEW_V,
            tag_sidebar: false,
            city_tag_sidebar: false,
            parent_tag: null,
            refresh: false,
            showButton: false,
            tag: {}
        }
    },
    methods: {
        open() {
            this.tag_sidebar = true;
        },
        setParentTag(tag) {
            this.parent_tag = tag
        },
        saved() {
            this.city_tag_sidebar = false;
            this.tag_sidebar = false;
            this.refresh = !this.refresh
        },
        openSidebar(data) {
            this.tag = data.item
            this.city_tag_sidebar = true;
        }
    },
    watch: {
        '$route.params.region_id': {
            handler() {
                this.refresh = !this.refresh
                this.showButton = false
            }
        }

    }
}
</script>

<style scoped>

</style>
