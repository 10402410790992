<template functional>
    <div class="main_calendar--cell position-relative main_calendar--availability-cell"
         :class="{'main_calendar__bg-hover': props.highlighted, 'main_calendar-br_green': props.cellData.lastDayOfMonthClass}"
         @click.shift.exact="listeners.drag"
         @click.exact="listeners.startEndDrag"
         @mouseover.shift="listeners.currentDrag"

         :style="{'grid-column': props.cellData.column + '/ span 1'}">
        <div class="justify-content-center main_calendar--status" :class="props.cellData.classList">
            <span></span>
        </div>
    </div>
</template>

<script>

export default {
    name: "TableUnitStatusContentCell",
    props: {
        cellData: {
            type: Object
        },
        highlighted: {
            type: Boolean
        }
    }
}
</script>

<style scoped>

</style>
