<template>
    <div>
        <label>{{restriction.label}}</label>
        <app-select :has-message="has_tooltip_message" :message="message" :disabled="disabled"   :value="value" @input="emitValue" :options="option_list"></app-select>
    </div>

</template>

<script>
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    export default {
        name: "SelectRestrictionFormElement",
        components: {AppSelect},
        props: {
            allowed_values: {
                type: Object,
            },
            value: {
                type: String | Number,
                default: null
            },
            restriction: {
                type: Object
            },
            disabled: {
                type: Boolean,
                default: false
            },
            color_border: {
                type: Boolean,
                default: false
            },
            has_tooltip_message:{
                type:Boolean,
                default:false
            },
            message:{
                type:String,
            }
        },
        computed: {
            computedValue() {
                if (this.value === null) {
                    return null
                }
                return Number(this.value)
            },
            option_list() {
                return Object.keys(this.allowed_values).map(key => {
                    return {
                        id: Number(key),
                        name: key,
                    }
                })
            }
        },
        methods: {
            emitValue(val) {
                this.$emit("input", val)
            }
        }
    }
</script>

<style scoped>
    .border-color {
        border: 1px solid #fb6b41
    }
</style>
