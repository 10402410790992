<template>
    <b-card class="main-section-card">
        <div slot="header">
            <h2>{{ $t("WEBSITES") }}</h2>
            <app-button
                v-if="checkPermission(C_WEBSITE_NEW) && showButton"
                button_type="new"
                variant="primary"
                class="pull-right"
                @click="wizardAsideState=true">{{ $t("WEBSITE_NEW") }}
            </app-button>
        </div>

        <website-table :company="company.id" @openNewWebsite="wizardAsideState=true" :key="wizardAsideState"
                       @showButton="handleButton"></website-table>

        <app-aside :widths="['col-sm-12', '40%']" v-model="wizardAsideState" @input="onCloseAside({reload:true})">
            <template v-if="!websiteCreated" slot="header">{{ $t('WEBSITE_NEW') }}</template>
            <website-wizard @created="websiteCreated=true" @close="onCloseAside"></website-wizard>
        </app-aside>
    </b-card>
</template>

<script>
import WebsiteTable from "@/components/direct_booking/website/list/WebsiteTable";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import WebsiteWizard from "@/components/direct_booking/website/new/WebsiteWizard";
import {C_WEBSITE_NEW} from '@/shared/component_permission';

export default {
    name: "Index",
    components: {WebsiteWizard, AppAside, AppButton, WebsiteTable},
    data() {
        return {
            showButton: false,
            wizardAsideState: false,
            websiteCreated: false,
            C_WEBSITE_NEW
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods: {
        onCloseAside({reload}) {
            this.wizardAsideState = false
        },
        handleButton(value) {
            this.showButton = value
        }
    },
    created() {
        this.$store.commit('website/setWebsite', {})
        this.$store.commit('website/setCurrentPage', null)
    }
}
</script>

<style scoped>

</style>
