<template>
    <div>
    <b-card class="main-section-card">
        <div slot="header">
            <h2>{{ $t("PROPERTIES") }}</h2>
            <new-button v-if="checkPermission(R_PROPERTY_NEW) && !empty_initial_list" button_type="new" variant="primary" class="pull-right ml-3" @click="aside=true">{{ $t("PROPERTY_NEW") }}
            </new-button>
            <app-button
                v-if="!empty_initial_list"
                :access_control_context="{function: C_PROPERTY_IMPORT_FROM_OTA, key: access_control_fetch_key}"
                @AccessControlEvent="addToAccessControlCounter()"
                class="pull-right ml-3"
                @click="importPropertyModalState = true">
                {{ $t("IMPORT_FROM_OTA") }}
            </app-button>
            <new-button v-if="checkPermission(C_UNIT_CALENDAR_PRICE_IMPORT_EXPORT) && company.id === directBookerCompany" button_type="upload"
                        class="pull-right" @click="openImportExportSidebar=true">{{ $t('BULK_PRICE_UPLOAD') }}
            </new-button>
        </div>
        <property-list-filter :hasFilter="hasFilter"></property-list-filter>
        <PropertyListTable :hasFilter="hasFilter" :empty_initial_list="empty_initial_list"></PropertyListTable>
        <app-aside :widths="['col-sm-12', '40%']" v-model="aside">
            <template slot="header">{{$t('NEW_PROPERTY')}}</template>
            <property-wizard></property-wizard>
        </app-aside>
        <app-aside :widths="['col-lg-4', 'col-sm-12', '40%']" v-model="openImportExportSidebar">
            <template slot="header">
                <app-object-header :name="$t('BULK_PRICE_UPLOAD')">
                </app-object-header>
            </template>
            <bulk-price-uploader-import-export @input="openImportExportSidebar = $event"
            ></bulk-price-uploader-import-export>
        </app-aside>

        <b-modal
            v-model="importPropertyModalState"
            :title="$t('IMPORTANT')">
            <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "Booking.com"}) }}</p>
            <ul>
                <li>{{ $t("IMPORT_PROPERTY_FROM_BOOKING_DISCLAIMER_1") }}</li>
                <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
            </ul>
            <app-button
                @click="importFromDistribution(BOOKING)">
                {{ $t("IMPORT") }}
            </app-button>
            <br/>
            <br/>
            <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "AirBnB"}) }}</p>
            <ul>
                <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_1") }}</li>
                <li>{{
                        $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_2", {
                            "min_image_width": 2048,
                            "min_image_height": 1536
                        })
                    }}
                </li>
                <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_3") }}</li>
                <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
            </ul>
            <app-button
                @click="importFromDistribution(AIRBNB)">
                {{ $t("IMPORT") }}
            </app-button>
            <template slot="modal-footer">
                <template slot="modal-ok">
                </template>
            </template>
        </b-modal>

    </b-card>
        <app-aside
            v-model="importPropertyWizardState"
            :widths="['col-lg-12', '100%']">
            <template slot="header">{{ $t('IMPORT_FROM_OTA') }}</template>
            <import-property-wizard
                @importProperty="closeImportWizard"
                @close="closeImportWizard"
                :distribution="importDistribution"
                :entity="importEntity">
            </import-property-wizard>
        </app-aside>
    </div>
</template>

<script>
    import PropertyListTable from '@/components/property/list/PropertyListTable'
    import PropertyListFilter from '@/components/property/list/PropertyFilter'
    import routeNames from '@/router/routeNames'
    import NewButton from '@/components/app/AppButton/AppButton'
    import {C_PROPERTY_IMPORT_FROM_OTA, R_PROPERTY_NEW} from "@/shared/route_permission";
    import AppAside from "@/components/app/form/AppAside";
    import PropertyWizard from "@/components/property/new/PropertyWizard";
    import {AIRBNB, BOOKING, DEFAULT_PAGINATION_LIMIT, LS_PRESETS, DIRECT_BOOKER_COMPANY} from "@/shared/constants";
    import AppButton from "@/components/app/AppButton/AppButton";
    import ImportPropertyWizard from "@/components/property/import/ImportPropertyWizard";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_PROPERTY_IMPORT} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {getPresets} from "@/services/user";
    import {C_UNIT_CALENDAR_PRICE_IMPORT_EXPORT} from "@/shared/component_permission";
    import AppObjectHeader from "@/components/app/AppObjectHeader";
    import BulkCalendarSaveForm from "@/components/unit/bulk_calendar/form/BulkCalendarSaveForm.vue";
    import BulkPriceUploaderImportExport from "@/components/property/list/BulkPriceUploaderImportExport.vue";

    export default {
        name: "Index",
        components: {
          BulkPriceUploaderImportExport,
          BulkCalendarSaveForm,
          AppObjectHeader,
          ImportPropertyWizard,
          AppButton,
          PropertyWizard,
          PropertyListTable, PropertyListFilter, NewButton, AppAside
        },
        mixins: [AccessControlComponent],
        data() {
            return {
                routeNames,
                R_PROPERTY_NEW,
                C_PROPERTY_IMPORT_FROM_OTA,
                C_UNIT_CALENDAR_PRICE_IMPORT_EXPORT,
                aside: false,
                loading:false,
                hasFilter:true,
                empty_initial_list:false,
                importPropertyModalState: false,
                importPropertyWizardState: false,
                BOOKING,
                AIRBNB,
                importDistribution: null,
                importEntity: null,
                access_control_components: 1,
                access_control_fetch_key: AC_PROPERTY_IMPORT,
                openImportExportSidebar: false,
                directBookerCompany: DIRECT_BOOKER_COMPANY
            }
        },
        methods: {
            clearPropertyData(id) {
                if (this.property && this.property.id && this.property.id !== Number(id)) {
                    this.$store.commit('property/setProperty', {
                        status: {}
                    })
                }
            },
            importPropertyTermsConfirmed() {
                this.importPropertyModalState = false
                this.importPropertyWizardState = true
            },
            importFromDistribution(distributionId) {
                this.importDistribution = distributionId
                this.importPropertyTermsConfirmed()
            },
            closeImportWizard() {
                this.importPropertyWizardState = false
                this.importDistribution = null
                this.importEntity = null
                this.$router.replace({"query": null})
            },
            setFromStoragePresets(storagePresets) {
                if (storagePresets && typeof storagePresets === 'object') {
                    if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                        if (storagePresets.properties === 0){
                            this.empty_initial_list = true
                        }
                        this.hasFilter = false
                    }
                }
            },
        },
        computed: {
            property() {
                return this.$store.getters['property/getProperty']
            },
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
        },
        beforeRouteLeave(to, from, next) {
            this.clearPropertyData(to.params.id)
            next()
        },
        watch: {
            "$route.query": {
                handler(queryParams) {
                    const queryParamKeys = Object.keys(queryParams)
                    if (queryParamKeys.length > 0) {
                        if (queryParamKeys.indexOf("import") >= 0 && Number(queryParams.import) === 1 && queryParamKeys.indexOf("one_time_error") < 0) {
                            const distributionId = queryParamKeys.indexOf("distribution") >= 0 ? Number(queryParams.distribution) : null
                            if (distributionId === AIRBNB) {
                                const hostId = queryParamKeys.indexOf("host") >= 0 ? queryParams.host : null
                                if (hostId) {
                                    this.importDistribution = AIRBNB
                                    this.importEntity = hostId
                                    this.importPropertyWizardState = true
                                }
                            }
                        }
                    }
                },
                immediate: true,
            },
        },
        created(){
            let storagePresets = localStorage.getItem(LS_PRESETS)
            if (storagePresets) {
                storagePresets = JSON.parse(storagePresets)
                this.setFromStoragePresets(storagePresets)
            } else {
                getPresets().then(response => {
                    localStorage.setItem(LS_PRESETS, JSON.stringify(response.data))
                    this.setFromStoragePresets(response.data)
                })
            }

            this.access_control_general_context = {
                company: this.company.id
            }
        }
    }
</script>

<style scoped>

</style>
