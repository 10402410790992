import http from '../http'
import {DASHBOARD} from '@/services/endpoints'

export function getIncome(company, property) {
    return http.request({
        url: DASHBOARD + '/income',
        method: 'get',
        params: {
            company: company,
            property: property
        }
    })
}

export function getNights(company, property) {
    return http.request({
        url: DASHBOARD + '/nights',
        method: 'get',
        params: {
            company: company,
            property: property
        }
    })
}

export function getCheckinReservations(params) {
    return http.request({
        url: DASHBOARD + '/reservations/checkin',
        method: 'get',
        params: params
    })
}

export function getCheckoutReservations(params) {
    return http.request({
        url: DASHBOARD + '/reservations/checkout',
        method: 'get',
        params: params
    })
}

export function getLatestReservations(params) {
    return http.request({
        url: DASHBOARD + '/reservations/latest',
        method: 'get',
        params: params
    })
}

export function getOpenInvoice(params) {
    return http.request({
        url: DASHBOARD + '/open-invoice',
        method: 'get',
        params: params
    })
}

export function getInvoiceForCheckCount(params) {
    return http.request({
        url: DASHBOARD + '/invoice/check',
        method: 'get',
        params: params
    })
}

export function getReservationCount(params) {
    return http.request({
        url: DASHBOARD + '/reservations/count',
        method: 'get',
        params: params
    })
}

export function getPropertyDocument() {
    return http.request({
        url: DASHBOARD + '/property/document',
        method: 'get'
    })
}


