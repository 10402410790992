<template>
    <div>
        <app-table-v2 :fields="computedFields"
                      :selectable="false"
                      :provider="getData"
                      :default-limit="defaultLimit"
                      :show-limit="showLimit"
                      @busyToggled="toggleBusy"
                      :search-event="SEARCH_EVENT"
                      :search-on-create="searchOnCreate">
            <template v-slot:start_date="data">
                {{data.item.start_date | date}}
            </template>

            <template v-slot:end_date="data">
                {{data.item.end_date | date}}
            </template>

            <template v-slot:due_date="data">
                {{data.item.due_date | date}}
            </template>

            <template v-slot:actions="data">
                <app-button v-if="data.item.hasOwnProperty('export') && data.item.export && data.item.export.hasOwnProperty('id') && data.item.export.id"
                            variant="link"
                            icon="excel"
                            :show_text="false"
                            @click="downloadXLS(data.item)">
                </app-button>

                <app-button variant="link"
                            icon="edit"
                            :show_text="false"
                            @click="showCalculation(data.item)">
                </app-button>
            </template>
        </app-table-v2>

        <app-aside v-model="calculationPanelState" :widths="['col-lg-12', 'col-sm-12', '100%']" full-width
                   :header-classes="[]">
            <template slot="header">
                <app-object-header :label="$t('STATEMENT')"
                                   :name="calculationId"
                ></app-object-header>
            </template>
            <company-calculation-form :company="company"
                                      :calculation-id="calculationId"
                                      :calculation-data="calculationData"
                                      @close="() => { this.calculationPanelState = false }"
            ></company-calculation-form>
        </app-aside>
    </div>
</template>

<script>
    import AppAside from "@/components/app/form/AppAside";
    import AppTableV2 from "@/components/app/AppTableV2";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppObjectHeader from "@/components/app/AppObjectHeader";
    import CompanyCalculationForm from "@/components/finance/company_calculation/form/CompanyCalculationForm";
    import {EventBus} from "@/shared/EventBus";
    import {fetchSubscriptionStatements} from "@/services/company";
    import {downloadLocalDocument} from "@/services/document";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {cloneDeep} from "lodash";
    import {fileHelpers} from "@/mixins/shared/helpers";

    export default {
        name: "CompanyCalculationList",
        mixins: [getErrorMessage, fileHelpers],
        components: {AppAside, AppButton, AppTableV2, AppObjectHeader, CompanyCalculationForm},
        props: {
            company: {
                type: Number,
                required: true
            },
            searchCriteria: {
                type: Object,
                default() {
                    return {}
                }
            },
            searchOnCreate: {
                type: Boolean,
                default: true
            },
            defaultLimit: {
                type: Number,
                default: 10
            },
            showLimit: {
                type: Boolean,
                default: true
            },
            refreshListRequested: {
                type: Number,
                default: 1
            },
        },
        data() {
            return {
                fields: [
                    {key: 'id', label: this.$t('ID')},
                    {key: 'start_date', label: this.$t('START')},
                    {key: 'end_date', label: this.$t('END')},
                    {key: 'total_f', label: this.$t('TOTAL'), tdClass: 'text-right'},
                    {key: 'status.label', label: this.$t('STATUS')},
                    {key: 'due_date', label: this.$t('DUE_DATE')},
                    {key: 'actions', label: '', class: 'text-right'},
                ],
                SEARCH_EVENT: 'getCalculations',
                filter: {},
                calculationPanelState: false,
                calculationData: null,
                calculationId: null,
            }
        },
        computed: {
            computedFields() {
                return this.fields
            },
        },
        methods: {
            getData(req) {
                return fetchSubscriptionStatements(this.company, {
                    page: req.currentPage,
                    limit: req.perPage,
                    order_by: req.sortBy ? req.sortBy : 'id',
                    order_by_direction: req.sortDirection ? req.sortDirection : 'desc',
                    ...this.filter,
                })
            },
            toggleBusy($event) {
                EventBus.$emit('loading', $event)
            },
            downloadXLS(item) {
                downloadLocalDocument(item.export.id).then(response => {
                    this.handleFileDownload(response, item.id, 'xlsx')
                }, (error) => {
                    this.showErrorMessages(error)
                }).catch((error) => {
                    this.showErrorMessages(error)
                })
            },
            showCalculation(calculationData) {
                this.calculationId = calculationData.id
                this.calculationData = calculationData
                this.calculationPanelState = true
            },
        },
        watch: {
            refreshListRequested: {
                handler(value) {
                    if (value !== null) {
                        EventBus.$emit(this.SEARCH_EVENT)
                    }
                },
            },
        },
        created() {
            if (this.searchOnCreate) {
                this.filter = cloneDeep(this.searchCriteria)
            }
        },
        mounted() {
            EventBus.$on('searchCalculations', filter => {
                if (filter) {
                    this.filter = cloneDeep(filter)
                }
                EventBus.$emit(this.SEARCH_EVENT)
            })
        },
        beforeDestroy() {
            EventBus.$off('searchCalculations')
        }
    }
</script>

<style scoped>

</style>
