import {getRestrictionList} from "@/services/pricing/restriction";
import {normalize, schema} from "normalizr";
import {isNull} from "lodash";


const state = {
  restrictions: null
}
const getters = {
  getRestrictions(state) {
    return state.restrictions
  }
}

const mutations = {
  setSchema(state, {schema, data}) {
    state[schema] = data.entities[schema]
  }
}

const actions = {
  actionSetRestrictions({state, commit}) {
    return new Promise((resolve, reject) => {
      if (isNull(state.restrictions)) {
        getRestrictionList().then(response => {
          if (response.data.length > 0) {
            const entity = new schema.Entity('restrictions');
            const newSchema = {restrictions: [entity]}
            const normalizedData = normalize({restrictions: response.data}, newSchema);
            commit('setSchema', {schema: 'restrictions', data: normalizedData})
            resolve(state.restrictions);
          }
        }, error => {
          reject(error)
        })
      } else {
        resolve(state.restrictions)
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters

}
