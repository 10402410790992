<template>
    <div>
        <b-form @submit.prevent="save">
            <b-row>
                <b-col class="mb-4">
                    <app-translation-input
                        :label="$t('NAME')"
                        :context-label="$t('AMENITY_CATEGORIES')"
                        :max-length="70"
                        v-model="category.label"
                        :lang_id="category.lang_id"
                        :languages="category.supportedLanguages">
                    </app-translation-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="mb-4">
                    <app-button-submit @click="save" :loading="saving">{{ $t("SAVE") }}</app-button-submit>
                </b-col>
                <b-col class="mb-4">
                    <app-button-delete @click="deleteCategory" :loading="deleteItem"
                                       class="pull-right"></app-button-delete>
                </b-col>
            </b-row>
            <app-confirmation-dialog
                :show="confirmation_dialog_state"
                @confirm="continueAction"
                @cancel="cancelAction"
                :title="$t('DELETE_TITLE')">
                <p>{{$t('DELETE_MESSAGE',{name})}}</p>
            </app-confirmation-dialog>
        </b-form>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {getAmenityCategory, updateAmenityCategory} from "@/services/direct_booking/website";
    import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
    import {getTranslateList} from "@/services/system";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";

    export default {
        name: "WebsiteAmenityCategory",
        mixins: [getErrorMessage],
        components: {AppButtonDelete, AppTranslationInput, AppButtonSubmit, AppButton, AppConfirmationDialog},
        props: {
            category_id: {
                type: Number,
                default: null
            },
            websiteId: {
                type: Number,
                default: null,
            }
        },
        data() {
            return {
                category: {},
                saving: false,
                deleteItem: false,
                confirmation_dialog_state: false,
                name: null,
            }
        },
        methods: {
            save() {
                this.saving = true
                updateAmenityCategory(this.websiteId, this.category_id, JSON.stringify(this.category)).then(response => {
                    notifySuccess()
                    this.$emit('saved')
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => this.saving = false)
            },
            deleteCategory() {
                this.confirmation_dialog_state = true
            },
            continueAction(){
                this.deleteItem = true
                this.category.deleted = 1
                updateAmenityCategory(this.websiteId, this.category_id, JSON.stringify(this.category)).then(response => {
                    notifySuccess()
                    this.confirmation_dialog_state = false
                    this.$emit('saved')
                    this.$emit('deletedCategory')
                }).catch(error => {
                    this.confirmation_dialog_state = false
                    this.showErrorMessages(error)
                }).finally(() => this.deleteItem = false)
            },
            cancelAction(){
                this.confirmation_dialog_state = false
            },
            getCategory() {
                getAmenityCategory(this.websiteId, this.category_id).then(response => {
                    this.category = response.data
                    this.name = response.data.label
                })
            },
        },
        created() {
            this.getCategory()
        }
    }
</script>

<style scoped>

</style>
