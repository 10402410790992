<template>
    <div class="app_table_container">
        <div v-if="items.length && ((showSort && computedSortList.length) || showLimit) && paginated"
             class="d-flex mb-3  align-items-end justify-content-end">
            <div class="d-flex align-items-end">
                <div class="d-flex align-items-center" v-if="showSort && computedSortList.length"
                     style="min-width: 15rem">
                    <span class="mr-2">{{$t('SORT_RESULTS_BY')}}</span>

                    <app-select class="flex-grow-1" v-model="sortCustomIndex" :options="computedSortList"
                                text-field="label"
                                value-field="index"
                                :sanitize-text-value="false">
                    </app-select>
                </div>

                <div class="d-flex align-items-center" v-if="showLimit && computedLimitList.length">
                    <span class="ml-3 mr-1">{{$t("SHOWING")}}</span>
                    <span v-if="totalRows <= computedLimitList[0].id" class="mr-1">
                        {{totalRows}}
                    </span>
                    <app-select
                        v-else
                        class="mr-2 ml-1"
                        v-model="perPage"
                        :search-empty-item="false"
                        @input="updatePagination(1)"
                        :sanitize-text-value="false"
                        :options="computedLimitList">
                    </app-select>
                    <span v-html='$t("_RESULTS", {number: totalRowsBold })'></span>
                </div>
            </div>
        </div>

        <b-table
            class="animated fadeIn"
            responsive
            hover
            v-show="items.length"
            ref="table"
            :id="id"
            :primary-key="primaryKey"
            :busy="isBusy || loading"
            :items="items"
            :fields="noSortFields"
            :no-local-sorting="true"
            :no-sort-reset="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @sort-changed="updateSort"
            :fixed="fixed">
            <template v-slot:HEAD_selectableCheckbox="data">
                <div v-if="items.length > 1" class="tableSelectableCheckbox">
                    <b-checkbox v-model="selectAllValue" @change="selectAll" size="sm" :inline="true"></b-checkbox>
                </div>
            </template>

            <template v-slot:selectableCheckbox="data">
                <b-checkbox @change="setSelectedItem($event, data.item)" v-model="selectedIds"
                            :value="data.item[primaryKey]" size="sm" :inline="true"></b-checkbox>
            </template>

            <template v-slot:[getSlotName(field.key)]="data" v-for="field in computedFields">
                <slot :name="field.key" :item="data.item">
                    {{get(data.item, field.key) }}
                </slot>
            </template>

            <template v-slot:[getHeadSlotName(field.key)]="data" v-for="field in computedFields">
                <slot :name="`HEAD_${field.key}`" :item="data.item">
                    {{ field.label }}
                </slot>
            </template>

            <slot></slot>
        </b-table>

        <b-pagination
            v-show="paginated && items.length && lastPage> 1"
            class="app_pagination"
            size="sm"
            :disabled="isBusy"
            :hide-goto-end-buttons="true"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="updatePagination">
            <template v-slot:prev-text>
                <i class="fa fa-angle-left" aria-hidden="true"></i>
            </template>

            <template v-slot:next-text>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </template>
        </b-pagination>

        <slot v-if="showInitialSearchMessage" name="initialMessage">
            <app-search-data></app-search-data>
        </slot>

        <slot v-if="showNoResultsMessage" name="noDataMessage">
            <slot name="tableNoData">
                <app-no-data></app-no-data>
            </slot>
        </slot>
        <slot name="container_selected_item">
            <div class="selectedContainer animated fadeIn" v-if="selectedIds.length">
                <div>
                    <h4 v-if="selectedIds.length > 1">{{$t("ITEMS_SELECTED", {value: selectedIds.length})}}</h4>
                    <h4 v-else-if="selectedIds.length === 1">{{$t("ITEM_SELECTED")}}</h4>
                    <app-button @click="deselectAll" class="action_button" variant="link">{{$t("DESELECT")}}
                    </app-button>
                </div>

                <div class="flex-grow-1 d-flex justify-content-end">
                    <slot  v-bind="{selected:selectedObjects, totalRows:totalRows, sort:{order_by:this.sortBy, order_by_direction: this.sortDirection}}" name="selectedContainerOptions">

                    </slot>
                    <slot
                        v-bind="{selected:selectedObjects, totalRows:totalRows, sort:{order_by:this.sortBy, order_by_direction: this.sortDirection}}"
                        name="selectedContainer">

                        <app-button
                            @click="exportModal=true">
                            {{$t("EXPORT")}}
                        </app-button>

                        <b-modal :title="$t('EXPORT')" v-model="exportModal">
                            <b-checkbox :checked="true" @change="updateSelectAllExport">
                                {{$t("SELECT_ALL")}}
                            </b-checkbox>
                            <hr class="p-0 m-0 mb-1 mt-1">
                            <b-checkbox-group class="two_col_checkbox_group" v-model="exportActionSelected" stacked
                                              :options="exportColumns"></b-checkbox-group>
                            <template v-slot:modal-footer>
                                <div class="w-100 d-flex justify-content-between align-items-center">
                                    <div v-if="totalRows > 1">
                                        <b-checkbox v-model="selectAllForExport">
                                            {{$t("APPLY_TO_ALL_NUMBER", {number:totalRows})}}
                                            <!--  <span v-if="data.selected.length === 1" class="d-block text-muted font-xs">({{$t("ITEM_SELECTED", {value: data.selected.length})}})</span>
                                              <span v-if="data.selected.length > 1" class="d-block text-muted font-xs">({{$t("ITEMS_SELECTED", {value: data.selected.length})}})</span>-->
                                        </b-checkbox>
                                        <small v-if="totalRows > maxExportLimit" class="text-muted">
                                            {{$t('EXPORT_MAX_LIMIT_WARNING', {num:maxExportLimit})}}
                                        </small>
                                    </div>
                                    <div>
                                        <b-checkbox v-model="downloadPdf">
                                            Pdf
                                        </b-checkbox>
                                        <b-checkbox v-model="downloadXls">
                                            Xls
                                        </b-checkbox>
                                    </div>
                                    <app-button :disabled="disabledExport" :loading="downloading" @click="download">
                                        {{$t('EXPORT')}}
                                    </app-button>
                                </div>
                            </template>
                        </b-modal>
                        <!--        <div class="selectedContainer&#45;&#45;buttons">
                                    <app-button :loading="downloading" @click="downloadGenericFile('xlsx')">Excel</app-button>
                                    <app-button :loading="downloading" @click="downloadGenericFile('pdf')">Pdf</app-button>
                                </div>-->
                    </slot>
                </div>
            </div>
        </slot>

    </div>
</template>
if (this.sortBy && this.sortDesc !== null) {
req.sortBy = this.sortBy
req.sortDirection = this.sortDesc ? 'DESC' : 'ASC'
}
<script>

    /**
     * Mandatory props: id
     * */
    import {cloneDeep, get, has, isFunction} from 'lodash'
    import {EventBus, GE_TABLE_SEARCH, GE_TABLE_UPDATE_ROW, GE_TABLE_REFRESH_SEARCH, GE_TABLE_DESELECT_ALL} from "@/shared/EventBus";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {DEFAULT_PAGINATION_LIMIT} from "@/shared/constants";
    import AppSearchData from "@/components/app/AppSearchData";
    import AppNoData from "@/components/app/AppNoData";
    import {downloadGenericExportFile} from "@/services/document";
    import AppButton from "@/components/app/AppButton/AppButton";

    export default {
        name: "AppTableV2",
        components: {AppButton, AppNoData, AppSearchData, AppSelect},
        props: {
            id: {
                type: String
            },
            provider: {
                type: Function
            },
            fields: {
                type: Array,
                default: () => []
            },
            filter: {
                type: Object,
                default: null
            },
            primaryKey: {
                type: String,
                default: 'id'
            },
            searchOnCreate: {
                type: Boolean,
                default: false
            },
            searchEvent: {
                type: String,
                default: GE_TABLE_SEARCH
            },
            refreshEvent: {
                type: String,
                default: GE_TABLE_REFRESH_SEARCH
            },
            deselectEvent:{
                type: String,
                default: GE_TABLE_DESELECT_ALL
            },
            showLimit: {
                type: Boolean,
                default: true
            },
            showSort: {
                type: Boolean,
                default: true
            },
            showSearchMessages: {
                type: Boolean,
                default: true
            },
            limitList: {
                type: Array,
                default: () => {
                    return [DEFAULT_PAGINATION_LIMIT, 20, 50]
                }
            },
            additionalSortOptions: {
                type: Array,
                default: () => []
            },
            defaultLimit: {
                type: Number,
                default: DEFAULT_PAGINATION_LIMIT
            },
            selectable: {
                type: Boolean,
                default: false
            },
            genericDownloadFields: {
                type: Array,
                default: () => []
            },
            exportColumns: {
                type: Array,
                default: () => []
            },
            fixed: {
                type: Boolean,
                default: false
            },
            paginated: {
                type: Boolean,
                default: true
            },
            maxExportLimit: {
                type: Number,
                default: 5000
            },
            http: {
                type: Object,
                default: () => {
                    return {
                        url: null,
                        methods: null
                    }
                }
            },
            scrollToResults: {
                type: Boolean,
                default: false,
            },
            listPath: {
                type: String,
                default: null
            },
            exportEndpoint: {
                type: Function,
                default: null,
            },
            sortByOption: {
                type: String,
                default: null,
            },
            sortDescOption: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                isBusy: false,
                items: [],
                totalRows: 0,
                currentPage: 1,
                perPage: this.defaultLimit,
                lastPage: 0,
                sortCustomIndex: null,
                sortBy: null,
                sortDesc: false,
                searched: false,
                selectAllValue: false,
                selectedIds: [],
                selectedObjects: [],
                downloading: false,
                exportActionSelected: [],
                selectAllForExport: false,
                exportModal: false,
                downloadPdf: false,
                downloadXls: false
            }
        },
        computed: {
            sortDirection() {
                return this.sortDesc ? 'DESC' : 'ASC'
            },
            totalRowsBold() {
                return '<span class="font-weight-bolder">' + this.totalRows + '</span>'
            },
            showInitialSearchMessage() {
                return !this.isBusy && !this.searchOnCreate && !this.searched && this.showSearchMessages
            },
            showNoResultsMessage() {
                return !this.isBusy && this.searched && this.totalRows === 0 && this.showSearchMessages
            },
            filteredFields() {
                return this.showSort ? this.fields : this.fields.map(el => {
                    return {...el, ...{sortable: false}}
                })
            },
            noSortFields() {
                let list = this.fields.map(el => {
                    return {...el, ...{sortable: false}}
                })

                if (this.selectable) {
                    list = [...[{key: 'selectableCheckbox', label: '', tdClass: 'tableSelectableCheckbox'}], ...list]
                }
                return list
            },
            computedFields() {
                return this.filteredFields.length ? this.filteredFields : this.items.length ? Object.keys(this.items[0]).map(key => {
                    return {key}
                }) : []
            },
            computedLimitList() {
                let list = this.limitList.includes(this.defaultLimit) ? this.limitList : [...this.limitList, ...[this.defaultLimit]]
                return list.sort((a, b) => a - b).map(el => {
                    return {
                        id: el,
                        name: el
                    }
                })
            },
            computedSortList() {
                return [...this.computedFields.filter(el => {
                    return el.hasOwnProperty('sortable') && el.sortable
                }), ...this.additionalSortOptions]
                    .reduce((list, element) => {
                        list.push({
                            ...element, ...{
                                sortDesc: true,
                                label: `<div class="d-flex align-items-baseline"><div>${element.label}</div><small class="ml-1""> &#9660;</small></div>`
                            }
                        })
                        list.push({
                            ...element, ...{
                                sortDesc: false,
                                label: `<div class="d-flex align-items-baseline"><div>${element.label}</div><small class="ml-1">&#9650;</small></div>`
                            }
                        })
                        return list
                    }, [])
                    .map((el, index) => {
                        return {...el, ...{index: index}}
                    })
            },
            disabledExport() {

                return this.exportActionSelected.length === 0 || (!this.downloadPdf && !this.downloadXls)
            },
            selectedExportColumn() {
                let data = []
                if (this.exportActionSelected.length) {
                    this.exportColumns.map(el => {
                        this.exportActionSelected.forEach(item => {
                            if (item === el.value) {
                                data.push({
                                    key: el.value,
                                    label: el.text
                                })
                            }
                        })
                    })
                }
                return data
            }
        },
        methods: {
            deselectAll() {
                this.selectedIds = []
                this.selectedObjects = []
                this.selectAllValue = false
            },
            setSelectedItem($event, el) {
                let index = this.selectedIds.indexOf(el[this.primaryKey])
                if (index >= 0) {
                    this.selectedIds.splice(index, 1)
                    this.selectedObjects.splice(index, 1)
                }
                if ($event) {
                    this.selectedIds.push($event)
                    this.selectedObjects.push(el)
                    const selectedIds = [...this.selectedIds, ...this.items.filter(item => {
                        return !this.selectedIds.includes(item[this.primaryKey])
                    }).map(item => {
                        return item[this.primaryKey]
                    })]
                    this.selectAllValue = selectedIds.length === this.selectedIds.length
                } else {
                    this.selectAllValue = false
                }

            },
            selectAll($event) {
                this.items.forEach(el => {
                    let index = this.selectedIds.indexOf(el[this.primaryKey])
                    if (index >= 0) {
                        this.selectedIds.splice(index, 1)
                        this.selectedObjects.splice(index, 1)
                    }
                })

                if ($event) {
                    this.selectedIds = [...this.selectedIds, ...this.items.map(el => {
                        return el[this.primaryKey]
                    })]
                    this.selectedObjects = [...this.selectedObjects, ...this.items]
                }
            },
            updateSelectAllExport($event) {
                if ($event) {
                    this.exportActionSelected = this.exportColumns.map(el => el.value)
                } else {
                    this.exportActionSelected = []
                }
            },
            fetchData() {

                if (!this.isBusy) {
                    this.$emit('busyToggled', true)
                    this.isBusy = true
                    let req = {
                        currentPage: this.currentPage,
                        perPage: this.perPage,
                    }
                    if (this.sortBy && this.sortDesc !== null) {
                        req.sortBy = this.sortBy
                        req.sortDirection = this.sortDesc ? 'DESC' : 'ASC'
                    }
                    this.provider(req).then(response => {
                        if (this.paginated) {
                            this.items = cloneDeep(response.data.items)
                            this.totalRows = response.data.total
                            this.currentPage = response.data.page
                            this.lastPage = response.data.last_page
                        } else {
                            if (this.listPath) {
                                this.items = cloneDeep(response.data[0][this.listPath])
                            } else {
                                this.items = cloneDeep(response.data)
                            }

                            this.totalRows = this.items.length
                        }

                        if (this.scrollToResults) {
                            this.scrollToTable()
                        }

                    }).finally(() => {
                        this.isBusy = false
                        this.searched = true
                        this.$emit('fetched', this.items)
                        this.$emit('busyToggled', false)

                        const selectedIds = [...this.selectedIds, ...this.items.filter(item => {
                            return !this.selectedIds.includes(item[this.primaryKey])
                        }).map(item => {
                            return item[this.primaryKey]
                        })]
                        this.selectAllValue = selectedIds.length === this.selectedIds.length
                    })
                }

            },
            updatePagination(value) {
                this.selectAllValue = false
                this.currentPage = value
                this.fetchData()
            },
            get: get,
            getSlotName(name) {
                return name
            },
            getHeadSlotName(name) {
                return `HEAD_${name}`
            },
            updateSort(filter) {
                let element = this.computedSortList.find(el => el.key === filter.sortBy && el.sortDesc === filter.sortDesc)
                if (element) {
                    this.sortCustomIndex = element.index
                }
            },
            downloadGenericFile(filetype) {
                this.downloading = true
                if (this.filter.hasOwnProperty('perPage')) {
                    this.filter.perPage = this.maxExportLimit
                } else {
                    this.filter.limit = this.maxExportLimit
                }

                if(this.exportEndpoint){
                    this.exportEndpoint({
                        fields: this.selectedExportColumn,
                        data: this.selectedObjects,
                        filter: this.selectAllForExport ? this.filter : null,
                    }, filetype).finally(() => {
                        this.downloading = false
                        this.selectedIds = []
                        this.selectedObjects = []
                        this.selectAllValue = false
                        this.exportModal = false
                    })
                } else {
                    downloadGenericExportFile({
                        fields: this.selectedExportColumn,
                        data: this.selectedObjects,
                        filter: this.selectAllForExport ? this.filter : null,
                        url: this.http.url,
                        methods: this.http.methods
                    }, filetype).finally(() => {
                        this.downloading = false
                        this.selectedIds = []
                        this.selectedObjects = []
                        this.selectAllValue = false
                        this.exportModal = false
                    })
                }
            },
            download() {
                if (this.downloadXls) {
                    this.downloadGenericFile('xlsx')
                }
                if (this.downloadPdf) {
                    this.downloadGenericFile('pdf')
                }
            },
            scrollToTable() {
                this.$nextTick(() => {
                    const header = document.getElementsByClassName('app-header')[0]
                    if (header) {
                        const y = this.$el.getBoundingClientRect().top + window.pageYOffset - header.offsetHeight - 5
                        window.scrollTo({top: y, behavior: 'smooth'})
                    }
                })
            }
        },
        watch: {
            sortCustomIndex(index, oldIndex) {
                this.sortBy = null
                this.sortDesc = false
                if (index !== oldIndex) {
                    let element = this.computedSortList.find(el => el.index === index)
                    if (element) {
                        this.sortBy = element.key
                        this.sortDesc = element.sortDesc
                    } else {
                        this.sortBy = null
                        this.sortDesc = false
                    }

                    this.updatePagination(1)
                }
            },
            sortByOption : {
                handler(value) {
                    if (value) {
                        let element = this.computedSortList.find(el => el.key === value && el.sortDesc === this.sortDescOption)
                        if (element) {
                            this.sortBy = element.key
                            this.sortDesc = element.sortDesc
                            this.sortCustomIndex = element.index
                        } else {
                            this.sortBy = null
                            this.sortDesc = false
                        }
                    }
                }
            },
            exportModal: {
                handler(value) {
                    if (value) {
                        this.exportActionSelected = this.exportColumns.map(el => el.value)
                    }
                }
            }
        },
        mounted() {
            EventBus.$on(this.searchEvent, () => {
                this.currentPage = 1
                this.selectedIds = []
                this.selectedObjects = []
                this.selectAllValue = false
                this.fetchData()
            })

            EventBus.$on(this.refreshEvent, () => {
                this.fetchData()
            })

            EventBus.$on(GE_TABLE_UPDATE_ROW, (data) => {
                if (has(data, 'id') && data.id === this.id && has(data, 'callback') && isFunction(data.callback)) {
                    this.items = data.callback(this.items)
                }
            })

            EventBus.$on(this.deselectEvent, () => {
                this.selectedIds = []
                this.selectedObjects = []
                this.selectAllValue = false
                this.fetchData()
            })

            if (this.searchOnCreate) {
                this.fetchData()
            }
        },
        beforeDestroy() {
            EventBus.$off(this.refreshEvent)
            EventBus.$off(this.searchEvent)
            EventBus.$off(this.deselectEvent)
            EventBus.$off(GE_TABLE_UPDATE_ROW)
        }
    }
</script>

<style scoped>

</style>
