import http from '../http'
import {
    PROPERTY_URL,
    PROPERTY_WITH_OWNER_URL,
    PROPERTY_POI_URL,
    PROPERTY_BOOKING_SITES_URL,
    PROPERTY_CANCELATION_POLICIES_URL,
    PROPERTY_AMENITIES_URL,
    PROPERTY_DECLINED_CC_ACTIONS_URL,
    PROPERTY_DESCRIPTIONS_URL,
    PROPERTY_PROMOTION_LIST_URL,
    PROPERTY_PROMOTION_EDIT_URL,
    PROPERTY_PROMOTION_URL,
    PROPERTY_RM_SETUP_LIST_URL,
    PROPERTY_RM_SETUP_EDIT_URL,
    PROPERTY_RM_SETUP_PARAMETER_URL,
    PROPERTY_RM_SETUP_URL,
    PROPERTY_STATUS_URL,
    CLIENT_TYPE_URL,
    PROPERTY_STATUS_VALIDATE_URL, PRP_SETUP_URL,
    PROPERTY_PROMOTION_RATE_PLAN_MAPPING_URL,
    PROPERTY_RM_SETUP_RATE_PLAN_MAPPING,
    PROPERTY_VALIDATION_URL,
    INVENTORY_DEFAULT_URL,
    PROPERTY_IMPORT_URL,
    PROPERTY_FEATURE_SETUP_URL, GET_BULK_DATA,

} from '@/services/endpoints'


export function getPropertyList(filter) {
    return http.request({
        url: '/adminapi/property-list',
        method: 'post',
        data: filter
    })
}

export function getPropertyTypeList() {
    return http.request({
        url: '/adminapi/property-type',
        method: 'get'
    })
}

export function getProperty(id) {
    return http.request({
        url: '/adminapi/property/' + id,
        method: 'get',
    })
}

export function setProperty(id, data) {
    return http.request({
        url: PROPERTY_URL + "/" + id,
        method: 'put',
        data: data
    })
}

export function deleteProperty(id) {
    return http.request({
        url: PROPERTY_URL + "/" + id,
        method: 'delete'
    })
}


export function createProperty(data) {
    return http.request({
        url: PROPERTY_URL,
        method: 'post',
        data: data
    })
}
export function createPropertyWithOwner(postData){
    return http.request({
        url:PROPERTY_WITH_OWNER_URL,
        method:'post',
        data:postData
    })
}
export function validationCreatePropertyWithOwner(postData) {
    return http.request({
        url:PROPERTY_VALIDATION_URL,
        method:'post',
        data:postData
    })
}

export function getPropertyPoi(id) {
    return http.request({
        url: PROPERTY_POI_URL(id),
        method: 'get',
    })
}

export function setPropertyPoi(id, data) {

    return http.request({
        url: PROPERTY_POI_URL(id),
        method: 'put',
        data: data
    })
}

export function deletePropertyPoi(id, data) {

    return http.request({
        url: PROPERTY_POI_URL(id),
        method: 'delete',
        data: data
    })
}

export function getPropertyBookingSites(id) {
    return http.request({
        url: PROPERTY_BOOKING_SITES_URL(id),
        method: 'get',
    })
}

export function getAllPropertyBookingSites(id) {
    return http.request({
        url: PROPERTY_BOOKING_SITES_URL(id) + '/all',
        method: 'get',
    })
}

export function updatePropertyBookingSites(id, data) {
    return http.request({
        url: PROPERTY_BOOKING_SITES_URL(id),
        method: 'put',
        data: data
    })
}

export function deletePropertyBookingSites(id, data) {
    return http.request({
        url: PROPERTY_BOOKING_SITES_URL(id),
        method: 'delete',
        data: data
    })
}

export function getPropertyCancelationPolicies(id) {
    return http.request({
        url: PROPERTY_CANCELATION_POLICIES_URL(id),
        method: 'get',
    })
}

export function getPropertyCancelationPolicyOptions(id) {
    return http.request({
        url: PROPERTY_CANCELATION_POLICIES_URL(id) + '/options',
        method: 'get',
    })
}

export function createPropertyCancelationPolicy(id, data) {
    return http.request({
        url: PROPERTY_CANCELATION_POLICIES_URL(id),
        method: 'post',
        data: data
    })
}

export function updatePropertyCancelationPolicy(id, policyId, data) {
    return http.request({
        url: PROPERTY_CANCELATION_POLICIES_URL(id) + '/' + policyId,
        method: 'put',
        data: data
    })
}

export function deletePropertyCancelationPolicy(id, policyId) {
    return http.request({
        url: PROPERTY_CANCELATION_POLICIES_URL(id) + '/' + policyId,
        method: 'delete'
    })
}

export function getPropertyAmenityList(id) {
    return http.request({
        url: PROPERTY_AMENITIES_URL(id),
        method: 'get',
    })
}

export function setPropertyAmenity(id, amenityId, data) {
    return http.request({
        url: PROPERTY_AMENITIES_URL(id) + '/' + amenityId,
        method: 'put',
        data: data
    })
}

export function updatePropertyAmenityList(id, data) {
    return http.request({
        url: PROPERTY_AMENITIES_URL(id),
        method: 'put',
        data: data
    })
}

export function getPropertyDescriptionList(id) {
    return http.request({
        url: PROPERTY_DESCRIPTIONS_URL(id),
        method: 'get',
    })
}

export function getPropertyDeclinedCcActions(id) {
    return http.request({
        url: PROPERTY_DECLINED_CC_ACTIONS_URL(id),
        method: 'get',
    })
}

export function setPropertyDeclinedCcActions(id, data) {
    return http.request({
        url: PROPERTY_DECLINED_CC_ACTIONS_URL(id),
        method: 'put',
        data: data
    })
}

export function setPropertyDescription(id, postData) {
    return http.request({
        url: '/adminapi/property/' + id + '/descriptions',
        method: 'put',
        data: postData
    })
}

export function getPropertyPromotionList(id) {
    return http.request({
        url: PROPERTY_PROMOTION_LIST_URL(id),
        method: 'get'
    })
}

export function deletePropertyPromotion(id, promotionId) {
    return http.request({
        url: PROPERTY_PROMOTION_EDIT_URL(id, promotionId),
        method: 'delete'
    })
}

export function createPropertyPromotion(id, data) {
    return http.request({
        url: PROPERTY_PROMOTION_URL(id),
        method: 'post',
        data: data
    })
}

export function updatePropertyPromotion(id, promotion_id, data) {
    return http.request({
        url: PROPERTY_PROMOTION_EDIT_URL(id, promotion_id),
        method: 'put',
        data: data
    })
}
export function getPropertyPromotionRatePlanMapping(id, promotion_id) {
    return http.request({
        url: PROPERTY_PROMOTION_RATE_PLAN_MAPPING_URL(id, promotion_id),
        method: 'get',
    })
}

export function setPropertyPromotionRatePlanMappingList(id, promotion_id, data) {
    return http.request({
        url: PROPERTY_PROMOTION_RATE_PLAN_MAPPING_URL(id, promotion_id),
        method: 'put',
        data:data
    })
}
export function getAmenityOptionList(id) {
    return http.request({
        url: '/adminapi/property/' + id + '/amenity-options',
        method: 'get',
    })
}

export function getPropertyRmSetupList(id, data) {
    return http.request({
        url: PROPERTY_RM_SETUP_LIST_URL(id),
        method: 'post',
        data: data
    })
}

export function deletePropertyRmSetup(id, collectionId, data) {
    return http.request({
        url: PROPERTY_RM_SETUP_EDIT_URL(id, collectionId),
        method: 'delete',
        data: data
    })
}

export function getRmSetupFormParameter(id, data) {

    return http.request({
        url: PROPERTY_RM_SETUP_PARAMETER_URL(id),
        method: 'post',
        data: data,
    })
}

export function createRmSetup(id, data) {
    return http.request({
        url: PROPERTY_RM_SETUP_URL(id),
        method: 'post',
        data: data,
    })
}

export function updateRmSetup(id, collection_id, data) {
    return http.request({
        url: PROPERTY_RM_SETUP_EDIT_URL(id, collection_id),
        method: 'put',
        data: data
    })
}

export function getStatuses(id) {
    return http.request({
        url: PROPERTY_STATUS_URL(id),
        method: 'get'
    })
}

export function setStatus(id, status_transition_id, data) {
    return http.request({
        url: PROPERTY_STATUS_URL(id) + '/' + status_transition_id,
        method: 'post',
        data: data
    })
}

export function validateStatus(propertyId, statusTransitionId) {
    return http.request({
        url: PROPERTY_STATUS_VALIDATE_URL(propertyId, statusTransitionId),
        method: 'get',
    })
}

export function getClientList(company_id) {
    return http.request({
        url: CLIENT_TYPE_URL,
        method: 'get',
        params: {
            company: company_id
        }
    })
}

export function getPropertySystemCancelationPolicies(id) {
    return http.request({
        url: PROPERTY_CANCELATION_POLICIES_URL(id) + '/company',
        method: 'get',
    })
}

export function applyPropertyCancelationPolicy(id, data) {
    return http.request({
        url: PROPERTY_CANCELATION_POLICIES_URL(id) + '/apply-policy',
        method: 'post',
        data
    })
}

export function createPropertyPdf(id) {
    return http.request({
        url: PROPERTY_URL + "/" + id + '/pdf',
        method: 'post'
    })
}

export function sendPropertyPdfEmail(id, data) {
    return http.request({
        url: PROPERTY_URL + "/" + id + '/pdf/email',
        method: 'post',
        data: data
    })
}

export function getPropertyPdfEmails(id) {
    return http.request({
        url: PROPERTY_URL + "/" + id + '/pdf/email-contacts',
        method: 'get'
    })
}

export function getPrpMessage(id) {
    return http.request({
        url: PRP_SETUP_URL(id) + '/message',
        method: 'get'
    })
}
export function setPrpMessage(id, data) {
    return http.request({
        url: PRP_SETUP_URL(id) + '/message',
        method: 'put',
        data: data
    })
}
export function getRmSetupRatePlanMapping(id, collection_id) {
    return http.request({
        url: PROPERTY_RM_SETUP_RATE_PLAN_MAPPING(id, collection_id),
        method: 'get',
    })
}
export function setRmSetupRatePlanMapping(id, collection_id, data){
    return http.request({
        url: PROPERTY_RM_SETUP_RATE_PLAN_MAPPING(id, collection_id),
        method: 'put',
        data: data
    })
}

export function getEventMailTemplatesList(id) {
    return http.request({
        url: PROPERTY_URL + '/' + id + '/event-templates',
        method: 'get'
    })
}

export function fetchEventMailTemplate(templateId, params) {
    return http.request({
        url: 'adminapi/event-template/' + templateId,
        method: 'get',
        params: params
    })
}

export function createEventTemplate(id, data) {
    return http.request({
        url: PROPERTY_URL + '/' + id + '/event-template',
        method: 'post',
        data: data
    })
}

export function updateEventTemplate(id, templateId, data) {
    return http.request({
        url: PROPERTY_URL + '/' + id + '/event-template/' + templateId,
        method: 'put',
        data: data
    })
}

export function deleteEventMailTemplate(id, templateId) {
    return http.request({
        url: PROPERTY_URL + '/' + id + '/event-template/' + templateId,
        method: 'delete'
    })
}


export function updateEventTemplateActiveParam (id, templateId, data) {
    return http.request({
        url: PROPERTY_URL + '/' + id + '/event-template/' + templateId + '/activate',
        method: 'put',
        data: data
    })
}

export function getUserAccessUnits(id) {
    return http.request({
        url: PROPERTY_URL + '/' + id + '/user-units',
        method: 'get'
    })
}

export function updateUserAccessUnits(id, data) {
    return http.request({
        url: PROPERTY_URL + '/' + id + '/user-units',
        method: 'put',
        data: data
    })
}

export function getExistingUserAccessList(id, params) {
    return http.request({
        url: PROPERTY_URL + '/' + id + '/user-units/user-list',
        method: 'get',
        params
    })
}

export function getUnitsICal(id) {
    return http.request({
        url: PROPERTY_URL + '/' + id + '/ical',
        method: 'get'
    })
}

export function applySystemTemplates(id, data) {
    return http.request({
        url: PROPERTY_URL + '/' + id + '/system-templates',
        method: 'post',
        data: data
    })
}

export function propertyQuickSearch(params, cancelToken = null) {
    return http.request({
        url: PROPERTY_URL + '/quick-search',
        method: 'get',
        cancelToken: cancelToken,
        params: params
    })
}

export function getForResolve(external_id = '', params) {
    return http.request({
        url: PROPERTY_URL + '/for-resolve' + `${external_id ? `/${external_id}` : ''}`,
        method: 'GET',
        params,
    })
}
export function getInventoryDefaultValues(params) {
    return http.request({
        url: INVENTORY_DEFAULT_URL + '/values',
        method: 'GET',
        params,
    })
}
export function setInventoryDefaultValues(data) {
    return http.request({
        url: INVENTORY_DEFAULT_URL + '/values',
        method: 'PUT',
        data,
    })
}

export function getPossibleSystemTemplates(params) {
    return http.request({
        url: 'adminapi/possible-system-templates',
        method: 'get',
        params: params
    })
}
export function getCalendarValues(data) {
    return http.request({
        url: PROPERTY_URL  + '/calendar-values',
        method: 'post',
        data

    })
}
export function getPropertyTaggedImages(id){
    return http.request({
        url: PROPERTY_URL + '/' + id + '/gallery-images',
        method: 'get'
    })
}

export function postPropertyTaggedImages(id, data){
    return http.request({
        url: PROPERTY_URL + '/' + id + '/gallery-images',
        method: 'post',
        data
    })
}

export function importPropertyFetchData(params) {
    return http.request({
        url: PROPERTY_IMPORT_URL,
        method: "get",
        params: params
    })
}

export function importPropertyValidate(data) {
    return http.request({
        url: PROPERTY_IMPORT_URL + "/validate",
        method: "post",
        data: data
    })
}

export function importProperty(data) {
    return http.request({
        url: PROPERTY_IMPORT_URL,
        method: "post",
        data: data
    })
}

export function getPropertyFeatureSetup(id, params) {
    return http.request({
        url: PROPERTY_FEATURE_SETUP_URL(id),
        method: 'get',
        params
    })
}

export function setPropertyFeatureSetup(id, data) {
    return http.request({
        url: PROPERTY_FEATURE_SETUP_URL(id),
        method: 'put',
        data
    })
}

export function copyBulkCalendar(id, data) {
    return http.request({
        url: GET_BULK_DATA(id) + "/copy",
        method: "post",
        data
    })
}
