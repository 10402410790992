<template>
    <div>
        <b-card class="main-section-card">
            <app-no-data v-if="empty_initial_list" :heading="$t('MODULE_NOT_ACTIVE')"
                         :tip="$t('MODULE_NEED_PROPERTIES')" :show-tip="true">
                <new-button v-if="checkPermission(R_PROPERTY_NEW)" button_type="new" variant="primary"
                            class="pull-right ml-3" @click="property_aside = true">
                    {{ $t('NEW_PROPERTY') }}
                </new-button>
                <app-button
                    :access_control_context="{function: C_PROPERTY_IMPORT_FROM_OTA, key: access_control_fetch_key}"
                    @AccessControlEvent="addToAccessControlCounter()"
                    class="pull-right ml-3"
                    @click="importPropertyModalState = true">
                    {{ $t("IMPORT_FROM_OTA") }}
                </app-button>
            </app-no-data>

            <owner-filter v-if="!empty_initial_list" @search="search" :loading="loading"></owner-filter>
            <owner-table v-if="!empty_initial_list" :filter="filter" @busyToggled="toggleBusy"
                         :setup_parameter="setup_parameter"></owner-table>
            <b-modal
                v-model="importPropertyModalState"
                :title="$t('IMPORTANT')">
                <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "Booking.com"}) }}</p>
                <ul>
                    <li>{{ $t("IMPORT_PROPERTY_FROM_BOOKING_DISCLAIMER_1") }}</li>
                    <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
                </ul>
                <app-button
                    @click="importFromDistribution(BOOKING)">
                    {{ $t("IMPORT") }}
                </app-button>
                <br/>
                <br/>
                <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "AirBnB"}) }}</p>
                <ul>
                    <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_1") }}</li>
                    <li>{{
                            $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_2", {
                                "min_image_width": 2048,
                                "min_image_height": 1536
                            })
                        }}
                    </li>
                    <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_3") }}</li>
                    <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
                </ul>
                <app-button
                    @click="importFromDistribution(AIRBNB)">
                    {{ $t("IMPORT") }}
                </app-button>
                <template slot="modal-footer">
                    <template slot="modal-ok">
                    </template>
                </template>
            </b-modal>
        </b-card>
        <app-aside :widths="['col-sm-12', '40%']" v-model="property_aside">
            <template slot="header">{{ $t('NEW_PROPERTY') }}</template>
            <property-wizard></property-wizard>
        </app-aside>
        <app-aside
            v-model="importPropertyWizardState"
            :widths="['col-lg-12', '100%']">
            <template slot="header">{{ $t('IMPORT_FROM_OTA') }}</template>
            <import-property-wizard
                @importProperty="closeImportWizard"
                @close="closeImportWizard"
                :distribution="importDistribution"
                :entity="importEntity">
            </import-property-wizard>
        </app-aside>
    </div>
</template>

<script>
import {cloneDeep} from "lodash";
import {C_PROPERTY_IMPORT_FROM_OTA, R_PROPERTY_NEW} from "@/shared/route_permission";
import {AIRBNB, BOOKING, DEFAULT_PAGINATION_LIMIT, LS_PRESETS} from "@/shared/constants";
import AppAside from "@/components/app/form/AppAside";
import PropertyWizard from "@/components/property/new/PropertyWizard";
import ImportPropertyWizard from "@/components/property/import/ImportPropertyWizard";
import NewButton from '@/components/app/AppButton/AppButton'
import AppButton from "@/components/app/AppButton/AppButton";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_PROPERTY_IMPORT} from "@/mixins/AccessControl/AccessControlEnumeration";
import AppNoData from "@/components/app/AppNoData";

export default {
    name: "OwnerReservationSetup",
    components: {
        "OwnerFilter": () => import("@/components/owner/list/OwnerFilter"),
        "OwnerTable": () => import("@/components/owner/list/OwnerTable"),
        ImportPropertyWizard,
        AppButton,
        PropertyWizard,
        NewButton, AppAside, AppNoData
    },
    mixins: [AccessControlComponent],
    props: {
        setup_parameter: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            filter: {},
            loading: false,
            R_PROPERTY_NEW,
            C_PROPERTY_IMPORT_FROM_OTA,
            property_aside: false,
            empty_initial_list: false,
            importPropertyModalState: false,
            importPropertyWizardState: false,
            BOOKING,
            AIRBNB,
            access_control_components: 1,
            access_control_fetch_key: AC_PROPERTY_IMPORT,
            importDistribution: null,
            importEntity: null,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods: {
        search(value) {
            this.filter = cloneDeep(value)
            this.loading = true
        },
        toggleBusy($event) {
            this.loading = $event
        },
        importPropertyTermsConfirmed() {
            this.importPropertyModalState = false
            this.importPropertyWizardState = true
        },
        importFromDistribution(distributionId) {
            this.importDistribution = distributionId
            this.importPropertyTermsConfirmed()
        },
        closeImportWizard() {
            this.importPropertyWizardState = false
            this.importDistribution = null
            this.importEntity = null
            this.$router.replace({"query": null})
        },
    },
    created() {
        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)

            if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                if (storagePresets.properties === 0) {
                    this.empty_initial_list = true
                }
            }
        }
        this.access_control_general_context = {
            company: this.company.id
        }
    }
}
</script>

<style scoped>

</style>
