<template>
    <div>
        <app-table-v2 :fields="computedFields"
                      :provider="getData"
                      :default-limit="defaultLimit"
                      :show-limit="showLimit"
                      @busyToggled="toggleBusy" :search-event="SEARCH_EVENT"
                      :refresh-event="EVENT_TABLE_REFRESH" >
            <template v-slot:user="data">
                {{data.item.user_uploaded.first_name}} {{data.item.user_uploaded.last_name}}
            </template>

            <template v-slot:timestamp="data">
                <small class="text-muted d-block">
                    {{data.item.ts_uploaded | time}}
                </small>
                {{data.item.ts_uploaded | date}}
            </template>

            <template v-slot:status="data">
                <template v-if="data.item.status.id === STATUS_INVOICE_CREATED">
                    {{data.item.status.label}} (<b-link :to="{name: routeNames.RN_INVOICE_LIST, query: {payment_message: data.item.id}}" target="_blank">{{$t('INVOICES')}}</b-link>)
                </template>
                <template v-else>
                    {{data.item.status.label}}
                </template>
            </template>

            <template v-slot:actions="data">
                <app-button @click="openPaymentMessage(data.item)"
                            icon="edit"
                            :show_text="false"
                            variant="link">
                </app-button>
            </template>
        </app-table-v2>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppTableV2 from "@/components/app/AppTableV2";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {EventBus} from "@/shared/EventBus";
    import {cloneDeep} from "lodash";
    import {getPaymentMessageList} from "@/services/finance/payment_message";
    import routeNames from "@/router/routeNames";

    const STATUS_INVOICE_CREATED = 14

    export default {
        name: "PaymentMessageListTable",
        components: {AppButton, AppTableV2},
        mixins: [getErrorMessage],
        props: {
            defaultLimit: {
                type: Number,
                default: 10
            },
            showLimit: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                filter: null,
                fields: [
                    {key: 'message_number', label: 'EID'},
                    {key: 'user', label: this.$t('USER')},
                    {key: 'item_number', label: this.$t('ITEMS'), class: 'w-10'},
                    {key: 'status', label: this.$t('STATUS')},
                    {key: 'timestamp', label: this.$t('DATE_CREATED')},
                    {key: 'actions', label: '', class: 'text-right'},
                ],
                SEARCH_EVENT: 'getPaymentMessages',
                routeNames,
                STATUS_INVOICE_CREATED,
                EVENT_TABLE_REFRESH: 'refreshPaymentMessageList',
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            computedFields() {
                return this.fields
            }
        },
        methods: {
            getData(req) {
                return getPaymentMessageList({
                    page: req.currentPage,
                    limit: req.perPage,
                    ...this.filter,
                    company: this.company.id,
                    order_by: 'id',
                    order_by_direction: 'desc'
                })
            },
            toggleBusy($event) {
                EventBus.$emit('loading', $event)
            },
            openPaymentMessage(item) {
                this.$emit('openPaymentMessage', item)
            }
        },
        mounted() {
            EventBus.$emit(this.SEARCH_EVENT)

            EventBus.$on('searchPaymentMessages', filter => {
                if (filter) {
                    this.filter = cloneDeep(filter)
                }
                EventBus.$emit(this.SEARCH_EVENT)
            })
        },
        beforeDestroy() {
            EventBus.$off('searchPaymentMessages')
        }
    }
</script>

<style scoped>

</style>
