<template>
    <div v-if="loading">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else>

        <form @submit.prevent="save">
            <template v-if="contigentMode">
                <b-row>
                    <b-col class="mb-3">
                        <b-form-radio-group
                            v-model="av_mode_radio_value"
                            :options="av_mode_radio_options"

                            id="radios-open-close-availability"
                            name="radios-open-close-availability"
                            class="main_calendar-custom-radio"
                        ></b-form-radio-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-5">
                        <app-number-input :min="minValue" :max="maxValue" controls v-model="units"></app-number-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-5" v-if="showNote">
                        <label>{{$t('NOTE')}}</label>
                        <b-textarea rows="3"></b-textarea>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <submit-button :disabled="isDisabled" :c_permission="c_edit"
                                       :loading="loading_button"></submit-button>
                    </b-col>
                </b-row>
            </template>
            <template v-else>
                <b-row class="mb-4">
                    <b-col>
                        <b-form-radio-group
                            class="main_calendar-custom-radio"
                            v-model="units"
                            stacked
                            :options="radio_options"
                            id="radios-open-close-availability"
                            name="radios-open-close-availability"
                        ></b-form-radio-group>
                    </b-col>
                </b-row>

                <b-row class="mb-4" v-if="showNote">
                    <b-col>
                        <label>{{$t('NOTE')}}</label>
                        <b-textarea rows="3" v-model="note"></b-textarea>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <submit-button :disabled="dateRanges.length === 0 || units === null" :c_permission="c_edit"
                                       :loading="loading_button"></submit-button>

                    </b-col>
                </b-row>
            </template>


        </form>
    </div>

</template>

<script>
import SubmitButton from '@/components/app/AppButton/AppButtonSubmit'
import AppValidationDescription from '@/components/app/form/AppValidationDescription'
import {required} from 'vuelidate/lib/validators'
import {toast} from '@/shared/plugins/toastr'
import {setUnitAvailibility} from "@/services/unit";

import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import {C_PROPERTY_CALENDAR_AVAILIBILITY_E} from "@/shared/component_permission";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import {has, get} from 'lodash'


export default {
    name: "CalendarAvailabilityTest",
    components: {
        AppNumberInput,
        SubmitButton, AppValidationDescription
    },
    props: {
        dateRanges: {
            type: Array
        },
        defaultValues: {
            type: Object
        },
        rootUnit: {
            type: Object
        },
        contigentUnit: {
            type: Object
        },
        contigentMode: {
            type: Boolean
        },
        property:{
            type:Object
        }
    },
    mixins: [getErrorMessage],
    data() {
        return {
            units: null,
            loading: false,
            loading_button: false,
            c_edit: C_PROPERTY_CALENDAR_AVAILIBILITY_E,
            radio_options: [
                {text: this.$t("AVAILABILITY_UNIT_CALENDAR_OPEN"), value: 1},
                {text: this.$t("AVAILABILITY_UNIT_CALENDAR_CLOSED"), value: 0},
            ],
            radio_value: null,
            av_mode_radio_options: [
                {text: this.$t("SET_AVAILABILITY_UNIT_CALENDAR"), value: null},
                {text: this.$t("INCREASE_DECREASE"), value: 1},
            ],
            av_mode_radio_value: null,
            note: null,
            initialUnits: null,
            showNote: false,
        }
    },
    methods: {
        save() {
            this.$v.$touch();
            if ((this.$v.$error)) {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('VALIDATION_FAILED'),
                    'type': 'error',
                    'timeout': 6000
                })
                return
            }

            this.loading_button = true

            let requestData = {
                list: this.dateRanges.map(element => {
                    return {
                        ...element,
                        ...{
                            unit: this.contigentUnit ? this.contigentUnit.id : this.rootUnit.id,
                            value: this.units,
                            contigent: this.contigentMode === true ? 1 : 0,
                            relative: this.av_mode_radio_value === 1 ? 1 : 0,
                            note: this.note

                        }
                    }
                })
            }

            setUnitAvailibility(requestData).then(() => {
                this.loading_button = false
                //reload data
                this.$emit('successSetAvailability')


                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
            }, error => {

                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
                this.loading_button = false
            })
        }
    },
    computed: {

        isDisabled() {
            return this.dateRanges.length === 0 || this.units === null || (this.av_mode_radio_value === 1 && this.units === 0)
        },
        isRoot() {
            return !this.contigentUnit
        },

        minValue() {
            if (this.av_mode_radio_value === null && has(this.defaultValues, 'availability.absolute.min')) {
                return get(this.defaultValues, 'availability.absolute.min')
            } else if (this.av_mode_radio_value !== null && has(this.defaultValues, 'availability.relative.min')) {
                return get(this.defaultValues, 'availability.relative.min')
            } else {
                return Number.NEGATIVE_INFINITY
            }
        },
        maxValue() {
            if (this.av_mode_radio_value === null && has(this.defaultValues, 'availability.absolute.max')) {
                return get(this.defaultValues, 'availability.absolute.max')
            } else if (this.av_mode_radio_value !== null && has(this.defaultValues, 'availability.relative.max')) {
                return get(this.defaultValues, 'availability.relative.max')
            } else {
                return Number.POSITIVE_INFINITY
            }
        }
    },
    validations: {
        units: {
            required
        }
    },
    watch: {
        defaultValues: {
            handler(defaultValue) {
                if (defaultValue && defaultValue.hasOwnProperty('availability')) {
                    this.units = defaultValue.availability.value
                    this.initialUnits = defaultValue.availability.value
                    this.note = defaultValue.availability.note
                }
            },
            immediate: true,
            deep: true
        },
        av_mode_radio_value(val) {
            if (val) {
                this.units = null
            } else {
                if (this.defaultValues && this.defaultValues.hasOwnProperty('availability')) {
                    this.units = this.defaultValues.availability.value
                }
            }
        },
        dateRanges: {
            handler() {
                this.units = null
            },
            deep: true
        },
        units: {
            handler(value) {

                if (!this.contigentMode) {

                    this.showNote = this.initialUnits && !value || this.initialUnits === 0 && !value  || this.initialUnits === null && value === 0 ? true : false

                } else {
                    this.showNote = this.av_mode_radio_value && value < 0 ? true : false

                }
            }, immediate: true,
            deep:true
        }
    }
}
</script>

<style scoped>

</style>
