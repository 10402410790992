<template>

    <app-aside :value="state" @input="$emit('closeAside')">
        <template slot="header">
            {{$t("COPY_IMAGES_TO_UNITS")}}
            <h4 class="header-info" v-if="list.length > 1">{{$t("ITEMS_SELECTED", {value: list.length})}}</h4>
            <h4 class="header-info" v-else-if="list.length === 1">{{$t("ITEM_SELECTED")}}</h4>
        </template>
        <b-form @submit.prevent="save">
            <b-form-checkbox-group class="mb-3" id="flavors"
                                   stacked
                                   v-model="selected"
                                   :options="options"
                                   name="objects"
                                   aria-label="Checkbox objects">

            </b-form-checkbox-group>
            <app-button-submit :disabled="disabledButton">
                {{$t('COPY')}}
            </app-button-submit>
        </b-form>
    </app-aside>
</template>

<script>
    import AppAside from '@/components/app/form/AppAside'
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";

    export default {
        name: "GalleryCopy",
        props: {
            list: {
                type: Array
            },
            ObjectList: {
                type: Array
            },
            state: {
                type: Boolean,
                default: false
            }
        },
        components: {
            AppButtonSubmit,
            AppAside,
        },
        data() {
            return {
                selected: []
            }
        },
        computed: {
            options() {
                return this.ObjectList.map(item => {
                    return {text: item.name, value: item.id}
                })
            },
            disabledButton() {
                return this.selected.length === 0
            }
        },
        methods: {
            save() {
                let response = {
                    object_list: this.selected,
                    document_list: this.list
                }

                this.$emit('saveCopyItems', response)
                this.$emit('closeAside')
                this.selected = []
            }
        }
    }
</script>

<style scoped>

</style>
