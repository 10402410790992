<template>
    <div>
        <rate-plan-setup :setup_parameter="SETUP_PARAMETER_CURRENCY_SOURCE"></rate-plan-setup>
    </div>
</template>

<script>

import {SETUP_PARAMETER_CURRENCY_SOURCE} from "@/shared/constants";

export default {
    name: "Index",
    components: {
        "RatePlanSetup": () => import("@/components/rate_plan/setup/RatePlanSetup"),
        "OwnerReservationSetup": () => import("@/components/owner/OwnerReservationSetup"),
    },
    data() {
        return {
            SETUP_PARAMETER_CURRENCY_SOURCE
        }
    },
}
</script>

<style scoped>

</style>
