<template>
    <b-tabs v-if="Object.keys(unitObject).length > 0"
            v-model="tab"
            nav-class="tab_navigation-horizontal tab-nav mt-5"
            content-class="tab_content-horizontal"
            pills justified>
        <template v-if="company.workflow_id === WORKFLOW_DIRECT_BOOKER">
            <b-tab :title="$t('AUTOMATIC_RATES_SETUP')">
                <template v-if="Number(propertyObject.rates_activated) === 1 || activated_rates">
                    <revenue-manager-setup-action-table mode="unit"
                                                        v-if="tab === 0"
                                                        :object_id="unitObject.id"
                                                        :type_action="ACTION_TYPE_MIN_PRICE"
                                                        :read_only="read_only"
                                                        :read_only_message="read_only_message"
                    ></revenue-manager-setup-action-table>
                </template>
                <app-no-data v-else :heading="$t('MODULE_NOT_ACTIVE')" :show-tip="false">
                    <b-tooltip v-if="read_only" triggers="hover"
                               :target="propertyObject.id+'_automatic_rates_setup_activate'"
                               :title="read_only_message"
                    ></b-tooltip>
                    <app-button :id="propertyObject.id+'_automatic_rates_setup_activate'"
                                :disabled="read_only"
                                @click="activationRateAside = true"
                    >{{ $t('ACTIVATE') }}</app-button>
                </app-no-data>
            </b-tab>

            <b-tab :title="$t('AUTOMATIC_MIN_STAY_SETUP')">
                <template v-if="Number(propertyObject.min_stay_activated) === 1 || activated_min_stay">
                    <revenue-manager-setup-action-table v-if="tab === 1"
                                                        mode="unit"
                                                        :object_id="unitObject.id"
                                                        :type_action="ACTION_TYPE_MIN_STAY"
                                                        :read_only="read_only"
                                                        :read_only_message="read_only_message"
                    ></revenue-manager-setup-action-table>
                </template>
                <app-no-data v-else :heading="$t('MODULE_NOT_ACTIVE')" :show-tip="false">
                    <b-tooltip v-if="read_only" triggers="hover"
                               :target="propertyObject.id+'_automatic_min_stay_setup_activate'"
                               :title="read_only_message"
                    ></b-tooltip>
                    <app-button :id="propertyObject.id+'_automatic_min_stay_setup_activate'"
                                :disabled="read_only"
                                @click="activationMinStayAside = true"
                    >{{ $t('ACTIVATE') }}</app-button>
                </app-no-data>
            </b-tab>

            <b-tab :title="$t('DEVIATION')">
                <unit-bulk-revenue-manager v-if="tab === 2"
                                           mode="deviation_setup"
                                           :primary-rate-plan="primaryRatePlan"
                                           :default-los="default_los"
                                           :default-occupancy="default_occupancy"
                                           :property_id="unitObject.property.id"
                                           :unit_id="unitObject.id"
                                           :read_only="read_only"
                                           :read_only_message="read_only_message"
                ></unit-bulk-revenue-manager>
            </b-tab>

            <b-tab :title="$t('SECURED_VALUES')">
                <unit-bulk-revenue-manager v-if="tab === 3"
                                           mode="secured_values"
                                           :primary-rate-plan="primaryRatePlan"
                                           :default-los="default_los"
                                           :default-occupancy="default_occupancy"
                                           :property_id="unitObject.property.id"
                                           :unit_id="unitObject.id"
                                           :read_only="read_only"
                                           :read_only_message="read_only_message"
                ></unit-bulk-revenue-manager>
            </b-tab>

            <b-tab :title="$t('COMPETITORS')">
                <revenue-manager-competitor-form v-if=" tab === 4"
                                                 :id="unitObject && unitObject.hasOwnProperty('filter') && unitObject.filter && unitObject.filter.hasOwnProperty('id') ? unitObject.filter.id : null"
                                                 :unit_id="unitObject.id"
                                                 :read_only="read_only"
                                                 :read_only_message="read_only_message"
                ></revenue-manager-competitor-form>
            </b-tab>
        </template>
        <template v-else>
            <b-tab :title="$t('RATES')">
                <template v-if="Number(propertyObject.rates_activated) === 1 || activated_rates">
                    <revenue-manager-setup-action-table mode="unit"
                                                        v-if="tab === 0"
                                                        :object_id="unitObject.id"
                                                        :type_action="ACTION_TYPE_MIN_PRICE"
                                                        :read_only="read_only"
                                                        :read_only_message="read_only_message"
                    ></revenue-manager-setup-action-table>
                </template>
                <app-no-data v-else :heading="$t('MODULE_NOT_ACTIVE')" :show-tip="false">
                    <b-tooltip v-if="read_only" triggers="hover"
                               :target="propertyObject.id+'_automatic_rates_setup_activate'"
                               :title="read_only_message"
                    ></b-tooltip>
                    <app-button :id="propertyObject.id+'_automatic_rates_setup_activate'"
                                :disabled="read_only"
                                @click="activationRateAside = true"
                    >{{ $t('ACTIVATE') }}</app-button>
                </app-no-data>
            </b-tab>

            <b-tab :title="$t('MIN_STAY')">
                <template v-if="Number(propertyObject.min_stay_activated) === 1 || activated_min_stay">
                    <revenue-manager-setup-action-table v-if="tab === 1"
                                                        mode="unit"
                                                        :object_id="unitObject.id"
                                                        :type_action="ACTION_TYPE_MIN_STAY"
                    ></revenue-manager-setup-action-table>
                </template>
                <app-no-data v-else :heading="$t('MODULE_NOT_ACTIVE')" :show-tip="false">
                    <b-tooltip v-if="read_only" triggers="hover"
                               :target="propertyObject.id+'_automatic_min_stay_setup_activate'"
                               :title="read_only_message"
                    ></b-tooltip>
                    <app-button :id="propertyObject.id+'_automatic_min_stay_setup_activate'"
                                :disabled="read_only"
                                @click="activationMinStayAside = true"
                    >{{ $t('ACTIVATE') }}</app-button>
                </app-no-data>
            </b-tab>

            <b-tab :title="$t('SECURED_VALUES')">
                <unit-bulk-revenue-manager v-if="tab === 2"
                                           mode="secured_values"
                                           :primary-rate-plan="primaryRatePlan"
                                           :default-los="default_los"
                                           :default-occupancy="default_occupancy"
                                           :property_id="unitObject.property.id"
                                           :unit_id="unitObject.id"
                                           :read_only="read_only"
                                           :read_only_message="read_only_message"
                ></unit-bulk-revenue-manager>
            </b-tab>

            <b-tab :title="$t('COMPETITORS')">
                <revenue-manager-competitor-form v-if="tab === 3"
                                                 :id="unitObject && unitObject.hasOwnProperty('filter') && unitObject.filter && unitObject.filter.hasOwnProperty('id') ? unitObject.filter.id : null"
                                                 :unit_id="unitObject.id"
                                                 :read_only="read_only"
                                                 :read_only_message="read_only_message"
                ></revenue-manager-competitor-form>
            </b-tab>
        </template>

        <app-aside v-model="activationRateAside">
            <template v-slot:header>
                {{ $t('ACTIVATION_RATES') }}
            </template>
            <revenue-manager-setup-rates-activation v-if="propertyObject.id"
                                                    mode="new"
                                                    :property_ids="[propertyObject.id]"
                                                    :submodule="ACTIVE_REVENUE_MANAGER_RATES"
                                                    @close="activationRateAside = false; activated_rates = true"
            ></revenue-manager-setup-rates-activation>
        </app-aside>

        <app-aside v-model="activationMinStayAside">
            <template v-slot:header>
                {{ $t('ACTIVATION_MIN_STAY') }}
            </template>
            <revenue-manager-setup-rates-activation v-if="propertyObject.id"
                                                    mode="new"
                                                    :property_ids="[propertyObject.id]"
                                                    :submodule="ACTIVE_REVENUE_MANAGER_MINIMUM_STAY"
                                                    @close="activationMinStayAside = false; activated_min_stay = true"
            ></revenue-manager-setup-rates-activation>
        </app-aside>
    </b-tabs>
</template>

<script>
import AppAside from "@/components/app/form/AppAside";
import routeNames from "@/router/routeNames";
import AppNoData from "@/components/app/AppNoData";
import RevenueManagerSetupActionTable from "@/components/revenue_manager/setup/RevenueManagerSetupActionTable";
import {
    ACTION_TYPE_MIN_PRICE,
    ACTIVE_REVENUE_MANAGER_RATES,
    ACTIVE_REVENUE_MANAGER_MINIMUM_STAY,
    ACTION_TYPE_MIN_STAY,
} from "@/shared/constants";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import UnitBulkRevenueManager from "@/components/unit/bulk_calendar/revenue_manager/UnitBulkRevenueManager";
import RevenueManagerCompetitorForm from "@/components/revenue_manager/setup/unit/RevenueManagerCompetitorForm";
import {WORKFLOW_DIRECT_BOOKER} from "@/shared/constants";
import AppButton from "@/components/app/AppButton/AppButton";
import RevenueManagerSetupRatesActivation
    from "@/components/revenue_manager/setup/properties/RevenueManagerSetupRatesActivation";

export default {
    name: "RevenueManagerActionForm",
    components: {
        RevenueManagerSetupRatesActivation,
        AppButton,
        RevenueManagerCompetitorForm,
        UnitBulkRevenueManager,
        AppButtonSubmit,
        AppNumberInput,
        RevenueManagerSetupActionTable,
        AppNoData,
        AppAside,
    },
    props: {
        unitObject: {
            type: Object,
            default() {
                return {}
            }
        },
        propertyObject: {
            type: Object,
            default() {
                return {}
            }
        },
        previewTab: {
            type: Number
        },
        read_only: {
            type: Boolean,
            default: false
        },
        read_only_message: {
            type: String
        },
    },
    data() {
        return {
            activated_rates: false,
            activated_min_stay: false,
            ACTIVE_REVENUE_MANAGER_RATES,
            ACTIVE_REVENUE_MANAGER_MINIMUM_STAY,
            activationRateAside: false,
            activationMinStayAside: false,
            tab: null,
            routeNames,
            activated: null,
            ACTION_TYPE_MIN_PRICE,
            ACTION_TYPE_MIN_STAY,
            WORKFLOW_DIRECT_BOOKER,
            fetchData: false,
            step: 0,
            default_los: null,
            default_occupancy: null,
            primaryRatePlan: {}
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        currency() {
            if (typeof this.company !== 'undefined' && this.company !== null && this.company.hasOwnProperty('working_currency')) {
                return this.company.working_currency.code
            } else {
                return ''
            }
        },
    },
    methods: {
        setData(unitObject) {
            this.default_los = unitObject.default_los
            this.default_occupancy = unitObject.default_occupancy
            this.primaryRatePlan = unitObject.primary_rate_plan
        }
    },
    watch: {
        unitObject: {
            handler(object) {
                if (Object.keys(object).length > 0) {
                    this.$nextTick(() => {
                        this.setData(object)
                    })
                }
            }, immediate: true
        },
        previewTab: {
            handler(value) {
                this.tab = value
            }, immediate: true
        }
    }
}
</script>

<style scoped>

</style>
