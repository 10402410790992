<template>
    <div>
        <b-table :items="tableItems" :fields="tableFields" :busy="loading">
            <template v-slot:action_buttons="data">
                <app-button variant="link"
                            button_type="edit"
                            :show_text="false"
                            @click="$emit('edit', data.item)">
                </app-button>
            </template>
        </b-table>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";

    export default {
        name: "InvoiceItemList",
        components: {AppButton},
        props: {
            tableItems: {
                type: Array,
                default: []
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                tableFields: [
                    {key: 'name', label: this.$t('NAME')},
                    {key: 'unit', label: this.$t('UNIT_OF_MEASUREMENT')},
                    {key: 'action_buttons', label: '', class: 'text-right'},
                ]
            }
        }
    }
</script>

<style scoped>

</style>
