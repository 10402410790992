<template>
    <div>
        <website-page-list></website-page-list>

        <app-aside v-model="newPageAsideState">
            <template slot="header">
                <app-object-header :label="$t('WEBSITE')" :name="$t('NEW_PAGE')"></app-object-header>
            </template>
            <website-page-general-form in-modal @close="newPageAsideState=false"></website-page-general-form>
        </app-aside>
    </div>
</template>

<script>
export default {
    name: "Index",
    components: {
        "WebsiteNavigationForm": () => import("@/components/direct_booking/website/pages/WebsiteNavigationForm"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "WebsitePageList": () => import("@/components/direct_booking/website/pages/WebsitePageList"),
        "AppAside": () => import("@/components/app/form/AppAside"),
        "WebsitePageGeneralForm": () => import("@/components/direct_booking/website/pages/WebsitePageGeneralForm"),
        "AppObjectHeader": () => import("@/components/app/AppObjectHeader"),
    },
    computed: {
        newPageAsideState: {
            get() {
                return this.$store.state.website.newPageAsideState
            },
            set(value) {
                this.$store.commit('website/setNewPageAsideState', value)
            }
        }
    },
    created() {
        this.$store.commit('website/setCurrentPage', null)
    }
}
</script>

<style scoped>

</style>
