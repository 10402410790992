<template>
    <div v-if="acResponse">
        <b-form @submit.prevent="save">
            <b-row>
                <b-col md="6" class="mb-4" v-if="!invoiceeTypeId" v-show="invoiceeTypeList.length > 1">
                    <label>{{$t('CLIENT_TYPE')}}</label>
                    <app-select :options="invoiceeTypeList" v-model="invoiceeType" @input="setInvoiceTypeList"
                                text-field="label" :search-empty-item="false" set-first></app-select>
                </b-col>

                <b-col md="6" class="mb-4">
                    <label>{{$t('TYPE')}}</label>
                    <app-select :options="invoiceTypeList" v-model="invoiceType" :disabled="!invoiceTypeList.length"
                                :search-empty-item="false"></app-select>
                </b-col>

                <b-col md="6" class="mb-4" v-if="formData.number">
                    <label>{{$t('NUMBER')}}</label>
                    <b-input-group>
                        <b-input type="text" v-model="formData.number"></b-input>
                        <b-input-group-append>
                            <b-input-group-text>{{numberStatic}}</b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>

            <template v-if="invoiceType">
                <b-row>
                    <b-col class="mb-4" v-if="isVisible('payment_type')">
                        <label>{{$t('PAYMENT_TYPE')}}</label>
                        <app-select :options="paymentTypeList"
                                    :disabled="!paymentTypeList.length"
                                    v-model="formData.payment_type"
                                    text-field="label" :search-empty-item="false">
                        </app-select>
                    </b-col>

                    <b-col md="6" class="mb-4">
                        <app-input-control :error-object="validation.description">
                            <template v-slot:input="data">
                                <label>{{$t('DESCRIPTION')}}</label>
                                <b-input type="text" v-model="formData.description" :state="data.state"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="6" class="mb-4">
                        <app-input-control :error-object="validation.delivery_date">
                            <template v-slot:input="data">
                                <label>{{$t('DELIVERY_DATE')}}</label>
                                <app-date-picker v-model="formData.delivery_date" :state="data.state"></app-date-picker>
                            </template>
                        </app-input-control>
                    </b-col>

                    <b-col md="6" class="mb-4">
                        <app-input-control :error-object="validation.due_date">
                            <template v-slot:input="data">
                                <label>{{$t('DUE_DATE')}}</label>
                                <app-date-picker v-model="formData.due_date" :state="data.state"></app-date-picker>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>

                <b-row v-if="ccTypesList.length">
                    <b-col md="6" class="mb-4">
                        <label>{{$t('CC_TYPE')}}</label>
                        <app-select text-field="caption" :options="ccTypesList"
                                    v-model="formData.cc_type"></app-select>
                    </b-col>

                    <b-col md="6" class="mb-4">
                        <label>{{$t('APPROVAL_NUMBER')}}</label>
                        <b-input v-model="formData.cc_approval_number"></b-input>
                    </b-col>
                </b-row>

                <!--                <b-row>-->
                <!--                    <b-col class="d-flex justify-content-between align-items-baseline">-->
                <!--                        <form-header>{{$t('CLIENT')}}</form-header>-->

                <!--                        <app-button icon="new" @click="openPartnerForm(null)">{{$t('NEW_CLIENT')}}</app-button>-->
                <!--                    </b-col>-->
                <!--                </b-row>-->

                <b-row>
                    <b-col :xl="editPartnerVisible ? '10' : ''" md="12" class="mb-4">
                        <app-input-control :error-object="validation.partner">
                            <template v-slot:input="data">
                                <label>{{$t('CLIENT')}}</label>
                                <app-select mode="search" :search="searchPartner" search-clear :state="data.state"
                                            v-model="formData.partner">
                                    <template v-slot:text="data">
                                        {{data.item.name}} {{data.item.last_name}} {{data.item.oib ? `| OIB:
                                        ${data.item.oib}` : ''}}
                                    </template>
                                </app-select>
                            </template>
                        </app-input-control>

                    </b-col>

                    <b-col class="mb-4 d-flex justify-content-end align-items-end"
                           v-if="editPartnerVisible">
                        <!--                        <label>&zwnj;</label>-->
                        <app-button @click="openPartnerForm(formData.partner)">
                            {{$t('EDIT')}}
                        </app-button>
                    </b-col>
                </b-row>

                <template v-if="mapReservations">
                    <b-row>
                        <b-col md="6">
                            <label>{{$t('RESERVATION')}}</label>
                            <app-select mode="search" :search="searchReservations" @input="mapReservationToInvoice"
                                        :options="reservationOptions" :disabled="!formData.partner.id">
                                <template v-slot:text="data">
                                    {{data.item && data.item.reservation_code}} {{data.item && data.item.guest.name &&
                                    `(${data.item.guest.name})`}}
                                </template>
                            </app-select>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <b-badge v-for="(reservation, index) in mappedReservationList" pill
                                     :class="index ? 'ml-1' : ''"
                                     @click="removeMappedReservation(reservation.id)">
                                {{reservation.reservation_code}} ({{reservation.guest.name}})
                            </b-badge>
                        </b-col>
                    </b-row>
                </template>

                <b-row>
                    <b-col class="d-flex justify-content-between align-items-baseline">
                        <form-header>{{$t('ITEMS')}}</form-header>

                        <app-button button_type="new" @click="addArticle">
                            {{ $t("NEW_ITEM") }}
                        </app-button>
                    </b-col>
                </b-row>

                <template v-for="(item, index) in formData.items">
                    <b-row>
                        <b-col class="mb-4 d-flex justify-content-between invoice-item-header">
                            <span>{{$t('ITEM')}} #{{index+1}}</span>
                            <app-button-delete v-if="index > 0" @click="removeItem(index)"></app-button-delete>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="6" xl="3" class="mb-4">
                            <label>{{$t('DESCRIPTION')}}</label>
                            <app-select v-model="item.articleId" @input="resetItem(index)" :options="articleList"
                                        :search-empty-item="false"></app-select>
                        </b-col>

                        <b-col md="6" xl="3" class="mb-4">
                            <label>{{$t('QUANTITY')}}</label>
                            <app-number-input v-model="item.quantity" @input="updateValues(item)"
                                              :append="item.unit_of_measurement" :min="-9999"
                                              align="right"></app-number-input>
                        </b-col>

                        <b-col md="6" xl="3" class="mb-4">
                            <label>{{$t('UNIT_PRICE')}}</label>
                            <app-number-input :value="item.unit_price"
                                              @input="setFromUnitPrice($event, item)"
                                              allow-decimal :min="0"
                                              :disabled="item.calculation_type !== CALC_TYPE_UNIT_TO_TOTAL"
                                              align="right"
                                              :append="company.main_currency.symbol"></app-number-input>
                        </b-col>

                        <b-col md="6" xl="3" class="mb-4">
                            <label>{{$t('GROSS_AMOUNT')}}</label>
                            <app-number-input :value="item.gross"
                                              @input="setFromGross($event, item)"
                                              allow-decimal :min="0"
                                              :disabled="item.calculation_type !== CALC_TYPE_TOTAL_TO_UNIT"
                                              align="right"
                                              :append="company.main_currency.symbol"></app-number-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="6" xl="3" class="mb-2">
                            <label>{{$t('DISCOUNT')}}</label>
                            <app-number-input v-model="item.discount" @input="updateValues(item)" :min="0"
                                              allow-decimal append="%" align="right"></app-number-input>
                        </b-col>

                        <b-col md="6" xl="3" class="mb-2">
                            <label>{{$t('TOTAL_DISCOUNT')}}</label>
                            <app-number-input :value="getTotalDiscount(item).toFixed(2)"
                                              :append="company.main_currency.symbol"
                                              allow-decimal align="right" :min="0"
                                              disabled>
                            </app-number-input>
                        </b-col>

                        <b-col md="6" xl="3" class="mb-2">
                            <label>{{$t('VAT_RATE')}}</label>
                            <app-select v-model="item.vatId" @input="updateValues(item)"
                                        :options="vatList"></app-select>
                        </b-col>

                        <b-col md="6" xl="3" class="mb-2">
                            <label>{{$t('TOTAL_VAT')}}</label>
                            <app-number-input :value="getTotalVAT(item).toFixed(2)" :min="0"
                                              :append="company.main_currency.symbol" align="right"
                                              allow-decimal
                                              disabled>
                            </app-number-input>
                        </b-col>
                    </b-row>
                    <hr>
                </template>

                <b-row>
                    <b-col md="3">
                        <label>{{$t('TOTAL')}}</label>
                        <app-number-input :value="invoiceTotal" :append="company.main_currency.symbol" disabled
                                          allow-decimal align="right"></app-number-input>
                    </b-col>

                    <b-col class="d-flex align-items-end justify-content-end">
                        <app-button-submit :loading="saving"></app-button-submit>
                    </b-col>
                </b-row>
            </template>
        </b-form>

        <app-aside v-model="sidebarState">
            <template slot="header"> {{ $t('EDIT_CLIENT') }}</template>
            <partner-form :partner-object="partnerObject"
                          :invoicee-type-id="invoiceeTypeId"
                          :creation-location="creationLocation"
                          :context-object="contextObject"
                          :available-partner-types="availablePartnerTypes"
                          :primary-partner-type="primaryPartnerType"
                          @saved="setPartner">
            </partner-form>
        </app-aside>
    </div>
</template>

<script>

    import {getArticleList, getPaymentType, getVatList} from "@/services/finance/setup";
    import AppDatePicker from "@/components/app/datetime/AppDatePicker";
    import FormHeader from "@/components/app/form/FormHeader";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import {fetchCompany} from "@/services/company";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import {cloneDeep, isObject} from "lodash";
    import AppAside from "@/components/app/form/AppAside"
    import PartnerForm from "@/components/finance/setup/partner/PartnerForm";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {getPartnerList} from "@/services/finance/partner";
    import {createInvoice} from "@/services/finance";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {IN_CLIENT, IN_DELIVERY_DATE, IN_DESCRIPTION, IN_DUE_DATE} from "@/shared/error_codes";
    import AppInputControl from "@/components/app/AppInputControl";
    import {CALC_TYPE_TOTAL_TO_UNIT, CALC_TYPE_UNIT_TO_TOTAL, ICL_INVOICE_LIST} from "@/shared/constants";
    import {ICL_RESERVATION_PREVIEW} from "@/shared/constants";
    import {getAccommodationReservationList} from "@/services/accommodation_reservation";
    import {fetchAccessControlData} from "@/services/access";
    import {AC_INVOICE_NEW} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {C_INVOICE_FIELDS_PAYMENT_TYPE} from "@/shared/component_permission";

    export default {
        name: "InvoiceForm",
        mixins: [getErrorMessage],
        components: {
            AppInputControl,
            AppButtonSubmit,
            PartnerForm,
            AppButtonDelete, AppNumberInput, AppSelect, AppButton, FormHeader, AppDatePicker, AppAside
        },
        props: {
            setupList: {
                type: Array,
                required: true
            },
            invoiceeTypeId: {
                type: Number,
            },
            creationLocation: {
                type: Number,
                required: true
            },
            contextObject: {
                type: Object,
                default: function () {
                    return {}
                },
            }
        },
        data() {
            return {
                company: {
                    main_currency: {}
                },
                typeSetup: [],
                articleList: [],
                vatList: [],
                invoiceTypeList: [],
                invoiceeTypeList: [],
                ccTypesList: [],
                mapReservations: false,
                mappingReservation: null,
                mappedReservationList: [],
                reservationOptions: [],
                initialFormData: null,
                invoiceType: null,
                numberStatic: null,
                availablePartnerTypes: [],
                primaryPartnerType: null,
                invoiceeType: null,
                formData: {
                    number: null,
                    invoice_type: null,
                    description: null,
                    delivery_date: null,
                    due_date: null,
                    cc_type: null,
                    cc_approval_number: null,
                    partner: {
                        id: null,
                        // name: null
                    },
                    items: [],
                    payment_type: null,
                },
                formItems: [],
                sidebarState: false,
                partnerObject: null,
                saving: false,
                validation: {
                    due_date: IN_DUE_DATE,
                    delivery_date: IN_DELIVERY_DATE,
                    description: IN_DESCRIPTION,
                    partner: IN_CLIENT,
                },
                CALC_TYPE_TOTAL_TO_UNIT,
                CALC_TYPE_UNIT_TO_TOTAL,
                acResponse: null,
                paymentTypeList: [],
            }
        },
        computed: {
            companyId() {
                return this.$store.getters['user/getCompany']
            },
            /*partnerSidebarHeader() {
                return this.partnerObjectId ? this.$t('EDIT_CLIENT') : this.$t('NEW_CLIENT')
            },*/
            invoiceTotal() {
                return this.formData.items.reduce((acc, curr) => {
                    return Number(acc) + Number(curr.gross) /*- Number(this.getTotalDiscount(curr))*/
                }, 0)
            },
            editPartnerVisible() {
                return this.creationLocation !== ICL_INVOICE_LIST
            }
        },
        watch: {
            invoiceType: {
                handler(value) {
                    this.formData = cloneDeep(this.initialFormData)
                    if (value) {
                        let index = this.typeSetup.findIndex(el => Number(el.invoice_type.id) === Number(value))
                        this.mapPartnerToFormData(this.typeSetup[index].partner)
                        this.mapSetupToFormData(this.typeSetup[index])
                    }
                }
            }
        },
        methods: {
            setFromUnitPrice(value, item) {
                if (item.calculation_type === CALC_TYPE_UNIT_TO_TOTAL) {
                    item.unit_price = value
                    const totalUnitPrice = Math.abs(value * item.quantity)
                    item.gross = totalUnitPrice - ((totalUnitPrice) * item.discount / 100)
                }
            },
            setFromGross(value, item) {
                if (item.calculation_type === CALC_TYPE_TOTAL_TO_UNIT) {
                    item.gross = value
                    const withoutVat = value - this.getTotalVAT(item)

                    if (!item.quantity) {
                        item.unit_price = null
                    } else {
                        item.unit_price = Math.abs(((withoutVat + (((withoutVat / (1 - (item.discount / 100))) - withoutVat))) / item.quantity))
                    }
                }
            },
            updateValues(item) {
                if (item.calculation_type === CALC_TYPE_TOTAL_TO_UNIT) {
                    this.setFromGross(item.gross, item)
                } else if (item.calculation_type === CALC_TYPE_UNIT_TO_TOTAL) {
                    this.setFromUnitPrice(item.unit_price, item)
                }
            },
            getTotalDiscount(item) {
                if (item.discount) {
                    let nett = Math.abs(item.unit_price * item.quantity)
                    return Number(nett * item.discount / 100)
                } else {
                    return 0
                }
            },
            getTotalVAT({gross = 0, vatId = null}) {
                if (vatId) {
                    let vatRate = this.vatList.find(el => el.id === Number(vatId)).amount
                    return Number(gross - (gross / (1 + (vatRate / 100))));
                } else {
                    return 0
                }
            },
            save() {
                this.saving = true
                this.formData.partner.company = this.companyId
                this.formData.partner.invoicee_type = this.invoiceeTypeId

                if (isObject(this.formData.partner.partner_type)) {
                    this.formData.partner.partner_type = this.formData.partner.partner_type.id
                }

                if (this.formData.hasOwnProperty('number') && !this.formData.number) {
                    delete this.formData.number
                }

                const objectIds = this.getObjectIds()

                createInvoice(this.creationLocation, objectIds, this.formData).then(() => {
                    notifySuccess()
                    this.$emit('created')
                }).catch(error => {
                    this.showErrorMessages(error, this.validation)
                }).finally(() => this.saving = false)
            },
            getObjectIds() {
                switch (this.creationLocation) {
                    case ICL_RESERVATION_PREVIEW:
                        return this.contextObject.id
                    case ICL_INVOICE_LIST:
                        return this.mappedReservationList.map(el => el.id)
                }
            },
            addArticle() {
                this.formData.items.push({
                    articleId: null,
                    quantity: 1,
                    gross: 0,
                    discount: 0,
                    vatId: null,
                    unit_of_measurement: 'kom',
                    unit_price: 0
                })
            },
            removeItem(index) {
                this.formData.items.splice(index, 1)
            },
            resetItem(index) {
                const existingItemSetup = this.formItems.find(el => el.articleId === this.formData.items[index].articleId)
                if (existingItemSetup) {
                    this.$set(this.formData.items, index, cloneDeep(existingItemSetup))
                } else {
                    this.formData.items[index].calculation_type = this.articleList.find(el => el.id === this.formData.items[index].articleId).calculation_type.key
                    this.formData.items[index].gross = 0
                    this.formData.items[index].discount = 0
                    this.formData.items[index].vatId = null
                    this.formData.items[index].quantity = 1
                    this.formData.items[index].unit_price = 0
                }
            },
            mapSetupToFormData(invoiceTypeSetup) {
                this.formData.number = invoiceTypeSetup.number || null
                this.numberStatic = invoiceTypeSetup.number_static
                this.formData.invoice_type = invoiceTypeSetup.invoice_type.id
                this.formData.description = invoiceTypeSetup.description
                this.formData.delivery_date = invoiceTypeSetup.delivery_date
                this.formData.due_date = invoiceTypeSetup.due_date

                this.availablePartnerTypes = invoiceTypeSetup.available_partner_type
                this.primaryPartnerType = invoiceTypeSetup.primary_partner_type

                if (invoiceTypeSetup.cc_data) {
                    this.ccTypesList = invoiceTypeSetup.cc_data
                } else {
                    this.ccTypesList = []
                }

                if (invoiceTypeSetup.map_to_reservation) {
                    this.mapReservations = true
                    this.mappedReservationList = []
                }

                this.formItems = []
                if (invoiceTypeSetup.items && invoiceTypeSetup.items.length) {
                    invoiceTypeSetup.items.forEach(item => {
                        let formItem = {
                            articleId: item.article,
                            quantity: item.quantity,
                            gross: item.gross,
                            unit_price: (item.gross / item.quantity).toFixed(2),
                            discount: 0,
                            vatId: item.vat,
                            unit_of_measurement: item.unit_of_measurement,
                            calculation_type: this.articleList.find(el => el.id === item.article).calculation_type.key
                        }

                        if (item.auto_generate > 0) {
                            this.formData.items.push({...formItem})
                        }

                        this.formItems.push(formItem)
                    })
                }
            },
            mapPartnerToFormData(partner) {
                this.formData.partner = partner
            },
            searchPartner(value) {
                return getPartnerList({
                    search: value,
                    company: this.companyId,
                    invoicee_type: this.invoiceeType,
                    results: 20
                })
            },
            openPartnerForm(partner) {
                this.partnerObject = partner
                // this.partnerObject.company = this.companyId
                this.sidebarState = true
            },
            setPartner(partner) {
                this.formData.partner = partner
                this.sidebarState = false
            },
            setInvoiceTypeList(value) {
                this.typeSetup = this.setupList.find(setup => setup.invoicee_type.id === value).invoice_setup
                this.invoiceTypeList = this.typeSetup.map(typeSetup => typeSetup.invoice_type)
                this.invoiceType = this.invoiceTypeList[0].id
            },
            searchReservations(value) {
                return getAccommodationReservationList({
                    perPage: 30,
                    reservation_code: value,
                    not_reservation: this.mappedReservationList.map(res => res.id),
                    // distribution: this.formData.partner.entity
                }).then(response => ({data: response.data.items}))
            },
            mapReservationToInvoice(reservation) {
                this.mappedReservationList.push(reservation)
            },
            removeMappedReservation(reservationId) {
                this.mappedReservationList.splice(this.mappedReservationList.findIndex(el => el.id === reservationId), 1)
            },
            generateACRequest() {
                return {
                    key: AC_INVOICE_NEW,
                    context: {company: this.companyId},
                    data: [
                        {uid: `payment_type_${this._uid}`, function: C_INVOICE_FIELDS_PAYMENT_TYPE},
                    ]
                }
            },
            isVisible(field) {
                const f = `${field}_${this._uid}`
                return this.acResponse && this.acResponse[f] && this.acResponse[f].visible
            },
            isDisabled(field) {
                const f = `${field}_${this._uid}`
                return this.acResponse && this.acResponse[f] && this.acResponse[f].disabled
            }
        },
        created() {
            Promise.all([
                fetchAccessControlData(AC_INVOICE_NEW, this.generateACRequest()),
                getVatList({company: this.companyId, limit: 999}),
                getArticleList({company: this.companyId, limit: 999}),
                fetchCompany(this.companyId),
                getPaymentType({company: this.companyId})
            ]).then(([acResponse, vatResponse, articleResponse, companyResponse, paymentTypeResponse]) => {
                // [this.vatList, this.articleList] = responses.map(r => r.data.items);
                this.acResponse = acResponse.data
                this.paymentTypeList = paymentTypeResponse.data

                this.vatList = vatResponse.data.items.map(i => {
                    return {
                        id: i.id,
                        name: `${i.label} ( ${i.amount}% )`,
                        amount: i.amount
                    }
                })
                this.articleList = articleResponse.data.items
                this.company = companyResponse.data
                this.initialFormData = cloneDeep(this.formData)
                this.invoiceeTypeList = this.setupList.map(setup => setup.invoicee_type)
                this.invoiceeType = this.invoiceeTypeId

                if (this.invoiceeType) {
                    this.typeSetup = this.setupList.find(setup => setup.invoicee_type.id === this.invoiceeType).invoice_setup
                    this.invoiceTypeList = this.typeSetup.map(typeSetup => typeSetup.invoice_type)
                    this.invoiceType = this.invoiceTypeList[0].id
                }
            }).catch(error => {
                this.showErrorMessages(error)
                this.$emit('closed')
            })
        }
    }
</script>

<style scoped>
    .invoice-item-header {
        /*margin: 0 0 15px;*/
        margin: 0 12px;
        font-weight: bold;
    }

    .badge {
        cursor: pointer
    }

    .badge:hover {
        background-color: rgba(217, 23, 23, 0.5)
    }
</style>
