<template>
    <div>
        <b-row>
            <b-col class="mb-4 d-flex justify-content-end align-items-baseline">
                <app-button v-for="(setupObject, index) in setupList" :key="index"
                            :class="(index > 0) ? 'ml-3' : ''"
                            :disabled="loading"
                            button_type="new"
                            variant="primary"
                            @click="toggleInvoiceForm(setupObject)">
                    {{getInvoiceLabel(setupObject.invoicee_type.id)}}
                </app-button>
            </b-col>
        </b-row>

        <invoice-list v-if="checkPermission(C_RESERVATION_ACCOMMODATION_INVOICES_ADMIN_V)" :search-criteria="searchCriteria"></invoice-list>
        <owner-invoice-list v-if="checkPermission(C_RESERVATION_ACCOMMODATION_INVOICES_OWNER_V)" :search-criteria="searchCriteria"></owner-invoice-list>

        <app-aside v-model="asidePanelState" :header-classes="[]">
            <template slot="header">{{$t("CREATE_INVOICE")}}</template>
            <invoice-form :setup="setup"
                          :invoicee-type-id="invoiceeTypeId"
                          :creation-location="ICL_RESERVATION_PREVIEW" :context-object="reservation"
                          :object-id="reservation.id" @created="reloadData" @closed="()=>asidePanelState=false">
            </invoice-form>
        </app-aside>
    </div>
</template>

<script>
    import InvoiceList from "@/components/finance/invoices/InvoiceList";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppAside from "@/components/app/form/AppAside"
    import InvoiceForm from "@/components/reservation/accommodation/forms/InvoiceForm";
    import {getManualInvoiceSetupList} from "@/services/finance/setup";
    import {
        ICL_RESERVATION_PREVIEW,
        INVOICEE_TYPE_BT_OWNER,
        INVOICEE_TYPE_GUEST,
        INVOICEE_TYPE_OWNER
    } from "@/shared/constants";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {EventBus} from "@/shared/EventBus";
    import {WORKFLOW_BOOKER_TOOLS} from "@/shared/constants";
    import OwnerInvoiceList from "@/components/finance/invoices/OwnerInvoiceList";
    import {
        C_RESERVATION_ACCOMMODATION_INVOICES_ADMIN_V,
        C_RESERVATION_ACCOMMODATION_INVOICES_OWNER_V
    } from "@/shared/component_permission";


    export default {
        name: "Index",
        components: {OwnerInvoiceList, InvoiceForm, AppButton, InvoiceList, AppAside},
        mixins: [getErrorMessage],
        props: {
            reservation: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                ICL_RESERVATION_PREVIEW,
                searchCriteria: {
                    reservation: this.reservation.id,
                },
                asidePanelState: false,
                setupList: [],
                setup: null,
                invoiceeTypeId: null,
                loading: false,
                WORKFLOW_BOOKER_TOOLS,
                C_RESERVATION_ACCOMMODATION_INVOICES_OWNER_V,
                C_RESERVATION_ACCOMMODATION_INVOICES_ADMIN_V
            }
        },
        methods: {
            toggleInvoiceForm(setupObject) {
                this.setup = setupObject.invoice_setup
                this.invoiceeTypeId = setupObject.invoicee_type.id
                this.asidePanelState = true
            },
            reloadData() {
                this.asidePanelState = false
                this.fetchSetup()
                EventBus.$emit('invoiceSearch', this.searchCriteria)
            },
            fetchSetup() {
                this.loading = true
                getManualInvoiceSetupList({creation_location: ICL_RESERVATION_PREVIEW, reservation: this.reservation.id}).then(response => {
                    this.setupList = response.data
                }).catch(error => {
                    // this.showErrorMessages(error)
                }).finally(() => this.loading = false)
            },
            getInvoiceLabel(id) {
                switch (id) {
                    case INVOICEE_TYPE_GUEST:
                        return this.$t('INVOICE_GUEST')
                    case INVOICEE_TYPE_OWNER:
                        return this.$t('INVOICE_OWNER')
                    case INVOICEE_TYPE_BT_OWNER:
                        return this.$t('INVOICE_BT_OWNER')
                    default:
                        return this.$t('INVOICE')
                }
            }
        },
        created() {
            this.fetchSetup()
        }
    }
</script>

<style scoped>

</style>
