<template>
    <form @submit.prevent="search" class="filter" autocomplete="off">
        <b-row>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t('RESERVATION_CODE') }}</label>
                <b-input v-model="filter.reservation_code" type="text"></b-input>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t('GUEST') }}</label>
                <app-select mode="search"
                            :search="searchUserGuest"
                            v-model="guest">
                    <template v-slot:text="data">
                        {{ data.item.first_name }} {{ data.item.last_name }}
                    </template>
                </app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3"
                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FILTER_ACCOUNT_MANAGER)">
                <label>{{ $t('ACCOUNT_MANAGER') }}</label>
                <app-select mode="multiselect"
                            :options="acmList"
                            v-model="filter.account_manager">
                </app-select>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t('PROPERTY') }}</label>
                <app-select v-if="preloadPropertyList" mode="multiselect" :options="propertyList" set-first
                            v-model="property" :disabled="!!owner.id"></app-select>
                <app-select v-else mode="search" :disabled="!!owner.id" :search="searchProperty" set-first
                            v-model="property"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3"
                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FILTER_OWNER)">
                <label>{{ $t("OWNER") }}</label>
                <app-select mode="search" :search="searchOwner" v-model="owner"
                            :disabled="!!((preloadPropertyList && property.length) || (!preloadPropertyList && property.id))"
                            text-field="full_name"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t('UNIT') }}</label>
                <app-select
                    :disabled="(preloadPropertyList && !groupedUnitList.length && !owner.id) || (!preloadPropertyList && !property.id && !owner.id) "
                    :options="preloadPropertyList && !owner.id ? groupedUnitList : unitList"
                    :group="preloadPropertyList && !owner.id ? 'units' : ''"
                    v-model="filter.unit" set-first :search-empty-item="false"
                    :mode="unitList.length === 1 && !preloadPropertyList ? 'select' : 'multiselect'">
                </app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6">
                <label>{{ $t("_DATE") }}</label>
                <app-select :options="dateOptions" v-model="dateOptionValue"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("FROM") }}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="fromDate" :max-date="toDate"></app-date-picker>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("TO") }}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="toDate" :min-date="fromDate"
                                 :from-date="fromDate"></app-date-picker>
            </b-col>

            <app-access-control xl="2" lg="3" md="6" class="mb-4"
                                @AccessControlEvent="addToAccessControlCounter()"
                :access_control_context="{key: access_control_fetch_key, function: C_RESERVATION_ACCOMMODATION_DISTRIBUTION}">

                    <label>{{ $t('DISTRIBUTION') }}</label>
                    <app-select v-model="filter.distribution"
                                :options="distributionOptions"
                                mode="multiselect">
                    </app-select>
            </app-access-control>

            <app-access-control xl="2" lg="4" md="6" class="mb-3"
                                @AccessControlEvent="addToAccessControlCounter()"
                :access_control_context="{key: access_control_fetch_key, function: C_RESERVATION_ACCOMMODATION_BOOKING_SITE}">

                    <label>{{ $t('SALES_CHANNEL') }}</label>
                    <app-select v-model="filter.channel"
                                :options="channelList"
                                mode="multiselect">
                    </app-select>
            </app-access-control>

<!--            <b-col xl="2" lg="4" md="6" class="mb-3" v-if="isDBWorkflow">-->
<!--                <label>{{ $t("CHARGE_PROFILE") }}</label>-->
<!--                <app-select v-model="filter.charge_profile"-->
<!--                            :options="chargeProfileList"-->
<!--                            text-field="name" search-input-->
<!--                            mode="multiselect">-->
<!--                </app-select>-->
<!--            </b-col>-->

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("PAYMENT_STATUS") }}</label>
                <app-select v-model="filter.payment_status"
                            :options="arFinanceStatusList"
                            text-field="label"
                            mode="multiselect">
                </app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("STATUS") }}</label>
                <app-select v-model="filter.status"
                            :options="arStatusList"
                            text-field="label"
                            mode="multiselect">
                </app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3"
                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FILTER_COST_CENTER) && isDBWorkflow">
                <label>{{ $t("COST_CENTER") }}</label>
                <b-input v-model="filter.cost_center"></b-input>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3"
                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FILTER_SELF_ISSUE) && isDBWorkflow">
                <label>{{ $t("SELF_ISSUE") }}</label>
                <app-select :options="yesNoOptions" v-model="filter.self_issue"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3"
                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FILTER_ADVANCE) && isDBWorkflow">
                <label>{{ $t("ADVANCE") }}</label>
                <app-select :options="yesNoOptions" v-model="filter.is_advance"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3"
                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FILTER_INVOICE) && isDBWorkflow">
                <label>Owner/guest invoice</label>
                <app-select mode="multiselect" :options="containInvoiceOptions" text-field="label"
                            v-model="contain_invoice"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3"
                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FILTER_TAG)">
                <label>{{ $t("TAG") }}</label>
                <app-select mode="multiselect" :options="tagOptions" text-field="label"
                            v-model="filter.tag_id"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3"
                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FILTER_USER)" >
                <label>{{ $t("USER") }}</label>
                <app-select v-if="showUserMultiselect"
                            mode="multiselect"
                            :options="userList"
                            v-model="user">
                    <template v-slot:text="data">
                        {{ data.item.first_name }} {{ data.item.last_name }}
                    </template>
                </app-select>
                <app-select v-else mode="search"
                            :search="searchUser"
                            v-model="user">
                    <template v-slot:text="data">
                        {{ data.item.first_name }} {{ data.item.last_name }}
                    </template>
                </app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3"
                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FILTER_TAG)">
                <label>{{ $t("CHARGE_PERSON_TYPE") }}</label>
                <app-select mode="multiselect" :options="chargerPersonTypeOptions" text-field="label"
                            v-model="filter.charge_person"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3"
                   v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FILTER_TAG)">
                <label>{{ $t("PAYMENT_TYPE") }}</label>
                <app-select mode="multiselect" :options="paymentTypeOptions" text-field="label"
                            v-model="filter.payment_type"></app-select>
            </b-col>


            <b-col xl="4" lg="4" md="6" class="mb-3">
                <app-submit class="filter-submit-wrapper" :inline="true" :disabled="loading" :loading="loaderSubmit"
                            variant="primary"
                            button_type="search">{{ $t("SEARCH") }}
                </app-submit>

                <app-button-reset @click="resetForm" :disabled="loading" :loading="loaderReset" :inline="true"
                                  class="pl-2 ml-2"></app-button-reset>
            </b-col>
        </b-row>
    </form>
</template>

<script>
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit"
    import AppDatePicker from "@/components/app/datetime/AppDatePicker";
    import AppSelect from "@/components/app/AppSelect/AppSelect"
    import {cloneDeep} from "lodash";
    import {getStatusList} from "@/services/status";
    import {
        DEFAULT_PAGINATION_LIMIT,
        LS_PRESETS,
        OBJECT_TYPE_ACCOMMODATION_RESERVATION,
        OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE, TAG_TYPE_RESERVATION, WORKFLOW_DIRECT_BOOKER
    } from "@/shared/constants";
    import {getPropertyList} from "@/services/property";
    import {getUnitList} from "@/services/unit";
    import {getChannelList, getDistributionList} from "@/services/distribution";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
    import {listHelpers} from "@/mixins/shared/helpers";
    import {
        C_RESERVATION_ACCOMMODATION_FILTER_SELF_ISSUE,
        C_RESERVATION_ACCOMMODATION_FILTER_OWNER,
        C_RESERVATION_ACCOMMODATION_FILTER_INVOICE,
        C_RESERVATION_ACCOMMODATION_FILTER_ADVANCE,
        C_RESERVATION_ACCOMMODATION_FILTER_COST_CENTER, C_RESERVATION_ACCOMMODATION_FILTER_ACCOUNT_MANAGER,
        C_RESERVATION_ACCOMMODATION_DISTRIBUTION, C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
        C_RESERVATION_ACCOMMODATION_FILTER_USER, C_RESERVATION_ACCOMMODATION_FILTER_TAG
    } from '@/shared/component_permission'
    import {getOwner, getOwnerList} from "@/services/owner";
    import {getList as getAccountManagerList} from "@/services/acc_manager";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_ACCOMMODATION_RESERVATION_FILTER} from "@/mixins/AccessControl/AccessControlEnumeration";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {getUserList} from "@/services/user";
    import {getAllTags} from "@/services/tag";
    import {getFinancePaymentType} from "@/services/finance";
    import {getChargePersonType} from "@/services/system";

    function formatHasInvoiceList(list) {
        return list.reduce((acc, val) => {
            acc[val] = 0
            return acc
        }, {})
    }


    export default {
        name: "AccommodationReservationFilter",
        components: {AppAccessControl, AppButtonReset, AppSubmit, AppDatePicker, AppSelect},
        mixins: [AccessControlComponent, listHelpers],
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                propertyList: [],
                acmList: [],
                property: {id: null, name: null},
                owner: {id: null, full_name: null},
                guest: {id: null, full_name: null},
                user: {id: null, full_name: null},
                userList: null,
                filter: {
                    property: null,
                    account_manager: [],
                    status: [],
                    payment_status: [],
                    unit: [],
                    distribution: [],
                    channel: [],
                    calculation_item: null,
                    reservation: null,
                    self_issue: null,
                    cost_center: null,
                    owner: null,
                    is_advance: null,
                    invoice: null,
                    company: this.$store.getters['user/getCurrentCompany'].id,
                    guest_invoice_from: this.$route.query.guest_invoice_from ? this.$route.query.guest_invoice_from : null,
                    guest_user_id: null,
                    insert_user: null,
                    tag_id: null,
                    payment_type: [],
                    charge_person: []
                },
                orderByOption: null,
                sortDescOption: null,
                contain_invoice: [],
                initialFilter: null,
                arStatusList: [],
                arFinanceStatusList: [],
                unitList: [],
                preloadedUnitList: [],
                distributionOptions: [],
                channelList: [],
                tagOptions: [],
                dateOptions: [
                    {id: 'check_in', name: this.$t('CHECK_IN')},
                    {id: 'check_out', name: this.$t('CHECK_OUT')},
                    {id: 'insert', name: this.$t('BOOKED_AT')},
                    {id: 'status_update', name: this.$t('LAST_STATUS_CHANGE')},
                    {id: 'prepayment', name: this.$t('PREPAYMENT')},
                ],
                dateOptionValue: null,
                fromDate: null,
                toDate: null,
                preloadPropertyList: false,
                reset: 0,
                loaderReset: false,
                loaderSubmit: false,
                showUserMultiselect: false,
                C_RESERVATION_ACCOMMODATION_FILTER_SELF_ISSUE,
                C_RESERVATION_ACCOMMODATION_FILTER_OWNER,
                C_RESERVATION_ACCOMMODATION_FILTER_INVOICE,
                C_RESERVATION_ACCOMMODATION_FILTER_ADVANCE,
                C_RESERVATION_ACCOMMODATION_FILTER_COST_CENTER,
                C_RESERVATION_ACCOMMODATION_FILTER_ACCOUNT_MANAGER,
                C_RESERVATION_ACCOMMODATION_DISTRIBUTION,
                C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
                C_RESERVATION_ACCOMMODATION_FILTER_USER,
                C_RESERVATION_ACCOMMODATION_FILTER_TAG,
                access_control_components: 2,
                access_control_fetch_key: AC_ACCOMMODATION_RESERVATION_FILTER,
                paymentTypeOptions: [],
                chargerPersonTypeOptions: [],
                filterFieldsDefaultValue: {
                    property: null,
                    account_manager: [],
                    status: [],
                    payment_status: [],
                    unit: [],
                    distribution: [],
                    channel: [],
                    calculation_item: null,
                    reservation: null,
                    self_issue: null,
                    cost_center: null,
                    owner: null,
                    is_advance: null,
                    invoice: null,
                    company: this.$store.getters['user/getCurrentCompany'].id,
                    guest_invoice_from: null,
                    guest_user_id: null,
                    insert_user: null,
                    tag_id: null,
                    payment_type: [],
                    charge_person: [],
                },
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            isDBWorkflow() {
                return this.company.workflow_id === WORKFLOW_DIRECT_BOOKER
            },
            groupedUnitList() {
                if (Array.isArray(this.filter.property)) {
                    const selectedPropertyUnits = this.unitList.filter(unit => this.filter.property.includes(unit.property.id))
                    return this.filter.property.map(property => {
                        return {
                            name: this.propertyList.find(p => Number(p.id) === Number(property)).name,
                            units: selectedPropertyUnits.filter(unit => Number(property) === Number(unit.property.id))
                        }
                    })
                } else {
                    return []
                }
            },
            containInvoiceOptions() {
                return [
                    {id: 'has_invoice_owner', label: this.$t('NO_OWNER_INVOICE')},
                    {id: 'has_invoice_guest', label: this.$t('NO_GUEST_INVOICE')},
                ]
            },
            dateObject() {
                return this.dateOptionValue ? {
                    [`${this.dateOptionValue}_from_date`]: this.fromDate,
                    [`${this.dateOptionValue}_to_date`]: this.toDate,
                } : {}
            }
        },
        methods: {
            search() {
                this.reset = 0
                this.$emit('search', {filter:{...this.filter, ...this.dateObject, ...formatHasInvoiceList(this.contain_invoice)},
                    sort:{ orderByOption: this.orderByOption, sortDescOption: this.sortDescOption}})
            },
            resetForm() {
                this.reset = 1
                if (Object.keys(this.$route.query).length > 0) {
                    this.$router.replace({query: {}})
                }
                this.filter = cloneDeep(this.initialFilter)
                this.filter.reservation = this.filter.calculation_item = this.filter.invoice = null
                this.property = this.preloadPropertyList ? [] : {id: null, name: null}
                this.owner = {id: null, full_name: null}
                this.filter.owner = null
                this.filter.guest_user_id = null
                this.filter.status = this.contain_invoice = []
                this.user = {id: null, name: null}
                this.dateOptionValue = null
                this.orderByOption = null
                this.sortDescOption = null
                this.$emit('search', {
                    filter: {...this.filter, ...this.dateObject},
                    sort: {orderByOption: this.orderByOption, sortDescOption: this.sortDescOption}
                })
            },
            searchProperty(value) {
                return getPropertyList({
                    name: value,
                    company_id: this.company.id,
                    account_manager: this.filter.account_manager.length ? this.filter.account_manager : null
                }).then(response => {
                    return {data: response.data.items}
                })
            },
            searchOwner(value) {
                return getOwnerList({name: value, company: this.company.id, limit: 20})
            },
            searchUserGuest(value){
                return getUserList({name: value, company: this.company.id,is_guest:true}).then(response => {
                    return {data: response.data.items}
                })
            },
            searchUser(value){
                return getUserList({name: value, company: this.company.id}).then(response => {
                    return {data: response.data.items}
                })
            },
            unsetInitialFilterQueryParameterValues(parameters) {
                const parameterKeys = Object.keys(parameters)
                if (parameterKeys.length > 0) {
                    parameterKeys.forEach((parameterKey) => {
                        if (this.initialFilter.hasOwnProperty(parameterKey)) {
                            if (this.filterFieldsDefaultValue.hasOwnProperty(parameterKey)) {
                                this.$set(this.initialFilter, parameterKey, this.filterFieldsDefaultValue[parameterKey])
                            } else {
                                this.$delete(this.initialFilter, parameterKey)
                            }
                        }
                    })
                }
            },
        },
        watch: {
            property: {
                handler(data) {
                    if (this.preloadPropertyList) {
                        this.filter.property = data
                        this.selectedUnits = this.unitList.filter(unit => this.filter.unit.includes(unit.id))
                        this.filter.unit = this.selectedUnits.filter(unit => this.filter.property.includes(unit.property.id)).map(unit => unit.id)
                    } else {
                        if (data.id) {
                            this.filter.property = data.id
                            getUnitList({property_id: data.id, limit: 1000}).then(response => {
                                this.unitList = response.data.items
                            })
                        } else {
                            this.filter.property = null
                            this.filter.unit = []
                            this.unitList = this.preloadPropertyList ? this.preloadedUnitList : []
                        }
                    }
                },
            },
            owner: {
                handler(value) {
                    if (value && value.id) {
                        this.filter.owner = value.id
                        getUnitList({owner: value.id}).then(response => {
                            this.unitList = response.data.items
                        })
                    } else {
                        this.filter.owner = []
                        this.filter.unit = []
                        this.unitList = this.preloadPropertyList ? this.preloadedUnitList : []
                    }
                }
            },
            guest: {
                handler(value){
                    if(value && value.id){
                        this.filter.guest_user_id = value.id
                    }
                }
            },
            user: {
                handler(value){
                    if(value){
                        if (Array.isArray(value)){
                            this.filter.insert_user = value
                        } else {
                            this.filter.insert_user = value.id
                        }
                    } else {
                        this.filter.insert_user = null
                    }
                }
            },
            dateOptionValue(value, oldValue) {
                if (oldValue !== null) {
                    this.fromDate = null
                    this.toDate = null
                    this.filter.order_by_direction = null
                    this.filter.order_by = null
                }

                if (value) {
                    if (value === 'check_in') {
                        this.sortDescOption = true
                        this.orderByOption = 'check_in_from_date'
                    }
                    if (value === 'check_out') {
                        this.sortDescOption = true
                        this.orderByOption = 'check_out_from_date'
                    }
                    if (value === 'insert') {
                        this.sortDescOption = true
                        this.orderByOption = 'created_date'
                    }
                    if (value === 'status_update') {
                        this.sortDescOption = true
                        this.orderByOption = 'status_updated_date'
                    }
                    if (value === 'prepayment') {
                        this.sortDescOption = true
                        this.orderByOption = 'prepayment_date'
                    }
                }
            },
            '$route.query': {
                handler(queryParams) {
                    const queryParamKeys = Object.keys(queryParams)
                    // if filter contains provided query parameters perform a search with them
                    if (queryParamKeys.length > 0) {
                        for (const [key, value] of Object.entries(queryParams)) {
                            if (Object.keys(this.filter).includes(key)) {
                                if (Array.isArray(this.filter[key])) {
                                    this.$set(this.filter, key, this.filter[key].concat(value))
                                } else {
                                    this.filter[key] = value
                                }
                            }
                        }

                        if (queryParamKeys.includes('date')) {
                            this.dateOptionValue = queryParams.date
                            this.fromDate = queryParams.from
                            this.toDate = queryParams.to
                        }

                        if (queryParamKeys.includes('owner')) {
                            this.owner = JSON.parse(queryParams.owner)
                            this.filter.owner = this.owner.id
                        }

                        if (queryParamKeys.includes('has_invoice_owner')) {
                            this.contain_invoice.push('has_invoice_owner')
                        }

                        if (queryParamKeys.includes('has_invoice_guest')) {
                            this.contain_invoice.push('has_invoice_guest')
                        }

                        if (queryParamKeys.includes('invoice_status')) {
                            this.filter['invoice_status'] = queryParams.invoice_status
                        }

                        if (queryParamKeys.includes('reservation_code')) {
                            this.filter['reservation_code'] = queryParams.reservation_code
                            this.filter['reservation'] = null
                        }

                        this.search()
                    }
                },
                immediate: true
            },
            loading: {
                handler(value) {
                    if (this.reset) {
                        this.loaderReset = value
                    } else {
                        this.loaderSubmit = value
                    }
                }
            }
        },
        created() {
            this.initialFilter = cloneDeep(this.filter)
            if (this.$route.query) {
                this.unsetInitialFilterQueryParameterValues(this.$route.query)
            }
            getAccountManagerList({company_id: this.company.id}).then(response => {
                this.acmList = response.data
            })

            getStatusList([OBJECT_TYPE_ACCOMMODATION_RESERVATION, OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE]).then(res => {
                this.arStatusList = res.data.filter(status => status.object_type.id === OBJECT_TYPE_ACCOMMODATION_RESERVATION)
                this.arFinanceStatusList = res.data.filter(status => status.object_type.id === OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE)
            })
            getDistributionList({company: this.company.id, for_reservation: 1, sorted: 1}).then(res => {
                this.distributionOptions = res.data
            })

            getChannelList({with_icons: 0}).then(response => {
                this.channelList = response.data
            })

            getAllTags(TAG_TYPE_RESERVATION,{company: this.company.id}).then(response => {
                this.tagOptions = response.data
            })

            let storagePresets = localStorage.getItem(LS_PRESETS)
            if (storagePresets) {
                storagePresets = JSON.parse(storagePresets)
                if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                    this.property = []
                    this.preloadPropertyList = true
                    this.searchProperty().then(response => {
                        this.propertyList = response.data
                        return response.data.map(property => property.id)
                    }).then(propertyIds => {
                        return getUnitList({property_id: propertyIds, limit: 1000})
                    }).then(response => {
                        this.unitList = response.data.items
                        this.preloadedUnitList = cloneDeep(this.unitList)
                    })
                }

                if (storagePresets.hasOwnProperty('users') && storagePresets.users <= DEFAULT_PAGINATION_LIMIT) {
                    getUserList({company: this.company.id}).then(response => {
                        this.userList = response.data.items
                    })
                    this.showUserMultiselect = true
                    this.user = []
                }
            }

            this.access_control_general_context = {
                company: this.$store.getters['user/getCompany']
            }

            getFinancePaymentType({not_cash_register:true}).then(res => this.paymentTypeOptions = res.data)
            getChargePersonType().then(res => this.chargerPersonTypeOptions = res.data)
        }
    }
</script>

<style scoped>

</style>
