<template>
    <b-form v-if="checkPermission(C_PROPERTY_USER_ACCESS_V) && userAccessUnits.length" @submit.prevent="save">
        <b-row>
            <b-col lg="4" md="6" class="col-xxl-3" :key="userIndex" v-for="(user, userIndex) in userAccessUnits">
                <b-card no-body>
                    <repeater-header>
                        {{user.first_name}} {{user.last_name}}
                    </repeater-header>
                    <div class="pl-3 mb-3 border_not_last d-flex">
                        <div class="text-lowercase mr-2">{{$t("ROLES")}}:</div><span :key="index" v-for="(role, index) in user.role">{{ role.name }}</span>
                    </div>
                    <b-list-group class="repeater-2">
                        <b-list-group-item class="list-group-item-title font-weight-bold">
                            <form-header header-class="d-flex justify-content-between mb-0">
                                <span>{{user.username}}</span>
                                <b-form-checkbox :checked="allChecked(userIndex)"
                                                 :unchecked-value="0"
                                                 :value="1"
                                                 inline
                                                 class="m-0 p-0"
                                                 @change="selectAll(userIndex, $event)">
                                </b-form-checkbox>
                            </form-header>
                        </b-list-group-item>
                        <b-list-group-item v-for="(unit, unitIndex) in user.units" :key="unitIndex">
                            <b-form-checkbox @change="addChangeLogByIndex(userIndex, unitIndex, $event)"
                                             :id="'user_' + user.id + '_unit_' + unit.id"
                                             :name="'user_' + user.id + '_unit_' + unit.id"
                                             v-model="unit.value"
                                             :unchecked-value="0"
                                             :value="1">
                                {{unit.name}}
                            </b-form-checkbox>
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-4">
                <submit-button class="mt-3" :loading="saving" :c_permission="C_PROPERTY_USER_ACCESS_E"></submit-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import FormHeader from "@/components/app/form/FormHeader";
    import SubmitButton from "@/components/app/AppButton/AppButtonSubmit";
    import {getUserAccessUnits, updateUserAccessUnits} from "@/services/property";
    import {toast} from "@/shared/plugins/toastr";
    import {
        C_PROPERTY_USER_ACCESS_V,
        C_PROPERTY_USER_ACCESS_E
    } from "@/shared/component_permission";
    import RepeaterHeader from "@/components/app/form/RepeaterHeader";

    export default {
        name: "UserAccessList",
        components: {
            RepeaterHeader,
            FormHeader,
            SubmitButton
        },
        data() {
            return {
                userAccessUnits: [],
                editList: [],
                saving: false,
                C_PROPERTY_USER_ACCESS_V,
                C_PROPERTY_USER_ACCESS_E
            }
        },
        props: {
            userData: {
                type: Array,
                default: null
            }
        },
        watch: {
            '$route.params.id': {
                handler(id) {
                    this.getData(id);
                },
                immediate: true
            },
            userData: {
                handler(value) {
                    if (value) {
                        this.getData(this.$route.params.id)
                    }
                }
            }
        },
        methods: {
            save() {
                if (this.editList.length === 0) {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NO_CHANGE'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }
                this.saving = true
                updateUserAccessUnits(this.$route.params.id, {userUnits: JSON.stringify(this.editList)}).then((res) => {
                    this.userAccessUnits = res.data
                    this.editList = []
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }, error => {
                    this.showErrorMessages(error);
                }).finally(() => {
                    this.saving = false
                })
            },
            addChangeLogByIndex(userIndex, unitIndex, event) {

                let deleted = event === 1 ? 0 : 1
                let unitId = this.userAccessUnits[userIndex].units[unitIndex].id
                let userId = this.userAccessUnits[userIndex].id

                // find index if there is already the same element in array and remove it
                let index = this.editList.findIndex(element => element.user_id === userId && element.unit_id === unitId)
                if (index > -1) {
                    this.editList.splice(index, 1)
                } else {
                    // adding to update
                    this.editList.push({user_id: userId, unit_id: unitId, deleted: deleted})
                }
            },
            selectAll(userIndex, event) {
                let forLength = this.userAccessUnits[userIndex].units.length
                for (let i = 0; i < forLength; i++) {
                    if (this.userAccessUnits[userIndex].units[i].value !== event) {
                        this.addChangeLogByIndex(userIndex, i, event)
                        this.userAccessUnits[userIndex].units[i].value = event
                    }
                }
            },
            allChecked(userIndex) {
                return this.userAccessUnits[userIndex].units.findIndex(element => element.value === 0) < 0 ? 1 : 0
            },
            getData(id) {
                getUserAccessUnits(id).then(res => {
                    this.userAccessUnits = res.data
                });
            }
        }
    }
</script>

<style scoped>
    .border_not_last span:not(:last-child) {
        border-right: 1px solid lightgray;
        margin-right: .6rem;
        padding-right: .5rem;
    }
</style>
