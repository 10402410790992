<template>
    <v-date-picker
        :locale="defaultLocale"
        :value="rangePickerDate"
        :color="variant"
        mode="range"
        @input="setValue"
        @drag="dragHandle"
        :disabled-dates="disabledDates"
        :popover="popoverState"
        :animated="false" con
        :min-date="MinDateCalc"
        :max-date="MaxDateCalc"
        @popoverWillShow="toggleFocus(true)"
        @popoverWillHide="toggleFocus(false)"
        :from-date="fromDate"
        :attributes="computedAttributes"
        :columns="$screens(layout)">
        <div class="form-control app-calendar" :class="computedClasses">

                <span class="app-calendar--data">
                    <template v-if="value.start !== null && value.end !== null">
                        {{previewDate}}
                    </template>
                </span>

            <i v-if="value.start !== null && value.end !== null && clear && !disabled"
               @click.prevent="deleteValue"
               class='fa fa-times fa-fw app-calendar__icon app-calendar__icon--close' aria-hidden='true'></i>
            <i v-else class="fa fa-calendar-o fa-fw app-calendar__icon" aria-hidden="true"></i>
        </div>
    </v-date-picker>
</template>

<script>
    import Vue from 'vue';
    import VCalendar from 'v-calendar';
    import {MOMENT_LOCALE_MAPPINGS} from "@/shared/constants";

    Vue.use(VCalendar, {
        firstDayOfWeek: 2,
    });

    import moment from 'moment'

    export default {
        name: "AppDateRangePicker",
        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        start: null,
                        end: null
                    }
                }
            },
            variant: {
                //gray, red, orange, yellow, green, teal, blue, indigo, purple, pink
                type: String,
                default: 'teal'
            },
            MinDate: {
                default: null
            },
            MaxDate: {
                default: null
            },
            clear: {
                type: Boolean,
                default: true
            },
            disabledDates: {
                default: null
            },
            format: {
                default: null
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            maxDateDiff: {
                type: Number,
                default: null
            },
            layout: {
                type: Object,
                default: () => {
                    return {default: 1, lg: 2}
                },
            },
            attributes: {
                default: () => {
                    return []
                }
            },
            fromDate: {
                default: undefined
            },
            placement: {
                type: String,
                default: undefined
            },
            state: {
                default: null,
            },

        },
        data() {
            return {
                dragValue: {
                    start: null,
                    end: null
                },
                focused: false
            }
        },
        computed: {
            defaultLocale() {

                if (MOMENT_LOCALE_MAPPINGS.hasOwnProperty(this.$i18n.locale)) {
                    return MOMENT_LOCALE_MAPPINGS[this.$i18n.locale]
                }
                return 'en'
            },
            previewDate() {
                if (this.format) {
                    return new moment(this.value.start).format(this.format) + ' - ' + new moment(this.value.end).format(this.format)
                }

                return this.$options.filters.date(this.value.start) + ' - ' + this.$options.filters.date(this.value.end)
            },
            rangePickerDate() {
                return {
                    start: this.value.start !== null ? new moment(this.value.start, 'YYYY-MM-DD').toDate() : null,
                    end: this.value.end !== null ? new moment(this.value.end, 'YYYY-MM-DD').toDate() : null,
                }
            },
            popoverState() {

                return {
                    visibility: this.disabled ? 'hidden' : 'click',
                    placement: this.placement,
                    positionFixed: true,
                    modifiers: {
                        preventOverflow: {
                            boundariesElement: 'scrollParent'
                        }
                    }
                }
                //return this.disabled ? {visibility: 'hidden'} : {visibility: 'click', placement: this.placement}
            },
            computedClasses() {
                return {
                    'app-calendar--disabled': this.disabled,
                    'is-invalid': this.state === false,
                    'is-focused': this.focused
                }
            },
            MinDateCalc() {
                if (this.MinDate) {
                    return this.MinDate
                }
                if (this.maxDateDiff !== null
                    && this.dragValue
                    && this.dragValue.end !== null) {
                    return new moment(this.dragValue.end).subtract(this.maxDateDiff, 'day').toDate()
                }

                return null

            },
            MaxDateCalc() {
               let value = undefined
                if (this.maxDateDiff !== null
                    && this.dragValue
                    && this.dragValue.start !== null) {
                    value = new moment(this.dragValue.start).add(this.maxDateDiff, 'day').toDate()
                    return  this.MaxDate !==null ? value < this.MaxDate ? value : this.MaxDate : value
                }
                return this.MaxDate !== null ? this.MaxDate : value
            },
            computedAttributes() {
                return this.attributes.concat([{
                    key: 'today',
                    highlight: {class: 'today--background', contentClass: 'today--content'},
                    dates: new Date()
                }])
            }
        },
        methods: {
            dragHandle($event) {

                this.dragValue = $event
            },
            setValue(value) {
                if (value && value.start && value.end) {
                    this.$emit('input', {
                        start: new moment(value.start, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                        end: new moment(value.end, 'YYYY-MM-DD').format('YYYY-MM-DD')
                    })
                    this.dragValue.start = null
                    this.dragValue.end = null
                }
            },
            deleteValue() {
                this.$emit('input', {
                    start: null,
                    end: null
                })
            },
            toggleFocus(value) {
                this.focused = value
            }
        }
    }
</script>

<style lang="scss" scoped>
    .date-invalid {
        border-width: 1px;
        border-style: solid;
        border-color: rgb(236, 81, 81);
        border-image: initial;
    }
</style>
