<template>
    <b-form @submit.prevent autocomplete="off">
        <form-wizard ref="wizard" :startIndex="currentIndex" transition="fade-in" color=var(--color-primary) errorColor=var(--color-warning)
                     name="copy_restrictions_from_rate_plan_wizard" title="" subtitle=""
                     @on-validate="handleNextStep"
                     @on-complete="copy">

            <tab-content name="source" :title="$t('COPY_RESTRICTIONS_SOURCE')"
                         :before-change="() => validate('source')">
                <b-row class="d-flex justify-content-center">
                    <b-col md="6" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.source.unit">
                            <template v-slot:input="data">
                                <label>{{$t('UNIT')}}</label>
                                <app-select v-model="selected_unit"
                                            :options="unit_list"
                                            :search-empty-item="false"
                                            value-field="id"
                                            text-field="name"
                                            :state="data.state">
                                </app-select>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
                <b-row class="d-flex justify-content-center">
                    <b-col md="6" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.source.rate_plan">
                            <template v-slot:input="data">
                                <label>{{$t('RATE_PLAN')}}</label>
                                <app-select :disabled="!selected_unit"
                                            v-model="selected_rate_plan"
                                            :options="formattedRatePlanList"
                                            :search-empty-item="false"
                                            value-field="id"
                                            text-field="name"
                                            :state="data.state">
                                </app-select>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
                <b-row class="d-flex justify-content-center" v-if="selected_unit && selected_rate_plan && !restrictionsLoading">
                    <b-col md="6" class="mb-4">
                        <label>{{$t("RESTRICTIONS")}}</label>
                        <b-form-checkbox
                            v-if="restriction_list.length > 1"
                            id="select_all_restrictions"
                            v-model="select_all_restrictions"
                            name="select_all_restrictions-1"
                            @change="toggleRestrictions">
                            {{$t("SELECT_ALL")}}
                        </b-form-checkbox>
                        <b-form-checkbox-group
                            id="restriction-checkbox"
                            class="ml-4"
                            stacked
                            value-field="id"
                            text-field="label"
                            v-model="selected_restrictions"
                            :options="restriction_list"
                            name="restrictions_to_copy">
                        </b-form-checkbox-group>
                    </b-col>
                </b-row>
                <b-row class="d-flex justify-content-center" v-else-if="restrictionsLoading">
                    <b-col md="6" class="mb-4">
                        <b-spinner variant="primary"></b-spinner>
                    </b-col>
                </b-row>
                <b-row class="d-flex justify-content-center">
                    <b-col md="6" class="mb-4">
                        <div class="table-wrapper">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th class="table-header-period-range" scope="col">{{ $t('PERIOD') }}</th>
                                    <th class="table-header-actions text-right" scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <period-item :disabled="true" @delPeriod="removePeriod(period)"
                                             :key="index" v-for="(period, index) in sortedPeriods"
                                             :period="period"
                                             ref="period_items"
                                             :min-date="new Date()"
                                             :max-date="maxDate"
                                             :disabled-dates="disabledDates">
                                </period-item>
                                <period-item-new @save="addPeriod"
                                                 :from-date="fromDate"
                                                 :min-date="new Date()"
                                                 :max-date="maxDate"
                                                 :disabled-dates="disabledDates">
                                </period-item-new>
                                </tbody>
                            </table>
                        </div>
                    </b-col>
                </b-row>
            </tab-content>

            <tab-content name="prefilter" :title="$t('COPY_RESTRICTIONS_PREFILTER')"
                         :before-change="() => validate('prefilter')">
                <b-row class="d-flex justify-content-center">
                    <b-col md="6" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.prefilter.units">
                            <template v-slot:input="data">
                                <label>{{$t('UNITS')}}</label>
                                <app-select mode="multiselect"
                                            :set-first="true"
                                            v-model="selected_units"
                                            :options="unit_list"
                                            :state="data.state">
                                </app-select>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
                <b-row class="d-flex justify-content-center">
                    <b-col md="6" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.prefilter.distributions">
                            <template v-slot:input="data">
                                <label>{{$t('DISTRIBUTIONS')}}</label>
                                <app-select mode="multiselect"
                                            :set-first="true"
                                            v-model="selected_distributions"
                                            :options="distribution_list"
                                            :disabled="selected_units.length < 1"
                                            :state="data.state">
                                </app-select>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
            </tab-content>

            <tab-content name="target" :title="$t('COPY_RESTRICTIONS_DESTINATION')"
                         :before-change="() => validate(null)">
                <template v-if="!byUnitRatePlanListLoading && byUnitRatePlanList.length > 0">
                    <b-row>
                        <b-col lg="12">
                            <b-form-checkbox
                                id="check_all_possible_restrictions"
                                name="check_all_possible_restrictions"
                                :checked="allPossibleRestrictionsChecked()"
                                @change="toggleAllPossibleRestrictions($event)">
                                {{$t('SELECT_ALL')}}
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row v-for="(unit, unitIndex) in byUnitRatePlanList">
                        <b-col lg="12">
                            <hr>
                            <b-form-checkbox
                                :id="'unit_' + unit.id + '_check_all_restrictions'"
                                :name="'unit_' + unit.id + '_check_all_restrictions'"
                                :checked="unitRestrictionsChecked(unit)"
                                @change="toggleUnitRestrictions(unit, $event)">
                                {{unit.name}} ({{unit.id}})
                            </b-form-checkbox>
                        </b-col>
                        <template v-for="(distribution, distributionIndex) in unit.distributions">
                            <b-col lg="12">
                                <div class="position-relative">
                                    <span :class="expanded[unit.id + '_' + distribution.id] ? 'icon-expanded-dropdown-down' : 'icon-collapsed-dropdown-up'"
                                          @click="toggleRatePlanSelection(unit.id, distribution.id)">
                                    </span>
                                    <b-form-checkbox
                                        :id="'unit_' + unit.id + '_distribution_' + distribution.id + '_check_all_restrictions'"
                                        :name="'unit_' + unit.id + '_distribution_' + distribution.id + '_check_all_restrictions'"
                                        :checked="distributionRestrictionsChecked(distribution)"
                                        @change="toggleDistributionRestrictions(distribution, $event)"
                                        class="ml-4">
                                        {{distribution.name}}
                                    </b-form-checkbox>
                                </div>
                            </b-col>
                            <template v-for="(ratePlan, ratePlanIndex) in distribution.rate_plans"
                                      v-if="expanded[unit.id + '_' + distribution.id]">
                                <b-col lg="12">
                                    <div class="ml-4">
                                        <b-form-checkbox
                                            :id="'rate_plan_' + ratePlan.id + 'check_all_restrictions'"
                                            :name="'rate_plan_' + ratePlan.id + 'check_all_restrictions'"
                                            :checked="ratePlanRestrictionsChecked(ratePlan)"
                                            @change="toggleRatePlanRestrictions(ratePlan, $event)"
                                            class="ml-4">
                                            {{ratePlan.name}} ({{ratePlan.id}})
                                        </b-form-checkbox>
                                        <div class="ml-4">
                                            <b-form-checkbox-group
                                                v-if="selected_restrictions.length > 1"
                                                :id="'rate_plan_' + ratePlan.id + '_restriction_group'"
                                                :name="'rate_plan_' + ratePlan.id + '_restriction_group'"
                                                class="ml-4"
                                                stacked
                                                v-model="selected_list_for_copy[ratePlan.id]"
                                                :options="ratePlan.restrictions"
                                                value-field="id"
                                                text-field="label">
                                            </b-form-checkbox-group>
                                        </div>
                                    </div>
                                </b-col>
                            </template>
                        </template>
                    </b-row>
                </template>
                <template v-else-if="!byUnitRatePlanListLoading">
                    <app-no-data>
                    </app-no-data>
                </template>
                <template v-else>
                    <b-row class="d-flex justify-content-center">
                        <b-spinner variant="primary"></b-spinner>
                    </b-row>
                </template>
            </tab-content>

            <button class="btn btn-outline-primary" slot="prev">{{$t('PREVIOUS')}}</button>
            <button class="btn btn-primary" slot="next">{{$t('NEXT')}}</button>
            <button class="btn btn-primary" slot="finish"
                    :disabled="copyDisabled || loading">
                <i v-if="loading"
                   class="mr-1 fa fa-spinner fa-spin fa-fw"
                   aria-hidden="true">
                </i>
                {{$t('FINISH')}}
            </button>

        </form-wizard>
    </b-form>
</template>

<script>
import {
    copyRestrictionListForRatePlans,
    getPricingRatePlanList,
    getRatePlanListWithRestrictionsByUnit, validateCopyRestrictionListForRatePlansData
} from "@/services/pricing";
import {getRestrictionList} from "@/services/pricing/restriction";
import {getUnitList} from "@/services/unit";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {FormWizard, TabContent} from 'vue-form-wizard'
import {EventBus, V_ERROR} from "@/shared/EventBus";
import moment from 'moment'
import AppNoData from "@/components/app/AppNoData";
import {getDistributionList} from "@/services/distribution";
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppInputControl from "@/components/app/AppInputControl";
import {
    ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_DESTINATION_DISTRIBUTION_LIST_REQUIRED,
    ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_DESTINATION_UNIT_LIST_REQUIRED,
    ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_RESTRICTION_LIST_REQUIRED,
    ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_SOURCE_RATE_PLAN_REQUIRED,
    ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_SOURCE_UNIT_REQUIRED
} from "@/shared/error_codes";
import PeriodItem from "@/components/app/Period/PeriodItem";
import PeriodItemNew from "@/components/app/Period/PeriodItemNew";

export default {
    name: "CopyRestrictionsFromRatePlanWizard",
    components: {
        PeriodItemNew,
        PeriodItem,
        AppInputControl,
        AppNoData,
        FormWizard,
        TabContent,
        AppSelect
    },
    mixins: [getErrorMessage],
    props: {
        propertyId: {
            type: Number
        },
        unitId: {
            type: Number,
            default: null
        },
        ratePlanId: {
            type: Number,
            default: null
        },
        defaultDatePeriod: {
            type: Object,
            default: () => {
                return {start: null, end: null}
            }
        },
    },
    data() {
        return {
            currentIndex: 0,
            selected_unit: null,
            selected_rate_plan: null,
            selected_restrictions: [],
            selected_periods: [],
            selected_units: [],
            selected_distributions: [],
            selected_list_for_copy: {},
            unit_list: [],
            rate_plan_list: [],
            distribution_list: [],
            restriction_list: [],
            byUnitRatePlanList: [],
            select_all_restrictions: false,
            restrictionsLoading: false,
            byUnitRatePlanListLoading: false,
            loading: false,
            validationErrorObject: {
                source: {
                    unit: {
                        input: [ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_SOURCE_UNIT_REQUIRED]
                    },
                    rate_plan: {
                        input: [ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_SOURCE_RATE_PLAN_REQUIRED]
                    },
                    restrictions: {
                        toast: [ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_RESTRICTION_LIST_REQUIRED]
                    },
                },
                prefilter: {
                    units: {
                        input: [ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_DESTINATION_UNIT_LIST_REQUIRED]
                    },
                    distributions: {
                        input: [ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_DESTINATION_DISTRIBUTION_LIST_REQUIRED]
                    },
                },
            },
            expanded: [],
        }
    },
    watch: {
        selected_unit: {
            handler(value) {
                if (value) {
                    getPricingRatePlanList({
                        unit: this.selected_unit,
                        without_fully_inherited_restrictions: 1,
                        limit: 999
                    }).then(response => {
                        this.rate_plan_list = response.data.items
                    }, () => {
                        this.rate_plan_list = []
                    })
                }
            }
        },
        selected_rate_plan: {
            handler(value) {
                this.restrictionsLoading = true
                this.selected_restrictions = []
                if (value) {
                    getRestrictionList({
                        not_inherited_by_rate_plan: value
                    }).then(response => {
                        this.restriction_list = response.data
                        if (response.data.length === 1) {
                            this.selected_restrictions.push(response.data[0].id)
                        }
                    }, () => {
                        this.restriction_list = []
                    }).finally(() => {
                        this.restrictionsLoading = false
                    })
                } else {
                    this.restriction_list = []
                    this.restrictionsLoading = false
                }
            }
        },
        selected_restrictions: {
            handler(value) {
                this.selected_distributions = []
                if (value.length > 0 && this.selected_units.length > 0) {
                    this.getDistributionList()
                } else {
                    this.distribution_list = []
                }
                if (this.restriction_list.length > 1) {
                    if (value.length === this.restriction_list.length) {
                        this.select_all_restrictions = true
                    }
                    if (value.length < this.restriction_list.length) {
                        this.select_all_restrictions = false
                    }
                }
            }
        },
        selected_units: {
            handler(value) {
                this.selected_distributions = []
                if (value.length > 0 && this.selected_restrictions.length > 0) {
                    this.getDistributionList()
                } else {
                    this.distribution_list = []
                }
            }
        },
    },
    computed: {
        company() {
            return this.$store.getters['user/getCompany']
        },
        copyDisabled() {
            for (let [ratePlan, restrictions] of Object.entries(this.selected_list_for_copy)) {
                if (restrictions.length > 0) {
                    return false
                }
            }
            return true
        },
        formattedRatePlanList() {
            let formatted_rate_plan_list = []
            if (this.rate_plan_list.length > 0) {
                for (let rate_plan of this.rate_plan_list) {
                    let rate_plan_name = rate_plan.name
                    if (rate_plan.distribution && rate_plan.distribution.name) {
                        rate_plan_name += ' (' + rate_plan.distribution.name + ')'
                    }
                    formatted_rate_plan_list.push({
                        id: rate_plan.id,
                        name: rate_plan_name
                    })
                }
            }

            return formatted_rate_plan_list
        },
        fromDate() {
            let periodList = this.selected_periods.filter(el => el.deleted !== 1)
            if (periodList.length > 0) {
                let lastItem = periodList[periodList.length - 1]
                if (lastItem.end) {
                    return new moment(lastItem.end).add(1, 'day').toDate()
                }
            }

            return undefined
        },
        disabledDates() {
            return this.selected_periods.map(item => {
                return {
                    start: moment(item.start).toDate(),
                    end: moment(item.end).toDate(),
                }
            })
        },
        sortedPeriods() {
            return this.selected_periods.map(period => {
                return {
                    start: period.start,
                    end: period.end
                }
            }).sort((a, b) => new Date(a.start) - new Date(b.start))
        },
        maxDate(){
            const currentYear = new Date().getFullYear()
            let maxDate =  (currentYear + 3) + '-12-31'
            return new Date(maxDate)
        },
    },
    methods: {
        handleNextStep(isValid, tabIndex) {
            if (!isValid) {
                return
            }

            if (tabIndex === 1) {
                this.getByUnitRatePlanList()
            }

            EventBus.$emit(V_ERROR, [])
            this.currentIndex = tabIndex++
        },
        validate(step) {
            if (step === null) {
                return true
            }
            let request = {
                source_unit: this.selected_unit,
                source_rate_plan: this.selected_rate_plan,
                restriction_list: this.selected_restrictions,
                destination_units: this.selected_units,
                destination_distributions: this.selected_distributions
            }
            return validateCopyRestrictionListForRatePlansData(request).then(() => {
                return true
            }, error => {
                if (this.validationErrorObject.hasOwnProperty(step)) {
                    return this.serverStepValidation(this.validationErrorObject[step], error.response.data.error_list)
                }
            })
        },
        serverStepValidation(stepObject, errorList) {
            if (Array.isArray(errorList) && errorList.length) {
                const serverErrorCodes = errorList.map(list => Number(list.code))
                for (const [filedName, errorObject] of Object.entries(stepObject)) {
                    for (const [type, typeErrors] of Object.entries(errorObject)) {
                        for (let error of typeErrors) {
                            if (serverErrorCodes.includes(error.code)) {
                                if (error.code === ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_RESTRICTION_LIST_REQUIRED.code) {
                                    toast({
                                        'title': this.$t('NOTIFICATIONS.ERROR'),
                                        'message': this.$t('V.SELECT_RESTRICTIONS_TO_COPY'),
                                        'type': 'error',
                                        'timeout': 3000
                                    })
                                }
                                return false
                            }
                        }
                    }
                }
            }
            return true
        },
        getUnits() {
            let request = {property_id: this.propertyId, contigent: 0}
            getUnitList(request).then(response => {
                this.unit_list = response.data.items
            })
        },
        toggleRestrictions() {
            this.selected_restrictions = []
            if (!this.select_all_restrictions) {
                this.restriction_list.forEach(restriction => {
                    this.selected_restrictions.push(restriction.id)
                })
            }
        },
        getDistributionList() {
            getDistributionList({
                company: this.company,
                rate_plan_without_inheritance: 1,
                unit_list: this.selected_units,
                restriction_list: this.selected_restrictions,
                sorted: 1
            }).then(response => {
                this.distribution_list = response.data
            }, () => {
                this.distribution_list = []
            })
        },
        getByUnitRatePlanList() {
            this.byUnitRatePlanListLoading = true
            let request = {
                property: this.propertyId,
                restriction_list: this.selected_restrictions,
                rate_plan: this.selected_rate_plan
            }
            if (this.selected_units.length > 0) {
                request.unit_list = this.selected_units
            }
            if (this.selected_distributions.length > 0) {
                request.distribution_list = this.selected_distributions
            }
            if (this.selected_periods.length > 0) {
                request.period_list = this.selected_periods
            }
            getRatePlanListWithRestrictionsByUnit(request).then(response => {
                this.byUnitRatePlanList = response.data
            }, () => {
                this.byUnitRatePlanList = []
            }).finally(() => {
                this.byUnitRatePlanListLoading = false
            })
        },
        allPossibleRestrictionsChecked() {
            for (let unit of this.byUnitRatePlanList) {
                if (!this.unitRestrictionsChecked(unit)) {
                    return false
                }
            }
            return true
        },
        unitRestrictionsChecked(unit) {
            for (let distribution of unit.distributions) {
                if (!this.distributionRestrictionsChecked(distribution)) {
                    return false
                }
            }
            return true
        },
        distributionRestrictionsChecked(distribution) {
            for (let ratePlan of distribution.rate_plans) {
                if (!this.ratePlanRestrictionsChecked(ratePlan)) {
                    return false
                }
            }
            return true
        },
        ratePlanRestrictionsChecked(ratePlan) {
            if (!this.selected_list_for_copy[ratePlan.id]) {
                return false
            }
            return ratePlan.restrictions.length === this.selected_list_for_copy[ratePlan.id].length
        },
        toggleAllPossibleRestrictions(e) {
            for (let unit of this.byUnitRatePlanList) {
                this.toggleUnitRestrictions(unit, e)
            }
        },
        toggleUnitRestrictions(unit, e) {
            for (let distribution of unit.distributions) {
                this.toggleDistributionRestrictions(distribution, e)
            }
        },
        toggleDistributionRestrictions(distribution, e) {
            for (let ratePan of distribution.rate_plans) {
                this.toggleRatePlanRestrictions(ratePan, e)
            }
        },
        toggleRatePlanRestrictions(ratePlan, e) {
            this.$set(this.selected_list_for_copy, ratePlan.id, [])
            if (e) {
                for (let restriction of ratePlan.restrictions) {
                    this.selected_list_for_copy[ratePlan.id].push(restriction.id)
                }
            }
        },
        copy() {
            this.loading = true
            let request = {
                rate_plan: this.selected_rate_plan,
                rate_plan_list: this.selected_list_for_copy
            }
            if (this.selected_periods.length > 0) {
                request.period_list = this.selected_periods
            }
            copyRestrictionListForRatePlans(request).then(response => {
                notifySuccess()
                this.$emit('restrictionsCopied')
            }, error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
            })
        },
        toggleRatePlanSelection(unit_id, distribution_id) {
            if (!this.expanded[unit_id + '_' + distribution_id]) {
                this.$set(this.expanded, unit_id + '_' + distribution_id, true)
            } else {
                this.$set(this.expanded, unit_id + '_' + distribution_id, false)
            }
        },
        addPeriod(period) {
            this.selected_periods.push({
                start: period.start,
                end: period.end,
            })
        },
        removePeriod(period) {
            let index = this.selected_periods.findIndex(el => el.start === period.start && el.end === period.end)
            if (index > -1) {
                this.selected_periods.splice(index, 1)
            }
        }
    },
    created() {
        if (this.unitId) {
            this.selected_unit = this.unitId
        }
        if (this.ratePlanId) {
            this.selected_rate_plan = this.ratePlanId
        }
        this.getUnits()
    },
    mounted() {
        if (this.defaultDatePeriod.start !== null && this.defaultDatePeriod.end !== null) {
            this.selected_periods.push(this.defaultDatePeriod)
        }
    }
}
</script>

<style scoped>
    .icon-expanded-dropdown-down:before {
        font-family: "FontAwesome";
        font-size: .7rem;
        position: absolute;
        left: .5rem;
        top: 56%;
        transform: translateY(-50%);
        color: #6F6EFE;
        font-weight: normal;
        content: "\f078";
    }

    .icon-expanded-dropdown-down:hover:before {
         color: #5352C2;
         cursor: pointer;
    }

    .icon-collapsed-dropdown-up:before {
         font-family: "FontAwesome";
         font-size: .7rem;
         position: absolute;
         left: .5rem;
         top: 56%;
         transform: translateY(-50%);
         color: #6F6EFE;
         font-weight: normal;
         content: "\f054";
     }

    .icon-collapsed-dropdown-up:hover:before {
         color: #5352C2;
         cursor: pointer;
     }
</style>
