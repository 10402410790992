<template>
    <div>
        <b-form>
            <b-row :key="data.id" v-for="data in detailsData" class="mb-4" style="border-bottom: 1px solid #e8e6e6;">
                <b-col class="mb-3">
                 <label class="mr-3">{{$t('STATUS')}}:</label>     <b-badge :variant="(data.status.id === 21) ? 'danger' : 'primary'">{{data.status.label}}</b-badge>
                </b-col>
                <b-col cols="12" class="mb-3">
                    <label>{{$t('REQUEST')}}</label>
                    <b-form-textarea no-resize class="finance-qe" disabled :value="formatValue(data.request)" :rows="5" style="background: white"></b-form-textarea>
                </b-col>
                <b-col cols="12" class="mb-3">

                    <label>{{$t('RESPONSE')}}</label>
                    <b-form-textarea no-resize class="finance-qe" disabled :value="formatValue(data.response)" :rows="5" style="background: white"></b-form-textarea>

                </b-col>
                <hr>
            </b-row >

        </b-form>

    </div>


</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import TreeView from "@/components/channel_manager/synchronization_log/tree_view/TreeView";
    import FormHeader from "@/components/app/form/FormHeader";
    export default {
        name: "QueueDetails",
        components: {FormHeader, TreeView, AppButton},
        props: {
            detailsData: {
                type: Array
            }
        },
        data() {
            return {
                modalOpen: false,
                detailsFields: [
                    {key: 'status.label', label: this.$t('STATUS')},
                    {key: 'ts_insert', label: this.$t('SYNCHRONIZATION_TIME')},
                    {key: 'ts_start', label: this.$t('SYNCHRONIZATION_START')},
                    {key: 'ts_end', label: this.$t('SYNCHRONIZATION_END')},
                    {key: 'action_buttons', label: '', class: 'text-right'},
                    {key: 'request', label: this.$t('REQUEST')},
                    {key: 'response', label: this.$t('RESPONSE')}
                ],
                isJSON: false,
                modalData:null
            }
        },
        methods:{
            formatValue(value){
                if(!value){
                    return
                }
                let val = null
                if( value.toString().startsWith("<?xml version=")){
                    val = value
                } else {
                    //if json
                    try {
                        let object  = JSON.parse(value)
                        if(object.hasOwnProperty('data') && object.data !== null){
                            if(object.data.hasOwnProperty('distribution_response')){
                                val = object.data.distribution_response
                            } else{
                                val = typeof object.data === 'object' ? object.data.stringify() :  object.data
                            }
                        } else{
                            val = value
                        }
                    } catch (e) {
                     val = value
                    }
                }
                return val
            },
            showModal(data) {
                this.modalData = []
                this.modalOpen = true

                if (data) {
                    if (data.toString().startsWith("<?xml version=")) {
                        this.isJSON = false
                        this.modalData = data
                    } else if (data.length > 0) {
                        this.isJSON = true
                        try {
                            this.modalData = JSON.parse(data)
                        } catch (e) {
                            this.isJSON = false
                            this.modalData = data
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
