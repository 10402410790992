import {
    ACCESS_CONTROL_PRODUCT_MANAGEMENT,
    ACCESS_CONTROL_RATE_PLAN,
    ACCESS_CONTROL_PROPERTY,
    ACCESS_CONTROL_PROPERTY_NAVIGATION,
    ACCESS_CONTROL_UNIT_NAVIGATION,
    ACCESS_CONTROL_UNIT_GENERAL,
    ACCESS_CONTROL_ACCOMMODATION_RESERVATION,
    ACCESS_CONTROL_SUBSCRIPTION,
    ACCESS_CONTROL_ADDITIONAL_SETUP,
    ACCESS_CONTROL_PROPERTY_ACTIVATION_WIZARD,
    ACCESS_CONTROL_PRODUCT_MANAGEMENT_UNIT,
    ACCESS_CONTROL_TRANSACTION_ACTION,
    ACCESS_CONTROL_ACCOMMODATION_RESERVATION_REPORTING,
    ACCESS_CONTROL_USER_NAVIGATION,
    ACCESS_CONTROL_SYSTEM_SETTINGS_NAVIGATION,
    ACCESS_CONTROL_CM_SETUP_PARAMETER,
    ACCESS_CONTROL_PARTNER,
    ACCESS_CONTROL_INVOICE,
    ACCESS_CONTROL_MAIN_NAVIGATION,
    ACCESS_CONTROL_MAIN_CALENDAR,
    ACCESS_CONTROL_SYSTEM_FINANCE_SETUP,
    ACCESS_CONTROL_BULK_CALENDAR,
    ACCESS_CONTROL_OWNER,
    ACCESS_CONTROL_DASHBOARD,
    ACCESS_CONTROL_FEATURE_SETUP, ACCESS_CONTROL_REVENUE_MANAGER,
    ACCESS_CONTROL_BULK_RATE_PLAN, ACCESS_CONTROL_WEBSITE, ACCESS_CONTROL_DISTRIBUTION,
    ACCESS_CONTROL_WEBSITE_PAGES, ACCESS_CONTROL_CANCELLATION_POLICY,
    ACCESS_CONTROL_COMPANY_NAVIGATION, ACCESS_CONTROL_COMPANY, ACCESS_CONTROL_CRM, ACCESS_CONTROL_ADD_RETURN_TRANSFER
} from "@/services/endpoints";


export const AC_CHANNEL_MANAGER = 1
export const AC_RATE_PLAN = 2
// property
export const AC_PROPERTY_FILTER = 'ac_property_filter'
export const AC_PROPERTY_NAVIGATION = 'ac_property_navigation'
export const AC_PROPERTY_HEADER_BUTTON_KEY = 'ac_property_header_button_key'
export const AC_PROPERTY_NO_DATA_BUTTON_KEY = 'ac_property_no_data_button_key'
export const AC_UNIT_NAVIGATION = 5
export const AC_PROPERTY_OVERVIEW = 'ac_property_overview'
export const AC_PROPERTY_GENERAL = 'ac_property_general'
export const AC_PROPERTY_CALENDAR  = 'ac_property_calendar'
export const AC_PROPERTY_ACTIVATION_WIZARD = 17
export const AC_PROPERTY_CANCELLATION_POLICY_KEY ='ac_property_cancellation_policy';
export const AC_PROPERTY_DETAILS_KEY ='ac_property_details';
export const AC_PROPERTY_AMENITIES= 'ac_property_amenities'
export const AC_PROPERTY_GALLERY = 'ac_property_gallery'
export const AC_PROPERTY_GALLERY_A = 'ac_property_gallery_a'
export const AC_PROPERTY_POI = 'ac_property_poi'
export const AC_PROPERTY_IMPORT = "ac_property_import"

export const AC_UNIT_GENERAL = 'ac_unit_general'
export const AC_UNIT_KEY_PICKUP = 'ac_unit_key_pickup'
export const AC_UNIT_DAMAGE_DEPOSIT= 'ac_unit_damage_deposit'
export const AC_UNIT_FEES= 'ac_unit_fees'
export const AC_UNIT_LOS = 'ac_unit_los'
export const AC_UNIT_OCCUPANCY = 'ac_unit_occupancy'
export const AC_UNIT_AMENITY = 'ac_unit_amenity'
export const AC_UNIT_CONTINGENT = 'ac_unit_contingent'


export const AC_RATE_PLAN_FORM  = 11
export const AC_RATE_PLAN_FORM_NEXTPAX  = 'ac_rate_plan_nextpax'
export const AC_SUBSCRIPTION = 'ac_subscription' //12
export const AC_SUBSCRIPTION_CONTRACT_DETAILS = 'ac_subscription_contract_details'
export const AC_CHANNEL_MANAGER_UNIT = 18
export const AC_CHANNEL_MANAGER_SETUP_PARAMETER = 24
export const AC_CHANNEL_MANAGER_SETUP_PARAMETER_EDIT = 'ac_channel_manager_setup_parameter_edit'
export const AC_PARTNER = 25

export const AC_OWNER ='ac_owner'
export const AC_OWNER_FINANCE ='ac_owner_finance'

// Reservation
// Accommodation
export const AC_ACCOMMODATION_RESERVATION_NEW = 13
export const AC_ACCOMMODATION_RESERVATION_GENERAL = 14
export const AC_ACCOMMODATION_RESERVATION_NAVIGATION = 16
export const AC_ACCOMMODATION_RESERVATION_TRANSACTION = 19
export const AC_ACCOMMODATION_RESERVATION_REPORTING = 20
export const AC_ACCOMMODATION_RESERVATION_FINANCE = 22
export const AC_ACCOMMODATION_RESERVATION_FILTER = 'ac_accommodation_reservation_filter'
export const AC_ACCOMMODATION_RESERVATION_INFORMATION = 'ac_accommodation_reservation_information'
export const AC_ACCOMMODATION_RESERVATION_FINANCE_DETAILS = 'ac_accommodation_reservation_finance_details'
export const AC_ACCOMMODATION_RESERVATION_LIST = 'ac_accommodation_reservation_list'
export const AC_USER_NAVIGATION = 21
export const AC_ADDITIONAL_SETUP = 15

export const AC_MAIN_NAVIGATION ='ac_main_navigation'
export const AC_MAIN_CALENDAR ='ac_main_calendar'
export const AC_CALENDAR_EDIT ='ac_main_edit'

//bulk calendar
export const AC_BULK_CALENDAR = 'ac_bulk_calendar'
export const AC_BULK_CALENDAR_DAILY_ADJUSTMENT = 'ac_bulk_calendar_daily_adjustment'
export const AC_BULK_CALENDAR_REVENUE = 'ac_bulk_calendar_revenue'

//system template
export const AC_SYSTEM_SETTINGS_NAVIGATION = 23
export const AC_SYSTEM_SETUP_FINANCE = 'ac_system_setup_finance'

export const AC_INVOICE_NEW = 'ac_invoice_new'

//dashboard
export const AC_DASHBOARD = 'ac_dashboard'

export const AC_FEATURE_SETUP = 'ac_feature_setup'

//revenue manager
export const AC_REVENUE_MANAGER = 'ac_revenue_manager'
export const AC_REVENUE_MANAGER_SIDE = 'ac_revenue_manager_side'
export const AC_REVENUE_MANAGER_BOOKING_WINDOW = 'ac_revenue_manager_window'
export const AC_REVENUE_MANAGER_PROPERTY = 'ac_revenue_manager_property'
export const AC_REVENUE_MANAGER_SETUP_PROPERTY = 'ac_revenue_manager_setup_property'

//website
export const AC_BULK_RATE_PLAN = 'ac_bulk_rate_plan'
export const AC_WEBSITE = 'ac_website'
export const AC_WEBSITE_PAGES = 'ac_website_pages'

// distribution
export const AC_DISTRIBUTION = 'ac_distribution'

export const AC_CANCELLATION_POLICY = 'ac_cancellation_policy'

//company
export const AC_COMPANY = 'ac_company'
export const AC_COMPANY_NAVIGATION = 'ac_company_navigation'
export const AC_CRM = 'ac_crm'

//transfer
export const AC_ADD_RETURN_TRANSFER = 'ac_add_return_transfer'

const AccessControlKeyUrls = {
    [AC_CHANNEL_MANAGER]: ACCESS_CONTROL_PRODUCT_MANAGEMENT,
    [AC_RATE_PLAN]: ACCESS_CONTROL_RATE_PLAN,
    [AC_PROPERTY_FILTER]: ACCESS_CONTROL_PROPERTY,
    [AC_PROPERTY_NAVIGATION]: ACCESS_CONTROL_PROPERTY_NAVIGATION,
    [AC_UNIT_NAVIGATION]: ACCESS_CONTROL_UNIT_NAVIGATION,
    [AC_PROPERTY_OVERVIEW]: ACCESS_CONTROL_PROPERTY,
    [AC_PROPERTY_GENERAL]: ACCESS_CONTROL_PROPERTY,
    [AC_ACCOMMODATION_RESERVATION_NEW]: ACCESS_CONTROL_ACCOMMODATION_RESERVATION,
    [AC_ACCOMMODATION_RESERVATION_GENERAL]: ACCESS_CONTROL_ACCOMMODATION_RESERVATION,
    [AC_ACCOMMODATION_RESERVATION_NAVIGATION]: ACCESS_CONTROL_ACCOMMODATION_RESERVATION,
    [AC_ACCOMMODATION_RESERVATION_FINANCE]: ACCESS_CONTROL_ACCOMMODATION_RESERVATION,
    [AC_ACCOMMODATION_RESERVATION_INFORMATION]: ACCESS_CONTROL_ACCOMMODATION_RESERVATION,
    [AC_ACCOMMODATION_RESERVATION_FINANCE_DETAILS]: ACCESS_CONTROL_ACCOMMODATION_RESERVATION,
    [AC_UNIT_GENERAL]:ACCESS_CONTROL_UNIT_GENERAL,
    [AC_UNIT_KEY_PICKUP]:ACCESS_CONTROL_UNIT_GENERAL,
    [AC_PROPERTY_CALENDAR]:ACCESS_CONTROL_PROPERTY,
    [AC_RATE_PLAN_FORM]:ACCESS_CONTROL_RATE_PLAN,
    [AC_RATE_PLAN_FORM_NEXTPAX]:ACCESS_CONTROL_RATE_PLAN,
    [AC_SUBSCRIPTION]: ACCESS_CONTROL_SUBSCRIPTION,
    [AC_SUBSCRIPTION_CONTRACT_DETAILS]: ACCESS_CONTROL_SUBSCRIPTION,
    [AC_ADDITIONAL_SETUP]: ACCESS_CONTROL_ADDITIONAL_SETUP,
    [AC_PROPERTY_ACTIVATION_WIZARD]:ACCESS_CONTROL_PROPERTY_ACTIVATION_WIZARD,
    [AC_CHANNEL_MANAGER_UNIT]:ACCESS_CONTROL_PRODUCT_MANAGEMENT_UNIT,
    [AC_ACCOMMODATION_RESERVATION_TRANSACTION]: ACCESS_CONTROL_TRANSACTION_ACTION,
    [AC_ACCOMMODATION_RESERVATION_REPORTING]: ACCESS_CONTROL_ACCOMMODATION_RESERVATION_REPORTING,
    [AC_USER_NAVIGATION]: ACCESS_CONTROL_USER_NAVIGATION,
    [AC_SYSTEM_SETTINGS_NAVIGATION]:ACCESS_CONTROL_SYSTEM_SETTINGS_NAVIGATION,
    [AC_CHANNEL_MANAGER_SETUP_PARAMETER]:ACCESS_CONTROL_CM_SETUP_PARAMETER,
    [AC_CHANNEL_MANAGER_SETUP_PARAMETER_EDIT]:ACCESS_CONTROL_CM_SETUP_PARAMETER,
    [AC_PARTNER]: ACCESS_CONTROL_PARTNER,
    [AC_INVOICE_NEW]: ACCESS_CONTROL_INVOICE,
    [AC_MAIN_NAVIGATION]:ACCESS_CONTROL_MAIN_NAVIGATION,
    [AC_MAIN_CALENDAR]:ACCESS_CONTROL_MAIN_CALENDAR,
    [AC_SYSTEM_SETUP_FINANCE]:ACCESS_CONTROL_SYSTEM_FINANCE_SETUP,
    [AC_BULK_CALENDAR]:ACCESS_CONTROL_BULK_CALENDAR,
    [AC_BULK_CALENDAR_REVENUE]:ACCESS_CONTROL_BULK_CALENDAR,
    [AC_PROPERTY_CANCELLATION_POLICY_KEY]:ACCESS_CONTROL_PROPERTY,
    [AC_PROPERTY_DETAILS_KEY]:ACCESS_CONTROL_PROPERTY,
    [AC_UNIT_DAMAGE_DEPOSIT]:ACCESS_CONTROL_UNIT_GENERAL,
    [AC_UNIT_FEES]:ACCESS_CONTROL_UNIT_GENERAL,
    [AC_PROPERTY_AMENITIES]:ACCESS_CONTROL_PROPERTY,
    [AC_UNIT_OCCUPANCY]:ACCESS_CONTROL_UNIT_GENERAL,
    [AC_UNIT_LOS]:ACCESS_CONTROL_UNIT_GENERAL,
    [AC_PROPERTY_GALLERY]:ACCESS_CONTROL_PROPERTY,
    [AC_PROPERTY_GALLERY_A]:ACCESS_CONTROL_PROPERTY,
    [AC_PROPERTY_POI]:ACCESS_CONTROL_PROPERTY,
    [AC_UNIT_AMENITY]:ACCESS_CONTROL_UNIT_GENERAL,
    [AC_UNIT_CONTINGENT]:ACCESS_CONTROL_UNIT_GENERAL,
    [AC_OWNER]:ACCESS_CONTROL_OWNER,
    [AC_OWNER_FINANCE]:ACCESS_CONTROL_OWNER,
    [AC_PROPERTY_HEADER_BUTTON_KEY]:ACCESS_CONTROL_PROPERTY_NAVIGATION,
    [AC_PROPERTY_NO_DATA_BUTTON_KEY]:ACCESS_CONTROL_PROPERTY_NAVIGATION,
    [AC_DASHBOARD]:ACCESS_CONTROL_DASHBOARD,
    [AC_FEATURE_SETUP]:ACCESS_CONTROL_FEATURE_SETUP,
    [AC_ACCOMMODATION_RESERVATION_FILTER]:ACCESS_CONTROL_FEATURE_SETUP,
    [AC_REVENUE_MANAGER]:ACCESS_CONTROL_REVENUE_MANAGER,
    [AC_BULK_CALENDAR_DAILY_ADJUSTMENT]:ACCESS_CONTROL_BULK_CALENDAR,
    [AC_REVENUE_MANAGER_SIDE]:ACCESS_CONTROL_REVENUE_MANAGER,
    [AC_REVENUE_MANAGER_PROPERTY]:ACCESS_CONTROL_REVENUE_MANAGER,
    [AC_BULK_RATE_PLAN]:ACCESS_CONTROL_BULK_RATE_PLAN,
    [AC_REVENUE_MANAGER_SETUP_PROPERTY]:ACCESS_CONTROL_REVENUE_MANAGER,
    [AC_WEBSITE]:ACCESS_CONTROL_WEBSITE,
    [AC_DISTRIBUTION]: ACCESS_CONTROL_DISTRIBUTION,
    [AC_CALENDAR_EDIT]:ACCESS_CONTROL_MAIN_CALENDAR,
    [AC_WEBSITE]:ACCESS_CONTROL_WEBSITE,
    [AC_REVENUE_MANAGER_SETUP_PROPERTY]:ACCESS_CONTROL_REVENUE_MANAGER,
    [AC_WEBSITE_PAGES]:ACCESS_CONTROL_WEBSITE_PAGES,
    [AC_CANCELLATION_POLICY]: ACCESS_CONTROL_CANCELLATION_POLICY,
    [AC_COMPANY_NAVIGATION]: ACCESS_CONTROL_COMPANY_NAVIGATION,
    [AC_COMPANY]:ACCESS_CONTROL_COMPANY,
    [AC_CRM]:ACCESS_CONTROL_CRM,
    [AC_PROPERTY_IMPORT]:ACCESS_CONTROL_PROPERTY,
    [AC_ACCOMMODATION_RESERVATION_LIST]:ACCESS_CONTROL_ACCOMMODATION_RESERVATION,
    [AC_ADD_RETURN_TRANSFER]:ACCESS_CONTROL_ADD_RETURN_TRANSFER,
}

export function getAccessControlUrl(key) {
    if (AccessControlKeyUrls.hasOwnProperty(key)) {
        return AccessControlKeyUrls[key]
    }
    return null
}
