<template>
    <div class="position-relative">
<!--        <transition name="fade">-->
<!--            <div class="app-list-spinner" v-if="busy">-->
<!--                <b-spinner variant="primary" label="Spinning"></b-spinner>-->
<!--            </div>-->
<!--        </transition>-->

        <b-table :items="items" :fields="computedFields" :busy="busy" :hover="hover" :responsive="responsive">
            <template v-slot:[getSlotName(field.key)]="data" v-for="field in computedFields">
                <slot :name="field.key" :item="data.item">
                    {{_get(data.item, field.key) }}
                </slot>
            </template>

            <template v-slot:actions="data">
                <slot name="actions" :item="data.item">

                </slot>

                <app-button v-if="Array.isArray(actions) && actions.includes('delete')"
                            variant="link"
                            icon="delete"
                            :show_text="false"
                            @click="() => {
                                deleteDialogObjectId = data.item
                                deleteDialogState = true
                            }">
                </app-button>
            </template>
        </b-table>

        <app-confirmation-dialog
            :show="deleteDialogState"
            @confirm="() => {
                $emit('delete', deleteDialogObjectId);
                deleteDialogState = false
            }"
            @cancel="deleteDialogState=false">
        </app-confirmation-dialog>
    </div>
</template>

<script>
    import {cloneDeep, get as _get} from "lodash"
    import AppButton from "@/components/app/AppButton/AppButton"
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog"
    import AppPagination from "@/components/app/filter/AppPagination"

    const allowedActions = ['delete']
    const validateActions = (actions) => {
        if (Array.isArray(actions)) {
            let valid = true
            actions.forEach(action => {
                if (!allowedActions.includes(action)) {
                    valid = false
                }
            })
            return valid
        } else if (typeof actions === 'string') {
            return allowedActions.includes(actions)
        } else {
            return false
        }
    }

    export default {
        name: "AppTable",
        components: {AppPagination, AppConfirmationDialog, AppButton},
        props: {
            items: {
                type: Array,
                required: true
            },
            fields: {
                type: Array,
                default: () => []
            },
            actions: {
                validator: (actions) => {
                    if (actions === true) return true
                    return validateActions(actions)
                },
                default: () => []
            },
            edit: {
                type: Object,
                default: null
            },
            busy: {
                type: Boolean,
                default: false
            },
            hover: {
                type: Boolean,
                default: true
            },
            responsive: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                allowedActions,
                deleteDialogState: false,
                deleteDialogObjectId: null,
            }
        },
        computed: {
            computedFields() {
                let f = this.fields.length ? cloneDeep(this.fields) : Object.keys(this.items[0]).map(key => {
                    return {key}
                })
                if (this.actions === true || this.actions.length) {
                    f.push({key: "actions", label: "", class: "text-right"});
                }
                return f
            }
        },
        methods: {
            _get: _get,
            getSlotName(name) {
                return name
            }
        }
    }
</script>

<style scoped>
    .app-list-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
    }

    .fade-enter-active {
        transition: opacity 1s
    }

    .fade-leave-active {
        transition: opacity .1s
    }

    .fade-enter, .fade-leave-to {
        opacity: 0
    }
</style>
