<template>
    <div>
        <b-card class="main-section-card"  v-if="hasPermission(NEW_CALENDAR_V)">
            <div slot="header" >
                <h2>{{$t('CALENDAR_TEST')}}</h2>

                <app-button v-if="checkPermission(MAIN_CALENDAR_LOG_V)"  @click="aside_log =true" class="pull-right ml-3">
                    {{$t('OVERVIEW_SALE_TERMS_CHANGE_LOG')}}</app-button>
                <app-button v-if="checkPermission(C_RESERVATION_ACCOMMODATION_NEW)" button_type="new" class="ml-3 pull-right" variant="primary" @click="res_aside=true">{{
                        $t("NEW_RESERVATION") }}
                </app-button>
                <app-button v-if="checkPermission(MAIN_CALENDAR_AVAILABILITY_QUOTE)" class="pull-right" @click="openAdvancedFilter">{{$t('AVAILABILITY_AND_QUOTE')}}</app-button>

            </div>
                <calendar-wrapper v-if="checkPermission(MAIN_CALENDAR_V)"  @selectUnit="updateSelectList"    :showPropertyItem="showPropertyItem"  ></calendar-wrapper>
            <app-aside v-model="res_aside">
                <template slot="header">
                    {{$t('NEW_RESERVATION')}}
                </template>
                <new-accommodation-reservation-form @created="()=>res_aside=false">
                </new-accommodation-reservation-form>
            </app-aside>

        </b-card>
        <app-aside :widths="['col-lg-12','col-sm-12', '100%']" full-width v-model="aside_log">
            <template v-slot:header>
                {{$t('OVERVIEW_SALE_TERMS_CHANGE_LOG')}}
            </template>
            <logs :revenue-manager-log="revenue_manager_log"></logs>
        </app-aside>
    </div>
</template>

<script>

    import PropertyCalendarWrapper from "@/components/property/calendar/PropertyCalendarWrapper";
    import CalendarAside from "@/components/unit/main_calendar/Aside/Calendar/CalendarAside";
    import AppAside from "@/components/app/form/AppAside";
    import CalendarWrapper from "@/components/calendar/CalendarWraper";
    import AppNoData from "@/components/app/AppNoData";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {MAIN_CALENDAR_V, MAIN_CALENDAR_AVAILABILITY_QUOTE,C_RESERVATION_ACCOMMODATION_NEW,MAIN_CALENDAR_LOG_V} from "@/shared/component_permission";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {EventBus, GE_CALENDAR_ADVANCED_FILTER} from "@/shared/EventBus";
    import {DEFAULT_PAGINATION_LIMIT, LS_PRESETS} from "@/shared/constants";
    import NewAccommodationReservationForm
        from "@/components/reservation/accommodation/forms/NewAccommodationReservationForm";
    import LogFilter from "@/components/logs/LogFilter";
    import LogTable from "@/components/logs/LogTable";
    import Logs from "@/components/logs/Logs";
    import {NEW_CALENDAR_V} from "@/shared/component_permission";

    export default {
        name: "Index",

        data(){
          return{
              empty_initial_list:false,
              showPropertyItem:true,
              MAIN_CALENDAR_V,
              MAIN_CALENDAR_AVAILABILITY_QUOTE,
              selected_unit:[],
              C_RESERVATION_ACCOMMODATION_NEW,
              property_count:null,
              res_aside:false,
              aside_log:false,
              MAIN_CALENDAR_LOG_V,
              revenue_manager_log:false,
              NEW_CALENDAR_V
          }
        },
        computed:{
            company() {
                return this.$store.getters['user/getCompany']
            },
            hasPermission() {
                return this.$store.getters['user/getPermission']
            },
        },
        components: {
            Logs,
            LogTable,
            LogFilter,
            NewAccommodationReservationForm,
            AppButton,
            AppAccessControl,
            AppNoData,
            CalendarWrapper, CalendarAside, PropertyCalendarWrapper,AppAside},
        methods:{
            updateSelectList(select_unit_ids){
                this.selected_unit = select_unit_ids
            },
            openAdvancedFilter(){

                EventBus.$emit(GE_CALENDAR_ADVANCED_FILTER)
            }

        },
        mounted() {
            EventBus.$on('gl_advanced_filter_result',data=>{
                this.selected_unit.push(Number(data.unit))
            })
        },
        async  created(){

            let storagePresets = localStorage.getItem(LS_PRESETS)
            if (storagePresets) {
                storagePresets = JSON.parse(storagePresets)
                this.property_count = storagePresets.hasOwnProperty('properties') ? storagePresets.properties : null
                if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                    if(storagePresets.properties === 0){
                        this.empty_initial_list = true
                    }
                    if(storagePresets.properties === 1){
                        this.showPropertyItem = false
                    }
                }
            }
        },
        watch:{
            '$route.query':{
                handler(value){
                    if(value.hasOwnProperty('revenue_manager_log')){
                         this.aside_log = true
                         this.revenue_manager_log = true
                    }
                },immediate:true
            }
        }

    }
</script>

<style scoped>

</style>
