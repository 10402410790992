<template>
    <b-form @submit.prevent="save" v-if="formData !== null && typeof formData === 'object' && options">
        <b-row class="mb-4">
            <b-col xl="2" lg="3" md="6" class="mb-4" v-if="formData.hasOwnProperty(INVOICEE_TYPE)">
                <label>{{ formData[INVOICEE_TYPE].label }}</label>
                <app-select v-model="formData[INVOICEE_TYPE].value"
                            mode="select"
                            text-field="label"
                            :options="options.invoicee_type"
                            :search-clear="true"></app-select>
            </b-col>
            <b-col xl="2" lg="3" md="6" class="mb-4" v-if="formData.hasOwnProperty(CHARGE_MADE_BY)">
                <label>{{ formData[CHARGE_MADE_BY].label }}</label>
                <app-select v-model="formData[CHARGE_MADE_BY].value"
                            mode="select"
                            text-field="label"
                            :options="options.charge_person_type"
                            :search-clear="true"></app-select>
            </b-col>
            <b-col xl="2" lg="3" md="6" class="mb-4" v-if="formData.hasOwnProperty(PAYMENT_TYPE)">
                <label>{{ formData[PAYMENT_TYPE].label }}</label>
                <app-select v-model="formData[PAYMENT_TYPE].value"
                            mode="select"
                            text-field="label"
                            :options="options.payment_type"
                            :search-clear="true"></app-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="2" lg="3" md="6" class="mb-4" v-if="formData.hasOwnProperty(NOTIFY_CUSTOMER_AFTER_TRANSACTION)">
                <b-checkbox v-model="formData[NOTIFY_CUSTOMER_AFTER_TRANSACTION].value"
                            switch
                            :unchecked-value="0"
                            :value="1">
                    <label>{{ formData[NOTIFY_CUSTOMER_AFTER_TRANSACTION].label }}</label>
                </b-checkbox>
            </b-col>
            <b-col xl="2" lg="3" md="6" class="mb-4" v-if="formData.hasOwnProperty(CHARGE_RESERVATION_AUTOMATICALLY)">
                <b-checkbox v-model="formData[CHARGE_RESERVATION_AUTOMATICALLY].value"
                            switch
                            :unchecked-value="0"
                            :value="1">
                    <label>{{ formData[CHARGE_RESERVATION_AUTOMATICALLY].label }}</label>
                </b-checkbox>
            </b-col>
            <b-col xl="2" lg="3" md="6" class="mb-4" v-if="formData.hasOwnProperty(SEND_INVOICE_TO_CUSTOMER)">
                <b-checkbox v-model="formData[SEND_INVOICE_TO_CUSTOMER].value"
                            switch
                            :unchecked-value="0"
                            :value="1">
                    <label>{{ formData[SEND_INVOICE_TO_CUSTOMER].label }}</label>
                </b-checkbox>
            </b-col>
            <b-col xl="2" lg="3" md="6" class="mb-4" v-if="formData.hasOwnProperty(OWNER_STATEMENT_SETUP)">
                <b-checkbox v-model="formData[OWNER_STATEMENT_SETUP].value"
                            switch
                            :unchecked-value="0"
                            :value="1">
                    <label>{{ formData[OWNER_STATEMENT_SETUP].label }}</label>
                </b-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <app-button-submit v-if="saveVisible" :loading="saving">
            </app-button-submit>
        </b-row>
    </b-form>
</template>

<script>
import AppAccessControl from "@/components/app/AppAccessControl";
import AppInputControl from "@/components/app/AppInputControl";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {
    getReservationSetup,
    getReservationSetupOptions,
    updateReservationSetup
} from "@/services/accommodation_reservation";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";


const INVOICEE_TYPE = 106
const CHARGE_MADE_BY = 113
const NOTIFY_CUSTOMER_AFTER_TRANSACTION = 118
const SEND_INVOICE_TO_CUSTOMER = 120
const PAYMENT_TYPE = 123
const CHARGE_RESERVATION_AUTOMATICALLY = 124
const OWNER_STATEMENT_SETUP = 121

const paramConstants = {
    INVOICEE_TYPE,
    CHARGE_MADE_BY,
    NOTIFY_CUSTOMER_AFTER_TRANSACTION,
    SEND_INVOICE_TO_CUSTOMER,
    PAYMENT_TYPE,
    CHARGE_RESERVATION_AUTOMATICALLY,
    OWNER_STATEMENT_SETUP
}


export default {
    name: "ReservationAccommodationSetup",
    components: {AppButtonSubmit, AppSelect, AppInputControl, AppAccessControl},
    mixins: [getErrorMessage],
    props: {
        reservationId: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            ...paramConstants,
            formData: null,
            options: null,
            saving: false
        }
    },
    computed: {
        saveVisible() {
            return this.formData !== null && typeof this.formData === 'object' && (
                this.formData.hasOwnProperty(INVOICEE_TYPE) ||
                this.formData.hasOwnProperty(CHARGE_MADE_BY) ||
                this.formData.hasOwnProperty(NOTIFY_CUSTOMER_AFTER_TRANSACTION) ||
                this.formData.hasOwnProperty(SEND_INVOICE_TO_CUSTOMER) ||
                this.formData.hasOwnProperty(PAYMENT_TYPE) ||
                this.formData.hasOwnProperty(CHARGE_RESERVATION_AUTOMATICALLY) ||
                this.formData.hasOwnProperty(OWNER_STATEMENT_SETUP)
            )
        },
    },
    methods: {
        save() {
            this.saving = true
            updateReservationSetup(this.reservationId, {list: this.formData}).then(response => {
                notifySuccess()
                this.$emit('setupSaved')
                this.formData = response.data
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => this.saving = false)
        },
        getData() {
            getReservationSetup(this.reservationId).then(response => {
                this.formData = response.data
            })
        },
        getOptions() {
            getReservationSetupOptions().then(response => {
                this.options = response.data
            })
        }
    },
    created() {
        this.$emit('created', true)
        this.getOptions()
        this.getData()
    }
}
</script>

<style scoped>

</style>
