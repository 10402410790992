<template>
    <multi-select :value="value" label="name" :multiple="false" :options="options" :loading="loading" :searchable="true" track-by="id"
                  :placeholder="$t('SEARCH')"
                  :selectLabel="$t('APPLY')"
                  :deselectLabel="$t('CLEAR')"
                  @search-change="onSearch"
                  @input="onSelect">
        <span slot="noResult">{{$t("NO_DATA")}}</span>
        <span slot="noOptions">{{$t("NO_DATA")}}</span>


    </multi-select>
</template>

<script>
    import MultiSelect from 'vue-multiselect'
    import _debounce from 'lodash/debounce'
    import {getCountries} from '@/services/location/index'

    export default {
        name: "CountrySelect",
        props: {
            value: {
                type:Object,
                 default: function () {
                return {
                  id: null,
                  name:null
                }
              }
            }
        },
        components: {
            MultiSelect
        },
        data() {
            return {
                options: [],
                loading: false,
                placeholder:'Search'
            }
        },
        methods: {
            onSearch: _debounce(function (search) {
                if(search === ""){
                    return
                }
                this.loading = true
                getCountries({name: search}).then(response => {
                    this.options = response.data
                    this.loading = false
                })
            }, 500),
            onSelect(val) {
                if(val === null){
                    this.$emit("input", {id: null, name: null})
                } else {
                    this.$emit("input", val)
                }
            }
        },
        created(){
            if(this.value !== null && this.value.id !== null){
                this.options.push(this.value)
            }

        }
    }
</script>

<style scoped>

</style>
