<template>
    <v-date-picker
        :state="state"
        :locale="defaultLocale"
        :value="pickerDate"
        :color="variant"
        @input="setValue"
        :disabled-dates="disabledDates"
        :popover="popoverState"
        @popoverWillShow="toggleFocus(true)"
        @popoverWillHide="toggleFocus(false)"
        :animated="false"
        :min-date="MinDate"
        :max-date="maxDate"
        :from-date="computedFrom" :attributes="computedAttributes">
        <div class="form-control app-calendar" :class="computedClasses" :ref="component_input_id" tabindex="0">
                <span class="app-calendar--data">
                    <template v-if="value !== null">
                        {{previewDate}}
                    </template>
                </span>
            <i v-if="value !== null && clear && !disabled"
               @click.prevent="deleteValue"
               class='fa fa-times fa-fw app-calendar__icon app-calendar__icon--close' aria-hidden='true'></i>
            <i v-else class="fa fa-calendar-o fa-fw app-calendar__icon" aria-hidden="true"></i>
        </div>
    </v-date-picker>
</template>

<script>
    import Vue from 'vue';
    import VCalendar from 'v-calendar';
    import moment from 'moment'
    import {MOMENT_LOCALE_MAPPINGS} from "@/shared/constants";

    Vue.use(VCalendar, {
        firstDayOfWeek: 2,
        columns: 2,
    });

    function isValidDate(date) {
        return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
    }

    export default {
        name: "AppDatePicker",
        props: {
            value: {
                default: null
            },
            variant: {
                //gray, red, orange, yellow, green, teal, blue, indigo, purple, pink
                type: String,
                default: 'teal'
            },
            MinDate: {
                default: null
            },
            clear: {
                type: Boolean,
                default: true
            },
            disabledDates: {
                default: null
            },
            format: {
                default: null
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            placement: {
                type: String,
                default: undefined
            },
            state: {
                type: Boolean,
                default: true
            },
            fromDate: {
                default: undefined
            },
            maxDate: {
                default: null
            }
        },
        data() {
            return {
                focused: false
            }
        },
        computed: {
            component_input_id() {
                return 'datepicker_' + this._uid
            },
            defaultLocale() {
                if (MOMENT_LOCALE_MAPPINGS.hasOwnProperty(this.$i18n.locale)) {
                    return MOMENT_LOCALE_MAPPINGS[this.$i18n.locale]
                }
                return 'en'
            },
            previewDate() {
                if (this.format) {
                    return new moment(this.value).format(this.format)
                }
                return this.$options.filters.date(this.value)
            },
            pickerDate() {
                return this.value !== null ? new moment(this.value, 'YYYY-MM-DD').toDate() : null
            },
            popoverState() {

                return {
                    visibility: this.disabled ? 'hidden' : 'click',
                    placement: this.placement,
                    positionFixed: true,
                    modifiers: {
                        preventOverflow: {
                            boundariesElement: 'scrollParent'
                        }
                    }
                }
                //return this.disabled ? {visibility: 'hidden'} : {visibility: 'click', placement: this.placement}
            },
            computedClasses() {
                return {
                    'app-calendar--disabled': this.disabled,
                    'is-invalid': this.state === false,
                    'is-focused': this.focused
                }
            },
            computedFrom() {
                if (moment(this.fromDate, 'YYYY-MM-DD', true).isValid()) {
                    return new moment(this.fromDate).toDate()
                } else {
                    return undefined
                }
            },
            computedAttributes() {
                return [{
                    key: 'today',
                    highlight: {class: 'today--background', contentClass: 'today--content'},
                    dates: new Date()
                }]
            }
            // popoverState(){
            //     return this.disabled ? {visibility:'hidden'} : {visibility:'click', placement:this.placement}
            // }
        },
        methods: {
            focusInput() {
                this.$nextTick(() => {
                        this.$refs[this.component_input_id].focus()
                });
            },
            setValue(value) {

                if (value) {
                    this.$emit('input', new moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'))
                }

            },
            deleteValue() {
                this.$emit('input', null)
            },
            toggleFocus(value) {
                this.focusInput()
                this.focused = value
            }
        }
    }
</script>

<style lang="scss" scoped>
.red {
    background-color: red;
}
</style>
