<template>
    <div>
        <b-card v-if="checkPermission(C_COMPANY_LIST_V)" class="main-section-card">
            <div slot="header">
                <h2>{{ $t("ORGANIZATIONS") }}</h2>
                <new-button button_type="new" variant="primary" class="pull-right" @click="aside=true">
                    {{ $t("ORGANIZATION_NEW") }}
                </new-button>
            </div>

            <company-filter @search="filter=Object.assign({}, $event)" :loading="loading"></company-filter>
            <company-list-table :filter="filter" @busyToggled="toggleBusy" ></company-list-table>
        </b-card>

        <app-aside :widths="['col-sm-12', '40%']" v-model="aside">
            <template slot="header">{{$t('ORGANIZATION_NEW')}}</template>
            <company-wizard></company-wizard>
        </app-aside>
    </div>
</template>

<script>
    import NewButton from '@/components/app/AppButton/AppButton'
    import CompanyListTable from "@/components/company/list/CompanyListTable";
    import CompanyFilter from "@/components/company/list/CompanyFilter";
    import routeNames from "@/router/routeNames";
    import {C_COMPANY_LIST_V} from "@/shared/component_permission";
    import CompanyWizard from "@/components/company/new/CompanyWizard";
    import AppAside from "@/components/app/form/AppAside";

    export default {
        name: "Index",
        components: {
            CompanyWizard,
            CompanyFilter,
            CompanyListTable,
            NewButton,
            AppAside
        },
        data() {
            return {
                filter: {},
                routeNames,
                C_COMPANY_LIST_V,
                aside: false,
                loading: false,
            }
        },
        methods:{
            toggleBusy($event){
                this.loading = $event
            }
        }
    }
</script>

<style scoped>

</style>
