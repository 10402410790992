<template>
    <b-card class="main-section-card">
        <div slot="header">
            <h2>{{ $t("ACTIVITIES") }}</h2>
        </div>
        <activity-reservation-filter :busy="busy"></activity-reservation-filter>
        <b-row>
            <b-col>
                <activity-reservation-table @busyToggled="busy=$event"></activity-reservation-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
    import ActivityReservationTable from "@/components/reservation/activity/list/ActivityReservationTable";
    import ActivityReservationFilter from "@/components/reservation/activity/list/ActivityReservationFilter";
    export default {
        name: "Index",
        components: {ActivityReservationFilter, ActivityReservationTable},
        data() {
            return {
                busy: false,
            }
        }
    }
</script>

<style scoped>

</style>
