<template>
    <div>
        <b-row class="mb-4" v-if="checkPermission(C_PROPERTY_CM_PM_UNIT_NEW_HEADLINE_V)">
            <b-col md="8">
                <b-input v-model="name" type="text"
                         @input="handleHeadlineEntry">
                </b-input>
            </b-col>
            <b-col md="4">
                <new-headline-button :disabled="disabledSave" button_type="new" :loading="savingHeadline"
                                     @click="newHeadline"></new-headline-button>
            </b-col>
        </b-row>
        <app-translation-list class="mb-4"
                              v-if="displayTranslationList && checkPermission(C_PROPERTY_CM_PM_UNIT_HEADLINE_TRANSLATE)"
                              :language-list="languageList"
                              @update="setTranslations">
        </app-translation-list>
        <b-form v-if="this.list.length > 0" @submit.prevent="saveHeadlineMapping">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                    <th>{{$t('NAME')}}</th>
                    <th v-if="checkPermission(C_PROPERTY_CM_PM_UNIT_HEADLINE_TRANSLATE)"></th>
                    <th></th>
                    </thead>
                    <tbody>
                    <tr v-for="(val, key) in list">

                        <td>
                            {{ val.name }}

                        </td>
                        <td v-if="checkPermission(C_PROPERTY_CM_PM_UNIT_HEADLINE_TRANSLATE)">
                            <i
                                @click="editTranslatedTexts"
                                :key="'edit_translations_' + key + '_' + val.id"
                                v-if="activeHeadline !== null && activeHeadline.id === val.id"
                                class="fa fa-language action_button"
                                style="cursor:pointer">
                            </i>
                        </td>
                        <td>
                            <input type="radio" :checked="unitHeadline === val.id" :id="val.id"
                                   :disabled="!checkPermission(C_PROPERTY_CM_PM_UNIT_ASSIGN_HEADLINE_V)"
                                   @click="updateMapping(val.id)" :key="'input' + key">

                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <headline-submit :loading="savingHeadlineMapping" v-if="mode !== 'withoutSave' && hasPermission(C_PROPERTY_CM_PM_UNIT_ASSIGN_HEADLINE_V)"></headline-submit>
        </b-form>

        <app-aside v-model="headlineTranslationsPanelState"
                   v-if="activeHeadline !== null"
                   :widths="['col-lg-4', 'col-sm-12', '40%']">
            <template slot="header">
                <app-object-header
                    :name="activeHeadline.name">
                </app-object-header>
            </template>
            <headline-translations-form :headline="activeHeadline.id"
                                        :language-list="languageList"
                                        @update="updateHeadlineTranslations">
            </headline-translations-form>
        </app-aside>

    </div>
</template>

<script>
    import {
        getHeadlineList,
        createHeadlineMapping,
        createHeadline,
        updateHeadlineMapping
    } from '@/services/distribution/index'
    import HeadlineSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import NewHeadlineButton from '@/components/app/AppButton/AppButtonSubmit'
    import {notifySuccess, toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {EventBus} from "@/shared/EventBus";
    import {
        C_PROPERTY_CM_PM_UNIT_NEW_HEADLINE_V,
        C_PROPERTY_CM_PM_UNIT_ASSIGN_HEADLINE_V,
        C_PROPERTY_CM_PM_UNIT_HEADLINE_TRANSLATE
    } from "@/shared/component_permission";
    import AppTranslationList from "@/components/app/translation/AppTranslationList";
    import {DEFAULT_LANGUAGE, OBJECT_TYPE_UNIT} from "@/shared/constants";
    import {getTranslateList} from "@/services/system";
    import AppAside from "@/components/app/form/AppAside";
    import HeadlineTranslationsForm from "@/components/channel_manager/headline/HeadlineTranslationsForm";
    import AppObjectHeader from "@/components/app/AppObjectHeader";


    export default {
        name: "unit-headline-form",
        props: {
            unitId: {
                type: Number,
                default: null
            },
            distributionId:{
                type: Number,
                default: null
            },
            mode:{
                type: String,
                default: null
            }
        },
        mixins: [getErrorMessage],
        data() {
            return {
                name: null,
                translations: {},
                distributions: [],
                unitHeadline: null,
                edit: false,
                list: [],
                fields: [
                    {key: 'name', label: this.$t('NAME')},
                    {key: 'edit', label: "", class: 'text-right'}
                ],
                mappingFields: [],
                savingHeadline: false,
                savingHeadlineMapping: false,
                C_PROPERTY_CM_PM_UNIT_NEW_HEADLINE_V,
                C_PROPERTY_CM_PM_UNIT_ASSIGN_HEADLINE_V,
                C_PROPERTY_CM_PM_UNIT_HEADLINE_TRANSLATE,
                enableTranslationList: false,
                languageListLoading: false,
                languageList: [],
                activeHeadline: null,
                headlineTranslationsPanelState: false,
            }
        },
        components: {
            AppObjectHeader,
            HeadlineTranslationsForm,
            AppAside,
            AppTranslationList,
            AppSelect,
            AppButton,
            HeadlineSubmit,
            NewHeadlineButton
        },
        computed: {
            disabledSave() {
                return this.name === "" || this.name === null
            },
            hasPermission() {
                return this.$store.getters['user/getPermission']
            },
            displayTranslationList() {
                return this.enableTranslationList && this.languageList.length > 0
            },
        },
        methods: {
            updateMapping(value) {
                if (value === this.unitHeadline) {
                    this.unitHeadline = false
                    // this.activeHeadline = null
                } else {
                    this.unitHeadline = value
                    // this.setActiveHeadline()
                }
            },
            newHeadline() {
                let object = {
                    object_id: this.unitId,
                    type_id: OBJECT_TYPE_UNIT,
                    name: this.name,
                    distribution: this.distributionId
                }

                if (this.translations !== null && Object.keys(this.translations).length > 0) {
                    let requestFieldName = 'headline_translations'
                    for (const [lang, value] of Object.entries(this.translations)) {
                        if (value === null || typeof value !== 'string' || value.trim() === '') {
                            continue
                        }
                        if (!object.hasOwnProperty(requestFieldName)) {
                            object[requestFieldName] = {}
                        }
                        object[requestFieldName][lang] = value
                    }
                }

                this.savingHeadline = true
                createHeadline(object).then(response => {
                        this.list = response.data
                        notifySuccess()
                        this.enableTranslationList = false
                        this.headlineMapping()
                        this.$nextTick(() => {
                            this.setActiveHeadline()
                        })
                    }, error => {
                        this.showErrorMessages(error)
                    }
                ).finally(() => this.savingHeadline = false)
            },
            saveHeadlineMapping() {
                if (this.edit) {
                    this.updateHeadlineMapping()
                } else {
                    this.createHeadlineMapping()
                }
            },
            createHeadlineMapping() {
                let post = {
                    object_id: this.unitId,
                    type: OBJECT_TYPE_UNIT,
                    headline: this.unitHeadline,
                    distribution: this.distributionId
                }
                this.savingHeadlineMapping = true
                createHeadlineMapping(post)
                    .then(() => {
                            notifySuccess()
                            this.fetchList()
                        }, error => {
                            this.showErrorMessages(error)
                        }
                    ).finally(() => this.savingHeadlineMapping = false)
            },
            updateHeadlineMapping() {
                let post = {
                    object_id: this.unitId,
                    type: OBJECT_TYPE_UNIT,
                    headline: this.unitHeadline,
                    distribution: this.distributionId
                }
                this.savingHeadlineMapping = true
                updateHeadlineMapping(post)
                    .then(() => {
                            notifySuccess()
                            this.fetchList()
                        }, error => {
                            this.showErrorMessages(error)
                        }
                    ).finally(() => this.savingHeadlineMapping = false)
            },
            headlineMapping() {
                if (this.list.length > 0) {
                    for (let headline of this.list) {
                        if (headline.type_id === OBJECT_TYPE_UNIT && headline.object_id === this.unitId) {
                            if (headline.mapping.length > 0) {
                                for (let mapping of headline.mapping) {
                                    if (mapping.distribution === this.distributionId) {
                                        this.unitHeadline = headline.id
                                        this.edit = true
                                    }
                                }
                            }

                        }
                    }
                }
            },
            fetchList() {
                getHeadlineList(this.unitId, OBJECT_TYPE_UNIT)
                    .then(response => {
                        this.list = response.data
                    }).catch(() => {
                    this.list = []
                }).then(() => {
                    this.headlineMapping()
                    // this.setLanguageListFromHeadlineList()
                    this.$nextTick(() => {
                        this.setActiveHeadline()
                    })
                })
            },
            handleHeadlineEntry() {
                this.enableTranslationList = true
            },
            setTranslations(translations) {
                if (translations !== null && typeof translations === 'object') {
                    for (const [lang, value] of Object.entries(translations)) {
                        this.$set(this.translations, lang, value)
                    }
                }
            },
            setLanguageListFromHeadlineList() {
                if (this.list.length > 0) {
                    for (let headline of this.list) {
                        if (headline.type_id === OBJECT_TYPE_UNIT && headline.object_id === this.unitId) {
                            if (headline.hasOwnProperty('translations') && headline.translations !== null) {
                                let languageList = Object.keys(headline.translations)
                                for (let language of languageList) {
                                    if (language === DEFAULT_LANGUAGE) {
                                        continue
                                    }
                                    let index = this.languageList.findIndex(el => el === language)
                                    if (index < 0) {
                                        this.languageList.push(language)
                                    }
                                }
                            }
                        }
                    }
                }
            },
            editTranslatedTexts() {
                this.headlineTranslationsPanelState = true
            },
            updateHeadlineTranslations() {
                this.fetchList()
                this.headlineTranslationsPanelState = false
            },
            setActiveHeadline() {
                let index = this.list.findIndex(headline => headline.id === this.unitHeadline)
                if (index >= 0) {
                    this.activeHeadline = this.list[index]
                    return
                }

                this.activeHeadline = null
            },
            setTranslationList() {
                this.languageListLoading = true
                getTranslateList().then(response => {
                    this.languageList = response.data
                }).finally(() => {
                    this.languageListLoading = false
                })
            },
        },
        created() {
            this.fetchList()
            this.setTranslationList()
            // this.headlineMapping()
        },
        mounted() {
            EventBus.$on("SaveWithoutSaveButton", () => {
                this.saveHeadlineMapping()
            })
        }
    }
</script>

<style scoped>

</style>
