<template>
    <b-form @submit.prevent>
        <form-wizard ref="wizard_activation_unit_on_distribution" :startIndex="currentIndex" transition="fade-in"
                     color=var(--color-primary) errorColor=var(--color-warning)
                     name="distributionUnitActivationWizard" title="" subtitle=""
                     @on-validate="handleNextStep"
                     @on-complete="activate">

            <tab-content name="object" :title="$t('TYPE')">
                <b-row>
                    <b-col class="d-flex justify-content-center">
                        <b-form-group>
                            <div class="header-2 mb-3">

                                {{$t('CONNECTING_EXISTING_UNIT')}} ?
                            </div>
                            <b-form-radio-group
                                :disabled="disabled_object_exist"
                                v-model="object_exist"
                                :options="object_exist_options"
                                id="radios-object_exist"
                                name="radios-object_exist"
                                stacked
                                class="main_calendar-custom-radio text-center">
                            </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </tab-content>
            <tab-content name="connection_type" :title="$t('SETUP')" :before-change="() => validate('setup')"
                         v-if="distribution_id == AIRBNB">
                <b-row>
                    <b-col cols="4">
                        <app-input-control :error-object="validationErrorObject.setup.connection_type">
                            <template v-slot:input="data">
                                <app-select :state="data.state" :label="$t('CONNECTION_TYPE')" v-model="connection_type"
                                            :options="setup_parameters"></app-select>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col cols="4" v-if="Number(company_id) === DIRECT_BOOKER_COMPANY">
                        <app-select :label="$t('SUPPORT_EXTENDED_LOS')" text-field="text" value-field="value" v-model="extended_los_enabled" :options="extended_los_enabled_options"></app-select>
                    </b-col>
                </b-row>

            </tab-content>
            <tab-content v-if="!object_exist" name="create" :title="$t('CREATE')"
                         :before-change="() => validate('create_unit')">
                <b-row>
                    <b-col class="d-flex justify-content-center">
                        <b-form-group>
                            <div class="header-2 mb-3">
                                {{$t('CREATE_UNIT')}} ?
                            </div>
                            <b-form-radio-group
                                v-model="create_unit"
                                :options="create_unit_options"
                                id="radios-create-unit"
                                name="radios-create-unit"
                                stacked
                                class="main_calendar-custom-radio text-center">
                            </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="create_unit">
                    <b-col cols="4">
                        <b-form-group>
                            <label>{{$t('HEADLINE')}}</label>
                            <b-input v-model="unit_headline"></b-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </tab-content>
            <tab-content v-else-if="object_exist" name="mapping" :title="$t('MAPPING')"
                         :before-change="() => validate('mapping')">
                <b-col cols="4" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.mapping.unit_eid">
                        <template v-slot:input="data">
                            <app-select :state="data.state" :label="$t('UNITS')" v-model="unit_eid"
                                        :options="external_unit_list"></app-select>
                        </template>
                    </app-input-control>
                </b-col>

                    <b-col  v-if="unit_eid && distribution_id !== AIRBNB">
                        <b-table responsive   :items="rate_plan_list" :fields="rate_plan_fields">
                            <template v-slot:mapping="data">
                                    <app-select :options="external_rate_plans" :value="rate_plan_mapping[data.index].rate_plan_eid"
                                                @input="updateRatePlanMapping(data.index,$event)">
                                    </app-select>
                            </template>
                        </b-table>
                    </b-col>

            </tab-content>
            <tab-content v-if="object_exist && distribution_id === BOOKING && licence !== null"
                         name="licences" :title="$t('REQUIRED_LICENCES')">
                <BookingLicenceForm :licence="licence">
                </BookingLicenceForm>
            </tab-content>
            <tab-content v-if="object_exist && distribution_id == AIRBNB  && hasPermissionPullReservation"   name="additional" :title="$t('ADDITIONAL_OPTIONS')">

                <div>
                    <b-row class="d-flex justify-content-center">
                        <b-form-group >
                            <b-form-checkbox  v-model="pull_previous_reservations"
                                              :value="1"
                                              :unchecked-value="0" class=" pr-3 mr-3">{{$t("IMPORT_FUTURE_RESERVATIONS")}}
                            </b-form-checkbox>
                        </b-form-group>
                    </b-row>
                    <b-row class="d-flex justify-content-center" >
                        <b-col lg="8" class="d-flex align-items-center disclaimer-text">
                            <span class="text-center" v-html="$t('PULL_PREVIOUS_RESERVATIONS_DISCLAIMER_TEXT')"></span>
                        </b-col>
                    </b-row>

                </div>
            </tab-content>
            <button class="btn btn-outline-primary" slot="prev">{{$t('PREVIOUS')}}</button>

            <button class="btn btn-primary" slot="next" :disabled="loader">
                <i v-if="loader "
                   class="mr-1 fa fa-spinner fa-spin fa-fw"
                   aria-hidden="true">
                </i>
                {{$t('NEXT')}}
            </button>
            <button class="btn btn-primary" :disabled="loader" slot="finish">
                <i v-if="loader "
                   class="mr-1 fa fa-spinner fa-spin fa-fw"
                   aria-hidden="true">
                </i>
                {{$t('FINISH')}}
            </button>
        </form-wizard>
    </b-form>
</template>

<script>
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import {
        getDistributionSetupParameter,
        getChannelUnitList,
        getChannelRatePlanList, getBookingLicences
    } from "@/services/channel_manager/index";
    import {getPricingRatePlanList} from "@/services/pricing";
    import ConfirmationDialog from '@/components/app/form/AppConfirmationDialog'
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppInputControl from "@/components/app/AppInputControl";
    import {validationActivateUnit} from "@/services/channel_manager";
    import {CM_CONNECTION_TYPE_VALIDATION_FAIL, CM_MAPPING_UNIT_EID_VALIDATION_FAIL} from "@/shared/error_codes";
    import {EventBus, V_ERROR, GE_LOADER_SHOW, GE_LOADER_HIDE} from "@/shared/EventBus";
    import {CONNECTION_TYPE, AIRBNB,DIRECT_BOOKER_COMPANY, BOOKING} from "@/shared/constants";
    import {F_CM_PULL_UNIT_RESERVATIONS} from "@/shared/component_permission";
    import BookingLicenceForm from "@/components/channel_manager/wizard/DistributionActivationWizardBookingLicenceForm";


    export default {
        name: "distribution-unit-activation-wizard",
        data() {
            return {
                AIRBNB,DIRECT_BOOKER_COMPANY,
                BOOKING,
                loader: false,
                findPropertyEid: false,
                next_pricing_type: false,
                currentIndex: 0,
                object_exist: 0,
                object_exist_options: [
                    {value: 1, text: this.$t('YES')},
                    {value: 0, text: this.$t('NO')}
                ],
                create_unit_options: [
                    {value: 1, text: this.$t('YES')},
                    {value: 0, text: this.$t('NO')}
                ],
                extended_los_enabled_options:[
                    {value: 1, text: this.$t('YES')},
                    {value: 0, text: this.$t('NO')}
                ],
                create_unit: 0,
                connection_type: null,
                setup_parameters: [],
                external_unit_list: [],
                rate_plan_list: [],
                rate_plan_fields: [
                    {key: 'id'},
                    {key: 'name', label:this.$t('NAME')},
                    {key: 'mapping', label:this.$t('MAPPING'), tdClass:'mb-0 mt-0 pb-2 pt-2 w-50 '}

                ],
                unit_eid: null,
                unit_headline: null,
                disabled_object_exist: true,
                external_rate_plans: [],
                rate_plan_mapping: [],
                pull_previous_reservations:null,
                extended_los_enabled:0,
                validationErrorObject: {
                    setup: {
                        connection_type: {
                            input: [CM_CONNECTION_TYPE_VALIDATION_FAIL]
                        },
                    },
                    mapping: {
                        unit_eid: {
                            input: [CM_MAPPING_UNIT_EID_VALIDATION_FAIL]
                        }

                    },
                },
                licence: null,
            }
        },
        props: {
            unit: {
                type: Object
            },
            distribution_id: {
                type: Number
            },
            property: {
                type: Object
            }
        },
        components: {
            BookingLicenceForm,
            AppInputControl,
            AppButton,
            AppSelect, FormWizard, TabContent, ConfirmationDialog
        },
        computed: {
            company_id() {
                return this.$store.getters['user/getCompany']
            },
            hasPermissionPullReservation(){
                return this.$store.getters['user/getPermission'](F_CM_PULL_UNIT_RESERVATIONS)
            }
        },
        methods: {
            updateRatePlanMapping(index, value) {
                let mappingObject = this.getRatePlanExternalById(value)
                this.rate_plan_mapping[index].rate_plan_eid = value
                this.rate_plan_mapping[index].external_name = mappingObject.hasOwnProperty('external_name') ? mappingObject.external_name : null
            },
            getRatePlanExternalById(id) {

                let object = this.external_rate_plans.find((el) => Number(el.id) === Number(id))
                if (object) {
                    return object
                }
                return {}

            },
            setChannelUnitList(distribution_id) {
                EventBus.$emit(GE_LOADER_SHOW)
                this.external_unit_list = []
                    getChannelUnitList({
                    distribution: distribution_id,
                    property: this.property.id
                }).then(response => {

                    if (response.data.length > 0) {
                        response.data.forEach(item => {
                            let responseObject = {
                                id: item.id,
                                name: item.name + ' (' + item.id + ')'
                            }
                            this.external_unit_list.push(responseObject)
                        })
                        this.disabled_object_exist = false
                    }
                }, () => {
                    this.external_unit_list = []
                }).finally(()=>{
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            },
            setDistributionSetupParameters(distribution_id) {

                getDistributionSetupParameter({
                    distribution: distribution_id,
                    company: this.company_id
                }).then(response => {
                    if (response.data.length > 0) {
                        response.data.forEach(el => {

                            if (el.id === CONNECTION_TYPE) {
                                this.setup_parameters = el.allowed
                            }
                        })
                    }
                })
            },

            handleNextStep(isValid, tabIndex) {
                if (!isValid) {
                    return
                }
                EventBus.$emit(V_ERROR, [])
                this.currentIndex = tabIndex++
            },
            prepareRequest() {
                let requestObject = {
                    "distribution": this.distribution_id,
                    "unit": this.unit.id,
                    "connection_type": this.connection_type,
                    "unit_eid": this.unit_eid,
                    "existing_unit": this.object_exist,
                    "create_unit": this.create_unit,
                    "property": this.property.id,
                    "unit_headline": this.unit_headline,
                    "rate_plan_mapping": [],
                    "pull_previous_reservations":this.pull_previous_reservations
                }
                if(Number(this.company_id) === DIRECT_BOOKER_COMPANY){
                    requestObject.extended_los_enabled = this.extended_los_enabled
                }
                if (this.rate_plan_mapping.length > 0) {
                    this.rate_plan_mapping.forEach(item => {
                        if (item.hasOwnProperty('rate_plan_eid') && item.rate_plan_eid !== null){
                            requestObject.rate_plan_mapping.push(item)
                        }
                    })
                }
                return requestObject
            },
            activate() {
                this.$emit('activate', this.prepareRequest())
            },
            validate(step) {
                return validationActivateUnit(this.prepareRequest()).then(() => {
                    return true
                }, error => {
                    if (this.validationErrorObject.hasOwnProperty(step)) {
                        return this.serverStepValidation(this.validationErrorObject[step], error.response.data.error_list)
                    }
                })
            },
            serverStepValidation(stepObject, errorList) {

                if (Array.isArray(errorList) && errorList.length) {
                    const serverErrorCodes = errorList.map(list => Number(list.code))
                    for (const [filedName, errorObject] of Object.entries(stepObject)) {
                        for (const [type, typeErrors] of Object.entries(errorObject)) {
                            for (let error of typeErrors) {
                                if (serverErrorCodes.includes(error.code)) {
                                    return false
                                }
                            }
                        }
                    }
                }
                return true
            },
            getUnitRatePlansByDistribution() {
                getPricingRatePlanList({
                    unit: Number(this.unit.id),
                    distribution: Number(this.distribution_id),
                    limit: 1000
                })
                    .then(response => {
                            this.rate_plan_list = response.data.items
                            if (this.rate_plan_list.length > 0) {
                                this.rate_plan_list.forEach(item => {
                                    this.rate_plan_mapping.push({
                                        rate_plan: item.id,
                                        rate_plan_eid: null,
                                        external_name: null
                                    })
                                })
                            }

                        },
                        () => {
                            this.rate_plan_list = []
                            this.rate_plan_mapping = []
                        })
            },
            getChannelUnitRatePlan(unit_eid) {
                EventBus.$emit(GE_LOADER_SHOW)
                getChannelRatePlanList({
                    distribution: this.distribution_id,
                    property: Number(this.property.id),
                    unit: Number(this.unit.id),
                    unit_eid
                }).then(response => {
                    this.external_rate_plans = []
                    if (response.data.length > 0) {

                        for (let item of response.data) {
                            let object = {
                                id: item.id,
                                name: item.name + ' (' + item.id + ')',
                                external_name: item.name
                            }
                            this.external_rate_plans.push(object)
                        }
                    }
                }).finally(() => {
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            },
            getLicencesInformationForBooking()
            {
                let requestObject = {
                    property: this.property.id,
                }
                let unit_eid_list = []
                if (this.unit_eid) {
                    unit_eid_list.push(this.unit_eid)
                    requestObject.unit_eid_list = unit_eid_list
                }
                getBookingLicences(requestObject).then(response => {
                    this.licence = response.data
                    if (this.licence !== null && typeof this.licence === 'object' && this.licence.hasOwnProperty('level') && this.licence.level !== 'room') {
                        this.licence = null
                    }
                }, () => {
                    this.licence = null
                })
            }
        },
        watch: {

            distribution_id: {
                handler(value) {
                    if (Number(value) === AIRBNB) {
                        this.setDistributionSetupParameters(value)
                    }
                    this.setChannelUnitList(value)
                },
                immediate: true
            },
            unit_eid:{
                handler(value){
                    this.getChannelUnitRatePlan(value)
                    if (this.distribution_id === BOOKING) {
                        this.getLicencesInformationForBooking()
                    }
                }
            },
            object_exist: {
                handler(value) {
                    this.unit_eid = null
                    this.statusObject = {}
                    this.mapping_exist = false
                    if (value && Number(this.distribution_id) !== AIRBNB) {
                        if (this.rate_plan_list.length === 0) {
                            this.getUnitRatePlansByDistribution()
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
