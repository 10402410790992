<template>
    <div>
        <app-table-v2
            :provider="getData" :fields="fields"
            :show-limit="false"
            :search-on-create="true"
            @fetched="fetchedData"
            :showSearchMessages="false"
            search-event="reloadWebsiteCategoryTable">

            <template v-slot:edit="data">
                <app-button
                    variant="link"
                    button_type="edit"
                    :show_text="false"
                    @click="open(data)">
                </app-button>
            </template>
        </app-table-v2>
        <app-aside v-model="openAside" :widths="['col-lg-4','col-sm-12', '40%']">
            <template slot="header">
                <app-object-header
                    :label="$t('CATEGORY')"
                    :name="label">
                </app-object-header>
            </template>
            <website-amenity-category :websiteId="websiteId" :category_id="category_id"
                                      @saved="saved" @deletedCategory="lastDeleted"></website-amenity-category>
        </app-aside>
    </div>
</template>

<script>
    import AppTableV2 from "@/components/app/AppTableV2";
    import {getAmenityCategories} from "@/services/direct_booking/website";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppObjectHeader from "@/components/app/AppObjectHeader";
    import AppAside from '@/components/app/form/AppAside';
    import WebsiteAmenityCategory from "@/components/direct_booking/website/amenities/WebsiteAmenityCategory";
    import AppNoData from "@/components/app/AppNoData";
    import {EventBus} from "@/shared/EventBus";

    export default {
        name: "WebsiteAmenityCategoriesTable",
        components: {AppNoData, WebsiteAmenityCategory, AppObjectHeader, AppButton, AppTableV2, AppAside},
        props: {
            websiteId: {
                type: Number,
                default: null,
            },
            refresh: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                openAside: false,
                label: "",
                category_id: null,
                fields: [
                    {key: 'id', label: this.$t('ID'),},
                    {key: 'label', label: this.$t('NAME')},
                    {key: 'edit', label: '', class: 'text-right'},
                ],
            }
        },
        methods: {
            getData(filter) {
                let req = {
                    page: filter.currentPage,
                    perPage: filter.perPage,
                }
                let provider = getAmenityCategories(this.websiteId, req)
                provider.then(response => {
                        if(response.data.items.length){
                            this.$store.commit('website/setAmenityCategory', true)
                        }
                    })
                return provider
            },
            open(data) {
                this.openAside = true;
                this.label = data.item.label;
                this.category_id = data.item.id;
            },
            saved() {
                this.openAside = false;
                EventBus.$emit('reloadWebsiteCategoryTable')
            },
            fetchedData(data){
                if (data.length > 0){
                    this.$emit('noResultsInCategories', false)
                    this.$store.commit('website/setAmenityCategory', true)
                }else {
                    this.$emit('noResultsInCategories', true)
                }
            },
            lastDeleted(){
                this.getData().then(response => {
                    if (response.data.items < 1){
                        this.$store.commit('website/setAmenityCategory', false)
                    }
                })
            }
        },
    }
</script>

<style scoped>

</style>
