<template>
    <b-row>
        <b-col>
            <div class="d-flex justify-content-start align-items-center" v-if="loading">
                <b-spinner variant="primary"></b-spinner>
            </div>
            <div v-else>
                <template v-if="customPolicyText !== null && editDisabled">
                    <b-form-textarea :value="cancellationPolicyText"
                                     disabled
                                     rows="5"
                                     no-resize>
                    </b-form-textarea>
                </template>
                <template v-else-if="customPolicyText !== null && !editDisabled">
                    <app-input-control :error-object="validationErrorObject.customText">
                        <template v-slot:input="data">
                            <app-translation-input :languages="supportedLanguages"
                                                   v-model="cancellationPolicyText"
                                                   :lang_id="customPolicyText !== null ? customPolicyText.id : null"
                                                   component="textarea"
                                                   :sidebar-description="translationSidebarDescription"
                                                   :state="customPolicyTextareaState"
                                                   @input="policyTextInput">
                            </app-translation-input>
                        </template>
                    </app-input-control>
                    <template v-if="customPolicyTextState === false">
                        <small class="d-flex text-danger">
                            <slot>
                                <div v-html="updateCustomTextNotification"></div>
                            </slot>
                        </small>
                    </template>
                    <template v-if="policyErrors.length">
                        <small class="d-flex text-danger" v-for="error in policyErrors">
                            <slot>
                                <div v-html="error.message"></div>
                            </slot>
                        </small>
                    </template>
                </template>
                <template v-else-if="policyErrors.length">
                    <p class="text-danger" v-for="error in policyErrors">{{ error.message }}</p>
                </template>
                <template v-else>
                    <p>{{ policyText }}</p>
                </template>
            </div>
        </b-col>
    </b-row>
</template>

<script>
import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
import AppInputControl from "@/components/app/AppInputControl";
import {CANCELLATION_PREFERENCE_CUSTOM_TEXT_NOT_SET} from "@/shared/error_codes";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {DEFAULT_LANGUAGE} from "@/shared/constants";

export default {
    name: "CancellationPolicyText",
    components: {AppInputControl, AppTranslationInput},
    mixins: [getErrorMessage],
    props: {
        policyName: {
            type: String,
            default: null,
        },
        policyText: {
            type: String,
            default: '',
        },
        policyErrors: {
            type: Array,
            default() {
                return []
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
        customPolicyText: {
            type: Object,
            default: null,
        },
        editDisabled: {
            type: Boolean,
            default: false,
        },
        showUpdateTextMessage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            cancellationPolicyText: null,
            customPolicyTextState: null,
            updateCustomTextNotification: this.$t('CANCELLATION_CONDITIONS_CHANGED_UPDATE_CUSTOM_TEXT'),
            validationErrorObject: {
                customText: {
                    input: [CANCELLATION_PREFERENCE_CUSTOM_TEXT_NOT_SET]
                },
            },
        }
    },
    watch: {
        customPolicyText: {
            handler(value) {
                if (value && value.hasOwnProperty('translations') && value.translations.hasOwnProperty(DEFAULT_LANGUAGE)) {
                    this.cancellationPolicyText = value.translations[DEFAULT_LANGUAGE]
                } else {
                    this.cancellationPolicyText = null
                    this.customPolicyTextState = null
                }
            },
            immediate: true,
            deep: true,
        },
        showUpdateTextMessage: {
            handler(value) {
                if (value) {
                    this.customPolicyTextState = false
                } else {
                    this.customPolicyTextState = null
                }
            },
            immediate: true,
        },
    },
    computed: {
        supportedLanguages() {
            let supportedLanguages = []
            if (this.customPolicyText !== null
                && this.customPolicyText.hasOwnProperty('translations')) {
                let translationList = this.customPolicyText.translations
                Object.keys(translationList).forEach((language) => {
                    if (language !== DEFAULT_LANGUAGE) {
                        supportedLanguages.push(language)
                    }
                })
            }
            return supportedLanguages
        },
        translationSidebarDescription() {
            return this.policyName !== null ? this.policyName : ''
        },
        customPolicyTextareaState() {
            return this.customPolicyTextState === false || this.policyErrors.length > 0 ? false : null
        },
    },
    methods: {
        policyTextInput() {
            this.customPolicyTextState = null
            this.$emit('customTextChanged')
        }
    },
}
</script>

<style scoped>

</style>
