<template>
    <b-card body-class="d-flex">
        <div class="d-flex flex-grow-1 align-items-start flex-column">
            <span class="header-2 mb-5">{{$t("SHORTCUTS")}}</span>
            <div class="d-flex flex-grow-1 flex-wrap align-items-end">

                <app-button
                    @AccessControlEvent="addToAccessControlCounter()"
                    :access_control_context="{company: this.company.id, function:C_OWNER_NEW_V, key: AC_PROPERTY_OVERVIEW}"
                    button_type="new" @click="ownerAside=true" class="mr-3 mt-3">{{$t("NEW_OWNER")}}
                </app-button>
                <app-button @AccessControlEvent="addToAccessControlCounter()"
                            :access_control_context="{function:C_UNIT_NEW_V, key: AC_PROPERTY_OVERVIEW}"  class="mr-3  mt-3" button_type="new" @click="unitAside=true">
                    {{ $t('NEW_UNIT') }}
                </app-button>
                <app-button v-if="checkPermission(C_RESERVATION_ACCOMMODATION_NEW)"  class="mt-3" button_type="new" variant="primary" @click="resAside=true">{{
                    $t("NEW_RESERVATION") }}
                </app-button>
            </div>

        </div>

        <app-aside :widths="['col-sm-12', '40%']" v-model="ownerAside">
            <template slot="header">{{$t('NEW_OWNER')}}</template>
            <owner-wizard :property-id="propertyId"></owner-wizard>
        </app-aside>
        <app-aside :widths="['col-sm-12', '40%']" v-model="unitAside">
            <template slot="header">{{$t('NEW_UNIT')}}</template>
            <unit-wizard :property="property"></unit-wizard>
        </app-aside>
        <app-aside v-model="resAside">
            <template slot="header">
                {{$t('NEW_RESERVATION')}}
            </template>
            <new-accommodation-reservation-form @created="()=>resAside=false" :property-id="propertyId">
            </new-accommodation-reservation-form>
        </app-aside>

    </b-card>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import OwnerWizard from "@/components/owner/new/OwnerWizard";
    import AppAside from "@/components/app/form/AppAside";
    import routeNames from '@/router/routeNames'
    import UnitWizard from "@/components/unit/new/UnitWizard";
    import {C_OWNER_NEW_V, C_RESERVATION_ACCOMMODATION_NEW, C_UNIT_NEW_V} from '@/shared/component_permission'
    import {AC_PROPERTY_OVERVIEW} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import NewAccommodationReservationForm
        from "@/components/reservation/accommodation/forms/NewAccommodationReservationForm";

    export default {
        name: "PropertyButtonGrid",
        mixins: [AccessControlComponent],
        components: {NewAccommodationReservationForm, UnitWizard, OwnerWizard, AppAside, AppButton},
        props: {
            propertyId: {
                type: Number
            }
        },
        data() {
            return {
                access_control_components:2,
                ownerAside: false,
                unitAside: false,
                resAside: false,
                routeNames,
                C_OWNER_NEW_V,
                AC_PROPERTY_OVERVIEW,
                C_RESERVATION_ACCOMMODATION_NEW,
                C_UNIT_NEW_V
            }
        },
        computed: {
            property() {
                return this.$store.getters['property/getProperty']
            },
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
        }

    }
</script>

<style>

</style>
