import http from '../http'
import {
    CM_AVAILABILITY_SYNC,
    CM_PRICING_SYNC,
    CM_RESTRICTION_SYNC,
    CM_ROOM_STATUS_SYNC,
    UNIT_URL
} from "@/services/endpoints";


export function syncAvailiability(data) {
    return http.request({
        url: CM_AVAILABILITY_SYNC,
        method: 'post',
        data: data
    })
}

export function syncPrices(data) {
    return http.request({
        url: CM_PRICING_SYNC,
        method: 'post',
        data: data
    })
}

export function syncRestrictions(data) {
    return http.request({
        url: CM_RESTRICTION_SYNC,
        method: 'post',
        data: data
    })
}

export function syncRoomStatus(data) {
    return http.request({
        url: CM_ROOM_STATUS_SYNC,
        method: 'post',
        data: data
    })
}
export function syncCalendar(data) {
    return http.request({
        url: UNIT_URL + '/sync-calendar',
        method: 'post',
        data: data
    })
}
