<template>
    <b-form @submit.prevent="save">
        <b-row>
            <b-col cols="12" class="mb-4">
                <label>{{$t("CHANNEL")}}</label>
                <app-select v-model="form.channel" :options="channelList" text-field="label"></app-select>
            </b-col>
            <b-col cols="12" class="mb-4">
                <label>{{$t("DESCRIPTION")}}</label>
                <b-input v-model="form.description"></b-input>
            </b-col>
            <b-col cols="12" class="mb-4">
                <label>{{contactLabel}}</label>
                <b-input v-model="form.contact"></b-input>
            </b-col>
            <b-col cols="12" class="d-flex align-items-end justify-content-between">
                <app-button-submit :loading="saving"></app-button-submit>
                <template v-if="contact !== null && contact.hasOwnProperty('id')">
                    <app-button-delete @click="deleteDialogState=true"></app-button-delete>
                    <app-confirmation-dialog
                        :show="deleteDialogState"
                        @confirm="deleteItem"
                        @cancel="deleteDialogState=false"
                        :delete_title="true"
                    ></app-confirmation-dialog>
                </template>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {createContact, updateContact, deleteContact} from "@/services/contacts/index";
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";

    export default {
        name: "AppContactEdit",
        components: {AppConfirmationDialog, AppButtonDelete, AppButtonSubmit, AppSelect},
        mixins: [getErrorMessage],
        props: {
            contact: {
                type: Object
            },
            object: {
                type: Number
            },
            objectType: {
                type: Number
            },
            channelList: {
                type: Array
            }
        },
        data() {
            return {
                form: {
                    channel: null,
                    description: null,
                    contact: null,
                },
                deleteDialogState: false,
                saving: false
            }
        },
        computed:{
            contactLabel(){
                if(this.form.channel){
                    return this.channelList.find(item =>
                        item.id === this.form.channel
                    ).label
                }
                return this.$t("CONTACT")
            }
        },
        methods: {
            deleteItem(){
                deleteContact(this.contact.id).then(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.DELETE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.DELETE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.$emit('reload')
                }, () => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.DELETE_ERROR.TITLE'),
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 2000
                    })
                })
            },
            save() {
                if (this.contact !== null && this.contact.hasOwnProperty('id')) {
                    this.update()
                } else {
                    this.new()
                }
            },
            new() {
                let request = {
                    "object": this.object,
                    "object_type": this.objectType,
                    "channel": this.form.channel,
                    "description": this.form.description,
                    "contact_value": this.form.contact,
                }

                this.saving = true
                createContact(request).then(response => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.$emit('reload', response.data.contacts[0])
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(() => this.saving = false)
            },
            update() {
                let request = {
                    "object": this.object,
                    "object_type": this.objectType,
                    "channel": this.form.channel,
                    "description": this.form.description,
                    "contact_value": this.form.contact,
                }

                this.saving = true
                updateContact(this.contact.id, request).then(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.$emit('reload')
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(() => this.saving = false)
            }
        },
        watch: {
            contact: {
                handler(object) {
                    if (object !== null) {
                        this.form.channel = object.channel ? object.channel : null
                        this.form.description = object.description ? object.description : null
                        this.form.contact = object.contact ? object.contact : null
                    }
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
