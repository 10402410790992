<template>
    <b-form @submit.prevent="save">

        <b-row>
            <b-col class="mb-3" md="4" lg="2">
                <label>{{$t("ROUTE")}}</label>
                <app-select mode="search" :search="searchRoute" @input="clearAll" text-field="name"
                            v-model="formData.route"
                            :search-empty-item="true"
                            :search-clear="true"></app-select>
            </b-col>


            <b-col class="mb-3" md="4" lg="2">
                <label>{{$t("COUNTRY")}}</label>
                <app-select :disabled="!countryList.length" :options="countryList" text-field="name" :search-input="true" :search-empty-item="true"
                            v-model="formData.country.id"></app-select>

            </b-col>
            <b-col class="mb-3" md="4" lg="2">
                <label>{{$t("REGION")}}</label>
                <app-select :disabled="!regionList.length" :options="regionList" text-field="name" :search-input="true" :search-empty-item="true"
                            v-model="formData.region.id"></app-select>
            </b-col>
            <b-col class="mb-3" md="4" lg="2">
                <label>{{$t("LOCATION")}}</label>

                <app-select :disabled="!locationList.length" :options="locationList" text-field="name" :search-input="true" :search-empty-item="true"
                            v-model="formData.location.id"></app-select>
            </b-col>

            <b-col class="mb-3" md="4" lg="2">
                <label>{{$t("DRIVER")}}</label>
                <app-select :disabled="!formData.region.id" :search-input="true" v-model="formData.driver.id" :options="driverList" :search-empty-item="true"
                            text-field="name"></app-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-submit :loading="loading" :disabled="saveDisabled"></app-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import FormHeader from "@/components/app/form/FormHeader";
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {cloneDeep, has} from 'lodash'
    import {getRouteList, getDriverList, getPropertyMapping, setPropertyMapping} from "@/services/transfer";
    import {notifySuccess} from '@/shared/plugins/toastr'

    export default {
        name: "Index",
        components: {AppSubmit, FormHeader, AppSelect},
        data() {
            return {
                formData: {
                    country: {id: null, name: null},
                    region: {id: null, name: null},
                    location: {id: null, name: null},
                    route: {id: null, name: null},
                    driver: {id: null, name: null},
                },
                countryList: [],
                regionList: [],
                locationList: [],
                driverList: [],
                loading: false
            }
        },
        computed: {
            saveDisabled() {
                if (
                    this.formData.country.id &&
                    this.formData.region.id &&
                    this.formData.location.id &&
                    this.formData.route.id &&
                    this.formData.driver.id
                ) {
                    return false
                }

                if (
                    !this.formData.country.id &&
                    !this.formData.region.id &&
                    !this.formData.location.id &&
                    !this.formData.route.id &&
                    !this.formData.driver.id
                ) {
                    return false
                }

                return true
            }
        },
        methods: {
            save() {
                this.loading = true
                setPropertyMapping(this.$route.params.id, this.formData).then(response => {
                    this.setPropertyMapping(response)

                    notifySuccess()
                }).finally(() => {
                    this.loading = false
                })
            },
            clearAll(val) {

                if (has(val, 'id') && val.id === null) {
                    let resetValue = {
                        id: null,
                        name: null
                    }
                    this.formData.country = cloneDeep(resetValue)
                    this.formData.region = cloneDeep(resetValue)
                    this.formData.driver = cloneDeep(resetValue)
                    this.formData.location = cloneDeep(resetValue)
                    this.countryList = []
                    this.regionList = []
                    this.driverList = []
                    this.locationList = []
                }
            },
            setPropertyMapping(response) {
                this.countryList = []
                this.regionList = []
                this.driverList = []
                this.locationList = []
                if (has(response.data, 'country')) {
                    this.formData.country = response.data.country
                    if (this.formData.country.id) {
                        this.countryList.push(cloneDeep(this.formData.country))
                    }

                }
                if (has(response.data, 'region')) {
                    this.formData.region = response.data.region
                    if (this.formData.region.id) {
                        this.regionList.push(cloneDeep(this.formData.region))
                    }

                }

                if (has(response.data, 'location')) {
                    this.formData.location = response.data.location
                    if (this.formData.location.id) {
                        this.locationList.push(cloneDeep(this.formData.location))
                    }
                }
                if (has(response.data, 'route')) {
                    this.formData.route = response.data.route
                }

                if (has(response.data, 'driver')) {
                    this.formData.driver = response.data.driver
                }
            },
            searchRoute(value) {
                return getRouteList({
                    name: value,
                    location: this.formData.location.id,
                    perPage: 10000
                }).then(response => {
                    return {data: response.data.items}
                })
            },
        },
        watch: {
            '$route.params.id': {
                handler(id) {
                    getPropertyMapping(id).then(response => {
                        this.setPropertyMapping(response)
                    })
                },
                immediate: true
            },
            'formData.route': {
                handler(val) {

                    if (has(val, 'countries')) {
                        this.countryList = cloneDeep(val.countries)
                        if (this.countryList.length && this.countryList[0].id) {
                            this.formData.country = cloneDeep(this.countryList[0])
                        }
                    }

                    if (has(val, 'regions')) {
                        this.regionList = cloneDeep(val.regions)
                        if (this.regionList.length && this.regionList[0].id) {
                            this.formData.region = cloneDeep(this.regionList[0])
                        }
                    }

                    if (has(val, 'locations')) {
                        this.locationList = cloneDeep(val.locations)
                        if (this.locationList.length && this.locationList[0].id) {
                            this.formData.location = cloneDeep(this.locationList[0])
                        }
                    }


                    if (has(val, 'drivers')) {
                        this.driverList = cloneDeep(val.drivers)
                        if (this.driverList.length && this.driverList[0].id) {
                            this.formData.driver = cloneDeep(this.driverList[0])
                        }
                    }

                    if (this.formData.region.id) {
                        getDriverList({region: this.formData.region.id, perPage: 10000}).then(response => {
                            this.driverList = response.data.items
                        })
                    }
                },
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>
