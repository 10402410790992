<template>
    <div>
        <form-wizard
            ref="wizard"
            :startIndex="0"
            transition="fade-in"
            color=var(--color-primary)
            errorColor=var(--color-warning)
            title=""
            subtitle=""
            @on-change="onStepChange"
            @on-complete="onComplete">
            <tab-content
                :title="$t('SOURCE')"
                :beforeChange="() => validateStep('source')">
                <import-property-wizard-source-form
                    ref="source"
                    :distribution="distribution"
                    :entity="entity"
                    :import-data="fetchedDataForStep.hasOwnProperty('source') ? fetchedDataForStep.source : null"
                    :loading="fetchingData"
                    @update="updateFormData"
                    @fetchData="fetchData($event, 'source')"
                    :listen-for-error="listenForError">
                </import-property-wizard-source-form>
            </tab-content>

            <tab-content
                :title="$t('UNITS')"
                :beforeChange="() => validateStep('units')">
                <import-property-wizard-units-form
                    :distribution="distributionId"
                    :import-data="fetchedDataForStep.hasOwnProperty('units') ? fetchedDataForStep.units : null"
                    :loading="fetchingData"
                    ref="units"
                    @update="updateFormData"
                    @fetchProperty="fetchProperty"
                    @unsetFetchedDataProperties="unsetFetchedDataProperties"
                    @updateFetchedDataForStep="setFetchedDataForStep"
                    :listen-for-error="listenForError">
                </import-property-wizard-units-form>
            </tab-content>

            <tab-content
                :title="$t('SELECT_DATA')"
                :beforeChange="() => validateStep('options')">
                <import-property-wizard-options-form
                    :distribution="distributionId"
                    :import-data="fetchedDataForStep.hasOwnProperty('options') ? fetchedDataForStep.options : null"
                    ref="options"
                    @update="updateFormData"
                    :listen-for-error="listenForError">
                </import-property-wizard-options-form>
            </tab-content>

            <tab-content
                :title="$t('MANDATORY_DATA_CHECK')"
                :beforeChange="() => validateStep('mandatory_data')">
                <import-property-wizard-mandatory-data-form
                    :distribution="distributionId"
                    :import-data="fetchedDataForStep.hasOwnProperty('mandatory_data') ? fetchedDataForStep.mandatory_data : null"
                    ref="mandatory_data"
                    @update="updateFormData"
                    :listen-for-error="listenForError">
                </import-property-wizard-mandatory-data-form>
            </tab-content>

            <button class="btn btn-outline-primary" slot="prev">{{ $t("PREVIOUS") }}</button>
            <button class="btn btn-primary" slot="next" :disabled="loading || !nextStepTransitionAllowed">
                <i v-if="loading"
                   class="mr-1 fa fa-spinner fa-spin fa-fw"
                   aria-hidden="true">
                </i>{{ $t("NEXT") }}
            </button>
            <button class="btn btn-primary" slot="finish" :disabled="loading">
                <i v-if="loading"
                   class="mr-1 fa fa-spinner fa-spin fa-fw"
                   aria-hidden="true">
                </i>
                {{ $t("FINISH") }}
            </button>
        </form-wizard>
    </div>
</template>

<script>
import {FormWizard, TabContent} from "vue-form-wizard";
import _isEqual from "lodash/isEqual";
import _cloneDeep from "lodash/cloneDeep";
import ImportPropertyWizardSourceForm from "@/components/property/import/ImportPropertyWizardSourceForm";
import {getProperty, importProperty, importPropertyFetchData, importPropertyValidate} from "@/services/property";
import {AIRBNB, BOOKING, LS_PRESETS} from "@/shared/constants";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import ImportPropertyWizardUnitsForm from "@/components/property/import/ImportPropertyWizardUnitsForm";
import ImportPropertyWizardOptionsForm from "@/components/property/import/ImportPropertyWizardOptionsForm";
import ImportPropertyWizardMandatoryDataForm from "@/components/property/import/ImportPropertyWizardMandatoryDataForm";
import {toast} from "@/shared/plugins/toastr";
import {EventBus, V_ERROR} from "@/shared/EventBus";
import {getPresets} from "@/services/user";

const WIZARD_STEP_LIST = [
    "source",
    "units",
    "options",
    "mandatory_data",
]

export default {
    name: "ImportPropertyWizard",
    mixins: [getErrorMessage],
    components: {
        ImportPropertyWizardMandatoryDataForm,
        ImportPropertyWizardOptionsForm,
        ImportPropertyWizardUnitsForm,
        ImportPropertyWizardSourceForm,
        FormWizard,
        TabContent,
    },
    data() {
        return {
            formData: {
                source: {},
                units: {},
                options: {},
                mandatory_data: {},
            },
            successfulStepFormData: {},
            fetchedData: null,
            loading: false,
            fetchingData: false,
            dataFetchedSuccessfully: false,
            fetchedDataForStep: {},
            listenForError: false,
        }
    },
    props: {
        distribution: {
            type: Number,
            default: null,
        },
        entity: {
            default: null,
        },
    },
    computed: {
        distributionId() {
            return this.distribution ? this.distribution : this.formData.source.hasOwnProperty("distribution") && this.formData.source.distribution
                ? this.formData.source.distribution : null
        },
        company() {
            return this.$store.getters["user/getCurrentCompany"]
        },
        nextStepTransitionAllowed() {
            return this.distributionId !== null && this.dataFetchedSuccessfully
        },
    },
    methods: {
        onComplete() {
            let request = {
                fetched_data: this.fetchedData,
                ...this.formData.source,
                ...this.formData.units,
                ...this.formData.options,
                ...this.formData.mandatory_data,
            }
            this.$set(request, "distribution", this.distributionId)
            this.$set(request, "company", this.company.id)

            importProperty(request).then(() => {
                toast({
                    "title": this.$t("NOTIFICATIONS.IMPORT_FROM_OTA.TITLE"),
                    "message": this.$t("NOTIFICATIONS.IMPORT_FROM_OTA.MESSAGE"),
                    "type": 'success',
                    "timeout": 3000
                })
                this.$emit("importProperty")
                getPresets().then(response => {
                    localStorage.setItem(LS_PRESETS, JSON.stringify(response.data))
                })
            }, (error) => {
                this.showErrorMessages(error)
            })
        },
        onStepChange(prevIndex, nextIndex) {
            if (prevIndex === 0 && nextIndex > -1 && this.distributionId === BOOKING && this.fetchedData && this.fetchedData.hasOwnProperty("connection_approved") && !this.fetchedData.connection_approved) {
                toast({
                    title: this.$t("NOTIFICATIONS.BOOKING_CONNECTION_WITH_BOOKER_TOOLS_PENDING.TITLE"),
                    message: this.$t("NOTIFICATIONS.BOOKING_CONNECTION_WITH_BOOKER_TOOLS_PENDING.MESSAGE"),
                    type: "warning",
                    timeout: null,
                    time_out_close: false
                })
            }
            if (nextIndex === -1) {
                this.$emit("close")
            }
        },
        validateStep(step) {
            if (this.successfulStepFormData.hasOwnProperty(step) && _isEqual(this.formData[step], this.successfulStepFormData[step])) {
                return true
            }

            if (!this.successfulStepFormData.hasOwnProperty(step)) {
                this.$set(this.successfulStepFormData, step, {})
            }

            this.loading = true
            this.listenForError = true
            let request = {
                step: step,
                ...this.formData[step]
            }
            this.$set(request, "company", this.company.id)
            if (!request.hasOwnProperty("distribution")) {
                this.$set(request, "distribution", this.distributionId)
            }
            return importPropertyValidate(request).then(() => {
                this.successfulStepFormData[step] = _cloneDeep(this.formData[step])
                return true
            }, (error) => {
                this.showErrorMessages(error, this.$refs[step].$data.validationObject)
                return false
            }).finally(() => {
                this.loading = false
                this.listenForError = false
            })
        },
        updateFormData(data) {
            this.formData = {...this.formData, ...data}
        },
        fetchData(payload, step, update = false) {
            let request = {...payload, ...{distribution: this.distributionId, company: this.company.id}}
            this.fetchingData = true
            this.listenForError = true
            importPropertyFetchData(request).then((response) => {
                EventBus.$emit(V_ERROR, [])
                if (!update) {
                    this.fetchedData = response.data
                    this.setDataFetchedSuccessfully()
                    this.setFetchedDataForStep()
                } else {
                    this.fetchedData = {...this.fetchedData, ...response.data}
                    let steps = []
                    for (const key of Object.keys(response.data)) {
                        if (key === "property") {
                            if (steps.indexOf("options") < 0) {
                                steps.push("options")
                            }
                            if (steps.indexOf("mandatory_data") < 0) {
                                steps.push("mandatory_data")
                            }
                        }
                        if (key === "rate_plan_list") {
                            if (steps.indexOf("options") < 0) {
                                steps.push("options")
                            }
                        }
                    }

                    this.setFetchedDataForStep({steps: steps})
                }
                this.$refs.wizard.tabs[WIZARD_STEP_LIST.indexOf(step)].validationError = null
            }, (error) => {
                EventBus.$emit(V_ERROR, error.response.data.error_list)
                this.showErrorMessages(error, this.$refs[step].$data.validationObject)
                this.$refs.wizard.tabs[WIZARD_STEP_LIST.indexOf(step)].validationError = 'error'
                this.fetchedData = null
                this.dataFetchedSuccessfully = false
                this.setFetchedDataForStep()
            }).finally(() => {
                this.fetchingData = false
                this.listenForError = false
            })
        },
        fetchProperty(payload) {
            this.fetchingData = true
            getProperty(payload.property).then((response) => {
                const owners = response.data.owners
                const owner = owners.length > 0 ? owners[0] : null
                const property = {
                    existing: true,
                    id: response.data.id,
                    name: response.data.name,
                    type: response.data.property_type.id,
                    categorization: response.data.categorization,
                    location: {
                        city: response.data.location.city,
                        region: response.data.location.region,
                        county: response.data.location.county,
                        country: response.data.location.country,
                        latitude: response.data.location.latitude,
                        longitude: response.data.location.longitude,
                        address: response.data.location.address,
                        address_number: response.data.location.address_no,
                        postal_code: response.data.location.postal_number,
                    },
                    owner: {
                        existing: owner !== null,
                        id: owner ? owner.id : null,
                        name: owner ? owner.name : null,
                        email: owner ? owner.email : null,
                    },
                    units_number: response.data.unit_count_list.total_count,
                }
                this.$set(this.fetchedData, "property", property)
                const steps = ["options", "mandatory_data"]
                this.setFetchedDataForStep({steps: steps})
            }, () => {

            }).finally(() => {
                this.fetchingData = false
            })
        },
        unsetFetchedDataProperties(payload) {
            if (typeof payload === "object") {
                if (payload.hasOwnProperty("properties")) {
                    if (payload.properties === null) {
                        this.fetchedData = null
                    } else if (Array.isArray(payload.properties)) {
                        payload.properties.forEach(property => {
                            property = property.toString()
                            if (this.fetchedData && this.fetchedData.hasOwnProperty(property)) {
                                this.$set(this.fetchedData, property, null)
                            }
                        })
                    }
                }
                if (payload.hasOwnProperty("update_steps_data")) {
                    this.setFetchedDataForStep(payload.update_steps_data)
                }
            }
        },
        setDataFetchedSuccessfully() {
            if (this.distributionId === AIRBNB) {
                this.dataFetchedSuccessfully = this.fetchedData.hasOwnProperty("host_connected") && this.fetchedData.host_connected
                return
            } else if (this.distributionId === BOOKING) {
                this.dataFetchedSuccessfully = this.fetchedData.hasOwnProperty("property_connected") && this.fetchedData.property_connected
                return
            }

            this.dataFetchedSuccessfully = false
        },
        setFetchedDataForStep(payload = null) {
            let excludeStepList = []
            let specificStepList = []
            if (payload && typeof payload === "object") {
                if (payload.hasOwnProperty("exclude_steps") && Array.isArray(payload.exclude_steps)) {
                    excludeStepList = payload.exclude_steps
                }
                if (payload.hasOwnProperty("steps") && Array.isArray(payload.steps)) {
                    specificStepList = payload.steps
                }
            }
            WIZARD_STEP_LIST.forEach(step => {
                if (
                    (specificStepList.length === 0 || specificStepList.indexOf(step) > -1) &&
                    (excludeStepList.length === 0 || excludeStepList.indexOf(step) < 0)
                ) {
                    this.$set(this.fetchedDataForStep, step, this.getImportDataForStep(step))
                }
            })
        },
        getImportDataForStep(step) {
            if (this.distributionId === null || !this.fetchedData || typeof this.fetchedData !== "object") {
                return null
            }

            switch (step) {
                case "source":
                    return this.getImportDataForStepSource()
                case "units":
                    return this.getImportDataForStepUnits()
                case "options":
                    return this.getImportDataForStepOptions()
                case "mandatory_data":
                    return this.getImportDataForStepMandatoryData()
                default:
                    return null
            }
        },
        getImportDataForStepSource() {
            let importData = {}
            if (this.distributionId === AIRBNB) {
                this.$set(importData, "host_connected", this.fetchedData.host_connected)
                this.$set(importData, "connect_data", this.fetchedData.connect_data)
            } else if (this.distributionId === BOOKING) {
                this.$set(importData, "existing_property", this.fetchedData.existing_property)
                this.$set(importData, "property_connected", this.fetchedData.property_connected)
            }

            const unitCount = this.fetchedData.hasOwnProperty("unit_list") && Array.isArray(this.fetchedData.unit_list)
                ? this.fetchedData.unit_list.length : 0
            this.$set(importData, "unit_count", unitCount)

            return importData
        },
        getImportDataForStepUnits() {
            let importData = {}
            let unitList = []
            if (this.fetchedData.hasOwnProperty("unit_list") && Array.isArray(this.fetchedData.unit_list) && this.fetchedData.unit_list.length > 0) {
                unitList = this.fetchedData.unit_list.map(unit => {
                    return {
                        external_id: unit.external_id,
                        name: unit.name,
                        display_name: unit.name + " (" + unit.external_id + ")",
                        existing: unit.existing
                    }
                })
            }
            this.$set(importData, "unit_list", unitList)

            if (this.distributionId === AIRBNB && this.formData.source.hasOwnProperty("host_id")) {
                this.$set(importData, "host_id", this.formData.source.host_id)
            }

            return importData
        },
        getImportDataForStepOptions() {
            let importData = {}
            let selectedUnits = this.formData.units.hasOwnProperty("selected_units") ? this.formData.units.selected_units : []
            let unitList = []
            if (this.fetchedData.hasOwnProperty("unit_list") && Array.isArray(this.fetchedData.unit_list) && this.fetchedData.unit_list.length > 0) {
                unitList = this.fetchedData.unit_list.map(unit => {
                    return {
                        external_id: unit.external_id,
                        name: unit.name + " (" + unit.external_id + ")",
                    }
                })
            }
            let ratePlanList = []
            if (this.fetchedData.hasOwnProperty("rate_plan_list") && Array.isArray(this.fetchedData.rate_plan_list) && this.fetchedData.rate_plan_list.length > 0) {
                this.fetchedData.rate_plan_list.forEach(ratePlan => {
                    let ratePlanUnitList = []
                    if (ratePlan.hasOwnProperty("unit_list") && ratePlan.unit_list && ratePlan.unit_list.length > 0) {
                        for (const unitEid of ratePlan.unit_list) {
                            if (selectedUnits.indexOf(unitEid) > -1) {
                                let index = unitList.findIndex(element => {
                                    return element.external_id === unitEid
                                })
                                ratePlanUnitList.push(unitList[index])
                            }
                        }
                    }
                    if (ratePlanUnitList.length > 0)
                        ratePlanList.push({
                            external_id: ratePlan.external_id,
                            name: this.distributionId === BOOKING ? ratePlan.name + " (" + ratePlan.external_id + ")" : ratePlan.name,
                            unit_list: ratePlanUnitList,
                            existing: ratePlan.existing,
                            linked_calc_value: ratePlan.linked_calc_value,
                            linked_calc_type: ratePlan.linked_calc_type,
                            type: ratePlan.type,
                        })
                })
            }
            this.$set(importData, "selected_units", selectedUnits)
            this.$set(importData, "rate_plan_list", ratePlanList)

            if (this.distributionId === AIRBNB) {

            } else if (this.distributionId === BOOKING) {
                this.$set(importData, "connection_approved", this.fetchedData.connection_approved)
            }

            return importData
        },
        getImportDataForStepMandatoryData() {
            let importData = {}
            if (this.formData.units.hasOwnProperty("import_mode") && this.formData.units.import_mode) {
                this.$set(importData, "import_mode", this.formData.units.import_mode)
            }

            let selectedUnits = this.formData.units.hasOwnProperty("selected_units") ? this.formData.units.selected_units : []
            let unitList = []
            if (this.fetchedData.hasOwnProperty("unit_list") && Array.isArray(this.fetchedData.unit_list) && this.fetchedData.unit_list.length > 0) {
                for (let unit of this.fetchedData.unit_list) {
                    if (selectedUnits.indexOf(unit.external_id) > -1) {
                        const max_adults = unit.hasOwnProperty("max_adults") ? unit.max_adults : null
                        const max_children = unit.hasOwnProperty("max_children") ? unit.max_children : null
                        const max_babies = unit.hasOwnProperty("max_babies") ? unit.max_babies : null

                        unitList.push({
                            ...{
                                external_id: unit.external_id,
                                display_name: unit.name + " (" + unit.external_id + ")",
                                name: unit.name,
                                type: unit.type,
                                bedrooms_count: unit.bedrooms_count,
                                floor_level: unit.floor_level,
                                size: unit.size,
                                max_adults: max_adults,
                                max_children: max_children,
                                max_babies: max_babies,
                            }, ...this.distributionId === BOOKING ? {units_number: unit.hasOwnProperty("units_number") ? unit.units_number : null} : {},
                            ...unit.hasOwnProperty("location") ? {location: unit.location} : {},
                            ...unit.hasOwnProperty("owner") ? {owner: unit.owner} : {},
                            ...unit.hasOwnProperty("property") ? {property: unit.property} : {},
                        })

                    }
                }
            }

            let existingProperty = false
            if (this.fetchedData.hasOwnProperty("property") && this.fetchedData.property) {
                if (this.fetchedData.property.hasOwnProperty("existing") && this.fetchedData.property.existing) {
                    existingProperty = true
                }
                let property = {
                    external_id: this.fetchedData.property.external_id,
                    name: this.fetchedData.property.name,
                    type: this.fetchedData.property.type,
                    categorization: this.fetchedData.property.categorization,
                    units_number: this.fetchedData.property.units_number,
                    owner: {
                        existing: this.fetchedData.property.owner.existing,
                        name: this.fetchedData.property.owner.name,
                        email: this.fetchedData.property.owner.email,
                    },
                    location: {
                        latitude: this.fetchedData.property.location && this.fetchedData.property.location.hasOwnProperty("latitude") ? this.fetchedData.property.location.latitude : null,
                        longitude: this.fetchedData.property.location && this.fetchedData.property.location.hasOwnProperty("longitude") ? this.fetchedData.property.location.longitude : null,
                        address: this.fetchedData.property.location && this.fetchedData.property.location.hasOwnProperty("address") ? this.fetchedData.property.location.address : null,
                        address_number: this.fetchedData.property.location && this.fetchedData.property.location.hasOwnProperty("address_number") ? this.fetchedData.property.location.address_number : null,
                        postal_code: this.fetchedData.property.location && this.fetchedData.property.location.hasOwnProperty("postal_code") ? this.fetchedData.property.location.postal_code : null,
                        city: this.fetchedData.property.location && this.fetchedData.property.location.hasOwnProperty("city") ? this.fetchedData.property.location.city : null,
                        country: this.fetchedData.property.location && this.fetchedData.property.location.hasOwnProperty("country") ? this.fetchedData.property.location.country : null,
                        region: this.fetchedData.property.location && this.fetchedData.property.location.hasOwnProperty("region") ? this.fetchedData.property.location.region : null,
                        county: this.fetchedData.property.location && this.fetchedData.property.location.hasOwnProperty("county") ? this.fetchedData.property.location.county : null,
                    },
                }
                this.$set(importData, "property", property)
            }

            this.$set(importData, "unit_list", unitList)
            this.$set(importData, "existing_property", existingProperty)
            const propertyId = existingProperty ? this.fetchedData.property.id : null
            if (propertyId !== null) {
                this.$set(importData, "property_id", propertyId)
            }

            return importData
        },
    },
}
</script>

<style scoped>

</style>
