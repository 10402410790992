<template>
    <div>
        <b-form v-if="loaded && subscription" @submit.prevent="save">
            <template>
                <b-row>

                    <b-col md="2" class="mb-4">
                        <label>{{ $t('TYPE') }}</label>
                        <b-input :value="subscription.label"
                                 disabled>
                        </b-input>
                    </b-col>

                    <b-col md="2" class="mb-4">
                        <label>{{ $t('EXPIRATION_DATE') }}</label>
                        <app-date-picker v-model="subscription.expiration_date"
                                         disabled>
                        </app-date-picker>
                    </b-col>

                    <app-access-control tag="b-col" md="2" class="mb-4"
                                        @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{function: C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_FEE_PERCENT, key: access_control_fetch_key}">
                        <template v-slot:default="props">
                            <app-input-control v-if="subscriptionPackageType === SUBSCRIPTION_PACKAGE_FLAT_PER_UNIT"
                                               :listening-for-event="listenForError"
                                               :error-object="validationErrorObject.flat_unit_fee">
                                <template v-slot:input="data">
                                    <label>{{ $t('PER_UNIT_FLAT_FEE') }}</label>
                                    <app-number-input :append="currency"
                                                      v-model="subscription.flat_unit_fee"
                                                      align="right"
                                                      :disabled="props.disabled"
                                                      allow-decimal
                                                      :state="data.state">
                                    </app-number-input>
                                </template>
                            </app-input-control>
                            <app-input-control v-if="subscriptionPackageType === SUBSCRIPTION_PACKAGE_PER_RESERVATION"
                                               :listening-for-event="listenForError"
                                               :error-object="validationErrorObject.fee_percent">
                                <template v-slot:input="data">
                                    <label>{{ $t('COMMISSION') }}</label>
                                    <app-number-input append="%"
                                                      v-model="subscription.fee_percent"
                                                      align="right"
                                                      :disabled="props.disabled"
                                                      allow-decimal
                                                      :state="data.state">
                                    </app-number-input>
                                </template>
                            </app-input-control>
                        </template>
                    </app-access-control>

<!--                    <app-access-control tag="b-col" md="2" class="mb-4"
                                        @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{function: C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_CANCELLATION_FEE, key: access_control_fetch_key}">
                        <template v-slot:default="props" v-if="subscriptionPackageType === SUBSCRIPTION_PACKAGE_PER_RESERVATION">
                            <app-input-control :listening-for-event="listenForError"
                                               :error-object="validationErrorObject.fee_cancel_amount">
                                <template v-slot:input="data">
                                    <label>{{ $t('CANCEL_FEE_AMOUNT') }}</label>
                                    <app-number-input v-model="subscription.fee_cancel_amount"
                                                      :append="currency"
                                                      align="right"
                                                      :disabled="props.disabled"
                                                      allow-decimal
                                                      :state="data.state">
                                    </app-number-input>
                                </template>
                            </app-input-control>
                        </template>
                    </app-access-control>-->

                    <app-access-control tag="b-col" md="2" class="mb-4"
                                        @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{function: C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_MAX_YEARLY_AMOUNT, key: access_control_fetch_key}">
                        <template v-slot:default="props">
                            <app-input-control :listening-for-event="listenForError"
                                               :error-object="validationErrorObject.max_amount">
                                <template v-slot:input="data">
                                    <label>{{ $t('MAX_YEARLY_AMOUNT') }}</label>
                                    <app-number-input v-model="subscription.max_amount"
                                                      :append="currency"
                                                      align="right"
                                                      :disabled="props.disabled"
                                                      allow-decimal
                                                      :state="data.state">
                                    </app-number-input>
                                </template>
                            </app-input-control>
                        </template>
                    </app-access-control>

                    <app-access-control tag="b-col" md="2" class="mb-4"
                                        @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{function: C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_CURRENT_TOTAL_AMOUNT, key: access_control_fetch_key}">
                        <label>{{ $t('CURRENT_YEARLY_AMOUNT') }}</label>
                        <app-number-input align="right"
                                          :append="currency"
                                          :value="total_amount"
                                          allow-decimal
                                          disabled>
                        </app-number-input>
                    </app-access-control>


                </b-row>
                <b-row>
                    <b-col>
                        <app-button-submit :v_show="checkPermission(C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_E)"
                                           :loading="loading"
                                           :access_control_context="{function: C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_E, key: access_control_fetch_key}"
                                           @AccessControlEvent="addToAccessControlCounter()">
                            {{ $t('SAVE') }}
                        </app-button-submit>
                    </b-col>
                </b-row>
            </template>
        </b-form>

        <app-aside v-model="extendSubscriptionPanelState"
                   v-if="latestSubscription"
                   :widths="['col-lg-3 col-sm-5', '40%']">
            <template slot="header">
                {{ !reactivateSubscription ? $t('EXTEND_DEMO') : $t('REACTIVATE_DEMO') }}
            </template>
            <extend-subscription-form :subscription-id="latestSubscription.id"
                                      :company="company.id"
                                      :reactivate-subscription="reactivateSubscription"
                                      @subscriptionSaved="subscriptionSaved">
            </extend-subscription-form>
        </app-aside>

        <app-aside v-model="contractDetailsPanelState"
                   v-if="latestSubscription"
                   :widths="['col-lg-3 col-sm-5', '40%']">
            <template slot="header">
                {{ !signContract ? $t('EXTEND_CONTRACT') : $t('SIGN_CONTRACT') }}
            </template>
            <contract-details-form :subscription-id="latestSubscription.id"
                                   :company="company.id"
                                   :currency="currency"
                                   :sign-contract="signContract"
                                   @contractSaved="contractSaved">
            </contract-details-form>
        </app-aside>

    </div>
</template>

<script>
import {
    getActiveSubscription,
    updateContractDetails,
} from "@/services/company";
import AppNoData from "@/components/app/AppNoData";
import FormHeader from "@/components/app/form/FormHeader";
import {
    C_COMPANY_SUBSCRIPTION_E,
    C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_E,
    C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_FEE_PERCENT,
    // C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_CANCELLATION_FEE,
    C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_MAX_YEARLY_AMOUNT,
    C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_CURRENT_TOTAL_AMOUNT,
} from "@/shared/component_permission";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {
    EventBus,
    GE_ACTIVATE_DEMO_SUBSCRIPTION,
    GE_EXTEND_DEMO_SUBSCRIPTION,
    GE_SUBSCRIPTION_CONTRACT,
} from "@/shared/EventBus"
import {SUBSCRIPTION_PACKAGE_PER_RESERVATION, SUBSCRIPTION_PACKAGE_FLAT_PER_UNIT} from "@/shared/constants";
import AppInputControl from "@/components/app/AppInputControl";
import {
    SU_CANCELLATION_FEE_AMOUNT_CANNOT_BE_LESS_THAN_ZERO,
    SU_CANCELLATION_FEE_AMOUNT_MUST_BE_NUMERIC,
    SU_FEE_PERCENT_IS_MANDATORY,
    SU_FEE_PERCENT_MUST_BE_GREATER_THAN_ZERO,
    SU_FEE_PERCENT_CANNOT_BE_GREATER_THAN_HUNDRED,
    SU_FEE_PERCENT_MUST_BE_NUMERIC,
    SU_MAX_AMOUNT_CANNOT_BE_LESS_THAN_ZERO,
    SU_MAX_AMOUNT_MUST_BE_NUMERIC,
} from "@/shared/error_codes";
import ContractDetailsForm from "@/components/company/subscription/forms/ContractDetailsForm";
import ExtendSubscriptionForm from "@/components/company/subscription/forms/ExtendSubscriptionForm";
import AppAccessControl from "@/components/app/AppAccessControl";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_SUBSCRIPTION_CONTRACT_DETAILS} from "@/mixins/AccessControl/AccessControlEnumeration";

export default {
    name: "Subscription",
    components: {
        AppAccessControl,
        ExtendSubscriptionForm,
        ContractDetailsForm,
        AppInputControl,
        AppNumberInput,
        AppButton,
        AppButtonSubmit,
        FormHeader,
        AppNoData,
        AppDatePicker,
        AppAside,
        AppSelect
    },
    mixins: [getErrorMessage, AccessControlComponent],
    data() {
        return {
            subscription: null,
            subscriptionPackageType: SUBSCRIPTION_PACKAGE_PER_RESERVATION,
            latestSubscription: null,
            currency: null,
            total_amount: null,
            subscriptionLoaded: false,
            latestSubscriptionLoaded: false,
            extendSubscriptionPanelState: false,
            contractDetailsPanelState: false,
            package_id: null,
            saving: false,
            packages: [],
            loading: false,
            listenForError: false,
            validationErrorObject: {
                flat_unit_fee: {
                    input: [
                        SU_FEE_PERCENT_IS_MANDATORY,
                        SU_FEE_PERCENT_MUST_BE_NUMERIC,
                        SU_FEE_PERCENT_MUST_BE_GREATER_THAN_ZERO,
                        SU_FEE_PERCENT_CANNOT_BE_GREATER_THAN_HUNDRED,
                    ],
                },
                fee_percent: {
                    input: [
                        SU_FEE_PERCENT_IS_MANDATORY,
                        SU_FEE_PERCENT_MUST_BE_NUMERIC,
                        SU_FEE_PERCENT_MUST_BE_GREATER_THAN_ZERO,
                        SU_FEE_PERCENT_CANNOT_BE_GREATER_THAN_HUNDRED,
                    ]
                },
                fee_cancel_amount: {
                    input: [
                        SU_CANCELLATION_FEE_AMOUNT_MUST_BE_NUMERIC,
                        SU_CANCELLATION_FEE_AMOUNT_CANNOT_BE_LESS_THAN_ZERO,
                    ]
                },
                max_amount: {
                    input: [
                        SU_MAX_AMOUNT_MUST_BE_NUMERIC,
                        SU_MAX_AMOUNT_CANNOT_BE_LESS_THAN_ZERO,
                    ]
                },
            },
            reactivateSubscription: false,
            signContract: false,
            access_control_components: 4, // 5
            access_control_fetch_key: AC_SUBSCRIPTION_CONTRACT_DETAILS,
            access_control_general_context: {
                company: this.company.id
            },
            SUBSCRIPTION_PACKAGE_PER_RESERVATION,
            SUBSCRIPTION_PACKAGE_FLAT_PER_UNIT,
            C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_E,
            C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_FEE_PERCENT,
            // C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_CANCELLATION_FEE,
            C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_MAX_YEARLY_AMOUNT,
            C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_CURRENT_TOTAL_AMOUNT,
        }
    },
    props: {
        company: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    computed: {
        loaded() {
            return this.subscriptionLoaded && this.latestSubscriptionLoaded
        },
    },
    methods: {
        save() {
            this.loading = true
            this.listenForError = true
            let requestData = {
                flat_unit_fee: this.subscription.flat_unit_fee,
                fee_percent: this.subscription.fee_percent,
                fee_cancel_amount: this.subscription.fee_cancel_amount,
                max_amount: this.subscription.max_amount,
            }

            if (this.subscriptionPackageType === SUBSCRIPTION_PACKAGE_FLAT_PER_UNIT) {
                requestData.fee_percent = null
                requestData.fee_cancel_amount = null
            } else if (this.subscriptionPackageType === SUBSCRIPTION_PACKAGE_PER_RESERVATION) {
                requestData.flat_unit_fee = null
            }
            // if (!(this.subscription.fee_cancel_amount === null || this.subscription.fee_cancel_amount === undefined || this.subscription.fee_cancel_amount === '')) {
            //     requestData.fee_cancel_amount = this.subscription.fee_cancel_amount
            // }
            // if (!(this.subscription.max_amount === null || this.subscription.max_amount === undefined || this.subscription.max_amount === '')) {
            //     requestData.max_amount = this.subscription.max_amount
            // }
            updateContractDetails(this.company.id, this.subscription.id, requestData).then(() => {
                notifySuccess()
                this.getSubscription()
            }, error => {
                this.showErrorMessages(error, this.validationErrorObject)
            }).finally(() => {
                this.listenForError = false
                this.loading = false
            })
        },
        getSubscription() {
            getActiveSubscription(this.company.id, {active: 1}).then(res => {
                this.currency = res.data.currency
                this.total_amount = res.data.total_amount
                if (res.data.list.length) {
                    this.subscription = res.data.list[0]
                    this.subscriptionPackageType = this.subscription.hasOwnProperty('flat_unit_fee') && this.subscription.flat_unit_fee !== null ? SUBSCRIPTION_PACKAGE_FLAT_PER_UNIT : SUBSCRIPTION_PACKAGE_PER_RESERVATION
                }
            }).finally(() => this.subscriptionLoaded = true)

            if (this.checkPermission(C_COMPANY_SUBSCRIPTION_E)) {
                getActiveSubscription(this.company.id).then(res => {
                    if (res.data.list.length) {
                        this.latestSubscription = res.data.list[0]
                    }
                }).finally(() => {
                    this.latestSubscriptionLoaded = true
                })
            } else {
                this.latestSubscriptionLoaded = true
            }
        },
        subscriptionSaved() {
            EventBus.$emit(GE_EXTEND_DEMO_SUBSCRIPTION)
            this.getSubscription()
            this.extendSubscriptionPanelState = false
        },
        contractSaved() {
            EventBus.$emit(GE_SUBSCRIPTION_CONTRACT)
            this.getSubscription()
            this.contractDetailsPanelState = false
        },
    },
    created() {
        this.getSubscription()
    },
    mounted() {
        EventBus.$on(GE_ACTIVATE_DEMO_SUBSCRIPTION, () => {
            this.getSubscription()
        })
        EventBus.$on(GE_EXTEND_DEMO_SUBSCRIPTION, (reactivateDemo) => {
            this.reactivateSubscription = reactivateDemo
            this.extendSubscriptionPanelState = true
        })
        EventBus.$on(GE_SUBSCRIPTION_CONTRACT, (signContract) => {
            this.signContract = signContract
            this.contractDetailsPanelState = true
        })
    },
}
</script>

<style scoped>

</style>
