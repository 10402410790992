<template>
    <app-contact-list :object="Number($route.params.id)" :object-type="OBJECT_TYPE_COMPANY"
                      :c-permission-e="C_COMPANY_CONTACTS_E" :c-permission-v="C_COMPANY_CONTACTS_V"></app-contact-list>

</template>

<script>
    import AppContactList from "@/components/app/contacts/AppContactList";
    import {OBJECT_TYPE_COMPANY} from "@/shared/constants";
    import {C_COMPANY_CONTACTS_V, C_COMPANY_CONTACTS_E} from "@/shared/component_permission";

    export default {
        name: "Index",
        components: {AppContactList},
        data() {
            return {
                OBJECT_TYPE_COMPANY,
                C_COMPANY_CONTACTS_V,
                C_COMPANY_CONTACTS_E
            }
        }
    }
</script>

<style scoped>

</style>
