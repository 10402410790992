<template>
    <b-col>
        <b-form-checkbox inline :checked="select_all" :value="true"
                         :unchecked-value="false"
                         @change="selectAllChange">{{ $t("SELECT_ALL") }}
        </b-form-checkbox>

        <label>{{ restriction.label }}</label>
        <b-form-checkbox-group stacked @change="change" v-model="selected" :options="options"
                               disabled-field="notEnabled"
                               name=""></b-form-checkbox-group>
    </b-col>
</template>

<script>

export default {

    name: "arrival-departure-days-form-element",
    props: {
        allowed_values: {
            type: Array
        },
        value: {
            type: String,

        },
        restriction: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selected: [],
            select_all: false
        }
    },
    methods: {
        getWeekday(number) {
            switch (Number(number)) {
                case 0:
                    return this.$t('DATE.DAYS.MONDAY')
                case 1:
                    return this.$t('DATE.DAYS.TUESDAY')
                case 2:
                    return this.$t('DATE.DAYS.WEDNESDAY')
                case 3:
                    return this.$t('DATE.DAYS.THURSDAY')
                case 4:
                    return this.$t('DATE.DAYS.FRIDAY')
                case 5:
                    return this.$t('DATE.DAYS.SATURDAY')
                case 6:
                    return this.$t('DATE.DAYS.SUNDAY')
            }
        },
            getSelected() {
                this.selected = []
                if (this.value === null || typeof this.value === "undefined") {
                    return
                }
                let tempValue = typeof this.value === "number" ? this.value.toString().split('') : this.value.split('')
                if (tempValue.length === 0) {
                    return
                }
                tempValue.forEach(item => {
                    if (Number(item) > 0) {
                        this.selected.push(Number(item))
                    }
                })
            },
        change(item) {
            this.select_all = false
            if (item.length === 0) {
                this.$emit("input", '0')
                return
            }
            this.$emit("input", item.join(""))
        },
        selectAllChange(value) {

            this.select_all = value
            this.selected = []
            if (this.select_all) {
                this.options.forEach(item => {
                    this.selected.push(item.value)
                })
            }
            if (this.selected.length === 0) {
                this.$emit("input", '0')
                return
            }

            this.$emit("input", this.selected.join(""))
        }
    },
    computed: {
        options() {
            let data = []
            for (const key in this.allowed_values) {
                data.push({text: this.getWeekday(key), value: Number(key) + 1})
            }

            return data
        },
    },
    watch: {
        value: {
            handler() {
                this.getSelected()
            },
            immediate: true,
        },
        selected: {
            handler(value) {
                this.select_all = value.length === this.options.length
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.getSelected()
    }
}
</script>

<style scoped>

</style>
