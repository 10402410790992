<template>
    <div v-if="cellObjectList.length > 0" class="main_calendar_test__holder--content_dates section_availability main_calendar_test-bb animated fadeIn"
         :class="{'main_calendar_test--background_gray': isRoot}"
         ref="calendar_scroll_container"
         :style="`grid-template-columns: repeat(${dates.length}, 2.87rem)`">
        <table-availability-content-cell
            v-for="(cellData, index) in cellObjectList"
            v-if="cellData.showCell"
            :cell-data="cellData"
            :noteHighLight="isNoteHighlighted(cellData)"
            :date="cellData.date"
            :highlighted="isHighlighted(cellData.date)"
            :key="index"
            :shiftKeyValue="shiftKeyValue"
            :drag-period="dragPeriod"
            :current-drag-date="currentDragDate"
            @showReservation="showReservation(cellData.reservation)"
            @startEndDrag="dragStartEnd(cellData.date)"
            @drag="drag(cellData.date)"
            @currentDrag="dragCurrent(cellData.date)"
            @mouseEnter="mouseEnter(cellData)"
            @mouseLeave="mouseLeave(cellData)"
            @mouseEnterNote="mouseEnterNote(cellData)"
            @mouseLeaveNote="mouseLeaveNote(cellData)"
            :show="show"
            :column="index+1"
            :show_channel_data="ac_response_value"
        >
        </table-availability-content-cell>

    </div>
</template>

<script>

import TableAvailabilityContentCell
    from "@/components/unit/calendar_opt/Table/Availability/TableAvailabilityContentCell";
import {
  EventBus,
  GE_CALENDAR_OPEN_AVAILABILITY, GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR, GE_CALENDAR_OPEN_RESERVATIONS
} from "@/shared/EventBus";

import {CalendarRowMixin} from "@/mixins/calendar/CalendarRowMixin";
import {DISTRIBUTION_PHOTO} from "@/services/endpoints";
import moment from 'moment';
import i18n from "@/locale";
import {C_RESERVATION_ACCOMMODATION_BOOKING_SITE} from "@/shared/component_permission";
import {fetchAccessControlData} from "@/services/access";
import {AC_FEATURE_SETUP} from "@/mixins/AccessControl/AccessControlEnumeration";

export default {
    name: "TableAvailabilityContent",
    components: {TableAvailabilityContentCell},
    mixins: [CalendarRowMixin],
    props: {
        dates: {
            type: Array
        },
        shiftKeyValue: {
            type: Boolean
        },
        unit: {
            type: Object
        },
        contigent: {
            type: Array
        },
        isRoot: {
            type: Boolean
        },
        dateRange: {
            type: Object
        },
        addMonths: {
            type: Number
        },
        availability: {
            type: Object
        },
        reservations: {
            type: Array
        }
    },
    data() {
        return {
            currentDragDate: null,
            dragPeriod: {
                start: null,
                end: null
            },
            cellObjectList: [],
          show:false,
          enter:false,
            ac_response_value:true
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        isContigent() {
            return !this.isRoot || (this.isRoot && this.contigent.length === 0)
        },
        isContingentContainerRow() {
            return this.isRoot && this.contigent.length > 0
        },
        availabilityForUnit() {
            return Object.entries(this.availability).reduce((acc, [key, value]) => {
                acc[key] = {
                    ...value,
                    available: value.available_unit.includes(this.unit.id),
                    available_count: value ? value.available_unit.length : null,
                    noteObject:this.getUnitNote(value),
                    open_for_sale: value.open_for_sale
                }
                return acc
            }, {})
        }
    },
    methods: {
      getUnitNote(availabilityObject){
        let  noteObject = null
        if(availabilityObject.hasOwnProperty('note')){
          noteObject = availabilityObject.note
        }
        return noteObject
      },
      mouseEnterNote(cellData){
          cellData.showResPopup.enter = true
          setTimeout(()=>{
              cellData.showResPopup.show = cellData.showResPopup.enter && cellData.note_highlight.key !== null
              if(cellData.showResPopup.enter && cellData.note_highlight.key && !this.shiftKeyValue){
                  this.cellObjectList.forEach(el =>{
                      if(el.note_highlight.key === cellData.note_highlight.key){
                          el.note_highlight.value = true
                      } else {
                          el.note_highlight.value = false
                      }
                  })
              }
          },500)

      },
      mouseLeaveNote(cellData){
          cellData.showResPopup.enter = false
          cellData.showResPopup.show = false

          this.cellObjectList.forEach(el =>{
                  el.note_highlight.value = false
          })
      },
      mouseEnter(cellData){
        cellData.showResPopup.enter = true
        setTimeout(()=>{
          cellData.showResPopup.show = cellData.showResPopup.enter
        },400)
      },
      mouseLeave(cellData){
        cellData.showResPopup.enter = false
        cellData.showResPopup.show = false
      },
        dragCurrent(date) {
            this.currentDragDate = date
        },
        drag(date) {
            if (!this.dragPeriod.start) {
                this.dragPeriod.start = date
            } else if (!this.dragPeriod.end) {
                this.dragPeriod.end = date
                this.openAvailabilityAside()
            }

        },
        dragStartEnd(date) {
        this.cellObjectList.forEach(cellData=>{
          cellData.showResPopup.enter = false
          cellData.showResPopup.show = false
        })
            this.dragPeriod.start = date
            this.dragPeriod.end = date
            this.openAvailabilityAside()
        },
        openAvailabilityAside() {
            if (this.dragPeriod.start > this.dragPeriod.end) {
                this.dragPeriod = {
                    start: this.dragPeriod.end,
                    end: this.dragPeriod.start
                }
            }

            let eventData = {
                unitId: this.unit.parent_unit_id !== null ? this.unit.parent_unit_id : this.unit.id,
                contigentMode: !this.isContigent,
                contigentUnit: this.isContigent && !this.isRoot ? this.unit : null,
                tab: 0,
                dateRange: {
                    start: this.dragPeriod.start.format("YYYY-MM-DD"),
                    end: this.dragPeriod.end.format("YYYY-MM-DD")
                }
            }
            EventBus.$emit(GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR, eventData)
            this.dragPeriod.start = null
            this.dragPeriod.end = null
            this.currentDragDate = null
        },
        getCellObjectList(dates) {
          if(Array.isArray(dates) && dates.length > 0){
              return dates.map((date, cellIndex) => {
                  const dayBefore = date.clone().subtract(1, 'days')
                  const dayAfter = date.clone().add(1, 'days')
                  const dayBeforeF = dayBefore.format('YYYY-MM-DD')
                  const dayAfterF = dayAfter.format('YYYY-MM-DD')
                  const dateF = date.format('YYYY-MM-DD')

                  const reservationDataObject = this.isContingentContainerRow ? {} : this.getCellObjectReservationData(date, dayBefore, dayAfter, cellIndex)
                  const colspan = this.isContingentContainerRow ? 1 : this.getCellObjectColumnSpan(reservationDataObject, date, cellIndex)
                  const showCell = this.isContingentContainerRow ? !!this.availabilityForUnit[dateF] : (colspan > 0 && this.availabilityForUnit.hasOwnProperty(dateF))
                  const label = this.isContingentContainerRow && this.availabilityForUnit[dateF]  ? this.availabilityForUnit[dateF].available_count : ''
                  const note =  !this.isContingentContainerRow && this.availabilityForUnit[dateF].hasOwnProperty('noteObject') ? this.availabilityForUnit[dateF].noteObject : null
                  const width = this.getWidth(reservationDataObject, colspan, cellIndex+1)
                  const min_guest_width = 0.78

                  return {
                      date,
                      label,
                      showCell,
                      colspan,
                      popup_title: reservationDataObject.reservation ? reservationDataObject.reservation.check_in_date + ' - ' + reservationDataObject.reservation.check_out_date : null,
                      lastDayOfMonthClass: {'main_calendar-br_green': date.date() === date.daysInMonth()},
                      reservationClasses: this.getReservationClasses(this.availabilityForUnit[dayBeforeF], reservationDataObject),
                      hasReservationOnDayBefore: reservationDataObject ? reservationDataObject.hasReservationOnDayBefore : 0,
                      hasReservationOnDayAfter: reservationDataObject ? reservationDataObject.hasReservationOnDayAfter : 0,
                      width:width,
                      guest_width: (width-3) > min_guest_width ? width-3 : reservationDataObject && reservationDataObject.hasReservationOnDayBefore ? min_guest_width : width - 3,
                      reservation:  reservationDataObject.reservation,
                      showResPopup:{show:false,enter:false},
                      showNotePopup:{show:false,enter:false},
                      note:this.formatNote(note),
                      translations: this.getTranslations(),
                      note_highlight:this.setNoteKey(this.availabilityForUnit[dateF], note),
                      availabilityObject:this.availabilityForUnit[dateF],
                      unit:this.unit.id,
                      isContigent: this.isContigent,
                      showNumericSpan: this.showNumSpan(this.availabilityForUnit[dateF]),
                      showOpenIndicator:this.showGreenIndicator(this.availabilityForUnit[dateF])
                  }
              })
          } else {
              return []
          }

        },
        showGreenIndicator(availabilityObject){
          if(this.isContingentContainerRow){
           return availabilityObject.available_count > 0 && availabilityObject.open_for_sale
          } else if(this.isRoot && !this.isContingentContainerRow) {
            return availabilityObject.available && availabilityObject.open_for_sale
          } else {
            return availabilityObject.available
          }
        },
        showNumSpan(availabilityObject){
          const available = availabilityObject.available ? 1 : 0
          if(this.isContingentContainerRow){
            const available_contingent = availabilityObject.available_count  > 1 || (availabilityObject.available_count > 0 && !availabilityObject.open_for_sale)? true : false
            return {
              available_count:available_contingent,
              room_status: availabilityObject.available_count === 0 &&  availabilityObject.open_for_sale
            }
          } else if(this.contigent.length === 0 && this.isRoot){
            return {
              available_count:available && !availabilityObject.open_for_sale,
              room_status: !available &&  availabilityObject.open_for_sale
            }
          } else  {
            return {
              available_count:false,
              room_status: false
            }
          }
        },
        setNoteKey(av, note){
          return note && !av.available ? {key:note.user_id + '_' + note.unit  + '_' +note.timestamp,value:false} : {key:null, value:false}
        },
       formatNote(note){
        if(note){
          note.timestamp_formatted =this.formatTimeStamp(note.timestamp)
          note.period_start_foramtted =this.formatDate(note.period_start)
          note.period_end_foramtted =this.formatDate(note.period_end)
        }
        return note
       },
      formatTime(value){
        let format = i18n.t('DATE.HOUR_MIN_FORMAT')

        if (format === null) {
          format = "HH:mm"
        }

        if (value) {
          if (typeof value === 'number') {
            return moment.unix(value).format(format)
          } else if (typeof value === 'string') {
            if ((new Date(value)).getTime() > 0) {
              return moment(value).format(format)
            } else {
              return moment(value, "HH:mm").format(format)
            }
          }
        } else {
          return value
        }
      },
      formatDate(value){
       const  year = true
        if (!value) return

        let format = (year) ? i18n.t('DATE.DATE_FORMAT') : i18n.t('DATE.DATE_FORMAT_SANS_YEAR')

        if (format === null) {
          format = "YYYY-MM-DD"
        }

        if(typeof value === 'number'){
          return moment.unix(value).format(format)
        }

        return moment(String(value)).format(format)
      },
      formatTimeStamp(value){
        let format = i18n.t('DATE.TIMESTAMP_FORMAT')
        if (format === null) {
          format = "YYYY-MM-DD HH:mm"
        }
        return moment(String(value)).format(format)
      },
        getReservationClasses(avBefore,{hasReservationOnDayBefore,hasReservationOnDayAfter}){
            return this.isContigent && avBefore ? {
                '':hasReservationOnDayBefore,
                'main_calendar_test--status-start-item_available-before-res': avBefore.available  && !hasReservationOnDayBefore,
                'main_calendar_test--status-start-item_not_available-before-res': !avBefore.available && !hasReservationOnDayBefore,
                'main_calendar_test_reservation':!hasReservationOnDayAfter

            } : {

            }
        },
        getTranslations(){
          return {
            RESERVATION_CODE: this.$t('RESERVATION_CODE'),
            SALES_CHANNEL:this.$t('SALES_CHANNEL'),
            GUEST:this.$t('GUEST'),
            ADULTS:this.$t('ADULTS'),
            CHILDREN:this.$t('CHILDREN'),
            BABIES:this.$t('BABIES'),
            TOTAL:this.$t('TOTAL'),
            ARRIVAL_TIME:this.$t('ARRIVAL_TIME'),
            BOOKED_AT:this.$t('BOOKED_AT'),
            UPDATED_AT:this.$t('UPDATED_AT'),
            NOTE:this.$t('NOTE'),
            CLOSED_AT:this.$t('CLOSED_AT'),
            USER:this.$t('USER'),
            TAG:this.$t('TAG')
          }
        },
        getWidth(reservationDataObject, colspan, column){
            return colspan *  2.87
        },

        getCellObjectReservationData(date, dayBefore, dayAfter, cellIndex) {
            const reservationDataObject = {
                reservation: null,
                hasReservationOnDayBefore: null,
                hasReservationOnDayAfter: null,
                hasReservationOnDay: null,
                hasOneNightReservation: null
            }

            let reservation = null

            if (cellIndex === 0) {
                reservation = this.reservations.find(r => date >= moment(r.check_in_date) && date < moment(r.check_out_date))
            } else {
                reservation = this.reservations.find(r => date.isSame(moment(r.check_in_date), 'day'))
            }

            if (reservation) {
                if (reservation.distribution) {
                    reservation.channel_icon = DISTRIBUTION_PHOTO(reservation.distribution)
                } else {
                    reservation.channel_icon = DISTRIBUTION_PHOTO(0)
                }
                reservation.formatted_name = `${(reservation.first_name ? reservation.first_name.substr(0, 1) + '. ' : '')}${reservation.last_name}`
                reservation.formated_name_count = reservation.formatted_name.length
                reservation.formatted_check_in_time = this.formatTime(reservation.check_in_time)
                reservation.formatted_check_in_date = this.formatDate(reservation.check_in_date)
                reservation.formatted_check_out_date = this.formatDate(reservation.check_out_date)
                reservation.formatted_created_date = this.formatDate(reservation.created_date) + ' ' + this.formatTime(reservation.created_date)
                reservation.formatted_updated_date = this.formatDate(reservation.updated_date) + ' ' + this.formatTime(reservation.updated_date)
                reservation.tags = reservation.tag ? reservation.tag.map(value => value.label).join(', ') : ""
            }

            reservationDataObject.reservation = reservation
            reservationDataObject.hasReservationOnDayBefore = !!this.reservations.find(r => (dayBefore >= moment(r.check_in_date) && dayBefore < moment(r.check_out_date)))
            reservationDataObject.hasReservationOnDayAfter = !!this.reservations.find(r => (dayAfter >= moment(r.check_in_date) && dayAfter < moment(r.check_out_date)))
            reservationDataObject.hasReservationOnDay = !!this.reservations.find(r => (date >= moment(r.check_in_date) && date < moment(r.check_out_date)))
            return reservationDataObject
        },
        getCellObjectColumnSpan({reservation, hasReservationOnDay}, date, cellIndex) {
            if (reservation && reservation.hasOwnProperty('num_nights')) {
                const checkInDate = moment(reservation.check_in_date)
                const checkOutDate = moment(reservation.check_out_date)
                const numberOfCells = this.dates.length + 1

                if (cellIndex === 0 && date > checkInDate) {
                    if (reservation.num_nights > numberOfCells && checkOutDate > this.dates[this.dates.length - 1]) {
                        return numberOfCells - (cellIndex + 1)
                    } else {
                        return reservation.num_nights - date.diff(checkInDate, 'days')
                    }
                }

                if (((cellIndex + 1) + reservation.num_nights) >= numberOfCells) {
                    return numberOfCells - (cellIndex + 1)
                }

                return reservation.num_nights
            }

            if (hasReservationOnDay && !reservation) {
                return 0
            }

            return 1
        },
        isHighlighted(date) {
            return (this.shiftKeyValue && this.dragPeriod.start && this.currentDragDate)
                && ((date >= this.dragPeriod.start && date <= this.currentDragDate) ||
                    (date <= this.dragPeriod.start && date >= this.currentDragDate)
                )
        },
        isNoteHighlighted(data){
          const object =  {key:null, value:null}
          if(data.note && this.cellObjectList.length > 0){
              this.cellObjectList.forEach(el => {

                  if(el.note === data.note ) {
                       object.key = data.note.unit + '_' + data.note.user_id + '_' + data.note.timestamp
                       object.value = data.showResPopup.show
                      }
              })
          }
          return object

        },
        showReservation(reservation) {
            if (this.shiftKeyValue) {
                return
            }
            EventBus.$emit(GE_CALENDAR_OPEN_RESERVATIONS, reservation)
        }
    },
    watch: {
        shiftKeyValue(val) {
            if (val === false) {
                this.dragPeriod.start = null
                this.dragPeriod.end = null
                this.currentDragDate = null
            }
        },
        availability: {
            immediate: true,
            handler(value) {
                if (Object.keys(value).length && this.dates.length) {
                    this.cellObjectList = this.getCellObjectList(this.dates)
                }
            }
        },

    },
    mounted() {
        let request = {
            context:{
                company: this.$store.getters['user/getCompany']
            },
            data:[]
        }
        request.data.push({
            uid: C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
            function: C_RESERVATION_ACCOMMODATION_BOOKING_SITE
        })
        fetchAccessControlData(AC_FEATURE_SETUP, request).then(response => {
            this.ac_response_value = response.data[C_RESERVATION_ACCOMMODATION_BOOKING_SITE].visible
        })
    }
}
</script>

<style scoped>

</style>
