<template>
    <div>
        <div class="dashboard-wrapper">
            <b-card >
                <b-row>
                    <b-col md="6" lg="5" class="d-flex align-items-start flex-column">
                        <div class="header-2 mb-2">
                            <b-img style="width: 54px;" :src="distribution.icon"
                                   alt="logo"></b-img>
                            <span class="ml-3">{{distribution.name}}</span>
                        </div>
                        <div class="d-flex no-wrap mt-4">
                            <app-button variant="link" class="action_button"
                                        @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{property:property_id, function:C_PROPERTY_CM_PM_PROPERTY_V,distribution:distribution.id,key:AC_CHANNEL_MANAGER}"
                                        :to="{name: routeNames.RN_PROPERTY_CHANNEL_MANAGER_PRODUCT_MANAGEMENT, params: {distribution_id: distribution.id}}">
                                {{$t('PRODUCT_MANAGEMENT')}}
                            </app-button>
                            <app-button variant="link" class="action_button b-l-1 pl-2 ml-2"
                                        @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{property:property_id, function:C_PROPERTY_CM_SYNC_LOG_V,distribution:distribution.id,key:AC_CHANNEL_MANAGER}"
                                        :to="{name: routeNames.RN_PROPERTY_CHANNEL_MANAGER_SYNCHRONIZATION_LOG, params: {distribution_id: distribution.id}}">
                                {{$t('SYNCHRONIZATION_LOG')}}
                            </app-button>
                        </div>
                    </b-col>
                    <b-col md="6" lg="4" class="d-flex align-items-center justify-content-lg-center pr-lg-3 pt-3  pt-lg-0">
                        <doughnut-chart :styles="customStyle" :chart-data="dataCollection"></doughnut-chart>
                        <div class="ml-3">
                            <h1 class="header-1 m-0">{{calculatePercentage}} %</h1>
                            <span class="small-text">{{$t('ACTIVE')}}</span>
                        </div>

                    </b-col>
                    <b-col md="12" lg="3" class="bl-lg-show pl-lg-3 pt-3  pt-lg-0 d-flex flex-column justify-content-end">
                        <div v-show="distribution.id !== ICAL">
                            <app-button
                                :loading="loader"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{property:property_id, function:C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_V,distribution:distribution.id,key:AC_CHANNEL_MANAGER}"
                                @click="enablePropertyStatusActive(distribution.id)">{{$t('ACTIVATE')}}
                            </app-button>
                            <app-access-control tag="span"

                                                @AccessControlEvent="addToAccessControlCounter()"
                                                :access_control_context="{property:property_id, function:C_PROPERTY_CM_PM_PROPERTY_DEACTIVATE_V,distribution:distribution.id,key:AC_CHANNEL_MANAGER}">
                                <template v-slot:default="props">

                                    <b-tooltip v-if="props.messages.length"
                                               class="mb-5"
                                               triggers="hover"
                                               :title="props.messages[0].message" :target="'key_'+distribution.id">
                                    </b-tooltip>
                                    <div>
                                        <app-button class="mt-2"
                                                    :disabled="props.disabled"
                                                    :id="'key_'+distribution.id" @click="showDeactivateDialog=true">
                                            {{$t('DEACTIVATE')}}
                                        </app-button>
                                    </div>

                                </template>
                            </app-access-control>
                        </div>
                    </b-col>
                </b-row>
                <modal-dialog :show="showDeactivateDialog" @confirm="disablePropertyStatusActive()" @cancel="showDeactivateDialog = false" :title="$t('DEACTIVATION_CONFIRMATION')">
                    {{$t('DEACTIVATE_PROPERTY_MESSAGE',{distribution_name:distribution.name})}}
                </modal-dialog>
            </b-card>
        </div>
        <app-aside :widths="['col-sm-12', '40%']" v-model="aside">
            <template slot="header">
                {{distribution.name}} {{$t('ACTIVATION').toLowerCase()}}
            </template>
            <air-bnb-activation-wizard v-if="distribution.id === AIRBNB" @activate="activate" :property_id="property_id" :distribution="distribution" :loader="loader"></air-bnb-activation-wizard>
            <distribution-activation-wizard v-else :distribution="distribution" @activate="activate" :property_id="property_id" :loader="loader"></distribution-activation-wizard>
        </app-aside>
    </div>


</template>

<script>
    import routeNames from '@/router/routeNames'
    import EditDistribution from '@/components/app/AppButton/AppButton'
    import AppButton from "@/components/app/AppButton/AppButton"
    import {AC_CHANNEL_MANAGER} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {
        C_PROPERTY_CM_PM_PROPERTY_DEACTIVATE_V,
        C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_V,
        C_PROPERTY_CM_SYNC_LOG_V,
        C_PROPERTY_CM_PM_PROPERTY_V,
        C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_TEST_V

    } from "@/shared/component_permission";
    import DoughnutChart from '@/components/app/Charts/ChartDoughnut'
    import {
        disablePropertyActivation,
        activateProperty
    } from "@/services/channel_manager";
    import {toast} from "@/shared/plugins/toastr";
    import FormHeader from "@/components/app/form/FormHeader";
    import DistributionActivationWizard from "@/components/channel_manager/wizard/DistributionPropertyActivationWizard";
    import AppAside from "@/components/app/form/AppAside";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import RouteNames from '@/router/routeNames'
    import ModalDialog from '@/components/app/form/AppConfirmationDialog'
    import {BOOKING, EXPEDIA,HOTEL_BEDS,AIRBNB,AGODA, ICAL} from "@/shared/constants";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import AirBnbActivationWizard from "@/components/channel_manager/wizard/AirBnbActivationWizard";

    export default {
        name: "distribution-item",
        props: {
            distribution: {
                type: Object
            },
            property_id: {
                type: Number
            },
            distribution_statistic: {
                type: Array | Function
            }
        },
        mixins: [AccessControlComponent, getErrorMessage],
        data() {
            return {
                C_PROPERTY_CM_PM_PROPERTY_DEACTIVATE_V,
                C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_V,
                C_PROPERTY_CM_SYNC_LOG_V,
                C_PROPERTY_CM_PM_PROPERTY_V,
                AC_CHANNEL_MANAGER,
                C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_TEST_V,
                routeNames,
                active_unit: null,
                inactive_unit: null,
                data_t: [],
                dataCollection: null,
                access_control_components:4,
                aside:false,
                showDeactivateDialog:false,
                loader:false,
                distribution_with_wizard:[BOOKING,EXPEDIA,HOTEL_BEDS,AIRBNB,AGODA],
                AIRBNB,
                ICAL
            }
        },
        components: {
            AirBnbActivationWizard,
            AppAccessControl,
            DistributionActivationWizard,
            FormHeader,
            EditDistribution,
            AppButton,
            DoughnutChart,
            AppAside,
            ModalDialog

        },
        computed: {
            customStyle() {
                return {
                    height: '60px',
                    position: 'relative',
                    width: '60px'
                }
            },
            calculatePercentage() {
                let sum = this.active_unit + this.inactive_unit
                if(!sum){
                    return 0
                }
                return ((this.active_unit / sum) * 100).toFixed(0)


            },
        },
        methods: {
            activate(request){
                this.loader = true
                activateProperty(request).then(() => {
                    toast({
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.aside =false
                    this.$router.push({name: RouteNames.RN_PROPERTY_CHANNEL_MANAGER_PRODUCT_MANAGEMENT, params: {id:this.property_id, distribution_id:this.distribution.id}})
                }, () => {
                    toast({
                        'title':this.$t('ERROR'),
                        'message': this.$t('ERROR_ACTIVATION'),
                        'type': 'error',
                        'timeout': 3000
                    })
                }).finally(()=>{
                    this.loader = false
                })
            },
            enablePropertyStatusActive(distribution) {
                if(this.distribution_with_wizard.includes(distribution)){
                    this.aside=true
                    return
                } else {
                    this.activate({distribution, property:this.property_id})
                }
            },
            disablePropertyStatusActive() {
                disablePropertyActivation({property: Number(this.property_id), distribution:this.distribution.id}).then(response => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.$emit('refreshData')

                }, error => {
                    toast({
                        'title': "Action  fail",
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 2000
                    })
                })
            },

            fillChartData(value) {

                this.dataCollection = {
                    labels: [this.$t("ACTIVE"), this.$t('INACTIVE')],
                    datasets: [
                        {
                            label: 'Data One',
                            backgroundColor: [
                                '#fb6b41',
                                '#e8e8e8'
                            ],
                            data: [value.active, value.inactive]
                        }
                    ]
                }
            }

        },
        watch: {
            distribution_statistic: {
                handler(value) {
                    this.active_unit = value.active
                    this.inactive_unit = value.inactive
                    this.fillChartData(value)
                }, immediate: true
            },

        },

    }
</script>

<style scoped>

</style>
