<template>
  <div>
    <b-row>
      <b-col>
        <form-header>{{ $t("IMPORT") }}</form-header>
      </b-col>
    </b-row>
    <template v-if="importEnabled">
      <b-form-file v-model="file"
                   @change="handleFileChange"
                   accept=".xlsx, .xls, .ods, .csv"
                   class="mb-4">
      </b-form-file>
      <b-row>
        <b-col>
          <app-button-submit class="mb-4 pull-right" @click="importPrices" :loading="loading">
            {{ $t('Upload') }}
          </app-button-submit>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import FormHeader from "@/components/app/form/FormHeader.vue";
import {
  C_PROPERTY_CALENDAR_PRICES_IMPORT_EXPORT_IMPORT_PRICES,
  C_UNIT_CALENDAR_PRICE_IMPORT
} from "@/shared/component_permission";
import AppButton from "@/components/app/AppButton/AppButton.vue";
import {bulkRelativePriceUploader} from "@/services/pricing";
import {notifySuccess} from "@/shared/plugins/toastr";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit.vue";
import ImportExportPricesForm from "@/components/pricing/ImportExportPricesForm.vue";

export default {
  name: 'BulkPriceUploaderImportExport',
  components: {ImportExportPricesForm, AppButtonSubmit, AppButton, FormHeader},
  props: {
    unit: Object,
    property: Object,
    mode: {
      type: String,
      default: 'list',
    },
  },
  data() {
    return {
      loading: false,
      file: null,
      C_UNIT_CALENDAR_PRICE_IMPORT,
      showImport: false,
    };
  },
  computed: {
    importEnabled() {
      return this.mode === 'list' ? this.checkPermission(C_PROPERTY_CALENDAR_PRICES_IMPORT_EXPORT_IMPORT_PRICES) : this.showImport
    },
  },
  methods: {
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    async importPrices() {
      if (!this.file) {
        alert('Please select a file to import!');
        return;
      }

      const fileType = this.file.type;
      const fileSize = this.file.size;

      if (!fileType.includes('csv') && !['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.oasis.opendocument.spreadsheet'].includes(fileType)) {
        alert('Only CSV, XLS, XLSX, and ODS files are allowed');
        return;
      }

      if (fileSize > 1024 * 1024) {
        alert('File size must be less than 1MB');
        return;
      }

      this.loading = true;
      const formData = new FormData();
      formData.append('pricing', this.file);

      try {
        await bulkRelativePriceUploader(formData);
        notifySuccess();
      } catch (error) {
        const errorList = error.response.data.error_list;
        let errorMessages = [];

        const extractErrorMessages = (list) => {
          list.forEach((item) => {
            if (typeof item === 'string') {
              errorMessages.push(item);
            } else if (item.hasOwnProperty('message')) {
              errorMessages.push(item.message);
            } else if (Array.isArray(item)) {
              extractErrorMessages(item);
            }
          });
        };

        if (Array.isArray(errorList)) {
          extractErrorMessages(errorList);
        } else {
          errorMessages.push(errorList);
        }

        if (errorMessages.length > 0) {
          alert(`An error occurred while uploading the file:\n${errorMessages.join('\n')}`);
        }
        this.loading = false;
      } finally {
        this.loading = false;
        this.$emit('input', false);
      }
    },
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
