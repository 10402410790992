<template>
    <div>
        <form @submit.prevent="setList" class="filter"
              @reset.prevent="resetForm">
            <b-row>
                <b-col md="2" class="mb-3">
                    <label>{{$t('NAME')}}</label>
                    <b-input v-model="filter.name"></b-input>
                </b-col>
                <b-col xl="2" lg="4" md="6" class="mb-3">
                    <label>{{$t('CATEGORY')}}</label>
                    <app-select v-model="filter.category"
                                :options="categoryOptions"
                                mode="multiselect"
                                text-field="label">
                    </app-select>
                </b-col>
                <b-col md="3" class="mb-3 d-flex align-items-baseline">
                    <app-button-submit class="filter-submit-wrapper" :inline="true" :disabled="loading" :loading="loaderSubmit" variant="primary"
                                       button_type="search">{{
                            $t("SEARCH") }}
                    </app-button-submit>
                    <app-button-reset :inline="true"  class="pl-2 ml-2" :disabled="loading" :loading="loaderReset" ></app-button-reset>
                </b-col>
            </b-row>
        </form>
    </div>
</template>

<script>
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {EventBus, GE_TABLE_SEARCH} from "@/shared/EventBus";
import {getAllThemeCategories} from "@/services/appearance";
export default {
    name: "AppearanceColorFilter",
    components:{AppButtonSubmit,AppButtonReset,AppSelect},
    data(){
        return {
            loading: false,
            loaderSubmit: false,
            loaderReset: false,
            filter:{
                name: null,
                category: []
            },
            categoryOptions: []
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods:{
        setList(){
            EventBus.$emit("SearchColorTable", this.formatRequest())
        },
        formatRequest(){
            return{
                name: this.filter.name,
                category: this.filter.category
            }
        },
        resetForm(){
            this.filter.name = null
            this.filter.category = []
            this.setList()
        }
    },
    created() {
        getAllThemeCategories({company_id: this.company.id}).then(response => {
            this.categoryOptions = response.data
        })
        EventBus.$on('SearchColorTableLoading', $event => {
            if(this.reset === true){
                this.loaderReset  = $event
            } else {
                this.loaderSubmit = $event
            }
            this.loading = $event
        })
    }
}
</script>

<style scoped>

</style>
