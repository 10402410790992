<template>
    <div>
        <b-row class="mb-4 mt-4">
            <b-col sm="5">
                <app-select :disabled="mappingUnitFormData.distribution_id === NEXTPAX" v-model="mappingUnitFormData.unit_eid" :options="externalUnitList"></app-select>
            </b-col>
            <b-col md="4">
                <save-unit-map   v-if="editedUnit" class="ml-2" small :disabled="!mappingUnitFormData.unit_eid || mappingUnitFormData.distribution_id === NEXTPAX" @click="updateUnitMap" :loading="unit_map_loader"></save-unit-map>
                <delete-unit-map :disabled="mappingUnitFormData.distribution_id === NEXTPAX" v-else @click="deleteUnitMap(mappingUnitFormData.id)"></delete-unit-map>
            </b-col>
        </b-row>
        <b-table v-if="mappingUnitFormData.id && formRatePlanMappingList.length > 0" :fields="fields" :items="formRatePlanMappingList">
            <template v-slot:external_rate_plan="data">
                <app-select :value="data.item.rate_plan_eid"
                            @input="updateRateMapping(data.item.id, $event)"
                            text-field="name_show"
                            :options="externalRatePlanList">

                </app-select>
            </template>
            <template v-slot:options="data">
                <app-button v-if="edited(data.item)" :disabled="!data.item.rate_plan_eid" :loading="ratePlanMappingLoader(data.item.id)"
                            @click="saveRatePlanMapping(data.item.id)">{{$t('SAVE')}}
                </app-button>
                <AppButton v-else
                           @click="()=>{
                    showDeleteDialog=true
                    idRateMap=data.item.rate_plan_mapping_id
                }"
                           button_type="delete"
                           :show_text="false"
                           variant="link">
                </AppButton>
            </template>
        </b-table>
        <unit-mapping-delete-dialog :show="showUnitDeleteDialog" @confirm="deleteDistributionUnitMapping"
                                    @cancel="showUnitDeleteDialog=false" :delete_title="true"></unit-mapping-delete-dialog>
        <rate-plan-mapping-delete-dialog :show="showDeleteDialog" @confirm="deleteDistributionRatePlanMapping"
                                         @cancel="showDeleteDialog=false" :delete_title="true"></rate-plan-mapping-delete-dialog>
    </div>
</template>

<script>
    import SaveRateMap from '@/components/app/AppButton/AppButtonSubmit'
    import SaveUnitMap from '@/components/app/AppButton/AppButtonSubmit'
    import {
        saveDistributionUnitMapping,
        deleteDistributionUnitMapping,
        saveDistributionRatePlanMapping,
        deleteDistribuionRatePlanMapping
    } from "@/services/channel_manager";
    import {getPricingRatePlanList} from "@/services/pricing";
    import DeleteRateMap from "@/components/app/AppButton/AppButtonDelete"
    import DeleteUnitMap from "@/components/app/AppButton/AppButtonDelete"
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import RatePlanMappingDeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import UnitMappingDeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import SaveStatus from "@/components/app/AppSubmit"
    import RatePlanItem from "@/components/channel_manager/product_management/RatePlanMappingItem"
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {cloneDeep, isEqual} from 'lodash'
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import {EventBus, GE_UPDATE_DATA_CHANNEL_MANAGER, GE_UPDATE_UNIT_CHANNEL_MANAGER,GE_UPDATE_EXT_RATE_PLAN_CHANNEL_MANAGER} from "@/shared/EventBus";
    import {EXPEDIA,NEXTPAX} from "@/shared/constants";


    export default {

        name: "unit-rate-product_management-edit",
        props: {
            unit: {
                type: Object
            },
            mappingUnitData: {
                type: Object
            },
            externalUnitList: {
                type: Array
            },
            externalRatePlanList: {
                type: Array
            }
        },
        mixins: [getErrorMessage],
        data() {
            return {
                idRateMap: null,
                idUnitMap: null,
                ratePlanMappingList: [],
                formRatePlanMappingList: [],
                externalRatePlan: null,
                showDeleteDialog: false,
                showUnitDeleteDialog: false,
                deleteState: false,
                externalStatusList: [],
                status: null,
                mappingUnitFormData: {},
                mappingRatePlanFormData: [],
                unit_map_loader:false,
                fields: [

                    {key: 'name', label: this.$t('RATE_PLAN')},
                    {
                        key: 'external_rate_plan',
                        label: this.$t('EXTERNAL_RATE_PLAN'),
                        tdClass: 'mb-0 mt-0 pb-2 pt-2 w-50'
                    },
                    {key: 'options', label: ''}
                ],
                rate_plan_map_loader:{
                    id:null,
                    loader:false
                },
                NEXTPAX,
                INBOUND:2
            }
        },
        components: {
            AppButtonDelete,
            AppButton,
            AppSelect,
            SaveRateMap,
            SaveUnitMap,
            DeleteRateMap,
            DeleteUnitMap,
            RatePlanMappingDeleteDialog,
            UnitMappingDeleteDialog,
            SaveStatus,
            RatePlanItem

        },
        computed: {
            editedUnit() {

                return Number(this.mappingUnitFormData.unit_eid) !== Number(this.mappingUnitData.unit_eid)
            }
        },
        methods: {

            edited(rateMapping) {
                if(!rateMapping.rate_plan_eid){
                    return true
                }
                return !isEqual(rateMapping, this.ratePlanMappingList.find(el => el.id === rateMapping.id))
            },
            ratePlanMappingLoader(id){
                if(this.rate_plan_map_loader.id === id){
                    return this.rate_plan_map_loader.loader
                }
                return false
            },
            updateRateMapping(rate_plan_id, value) {

                if (this.formRatePlanMappingList.length) {
                    let externalRateObject = {}
                    if (value) {
                        externalRateObject = this.externalRatePlanList.find(el => el.id === value)
                    }
                    let mappingFormObject = this.formRatePlanMappingList.find(el => el.id === rate_plan_id)
                    mappingFormObject.rate_plan_eid = value
                    mappingFormObject.rate_plan_external_name = typeof externalRateObject !== 'undefined' && externalRateObject.hasOwnProperty('name') ? externalRateObject.name : null
                }
            },
            updateUnitMap() {
                this.unit_map_loader = true
                saveDistributionUnitMapping(Number(this.unit.id), {
                    distribution: Number(this.$route.params.distribution_id),
                    unit_eid: this.mappingUnitFormData.unit_eid
                })
                    .then(response => {
                            toast({
                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 7000
                            })
                        EventBus.$emit(GE_UPDATE_UNIT_CHANNEL_MANAGER, {unit_mapping:response.data[0], unit_id:Number(this.unit.id)})
                        EventBus.$emit(GE_UPDATE_EXT_RATE_PLAN_CHANNEL_MANAGER, {distribution_id:Number(this.$route.params.distribution_id), unit_id:Number(this.unit.id)})
                        }, error => {
                            toast({
                                'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                                'type': 'error',
                                'timeout': 6000
                            })
                        }
                    ).finally(() => {
                        this.unit_map_loader = false
                })
            },
            deleteDistributionUnitMapping() {
                if (!this.idUnitMap) {
                    return
                }
                deleteDistributionUnitMapping(Number(this.unit.id), {
                    distribution: this.$route.params.distribution_id,
                    id: this.idUnitMap
                })
                    .then(() => {
                            this.showUnitDeleteDialog = false
                            toast({
                                'message': this.$t('NOTIFICATIONS.DELETE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 7000
                            })
                        EventBus.$emit(GE_UPDATE_DATA_CHANNEL_MANAGER, this.$route.params.distribution_id)

                        }, error => {
                            toast({
                                'message': this.$t('NOTIFICATIONS.DELETE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                                'type': 'error',
                                'timeout': 6000
                            })
                        }
                    )
            },
            deleteUnitMap(id) {
                this.showUnitDeleteDialog = true
                this.idUnitMap = id
            },
            prepareRequestRatePlanMapping(rate_plan_id) {
                let ratePlanMappingObject = this.formRatePlanMappingList.find(el => el.id === rate_plan_id)
                let requestData = {
                    rate_plan_id: rate_plan_id,
                    rate_plan_eid: ratePlanMappingObject.rate_plan_eid,
                    external_name: ratePlanMappingObject.rate_plan_external_name
                }
                return requestData
            },
            saveRatePlanMapping(rate_plan_id) {
            this.rate_plan_map_loader.id = rate_plan_id
            this.rate_plan_map_loader.loader = true

                saveDistributionRatePlanMapping(Number(this.$route.params.distribution_id), Number(this.unit.id), this.prepareRequestRatePlanMapping(rate_plan_id))
                    .then(response => {
                            toast({
                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 7000
                            })


                            this.$emit('updateRateMapping', response.data)

                        }, error => {
                            toast({
                                'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                                'type': 'error',
                                'timeout': 6000
                            })
                        }
                    ).finally(()=>{
                    this.rate_plan_map_loader.loader = false
                })
            },
            deleteDistributionRatePlanMapping() {

                deleteDistribuionRatePlanMapping(Number(this.$route.params.distribution_id), this.idRateMap, {unit: Number(this.unit.id)})
                    .then(response => {
                            toast({

                                'message': this.$t('NOTIFICATIONS.DELETE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 7000
                            })
                            this.showDeleteDialog = false
                            this.$emit('updateRateMapping', response.data)

                        }, error => {
                            toast({
                                'message': this.$t('NOTIFICATIONS.DELETE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                                'type': 'error',
                                'timeout': 6000
                            })
                        }
                    )
            },

            setRatePlanMappingList(ratePlanList) {
                this.ratePlanMappingList = []
                if (ratePlanList.length) {
                    ratePlanList.forEach(item => {
                        if(item.hasOwnProperty('distribution') && item.distribution && item.distribution.id === NEXTPAX){
                            if(Number(item.cm_communication_type) === this.INBOUND){
                                let object = {
                                    id: item.id,
                                    name: item.name,
                                    rate_plan_mapping_id:null,
                                    rate_plan_eid: null,
                                    rate_plan_external_name: null
                                }
                                if (this.mappingUnitData.hasOwnProperty('rate_plan_mapping')) {
                                    let mapping = this.mappingUnitData.rate_plan_mapping.find(el => el.rate_plan_id === object.id)
                                    if (typeof mapping !== 'undefined') {
                                        object.rate_plan_mapping_id = mapping.hasOwnProperty('id') ? mapping.id : null
                                        object.rate_plan_eid = mapping.hasOwnProperty('rate_plan_eid') ? mapping.rate_plan_eid : null
                                        object.rate_plan_external_name = mapping.hasOwnProperty('rate_plan_external_name') ? mapping.rate_plan_external_name : null
                                    }
                                }
                                this.ratePlanMappingList.push(object)
                            }
                        } else {
                            let object = {
                                id: item.id,
                                name: item.name,
                                rate_plan_mapping_id:null,
                                rate_plan_eid: null,
                                rate_plan_external_name: null
                            }
                            if (this.mappingUnitData.hasOwnProperty('rate_plan_mapping')) {
                                let mapping = this.mappingUnitData.rate_plan_mapping.find(el => el.rate_plan_id === object.id)
                                if (typeof mapping !== 'undefined') {
                                    object.rate_plan_mapping_id = mapping.hasOwnProperty('id') ? mapping.id : null
                                    object.rate_plan_eid = mapping.hasOwnProperty('rate_plan_eid') ? mapping.rate_plan_eid : null
                                    object.rate_plan_external_name = mapping.hasOwnProperty('rate_plan_external_name') ? mapping.rate_plan_external_name : null
                                }
                            }
                            this.ratePlanMappingList.push(object)
                        }

                        this.formRatePlanMappingList = cloneDeep(this.ratePlanMappingList)
                    })
                }
            }
        },

        created() {
            getPricingRatePlanList({
                unit: Number(this.unit.id),
                distribution: Number(this.$route.params.distribution_id),
                limit: 1000
            })
                .then(response => {
                        this.setRatePlanMappingList(response.data.items)
                    },
                    () => {
                        this.ratePlanList = []
                        this.ratePlanMappingList = []
                    })
        },
        watch: {
            mappingUnitData: {
                handler(object) {
                    this.mappingUnitFormData = cloneDeep(object)
                    this.setRatePlanMappingList(this.ratePlanMappingList)
                },
                immediate: true,
                deep: true
            }
        },
    }
</script>

<style scoped>
    .status-save-m {
        margin-top: 1.8rem
    }
</style>
