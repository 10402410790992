import http from '../http'
import {TRANSFER_URL} from "@/services/endpoints";


export function getList(data) {
    return http.request({
        url: TRANSFER_URL + '/reservations',
        method: 'post',
        data: data
    })
}


export function getRegionList(params) {
    return http.request({
        url: TRANSFER_URL + '/regions',
        method: 'get',
        params: params
    })
}

export function getLocationList(params) {
    return http.request({
        url: TRANSFER_URL + '/locations',
        method: 'get',
        params: params
    })
}

export function getRouteList(params) {
    return http.request({
        url: TRANSFER_URL + '/routes',
        method: 'get',
        params: params
    })
}

export function getDriverList(params) {
    return http.request({
        url: TRANSFER_URL + '/drivers',
        method: 'get',
        params: params
    })
}

export function getPropertyMapping(id) {
    return http.request({
        url: TRANSFER_URL + '/property-mapping/' + id,
        method: 'get'
    })
}

export function setPropertyMapping(id, data) {
    return http.request({
        url: TRANSFER_URL + '/property-mapping/' + id,
        method: 'post',
        data: data
    })
}

export function getTransferReservation(id,) {
    return http.request({
        url: TRANSFER_URL + '/reservation/' + id,
        method: 'get'
    })
}
export function UpdateTransferReservation(id,data) {
    return http.request({
        url: TRANSFER_URL + '/reservation/' + id,
        method: 'put',
        data: data
    })
}

export function setTransferReservation(id, data) {
    return http.request({
        url: TRANSFER_URL + '/reservation/' + id,
        method: 'put',
        data: data
    })
}

export function getPrice({route, driver, num_guests, return_transfer, reservation}) {
    return http.request({
        url: TRANSFER_URL + '/price/calculate-price',
        method: 'get',
        params: {route, driver, num_guests}
    })
}

export function setReturnTransferReservation(id, data) {
    return http.request({
        url: `${TRANSFER_URL}/reservation/${id}/return-transfer`,
        method: 'POST',
        data: data
    })
}
