<template>

    <app-aside :value="value" @input="$emit('input', false)" :widths="['col-lg-12','col-sm-12', '100%']" full-width
               >
        <template slot="header">
            <property-status-message-header></property-status-message-header>
        </template>
        <b-form @submit.prevent="save">

    <div class="table-wrapper">
        <table class="table">
            <thead>
            <tr>
                <th class="width-25">{{$t('CHANGE_TYPE')}}</th>
                <th class="width-25">{{$t('CHANGE')}}</th>
                <th class="width-35">{{$t('ADDITIONAL_INFO')}}</th>
                <th class="text-right"></th>
            </tr>
            </thead>
            <tbody>
            <tr :key="msgIndex" v-if="!message.for_finance" v-for="(message, msgIndex) in messages">

                <td v-if="message.hasOwnProperty('type')">
                    {{getTranslationType(message.type)}}
                </td>
                <td>
                                <p v-if="message.hasOwnProperty('list') && message.list.length > 0" :key="itemIndex+'_value'" v-for="(item, itemIndex) in message.list">
                                    {{getTranslationKey(item.id)}}
                                </p>
                </td>
                <td>
                    <template v-if="message.hasOwnProperty('value')">

                        <span v-if=" messageTypeBoldUnit.includes(Number(message.type))" v-html="formatTextBoldUnit(sanitizeText(message.value))">
                        </span>

                    <span v-else v-html="formatString(sanitizeText(message.value))"></span>
                    </template>
                    <p v-if="message.hasOwnProperty('list')" :key="itemIndex+'_value_msg'" v-for="(item, itemIndex) in message.list">
                        <span v-if="messageTypeBoldValue.includes(Number(message.type))" v-html="formatTextBoldUnit(sanitizeText(item.value))">

                        </span>
                        <span v-else-if="Number(message.type) ===TYPE_UNIT_LOS_OCCUPANCY_CHANGE" v-html="formatLosOccupancyText(sanitizeText(item.value))"></span>
                        <span v-else>
                             {{item.value}}
                        </span>

                    </p>
                </td>
                <td class="text-right">
                    <app-indicator class="mr-3" indicator_type="indicator-danger" v-if="message.important"></app-indicator>
                    <app-button
                        button_type="delete"
                        :show_text="false"
                        variant="link"
                        :c_permission="C_PROPERTY_STATUS_MESSAGE_E"  v-if="showDeleteButton(message)" :disabled="disabled"
                        @click="deleteMessage(msgIndex)"
                    ></app-button>
                </td>
            </tr>
            <tr v-if="!disabled">

                <td>
                    <app-select :disabled="disabled" v-model="messageType"
                                :options="message_type_list"></app-select>
                </td>
                <td >
                    <app-select mode="multiselect"  :disabled="message_items.length === 0" v-model="messageItem"
                                :options="message_items"></app-select>
                </td>
                <td >
                    <b-input :disabled="disabled" v-model="newCustomMessage"></b-input>
                </td>
                <td class="text-right">
                    <app-button :disabled="disabled || !(newCustomMessage || messageType)"
                                :c_permission="C_PROPERTY_STATUS_MESSAGE_E"
                                @click="addNewItemCustomMessage">
                        {{$t("NEW")}}
                    </app-button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
            <b-row v-if="!disabled">
                <b-col>
                    <app-submit :loading="saveLoader" :disabled="saveDisabled" :c_permission="C_PROPERTY_STATUS_MESSAGE_E"></app-submit>

                </b-col>
            </b-row>
        </b-form>
    </app-aside>


</template>

<script>
    import {getPrpMessage, setPrpMessage} from "@/services/property"
    import AppSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import {toast} from '@/shared/plugins/toastr'
    import {
        C_PROPERTY_STATUS_MESSAGE_E
    } from "@/shared/component_permission";
    import FormHeader from "@/components/app/form/FormHeader";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {isEqual, cloneDeep} from 'lodash'
    import AppAside from '@/components/app/form/AppAside'
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {
        TYPE,
        KEYS,
        TYPE_UNIT_ROOMS_CHANGE,
        TYPE_UNIT_CONTINGENTS_CHANGE,
        TYPE_UNIT_LOS_OCCUPANCY_CHANGE,
        TYPE_UNIT_FEE_CHANGE,
        TYPE_UNIT_KEY_PICKUP_CHANGE,
        TYPE_UNIT_AMENITIES_CHANGE, TYPE_UNIT_IMAGE_CHANGE
    } from "@/shared/prp_mesage_translation_enumeration";
    import AppIndicator from "@/components/app/indicators/AppIndicator";
    import PropertyStatusMessageHeader from "@/components/property/status/PropertyStatusMessageHeader";
    import {textFormatters} from "@/mixins/shared/helpers";

    export default {
        name: "PropertyStatusMessage",
        props: {
            value: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                message_type_list:[],
                db_mesages: [],
                messages: [],
                newCustomMessage: null,
                newMessage: null,
                message_items:[],
                messageType:null,
                messageItem:[],
                C_PROPERTY_STATUS_MESSAGE_E,
                translations:[],
                message_list_delete:[],
                TYPE,
                saveLoader:false,
                messageTypeBoldUnit:[TYPE_UNIT_AMENITIES_CHANGE,TYPE_UNIT_FEE_CHANGE, TYPE_UNIT_KEY_PICKUP_CHANGE,TYPE_UNIT_CONTINGENTS_CHANGE,TYPE_UNIT_IMAGE_CHANGE],
                messageTypeBoldValue:[TYPE_UNIT_ROOMS_CHANGE,TYPE_UNIT_CONTINGENTS_CHANGE],
                TYPE_UNIT_LOS_OCCUPANCY_CHANGE
            }
        },
        components: {
            PropertyStatusMessageHeader,
            AppIndicator,
            AppSubmit,
            AppSelect,
            AppButtonDelete,
            AppButton,
            FormHeader, AppAside
        },
        mixins: [getErrorMessage, textFormatters],
        methods: {
            formatTextBoldUnit(text){
                let str1 = "<b>"
                str1 += text
                let data  =  str1 !== null ? str1.replaceAll(":", "</b>"): null
                let t = data ?  data.replaceAll(";","<br><br><b>") : null
                let str  =  t !== null ? t.replaceAll(",", "<br><br>") : null
                return str
            },
            formatString(text) {
                let data  =  text !== null ? text.replaceAll(",", "<br><br>") : null
                return data !== null ? data.replaceAll(";", "<br><br>") : ''

            },

            formatLosOccupancyText(text){
                let str1 = "<b>"
                str1 += text
                let data  =  str1 !== null ? str1.replaceAll(":,", "</b><br><br>"): null
                let str = data ?  data.replaceAll(";","<br><br><b>") : null
                return str
            },
            getTranslationType(type){
               let name = null
                for(let el of this.TYPE){
                    if(el.id === type){
                        name = el.t
                        break
                    }
                }
                if(name){
                    return  this.$t(name)
                }
                return name;
            },
            getTranslationKey(id){
                let name = null
                for(let el of KEYS){
                    if(el.id === id){
                        name = el.t
                        break
                    }
                }
                if(name){
                    return  this.$t(name)
                }
                return name;
            },
            addNewItemCustomMessage() {
                let list_item = []
                if(this.messageItem.length > 0){
                    this.messageItem.forEach(el=>{
                        list_item.push({id:el, value:null})
                    })
                }
               this.messages.push({type: this.messageType,list:list_item, value: this.newCustomMessage, manual:1, new_item:true})
                this.messageType = null
                this.messageItem = []
                this.newCustomMessage = null
            },
            deleteMessage(index) {
                this.message_list_delete.push(this.messages[index])
                this.messages.splice(index, 1)
            },
            save() {
                let message_list_save = []
                for(let msgItem of this.messages){
                    if(msgItem.hasOwnProperty('new_item') && msgItem){
                        delete msgItem.new_item
                        msgItem.manual  = 1
                        message_list_save.push(msgItem)
                    }
                }
                setPrpMessage(this.$route.params.id, {
                    Delete:this.message_list_delete,
                    Message: message_list_save,
                }).then(response => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 5000
                    })
                    if (response.data && response.data.hasOwnProperty('Message')) {
                        this.messages = cloneDeep(response.data.Message)
                        this.db_mesages = cloneDeep(response.data.Message)
                    }

                }, (error) => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                    this.messages = []
                    this.db_mesages = []
                }).finally(()=>{
                    this.messageType = null
                    this.messageItem = []
                    this.newCustomMessage = null
                    this.saveLoader = false
                })
            },
            showDeleteButton(item){
                return !this.disabled && item.hasOwnProperty('manual') && item.manual
            }
        },
        computed: {

            saveDisabled() {
                return this.disabled || isEqual(this.db_mesages, this.messages)
            }
        },
        watch:{
            messageType:{
                handler(value){
                 let typeObject = this.message_type_list.find(el=>el.id === value)
                    this.message_items = []
                    if(typeObject && typeObject.hasOwnProperty('items') && typeObject.items.length){
                        typeObject.items.forEach(el =>{
                            let itemObject = {
                                id:el.id,
                                name:this.getTranslationKey(el.id)
                            }
                            this.message_items.push(itemObject)
                        })
                    }
                }
            }
        },
        created() {
            getPrpMessage(this.$route.params.id).then(response => {
                if (response.data && response.data.hasOwnProperty('Message')) {
                    this.messages = cloneDeep(response.data.Message)
                    this.db_mesages = cloneDeep(response.data.Message)
                    if(response.data.message_type_list.length > 0){
                        response.data.message_type_list.forEach(el =>{
                            let typeObject = {
                                id:el.id,
                                name:this.getTranslationType(el.id),
                                items:el.hasOwnProperty('items') ? el.items : []
                            }
                            this.message_type_list.push(typeObject)
                        })
                    }
                }

            }, () => {
                this.messages = []
                this.db_mesages = []
                this.message_type_list = []
            })
        }
    }
</script>

<style scoped>
    .width-35 {
        width: 35%;
    }
    .width-25 {
        width: 25%;
    }

    .table-wrapper {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
</style>
