<template>
    <b-card v-cloak class="property-wrapper">
        <template slot="header">
            <div class="d-flex justify-content-between flex-column flex-md-row">
                <div>
                    <h2>{{ $t('DISTRIBUTION_SETUP') }}</h2>
                </div>

                <div v-if="distribution">
                    <div class="header-caption pl-0 pl-md-2 mt-3 mt-md-0">{{ $t('DISTRIBUTION') }}</div>
                    <app-quick-link :value="distribution.id" :routes="distributionLinks"></app-quick-link>
                </div>
            </div>

            <!--            <h2 class="property-wrapper-padding">{{ distribution.name }}</h2>-->
            <Navigation :key="'fin_system_distribution_settings'+componentKey" :back="backButton"
                        :nav-elements="navElements">
            </Navigation>
        </template>

        <router-view>
        </router-view>
    </b-card>
</template>

<script>
import routeNames from "@/router/routeNames";
import {getDistribution, getDistributionList} from "@/services/distribution";


export default {
    name: "Index",
    components: {
        "AppQuickLink": () => import("@/components/app/AppQuickLink"),
        "Navigation": () => import("@/components/app/navigation/Navigation"),
    },
    data() {
        return {
            componentKey: 1,
            distribution: null,
            distributionList: []
        }
    },
    methods: {
        async getDistributions() {
            await getDistributionList({company: this.$store.getters['user/getCurrentCompany'].id, for_reservation_process: 1}).then(response => {
                this.distributionList = response.data
            });
        }
    },
    computed: {
        navElements() {
            return [
                //{route: {name: routeNames.RN_SYSTEM_DISTRIBUTION_GENERAL}},
                {route: {name: routeNames.RN_SYSTEM_DISTRIBUTION_PROPERTY}},
                {route: {name: routeNames.RN_SYSTEM_DISTRIBUTION_OWNER}},
                {route: {name: routeNames.RN_SYSTEM_DISTRIBUTION_UNIT}},
                {route: {name: routeNames.RN_SYSTEM_DISTRIBUTION_RATE_PLAN}}
            ]
        },
        backButton() {
            return {name: routeNames.RN_SYSTEM_RESERVATION_PROCESS}
        },
        distributionLinks() {
            return this.distributionList.map(distribution => {
                return {
                    id: distribution.id,
                    name: this.$route.name,
                    label: distribution.name,
                    params: {distribution_id: distribution.id}
                }
            })
        },
    },
    watch: {
        '$route.params.distribution_id': {
            handler(value) {
                if (value) {
                    getDistribution(value).then(res => {
                        this.distribution = res.data
                    })
                }
            },
            immediate: true
        },
    },
    mounted() {
        this.getDistributions()
    }

}
</script>

<style scoped>

</style>
