<template>
    <div>
        <reservation-setup-parameter
            :parameter_id="SETUP_PARAMETER_RESERVATION_NOTE_SETUP" :company_id="company.id"></reservation-setup-parameter>
    </div>
</template>

<script>

import {SETUP_PARAMETER_RESERVATION_NOTE_SETUP} from "@/shared/constants";
import ReservationSetupParameter from "@/components/reservation/setup/ReservationSetupParameter";

export default {
    name: "Index",
    components: {ReservationSetupParameter},
    data() {
        return {
            SETUP_PARAMETER_RESERVATION_NOTE_SETUP
        }
    },
    computed:{
        company(){
            return this.$store.getters['user/getCurrentCompany']
        }
    },
}
</script>

<style scoped>

</style>
