// User
export const F_USER_HIGH_SECURITY = 1591;

// COMPANY
export const F_COMPANY_SWITCH = 1304
export const F_COMPANY_WHITELABEL = 2306
export const C_COMPANY_WHITELABEL_SETUP = 2307
export const F_COMPANY_WHITELABEL_GUEST_BACKOFFICE_APPEARANCE = 2488
export const F_COMPANY_WHITELABEL_BOOKER_TOOLS_APPEARANCE = 2489

// INVOICE
export const F_INVOICE_CANCEL = 1852
export const F_INVOICE_CREATE = 1762
export const F_INVOICE_RESEND = 1853
export const F_HAS_OWN_CASH_REGISTER = 1872
export const F_INVOICE_DOWNLOAD = 2007;
export const F_INVOICE_REPORT_DOWNLOAD = 2346

// CALCULATION
export const F_CALCULATION_CREATE = 2108
export const F_CALCULATION_MENU = 2204;

// Calculation (Transaction)
export const F_CALCULATION_TRANSACTION_AUTHORIZE = 2519;
export const F_CALCULATION_TRANSACTION_PURCHASE = 2520;
export const F_CALCULATION_TRANSACTION_VOID = 2521;
export const F_CALCULATION_TRANSACTION_REFUND = 2522;
export const F_CALCULATION_TRANSACTION_CAPTURE = 2523;
export const F_CALCULATION_TRANSACTION_PAYMENT_ORDER = 2524;

// CC (Transaction)
export const F_TRANSACTION_AUTHORIZE = 1809;
export const F_TRANSACTION_PURCHASE = 1810;
export const F_TRANSACTION_VOID = 1811;
export const F_TRANSACTION_REFUND = 1812;
export const F_TRANSACTION_CAPTURE = 1813;
export const F_TRANSACTION_PAYMENT_ORDER = 2471;

// Reservation
// Accommodation
export const F_NO_CHARGE_PROFILE_AR = 1921;
export const F_SET_DEFAULT_CHANNEL = 2080;


export const F_MAIN_CALENDAR = 2072

export const F_INBOX = 2087
// revenue manager
export const F_REVENUE_MANAGER = 2160
export const F_REVENUE_MANAGER_UNIT_MIN_STAY_ACTIVATE = 2239;
export const F_REVENUE_MANAGER_UNIT_RATES_ACTIVATE = 2240;
//
export const F_DIRECT_BOOKING_ACTIVATION = 2557
export const F_DIRECT_BOOKING = 2174

export const F_REVENUE_MANAGER_ACTIVATION = 2175

//direct-booking
export const F_DIRECT_BOOKING_MARKETPLACE = 2243

