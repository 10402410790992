<template>
    <div>
        <app-table-v2 class="reservation_table" :show-sort="!searchOptions" search-event="getTransferListData"
                      :additional-sort-options="sortingFields"
                      :fields="fields" :provider="getData"
                      :generic-download-fields="genericDownloadFields"
                      :selectable="checkPermission(C_TRANSFER_RESERVATION_EXPORT)"
                      :show-limit="showLimit"
                      :refresh-event="EVENT_TABLE_REFRESH">
            <template v-slot:date="data">
                <small class="text-muted d-block">{{ data.item.transfer_time|time}}</small>
                {{ data.item.transfer_date|date}}
            </template>
            <template v-slot:guest_name="data">
                {{data.item.guest_firstname}}
                {{data.item.guest_lastname}}
            </template>
            <template v-slot:created_on="data">
                <small class="text-muted d-block">{{ data.item.created_on|time}}</small>
                {{data.item.created_on|date}}
            </template>
            <template v-slot:edit="data">
                <app-button button-class="action_button"
                            variant="link" target="_blank" :href="getVoucherLink(data.item.id, data.item.pdf_hash)"><i
                    class="fa fa-file-pdf-o"
                    aria-hidden="true"></i>
                </app-button>
                <app-button v-if="checkPermission(C_TRANSFER_RESERVATION_E)" button-class="action_button"
                            @click="editReservation(data.item.id)"
                            icon="edit"
                            :show_text="false"
                            variant="link">
                </app-button>
            </template>
        </app-table-v2>
        <app-aside v-model="showReservationAside">
            <template slot="header">{{$t("TRANSFER")}}</template>
            <transfer-reservation-form @reload="reload" :reservation-id="selectedReservationId"
                                       @addReturnTransfer="addReturnTransfer"></transfer-reservation-form>
        </app-aside>
    </div>
</template>

<script>
    import AppTableV2 from "@/components/app/AppTableV2";
    import {getList} from '@/services/transfer/index'
    import {EventBus, GE_TABLE_SEARCH} from "@/shared/EventBus";
    import {cloneDeep} from 'lodash'
    import AppButton from "@/components/app/AppButton/AppButton";
    import {API_CONFIG} from "@/services/config";
    import {API_URL} from "@/services/endpoints";
    import TransferReservationForm from "@/components/transfer/reservation/TransferReservationForm";
    import AppAside from "@/components/app/form/AppAside";
    import {C_TRANSFER_RESERVATION_E, C_TRANSFER_RESERVATION_EXPORT} from "@/shared/component_permission";

    export default {
        name: "TransferListTable",
        components: {TransferReservationForm, AppButton, AppTableV2, AppAside},
        props: {
            searchOnCreate: {
                type: Boolean,
                default: false
            },
            searchOptions: {
                type: Object,
                default: null
            },
            showLimit: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                filter: {},
                selectedReservationId: null,
                showReservationAside: false,
                C_TRANSFER_RESERVATION_E: C_TRANSFER_RESERVATION_E,
                C_TRANSFER_RESERVATION_EXPORT: C_TRANSFER_RESERVATION_EXPORT,
                genericDownloadFields:[
                    {key: 'id', label: this.$t("ID")},
                    {key: 'pickup.name', label: this.$t("FROM")},
                    {key: 'dropoff.name', label: this.$t("TO")},
                    {key: 'transfer_date', label: this.$t("_DATE")},
                    {key: 'transfer_time', label: this.$t("TIME")},
                    {key: 'guest_firstname', label: this.$t("FIRST_NAME")},
                    {key: 'guest_lastname', label: this.$t("LAST_NAME")},
                    {key: 'status', label: this.$t("STATUS")},
                    {key: 'message', label: this.$t("MESSAGE")},
                    {key: 'driver.name', label: this.$t("DRIVER")},
                    {key: 'total', label: this.$t("TOTAL")},
                    {key: 'discount', label: this.$t("DISCOUNT")},
                    {key: 'commission', label: this.$t("COMMISSION")},
                    {key: 'created_on', label: this.$t("BOOKED_AT")},
                ],
                EVENT_TABLE_REFRESH: 'refreshTransferList',
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            fields() {
                return [
                    {key: 'id', label: this.$t("ID")},
                    {key: 'pickup.name', label: this.$t("FROM")},
                    {key: 'dropoff.name', label: this.$t("TO")},
                    {key: 'date', label: this.$t("_DATE"), tdClass: 'ws-no-wrap remove_padding'},
                    {key: 'guest_name', label: this.$t("NAME")},
                    {key: 'status', label: this.$t("STATUS")},
                    {key: 'message', label: this.$t("MESSAGE"), tdClass: 'remove_padding w-25'},
                    {key: 'driver.name', label: this.$t("DRIVER")},
                    {key: 'total', label: this.$t("TOTAL"), tdClass: 'text-right'},
                    {key: 'discount', label: this.$t("DISCOUNT"), tdClass: 'text-right'},
                    {key: 'commission', label: this.$t("COMMISSION"), tdClass: 'text-right'},
                    {
                        key: 'created_on',
                        label: this.$t("BOOKED_AT"),
                        tdClass: 'ws-no-wrap remove_padding'
                    },
                    {key: 'edit', label: "", tdClass: 'ws-no-wrap'},
                ]
            },
            sortingFields() {
                return [
                    {key: 'transfer_date', label: this.$t("_DATE")},
                    {key: 'created_on', label: this.$t("BOOKED_AT")}
                ]
            }

        },
        methods: {
            getData(filter) {
                let req = {
                    page: filter.currentPage,
                    perPage: filter.perPage,
                    order_by: filter.sortBy,
                    order_by_direction: filter.sortDirection,
                    company: this.company.id,
                    ...this.filter,
                }
                return getList(req)
            },
            getVoucherLink(id, pdf_hash) {
                return API_CONFIG.API_URL + API_URL + '/transfer-reservation/' + id + '/voucher-pdf?hash=' + pdf_hash
            },
            editReservation(resId) {
                this.selectedReservationId = resId
                this.showReservationAside = true
            },
            reload(){
                EventBus.$emit(this.EVENT_TABLE_REFRESH)
                this.showReservationAside = false
            },
            addReturnTransfer(){
                EventBus.$emit("getTransferListData")
                this.showReservationAside = false
            },
        },
        mounted() {
            if (this.searchOptions !== null) {
                this.filter = {...this.filter, ...this.searchOptions}
            }

            if (this.searchOnCreate) {
                EventBus.$emit("getTransferListData")
            }

            EventBus.$on('transferListFilter', (filter) => {
                this.filter = cloneDeep(filter)
                EventBus.$emit("getTransferListData")
            })
        },
    }
</script>

<style scoped>

</style>
