import http from '../http'
import {RESTRICTION_DEFAULT_URL, RESTRICTION_URL, ROOM_STATUS_URL, UNIT_URL} from "@/services/endpoints";
import qs from 'qs'

export function getRestrictionList(params) {
  return http.request({
    url: RESTRICTION_URL + '/setup',
    method: 'get',
    params
  })
}

export function getRestrictionDefaultList(params) {
  return http.request({
    url: RESTRICTION_DEFAULT_URL,
    method: 'get',
    params
  })
}

export function updateDefaultRestrictions(data) {
  return http.request({
    url: RESTRICTION_DEFAULT_URL,
    method: 'put',
    data
  })
}

export function getRoomStatus(unit_id, params) {
  //Room Status
  return http.request({
    url: ROOM_STATUS_URL(unit_id),
    method: 'get',
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: true});
    }
  })
}

export function saveRoomStatus(unit_id, data) {
  //Room Status
  return http.request({
    url: ROOM_STATUS_URL(unit_id),
    method: 'post',
    data,
  })
}
export function saveRoomStatusTest(unit_id, data) {
    //Room Status
    return http.request({
        url: ROOM_STATUS_URL(unit_id),
        method: 'post',
        data,
    })
}
export function saveGroupRoomStatus(data) {
  //Room Status
  return http.request({
    url: UNIT_URL + '/bulk-sales-regulation',
    method: 'post',
    data,
  })
}
