<template>
    <div>
        <fixed-exchange-rate-filter @search="search">
            <app-button button_type="new" class="pull-right"
                        variant="primary"
                        @click="edit({})"
                        :c_permission="C_SETUP_FINANCE_CE_FIXED_NEW">
                {{ $t("NEW_FIXED_EXCHANGE_RATE") }}
            </app-button>
        </fixed-exchange-rate-filter>

        <app-table v-if="tableItems.length"
                   :busy="loading"
                   :items="tableItems"
                   :fields="fields">

            <template v-slot:actions="data">
                <app-button variant="link"
                            icon="edit"
                            :show_text="false"
                            @click="edit(data.item)">
                </app-button>
            </template>
        </app-table>
        <app-no-data v-else-if="!tableItems.length && !loading"></app-no-data>
        <app-pagination :listCount="total" v-model="filter.page" :last-page="lastPage" @change="search(filter, $event)"></app-pagination>

        <app-aside v-model="sidebarState">
            <template slot="header">{{ sidebarHeader }}</template>
            <fixed-exchange-rate-form :exchange-rate-object="exchangeRateObject"
                                         @created="create"
                                         @updated="update"
                                         @deleted="_delete">
            </fixed-exchange-rate-form>
        </app-aside>
    </div>

</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import {C_SETUP_FINANCE_CE_FIXED_NEW} from "@/shared/component_permission";
    import AppTable from "@/components/app/AppTable";
    import AppNoData from "@/components/app/AppNoData";
    import AppPagination from "@/components/app/filter/AppPagination";
    import {deleteCurrencyExchangeFixed, getCurrencyExchangeFixedList} from "@/services/finance/currency_exchange";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import FixedExchangeRateForm from "@/components/finance/setup/fixed_exchange_rates/FixedExchangeRateForm";
    import AppAside from "@/components/app/form/AppAside";
    import FixedExchangeRateFilter from "@/components/finance/setup/fixed_exchange_rates/FixedExchangeRateFilter";
    import {cloneDeep} from "lodash";
    export default {
        name: "Index",
        components: {
            FixedExchangeRateFilter,
            FixedExchangeRateForm, AppPagination, AppNoData, AppTable, AppButton, AppAside},
        data() {
            return {
                loading: false,
                sidebarState: false,
                exchangeRateObject: null,
                tableItems: [],
                total: null,
                lastPage: null,
                filter: {page: 1},
                fields: [
                    {key: 'source_currency.code', label: this.$t('FROM_CURRENCY')},
                    {key: 'destination_currency.code', label: this.$t('TO_CURRENCY')},
                    {key: 'value', label: this.$t('AMOUNT')},
                    {key: 'actions', label: '', class: 'text-right'}
                ],
                C_SETUP_FINANCE_CE_FIXED_NEW
            }
        },
        computed: {
            sidebarHeader() {
                return this.exchangeRateObject && this.exchangeRateObject.id ? this.$t('EDIT_FIXED_EXCHANGE_RATE') : this.$t('NEW_FIXED_EXCHANGE_RATE')
            },
            companyId() {
                return this.$store.getters['user/getCompany']
            },
        },
        methods: {
            edit(object) {
                this.exchangeRateObject = object
                this.sidebarState = true
            },
            create(object) {
                this.tableItems.unshift(object)
                this.sidebarState = false
            },
            update(object) {
                this.$set(this.tableItems, this.tableItems.findIndex(x => x.id === object.id), object)
                this.sidebarState = false
            },
            _delete(object) {
                this.loading = true
                deleteCurrencyExchangeFixed(object.id).then(() => {
                    this.tableItems.splice(this.tableItems.findIndex(x => x.id === object.id), 1)
                    notifySuccess()
                    if (this.page > 1) {
                        this.page = 1
                    } else {
                        this.search(this.filter, this.page)
                    }
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.loading = false
                    this.sidebarState = false
                })
            },
            getList() {
                this.loading = true
                getCurrencyExchangeFixedList({company: this.companyId, ...this.filter}).then(response => {
                    this.total = response.data.total
                    this.lastPage = response.data.last_page
                    this.tableItems = response.data.items
                }).finally(() => this.loading = false)
            },
            search(filter) {
                this.filter = cloneDeep(filter)
            }
        },
        watch: {
            filter: {
                handler() {
                    this.getList()
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>
