<template>
    <b-form @submit.prevent="save" v-if="loaded">
        <b-row>
            <b-col cols="12" xl="12">
                <draggable v-model="categories" handle=".handle">
                    <div v-for="(category, index) in categories" v-if="!category.deleted" :key="index" class="mb-2">
                        <div class="d-flex align-items-center">
                            <b-button block variant="outline-primary" class="text-left"
                                      @click="category.visible=!category.visible">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <i class="fa fa-bars mr-2 handle" style="cursor: move"></i>

                                        <b-badge pill variant="primary" class="mr-1 number-badge">
                                            {{ index + 1 }}
                                        </b-badge>
                                        {{ getLabel(category) }}
                                    </div>
                                    <i :class="`fa fa-caret-${category.visible ? 'up' : 'down'}`"></i>
                                </div>
                            </b-button>

                            <app-button
                                class="ml-2"
                                button_type="delete"
                                :show_text="false"
                                variant="link"
                                @click="removeItem(category, index)">
                            </app-button>
                        </div>

                        <b-collapse v-model="category.visible" :id="`category-${index}`">
                            <b-row class="mt-3">
                                <b-col v-if="!category.id" cols="12" xl="3" class="mb-4">
                                    <label>{{ $t('CONTENT_TYPE') }}</label>
                                    <app-select
                                        :value="category.contentType"
                                        @input="value => setContentAndCategoryType(category, value)"
                                        :options="contentTypeOptions"
                                        text-field="label"
                                        :search-empty-item="false">
                                    </app-select>
                                </b-col>

                                <b-col v-if="category.contentType && category.contentType === PREPARED_CONTENT"
                                       cols="12" xl="3" class="mb-4">
                                    <label>{{ $t('TEMPLATE') }}</label>
                                    <app-select
                                        v-model="category.html_template"
                                        :options="htmlTemplateList"
                                        text-field="label" return-type="object"
                                        :search-empty-item="false">
                                    </app-select>
                                </b-col>

                                <template
                                    v-if="category.contentType && category.contentType === DYNAMIC_SYSTEM_CONTENT">
                                    <b-col cols="12" xl="3" class="mb-4">
                                        <label>{{ $t('SOURCE') }}</label>
                                        <app-select
                                            v-model="category.source"
                                            @input="category.type = {id: CATEGORY_TYPE_CARD_LIST, label: null};category.source_filters = {}"
                                            :options="sourceOptions"
                                            text-field="label" :search-empty-item="false">
                                        </app-select>
                                    </b-col>

                                    <b-col cols="12" xl="3" class="mb-4">
                                        <label>{{ $t('MAX_NUMBER_OF_ITEMS') }}</label>
                                        <app-number-input
                                            :disabled="!category.source"
                                            v-model="category.source_filters.page_size"
                                            :max="15" :min="1">
                                        </app-number-input>
                                    </b-col>

                                    <b-col cols="12" xl="3" class="mb-4">
                                        <label>{{ $t('LOAD_MORE') }}</label>
                                        <b-form-checkbox
                                            v-model="category.pagination"
                                            :value="true" :unchecked-value="false" switch>
                                        </b-form-checkbox>
                                    </b-col>
                                </template>
                            </b-row>

                            <b-row>
                                <template v-if="category.contentType && category.contentType !== PREPARED_CONTENT">
                                    <b-col v-if="category.source" cols="12" xl="3" class="mb-4">
                                        <label>{{ $t('TAG') }}</label>
                                        <app-select
                                            v-model="category.source_filters.category"
                                            :options="customTagOptions"
                                            text-field="label">
                                        </app-select>
                                    </b-col>

                                    <b-col v-if="category.source === 'destinations'" cols="12" xl="3" class="mb-4">
                                        <label>{{ $t('GEOGRAPHIC_SCOPE') }}</label>
                                        <app-select
                                            v-model="category.source_filters.type"
                                            :options="destinationTypeOptions"
                                            text-field="label">
                                        </app-select>
                                    </b-col>

                                    <b-col v-if="category.source === 'listings'" cols="12" xl="3" class="mb-4">
                                        <label>{{ $t('DESTINATION') }}</label>
                                        <app-select
                                            v-model="category.source_filters.destination"
                                            :options="destinationTagOptions"
                                            search-input
                                            text-field="label">
                                        </app-select>
                                    </b-col>

                                    <b-col v-if="category.source === 'listings'" cols="12" xl="3" class="mb-4">
                                        <label>{{ $t('UNIT_TYPE') }}</label>
                                        <app-select
                                            v-model="category.source_filters['type[]']"
                                            :options="websiteUnitTypeOptions"
                                            text-field="label">
                                        </app-select>
                                    </b-col>
                                </template>
                            </b-row>

                            <template v-if="category.contentType">
                                <website-page-html-template-form
                                    v-if="category.contentType === PREPARED_CONTENT && category.html_template.id"
                                    class="mb-4"
                                    :template-config="getHtmlTemplateConfig(category.html_template.id)"
                                    :template-data="category.html_template_data"
                                    @uploadingImage="uploadingImage = $event"
                                    @update="category.html_template_data=$event">
                                </website-page-html-template-form>
                            </template>
                        </b-collapse>
                    </div>
                </draggable>

                <div class="d-flex justify-content-center align-items-baseline mt-4 mb-4">
                    <app-button button_type="new" @click="addItem()">
                        {{ $t('ADD_ITEM') }}
                    </app-button>

                    <app-button class="ml-3" button_type="new" @click="addItem(HTML_TEMPLATE_TYPE_SPACING)">
                        {{ $t('ADD_SPACING') }}
                    </app-button>
                </div>
            </b-col>
        </b-row>

        <b-row class="mt-3">
            <b-col>
                <app-button-submit :loading="loading" :disabled="uploadingImage"></app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {
    fetchWebsitePageContent, getUnitType,
    getWebsitePageContentSetupData,
    updateWebsitePageContent, uploadOrFetchWebsiteDocument
} from "@/services/direct_booking/website";
import _omit from 'lodash/omit'
import _pickBy from 'lodash/pickBy'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import {notifySuccess} from "@/shared/plugins/toastr";
import {getAllTags} from "@/services/tag";
import {TAG_TYPE_CUSTOM, TAG_TYPE_DESTINATION, TYPE_WEBSITE_GALLERY} from "@/shared/constants";

const TYPE_HTML = 3
const VALUE_TYPE_LIST = "list"
const VALUE_TYPE_DOCUMENT = "document"

const DYNAMIC_SYSTEM_CONTENT = 1
const PREPARED_CONTENT = 2

const CATEGORY_TYPE_LIST = 1
const CATEGORY_TYPE_CARD_LIST = 2

const HTML_TEMPLATE_TYPE_SPACING = 8

//todo fix: Set source to units freezes application if setFirst prop is set

export default {
    name: "WebsitePageContentForm",
    mixins: [getErrorMessage],
    components: {
        "AppNumberInput": () => import("@/components/app/form/AppNumberInput"),
        "WebsitePageHtmlTemplateForm": () => import("@/components/direct_booking/website/pages/WebsitePageHtmlTemplateForm"),
        "AppTranslationInput": () => import("@/components/app/translation/AppTranslationInput"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "FormHeader": () => import("@/components/app/form/FormHeader"),
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "draggable": () => import("vuedraggable")
    },
    data() {
        return {
            initialCategories: [],
            categories: [],
            loading: false,
            loaded: false,
            categoryTypeOptions: [],
            htmlTemplateList: [],
            customTagOptions: [],
            destinationTagOptions: [],
            websiteUnitTypeOptions: [],
            destinationTypeOptions: [],
            TYPE_HTML,
            uploadingImage: false,
            DYNAMIC_SYSTEM_CONTENT,
            PREPARED_CONTENT,
            CATEGORY_TYPE_CARD_LIST,
            HTML_TEMPLATE_TYPE_SPACING
        }
    },
    computed: {
        website() {
            return this.$store.getters['website/getWebsite']
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        page() {
            return this.$store.getters['website/getCurrentPage']
        },
        contentTypeOptions() {
            return [
                {id: DYNAMIC_SYSTEM_CONTENT, label: this.$t('DYNAMIC_SYSTEM_CONTENT')},
                {id: PREPARED_CONTENT, label: this.$t('PREPARED_CONTENT')}
            ]
        },
        sourceOptions() {
            return [
                {id: 'destinations', label: this.$t('DESTINATIONS')},
                {id: 'listings', label: this.$t('UNITS')},
            ]
        }
    },
    watch: {
        page: {
            immediate: true,
            handler(page) {
                this.loading = true
                fetchWebsitePageContent(this.website.id, page.id).then(response => {
                    this.initialCategories = _cloneDeep(response.data)
                    this.categories = response.data.map(el => ({
                        ...el,
                        visible: false,
                        contentType: this.getContentType(el)
                    }))
                }, error => this.showErrorMessages(error)).finally(() => this.loading = false)
            }
        }
    },
    methods: {
        getContentType(category) {
            if (category.type.id) {
                return category.type.id !== TYPE_HTML ? DYNAMIC_SYSTEM_CONTENT : PREPARED_CONTENT
            } else {
                return null
            }
        },
        setContentAndCategoryType(category, type) {
            category.title = {lang_id: null, value: null}
            category.subtitle = {lang_id: null, value: null}
            category.html_template = {id: null, label: null}
            category.html_template_data = null
            category.source = null
            category.type.id = type === PREPARED_CONTENT ? TYPE_HTML : null
            category.contentType = type
        },
        prepareCategorySource(category) {
            let sourceParams = ""
            const validKeys = Object.keys(_pickBy(category.source_filters, el => !!el))
            validKeys.forEach((key, index) => {
                sourceParams += `${key}=${category.source_filters[key]}`
                if (index < validKeys.length - 1) {
                    sourceParams += "&"
                }
            })
            return sourceParams ? `${category.source}?${sourceParams}` : category.source
        },
        saveAndPrepareCategoryDocuments(categories) {
            const recursivePreparation = (properties, promiseList, flatConfig, parentKey = null) => {
                return Object.entries(properties).reduce((result, [key, value]) => {
                    if (flatConfig[parentKey ? `${parentKey}_${key}` : key].type === VALUE_TYPE_DOCUMENT && value) {
                        if (value.id instanceof File) {
                            const formData = new FormData();
                            formData.append('document', value.id)
                            promiseList.push(
                                uploadOrFetchWebsiteDocument(this.website.id, TYPE_WEBSITE_GALLERY, formData).then(response => {
                                    result[key] = response.data.id
                                }, error => {
                                    this.showErrorMessages(error)
                                    result[key] = result[key].id
                                })
                            )
                        } else {
                            result[key] = value.id
                        }
                    } else if (Array.isArray(value)) {
                        result[key] = value.map(childProperties => recursivePreparation(childProperties, promiseList, flatConfig, key))
                    } else {
                        result[key] = value
                    }
                    return result
                }, {})
            }

            const promiseList = []
            categories.forEach((category, index) => {
                if (category.deleted || !category.changed || category.type.id !== TYPE_HTML) return;
                const flatConfig = this.getHtmlTemplateConfig(category.html_template.id, true)
                category.html_template_data = recursivePreparation(category.html_template_data, promiseList, flatConfig)
            })

            return Promise.all(promiseList)
        },
        save() {
            this.loading = true
            this.categories.forEach(el => el.visible = false)

            // we will avoid extra work on backend if the category hasn't been changed
            // but still sending it to make order setting easier

            // preparing object for comparison
            const initialCategories = this.initialCategories.reduce((result, current) => {
                result[current.id] = current
                return result
            }, {})

            // filter invalid, omit client helper properties and check if changed
            const preparedCategories = _cloneDeep(this.categories.filter(el => (
                // deleted flag bypasses validity check for the sake of deletion
                el.deleted || (el.type.id && ((el.type.id === TYPE_HTML && el.html_template_data) || (el.type.id !== TYPE_HTML && el.source)))
                //remove client added properties so equality check is not polluted by them
            ))).map(el => _omit(el, ['visible', 'contentType'])).map(el => {
                return {
                    ...el,
                    // prepare source string if there is a source defined (if data driven category)
                    source: el.source ? this.prepareCategorySource(el) : null,
                    changed: !el.id || !_isEqual(el, initialCategories[el.id]),
                }
            })

            this.saveAndPrepareCategoryDocuments(preparedCategories).then(() => {
                return updateWebsitePageContent(this.website.id, this.page.id, {list: preparedCategories})
            }).then(response => {
                notifySuccess()
                this.categories = response.data.map(el => ({
                    ...el,
                    visible: false,
                    contentType: this.getContentType(el)
                }))
            }).catch(error => this.showErrorMessages(error)).finally(() => this.loading = false)
        },
        addItem(type = null) {
            let item = {
                id: null,
                type: {id: null, label: null},
                //todo cleanup title/subtitle, unused fields
                title: {lang_id: null, value: null},
                subtitle: {lang_id: null, value: null},
                html_template: {id: null, label: null},
                html_template_data: null,
                source: null,
                source_filters: {},
                deleted: 0,
                //client attributes
                visible: true,
                contentType: null,
                pagination: false
            }

            if (type === HTML_TEMPLATE_TYPE_SPACING) {
                item.type = {id: TYPE_HTML}
                item.contentType = PREPARED_CONTENT
                item.html_template = this.htmlTemplateList.find(el => el.id === HTML_TEMPLATE_TYPE_SPACING)
            }

            this.categories.forEach(el => el.visible = false)
            this.categories.push(item)
        },
        removeItem(category, index) {
            this.categories.forEach(el => el.visible = false)
            if (category.id) {
                category.deleted = 1
            } else {
                this.categories.splice(index, 1)
            }
        },
        getLabel(category) {
            if (!category.contentType) return ''
            const contentTypeLabel = this.contentTypeOptions.find(el => el.id === category.contentType).label
            const suffix = category.contentType === DYNAMIC_SYSTEM_CONTENT
                ? (category.source ? ` – ${this.sourceOptions.find(el => el.id === category.source).label}` : '')
                : (category.html_template.label ? ` – ${category.html_template.label}` : '')
            const label = category.contentType === DYNAMIC_SYSTEM_CONTENT && category.title.value ? ` – ${category.title.value}` : ''
            return `${contentTypeLabel}${suffix}${label}`
        },
        getHtmlTemplateConfig(id, flat = false) {
            const config = this.htmlTemplateList.find(el => el.id === id).config
            if (flat) {
                return Object.entries(config).reduce((result, [key, value]) => {
                    result[key] = value
                    if (value.type === VALUE_TYPE_LIST) {
                        Object.entries(value.properties).forEach(([key2, value2]) => {
                            result[`${key}_${key2}`] = value2
                        })
                    }
                    return result
                }, {})
            } else {
                return config
            }
        },
    },
    created() {
        Promise.all([
            getWebsitePageContentSetupData({template: this.website.template.id}),
            getAllTags(TAG_TYPE_CUSTOM, {company: this.company.id}),
            getAllTags(TAG_TYPE_DESTINATION, {company: this.company.id}),
            getUnitType(this.website.id)
        ]).then(([setupResponse, customTagResponse, destinationTagResponse, websiteUnitTypeResponse]) => {
            this.categoryTypeOptions = setupResponse.data.category_types
            this.htmlTemplateList = setupResponse.data.html_templates
            this.destinationTypeOptions = setupResponse.data.destination_types
            this.customTagOptions = customTagResponse.data
            this.destinationTagOptions = destinationTagResponse.data
            this.websiteUnitTypeOptions = websiteUnitTypeResponse.data
        }, error => this.showErrorMessages(error)).finally(() => this.loaded = true)
    }
}
</script>

<style scoped>
.number-badge {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
}
</style>
