<template>
<form v-if="unitSetup !== null" class="pt-0 mt-0">
    <template v-if="enableWorkflowOptions">
        <b-row>
            <b-col>
                <form-header>
                    {{$t('WORKFLOW')}}
                </form-header>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <b-col cols="12" lg="6">
                <label>{{$t("WORKFLOW")}}</label>
                <app-select :options="workflowOptions"
                            v-model="unitSetup[MPL_WORKFLOW].id"
                            text-field="label"
                            :search-empty-item="false"
                ></app-select>
            </b-col>
        </b-row>
    </template>

    <b-row>
        <b-col>
            <form-header>
                {{$t('UNIT_TYPE')}}
            </form-header>
        </b-col>
    </b-row>
    <b-row class="mb-4">
        <b-col>
            <label>{{$t('UNIT_TYPE')}}</label>
            <app-select mode="multiselect"
                        :search-empty-item="false"
                        :options="unitTypeOptions"
                        v-model="unitSetup[MPL_UNIT_TYPE].type"
                        text-field="label"
            ></app-select>
        </b-col>
        <b-col>
            <label>{{$t('ACTIONS')}}</label>
            <app-select mode="select"
                        :search-empty-item="false"
                        :options="unitTypeOperation"
                        v-model="unitSetup[MPL_UNIT_TYPE].operator"
                        text-field="label"></app-select>
        </b-col>
    </b-row>

    <b-row>
        <b-col>
            <form-header>
                {{$t('RATE_PLAN')}}
            </form-header>
        </b-col>
    </b-row>
    <b-row class="mb-4">
        <b-col>
            <label>{{$t('RATE_PLAN')}}</label>
            <app-select mode="multiselect"
                        :search-empty-item="false"
                        :options="ratePlansOptions"
                        v-model="unitSetup[MPL_RATE_PLAN].type"
                        text-field="label"
            ></app-select>
        </b-col>
        <b-col>
            <label>{{$t('ACTIONS')}}</label>
            <app-select mode="select"
                        :search-empty-item="false"
                        :options="rateTypeOperation"
                        v-model="unitSetup[MPL_RATE_PLAN].operator"
                        text-field="label"></app-select>
        </b-col>
    </b-row>

    <b-row>
        <b-col>
            <form-header>
                {{$t('DESCRIPTIONS')}}
            </form-header>
        </b-col>
    </b-row>
    <b-row class="mb-4">
        <b-col v-for="list in unitSetup[MPL_DESCRIPTION].descriptions">
            <div class="d-flex align-items-baseline mb-2">
                <b-checkbox switch v-model="list.value"></b-checkbox>
                <label class="ml-0 pl-0" style="text-transform: capitalize">{{list.lang}}</label>
            </div>
            <div v-show="list.value">
                <label>{{$t('MIN_NUMBER_OF_CHARACTERS')}}</label>
                <b-input type="number" v-model="list.min_length"></b-input>
            </div>
        </b-col>
    </b-row>

    <b-row class="mb-4">
        <b-col cols="12" lg="6">
            <label>{{$t('ACTIONS')}}</label>
            <app-select mode="select"
                        :search-empty-item="false"
                        :options="descriptionOperation"
                        v-model="unitSetup[MPL_DESCRIPTION].operator"
                        text-field="label"></app-select>
        </b-col>
    </b-row>

    <b-row>
        <b-col>
            <form-header>
                {{$t('IMAGES')}}
            </form-header>
        </b-col>
    </b-row>
    <b-row class="mb-4 pb-4">
        <b-col cols="12" lg="6">
            <label>{{$t('MIN_NUMBER_OF_IMAGES')}}</label>
            <b-input type="number" v-model="unitSetup[MPL_IMAGE].min_number"></b-input>
        </b-col>
    </b-row>

    <b-row v-if="mode === 'save'" class="mt-4">
        <b-col>
            <app-button :loading="saveLoading" @click="save">{{$t('SAVE')}}</app-button>
        </b-col>
    </b-row>

    <b-row v-if="mode === 'saveSystem'" class="mt-4">
        <b-col>
            <app-button :loading="saveLoading" @click="saveSystem">{{$t('SAVE')}}</app-button>
        </b-col>
    </b-row>

</form>
</template>

<script>

import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButton from "@/components/app/AppButton/AppButton";
import FormHeader from "@/components/app/form/FormHeader";
import {
    getMarketplaceUnitSetup,
    getMarketplaceWorkflowOptions,
    getUnitTypeList, getUnitWorkflowOptions,
    saveMarketplaceUnitActivation
} from "@/services/marketplace";
import {getRatePlanType} from "@/services/pricing";
import {
    MPL_DESCRIPTION,
    MPL_UNIT_TYPE,
    MPL_IMAGE,
    MPL_RATE_PLAN,
    MPL_WORKFLOW, CM_COMMUNICATION_TYPE_DIRECT_BOOKING,
} from "@/shared/constants";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {EventBus} from "@/shared/EventBus";
import {notifySuccess} from "@/shared/plugins/toastr";

export default {
    name: "MarketplaceUnitActivationRules",
    mixins: [getErrorMessage],
    components:{AppSelect, AppButton, FormHeader},
    props:{
        websiteId:{
            type: Number,
            default: null
        },
        distributionId:{
            type: Number,
            default: null
        },
        mode:{
            type: String,
            default: null
        },
        companyId:{
            type: Number,
            default: null
        },
        enableWorkflowOptions: {
            type: Boolean,
            default: true,
        },
        unitActivationSetupObject: {
            type: Object,
            default: () => {
                return null
            }
        },
    },
    data(){
        return{
            unitTypeOptions: [],
            ratePlansOptions: [],
            workflowOptions: [],
            unitSetup: null,
            MPL_UNIT_TYPE,
            MPL_DESCRIPTION,
            MPL_IMAGE,
            MPL_RATE_PLAN,
            MPL_WORKFLOW,
            unitTypeOperation: [
                {id: 1, label: this.$t('IN_LIST')},
                {id: 2, label: this.$t('NOT_IN_LIST')}
            ],
            rateTypeOperation: [
                {id: 1, label: this.$t('ALL_SATISFY')},
                {id: 2, label: this.$t('INDIVIDUALLY_SATISFY')},
            ],
            descriptionOperation: [
                {id: 1, label: this.$t('ALL_SATISFY')},
                {id: 2, label: this.$t('INDIVIDUALLY_SATISFY')},
            ],
            saveLoading: false
        }
    },
    watch: {
        unitSetup: {
            handler() {
                if (this.unitSetup !== null){
                    this.$emit('update', {unit_activation_rules: this.prepareData()})
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods:{
        checkDescription(){
            return true
        },
        prepareData(){
            const list = []
            this.unitSetup[MPL_DESCRIPTION].descriptions.forEach(el => {
                el.value ? list.push({
                    'type': el.type.name,
                    'min_length': el.min_length
                }) : ''
            })
            return {
                workflow: {
                    id: this.unitSetup[MPL_WORKFLOW].id,
                    initial_status: this.unitSetup[MPL_WORKFLOW].initial_status
                },
                activation_rules:{
                    unit_type:{
                        operator: this.unitSetup[MPL_UNIT_TYPE].operator === 1 ? 'IN' : 'NOT_IN',
                        type: this.unitSetup[MPL_UNIT_TYPE].type !== null ? this.unitSetup[MPL_UNIT_TYPE].type.length === 0 ? null : this.unitSetup[MPL_UNIT_TYPE].type : null
                    },
                    description: {
                        operator: this.unitSetup[MPL_DESCRIPTION].operator === 1 ? 'AND' : 'OR',
                        descriptions: list.length === 0 ? null : list
                    },
                    image: {
                        min_number: this.unitSetup[MPL_IMAGE].min_number
                    },
                    rate_plan:{
                        operator: this.unitSetup[MPL_RATE_PLAN].operator === 1 ? 'AND' : 'OR',
                        type: this.unitSetup[MPL_RATE_PLAN].type !== null ? this.unitSetup[MPL_RATE_PLAN].type.length === 0 ? null : this.unitSetup[MPL_RATE_PLAN].type : null
                    }
                },
            }
        },
        save(){
            this.saveLoading = true
            saveMarketplaceUnitActivation({data: this.prepareData(), distribution_id: this.distributionId, company_id: this.companyId, setup_override: true}).then(response => {
                this.$emit('unit_setup_saved')
                notifySuccess()
                this.saveLoading = false
            }, error => {
                this.showErrorMessages(error)
                this.saveLoading = false
            })
        },
        saveSystem(){
            this.saveLoading = true
            saveMarketplaceUnitActivation({data: this.prepareData(), distribution_id: this.distributionId}).then(response => {
                this.$emit('unit_setup_saved')
                notifySuccess()
                this.saveLoading = false
            }, error => {
                this.showErrorMessages(error)
                this.saveLoading = false
            })
        }
    },
    created() {
        getUnitTypeList().then(response => {
            this.unitTypeOptions = response.data
        })
        getRatePlanType().then(response => {
            this.ratePlansOptions = response.data
        })
        if (this.enableWorkflowOptions) {
            getUnitWorkflowOptions().then(response => {
                this.workflowOptions = response.data
            })
        }
        if (this.unitActivationSetupObject !== null) {
            this.unitSetup = this.unitActivationSetupObject
        } else {
            let getSetupRequestObject = {}
            if (this.distributionId === null){
                getSetupRequestObject.default_setup = true
                getSetupRequestObject.communication_type = CM_COMMUNICATION_TYPE_DIRECT_BOOKING
            }
            getSetupRequestObject.distribution_id = this.distributionId
            if (this.mode === 'save') {
                getSetupRequestObject.company_id = this.companyId
                getSetupRequestObject.get_cm = true
            }
            getMarketplaceUnitSetup(getSetupRequestObject).then(response => {
                this.unitSetup = response.data
            })
        }

        // if (!this.enableWorkflowOptions && this.unitSetup !== null && typeof this.unitSetup === 'object' && this.unitSetup.hasOwnProperty(MPL_WORKFLOW)) {
        //     this.$delete(this.unitSetup, MPL_WORKFLOW)
        // }
    },
    mounted() {
        EventBus.$on('save_unit_activation_setup',()=>{
            saveMarketplaceUnitActivation({data: this.prepareData(), distribution_id: this.distributionId}).then(response => {
            }, error => {
                this.showErrorMessages(error)
            })
        })
        EventBus.$on('edit_unit_activation_setup', ()=>{
            saveMarketplaceUnitActivation({data: this.prepareData(), distribution_id: this.distributionId, company_id: this.companyId, setup_override: true}).then(response => {
            }, error => {
                this.showErrorMessages(error)
            })
        })
    },
    beforeDestroy() {
        // EventBus.$destroy('save_unit_activation_setup')
        // EventBus.$destroy('edit_unit_activation_setup')
    }
}
</script>

<style scoped>

</style>
