<template>
    <div class="property-wrapper mb-0 res-no-border">
        <div class="card-header">
            <div class="header-caption">{{object.type.label}}</div>
            <h2 class="property-wrapper-padding mb-2">
                <template v-if="object.type.id === THREAD_OBJECT_TYPE_RESERVATION">
                    {{object.details.reservation_code}}
                </template>
                <template v-else>
                    {{object.property.name}}
                </template>
            </h2>
            <div class="header-info">
                <template v-if="object.details.checkin_date">
                    {{object.details.checkin_date|date}} -
                </template>
                <template v-if="object.details.checkout_date">
                    {{object.details.checkout_date|date}} |
                </template>
                <template v-if="object.details && object.type.id !== support_type">
                    {{object.details.guest_no ? object.details.guest_no : 0}} {{$t('ADULTS').toLowerCase()}}, {{object.details.child_no ? object.details.child_no : 0 }} {{$t('CHILDREN').toLowerCase()}}, {{object.details.baby_no ? object.details.baby_no : 0}} {{$t('BABIES').toLowerCase()}} |
                </template>
                {{object.distribution.name}}
                <template v-if="object.hasOwnProperty('unit') && object.unit.name">
                    | {{object.unit.name}}
                </template>


            </div>
        </div>
    </div>
</template>

<script>
    import {THREAD_OBJECT_TYPE_RESERVATION} from '@/shared/constants'
    export default {
        name: "InboxHeader",
        props: {
            object: {
                type: Object,
                required: true,
            }
        },
        data(){
            return {
                THREAD_OBJECT_TYPE_RESERVATION,
                support_type:6
            }
        }
    }
</script>

<style scoped>
    .res-no-border {
        box-shadow: none;
    }

    .res-no-border > div {
        border: none !important;
        padding-left: 0;
    }
</style>
