<template>
    <b-form @submit.prevent="save" v-if="formData">
        <div class="d-flex">
            <div class="w-50">
                <b-row>
                    <app-access-control xl="4" lg="6" class="mb-4"
                                        v-slot:default="props">
                        <label>{{$t("TOTAL")}}</label>
                        <app-number-input :disabled="props.disabled || true" v-model="formData.total.channel"
                                          :append="formData.channel_currency.code"
                                          allow-decimal align="right">
                        </app-number-input>
                    </app-access-control>

                    <b-col xl="4" lg="6" class="mb-4">
                        <template v-if="company.main_currency.id !== formData.channel_currency.id">
                            <label>&zwnj;</label>
                            <app-number-input v-model="formData.total.company" :append="company.main_currency.code"
                                              allow-decimal align="right" disabled>
                            </app-number-input>
                        </template>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col xl="4" lg="6" class="mb-4">
                        <label>{{$t("COMMISSION_TOTAL")}}</label>
                        <app-number-input disabled v-model="formData.total_commission.channel"
                                          :append="formData.channel_currency.code"
                                          allow-decimal align="right">
                        </app-number-input>
                    </b-col>

                    <b-col xl="4" lg="6" class="mb-4">
                        <template v-if="company.main_currency.id !== formData.channel_currency.id">
                            <label>&zwnj;</label>
                            <app-number-input v-model="formData.total_commission.company"
                                              :append="company.main_currency.code"
                                              allow-decimal align="right" disabled>
                            </app-number-input>
                        </template>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col xl="4" lg="6" class="mb-4">
                        <label>{{$t("OWNER_PART")}}</label>
                        <app-number-input disabled v-model="formData.owner_part.channel"
                                          :append="formData.channel_currency.code"
                                          allow-decimal align="right">
                        </app-number-input>
                    </b-col>

                    <b-col xl="4" lg="6" class="mb-4">
                        <template v-if="company.main_currency.id !== formData.channel_currency.id">
                            <label>&zwnj;</label>
                            <app-number-input v-model="formData.owner_part.company" :append="company.main_currency.code"
                                              allow-decimal align="right" disabled>
                            </app-number-input>
                        </template>
                    </b-col>
                </b-row>
            </div>

            <div class="w-50" style="padding-left: 30px">
<!--                <b-row>-->
<!--                    <app-access-control md="6" class="mb-4"-->
<!--                                        v-slot:default="props">-->
<!--                        <label>{{$t("CHARGE_PROFILE")}}</label>-->
<!--                        <app-select mode="search"-->
<!--                                    v-model="formData.charge_profile"-->
<!--                                    text-field="name" :disabled="props.disabled || true">-->
<!--                        </app-select>-->
<!--                    </app-access-control>-->
<!--                </b-row>-->

                <b-row>
                    <b-col md="12" v-if="formData.charge_profile.id">
                        <label>{{$t('NOTE')}}</label>
                        <quill-editor v-model="formData.charge_profile.note" :options="{modules: {toolbar: false}}"
                                      disabled class="finance-qe"></quill-editor>
                        <!--                        <div v-html="formData.charge_profile.note"></div>-->
                    </b-col>
                </b-row>
            </div>
        </div>

        <b-row>
            <b-col>
                <form-header>{{$t('COMMISSION')}}</form-header>
            </b-col>
        </b-row>

        <b-row>
            <b-col xl="2" lg="3" sm="6" class="mb-4">
                <label style="white-space: nowrap">{{$t("SALES_CHANNEL_COMMISSION")}}</label>
                <app-number-input v-model="formData.channel_commission.channel"
                                  :append="formData.channel_currency.code"
                                  allow-decimal align="right" disabled>
                </app-number-input>
            </b-col>

            <b-col xl="2" lg="3" sm="6" class="mb-4">
                <template v-if="company.main_currency.id !== formData.channel_currency.id">
                    <label>&zwnj;</label>
                    <app-number-input v-model="formData.channel_commission.company" :append="company.main_currency.code"
                                      allow-decimal align="right" disabled>
                    </app-number-input>
                </template>
            </b-col>
        </b-row>


        <b-row>
            <b-col xl="2" lg="3" sm="6" class="mb-4">
                <label>{{$t("AGENCY_COMMISSION")}}</label>
                <app-number-input v-model="formData.agency_commission.channel"
                                  :append="formData.channel_currency.code"
                                  allow-decimal align="right" disabled>
                </app-number-input>
            </b-col>

            <b-col xl="2" lg="3" sm="6" class="mb-4">
                <template v-if="company.main_currency.id !== formData.channel_currency.id">
                    <label>&zwnj;</label>
                    <app-number-input v-model="formData.agency_commission.company" :append="company.main_currency.code"
                                      allow-decimal align="right" disabled>
                    </app-number-input>
                </template>
            </b-col>
        </b-row>


        <b-row>
            <b-col xl="2" lg="3" sm="6" class="mb-4">
                <label>{{$t("TRANSACTION_FEE")}}</label>
                <app-number-input v-model="formData.bank_commission.channel"
                                  :append="formData.channel_currency.code"
                                  allow-decimal align="right" disabled>
                </app-number-input>
            </b-col>

            <b-col xl="2" lg="3" sm="6" class="mb-4">
                <template v-if="company.main_currency.id !== formData.channel_currency.id">
                    <label>&zwnj;</label>
                    <app-number-input v-model="formData.bank_commission.company" :append="company.main_currency.code"
                                      allow-decimal align="right" disabled>
                    </app-number-input>
                </template>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import {fetchAccommodationReservationFinance} from "@/services/accommodation_reservation";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import FormHeader from "@/components/app/form/FormHeader";
    import {COMMISSION_TYPE_PERCENT} from "@/shared/constants";
    import {quillEditor} from 'vue-quill-editor'

    export default {
        name: "ReservationAccommodationFinanceForm",
        components: {FormHeader, AppSelect, AppNumberInput, AppAccessControl, quillEditor},
        mixins: [getErrorMessage, AccessControlComponent],
        props: {
            reservation: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                formData: null
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany'];
            }
        },
        methods: {
            save() {
                //
            },
            /*isPercentType(commissionType) {
                return Number(commissionType) === COMMISSION_TYPE_PERCENT
            }*/
        },
        created() {
            fetchAccommodationReservationFinance(this.reservation.id).then(response => {
                this.formData = response.data
            }, error => {
                this.showErrorMessages(error)
            })
        }
    }
</script>

<style scoped>
    .finance-qe /deep/ .ql-container {
        min-height: 122px;
    }
</style>
