<template>
    <b-row>
        <b-col cols="12">
            <table class="table table-hover">
                <tbody>

                <tr>
                    <td>{{$t("CHECKIN_DATE")}}:</td>
                    <td>{{reservation.check_in_date | date }}</td>
                </tr>
                <tr>
                    <td>{{$t("CHECKOUT_DATE")}}:</td>
                    <td>{{reservation.check_out_date | date }}</td>
                </tr>
                <tr>
                    <td>{{$t("NIGHTS")}}:</td>
                    <td>{{reservation.num_nights}}</td>
                </tr>
                <tr>
                    <td>{{$t("NUM_GUESTS")}}:</td>
                    <td>{{reservation.num_guests}}</td>
                </tr>
                <tr>
                    <td>{{$t("UNIT")}}:</td>
                    <td><span>{{reservation.unit.name}}</span><small class="ml-2 text-muted">{{reservation.property.name}}</small></td>
                </tr>
                <tr>
                    <td>{{$t("STATUS")}}:</td>
                    <td>{{reservation.status.name}}</td>
                </tr>
                <tr>
                    <td>{{$t("GUEST")}}:</td>
                    <td>{{reservation.guest.name}}</td>
                </tr>

                <tr>
                    <td>{{$t("CHANNEL")}}:</td>
                    <td><span>{{reservation.channel.name}}</span><small class="ml-2 text-muted">{{reservation.distribution.name}}</small></td>
                </tr>
                <tr>
                    <td>{{$t("TOTAL")}}:</td>
                    <td><span>{{reservation.company_total}}</span><small class="ml-2 text-muted">{{reservation.channel_total}}</small></td>
                </tr>
                <tr>
                    <td>{{$t("CREATED_AT")}}:</td>
                    <td>{{reservation.created_date | timestamp }}</td>
                </tr>
                </tbody>
            </table>

        </b-col>

        <b-col cols="12">
            <app-button
                v-if="checkPermission(R_RESERVATION_ACCOMMODATION_GENERAL)"
                @click="showReservationDetails(data.item)">
                {{$t("EDIT")}}
            </app-button>
        </b-col>

        <app-aside v-model="reservationDetailsAsideState" :widths="['col-lg-12','col-sm-12', '100%']" full-width
                   header-styles="position: sticky; top: 0; z-index: 999; background-color:#fff"
                   :header-classes="[]">
            <template slot="header">
                <reservation-accommodation-header :reservation="reservationDetailsObject"></reservation-accommodation-header>
            </template>
            <reservation-details-component :reservation-id="reservationDetailsObject.id"
                                           @update="reservationDetailsObject=$event"
                                           :tab="reservationDetailsTab"></reservation-details-component>
        </app-aside>
    </b-row>


</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import routeNames from "@/router/routeNames";
    import {R_RESERVATION_ACCOMMODATION_GENERAL} from "@/shared/route_permission";
    import ReservationDetailsComponent from '@/components/reservation/accommodation/ReservationDetails';
    import AppAside from "@/components/app/form/AppAside";
    import ReservationAccommodationHeader from "@/components/reservation/accommodation/ReservationAccommodationHeader";


    export default {
        name: "CalendarReservationPreview",
        components: {ReservationAccommodationHeader, AppButton, ReservationDetailsComponent, AppAside},
        props: {
            reservation: {
                type: Object
            }
        },
        data() {
            return {
                routeNames,
                R_RESERVATION_ACCOMMODATION_GENERAL,
                reservationDetailsObject: {},
                reservationDetailsAsideState: false,
                reservationDetailsTab: null,
            }
        },
        methods: {
            showReservationDetails(reservation, tab = null) {
                if (tab) {
                    this.reservationDetailsTab = tab
                }
                this.reservationDetailsObject = reservation
                this.reservationDetailsAsideState = true
            },
        }
    }
</script>

<style scoped>

</style>
