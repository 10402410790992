<template>
    <div>
        <b-row>
            <b-col xl="6" md="12" class="mb-4">
                <label>{{ $t('TEMPLATE') }}</label>
                <app-input-control :error-object="validation.event_template">
                    <template #input="data">
                        <app-select
                            :state="data.state"
                            v-model="eventData_.action_data.event_template"
                            mode="search"
                            :search="searchEventTemplates"
                            :disabled="!eventData_.editable"
                            text-field="label">
                        </app-select>
                    </template>
                </app-input-control>

            </b-col>
        </b-row>
    </div>
</template>

<script>
import {getEventTemplates} from "@/services/event_template";
import {NOTIFICATION_CHANNEL_MAIL} from "@/shared/constants";
import {cloneDeep as _cloneDeep} from "lodash"

export default {
    name: "ReservationAccommodationSchedulerNotificationForm",
    components: {
        "AppInputControl": () => import("@/components/app/AppInputControl"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect")
    },
    props: {
        eventData: {
            type: Object
        },
        validation: {
            type: Object
        }
    },
    data() {
        return {
            eventData_: null
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    watch: {
        eventData_: {
            deep: true,
            handler(data) {
                this.$emit('update', data)
            }
        }
    },
    methods: {
        searchEventTemplates(search) {
            return getEventTemplates({
                notification_channel: NOTIFICATION_CHANNEL_MAIL,
                company: this.company.id,
                name: search
            }).then(response => ({data: response.data.items.map(el => ({id: el.id, label: el.label}))}))
        }
    },
    created() {
        this.eventData_ = _cloneDeep({
            ...this.eventData,
            action_data: this.eventData.action_data || {
                event_template: null
            }
        })
    }
}
</script>

<style scoped>

</style>
