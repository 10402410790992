<template>
    <b-form @submit.prevent v-if="checkPermission(C_USER_NOTIFICATIONS_V)">
        <b-row v-if="templateEvents.length">
            <b-col class="mb-4">
                <b-form-checkbox
                    v-for="(eventTemplate, index) in templateEvents"
                    v-model="formData.notifications"
                    :key="index" switch
                    :value="eventTemplate.id">
                    {{ eventTemplate.event.label }}
                </b-form-checkbox>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <app-button-submit :inline="true" v-on:click="save"
                                   :c_permission="C_USER_NOTIFICATIONS_E"
                                   :loading="updating"></app-button-submit>
                <app-button v-on:click="sendNotification" :c_permission="C_USER_NOTIFICATIONS_E"
                            :loading="updating" :disabled="!formData.notifications.length" class="ml-3">
                    {{ $t('TEST') }}
                </app-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>

import {C_USER_NOTIFICATIONS_E, C_USER_NOTIFICATIONS_V} from "@/shared/component_permission";
import AppButtonSubmit from '@/components/app/AppButton/AppButtonSubmit'
import FormHeader from "@/components/app/form/FormHeader";
import {
    getUserNotifications,
    sendUserNotifications,
    updateUserNotifications
} from "@/services/user";
import {
    getEventNotificationTemplates
} from "@/services/event";
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppButton from "@/components/app/AppButton/AppButton";


export default {
    name: "UserNotificationForm",
    mixins: [getErrorMessage],
    data() {
        return {
            C_USER_NOTIFICATIONS_E,
            C_USER_NOTIFICATIONS_V,
            formData: {
                notifications: [],
            },
            templateEvents: [],
            updating: false,
            filter: {
                page: 1,
                limit: 1000,
                user: this.$route.params.id
            },
        }
    },
    components: {
        FormHeader,
        AppButtonSubmit,
        AppButton
    },
    created() {
        getEventNotificationTemplates(this.filter).then(res => {
            this.templateEvents = res.data.items
        })

        getUserNotifications(this.$route.params.id, this.filter).then(res => {
            this.formData.notifications = res.data
        })

    },
    methods: {
        save() {
            let notificationsList = this.getNotifications();

            this.updating = true;
            let data = {notifications: notificationsList};
            updateUserNotifications(this.$route.params.id, data).then(() => {
                notifySuccess();
                this.sendNotification();
            }, error => {
                this.showErrorMessages(error);
            }).finally(() => {
                this.updating = false;
            })

        },
        sendNotification() {

            let notificationsList = this.getNotifications();

            this.updating = true;
            let data = {notifications: JSON.stringify(notificationsList)};
            sendUserNotifications(this.$route.params.id, data).then(() => {
                notifySuccess();
            }, error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.updating = false;
            })

        },

        getNotifications() {
            let notifications = this.formData.notifications;
            return this.templateEvents.map((ChangedNotifications) => {
                const find = notifications.find(element => element === ChangedNotifications.id);
                ChangedNotifications.active = find ? 1 : 0;
                return {
                    event_template_id: ChangedNotifications.id,
                    active: ChangedNotifications.active
                }
            });
        }
    }
}

</script>

<style scoped>

</style>
