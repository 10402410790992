<template>
    <div v-if="!loading">
        <b-row v-if="salesPeriodsList.length">
            <b-col sm="12" :key="index" v-for="(salesPeriod, index) in salesPeriodsList">
                <b-card class="repeater-1">
                    <div slot="header">
                        <b-row>
                            <b-col>
                                <repeater-header class="d-flex align-items-center">
                                    {{salesPeriod.name}}
                                    <app-button :c_permission="C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_E"
                                                class="action_button"
                                                :show_text="false"
                                                variant="link"
                                                button_type="edit"
                                                @click="$emit('editSalesPeriod', salesPeriod.id)">
                                    </app-button>
                                </repeater-header>
                            </b-col>
                        </b-row>
                    </div>
                    <b-row class="mb-2" v-if="salesPeriod.county">
                        <b-col>
                            <div class="pl-3">
                                {{$t("REGION")}}: {{salesPeriod.county.name}}
                            </div>
                        </b-col>
                    </b-row>
                    <b-table responsive small hover :fields="fields" :items="salesPeriod.sales_periods">
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col>
                <app-no-data :tip="$t('TIP.NEW_SALES_PERIOD')">
                </app-no-data>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import RepeaterHeader from "@/components/app/form/RepeaterHeader";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppNoData from "@/components/app/AppNoData";
    import {C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_E} from "@/shared/component_permission";

    export default {
        name: "SalesPeriodsList",
        components: {
            RepeaterHeader,
            AppButton,
            AppNoData
        },
        data() {
            return {
                fields: [
                    {key: 'period_start', label: this.$t('PERIOD_START')},
                    {key: 'period_end', label: this.$t('PERIOD_END')}
                ],
                C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_E
            }
        },
        props: {
            salesPeriodsList: {
                type: Array,
                default() {
                    return []
                }
            },
            loading: {
                type: Boolean,
                default: true
            }
        }
    }
</script>

<style scoped>

</style>
