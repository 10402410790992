<template>
    <inventory-default-value-form :object_id="property.id" :object_type="OBJECT_TYPE_PROPERTY" :default-value-object="defaultValueObject">

    </inventory-default-value-form>
</template>

<script>
    import DefaultRestrictionsForm from "@/components/app/restrictions/DefaultRestrictionsForm";
    import FormHeader from "@/components/app/form/FormHeader";
    import InventoryDefaultValueForm from "@/components/inventory_default/InventoryDefaultValueForm";
    import {OBJECT_TYPE_PROPERTY} from "@/shared/constants";
    import {getInventoryDefaultValues} from "@/services/property";

    export default {
        name: "Index",
        components: {InventoryDefaultValueForm, FormHeader, DefaultRestrictionsForm},
        data(){
          return{
              price_amount:null,
              OBJECT_TYPE_PROPERTY,
              defaultValueObject:{}
          }
        },
        computed:{
            property() {
                return this.$store.getters['property/getProperty']
            }
        },
        watch:{
            property:{
              handler(object){
                  getInventoryDefaultValues({object:object.id, object_type:OBJECT_TYPE_PROPERTY}).then(response =>{
                      this.defaultValueObject = response.data
                  })
              },deep:true,
                immediate:true
            },
        }
    }
</script>

<style scoped>

</style>
