<template>
    <b-form @submit.prevent="save" v-if="selectedList">
        <b-row class="mt-3 mb-3">
            <b-col lg="6" class="mb-3">
                <label>{{$t('FOR_DATES')}}</label>
                <app-date-range-picker v-model="periodUse"></app-date-range-picker>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{$t('VALID_THROUGH')}}</label>
                <app-date-range-picker v-model="periodValid"></app-date-range-picker>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{$t('AVAILABLE')}}</label>
                <b-form-input type="number" v-model="number_of_codes"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button @click="save" :loading="loader">{{$t('SAVE')}}</app-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>

import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import AppButton from "@/components/app/AppButton/AppButton";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import {updatePromoCodes} from "@/services/promo_codes";
import {EventBus} from "@/shared/EventBus";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {notifySuccess} from "@/shared/plugins/toastr";

export default {
    name: "PromoCodeListEditForm",
    components:{AppDatePicker, AppButton, AppDateRangePicker},
    mixins: [getErrorMessage],
    props:{
        selectedList:{
            type: Array,
            default: null
        }
    },
    data(){
        return{
            use_from: null,
            use_to: null,
            valid_from: null,
            valid_to: null,
            number_of_codes: null,
            loader: false
        }
    },
    computed:{
        periodUse: {
            get() {
                return {
                    start: this.use_from,
                    end: this.use_to
                }
            },
            set({start, end}) {
                this.use_from = start
                this.use_to = end
            }
        },
        periodValid: {
            get() {
                return {
                    start: this.valid_from,
                    end: this.valid_to
                }
            },
            set({start, end}) {
                this.valid_from = start
                this.valid_to = end
            }
        },
    },
    methods:{
        save(){
            this.loader = true
            updatePromoCodes({
                list: this.selectedList,
                valid_dates: {from: this.valid_from, to:this.valid_to},
                for_dates: {from: this.use_from, to:this.use_to},
                available_count: this.number_of_codes
            }).then(response => {
                notifySuccess()
                this.loader = false
                this.$emit('codesEdited')
                EventBus.$emit('reloadPromoCodes')
            },error => {
                this.loader = false
                this.showErrorMessages(error)
            })
        },
    }
}
</script>

<style scoped>

</style>
