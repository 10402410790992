import { render, staticRenderFns } from "./InvoiceArticleForm.vue?vue&type=template&id=769a3a5e&scoped=true&"
import script from "./InvoiceArticleForm.vue?vue&type=script&lang=js&"
export * from "./InvoiceArticleForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "769a3a5e",
  null
  
)

export default component.exports