<template>
    <b-form @submit.prevent="save"
            autocomplete="off"
            v-show="formLoaded">
        <b-tabs v-model="activeTab" nav-class="tab_navigation-horizontal"
                content-class="tab_content-horizontal" pills
                justified class="mb-1">
            <b-tab :title="$t('GENERAL')">
                <b-row>
                    <app-access-control tag="b-col" md="6" class="mb-4"
                                        :access_control_context="{function: CM_SETUP_DISTRIBUTION_ADVERTISER_ID_E, key: access_control_fetch_key}"
                                        @AccessControlEvent="addToAccessControlCounter()"
                                        @updated="formLoaded = true">
                        <template v-slot:default="props">
                            <app-input-control :error-object="validationObject.advertiser_id">
                                <template v-slot:input="data">
                                    <label>{{ formData.advertiser_id.label }}</label>
                                    <b-input :disabled="props.disabled"
                                             v-model="formData.advertiser_id.value"
                                             :state="data.state">
                                    </b-input>
                                </template>
                            </app-input-control>
                        </template>
                    </app-access-control>
                    <app-access-control tag="b-col" md="6" class="mb-4"
                                        :access_control_context="{function: CM_SETUP_DISTRIBUTION_MERCHANT_NAME_E, key: access_control_fetch_key}"
                                        @AccessControlEvent="addToAccessControlCounter()">
                        <app-input-control :error-object="validationObject.merchant_name">
                            <template v-slot:input="data">
                                <label>{{ formData.merchant_name.label }}</label>
                                <b-input v-model="formData.merchant_name.value"
                                         :state="data.state">
                                </b-input>
                            </template>
                        </app-input-control>
                    </app-access-control>
                    <app-access-control tag="b-col" md="6" class="mb-4"
                                        :access_control_context="{function: CM_SETUP_DISTRIBUTION_RENTAL_AGREEMENT_E, key: access_control_fetch_key}"
                                        @AccessControlEvent="addToAccessControlCounter()">
                        <b-tooltip class="tooltiptext"
                                   :target="'rental_agreement_info_' + distribution"
                                   placement="top"
                                   triggers="hover">
                            {{ $t('RENTAL_AGREEMENT_TOOLTIP_MSG') }}
                        </b-tooltip>
                        <app-input-control :error-object="validationObject.rental_agreement">
                            <template v-slot:input="data">
                                <label>{{ formData.rental_agreement.label }}</label>
                                <i class="fa fa-question-circle-o ml-1 pull-right tooltip-help  mr-3 mt-1"
                                   :id="'rental_agreement_info_' + distribution"></i>
                                <b-input v-model="formData.rental_agreement.value"
                                         :state="data.state">
                                </b-input>
                            </template>
                        </app-input-control>
                    </app-access-control>
                </b-row>
                <app-access-control tag="b-row"
                                    :access_control_context="{function: CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_E, key: access_control_fetch_key}"
                                    @AccessControlEvent="addToAccessControlCounter()">
                    <template v-if="formData.home_away_4_2_setup.value.descriptions">
                        <b-col md="6" class="mb-4"
                               v-for="(description, key) in formData.home_away_4_2_setup.value.descriptions">
                            <label>{{ description.label }}</label>
                            <app-select mode="multiselect"
                                        v-model="formData.home_away_4_2_setup.value.descriptions[key].value"
                                        :options="formData.home_away_4_2_setup.options.descriptions"
                                        value-field="id"
                                        text-field="label">
                            </app-select>
                        </b-col>
                    </template>
                </app-access-control>
            </b-tab>
            <b-tab :title="$t('UNIT_ACTIVATION_RULES')">
                <div class="mt-0 pt-0">
                    <unit-activation-rules :distribution-id="distribution"
                                           :enable-workflow-options="false"
                                           @update="updateFormData">
                    </unit-activation-rules>
                </div>
            </b-tab>
        </b-tabs>
        <b-row>
            <b-col>
                <app-button-submit :loading="loading">
                    {{ $t('SAVE') }}
                </app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import AppAccessControl from "@/components/app/AppAccessControl";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {
    CM_SETUP_DISTRIBUTION_ADVERTISER_ID_E,
    CM_SETUP_DISTRIBUTION_MERCHANT_NAME_E,
    CM_SETUP_DISTRIBUTION_RENTAL_AGREEMENT_E,
    CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_E,
} from "@/shared/component_permission";
import {
    AC_CHANNEL_MANAGER_SETUP_PARAMETER_EDIT
} from "@/mixins/AccessControl/AccessControlEnumeration";
import {
    SETUP_PARAMETER_ADVERTISER_ID,
    SETUP_PARAMETER_HOME_AWAY_4_2_SETUP,
    SETUP_PARAMETER_MERCHANT_NAME,
    SETUP_PARAMETER_RENTAL_AGREEMENT, SETUP_PARAMETER_UNIT_ACTIVATION_RULES,
} from "@/shared/constants";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppInputControl from "@/components/app/AppInputControl";
import {
    CM_ADVERTISER_ID_NOT_VALID,
    CM_ADVERTISER_ID_REQUIRED,
    CM_MERCHANT_NAME_MAX_LENGTH_VALIDATION_FAILED,
    CM_MERCHANT_NAME_REQUIRED,
    CM_RENTAL_AGREEMENT_REQUIRED
} from "@/shared/error_codes";
import UnitActivationRules from "@/components/direct_booking/marketplace/MarketplaceUnitActivationRules";

const SETUP_PARAMETER_LIST = {
    [SETUP_PARAMETER_ADVERTISER_ID]: 'advertiser_id',
    [SETUP_PARAMETER_MERCHANT_NAME]: 'merchant_name',
    [SETUP_PARAMETER_RENTAL_AGREEMENT]: 'rental_agreement',
    [SETUP_PARAMETER_HOME_AWAY_4_2_SETUP]: 'home_away_4_2_setup',
    [SETUP_PARAMETER_UNIT_ACTIVATION_RULES]: 'unit_activation_rules',
};

export default {
    name: "HomeAwayStandardGeneralSetupForm",
    components: {
        UnitActivationRules,
        AppInputControl,
        AppSelect,
        AppButtonSubmit,
        AppAccessControl,
    },
    mixins: [AccessControlComponent, getErrorMessage],
    props: {
        distribution: {
            type: Number,
        },
        setupParameters: {
            type: Array,
            default() {
                return []
            },
        },
        setupSaving: {
            type: Boolean,
            default: false,
        },
        integrate: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formData: {
                advertiser_id: {},
                merchant_name: {},
                rental_agreement: {},
                home_away_4_2_setup: {
                    value: {
                        descriptions: {},
                    },
                    options: {},
                },
                unit_activation_rules: {},
            },
            validationObject: {
                advertiser_id: {
                    input: [
                        CM_ADVERTISER_ID_REQUIRED,
                        CM_ADVERTISER_ID_NOT_VALID,
                    ],
                },
                merchant_name: {
                    input: [
                        CM_MERCHANT_NAME_REQUIRED,
                        CM_MERCHANT_NAME_MAX_LENGTH_VALIDATION_FAILED,
                    ],
                },
                rental_agreement: {
                    input: [
                        CM_RENTAL_AGREEMENT_REQUIRED,
                    ],
                },
            },
            formLoaded: false,
            loading: false,
            access_control_fetch_key: AC_CHANNEL_MANAGER_SETUP_PARAMETER_EDIT,
            access_control_components: 4,
            CM_SETUP_DISTRIBUTION_ADVERTISER_ID_E,
            CM_SETUP_DISTRIBUTION_MERCHANT_NAME_E,
            CM_SETUP_DISTRIBUTION_RENTAL_AGREEMENT_E,
            CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_E,
            activeTab: 0,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCompany']
        }
    },
    watch: {
        setupParameters: {
            handler() {
                this.setSetupParameterValues()
            },
            immediate: true,
            deep: true,
        },
        setupSaving: {
            handler(value) {
                this.loading = value
            },
            immediate: true,
        },
    },
    methods: {
        setSetupParameterValues() {
            this.setupParameters.forEach(el => {
                if (el.hasOwnProperty('parameter_id') &&
                    Object.keys(SETUP_PARAMETER_LIST).indexOf(String(el.parameter_id)) > -1 &&
                    el.hasOwnProperty('values')) {
                    this.$set(this.formData[SETUP_PARAMETER_LIST[el.parameter_id]], 'parameter_id', el.parameter_id)
                    this.$set(this.formData[SETUP_PARAMETER_LIST[el.parameter_id]], 'label', el.label)
                    this.$set(this.formData[SETUP_PARAMETER_LIST[el.parameter_id]], 'options', el.options)
                    if (el.values !== null && typeof el.values === 'object') {
                        for (const [entity, values_data] of Object.entries(el.values)) {
                            this.$set(this.formData[SETUP_PARAMETER_LIST[el.parameter_id]], 'entity', entity)
                            values_data.forEach(value_data => {
                                if (value_data.hasOwnProperty('value')) {
                                    if (el.parameter_id === SETUP_PARAMETER_HOME_AWAY_4_2_SETUP) {
                                        let descriptions = value_data.value.descriptions
                                        descriptions.forEach((description) => {
                                            let descriptionObject = {
                                                label: description.label,
                                                value: description.value,
                                            }
                                            this.$set(this.formData[SETUP_PARAMETER_LIST[el.parameter_id]].value.descriptions, description.id, descriptionObject)
                                        })
                                    } else {
                                        this.$set(this.formData[SETUP_PARAMETER_LIST[el.parameter_id]], 'value', value_data.value)
                                    }
                                }
                            })
                        }
                    } else {
                        this.$set(this.formData[SETUP_PARAMETER_LIST[el.parameter_id]], 'value', null)
                    }
                }
            })
        },
        save() {
            this.loading = true
            let setupParameterList = [];
            for (const [key, setupObject] of Object.entries(this.formData)) {
                if (setupObject.parameter_id !== null) {
                    let setupParameterRequestItem = {
                        'parameter_id': setupObject.parameter_id,
                        'entity': setupObject.hasOwnProperty('entity') && setupObject.entity !== null ? setupObject.entity : null,
                        'value': setupObject.value,
                    }
                    setupParameterList.push(setupParameterRequestItem)
                }
            }

            this.$emit('saveSetup', setupParameterList)
        },
        updateFormData(args) {
            for (const [key, value] of Object.entries(args)) {
                if (this.formData.hasOwnProperty(key)) {
                    let formDataObject = this.formData[key]
                    formDataObject.value = value
                    this.$set(this.formData[key], 'value', value)
                }
            }
        },
    },
    created() {
        this.access_control_general_context = {
            distribution: this.distribution,
            company: this.company,
            integrate: this.integrate,
        }
    }
}
</script>

<style scoped>

</style>
