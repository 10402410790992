<template>

    <div v-if="companyCreatedDistribution">
        <app-access-control tag="b-row"
                            :access_control_context="{function: CM_SETUP_DISTRIBUTION_INTEGRATED_WITH_SETUP, distribution: distribution.id, key: AC_CHANNEL_MANAGER_SETUP_PARAMETER}"
                            @AccessControlEvent="addToAccessControlCounter()"
                            :key="'integrate_with_setup_' + distribution.id">
            <template v-slot:default="props">
                <b-col xl="3" lg="3" md="6"  class="mb-4">
                    <label class="label">{{ integrated_label }}</label>
                    <app-select :search-empty-item="false"
                                :value="integrated_value"
                                :options="select_integration_options"
                                :disabled="props.disabled"
                                @input="setIntegrated($event, true)">
                    </app-select>
                </b-col>
            </template>
        </app-access-control>

        <app-access-control tag="b-row"
                            :access_control_context="{function: CM_SETUP_DISTRIBUTION_INTEGRATED_WITHOUT_SETUP, distribution: distribution.id, key: AC_CHANNEL_MANAGER_SETUP_PARAMETER}"
                            @AccessControlEvent="addToAccessControlCounter()"
                            :key="'integrate_without_setup_' + distribution.id">
            <template v-slot:default="props">
                <b-col xl="3" lg="3" md="6"  class="mb-4">
                    <label class="label">{{ integrated_label }}</label>
                    <app-select :search-empty-item="false"
                                :value="integrated_value"
                                :options="select_integration_options"
                                :disabled="props.disabled"
                                @input="setIntegrated($event)">
                    </app-select>
                </b-col>
            </template>
        </app-access-control>

        <b-row>
            <app-access-control tag="b-col" xl="3" lg="3" md="6" class="mb-4"
                                :access_control_context="{function: CM_SETUP_DISTRIBUTION_ADVERTISER_ID_V, distribution: distribution.id, key: AC_CHANNEL_MANAGER_SETUP_PARAMETER}"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :key="'advertiser_id_overview_' + distribution.id">
                <label class="label">{{ formData.advertiser_id.label }}</label>
                <b-input :value="formData.advertiser_id.value"
                         :disabled="true">
                </b-input>
            </app-access-control>

            <app-access-control tag="b-col" xl="3" lg="3" md="6" class="mb-4"  @AccessControlEvent="addToAccessControlCounter()" :access_control_context="{function:CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_ID_V,distribution:distribution.id, key:AC_CHANNEL_MANAGER_SETUP_PARAMETER}">

                <label class="label">{{formData.legal_entity.label}}</label>
                <b-input disabled :value="formData.legal_entity.value" @input="updateLegalEntity($event)"></b-input>

            </app-access-control>

            <app-access-control tag="b-col" xl="3" lg="3" md="6" class="mb-4"
                                :access_control_context="{function: CM_SETUP_DISTRIBUTION_MERCHANT_NAME_V, distribution: distribution.id, key: AC_CHANNEL_MANAGER_SETUP_PARAMETER}"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :key="'merchant_name_overview_' + distribution.id">
                <label class="label">{{ formData.merchant_name.label }}</label>
                <b-input :value="formData.merchant_name.value"
                         :disabled="true">
                </b-input>
            </app-access-control>

            <app-access-control tag="b-col" xl="3" lg="3" md="6" class="mb-4"
                                :access_control_context="{function: CM_SETUP_DISTRIBUTION_RENTAL_AGREEMENT_V, distribution: distribution.id, key: AC_CHANNEL_MANAGER_SETUP_PARAMETER}"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :key="'rental_agreement_overview_' + distribution.id">
                <label class="label">{{ formData.rental_agreement.label }}</label>
                <b-input :value="formData.rental_agreement.value"
                         :disabled="true">
                </b-input>
            </app-access-control>
        </b-row>

        <app-access-control tag="b-row"
                            :access_control_context="{function: CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_V, distribution: distribution.id, key: AC_CHANNEL_MANAGER_SETUP_PARAMETER}"
                            @AccessControlEvent="addToAccessControlCounter()"
                            :key="'home_away_descriptions_overview_' + distribution.id">
            <template v-if="formData.home_away_4_2_setup.descriptions">
                <b-col :xl="Object.keys(formData.home_away_4_2_setup.descriptions).length % 3 === 0 ? '4' : '3'"
                       :lg="Object.keys(formData.home_away_4_2_setup.descriptions).length % 3 === 0 ? '4' : '3'"
                       md="6" class="mb-4"
                       v-for="(description, key) in formData.home_away_4_2_setup.descriptions">
                    <label class="label">{{ description.label }}</label>
                    <div class="border_not_last">
                    <span v-for="(selectedDescription, index) in description.value" v-if="formData.home_away_4_2_setup.options.descriptions">
                        {{ descriptionOptionLabel(selectedDescription) }}
                    </span>
                    </div>
                </b-col>
            </template>
        </app-access-control>
    </div>
    <b-row v-else>

        <app-access-control tag="b-col" xl="3" lg="3" md="6"  class="mb-4" @AccessControlEvent="addToAccessControlCounter()" :access_control_context="{function:CM_SETUP_DISTRIBUTION_INTEGRATED,distribution:distribution.id, key:AC_CHANNEL_MANAGER_SETUP_PARAMETER}">
            <label>{{integrated_label}}</label>
            <app-select :search-empty-item="false"  :value="integrated_value" :options="select_integration_options" @input="updateIntegrated($event)">
            </app-select>
        </app-access-control>
        <app-access-control tag="b-col" xl="3" lg="3" md="6" class="mb-4"  @AccessControlEvent="addToAccessControlCounter()" :access_control_context="{function:CM_SETUP_DISTRIBUTION_EXPEDIA_PROVIDER_NAME,distribution:distribution.id, key:AC_CHANNEL_MANAGER_SETUP_PARAMETER}">
            <label>{{formData.provider.label}}</label>
            <b-input :value="formData.provider.value" @input="updateProvider($event)"></b-input>
        </app-access-control>
        <app-access-control tag="b-col" xl="3" lg="3" md="6" class="mb-4"  @AccessControlEvent="addToAccessControlCounter()" :access_control_context="{function:CM_SETUP_DISTRIBUTION_AGODA_CHAIN_CODE,distribution:distribution.id, key:AC_CHANNEL_MANAGER_SETUP_PARAMETER}">
            <label>{{formData.chain_code.label}}</label>
            <b-input :value="formData.chain_code.value" @input="updateChainCode($event)"></b-input>
        </app-access-control>
        <app-access-control tag="b-col" xl="3" lg="3" md="6" class="mb-4"
                            :access_control_context="{function: CM_SETUP_DISTRIBUTION_ADVERTISER_ID, distribution: distribution.id, key: AC_CHANNEL_MANAGER_SETUP_PARAMETER}"
                            @AccessControlEvent="addToAccessControlCounter()">
            <template v-slot:default="props">
                <label>{{ formData.advertiser_id.label }}</label>
                <b-input :disabled="props.disabled"
                         :value="formData.advertiser_id.value"
                         @input="updateAdvertiserId($event)"></b-input>
            </template>
        </app-access-control>
        <app-access-control tag="b-col" xl="3" lg="3" md="6" class="mb-4"
                            :access_control_context="{function: CM_SETUP_DISTRIBUTION_MERCHANT_NAME, distribution: distribution.id, key: AC_CHANNEL_MANAGER_SETUP_PARAMETER}"
                            @AccessControlEvent="addToAccessControlCounter()">
            <label>{{ formData.merchant_name.label }}</label>
            <b-input :value="formData.merchant_name.value"
                     @input="updateMerchantName($event)"></b-input>
        </app-access-control>
        <app-access-control tag="b-col" xl="3" lg="3" md="6" class="mb-4"
                            :access_control_context="{function: CM_SETUP_DISTRIBUTION_RENTAL_AGREEMENT, distribution: distribution.id, key: AC_CHANNEL_MANAGER_SETUP_PARAMETER}"
                            @AccessControlEvent="addToAccessControlCounter()">
            <label>{{ formData.rental_agreement.label }}</label>
            <b-input :value="formData.rental_agreement.value"
                     @input="updateRentalAgreement($event)"></b-input>
        </app-access-control>
        <app-access-control tag="b-col" xl="3" lg="3" md="6" class="mb-4"  @AccessControlEvent="addToAccessControlCounter()" :access_control_context="{function:CM_SETUP_DISTRIBUTION_TA_LOGIN_DETAILS,distribution:distribution.id, key:AC_CHANNEL_MANAGER_SETUP_PARAMETER}">
            <label>Client id</label>
            <b-input :value="formData.client.client_id"  @input="updateClientId($event)"></b-input>
        </app-access-control>
        <app-access-control tag="b-col" xl="3" lg="3" md="6" class="mb-4"  @AccessControlEvent="addToAccessControlCounter()" :access_control_context="{function:CM_SETUP_DISTRIBUTION_TA_LOGIN_DETAILS,distribution:distribution.id, key:AC_CHANNEL_MANAGER_SETUP_PARAMETER}">
            <label>Client secret</label>
            <b-input :value="formData.client.client_secret" @input="updateClientSecret($event)"></b-input>
        </app-access-control>

        <app-access-control tag="b-col" xl="3" lg="3" md="6" class="mb-4"  @AccessControlEvent="addToAccessControlCounter()" :access_control_context="{function:CM_SETUP_DISTRIBUTION_HOTEL_BEDS_LOGIN_DETAILS,distribution:distribution.id, key:AC_CHANNEL_MANAGER_SETUP_PARAMETER}">
            <label>{{$t('USERNAME')}}</label>
            <b-input :value="formData.account.username" @input="updateUsername($event)"></b-input>
        </app-access-control>
        <app-access-control tag="b-col" xl="3" lg="3" md="6"  class="mb-4" @AccessControlEvent="addToAccessControlCounter()" :access_control_context="{function:CM_SETUP_DISTRIBUTION_HOTEL_BEDS_LOGIN_DETAILS,distribution:distribution.id, key:AC_CHANNEL_MANAGER_SETUP_PARAMETER}">
            <label>{{$t('PASSWORD')}}</label>
            <b-input type="password"  :value="formData.account.password" @input="updatePassword($event)" ></b-input>
        </app-access-control>

        <app-access-control tag="b-col" xl="3" lg="3" md="6" class="mb-4"  @AccessControlEvent="addToAccessControlCounter()" :access_control_context="{function:CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY,distribution:distribution.id, key:AC_CHANNEL_MANAGER_SETUP_PARAMETER}">

            <label>{{formData.legal_entity.label}}</label>
            <b-input :value="formData.legal_entity.value" @input="updateLegalEntity($event)"></b-input>

        </app-access-control>
    </b-row>

</template>

<script>

    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {EventBus, GE_UPDATE_CM_SETUP_PARAMETERS} from "@/shared/EventBus";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {
        CM_SETUP_DISTRIBUTION_INTEGRATED,
        CM_SETUP_DISTRIBUTION_TA_LOGIN_DETAILS,
        CM_SETUP_DISTRIBUTION_EXPEDIA_PROVIDER_NAME,
        CM_SETUP_DISTRIBUTION_HOTEL_BEDS_LOGIN_DETAILS,
        CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY,
        CM_SETUP_DISTRIBUTION_AGODA_CHAIN_CODE,
        CM_SETUP_DISTRIBUTION_ADVERTISER_ID,
        CM_SETUP_DISTRIBUTION_MERCHANT_NAME,
        CM_SETUP_DISTRIBUTION_RENTAL_AGREEMENT,
        CM_SETUP_DISTRIBUTION_INTEGRATED_WITH_SETUP,
        CM_SETUP_DISTRIBUTION_INTEGRATED_WITHOUT_SETUP,
        CM_SETUP_DISTRIBUTION_ADVERTISER_ID_V,
        CM_SETUP_DISTRIBUTION_MERCHANT_NAME_V,
        CM_SETUP_DISTRIBUTION_RENTAL_AGREEMENT_V,
        CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_V,
        CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_ID_V
    } from "@/shared/component_permission";
    import {AC_CHANNEL_MANAGER_SETUP_PARAMETER} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {
        TRIP_ADVISOR,
        HOTEL_BEDS,
        BOOKING,
        EXPEDIA,
        SETUP_PARAMETER_API_NAME,
        SETUP_PARAMETER_INTEGRATED,
        SETUP_PARAMETER_LOGIN_DETAILS,
        SETUP_PARAMETER_CHAIN_CODE,
        SETUP_PARAMETER_LEGAL_ENTITY,
        AGODA,
        E_DOMIZIL,
        CM_COMMUNICATION_TYPE_HOME_AWAY,
        SETUP_PARAMETER_ADVERTISER_ID,
        SETUP_PARAMETER_MERCHANT_NAME,
        SETUP_PARAMETER_RENTAL_AGREEMENT,
        SETUP_PARAMETER_HOME_AWAY_4_2_SETUP,

    } from '@/shared/constants';
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import RepeaterHeader from "@/components/app/form/RepeaterHeader";
    import {has} from "lodash";
    export default {
        name: "CommonSetupForm",
        props: {
            setupParameter: {
                type: Object
            },
            distribution:{
                type:Object
            },
            /* TODO: remove`companyCreatedDistribution` prop after implementing "same" logic for all distributions */
            companyCreatedDistribution: {
                type: Boolean,
                default: false,
            },
        },
        mixins: [getErrorMessage, AccessControlComponent],
        components: {RepeaterHeader, AppAccessControl, AppSelect},
        data() {
            return {
                integrated_value: null,
                select_integration_options: [
                    {id: 1, name: this.$t('YES')},
                    {id: 0, name: this.$t('NO')},
                ],
                CM_SETUP_DISTRIBUTION_INTEGRATED,
                CM_SETUP_DISTRIBUTION_INTEGRATED_WITH_SETUP,
                CM_SETUP_DISTRIBUTION_INTEGRATED_WITHOUT_SETUP,
                CM_SETUP_DISTRIBUTION_TA_LOGIN_DETAILS,
                AC_CHANNEL_MANAGER_SETUP_PARAMETER,
                CM_SETUP_DISTRIBUTION_EXPEDIA_PROVIDER_NAME,
                CM_SETUP_DISTRIBUTION_HOTEL_BEDS_LOGIN_DETAILS,
                CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY,
                CM_SETUP_DISTRIBUTION_AGODA_CHAIN_CODE,
                CM_SETUP_DISTRIBUTION_ADVERTISER_ID,
                CM_SETUP_DISTRIBUTION_ADVERTISER_ID_V,
                CM_SETUP_DISTRIBUTION_MERCHANT_NAME,
                CM_SETUP_DISTRIBUTION_MERCHANT_NAME_V,
                CM_SETUP_DISTRIBUTION_RENTAL_AGREEMENT,
                CM_SETUP_DISTRIBUTION_RENTAL_AGREEMENT_V,
                CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_V,
                CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_ID_V,
                TRIP_ADVISOR,
                HOTEL_BEDS,
                BOOKING,
                EXPEDIA,
                initial_integrated_value:null,
                integrated_label:null,
                access_control_components: 11,
                formData:{
                    provider:{
                        value:null
                    },
                    client:{
                        client_id:null,
                        client_secret:null,
                    },
                    legal_entity:{
                        value:null,
                        label:null
                    },
                    chain_code:{
                        label:null,
                        value:null
                    },
                    account:{
                        username:null,
                        password:null
                    },
                    advertiser_id: {
                        label: null,
                        value: null,
                    },
                    merchant_name: {
                        label: null,
                        value: null,
                    },
                    rental_agreement: {
                        label: null,
                        value: null,
                    },
                    home_away_4_2_setup: {
                        descriptions: {},
                        advertisers_index_url: null,
                        property_hierarchy_url: null,
                        options: {},
                    },
                }
            }
        },
        computed:{
            company_id() {
                return this.$store.getters['user/getCompany']
            },
        },
        methods: {
            setValues(setupObject) {
                if(!setupObject.hasOwnProperty('parameters')){
                    return
                }
                let integratedObject = setupObject.parameters.find(el => el.parameter_id === SETUP_PARAMETER_INTEGRATED)
                if(integratedObject.hasOwnProperty('values')){
                    this.integrated_label = integratedObject.label
                    this.integrated_value = integratedObject.values.company_value
                    this.initial_integrated_value = integratedObject.values.company_value
                }
                let providerObject = setupObject.distribution === EXPEDIA && setupObject.parameters.find(el => el.parameter_id === SETUP_PARAMETER_API_NAME)
                if(providerObject && providerObject.hasOwnProperty('values')) {
                    this.formData.provider.parameter_id = providerObject.parameter_id
                    this.formData.provider.label = providerObject.label
                    this.formData.provider.value = providerObject.values.company_value
                }
                let legalEntityObject = setupObject.distribution === BOOKING && setupObject.parameters.find(el => el.parameter_id === SETUP_PARAMETER_LEGAL_ENTITY)
                if(this._has(legalEntityObject,'values.company_value')) {
                    this.formData.legal_entity.value = legalEntityObject.values.company_value
                    this.formData.legal_entity.label = legalEntityObject.label
                }
                let clientObject = setupObject.distribution === TRIP_ADVISOR && setupObject.parameters.find(el => el.parameter_id === SETUP_PARAMETER_LOGIN_DETAILS)
                if(this._has(clientObject,'values.login_details')){
                    this.formData.client.client_id = clientObject.values.login_details.client_id
                    this.formData.client.client_secret =  clientObject.values.login_details.client_secret
                }

                let accountObject = setupObject.distribution === HOTEL_BEDS && setupObject.parameters.find(el => el.parameter_id === SETUP_PARAMETER_LOGIN_DETAILS)

                if(accountObject.hasOwnProperty('values') && accountObject.values.hasOwnProperty('login_details') ) {

                    this.formData.account.username = accountObject.values.login_details.username
                    this.formData.account.password =  accountObject.values.login_details.password
                }
                let chainCodeObject = setupObject.distribution === AGODA  && setupObject.parameters.find(el => el.parameter_id === SETUP_PARAMETER_CHAIN_CODE)
                if(chainCodeObject && chainCodeObject.hasOwnProperty('values')) {
                    this.formData.chain_code.label = chainCodeObject.label
                    this.formData.chain_code.value = chainCodeObject.values.company_value
                }

                let advertiserIdObject = (setupObject.distribution === E_DOMIZIL || setupObject.communication_type === CM_COMMUNICATION_TYPE_HOME_AWAY)
                    && setupObject.parameters.find(el => el.parameter_id === SETUP_PARAMETER_ADVERTISER_ID)
                if (advertiserIdObject && advertiserIdObject.hasOwnProperty('values')) {
                    this.formData.advertiser_id.label = advertiserIdObject.label
                    this.formData.advertiser_id.value = advertiserIdObject.values.company_value
                }

                let merchantNameObject = setupObject.communication_type === CM_COMMUNICATION_TYPE_HOME_AWAY
                    && setupObject.parameters.find(el => el.parameter_id === SETUP_PARAMETER_MERCHANT_NAME)
                if (merchantNameObject && merchantNameObject.hasOwnProperty('values')) {
                    this.formData.merchant_name.label = merchantNameObject.label
                    this.formData.merchant_name.value = merchantNameObject.values.company_value
                }

                let rentalAgreementObject = setupObject.communication_type === CM_COMMUNICATION_TYPE_HOME_AWAY
                    && setupObject.parameters.find(el => el.parameter_id === SETUP_PARAMETER_RENTAL_AGREEMENT)
                if (rentalAgreementObject && rentalAgreementObject.hasOwnProperty('values')) {
                    this.formData.rental_agreement.label = rentalAgreementObject.label
                    this.formData.rental_agreement.value = rentalAgreementObject.values.company_value
                }

                let homeAwayStandardObject = setupObject.communication_type === CM_COMMUNICATION_TYPE_HOME_AWAY
                    && setupObject.parameters.find(el => el.parameter_id === SETUP_PARAMETER_HOME_AWAY_4_2_SETUP)
                if (homeAwayStandardObject && homeAwayStandardObject.hasOwnProperty('values')
                    && homeAwayStandardObject.values.hasOwnProperty('system_value')) {
                    this.formData.home_away_4_2_setup.options = homeAwayStandardObject.options
                    let descriptions = homeAwayStandardObject.values.system_value.descriptions
                    descriptions.forEach((description) => {
                        let descriptionObject = {
                            label: description.label,
                            value: description.value,
                        }
                        this.$set(this.formData.home_away_4_2_setup.descriptions, description.id, descriptionObject)
                    })
                    this.formData.home_away_4_2_setup.advertisers_index_url = homeAwayStandardObject.values.system_value.advertisers_index_url
                    this.formData.home_away_4_2_setup.property_hierarchy_url = homeAwayStandardObject.values.system_value.property_hierarchy_url
                }

            },
            updateIntegrated(value){
                this.integrated_value  = value
                EventBus.$emit(GE_UPDATE_CM_SETUP_PARAMETERS,{distribution:this.distribution.id, parameter_id:SETUP_PARAMETER_INTEGRATED,value})
            },
            updateClientId(value){
                this.formData.client.client_id  = value
                EventBus.$emit(GE_UPDATE_CM_SETUP_PARAMETERS,{distribution:this.distribution.id, parameter_id:SETUP_PARAMETER_LOGIN_DETAILS,value:this.formData.client})
            },
            updateClientSecret(value){

                this.formData.client.client_secret  = value
                EventBus.$emit(GE_UPDATE_CM_SETUP_PARAMETERS,{distribution:this.distribution.id, parameter_id:SETUP_PARAMETER_LOGIN_DETAILS,value:this.formData.client})
            },
            updateUsername(value){
                this.formData.account.username  = value
                EventBus.$emit(GE_UPDATE_CM_SETUP_PARAMETERS,{distribution:this.distribution.id, parameter_id:SETUP_PARAMETER_LOGIN_DETAILS,value:this.formData.account})
            },
            updatePassword(value){
                this.formData.account.password  = value
                EventBus.$emit(GE_UPDATE_CM_SETUP_PARAMETERS,{distribution:this.distribution.id, parameter_id:SETUP_PARAMETER_LOGIN_DETAILS,value:this.formData.account})
            },
            updateLegalEntity(value){
                this.formData.legal_entity.value  = value
                EventBus.$emit(GE_UPDATE_CM_SETUP_PARAMETERS,{distribution:this.distribution.id, parameter_id:SETUP_PARAMETER_LEGAL_ENTITY,value:value})

            },
            updateProvider(value){
                this.formData.provider.value  = value
                EventBus.$emit(GE_UPDATE_CM_SETUP_PARAMETERS,{distribution:this.distribution.id, parameter_id:SETUP_PARAMETER_API_NAME,value:value})
            },
            updateChainCode(value){
                this.formData.chain_code.value  = value
                EventBus.$emit(GE_UPDATE_CM_SETUP_PARAMETERS,{distribution:this.distribution.id, parameter_id:SETUP_PARAMETER_CHAIN_CODE,value:value})
            },
            updateAdvertiserId(value) {
                this.formData.advertiser_id.value = value
                EventBus.$emit(GE_UPDATE_CM_SETUP_PARAMETERS, {
                    distribution: this.distribution.id,
                    parameter_id: SETUP_PARAMETER_ADVERTISER_ID,
                    value: value
                })
            },
            updateMerchantName(value) {
                this.formData.merchant_name.value = value
                EventBus.$emit(GE_UPDATE_CM_SETUP_PARAMETERS, {
                    distribution: this.distribution.id,
                    parameter_id: SETUP_PARAMETER_MERCHANT_NAME,
                    value: value
                })
            },
            updateRentalAgreement(value) {
                this.formData.rental_agreement.value = value
                EventBus.$emit(GE_UPDATE_CM_SETUP_PARAMETERS, {
                    distribution: this.distribution.id,
                    parameter_id: SETUP_PARAMETER_RENTAL_AGREEMENT,
                    value: value
                })
            },
            _has(object, path) {
                return has(object, path)
            },
            setIntegrated(value, integrationSetup = false) {
                // this.integrated_value  = value
                if (value) {
                    this.$emit('integrateDistribution', {distribution: this.distribution, integrationSetup: integrationSetup})
                }
            },
            descriptionOptionLabel(descriptionId)
            {
                let index = this.formData.home_away_4_2_setup.options.descriptions.findIndex(el => el.id === descriptionId)
                if (index < 0) {
                    return ''
                }
                return this.formData.home_away_4_2_setup.options.descriptions[index].label
            },
        },
        watch: {
            setupParameter: {
                handler(setupObject) {
                    if(typeof setupObject === 'undefined') {
                        return
                    }
                    this.setValues(setupObject)
                    this.access_control_components = this.companyCreatedDistribution ? 6 : 11
                },
                immediate: true
            },
            'formData.home_away_4_2_setup.advertisers_index_url': {
                handler(value) {
                    this.$emit('homeAwayRootFileUrl', {distribution: this.distribution.id, url: value})
                },
                immediate: true,
            },
            'formData.home_away_4_2_setup.property_hierarchy_url': {
                handler(value) {
                    this.$emit('homeAwayPropertyHierarchyFileUrl', {distribution: this.distribution.id, url: value})
                },
                immediate: true,
            },
        },
        created(){
            this.access_control_components = this.companyCreatedDistribution ? 6 : 11
            this.access_control_general_context = {
                company: this.company_id
            }
        }
    }
</script>

<style scoped>
    .border_not_last span:not(:last-child) {
        border-right: 1px solid lightgray;
        margin-right: .6rem;
        padding-right: .5rem;
    }
</style>
