<template>
    <div>
        <b-row>
            <b-col class="mb-3">
                <h4>
                    {{ $t("ITEMS_SELECTED", {value: property_ids.length}) }}
                </h4>
            </b-col>
        </b-row>
        <b-form @submit.prevent="save">
            <b-row>
                <b-col lg="12" md="12" class="mb-4">
                    <label v-if="submodule === ACTIVE_REVENUE_MANAGER_RATES">{{ $t('SECURED_VALUE_DECREASE') }} %</label>
                    <label v-else>{{ $t('SECURED_VALUE') }}</label>

                    <app-number-input v-model="secured_value"></app-number-input>
                </b-col>
                <b-col lg="12" md="12" class="mb-4">
                    <b-form-checkbox> {{ $t('RM_ACTIVATE_ALL_UNITS') }}</b-form-checkbox>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <app-button-submit :loading="loader"></app-button-submit>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import AppNumberInput from "@/components/app/form/AppNumberInput";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {setRevenueManagerActivateProperty} from "@/services/revenue_manager";
import {EventBus} from "@/shared/EventBus";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {
    ACTIVE_REVENUE_MANAGER_RATES,
    ACTIVE_REVENUE_MANAGER_RATES_EXTERNAL,
    DIRECT_BOOKER_COMPANY
} from "@/shared/constants";

export default {
    name: "RevenueManagerSetupGroupActivation",
    props: {
        property_ids: {
            type: Array,
            default() {
                return []
            }
        },
        submodule: {
            type: Number
        }
    },
    data() {
        return {
            secured_value: null,
            loader: false,
            ACTIVE_REVENUE_MANAGER_RATES,
        }
    },
    components: {AppButtonSubmit, AppNumberInput},
    mixins: [getErrorMessage],
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        submoduleComputed() {
            if (this.submodule === ACTIVE_REVENUE_MANAGER_RATES && this.company.id === DIRECT_BOOKER_COMPANY) {
                return ACTIVE_REVENUE_MANAGER_RATES_EXTERNAL
            }
            return this.submodule
        },
    },
    methods: {
        save() {
            this.loader = true
            const request = {
                submodule: this.submoduleComputed,
                secured_value: this.secured_value,
                property: []
            }
            for (const property_id of this.property_ids) {
                let reqPropertyObject = {
                    id: property_id,
                }

                request.property.push(reqPropertyObject)
            }
            setRevenueManagerActivateProperty(request).then(response => {
                this.$emit('close')
                EventBus.$emit('gl_revenue_setup_refresh_property')
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })
            }).finally(() => {
                this.loader = false
            })
        }
    }
}
</script>

<style scoped>

</style>
