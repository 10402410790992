<template>
    <b-form @submit.prevent="save" v-if="subscription !== null && subscriptionLoaded">
        <b-row>
            <b-col lg="12" class="mb-3">
                <app-input-control :error-object="validationErrorObject.expiration_date">
                    <template v-slot:input="data">
                        <label>{{ $t('EXPIRATION_DATE') }}</label>
                        <app-date-picker v-model="expirationDate"
                                         :min-date="currentDate"
                                         :state="data.state">
                        </app-date-picker>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button-submit class="filter-submit-wrapper"
                                   :inline="true"
                                   variant="primary"
                                   :loading="loading">
                </app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import AppInputControl from "@/components/app/AppInputControl";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import moment from "moment";
import {extendDemoSubscription, fetchActiveSubscription, reactivateDemoSubscription} from "@/services/company";
import {SUBSCRIPTION_PACKAGE_DEMO} from "@/shared/constants";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {SU_EXPIRATION_DATE_NOT_VALID} from "@/shared/error_codes";

export default {
    name: "ExtendSubscriptionForm",
    components: {AppButtonSubmit, AppDatePicker, AppInputControl},
    mixins: [getErrorMessage],
    props: {
        subscriptionId: {
            type: Number,
        },
        company: {
            type: Number,
        },
        reactivateSubscription: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            expirationDate: null,
            subscription: null,
            subscriptionLoaded: false,
            validationErrorObject: {
                expiration_date: {
                    input: [SU_EXPIRATION_DATE_NOT_VALID],
                },
            },
            loading: false,
        }
    },
    computed: {
        currentDate() {
            return moment(new Date()).format('YYYY-MM-DD')
        },
    },
    methods: {
        fetchSubscription() {
            fetchActiveSubscription(this.company, this.subscriptionId).then(response => {
                this.subscription = response.data
                if (Number(this.subscription.subscription_package_id !== SUBSCRIPTION_PACKAGE_DEMO)
                    || (Number(this.subscription.active) === 1 && this.reactivateSubscription)
                    || (Number(this.subscription.active) !== 1 && !this.reactivateSubscription)) {
                    this.subscription = null
                }
            }, () => {
                this.subscription = null
            }).finally(() => {
                this.subscriptionLoaded = true
            })
        },
        save() {
            this.loading = true
            let requestData = {expiration_date: this.expirationDate}
            if (this.reactivateSubscription) {
                reactivateDemoSubscription(this.company, this.subscription.id, requestData).then(() => {
                    notifySuccess()
                    this.$emit('subscriptionSaved')
                }, error => {
                    this.showErrorMessages(error, this.validationErrorObject)
                }).finally(() => {
                    this.loading = false
                })
            } else {
                extendDemoSubscription(this.company, this.subscription.id, requestData).then(() => {
                    notifySuccess()
                    this.$emit('subscriptionSaved')
                }, error => {
                    this.showErrorMessages(error, this.validationErrorObject)
                }).finally(() => {
                    this.loading = false
                })
            }
        },
    },
    created() {
        this.fetchSubscription()
    },
}
</script>

<style scoped>

</style>
