<template>
    <div v-if="!loading">
        <b-form @submit.prevent="save">
                        <b-row>
                <b-col class="col-6" v-for="tagOption in tagOptions">
                    <b-form-radio v-model="tag.id" :value="tagOption.id" :checked="tag.id && tag.id === tagOption.id" >
                        {{ tagOption.label }}
                    </b-form-radio>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mt-3">
                    <save-button :disabled="saveDisabled"></save-button>
                </b-col>
            </b-row>
        </b-form>
    </div>
    <div v-else class="d-flex justify-content-center">
        <b-spinner variant="primary"></b-spinner>
    </div>
</template>

<script>
import {
    getAllTags,
    getExistingTagsList,
    overrideTagForEntityList,
} from "@/services/tag";
import SaveButton from '@/components/app/AppButton/AppButtonSubmit'
import {notifySuccess} from '@/shared/plugins/toastr'
import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {TAG_PROPERTY_GALLERY} from "@/shared/constants";



export default {
    name: "TagForm",
    components: {SaveButton, AppSelect},
    props: {
        entity: {
            type: Array
        },
        tag_type: {
            type: Number
        },
        object_type: {
            type: Number
        },
    },
    mixins: [getErrorMessage],
    data() {
        return {
            tag:  {id: null, label: null},
            initialTagIdList: {},
            tagOptionsList: null,
            loading: true,
        }
    },
    computed: {
        saveDisabled() {
            return this.entity.length >= 1 &&
                (this.tag.id === null || this.tag.id === this.initialTagIdList[this.entity[0]]);
        },
        tagOptions() {
            if (this.tagOptionsList === null) {
                getAllTags(this.tag_type, {}).then(response => {
                    this.tagOptionsList = response.data
                })
            }
            return this.tagOptionsList
        },
    },
    methods: {
        save() {
            this.loading = true
            overrideTagForEntityList(this.object_type, this.tag.id, this.entity).then(response => {
                notifySuccess()
                if (this.entity.length === 1) {
                    let filteredTags = this.filterTags(response.data)
                    this.initialTagIdList = filteredTags.length > 0 ? filteredTags[0].id : null
                    this.tag = filteredTags.length > 0 ? filteredTags[0] : {id: null, label: null}
                }
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.$emit('tagSaved')
                this.loading = false
            })
        },
        filterTags(tagList) {
            let filteredTags = []
            tagList.forEach((tag) => {
                if (tag.id !== TAG_PROPERTY_GALLERY) {
                    filteredTags.push(tag)
                }
            })
            return filteredTags
        },
    },
    created() {
        this.tag.id = null
        this.loading = true
        getExistingTagsList(this.object_type, {tag_type_id: this.tag_type, entity_id: this.entity}).then(response => {
            let entityTags = []
            let filteredTags = []
            response.data.forEach(entity => {
                filteredTags = this.filterTags(entity.list)
                this.$set(this.initialTagIdList, entity.id, filteredTags.length > 0 ? filteredTags[0].id : null)
                filteredTags.forEach((tag) => {
                    if (entityTags.length === 0 || entityTags.findIndex(el => el.id === tag.id) < 0) {
                        entityTags.push(tag)
                    }
                })
            })
            if (entityTags.length === 1) {
                let selectedTag = entityTags[0]
                this.entity.forEach((entityId) => {
                    if (selectedTag.id !== null && entityTags.findIndex(el => el.id === this.initialTagIdList[entityId]) < 0) {
                        selectedTag =  {id: null, label: null}
                    }
                })
                this.tag = selectedTag
            }
        }, () => {

        }).finally(() => {
            this.loading = false
        })
    }
}
</script>

<style scoped>

</style>
