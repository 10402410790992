<template>
    <div v-if="formLoading">
        <b-spinner
            variant="primary">
        </b-spinner>
    </div>
    <div v-else>
        <form @submit.prevent="save">
            <template v-if="!singleUnit || !contingentMode">
                <b-row>
                    <b-col class="mb-3">
                        <b-form-radio-group
                            class="main_calendar-custom-radio fit-content-width"
                            v-model="value"
                            stacked
                            :options="options"
                            id="unit_availability_options_group"
                            name="unit_availability_options_group">
                        </b-form-radio-group>
                    </b-col>
                </b-row>
            </template>
            <template v-else>
                <b-row>
                    <b-col class="mb-3">
                        <b-form-radio-group
                            v-model="availabilityMode"
                            :options="availabilityModeOptions"
                            id="root_unit_availability_mode_options_group"
                            name="root_unit_availability_mode_options_group"
                            class="main_calendar-custom-radio">
                        </b-form-radio-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <app-number-input
                            :min="minValue"
                            :max="maxValue"
                            controls
                            v-model="value">
                        </app-number-input>
                    </b-col>
                </b-row>
            </template>
            <b-row v-if="showNote">
                <b-col class="mb-4">
                    <label>{{ $t("NOTE") }}</label>
                    <b-textarea
                        rows="3"
                        v-model="note">
                    </b-textarea>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <app-button-submit
                        v-if="checkPermission(C_PROPERTY_CALENDAR_AVAILABILITY_E)"
                        :disabled="saveDisabled"
                        :loading="loading">
                    </app-button-submit>
                </b-col>
            </b-row>
        </form>
    </div>
</template>

<script>
import AppNumberInput from "@/components/app/form/AppNumberInput";
import {get, has} from "lodash";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {C_PROPERTY_CALENDAR_AVAILIBILITY_E as C_PROPERTY_CALENDAR_AVAILABILITY_E} from "@/shared/component_permission";
import {setUnitAvailibility, setUnitBulkAvailabilityTest} from "@/services/unit";
import {EventBus, GE_CALENDAR_LOAD_AVAILABILITY, GE_CALENDAR_REFRESH_DATA} from "@/shared/EventBus";
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "CalendarEditAvailabilityForm",
    components: {AppButtonSubmit, AppNumberInput},
    mixins: [getErrorMessage],
    props: {
        unit: {
            type: Object,
            default: () => {
                return null
            },
        },
        units: {
            type: Array,
            default: () => {
                return []
            },
        },
        rootUnit: {
            type: Object,
            default: () => {
                return null
            },
        },
        contingentMode: {
            type: Boolean,
        },
        singleUnit: {
            type: Boolean,
            default: true
        },
        periods: {
            type: Array
        },
        defaultValues: {
            type: Object,
            default: null
        },
        allowedWeekday: {
            type: Array
        },
    },
    data() {
        return {
            value: null,
            note: null,
            availabilityMode: null,
            availabilityModeOptions: [
                {text: this.$t("SET_AVAILABILITY_UNIT_CALENDAR"), value: null},
                {text: this.$t("INCREASE_DECREASE"), value: 1},
            ],
            options: [
                {text: this.$t("AVAILABILITY_UNIT_CALENDAR_OPEN"), value: 1},
                {text: this.$t("AVAILABILITY_UNIT_CALENDAR_CLOSED"), value: 0},
            ],
            formLoading: false,
            loading: false,
            showNote: false,
            C_PROPERTY_CALENDAR_AVAILABILITY_E,
        }
    },
    computed: {
        minValue() {
            if (this.availabilityMode === null && this.defaultValues && has(this.defaultValues, "availability.absolute.min")) {
                return get(this.defaultValues, "availability.absolute.min")
            } else if (this.availabilityMode !== null && this.defaultValues && has(this.defaultValues, "availability.relative.min")) {
                return get(this.defaultValues, "availability.relative.min")
            } else {
                return Number.NEGATIVE_INFINITY
            }
        },
        maxValue() {
            if (this.availabilityMode === null && this.defaultValues && has(this.defaultValues, "availability.absolute.max")) {
                return get(this.defaultValues, "availability.absolute.max")
            } else if (this.availabilityMode !== null && this.defaultValues && has(this.defaultValues, "availability.relative.max")) {
                return get(this.defaultValues, "availability.relative.max")
            } else {
                return Number.POSITIVE_INFINITY
            }
        },
        saveDisabled() {
            return this.allowedWeekday.length === 0 || this.periods.length === 0 || (!this.singleUnit && (this.value === null || this.units.length === 0)) || (this.singleUnit && this.value === null)
        },
        isRootUnit() {
            if (!this.singleUnit || !this.unit || typeof this.unit !== "object") {
                return false
            }

            if (this.rootUnit) {
                return this.unit.id === this.rootUnit.id
            }

            if (this.unit.hasOwnProperty("contigent") && this.unit.contigent && this.unit.contigent.hasOwnProperty("parent")) {
                return !this.unit.contigent.parent
            }

            if (this.unit.has("parent_unit_id")) {
                return !this.unit.parent_unit_id
            }

            return false
        },
        rootUnitId() {
            if (this.isRootUnit) {
                return this.unit.id
            }

            if (this.unit.hasOwnProperty("contigent") && this.unit.contigent && this.unit.contigent.hasOwnProperty("parent") && this.unit.contigent.parent) {
                return this.unit.contigent.parent.id
            }

            if (this.unit.has("parent_unit_id")) {
                return this.unit.parent_unit_id
            }

            return null
        },
    },
    watch: {
        defaultValues: {
            handler(defaultValue) {
                if (defaultValue && defaultValue.hasOwnProperty("availability")) {
                    this.initialValue = this.value = defaultValue.availability.value
                    this.note = defaultValue.availability.note
                } else {
                    this.initialValue = this.value = this.note = null
                }
            },
            deep: true,
            immediate: true,
        },
        availabilityMode: {
            handler(value) {
                if (value) {
                    this.value = null
                } else {
                    if (this.defaultValues && this.defaultValues.hasOwnProperty("availability")) {
                        this.value = this.defaultValues.availability.value
                    }
                }
            },
        },
        value: {
            handler(value) {
                if (!this.singleUnit) {
                    this.showNote = this.value === 0
                    return
                }
                this.showNote = this.contingentMode ? true : (this.initialValue && !value) || (this.initialValue === 0 && !value) || (this.initialValue === null && value === 0)
                /*if (this.contingentMode) {
                    this.showNote = this.availabilityMode === 1 ? this.value === this.minValue : (this.initialValue && !value) || (this.initialValue === 0 && !value) || (this.initialValue === null && value === 0)
                    return
                }
                this.showNote = true*/
            },
            immediate: true,
        },
        periods: {
            handler() {
                if (!this.singleUnit) {
                    this.value = null
                    this.note = null
                }
            },
            deep: true,
            immediate: true,
        },
        units: {
            handler(value) {
                if (value.length > 1) {
                    this.value = null
                    this.note = null
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        save() {
            this.loading = true

            if (this.singleUnit) {
                this.saveSingleUnitAvailability()
            } else {
                this.saveMultipleUnitsAvailability()
            }
        },
        saveSingleUnitAvailability() {
            let request = {
                allowed_weekday: this.allowedWeekday,
                list: this.periods.map(period => {
                    return {
                        ...{
                            start: period.start,
                            end: period.end
                        },
                        ...{
                            unit: this.unit ? this.unit.id : this.rootUnit.id,
                            value: this.value,
                            contigent: this.contingentMode ? 1 : 0,
                            relative: this.availabilityMode === 1 ? 1 : 0,
                            note: this.note
                        }
                    }
                })
            }

            setUnitAvailibility(request).then(() => {
                EventBus.$emit(GE_CALENDAR_LOAD_AVAILABILITY, {
                    unitId: this.rootUnit ? this.rootUnit.id : this.rootUnitId,
                    contigentUnitId: !this.isRootUnit && this.unit ? this.unit.id : null,
                    all: this.contingentMode
                })
                EventBus.$emit(GE_CALENDAR_REFRESH_DATA, {
                    unitId: this.rootUnit ? this.rootUnit.id : this.rootUnitId,
                    contigentUnitId: !this.isRootUnit && this.unit ? this.unit.id : null,
                    all: this.contingentMode
                })
                notifySuccess()
            }, (error) => {
                EventBus.$emit(GE_CALENDAR_LOAD_AVAILABILITY, {
                    unitId: this.rootUnit ? this.rootUnit.id : this.rootUnitId,
                    contigentUnitId: !this.isRootUnit && this.unit ? this.unit.id : null,
                    all: !this.contingentMode
                })
                EventBus.$emit(GE_CALENDAR_REFRESH_DATA, {
                    unitId: this.rootUnit ? this.rootUnit.id : this.rootUnitId,
                    contigentUnitId: !this.isRootUnit && this.unit ? this.unit.id : null,
                    all: !this.contingentMode
                })
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
            })
        },
        saveMultipleUnitsAvailability() {
            setUnitBulkAvailabilityTest({
                periods: this.periods.map(period => {
                    return {
                        start: period.start,
                        end: period.end
                    }
                }),
                allowed_weekday: this.allowedWeekday,
                unit: this.units,
                value: this.value,
                note: this.note
            }).then(() => {
                this.units.forEach(unitId => {
                    EventBus.$emit(GE_CALENDAR_LOAD_AVAILABILITY, {unitId: unitId})
                })
                notifySuccess()
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
            })
        },
    },
}
</script>

<style scoped>
.fit-content-width {
    width: fit-content;
}
</style>
