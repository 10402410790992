<template>
    <div>
        <app-button class="pull-right mb-3" :icon="!reservation.chd_id ? 'new' : null"
                    v-if="checkPermission(C_RESERVATION_ACCOMMODATION_CC_E) || checkPermission(C_RESERVATION_ACCOMMODATION_CC_MASKED_E)"
                    @click="editCCData">
            {{ reservation.chd_id ? $t('EDIT_CC_DATA') : $t('CC_DATA')}}
        </app-button>

        <app-button v-if="reservation.chd_id" class="pull-right mb-3 mr-3"
                    :c_permission="C_RESERVATION_ACCOMMODATION_CC_V"
                    :c_masked_permission="C_RESERVATION_ACCOMMODATION_CC_MASKED_V"
                    @click="showCCData">
            {{ $t('SHOW_CC_DATA') }}
        </app-button>

        <app-aside v-model="asidePanelState">
            <template slot="header">
                {{ccPanelHeader}}
            </template>
            <credit-card-form :object-type="OBJECT_TYPE_ACCOMMODATION_RESERVATION"
                              :object-id="reservation.id"
                              :chd-id="reservation.chd_id"
                              :edit="edit"
                              @created="(id)=>{this.$emit('update', {chd_id: id});this.asidePanelState=false}"
                              @updated="(id)=>{this.$emit('update', {chd_id: id});this.asidePanelState=false}"
                              @close="asidePanelState=false"></credit-card-form>
        </app-aside>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import {C_RESERVATION_ACCOMMODATION_CC_E, C_RESERVATION_ACCOMMODATION_CC_V, C_RESERVATION_ACCOMMODATION_CC_MASKED_E, C_RESERVATION_ACCOMMODATION_CC_MASKED_V} from "@/shared/component_permission";
    import CreditCardForm from "@/components/finance/credit_card/CreditCardForm";
    import AppAside from "@/components/app/form/AppAside";
    import {OBJECT_TYPE_ACCOMMODATION_RESERVATION} from "@/shared/constants";

    export default {
        name: "Index",
        components: {CreditCardForm, AppButton, AppAside},
        props: {
            reservation: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                C_RESERVATION_ACCOMMODATION_CC_V,
                C_RESERVATION_ACCOMMODATION_CC_E,
                C_RESERVATION_ACCOMMODATION_CC_MASKED_V,
                C_RESERVATION_ACCOMMODATION_CC_MASKED_E,
                OBJECT_TYPE_ACCOMMODATION_RESERVATION,
                asidePanelState: false,
                edit: false,
            }
        },
        computed: {
            ccPanelHeader() {
                return this.edit && this.reservation.chd_id ? this.$t('EDIT_CC_DATA')
                    : this.edit ? this.$t('CREATE_CC_DATA')
                        : this.$t('CC_DATA')
            }
        },
        methods: {
            showCCData() {
                this.edit = false
                this.asidePanelState = true
            },
            editCCData() {
                this.edit = true
                this.asidePanelState = true
            }
        }
    }
</script>

<style scoped>

</style>
