import http from "@/services/http";
import {RATE_MANAGER} from "@/services/endpoints";

export function getRateManagerReport(params) {
    return http.request({
        url: RATE_MANAGER + '/report',
        method: 'GET',
        params
    })
}

export function getRateManagerSuggestions(params) {
    return http.request({
        url: RATE_MANAGER + '/suggestion',
        method: 'GET',
        params
    })
}

export function saveRateManagerSuggestions(data) {
    return http.request({
        url: RATE_MANAGER + '/suggestion',
        method: 'POST',
        data
    })
}
