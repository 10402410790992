<template>
    <div>
        <b-form @submit.prevent="save">
            <b-row>
                <b-col md="3" class="mb-4">
                    <app-input-control :error-object="validation.source_currency">
                        <template v-slot:input="data">
                            <label>{{ $t('FROM_CURRENCY') }}</label>
                            <app-select :options="currencyList"
                                        v-model="formData.source_currency.id"
                                        :state="data.state">
                            </app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="3" class="mb-4">
                    <app-input-control :error-object="validation.destination_currency">
                        <template v-slot:input="data">
                            <label>{{ $t('TO_CURRENCY') }}</label>
                            <app-select :options="currencyList"
                                        v-model="formData.destination_currency.id"
                                        :state="data.state">
                            </app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" class="mb-4">
                    <app-input-control :error-object="validation.value">
                        <template v-slot:input="data">
                            <label>{{ $t('AMOUNT') }}</label>
                            <app-number-input v-model="formData.value"
                                              :min="0" allow-decimal
                                              :decimals="9" align="right" :nullable="false"
                                              :state="data.state">
                            </app-number-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="mb-4">
                    <app-button-submit :loading="saving"
                                       :c_permission="C_SETUP_FINANCE_CE_FIXED_E">
                    </app-button-submit>
                </b-col>

                <b-col md="6" class="mb-4">
                    <app-button-delete
                        v-if="exchangeRateObject.id"
                        class="pull-right"
                        :c_permission="C_SETUP_FINANCE_CE_FIXED_E"
                        @click="() => {
                            deleteDialogObjectId = exchangeRateObject.id
                            deleteDialogState = true
                        }">
                    </app-button-delete>
                </b-col>
            </b-row>
        </b-form>

        <app-confirmation-dialog
            :show="deleteDialogState"
            @confirm="_delete"
            :delete_title="true"
            @cancel="deleteDialogState=false">
        </app-confirmation-dialog>
    </div>
</template>

<script>
    import AppInputControl from "@/components/app/AppInputControl";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {C_SETUP_FINANCE_CE_FIXED_E} from "@/shared/component_permission";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {getCurrencyList} from "@/services/finance";
    import {
        createCurrencyExchangeFixed,
        fetchCurrencyExchangeFixed,
        updateCurrencyExchangeFixed
    } from "@/services/finance/currency_exchange";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import {
        FIN_CE_FIXED_DESTINATION_CURRENCY_REQUIRED,
        FIN_CE_FIXED_SOURCE_CURRENCY_REQUIRED, FIN_CE_FIXED_VALUE_NOT_VALID
    } from "@/shared/error_codes";

    export default {
        name: "FixedExchangeRateForm",
        components: {AppNumberInput, AppButtonDelete, AppButtonSubmit, AppConfirmationDialog, AppSelect, AppInputControl},
        mixins: [getErrorMessage],
        props: {
            exchangeRateObject: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                saving: false,
                formData: {
                    source_currency: {id: null, label: null},
                    destination_currency: {id: null, label: null},
                },
                validation: {
                    source_currency: {
                        input: [FIN_CE_FIXED_SOURCE_CURRENCY_REQUIRED]
                    },
                    destination_currency: {
                        input: [FIN_CE_FIXED_DESTINATION_CURRENCY_REQUIRED]
                    },
                    value: {
                        input: [FIN_CE_FIXED_VALUE_NOT_VALID]
                    }
                },
                currencyList: [],
                deleteDialogState: false,
                deleteDialogObjectId: null,
                C_SETUP_FINANCE_CE_FIXED_E
            }
        },
        computed: {
            companyId() {
                return this.$store.getters['user/getCompany']
            },
        },
        methods: {
            save() {
                this.saving = true
                const savePromise = !this.exchangeRateObject.id
                    ? createCurrencyExchangeFixed(this.formData)
                    : updateCurrencyExchangeFixed(this.exchangeRateObject.id, this.formData)
                savePromise.then(response => {
                    !this.exchangeRateObject.id ? this.$emit('created', response.data) : this.$emit('updated', response.data)
                    notifySuccess()
                }).catch(error => {
                    this.showErrorMessages(error, this.validation)
                }).finally(() => this.saving = false)
            },
            _delete() {
                this.$emit('deleted', this.exchangeRateObject)
            },
        },
        created() {
            this.formData.company = this.companyId
            if (this.exchangeRateObject.id) {
                fetchCurrencyExchangeFixed(this.exchangeRateObject.id).then(response => {
                    this.formData = Object.assign({}, this.formData, response.data)
                })
            }
            getCurrencyList().then(response => {
                this.currencyList = response.data
            })
        }
    }
</script>

<style scoped>

</style>
