<template>
    <div v-cloak class="app flex-row align-items-center" v-if="loaded">
        <div class="container">
            <b-row class="justify-content-center">
                <b-col md="6">
                    <b-card-group>
                        <b-card no-body class="p-0">
                            <img v-if="template && template.logo" class="navbar-brand-full" :src="template.logo" style="max-width:250px;max-height:40px">
                            <b-card-header class="pl-0">
                                <h1>{{$t('LOGIN')}}</h1>
                            </b-card-header>

                            <b-card-body class="p-0">
                                <b-form @submit.prevent="submitLogin" autocomplete="off">
                                    <b-input-group class="mb-3">
                                        <b-input-group-prepend>
                                            <b-input-group-text><i>@</i></b-input-group-text>
                                        </b-input-group-prepend>
                                        <b-form-input
                                            :state="usernameState"
                                            type="text"
                                            class="form-control"
                                            :placeholder="$t('EMAIL')"
                                            aria-describedby="inputUsernameFeedback"
                                            v-model.trim="username">
                                        </b-form-input>
                                    </b-input-group>
                                    <b-input-group class="mb-1">
                                        <b-input-group-prepend>
                                            <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                                        </b-input-group-prepend>
                                        <b-form-input
                                            type="password"
                                            :state="passwordState"
                                            class="form-control"
                                            :placeholder="$t('PASSWORD')"
                                            aria-describedby="inputPasswordFeedback"
                                            v-model="password">
                                        </b-form-input>
                                    </b-input-group>
                                    <b-row class="mb-3">
                                        <b-col class="text-left">
                                            <a
                                                :href="redirectToForgetPassword" target='_top'>{{$t("FORGOT_PASSWORD")}}
                                            </a>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-3">
                                        <b-col cols="6">
                                            <app-submit :disabled="maintenance" button-class="button-min-width" :loading="show">{{$t('LOGIN')}}</app-submit>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-4">
                                        <b-col>
                                            <b-alert variant="danger" :show="maintenance">
                                                {{$t('MAINTENANCE_MESSAGE')}}
                                            </b-alert>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-4">
                                        <b-col>
                                            <b-alert variant="danger" :show="showError">{{errorMessage}}
                                            </b-alert>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-card-body>
                        </b-card>

                    </b-card-group>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import routeName from '@/router/routeNames'
import AppValidationDescription from "@/components/app/form/AppValidationDescription";
import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import {
    RegisterPageEn,
    RegisterPageHr,
    ForgotPasswordPageEn,
    ForgotPasswordPageHr,
    LS_PRESETS
} from "@/shared/constants";
import AppButton from "@/components/app/AppButton/AppButton";
import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {getPresets} from "@/services/user";
import {getCompanyTemplate} from "@/services/company";

export default {
    name: 'WLogin',
    mixins: [getErrorMessage],
    components: {
        AppSubmit,
        AppButton,
        AppValidationDescription,
    },
    data() {
        return {
            maintenance:false,
            routeName,
            username: '',
            password: '',
            usernameState: null,
            passwordState: null,
            show: false,
            label: 'Logging in...',
            overlay: true,
            error: "",
            showError: false,
            showSuccessMessage: false,
            template: null,
            loaded: false
        }
    },
    computed: {
        errorMessage: function () {
            return this.error
        },
        redirectToForgetPassword() {
            if (process.env.NODE_ENV === 'production') {
                return window.location.host + '/' + this.$i18n.locale + '/forgot-password'
            } else {
                return window.location.host + '/' + this.$i18n.locale + '/forgot-password'
            }
        },
    },

    methods: {
        submitLogin() {
            localStorage.clear();
            const formData = {
                password: this.password,
                username: this.username
            }
            this.show = true
            this.showError = false
            this.showSuccessMessage = false

            this.$store.dispatch('auth/actionLogin', {
                username: formData.username,
                password: formData.password,
                lang: this.$i18n.locale
            })
                .then(() => {
                    let link =  this.$router.currentRoute.query.redirect ?  this.$router.currentRoute.query.redirect : '/'
                    this.$store.dispatch('user/actionSetUser').then(()=>{
                        this.$store.dispatch('user/actionSetUserPermissions').then(() => {

                            if (!this.$router.currentRoute.query.redirect) {
                                this.$router.replace({name: routeName.RN_HOME})
                            } else {
                                if (this.$router.currentRoute.query.redirect === '/login') {
                                    this.$router.replace({name: routeName.RN_HOME})
                                } else {
                                    this.$router.push(link)
                                }
                            }

                        }).finally(() =>{
                            this.show = false
                        })
                    })


                }).catch(error => {
                if(error.response.status===302 && error.response.data.user_id){
                    this.$router.replace({
                        name: routeName.RN_CHANGE_EXPIRED_PASSWORD,
                        query: {
                            user_id: error.response.data.user_id,
                            lang: this.$i18n.locale
                        }
                    })
                }
                this.password = null
                this.error = error.response.data.message_description
                this.show = false
                this.showError = true

            })
        },
        setTemplate() {
            let template = this.template
            const root = document.querySelector(':root');
            Object.entries(template.theme.colors).forEach(([key, value]) => {
                root.style.setProperty(key, value)
            })
            if(template && template.name) {
                window.document.title = template.name
            }
            if(template && template.favicon) {
                const favicon = document.querySelector("link[rel~='icon']")
                favicon.href = template.favicon
            }
        }
    },
    watch:{
        '$route.query.lang':{
            handler(value){
                if(value === 'sl'){
                    this.$i18n.locale = 'si'
                }  else  {
                    this.$i18n.locale = value ? value  : 'en'
                }
            },
            immediate:true
        },
        'template.theme': {
            handler(value){
                if(value){
                    this.setTemplate()
                }
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        document.body.classList.add('page-w-login');
        next()
    },
    created() {
        getCompanyTemplate(window.location.host).then(res => {
            this.template = res.data
        }).finally(() => {
            this.loaded = true
        })
    }
}

</script>
