<template>

    <b-card class="main-section-card">
        <div slot="header">
            <h2>{{ $t("Payout Reports") }}</h2>
        </div>
        <payout-filter @search="setFilter"  :loading="loading"></payout-filter>
        <payout-table :filter="filter" @busyToggled="toggleBusy" ></payout-table>
    </b-card>
</template>

<script>
import PayoutFilter from "@/components/finance/payout_report/PayoutFilter.vue";
import PayoutTable from "@/components/finance/payout_report/PayoutTable.vue";
export default {
    name: "Index",
    components: {
        PayoutFilter, PayoutTable
    },
    data(){
        return{
            filter:null,
            loading:false
        }
    },
    methods:{
        setFilter(values) {
            this.filter = values
        },
        toggleBusy($event) {
            this.loading = $event
        }
    }
}
</script>

<style scoped>

</style>
