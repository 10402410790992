<template>
    <b-form @submit.prevent="save" v-if="loaded">
        <b-row>
            <b-col cols="12" xl="6" :class="index === 0 ? 'br-xl-show' : ''"
                   v-for="(field, index) in (['header', 'footer'])">
                <div class="d-flex justify-content-between align-items-baseline mb-4">
                    <form-header :header-class="`mt-xl-0 mb-0`">{{ $t(field.toUpperCase()) }}</form-header>

                    <div>
                        <app-button button_type="new" @click="addItem(formData[field])">
                            {{ $t('ADD_ITEM') }}
                        </app-button>

                        <app-button v-if="field === 'header'" class="ml-2" button_type="new"
                                    @click="addItem(formData[field], true)">{{ $t('ADD_DROPDOWN') }}
                        </app-button>
                    </div>
                </div>

                <draggable v-model="formData[field]" handle=".handle">
                    <div v-for="(navItem, index) in formData[field]" :key="index" class="mb-2">
                        <div class="d-flex align-items-center">
                            <b-button block variant="outline-primary" class="text-left"
                                      @click="navItem.visible=!navItem.visible">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <i class="fa fa-bars mr-2 handle" style="cursor: move"></i>

                                        <b-badge pill variant="primary" class="mr-1 number-badge">
                                            {{ index + 1 }}
                                        </b-badge>
                                        {{ navItem.label.value }}
                                    </div>
                                    <i :class="`fa fa-caret-${navItem.visible ? 'up' : 'down'}`"></i>
                                </div>
                            </b-button>

                            <app-button
                                class="ml-2"
                                button_type="delete"
                                :show_text="false"
                                variant="link"
                                @click="removeItem(formData[field], index)">
                            </app-button>
                        </div>

                        <b-collapse v-model="navItem.visible" :id="`${field}-${index}`">
                            <template v-if="navItem.visible">
                                <template v-if="!navItem.children">
                                    <website-navigation-item-form :nav-item="navItem" @update="$set(formData[field], index, $event)"
                                                                  :nav-position="field">
                                    </website-navigation-item-form>
                                </template>

                                <template v-else>
                                    <b-row class="mt-3 ml-0 ml-md-1">
                                        <b-col cols="6" xl="6" class="mb-4">
                                            <app-translation-input
                                                :label="$t('TITLE')"
                                                :context-label="$t(`${field}_NAVIGATION`.toUpperCase())"
                                                v-model="navItem.label.value"
                                                :lang_id="navItem.label.lang_id"
                                                :languages="website.supported_languages">
                                            </app-translation-input>
                                        </b-col>
                                    </b-row>


                                    <b-row class="ml-1">
                                        <b-col>

                                            <div class="d-flex justify-content-between align-items-baseline mb-4">
                                                <app-button button_type="new" @click="addItem(navItem.children)">
                                                    {{ $t('ADD_ITEM') }}
                                                </app-button>
                                            </div>

                                            <draggable :list="navItem.children" handle=".handle-children">
                                                <div v-for="(childNavItem, childIndex) in navItem.children"
                                                     :key="childIndex" class="mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <b-button block variant="outline-primary" class="text-left"
                                                                  @click="childNavItem.visible=!childNavItem.visible">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <i class="fa fa-bars mr-2 handle-children"
                                                                       style="cursor: move"></i>

                                                                    <b-badge pill variant="primary"
                                                                             class="mr-1 number-badge">
                                                                        {{ childIndex + 1 }}
                                                                    </b-badge>
                                                                    {{ childNavItem.label.value }}
                                                                </div>
                                                                <i :class="`fa fa-caret-${childNavItem.visible ? 'up' : 'down'}`"></i>
                                                            </div>
                                                        </b-button>

                                                        <app-button
                                                            class="ml-2"
                                                            button_type="delete"
                                                            :show_text="false"
                                                            variant="link"
                                                            @click="removeItem(navItem.children, childIndex)">
                                                        </app-button>
                                                    </div>

                                                    <b-collapse v-model="childNavItem.visible" :id="`${field}-${index}`">
                                                        <website-navigation-item-form :nav-item="childNavItem"
                                                                                      @update="$set(navItem.children, childIndex, $event)"
                                                                                      :nav-position="field">
                                                        </website-navigation-item-form>
                                                    </b-collapse>
                                                </div>
                                            </draggable>
                                        </b-col>
                                    </b-row>
                                </template>
                            </template>

                        </b-collapse>
                    </div>
                </draggable>
            </b-col>
        </b-row>

        <b-row class="mt-3">
            <b-col>
                <app-button-submit :loading="loading"></app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import {fetchWebsiteNavigation, updateWebsiteNavigation} from "@/services/direct_booking/website";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import _omit from 'lodash/omit'
import {notifySuccess} from "@/shared/plugins/toastr";

const WEBSITE = 1
const EXTERNAL = 2

export default {
    name: "WebsiteNavigationForm",
    mixins: [getErrorMessage],
    components: {
        "WebsiteNavigationItemForm": () => import("@/components/direct_booking/website/pages/WebsiteNavigationItemForm"),
        "AppTranslationInput": () => import("@/components/app/translation/AppTranslationInput"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "FormHeader": () => import("@/components/app/form/FormHeader"),
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "draggable": () => import("vuedraggable")
    },
    data() {
        return {
            formData: {
                header: [],
                footer: [],
            },
            loading: false,
            loaded: false
        }
    },
    computed: {
        website() {
            return this.$store.getters['website/getWebsite']
        }
    },
    methods: {
        save() {
            this.loading = true

            const data = {
                header: this.prepareForSave(this.formData.header),
                footer: this.prepareForSave(this.formData.footer),
            }

            updateWebsiteNavigation(this.website.id, data).then(response => {
                notifySuccess()
                this.formData.header = this.prepareFormData(response.data.header)
                this.formData.footer = this.prepareFormData(response.data.footer)
            }, error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
            })
        },
        prepareForSave(list) {
            return list.reduce((acc, item) => {
                if (item.type === WEBSITE && item.page_id) {
                    acc.push(_omit(item, ['type', 'url', 'visible']))
                } else if (item.type === EXTERNAL && item.url) {
                    acc.push(_omit(item, ['type', 'page_id', 'query', 'visible']))
                } else if (item.children) {
                    acc.push({
                        ...(_omit(item, ['type', 'visible'])),
                        children: this.prepareForSave(item.children)
                    })
                }
                return acc
            }, [])
        },
        addItem(list, isDropdown) {
            list.forEach(el => el.visible = false)
            if (isDropdown) {
                list.push({label: {lang_id: null, value: null}, children: [], visible: true})
            } else {
                list.push({
                    page_id: null, url: null, gtm: null,
                    label: {lang_id: null, value: null}, query: null,
                    visible: true, type: WEBSITE
                })
            }

        },
        removeItem(list, index) {
            list.splice(index, 1)
        },
        prepareFormData(list) {
            return list.map(el => {
                const e = {...el, type: el.page_id ? WEBSITE : EXTERNAL, visible: false}
                if (el.children) {
                    e.children = this.prepareFormData(el.children)
                }
                return e
            })
        }
    },
    created() {
        fetchWebsiteNavigation(this.website.id).then(response => {
            this.formData.header = this.prepareFormData(response.data.header)
            this.formData.footer = this.prepareFormData(response.data.footer)
        }, error => {
            this.showErrorMessages(error)
        }).finally(() => {
            this.loaded = true
        })
    }
}
</script>

<style scoped>
.number-badge {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
}
</style>
