<template>

    <div v-if="fetchData">
        <b-row v-if="type_list.length">
            <b-col sm="12" :key="index" v-for="(type, index) in type_list">
                <b-card class="repeater-1">
                    <div slot="header">
                        <b-row>
                            <b-col>
                                <repeater-header class="d-flex align-items-center">
                                    {{type.title}}
                                    <app-button @click="edit(type.id)" class="action_button" button_type="edit" variant="link" :show_text="false"></app-button>

                                </repeater-header>
                            </b-col>
                        </b-row>
                    </div>
                    <b-row class="mb-2" :key="component_key+'_'+type.id">

                        <b-col>
                            <div class="pl-3">
                                {{$t("TYPE")}}: {{capitalize(type.booking_window_setup_type.title)}}

                            </div>
                            <app-access-control tag="b-row" class="ml-3" @AccessControlEvent="addToAccessControlCounter()"
                                                :access_control_context="{function:F_REVENUE_MANAGER_RATE_ACTIVATION_INPUT,type:type.booking_window_setup_type.id, key: AC_REVENUE_MANAGER}">
                                <template v-slot="props">
                                <b-tooltip v-if="props.disabled"  :target="'key_'+type.id" triggers="hover">
                                    {{$t('DEACTIVATION_NOT_POSSIBLE')}}
                                </b-tooltip>
                                {{$t('ACTIVE')}}
                                <b-form-group  :id="'key_'+type.id">
                                    <b-form-checkbox

                                        :disabled="props.disabled" class="ml-1"
                                        v-model.lazy="activation[type.id]"
                                        switch
                                        @change="activationSubmit(type,index, $event)"
                                        :value="true"
                                        :unchecked-value="false">

                                    </b-form-checkbox>
                                </b-form-group>
                                </template>
                            </app-access-control>

                        </b-col>
                    </b-row>
                    <table class="table b-table table-sm" v-if="formattedBookingList[type.id] && formattedBookingList[type.id].length > 0">
                        <thead>
                        <th>{{$t('NAME')}}</th>
                        <th class="text-center">{{$t('INTERVAL_FROM')}}</th>
                        <th class="text-center">{{$t('INTERVAL_TO')}}</th>
                        </thead>
                        <tbody>
                        <template v-for="(bookingWindow,index) in formattedBookingList[type.id]">
                            <tr class="bg-light" :key="index" >
                                <td >{{bookingWindow.title}}</td>
                                <td class="text-center">{{bookingWindow.interval_from}}</td>
                                <td  class="text-center">{{bookingWindow.interval_to}}</td>
                            </tr>
                            <tr  :key="childBooking.id" v-if="bookingWindow.hasOwnProperty('child_booking_windows') && bookingWindow.child_booking_windows.length > 0" v-for="childBooking in bookingWindow.child_booking_windows">
                                <td>{{childBooking.title}}</td>
                                <td class="text-center">{{childBooking.interval_from}}</td>
                                <td class="text-center">{{childBooking.interval_to}}</td>
                            </tr>
                        </template>

                        </tbody>

                    </table>
                </b-card>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col>
                <app-no-data :showTip="false">
                    <AppButton
                        button_type="new"
                        variant="primary"
                        @click="new_aside=true"
                        class="pull-right">{{ $t('NEW_BOOKING_WINDOW_SETUP') }}
                    </AppButton>
                </app-no-data>
            </b-col>
        </b-row>
        <app-aside v-model="new_aside">
            <template v-slot:header>
                {{header}}
            </template>
            <revenue-manger-booking-window-form @changeHeader="updateHeader" :booking-window-setup-list="type_list" mode="new"></revenue-manger-booking-window-form>
        </app-aside>
        <app-aside v-model="aside">
            <template v-slot:header>
                {{$t('BOOKING_WINDOW_SETUP')}}
            </template>
            <revenue-manger-booking-window-form  @changeHeader="updateHeader" :booking-window-setup-list="type_list" :setup_id="setup_id"></revenue-manger-booking-window-form>
        </app-aside>
        <app-confirmation-dialog :title="activationObject.value === 1 ?$t('ACTIVATION_CONFIRMATION') :$t('DEACTIVATION_CONFIRMATION')" :show="activationConfirmationDialog" @confirm="confirmConfirmation"  @cancel="activationConfirmationDialog = false">
          <template v-if="activationObject.value === 1">
              {{$t('ACTIVATE_BW_MESSAGE')}}
          </template>
            <template v-else>
                {{$t('DEACTIVATE_BW_MESSAGE')}}
            </template>
        </app-confirmation-dialog>
    </div>
</template>

<script>
import AppTableV2 from "@/components/app/AppTableV2";
import AppAside from "@/components/app/form/AppAside";
import {
    activateBookingWindowSetup,
    getBookingWindowSetup,
    getRevenueBookingWindow,
    updateBookingWindowSetup
} from "@/services/revenue_manager";
import AppButton from "@/components/app/AppButton/AppButton";
import RevenueMangerBookingWindowForm from "@/components/revenue_manager/booking_window/RevenueManagerBookingWindowForm";
import {
    EventBus,
    GE_REVENUE_MANAGER_BOOKING_WINDOW_TYPE_NEW,
    GE_TABLE_SEARCH,
    GE_TABLE_UPDATE_ROW
} from '@/shared/EventBus';
import {has, isFunction} from "lodash";
import AppNoData from "@/components/app/AppNoData";
import RepeaterHeader from "@/components/app/form/RepeaterHeader";
import RootBookingWindow from "@/components/revenue_manager/booking_window/RootBookingWindowForm";
import {toast} from "@/shared/plugins/toastr";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppAccessControl from "@/components/app/AppAccessControl";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_REVENUE_MANAGER} from "@/mixins/AccessControl/AccessControlEnumeration";
import {F_REVENUE_MANAGER_RATE_ACTIVATION_INPUT} from "@/shared/component_permission";

export default {
    name: "RevenueManagerSetupBookingWindowTable",
    mixins: [getErrorMessage,AccessControlComponent],
    components: {
        AppAccessControl,
        AppConfirmationDialog,
        RootBookingWindow,
        RepeaterHeader, AppNoData, AppButton, AppTableV2,AppAside,RevenueMangerBookingWindowForm},
    data(){
        return {
            GE_TABLE_SEARCH,
            F_REVENUE_MANAGER_RATE_ACTIVATION_INPUT,
            AC_REVENUE_MANAGER,
            access_control_fetch_key:AC_REVENUE_MANAGER,
            EventBus,
            aside:false,
            aside_new:false,
            setupObject:{},
            fields: [
                {key: 'title', label: this.$t('NAME')},
                {key: 'interval_from', label: 'Interval from'},
                {key: 'interval_to', label: 'Interval to'},

            ],
            type_list:[],
            booking_window_list:[],
            formattedBookingList:{},
            fetchData:false,
            setup_id: null,
            new_aside:false,
            activationConfirmationDialog:false,
            isSelected:false,
            editType:{},
            header:this.$t('NEW_BOOKING_WINDOW_SETUP'),
            activation:{},
            activationObject:{},
            component_key:1
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods:{
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        updateHeader(){
            this.header = this.$t('BOOKING_WINDOW_SETUP')
        },
        disableActivation(setupType){
            return setupType.active === 1  && this.type_list.filter(el=> el.booking_window_setup_type.id === setupType.booking_window_setup_type.id && el.active === 1).length === 1
        },
        confirmConfirmation(){
            this.activationConfirmationDialog = false
            const request = {
                company: this.company.id,
                name: this.activationObject.type.title,
                setup_type: this.activationObject.type.booking_window_setup_type.id,
                active: this.activationObject.value
            }
            let value = this.activationObject.value
            activateBookingWindowSetup(this.activationObject.type.id,request).then(response => {
                this.setData()
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })

            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })
                value = !this.activationObject.value

            }).finally(() => {
                this.loader_setup = false
                value = value === 1 ? true : false
                this.$set(this.activation, this.activationObject.type.id,value)


            })
        },
     async activationSubmit(type,index,value){
         await this.$nextTick()
         this.activation[type.id] = !value
         this.activationObject = {
             type,
             value : value === true ? 1 : 0
         }
         this.activationConfirmationDialog = true
        },

        edit(setup_id){
            this.setup_id = setup_id
            this.aside = true
        },
        getProvider(){
          const provider =   getBookingWindowSetup({company:this.company.id})
           provider.then(response=>{
                EventBus.$emit('update_booking_setup_type_list',response.data)
            })

            return provider

        },
        setData(){
            getBookingWindowSetup({company:this.company.id}).then(response=>{
                this.component_key += 1
                this.type_list = response.data
                this.access_control_components = response.data.length
                this.$emit('data', response.data)
                getRevenueBookingWindow({company: this.company.id}).then(response => {

                    this.booking_window_list = response.data

                    for(const type of this.type_list){
                        const value = type.active === 1 ? true : false
                        this.$set(this.activation, type.id,value)
                        const data = response.data.length > 0 ? response.data.filter(el=> el.booking_window_setup.id === type.id) :[]
                        this.$set(this.formattedBookingList,type.id,data)

                    }


                }).finally(()=>{
                    this.fetchData = true
                })
            })

        },

    },
    mounted(){
        EventBus.$on(GE_REVENUE_MANAGER_BOOKING_WINDOW_TYPE_NEW, ()=>{
            this.aside_new = true
        })
        EventBus.$on('gl_reload_booking_windows',()=>{
            this.setData()
            this.aside = false
        })

    },
    created() {
        this.setData()
        this.access_control_general_context= {
            company: this.company.id
        }
    }

}
</script>

<style scoped>

</style>
