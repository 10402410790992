<template>
    <form @submit.prevent="search" @reset.prevent="reset" class="filter" autocomplete="off">
        <b-row>
            <b-col md="4" lg="4">
                <label>{{$t("FIRST_NAME")}}</label>
                <b-input v-model="filter.first_name" type="text"></b-input>
            </b-col>
            <b-col md="4" lg="4">
                <label>{{$t("LAST_NAME")}}</label>
                <b-input v-model="filter.last_name" type="text"></b-input>
            </b-col>
            <b-col md="4" lg="4">
                <label>{{$t("USERNAME")}}</label>
                <b-input v-model="filter.username" type="text"></b-input>
            </b-col>
            <b-col>
                <app-button-submit class="filter-submit-wrapper"
                                   :inline="true" variant="primary"
                                   button_type="search"
                                   :loading="loading">
                    {{$t("SEARCH")}}
                </app-button-submit>
                <app-button-reset class="ml-3" :inline="true" :disabled="loading"></app-button-reset>
            </b-col>
        </b-row>
    </form>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {pickBy, cloneDeep} from "lodash";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";

    export default {
        name: "UserAccessFilter",
        components: {
            AppButtonReset,
            AppButtonSubmit
        },
        data() {
            return {
                filter: {
                    first_name: null,
                    last_name: null,
                    username: null,
                    page: 1,
                    limit: 5
                },
                initialFilter: {},
            }
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            search() {
                this.$emit('search', pickBy(this.filter, item => item !== null))
            },
            reset() {
                this.filter = cloneDeep(this.initialFilter)
                this.$emit('reset')
            }
        },
        created() {
            this.initialFilter = cloneDeep(this.filter)
        }
    }
</script>

<style scoped>

</style>
