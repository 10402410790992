<template>
    <b-card v-if="propertyList.length > 0" body-class="d-flex">
        <div class="d-flex flex-grow-1 flex-column">
            <span class="header-2 mb-5">{{$t("CONTRACT")}}</span>
            <template v-for="(item, index) in propertyList">

                <div v-if="propertyList.length > 1">
                    <b-row class="mb-2" :class="index > 0 ? 'mt-2' : ''">
                        <b-col>
                            <div slot="header">
                                <repeater-header class="d-flex pull-left flex-column pl-0">
                                    {{item.property}}
                                </repeater-header>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <template v-for="owner in item.owners">
                    <b-row v-if="owner.documents.length>0">
                        <b-col xs="10">
                            {{owner.owner}}
                        </b-col>
                        <b-col xs="2" class="pull-right">
                            <template v-for="document in  owner.documents">
                                <b-col>
                                    <template>
                                        <b-button size="default"
                                                  class="pull-right action_button"
                                                  :href="document.route + '?version=' + document.id"
                                                  target="_blank"
                                                  variant="link"
                                        >
                                            <i class="fa fa-file-pdf-o fa-fw mr-1 btn-pdf-i"
                                               aria-hidden="true">
                                            </i>
                                        </b-button>
                                    </template>
                                </b-col>
                            </template>
                        </b-col>
                    </b-row>
                </template>
            </template>
        </div>
    </b-card>
</template>

<script>

    import {downloadInvoicePDF} from "@/services/finance";
    import {getOwnerDocuments} from "@/services/document";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {TYPE_OWNER_CONTRACT} from "@/shared/constants";
    import RepeaterHeader from "@/components/app/form/RepeaterHeader";


    export default {
        name: "DocumentActionGrid",
        components: {
            RepeaterHeader,
            AppButton,
        },
        data() {
            return {
                propertyList: [],
                DocumentTypeIdContract: TYPE_OWNER_CONTRACT,
            }
        },
        created() {
            getOwnerDocuments(this.DocumentTypeIdContract).then((response) => {
                let responseData = response.data
                this.propertyList = responseData.filter(o => o.owners.some(({documents}) => documents.length > 0));
            })
        },
        computed: {
            user() {
                return this.$store.getters['user/getUser']
            },
        },
        methods: {
            downloadPDF(id) {
                downloadInvoicePDF(id).then(response => {
                    const url = window.URL.createObjectURL(response);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${id}.pdf`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }).catch((error) => {
                    this.showErrorMessages(error)
                })
            },
            getData() {
                return getOwnerDocuments(
                    {
                        objectId: this.user.id,
                    }
                )
            },
        }
    }
</script>

<style scoped>

</style>
