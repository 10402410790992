<template>
    <div v-if="DocumentTypeId && ObjectId && !disabled">
        <dropzone
                ref="dropZoneUpload"
                id="myVueDropzone"
                @vdropzone-success="fileUploadSuccess"
                @vdropzone-queue-complete="queueComplete"
                @vdropzone-error="fileUploadError"
                @vdropzone-files-added="uploadFinishedFiles = []"
                @vdropzone-file-added="uploadFinishedFiles = []"
                :duplicateCheck="true"
                :useCustomSlot="true"
                :options="dropzoneOptions">
            <i class="fa fa-cloud-upload fa-5x" aria-hidden="true"></i><br>
            {{$t('UPLOAD_TEXT')}}
        </dropzone>
        <b-progress :max="uploadFinishedFiles.length" class="mt-2" v-if="uploadFinishedFiles.length > 0" @click.native="uploadFinishedFiles = []">
            <b-progress-bar
                    :value="1"
                    :key="fileIndex"
                    :id="'progress_file_' + fileIndex"
                    v-for="(file, fileIndex) in uploadFinishedFiles"
                    style="cursor: pointer"
                    :variant="file.fail ? 'danger' : 'info'">
                {{getProgressName(fileIndex)}}
                <b-tooltip :target="'progress_file_' + fileIndex" placement="bottom" offset="2">
                    <div>
                        <h6>{{file.name}}</h6>
                        <dl>
                            <dt :key="msgIndex" v-for="(msg, msgIndex) in file.messages">{{msg}}</dt>
                        </dl>
                    </div>
                </b-tooltip>

            </b-progress-bar>
        </b-progress>
    </div>
</template>

<script>
    import Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {API_CONFIG} from '@/services/config'
    import {DOCUMENT_URL} from '@/services/endpoints'

    export default {
        name: "GalleryUpload",
        components: {
            Dropzone
        },
        mixins: [getErrorMessage],
        props: {
            ObjectId: {
                type: Number,
                required: true
            },
            DocumentTypeId: {
                type: Number,
                required: true
            },
            DocumentType: {
                default: 'image/*,application/pdf',
                type: String
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                dropzoneOptions: {
                    url: API_CONFIG.API_URL + DOCUMENT_URL + `/${this.DocumentTypeId}/${this.ObjectId}`,
                    method: 'post',
                    acceptedFiles: this.DocumentType,
                    paramName: 'list',
                    uploadMultiple: true,
                    dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>UPLOAD ME",
                    dictFallbackMessage: "<i class='fa fa-cloud-upload'></i>UPLOAD ME",
                    parallelUploads: 1,
                    headers: {
                        "Cache-Control": "",
                        "X-Requested-With": "",
                        "Authorization": 'Bearer ' + this.$store.getters['auth/getToken'],
                    }
                },
                uploadFinishedFiles: []
            }
        },
        methods: {
            fileUploadError(file, response, xhr) {
                let status = {name: file.name, fail: true, messages: []}
                if (response.hasOwnProperty('error_list') && response.error_list.length > 0) {
                    response.error_list.forEach(msg => {
                        status.messages.push(msg.message)
                    })
                }
                this.uploadFinishedFiles.push(status)
            },
            fileUploadSuccess(file) {
                this.uploadFinishedFiles.push({name: file.name, fail: false, messages: []})
            },
            getProgressName(fileIndex) {
                return this.uploadFinishedFiles.length > 5 ? fileIndex + 1 : this.uploadFinishedFiles[fileIndex].name
            },
            queueComplete(response) {
                this.$refs.dropZoneUpload.removeAllFiles();
                this.$emit('uploadSuccess')
            }
        }
    }
</script>

<style scoped>

#myVueDropzone{
    min-height: 2rem !important;
}

</style>

<style>

.tooltip .tooltip-inner{
    max-width: 100% !important;
    width: 100% !important;
    text-align: left;
}

</style>
