<template>
    <div>
        <app-table-v2

            :fields="fields"
            :search-on-create="false"
            :provider="getProvider"
            :limit-list="[DEFAULT_PAGINATION_LIMIT, 20, 50, 100]"
            @busyToggled="toggleBusy"
            :search-event="EVENT_TABLE_SEARCH"
            :filter="filter"
            :selectable="true"
        >
            <template v-slot:selectedContainer="data">
                <app-button @click="groupNew(data.selected)" button_type="new">{{$t('NEW_GROUP')}}</app-button>
                <app-button class="ml-3" @click="assignToFilter(data.selected)" >{{$t('ASSIGN_TO_GROUP')}}</app-button>
               <b-modal :title="$t('NEW_GROUP')" v-model="new_aside">

                   <b-form >

                       <b-row class="mb-2">
                           <b-col lg="12" class="mb-4">
                               <app-input-control :error-object="validationErrorObject.title">
                                   <template v-slot:input="data">
                               <label>{{ $t('NAME') }}</label>
                               <b-input :state="data.state" v-model="title_new_group"></b-input>
                                   </template>
                               </app-input-control>
                           </b-col>
                       </b-row>
                   </b-form>
                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <div v-if="data.totalRows > 1 && data.totalRows > data.selected.length">
                                <b-checkbox v-model="selectAllForNewGroup">
                                    {{$t("APPLY_TO_ALL_NUMBER", {number:data.totalRows})}}
                                    <span v-if="data.selected.length === 1" class="d-block text-muted font-xs">({{$t("ITEM_SELECTED", {value: data.selected.length})}})</span>
                                    <span v-if="data.selected.length > 1" class="d-block text-muted font-xs">({{$t("ITEMS_SELECTED", {value: data.selected.length})}})</span>
                                </b-checkbox>

                            </div>
                            <app-button-submit :disabled="title_new_group === null" :loading="loading_new_group" @click="addNewGroupCheck(data.selected)"></app-button-submit>
                        </div>
                    </template>
                </b-modal>

                <b-modal :title="$t('ASSIGN_TO_GROUP')" v-model="aside">

                    <b-form>

                        <b-row class="mb-2">
                            <b-col lg="12" class="mb-4">
                                <label>{{$t('GROUP')}}</label>
                                <app-select mode="search" text-field="title" value-field="id" :search="searchFilter" v-model="filterObject">

                                </app-select>
                            </b-col>
                        </b-row>

                    </b-form>
                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <div v-if="data.totalRows > 1 && data.totalRows > data.selected.length">
                                <b-checkbox v-model="selectAllForAssign">
                                    {{$t("APPLY_TO_ALL_NUMBER", {number:data.totalRows})}}
                                    <span v-if="data.selected.length === 1" class="d-block text-muted font-xs">({{$t("ITEM_SELECTED", {value: data.selected.length})}})</span>
                                    <span v-if="data.selected.length > 1" class="d-block text-muted font-xs">({{$t("ITEMS_SELECTED", {value: data.selected.length})}})</span>
                                </b-checkbox>

                            </div>
                            <app-button-submit :loading="loading_assign" @click="assignToGroupCheck(data.selected)" :disabled="filterObject === null" ></app-button-submit>
                        </div>
                    </template>
                </b-modal>
                <app-confirmation-dialog :title="$t('GROUP_CHANGE_CONFIRMATION_TITLE')" :show="confirmationNewGroup" @confirm="saveNewGroup(data.selected)"  @cancel="confirmationNewGroup = false">
                    {{$t('GROUP_CHANGE_CONFIRMATION')}}
                </app-confirmation-dialog>
                <app-confirmation-dialog :title="$t('GROUP_CHANGE_CONFIRMATION_TITLE')" :show="conftirmationAssign" @confirm="saveAssignToGroup(data.selected)"  @cancel="conftirmationAssign = false">
                    {{$t('GROUP_CHANGE_CONFIRMATION')}}
                </app-confirmation-dialog>
            </template>
            <template v-slot:title="data">
                <small class="text-muted d-block">
                    <b-link v-if="checkPermission(R_PROPERTY_BASIC) && data.item.property.id"
                            :to="{name: routeNames.RN_PROPERTY_BASIC, params: {id: data.item.property.id}}"
                            target="_blank">
                        {{data.item.property.name}}
                    </b-link>
                    <template v-else>
                        {{data.item.property.name}}
                    </template>
                </small>

                <b-link v-if="checkPermission(R_PROPERTY_UNIT_BASIC)"
                        :to="{name: routeNames.RM_PROPERTY_UNIT_BASIC, params: {id: data.item.property.id, unit_id: data.item.id}}"
                        target="_blank">
                    {{data.item.title}}
                </b-link>
                <template v-else>
                    {{data.item.title}}
                </template>
            </template>
            <template v-slot:filter="data">
                <app-button variant="link"  @click="edit(data.item)" >{{ data.item.filter && data.item.filter.hasOwnProperty('title') ? data.item.filter.title : ""}}</app-button>
            </template>
 >
        </app-table-v2>
        <app-aside v-model="edit_aside"  :widths="['col-lg-4']">
            <template v-slot:header >
              {{$t('GROUP')}}
            </template>
            <revenue-manager-filter-form @close="closeAndUpdate"  :id="editfilter.id"></revenue-manager-filter-form>
        </app-aside>

    </div>
</template>

<script>
import AppTableV2 from "@/components/app/AppTableV2";
import AppAside from "@/components/app/form/AppAside";
import AppButton from "@/components/app/AppButton/AppButton";
import {

    DEFAULT_PAGINATION_LIMIT
} from "@/shared/constants";
import {
    createRmFilterUnit,
    getRmFilterUnit,
    getRmFilterUnitById,
    rmFilterSetUnits,
    searchRmUnits
} from "@/services/revenue_manager";
import {EventBus} from "@/shared/EventBus";
import RevenueManagerGroupFilterForm
    from "@/components/revenue_manager/similar_listing/unit/RevenueManagerGroupFilterForm";
import RevenueManagerGroupAssignToFilter
    from "@/components/revenue_manager/similar_listing/unit/RevenueManagerGroupAssignToFilter";
import {R_PROPERTY_BASIC,R_PROPERTY_UNIT_BASIC} from "@/shared/route_permission";
import routeNames from "@/router/routeNames";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {toast} from "@/shared/plugins/toastr";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {FILTER_TITLE} from "@/shared/error_codes";
import AppInputControl from "@/components/app/AppInputControl";
import RevenueManagerFilterForm from "@/components/revenue_manager/similar_listing/RevenueManagerFilterForm";

export default {
    name: "RevenueManagerSimilarUnitList",
    props:{
        filter:{
            type:Object,
            default(){
                return {}
            }
        }
    },
    mixins: [getErrorMessage],
    components: {
        RevenueManagerFilterForm,
        AppInputControl,
        AppConfirmationDialog,
        AppSelect,
        AppButtonSubmit,
        RevenueManagerGroupAssignToFilter, RevenueManagerGroupFilterForm, AppButton, AppTableV2,AppAside},
    data(){
        return {
            validationErrorObject:{
                title:FILTER_TITLE
            },
            routeNames,
            R_PROPERTY_BASIC,
            R_PROPERTY_UNIT_BASIC,
            aside:false,
            new_aside:false,
            filterObject:null,
            fields: [
                {key: 'id', label: this.$t('ID')},
                {key: 'title', label: this.$t('UNIT')},
                {key: 'unit_type.label', label: this.$t('TYPE')},
                {key: 'property.city', label: this.$t('CITY')},
                {key: 'filter', label: this.$t('GROUP')},

            ],
            EVENT_TABLE_SEARCH:'gl_revenue_manager_similar_units',
            DEFAULT_PAGINATION_LIMIT,
            selected:[],
            edit_aside:false,
            editObject:{
                title:null
            },
            editfilter:{},
            conftirmationAssign:false,
            confirmationNewGroup:false,
            selectAllForNewGroup:false,
            selectAllForAssign:false,
            loading_assign:false,
            unit_ids:[],
            title_new_group:null,
            loading_new_group:false,
            edit_unit:[],
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods:{

        closeAndUpdate(){
            this.edit_aside=false;
            EventBus.$emit(this.EVENT_TABLE_SEARCH)
        },
        saveNewGroup(selected){
            this.loading_new_group = true
            createRmFilterUnit({title:this.title_new_group}).then(response=>{

                let request = {}
                if(this.selectAllForNewGroup){
                    request = {...request,...{filter:this.filter}}
                } else {
                    let units = selected.map(el=>{
                        return el.id
                    })
                    request = {...request,...{units}}
                }
                rmFilterSetUnits(response.data,request).then(response =>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    EventBus.$emit(this.EVENT_TABLE_SEARCH)
                },error=>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(()=>{
                    this.loading_new_group = false
                    this.title_new_group = null
                })
            })
        },
        assignToGroupCheck(selected){
            const assignedFilterList = selected.filter(el=>el.filter !== null)
            if(assignedFilterList.length > 0){
               this.aside = false
                this.conftirmationAssign = true
            } else {

                this.saveAssignToGroup(selected)
            }
        },
        addNewGroupCheck(selected){
            const assignedFilterList = selected.filter(el=>el.filter !== null)
            if(assignedFilterList.length > 0){
                this.new_aside = false
                this.confirmationNewGroup = true
            } else {
                this.saveNewGroup(selected)
            }
        },
        searchFilter(value){
            const req = {
                title:value,
                page: 1,
                perPage: 100,
                company: this.company.id
            }
            return getRmFilterUnit(req).then(response => ({data: response.data.items}))
        },
        saveAssignToGroup(selected){
            this.loading_assign = true
            let units = []


            getRmFilterUnitById(this.filterObject.id).then(response=>{
               const unit_list = response.data.unit_list
                unit_list.forEach(unit=>{
                    units.push(unit.id)
                })
                let request = {}

                if(this.selectAllForAssign){
                    let filterObject = this.filter
                    filterObject = {...filterObject,...{company:this.company.id}}
                    request = {...request,...{filter:filterObject}}
                } else {
                  selected.forEach(el=>{
                       units.push(el.id)
                    })

                }
                request = {...request,...{units}}
                rmFilterSetUnits(this.filterObject.id,request).then(response =>{
                    EventBus.$emit(this.EVENT_TABLE_SEARCH)
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                },error=>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })

                }).finally(()=>{
                    this.loading_assign = false
                    this.conftirmationAssign = false
                    this.filterObject = null
                })
            })



        },
        assignToFilter(selected){
            this.selected = selected.length > 0 ? selected.map(unit=>{
                return unit.id
            }) : []
            this.aside = true
        },
        groupNew(selected){

            this.selected = selected.length > 0 ? selected.map(unit=>{
                return unit.id
                }) : []
            this.new_aside = true
        },
        toggleBusy($event){
            this.$emit('busyToggled', $event)
        },

        getProvider(filter){
            let req = {
                ...this.filter,
                ...{
                    page: filter.currentPage,
                    perPage: filter.perPage,
                    order_by: filter.sortBy,
                    order_by_direction: filter.sortDirection,
                    company: this.company.id
                }
            }
            return   searchRmUnits(req)
        },
        edit(object){
            this.edit_unit = []
            this.editObject = object
            this.edit_aside = true
            this.editfilter = object.filter
            this.edit_unit.push(object.id)
        },

    },
    watch: {
        filter: {
            handler(value) {
                EventBus.$emit(this.EVENT_TABLE_SEARCH)
            },
        },

    }
}
</script>

<style scoped>

.no-border {
    box-shadow: none;
}

.no-border > div {
    border: none !important;
    padding-left: 0;
}
</style>
