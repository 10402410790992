<template>
    <div v-if="distribution">
        <app-no-data v-if="emptyInitialList" :heading="$t('MODULE_NOT_ACTIVE')"
                     :tip="$t('MODULE_NEED_PROPERTIES')" :show-tip="true">
            <new-button v-if="checkPermission(R_PROPERTY_NEW)" button_type="new" variant="primary"
                        class="pull-right ml-3" @click="property_aside = true">
                {{ $t('NEW_PROPERTY') }}
            </new-button>
            <app-button
                :access_control_context="{function: C_PROPERTY_IMPORT_FROM_OTA, key: access_control_fetch_key}"
                @AccessControlEvent="addToAccessControlCounter()"
                class="pull-right ml-3"
                @click="importPropertyModalState = true">
                {{ $t("IMPORT_FROM_OTA") }}
            </app-button>
        </app-no-data>
        <property-filter
            v-if="!emptyInitialList"
            :has-filter="hasFilter"
            :distribution_id="distribution.id">
        </property-filter>
        <property-list-table
            v-if="!emptyInitialList"
            :has-filter="hasFilter"
            :empty_initial_list="emptyInitialList"
            :distribution_id="distribution.id"
            :selectable="false">
        </property-list-table>
        <b-modal
            v-model="importPropertyModalState"
            :title="$t('IMPORTANT')">
            <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "Booking.com"}) }}</p>
            <ul>
                <li>{{ $t("IMPORT_PROPERTY_FROM_BOOKING_DISCLAIMER_1") }}</li>
                <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
            </ul>
            <app-button
                @click="importFromDistribution(BOOKING)">
                {{ $t("IMPORT") }}
            </app-button>
            <br/>
            <br/>
            <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "AirBnB"}) }}</p>
            <ul>
                <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_1") }}</li>
                <li>{{
                        $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_2", {
                            "min_image_width": 2048,
                            "min_image_height": 1536
                        })
                    }}
                </li>
                <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_3") }}</li>
                <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
            </ul>
            <app-button
                @click="importFromDistribution(AIRBNB)">
                {{ $t("IMPORT") }}
            </app-button>
            <template slot="modal-footer">
                <template slot="modal-ok">
                </template>
            </template>
        </b-modal>
        <app-aside :widths="['col-sm-12', '40%']" v-model="property_aside">
            <template slot="header">{{ $t('NEW_PROPERTY') }}</template>
            <property-wizard></property-wizard>
        </app-aside>
        <app-aside
            v-model="importPropertyWizardState"
            :widths="['col-lg-12', '100%']">
            <template slot="header">{{ $t('IMPORT_FROM_OTA') }}</template>
            <import-property-wizard
                @importProperty="closeImportWizard"
                @close="closeImportWizard"
                :distribution="importDistribution"
                :entity="importEntity">
            </import-property-wizard>
        </app-aside>
    </div>
</template>

<script>
import PropertyFilter from "@/components/property/list/PropertyFilter";
import PropertyListTable from "@/components/property/list/PropertyListTable";
import {getDistribution} from "@/services/distribution";
import {AIRBNB, BOOKING, DEFAULT_PAGINATION_LIMIT, LS_PRESETS} from "@/shared/constants";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import {C_PROPERTY_IMPORT_FROM_OTA, R_PROPERTY_NEW} from "@/shared/route_permission";
import {AC_PROPERTY_IMPORT} from "@/mixins/AccessControl/AccessControlEnumeration";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import PropertyWizard from "@/components/property/new/PropertyWizard";
import NewButton from "@/components/app/AppButton/AppButton";
import ImportPropertyWizard from "@/components/property/import/ImportPropertyWizard";
import AppNoData from "@/components/app/AppNoData";

export default {
    name: "Index",
    components: {
        PropertyListTable,
        PropertyFilter, AppButton, AppAside,
        PropertyWizard,
        NewButton, ImportPropertyWizard, AppNoData
    },
    mixins: [AccessControlComponent],
    data() {
        return {
            hasFilter: true,
            emptyInitialList: false,
            distribution: null,
            R_PROPERTY_NEW,
            C_PROPERTY_IMPORT_FROM_OTA,
            property_aside: false,
            importPropertyModalState: false,
            importPropertyWizardState: false,
            BOOKING,
            AIRBNB,
            importDistribution: null,
            importEntity: null,
            access_control_components: 1,
            access_control_fetch_key: AC_PROPERTY_IMPORT,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods: {
        importPropertyTermsConfirmed() {
            this.importPropertyModalState = false
            this.importPropertyWizardState = true
        },
        importFromDistribution(distributionId) {
            this.importDistribution = distributionId
            this.importPropertyTermsConfirmed()
        },
        closeImportWizard() {
            this.importPropertyWizardState = false
            this.importDistribution = null
            this.importEntity = null
            this.$router.replace({"query": null})
        },
    },
    watch: {
        "$route.params.distribution_id": {
            handler(value) {
                if (value) {
                    getDistribution(value).then((response) => {
                        this.distribution = response.data
                    }, () => {
                        this.distribution = null
                    })
                }
            },
            immediate: true,
        },
    },
    created() {
        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)
            if (storagePresets.hasOwnProperty("properties") && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                if (storagePresets.properties === 0) {
                    this.emptyInitialList = true
                }
                this.hasFilter = false
            }
        }
        this.access_control_general_context = {
            company: this.company.id
        }
    },
}
</script>

<style scoped>

</style>
