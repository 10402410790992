<template>
    <div
        class="main_calendar_test--cell main_calendar_test--background_gray d-flex flex-column justify-content-around main_calendar_test-bb animated fadeIn">

        <div class="main_calendar_test__holder--sidebar-h1 position-relative main_calendar_test_default-height-row"
             :class="additionalData.los.length === 1 && additionalData.occupancy.length ===1 ? '' : 'main_calendar_test--poiner'">
            <b-tooltip  custom-class="my-tooltip-class" :target="'tooltip-target_help_rates-'+unit.id" >
                <template v-if="additionalData.los.length > 1 && defaultLosLabel">{{ $t("STAY").toLocaleLowerCase() }} {{ defaultLosLabel }}</template>
                <template
                    v-if="additionalData.los.length > 1 &&  defaultLosLabel"> / </template>{{ $t("OCCUPANCY_PERSONS").toLocaleLowerCase() }}
                {{ defaultOccupancyLabel }}
                <template v-if="showPlatformFeeDisclaimer && !!platformFeeLabel">
                    <b-row>
                        <b-col>
                            {{ $t("BOOKING_FEE_VALUE_CALENDAR_DISCLAIMER", {booking_fee_value: platformFeeLabel}) }}
                        </b-col>
                    </b-row>
                </template>
            </b-tooltip>
            <i :id="'tooltip-target_help_rates-'+unit.id"
               class=" fa fa-question-circle-o ml-1 pull-right tooltip-help tooltip-help-normal mt-1 "></i>
            <div :style="show ? 'margin-top: 0.3rem' : 'margin-top:0.7rem'">
              <span @click="handleClick" :class="openClass">{{ $t("RATES") }}
            </span>
                <app-button @click="handlePricingClick(0)" variant="link" button_type="edit" :show_text="false"
                            class="ml-2 action_button"></app-button>
                <app-button variant="link" button_type="plus"
                            v-if="checkPermission(C_PROPERTY_CALENDAR_PRICES_RATE_PLANS_V) &&(show || !expand_los_occ)"
                            @click="modalShow=true" :show_text="false" class="ml-1 action_button"></app-button>

            </div>

        </div>
        <div v-if="show && (additionalData.los.length > 1 || additionalData.occupancy.length > 1)"
             class="d-flex flex-nowrap justify-content-around">
            <div v-if="additionalData.los.length > 1" class="main_calendar_test__holder--sidebar-h3 text-muted">
                {{ $t("LENGTH_OF_STAY") }}
            </div>
            <div v-if="additionalData.occupancy.length > 1" class="main_calendar_test__holder--sidebar-h3 text-muted">
                {{ $t("OCCUPANY_PERSONS") }}
            </div>
        </div>

        <b-modal scrollable size="md" v-if="checkPermission(C_PROPERTY_CALENDAR_PRICES_RATE_PLANS_V)"
                 v-model="modalShow"
                 :title="$t('RATE_PLAN_SELECTION')">

            <b-form>
                <b-row>
                    <b-col lg="12" :key="distributionRate.id"
                           v-for="(distributionRate, distributionName) in listFormatted">
                        <b-form-checkbox @change="updateValue($event, distributionName)"
                                         v-if="distributionName !== 'system'">
                            {{ distributionName }}
                        </b-form-checkbox>
                        <b-form-checkbox-group
                            ref="rateplan-checkbox-list"
                            stacked
                            :id="'rateplan-checkbox-list_'+distributionRate[0].id"
                            value-field="id"
                            text-field="name"
                            :options="distributionRate"
                            name="rateplan-checkbox-list"
                            v-model="selected"
                            :class="distributionName !=='system' ? 'ml-4':''"
                        >
                        </b-form-checkbox-group>

                        <hr>
                    </b-col>
                </b-row>
            </b-form>


            <div slot="modal-footer">
                <app-button :loading="loader" @click="update">{{ $t('CONFIRM') }}</app-button>
                <app-button class="mr-2 ml-2 pl-2" @click="modalShow =false">{{ $t('CANCEL') }}</app-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {EventBus, GE_CALENDAR_OPEN_PRICING} from "@/shared/EventBus";
import {
    C_PROPERTY_CALENDAR_PRICES_RATE_PLANS_V,
    C_UNIT_CALENDAR_OCCUPANCY_E,
    C_UNIT_CALENDAR_LOS_E
} from '@/shared/component_permission'
import AppButton from "@/components/app/AppButton/AppButton";
import {BOOKING, FIX, PERC} from "@/shared/constants";

export default {
    name: "TableRatesSidebar",
    components: {AppButton},
    props: {
        unit: {
            type: Object
        },
        ratePlans: {
            type: Array,
            default: () => {
                return []
            }
        },
        show: {
            type: Boolean,
            default: false
        },
        unselected: {
            type: Array,
            default: () => {
                return []
            }
        },
        loader: {
            type: Boolean,
            default: false
        },

        expand_los_occ: {
            type: Boolean,
            default: false
        },
        additionalData: {
            type: Object
        },
        showPlatformFeeDisclaimer: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            modalShow: false,
            selected: [],
            C_PROPERTY_CALENDAR_PRICES_RATE_PLANS_V: C_PROPERTY_CALENDAR_PRICES_RATE_PLANS_V,
            ratePlanDistribution: [],
            select_distribution: [],
            comp_key: 1,
            showAddRatePlan: false
        }
    },
    computed: {

        defaultLosLabel() {
            let defaultLos = this.additionalData.los.find(item => item.default === 1)
            if (defaultLos && Object.keys(defaultLos).length > 1) {
                if (!defaultLos.max_stay) {
                    return defaultLos.min_stay + '+'
                }
                if (defaultLos.min_stay === defaultLos.max_stay) {
                    return defaultLos.min_stay
                }
                if (defaultLos.min_stay !== defaultLos.max_stay) {
                    return defaultLos.min_stay + '-' + defaultLos.max_stay
                }

            }
            return null
        },
        defaultOccupancyLabel() {
            let defaultOccupancy = this.additionalData.occupancy.find(item => item.default === 1)
            if (defaultOccupancy && Object.keys(defaultOccupancy).length > 1) {
                if (defaultOccupancy.min_guests === defaultOccupancy.max_guests) {
                    return defaultOccupancy.min_guests
                }
                if (defaultOccupancy.min_guests !== defaultOccupancy.max_guests && defaultOccupancy.max_guests !== null) {
                    return defaultOccupancy.min_guests + '-' + defaultOccupancy.max_guests
                }
                if (!defaultOccupancy.max_guests) {
                    return defaultOccupancy.min_guests + '+'
                }
            }
            return null
        },
        openClass() {
            return this.expand_los_occ ? this.show ? 'main_calendar_test--dropdown-down' : 'main_calendar_test--dropdown-up' : ''
        },
        listFormatted() {

            let ratePlanList = this.ratePlans.map(item => {
                return {...item, ...{name: item.name}}
            })

            let list = {}

            ratePlanList.forEach(currRatePlan => {
                if (currRatePlan.distribution_name === null) {
                    if (!list.hasOwnProperty('system')) {
                        list['system'] = []
                    }
                    list['system'].push(currRatePlan)
                } else {
                    if (!list.hasOwnProperty(currRatePlan.distribution_name)) {
                        list[currRatePlan.distribution_name] = []
                    }
                    list[currRatePlan.distribution_name].push(currRatePlan)
                }
            })
            return list
        },
        platformFeeLabel() {
            let platformFeeLabel = null
            let highestPercentageValue = null
            let highestFixValue = null
            const bookingRatePlans = this.ratePlans.filter((ratePlan) => ratePlan.distribution === BOOKING)
            if (bookingRatePlans.length > 0) {
                bookingRatePlans.forEach((bookingRatePlan) => {
                    if (bookingRatePlan.hasOwnProperty("platform_fee_setup") && Array.isArray(bookingRatePlan.platform_fee_setup) && bookingRatePlan.platform_fee_setup.length > 0) {
                        bookingRatePlan.platform_fee_setup.forEach((platformFee) => {
                            if (platformFee.value_type_id === PERC && Number(platformFee.value) > 0 && (!highestPercentageValue || Number(platformFee.value) > highestPercentageValue)) {
                                highestPercentageValue = Number(platformFee.value)
                            }
                            if (platformFee.value_type_id === FIX && Number(platformFee.value) > 0 && (!highestFixValue || Number(platformFee.value) > highestFixValue)) {
                                highestFixValue = Number(platformFee.value)
                            }
                        })
                    }
                })
            } else {
                this.ratePlans.forEach((ratePlan) => {
                    if (!platformFeeLabel && ratePlan.hasOwnProperty("platform_fee_setup") && Array.isArray(ratePlan.platform_fee_setup) && ratePlan.platform_fee_setup.length > 0) {
                        ratePlan.platform_fee_setup.forEach((platformFee) => {
                            if (platformFee.value_type_id === PERC && Number(platformFee.value) > 0 && (!highestPercentageValue || Number(platformFee.value) > highestPercentageValue)) {
                                highestPercentageValue = Number(platformFee.value)
                            }
                            if (platformFee.value_type_id === FIX && Number(platformFee.value) > 0 && (!highestFixValue || Number(platformFee.value) > highestFixValue)) {
                                highestFixValue = Number(platformFee.value)
                            }
                        })
                    }
                })
            }
            if (!!highestPercentageValue) {
                platformFeeLabel = highestPercentageValue.toString() + "%"
            } else if (!!highestFixValue) {
                platformFeeLabel = highestFixValue.toString() + " fix"
            }
            return platformFeeLabel
        },
    },
    methods: {
        updateValue(value, distribution_name) {
            let distributionRatePlanSelected = this.ratePlans.filter(el => el.distribution_name === distribution_name).map(el => {
                return el.id
            })
            if (distributionRatePlanSelected.length === 0) {
                return
            }
            if (value) {

                distributionRatePlanSelected.forEach(id => {
                    if (!this.selected.includes(id)) {
                        this.selected.push(id)
                    }
                })
            } else {
                let filtered = this.selected.filter(id => !distributionRatePlanSelected.includes(id))
                this.selected = filtered
            }
        },
        handleClick() {
            if (this.additionalData.los.length === 1 && this.additionalData.occupancy.length === 1) {
                return
            }
            EventBus.$emit(GE_CALENDAR_OPEN_PRICING, {
                unitId: this.unit.id
            })
        },
        handlePricingClick(val) {
            this.$emit('pricingSetup', val)
        },
        update() {
            this.$emit('updateRatePlanListPricing', this.selected)
            this.modalShow = false
        }
    },
    watch: {
        unselected: {
            handler(list) {
                if (list.length > 0) {
                    list.forEach(el => {
                        let index = this.selected.findIndex(item => item === el)
                        if (typeof index === 'undefined') {
                            return
                        }
                        this.selected.splice(index, 1)
                    })
                }
            }, immediate: true,
        }
    }
}
</script>

<style scoped>

</style>
