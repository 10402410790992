<template>
  <tr>
    <td>
      <p>
        {{ratePlan.name}} ({{ratePlan.id}})
      </p>
    </td>
    <td>
        <app-select v-model="mappingValue"  :options="externalRatePlans">
        </app-select>

    </td>
    <td class="text-right">
      <delete-rate-map v-if="edited === false" :loading="deleteState" @click="deleteMapping"></delete-rate-map>
      <save-rate-map v-else
        @click="save" :disabled="!mappingValue"></save-rate-map>
    </td>
  </tr>
</template>

<script>
  import SaveRateMap from '@/components/app/AppButton/AppButtonSubmit'
  import DeleteRateMap from "@/components/app/AppButton/AppButtonDelete"
  import AppSelect from "@/components/app/AppSelect/AppSelect";
  import _findKey from 'lodash/findKey'

  export default {
    name: "rate-plan-mapping-item",
    props: {
      ratePlan: {
        type: Object
      },
        mappingRateData:{
          type:Array
        },

      externalRatePlans: {
        type: Array
      }
    },
    data() {
      return {
        deleteState: false,
        mappingValue: null,
        mapping:null
      }
    },
    components: {
        AppSelect,
        SaveRateMap,
      DeleteRateMap
    },
    computed:{

      edited(){
        if ((!this.mapping)||(this.mappingValue !==  this.mapping.rate_plan_eid) ) {
          return true
        }
        return false
      },
        mappingObject(){
          return this.externalRatePlans.find(el => {
              if(el.id === this.mappingValue){
                  return el
              }
          })
            return null
        }


    },
    methods: {
        setMapping(mappingRateData) {

            if (typeof mappingRateData !== 'undefined') {
                if (mappingRateData.length > 0) {

                    let newElementKey = _findKey(mappingRateData, (el) => {
                        return el.rate_plan_id === this.ratePlan.id
                    })
                    if (typeof  newElementKey !== 'undefined') {
                        this.mappingValue =  mappingRateData[newElementKey].rate_plan_eid
                        this.mapping = mappingRateData[newElementKey]

                    } else {
                       this.mappingValue = null
                    }
                } else {
                    this.mapping = null
                    this.mappingValue = null
                }
            }
        },
      save(){
        let objectData = {
          rate_plan_id: this.ratePlan.id,
          rate_plan_eid: this.mappingObject.id,
          external_name: this.mappingObject.name
        }
        this.$emit('saveRateMapping', objectData)

      },
      deleteMapping(){
        this.$emit('deleteRateMapping', this.mapping.id)
      }
    },
      watch:{
          mappingRateData:{
              handler(array){

                  this.setMapping(array)
              },
              immediate:true,
              deep:true
          }
      },
  }
</script>

<style scoped>

</style>
