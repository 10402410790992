<template>
    <div>
        <form @submit.prevent="save">
            <b-row class="mb-4">
                <b-col md="6">
                    <label>
                        {{ $t("RATE_PLAN") }}
                    </label>
                    <app-select
                        v-model="selectedRatePlanId"
                        :search-empty-item="false"
                        :options="ratePlanOptions">
                    </app-select>
                </b-col>
            </b-row>
            <b-row :key="restriction.id"
                   v-for="(restriction) in restrictions">
                <b-col lg="6" md="6" class="mb-4">
                    <component
                        :disabled="disabledRestriction(restriction.id)"
                        @input="setUpdate($event, restriction.id)"
                        :restriction="restriction"
                        v-model="fields[restriction.id]['value']"
                        :allowed_values="restriction.allowed_values.allowed_values"
                        :has_tooltip_message="disabledRestriction(restriction.id)"
                        :message="$t('RESTRICTION_NOT_ALLOWED_CHANGE', {rate_plan_name: (disabledRestriction(restriction.id) ? parentRatePlanName : '')})"
                        :is="restriction.allowed_values.element_type + 'Element'">
                    </component>
                </b-col>
                <b-col lg="6" md="6" class="mb-4"
                       v-if="changes.indexOf(restriction.id) > -1 && byRestrictionFilteredRatePlanOptionsGroupedByDistribution.hasOwnProperty(restriction.id) && byRestrictionFilteredRatePlanOptionsGroupedByDistribution[restriction.id].length > 0">
                    <label>{{ $t("APPLY_TO_RATE_PLANS") }}</label>
                    <app-select
                        :disabled="selectedRatePlanId === null"
                        mode="multiselect"
                        :value="getRatePlanRestrictionValue(restriction.id)"
                        @input="updateRatePlanRestriction($event, restriction.id)"
                        :options="byRestrictionFilteredRatePlanOptionsGroupedByDistribution[restriction.id]"
                        group="rate_plans"
                        group-select-all
                        value-field="id"
                        text-field="name">
                    </app-select>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <app-button-submit
                        :loading="saving"
                        v-if="checkPermission(C_PROPERTY_CALENDAR_RESTRICTIONS_E)"
                        :disabled="selectedRatePlanId === null || periods.length === 0 || changes.length === 0">
                    </app-button-submit>
                </b-col>
            </b-row>
        </form>
    </div>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import SelectRestrictionFormElement from "@/components/unit/calendar/forms/restriction/SelectRestrictionFormElement";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {
    CLOSED_ON_ARRIVAL,
    CLOSED_ON_DEPARTURE,
    MAX_STAY,
    MIN_STAY,
    RATE_PLAN_TYPE_NON_REFUNDABLE,
    RATE_PLAN_TYPE_STANDARD,
    RELEASE_PERIOD,
    SALES_ENABLED,
} from "@/shared/constants";
import {C_PROPERTY_CALENDAR_RESTRICTIONS_E} from "@/shared/component_permission";
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import {getUnitCalendarValues, setUnitRestrictions} from "@/services/unit";
import {
    EventBus,
    GE_CALENDAR_LOAD_RESTRICTIONS,
    GE_CALENDAR_REFRESH_DATA,
    GE_LOADER_HIDE,
    GE_LOADER_SHOW
} from "@/shared/EventBus";
import {cloneDeep, each, has} from "lodash";
import {getCalendarValues} from "@/services/property";
import MultiselectRestrictionFormElement
    from "@/components/unit/calendar/forms/restriction/MultiselectRestrictionFormElement";

export default {
    name: "CalendarEditRestrictionsForm",
    components: {
        AppButtonSubmit,
        AppSelect,
        "SelectElement": SelectRestrictionFormElement,
        "CheckboxElement": MultiselectRestrictionFormElement,
    },
    mixins: [getErrorMessage],
    props: {
        unit: {
            type: Object,
        },
        selectedUnitList: {
            type: Array,
            default: () => {
                return []
            },
        },
        unitList: {
            type: Array,
            default: () => {
                return []
            },
        },
        periods: {
            type: Array,
            default: () => {
                return []
            },
        },
        allowedWeekday: {
            type: Array,
            default: () => {
                return []
            },
        },
        defaultValues: {
            type: Object,
            default: () => {
                return null
            },
        },
        ratePlans: {
            type: Array | Object,
            default: () => {
                return null
            },
        },
        singleUnit: {
            type: Boolean,
            default: true,
        },
        ratePlanId: {
            type: Number,
        },
    },
    data() {
        return {
            unitId: null,
            saving: false,
            defaultValues_: null,
            selectedRatePlanId: null,
            ratePlanList: [],
            fields: {},
            changes: [],
            minStayRatePlanList: [],
            maxStayRatePlanList: [],
            releasePeriodRatePlanList: [],
            closedOnArrivalRatePlanList: [],
            closedOnDepartureRatePlanList: [],
            byRestrictionRatePlanOptions: {},
            byRestrictionFilteredRatePlanOptionsGroupedByDistribution: {},
            applyToUnitList: [],
            restrictionDefaultValues: {
                MIN_STAY: {
                    id: MIN_STAY,
                    value: null,
                },
                MAX_STAY: {
                    id: MAX_STAY,
                    value: null,
                },
                RELEASE_PERIOD: {
                    id: RELEASE_PERIOD,
                    value: null,
                },
                CLOSED_ON_ARRIVAL: {
                    id: CLOSED_ON_ARRIVAL,
                    value: null,
                },
                CLOSED_ON_DEPARTURE: {
                    id: CLOSED_ON_DEPARTURE,
                    value: null,
                },
            },
            C_PROPERTY_CALENDAR_RESTRICTIONS_E,
        }
    },
    computed: {
        unitOptions() {
            if (this.selectedUnitList.length < 2) {
                return []
            }
            let list = []
            this.selectedUnitList.forEach(unitId => {
                const unit = this.unitList.find(unit => {
                    return unit.id === unitId
                })
                if (unit) {
                    list.push({
                        id: unit.id,
                        name: unit.name,
                    })
                }
            })
            return list
        },
        unitOptionsExcludingSelected() {
            return this.unitOptions.filter(unit => unit.id !== this.unitId)
        },
        restrictions() {
            const restrictionList = this.$store.getters["shared/getRestrictions"]
            let filtered = {}
            if (restrictionList && typeof restrictionList === "object" && Object.keys(restrictionList).length > 0) {
                for (const restrictionId in restrictionList) {
                    if (!restrictionList.hasOwnProperty(restrictionId) || Number(restrictionId) === SALES_ENABLED) {
                        continue
                    }
                    filtered[restrictionId] = restrictionList[restrictionId]
                }
            }
            return filtered
        },
        ratePlanOptions() {
            let list = {}
            const systemRatePlans = this.$t("SYSTEM_RATE_PLANS")

            if (this.ratePlanList && this.ratePlanList.length > 0) {
                this.ratePlanList.forEach(ratePlan => {
                    if (!ratePlan.inherit_minimum_stay || !ratePlan.inherit_maximum_stay || !ratePlan.inherit_check_in_days || !ratePlan.inherit_check_out_days || !ratePlan.inherit_release_period) {
                        if (ratePlan.distribution_name === null) {
                            if (!list.hasOwnProperty(systemRatePlans)) {
                                list[systemRatePlans] = []
                            }
                            list[systemRatePlans].push(ratePlan)
                        } else {
                            if (!list.hasOwnProperty(ratePlan.distribution_name)) {
                                list[ratePlan.distribution_name] = []
                            }
                            list[ratePlan.distribution_name].push(ratePlan)
                        }
                    }
                })
            }
            return list
        },
        ratePlan() {
            return this.defaultValues_ && typeof this.defaultValues_ === "object" && this.defaultValues_.hasOwnProperty("rate_plan") && this.defaultValues_.rate_plan ? this.defaultValues_.rate_plan : null
        },
        parentRatePlanName() {
            return this.ratePlan && this.ratePlan.parent_rate_plan && this.ratePlan.parent_rate_plan.hasOwnProperty("name") ? this.ratePlan.parent_rate_plan.name : null
        },
        computedFields() {
            return cloneDeep(this.fields)
        },
    },
    methods: {
        disabledRestriction(restrictionId) {
            if (this.selectedRatePlanId === null) {
                return true
            }
            if (this.ratePlan) {
                if (restrictionId === MIN_STAY) {
                    return this.ratePlan.inherit_minimum_stay
                }
                if (restrictionId === MAX_STAY) {
                    return this.ratePlan.inherit_maximum_stay
                }
                if (restrictionId === RELEASE_PERIOD) {
                    return this.ratePlan.inherit_release_period
                }
                if (restrictionId === CLOSED_ON_ARRIVAL) {
                    return this.ratePlan.inherit_check_in_days
                }
                if (restrictionId === CLOSED_ON_DEPARTURE) {
                    return this.ratePlan.inherit_check_out_days
                }
            }
            return false
        },
        formatFields() {
            this.fields = {}
            if (this.restrictions && typeof this.restrictions === "object" && Object.keys(this.restrictions).length > 0) {
                for (let restrictionId in this.restrictions) {
                    if (this.restrictions.hasOwnProperty(restrictionId)) {
                        if (Number(restrictionId) === CLOSED_ON_ARRIVAL || Number(restrictionId) === CLOSED_ON_DEPARTURE) {
                            this.$set(this.fields, restrictionId, {id: restrictionId, value: "0"})
                        } else {
                            this.$set(this.fields, restrictionId, {id: restrictionId, value: null})
                        }
                    }
                }
            }
        },
        setUpdate(value, restrictionId) {
            if (this.changes.indexOf(Number(restrictionId)) < 0) {
                this.changes.push(Number(restrictionId))
            }
            /*if (value === null && restrictionId !== MAX_STAY) {
                let index = this.changes.findIndex(restrictionId)
                if (index >= 0) {
                    this.changes.splice(index, 1)
                }
            } else {
                if (this.changes.indexOf(Number(restrictionId)) < 0) {
                    this.changes.push(Number(restrictionId))
                }
            }*/
        },
        save() {
            if (!this.changes.length) {
                toast({
                    "title": this.$t("NOTIFICATIONS.SAVE_ERROR.TITLE"),
                    "message": this.$t("NO_CHANGE"),
                    "type": "error",
                    "timeout": 6000
                })
                return
            }
            let restrictionItems = []

            for (let itemId in this.fields) {
                if (this.changes.includes(Number(itemId)) && this.fields.hasOwnProperty(itemId)) {
                    if (Number(itemId) === MIN_STAY) {
                        restrictionItems.push({
                            rate_plan: this.selectedRatePlanId,
                            restriction: itemId,
                            value: this.fields[itemId].value
                        })

                        if (this.minStayRatePlanList.length > 0) {
                            this.minStayRatePlanList.forEach(rateId => {
                                restrictionItems.push({
                                    rate_plan: rateId,
                                    restriction: itemId,
                                    value: this.fields[itemId].value
                                })
                            })
                        }
                    }
                    if (Number(itemId) === MAX_STAY) {
                        restrictionItems.push({
                            rate_plan: this.selectedRatePlanId,
                            restriction: itemId,
                            value: this.fields[itemId].value
                        })

                        if (this.maxStayRatePlanList.length > 0) {
                            this.maxStayRatePlanList.forEach(rateId => {
                                restrictionItems.push({
                                    rate_plan: rateId,
                                    restriction: itemId,
                                    value: this.fields[itemId].value
                                })
                            })
                        }
                    }

                    if (Number(itemId) === RELEASE_PERIOD) {
                        restrictionItems.push({
                            rate_plan: this.selectedRatePlanId,
                            restriction: itemId,
                            value: this.fields[itemId].value
                        })

                        if (this.releasePeriodRatePlanList.length > 0) {
                            this.releasePeriodRatePlanList.forEach(rateId => {
                                restrictionItems.push({
                                    rate_plan: rateId,
                                    restriction: itemId,
                                    value: this.fields[itemId].value
                                })
                            })
                        }
                    }

                    if (Number(itemId) === CLOSED_ON_ARRIVAL) {
                        restrictionItems.push({
                            rate_plan: this.selectedRatePlanId,
                            restriction: itemId,
                            value: this.fields[itemId].value
                        })

                        if (this.closedOnArrivalRatePlanList.length > 0) {
                            this.closedOnArrivalRatePlanList.forEach(rateId => {
                                restrictionItems.push({
                                    rate_plan: rateId,
                                    restriction: itemId,
                                    value: this.fields[itemId].value
                                })
                            })
                        }
                    }
                    if (Number(itemId) === CLOSED_ON_DEPARTURE) {
                        restrictionItems.push({
                            rate_plan: this.selectedRatePlanId,
                            restriction: itemId,
                            value: this.fields[itemId].value
                        })

                        if (this.closedOnDepartureRatePlanList.length > 0) {
                            this.closedOnDepartureRatePlanList.forEach(rateId => {
                                restrictionItems.push({
                                    rate_plan: rateId,
                                    restriction: itemId,
                                    value: this.fields[itemId].value
                                })
                            })
                        }
                    }

                }
            }

            let request = {
                restrictions: [],
                allowed_weekday: this.allowedWeekday
            }
            if (!this.singleUnit && this.applyToUnitList.length > 0) {
                request.apply_to_units = this.applyToUnitList
            }

            this.periods.forEach(periodItem => {
                request.restrictions.push({
                    date_from: periodItem.start,
                    date_to: periodItem.end,
                    restrictions: restrictionItems
                })
            })

            this.saving = true
            setUnitRestrictions(this.unitId, request).then(() => {
                this.changes = []
                EventBus.$emit(GE_CALENDAR_LOAD_RESTRICTIONS, {
                    unitId: this.unitId,
                    ratePlan: this.selectedRatePlanId
                })
                EventBus.$emit(GE_CALENDAR_REFRESH_DATA, {
                    unitId: this.unitId,
                    ratePlan: this.selectedRatePlanId
                })

                // this.$store.dispatch(`calendar/unit_calendar_${this.unitId}/actionSetRestrictions`)

                if (this.applyToUnitList.length > 0 && !this.singleUnit) {
                    this.applyToUnitList.forEach(unitId => {
                        const unit = this.getUnitObject(unitId)
                        if (unit) {
                            EventBus.$emit(GE_CALENDAR_LOAD_RESTRICTIONS, {
                                unitId: unitId,
                                ratePlan: unit.primary_rate_plan.id
                            })
                            EventBus.$emit(GE_CALENDAR_REFRESH_DATA, {
                                unitId: unitId,
                                ratePlan: unit.primary_rate_plan.id
                            })

                            // this.$store.dispatch(`calendar/unit_calendar_${unitId}/actionSetRestrictions`)
                        }
                    })
                }
                notifySuccess()
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.saving = false
            })
        },
        ratePlanByDistributionList(ratePlanList) {
            let list = []
            const systemRatePlans = this.$t("SYSTEM_RATE_PLANS")

            if (ratePlanList && ratePlanList.length > 0) {
                ratePlanList.forEach(ratePlan => {
                    let distributionName = ratePlan.hasOwnProperty("distribution_name") ? ratePlan.distribution_name : null
                    distributionName = !distributionName && ratePlan.hasOwnProperty("distribution") && ratePlan.distribution && ratePlan.distribution.hasOwnProperty("name") && ratePlan.distribution.name ? ratePlan.distribution.name : distributionName
                    distributionName = distributionName === null ? systemRatePlans : distributionName
                    let index = list.findIndex(element => {
                        return element.name === distributionName
                    })
                    if (index < 0) {
                        list.push({
                            name: distributionName,
                            rate_plans: [],
                        })
                    }
                    index = index >= 0 ? index : list.length - 1
                    list[index].rate_plans.push({
                        id: ratePlan.id,
                        name: ratePlan.name,
                    })
                })
            }

            return list
        },
        updateRatePlanRestriction(value, restrictionId) {
            if (restrictionId === MIN_STAY) {
                this.minStayRatePlanList = value
            }
            if (restrictionId === MAX_STAY) {
                this.maxStayRatePlanList = value
            }
            if (restrictionId === RELEASE_PERIOD) {
                this.releasePeriodRatePlanList = value
            }
            if (restrictionId === CLOSED_ON_ARRIVAL) {
                this.closedOnArrivalRatePlanList = value
            }
            if (restrictionId === CLOSED_ON_DEPARTURE) {
                this.closedOnDepartureRatePlanList = value
            }
        },
        getRatePlanRestrictionValue(restrictionId) {
            if (restrictionId === MIN_STAY) {
                return this.minStayRatePlanList
            }
            if (restrictionId === MAX_STAY) {
                return this.maxStayRatePlanList
            }
            if (restrictionId === RELEASE_PERIOD) {
                return this.releasePeriodRatePlanList
            }
            if (restrictionId === CLOSED_ON_ARRIVAL) {
                return this.closedOnArrivalRatePlanList
            }
            if (restrictionId === CLOSED_ON_DEPARTURE) {
                return this.closedOnDepartureRatePlanList
            }
        },
        getUnitObject(unitId) {
            return this.unitList.find(unit => {
                return unit.id === unitId
            })
        },
        setDefaultValues(unitId, ratePlanId = null, showRatePlans = true) {
            EventBus.$emit(GE_LOADER_SHOW)
            const unit = this.getUnitObject(unitId)
            if (this.singleUnit) {
                let show = ["restrictions", "additional_data"]
                if (showRatePlans) {
                    show.push("all_rate_plans")
                }
                getUnitCalendarValues(unitId, {
                    periods: this.periods,
                    rate_plan: ratePlanId ? ratePlanId : (this.selectedRatePlanId ? this.selectedRatePlanId : unit.primary_rate_plan.id),
                    show: show
                }).then(response => {
                    if (has(response, "data.restriction")) {
                        this.restrictionDefaultValues = cloneDeep(response.data.restriction)
                    }
                    if (has(response, "data.additional_data")) {
                        this.setDefaultList(response.data)
                    }
                    if (has(response, "data.rate_plan_list")) {
                        this.ratePlanList = response.data.rate_plan_list
                    }
                    this.defaultValues_ = cloneDeep(response.data)

                }).finally(() => {
                    if (!ratePlanId && !this.selectedRatePlanId) {
                        this.selectedRatePlanId = unit.primary_rate_plan.id
                    }
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            } else {
                let show = ["restrictions", "additional_data"]
                if (showRatePlans) {
                    show.push("rate_plans")
                }
                getCalendarValues({
                    show: show,
                    unit: this.selectedUnitList,
                    rate_plan: ratePlanId ? ratePlanId : (this.selectedRatePlanId ? this.selectedRatePlanId : unit.primary_rate_plan.id),
                    periods: this.periods
                }).then((response) => {
                    let restrictionValueList = response.data.restriction
                    if (restrictionValueList && typeof restrictionValueList === "object") {
                        this.restrictionDefaultValues = cloneDeep(restrictionValueList)
                    }
                    if (has(response, "data.additional_data")) {
                        this.setDefaultList(response.data)
                    }
                    if (has(response, "data.rate_plan_list")) {
                        this.ratePlanList = response.data.rate_plan_list
                    }
                    this.defaultValues_ = cloneDeep(response.data)
                }).finally(() => {
                    if (!ratePlanId && !this.selectedRatePlanId) {
                        this.selectedRatePlanId = unit.primary_rate_plan.id
                    }
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            }
        },
        setDefaultList(defaultValues) {
            if (typeof defaultValues !== "object" || !defaultValues.hasOwnProperty("additional_data")) {
                return
            }
            const restrictions = this.$store.getters["shared/getRestrictions"]
            if (restrictions && typeof restrictions === "object" && Object.keys(restrictions).length > 0) {
                for (const [key, restriction] of Object.entries(restrictions)) {
                    if (restriction.id === MIN_STAY) {
                        this.$set(this.byRestrictionRatePlanOptions, restriction.id, defaultValues.additional_data.min_stay.available_rate_plan_list)
                        this.$set(this.byRestrictionFilteredRatePlanOptionsGroupedByDistribution, restriction.id, this.ratePlanByDistributionList(defaultValues.additional_data.min_stay.available_rate_plan_list))
                        this.minStayRatePlanList = this.getAdditionalDataPreselectedRatePlanList(defaultValues.additional_data.min_stay)
                    }
                    if (restriction.id === MAX_STAY) {
                        this.$set(this.byRestrictionRatePlanOptions, restriction.id, defaultValues.additional_data.max_stay.available_rate_plan_list)
                        this.$set(this.byRestrictionFilteredRatePlanOptionsGroupedByDistribution, restriction.id, this.ratePlanByDistributionList(defaultValues.additional_data.max_stay.available_rate_plan_list))
                        this.maxStayRatePlanList = this.getAdditionalDataPreselectedRatePlanList(defaultValues.additional_data.max_stay)
                    }
                    if (restriction.id === RELEASE_PERIOD) {
                        this.$set(this.byRestrictionRatePlanOptions, restriction.id, defaultValues.additional_data.release_period.available_rate_plan_list)
                        this.$set(this.byRestrictionFilteredRatePlanOptionsGroupedByDistribution, restriction.id, this.ratePlanByDistributionList(defaultValues.additional_data.release_period.available_rate_plan_list))
                        this.releasePeriodRatePlanList = this.getAdditionalDataPreselectedRatePlanList(defaultValues.additional_data.release_period)
                    }
                    if (restriction.id === CLOSED_ON_ARRIVAL) {
                        this.$set(this.byRestrictionRatePlanOptions, restriction.id, defaultValues.additional_data.closed_on_arrival.available_rate_plan_list)
                        this.$set(this.byRestrictionFilteredRatePlanOptionsGroupedByDistribution, restriction.id, this.ratePlanByDistributionList(defaultValues.additional_data.closed_on_arrival.available_rate_plan_list))
                        this.closedOnArrivalRatePlanList = this.getAdditionalDataPreselectedRatePlanList(defaultValues.additional_data.closed_on_arrival)
                    }
                    if (restriction.id === CLOSED_ON_DEPARTURE) {
                        this.$set(this.byRestrictionRatePlanOptions, restriction.id, defaultValues.additional_data.closed_on_departure.available_rate_plan_list)
                        this.$set(this.byRestrictionFilteredRatePlanOptionsGroupedByDistribution, restriction.id, this.ratePlanByDistributionList(defaultValues.additional_data.closed_on_departure.available_rate_plan_list))
                        this.closedOnDepartureRatePlanList = this.getAdditionalDataPreselectedRatePlanList(defaultValues.additional_data.closed_on_departure)
                    }
                }
            }
        },
        getAdditionalDataPreselectedRatePlanList(additionalData) {
            let preselectedRatePlanList = []
            if (additionalData.hasOwnProperty("available_rate_plan_list") && Array.isArray(additionalData.available_rate_plan_list) && additionalData.available_rate_plan_list.length > 0) {
                if (additionalData.checked) {
                    preselectedRatePlanList = additionalData.available_rate_plan_list.map(el => {
                        return el.id
                    })
                } else if (additionalData.hasOwnProperty("checked_distribution_rate_plans") && additionalData.checked_distribution_rate_plans) {
                    preselectedRatePlanList = additionalData.available_rate_plan_list.filter(el => el.distribution && el.distribution.id !== null).map(el => {
                        return el.id
                    })
                } else if (additionalData.hasOwnProperty("checked_distribution_rate_plans_of_same_type") && additionalData.checked_distribution_rate_plans_of_same_type) {
                    preselectedRatePlanList = additionalData.available_rate_plan_list.filter(el => el.distribution && el.distribution.id !== null && this.ratePlan && this.ratePlan.distribution === null && el.type === (this.ratePlan.is_primary === 1 ? RATE_PLAN_TYPE_STANDARD : RATE_PLAN_TYPE_NON_REFUNDABLE)).map(el => {
                        return el.id
                    })
                } else if (additionalData.hasOwnProperty("checked_standard_rate_plan") && additionalData.checked_standard_rate_plan) {
                    preselectedRatePlanList = additionalData.available_rate_plan_list.filter(el => el.is_primary === 1).map(el => {
                        return el.id
                    })
                }
            }
            return preselectedRatePlanList
        },
        filterRatePlanOptions(ratePlanList, restrictionId) {
            if (restrictionId !== MIN_STAY || !ratePlanList || ratePlanList.length === 0) {
                return ratePlanList
            }
            let filteredList = []
            /*const initialValue = this.restrictionDefaultValues && this.restrictionDefaultValues.hasOwnProperty(restrictionId) ? this.restrictionDefaultValues[restrictionId].value : null*/
            const value = this.fields[restrictionId].value
            ratePlanList.forEach(ratePlan => {
                if (ratePlan.los === null || (ratePlan.los.max_stay !== null && value > ratePlan.los.max_stay) || (value >= ratePlan.los.min_stay && (ratePlan.los.max_stay === null || value <= ratePlan.los.max_stay))) {
                    filteredList.push(ratePlan)
                }
            })
            return filteredList
        },
        resetList() {
            this.byRestrictionRatePlanOptions = {}
            this.byRestrictionFilteredRatePlanOptionsGroupedByDistribution = {}

            this.minStayRatePlanList = []
            this.maxStayRatePlanList = []
            this.releasePeriodRatePlanList = []
            this.closedOnArrivalRatePlanList = []
            this.closedOnDepartureRatePlanList = []

            this.changes = []
        },
        setApplyToUnitList() {
            this.applyToUnitList = []
            if (this.singleUnit || this.unitOptionsExcludingSelected.length === 0) {
                return
            }

            this.unitOptionsExcludingSelected.forEach(unit => {
                this.applyToUnitList.push(unit.id)
            })
        },
        setInitialValues(restrictionDefaultValues) {
            if (!restrictionDefaultValues || typeof restrictionDefaultValues !== "object") {
                return
            }
            each(restrictionDefaultValues, (restriction, restrictionId) => {
                if (has(this.fields, `${restrictionId}.value`)) {
                    this.$set(this.fields[restrictionId], "value", restriction.value)
                }
                if (this.fields.hasOwnProperty(restrictionId) && restriction.hasOwnProperty("inherited")) {
                    this.$set(this.fields[restrictionId], "inherited", restriction.inherited)
                }
            })
        },
    },
    watch: {
        singleUnit: {
            handler() {
                this.resetList()
                this.setApplyToUnitList()
            },
            immediate: true,
        },
        unit: {
            handler(value) {
                this.unitId = value && value.hasOwnProperty("id") && value.id ? value.id : null
            },
            deep: true,
            immediate: true,
        },
        defaultValues: {
            handler(value) {
                if (value) {
                    this.setDefaultList(value)
                    if (value.hasOwnProperty("restriction")) {
                        this.restrictionDefaultValues = cloneDeep(value.restriction)
                    }
                    if (value.hasOwnProperty("rate_plan")) {
                        if (this.selectedRatePlanId !== value.rate_plan.id) {
                            this.selectedRatePlanId = value.rate_plan.id
                        }
                    } else {
                        this.selectedRatePlanId = null
                    }
                    if (value.hasOwnProperty("rate_plan_list")) {
                        this.ratePlanList = cloneDeep(value.rate_plan_list)
                    }
                }
                this.defaultValues_ = value ? cloneDeep(value) : null
            },
            deep: true,
            immediate: true,
        },
        selectedUnitList: {
            handler() {
                this.resetList()
                this.setApplyToUnitList()
            },
            deep: true,
            immediate: true,
        },
        unitId: {
            handler() {
                this.resetList()
                this.setApplyToUnitList()
            },
            immediate: true,
        },
        selectedRatePlanId: {
            handler(newValue, oldValue) {
                if (oldValue) {
                    this.resetList()
                }
                if (newValue && oldValue) {
                    this.setDefaultValues(this.unitId, newValue, false)
                }
            },
        },
        restrictions: {
            handler() {
                this.formatFields()
                this.setInitialValues(this.restrictionDefaultValues)
            },
            deep: true,
            immediate: true,
        },
        restrictionDefaultValues: {
            handler(value) {
                this.setInitialValues(value)
            },
            deep: true,
            immediate: true,
        },
        ratePlanId: {
            handler(value) {
                this.selectedRatePlanId = value
            },
            immediate: true,
        },
        ratePlans: {
            handler(value) {
                if (value && Array.isArray(value)) {
                    this.ratePlanList = value
                }
            },
            deep: true,
            immediate: true,
        },
        computedFields: {
            handler(value, oldValue) {
                if (
                    value && value.hasOwnProperty(MIN_STAY) && value[MIN_STAY] && value[MIN_STAY].hasOwnProperty("value") &&
                    this.changes.length > 0 && this.changes.indexOf(MIN_STAY) >= 0 &&
                    (
                        !oldValue || !oldValue.hasOwnProperty(MIN_STAY) || !oldValue[MIN_STAY].hasOwnProperty("value") || oldValue[MIN_STAY].value !== value[MIN_STAY].value
                    )
                ) {
                    if (this.defaultValues_ && this.defaultValues_.hasOwnProperty("additional_data") && this.defaultValues_.additional_data && this.defaultValues_.additional_data.hasOwnProperty("min_stay")) {
                        this.minStayRatePlanList = this.getAdditionalDataPreselectedRatePlanList(this.defaultValues_.additional_data.min_stay)
                    }
                    this.$set(this.byRestrictionFilteredRatePlanOptionsGroupedByDistribution, MIN_STAY, this.ratePlanByDistributionList(this.filterRatePlanOptions(this.byRestrictionRatePlanOptions[MIN_STAY], MIN_STAY)))
                    if (this.minStayRatePlanList.length > 0) {
                        if (this.byRestrictionFilteredRatePlanOptionsGroupedByDistribution[MIN_STAY].length === 0) {
                            this.minStayRatePlanList = []
                        } else {
                            let list = []
                            this.minStayRatePlanList.forEach(ratePlanId => {
                                let inList = false
                                this.byRestrictionFilteredRatePlanOptionsGroupedByDistribution[MIN_STAY].forEach(distributionRatePlans => {
                                    if (!inList && distributionRatePlans.hasOwnProperty("rate_plans") && Array.isArray(distributionRatePlans.rate_plans) && distributionRatePlans.rate_plans.length > 0) {
                                        distributionRatePlans.rate_plans.forEach(ratePlan => {
                                            if (ratePlan.id === ratePlanId) {
                                                inList = true
                                            }
                                        })
                                    }
                                })
                                if (inList) {
                                    list.push(ratePlanId)
                                }
                            })
                            this.minStayRatePlanList = cloneDeep(list)
                        }
                    }
                } else if (!value || !value.hasOwnProperty(MIN_STAY) || !value[MIN_STAY] || !value[MIN_STAY].hasOwnProperty("value") ||
                    this.changes.length === 0 || this.changes.indexOf(MIN_STAY) < 0) {
                    this.$set(this.byRestrictionFilteredRatePlanOptionsGroupedByDistribution, MIN_STAY, [])
                }
            },
            deep: true,
        },
        periods: {
            handler() {
                this.resetList()
            },
            deep: true,
        },
    },
}
</script>

<style scoped>

</style>
