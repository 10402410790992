<template>

    <b-card class="main-section-card">
        <div slot="header">
            <h2>{{ $t("TRANSACTIONS") }}</h2>
        </div>
        <transaction-filter @search="setFilter"  :loading="loading"></transaction-filter>
        <transaction-table :filter="filter" @busyToggled="toggleBusy" ></transaction-table>
    </b-card>
</template>

<script>
import TransactionFilter from "@/components/finance/transaction/TransactionFilter";
import TransactionTable from "@/components/finance/transaction/TransactionTable";
import TransactionForm from "@/components/finance/credit_card/TransactionForm";

export default {
    name: "Index",
    components: {TransactionForm, TransactionTable, TransactionFilter},
    data(){
        return{
            filter:null,
            loading:false
        }
    },
    methods:{
        setFilter(values) {
            this.filter = values
        },
        toggleBusy($event) {
            this.loading = $event
        }
    }
}
</script>

<style scoped>

</style>
