<template>
    <div v-if="(edit_id && policyLoaded) || !edit_id">
        <b-form @submit.prevent="save" autocomplete="off">
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <app-input-control :error-object="validationErrorObject.name">
                            <template v-slot:input="data">
                                <label>{{$t('NAME')}}</label>
                                <b-form-input v-model="cancellationPolicy.label"
                                              type="text"
                                              :state="data.state">
                                </b-form-input>
                            </template>
                        </app-input-control>
                    </b-form-group>
                </b-col>
            </b-row>

            <template v-if="!cancellationPolicy.system_policy || !cancellationPolicy.system_policy.id">
                <b-row>
                    <b-col class="mb-3" sm="12">
                        <label>{{$t('FOC_RESERVATION_CANCELATION')}}</label>
                        <b-checkbox @change="updateFOC"
                                    :checked="cancellationPolicy.foc_cancelation"
                                    switch
                                    :value="1"
                                    :unchecked-value="0">
                        </b-checkbox>
                    </b-col>
                </b-row>
            </template>

        <table class="table" v-if="!(cancellationPolicy.system_policy && cancellationPolicy.system_policy.id)">
            <thead>
            <tr>
                <th>{{$t('CANCELLATION_CONDITION')}}</th>
                <th>{{$t('NUM_DAYS')}}</th>
                <th>{{$t('WHEN')}}</th>
                <th>{{$t('CHARGE')}}</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <cancelation-condition-item
                v-show="condition.deleted !== 1"
                :selectConditionType="selectConditionType"
                :selectDay="selectDay"
                :selectDayType="selectDayType"
                :selectCharge="selectCharge"
                :policy="cancellationPolicy"
                :condition="condition"
                @saveCondition="saveCondition"
                @deleteCondition="removeCondition"
                @conditionChange="reloadText"
                :key="condition.id + '_' + condition.deleted"
                v-for="condition in cancellation_condition_list"
                ref="condition_items">
            </cancelation-condition-item>

            <cancelation-condition-item-new
                :selectConditionType="selectConditionType"
                :selectDay="selectDay"
                :selectDayType="selectDayType"
                :selectCharge="selectCharge"
                :policy="cancellationPolicy"
                :loading="loading_add_new"
                @saveCondition="addCondition">
            </cancelation-condition-item-new>
            </tbody>
        </table>
            <cancellation-policy-text class="mt-4 mb-4"
                                      :policy-text="cancellationPolicyText"
                                      :policy-errors="cancellationPolicyErrors"
                                      :loading="text_loading"
                                      :custom-policy-text="cancellationPolicy.policy_text"
                                      :edit-disabled="Boolean(cancellationPolicy.system_policy && cancellationPolicy.system_policy.id)"
                                      :policy-name="cancellationPolicy.label"
                                      ref="cancellation_policy_text">
            </cancellation-policy-text>
            <b-row class="mb-5">
                <b-col class="d-flex justify-content-between align-items-center">
                    <policy-save-button v-if="!policySetupSaved"
                                        :loading="saving"
                                        :disabled="saveDisabled">
                    </policy-save-button>

                </b-col>
            </b-row>

        </b-form>
    </div>
</template>

<script>
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButton from "@/components/app/AppButton/AppButton";
import AppInputControl from "@/components/app/AppInputControl";
import PolicyDelete from "@/components/property/cancelation_policies/policy/CancelationPolicyItemDelete";
import PolicySaveButton from "@/components/app/AppButton/AppButtonSubmit";
import PolicyConditionList from "@/components/property/cancelation_policies/condition/CancelationConditionList";
import AppAside from "@/components/app/form/AppAside";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {CANCELLATION_POLICY_NAME_MIN_LENGTH} from "@/shared/error_codes";
import {createSystemCancellationPolicy, updateSystemCancellationPolicy} from "@/services/company";
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import {
    createPropertyCancelationPolicy,
    getPropertyCancelationPolicyOptions,
    updatePropertyCancelationPolicy
} from "@/services/property";
import {fetchCancellationPolicy, fetchCancellationPolicyText} from "@/services/cancellation_policy";
import {updateReservationCancellationPolicy} from "@/services/accommodation_reservation";
import CancelationConditionItemNew
    from "@/components/property/cancelation_policies/condition/CancelationConditionItemNew";
import CancelationConditionItem from "@/components/property/cancelation_policies/condition/CancelationConditionItem";
import CancellationPolicyText from "@/components/property/cancelation_policies/policy/CancellationPolicyText";
export default {
    name: "CancellationPolicyForm",
    components: {
        CancellationPolicyText,
        CancelationConditionItem,
        CancelationConditionItemNew,
        AppButton,
        AppConfirmationDialog,
        AppSelect,
        AppInputControl,
        PolicyDelete,
        PolicySaveButton,
        PolicyConditionList,
        AppAside
    },
    mixins: [getErrorMessage],
    data() {
        return {
            validationErrorObject: {
                name: CANCELLATION_POLICY_NAME_MIN_LENGTH
            },
            cancellationPolicy: {
                id: null,
                foc_cancelation: null,
                label: null,
                conditions: [],
                system_policy: null,
                linked_policies: []
            },
            free_cancellation: null,
            cancellationPolicyText: '',
            cancellationPolicyErrors: [],
            text_loading: false,
            saving: false,
            policySetupSaved: false,
            system_policy_save_confirmation_state: false,
            policyLoaded: false,
            applyPolicyAside:false,
            selectConditionType: [],
            selectDay: [],
            selectDayType: [],
            selectCharge: [],
            cancellation_condition_list:[],
            loading_add_new:false,
        }
    },
    props: {
        edit_id: {
            type: Number,
            default: null
        },

        property_id: {
            type: Number,
            default: null
        },
        reservation_id:{
            type:Number,
            default:null
        }
    },
    watch: {
        'cancellationPolicy.conditions': {
            handler() {
                if (this.cancellationPolicy.id) {
                    let request = {policy_id: this.cancellationPolicy.id}
                    this.fetchPolicyText(request)
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        removeCondition(cancellation_condition) {
            let index = this.cancellation_condition_list.findIndex(element => element.id === cancellation_condition.id)
            if (index > -1) {
                this.$set(this.cancellation_condition_list[index], 'deleted', 1)
            }
            this.reloadText()
        },
        addCondition(cancellation_condition) {
            this.loading_add_new = true
            let cancellation_condition_id = 'index_' + this.cancellation_condition_list.length
            this.cancellation_condition_list.push({
                id: cancellation_condition_id,
                condition_type: cancellation_condition.condition_type,
                day: cancellation_condition.day,
                day_type: cancellation_condition.day_type,
                charge: cancellation_condition.charge,
                deleted: 0
            })
            this.$nextTick(() => this.loading_add_new = false)
            this.$nextTick(() => this.reloadText())
        },
        save() {
            this.saving = true
            let cancellation_conditions_request = []
            if (this.$refs.condition_items) {
                this.$refs.condition_items.forEach(condition_item => {
                    const cancellation_condition_data = condition_item.$data.selected
                    if(!cancellation_condition_data.deleted){
                        cancellation_conditions_request.push(
                            {
                                cancelation_days: cancellation_condition_data.day,
                                cancelation_days_type: cancellation_condition_data.day_type,
                                cancelation_charge:cancellation_condition_data.charge,
                                cancelation_condition_type:cancellation_condition_data.condition_type
                            }
                        )
                    }
                })
            }
            const request = {
                label: this.cancellationPolicy.label,
                foc_cancelation: this.cancellationPolicy.foc_cancelation,
                conditions: cancellation_conditions_request
            }

            updateReservationCancellationPolicy(this.reservation_id,request).then(response =>{
                notifySuccess()
                this.$emit('policySaved')
            }, error=>{
                this.showErrorMessages(error)
            }).finally(()=>{
                this.saving = false
            })
        },
        updateFOC(e) {
            this.$set(this.cancellationPolicy, 'foc_cancelation', e)
        },
        saveCondition(policy) {
            this.$emit('savePolicy')
            this.cancellationPolicy = policy
        },
        fetchPolicyText(request) {
            this.text_loading = true
            fetchCancellationPolicyText(request).then(response => {
                this.cancellationPolicyText = response.data
                this.cancellationPolicyErrors = []
            }, error => {
                this.cancellationPolicyText = ''
                if (!error.response || !error.response.data) {
                    this.cancellationPolicyErrors = []
                }
                this.cancellationPolicyErrors = error.response.data.error_list
            }).finally(() => {
                this.text_loading = false
            })
        },
        reloadText(conditions_list) {
            let request = {
                cancellation_condition_list: JSON.stringify(conditions_list),
                foc_cancelation: this.cancellationPolicy.foc_cancelation,
                validate_conditions: 1
            }
            this.fetchPolicyText(request)
        },

    },
    computed: {
        saveDisabled() {
            return this.cancellationPolicy.label === null || this.cancellationPolicy.label === ''
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    created() {
        if (this.edit_id) {
            this.policyLoaded = false
            fetchCancellationPolicy(this.edit_id).then(response => {
                this.cancellationPolicy = response.data
                this.free_cancellation = response.data.foc_cancelation
                if (response.data.conditions && response.data.conditions.length) {

                    response.data.conditions.forEach(cancellation_condition => {
                        let conditionObject = {
                            id: cancellation_condition.id,
                            condition_type: cancellation_condition.condition_type.id,
                            day: cancellation_condition.day.id,
                            day_type: cancellation_condition.day_type.id,
                            charge: cancellation_condition.charge.id,
                            deleted: 0
                        }
                        this.cancellation_condition_list.push(conditionObject)
                    })
                }
            }).finally(() => this.policyLoaded = true)
        }
        getPropertyCancelationPolicyOptions(this.property_id).then(response => {
            this.selectCharge = response.data.charge
            this.selectConditionType = response.data.condition_type
            this.selectDay = response.data.day
            this.selectDayType = response.data.day_type
            this.condOptionsLoaded = true
        })
    }
}
</script>

<style scoped>

</style>
