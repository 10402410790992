<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="loading">
            <b-spinner variant="primary"></b-spinner>
        </div>
        <b-form v-else @submit.prevent="save">
            <b-row>
                <b-col>
                    <table class="table b-table">
                        <thead>
                        <th>{{ $t('PERIOD') }}</th>
                        <th>{{ $t('LENGTH_OF_STAY') }}</th>
                        <th>{{ $t('OCCUPANY_PERSONS') }}</th>
                        <th>{{ $t('LOCK_UNTIL') }}</th>
                        <th>{{ $t('USER') }}</th>
                        <th>{{ $t('CREATED_AT') }}</th>
                        <th></th>
                        </thead>
                        <tbody>
                        <tr :key="index" v-for="(lockObject, index) of lock_data">
                            <td>
                                <span v-if="lockObject.date_start">
                                     {{ lockObject.date_start|date }} - {{ lockObject.date_end|date }}
                                </span>
                            </td>
                            <td width="11%">
                                <template v-if="lockObject.los">
                                    {{ lockObject.los.min_stay }}

                                    <template v-if="!lockObject.los.max_stay">
                                        +
                                    </template>
                                    <template v-else>
                                        - {{ lockObject.los.max_stay }}
                                    </template>
                                </template>
                            </td>
                            <td width="11%">
                                <template v-if="lockObject.occupancy">
                                    {{ lockObject.occupancy.min_guests }} -
                                    {{ lockObject.occupancy.max_guests }}
                                </template>
                            </td>
                            <td>
                                <template v-if="lockObject.lock_until_date">
                                    {{ lockObject.lock_until_date | date }}
                                </template>
                            </td>
                            <td width="15%">
                                <template v-if="lockObject.hasOwnProperty('user_locked')">
                                    {{ lockObject.user_locked.first_name }} {{ lockObject.user_locked.last_name }}
                                </template>
                            </td>
                            <td width="20%">
                                <template v-if="lockObject.hasOwnProperty('created_at')">
                                    {{ lockObject.created_at | timestamp }}
                                </template>
                            </td>
                            <td width="4%">
                                <app-button @click="removeRow(lockObject, index)"
                                            :show_text="false"
                                            variant="link"
                                            button_type="delete"
                                            class="pull-right"
                                ></app-button>
                            </td>
                        </tr>

                        <tr>
                            <td width="25%">
                                <app-date-range-picker v-model="period"
                                                       :min-date="new Date()"
                                ></app-date-range-picker>
                            </td>
                            <td>
                                <app-select v-model="los"
                                            :options="losListOptions"
                                            :disabled="losListOptions.length === 1 && los.length === 1"
                                            mode="multiselect"
                                            text-field="name"
                                            value-field="id"
                                ></app-select>
                            </td>
                            <td>
                                <app-select v-model="occupancy"
                                            :options="occupancyListOptions"
                                            mode="multiselect"
                                            text-field="name"
                                            value-field="id"
                                ></app-select>
                            </td>
                            <td width="16%">
                                <app-date-picker v-model="until_date"
                                                 :min-date="new Date()"
                                ></app-date-picker>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <app-button
                                    :disabled="los.length === 0"
                                    class="pull-right"
                                    @click="addNewRow"
                                >{{ $t('ADD_ITEM') }}
                                </app-button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <app-button-submit :disabled="lock_list_save.length === 0"
                                       :loading="loader"
                    ></app-button-submit>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {getPriceLock, setPriceLock} from "@/services/pricing";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import AppButton from "@/components/app/AppButton/AppButton";

export default {
    name: "LosLockForm",
    props: {
        unit_id: {
            type: Number
        },
        losList: {
            type: Array,
            default() {
                return []
            }
        },
        occupancyList: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    mixins: [getErrorMessage],
    data() {
        return {
            loader: false,
            period: {
                start: null,
                end: null
            },
            los: [],
            occupancy: [],
            until_date: null,
            lock_data: [],
            lock_list_save: [],
            loading: false
        }
    },
    components: {AppButton, AppDateRangePicker, AppSelect, AppButtonSubmit, AppDatePicker},
    computed: {
        losListOptions() {
            return this.losList.length > 0 ? this.losList.map(el => {
                if (el.max_stay !== null) {
                    return {id: el.id, name: el.min_stay + '-' + el.max_stay}
                } else {
                    return {id: el.id, name: el.min_stay + '+'}
                }
            }) : []
        },
        occupancyListOptions() {
            return this.occupancyList.length > 0 ? this.occupancyList.map(el => {
                if (el.max_guests !== null) {
                    return {id: el.id, name: el.min_guests + '-' + el.max_guests}
                } else {
                    return {id: el.id, name: el.min_guests + '+'}
                }
            }) : []
        }
    },
    methods: {
        getLosById(los_id) {
            return this.losList.length > 0 ? this.losList.find(el => el.id === los_id) : null
        },
        getOccupancyById(los_id) {
            return this.occupancyList.length > 0 ? this.occupancyList.find(el => el.id === los_id) : null
        },
        removeRow(lockObject, index) {
            if (lockObject.hasOwnProperty('created_at')) {
                lockObject.set_lock = 0
                this.lock_list_save.push(lockObject)
            }
            let saveIndex = -1;
            if (lockObject.hasOwnProperty('los') && lockObject.los !== null && lockObject.hasOwnProperty('occupancy') && lockObject.occupancy !== null) {
                saveIndex = this.lock_list_save.findIndex(el => el.date_start === lockObject.date_start && el.date_end === lockObject.date_end && el.los.id === lockObject.los.id && lockObject.occupancy.id === el.occupancy.id && !el.hasOwnProperty('created_at'))
            }
            if (lockObject.los !== null && lockObject.occupancy === null) {
                saveIndex = this.lock_list_save.findIndex(el => el.date_start === lockObject.date_start && el.date_end === lockObject.date_end && el.los.id === lockObject.los.id && !el.hasOwnProperty('created_at'))
            }
            if (lockObject.los === null && lockObject.occupancy === null) {
                saveIndex = this.lock_list_save.findIndex(el => el.date_start === lockObject.date_start && el.date_end === lockObject.date_end && !el.hasOwnProperty('created_at'))
            }

            if (saveIndex > -1) {
                this.lock_list_save.splice(saveIndex, 1)
            }
            this.lock_data.splice(index, 1)
        },
        addNewRow() {
            this.los.forEach(losId => {
                const occupancy_list = this.occupancy.length > 0 ? this.occupancy : [null]
                occupancy_list.forEach(occupancyId => {
                    const object = {
                        date_start: this.period.start,
                        date_end: this.period.end,
                        lock_until_date: this.until_date,
                        los: losId !== null ? this.getLosById(losId) : null,
                        occupancy: occupancyId !== null ? this.getOccupancyById(occupancyId) : null,
                        set_lock: 1
                    }

                    this.lock_data.push(object)
                    this.lock_list_save.push(object)
                })
            })

            this.period.start = null
            this.period.end = null
            this.until_date = null
            this.los = []
            this.occupancy = []
        },
        save() {
            this.loader = true
            let request = {
                items: []
            }
            if (this.lock_list_save.length > 0) {
                for (const lock of this.lock_list_save) {
                    let reqObject = {
                        lock_period_start: lock.date_start,
                        lock_period_end: lock.date_end,
                        lock_until_date: lock.lock_until_date,
                        set_lock: lock.set_lock,
                    }

                    const los = lock.los !== null ? lock.los.id : this.losList[0].id
                    if (los !== null) {
                        reqObject = {...reqObject, ...{los}}
                    }

                    if (lock.occupancy !== null) {
                        reqObject = {...reqObject, ...{occupancy: lock.occupancy.id,}}
                    }
                    request.items.push(reqObject)
                }
            }
            setPriceLock(this.unit_id, request).then(() => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
                this.lock_list_save = []
                this.setLockData(this.unit_id, false)
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(() => {
                this.loader = false
            })
        },
        setLockData(unit_id, loader) {
            this.loading = loader
            getPriceLock(unit_id, {future_dates: true}).then(response => {
                this.lock_data = response.data.length > 0 ? response.data : []
            }).finally(() => {
                this.loading = false
            })
        }
    },
    watch: {
        unit_id: {
            handler(value) {
                this.setLockData(value, true)
            }, immediate: true
        },
        losList: {
            handler(list) {
                if (list.length === 1) {
                    this.los = list.map(el => {
                        return el.id
                    })
                }
            }, immediate: true
        }
    }
}
</script>

<style scoped>

</style>
