import http from '../http'
import {
    PAYMENT_GATEWAY_URL,
    PAYMENT_GATEWAY_LIST_URL,
    PAYMENT_GATEWAY_PCIBOOKING_GATEWAY_LIST_URL,
    PAYMENT_GATEWAY_SETUP_URL
} from "@/services/endpoints";

export function getPaymentGatewayList(params) {
    return http.request({
        url: PAYMENT_GATEWAY_LIST_URL,
        method: 'GET',
        params: params
    })
}

export function pciBookingGetPaymentGatewayList() {
    return http.request({
        url: PAYMENT_GATEWAY_PCIBOOKING_GATEWAY_LIST_URL,
        method: 'GET'
    })
}

export function fetchPaymentGatewaySetup(setup_id) {
    return http.request({
        url: PAYMENT_GATEWAY_SETUP_URL + '/' + setup_id,
        method: 'GET'
    })
}

export function activatePaymentGatewaySetup(data) {
    return http.request({
        url: PAYMENT_GATEWAY_SETUP_URL,
        method: 'POST',
        data: data
    })
}

export function updatePaymentGatewaySetup(setup_id, data) {
    return http.request({
        url: PAYMENT_GATEWAY_SETUP_URL + '/' + setup_id,
        method: 'PUT',
        data: data
    })
}

export function deactivatePaymentGatewaySetup(setup_id) {
    return http.request({
        url: PAYMENT_GATEWAY_SETUP_URL + '/' + setup_id,
        method: 'DELETE'
    })
}

export function authorizePaymentGatewayTransaction(data) {
    return http.request({
        url: PAYMENT_GATEWAY_URL + '/authorize',
        method: 'POST',
        data: data
    })
}

export function purchasePaymentGatewayTransaction(data) {
    return http.request({
        url: PAYMENT_GATEWAY_URL + '/purchase',
        method: 'POST',
        data: data
    })
}

export function capturePaymentGatewayTransaction(transaction_id, data) {
    return http.request({
        url: PAYMENT_GATEWAY_URL + '/' + transaction_id + '/capture',
        method: 'PUT',
        data: data
    })
}

export function refundPaymentGatewayTransaction(transaction_id, data) {
    return http.request({
        url: PAYMENT_GATEWAY_URL + '/' + transaction_id + '/refund',
        method: 'PUT',
        data: data
    })
}

export function voidPaymentGatewayTransaction(transaction_id, data) {
    return http.request({
        url: PAYMENT_GATEWAY_URL + '/' + transaction_id + '/void',
        method: 'PUT',
        data: data
    })
}
