<template>

    <b-button style="white-space: nowrap;" type="submit" variant="success" size="sm" :disabled="loading || disabled"><i
            :class="{'fa fa-spinner fa-spin fa-fw' : loading, 'fa fa-floppy-o fa-fw' : !loading}"></i>
        <slot>{{$t('SAVE')}}</slot>
    </b-button>
</template>

<script>
    export default {
        name: "AppSubmit",
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },

        }
    }
</script>

<style scoped>

</style>
