<template>
  <div>
    <label>{{restriction.label}}</label>
    <b-form-checkbox switch  @input="change" v-model="selected" :options="options" :value="1"
                     :unchecked-value="0"></b-form-checkbox>
  </div>
</template>

<script>
  export default {
    name: "restriction-sales-period-enabled",
    props: {
      value: {
      },
      restriction: {
        type: Object
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        selected: null,

        options: [
          {text: "Disabled", value: 0},
          {text: "Enabled", value: 1}
        ]
      }
    },
    methods: {
      getSelected() {
        if (this.value === null) {
            this.selected = 1
            return
        }
        this.selected = Number(this.value)

      },
      change(item) {
        this.$emit("input", item)
      }
    },
    watch:{
      value(){
        this.getSelected()
      }
    },
    created(){
      this.getSelected()
    }
  }
</script>

<style scoped>

</style>
