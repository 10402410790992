<template>
    <b-form @submit.prevent="save">
        <b-card v-for="(param, key) in schemaList" :key="key">
            <b-row slot="header">
                <b-col>
                    <span>{{ param.paramType.label | capitalize }}</span>
                </b-col>
            </b-row>
            <b-row v-for="(object, key) in Object.keys(param.objects)" :key="key">
                <b-col md="2">
                    <label> {{ object | capitalize }}</label>
                </b-col>
                <b-col :md="param.fieldType === 'app-select' ? 2 : 6">
                    <component :is="param.fieldType" v-bind="param"
                               v-model="formData[param.paramType.id][object].value">
                    </component>

                </b-col>
            </b-row>

            <template v-if="param.hasOwnProperty('units')">
                <hr>
                <template v-for="(unit, index) in param.units">
                    <b-row class="ml-2"> <!-- Keep ml class for unit indent -->
                        <b-col md="4">
                            <label>{{ unit.name }}</label>
                        </b-col>
                        <b-col md="2">
                            <component :is="param.fieldType" v-bind="param"
                                       v-model="formData[param.paramType.id].unit[index].value">
                            </component>
                        </b-col>
                    </b-row>
                    <hr>
                </template>
            </template>
        </b-card>

 <!--       <save-button :disabled="!saveButtonState"></save-button>-->
    </b-form>
</template>

<script>

    import SaveButton from '@/components/app/AppButton/AppButtonSubmit'
    import {updateSetupParameters} from "@/services/channel_manager";
    import cloneDeep from 'lodash/cloneDeep'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {notifySuccess} from "@/shared/plugins/toastr";

    const TEXT = 1
    const BOOL = 2

    export default {
        name: "SetupForm",
        components: {
            SaveButton, AppSelect
        },
        mixins: [getErrorMessage],
        props: {
            level: String,
            parameterList: Array,
            units: Array
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },
        data() {
            return {
                formData: {},
                initialFormData: {},
                schemaList: [],
                saveButtonState: true
            }
        },
        computed: {
            options() {
                return [
                    {name: this.$t('YES'), id: "1"},
                    {name: this.$t('NO'), id: "0"}
                ]
            }
        },
        watch: {
            parameterList: {
                handler() {
                    this.schemaList = []

                    this.parameterList.forEach(param => {
                        this.$set(this.formData, param.parameter_type.id, {})
                    })
                    this.parameterList.forEach(param => {
                        let schema = {}
                        schema.paramType = param.parameter_type
                        schema.fieldType = this.determineFieldType(param.value_type.id)

                        this.formData[param.parameter_type.id].parameter = param.parameter_type.id
                        this.$set(this.formData[param.parameter_type.id], this.level, {
                            id: this.getObjectIdPair(this.level),
                            value: param.values[0][this.level]
                        })
                        schema.objects = schema.objects || []
                        schema.objects[this.level] = this.getObjectIdPair(this.level)

                        if (param.value_type.id === BOOL) {
                            schema.options = this.options
                        }

                        if (param.values[0].hasOwnProperty('unit')) {
                            this.$set(this.formData[param.parameter_type.id], 'unit', param.values[0].unit.map(unit => {
                                return {
                                    ...unit
                                }
                            }))

                            let unitIdList = param.values[0].unit.map(unit => unit["id"])
                            schema.units = this.units.filter(unit => unitIdList.includes(unit.id))
                        }
                        this.schemaList.push(schema)
                    })
                    this.initialFormData = cloneDeep(this.formData)
                },
                immediate: true
            }
        },
        methods: {
            determineFieldType(value) {
                switch (value) {
                    case TEXT:
                        return 'b-form-textarea'
                    case BOOL:
                        return 'app-select'
                    default:
                        return 'b-input'
                }
            },
            getObjectIdPair(object) {
                switch (object) {
                    case 'company':
                        return this.$store.getters['user/getCompany']
                    case 'property':
                        return this.$route.params.id
                }
            },
            save() {
                // if (!isEqual(this.initialFormData, this.formData)) {
                this.saveButtonState = false
                let formData = []
                Object.keys(this.formData).forEach(key => {
                    formData.push(this.formData[key])
                })
                updateSetupParameters({
                    distribution: this.$route.params.distribution_id,
                    data: formData
                }).then(() => {
                    this.initialFormData = cloneDeep(this.formData)
                    notifySuccess()
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.saveButtonState = true
                })
                // } else {
                //     toast({
                //         'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                //         'message': this.$t('NO_CHANGE'),
                //         'type': 'error',
                //         'timeout': 2000
                //     })
                // }
            }
        }
    }
</script>

<style scoped>

</style>
