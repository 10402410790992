<template>
    <div style="height: 100%" v-if="user">
        <iframe width="100%" height="700px" :src="iframeUrl" frameborder="0">

        </iframe>
    </div>
</template>

<script>
    import {RATE_MANAGER_IFRAME} from '@/shared/constants.js'

    export default {
        name: "Index",
        data() {
            return {
                RATE_MANAGER_IFRAME
            }
        },
        computed: {
            user() {
                return this.$store.getters['user/getUser']
            },
            iframeUrl() {
                return this.RATE_MANAGER_IFRAME + this.user.id
            }
        }
    }
</script>

<style scoped>

</style>
