<template>
    <b-form @submit.prevent="search" @reset.prevent="reset" class="filter" autocomplete="off">
        <b-row>
            <b-col md="2" class="mb-3">
                <label>{{$t('STATUS')}}</label>
                <app-select mode="select"
                            :options="invoiceStatusList"
                            text-field="label"
                            v-model="filter.status">
                </app-select>
            </b-col>
            <b-col md="3">
                <app-button-submit class="filter-submit-wrapper" :inline="true" :loading="loading" variant="primary" button_type="search">
                    {{$t("SEARCH") }}
                </app-button-submit>
                <app-button-reset :disabled="loading" :inline="true"  class="pl-2 ml-2"></app-button-reset>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {pickBy, cloneDeep} from "lodash";
    import {EventBus} from "@/shared/EventBus";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";

    export default {
        name: "PaymentOrderFilter",
        components: {
            AppButtonReset,
            AppSelect,
            AppButtonSubmit
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            invoiceStatusList: {
                type: Array,
                default() {
                    return []
                }
            }
        },
        data() {
            return {
                filter: {
                    page: 1,
                    per_page: 10,
                    company: null,
                    status: null
                },
                initialFilter: {}
            }
        },
        methods: {
            search() {
                this.$emit('search', pickBy(this.filter, item => item !== null))
            },
            reset() {
                this.filter = cloneDeep(this.initialFilter)
                this.search()
            }
        },
        created() {
            this.filter.company = this.$store.getters[`user/getCompany`];
            this.initialFilter = cloneDeep(this.filter)
            this.search()
        },
        mounted() {
            EventBus.$on('reloadPaymentOrderTable', () => {
                this.search()
            })
        }
    }
</script>

<style scoped>

</style>
