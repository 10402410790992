<template>
    <layout-v1 :company="company">
        <template v-slot:pageTop>

        </template>
        <template v-slot:body1>
            <b-row>
                <b-col v-if="checkPermission(C_DASHBOARD_OPEN_INVOICE_V)">
                    <dashboard-open-invoice :company="company.id"></dashboard-open-invoice>
                </b-col>
            </b-row>
        </template>
        <template v-slot:body2>
            <b-row>
                <b-col v-if="checkPermission(C_DASHBOARD_CHECK_INVOICE_V)">
                    <dashboard-invoice-check :company="company.id"></dashboard-invoice-check>
                </b-col>
            </b-row>
        </template>
        <template v-slot:body3>
            <b-row>
                <b-col v-if="checkPermission(C_DASHBOARD_RESERVATION_NO_INVOICE_V)">
                    <dashboard-reservations-no-invoice :company="company.id"></dashboard-reservations-no-invoice>
                </b-col>
            </b-row>
            <b-row>
                <b-col v-if="checkPermission(C_DASHBOARD_CANCELED_RESERVATION_INVOICE_V)">
                    <dashboard-canceled-reservation :company="company.id"></dashboard-canceled-reservation>
                </b-col>
            </b-row>
        </template>
        <template v-slot:pageBottom>

        </template>
    </layout-v1>
</template>

<script>
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {
        C_DASHBOARD_OPEN_INVOICE_V,
        C_DASHBOARD_CHECK_INVOICE_V,
        C_DASHBOARD_RESERVATION_NO_INVOICE_V,
        C_DASHBOARD_CANCELED_RESERVATION_INVOICE_V
    } from "@/shared/component_permission";
    import LayoutV1 from "@/components/dashboard/layout/LayoutV1";
    import DashboardInvoiceCheck from "@/components/dashboard/shared/DashboardInvoiceCheck";
    import DashboardOpenInvoice from "@/components/dashboard/shared/DashboardOpenInvoice";
    import DashboardReservationsNoInvoice from "@/components/dashboard/shared/DashboardReservationsNoInvoice";
    import DashboardCanceledReservation from "@/components/dashboard/shared/DashboardCanceledReservation";

    export default {
        name: "DashboardLayoutVer1",
        components: {
            DashboardCanceledReservation,
            DashboardOpenInvoice,
            DashboardInvoiceCheck, LayoutV1,
            DashboardReservationsNoInvoice
        },
        mixins: [AccessControlComponent],
        props: {
            company: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                C_DASHBOARD_OPEN_INVOICE_V,
                C_DASHBOARD_CHECK_INVOICE_V,
                C_DASHBOARD_RESERVATION_NO_INVOICE_V,
                C_DASHBOARD_CANCELED_RESERVATION_INVOICE_V
            }
        }
    }
</script>

<style scoped>

</style>
