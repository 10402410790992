<template>
    <b-card-group columns class="column-4">
        <b-card :key="amenityIndex" v-for="(amenity, amenityIndex) in amenityList">
            <b-list-group class="repeater-2">
                <b-list-group-item class="list-group-item-title font-weight-bold">
                    <h5>{{amenity.label}}</h5>
                    <b-badge variant="primary" pill class="ml-1">{{amenity.amount}}</b-badge>
                    <edit-button :access_control_context="{function:c_permission_e,key:AC_UNIT_AMENITY}"
                                 @AccessControlEvent="addToAccessControlCounter()" :c_permission="c_permission_e" :show_text="false" variant="link"
                                 button_type="edit"
                                 class="pull-right amenity-edit action_button"
                                 @click="editAmenity(amenity.id)"></edit-button>
                </b-list-group-item>
                <b-list-group-item variant="primary">{{$t('INVENTORY')}}</b-list-group-item>
                <template v-if="amenity.inventory.length">

                    <b-list-group-item :key="inventoryIndex"
                                       v-for="(inventoryItem, inventoryIndex) in amenity.inventory">
                        <span>{{inventoryItem.label}}</span>
                        <b-badge class="pull-right" variant="primary" pill>{{inventoryItem.amount}}</b-badge>
                    </b-list-group-item>
                </template>
            </b-list-group>
        </b-card>
    </b-card-group>
</template>

<script>
    import EditButton from '@/components/app/AppButton/AppButton'

    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {toast} from '@/shared/plugins/toastr'
    import {C_UNIT_AMENITY_E} from "@/shared/component_permission";
    import {AC_UNIT_AMENITY} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

    export default {
        name: "UnitAmenityList",
        components: {
            EditButton
        },
        mixins: [getErrorMessage,AccessControlComponent],
        data() {
            return {
                c_permission_e: C_UNIT_AMENITY_E,
                AC_UNIT_AMENITY
            }
        },
        computed: {
            amenityList() {
                return this.$store.getters['amenity/getUnitAmenity']
            },
        },
        methods: {
            editAmenity(id) {
                this.$emit('editRoom', id)
            }
        },
        created(){
            this.access_control_components = this.amenityList.length
        }
    }
</script>

<style scoped>

</style>
