<template>
    <div>
        <b-form @submit.prevent="saveDescriptions" v-if="list.length && hasPermissionView">
            <b-tabs content-class="tab_content" vertical pills>
                <b-tab :key="index" v-for="(description, index)  in list">
                    <template slot="title">
                        {{description.label}}
                    </template>
                    <b-row>
                        <b-col v-if="description.name === GETTING_THERE" cols="12" class="mb-1">
                            <app-button button-class="placeholder" variant="primary" :key="placeholderIndex"
                                        @click="setPlaceholder(placeholder.value, index)"
                                        v-for="(placeholder, placeholderIndex) in checkinInfoPlaceholderList">
                                {{ placeholder.label }}
                            </app-button>
                        </b-col>
                        <b-col cols="12">
                            <description-text-area :description="description"
                                                   :disabled="!checkPermission(permissions[description.name])"
                                                   :id="description.name"
                                                   :name="description.name"
                                                   @update="updateValue(index, $event)"
                                                   v-if="description.name !== GETTING_THERE">
                            </description-text-area>
                            <quill-editor
                                id="confEmailBody"
                                v-model="description.value"
                                ref="myQuillEditor"
                                @focus="subjectFocusLocation = null"
                                v-else>
                            </quill-editor>
                        </b-col>

                        <b-col cols="12" class="mt-4" v-if="description.name === GETTING_THERE">
                            <gallery
                                :object-id="Number($route.params.unit_id)"
                                :object-name="gettingThereObjectName"
                                :document-type-id="TYPE_UNIT_CONFIRMATION_EMAIL"
                                document-type=".png,.jpg,.jpeg"></gallery>
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
            <b-row>
                <b-col class="mt-4" v-if="checkPermission(c_permission_e)">
                    <unit-description-submit :loading="saving">
                    </unit-description-submit>
                </b-col>
            </b-row>
        </b-form>

    </div>

</template>

<script>
    import UnitDescriptionSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {C_UNIT_DESCRIPTION_E, C_UNIT_DECRIPTION_V} from "@/shared/component_permission";
    import {getUnitDescriptionList, setUnitDescriptions} from "@/services/unit";
    import {
        GETTING_THERE,
        TYPE_UNIT_CONFIRMATION_EMAIL,
        UNIT_DESCRIPTION,
        SPACE_DESCRIPTION,
        GUEST_ACCESS_AND_INVENTORY,
        HOUSE_RULES,
        GUEST_INTERACTION,
        POOL,
        NOTES, DIRECT_BOOKER_COMPANY,
    } from '@/shared/constants'
    import {
        C_UNIT_DESCRIPTION_NOTES_E,
        C_UNIT_DESCRIPTION_CONFIRMATION_EMAIL_BODY_E,
        C_UNIT_DESCRIPTION_POOL_E,
        C_UNIT_DESCRIPTION_GUEST_INTERACTION_E,
        C_UNIT_DESCRIPTION_HOUSE_RULES_E,
        C_UNIT_DESCRIPTION_GUEST_ACCESS_INVENTORY_E,
        C_UNIT_DESCRIPTION_SPACE_E,
        C_UNIT_DESCRIPTION_UNIT_SUMMARY_E
    } from '@/shared/component_permission'
    import FormHeader from "@/components/app/form/FormHeader";
    import Gallery from "@/components/app/gallery/Gallery";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {GET_UNIT_ITEM} from "@/store/store_types";
    import {quillEditor} from "vue-quill-editor";

    import "quill/dist/quill.core.css";
    import "quill/dist/quill.snow.css";
    import "quill/dist/quill.bubble.css";
    import DescriptionTextArea from "@/components/description/DescriptionTextArea";

    const permissions = {
        [GETTING_THERE]: C_UNIT_DESCRIPTION_CONFIRMATION_EMAIL_BODY_E,
        [UNIT_DESCRIPTION]: C_UNIT_DESCRIPTION_UNIT_SUMMARY_E,
        [SPACE_DESCRIPTION]: C_UNIT_DESCRIPTION_SPACE_E,
        [GUEST_ACCESS_AND_INVENTORY]: C_UNIT_DESCRIPTION_GUEST_ACCESS_INVENTORY_E,
        [HOUSE_RULES]: C_UNIT_DESCRIPTION_HOUSE_RULES_E,
        [GUEST_INTERACTION]: C_UNIT_DESCRIPTION_GUEST_INTERACTION_E,
        [POOL]: C_UNIT_DESCRIPTION_POOL_E,
        [NOTES]: C_UNIT_DESCRIPTION_NOTES_E,
    }

    export default {
        name: "Index",
        components: {
            DescriptionTextArea,
            AppButton,
            FormHeader,
            UnitDescriptionSubmit,
            Gallery,
            quillEditor
        },
        computed: {
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](C_UNIT_DECRIPTION_V)
            },
            basicInfo() {
                return this.$store.getters[GET_UNIT_ITEM]('basic')
            },
            gettingThereObjectName(){
                return this.$t("UNIT_GETTING_THERE_GALLERY_OBJECT_NAME", {value: this.basicInfo.name})
            },
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            checkinInfoPlaceholderList() {
                const placeholderList = this.checkinInfoPlaceholders
                if (Number(this.company.id) === DIRECT_BOOKER_COMPANY) {
                    placeholderList.push({label: this.$t('BACKOFFICE_SET_ARRIVAL_TIME_LINK'), value: '{BACKOFFICE_SET_ARRIVAL_TIME_LINK}'})
                    placeholderList.push({label: this.$t('BACKOFFICE_BOOK_TRANSFER_LINK'), value: '{BACKOFFICE_BOOK_TRANSFER_LINK}'})
                }

                return placeholderList
            }
        },
        mixins: [getErrorMessage],
        data() {
            return {
                list: [],
                c_permission_e: C_UNIT_DESCRIPTION_E,
                GETTING_THERE,
                TYPE_UNIT_CONFIRMATION_EMAIL,
                checkinInfoPlaceholders: [
                    {label: this.$t('UNIT_ADDRESS'), value: '{ADDRESS}'},
                    /*{label: this.$t('ADDRESS'), value: '{CHECK_IN_ADDRESS}'},
                    {label: this.$t('STREET_NUMBER'), value: '{CHECK_IN_STREET_NUMBER}'},*/
                    {label: this.$t('LATITUDE'), value: '{CHECK_IN_LATITUDE}'},
                    {label: this.$t('LONGITUDE'), value: '{CHECK_IN_LONGITUDE}'},
                    {label: this.$t('KEY_PICKUP_ADDRESS'), value: '{KEY_PICKUP_ADDR}'},
                    /*{label: this.$t('KEY_PICKUP_ADDRESS'), value: '{KEY_PICKUP_ADDRESS}'},
                    {label: this.$t('KEY_PICKUP_STREET_NUMBER'), value: '{KEY_PICKUP_STREET_NUMBER}'},*/
                    {label: this.$t('KEY_PICKUP_LATITUDE'), value: '{KEY_PICKUP_LATITUDE}'},
                    {label: this.$t('KEY_PICKUP_LONGITUDE'), value: '{KEY_PICKUP_LONGITUDE}'},
                    {label: this.$t('CHECKIN_FROM'), value: '{CHECKIN_FROM}'},
                    {label: this.$t('CHECKIN_TO'), value: '{CHECKIN_TO}'},
                    {label: this.$t('CHECKOUT_TO'), value: '{CHECKOUT_TO}'},
                ],
                placeholderPosition: null,
                permissions,
                saving: false,
            }
        },
        methods: {
            setPlaceholder(placeholder, index) {
                if(this.list[index].name === GETTING_THERE){
                    let quill = this.$refs.myQuillEditor[0].quill;
                    let selection = quill.getSelection(true);
                    quill.insertText(selection.index, placeholder);
                }
            },
            getLength(text) {
                return [...text].length
            },
            formatString(text) {
                return text !== null ? text.replace(/(\r\n|\n|\r)/gm, "\n") : ''
            },
            saveDescriptions() {
                this.saving = true
                let request = {}
                this.list.map((item) => {
                    request[item.name] = item.value
                    /*let translations = item.hasOwnProperty('translations') && item.translations !== null ? item.translations : null
                    if (translations !== null) {
                        let descriptionName = item.name === UNIT_DESCRIPTION ? 'summary' : (item.name === SPACE_DESCRIPTION ? 'space' : item.name)
                        let requestFieldName = 'field_' + descriptionName + '_translations'
                        request[requestFieldName] = {}
                        for (const [lang, value] of Object.entries(translations)) {
                            request[requestFieldName][lang] = value
                        }
                    }*/
                })

                setUnitDescriptions(this.$route.params.unit_id, request).then(response => {
                    this.prepareList(response.data)
                    notifySuccess()
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.saving = false
                })
            },
            updateValue(index, value) {
                this.$set(this.list, index, value)
            },
            prepareList(list) {
                this.list = []
                list.forEach((item) => {
                    let description = {
                        label: item.label,
                        name: item.name,
                        value: this.formatString(item.value),
                        lang_id: item.lang_id,
                        translations: item.translations,
                    }
                    this.list.push(description)
                })
                // return list.map(item => {
                //     return {
                //         label: item.label,
                //         name: item.name,
                //         value: this.formatString(item.value),
                //         lang_id: item.lang_id,
                //         translations: item.translations,
                //     }
                // })
            },
        },

        watch: {
            '$route.params.unit_id': {
                handler(value) {
                    getUnitDescriptionList(value).then(response => {
                            this.prepareList(response.data)
                        }, () => {
                            this.list = []
                        })
                },
                immediate: true
            }
        }
    }
</script>
<style scoped>

</style>
