<template>
    <div>
        <div v-if="hasPermissionView && fetchData">
            <div v-if="losListId.length > 0 && occupancyListId.length > 0 && periodList.length > 0">
                <b-row style="min-height: 2.5rem">
                    <b-col>
                        <div class="pull-right">
                            <app-button
                                button_type="new"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{function:C_UNIT_CALENDAR_PERIODS_APPLY,key:AC_BULK_CALENDAR}"
                                @click="applyPeriodsForm=true">
                                {{ $t('APPLY_PERIOD') }}
                            </app-button>
                            <app-access-control v-if="tab < 2" class="ml-3" tag="span"
                                                @AccessControlEvent="addToAccessControlCounter()"
                                                :access_control_context="{function:C_UNIT_CALENDAR_SAVE,key:AC_BULK_CALENDAR}">
                                <template v-slot:default="props">
                                    <b-tooltip v-if="props.messages.length"
                                               triggers="hover"
                                               :title="props.messages[0].message"
                                               target="tooltip-target-2">
                                    </b-tooltip>
                                    <app-button id="tooltip-target-2"
                                                :disabled="props.disabled"
                                                :loading="loadingSave"
                                                @click="saveAside = true">
                                        {{ $t('SAVE') }}
                                    </app-button>
                                </template>
                            </app-access-control>
                            <app-button
                                class="ml-3"
                                :access_control_context="{function: C_UNIT_CALENDAR_COPY_ACQUISITION, key: AC_BULK_CALENDAR}"
                                @AccessControlEvent="addToAccessControlCounter()"
                                @click="copyInStatusAcquisitionPanelState = true">
                                {{ $t("COPY_RATES_RESTRICTIONS") }}
                            </app-button>
                            <app-button
                                class="ml-3"
                                :access_control_context="{function: C_UNIT_CALENDAR_COPY_UPDATE, key: AC_BULK_CALENDAR}"
                                @AccessControlEvent="addToAccessControlCounter()"
                                @click="copyInStatusUpdatePanelState = true">
                                {{ $t("COPY_RATES_RESTRICTIONS") }}
                            </app-button>
                        </div>
                    </b-col>
                </b-row>

                <b-tabs v-if="hasPermissionView && fetchData"
                        nav-class="tab_navigation-horizontal tab-nav tab-bulk-calendar"
                        :class="fetchData ? 'mt-3' :'mt-5'"
                        @input="(selectedTab) => { this.tab = selectedTab }"
                        content-class="tab_content-horizontal"
                        pills justified>
                    <b-tab :title="$t('PRICES')" :key="0">
                        <b-row class="mb-4">
                            <b-col>
                                <app-button class="mr-3" @AccessControlEvent="addToAccessControlCounter()"
                                            :access_control_context="{function:C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_MAKE,key:AC_BULK_CALENDAR}"
                                            variant="primary"
                                            @click="getResetSuggestions">
                                    {{ $t('SUGGEST_PRICES') }}
                                </app-button>
                                <app-access-control class="mr-3" tag="span"
                                                    @AccessControlEvent="addToAccessControlCounter()"
                                                    :access_control_context="{function:C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_ACQUISITION,key:AC_BULK_CALENDAR}">
                                    <app-button id="popover_get_suggestion"
                                                variant="primary"
                                                @click="rmPopoverShow= !rmPopoverShow">
                                        {{ $t('SUGGEST_PRICES') }}
                                    </app-button>
                                    <b-popover md="12" xl="12" :show.sync="rmPopoverShow"
                                               target="popover_get_suggestion"
                                               placement="bottom"
                                               triggers="focus">
                                        <rm-sugg-price-acquisition-form :show="rmPopoverShow"
                                                                        @loadSuggestion="updateLoader"
                                                                        class="popover-rm-acquisition popover"
                                        ></rm-sugg-price-acquisition-form>
                                    </b-popover>
                                </app-access-control>
                                <app-button class="mr-3" variant="primary"
                                            v-if="checkPermission(C_UNIT_CALENDAR_PRICE_IMPORT_EXPORT)"
                                            @click="openImportExportSidebar=true">
                                    {{ $t('PRICE_LIST') }}
                                </app-button>
                                <app-access-control class="mr-3" tag="span"
                                                    @AccessControlEvent="addToAccessControlCounter()"
                                                    :access_control_context="{function:C_UNIT_CALENDAR_SAVE,key:AC_BULK_CALENDAR}">
                                    <template v-slot:default="props">
                                        <b-tooltip v-if="props.messages.length"
                                                   triggers="hover"
                                                   :title="props.messages[0].message"
                                                   target="tooltip-target-1">
                                        </b-tooltip>
                                        <app-button id="tooltip-target-1"
                                                    :disabled="props.disabled"
                                                    :loading="loadingSaveAdjusted"
                                                    @click="saveAdjustedAside = true">
                                            {{ $t('APPLY_INCREASE_DECREASE') }}
                                        </app-button>
                                    </template>
                                </app-access-control>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="4" class="pr-1 pb-0" v-for="(periodId, periodIndex) in periodList"
                                   :key="periodIndex">
                                <unit-bulk-calendar-price-panel
                                    @AccessControlEvent="addToAccessControlCounter()"
                                    v-if="fetchData"
                                    @openSideBar="rangesForm=true"
                                    @openDailyAdjustment="openDailyAdjustment"
                                    v-cloak
                                    :keyIndex="periodIndex"
                                    :form="form"
                                    :periodId="periodId"
                                    :currency="currency"
                                    :occupancyList="occupancyList"
                                    :losList="losList">
                                </unit-bulk-calendar-price-panel>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="$t('RESTRICTIONS')" :key="1" v-if="checkPermission(C_UNIT_CALENDAR_RESTRICTIONS_E)">
                        <b-row>
                            <b-col class="mb-4" lg="3" md="3">
                                <app-select v-model="selected_rate_plan_id" :options="systemRatePlanList"></app-select>
                            </b-col>
                            <app-button
                                v-if="selectedRatePlan && selectedRatePlan.hasOwnProperty('rate_plan_category') && selectedRatePlan.rate_plan_category === SYSTEM_NON_REFUNDABLE_RATE"
                                @click="applyFromStandard" class="mr-3">{{ $t('APPLY_FROM_STANDARD') }}
                            </app-button>
                            <restriction-apply-to-all-dropdown :selected_rate_plan="selectedRatePlan"
                                                               :period_list="periodList"
                                                               :restrictions="restrictions"
                            ></restriction-apply-to-all-dropdown>
                        </b-row>
                        <b-row>
                            <b-col lg="4" class="pr-1 pb-0" :key="periodId"
                                   v-for="(periodId, periodIndex) in periodList">
                                <unit-bulk-calendar-restriction-panel
                                    @AccessControlEvent="addToAccessControlCounter()"
                                    v-if="fetchData"
                                    @openSideBar="rangesForm=true"
                                    :keyIndex="periodIndex" v-cloak
                                    :periodId="periodId"
                                    :selectedRatePlan="selectedRatePlan"
                                    :restrictions="restrictions"
                                    :rate-plan-list="systemRatePlanList"
                                ></unit-bulk-calendar-restriction-panel>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </div>

            <app-no-data v-else :heading="$t('MODULE_NOT_ACTIVE')" :showTip="true" :tip="$t('MODULE_NEED_PERIODS')">
                <app-button button_type="new"
                            v-if="checkPermission(C_UNIT_CALENDAR_LOS_V) && (occupancyListId.length === 0 || losListId.length === 0)"
                            @click="rangesForm=true">{{ $t('NEW_RANGE') }}
                </app-button>

                <app-access-control class="mr-3" tag="span" @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function:C_UNIT_CALENDAR_PERIODS_APPLY,key:AC_BULK_CALENDAR}">
                    <template v-slot:default="props">
                        <b-tooltip v-if="props.messages.length"
                                   triggers="hover"
                                   :title="props.messages[0].message" target="tooltip-target-apply-1">
                        </b-tooltip>
                        <app-button button_type="new"
                                    :disabled="props.disabled"
                                    id="tooltip-target-apply-1"
                                    v-if="checkPermission(C_UNIT_CALENDAR_PERIODS_V) && periodList.length === 0"
                                    @click="periodsForm=true">{{ $t('NEW_PERIOD') }}
                        </app-button>
                    </template>
                </app-access-control>

                <app-access-control tag="span" @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function:C_UNIT_CALENDAR_PERIODS_APPLY,key:AC_BULK_CALENDAR}">
                    <template v-slot:default="props">
                        <b-tooltip v-if="props.messages.length"
                                   triggers="hover"
                                   :title="props.messages[0].message" target="tooltip-target-apply-2">
                        </b-tooltip>
                        <app-button button_type="new"
                                    :disabled="props.disabled"
                                    id="tooltip-target-apply-2"
                                    v-if="periodList.length === 0"
                                    @click="applyPeriodsForm=true" class="mr-3">{{ $t('APPLY_PERIOD') }}
                        </app-button>
                    </template>
                </app-access-control>
            </app-no-data>

            <app-aside :widths="['col-lg-4', 'col-sm-12']" v-model="applyPeriodsForm">
                <template slot="header">
                    {{ $t('APPLY_PERIOD') }}
                </template>
                <suggestion-list @closeAside="applyPeriodsForm=false"
                                 :unit_id="Number($route.params.unit_id)"
                ></suggestion-list>
            </app-aside>
            <app-aside :widths="['col-lg-4', 'col-sm-12']" v-model="periodsForm">
                <template slot="header">
                    {{ $t('PERIODS') }}
                </template>
                <period-list v-if="checkPermission(C_UNIT_CALENDAR_PERIODS_V)"></period-list>
            </app-aside>
            <app-aside v-model="rangesForm">
                <template slot="header"><app-object-header :name="$t('EDIT_RANGES')"></app-object-header></template>
                <b-tabs nav-class="tab_navigation-horizontal" content-class="tab_content-horizontal" pills
                        v-model="showRangesSetupTab" justified>
                    <b-tab v-if="checkPermission(C_UNIT_CALENDAR_PERIODS_V)" :title="$t('PERIODS')">
                        <period-list></period-list>
                    </b-tab>
                    <b-tab v-if="checkPermission(C_UNIT_CALENDAR_LOS_V)" :title="$t('LENGTH_OF_STAY')">
                        <unit-los-list @update="setData(Number($route.params.unit_id))"
                                       :los-list="losList"
                                       :unit="unit"
                        ></unit-los-list>
                    </b-tab>
                    <b-tab v-if="checkPermission(C_UNIT_CALENDAR_OCCUPANCY_V)" :title="$t('OCCUPANY_PERSONS')">
                        <unit-occupancy-list @update="setData(Number($route.params.unit_id))"
                                             :occupancy-list="occupancyList"
                                             :unit="this.unit"
                        ></unit-occupancy-list>
                    </b-tab>
                </b-tabs>
            </app-aside>

            <app-aside :widths="['col-lg-4', 'col-sm-12', '40%']" v-model="saveAside">
                <template slot="header">{{ $t('ADDITIONAL_SAVE_OPTIONS') }}</template>
                <bulk-calendar-save-form @openConfirmationSave="confirmationSave"
                                         :restrictions="restrictions"
                ></bulk-calendar-save-form>
            </app-aside>

            <app-aside :widths="['col-lg-4', 'col-sm-12', '40%']" v-model="saveAdjustedAside">
                <template slot="header">{{ $t('APPLY_INCREASE_DECREASE') }}</template>
                <bulk-calendar-save-adjusted-form @openConfirmationSaveAdjusted="confirmationSaveAdjusted"
                ></bulk-calendar-save-adjusted-form>
            </app-aside>

            <app-aside v-if="periodList.length > 0" :headerClasses="['mb-0']"
                       :widths="losList.length > 1 && occupancyList.length > 1 ?['col-lg-6', 'col-sm-12']:['col-lg-5', 'col-sm-12', '40%']"
                       v-model="dailyAdjustmentAside.open">
                <template slot="header" v-if="dailyAdjustmentAside.open">
                    <div class="property-wrapper mb-0 daily-adjustment-no-border">
                        <div class="card-header">
                            <div class="header-caption">{{ $t('DAY_OF_WEEK_ADJUSTMENT') }}</div>
                            <h2 class="property-wrapper-padding mb-2">
                                {{ getPeriodStart(dailyAdjustmentAside.period_id) }} -
                                {{ getPeriodEnd(dailyAdjustmentAside.period_id) }}
                            </h2>
                        </div>
                    </div>
                </template>
                <daily-adjustment-form :property_id="property.id" :unit_id="unit.id" :los-list="losList"
                                       :occupancy-list="occupancyList"
                                       :period="getPeriodById(dailyAdjustmentAside.period_id)"
                ></daily-adjustment-form>
            </app-aside>

            <app-confirmation-dialog :show="showSaveConfirm"
                                     :loading="loadingSave"
                                     :title="$t('SAVE_CONFIRMATION')"
                                     @confirm="savePrice"
                                     @cancel="showSaveConfirm = false">
                {{ $t('BULK_CALENDAR_SAVE_MESSAGE') }}
            </app-confirmation-dialog>
            <app-confirmation-dialog :show="showSaveAdjustedConfirm"
                                     :loading="loadingSaveAdjusted"
                                     :title="$t('SAVE_CONFIRMATION')"
                                     @confirm="saveAdjustedPrice"
                                     @cancel="showSaveAdjustedConfirm = false">
                {{ $t('BULK_CALENDAR_SAVE_MESSAGE') }}
            </app-confirmation-dialog>

            <app-aside :widths="['col-lg-4', 'col-sm-12', '40%']" v-model="openImportExportSidebar">
                <template slot="header">
                    <app-object-header :name="$t('PRICE_LIST')">
                    </app-object-header>
                </template>
                <import-export-prices-form :unit="unit"
                                           @input="openImportExportSidebar = $event"
                                           @pricesImported="pricesImported"
                                           :property="property"
                ></import-export-prices-form>
            </app-aside>

            <app-aside :widths="['col-lg-4', 'col-sm-12', '40%']"
                       v-model="copyInStatusAcquisitionPanelState">
                <template slot="header">
                    <app-object-header :name="$t('COPY_RATES_RESTRICTIONS')">
                    </app-object-header>
                </template>
                <bulk-calendar-copy-form
                    status="acquisition"
                    :unit-id="unit.id"
                    :property-id="property.id"
                    @saved="copyInStatusAcquisitionPanelState = false">
                </bulk-calendar-copy-form>
            </app-aside>

            <app-aside :widths="['col-lg-4', 'col-sm-12', '40%']"
                       v-model="copyInStatusUpdatePanelState">
                <template slot="header">
                    <app-object-header :name="$t('COPY_RATES_RESTRICTIONS')">
                    </app-object-header>
                </template>
                <bulk-calendar-copy-form
                    status="update"
                    :unit-id="unit.id"
                    :property-id="property.id"
                    @saved="copyInStatusUpdatePanelState = false">
                </bulk-calendar-copy-form>
            </app-aside>

        </div>
        <loading :show="showLoading" :overlay="overlay"></loading>
    </div>
</template>

<script>
import UnitBulkCalendarPricePanel from '@/components/unit/bulk_calendar/pricing/UnitBulkCalendarPricePanel'
import UnitBulkCalendarRestrictionPanel from "@/components/unit/bulk_calendar/restriction/UnitBulkCalendarRestrictionPanel"
import PeriodList from '@/components/unit/bulk_calendar/period/PeriodList'
import SubmitButton from '@/components/app/AppButton/AppButtonSubmit'
import AppAside from '@/components/app/form/AppAside'
import SuggestionList from '@/components/unit/bulk_calendar/period/PeriodSuggestionList'
import {toast} from "@/shared/plugins/toastr";
import AppButton from '@/components/app/AppButton/AppButton'
import RmSuggPriceAcquisitionForm from '@/components/unit/bulk_calendar/rate_manager/RmSuggPriceAcquisitionForm'
import {
    C_UNIT_CALENDAR_V,
    C_UNIT_CALENDAR_SAVE,
    C_UNIT_CALENDAR_RESTRICTIONS_E,
    C_UNIT_CALENDAR_PRICE_IMPORT,
    C_UNIT_CALENDAR_PRICE_IMPORT_EXPORT,
    C_UNIT_CALENDAR_COPY_ACQUISITION,
    C_UNIT_CALENDAR_COPY_UPDATE,
} from '@/shared/component_permission'
import {SYSTEM_STANDARD_RATE, SYSTEM_NON_REFUNDABLE_RATE} from "@/shared/constants";
import {getRestrictionList} from "@/services/pricing/restriction";
import RestrictionApplyToAllDropdown
    from "@/components/unit/bulk_calendar/restriction/RestrictionApplyToAllDropdown"
import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import moment from 'moment'
import {getRmResetSuggestions, setBulkCalendarBatchRateAdjust} from '@/services/pricing/index'
import loading from 'vue-full-loading'
import {STATUS_PROPERTY_IN_ACQUISITION, STATUS_PROPERTY_UPDATE, MIN_STAY} from '@/shared/constants'
import {
    C_UNIT_CALENDAR_PERIODS_V,
    C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_V,
    C_UNIT_CALENDAR_LOS_V,
    C_UNIT_CALENDAR_OCCUPANCY_V,
    C_UNIT_CALENDAR_PERIODS_E,
    C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_MAKE,
    C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_ACQUISITION,
    C_UNIT_CALENDAR_PERIODS_APPLY,
    C_UNIT_REVENUE_MANAGER_MIN_VALUES_V,
    C_UNIT_REVENUE_MANAGER_ACTION_V
} from "@/shared/component_permission";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppNoData from "@/components/app/AppNoData";
import UnitLosList from "@/components/unit/calendar/forms/los/UnitLosList";
import UnitOccupancyList from "@/components/unit/calendar/forms/occupancy/UnitOccupancyList";
import {EventBus, GE_LOADER_SHOW, GE_LOADER_HIDE} from "@/shared/EventBus";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_BULK_CALENDAR} from "@/mixins/AccessControl/AccessControlEnumeration";
import AppAccessControl from "@/components/app/AppAccessControl";
import BulkCalendarSaveForm from "@/components/unit/bulk_calendar/form/BulkCalendarSaveForm";
import BulkCalendarSaveAdjustedForm from "@/components/unit/bulk_calendar/form/BulkCalendarSaveAdjustedForm";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import UnitBulkRevenueManager from "@/components/unit/bulk_calendar/revenue_manager/UnitBulkRevenueManager";
import DailyAdjustmentForm from "@/components/unit/bulk_calendar/daily_adjustment/DailyAdjustmentForm";
import RevenueManagerSetupActionTable from "@/components/revenue_manager/setup/RevenueManagerSetupActionTable";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import ImportExportPricesForm from "@/components/pricing/ImportExportPricesForm";
import BulkCalendarCopyForm from "@/components/unit/bulk_calendar/form/BulkCalendarCopyForm";

export default {
    name: "index2",
    data() {
        return {
            saveAdjustedPayload: null,
            showSaveAdjustedConfirm: false,
            showSaveConfirm: false,
            disable: false,
            settingsAsidePanelState: false,
            rmPopoverShow: false,
            systemRatePlans: [],
            restrictions: [],
            selected_rate_plan_id: null,
            periodsSuggestionForm: false,
            losForm: false,
            occupancyForm: false,
            showLoading: false,
            overlay: true,
            form: null,
            STATUS_PROPERTY_UPDATE,
            STATUS_PROPERTY_IN_ACQUISITION,
            C_UNIT_CALENDAR_PERIODS_V,
            C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_V,
            C_UNIT_CALENDAR_PERIODS_APPLY,
            C_UNIT_CALENDAR_LOS_V,
            C_UNIT_CALENDAR_OCCUPANCY_V,
            MIN_STAY,
            SYSTEM_NON_REFUNDABLE_RATE,
            C_UNIT_CALENDAR_PERIODS_E,
            C_UNIT_CALENDAR_RESTRICTIONS_E,
            C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_MAKE,
            C_UNIT_CALENDAR_PRICE_IMPORT,
            C_UNIT_CALENDAR_PRICE_IMPORT_EXPORT,
            C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_ACQUISITION,
            C_UNIT_REVENUE_MANAGER_MIN_VALUES_V,
            C_UNIT_REVENUE_MANAGER_ACTION_V,
            C_UNIT_CALENDAR_SAVE,
            fetchData: false,
            rangesForm: false,
            periodsForm: false,
            applyPeriodsForm: false,
            showRangesSetupTab: 0,
            showPeriodsSetupTab: 0,
            loadingSaveAdjusted: false,
            loadingSave: false,
            saveAside: false,
            saveAdjustedAside: false,
            AC_BULK_CALENDAR,
            access_control_fetch_key: AC_BULK_CALENDAR,
            years: [],
            tab: 0,
            dailyAdjustmentAside: {
                open: false,
                period_id: null
            },
            openImportExportSidebar: false,
            C_UNIT_CALENDAR_COPY_ACQUISITION,
            C_UNIT_CALENDAR_COPY_UPDATE,
            copyInStatusAcquisitionPanelState: false,
            copyInStatusUpdatePanelState: false,
        }
    },
    mixins: [getErrorMessage, AccessControlComponent],
    computed: {
        defaultLos() {
            return this.losList.find(el => el.default === 1)
        },
        defaultOccupancy() {
            return this.occupancyList.find(el => el.default === 1)
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        currency() {
            if (typeof this.company !== 'undefined' && this.company !== null && this.company.hasOwnProperty('working_currency')) {
                return this.company.working_currency.code
            } else {
                return ''
            }
        },
        losList() {
            let data = []
            if (this.losListId.length > 0) {
                for (let losId of this.losListId) {
                    let los = this.$store.getters['pricing/getLosById'](losId)
                    if (typeof los !== 'undefined') {
                        los.label = los.max_stay ? los.min_stay + '-' + los.max_stay : los.min_stay
                        data.push(los)
                    }
                }
            }
            return data
        },
        occupancyList() {
            let data = []
            if (this.occupancyListId.length > 0) {
                for (let occupancyId of this.occupancyListId) {
                    let occupancy = this.$store.getters['pricing/getOccupancyById'](occupancyId)
                    if (typeof occupancy !== 'undefined') {
                        occupancy.label = occupancy.max_guests ? occupancy.min_guests + '-' + occupancy.max_guests : occupancy.min_guests
                        data.push(occupancy)
                    }
                }
            }
            return data
        },
        selectedRatePlan() {
            return this.$store.getters['pricing/getSystemRatePlanById'](this.selected_rate_plan_id)
        },
        unit() {
            return this.$store.getters['getUnit']
        },
        periodList() {
            return this.$store.getters['pricing/getPeriodList']
        },
        occupancyListId() {
            return this.$store.getters['pricing/getOccupancyList']
        },
        losListId() {
            return this.$store.getters['pricing/getLosList']
        },
        priceList() {
            return this.$store.getters['pricing/getPriceList']
        },
        restrictionList() {
            return this.$store.getters['pricing/getRestrictionList']
        },
        systemRatePlanList() {
            let system_rate_plans = []
            let list = this.$store.getters['pricing/getSystemRatePlanList']
            if (list.length > 0) {
                for (let id of list) {
                    let ratePlan = this.$store.getters['pricing/getSystemRatePlanById'](id)
                    if (ratePlan.rate_plan_category === SYSTEM_STANDARD_RATE) {
                        this.selected_rate_plan_id = ratePlan.id
                    }

                    system_rate_plans.push(ratePlan)
                }
            }
            return system_rate_plans
        },
        primaryRatePlan() {
            return this.systemRatePlanList.length > 0 ? this.systemRatePlanList.find(el => Number(el.default) === 1) : {}
        },
        hasPermissionView() {
            return this.$store.getters['user/getPermission'](C_UNIT_CALENDAR_V)
        },
        property() {
            return this.$store.getters['property/getProperty']
        },
    },
    components: {
        ImportExportPricesForm,
        AppObjectHeader,
        RevenueManagerSetupActionTable,
        DailyAdjustmentForm,
        UnitBulkRevenueManager,
        AppConfirmationDialog,
        BulkCalendarSaveForm,
        BulkCalendarSaveAdjustedForm,
        AppAccessControl,
        AppNoData,
        AppSelect,
        UnitBulkCalendarPricePanel,
        UnitBulkCalendarRestrictionPanel,
        PeriodList,
        SubmitButton,
        AppAside,
        RmSuggPriceAcquisitionForm,
        AppButton,
        SuggestionList,
        RestrictionApplyToAllDropdown,
        loading,
        UnitLosList,
        UnitOccupancyList,
        BulkCalendarCopyForm,
    },
    methods: {
        openDailyAdjustment(period_id) {
            this.dailyAdjustmentAside.open = true
            this.dailyAdjustmentAside.period_id = period_id
        },
        getPeriodById(period_id) {
            return this.$store.getters['pricing/getPeriodById'](period_id)
        },
        getPeriodStart(period_id) {
            return period_id && this.$store.getters['pricing/getPeriodById'](period_id) ? this.formatD(this.$store.getters['pricing/getPeriodById'](period_id).period_start) : null
        },
        formatD(value) {
            if (value) {
                const format = this.$t('DATE.DATE_FORMAT_SANS_YEAR')

                if (typeof value === 'number') {
                    return moment.unix(value).format(format)
                }

                return moment(String(value)).format(format)
            }
        },
        getPeriodEnd(period_id) {
            return period_id && this.$store.getters['pricing/getPeriodById'](period_id) ? this.formatD(this.$store.getters['pricing/getPeriodById'](period_id).period_end) : null
        },
        confirmationSave(data) {
            this.years = data.years
            this.showSaveConfirm = true
        },
        updateLoader() {

        },
        updateLos() {
            this.$store.dispatch('pricing/actionSetLosList', {unit: Number(this.unit.id)})
            .then(() => {
                    this.$store.dispatch('pricing/actionSetPriceListFromPrpSetup', {
                        property: Number(this.$route.params.id),
                        unit: Number(this.unit.id),
                        years: this.years,
                        sync: this.sync
                    })
                }, error => {
                    toast({
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 2000
                    })
                }
            )
        },
        updateOccupancy() {
            this.$store.dispatch('pricing/actionSetOccupancyList', {unit: Number(this.unit.id)})
            .then(() => {
                    this.$store.dispatch('pricing/actionSetPriceListFromPrpSetup', {
                        property: Number(this.$route.params.id),
                        unit: Number(this.unit.id)
                    })
                }, error => {
                    toast({
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 2000
                    })
                }
            )
        },
        savePrice() {
            this.loadingSave = true
            this.$store.dispatch('pricing/actionSavePricingToPrpSetup', {
                property: this.$route.params.id,
                unit: this.$route.params.unit_id,
                years: this.years,
            })
            .then(() => {
                    toast({
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                }, error => {
                    toast({
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 6000
                    })
                }
            ).finally(() => {
                this.loadingSave = false
                this.showSaveConfirm = false
                this.saveAside = false
            })
        },
        confirmationSaveAdjusted(data) {
            this.saveAdjustedPayload = {
                unit: this.$route.params.unit_id,
                adjust_value: data.adjust_value,
                adjust_value_type: data.adjust_value_type,
                sales_periods: data.sales_periods,
                years: data.years,
            }
            this.showSaveAdjustedConfirm = true
        },
        saveAdjustedPrice() {
            this.loadingSaveAdjusted = true
            setBulkCalendarBatchRateAdjust(this.$route.params.id, this.saveAdjustedPayload).then(() => {
                toast({
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })

                this.fetchData = false
                this.$store.dispatch('pricing/actionSetPriceList')
                this.$store.dispatch('pricing/actionSetStatistics')
                this.$store.dispatch('pricing/actionSetPriceListFromPrpSetup', {
                    property: Number(this.$route.params.id),
                    unit: Number(this.$route.params.unit_id)
                }).then(() => {
                    this.fetchData = true
                }, () => {
                    this.fetchData = true
                }).finally(() => {
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            }, error => {
                toast({
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(() => {
                this.loadingSaveAdjusted = false
                this.showSaveAdjustedConfirm = false
                this.saveAdjustedAside = false
            })
        },
        setRestrictions() {
            if (this.restrictions.length === 0) {
                getRestrictionList()
                .then(response => {
                    this.restrictions = response.data
                })
            }
        },
        applyFromStandard() {
            this.$store.dispatch('pricing/actionApplyRestrictionFromStandard')
        },
        getResetSuggestions() {
            let period_list = []
            for (let periodId of this.$store.getters['pricing/getPeriodList']) {
                let period = this.$store.getters['pricing/getPeriodById'](periodId)
                let object = {
                    date_from: this.formatDate(period.period_start),
                    date_to: this.formatDate(period.period_end)
                }
                period_list.push(object)
            }
            let requestObject = {
                unit: Number(this.$route.params.unit_id),
                period_list
            }
            this.showLoading = true
            getRmResetSuggestions(requestObject).then(response => {
                if (response.data.length > 0) {
                    for (let suggestions of response.data) {
                        this.$store.dispatch('pricing/actionSetRmSuggestion', suggestions)
                    }
                }
            }).finally(() => {
                this.showLoading = false
            })
        },
        formatDate(value) {
            if (value) {
                let currentYear = new Date().getFullYear()
                value = value + '/' + currentYear
                let date = new Date(value)
                return moment(date).format('Y-MM-DD')
            }
        },
        setData(value) {
            this.rangesForm = false
            EventBus.$emit(GE_LOADER_SHOW)
            this.fetchData = false
            this.setRestrictions()
            let promise1 = this.$store.dispatch('pricing/actionSetLosList', {unit: value})
            let promise2 = this.$store.dispatch('pricing/actionSetSalePeriodList', {unit: value})
            let promise3 = this.$store.dispatch('pricing/actionSetOccupancyList', {unit: value})
            let promise4 = this.$store.dispatch('pricing/actionSetSystemRatePlan', {
                unit: value,
                category_ids: [SYSTEM_STANDARD_RATE, SYSTEM_NON_REFUNDABLE_RATE]
            })
            Promise.all([promise1, promise2, promise3, promise4]).then(result => {
                if (result[1].data.length > 0) {
                    this.access_control_components = (result[1].data.length * 2)
                } else {
                    this.access_control_components = 2
                }
                this.$store.dispatch('pricing/actionSetRestrictionList')
                this.$store.dispatch('pricing/actionSetPriceList')
                this.$store.dispatch('pricing/actionSetStatistics')
                this.$store.dispatch('pricing/actionSetPriceListFromPrpSetup', {
                    property: Number(this.$route.params.id),
                    unit: Number(value)
                }).then(() => {
                    this.fetchData = true
                }, () => {
                    this.fetchData = true
                }).finally(() => {
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            })
        },
        pricesImported(args) {
            if (args.hasOwnProperty('unit')) {
                this.setData(args.unit)
            }
            this.openImportExportSidebar = false
        }
    },
    watch: {
        '$route.params.id': {
            handler(propertyId) {
                this.$store.dispatch('property/actionSetProperty', Number(propertyId))
                this.access_control_general_context = {
                    property: propertyId,
                    company: this.company.id
                }
            },
            immediate: true,
            deep: true
        },
        '$route.params.unit_id': {
            async handler(value) {
                await this.setData(value)
            },
            immediate: true,
            deep: true
        },
    },
    destroyed() {
        //    this.$store.unregisterModule('unit')
    },
}
</script>

<style scoped>

.daily-adjustment-no-border {
    box-shadow: none;
}

.daily-adjustment-no-border > div {
    border: none !important;
    padding-left: 0;
}

</style>
