<template>
    <app-aside v-model="opened">
        <template slot="header">
            <calendar-aside-header
                :dateRange="dateRange" :property="property" :rootUnit="rootUnit" :contigentMode="contigentMode" :contigentUnit="contigentUnit" :countDiffDays="countDiffDays">
            </calendar-aside-header>
        </template>
            <b-row>
                <b-col>
                    <calendar-availability
                        @successSetAvailability="successAvailability"
                        :default-values="defaultValues"
                        :date-ranges="dateRanges"
                        :root-unit="rootUnit"
                        :contigent-unit="contigentUnit"
                        :contigent-mode="contigentMode"
                        :property="property"></calendar-availability>
                </b-col>
            </b-row>
            <b-row class="mt-5" v-if="previewNewReservation">
                <b-col class="d-flex justify-content-center">
                    <app-button v-if="checkPermission(C_RESERVATION_ACCOMMODATION_NEW) " button_type="new" class="mr-3" variant="primary" @click="openNewReservation">{{
                        $t("NEW_RESERVATION") }}
                    </app-button>
                </b-col>
            </b-row>
    </app-aside>
</template>

<script>
    import moment from 'moment'
    import CalendarAsideRestrictions from "@/components/unit/calendar_opt/Aside/Calendar/CalendarAsideRestrictions";
    import CalendarAsideRates from "@/components/unit/calendar_opt/Aside/Calendar/CalendarAsideRates";
    import CalendarAsideRoomStatus from "@/components/unit/calendar_opt/Aside/Calendar/CalendarAsideRoomStatus";
    import CalendarAvailability from "@/components/calendar/aside/CalendarAvailability";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
    import AppAside from "@/components/app/form/AppAside";
    import {
        C_PROPERTY_CALENDAR_AVAILIBILITY_E,
        C_PROPERTY_CALENDAR_AVAILIBILITY_V,
        C_RESERVATION_ACCOMMODATION_NEW
    } from "@/shared/component_permission";

    import {
        EventBus, GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY, GE_LOADER_HIDE, GE_LOADER_SHOW,

    } from "@/shared/EventBus";

    import CalendarAsideHeader from "@/components/calendar/aside/CalendarAsideHeader";
    import {getUnitCalendarValues} from "@/services/unit";
    import {find} from 'lodash'

    export default {
        name: "PropertyCalendarAside",
        components: {
            CalendarAsideHeader,
            CalendarAsideRestrictions,
            CalendarAsideRates,
            CalendarAsideRoomStatus, CalendarAvailability, AppButton, AppDateRangePicker, AppAside
        },
        props: {
            elements: {
                type: Array
            },

        },
        data() {
            return {
                rootUnit:null,
                ratePlan: null,
                contigentUnit: null,
                contigentMode: true,
                defaultValues: null,
                opened: false,
                tab: null,
                tabs: [
                    C_PROPERTY_CALENDAR_AVAILIBILITY_V
                ],
                dateRange: {
                    start: null,
                    end: null
                },
                property:{},
                dateRanges:[],
                C_RESERVATION_ACCOMMODATION_NEW,
                initialUnits:null,
            }
        },
        computed: {
            countDiffDays(){
                let start = moment(this.dateRange.start);
                let end = moment(this.dateRange.end);
                let duration = moment.duration(start.diff(end));
                return duration.asDays();
            },
            previewAvailability() {
                return this.elements.includes(C_PROPERTY_CALENDAR_AVAILIBILITY_V) && this.checkPermission(C_PROPERTY_CALENDAR_AVAILIBILITY_E)
            },
            previewNewReservation(){
                if(this.countDiffDays === 0){
                    return false
                }
                if(this.defaultValues && this.defaultValues.hasOwnProperty('reservation')){
                    return this.defaultValues.reservation.allowed
                }
                return false


            },
        },
        methods: {
            openNewReservation(){
                let reqNewReservation = {
                    unit_id: this.contigentMode ? null : this.contigentUnit ? this.contigentUnit.id : this.rootUnit.id,
                    property:this.property,
                    dateRange:this.dateRange
                }
                this.$emit('res_aside',reqNewReservation)
                this.opened = false
            },
            successAvailability(){
                this.$emit('success',{property_id:this.property.id})
                this.opened = false
            }
        },
        watch:{
            dateRanges: {
                handler(value) {
                    if (value.length) {
                        EventBus.$emit(GE_LOADER_SHOW)
                        getUnitCalendarValues(
                            this.contigentUnit ? this.contigentUnit.id : this.rootUnit.id,
                            {
                                periods: value,
                                contigent: this.contigentMode,
                                rate_plan: this.ratePlan,
                                show: this.CalValuesReqData
                            })
                            .then(response => {

                                this.defaultValues = response.data
                                if (response.data && response.data.hasOwnProperty('availability')) {
                                    this.initialUnits = response.data.availability.value
                                }

                            }).finally(() => {
                            EventBus.$emit(GE_LOADER_HIDE)
                        })
                    }
                },
                immediate: true,
                deep: true
            },
            dateRange:{
                handler(objectValue){
                    this.dateRanges = []
                    if(objectValue.start && objectValue.end){
                        this.dateRanges.push(objectValue)
                    }
                }
            },deep:true
        },

        created() {

            EventBus.$on(GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY, (data) => {
                this.dateRange = data.dateRange
                this.property = data.hasOwnProperty('property') ? data.property : null
                this.rootUnit = data.hasOwnProperty('rootUnit') ? data.rootUnit : null
                this.contigentMode = data.hasOwnProperty('contigentMode') ? data.contigentMode : null
                this.contigentUnit = data.hasOwnProperty('contigentUnit') ? data.contigentUnit : null
                this.opened = true
            })


        },
        beforeDestroy(){
            EventBus.$off(GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY)
        }
    }
</script>

<style scoped>
.calendar-new-res{
    border-top:1px solid red;
}
</style>
