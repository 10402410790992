import { render, staticRenderFns } from "./DistributionReservationParameterTypeItem.vue?vue&type=template&id=d06fed58&scoped=true&"
import script from "./DistributionReservationParameterTypeItem.vue?vue&type=script&lang=js&"
export * from "./DistributionReservationParameterTypeItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d06fed58",
  null
  
)

export default component.exports