<template>
<div>
    <app-table-v2 :provider="getData" :fields="fields" @busyToggled="toggleBusy"
                  refresh-event="refreshColorTable" deselect-event="deselectColorTable">
        <template v-slot:category="data">
            {{data.item.category.label}}
        </template>
        <template v-slot:color="data">
            <div :style="'background-color:'+data.item.hex+';height:30px;width:30px;border-radius:15px;border:1px solid #ececec'"></div>
        </template>
        <template v-slot:hsl="data">
            <span>{{hslSplit(data.item.hsl)}}</span>
        </template>
        <template v-slot:edit="data">
            <app-button variant="link"
                        button_type="edit"
                        @click="openEditColor(data.item)"
                        :show_text="false">
            </app-button>
        </template>
    </app-table-v2>
    <app-aside v-model="newColorAside" :widths="['col-lg-3 col-sm-5', '40%']">
        <template slot="header"><app-object-header :name="$t('NEW_COLOR')"></app-object-header></template>
        <template v-slot="props">
            <appearance-new-color-form @color_saved="closeNewColorAside"></appearance-new-color-form>
        </template>
    </app-aside>
    <app-aside v-model="editColorAside" :widths="['col-lg-3 col-sm-5', '40%']">
        <template slot="header"><app-object-header :label="colorName" :name="$t('EDIT_COLOR')"></app-object-header></template>
        <template v-slot="props">
            <appearance-edit-color :color="colorObject" @saved-edit-color="closeEditColor"></appearance-edit-color>
        </template>
    </app-aside>
</div>
</template>

<script>
import {getThemeColors} from "@/services/appearance";
import AppTableV2 from "@/components/app/AppTableV2";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import AppearanceNewColorForm from "@/components/appearance/color/AppearanceNewColorForm";
import AppearanceEditColor from "@/components/appearance/color/AppearanceEditColor";
import {EventBus, GE_TABLE_SEARCH} from "@/shared/EventBus";
import {cloneDeep} from "lodash";

export default {
    name: "AppearanceColorTable",
    components:{AppTableV2, AppButton, AppAside, AppObjectHeader, AppearanceNewColorForm, AppearanceEditColor},
    data(){
        return {
            fields:[
                {key: 'id', label: this.$t('ID'),},
                {key: 'color', label:this.$t('COLOR')},
                {key: 'label', label: this.$t('NAME')},
                {key: 'category', label:this.$t('CATEGORY')},
                {key: 'hex', label:this.$t('HEX') },
                {key: 'rgb', label:this.$t('RGB')},
                {key: 'hsl', label:this.$t('HSL')},
                {key: 'edit', label:'', class: 'text-right'}
            ],
            filter:{},
            newColorAside: false,
            editColorAside: false,
            colorName: null,
            colorObject: []
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods:{
        getData(filter){

            let req = {
                ...this.filter,
                ...{
                    page: filter.currentPage,
                    perPage: filter.perPage,
                    order_by: filter.sortBy,
                    company_id: this.company.id
                }
            }

            return getThemeColors(req)
        },
        toggleBusy($event) {
            EventBus.$emit("SearchColorTableLoading", $event)
        },
        hslSplit(hsl){
            const str = hsl.split(',')
            return str[0] + ', ' + str[1] + '%, ' + str[2] + '%'
        },
        closeNewColorAside(){
            this.newColorAside = false
            EventBus.$emit("refreshColorTable")
        },
        openEditColor(data){
            this.colorObject = data
            this.colorName = data.label
            this.editColorAside = true
        },
        closeEditColor(){
            this.editColorAside = false
            EventBus.$emit("refreshColorTable")
        }
    },
    created() {
        EventBus.$on("SearchColorTable", filter => {
            this.filter = cloneDeep(filter)
        })
        EventBus.$on('AddNewColor', () => {
            this.newColorAside = true
        })
    },
    watch:{
        filter:{
            handler(){
                EventBus.$emit(GE_TABLE_SEARCH)
            }
        }
    }
}
</script>

<style scoped>

</style>
