<template>
    <b-card v-cloak class="property-wrapper">
        <template slot="header">
            <div class="d-flex justify-content-between mb-0">
                <div>
<!--                    <div class="header-caption">{{ $t('MARKETPLACE') }}</div>-->
                    <h2 class="property-wrapper-padding">{{ $t('B2B') }}</h2>
                </div>
            </div>
            <Navigation
                :nav-elements="navigation">
            </Navigation>
        </template>
        <app-transition transition-type="slide">
            <router-view v-cloak></router-view>
        </app-transition>
    </b-card>
</template>

<script>

import Navigation from "@/components/app/navigation/Navigation";
import AppTransition from "@/components/app/transition/AppTransition";
import routeNames from "@/router/routeNames";

export default {
    name: "Index",
    components:{Navigation, AppTransition},
    data(){
        return{
            navigation:[
                {route: {name: routeNames.RN_DIRECT_BOOKING_MARKETPLACE_GENERAL}},
                {route: {name: routeNames.RN_DIRECT_BOOKING_MARKETPLACE_LIST}},
            ]
        }
    }
}
</script>

<style scoped>

</style>
