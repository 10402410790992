<template>
    <div>
        <b-form @submit.prevent="save">
            <b-row>
                <b-col class="mb-4">
                    <label>{{ $t('COPY_FROM_UNIT') }}</label>
                    <b-input-group>
                        <b-input type="text"
                                 v-model="unitObject.title || unitObject.name"
                                 disabled
                        ></b-input>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="mb-4">
                    <label>{{ $t('UNIT_SELECTION') }}</label>
                    <app-select :search-empty-item="false"
                                :options="unitListComputed"
                                v-model="units"
                                value-field="id"
                                text-field="name"
                                mode="multiselect"
                                name="units"
                    ></app-select>
                </b-col>
            </b-row>

            <b-row>
                <label>{{ $t('SELECT_OPTIONS_TO_COPY') }}</label>
            </b-row>
            <b-row>
                <b-col class="mb-4">
                    <b-form-checkbox
                        inline
                        v-model="select_all_option"
                        aria-describedby="options"
                        aria-controls="options"
                        @change="toggleOptions"
                        :checked="true"
                        :unchecked-value="false">
                        {{ $t('SELECT_ALL') }}
                    </b-form-checkbox>
                    <b-form-checkbox-group
                        class="ml-4"
                        stacked
                        value-field="id"
                        text-field="name"
                        name="options"
                        v-model="options"
                        :options="optionListComputed"
                    ></b-form-checkbox-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <app-button-submit :loading="loader"
                                       :disabled="options.length === 0 || units.length === 0"
                    >{{ $t('COPY') }}</app-button-submit>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {EventBus} from "@/shared/EventBus";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {copyRevenueManagerUnitSetup} from "@/services/revenue_manager";
import {WORKFLOW_DIRECT_BOOKER} from "@/shared/constants";

export default {
    name: "RevenueManagerUnitCopySetupForm",
    components: {AppSelect, AppButtonSubmit},
    mixins: [getErrorMessage],
    props: {
        unitObject: {
            type: Object,
            required: true
        },
        unitList: {
            type: Array,
            default() {
                return []
            }
        },
        company: {
            type: Object
        }
    },
    data() {
        return {
            loader: false,
            units: [],
            options: [],
            select_all_option: false,
        }
    },
    computed: {
        unitListComputed() {
            return this.unitList.filter(unit => unit.id !== this.unitObject.id).map(unit => {
                return {
                    id: unit.id,
                    name: unit.title || unit.name
                }
            })
        },
        optionListComputed() {
            let options = []
            if (this.company.workflow_id === WORKFLOW_DIRECT_BOOKER) {
                options.push({
                    id: "availability_deviation",
                    name: this.$t('HIGH_AVAILABILITY')
                })
                options.push({
                    id: "rate_deviation",
                    name: this.$t('ALLOWED_RATE_DECREASE')
                })
            }
            options.push({
                id: "secured_rate",
                name: this.$t('SECURED_VALUES') + ': ' + this.$t('RATES')
            })
            options.push({
                id: "secured_minimum_stay",
                name: this.$t('SECURED_VALUES') + ': ' + this.$t('MIN_STAY')
            })
            return options
        },
    },
    methods: {
        save() {
            this.loader = true
            const request = {
                source_unit: this.unitObject.id,
                destination_units: this.units,
                options: this.options,
            }
            copyRevenueManagerUnitSetup(request).then(response => {
                this.$emit('close')
                EventBus.$emit('gl_revenue_setup_refresh_property')
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })
            }).finally(() => {
                this.loader = false
            })
        },
        toggleOptions() {
            this.options = []
            if (!this.select_all_option) {
                Object.keys(this.optionListComputed).forEach(key => {
                    this.options.push(this.optionListComputed[key].id)
                })
            }
        },
    },
    watch: {
        options(newVal, oldVal) {
            // Handle changes in individual flavour checkboxes
            if (newVal.length === 0) {
                this.select_all_option = false
            } else {
                this.select_all_option = newVal.length === this.optionListComputed.length;
            }
        },
    },
    created() {
        this.select_all_option = false
        this.toggleOptions()
    },
}
</script>

<style scoped>

</style>
