<template>
    <div v-cloak class="app flex-row align-items-center">
        <div class="container">
            <b-row class="justify-content-center">
                <b-col md="6">
                    <b-card-group>
                        <b-card no-body class="p-0">
                            <b-card-header class="pl-0">
                                <span>{{$t('EASE_OF_BUSINESS')}}</span>
                                <h1>{{$t('LOGIN')}}</h1>
                            </b-card-header>

                            <b-card-body class="p-0">
                                <b-form @submit.prevent="submitLogin" autocomplete="off">
                                    <b-input-group class="mb-3">
                                        <b-input-group-prepend>
                                            <b-input-group-text><i>@</i></b-input-group-text>
                                        </b-input-group-prepend>
                                        <b-form-input
                                            :state="usernameState"
                                            type="text"
                                            class="form-control"
                                            :placeholder="$t('EMAIL')"
                                            aria-describedby="inputUsernameFeedback"
                                            v-model.trim="username">
                                        </b-form-input>
                                        <b-form-invalid-feedback id="inputUsernameFeedback">
                                            {{$t('USERNAME_MANDATORY')}}
                                        </b-form-invalid-feedback>
                                    </b-input-group>
                                    <b-input-group class="mb-1">
                                        <b-input-group-prepend>
                                            <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                                        </b-input-group-prepend>
                                        <b-form-input
                                            type="password"
                                            :state="passwordState"
                                            class="form-control"
                                            :placeholder="$t('PASSWORD')"
                                            aria-describedby="inputPasswordFeedback"
                                            v-model="password">
                                        </b-form-input>
                                        <b-form-invalid-feedback id="inputPasswordFeedback">
                                            {{$t('PASSWORD_MANDATORY')}}
                                        </b-form-invalid-feedback>
                                    </b-input-group>
                                    <b-row class="mb-3">
                                        <b-col class="text-left">
                                            <a
                                                :href="redirectToForgetPassword" target='_top'>{{$t("FORGOT_PASSWORD")}}
                                            </a>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-3">
                                        <b-col cols="6">
                                            <app-submit :disabled="maintenance" button-class="button-min-width" :loading="show">{{$t('LOGIN')}}</app-submit>
                                        </b-col>
                                        <b-col cols="6">
                                            <p class="text-right">
                                                <a :href="redirectToRegister" target='_top' class="ml-2">{{$t('NOT_REGISTER_YET')}}</a>
                                            </p>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-4">
                                        <b-col>
                                            <b-alert variant="danger" :show="maintenance">
                                               {{$t('MAINTENANCE_MESSAGE')}}
                                             </b-alert>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-4">
                                        <b-col>
                                            <b-alert variant="danger" :show="showError">{{errorMessage}}
                                            </b-alert>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-card-body>
                        </b-card>

                    </b-card-group>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import routeName from '@/router/routeNames'
    import AppValidationDescription from "@/components/app/form/AppValidationDescription";
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {
        RegisterPageEn,
        RegisterPageHr,
        RegisterPageEs, RegisterPageIt, RegisterPageNl, RegisterPageDe, RegisterPageDa,
        ForgotPasswordPageEn,
        ForgotPasswordPageHr, ForgotPasswordPageEs, ForgotPasswordPageDa, ForgotPasswordPageIt, ForgotPasswordPageNl,
        LS_PRESETS, ForgotPasswordPageFallback, RegisterPageFallback, ForgotPasswordPageDe,
    } from "@/shared/constants";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {getPresets} from "@/services/user";
    export default {
        name: 'Login',
        mixins: [getErrorMessage],
        components: {
            AppSubmit,
            AppButton,
            AppValidationDescription,
        },
        data() {
            return {
                maintenance:false,
                routeName,
                username: '',
                password: '',
                usernameState: null,
                passwordState: null,
                show: false,
                label: 'Logging in...',
                overlay: true,
                error: "",
                showError: false,
                showSuccessMessage: false
            }
        },
        computed: {
            errorMessage: function () {
                return this.error
            },
            redirectToForgetPassword() {
                if (this.$i18n.locale == 'hr') {
                    return ForgotPasswordPageHr
                }
                if (this.$i18n.locale == 'en'){
                    return ForgotPasswordPageEn
                }
                if (this.$i18n.locale == 'es'){
                    return ForgotPasswordPageEs
                }
                if (this.$i18n.locale == 'da'){
                    return ForgotPasswordPageDa
                }
                if (this.$i18n.locale == 'de'){
                    return ForgotPasswordPageDe
                }
                if (this.$i18n.locale == 'it'){
                    return ForgotPasswordPageIt
                }
                if (this.$i18n.locale == 'nl'){
                    return ForgotPasswordPageNl
                }
                return ForgotPasswordPageFallback + this.$i18n.locale
            },
            redirectToRegister() {
                if (this.$i18n.locale == 'hr') {
                    return RegisterPageHr
                }
                if (this.$i18n.locale == 'en'){
                    return RegisterPageEn
                }
                if (this.$i18n.locale == 'es'){
                    return RegisterPageEs
                }
                if (this.$i18n.locale == 'da'){
                    return RegisterPageDa
                }
                if (this.$i18n.locale == 'de'){
                    return RegisterPageDe
                }
                if (this.$i18n.locale == 'it'){
                    return RegisterPageIt
                }
                if (this.$i18n.locale == 'nl'){
                    return RegisterPageNl
                }
                return RegisterPageFallback + this.$i18n.locale
            }

        },

        methods: {
            submitLogin() {
                localStorage.clear();
                const formData = {
                    password: this.password,
                    username: this.username
                }
                this.show = true
                this.showError = false
                this.showSuccessMessage = false

                this.$store.dispatch('auth/actionLogin', {
                    username: formData.username,
                    password: formData.password,
                    lang: this.$i18n.locale
                })
                    .then(() => {
                        let link =  this.$router.currentRoute.query.redirect ?  this.$router.currentRoute.query.redirect : '/'
                        this.$store.dispatch('user/actionSetUser').then(()=>{
                            this.$store.dispatch('user/actionSetUserPermissions').then(() => {

                                if (!this.$router.currentRoute.query.redirect) {
                                    this.$router.replace({name: routeName.RN_HOME})
                                } else {
                                    if (this.$router.currentRoute.query.redirect === '/login') {
                                        this.$router.replace({name: routeName.RN_HOME})
                                    } else {
                                        this.$router.push(link)
                                    }
                                }

                            }).finally(() =>{
                                this.show = false
                            })
                        })


                    }).catch(error => {
                    if(error.response.status===302 && error.response.data.user_id){
                        this.$router.replace({
                            name: routeName.RN_CHANGE_EXPIRED_PASSWORD,
                            query: {
                                user_id: error.response.data.user_id,
                                lang: this.$i18n.locale
                            }
                        })
                    }
                    this.password = null
                    this.error = error.response.data.message_description
                    this.show = false
                    this.showError = true

                })
            }
        },
        watch:{
            '$route.query.lang':{
                handler(value){
                    if(value === 'sl'){
                        this.$i18n.locale = 'si'
                    }  else  {
                        this.$i18n.locale = value ? value  : 'en'
                    }
                },
                immediate:true
            }
        },
        beforeRouteEnter(to, from, next) {
            document.body.classList.add('page-login');
            document.body.classList.remove('page-w-login')
            next()
        },
    }

</script>
