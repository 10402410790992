<template>
    <div v-if="hasViewPermission">
        <b-collapse visible :id="'filter'">
            <cm-queue-filter  @search="setFilter" :loading="loading"></cm-queue-filter>
        </b-collapse>
        <queue-list @busyToggled="toggleBusy" :filter="filter" :showProperty="true"></queue-list>
    </div>
</template>

<script>

    import QueueList from "@/components/channel_manager/synchronization_log/QueueList2";
    import AppPagination from "@/components/app/filter/AppPagination";
    import {C_PROPERTY_CM_PM_QUEUE_V} from "@/shared/component_permission";
    import CmQueueFilter from "@/components/channel_manager/synchronization_log/CmQueueFilter";
    export default {
        name: "Index",
        components: {CmQueueFilter, AppPagination, QueueList},
        data() {
            return {
                tableData: {
                    items: [],
                    total: 0
                },
                filter:null,
                currentPage: 1,
                lastPage: 0,
                loading: false,
            }
        },
        computed: {
            hasViewPermission() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_CM_PM_QUEUE_V)
            }
        },
        watch: {
            currentPage(val) {
                this.filter.page = val
                this.getTableData(this.filter)
            }
        },
        methods: {
            setFilter(values) {
                this.filter = values
            },
            toggleBusy($event) {
                this.loading = $event
            },
        }
    }
</script>

<style scoped>

</style>
